import React, { useEffect } from 'react'
import Nav from 'react-bootstrap/Nav';
import Button from '../../components/Button/Button';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FaDownload } from 'react-icons/fa';
import PayslipDetails from '../../components/ApprovedPaySlip/PayslipDetails';
import PaymentDetails from '../../components/DisbursedPaySlip/PaymentDetails';


const DisbursedPaySlip = () => {

    const [tab, setTab] = React.useState('Payslip Details')
    const [paySlip, setPaySlip] = React.useState({})

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const payslipNumber = useParams().payslipNumber

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/payslip/getPayslipByPayslipNumber?payslipNumber=${payslipNumber}&payslipStatus=Salary%20Disbursed`,
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response)
                setPaySlip(response.data.payslip)
            })

    }, [])

    return (
        <div>

            <div className='approvedPaySlip'>

                <div className='row justify-content-between'>

                    <div className='col-2 text-center'>
                        <Button navigateTo='/payRoll' />
                    </div>

                    <div className='col-10 text-center'>
                        <div className='row d-flex justify-content-evenly py-2'>

                            <div className='col-2'>
                                <a href={paySlip?.payslipS3Link}
                                    style={{ cursor: 'pointer' }}
                                    download><FaDownload className='download' style={{ fontSize: '30px' }} /></a>
                            </div>

                            <div className='col-4'>
                                <h5><b>Employee : </b>{paySlip?.employeeFullName}</h5>
                            </div>

                            <div className='col-4'>
                                <h5><b>Month : </b>{paySlip?.monthName} {`(${paySlip?.noOfDaysInMonth} Days)`}</h5>
                            </div>

                            <div className='col-2'>
                                <h5><b>Year : </b>{paySlip?.year}</h5>
                            </div>

                        </div>
                    </div>

                </div>

                <br />
                <br />

                <div className="tabs mb-5">
                    <Nav>
                        <Nav.Item
                            onClick={() => {
                                setTab('Payslip Details')
                            }}
                        >
                            <Nav.Link className={tab === 'Payslip Details' ? 'active' : 'inactive'} eventKey="first">
                                Payslip Details
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item
                            onClick={() => {
                                setTab('Payment Details')
                            }}
                        >
                            <Nav.Link className={tab === 'Payment Details' ? 'active' : 'inactive'} eventKey="second">
                                Payment Details
                            </Nav.Link>
                        </Nav.Item>

                    </Nav>

                </div>

                {tab === 'Payslip Details' && <PayslipDetails paySlip={paySlip} />}
                {tab === 'Payment Details' && <PaymentDetails paySlip={paySlip} />}

            </div>

        </div>
    )

}

export default DisbursedPaySlip