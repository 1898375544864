import MaterialTable, { MTableToolbar } from 'material-table'
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../utils/config/url'
import axios from 'axios'


const OpenSalaryAdvance = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const [salaryAdvanceList, setSalaryAdvanceList] = useState([])


    useEffect(() => {

        let url = `${BASE_URL}/api/v1/hrmgmt/salaryAdvance/getListOfSalaryAdvance?status=Open`;
        
        axios.get(url,
            {
                headers: {
                    Authorization: `Bearer ${userInfo?.token}`
                }
            })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status) {
                    setSalaryAdvanceList(response.data.SalaryAdvance)
                } else {
                    setSalaryAdvanceList([])
                }
            })


    }, [])

    const dateFormatting = (date) => {
        let d = new Date(date)
        let year = d.getFullYear()
        let month = d.getMonth() + 1
        let day = d.getDate()

        return `${day}/${month}/${year}`
    }


    return (
        <div>
            {/* <h4>Open Salary Advance</h4> */}
            <MaterialTable
                data={salaryAdvanceList}
                title="Open Salary Advance"
                columns={[

                    {
                        title: "Applied By",
                        render: (rowData) => {
                            return `${rowData?.employeeFullName || ''} (${rowData?.employeeId})`
                        }
                    },
                    { title: "Applied By EmpId", field: "employeeId" },

                    {
                        title: "Salary Advance Id",
                        field: 'salaryAdvanceId'
                    },
                    { title: 'Approved Amount', field: 'approvedAmount' },
                    { title: "Remaining Amount", field: "remainingAmount" },
                    { title: "No. Of Installments", field: "noOfInstallments" },
                    { title: "Paid Installments", field: "paidInstallments" },
                    { title: "Installment Amount", field: "installmentAmount" },


                ]}

                // actions={[
                //     {
                //         icon: 'remove_red_eye',
                //         tooltip: 'View Salary Advance',
                //         // onClick: (event, rowData) => {
                //         //     navigate(`/viewemployees/${rowData.employeeId}`)
                //         // },
                //     },

                // ]}


                options={{
                    search: true,
                    searchFieldStyle: {
                        width: 180
                    },
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    actionsCellStyle: {
                        width: 120,
                        paddingLeft: 30,
                    },
                    sorting: true,
                    headerStyle: {
                        backgroundColor: "#EE4B46",
                        color: "#fff",
                        paddingLeft: "11px",
                    },
                }}


              

            ></MaterialTable >
        </div>
    )
}

export default OpenSalaryAdvance