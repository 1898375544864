import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Form from 'react-bootstrap/Form';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import './HoldUnit.scss';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const HoldUnit = ({ invLead, change, setChange }) => {

    

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const { leadId } = useParams();


    const [siteList, setSiteList] = useState([]);
    const [site, setSite] = useState(`${invLead?.site?.siteName},${invLead?.site?.siteCode}`);
    const [unitList, setUnitList] = useState([]);
    const [unit, setUnit] = useState('')
    const [phaseList, setPhaseList] = useState([]);
    const [phase, setPhase] = useState('');
    const [amount, setAmount] = useState('');
    const [holdType, setHoldType] = useState('')
    const [error, setError] = useState('');
    const [cancellationReason, setCancellationReason] = useState('')

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/configuration/site/getAllSiteNames`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response.data);
                setSiteList(response.data.siteList)
            })

    }, []);


    useEffect(() => {

        (async () => {

            let siteCode = site.split(',')[1];

            const res = await
                axios
                    .get(`${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`,
                        { headers: { Authorization: `bearer ${userInfo.token}` } });
            const data = res.data.site;
            const phases = data?.phases;
            setPhaseList(phases);
        })();
    }, []);


    useEffect(() => {

        if (!phase) {
            setUnit("")
            setUnitList()
        }

        if (site && phase) {

            let siteCode = site.split(',')[1];
            let phaseCode = phase.split(',')[1];

            (async () => {
                const res = await
                    axios.get(`${BASE_URL}/api/v1/Configuration/Unit/getlistofunit?siteCode=${siteCode}&phaseCode=${phaseCode}&status=Available`,
                        { headers: { Authorization: `bearer ${userInfo.token}` } });
                const data = res.data.unitList;
                // console.slog(data)
                setUnitList(data);
            })();
        }
    }, [phase]);

    const putUnitOnHoldWithoutHoldAmount = () => {


        let body = {
            leadID: leadId,
            holdBy: userInfo.userName,
            unitName: unit,
        }


        axios.post(`${BASE_URL}/api/v1/leadmgmt/lead/putUnitOnHoldWithoutHoldAmount`, body, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response.data);
                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    })
                        .then(() => {
                            reset();
                            setChange(!change)
                        })
                }
            })
            .catch((error) => {
                // console.log(error)
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: error.response.data.error
                })
            })
    }

    const reset = () => {
        setSite('')
        setPhase('')
        setUnit('')
        setAmount('')
        setHoldType('')
    }

    const dateFormatting = (input) => {

        if (!input) {
            return 'N/A'
        }
        // console.log(input)
        let d = new Date(input)
        // console.log(d)

        let date = d.getDate()
        if (date < 10) {
            date = `0${date}`
        }
        let m = d.getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = d.getFullYear()

        let final;

        final = `${date}/${m}/${y}`
        return final

    }

    const releaseUnitHold = () => {

        if (!cancellationReason) return;

        axios.post(`${BASE_URL}/api/v1/leadmgmt/lead/releaseUnitOnHold`,
            {
                leadID: leadId,
                releaseReason: cancellationReason,
                userName: userInfo.userName
            }, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    })
                        .then(() => {
                            reset();
                            setChange(!change)
                        })
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: error.response.data.error
                })
            })
    }

    return (
        <div className='hold-unit'>

            <Paper elevation={2} style={{ padding: '20px' }}>

                <h4 className='text-center mb-4'><b>Hold Unit</b></h4>

                <div className='row justify-content-center'>
                    <div className='col-6'>

                        {/* <Form.Group controlId="Site">
                            <Form.Label>Site</Form.Label>
                            <Form.Control required
                                disabled={invLead?.finalQuotation === false}
                                onChange={(e) => setSite(e.target.value)} as="select">
                                <option value="">Select a Site</option>
                                {
                                    siteList?.map((site, index) => {
                                        return <option key={index} value={`${site.SiteName},${site.SiteCode}`}>{site.SiteName}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group> */}
                        <label>Site</label>
                        <input type="text"
                            className='form-control'
                            readOnly
                            value={invLead?.site?.siteName}
                        />

                    </div>

                    <div className='col-6'>

                        <Form.Group controlId="Phase">
                            <Form.Label>Phase</Form.Label>
                            <Form.Control required
                                disabled={invLead?.isHoldCancelled === true}
                                onChange={(e) => setPhase(e.target.value)} as="select">
                                <option value="">Select a Phase</option>
                                {
                                    phaseList?.map((phase, index) => {
                                        return <option key={index} value={`${phase.phaseName},${phase.phaseCode}`}>{phase.phaseName}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>

                    </div>

                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-6'>

                        <Form.Group controlId="Unit">
                            <Form.Label>Unit</Form.Label>
                            <Form.Control required
                                disabled={invLead?.isHoldCancelled === true}
                                onChange={(e) => setUnit(e.target.value)} as="select">
                                <option value="">Select a Unit</option>
                                {
                                    unitList?.map((unit, index) => {
                                        return <option key={index} value={unit.unitName}>{unit.unitName}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>

                    </div>

                    <div className='col-6'>
                        {/* <label>Unit Hold Amount <em style={{ color: '#EE4B46' }}>(Min 10000)</em></label>
                        <input type="number"
                            className='form-control'
                            disabled={invLead?.finalQuotation === false}
                            value={amount}
                            onChange={(e) => {
                                setAmount(e.target.value)
                            }} />
                        {error && <em style={{ color: '#EE4B46' }}>{error}</em>} */}
                        <Form.Group controlId="Unit">
                            <Form.Label>Hold Type</Form.Label>
                            <Form.Control required
                                disabled={invLead?.isHoldCancelled === true}
                                value={holdType}
                                onChange={(e) => setHoldType(e.target.value)} as="select">
                                <option value="">Select a Type</option>
                                <option>With Hold Amount</option>
                                <option>Without Hold Amount</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-6 text-center'>
                        <button className='hold-unit__btn'
                            onClick={holdType === "Without Hold Amount" ? putUnitOnHoldWithoutHoldAmount : null}
                            disabled={invLead?.leadStatus != "InDiscussion"  || invLead?.isOnHold === true || invLead?.isHoldCancelled === true}
                            style={{ backgroundColor: (invLead?.leadStatus != "InDiscussion" || invLead?.isHoldCancelled === true || invLead?.isOnHold === true) ? 'grey' : '#EE4B46' }}
                        >Put Unit On Hold</button>
                    </div>
                </div>

            </Paper>

            {
                invLead?.isOnHold === true
                &&
                <Paper elevation={2} style={{ padding: '20px', marginTop: '20px' }}>

                    <h4 className='text-center mb-4'><b>Hold Unit Details</b></h4>

                    <div className='row d-flex flex-column justify-content-center align-items-center'>

                        <h6>Hold By :  <b>{invLead?.holdByUserFullName}</b></h6>
                        <h6>Hold till Date : <b>{dateFormatting(invLead?.holdTillDate)}</b></h6>
                        <h6>Hold Amount : <b>{invLead?.holdAmount}</b></h6>
                        <h6>Hold Site : <b>{invLead?.holdSiteName}</b></h6>
                        <h6>Hold Unit : <b>{invLead?.holdUnit}</b></h6>

                    </div>

                </Paper>
            }
            {
                invLead?.isHoldCancelled === true
                &&
                <Paper elevation={2} style={{ padding: '20px', marginTop: '20px' }}>

                    <h4 className='text-center mb-4'><b>Hold Unit Details</b></h4>

                    <div className='row d-flex flex-column justify-content-center align-items-center'>
                        <h3>Status : <b style={{color:'red'}}>Cancelled</b></h3>
                        <h6>Hold By :  <b>{invLead?.holdByUserFullName}</b></h6>
                        <h6>Hold till Date : <b>{dateFormatting(invLead?.holdTillDate)}</b></h6>
                        <h6>Hold Amount : <b>{invLead?.holdAmount}</b></h6>
                        <h6>Hold Site : <b>{invLead?.holdSiteName}</b></h6>
                        <h6>Hold Unit : <b>{invLead?.holdUnit}</b></h6>

                    </div>

                </Paper>
            }

            {
                invLead?.isOnHold === true
                &&
                <Paper elevation={2} style={{ padding: '20px', marginTop: '20px' }}>

                    <h4 className='text-center mb-4'><b>Cancel Unit On Hold</b></h4>

                    <div className='row justify-content-center'>
                        <div className='col-6'>

                            <Form.Group controlId="Unit">
                                <Form.Label>Cancellation Reason</Form.Label>
                                <Form.Control required
                                    value={cancellationReason}
                                    onChange={(e) => setCancellationReason(e.target.value)} as="select">
                                    <option value="">Select a Reason</option>
                                    <option value="WestroadCancellation">Westroad Cancellation</option>
                                    <option value="ClientCancellation">Client Cancellation</option>
                                    <option value="TimeLimitExpiry">Time Limit Expiry</option>
                                </Form.Control>
                            </Form.Group>

                        </div>
                    </div>

                    <div className='row justify-content-center mt-5'>
                        <div className='col-6 text-center'>

                            <button
                                className='hold-unit__btn'
                                disabled={invLead?.leadStatus != "InDiscussion"}
                                style={{ backgroundColor: invLead?.leadStatus != "InDiscussion" ? 'grey' : '#EE4B46' }}
                                onClick={releaseUnitHold}
                            >Release Unit Hold</button>

                        </div>
                    </div>

                </Paper>
            }

        </div>
    )
}

export default HoldUnit
