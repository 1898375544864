import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';

const PaymentDetails = ({ paySlip }) => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [employee, setEmployee] = useState({})
    const [costCenter, setCostCenter] = useState('')
    const [costCenterList, setCostCenterList] = useState([])
    const [transactionId, setTransactionId] = useState('')
    const [transferMethod, setTransferMethod] = useState('')
    const [paymentBankName, setPaymentBankName] = useState('')
    // const [paymentBankAccount, setPaymentBankAccount] = useState('')
    const [payerBankAccounts, setPayerBankAccounts] = useState([])
    const [payerBankAccount, setPayerBankAccount] = useState('')

    useEffect(() => {

        if (!costCenter) return;

        axios.get(`${BASE_URL}/api/v1/financemgmt/costCenter/getCostCenterByCostCenterCode/${costCenter.split(',')[0]}`,
            {
                headers: {
                    Authorization: `bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response)
                if (response.data.status) {
                    setPayerBankAccounts(response?.data?.Cost_Center?.bankAccounts)
                } else {
                    setPayerBankAccounts([])
                }
            })

    }, [costCenter])

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${paySlip?.employeeId}`, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        })
            .then((response) => {
                // console.log(response)
                setEmployee(response.data.employee)
            })


    }, []);

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/financemgmt/costcenter/getListOfCostCenter`, {
            headers: {
                Authorization: `bearer ${userInfo.token}`
            }
        })
            .then((response) => {
                // console.log(response)
                if (response.data.status === true) {
                    setCostCenterList(response.data.Cost_Centers)
                } else {
                    setCostCenterList([])
                }
            })
    }, [])

    const submitHandler = (e) => {

        e.preventDefault();

        axios.put(`${BASE_URL}/api/v1/financeMgmt/payment/disburseSalaryPayment`,
            {
                payslipNumber: paySlip?.payslipNumber,
                payerEmployeeId: userInfo.employeeId,
                transferMethod: transferMethod,
                transactionId: transactionId,
                payerBankName: payerBankAccount.split(',')[1],
                payerAccountNumber: payerBankAccount.split(',')[0],
                payerBranchName : payerBankAccount.split(',')[4],
                costCenterCode: costCenter.split(',')[0],
                costCenterName: costCenter.split(',')[1],
                payeeEmployeeId: paySlip?.employeeId,
                payeeBankName: employee?.salaryAccount?.bankName,
                payeeAccountNumber: employee?.salaryAccount?.accountNumber,
                finYear: paySlip?.finYear,
                amount: paySlip?.netSalary
            },
            {
                headers: {
                    Authorization: `bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                console.log(response)
                if (response.data.status === true) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                    })
                        .then(() => {
                            window.location.href = '/payRoll'
                        })

                }
            })
            .catch((err) => {
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error,
                })
            })

    }


    return (
        <div className='approvedPaySlip__payment-details'>

            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Account Number</label>
                    <input type='number' className='form-control' readOnly value={employee?.salaryAccount?.accountNumber} />
                </div>

                <div className='col-6'>
                    <label>Bank Name</label>
                    <input type='text' className='form-control' readOnly value={employee?.salaryAccount?.bankName} />
                </div>
            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Branch Name</label>
                    <input type='text' className='form-control' readOnly value={employee?.salaryAccount?.branchName} />
                </div>

                <div className='col-6'>
                    <label>Bank IFSC</label>
                    <input type='text' className='form-control' readOnly value={employee?.salaryAccount?.IFSCCode} />
                </div>
            </div>


            <br />

            <form
                onSubmit={(e) => submitHandler(e)}
            >

                <div className='row justify-content-center'>
                    <div className='col-6'>
                        <Form.Group controlId="cost center">
                            <Form.Label>Cost Center</Form.Label>
                            <Form.Control as="select" required
                                value={costCenter}
                                onChange={(e) => {
                                    setCostCenter(e.target.value)
                                }}
                            >
                                <option value="">Select a Center</option>
                                {
                                    costCenterList?.map((center, index) => {
                                        return <option key={index} value={`${center?.costCenterCode},${center?.costCenterName}`}>{center?.costCenterName}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                        <p>{costCenter.split(',')[0]}</p>

                    </div>

                    <div className='col-6'>
                        <label>Transaction Id</label>
                        <input type="text" required className='form-control' value={transactionId} onChange={(e) => (setTransactionId(e.target.value))} />

                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>

                    <div className='col-6'>
                        <Form.Group controlId="Site-Name">
                            <Form.Label>Payer Bank Account</Form.Label>
                            <Form.Control as="select" required
                                value={payerBankAccount}
                                onChange={(e) => {
                                    if (e.target.value === '') setPayerBankAccount('');
                                    else { setPayerBankAccount(e.target.value) }
                                }}
                            >
                                <option value="">Select a Bank</option>
                                {
                                    payerBankAccounts?.map((account, index) => {
                                        return <option key={index} value={`${account?.accountNumber},${account?.bankName},${account?.accountHolderName},${account?.IFSCCode},${account?.branchName}`}>{account?.accountNumber}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>

                    <div className='col-6'>
                        <label>Payer Bank Name</label>
                        <input type="text" className='form-control' required readOnly value={payerBankAccount ? payerBankAccount.split(',')[1] : ''} />
                    </div>

                </div>

                <br />

                <div className="row container-fluid justify-content-center">

                    <div className='col-6'>
                        <label>Payer Account Holder Name</label>
                        <input type="text" className='form-control' required readOnly value={payerBankAccount ? payerBankAccount.split(',')[2] : ''} />
                    </div>

                    <div className='col-6'>
                        <label>Payer IFSC Code</label>
                        <input type="text" className='form-control' required readOnly value={payerBankAccount ? payerBankAccount.split(',')[3] : ''} />
                    </div>
                </div>

                <br />

                <div className="row container-fluid justify-content-center">

                    <div className='col-6'>
                        <label>Payer Branch Name</label>
                        <input type="text" className='form-control' required readOnly value={payerBankAccount ? payerBankAccount.split(',')[4] : ''} />
                    </div>

                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-6'>
                        <Form.Group controlId="Site-Name">
                            <Form.Label>Transfer Method </Form.Label>
                            <Form.Control as="select" required
                                value={transferMethod}
                                onChange={(e) => { setTransferMethod(e.target.value) }}
                            >
                                <option value="">Select a Method</option>
                                <option value="NEFT">NEFT</option>
                                <option value="RTGS">RTGS</option>
                                <option value="IMPS">IMPS</option>

                            </Form.Control>
                        </Form.Group>
                    </div>

                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-6 text-center'>
                        <button className='approvedPaySlip__btn'
                            disabled={userInfo?.userRole != 'Finance'}
                            style={{ backgroundColor: userInfo?.userRole != 'Finance' ? 'grey' : '' }}
                        >Submit</button>
                    </div>
                </div>


            </form>


        </div>
    )
}

export default PaymentDetails