import React from 'react'
import Paper from "@mui/material/Paper";

const Education = ({ educationalDetails }) => {
  if (educationalDetails.length === 0) {
    return (
      <div style={{marginTop:'2rem',textAlign:'center'}}>
        <h3>No Details Added</h3>
      </div>
    )
  }
  return (
    <div>
      {
        educationalDetails.map(({
          qualification,
          schoolInstitute,
          universityBoard,
          score,
          scoreType,
          yearOfPassing,
          city,
          State,
          country,
          degreeScan,
          marksheetScan,
        }, index) => {
          return (
            <Paper elevation={4} style={{ padding: '1rem', marginTop: '2rem' }} key={index} >
              <div className="row justify-content-start">
                <div className="col-3">
                  <label>Qualification</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled
                    value={qualification}
                  />
                </div>
                <div className="col-3">
                  <label>School/Institute</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled
                    value={schoolInstitute}
                  />
                </div>
                <div className="col-3">
                  <label>University/Board</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled
                    value={universityBoard}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-start">
                <div className="col-3">
                  <label>Score</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled
                    value={score}
                  />
                </div>
                <div className="col-3">
                  <label>Score Type</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled
                    value={scoreType}
                  />
                </div>
                <div className="col-3">
                  <label>Year of Passing</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled
                    value={yearOfPassing}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-start">
                <div className="col-3">
                  <label>City</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled
                    value={city}
                  />
                </div>
                <div className="col-3">
                  <label>State</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled
                    value={State}
                  />
                </div>
                <div className="col-3">
                  <label>Country</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled
                    value={country}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-start">
                <div className="col-3">
                  <label>Degree Scan</label>
                  <a href={degreeScan} className='ml-3' target="_blank" rel="noreferrer">
                    <button>Download</button>
                  </a>
                </div>
                <div className="col-3">
                  <label>Marksheet Scan</label>
                  <a href={marksheetScan} className='ml-3' target="_blank" rel="noreferrer">
                    <button>Download</button>
                  </a>
                </div>
              </div>
            </Paper>
          )
        })
      }
    </div>
  )
}

export default Education