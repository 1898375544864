import React from "react";
import constructionImg1 from "../../../assets/tableView/construction-1.png";
import constructionImg2 from "../../../assets/tableView/construction-2.png";
import constructionImg3 from "../../../assets/tableView/construction-3.png";
import "./Construction.scss";
export default function Construction() {
  const data = [
    {
      heading: "FOUNDATION",
      text: "RCC Pile foundation, Captain 600 TMT Bar & Ultratech Cement",
    },
    {
      heading: "SUPERSTRUCTURE",
      text: "Earthquake resistant reinforced concrete piling structure, Designed by prominent Structural Engineers, AK Dutta & Associates",
    },
    {
      heading: "WALLS",
      text: "Konark AAC Blocks (Autoclaved aerated concrete)",
    },
    {
      heading: "WALLS FINISHING",
      text: "Exterior: Weatherproof, non-fading exterior paint finish/textured. Asian Paints / Berger Paints",
    },
    {
      heading: "INTERNAL COMMON AREAS",
      text: "POP/JK Wall Putty over plaster",
    },
  ];
  return (
    <>
      <div className="construction__images">
        <img src={constructionImg1} alt="" />
        <div className="construction__images__subcontainer">
          <img src={constructionImg2} alt="" />

          <img src={constructionImg3} alt="" />
        </div>
      </div>
      <div className="construction__infoContainer">
        {data.map(({ heading, text }) => (
          <div className="construction__infoContainer__info">
            <b>{heading}</b>
            <p>{text}</p>
          </div>
        ))}
      </div>
    </>
  );
}
