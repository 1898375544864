import React from "react";
import Paper from "@mui/material/Paper";
import { formatDate } from "../../../utils/helper";
const WorkHistory = ({ previousEmployerDetails }) => {
  if (previousEmployerDetails.length === 0) {
    return (
      <div style={{ marginTop: "2rem", textAlign: "center" }}>
        <h3>No Details Added</h3>
      </div>
    );
  }
  return (
    <div>
      <div>
        {previousEmployerDetails.map(
          (
            {
              employerName,
              designation,
              department,
              dateOfJoining,
              dateOfLeaving,
              lastCTC,
              experienceLetterScan,
              payslipsScan,
              additionalDocumentScan,
            },
            index
          ) => {
            return (
              <Paper
                elevation={4}
                style={{ padding: "1rem", marginTop: "2rem" }}
                key={index}
              >
                <div className="row justify-content-start">
                  <div className="col-3">
                    <label>Employer Name</label>
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={employerName}
                    />
                  </div>
                  <div className="col-3">
                    <label>Designation</label>
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={designation}
                    />
                  </div>
                  <div className="col-3">
                    <label>Department</label>
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={department}
                    />
                  </div>
                  <div className="col-3">
                    <label>Date of Joining</label>
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={formatDate(dateOfJoining)}
                    />
                  </div>
                </div>
                <br />
                <div className="row justify-content-start">
                  <div className="col-3">
                    <label>Date of Leaving</label>
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={formatDate(dateOfLeaving)}
                    />
                  </div>
                  <div className="col-3">
                    <label>Last CTC</label>
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={lastCTC}
                    />
                  </div>
                </div>
                <br />
                <div className="row justify-content-start">
                  <div className="col-3">
                    <label>Experience Letter</label>
                    <a
                      href={experienceLetterScan}
                      className="ml-3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button type="button">Download</button>
                    </a>
                  </div>
                  <div className="col-3">
                    <label>Payslips</label>
                    <a
                      href={payslipsScan}
                      className="ml-3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button type="button">Download</button>
                    </a>
                  </div>
                  <div className="col-3">
                    <label>Additional Document</label>
                    <a
                      href={additionalDocumentScan}
                      className="ml-3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button
                        type="button"
                        disabled={
                          additionalDocumentScan === "Not Added" ? true : false
                        }
                        style={{
                          backgroundColor:
                            additionalDocumentScan === "Not Added"
                              ? "grey"
                              : "",
                        }}
                      >
                        Download
                      </button>
                    </a>
                  </div>
                </div>
              </Paper>
            );
          }
        )}
      </div>
    </div>
  );
};

export default WorkHistory;
