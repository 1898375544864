import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import { BASE_URL } from '../../utils/config/url'
import { useParams } from 'react-router-dom'
import Button from '../../components/Button/Button'
import './ViewInvCatalogueItem.scss'
import { Form } from 'react-bootstrap'

const ViewInvCatalogueItem = () => {

    const [edit, setEdit] = useState(false)
    const [catalogueItem, setCatalogueItem] = useState({})

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const catalogueItemCode = useParams().catalogueItemCode


    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/stockmgmtapi/catalougeItem/getCatalogueItemByItemCode/${catalogueItemCode}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status === true) {

                    setCatalogueItem(response.data.CatalogueItems)
                } else {
                    setCatalogueItem({})
                }
            })

    }, [])

    const dateFormatting = (input) => {

        if (!input) return

        let date = new Date(input)
        let d = date.getDate()
        if (d < 10) d = `0${d}`
        let m = date.getMonth() + 1
        if (m < 10) m = `0${m}`
        let y = date.getFullYear()

        return `${y}-${m}-${d}`
    }

    const updateCatalogueItem = (e) => {
        e.preventDefault();

        // console.log(category)

        axios.put(`${BASE_URL}/api/v1/stockmgmtapi/catalougeItem/updateCatalogueItemByCode`,
            {
                catalogueItemCode: catalogueItemCode,
                update: catalogueItem
            },
            {
                headers: {
                    Authorization: `bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                        timer: 2000
                    })
                        .then(() => {
                            setEdit(false)
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.message,
                        timer: 2000
                    })
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: err.response.data.error,
                    timer: 2000
                })
            })
    }


    return (
        <div className='inv-catalogue'>

            <Button navigateTo={1} />

            <br />

            <h1 className='text-center mb-5'
                style={{ color: '#EE4B46' }}
            >View Catalogue Item</h1>


            <form
                onSubmit={(e) => updateCatalogueItem(e)}
            >

                <div className='row d-flex justify-content-center'>
                    <div className='col-6'>
                        <label>Category Code</label>
                        <input type="text"
                            className='form-control'
                            value={catalogueItem.categoryCode} readOnly />
                    </div>
                    <div className='col-6'>
                        <label>Category Name</label>
                        <input type="text"
                            className='form-control'
                            value={catalogueItem.categoryName} readOnly
                        />
                    </div>
                </div>

                <br />

                <div className='row d-flex justify-content-center'>
                    <div className='col-6'>
                        <label>Sub Category Code</label>
                        <input type="text"
                            className='form-control'
                            value={catalogueItem.subCategoryCode} readOnly />
                    </div>
                    <div className='col-6'>
                        <label>Sub Category Name</label>
                        <input type="text"
                            className='form-control'
                            value={catalogueItem.subCategoryName} readOnly
                        />
                    </div>
                </div>

                <br />

                <div className='row d-flex justify-content-center'>
                    <div className='col-6'>
                        <label>Brand Code</label>
                        <input type="text"
                            className='form-control'
                            value={catalogueItem.brandCode} readOnly />
                    </div>
                    <div className='col-6'>
                        <label>Brand Name</label>
                        <input type="text"
                            className='form-control'
                            value={catalogueItem.brandName} readOnly
                        />
                    </div>
                </div>

                <br />

                <div className='row d-flex justify-content-center'>
                    <div className='col-6'>
                        <label>Catalogue Item Name</label>
                        <input type="text"
                            className='form-control'
                            value={catalogueItem.catalogueItemName} readOnly={!edit}
                            onChange={(e) => setCatalogueItem({ ...catalogueItem, catalogueItemName: e.target.value })}
                        />
                    </div>
                    <div className='col-6'>
                        <label>Catalogue Item Code</label>
                        <input type="text"
                            className='form-control'
                            value={catalogueItem.catalogueItemCode} readOnly />
                    </div>
                </div>

                <br />

                <div className='row d-flex justify-content-center'>
                    <div className='col-9'>
                        <label>Catalogue Item Description</label>
                        <textarea
                            rows={4}
                            type="text"
                            className='form-control'
                            value={catalogueItem.catalogueItemDescription} readOnly={!edit}
                            onChange={(e) => {
                                setCatalogueItem({ ...catalogueItem, catalogueItemDescription: e.target.value })
                            }}
                        />
                    </div>
                </div>

                <br />

                <div className='row d-flex justify-content-center'>
                    <div className='col-6'>
                        <label>Catalogue Item Number</label>
                        <input type="text"
                            className='form-control'
                            value={catalogueItem.catalogueItemNumber} readOnly />
                    </div>
                    <div className='col-6'>
                        {
                            edit === false
                                ?
                                <>
                                    <label>Orderable</label>
                                    <input type="text"
                                        className='form-control'
                                        value={catalogueItem.isOrderable ? 'Yes' : 'No'} readOnly />
                                </>
                                :
                                <>
                                    <Form.Group controlId="year">
                                        <Form.Label>Orderable</Form.Label>
                                        <Form.Control as="select" required
                                            value={catalogueItem.isOrderable}
                                            onChange={(e) => {
                                                setCatalogueItem({ ...catalogueItem, isOrderable: e.target.value })
                                            }}
                                        >
                                            <option value="">Select a option</option>
                                            <option value={true}>Yes</option>
                                            <option value={false}>No</option>
                                        </Form.Control>
                                    </Form.Group>
                                </>
                        }

                    </div>
                </div>

                <br />

                <div className='row d-flex justify-content-center'>
                    <div className='col-6'>
                        {
                            edit === false
                                ?
                                <>
                                    <label>Upgrade Item</label>
                                    <input type="text"
                                        className='form-control'
                                        value={catalogueItem.isUpgradeItem ? 'Yes' : 'No'} readOnly />
                                </>
                                :
                                <>
                                    <Form.Group controlId="year">
                                        <Form.Label>Upgrade Item</Form.Label>
                                        <Form.Control as="select" required
                                            value={catalogueItem.isUpgradeItem}
                                            onChange={(e) => {
                                                setCatalogueItem({ ...catalogueItem, isUpgradeItem: e.target.value })
                                            }}
                                        >
                                            <option value="">Select a option</option>
                                            <option value={true}>Yes</option>
                                            <option value={false}>No</option>
                                        </Form.Control>
                                    </Form.Group>
                                </>
                        }

                    </div>

                    <div className='col-6'>
                        <label>Measurement Unit</label>
                        <input type="text"
                            className='form-control'
                            value={catalogueItem?.measurementUnit || ''} readOnly />
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>

                    <div className='col-6'>
                        <label>Added By Username</label>
                        <input type="text"
                            className='form-control'
                            value={catalogueItem.addedBy} readOnly
                        />
                    </div>

                    <div className='col-6'>
                        <label>Added By Full Name</label>
                        <input type="text"
                            className='form-control'
                            value={catalogueItem.addedByFullName} readOnly
                        />
                    </div>
                </div>

                <br />
                <br />


                {
                    edit === false
                        ?
                        <div className='row justify-content-center'>
                            <div className='col-6 text-center'>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setEdit(true)
                                    }}
                                >Edit</button>
                            </div>
                        </div>
                        :
                        <div className='row justify-content-center'>
                            <div className='col-6 text-center'>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setEdit(false)
                                        // setChange(!change)
                                    }}
                                    style={{
                                        backgroundColor: 'white',
                                        color: '#EE4B46',
                                        border: '2px solid #EE4B46'
                                    }}
                                >Cancel</button>
                            </div>
                            <div className='col-6 text-center'>
                                <button>Save</button>
                            </div>
                        </div>
                }


            </form>

        </div>
    )
}

export default ViewInvCatalogueItem
