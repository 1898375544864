import React, { useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
// import './AddWFH.scss';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';

const AddScanIn = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate();

    const [wfhBalance, setWfhBalance] = useState('');
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [totalTime, setTotalTime] = useState('')
    const [employeeList, setEmployeeList] = useState([])
    const [employeeId, setEmployeeId] = useState('')

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                if (response.data.status == true) {
                    // console.log(response)
                    setEmployeeList(response.data.employees)
                } else {
                    setEmployeeList([])
                }
            })

    }, [])

    useEffect(() => {

        if (startTime && endTime) {

            let start = startTime.split('T')
            let end = endTime.split('T')

            start = start[1].split(':')
            end = end[1].split(':')

            let hrs = end[0] - start[0]
            let mins = end[1] - start[1]

            if (mins < 0) {
                hrs--;
                mins = 60 - Math.abs(mins);
            }

            setTotalTime(`${hrs} hrs and ${mins} min`)
        }

    }, [startTime, endTime])

    const submitForm = (e) => {
        e.preventDefault();

        axios.post(`${BASE_URL}/api/v1/hrmgmt/attendance/addAttendanceRecord`, {
            employeeId: employeeId,
            startTime,
            attendanceType: 'InOffice',
        }, {
            headers: { Authorization: `bearerr ${userInfo.token}` }
        })
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: 'Success',
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: response.data.message
                }).then(function () {
                    updateAttendanceRecord(response.data.attendance.attendanceId)

                })
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }

    const updateAttendanceRecord = (attendanceId) => {

        axios.patch(`${BASE_URL}/api/v1/hrmgmt/attendance/updateAttendanceRecord`, {
            employeeId: employeeId,
            endTime,
            attendanceId: attendanceId,
        }, {
            headers: { Authorization: `bearerr ${userInfo.token}` }
        })
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: 'Success',
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: response.data.message
                }).then(function () {
                    window.location.reload()
                    // navigate('/attendanceManagement/listOfAttendance')
                })
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })

    }

    const dateFormatting = (input, type) => {
        let d = new Date(input)
        let date = d.getDate()
        if (date < 10) {
            date = `0${date}`
        }
        let m = d.getMonth() + 1
        if (m < 10) {
            m = `0${m}`
        }
        let y = d.getFullYear()

        let final;

        if (type === 'original') {
            final = `${y}-${m}-${date}`
            return final
        } else {
            final = `${date}/${m}/${y}`
            return final
        }

    }

    return (
        <div className='add-wfh'>
            <Button navigateTo={'/attendanceManagement'} />
            <br />

            {/* <div className='row justify-content-center'>
                <div className='add-wfh__balance'>
                    <p style={{ margin: 0, fontSize: window.innerWidth < 700 ? '17px' : '22px', fontWeight: '700', textAlign: 'center' }}>Work from Home Balance : {wfhBalance}</p>
                </div>
            </div> */}



            <form onSubmit={(e) => submitForm(e)}>

                <h2 className='text-center'
                    style={{ color: '#EE4B46' }}
                >Add Scan In Attendance</h2>

                <br /> <br />

                <div className='row justify-content-center'>
                    <div className='col-4'>
                        <Form.Group controlId="employee">
                            <Form.Label>Employee</Form.Label>
                            <Form.Control as="select" required
                                value={employeeId}
                                onChange={(e) => {
                                    setEmployeeId(e.target.value)
                                }}
                            >
                                <option value="">Select a Employee</option>
                                {
                                    employeeList?.map((employee) => {
                                        return <option value={employee?.employeeId}>{employee?.employeeFullName}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className={window.innerWidth < 700 ? 'col-6' : 'col-4'}>
                        <label>Start Time</label>
                        <input id='calendar1'
                            className='form-control'
                            type='datetime-local'
                            required
                            value={startTime}
                            onChange={(e) => {
                                setStartTime(e.target.value)
                            }}
                        />
                    </div>

                    <div className={window.innerWidth < 700 ? 'col-6' : 'col-4'}>
                        <label>End Time</label>
                        <input id='calendar2'
                            className='form-control'
                            type='datetime-local'
                            required
                            value={endTime}
                            onChange={(e) => {
                                setEndTime(e.target.value)
                            }}
                        />
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className={window.innerWidth < 700 ? 'col-8' : 'col-4'}>
                        <label>Total Time</label>
                        <input type="text" className='form-control'
                            // placeholder='4hrs 30minutes'
                            readOnly
                            value={totalTime}
                        // onChange={(e) => setTotalTime(e.target.value)}
                        />
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>
                    <button className='add-wfh__btn' type='submit'
                        disabled={new Date(endTime).getTime() < new Date(startTime).getTime()}
                        style={{ backgroundColor: new Date(endTime).getTime() < new Date(startTime).getTime() ? 'grey' : '' }}
                    >Submit</button>
                </div>

            </form>
        </div>
    )
}

export default AddScanIn
