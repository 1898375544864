import React from 'react'
import {Navigate} from 'react-router-dom'
import BankTable from './BankTable';

const Banks = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if( userInfo.token === undefined ) {
        return <Navigate to="/login" noThrow />
    }
      else {
      return <BankTable/>;
      }
}

export default Banks