import React, { useRef, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { BsCameraFill } from "react-icons/bs";
import axios from "axios";
import Swal from "sweetalert2";
import getCroppedImg from "../../utils/CropImage/CropImage";
import Default_img from "../../assets/images/default_user.png";
import { BASE_URL } from "../../utils/config/url";

const InvUserDetails = ({ user }) => {
  const [img, setImg] = useState(null);
  const [preview, setPreview] = useState("");
  const [dob, setDob] = useState(user?.userDOB ? new Date(user?.userDOB).toISOString().slice(0, 10) : new Date().toISOString().slice(0, 10));
  // const [address, setAddress] = useState("");
  // const [city, setCity] = useState("");
  // const [state, setState] = useState("");
  // const [landMark, setLandMark] = useState("");
  // const [pinCode, setPinCode] = useState("");
  // const [aadhar, setAadhar] = useState("");
  // const [pan, setPan] = useState("");
  const [update, setUpdate] = useState(false);
  const [action, setAction] = useState(false);
  const [change, setChange] = useState(false);
  const [userFullName, setUserFullName] = useState('')
  const [officialMobile, setOfficialMobile] = useState('')
  const [officialEmail, setOfficialEmail] = useState('')

  const gallaryRef = useRef(null);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  
  useEffect(() => {
    // setAddress(user?.userAddress);
    // setCity(user?.userCity);
    // setState(user?.userState);
    // setLandMark(user?.userLandMark);
    // setPinCode(user?.userPinCode);
    // setPan(user?.userPAN);
    // setAadhar(user?.userAadhar);
    setUserFullName(user?.userFullName)
    setOfficialMobile(user?.userOfficialMobile)
    setOfficialEmail(user?.userOfficialEmail)

  }, [user]);

  // convert data url to file
  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) u8arr[n] = bstr.charCodeAt(n);
    return new File([u8arr], filename, { type: mime });
  };
  // image preview
  useEffect(() => {
    if (img) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(img);
    } else {
      setPreview(null);
    }
  }, [img]);

  const handleImgUpload = async () => {
    if (img) {
      const canvas = await getCroppedImg(img, { width: 450, height: 450, x: 0, y: 0 });
      const canvasDataURL = canvas.toDataURL("image/jpeg");
      const convertedURLtoFile = dataURLtoFile(canvasDataURL, "cropped-image.jpeg");
      const data = new FormData();
      data.append("file", convertedURLtoFile);
      data.append("type", "rrPhoto");
      data.append("userName", userInfo.userName);
      await axios.post(`${BASE_URL}/api/v1/util/uploadphoto`, data, { headers: { Authorization: `bearer ${userInfo.token}` } }).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Image Uploaded Successfully!",
          showConfirmButton: false,
          timer: 2000,
        });
        setAction(false);
        setChange(false);
        setUpdate(!update);
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Please Select an Image",
      });
    }
  };

  const handleClear = () => {
    if (!img) {
      Swal.fire({
        icon: "warning",
        title: "Please Select an Image",
      });
    } else {
      setImg(null);
    }
  };

  const handleRemove = () => {
    setImg(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (String(pinCode).length !== 6 || String(aadhar).length !== 12 || String(pan).length !== 10) {
    //   Swal.fire({
    //     icon: "warning",
    //     title: "Please Input Valid Information!",
    //   });
    //   return;
    // } else {
      axios
        .put(
          `${BASE_URL}/api/v1/user/updateUserByUserName`,
          {
            userName: user.userName,
            userFullName : userFullName,
            userOfficialEmail : officialEmail,
            userOfficialMobile : officialMobile
          },
          { headers: { Authorization: `bearer ${userInfo.token}` } }
        )
        .then((res) => {
          if (res.data.status) {
            Swal.fire({
              icon: "success",
              title: "All Details Saved Succesfully!",
              showConfirmButton: false,
              timer: 2000,
            });
            setUpdate(!update);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops there is an error. Kindly contact the developer team!",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops there is an error. Kindly contact the developer team!",
          });
        });
    // }
  };


  return (

    <div style={{
      margin: 'auto',
      marginTop: '50px',
      width: '50%'
    }} className="user_details">
      <div
        onClick={() => {
          setAction(!action);
          setChange(false);
        }}
        className="user_img"
      >
        <img className="img-fluid" src={preview || user?.userPhoto || Default_img} alt="user avatar" />
        <div className="camera_icon">
          <BsCameraFill />
        </div>
      </div>
      {/* image actions  */}
      {action && (
        <div className="d-flex align-items-center justify-content-center mb-3">
          <Button onClick={() => setChange(!change)} variant="contained">
            Change
          </Button>
          <Button onClick={handleRemove} className="rmv_btn" style={{ backgroundColor: '#EE4B46' }} variant="contained">
            Remove
          </Button>
        </div>
      )}
      {/* image actions  */}
      {change && (
        <div className="d-flex align-items-center justify-content-center mb-3">
          <Button onClick={handleClear} variant="contained">
            CLEAR
          </Button>
          <Button onClick={() => gallaryRef.current.click()} className="ml-2" variant="contained">
            CHOOSE
          </Button>
          <Button onClick={handleImgUpload} className="ml-2" variant="contained">
            UPLOAD
          </Button>
          {/* input for image upload  */}
          <Form.Control className="d-none" ref={gallaryRef} type="file" accept="image/*" onChange={(e) => setImg(e.target.files[0])} />
        </div>
      )}
      {/* user info  */}
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-column justify-content-center">
          <div style={{ width: "130px", fontSize: "25px", color: `#EE4B46` }} className=" text-center m-auto">
            Profile
          </div>

          <div className="row d-flex flex-column justify-content-center m-auto">

            <div className="user_info mb-2">
              <label>User Name</label>
              <Form.Control type="text" value={user.userName} disabled />
            </div>
            {
              user?.employeeId
              &&
              <div className="user_info mb-2">
                <label>Employee ID</label>
                <Form.Control type="text" value={user.employeeId} disabled />
              </div>
            }
            <div className="user_info mb-2">
              <label>User Full Name</label>
              <Form.Control type="text" value={userFullName} onChange={(e)=>setUserFullName(e.target.value)}/>
            </div>
            <div className="user_info mb-2">
              <label>Official Mobile No.</label>
              <Form.Control type="number" value={officialMobile}  onChange={(e)=>setOfficialMobile(e.target.value)}/>
            </div>
            <div className="user_info mb-2">
              <label>Official Email</label>
              <Form.Control type="email" value={officialEmail}  onChange={(e)=>setOfficialEmail(e.target.value)}/>
            </div>


            {/* <div className="user_info">
              <label>DOB</label>
              <Form.Control onChange={(e) => setDob(e.target.value)} type="date" name="userDOB" defaultValue={dob} />
            </div>
            <div className="user_info">
              <label>Address</label>
              <Form.Control onChange={(e) => setAddress(e.target.value)} type="text" name="userAddress" defaultValue={address} />
            </div>
            <div className="user_info">
              <label>Landmark</label>
              <Form.Control onChange={(e) => setLandMark(e.target.value)} type="text" name="userLandMark" defaultValue={landMark} />
            </div>
            <div className="user_info">
              <label>City</label>
              <input onChange={(e) => setCity(e.target.value)} type="text" name="userCity" defaultValue={city} />
            </div>
            <div className="user_info">
              <label>Pincode</label>
              <Form.Control onChange={(e) => setPinCode(e.target.value)} type="number" defaultValue={pinCode} />

              {String(pinCode).length !== 6 && <small className="mt-2 text-danger">Pincode must be of 6 digits</small>}
            </div>
            <div className="user_info">
              <label>State</label>
              <Form.Control onChange={(e) => setState(e.target.value)} type="text" name="userState" defaultValue={state} />
            </div>
            <div className="user_info">
              <label>Aadhar</label>
              <Form.Control onChange={(e) => setAadhar(e.target.value)} type="number" defaultValue={aadhar} />
              {String(aadhar).length !== 12 && <small className="mt-2 text-danger">Adhar must be of 12 digits</small>}
            </div>
            <div className="user_info">
              <label>PAN</label>
              <Form.Control onChange={(e) => setPan(e.target.value)} type="text" defaultValue={pan} />
              {String(pan).length !== 10 && <small className="mt-2 text-danger">PAN must be of 10 digits</small>}
            </div> */}
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button className="save_btn" type="submit">
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default InvUserDetails;
