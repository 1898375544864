import React, { useEffect, useState } from 'react'
import './EditUnitPricing.scss';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Form } from 'react-bootstrap';

const EditUnitPricing = () => {


    const unitName = useParams().unitname;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const Token = 'bearer' + " " + userInfo.token;
    const navigate = useNavigate()

    const [unit, setUnit] = useState({})
    const [basePrice, setBasePrice] = useState({})
    const [plc, setPlc] = useState({})
    const [fec, setFec] = useState({})
    const [ptc, setPtc] = useState({})

    useEffect(() => {
        getUnitPricingData()
    }, [])


    const getUnitPricingData = () => {
        const Token = 'bearer' + " " + userInfo.token;
        axios
            .get(`${BASE_URL}/api/v1/Configuration/Unit/getunitbyunitname/${unitName}`,
                { headers: { Authorization: Token } })
            .then((response) => {

                const unit = response.data.unit;

                setUnit((prev) => {
                    return {
                        ...prev,
                        grossAmount: unit.unitGrossPrice,
                        chargeableRate: unit.chargeableSqFeetRate,
                        gstRate: unit.gstPercentage,
                        totalGst: unit.unitGstAmount,
                        totalUnitPrice: unit.unitPrice
                    }
                })

                setBasePrice((prev) => {
                    return {
                        ...prev,
                        rate: unit.baseSqFeetRate,
                        chargeableArea: unit.superBuiltUpArea,
                        grossAmount: unit.baseUnitGrossPrice,
                        GST: unit.baseUnitPriceGST,
                        total: unit.baseUnitPrice
                    }
                })

                setPlc((prev) => {
                    return {
                        ...prev,
                        rate: unit.preferredLocationCharge,
                        chargeableArea: unit.superBuiltUpArea,
                        type: unit.preferredLocationChargeType,
                        grossAmount: unit.preferredLocationChargeAmount,
                        GST: unit.preferredLocationChargeGST,
                        total: unit.preferredLocationChargeTotal
                    }
                })

                setFec((prev) => {
                    return {
                        ...prev,
                        rate: unit.floorEscalationCharge,
                        chargeableArea: unit.superBuiltUpArea,
                        noOfFloors: unit.noOfEscalationFloor,
                        grossAmount: unit.floorEscalationChargeAmount,
                        GST: unit.floorEscalationChargeGST,
                        total: unit.floorEscalationChargeTotal
                    }
                })

                setPtc((prev) => {
                    return {
                        ...prev,
                        rate: unit.privateTerraceChargeRate,
                        grossAmount: unit.privateTerraceChargeAmount,
                        GST: unit.privateTerraceChargeGST,
                        total: unit.privateTerraceChargeTotal,
                        privateTerraceArea: unit.privateTerraceArea
                    }
                })
            })
    }

    const updateBasePrice = () => {

        axios
            .post(`${BASE_URL}/api/v1/Configuration/Unit/updateBasePrice`,
                {
                    unitName: unitName,
                    baseSqFeetRate: basePrice.rate
                },
                { headers: { Authorization: Token } }
            ).then((response) => {
                // console.log(response)
                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Base Price successfully updated",
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        getUnitPricingData()
                    })
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                        text: response.data.error
                    })
                }

            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }


    const updatePlc = () => {

        axios
            .post(`${BASE_URL}/api/v1/Configuration/Unit/updatePLC`,
                {
                    unitName: unitName,
                    preferredLocationCharge: plc.rate,
                    preferredLocationChargeType: plc.type
                },
                { headers: { Authorization: Token } }
            ).then((response) => {

                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Preferred Location Charge successfully updated",
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        getUnitPricingData()
                    })
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                        text: response.data.error
                    })
                }

            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.message
                })
            })
    }


    const updatePrivateTerrace = () => {

        axios
            .post(`${BASE_URL}/api/v1/Configuration/Unit/updatePrivateTerrace`,
                {
                    unitName: unitName,
                    privateTerraceArea: ptc.privateTerraceArea
                },
                { headers: { Authorization: Token } }
            ).then((response) => {

                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Private Terrace Charge successfully updated",
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        getUnitPricingData()
                    })
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                        text: response.data.error
                    })
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.message
                })
            })
    }

    const handleSave = () => {
        Swal.fire({
            icon: "success",
            title: "Success",
            text: "Unit Pricing Updated",
            showClass: {
                popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
                popup: "animate__animated animate__fadeOutUp",
            }
        }).then(function () {

        })
    }


    return (
        <div className='unitpricing'>

            <Paper elevation={2} style={{ padding: '20px' }}>
                <div className="row justify-content-start">
                    <div className='col-4'>
                        <label>Gross Amount <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                        <input className='form-control'
                            value={unit.grossAmount} readOnly />
                    </div>
                    <div className='col-5'>
                        <label>Chargeable Rate <em style={{ color: '#EE4B46' }}>(per sq. feet)</em></label>
                        <input className='form-control'
                            value={unit.chargeableRate} readOnly />
                    </div>
                </div>

                <br />

                <div className="row justify-content-center">
                    <div className='col-4'>
                        <label>GST Rate <em style={{ color: '#EE4B46' }}>(percentage)</em></label>
                        <input className='form-control'
                            value={unit.gstRate} readOnly />
                    </div>
                    <div className='col-4'>
                        <label>Total GST <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                        <input className='form-control'
                            value={unit.totalGst} readOnly />
                    </div>
                    <div className='col-4'>
                        <label>Total Unit Price <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                        <input className='form-control'
                            value={unit.totalUnitPrice} readOnly />
                    </div>
                </div>
            </Paper>

            <br />
            <br />

            {/* /////////////////////////// Base Price  //////////////////////*/}

            <Paper elevation={2} style={{ padding: '20px' }}>
                <h4>Base Price</h4>
                <br />
                <div className="row justify-content-start">
                    <div className='col-4'>
                        <label>Rate <em style={{ color: '#EE4B46' }}>(per sq. feet)</em></label>
                        <input className='form-control'
                            value={basePrice.rate}
                            onChange={(e) => setBasePrice((prev) => {
                                return {
                                    ...prev,
                                    rate: e.target.value
                                }
                            })}
                        />
                    </div>
                    <div className='col-4'>
                        <label>Chargeable Area <em style={{ color: '#EE4B46' }}>(sq. feet)</em></label>
                        <input className='form-control'
                            value={basePrice.chargeableArea} readOnly />
                    </div>
                </div>

                <br />

                <div className="row justify-content-center">
                    <div className='col-4'>
                        <label>Gross Amount <em style={{ color: '#EE4B46' }}>(INR)</em>)</label>
                        <input className='form-control'
                            value={basePrice.grossAmount} readOnly />
                    </div>
                    <div className='col-4'>
                        <label>GST <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                        <input className='form-control'
                            value={basePrice.GST} readOnly />
                    </div>
                    <div className='col-4'>
                        <label>Total <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                        <input className='form-control'
                            value={basePrice.total} readOnly />
                    </div>
                </div>


                <div className='unitpricing__btn'>
                    <button onClick={updateBasePrice}>Update Base Price</button>
                </div>
            </Paper>

            <br />
            <br />

            {/* /////////////////////////// Preferred Location Charge /////////////////*/}

            <Paper elevation={2} style={{ padding: '20px' }}>
                <h4>Preferred Location Charge</h4>
                <br />
                <div className="row justify-content-center">
                    <div className='col-4'>
                        <label>Rate <em style={{ color: '#EE4B46' }}>(per sq. feet)</em></label>
                        <input className='form-control'
                            value={plc.rate}
                            onChange={(e) => {
                                setPlc((prev) => {
                                    return {
                                        ...prev,
                                        rate: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>
                    <div className='col-4'>
                        <label>Chargeable Area <em style={{ color: '#EE4B46' }}>(sq. feet)</em></label>
                        <input className='form-control'
                            value={plc.chargeableArea} readOnly />
                    </div>

                    <div className='col-4'>


                        <Form.Group controlId="bhk">
                            <Form.Label>Type</Form.Label>
                            <Form.Control required as="select" value={plc.type}
                                onChange={(e) => {
                                    setPlc((prev) => {
                                        return {
                                            ...prev,
                                            type: e.target.value
                                        }
                                    })
                                }}
                               >
                                <option value="South Facing">South Facing </option>
                                <option value="Premium Apartment">Premium Apartment </option>
                                <option value="Garden View">Garden View </option>
                            </Form.Control>
                        </Form.Group>


                    </div>
                </div>

                <br />

                <div className="row justify-content-center">
                    <div className='col-4'>
                        <label>Gross Amount <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                        <input className='form-control'
                            value={plc.grossAmount} readOnly />
                    </div>
                    <div className='col-4'>
                        <label>GST <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                        <input className='form-control'
                            value={plc.GST} readOnly />
                    </div>
                    <div className='col-4'>
                        <label>Total <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                        <input className='form-control'
                            value={plc.total} readOnly />
                    </div>
                </div>


                <div className='unitpricing__btn'>
                    <button onClick={updatePlc}>Update Preferred Location Charge</button>
                </div>
            </Paper>

            <br />
            <br />
            {/* /////////////////////////// Floor Esacalation Charge /////////////////*/}

            <Paper elevation={2} style={{ padding: '20px' }}>
                <h4>Floor Esacalation Charge</h4>
                <br />
                <div className="row justify-content-center">
                    <div className='col-4'>
                        <label>Rate <em style={{ color: '#EE4B46' }}>(per sq. feet)</em></label>
                        <input className='form-control'
                            value={fec.rate} readOnly />
                    </div>
                    <div className='col-4'>
                        <label>Chargeable Area <em style={{ color: '#EE4B46' }}>(sq. feet)</em></label>
                        <input className='form-control'
                            value={fec.chargeableArea} readOnly />
                    </div>
                    <div className='col-4'>
                        <label>No. of Floors</label>
                        <input className='form-control'
                            value={fec.noOfFloors} readOnly />
                    </div>
                </div>

                <br />

                <div className="row justify-content-center">
                    <div className='col-4'>
                        <label>Gross Amount <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                        <input className='form-control'
                            value={fec.grossAmount} readOnly />
                    </div>
                    <div className='col-4'>
                        <label>GST <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                        <input className='form-control'
                            value={fec.GST} readOnly />
                    </div>
                    <div className='col-4'>
                        <label>Total <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                        <input className='form-control'
                            value={fec.total} readOnly />
                    </div>
                </div>
            </Paper>

            <br />
            <br />

            {/* /////////////////////////// Private Terrace Charge /////////////////*/}

            <Paper elevation={2} style={{ padding: '20px' }}>
                <h4>Private Terrace Charge</h4>
                <br />
                <div className="row justify-content-start">
                    <div className='col-4'>
                        <label>Rate <em style={{ color: '#EE4B46' }}>(per sq. feet)</em></label>
                        <input className='form-control'
                            value={ptc.rate} readOnly />
                    </div>
                    <div className='col-5'>
                        <label>Private Terrace Area <em style={{ color: '#EE4B46' }}>(sq. feet)</em></label>
                        <input className='form-control'
                            value={ptc.privateTerraceArea}
                            onChange={(e) =>
                                setPtc((prev) => {
                                    return {
                                        ...prev,
                                        privateTerraceArea: e.target.value
                                    }
                                })}
                        />
                    </div>
                </div>

                <br />

                <div className="row justify-content-center">
                    <div className='col-4'>
                        <label>Gross Amount <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                        <input className='form-control'
                            value={ptc.grossAmount} readOnly />
                    </div>
                    <div className='col-4'>
                        <label>GST <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                        <input className='form-control'
                            value={ptc.GST} readOnly />
                    </div>
                    <div className='col-4'>
                        <label>Total <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                        <input className='form-control'
                            value={ptc.total} readOnly />
                    </div>
                </div>


                <div className='unitpricing__btn'>
                    <button onClick={updatePrivateTerrace}>Update Private Terrace</button>
                </div>
            </Paper>


            <div className='unitpricing__btn'>
                <button onClick={handleSave}>Save</button>
            </div>

        </div>
    )
}

export default EditUnitPricing;