import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";
import { useParams } from "react-router-dom";

const CollectionAccount = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const siteCode = useParams().siteCode;
  const Token = "bearer" + " " + userInfo.token;
  const [validated, setValidated] = useState(true);
  const [disp, setDisp] = useState("none");
  const [cName, setCName] = useState("");
  const [cBranch, setCBranch] = useState("");
  const [cBank, setCBank] = useState("");
  const [cAccount, setCAccount] = useState("");
  const [IFSCcode, setIFSCcode] = useState("");
  const [chequePayeeName, setChequePayeeName] = useState('')

  useEffect(() => {
    const Token = "bearer" + " " + userInfo.token;
    axios
      .get(`${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`, {
        headers: { Authorization: Token },
      })
      .then((response) => {
        const siteInfo = response.data.site;
        const { collectionAccount } = siteInfo;
        setCName(collectionAccount?.accountHolderName)
        setCBank(collectionAccount?.bank)
        setCBranch(collectionAccount?.branch)
        setCAccount(collectionAccount?.accountNo)
        setIFSCcode(collectionAccount?.IFSCCode)
        setChequePayeeName(collectionAccount?.chequePayeeName)
      });

  }, []);

  // handle ifsc code validation
  const changeBcode = (e) => {
    setDisp("none");
    var value = e.target.value;
    setIFSCcode(e.target.value);
    const regex = /^[A-Z0-9]{12}$/;
    var message = document.getElementById("bcodeMessage");
    if (regex.test(value)) {
      message.classList.remove("d-block");
      message.classList.add("d-none");
      setValidated(true);
    } else {
      message.classList.remove("d-none");
      message.classList.add("d-block");
      setValidated(false);
    }
  };

  // save collection account
  const handleSaveCollection = (e) => {
    e.preventDefault();
    const Token = "bearer" + " " + userInfo.token;
    if (validated) {
      
      axios
        .put(
          `${BASE_URL}/api/v1/Configuration/Site/updateCollectionAc`,
          {
            siteCode: siteCode,
            collectionAccount: {
              accountHolderName: cName,
              bank: cBank,
              branch: cBranch,
              accountNo: cAccount,
              IFSCCode: IFSCcode,
              chequePayeeName : chequePayeeName
            },
          },
          { headers: { Authorization: Token } }
        )
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Success",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
              text: "Successfully Added",
            }).then(function () { });
          } else {
            Swal.fire({
              icon: "error",
              title: "Failure",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
              text: "Can not save",
            }).then();
          }
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Failure",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: "Input Wrong",
      });
    }
    //console.log(validated);
  };

  const handleReset = () => {
    setCName("");
    setCBank("");
    setCBranch("");
    setCAccount("");
    setIFSCcode("");
  };
  return (
    <div className="outlet_bg">
      <div className="outlet_container ml-0">
        <div className="row justify-content-center my-4">
          <div className="col-4">
            <h4>Collection Account</h4>
          </div>
        </div>
        <form onSubmit={handleSaveCollection}>
          <div className="row justify-content-center">
            <div className="col-3">
              <label>Account Name</label>
              <input
                type="text"
                className="form-control"
                name="fec"
                id="fec"
                value={cName}
                onChange={(e) => {
                  setCName(e.target.value);
                }}
                required
              />
            </div>
            <div className="col-3">
              <label>Bank</label>
              <input
                type="text"
                className="form-control"
                name="ugp"
                id="ugp"
                value={cBank}
                required
                onChange={(e) => {
                  setCBank(e.target.value);
                }}
              />
            </div>
            <div className="col-3">
              <label>Branch</label>
              <input
                type="text"
                className="form-control"
                name="nof"
                id="nof"
                required
                value={cBranch}
                onChange={(e) => {
                  setCBranch(e.target.value);
                }}
              />
            </div>
          </div>
          <br />

          <div className="row justify-content-center">

            <div className="col-3">
              <label>Account No.</label>
              <input
                type="text"
                className="form-control"
                name="nef"
                id="nef"
                value={cAccount}
                onChange={(e) => {
                  setCAccount(e.target.value);
                }}
                required
              />
            </div>

            <div className="col-3">
              <label>IFSC Code</label>
              <input
                className="form-control"
                name="nef"
                id="nef"
                value={IFSCcode}
                onChange={changeBcode}
                required
              />
              {IFSCcode && (
                <small id="bcodeMessage" className="text-danger d-none">
                  Must be of 12 digits with capital letters and numbers only
                  <br />
                </small>
              )}
            </div>

            <div className="col-3">
              <label>Cheque Payee Name</label>
              <input
                className="form-control"
                name="nef"
                id="nef"
                value={chequePayeeName}
                onChange={(e)=>setChequePayeeName(e.target.value)}
                required
              />
              {IFSCcode && (
                <small id="bcodeMessage" className="text-danger d-none">
                  Must be of 12 digits with capital letters and numbers only
                  <br />
                </small>
              )}
            </div>
          </div>

          <br />
          {/* save button  */}
          <div className="siteconfigurations__form__btn">
            <button type="reset" className="mr-4" onClick={handleReset}>
              Clear
            </button>
            <button type="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CollectionAccount;
