import React, { useEffect, useState } from "react";
import "../BankTransfer/BankTransfer.scss";
import MaterialTable, { MTableToolbar } from "material-table";
import Button from "../../components/Button/Button";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useStyles } from "../../utils/ModalStyles";
import { useNavigate } from "react-router-dom";
import DatePicker from "@mui/lab/DatePicker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import MuiPickersUtilsProvider from "@mui/lab";
// import DateFnsUtils from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

const BankPayment = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [bankpayment, setBankPayment] = useState([]);
  const [method, setMethod] = useState("");

  //filter list state data
  const [listPayerUserFullName, setListPayerUserFullName] = useState([]);
  const [listCostCenter, setListCostCenter] = useState([]);

  //fitler states
  const [finYear, setFinYear] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [payerUserFullName, setPayerUserFullName] = useState("");
  const [costCenter, setCostCenter] = useState("");

  useEffect(() => {
    getListOfBankPayment();
  }, []);

  const getListOfBankPayment = () => {
    axios
      .get(`${BASE_URL}/api/v1/FinanceMgmt/bankpayment/getListOfBankPayment`, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((res) => {
        // console.log(res);
        setBankPayment(res.data.listOfBankPayment);
      });
  };

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true&employeeRole=Finance`,
        {
          headers: {
            Authorization: `bearer ${userInfo.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setListPayerUserFullName(response.data.employees);
        } else {
          setListPayerUserFullName([]);
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/financemgmt/costCenter/getListOfCostCenter?isAvtive=true`,
        {
          headers: {
            Authorization: `bearer ${userInfo.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setListCostCenter(response.data.Cost_Centers);
        } else {
          setListCostCenter([]);
        }
      });
  }, []);

  const handleReset = () => {
    setPaymentType("");
    setCostCenter("");
    setPayerUserFullName("");
    setFinYear("");
    getListOfBankPayment();
  };

  //filter function
  const handleFilter = async () => {
    let url = `${BASE_URL}/api/v1/FinanceMgmt/bankpayment/getListOfBankPayment?`;
    let filter = "";

    if (paymentType) {
      filter += `paymentType=${paymentType}&`;
    }

    if (costCenter) {
      filter += `costCenterCode=${costCenter}&`;
    }

    if (payerUserFullName) {
      filter += `payerEmpId=${payerUserFullName}&`;
    }

    if (finYear) {
      filter += `finYear=${finYear}&`;
    }

    url += filter;

    const { data } = await axios.get(url, {
      headers: { Authorization: `bearer ${userInfo.token}` },
    });
    setBankPayment(data.listOfBankPayment);
  };

  useEffect(() => {
    handleFilter();
  }, [paymentType, costCenter, payerUserFullName, finYear]);

  const dateFormatting = (input) => {
    if (!input) return "N/A";

    let date = new Date(input).getDate();
    if (date < 10) {
      date = `0${date}`;
    }

    let month = new Date(input).getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }

    let year = new Date(input).getFullYear();

    let hour = new Date(input).getHours();
    if (hour < 10) {
      hour = `0${hour}`;
    }

    let minute = new Date(input).getMinutes();
    if (minute < 10) {
      minute = `0${minute}`;
    }

    return `${date}/${month}/${year} , ${hour}:${minute}`;
  };

  return (
    <div className="banktransfer">
      <div className="banktransfer__btns">
        <Button navigateTo={"/home"} />
        <button
          className="banktransfer__btn"
          style={{ backgroundColor: "#EE4B46", color: "white" }}
          onClick={() => navigate("/finance/banktransfer/bank-transfer-form")}
        >
          Add Bank Payment
        </button>
      </div>

      <br />
      <br />

      <h4>Bank Payment</h4>

      <MaterialTable
        data={bankpayment}
        title=""
        columns={[
          { title: "Bank Payment ID", field: "bankPaymentId" },
          {
            title: "Payment Date",
            render: (rowData) => dateFormatting(rowData.paymentDate),
            defaultSort: "desc", // Sort by paymentDate in descending order
            customSort: (a, b) => {
              return (
                new Date(a.paymentDate).getTime() -
                new Date(b.paymentDate).getTime()
              );
            },
          },
          {
            title: "Financial Year",
            field: "finYear",
            // filtering: filteringState,
          },
          {
            title: "Cost Center",
            field: "costCenterName",
            // filtering: filteringState,
          },
          {
            title: "Payment Type",
            field: "paymentType",
            // filtering: filteringState,
          },

          {
            title: "Payee Name",
            render: (rowData) => rowData.payeeFullName || "N/A",
          },

          { title: "Amount", field: "amount" },
          { title: "Transfer Method", field: "transferMethod" },
          { title: "Payee Bank Name", field: "payeeBankName" },
          { title: "Payee Account Number", field: "payeeAccountNumber" },
          {
            title: "UPI ID ",
            render: (rowData) =>
              rowData?.payeeUPIId ? rowData.payeeUPIId : "N/A",
          },
          {
            title: "Paid By ",
            field: "payeruserFullName",
            filtering: true,
            render: (rowData) => `${rowData.payerUserFullName}`,
          },
          { title: "Payer Bank Name ", field: "payerBankName" },
          { title: "Branch Name", field: "payerBranchName" },
          { title: "Payer Account No. ", field: "payerAccountNumber" },
        ]}
        options={{
          filtering: false,
          search: window.innerWidth < 700 ? false : true,
          searchFieldStyle: {
            width: 180,
          },
          
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
          },
          sorting: true,
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ position: "relative", margin: "1rem 0" }}>
              <MTableToolbar {...props} />
              <div className="filters text-left">
                <FormControl
                  variant="standard"
                  className="formControl"
                  style={{
                    margin: "0px 10px",
                    marginTop: "-65px",
                    width: "150px",
                  }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Financial Year
                  </InputLabel>
                  <Select
                    id="demo-simple-select-standard"
                    value={finYear}
                    onChange={(e) => {
                      setFinYear(e.target.value);
                    }}
                    className={""}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">Financial Year</MenuItem>
                    {[
                      "2020-21",
                      "2021-22",
                      "2022-23",
                      "2023-24",
                      "2024-25",
                    ].map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="standard"
                  className="formControl"
                  style={{
                    margin: "0px 10px",
                    marginTop: "-65px",
                    width: "150px",
                  }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Payment Type
                  </InputLabel>
                  <Select
                    id="demo-simple-select-standard"
                    value={paymentType}
                    onChange={(e) => {
                      setPaymentType(e.target.value);
                    }}
                    className={""}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">Payment Type</MenuItem>
                    <MenuItem value="Claims">Claims</MenuItem>
                    <MenuItem value="Salary">Salary</MenuItem>

                    <MenuItem value="Salary Advance">Salary Advance</MenuItem>

                    <MenuItem value="Labour Payment">Labour Payment</MenuItem>
                    <MenuItem value="Vendor Payment">Vendor Payment</MenuItem>
                    <MenuItem value="Petty Payments">Petty Payments</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                  </Select>
                </FormControl>

                {window.innerWidth < 1300 && (
                  <>
                    <br />
                  </>
                )}

                <FormControl
                  variant="standard"
                  className="formControl"
                  style={{
                    margin: "0px 10px",
                    marginTop: window.innerWidth < 1300 ? "-40px" : "-65px",
                    width: "12rem",
                  }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Payer Full Name
                  </InputLabel>
                  <Select
                    id="demo-simple-select-standard"
                    value={payerUserFullName}
                    onChange={(e) => {
                      setPayerUserFullName(e.target.value);
                    }}
                    className={""}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">Payer FullName</MenuItem>
                    {listPayerUserFullName?.map((payer, index) => {
                      return (
                        <MenuItem key={index} value={payer?.employeeId}>
                          {payer.employeeFullName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                {/* {window.innerWidth < 1300 && <br />} */}

                <FormControl
                  variant="standard"
                  className="formControl"
                  style={{
                    margin: "0px 10px",
                    marginTop: window.innerWidth < 1300 ? "-40px" : "-65px",
                  }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Cost Center
                  </InputLabel>
                  <Select
                    id="demo-simple-select-standard"
                    value={costCenter}
                    onChange={(e) => {
                      setCostCenter(e.target.value);
                    }}
                    className={""}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">Cost Center</MenuItem>
                    {listCostCenter?.map((center, index) => {
                      return (
                        <MenuItem key={index} value={center?.costCenterCode}>
                          {center?.costCenterName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                {/* {window.innerWidth < 1300 && <br />} */}

                <FormControl
                  style={{
                    margin: "0px 10px",
                    marginTop: window.innerWidth < 1300 ? "-30px" : "-55px",
                  }}
                >
                  <button onClick={handleReset} className="resetBtn">
                    Reset Filter
                  </button>
                </FormControl>
              </div>
            </div>
          ),
        }}
      ></MaterialTable>
    </div>
  );
};

export default BankPayment;
