import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button'
import MaterialTable, { MTableToolbar } from "material-table";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useStyles } from "../../utils/ModalStyles";
import { BASE_URL } from '../../utils/config/url';
import { AiFillEye } from 'react-icons/ai';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import './ListOfLeavePlan.scss';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';

const ListOfLeavePlan = () => {

  const classes = useStyles();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const navigate = useNavigate();

  const [isFlexi, setIsFlexi] = useState('');
  const [leavePlans, setLeavePlans] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState({});
  const [leavePlanId, setLeavePlanId] = useState('');
  const [modal, setModal] = useState(false);

  useEffect(() => {

    getListOfLeavePlan()

  }, [isFlexi])

  const getListOfLeavePlan = () => {
    let url;

    if (isFlexi === '') {
      url = `${BASE_URL}/api/v1/hrmgmt/leavePlan/getListOfLeavePlan`
    }
    else if (isFlexi === false) {
      url = `${BASE_URL}/api/v1/hrmgmt/leavePlan/getListOfLeavePlan/?isFlexiPlan=false`
    }
    else {
      url = `${BASE_URL}/api/v1/hrmgmt/leavePlan/getListOfLeavePlan/?isFlexiPlan=true`
    }


    axios.get(url, {
      headers: { Authorization: `bearer ${userInfo.token}` }
    })
      .then((response) => {
        setLeavePlans(response.data.leaveplanList)
      })
  }

  const reset = () => {
    setIsFlexi('')
    setSelectedTeamMember({});
  }


  useEffect(() => {
    axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`, {
      headers: { Authorization: `bearer ${userInfo.token}` }
    })
      .then((response) => {


        let emp = response.data.employees.map((emp) => {
          return {
            employeeFirstName: emp.employeeFirstName,
            employeeLastName: emp.employeeLastName,
            employeeId: emp.employeeId,
            leavePlanId: emp.leavePlanId,
            leavePlanName: emp.leavePlanName
          }
        })

        setTeamMembers(emp)

      })

  }, [])

  const assignLeavePlan = (e) => {
    e.preventDefault();

    axios.put(`${BASE_URL}/api/v1/hrmgmt/leavePlan/assignLeavePlanToEmployee`, {
      employeeId: selectedTeamMember.employeeId,
      leavePlanId: leavePlanId,
      // lineManagerId: selectedTeamMember.lineManagerId,
      // leavePlanName: selectedTeamMember.leavePlanName
    }, {
      headers: { Authorization: `bearer ${userInfo.token}` }
    })
      .then((response) => {
        setModal(false);

        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            }
          }).then(function () {
            reset();
          })
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response?.data?.message
          })
        }

      })
      .catch((err) => {
        setModal(false);

        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err?.response?.data?.error
        })
      })
  }

  return (
    <div className='leave-plan'>

      <div className='leave-plan__btns'>
        <Button navigateTo={'/leavemanagement'} />

        <button className='leave-plan__btns__btn'
          onClick={() => navigate('/leavemanagement/createleaveplan')}
        >Create Leave Plan</button>

      </div>

      <br />
      <br />

      <h4>Leave Plans</h4>

      <MaterialTable
        data={leavePlans}
        title=""
        columns={[
          {
            title: 'Actions',
            render: (rowData) => {
              return <div className='leave-plan__table__btns'>
                <AiFillEye style={{ fontSize: '35px', cursor: 'pointer' }}
                  title='View Leave Plan'
                  onClick={() => navigate(`/leavemanagement/editleaveplan/${rowData.leavePlanId}`)} />

                <button className='leave-plan__table__btns__btn'
                  onClick={() => {
                    setLeavePlanId(rowData.leavePlanId)
                    setModal(true)
                  }}>Assign Leave Plan</button>
              </div>
            }
          },
          { title: "Leave Plan ID", field: "leavePlanId" },
          { title: "Leave Plan Name", field: "leavePlanName" },
          { title: "Is Flexi Plan", field: "isFlexiPlan" },
          { title: "Earned Leave", field: "earnedLeave" },
          { title: "Casual Leave", field: "casualLeave" },
          { title: "Medical Leave", field: "medicalLeave" },
          {title: "Leave Without Pay", field: "lwp"},
          { title: "Description", field: "description" }
        ]}

        options={{
          search: true,
          searchFieldStyle: {
            width: 180
          },
          actionsColumnIndex: 0,
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          actionsCellStyle: {
            width: 120,
            paddingLeft: 30,
          },
          sorting: true,
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
            paddingLeft: "11px",
          },
        }}

        // actions={[
        //   (rowData) => ({
        //     icon: "remove_red_eye",
        //     tooltip: "View Leave Plan",
        //     onClick: (rowdata) => {
        //       navigate(`/leavemanagement/editleaveplan/${rowData.leavePlanId}`)
        //     }

        //   },{
        //     icon : <AiFillEye/>
        //   })
        // ]}

        components={{
          Toolbar: (props) => (
            <div className="filters text-left">
              <MTableToolbar {...props} />

              <FormControl variant='standard' className="formControl" style={{ margin: '0 13px', marginTop: "-65px", width: "120px" }}>
                <InputLabel id="demo-simple-select-helper-label" style={{ fontSize: "14px" }}>
                  Is Flexi Plan
                </InputLabel>

                <Select value={isFlexi}
                  onChange={(e) => setIsFlexi(e.target.value)}
                  inputProps={{ "aria-label": "Without label" }}>
                  <MenuItem value="" disabled>
                    Is Flexi Plan
                  </MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>

              {window.innerWidth < 700 && <br />}

              <FormControl className={classes.formControl} style={{ marginTop: window.innerWidth < 700 ? '-20px' : '-50px' }}>
                <button
                  onClick={reset}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                    borderRadius: "20px",
                    padding: "5px",
                    width: "100px",
                    margin: "auto",
                  }}
                >
                  Reset Filter
                </button>
              </FormControl>
            </div>
          ),
        }}

      ></MaterialTable>

      {/* //////////////////////// Modal to Assign Leave Plan ///////////////////// */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={() => setModal(false)}
        closeAfterTransition
      >
        <Fade in={modal}>
          <div className="paper">
            <form className='row flex-column justify-content-center align-items-center m-auto'
              onSubmit={(e) => assignLeavePlan(e)}
            >
              <div className='col-12 text-center'>
                {
                  teamMembers.length === 0 ?
                    <h5>No Active Employees Found</h5>
                    :
                    <Form.Group controlId="team-member">
                      <Form.Label>Select an Employee</Form.Label>
                      <Form.Control as="select" required
                        onChange={(e) => {
                          // console.log(e.target.value)
                          let val = e.target.value.split(',')
                          // console.log(val)
                          setSelectedTeamMember((prev) => {
                            return {
                              ...prev,
                              employeeId: val[0],
                              leavePlanId: val[1],
                              leavePlanName: val[2]
                            }
                          })
                        }}
                      >
                        <option value="">Select an Employee</option>
                        {
                          teamMembers?.map((member) => {

                            return <option value={`${member?.employeeId},${member?.leavePlanId},${member?.leavePlanName}`}>{member.employeeFirstName} {member.employeeLastName}</option>
                          })
                        }
                      </Form.Control>
                    </Form.Group>
                }

              </div>

              <br />

              <div className="row justify-content-center">
                <div className="col-6 text-right">
                  <button
                    className="modal__btns"
                    type='submit'
                  >
                    Yes
                  </button>
                </div>
                <div className="col-6 text-left">
                  <button
                    className="modal__btns"
                    onClick={(e) => {
                      e.preventDefault()
                      setModal(false)
                    }}
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    No
                  </button>
                </div>
              </div>

            </form>

          </div>
        </Fade >
      </Modal >

    </div >
  )
}

export default ListOfLeavePlan;