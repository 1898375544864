import { Switch, Paper } from "@mui/material";
import React from "react";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import { Modal, Fade } from "@mui/material";
import { MoonLoader } from "react-spinners";
import { dateFormatting } from "../../utils/dateFormatting";

const ValuationEVerification = ({ unitHandover, setUnitHandover }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loader, setLoader] = React.useState(false);
  const [initiatedDate, setInitiatedDate] = React.useState("");
  const [lawyerName, setLawyerName] = React.useState("");
  const [challanInfo, setChallanInfo] = React.useState({
    eAssessmentNo: "",
    eAssessmentDate: "",
    valuationAmount: "",
    file: "",
    validityDate: "",
  });

  const initiateValuation = (e) => {
    e.preventDefault();

    setLoader(true);

    axios
      .put(
        `${BASE_URL}/api/v1/CustomerMgmt/handover/initiateValuation`,
        {
          handoverId: unitHandover?.handoverId,
          initiatedByUserName: userInfo?.userName,
          initiatedByUserFullName: userInfo?.userFullName,
          initiatedDate,
          lawyerName,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setUnitHandover(response.data.handover);
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const uploadEVerificationDetails = (e) => {
    e.preventDefault();

    setLoader(true);

    let formData = new FormData();

    formData.append("handoverId", unitHandover.handoverId);

    for (let key in challanInfo) {
      formData.append(key, challanInfo[key]);
    }

    axios
      .put(
        `${BASE_URL}/api/v1/CustomerMgmt/handover/uploadEVerificationDetails`,
        formData,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setUnitHandover(response.data.handover);
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  return (
    <>
      <Paper
        elevation={1}
        style={{
          padding: "30px 20px",
          width: "90%",
          margin: "auto",
        }}
      >
        <div className="row mb-3 mx-2 card-1">
          <div className="col-12 tab-card pt-3 pb-3 text-center">
            {!unitHandover?.initiateEVerification?.isInitiated ? (
              userInfo.userRole !== "Operations" &&
              userInfo.userRole !== "Director" ? (
                <h5
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Valuation E-Verification Pending
                </h5>
              ) : (
                <>
                  <h4
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Valuation E-Verification
                  </h4>

                  <div className="row justify-content-center mt-4">
                    <div className="col-6">
                      <label>Initiated Date</label>
                      <input
                        type="date"
                        required
                        className="form-control"
                        value={initiatedDate}
                        onChange={(e) => setInitiatedDate(e.target.value)}
                      />
                    </div>

                    <div className="col-6">
                      <label>Lawyer Name</label>
                      <input
                        type="text"
                        required
                        className="form-control"
                        value={lawyerName}
                        onChange={(e) => setLawyerName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row justify-content-center mt-4">
                    <div className="col-6">
                      <button
                        className="unit-handover__btns"
                        onClick={(e) => {
                          if (!initiatedDate || !lawyerName) return;
                          initiateValuation(e);
                        }}
                      >
                        Initiate E-Verification
                      </button>
                    </div>
                  </div>
                </>
              )
            ) : !unitHandover?.initiateEVerification
                ?.eAssessmentDocumentLink ? (
              <form
                onSubmit={(e) => {
                  uploadEVerificationDetails(e);
                }}
              >
                <div className="row justify-content-center mt-4">
                  <div className="col-4">
                    <label>Lawyer Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={unitHandover?.initiateEVerification?.lawyerName}
                      disabled
                    />
                  </div>

                  <div className="col-4">
                    <label>Initiation Date</label>
                    <input
                      type="text"
                      className="form-control"
                      value={dateFormatting(
                        unitHandover?.initiateEVerification?.initiatedDate
                      )}
                      disabled
                    />
                  </div>
                  <div className="col-4">
                    <label>Initiated By</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        unitHandover?.initiateEVerification
                          ?.initiatedByUserFullName
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="row justify-content-center mt-4">
                  <div className="col-4">
                    <label>E-Assessment Slip No.</label>
                    <input
                      type="number"
                      required
                      className="form-control"
                      value={challanInfo?.eAssessmentNo}
                      onChange={(e) =>
                        setChallanInfo((prev) => {
                          return { ...prev, eAssessmentNo: e.target.value };
                        })
                      }
                    />
                  </div>
                  <div className="col-4">
                    <label>Assessment Date</label>
                    <input
                      type="date"
                      required
                      className="form-control"
                      value={challanInfo?.eAssessmentDate}
                      onChange={(e) =>
                        setChallanInfo((prev) => {
                          return { ...prev, eAssessmentDate: e.target.value };
                        })
                      }
                    />
                  </div>
                  <div className="col-4">
                    <label>Stamp Duty Amount</label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      value={challanInfo?.valuationAmount}
                      onChange={(e) =>
                        setChallanInfo((prev) => {
                          return { ...prev, valuationAmount: e.target.value };
                        })
                      }
                    />
                  </div>
                </div>

                <div className="row justify-content-center mt-4">
                  <div className="col-6">
                    <label>Validity Date</label>
                    <input
                      type="date"
                      required
                      className="form-control"
                      value={challanInfo?.validityDate}
                      onChange={(e) =>
                        setChallanInfo((prev) => {
                          return { ...prev, validityDate: e.target.value };
                        })
                      }
                    />
                  </div>

                  <div className="col-6">
                    <label>Assessment Scan</label>
                    <input
                      type="file"
                      required
                      className="form-control"
                      onChange={(e) =>
                        setChallanInfo((prev) => {
                          return { ...prev, file: e.target.files[0] };
                        })
                      }
                    />
                  </div>
                </div>

                <div className="row justify-content-center mt-4">
                  <div className="col-6">
                    <button className="unit-handover__btns" type="submit">
                      Upload Assessment Details
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      paddingRight: "10px",
                      marginRight: "5px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      paddingTop: "5px",
                      paddingLeft: "10px",
                    }}
                  >
                    Valuation E-Verification Completed
                  </p>
                  <br />
                  <div>
                    <p>
                      {" "}
                      Assessment Scan:{" "}
                      <b>
                        <a
                          href={
                            unitHandover?.initiateEVerification
                              ?.eAssessmentDocumentLink
                          }
                        >
                          Download
                        </a>
                      </b>
                    </p>
                    <p>
                      Initiated On:{" "}
                      <b>
                        {dateFormatting(
                          unitHandover?.initiateEVerification?.initiatedDate
                        )}
                      </b>{" "}
                    </p>
                    <p>
                      Initiated By :{" "}
                      <b>
                        {
                          unitHandover?.initiateEVerification
                            ?.initiatedByUserFullName
                        }
                      </b>
                    </p>
                    <p>
                      {" "}
                      Lawyer Name:{" "}
                      <b>{unitHandover?.initiateEVerification?.lawyerName}</b>
                    </p>
                    <p>
                      {" "}
                      E-Assessment Slip No.:{" "}
                      <b>
                        {unitHandover?.initiateEVerification?.eAssessmentNo}
                      </b>
                    </p>
                    <p>
                      {" "}
                      Assessment Date:{" "}
                      <b>
                        {dateFormatting(
                          unitHandover?.initiateEVerification?.eAssessmentDate
                        )}
                      </b>
                    </p>
                    <p>
                      {" "}
                      Stamp Duty Amount:{" "}
                      <b>
                        {unitHandover?.initiateEVerification?.valuationAmount}
                      </b>
                    </p>
                    <p>
                      {" "}
                      Validity Date:{" "}
                      <b>
                        {dateFormatting(
                          unitHandover?.initiateEVerification?.validityDate
                        )}
                      </b>
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <br />
        <div className="text-center">
          <em style={{ color: "Red", fontSize: "10px" }}>
            This Sum is received and handled by Westroad, only for third party
            payments as per Customer's request and discretion
          </em>
        </div>
      </Paper>

      {/* //////////// Modal for spinner //////////// */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={loader}
        onClose={() => {
          setLoader(false);
        }}
        closeAfterTransition
      >
        <Fade in={loader}>
          <div>
            <MoonLoader
              color="red"
              className="spinner"
              loading={loader}
              size={70}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default ValuationEVerification;
