import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button';
import { BASE_URL } from '../../utils/config/url';
import { Form } from "react-bootstrap";
import './BankTransferForm.scss';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const BankTransferForm = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const navigate = useNavigate();

    const [category, setCategory] = useState('')
    const [subCategory, setSubCategory] = useState('')
    const [customerIds, setCustomerIds] = useState([])
    const [customerId, setCustomerId] = useState('')
    const [siteCode, setSiteCode] = useState('')
    const [sites, setSites] = useState([])
    const [phaseCode, setPhaseCode] = useState('')
    const [phases, setPhases] = useState([])

    const [transferDate, setTransferDate] = useState('')
    const [transferAmount, setTransferAmount] = useState('');
    const [transferMethod, setTransferMethod] = useState('');
    const [bank, setBank] = useState('');
    const [account, setAccount] = useState('');
    const [upi, setUpi] = useState('')
    const [depositBankName, setDepositBankName] = useState('')
    const [depositBankAccount, setDepositBankAccount] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [transferComments, setTranferComments] = useState('')
    const [max, setMax] = useState('');

    useEffect(() => {
        let date = new Date();
        let yyyy = date.getFullYear();
        let mm = date.getMonth() + 1;
        let dd = date.getDate();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }
        setMax(`${yyyy}-${mm}-${dd}`);
    }, [])

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/Configuration/Site/getAllSites`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)
                setSites(response.data.siteArray)
            })
    }, [])

    useEffect(() => {

        if (!siteCode || siteCode === ' ') {
            setPhaseCode('')
            setSiteCode('')
            return
        }

        axios.get(`${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                //console.log(response)
                setPhases(response.data.site.phases)
            })
    }, [siteCode])


    useEffect(() => {

        if (!siteCode || !phaseCode) {
            return
        }

        axios.get(`${BASE_URL}/api/v1/CustomerMgmt/Customer/getListOfCustomers?siteCode=${siteCode}&phaseCode=${phaseCode}&isActive=true`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response)
                let arr = []
                response.data.customerList?.forEach((c) => {
                    arr.push(c.customerId)
                })

                setCustomerIds(arr)
            })

    }, [siteCode, phaseCode])

    const dateFormatting = (input, type) => {

        if (!input) {
            return 'N/A'
        }

        let d = new Date(input)
        let date = d.getDate()
        if (date < 10) {
            date = `0${date}`
        }
        let m = d.getMonth() + 1
        if (m < 10) {
            m = `0${m}`
        }
        let y = d.getFullYear()

        let final;

        if (type === 'original') {
            final = `${y}-${m}-${date}`
            return final
        } else {
            final = `${date}/${m}/${y}`
            return final
        }

    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        let body = {
            paymentCategory: category,
            paymentSubCategory: subCategory,
            siteCode,
            phaseCode,
            customerId,
            transferMethod: transferMethod,
            transferAmount : parseInt(transferAmount),
            transferDate: transferDate,
            transferBankName: bank,
            transferBankAccount: account,
            transferComments,
            depositBankName,
            depositBankAccount,
            transactionId,
            financeUserName: userInfo.userName,
            financeUserFullName: userInfo.userFullName,
            upiId: upi
        }

        axios.post(`${BASE_URL}/api/v1/FinanceMgmt/bankTransfer/bankTransferEntry`,
            body,
            {
                headers: { Authorization: `berare ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response)
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: response.data.message,
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    }
                })
                    .then(() => {
                        document.getElementById('bank-transfer').reset();
                        navigate('/finance/banktransfer')
                    })
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }

    return (
        <div className='banktransferform'>

            <Button navigateTo={'/finance/banktransfer'} />

            <br />
            <br />

            <div className="row container-fluid justify-content-center" >
                <div className="col-8">
                    <h3 className='text-center'>Bank Transfer Form</h3>
                </div>
            </div>
            <br />

            <form
                id='bank-transfer'
                onSubmit={(e) => handleFormSubmit(e)}
            >

                <div className="row container-fluid justify-content-center">
                    <div className="col-4">
                        <Form.Group controlId="payment-category">
                            <Form.Label>Payment Category</Form.Label>
                            <Form.Control as="select" required
                                value={category}
                                onChange={(e) => {
                                    setCategory(e.target.value)
                                }}
                            >
                                <option value="">Select a Category</option>
                                <option value="CustomerPayment">Customer Payment</option>
                                <option value="OtherPayment">Other Payment</option>
                            </Form.Control>
                        </Form.Group>
                    </div>

                    {
                        category === 'CustomerPayment' ?
                            <div className='col-4'>
                                <Form.Group controlId="payment-sub-category">
                                    <Form.Label>Payment Sub-Category</Form.Label>
                                    <Form.Control as="select" required
                                        value={subCategory}
                                        onChange={(e) => {
                                            setSubCategory(e.target.value)
                                        }}
                                    >
                                        <option value="">Select a Sub Category</option>
                                        <option value="DemandPayment">Demand Payment</option>
                                        <option value="FinalPayment">Final Payment</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            : category === 'OtherPayment' ?
                                <div className='col-4'>
                                    <Form.Group controlId="payment-sub-category">
                                        <Form.Label>Payment Sub-Category</Form.Label>
                                        <Form.Control as="select" required
                                            value={subCategory}
                                            onChange={(e) => {
                                                setSubCategory(e.target.value)
                                            }}
                                        >
                                            <option value="">Select a Sub Category</option>
                                            <option value="MaterialsSold">Materials Sold</option>
                                            <option value="OtherPayment">Other Payment</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>

                                :

                                <div className='col-4'>
                                    <Form.Group controlId="payment-sub-category">
                                        <Form.Label>Payment Sub-Category</Form.Label>
                                        <Form.Control as="select" required
                                            value={subCategory}
                                            onChange={(e) => {
                                                setSubCategory(e.target.value)
                                            }}
                                        >
                                            <option value="">Select a Sub Category</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                    }

                </div>

                <br />

                <div className="row container-fluid justify-content-center">

                    <div className='col-4'>
                        <Form.Group controlId="Site-Name">
                            <Form.Label>Site Name</Form.Label>
                            <Form.Control as="select" required
                                value={siteCode}
                                onChange={(e) => {
                                    setSiteCode(e.target.value)
                                }}
                            >
                                <option value=" ">Select a Site</option>
                                {
                                    sites?.map((s, index) => {
                                        return <option key={index} value={s.siteCode}>{s.siteName}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>

                    <div className='col-4'>
                        <Form.Group controlId="Site-Name">
                            <Form.Label>Phase Name</Form.Label>
                            <Form.Control as="select" required
                                value={phaseCode}
                                onChange={(e) => {
                                    setPhaseCode(e.target.value)
                                }}
                            >
                                <option value=" ">Select a Phase</option>
                                {
                                    phases?.map((p, index) => {
                                        return <option key={index} value={p.phaseCode}>{p.phaseName}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>

                </div>

                <br />

                <div className="row container-fluid justify-content-center">

                    <div className='col-4'>
                        <Form.Group controlId="Site-Name">
                            <Form.Label>Customer ID</Form.Label>
                            <Form.Control as="select" required
                                value={customerId}
                                onChange={(e) => {
                                    setCustomerId(e.target.value)
                                }}
                            >
                                <option value="">Select a Customer ID</option>
                                {
                                    customerIds?.map((c, index) => {
                                        return <option key={index} value={c}>{c}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>

                    <div className='col-4'>
                        <label>Transfer Date</label>
                        <input type="date"
                            max={max}
                            className='form-control' onChange={(e) => setTransferDate(e.target.value)} />
                    </div>

                </div>

                <br />

                <div className="row container-fluid justify-content-center">
                    <div className='col-4'>
                        <label>Transfer Amount</label>
                        <input type="number" className='form-control' onChange={(e) => setTransferAmount(e.target.value)} />
                    </div>

                    <div className='col-4'>
                        <Form.Group controlId="Transfer-Method">
                            <Form.Label>Transfer Method</Form.Label>
                            <Form.Control as="select" required
                                value={transferMethod}
                                onChange={(e) => {
                                    setTransferMethod(e.target.value)
                                }}
                            >
                                <option value="">Select a Transfer Method</option>
                                <option value="UPI">UPI</option>
                                <option value="NEFT">NEFT</option>
                                <option value="RTGS">RTGS</option>
                                <option value="IMPS">IMPS</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>

                <br />

                <div className="row container-fluid justify-content-center">
                    <div className='col-3'>
                        <label>Bank Name</label>
                        <input type="text" className='form-control' onChange={(e) => setBank(e.target.value)} />
                    </div>

                    <div className='col-3'>
                        <label>Bank Account No.</label>
                        <input type="text" className='form-control' onChange={(e) => setAccount(e.target.value)} />
                    </div>

                    {transferMethod === 'UPI'
                        &&
                        <div className='col-3'>
                            <label>UPI Id</label>
                            <input type="text" className='form-control' onChange={(e) => setUpi(e.target.value)} />
                        </div>}
                </div>

                <br />


                <div className="row container-fluid justify-content-center">

                    <div className='col-3'>
                        <Form.Group controlId="Site-Name">
                            <Form.Label>Deposit Bank Name</Form.Label>
                            <Form.Control as="select" required
                                value={depositBankName}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    setDepositBankName(value);
                                    if (value === 'WESTROAD DEVELOPERS PVT LTD A/C') setDepositBankAccount('50442540521')
                                    else if (value === 'WESTROAD HEIGHTS COLLECTION A/C') setDepositBankAccount('38861908851')

                                }}
                            >
                                <option value="">Select a Bank</option>
                                <option value="WESTROAD DEVELOPERS PVT LTD A/C">WESTROAD DEVELOPERS PVT LTD A/C</option>
                                <option value="WESTROAD HEIGHTS COLLECTION A/C">WESTROAD HEIGHTS COLLECTION A/C</option>
                            </Form.Control>
                        </Form.Group>
                    </div>

                    <div className='col-3'>
                        <label>Deposit Bank Account</label>
                        <input type="text" className='form-control' required readOnly value={depositBankAccount} />
                    </div>

                    <div className='col-3'>
                        <label>Transaction Id</label>
                        <input type="text" className='form-control' onChange={(e) => setTransactionId(e.target.value)} />
                    </div>

                </div>

                <br />

                <div className="row container-fluid justify-content-center">

                    <div className='col-6'>
                        <label>Transfer Comments</label>
                        <textarea type="text" className='form-control' onChange={(e) => setTranferComments(e.target.value)} />
                    </div>

                </div>

                <br />
                <br />

                <div className="row container-fluid justify-content-center">
                    <button type='submit' className='submit-btn'>Submit</button>
                </div>
            </form>

        </div>
    )
}

export default BankTransferForm;