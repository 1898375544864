import React, { useContext, useEffect, useState } from "react";
import { useStyles } from "../../utils/ModalStyles";
import { Backdrop, Modal, Fade, MenuItem } from "@material-ui/core";
import { useParams, useNavigate } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";
import CloseIcon from "@mui/icons-material/Close";
import "./Unit.scss";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

// const BootstrapInput = styled(InputBase)(({ theme }) => ({
//     "label + &": {
//         marginTop: '10px',
//     },
//     "& .MuiInputBase-input": {
//         borderRadius: 4,
//         position: "relative",
//         backgroundColor: "#EE4B46",
//         color: "white",
//         border: "1px solid #ced4da",
//         fontSize: 16,
//         padding: "10px",

//     }
// }));

const Unit = () => {
  const classes = useStyles();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const siteCode = useParams().siteCode;
  const navigate = useNavigate();
  const Token = "bearer" + " " + userInfo.token;

  const [rate, setRate] = useState();
  const [code, setCode] = useState();
  const [units, setUnits] = useState([]);
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [phaseNames, setPhaseNames] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [phaseNamesForCode, setPhaseNamesForCode] = useState([]);

  useEffect(() => {
    getListofUnit();
  }, []);

  const getListofUnit = () => {
    const Token = "bearer" + " " + userInfo.token;

    axios
      .get(
        `${BASE_URL}/api/v1/Configuration/Unit/getListOfUnit?siteCode=${siteCode}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        const newUnit = response.data.unitList.map((unit) => {
          const {
            status,
            phaseName,
            unitName,
            unitTypeName,
            unitFloor,
            bhk,
            isOnHold,
          } = unit;
          const unitPhaseName = phaseName;
          const unitOnHold = isOnHold;

          setPhaseNames((prev) => {
            if (!prev.includes(phaseName)) {
              return [...prev, phaseName];
            } else {
              return prev;
            }
          });

          return {
            status,
            unitName,
            unitTypeName,
            unitPhaseName,
            unitFloor,
            unitOnHold,
            bhk,
          };
        });
        setUnits(newUnit);
        setFilteredUnits(newUnit);
      })
      .then(() => {
        getPhaseCodeList();
      });
  };

  const getPhaseCodeList = () => {
    axios
      .get(
        `${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        const phases = response.data.site.phases;
        phases.map((p) => {
          setPhaseNamesForCode((prev) => {
            return [...prev, p];
          });
        });
      });
  };

  const updateBasePrice = () => {
    if (!rate || !code) {
      return;
    }

    setOpenModal(false);
    const Token = "bearer" + " " + userInfo.token;
    //console.log(siteCode, rate, code)
    axios
      .post(
        `${BASE_URL}/api/v1/Configuration/Unit/updateAllBasePrice`,
        {
          siteCode: siteCode,

          phaseCode: code,
          baseSqFeetRate: rate,
        },
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        //  console.log(response)
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: "Base Price successfully updated",
          }).then(() => {
            setRate("");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failure",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.error,
          }).then();
        }
      });
  };

  const handleDeleteUnit = () => {
    setOpenModal2(false);
    const Token = "bearer" + " " + userInfo.token;
    axios
      .delete(
        `${BASE_URL}/api/v1/Configuration/Unit/deleteUnitByUnitName/${unitName}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          }).then(() => {
            // console.log(units)
            const newUnitList = [];

            units.forEach((u) => {
              if (u.unitName !== unitName) {
                newUnitList.push(u);
              }
            });

            setUnits(newUnitList);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failure",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.error,
          }).then();
        }
      });
  };

  const handleFilterPhase = (e) => {
    if (
      (e === " " && selectedStatus == "") ||
      (e === " " && selectedStatus == " ")
    ) {
      setSelectedPhase("");
      return setUnits(filteredUnits);
    } else if (
      selectedStatus !== "" &&
      selectedStatus !== " " &&
      (e === "" || e === " ")
    ) {
      setSelectedPhase("");
      let filteredUnitTypes = [];
      filteredUnits.forEach((ut) => {
        if (ut.status === selectedStatus) {
          filteredUnitTypes.push(ut);
        }
      });

      setUnits(filteredUnitTypes);
    } else if (
      (selectedStatus === "" || selectedStatus === " ") &&
      e !== "" &&
      e !== " "
    ) {
      setSelectedPhase(e);
      let filteredUnitTypes = [];
      filteredUnits.forEach((ut) => {
        if (ut.unitPhaseName === e) {
          filteredUnitTypes.push(ut);
        }
      });

      setUnits(filteredUnitTypes);
    } else {
      setSelectedPhase(e);

      let filteredUnitTypes = [];
      units.forEach((ut) => {
        if (ut.unitPhaseName === e) {
          filteredUnitTypes.push(ut);
        }
      });

      setUnits(filteredUnitTypes);
    }
  };

  const handleFilterStatus = (e) => {
    if (
      (e === " " && selectedPhase === "") ||
      (e === " " && selectedPhase === " ")
    ) {
      setSelectedStatus("");
      return setUnits(filteredUnits);
    } else if (
      selectedPhase !== "" &&
      selectedPhase !== " " &&
      (e === "" || e === " ")
    ) {
      setSelectedStatus("");
      let filteredUnitTypes = [];
      filteredUnits.forEach((ut) => {
        if (ut.unitPhaseName === selectedPhase) {
          filteredUnitTypes.push(ut);
        }
      });

      setUnits(filteredUnitTypes);
    } else if (
      (selectedPhase === "" || selectedPhase === " ") &&
      e !== "" &&
      e !== " "
    ) {
      setSelectedStatus(e);
      let filteredUnitTypes = [];
      filteredUnits.forEach((ut) => {
        if (ut.status === e) {
          filteredUnitTypes.push(ut);
        }
      });

      setUnits(filteredUnitTypes);
    } else {
      setSelectedStatus(e);

      let filteredUnitTypes = [];
      units.forEach((ut) => {
        if (ut.status === e) {
          filteredUnitTypes.push(ut);
        }
      });

      setUnits(filteredUnitTypes);
    }
  };

  const reset = () => {
    setSelectedStatus("");
    setSelectedPhase("");
    getListofUnit();
  };

  return (
    <div className="unit">
      <div className="mt-2 container-fluid px-0">
        <center>
          <div style={{ display: "flex" }}>
            <div className="row buttons">
              <button
                className="unit__btn"
                onClick={() =>
                  navigate(`/configurations/site/addunit/${siteCode}`)
                }
              >
                Add Unit
              </button>

              <button className="unit__btn" onClick={() => setOpenModal(true)}>
                Update Base Price
              </button>
            </div>
          </div>

          <br />
          <br />

          <h3 style={{ textAlign: "left" }}>Units</h3>
          <div className="row container-fluid-justify-content-center">
            <div className="col-12 mt-2">
              <MaterialTable
                data={units}
                title=" "
                columns={[
                  { title: "Status", field: "status" },
                  { title: "Unit Name", field: "unitName" },
                  { title: "Unit Type", field: "unitTypeName" },

                  { title: "Unit Floor", field: "unitFloor" },
                  { title: "Unit Phase", field: "unitPhaseName" },
                  { title: "On Hold", field: "unitOnHold" },
                  { title: "BHK", field: "bhk" },
                ]}
                options={{
                  search: window.innerWidth < 700 ? false : true,
                  searchFieldStyle: {
                    width: 180,
                  },
                  actionsColumnIndex: 0,
                  actionsCellStyle: {
                    width: 120,
                    paddingLeft: 30,
                  },
                  paging: true,
                  pageSize: 20,
                  emptyRowsWhenPaging: false,
                  pageSizeOptions: [5, 10, 20, 30],
                  headerStyle: {
                    backgroundColor: "#EE4B46",
                    color: "#fff",
                    paddingLeft: "11px",
                  },
                }}
                components={{
                  Toolbar: (props) => (
                    <div className="filters text-left">
                      <MTableToolbar {...props} />

                      <FormControl
                        className={classes.formControl}
                        style={{
                          margin: "0px 10px",
                          marginTop: "-65px",
                          width: "120px",
                        }}
                      >
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          style={{ fontSize: "15px" }}
                        >
                          Status
                        </InputLabel>

                        <Select
                          value={selectedStatus}
                          style={{
                            fontSize: "15px",
                            textAlign: "left",
                          }}
                          className={classes.selectEmpty}
                          inputProps={{ "aria-label": "Without label" }}
                          onChange={(e) => handleFilterStatus(e.target.value)}
                        >
                          <MenuItem value="">Status</MenuItem>
                          <MenuItem value="Available">Available</MenuItem>
                          <MenuItem value="On Hold">On Hold</MenuItem>
                          <MenuItem value="Booking Initiated">
                            Booking Initiated
                          </MenuItem>
                          <MenuItem value="Alloted">Alloted</MenuItem>
                          <MenuItem value="Handover Initiated">
                            Handover Initiated
                          </MenuItem>
                          <MenuItem value="Registraion Done">
                            Registraion Done
                          </MenuItem>
                          <MenuItem value="In Maintanance">
                            In Maintanance
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl
                        className={classes.formControl}
                        style={{
                          margin: "0px 10px",
                          marginTop: "-65px",
                          width: "120px",
                        }}
                      >
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          style={{ fontSize: "15px" }}
                        >
                          Phase Name
                        </InputLabel>

                        <Select
                          value={selectedPhase}
                          onChange={(e) => handleFilterPhase(e.target.value)}
                        >
                          <MenuItem value=" ">
                            <em>None</em>
                          </MenuItem>
                          {phaseNames.map((pn, index) => {
                            return (
                              <MenuItem value={pn} key={index}>
                                {pn}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                      {window.innerWidth < 1000 && <br />}

                      <FormControl
                        className={classes.formControl}
                        style={{
                          marginTop:
                            window.innerWidth < 1000 ? "-30px" : "-50px",
                        }}
                      >
                        <button
                          onClick={reset}
                          style={{
                            backgroundColor: "white",
                            color: "black",
                            border: "1px solid black",
                            borderRadius: "20px",
                            padding: "0.3em 0.5em",
                          }}
                        >
                          Reset Filter
                        </button>
                      </FormControl>
                    </div>
                  ),
                }}
                actions={[
                  {
                    icon: "remove_red_eye",
                    tooltip: "View Unit",
                    onClick: (event, rowData) => {
                      navigate(
                        `/configurations/site/${siteCode}/editunit/${rowData.unitName}`
                      );
                      localStorage.setItem("ActiveKeyUnit", "first");
                    },
                  },
                  (rowData) => ({
                    icon: "delete",
                    tooltip: "Delete Unit",
                    onClick: (event, rowData) => {
                      setUnitName(rowData.unitName);
                      setOpenModal2(true);
                    },
                    disabled:
                      userInfo.userRole === "ITAdmin" ||
                      userInfo.userRole === "Director"
                        ? false
                        : true,
                  }),
                ]}
              ></MaterialTable>
            </div>
          </div>
        </center>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            setRate("");
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={classes.paper} style={{ padding: "12px 10px" }}>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <span style={{ cursor: "pointer" }}>
                  <CloseIcon
                    onClick={() => {
                      setOpenModal(false);
                      setRate("");
                    }}
                  />
                </span>
              </div>
              <div
                className="row container-fluid justify-content-center"
                style={{ margin: "auto" }}
              >
                <div className="col-6">
                  <label>Site Code</label>
                  <input
                    type="text"
                    class="form-control"
                    value={siteCode}
                    readOnly
                  />
                </div>
                <div className="col-6">
                  <label>Base Sq. Ft. Rate</label>
                  <input
                    required
                    type="number"
                    class="form-control"
                    name="rate"
                    id="rate"
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                  />
                </div>
              </div>
              <br />
              <div
                className="row justify-content-center"
                style={{ margin: "auto" }}
              >
                <div className="col-6">
                  <label>Phase Name</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      phaseNamesForCode.map((p) => {
                        if (p.phaseName === e.target.value) {
                          setCode(p.phaseCode);
                        }
                      });
                    }}
                  >
                    <option>Select a Phase</option>
                    {phaseNamesForCode.map((p, index) => {
                      return <option key={index}>{p.phaseName}</option>;
                    })}
                  </select>
                </div>
              </div>
              <br />
              <div
                className="row container-fluid justify-content-center"
                style={{ margin: "auto" }}
              >
                <div className="col-6 text-center">
                  <button
                    style={{
                      backgroundColor: "#EE4B46",
                      fontSize: "18px",
                      color: "white",
                      borderRadius: "20px",
                      padding: "0.3em 0.5em",
                      border: "none",
                    }}
                    onClick={updateBasePrice}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal2}
          onClose={() => setOpenModal2(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal2}>
            <div className={classes.paper} style={{ padding: "12px 10px" }}>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <span style={{ cursor: "pointer" }}>
                  <CloseIcon onClick={() => setOpenModal2(false)} />
                </span>
              </div>
              <div
                className="row container-fluid justify-content-center"
                style={{ margin: "auto" }}
              >
                <div className="col-12">
                  <label style={{ fontSize: "20px" }}>
                    Are you sure you want to delete this?
                  </label>

                  <div
                    style={{
                      margin: "15px 0",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "#EE4B46",
                        fontSize: "18px",
                        color: "white",
                        borderRadius: "20px",
                        padding: "0.3em 0.8em",
                        border: "none",
                        margin: "0 10px",
                      }}
                      onClick={handleDeleteUnit}
                    >
                      Yes
                    </button>

                    <button
                      style={{
                        backgroundColor: "white",
                        fontSize: "18px",
                        color: "black",
                        borderRadius: "20px",
                        padding: "0.3em 0.8em",
                        border: "1px solid black",
                        margin: "0 10px",
                      }}
                      onClick={() => setOpenModal2(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};

export default Unit;
