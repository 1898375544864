import { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import urgent from '../../assets/images/urgent-priority.png';
import high from '../../assets/images/high-priority.png';
import normal from '../../assets/images/normal-priority.png';

const RedSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#EE4B46",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#EE4B46",
    },
}));



const ShowNotifications = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [priority, setPriority] = useState('')
    const [isToday, setIsToday] = useState(false)
    const [notifications, setNotifications] = useState([])

    useEffect(() => {

        let url = `${BASE_URL}/api/v1/hrmgmt/notification/getListOfNotificationEmployee?employeeId=${userInfo.employeeId}&isToday=${isToday}`
        let filter = ''

        if (priority) {
            filter += `&notificationPriority=${priority}`
        }

        url += filter;

        axios.get(url,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response)
                if (response.data.status === true) {
                    // setActive('notification')
                    setNotifications(response.data.notificationList)
                } else {
                    setNotifications([])
                }
            })

    }, [isToday, priority])

    const dateFormatting = (input) => {
        let date = new Date(input)

        let d = date.getDate()
        if (d < 10) d = '0' + d
        let m = date.getMonth() + 1
        if (m < 10) m = '0' + m
        let y = date.getFullYear()

        let hr = date.getHours()
        if (hr < 10) hr = '0' + hr
        let min = date.getMinutes()
        if (min < 10) min = '0' + min

        return `${d}-${m}-${y} , ${hr}:${min}`
    }

    return (
        <div className="notification-container">


            <div className="notification--sticky">
                <h5>Notifications</h5>

                {/* <div className="row justify-content-center">
                    <Form.Group controlId="Priority">
                        <Form.Control required onChange={(e) => setPriority(e.target.value)} as="select">
                            <option value="">Select a Priority</option>
                            <option>Normal</option>
                            <option>High</option>
                            <option>Critical</option>
                        </Form.Control>
                    </Form.Group>
                </div> */}

                <br />

                <div className='row d-flex justify-content-between'
                    style={{
                        width: '100%',
                        margin: 'auto'
                    }}
                >
                    {/* <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={priority}
                            onClick={(e) => setPriority(e.target.value)}
                            name="radio-buttons-group"
                        >
                            <FormControlLabel value="Normal" control={<Radio
                                style={{ color: '#EE4B46' }}
                            />} label="Normal" />
                            <FormControlLabel value="High" control={<Radio style={{ color: '#EE4B46' }} />} label="High" />
                            <FormControlLabel value="Urgent" control={<Radio style={{ color: '#EE4B46' }} />} label="Urgent" />
                        </RadioGroup> */}
                    <div className="col-4 text-center">
                        <img src={normal} alt="" style={{ width : '40px' , height : '40px' , cursor: 'pointer', backgroundColor : priority === 'Normal' ? 'grey' : '' }}
                            onClick={() => {
                                setPriority('Normal')
                            }}
                        />
                        <b>Normal</b>
                    </div>

                    <div className="col-4 text-center">
                        <img src={high} alt="" style={{ width : '40px' , height : '40px' ,  cursor: 'pointer', backgroundColor : priority === 'High' ? 'grey' : '' }}
                            onClick={() => {
                                setPriority('High')
                            }}
                        />
                        <p style={{fontWeight : 700}}>High</p>
                    </div>

                    <div className="col-4 text-center">
                        <img src={urgent} alt="" style={{ width : '40px' , height : '40px' , cursor: 'pointer' , backgroundColor : priority === 'Urgent' ? 'grey' : '' }}
                            onClick={() => {
                                setPriority('Urgent')
                            }}
                        />
                        <b>Urgent</b>
                    </div>
                </div>

                <br />

                <div className="row justify-content-center align-items-center">
                    <span>30 Days</span>
                    <RedSwitch
                        checked={isToday}
                        onClick={() => {
                            setIsToday(!isToday)
                        }}
                    />
                    <span>Today</span>
                </div>

                <br />

            </div>

            <div className="row flex-column justify-content-center align-items-center">
                {
                    notifications.length > 0
                        ?
                        notifications.map((notification, index) => {
                            return (

                                <div className="card my-3 mx-4 pt-2"
                                    style={{
                                        width: '80%',
                                        borderRadius: '10px',
                                        border: '2px solid #EE4B46'
                                    }}
                                >
                                    <div >
                                        <h5 className="card-title"
                                            style={{ color: '#EE4B46', fontWeight: 'bold' }}
                                        >{notification.notificationHeadline}</h5>
                                    </div>
                                    <div className="card-body">
                                        <p>{notification.notificationBody}</p>

                                        <b>Audience : {notification.notificationAudience}</b>
                                        <br/>
                                        <b>Priority : {notification.notificationPriority}</b>
                                       
                                        <p className="mt-3">{dateFormatting(notification.notificationDate)}</p>
                                    </div>
                                </div>

                            )
                        })
                        :
                        <h5>No Notifications Found</h5>
                }
            </div>

        </div>
    )
};

export default ShowNotifications;