import React, { useEffect, useState } from 'react'
import './LeadReport.scss';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import Button from '../../components/Button/Button';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2'
ChartJS.register(...registerables);


const LeadReport = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    const [closedByClosureReason, setClosedByClosureReason] = useState([]);
    const [closedByClosureReasonCount, setClosedByClosureReasonCount] = useState([])
    const [closedByLeadSource, setClosedByLeadSource] = useState([]);
    const [closedByLeadSourceCount, setClosedByLeadSourceCount] = useState([])

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/report/getClosedLeadReport`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                
                let ninetynineAcres = 0
                let newspaper = 0
                let hoarding = 0
                let website = 0
                let facebook = 0
                let referral = 0
                let walkIn = 0
                let realEstateBroker = 0

                response.data.closedByLeadSource.forEach((lead) => {

                    if (lead._id === '99Acres') {
                        ninetynineAcres += lead.count
                    }
                    else if (lead._id === 'Newspaper') {
                        newspaper += lead.count
                    }
                    else if (lead._id === 'Hoarding') {
                        hoarding += lead.count
                    }
                    else if (lead._id === 'Website') {
                        website += lead.count
                    }
                    else if (lead._id === 'Facebook') {
                        facebook += lead.count
                    }
                    else if (lead._id === 'Referral') {
                        referral += lead.count
                    }
                    else if (lead._id === 'Walk In') {
                        walkIn += lead.count
                    }
                    else if (lead._id === 'Real Estate Broker') {
                        realEstateBroker += lead.count
                    }
                })


                let countArr1 = [ninetynineAcres, newspaper, hoarding, website, facebook, referral, walkIn, realEstateBroker]
                setClosedByLeadSourceCount(countArr1)
                setClosedByLeadSource(['99Acres', 'Newspaper', 'Hoarding', 'Website', 'Facebook', 'Referral', 'Walk In', 'Real Estate Broker'])

                let convertedtoSale = 0
                let flatNotOfChoice = 0
                let budgetIssue = 0
                let loanIssue = 0
                let noRequirement = 0
                let locationNotSuitable = 0
                let wrongLead = 0

                response.data.closedByClosureReason.forEach((lead) => {

                    if (lead._id === 'Converted to Sale') {
                        convertedtoSale += lead.count
                    }
                    else if (lead._id === 'Flat not of choice') {
                        flatNotOfChoice += lead.count
                    }
                    else if (lead._id === 'Budget Issue') {
                        budgetIssue += lead.count
                    }
                    else if (lead._id === 'Loan Issue') {
                        loanIssue += lead.count
                    }
                    else if (lead._id === 'No Requirement') {
                        noRequirement += lead.count
                    }
                    else if (lead._id === 'Location Not Suitable') {
                        locationNotSuitable += lead.count
                    }
                    else if (lead._id === 'Wrong Lead') {
                        wrongLead += lead.count
                    }

                })


                let countArr2 = [convertedtoSale, flatNotOfChoice, budgetIssue, loanIssue, noRequirement, locationNotSuitable, wrongLead]
                setClosedByClosureReasonCount(countArr2)
                setClosedByClosureReason(['Converted to Sale', 'Flat not of choice', 'Budget Issue', 'Loan Issue', 'No Requirement', 'Location Not Suitable', 'Wrong Lead'])
            })
    }, [])

    return (
        <div className='lead-report'>

            <Button navigateTo={1} />

            <h2>Closed Lead Report</h2>

            <br /><br />

            <div className='charts'>
                <p>Closed Lead By Closure Reason</p>

                <div className='chart'>
                    {/* <Pie
                        data={{
                            labels: ['Converted to Sale', 'Flat not of choice', 'Budget Issue' , 'Loan Issue' , 'No Requirement' , 'Location Not Suitable' , 'Wrong Lead'],
                            datasets: [{
                                // label: 'Task Open Due Counts',
                                data: closedByClosureReason,
                                backgroundColor: ['#e89e95', '#e1e895', '#a7e895', '#95b5e8', '#f0ade8', '#adeff0', '#f0cbad']
                                //borderWidth: 1
                            }]

                        }}
                        width={200}
                        height={250}

                        options={{
                            responsive: true,
                            maintainAspectRatio: false
                        }}
                    /> */}

                    <Bar
                        data={{
                            labels: closedByClosureReason,
                            datasets: [{
                                label: 'Closure Reason',
                                data: closedByClosureReasonCount,
                                backgroundColor: 'lightgrey',
                                //borderWidth: 1
                            }]

                        }}
                        height={100}
                        width={500}
                        options={{
                            responsive: true,
                            maintainAspectRatio: true
                        }}
                    />

                </div>

                <br /><br />

                <p>Closed Lead By Lead Source</p>
                <div className='chart'>

                    {/* <Pie
                        data={{
                            labels: ['99Acres', 'newspaper', 'hoarding', 'website', 'facebook', 'referral', 'Walk In', 'Real Estate Broker'],
                            datasets: [{
                                // label: 'Task Open Due Counts',
                                data: closedByLeadSource,
                                backgroundColor: ['#e89e95', '#e1e895', '#a7e895', '#95b5e8', '#f0ade8', '#adeff0', '#f0cbad', '#ede06d']
                                //borderWidth: 1
                            }]

                        }}
                        width={200}
                        height={250}

                        options={{
                            responsive: true,
                            maintainAspectRatio: false
                        }}
                    /> */}

                    <Bar
                        data={{
                            labels: closedByLeadSource,
                            datasets: [{
                                label: 'Lead Source',
                                data: closedByLeadSourceCount,
                                backgroundColor: '#f78e8b',
                                //borderWidth: 1
                            }]

                        }}
                        height={100}
                        width={500}
                        options={{
                            responsive: true,
                            maintainAspectRatio: true
                        }}
                    />

                </div>

            </div>

          
        </div>
    )
}

export default LeadReport