import './LoginPage.scss';
import LoginForm from "./LoginForm";

const LoginPage =()=>{
 
  return (
    <div className='loginform_container'>
      <LoginForm/>
    </div>
  )
}

export default LoginPage; 
