import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import { BASE_URL } from "../../utils/config/url";
import DemandList from "./DemandList";
import Button from "../../components/Button/Button";

const Demands = () => {
  const [openDemands, setOpenDemands] = useState([]);
  const [closedDemands, setClosedDemands] = useState([]);
  const [openType, setOpenType] = useState("");
  const [openCust, setOpenCust] = useState("");
  const [closedType, setClosedType] = useState("");
  const [closedCust, setClosedCust] = useState("");
  const [active, setActive] = useState("od");
  const [change, setChange] = useState(false);
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userName = userInfo.userName;
  const userRole = userInfo.userRole;

  // get all open demands with filtering
  useEffect(() => {
    if (openType === "" && openCust === "") {
      axios.get(`${BASE_URL}/api/v1/CustomerMgmt/Demand/getlistofdemands/?isPaid=${false}`,
       { headers: { Authorization: `bearer ${userInfo.token}` } })
       .then((response) => {
       // console.log(response)
        setOpenDemands(response.data.demandList);
      });
    }
     else if (openType !== "" && openCust === "") {
      axios.get(`${BASE_URL}/api/v1/CustomerMgmt/Demand/getlistofdemands/?isPaid=${false}&demandType=${openType}`,
       { headers: { Authorization: `bearer ${userInfo.token}` } })
       .then((response) => {
        setOpenDemands(response.data.demandList);
      });
    } 
    else if (openType === "" && openCust !== "") {
      axios.get(`${BASE_URL}/api/v1/CustomerMgmt/Demand/getlistofdemands/?isPaid=${false}&customerId=${openCust}`,
       { headers: { Authorization: `bearer ${userInfo.token}` } })
       .then((response) => {
        setOpenDemands(response.data.demandList);
      });
    }
     else if (openType !== "" && openCust !== "") {
      axios.get(`${BASE_URL}/api/v1/CustomerMgmt/Demand/getlistofdemands/?isPaid=${false}&demandType=${openType}&customerId=${openCust}`,
       { headers: { Authorization: `bearer ${userInfo.token}` } })
       .then((response) => {
        setOpenDemands(response.data.demandList);
      });
    }
  }, [change, openType, openCust]);


  //  get all closed demands with filtering
  useEffect(() => {
    if (closedType === "" && closedCust === "") {
      axios.get(`${BASE_URL}/api/v1/CustomerMgmt/Demand/getlistofdemands/?isPaid=${true}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } })
        .then((response) => {
          setClosedDemands(response.data.demandList);
        });
    }
    else if (closedType !== "" && closedCust === "") {
      axios.get(`${BASE_URL}/api/v1/CustomerMgmt/Demand/getlistofdemands/?isPaid=${true}&demandType=${closedType}`, { headers: { Authorization: `bearer ${userInfo.token}` } }).then((response) => {
        setClosedDemands(response.data.demandList);
      });
    }
    else if (closedType === "" && closedCust !== "") {
      axios.get(`${BASE_URL}/api/v1/CustomerMgmt/Demand/getlistofdemands/?isPaid=${true}&customerId=${closedCust}`, { headers: { Authorization: `bearer ${userInfo.token}` } }).then((response) => {
        setClosedDemands(response.data.demandList);
      });
    }
    else if (closedType !== "" && closedCust !== "") {
      axios.get(`${BASE_URL}/api/v1/CustomerMgmt/Demand/getlistofdemands/?isPaid=${true}&demandType=${closedType}&customerId=${closedCust}`, { headers: { Authorization: `bearer ${userInfo.token}` } }).then((response) => {
        setClosedDemands(response.data.demandList);
      });
    }
  }, [change, closedType, closedCust]);


  return (
    <div className="outlet_bg">
      <div className="outlet_container" style={{padding : '20px 10px' }}>
        <div className="d-flex justify-content-between align-items-center">
          <Button navigateTo={'/home'}/>
        </div>
        <div className="profile_tabs">
          <button
            className={`${active === "od" ? "bg-red" : "bg-dark"}`}
            onClick={() => {
              setActive("od");
              setChange(!change);
            }}
          >
            Open Demands
          </button>

          <button
            className={`${active === "cd" ? "bg-red" : "bg-dark"}`}
            onClick={() => {
              setActive("cd");
              setChange(!change);
            }}
          >
            Closed Demands
          </button>
        </div>
        {/* demands tabs  */}
        <div>
          {active === "od" && <DemandList openDemands={openDemands} title="Open Demands" tab={active} openCust={openCust} setOpenCust={setOpenCust} setOpenType={setOpenType} openType={openType} />}

          {active === "cd" && <DemandList closedDemands={closedDemands} title="Closed Demands" tab={active} closedCust={closedCust} setClosedCust={setClosedCust} setClosedType={setClosedType} closedType={closedType} />}
        </div>
      </div>
    </div>
  );
};

export default Demands;
