import './HomePage.scss';

function HomepageComponent(){

const screenSize = window.screen.width;

    return(

        <div className="homepage"  style={{
            marginLeft : screenSize > 1000 ? '240px' : '0px'
        }}>
           <div className="homepage__box">
             <p style={{fontSize : window.innerWidth < 700 ? '22px' : '30px' , margin : 0}}>Welcome to Westroad ERP!</p>
             </div>
        </div>
    )
}

export default HomepageComponent;