import React, { useEffect, useState } from 'react'
import Paper from "@mui/material/Paper";
import { Switch, Select, InputLabel, MenuItem } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { AiFillDelete } from 'react-icons/ai';
import { BASE_URL } from '../../utils/config/url';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';


const RedSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: "#EE4B46",
        "&:hover": {
            backgroundColor: alpha("#EE4B46", theme.palette.action.hoverOpacity),
        },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#EE4B46",
    },
}));


const AddIncentives = ({ paySlip, setPaySlip }) => {

    const payslipNumber = useParams().payslipNumber
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [performanceBonus, setPerformanceBonus] = useState(paySlip?.performanceBonusPercentage > 0 ? true : false)
    const [performanceBonusPercentage, setPerformanceBonusPercentage] = useState(100)
    const [festivalBonus, setFestivalBonus] = useState(paySlip?.festivalBonusPercentage > 0 ? true : false)
    const [festivalBonusPercentage, setFestivalBonusPercentage] = useState(0)
    const [specialIncentives, setSpecialIncentives] = useState(paySlip?.incentives || [])
    const [totalSpecialIncentives, setTotalSpecialIncentives] = useState(0)
    const [spinnerModal, setSpinnerModal] = useState(false);

    useEffect(() => {

        let total = 0;

        paySlip?.incentives.forEach((inc) => {
            total += inc.amount
        })

        setTotalSpecialIncentives(total)

    }, [])

    const addRow = () => {
        let item = {
            serial: specialIncentives.length,
            description: '',
            amount: ''
        }
        // console.log(item)
        setSpecialIncentives([...specialIncentives, item])

    }

    const deleteRow = (index) => {
        let temp = [...specialIncentives]
        // console.log(temp)
        let items = temp.filter((item, i) => (item.serial !== index))
        // console.log(items)
        let total = 0;
        items.forEach((item, i) => {
            item.serial = i
            total += parseInt(item.amount)
        })
        // console.log(items)
        setSpecialIncentives(items)
        setTotalSpecialIncentives(total)
    }

    const handleChange = (e, index, key) => {
        let temp = [...specialIncentives]
        temp[index][key] = e.target.value
        setSpecialIncentives(temp)
        let total = 0;
        temp.forEach((item, i) => {
            total += parseInt(item?.amount) || 0
        })

        setTotalSpecialIncentives(total)
    }

    const addIncentives = () => {

        setSpinnerModal(true)

        let body = {
            payslipNumber: payslipNumber,
            payslipStatus: paySlip?.payslipStatus,
            addMonthlyBonus: performanceBonus,
            addFestivalBonus: festivalBonus,
            incentives: specialIncentives
        };

        if (performanceBonus) {
            body.performanceBonusPercentage = performanceBonusPercentage
        }

        if (festivalBonus) {
            body.festivalBonusPercentage = festivalBonusPercentage
        }

        axios.put(`${BASE_URL}/api/v1/hrmgmt/paySlip/updatePayrollIncentives`,
            body,
            {
                headers: { Authorization: `Bearer ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response)
                setSpinnerModal(false)
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                    })
                        .then(() => {
                            setPaySlip(response.data.paySlip)
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                    })
                }
            })
            .catch((err) => {
                // console.log(err)
                setSpinnerModal(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error,
                })
            })
    }

    return (
        <div className='generatedPaySlip__incentives'>

            <Paper elevation={2} style={{ padding: "20px", marginTop: "40px", width: '70%', margin: 'auto' }}>
                <div className='row d-flex justify-content-between'>
                    <div className='col-6'>
                        <p>Monthly Performance Bonus</p>
                    </div>
                    <div className='col-4'>
                        <RedSwitch
                            checked={performanceBonus}
                            onChange={() => setPerformanceBonus(!performanceBonus)}
                        />
                    </div>
                </div>

                <br />

                <div className='row d-flex justify-content-between'>
                    <div className='col-6'>
                        <p>Total Percentage</p>
                    </div>
                    <div className='col-4'>
                        <input type="text"
                            className='form-control'
                            value={performanceBonusPercentage}
                            onChange={(e) => (setPerformanceBonusPercentage(e.target.value))} />
                    </div>
                </div>
            </Paper>

            <br />

            <Paper elevation={2} style={{ padding: "20px", marginTop: "40px", width: '70%', margin: 'auto' }}>
                <div className='row d-flex justify-content-between'>
                    <div className='col-6'>
                        <p>Yearly Festival Bonus</p>
                    </div>
                    <div className='col-4'>
                        <RedSwitch
                            checked={festivalBonus}
                            onChange={() => setFestivalBonus(!festivalBonus)}
                        />
                    </div>
                </div>

                <br />

                <div className='row d-flex justify-content-between'>
                    <div className='col-6'>
                        <p>Total Percentage</p>
                    </div>
                    <div className='col-4'>
                        <input type="text"
                            className='form-control'
                            value={festivalBonusPercentage}
                            onChange={(e) => (setFestivalBonusPercentage(e.target.value))} />
                    </div>
                </div>
            </Paper>

            <br />

            <Paper elevation={2} style={{ padding: "20px", marginTop: "40px", width: '70%', margin: 'auto' }}>

                <h4>Special Incentives</h4>

                <div className='row justify-content-between'>
                    <div className='col-6'>
                        <button className='generatedPaySlip__incentives__btn pt-2'
                            onClick={addRow}
                        >Add</button>
                    </div>

                    <div className='col-6'>
                        <b style={{ fontSize: '20px' }}>Total : {totalSpecialIncentives}</b>
                    </div>
                </div>


                {
                    specialIncentives?.map((item, index) => {

                        return <div className='row justify-content-center mt-4' key={index}>
                            <div className='col-7 text-center'>
                                <input type="text" placeholder='description'
                                    className='form-control'
                                    value={item.description}
                                    onChange={(e) => handleChange(e, index, 'description')}
                                />
                            </div>

                            <div className='col-3 text-center'>
                                <input type="number" placeholder='Amount'
                                    className='form-control'
                                    value={item.amount}
                                    onChange={(e) => handleChange(e, index, 'amount')}
                                />
                            </div>

                            <div className='col-2 text-center'>
                                <AiFillDelete style={{
                                    fontSize: '25px',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => deleteRow(index)}
                                />
                            </div>
                        </div>
                    })
                }

            </Paper>

            <div className='row justify-content-center mt-4'>
                <div className='col-6 text-center'>
                    <button className='generatedPaySlip__incentives__btn'
                        onClick={addIncentives}
                    >Re-Calculate</button>
                </div>
            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={spinnerModal}
                onClose={() => setSpinnerModal(false)}
                closeAfterTransition
            >
                <Fade in={spinnerModal}>

                    <div>
                        <ClipLoader
                            color='#EE4B46'
                            loading={spinnerModal}
                            size={70}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                </Fade>
            </Modal>

        </div>
    )
}

export default AddIncentives