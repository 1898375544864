import axios from 'axios';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react'
import { Nav } from 'react-bootstrap';
import Button from '../../components/Button/Button'
import { BASE_URL } from '../../utils/config/url';
import './ChequeBook.scss';
import '../../components/Tabs/Tabs.scss';
import CreditChequeBook from '../../components/ChequeBooks/CreditChequeBook';
import DebitChequeBook from '../../components/ChequeBooks/DebitChequeBook';
import { useNavigate } from 'react-router-dom';


const ChequeBook = () => {

    const navigate = useNavigate()
    const [tab, setTab] = useState(localStorage.getItem('ActiveKeyCheques'))

    return (
        <div className='chequebook'>

            <div className='chequebook__buttons'>
                <Button navigateTo={'/home'} />
                <button className='chequebook__btn'
                    onClick={() => navigate('/finance/chequebook/receive-cheque')}>Receive Cheque</button>
            </div>

            <br />
            <br />

            <div className="tabs">
                <Nav>
                    <Nav.Item
                        onClick={() => {
                            localStorage.setItem("ActiveKeyCheques", "first");
                            setTab('first')

                        }}
                    >
                        <Nav.Link className={tab === 'first' ? 'active' : 'inactive'} eventKey="first">
                            Debit Cheques
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        onClick={() => {
                            localStorage.setItem("ActiveKeyCheques", "second");
                            setTab('second')

                        }}
                    >
                        <Nav.Link className={tab === 'second' ? 'active' : 'inactive'} eventKey="second">
                            Credit Cheques
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>

            <br/>

            {
                tab === 'first' && <DebitChequeBook/>
            }

            {
                tab === 'second' && <CreditChequeBook />
            }

        </div>
    )
}

export default ChequeBook