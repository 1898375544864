import React, { useEffect, useState } from 'react'
import './ListOfCostCenter.scss';
import Button from '../../components/Button/Button';
import { Nav } from 'react-bootstrap';
import Active from '../../components/CostCenter/Active';
import Archived from '../../components/CostCenter/Archived';
import { useNavigate } from 'react-router-dom';

const ListOfCostCenter = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const [tab, setTab] = React.useState('Active')

    const navigate = useNavigate()

    return (
        <div className='list-of-cost-center'>

            <div className="row justify-content-between">
                <div className='col-4 text-left'>
                    <Button navigateTo='/' />
                </div>

                <div className='col-4 text-center'>
                    <button className="list-of-cost-center__btn"
                        onClick={() => {
                            navigate('/finance/addCostCenter')
                        }}
                    >Add Cost Center</button>
                </div>

            </div>

            <br />
            <br />

            <div className="tabs mb-5">
                <Nav>

                    <Nav.Item
                        onClick={() => {
                            setTab('Active')
                        }}
                    >
                        <Nav.Link className={tab === 'Active' ? 'active' : 'inactive'} eventKey="first">
                            Active
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        onClick={() => {
                            setTab("Archived")
                        }}
                    >
                        <Nav.Link className={tab === 'Archived' ? 'active' : 'inactive'} eventKey="second">
                            Archived
                        </Nav.Link>
                    </Nav.Item>

                </Nav>

            </div>

            {tab === 'Active' && <Active />}
            {tab === 'Archived' && <Archived />}

        </div>
    )
}

export default ListOfCostCenter