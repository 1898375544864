import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../utils/config/url";
import { useParams } from "react-router-dom";
import "./SAR.scss";
import Swal from "sweetalert2";
import { Switch, createMuiTheme, ThemeProvider } from "@material-ui/core";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import { useStyles } from "../../utils/ModalStyles";
import { MoonLoader } from "react-spinners";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#EE4B46",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
});

const SAR = () => {
  const classes = useStyles();
  const appId = useParams().applicationId;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [salesDraftLink, setSalesDraftLink] = useState("");
  const [salesDocName, setSalesDocName] = useState("");
  const [salesGeneratedBy, setSalesGeneratedBy] = useState("");
  const [salesGeneratedDate, setSalesGeneratedDate] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [saleAgreementDate, setSaleAgreementDate] = useState("");
  const [reSaleAgreementDate, setReSaleAgreementDate] = useState("");

  const [registerAppointment, setRegisterAppointment] = useState(false);
  const [stampProcurement, setStampProcurement] = useState(false);
  const [agreementPreparation, setAgreementPreparation] = useState(false);
  const [saleAgreement, setSaleAgreement] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [demandID, setDemandId] = useState("");
  const [legalFees, setLegalFees] = useState(false);
  const [customerAppointment, setCustomerAppointment] = useState(false);
  const [saleProposedDate, setSaleProposedDate] = useState("");

  const [loader, setLoader] = useState(false);

  // Date settings

  let today = new Date();

  today.setDate(today.getDate());
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;

  var maxdate = new Date(today);
  maxdate.setDate(maxdate.getDate() + 9);
  var dd2 = maxdate.getDate();
  var mm2 = maxdate.getMonth() + 1;
  var yyyy2 = maxdate.getFullYear();
  if (dd2 < 10) {
    dd2 = "0" + dd2;
  }
  if (mm2 < 10) {
    mm2 = "0" + mm2;
  }

  maxdate = yyyy2 + "-" + mm2 + "-" + dd2;

  const [regSaleLink, setRegSaleLink] = useState("");
  const [regSaleDocName, setRegSaleDocName] = useState("");
  const [regSaleUploadedBy, setRegSaleUploadedBy] = useState("");
  const [file, setFile] = useState("");
  const [regDate, setRegDate] = useState("");
  const [status, setStatus] = useState("");
  const [bookingApplication, setBookingApplication] = useState({});
  const [flag, setFlag] = useState(false);

  const [regSaleAgreementDetails, setRegSaleAgreementDetails] = useState({
    registrationDate: "",
    file: "",
    uploadedByUserFullName: userInfo.userFullName,
    uploadedByUserName: userInfo.userName,
    bookNo: "",
    volumeNo: "",
    pageStart: "",
    pageEnd: "",
    registrationNumber: "",
    year: "",
    registrarName: "",
    registrarPost: "",
    registrarOffice: "",
  });

  useEffect(() => {
    getSaleAgreementDetail();
  }, []);

  const getSaleAgreementDetail = () => {
    axios
      .get(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/getBookingApplicationByApplicationId/${appId}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        //console.log(response)

        let data = response.data.BookingApplication;
        setBookingApplication(data);
        setPaymentMode(data?.bookingPaymentMode);
        setStatus(data.status);

        // sale agreement generation
        if (data.saleAgreementDraft.saleAgreementDraftLink !== null) {
          let d = data.saleAgreementDraft;
          setSalesDraftLink(d.saleAgreementDraftLink);
          setSalesDocName(d.documentName);
          setSalesGeneratedBy(d.generatedByUserFullName);
          let res = dateFormatting(d.generatedDate);
          setSalesGeneratedDate(res);

          let res2 = dateFormatting(
            data?.saleAgreementPrepChecklist.saleAgreementProposedDate
          );
          setSaleAgreementDate(res2);
        } else {
          setSalesDraftLink("");
        }

        //sale agreement checklist

        if (data?.saleAgreementPrepChecklist) {
          let d = data.saleAgreementPrepChecklist;
          setRegisterAppointment(d.registarAppointment);
          setStampProcurement(d.stampPaperProcurement);
          setAgreementPreparation(d.agreementPreparation);
          setSaleAgreement(d.saleAgreementDemandPayment);
          setLegalFees(d.legalFeesPayment);
          setCustomerAppointment(d.customerAppointment);
          d.demandId ? setDemandId(d.demandId) : setDemandId("");

          if (d.demandPaymentDate) {
            let res = dateFormatting(d?.demandPaymentDate);
            setPaymentDate(res);
          } else {
            setPaymentDate("");
          }

          let res = dateFormatting(d.saleAgreementProposedDate, "original");
          setSaleProposedDate(res);
        }

        if (
          data.registeredSaleAgreementScan.RegisteredSaleAgreements3Link !==
          null
        ) {
          let d = data.registeredSaleAgreementScan;
          setRegSaleLink(d.RegisteredSaleAgreements3Link);
          setRegSaleDocName(d.documentName);
          setRegSaleUploadedBy(d.uploadedByUserFullName);
          let res = dateFormatting(d.registrationDate);
          setRegDate(res);
        } else {
          setRegSaleLink("");
        }
      });
  };

  const dateFormatting = (input, type) => {
    let d = new Date(input);
    let date = d.getDate();
    if (date < 10) {
      date = `0${date}`;
    }
    // let m = d.getMonth() + 1;
    const m = d.toLocaleString("default", { month: "long" });
    console.log(m);
    // if (m < 10) {
    //   m = `0${m}`;
    // }
    let y = d.getFullYear();

    let final;

    if (type === "original") {
      final = `${y}-${m}-${date}`;
      return final;
    } else {
      final = `${date} ${m} ${y}`;
      return final;
    }
  };

  const generateSalesAgreementDraft = (e, status) => {
    e.preventDefault();

    let date = status
      ? dateFormatting(reSaleAgreementDate)
      : dateFormatting(saleAgreementDate);
    setReSaleAgreementDate("");

    let body = {
      applicationId: appId,
      currentUserName: userInfo.userName,
      currentUserFullName: userInfo.userFullName,
      saleAgreementProposedDate: date,
    };

    setLoader(true);

    axios
      .post(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/generateSaleAgreementDraft`,
        body,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getSaleAgreementDetail();
            setFlag(true);
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const successModal = (response) => {
    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Checklist updated successfully",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(function () {
        getSaleAgreementDetail();
      });
    }
  };

  const errorModal = (err) => {
    Swal.fire({
      icon: "error",
      title: "Error",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
      text: err.response.data.error,
    });
  };

  const changeChecklist = (e, type) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This Action cannot be Reversd",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#EE4B46",
      cancelButtonColor: "#B6B6B6",
      confirmButtonText: "Yes, update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        updateChecklist(e, type);
      }
    });
  };

  const updateChecklist = (e, type) => {
    let body = {};

    if (type === "Registrar Appointment") {
      body = {
        applicationId: appId,
        saleAgreementPrepChecklist: {
          registarAppointment: registerAppointment === false ? true : false,
        },
      };
    } else if (type === "Customer Appointment") {
      body = {
        applicationId: appId,
        saleAgreementPrepChecklist: {
          customerAppointment: customerAppointment === false ? true : false,
        },
      };
    } else if (type === "Stamp Paper Procurement") {
      body = {
        applicationId: appId,
        saleAgreementPrepChecklist: {
          stampPaperProcurement: stampProcurement === false ? true : false,
        },
      };
    } else if (type === "Agreement Preparation") {
      body = {
        applicationId: appId,
        saleAgreementPrepChecklist: {
          agreementPreparation: agreementPreparation === false ? true : false,
        },
      };
    } else if (type === "Legal Fees Payment") {
      body = {
        applicationId: appId,
        saleAgreementPrepChecklist: {
          legalFeesPayment: legalFees === false ? true : false,
        },
      };
    }

    //  console.log(body)

    axios
      .put(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/saleAgreementPrepChecklist`,
        body,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        successModal(response);
      })
      .catch((err) => {
        errorModal(err);
      });
  };

  const uploadRegisteredSaleAgreement = (e) => {
    e.preventDefault();

    setLoader(true);
    let formData = new FormData();
    formData.append("applicationId", appId);

    for (let key in regSaleAgreementDetails) {
      formData.append(key, regSaleAgreementDetails[key]);
    }

    axios
      .post(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/uploadRegisteredSaleAgreement`,
        formData,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getSaleAgreementDetail();
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const saleAgreementClosure = () => {
    axios
      .post(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/saleAgreementClosure`,
        {
          applicationId: appId,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getSaleAgreementDetail();
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const readyForRegistration = () => {
    // console.log(new Date(saleProposedDate))

    if (new Date(saleProposedDate) === "Invalid Date") {
      return;
    }

    axios
      .post(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/readyForRegistration`,
        {
          applicationId: appId,
          saleAgreementProposedDate: new Date(saleProposedDate),
        },
        {
          headers: {
            Authorization: `bearer ${userInfo.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
          });
          window.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
          });
        }
        window.location.reload();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          text: err.response.data.error,
        });
        window.location.reload();
      });
  };

  return (
    <div className="sar">
      <br />
      <br />

      <div className="row mb-3 mx-2 card-1">
        <div className="col-12 tab-card pt-3 pb-3 text-center">
          {!salesDraftLink ? (
            userInfo?.userRole !== "Operations" &&
            userInfo?.userRole !== "Director" ? (
              <h5
                style={{
                  paddingRight: "10px",
                  marginRight: "5px",
                  fontSize: "22px",
                  fontWeight: "bold",
                  paddingTop: "5px",
                  paddingLeft: "10px",
                }}
              >
                Sale Agreement Draft Not Generated
              </h5>
            ) : (
              <>
                <h4
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Sale Agreement Draft
                </h4>
                <br />
                <form onSubmit={(e) => generateSalesAgreementDraft(e)}>
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="row justify-content-center"
                  >
                    <div className="col-5">
                      <label>Sale Agreement Date</label>
                      <input
                        type={"date"}
                        className="form-control"
                        required
                        value={saleAgreementDate}
                        onChange={(e) => setSaleAgreementDate(e.target.value)}
                      />
                    </div>

                    <div className="col-5">
                      <br />

                      <button
                        className="btn btn-user"
                        type="submit"
                        style={{
                          color: "white",
                        }}
                      >
                        Generate Draft
                      </button>
                    </div>
                  </div>
                </form>
              </>
            )
          ) : (
            <>
              <form
                style={{ display: "flex", flexDirection: "column" }}
                onSubmit={(e) => generateSalesAgreementDraft(e, "reGenerate")}
              >
                <p
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "25px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Sale Agreement Draft
                </p>
                <br />
                <div>
                  <p>
                    <b>Document Name : </b>
                    {salesDocName}
                  </p>
                  <p>
                    <b>Generated By : </b>
                    {salesGeneratedBy}
                  </p>
                  <p>
                    <b>Generate Date : </b>
                    {salesGeneratedDate}
                  </p>
                  <p>
                    <b>Sale Agreement Proposed Date : </b>
                    {saleAgreementDate}
                  </p>
                  <a href={salesDraftLink}>View Document</a>
                </div>

                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="row justify-content-center"
                >
                  <div className="col-5">
                    <label>Sale Agreement Date</label>
                    <input
                      type={"date"}
                      className="form-control"
                      required
                      value={reSaleAgreementDate}
                      onChange={(e) => setReSaleAgreementDate(e.target.value)}
                    />
                  </div>

                  <div className="col-5">
                    <br />

                    <button
                      className="btn btn-user"
                      type="submit"
                      disabled={
                        status === "Ready for Registration" ||
                        status === "Registration Complete" ||
                        status === "Booking Closed"
                      }
                      style={{
                        color: "white",
                        backgroundColor:
                          status === "Ready for Registration" ||
                          status === "Registration Complete" ||
                          status === "Booking Closed"
                            ? "grey"
                            : "#EE4B46",
                      }}
                    >
                      Re-Generate Draft
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </div>

      <br />
      <br />

      <ThemeProvider theme={theme}>
        <div className="row mb-3 mx-2 card-1">
          <div className="col-12 tab-card pt-3 pb-3 text-center">
            <h3>
              <b>Checklist</b>
            </h3>

            <br />
            <br />

            <div className="row justify-content-center">
              <div className="col-6">
                <h5>Agreement Preparation</h5>
              </div>

              <div className="col-6">
                <Switch
                  checked={agreementPreparation}
                  color="primary"
                  disabled={
                    userInfo?.userRole !== "Operations" &&
                    userInfo?.userRole !== "Director"
                  }
                  onChange={(e) =>
                    !agreementPreparation &&
                    changeChecklist(e, "Agreement Preparation")
                  }
                />
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              <div className="col-6">
                <h5> Customer Appointment</h5>
              </div>

              <div className="col-6">
                <Switch
                  checked={customerAppointment}
                  color="primary"
                  disabled={
                    userInfo?.userRole !== "Operations" &&
                    userInfo?.userRole !== "Director"
                  }
                  onChange={(e) =>
                    !customerAppointment &&
                    changeChecklist(e, "Customer Appointment")
                  }
                />
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              <div className="col-6">
                <h5>Legal Fees Payment</h5>
              </div>

              <div className="col-6">
                <Switch
                  checked={legalFees}
                  color="primary"
                  disabled={
                    userInfo?.userRole !== "Operations" &&
                    userInfo?.userRole !== "Director"
                  }
                  onChange={(e) =>
                    !legalFees && changeChecklist(e, "Legal Fees Payment")
                  }
                />
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              <div className="col-6">
                <h5>Stamp Paper Procurement</h5>
              </div>

              <div className="col-6">
                <Switch
                  checked={stampProcurement}
                  color="primary"
                  disabled={
                    userInfo?.userRole !== "Operations" &&
                    userInfo?.userRole !== "Director"
                  }
                  onChange={(e) =>
                    !stampProcurement &&
                    changeChecklist(e, "Stamp Paper Procurement")
                  }
                />
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              <div className="col-6">
                <h5>Registrar Appointment</h5>
              </div>

              <div className="col-6">
                <Switch
                  checked={registerAppointment}
                  color="primary"
                  disabled={
                    userInfo?.userRole !== "Operations" &&
                    userInfo?.userRole !== "Director"
                  }
                  onChange={(e) =>
                    !registerAppointment &&
                    changeChecklist(e, "Registrar Appointment")
                  }
                />
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              <div className="col-6">
                <h5>Sale Agreement Demand Payment</h5>
              </div>

              <div className="col-6">
                <FormControl component="fieldset">
                  <RadioGroup row value={saleAgreement}>
                    <FormControlLabel
                      value="true"
                      control={
                        <Radio
                          sx={{
                            color: "#EE4B46",
                            "&.Mui-checked": {
                              color: "#EE4B46",
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={
                        <Radio
                          sx={{
                            color: "#EE4B46",
                            "&.Mui-checked": {
                              color: "#EE4B46",
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              <div className="col-5">
                <h6>Sale Agreement Demand</h6>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={demandID}
                />
              </div>

              <div className="col-5">
                <h6>Payment Date</h6>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={paymentDate}
                />
              </div>
            </div>

            <br />
          </div>
        </div>
      </ThemeProvider>

      <br />
      <br />

      <div
        style={{ display: "flex", alignItems: "center" }}
        className="row justify-content-center mb-3 mx-2 card-1"
      >
        <div className="col-4">
          <h6>Sale Agreement Proposed Date</h6>
          <input
            type="date"
            className="form-control"
            value={saleProposedDate}
            min={today}
            max={maxdate}
            onChange={(e) => {
              // console.log(e.target.value)
              setSaleProposedDate(e.target.value);
            }}
          />
        </div>
        <div className="col-4">
          <button
            className="btn btn-user"
            type="click"
            disabled={
              (userInfo?.userRole !== "Director" &&
                userInfo?.userRole !== "Operations") ||
              status === "Ready for Registration"
            }
            style={{
              color: "white",
              backgroundColor:
                (userInfo?.userRole !== "Director" &&
                  userInfo?.userRole !== "Operations") ||
                status === "Ready for Registration"
                  ? "grey"
                  : "#EE4B46",
            }}
            onClick={readyForRegistration}
          >
            Ready for Registration
          </button>
        </div>
      </div>

      <br />
      <br />

      <div className="row mb-3 mx-2 card-1">
        <div className="col-12 tab-card pt-3 pb-3 text-center">
          {!regSaleLink ? (
            userInfo?.userRole !== "Operations" &&
            userInfo?.userRole !== "Director" ? (
              <h5
                style={{
                  paddingRight: "10px",
                  marginRight: "5px",
                  fontSize: "22px",
                  fontWeight: "bold",
                  paddingTop: "5px",
                  paddingLeft: "10px",
                }}
              >
                Registered Sale Agreement Not Uploaded
              </h5>
            ) : (
              <>
                <h4
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Registered Sale Agreement
                </h4>
                <br />
                <form onSubmit={(e) => uploadRegisteredSaleAgreement(e)}>
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="row justify-content-center"
                  >
                    <div className="col-4">
                      <label>Registration Date</label>
                      <input
                        type="date"
                        required
                        onChange={(e) =>
                          setRegSaleAgreementDetails({
                            ...regSaleAgreementDetails,
                            registrationDate: e.target.value,
                          })
                        }
                        className="form-control"
                      />
                    </div>

                    <div className="col-4">
                      <input
                        type="file"
                        id="myfile"
                        name="myfile"
                        required
                        onChange={(e) =>
                          setRegSaleAgreementDetails({
                            ...regSaleAgreementDetails,
                            file: e.target.files[0],
                          })
                        }
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                      />
                    </div>
                  </div>

                  <br />
                  {/* from here */}
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="row justify-content-center"
                  >
                    <div className="col-4">
                      <label>Book No.</label>
                      <input
                        type="text"
                        required
                        onChange={(e) =>
                          setRegSaleAgreementDetails({
                            ...regSaleAgreementDetails,
                            bookNo: e.target.value,
                          })
                        }
                        className="form-control"
                      />
                    </div>

                    <div className="col-4">
                      <label>Volumn No.</label>
                      <input
                        type="text"
                        required
                        onChange={(e) =>
                          setRegSaleAgreementDetails({
                            ...regSaleAgreementDetails,
                            volumeNo: e.target.value,
                          })
                        }
                        className="form-control"
                      />
                    </div>
                  </div>

                  <br />

                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="row justify-content-center"
                  >
                    <div className="col-4">
                      <label>Page Start</label>
                      <input
                        type="text"
                        required
                        onChange={(e) =>
                          setRegSaleAgreementDetails({
                            ...regSaleAgreementDetails,
                            pageStart: e.target.value,
                          })
                        }
                        className="form-control"
                      />
                    </div>

                    <div className="col-4">
                      <label>Page End</label>
                      <input
                        type="text"
                        required
                        onChange={(e) =>
                          setRegSaleAgreementDetails({
                            ...regSaleAgreementDetails,
                            pageEnd: e.target.value,
                          })
                        }
                        className="form-control"
                      />
                    </div>
                  </div>

                  <br />

                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="row justify-content-center"
                  >
                    <div className="col-4">
                      <label>Registration Number</label>
                      <input
                        type="text"
                        required
                        onChange={(e) =>
                          setRegSaleAgreementDetails({
                            ...regSaleAgreementDetails,
                            registrationNumber: e.target.value,
                          })
                        }
                        className="form-control"
                      />
                    </div>

                    <div className="col-4">
                      <label>Year</label>
                      <input
                        type="text"
                        required
                        onChange={(e) =>
                          setRegSaleAgreementDetails({
                            ...regSaleAgreementDetails,
                            year: e.target.value,
                          })
                        }
                        className="form-control"
                      />
                    </div>
                  </div>

                  <br />

                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="row justify-content-center"
                  >
                    <div className="col-4">
                      <label>Registrar Name</label>
                      <input
                        type="text"
                        required
                        onChange={(e) =>
                          setRegSaleAgreementDetails({
                            ...regSaleAgreementDetails,
                            registrarName: e.target.value,
                          })
                        }
                        className="form-control"
                      />
                    </div>

                    <div className="col-4">
                      <label>Registrar Post</label>
                      <input
                        type="text"
                        required
                        onChange={(e) =>
                          setRegSaleAgreementDetails({
                            ...regSaleAgreementDetails,
                            registrarPost: e.target.value,
                          })
                        }
                        className="form-control"
                      />
                    </div>

                    <div className="col-4">
                      <label>Registrar Office</label>
                      <input
                        type="text"
                        required
                        onChange={(e) =>
                          setRegSaleAgreementDetails({
                            ...regSaleAgreementDetails,
                            registrarOffice: e.target.value,
                          })
                        }
                        className="form-control"
                      />
                    </div>
                  </div>

                  <br />
                  {/* till here */}

                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="row justify-content-center"
                  >
                    <div className="col-4">
                      <button
                        className="btn btn-user"
                        type="submit"
                        disabled={status !== "Ready for Registration"}
                        style={{
                          color: "white",
                          backgroundColor:
                            status !== "Ready for Registration" && "grey",
                          cursor:
                            status !== "Ready for Registration" && "default",
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </form>
              </>
            )
          ) : (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "25px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Registered Sale Agreement
                </p>
                <br />
                <div>
                  <p>
                    Book No.:{" "}
                    <b>
                      {bookingApplication?.registeredSaleAgreementScan?.bookNo}
                    </b>
                    , Volume No.:{" "}
                    <b>
                      {
                        bookingApplication?.registeredSaleAgreementScan
                          ?.volumeNo
                      }
                    </b>
                    , Pages from{" "}
                    <b>
                      {
                        bookingApplication?.registeredSaleAgreementScan
                          ?.pageStart
                      }
                    </b>{" "}
                    to{" "}
                    <b>
                      {bookingApplication?.registeredSaleAgreementScan?.pageEnd}
                    </b>{" "}
                    Being No.{" "}
                    <b>
                      {
                        bookingApplication?.registeredSaleAgreementScan
                          ?.registrationNumber
                      }
                    </b>{" "}
                    of Year{" "}
                    <b>
                      {bookingApplication?.registeredSaleAgreementScan?.year}
                    </b>
                    <br />
                    <br />
                    Registrar Name:{" "}
                    <b>
                      {
                        bookingApplication?.registeredSaleAgreementScan
                          ?.registrarName
                      }
                    </b>
                    <br />
                    <br />
                    Registrar Post:{" "}
                    <b>
                      {
                        bookingApplication?.registeredSaleAgreementScan
                          ?.registrarPost
                      }
                    </b>
                    <br />
                    <br />
                    Registrar office:{" "}
                    <b>
                      {
                        bookingApplication?.registeredSaleAgreementScan
                          ?.registrarOffice
                      }
                    </b>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <br />

      <div className="row justify-content-center">
        <div className="col-5">
          <button
            className="btn btn-user"
            type="button"
            onClick={saleAgreementClosure}
            disabled={
              status != "Registration Complete" ||
              userInfo?.userName != bookingApplication?.bookingByUserName
            }
            style={{
              color: "white",
              backgroundColor:
                (status != "Registration Complete" ||
                  userInfo?.userName !=
                    bookingApplication?.bookingByUserName) &&
                "grey",
              cursor:
                (status != "Registration Complete" ||
                  userInfo?.userName !=
                    bookingApplication?.bookingByUserName) &&
                "default",
              marginLeft: "50px",
              width: "215px",
            }}
          >
            Sale Agreement Closure
          </button>
        </div>
      </div>

      <br />

      {/* //////////// Modal for spinner //////////// */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={loader}
        onClose={() => {
          setLoader(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={loader}>
          <div>
            <MoonLoader
              color="red"
              className="spinner"
              loading={loader}
              size={70}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default SAR;
