import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button';
import { Form } from 'react-bootstrap';
import './AddCarParking.scss';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import Swal from 'sweetalert2';

const AddCarParking = () => {

  const siteCode = useParams().siteCode;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const Token = 'bearer' + " " + userInfo.token
  const navigate = useNavigate()
  
  const [pn, setPn] = useState(0);
  const [sn, setSn] = useState('')
  const [phases, setPhases] = useState([]);
  const [cpt, setCpt] = useState([])
  const [cptCode, setCptCode] = useState('');

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`,
        { headers: { Authorization: Token } })
      .then((response) => {
        setSn(response.data.site.siteName)
        setPhases(response.data.site.phases)
      })
      .then(() => {
        axios
          .get(`${BASE_URL}/api/v1/Configuration/CarParkingType/getListOfCarParkingType/${siteCode}`,
            { headers: { Authorization: Token } })
          .then((response) => {

            const cptList = response.data.carParkingTypeList.map((cp) => {
              const { carParkingTypeName, carParkingTypeCode } = cp
              return {
                carParkingTypeName: carParkingTypeName,
                carParkingTypeCode: carParkingTypeCode
              }
            })

            setCpt(cptList)
          })
      })
  }, [])

  const addCarParking = (e) => {
    e.preventDefault();

    axios.post(`${BASE_URL}/api/v1/Configuration/CarParking/addCarParking`,
      {
        siteCode: siteCode,
        parkingNumber: pn,
        carParkingTypeCode: cptCode
      },
      { headers: { Authorization: Token } })
      .then((response) => {
        // console.log(response.data.message);
        if(response.status === 200){
        if (response.data.status === true) {
          Swal.fire({
              icon: "success",
              title: "Success",
              text: response.data.message,
              showClass: {
                  popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
              }
          }).then(function () {
              navigate(-1)
          })
      }
      else {
          Swal.fire({
              icon: "error",
              title: "Error",
              showClass: {
                  popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
              },
              text: response.data.message
          })
      }
    }
      })

  }

  return (
    <div className='addcarparking'>
      <Button navigateTo={1} />

      <div className="mt-5 row container-fluid justify-content-center">
        <div className="col-8">
          <h2>Add Car Parking</h2>
        </div>

      </div>
      <br />
      <form onSubmit={(e) => addCarParking(e)}>
        <div className="row container-fluid justify-content-center">
          <div className="col-4">
            <label>Site Name</label>
            <input readOnly value={sn} className='form-control' />
          </div>
          <div className="col-4">
            <Form.Group controlId="unittype">
              <Form.Label>Car Parking Type</Form.Label>
              <Form.Control as="select" required
                onChange={(e) => {
                  cpt.forEach((c) => {
                    if (c.carParkingTypeName === e.target.value) {
                      setCptCode(c.carParkingTypeCode)
                    }
                  })
                }}>

                <option value="">Select a Parking Type</option>
                {cpt.map((c, index) => {
                  return <option key={index} value={c.carParkingTypeName}>{`${c.carParkingTypeName} - ${c.carParkingTypeCode}`}</option>
                })}

              </Form.Control>
            </Form.Group>
          </div>
        </div>
        <br />
        <div className="row container-fluid justify-content-center">
          {/*<div className="col-4">
            <Form.Group controlId="unitphase">
              <Form.Label>Parking Phase</Form.Label>
              <Form.Control as="select" required>
                <option value="">Select a Unit Phase</option>
                {phases.map((p, index) => {
                  return <option key={index} value={p.phaseName}>{p.phaseName}</option>
                })}
              </Form.Control>
            </Form.Group>
              </div> */}

          <div className="col-4">
            <label>Parking Number</label>
            <input type="number" class="form-control" onChange={(e) => setPn(parseInt(e.target.value))} required />
          </div>

        </div>

        <br />
        <br />

        <div className="addcarparking__btns">

          <button type="reset"
            style={{ backgroundColor: "white", color: "black", border: '1px solid black' }}>
            Reset
          </button>

          <button type="submit" >Add Parking </button>

        </div>
      </form>
    </div>
  )
}

export default AddCarParking