import React from 'react'
import Button from '../../Button/Button'
import '../StockManagement.scss';
import { useState } from 'react';
import { BASE_URL } from '../../../utils/config/url';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CreateBrand = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate();

    const [brandCode, setBrandCode] = useState('');
    const [brandName, setBrandName] = useState('');
    const [brandDescription, setBrandDescription] = useState('');
    const [error, setError] = useState(false);

    const createBrand = (e) => {

        e.preventDefault();

        axios.post(`${BASE_URL}/api/v1/stockmgmtapi/brand/createBrand`,
            {
                brandCode,
                brandName,
                brandDescription,
                createdBy: userInfo?.userName,
                createdByFullName: userInfo?.userFullName,
                createdByEmpId: userInfo?.employeeId
            },
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {

                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: 'Form Submitted Successfully',
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        navigate('/listOfBrand')
                    })
                }

            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })

    }


    return (
        <div className='stock-mgmt'>

            <div className='row justify-content-start'>
                <Button navigateTo={1} />
            </div>

            <h2 className='text-center mt-5'>Create Brand</h2>

            <form className='container mt-5'
                onSubmit={(e) => (createBrand(e))}
            >

                <div className='row m-auto container-fluid justify-content-center'>

                    <div className='col-6 text-left'>
                        <label>Brand Code</label>
                        <input type="text" className='form-control'
                            required
                            value={brandCode}
                            onChange={(e) => {
                                let regex = /^[A-Z]{3,4}$/

                                if (regex.test(e.target.value)) {
                                    setError(false)
                                } else {
                                    setError(true)
                                }
                                setBrandCode(e.target.value)
                            }}
                        />
                        {error && <em style={{ color: '#EE4B46' }}>Brand Code Must consist of 3 or 4 Capital Characters</em>}
                    </div>

                    <div className='col-6 text-left'>
                        <label>Brand Name</label>
                        <input type="text" className='form-control'
                            required
                            value={brandName}
                            onChange={(e) => setBrandName(e.target.value)}
                        />
                    </div>

                </div>

                <br />

                <div className='row m-auto container-fluid justify-content-center'>

                    <div className='col-9 text-left'>
                        <label>Description</label>
                        <textarea type="text"
                            className='form-control'
                            required
                            value={brandDescription}
                            onChange={(e) => setBrandDescription(e.target.value)}
                        />
                    </div>

                </div>

                <div className='row justify-content-center mt-5'>
                    <div className='col-6 text-center'>
                        <button className='stock-mgmt__btn'
                            type='submit'
                        >Create</button>
                    </div>
                </div>

            </form>

        </div>
    )
}

export default CreateBrand