import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AssignModal from "../InvLead/AssignModal";
import Select from '@mui/material/Select';
import InputBase from "@mui/material/InputBase";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { styled } from "@mui/material/styles";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: '10px',
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#EE4B46",
    color: "white",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px",
    width: "100"

  }
}));

const LeadList = ({ AllLeads, leads, title, tab, setChange, change, fullAccess }) => {

  const [open, setOpen] = useState(false);
  const [lId, setLId] = useState("");
  const [selectedTeamMember, setSelectedTeamMember] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')
  const [selectedLeadSource, setSelectedLeadSource] = useState('')
  const [teamMembers, setTeamMembers] = useState([])
  const [ListOfLeads, setListOfLeads] = useState([])
  const [openLeads, setOpenLeads] = useState(leads);

  // My open leads variables
  const [status, setStatus] = useState('');

  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    setListOfLeads(AllLeads)
  }, [AllLeads])

  useEffect(() => {
    setOpenLeads(leads)
  }, [leads])

  useEffect(() => {

    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/user/getListOfUsers?isActive=true`,
        { headers: { Authorization: `bearer ${userInfo.token}` } })

      const userList = res.data.userList

      const list = []
      userList?.forEach((user) => {
        list.push(user)
      })
      setTeamMembers(list)
    }
    )()

  }, [])

  const handleFilter = (e, filtername) => {
    e.preventDefault()

    let user = selectedTeamMember;
    let status = selectedStatus;
    let source = selectedLeadSource;

    if (filtername === 'team member') {
      setSelectedTeamMember(e.target.value)
      user = e.target.value
      // getFilteredList(e.target.value, '')
    } else if (filtername === 'status') {
      setSelectedStatus(e.target.value)
      status = e.target.value

    } else {
      setSelectedLeadSource(e.target.value)
      source = e.target.value
    }

    // console.log(user , status , source)

    if (user || status || source) {
      axios.get(`${BASE_URL}/api/v1/leadmgmt/lead/getListOfLeads?assigneeUserName=${user}&leadStatus=${status}&leadSource=${source}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } })
        .then((response) => {
          // console.log(response)
          setListOfLeads(response.data.leads)
        })
    } else {
      axios.get(`${BASE_URL}/api/v1/leadmgmt/lead/getListOfLeads`,
        { headers: { Authorization: `bearer ${userInfo.token}` } })
        .then((response) => {
          //console.log(response)
          setListOfLeads(response?.data?.leads)
        })
    }

  }

  const handleMyOpenLeadsFilter = async (e) => {
    setStatus(e.target.value)

    const res = await axios.get(`${BASE_URL}/api/v1/leadmgmt/lead/getListOfLeads?assigneeUserName=${userInfo.userName}&leadStatus=${e.target.value}`,
      { headers: { Authorization: `bearer ${userInfo.token}` } });
    const data = res.data.leads;
    setOpenLeads(data);
  }


  const dateFormatting = (date) => {

    let str = new Date(date)

    let d = new Date(str).getDate();
    if (d < 10) {
      d = `0${d}`
    }
    let m = new Date(str).getMonth() + 1;
    if (m < 10) {
      m = `0${m}`
    }
    let y = new Date(str).getFullYear();

    let h = new Date(str).getHours()
    if (h < 10) {
      h = `0${h}`
    }
    let min = new Date(str).getMinutes()
    if (min < 10) {
      min = `0${min}`
    }
    // console.log(h , min)
    return `${d}-${m}-${y},${h}:${min}`
  }


  return (
    <div className="mt-5">
      {/* <h4>{title}</h4> */}
      <div>
        <br />
        {tab === "al" && (

          <>

            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                marginBottom: '20px',
                marginTop: '-50px'
              }}>

              <FormControl sx={{ m: 1, minWidth: 185, marginBottom: '10px' }}>

                <InputLabel id="demo-simple-select-helper-label" style={{ color: 'black', fontSize: '15px', paddingTop: '5px' }}>
                  Team Members <FilterAltRoundedIcon style={{ marginLeft: '10px' }}
                  />
                </InputLabel>

                <Select
                  value={selectedTeamMember}
                  input={<BootstrapInput />}
                  onChange={(e) => handleFilter(e, 'team member')}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {teamMembers?.map((m, index) => {
                    return <MenuItem value={m.userName} key={index}>{m.userFullName}</MenuItem>
                  })}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, minWidth: 140, marginBottom: '10px' }}>

                <InputLabel id="demo-simple-select-helper-label" style={{ color: 'black', fontSize: '15px', paddingTop: '5px' }}>
                  Status <FilterAltRoundedIcon style={{ marginLeft: '10px' }}
                  />
                </InputLabel>

                <Select
                  value={selectedStatus}
                  input={<BootstrapInput />}
                  onChange={(e) => handleFilter(e, 'status')}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='NewLead'>New Lead</MenuItem>
                  <MenuItem value='Assigned'>Assigned</MenuItem>
                  <MenuItem value='InFollowUp'>In FollowUp</MenuItem>
                  <MenuItem value='InDiscussion'>In Discussion</MenuItem>
                  <MenuItem value='Closed-Converted'>Closed-Converted</MenuItem>
                  <MenuItem value='Closed-NoResponse'>Closed-NoResponse</MenuItem>
                  <MenuItem value='Closed-WrongLead'>Closed-WrongLead</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, minWidth: 170, marginBottom: '10px' }}>

                <InputLabel id="demo-simple-select-helper-label" style={{ color: 'black', fontSize: '15px', paddingTop: '5px' }}>
                  Lead Source <FilterAltRoundedIcon style={{ marginLeft: '10px' }}
                  />
                </InputLabel>

                <Select
                  value={selectedLeadSource}
                  input={<BootstrapInput />}
                  onChange={(e) => handleFilter(e, 'lead source')}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="99Acres">99Acres</MenuItem>
                  <MenuItem value="Newspaper">Newspaper</MenuItem>
                  <MenuItem value="Hoarding">Hoarding</MenuItem>
                  <MenuItem value="Website">Website</MenuItem>
                  <MenuItem value="Facebook">Facebook</MenuItem>
                  <MenuItem value="Referral">Referral</MenuItem>
                  <MenuItem value="Walk In">Walk In</MenuItem>
                  <MenuItem value="Real Estate Broker">Real Estate Broker</MenuItem>
                </Select>
              </FormControl>

            </div>

            <MaterialTable
              data={ListOfLeads}
              title="Leads"
              columns={[
                { title: "Lead Id", field: "leadID", defaultSort: 'desc' },
                { title: "Name", field: "name" },
                { title: "Source", field: "leadSource" },
                { title: "Phone No", field: "phone" },
                {
                  title: "Creation Date",
                  render: (rowData) => {
                    let res = '';
                    if (rowData.creationdate) {
                      res = dateFormatting(rowData.creationdate)
                    }

                    return !rowData.creationdate ? "" : res
                  },
                  customSort: (a, b) => (a.creationdate < b.creationdate ? -1 : 1),
                },
                {
                  title: "Updated At",
                  defaultSort: "desc",
                  render: (rowData) => {
                    let res = '';
                    if (rowData.updatedAt) {
                      res = dateFormatting(rowData.updatedAt)
                    }

                    return !rowData.updatedAt ? "" : res
                  },
                  customSort: (a, b) => (a.updatedAt < b.updatedAt ? -1 : 1),
                },
                {
                  title: "BHK",
                  field: 'bhk'
                  // render: (rowData) => {
                  //   let string = rowData.bhk.join(' , ');
                  //   return string;
                  // }
                },
                {
                  title: 'Site',
                  render: (rowData) => {
                    return rowData?.site?.siteName || 'N/A'
                  }
                },
                { title: "Lead Type", field: "leadWeightage" },
                { title: "Lead Status", field: "leadStatus" },
                {
                  title: "Assigned To",
                  field: "assigneeUserFullName",
                },
              ]}
              options={{
                search: true,
                searchFieldStyle: {
                  width: 180
                },
                paging: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 20, 30],
                headerStyle: {
                  backgroundColor: "#EE4B46",
                  color: "#fff",
                },
              }}
              actions={[
                {
                  icon: "remove_red_eye",
                  tooltip: "Edit Lead",
                  onClick: (event, rowData) => {
                    navigate(`/individuallead/${rowData.leadID}`);
                  },
                },
              ]}
            />
          </>
        )}


        {tab === "ul" && (
          <MaterialTable
            data={leads}
            title="Leads"
            columns={[
              { title: "Lead Id", field: "leadID" },
              {
                title: "Date",
                render: (rowData) => (dateFormatting(rowData?.createdAt)),
                defaultSort: 'desc',
                customSort: (a, b) => (a.createdAt < b.createdAt ? -1 : 1),
              },
              { title: "Name", field: "name" },
              { title: "Source", field: "leadSource" },
              {
                title: "BHK",
                field: 'bhk'
                // render: (rowData) => {
                //   let string = rowData.bhk.join(' , ');
                //   return string;
                // }
              },
              {
                title: 'Site',
                render: (rowData) => {
                  return rowData?.site?.siteName
                }
              },
            ]}
            options={{
              search: true,
              searchFieldStyle: {
                width: 180
              },
              paging: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 20, 30],
              headerStyle: {
                backgroundColor: "#EE4B46",
                color: "#fff",
              },
            }}
            actions={[
              {
                icon: "remove_red_eye",
                tooltip: "Edit Lead",
                disabled: userInfo.userRole === "ITAdmin" || userInfo.userRole === "Director" || fullAccess ? false : true,
                onClick: (event, rowData) => {
                  navigate(`/individuallead/${rowData.leadID}`);
                },
              },
              {
                icon: "edit",
                tooltip: "Assign Lead",
                hidden: (userInfo.userRole === "Director" || fullAccess) ? false : true,
                onClick: (event, rowData) => {
                  setOpen(true);
                  setLId(rowData.leadID);
                },
              },
            ]}
          />
        )}


        {tab === "cl" && (
          <MaterialTable
            data={leads}
            title="Leads"
            columns={[
              { title: "Lead Id", field: "leadID" },
              { title: "Name", field: "name" },
              { title: "Source", field: "leadSource" },
              { title: "Phone No", field: "phone" },
              {
                title: "Creation Date",
                defaultSort: "desc",
                render: (rowData) => {
                  let res = '';
                  if (rowData.creationdate) {
                    res = dateFormatting(rowData.creationdate)
                  }

                  return !rowData.creationdate ? "" : res
                },
                customSort: (a, b) => (a.creationdate < b.creationdate ? -1 : 1),
              },
              {
                title: "Updated At",
                defaultSort: "desc",
                render: (rowData) => {
                  let res = '';
                  if (rowData.updatedAt) {
                    res = dateFormatting(rowData.updatedAt)
                  }

                  return !rowData.updatedAt ? "" : res
                },
                customSort: (a, b) => (a.updatedAt < b.updatedAt ? -1 : 1),
              },
              { title: "Closure Reason", field: "closureReason" },
              {
                title: "Closure Date",
                defaultSort: "desc",
                render: (rowData) => {
                  let res = '';
                  if (rowData.closureDate) {
                    res = dateFormatting(rowData.closureDate)
                  }

                  return !rowData.closureDate ? "" : res
                },
                defaultSort: 'desc',
                customSort: (a, b) => (a.closureDate < b.closureDate ? -1 : 1),
              },
              {
                title: "BHK",
                field: 'bhk'
                // render: (rowData) => {
                //   let string = rowData.bhk.join(' , ');
                //   return string;
                // }
              },
              {
                title: 'Site',
                render: (rowData) => {
                  return rowData.site.siteName
                }
              },
              { title: "Lead Type", field: "leadWeightage" },
              { title: "Lead Status", field: "leadStatus" },
              {
                title: "Assigned To",
                field: "assigneeUserFullName",
              },
            ]}
            options={{
              search: true,
              searchFieldStyle: {
                width: 180
              },
              paging: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 20, 30],
              headerStyle: {
                backgroundColor: "#EE4B46",
                color: "#fff",
              },
            }}
            actions={[
              {
                icon: "remove_red_eye",
                tooltip: "Edit Lead",
                onClick: (event, rowData) => {
                  navigate(`/individuallead/${rowData.leadID}`);
                },
              },
            ]}
          />
        )}


        {tab === "ol" && (

          <>

            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                marginBottom: '20px',
                marginTop: '-50px'
              }}>

              <FormControl sx={{ m: 1, minWidth: 140, marginBottom: '10px' }}>

                <InputLabel id="demo-simple-select-helper-label" style={{ color: 'black', fontSize: '15px', paddingTop: '5px' }}>
                  Status <FilterAltRoundedIcon style={{ marginLeft: '10px' }}
                  />
                </InputLabel>

                <Select
                  // value={status}
                  defaultValue={'Assigned'}
                  input={<BootstrapInput />}
                  onChange={(e) => handleMyOpenLeadsFilter(e)}
                >
                  {/* <MenuItem value="">
                    <em>None</em>
                  </MenuItem> */}
                  <MenuItem value='Assigned'>Assigned</MenuItem>
                  <MenuItem value='InFollowUp'>In Follow Up</MenuItem>
                </Select>
              </FormControl>

            </div>


            <MaterialTable
              data={openLeads}
              title="Leads"
              columns={[
                { title: "Lead Id", field: "leadID" },
                { title: "Name", field: "name" },
                { title: "Source", field: "leadSource" },
                { title: "Phone No", field: "phone" },
                {
                  title: "Creation Date",
                  render: (rowData) => {
                    let res = '';
                    if (rowData.creationdate) {
                      res = dateFormatting(rowData.creationdate)
                    }

                    return !rowData.creationdate ? "" : res
                  },
                  defaultSort: "desc",
                  customSort: (a, b) => (a.creationdate < b.creationdate ? -1 : 1),
                },
                {
                  title: "Updated At",
                  render: (rowData) => {
                    let res = '';
                    if (rowData.updatedAt) {
                      res = dateFormatting(rowData.updatedAt)
                    }

                    return !rowData.updatedAt ? "" : res
                  },
                  // defaultSort: 'desc',
                  // customSort: (a, b) => (a.updatedAt < b.updatedAt ? -1 : 1),
                },
                {
                  title: "Next FollowUp Date",
                  render: (rowData) => {
                    let res = '';
                    if (rowData.updatedAt) {
                      res = dateFormatting(rowData.nextFollowUpDate)
                    }

                    return !rowData.nextFollowUpDate ? "" : res
                  },
                  // customSort: (a, b) => (a.updatedAt < b.updatedAt ? -1 : 1),
                },
                {
                  title: "BHK",
                  field: 'bhk'
                },
                {
                  title: 'Site',
                  render: (rowData) => {
                    return rowData.site.siteName
                  }
                },
                { title: "Lead Type", field: "leadWeightage" },
                { title: "Lead Status", field: "leadStatus" },
                {
                  title: "Assigned To",
                  field: "assigneeUserFullName"
                },
              ]}
              options={{
                search: true,
                searchFieldStyle: {
                  width: 180
                },
                paging: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 20, 30],
                headerStyle: {
                  backgroundColor: "#EE4B46",
                  color: "#fff",
                },
              }}
              actions={[
                {
                  icon: "remove_red_eye",
                  tooltip: "Edit Lead",
                  onClick: (event, rowData) => {
                    navigate(`/individuallead/${rowData.leadID}`);
                  },
                },
              ]}
            />
          </>
        )}

        {tab === "idl" && (

          <MaterialTable
            data={leads}
            title="Leads"
            columns={[
              { title: "Lead Id", field: "leadID" },
              { title: "Name", field: "name" },
              { title: "Source", field: "leadSource" },
              { title: "Phone No", field: "phone" },
              {
                title: "Creation Date",
                defaultSort: "desc",
                render: (rowData) => {
                  let res = '';
                  if (rowData.creationdate) {
                    res = dateFormatting(rowData.creationdate)
                  }

                  return !rowData.creationdate ? "" : res
                },
                customSort: (a, b) => (a.creationdate < b.creationdate ? -1 : 1),
              },
              {
                title: "Updated At",
                defaultSort: "desc",
                render: (rowData) => {
                  let res = '';
                  if (rowData.updatedAt) {
                    res = dateFormatting(rowData.updatedAt)
                  }

                  return !rowData.updatedAt ? "" : res
                },
                defaultSort: 'desc',
                customSort: (a, b) => (a.updatedAt < b.updatedAt ? -1 : 1),
              },
              {
                title: "BHK",
                field: 'bhk'
                // render: (rowData) => {
                //   let string = rowData.bhk.join(' , ');
                //   return string;
                // }
              },
              {
                title: 'Site',
                render: (rowData) => {
                  return rowData.site.siteName
                }
              },
              { title: "Lead Type", field: "leadWeightage" },
              { title: "Lead Status", field: "leadStatus" },
              {
                title: "Assigned To",
                field: "assigneeUserFullName"
              },
            ]}
            options={{
              search: true,
              searchFieldStyle: {
                width: 180
              },
              paging: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 20, 30],
              headerStyle: {
                backgroundColor: "#EE4B46",
                color: "#fff",
              },
            }}
            actions={[
              {
                icon: "remove_red_eye",
                tooltip: "Edit Lead",
                onClick: (event, rowData) => {
                  navigate(`/individuallead/${rowData.leadID}`);
                },
              },
            ]}
          />

        )}


        <AssignModal open={open} setOpen={setOpen} lId={lId} setChange={setChange}></AssignModal>
      </div>
    </div>
  );
};

export default LeadList;
