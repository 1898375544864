import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../utils/config/url";

import "../../../index.scss";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

const DeditTransactions = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [debit, setDebit] = useState([]);
  const [financialYear, setFinancialYear] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [disclaimer, setDisclaimer] = useState(
    " If date range not selected, table shows Transaction Entry for last 90 days only"
  );
  const [disclaimer2, setDisclaimer2] = useState("");


  useEffect(() => {
    getListOfDeditTransaction();
  }, [financialYear , paymentMode , startDate , endDate]);

  const getListOfDeditTransaction = () => {

    let url = `${BASE_URL}/api/v1/FinanceMgmt/transaction/getListOfDebitTransactionEntry?`;

    if (financialYear != "") {
      url += `finYear=${financialYear}&`;
    }

    if (paymentMode != "") {
      url += `transactionMode=${paymentMode}&`;
    }

    if (startDate != "") {
      url += `transStartDate=${startDate}&`;
    }

    if (endDate != "") {
      url += `transEndDate=${endDate}&`;
    }

    if (startDate != "" && endDate == "") {
      return setDisclaimer2("Select an End Date");
    }else if(startDate == "" && endDate != ""){
      return setDisclaimer2("Select a Start Date");
    }else if(startDate != "" && endDate != ""){
      setDisclaimer2("");
    }

    axios
      .get(
        url,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        // console.log(response);
        setDebit(response.data.DebitTransaction);
      });
  };

  const reset = () => {
    setFinancialYear("");
    setPaymentMode("");
    setEndDate("");
    setStartDate("");
    setDisclaimer2("");
  };

  // const filterByFinancialYear = (arr) => {
  //   if (financialYear === "") {
  //     return arr;
  //   } else {
  //     return arr.filter((item) => {
  //       console.log("Year", item.finYear, financialYear);
  //       return item.finYear === financialYear;
  //     });
  //   }
  // };

  // const filterByPaymentMode = (arr) => {
  //   if (paymentMode === "") {
  //     return arr;
  //   } else {
  //     return arr.filter((item) => item.transactionMode === paymentMode);
  //   }
  // };


  // const filters = [filterByFinancialYear, filterByPaymentMode];
  // const debitData = filters.reduce((acc, cur) => cur(acc), debit);

  return (
    <div className="credit">
      <MaterialTable
        data={debit}
        title=""
        columns={[
          { title: "Transaction ID", field: "dbtTransId" },

          {
            title: "Transaction Date",
            render: (rowData) => {
              return !rowData.dbtTransDate
                ? ""
                : rowData.dbtTransDate.substring(8, 10) +
                "-" +
                rowData.dbtTransDate.substring(5, 7) +
                "-" +
                rowData.dbtTransDate.substring(0, 4);
            },
            customSort: (a, b) => (a.dbtTransDate < b.dbtTransDate ? -1 : 1),
          },
          { title: "Financial Year", field: "finYear" },
          {
            title: "Cost Center",
            field: "costCenterCode",
          },
          { title: "Payment Mode", field: "transactionMode" },
          {
            title: "Payment ID",
            render: (rowData) => {
              return rowData.transactionMode === "Cash"
                ? rowData.cashPaymentId
                : rowData.transactionMode === "Bank Transfer"
                  ? rowData.bankTransferId
                  : rowData.transactionMode === "Cheque"
                    ? rowData.chequeNo
                    : rowData.transactionMode === "Demand Draft"
                      ? rowData.demandDraftNo
                      : rowData.transactionMode === "Payment Voucher"
                        ? rowData.paymentVoucherId
                        : "";
            },
          },
          { title: "Amount", field: "dbtTransactionAmount" },
        ]}
        options={{
          search: window.innerWidth < 700 ? false : true,
          searchFieldStyle: {
            width: 180,
          },
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          actionsColumnIndex: 0,
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
          },
        }}
        components={{
          Toolbar: (props) => (
            <div className="filters text-left">
              <MTableToolbar {...props} />
              <FormControl
                variant="standard"
                style={{
                  margin: "0px 10px",
                  marginTop: "-65px",
                  width: "150px",
                }}
              >
                <InputLabel>Financial Year</InputLabel>
                <Select
                  style={{
                    fontSize: "13px",
                    textAlign: "left",
                  }}
                  value={financialYear}
                  onChange={(e) => setFinancialYear(e.target.value)}
                >
                  <MenuItem value="">Financial Year</MenuItem>
                  <MenuItem value="2023-24">2023-24</MenuItem>
                  <MenuItem value="2022-23">2022-23</MenuItem>
                  <MenuItem value="2021-22">2021-22</MenuItem>
                  <MenuItem value="2020-21">2020-21</MenuItem>
                  <MenuItem value="2019-20">2019-20</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                variant="standard"
                style={{
                  margin: "0px 10px",
                  marginTop: "-65px",
                  width: "150px",
                }}
              >
                <InputLabel>Payment Mode</InputLabel>
                <Select
                  style={{
                    fontSize: "13px",
                    textAlign: "left",
                  }}
                  value={paymentMode}
                  onChange={(e) => setPaymentMode(e.target.value)}
                >
                  <MenuItem value="">Payment Mode</MenuItem>
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                  <MenuItem value="Cheque">Cheque</MenuItem>
                  <MenuItem value="DemantDraft">Demand Draft</MenuItem>
                  <MenuItem value="Payment Voucher">Payment Voucher</MenuItem>
                </Select>
              </FormControl>


              {window.innerWidth < 1100 && <br />}

              <FormControl
                className="formControl"
                style={{
                  margin: "0px 10px",
                  marginTop: window.innerWidth < 1100 ? "-35px" : "-60px",
                  // width: '10vw',
                }}
              >
                <label
                  style={{
                    margin: "0",
                    fontSize: "12px",
                    textAlign: "left",
                    color: "grey",
                  }}
                >
                  Start Date
                </label>
                <input
                  type="date"
                  style={{
                    border: "2px solid lightgrey",
                    padding: "0px 10px",
                    fontSize: "12px",
                  }}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </FormControl>

              <FormControl
                className="formControl"
                style={{
                  margin: "0px 10px",
                  marginTop: window.innerWidth < 1100 ? "-35px" : "-60px",
                  width: "120px",
                }}
              >
                <label
                  style={{
                    margin: "0",
                    fontSize: "12px",
                    textAlign: "left",
                    color: "grey",
                  }}
                >
                  End Date
                </label>
                <input
                  type="date"
                  style={{
                    border: "2px solid lightgrey",
                    padding: "0px 10px",
                    fontSize: "12px",
                  }}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </FormControl>

              {window.innerWidth < 1200 && (
                <>
                  <br /> <br /> <br />
                </>
              )}


              <FormControl
                className="formControl"
                style={{
                  marginTop: "-50px",
                  marginRight: "110px",
                  marginLeft: "10px",
                }}
              >
                <button
                  onClick={reset}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                    borderRadius: "20px",
                    padding: "2px 7px",
                  }}
                >
                  Reset Filter
                </button>
              </FormControl>

              <p
                style={{
                  marginLeft: "10px",
                  marginTop: "-15px",
                  color: "#EE4B46",
                }}
              >
                <em>{disclaimer}</em>
              </p>

              <p
                style={{
                  marginLeft: "10px",
                  marginTop: "-10px",
                  color: "#EE4B46",
                }}
              >
                <em>{disclaimer2}</em>
              </p>

            </div>
          ),
        }}
      ></MaterialTable>
    </div>
  );
};

export default DeditTransactions;
