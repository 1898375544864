import { Button } from '@material-ui/core';
import axios from 'axios';
import Swal from 'sweetalert2'
import React, { useEffect, useState, useRef } from 'react';
import Default_img from '../../../assets/images/default_user.png';
import { BsCameraFill } from 'react-icons/bs'
import { BASE_URL } from '../../../utils/config/url';
import './Details.scss'
import UserInfo from './UserInfo';
import getCroppedImg from '../../../utils/CropImage/CropImage';
import { Form } from 'react-bootstrap';

const Details = () => {

    const [user, setUser] = useState({});
    const [img, setImg] = useState(null);
    const [preview, setPreview] = useState('');
    const [update, setUpdate] = useState(false);
    const [action, setAction] = useState(false);
    const [change, setChange] = useState(false);


    const gallaryRef = useRef(null);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) u8arr[n] = bstr.charCodeAt(n);
        return new File([u8arr], filename, { type: mime });
    };

    // get user details
    useEffect(() => {
        (async () => {
            const res = await axios.get(`${BASE_URL}/api/v1/user/getUserByUserName/${userInfo.userName}`,
                { headers: { Authorization: `bearer ${userInfo.token}` } });
            const data = await res.data.user;
            // console.log(data)
            setUser(data);
        })();
    }, [update])

    // image preview
    useEffect(() => {
        if (img) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            }
            reader.readAsDataURL(img);
        } else {
            setPreview(null)
        }
    }, [img])

    const handleImgUpload = async () => {

        if (img) {
            // console.log(img)
            // const canvas = await getCroppedImg(img, { width: 450, height: 450, x: 0, y: 0 });

            // const canvasDataURL = canvas.toDataURL("image/jpeg");
            // const convertedURLtoFile = dataURLtoFile(
            //     canvasDataURL,
            //     "cropped-image.jpeg"
            // );

            //  console.log(convertedURLtoFile)
            const data = new FormData();
            data.append('file', img);
            data.append('type', 'userImage');
            data.append('userName', userInfo.userName);
            //console.log(data)
            // console.log(userInfo.token)
            await axios.post(`${BASE_URL}/api/v1/util/uploadUserPhoto`,
                data,
                { headers: { Authorization: `bearer ${userInfo.token}` } })

                .then(res => {
                  //  console.log(res)
                    Swal.fire({
                        icon: 'success',
                        title: 'Image Uploaded Successfully!',
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setAction(false);
                    setChange(false);
                    setUpdate(!update);
                    
                })
                .then(()=>{
                    window.location.reload()
                })
        } else {
            Swal.fire({
                icon: 'warning',
                title: "Please Select an Image"
            })
        }
    }

    const handleClear = () => {
        if (!img) {
            Swal.fire({
                icon: 'warning',
                title: "Please Select an Image"
            })
        } else {
            setImg(null);
        }
    }

    const handleRemove = () => {
        setImg(null)
    }
    return (
        <div className='user_details'>
            <div onClick={() => {
                setAction(!action);
                setChange(false);
            }} className='user_img'>
                <img className='img-fluid' src={preview || user?.userPhoto || Default_img} alt="user avatar" />
                <div className='camera_icon'>
                    <BsCameraFill />
                </div>
            </div>
            {/* image actions  */}
            {action && <div className='d-flex align-items-center justify-content-center mb-3'>
                <Button onClick={() => setChange(!change)} variant="contained">
                    Change
                </Button>
                <Button onClick={handleRemove} className="rmv_btn" variant="contained">
                    Remove
                </Button>
            </div>}
            {/* image actions  */}
            {change && <div className='d-flex align-items-center justify-content-center mb-3'>
                <Button onClick={handleClear} variant="contained">
                    CLEAR
                </Button>
                <Button onClick={() => gallaryRef.current.click()} className="ml-2" variant="contained">
                    CHOOSE
                </Button>
                <Button onClick={handleImgUpload} className="ml-2" variant="contained">
                    UPLOAD
                </Button>
                {/* input for image upload  */}
                <Form.Control
                    className="d-none"
                    ref={gallaryRef}
                    type="file"
                    accept="image/*"
                    onChange={(e) => setImg(e.target.files[0])}
                />
            </div>}
            {/* user info  */}
            <div className='d-flex justify-content-center'>
                <div className='details'>
                    <div className="user_info">
                        <label>User Name</label>
                        <Form.Control type="text" value={user.userName} disabled />
                    </div>
                    <div className="user_info">
                        <label>User Full Name</label>
                        <Form.Control type="text" value={user.userFullName} disabled />
                    </div>
                    <div className="user_info">
                        <label>Mobile</label>
                        <Form.Control type="text" value={user.userOfficialMobile} disabled />
                    </div>
                    <div className="user_info">
                        <label>Email</label>
                        <Form.Control type="text" value={user.userOfficialEmail} disabled />
                    </div>
                </div>
            </div>
            <UserInfo
                userAuth={userInfo}
                setUpdate={setUpdate}
                update={update}
            />
        </div>
    );
};

export default Details;