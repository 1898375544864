import React, { useEffect, useState } from "react";
import "./Quotations.scss";
import MaterialTable, { MTableToolbar } from "material-table";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import { dateFormatting } from "../../utils/dateFormatting";

const PendingApproval = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [siteList, setSiteList] = useState([]);
  const [site, setSite] = useState("");
  const [phase, setPhase] = useState("");
  const [phaseList, setPhaseList] = useState([]);
  const [QuotationList, setQuotationList] = useState([]);
  const [quotationId, setQuotationId] = useState("");

  useEffect(() => {
    let url = `${BASE_URL}/api/v1/leadmgmt/quotation/getListOfQuotation?isFinalRequested=true&isFinalApproved=NA&finalApproverEmpId=${userInfo?.employeeId}`;

    if (site) {
      url += `&siteCode=${site}`;
    }

    if (phase) {
      url += `&phaseCode=${phase}`;
    }

    axios
      .get(url, {
        headers: { Authorization: `bearer ${userInfo.token} ` },
      })
      .then((response) => {
        
        setQuotationList(response.data.quotations);
      });
  }, [site, phase]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/Configuration/site/getAllSiteNames`, {
        headers: { Authorization: `bearer ${userInfo.token} ` },
      })
      .then((response) => {
        if (response.data.status) {
          setSiteList(response.data.siteList);
        } else {
          setSiteList([]);
        }
      });
  }, []);

  useEffect(() => {
    if (!site) {
      setPhase("");
      return;
    }

    axios
      .get(`${BASE_URL}/api/v1/Configuration/site/getSiteBySiteCode/${site}`, {
        headers: { Authorization: `bearer ${userInfo.token} ` },
      })
      .then((response) => {
        // console.log(response)
        if (response.data.status === true) {
          setPhaseList(response.data.site.phases);
        } else {
          setPhaseList([]);
        }
      });
  }, [site]);

  ///approved quotation
  const handleApprove = (quotationId) => {
    Swal.fire({
      title: "Are you sure you want to approve this quotation?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${BASE_URL}/api/v1/leadmgmt/quotation/markQuotationFinal`,
            {
              quotationId: quotationId,
              isApproved: true,
              userName: userInfo.userName,
              userFullName: userInfo.userFullName,
            },
            { headers: { Authorization: `bearer ${userInfo.token}` } }
          )
          .then((response) => {
            if (response.data.status) {
              Swal.fire({
                icon: "success",
                title: "Approved Quotation Successfully!",
                timer: 1900,
                showConfirmButton: false,
              });
              // .then(() => {
              //     setChange((prev) => !prev);
              //     setApproved(true)
              // })
            } else {
              Swal.fire({
                icon: "warning",
                title: "Oops!! there is an error in approving quotation",
                timer: 5000,
                showConfirmButton: false,
              });
            }
          })
          .catch((error) => {
            // console.log(error);
            Swal.fire({
              icon: "warning",
              title: "Oops!! there is an error in approving quotation",
              timer: 1900,
              showConfirmButton: false,
            });
          })
          .finally(() => {
            window.location.reload();
            
          });
      }
    });
  };

  ///reject qoutation
  const handleReject = (quotationId) => {
    Swal.fire({
      title: "Are you sure you want to reject this quotation?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${BASE_URL}/api/v1/leadmgmt/quotation/markQuotationFinal`,
            {
              quotationId: quotationId,
              isApproved: false,
              userName: userInfo.userName,
              userFullName: userInfo.userFullName,
            },
            { headers: { Authorization: `bearer ${userInfo.token}` } }
          )
          .then((response) => {
            if (response.data.status) {
              Swal.fire({
                icon: "success",
                title: "Rejected Quotation Successfully!",
                timer: 1900,
                showConfirmButton: false,
              });
              // setChange((prev) => !prev);
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                timer: 1900,
                showConfirmButton: false,
              });
            }
          })
          .catch((error) => {
           
            Swal.fire({
              icon: "error",
              title: "Error",
              timer: 1900,
              showConfirmButton: false,
              text: error.response.data.error,
            });
          })
          .finally(() => {
            window.location.reload();
          });
      }
    });
  };

  return (
    <div className="quotation-list">
      <MaterialTable
        data={QuotationList}
        title="All Quotations"
        columns={[
          {
            title: "Quotation ID",
            field: "quotationId",
          },
          {
            title: "Lead ID",
            field: "leadID",
          },
          {
            title: "Unit Name",
            render: (rowData) => rowData?.unit?.unitName,
            customSort: (a, b) =>
              a?.unit?.unitName < b?.unit?.unitName ? -1 : 1,
          },
          {
            title: "BHK",
            render: (rowData) => rowData?.unit?.bhk,
            customSort: (a, b) => (a?.unit?.bhk < b?.unit?.bhk ? -1 : 1),
          },
          {
            title: "Quotation Date",
            render: (rowData) => {
              let res = "";

              if (rowData.quotationDate) {
                return (res = dateFormatting(rowData.quotationDate));
              } else {
                return "";
              }
            },
            customSort: (a, b) => (a.quotationDate < b.quotationDate ? -1 : 1),
          },
          {
            title: "Generated By",
            field: "qtnGeneratedBy",
          },
          {
            title: "Site Name",
            render: (rowData) => rowData?.siteDetails?.siteName,
            customSort: (a, b) =>
              a?.siteDetails?.siteName < b?.siteDetails?.siteName ? -1 : 1,
          },
          {
            title: "Phase Name",
            render: (rowData) => rowData?.siteDetails?.phaseName,
            customSort: (a, b) =>
              a?.siteDetails?.phaseName < b?.siteDetails?.phaseName ? -1 : 1,
          },
          {
            title: "Requested By",
            field: "isFinalRequestedByFullName",
          },
        ]}
        options={{
          search: window.innerWidth < 700 ? false : true,
          searchFieldStyle: {
            width: 180,
          },
          actionsColumnIndex: 0,
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
            textAlign: "center",
          },
          actionsCellStyle: {
            textAlign: "center",
          },
        }}
        actions={[
          {
            icon: "check",
            tooltip: "Approve",
            onClick: (event, rowData) => handleApprove(rowData.quotationId),
          },
          {
            icon: "close",
            tooltip: "Reject",
            onClick: (event, rowData) => handleReject(rowData.quotationId),
          },
          {
            icon: "download",
            tooltip: "Download Quotation",
            onClick: (event, rowData) => {
              const link = document.createElement("a");
              link.download = `quotation.pdf`;
              link.href = rowData.quotationPDFLink;
              link.click();
            },
          },
        ]}
        components={{
          Toolbar: (props) => (
            <div className="filters text-center">
              <MTableToolbar {...props} />

              <FormControl
                className="formControl"
                variant="standard"
                style={{
                  marginTop: "-65px",
                  marginLeft: "20px",
                  width: "120px",
                }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  Site Name
                </InputLabel>
                <Select
                  value={site}
                  onChange={(e) => {
                    setSite(e.target.value);
                  }}
                  className="selectEmpty"
                  style={{
                    textAlign: "left",
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">Select a Site</MenuItem>
                  {siteList?.map((site, index) => {
                    return (
                      <MenuItem key={index} value={site.SiteCode}>
                        {site.SiteName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl
                className="formControl"
                variant="standard"
                style={{
                  marginTop: "-65px",
                  marginLeft: "20px",
                  width: "120px",
                }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  Phase Name
                </InputLabel>
                <Select
                  value={phase}
                  disabled={!site}
                  onChange={(e) => {
                    setPhase(e.target.value);
                  }}
                  className="selectEmpty"
                  style={{
                    textAlign: "left",
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">Select a Phase</MenuItem>
                  {phaseList?.map((phase, index) => {
                    return (
                      <MenuItem key={index} value={phase.phaseCode}>
                        {phase.phaseName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          ),
        }}
      ></MaterialTable>
    </div>
  );
};

export default PendingApproval;
