import React, { useState, useEffect } from "react";
import "./Quotationform.scss";
import apartment from "../../../assets/icons/apartment.svg";
import phases from "../../../assets/icons/meeting_room.svg";
import units from "../../../assets/icons/nest_multi_room.svg";
import carpark from "../../../assets/icons/garage_home.svg";
import cashback from "../../../assets/icons/monetization_on.svg";
import discount from "../../../assets/icons/discount.svg";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import success from "../../../assets/icons/Success.svg";
import download from "../../../assets/icons/download.svg";
import share from "../../../assets/icons/share.svg";
import petals from "../../../assets/icons/image 12.svg";
import LinkIcon from "@mui/icons-material/Link";
import car from "../../../assets/icons/vehicle.svg";
import LinearProgress from "@mui/material/LinearProgress";
import Slider from "@mui/material/Slider";
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Back from "../Back/Back";
import axios from "axios";
import { BASE_URL } from '../../../utils/config/url';
import Swal from 'sweetalert2';
import { AiOutlineSend } from "react-icons/ai";
import { AiFillCar, AiOutlinePlusCircle } from "react-icons/ai";


const marks = [
  {
    value: 0,
    label: <span className="bold-label">1</span>,
  },
  {
    value: 50,
    label: <span className="bold-label">2</span>,
  },
  {
    value: 100,
    label: <span className="bold-label">3</span>,
  },
];

function QuotationForm() {


  const [popup, setpopup] = useState(false);
  const site = JSON.parse(localStorage.getItem("site"));
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [listOfLeads, setListOfLeads] = useState([])
  const [lead, setLead] = useState('')
  const [selectedPhase, setSelectedPhase] = useState("")
  const [legalDiscount, setLegalDiscount] = useState("")
  const [listOfUnits, setListOfUnits] = useState([])
  const [selectedUnit, setSelectedUnit] = useState("")
  const [res, setRes] = useState("")
  const [quotation, setQuotation] = useState({})
  const [email, setEmail] = useState("")
  const [spinnerModal, setSpinnerModal] = useState(false);
  const [listOfCarParking, setListOfCarParking] = useState([])
  const [allFilled, setAllFilled] = useState(false)
  const [parking, setParking] = useState('')
  const [selectedCarParkings, setSelectedCarParkings] = useState([
    {
      carParkingTypeCode: '',
      carParkingTypeName: '',
      carParkingGrossPrice: ''
    },
    {
      carParkingTypeCode: '',
      carParkingTypeName: '',
      carParkingGrossPrice: ''
    },
    {
      carParkingTypeCode: '',
      carParkingTypeName: '',
      carParkingGrossPrice: ''
    },
  ])

  useEffect(() => {

    axios.get(`${BASE_URL}/api/v1/leadmgmt/lead/getListOfLeads?leadStatus=InDiscussion&assigneeUserName=${userInfo?.userName}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`
        }
      })
      .then((response) => {
        // console.log(response)
        if (response.data.status) {
          setListOfLeads(response.data.leads)
        } else {
          setListOfLeads([])
        }
      })
      .catch((err) => {
        setListOfLeads([])
      })

  }, [])

  useEffect(() => {


    axios.get(`${BASE_URL}/api/v1/configuration/carParking/getListOfCarParking?siteCode=${site?.siteCode}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`
        }
      })
      .then((response) => {
        // console.log(response)
        if (response.data.status) {
          setListOfCarParking(response.data.carParkingList)
        } else {
          setListOfCarParking([])
        }
      })
      .catch((err) => {
        setListOfCarParking([])
      })

  }, [])


  useEffect(() => {

    if (!selectedPhase) return;

    axios.get(`${BASE_URL}/api/v1/Configuration/unit/getListOfUnit?siteCode=${site?.siteCode}&phaseCode=${selectedPhase}&status=Available`,
      {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`
        }
      })
      .then((response) => {
        // console.log(response)
        if (response.data.status) {
          setListOfUnits(response.data.unitList)
        } else {
          setListOfUnits({})
        }
      })
      .catch((err) => {
        setListOfUnits({})
      })

  }, [selectedPhase])

  const generateQuotation = () => {

    console.log(selectedCarParkings)


    let parkings = selectedCarParkings.filter((item) => {
      return item.carParkingTypeCode !== ''
    })

    console.log(parkings)

    let body = {
      unitName: selectedUnit?.split(',')[0].trim(),
      leadID: lead?.split(',')[0],
      leadName: lead?.split(',')[1],
      carParkings: parkings,
      legalDiscount: legalDiscount,
      currentUserFullName: userInfo?.userFullName,
    }
    console.log(body)
    // return;
    setSpinnerModal(true)

    axios.post(`${BASE_URL}/api/v1/leadmgmt/quotation/generateQuotation`,
      body,
      {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`
        }
      })
      .then((response) => {
        setSpinnerModal(false)
        if (response.data.status) {
          setRes(response.data.message)
          setQuotation(response.data.quotation)
          setpopup(true)
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
          })
        }
      })
      .catch((err) => {
        setSpinnerModal(false)
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: err.response.data.error,
        })
      })


  }

  // console.log(selectedCarParkings)

  const sendQuotationByEmail = () => {
    if (!email) return;

    axios.post(`${BASE_URL}/api/v1/leadmgmt/quotation/sendQuotationByEmail`,
      {
        quotationId: quotation?.quotationId,
        leadEmail: email
      }, {
      headers: {
        Authorization: `Bearer ${userInfo?.token}`
      }
    })
      .then((response) => {

        if (response.data.status) {
          alert(response.data.message)
          setEmail("")
        } else {
          alert(response.data.message)
        }
      })
      .catch((err) => {
        alert(err.response.data.error)
      })
  }

  useEffect(() => {

    for (let i = 0; i < selectedCarParkings.length; i++) {
      if (selectedCarParkings[i].carParkingTypeCode === '') {
        setAllFilled(false)
        return;
      }
    }
  }, [selectedCarParkings])

  return (
    <>
      <div className="container">

        {/*<Back navigateTo={`/tabletView/dashboard/${site?.siteCode}`} /> */}

        <div className="heading">
          <p>Generate Quotation</p>
        </div>

        <div className={`quotation-container ${popup ? "active-popup" : ""}`}>

          <div className="quotation-container__lead">
            <select name="lead-id" className="lead-id"
              onChange={(e) => setLead(e.target.value)}
            >
              <option value="">Lead ID</option>
              {
                listOfLeads?.map((lead) => {
                  return (
                    <option key={lead?.leadID} value={`${lead.leadID},${lead?.name}`}>{lead.leadID}</option>
                  )
                })
              }
            </select>

            {
              lead != ''
              &&
              <button className="name-tile">
                <span className="name">{lead?.split(',')[1]}</span>
              </button>
            }

          </div>

          <div className="quotation-container__project-detail">
            <div className="row1">
              <h4 className="title">
                <img src={apartment} alt="img" />
                {"  "}
                Project Name
              </h4>
              <button className="project-name">
                <span className="text">
                  <img src={petals} alt="img" /> Westroad Heights
                </span>
              </button>
            </div>

            <div className="row2">
              <h4 className="title">
                <img src={phases} alt="img" />
                {"  "}
                Phase
              </h4>

              {
                site?.phases?.map((phase, index) => {
                  return <button
                    key={index}
                    onClick={() => {
                      setSelectedPhase(phase?.phaseCode)
                    }}
                    className="phases"
                    style={{
                      backgroundColor: selectedPhase === phase?.phaseCode ? 'grey' : '',
                      color: selectedPhase === phase?.phaseCode ? 'white' : ''
                    }}
                  >
                    {phase?.phaseName}
                  </button>
                })
              }

            </div>
            <div className="row3">
              <h4 className="title">
                <img src={units} alt="img" />
                {"  "}
                Available Units
              </h4>

              <select name="units" className="units"
                onChange={(e) => {
                  // console.log(e.target.value)
                  setSelectedUnit(e.target.value)
                }}
              >
                <option value="">Select</option>
                {
                  listOfUnits?.map((unit, index) => {
                    return <option key={index} value={`${unit?.unitName} , Floor-${unit?.unitFloor}`}>{unit?.unitName}</option>
                  })
                }
              </select>

              {
                selectedUnit
                &&
                <button
                  className="name-tile"
                  style={{ backgroundColor: "grey", borderRadius: "10px" }}
                >
                  <span className="name">{selectedUnit}</span>
                </button>
              }

            </div>
          </div>

          <div className="quotation-container__parking">



            <div className="d-flex">

              <h4 className="title">
                <img src={units} alt="img" />
                {"  "}
                Parking Type
              </h4>

              <select name="carparking" className="carParkings"
                value={parking}
                onChange={(e) => {
                  setParking(e.target.value)
                }}
              >
                <option value="">Select</option>
                {
                  listOfCarParking?.map((parking, index) => {
                    return <option key={index} value={`${parking?.carParkingTypeName},${parking?.carParkingTypeCode},${parking?.carParkingGrossPrice}`}>
                      {parking?.carParkingTypeName} - {parking?.carParkingTypeCode}
                    </option>
                  })
                }
              </select>

              {/* <p style={{ color: '#EE4B46', marginTop: '10px', marginLeft: '10px' }}><em>Cannot Add more than 3 options</em></p> */}


              <button
                className="name-tile"
                disabled={allFilled}
                style={{ backgroundColor: allFilled ? 'grey' : '', borderRadius: "10px", marginLeft: '10px', minWidth: '80px' }}
                onClick={() => {

                  if (parking === '') return;
                  let index = selectedCarParkings?.filter((item) => {
                    return item.carParkingTypeCode === parking.split(',')[1]
                  })

                  // console.log(index)
                  if (index.length > 0) return;


                  for (let i = 0; i < selectedCarParkings.length; i++) {
                    if (selectedCarParkings[i].carParkingTypeCode === "") {
                      index = i;
                      break;
                    }
                  }

                  // console.log(index)
                  if (index === '') return;

                  let parkings = selectedCarParkings[index];
                  parkings.carParkingTypeCode = parking.split(',')[1];
                  parkings.carParkingTypeName = parking.split(',')[0];
                  parkings.carParkingGrossPrice = parseInt(parking.split(',')[2]);

                  setSelectedCarParkings((prev) => {
                    return prev.map((item, i) => {
                      if (i === index) {
                        return parkings
                      } else {
                        return item
                      }
                    })
                  })

                  setParking('')

                }}
              >
                Add
              </button>
            </div>

            <br />

            <div className="carParkingCards">

              {
                selectedCarParkings?.map((parking, index) => {
                  if (parking?.carParkingTypeCode === "") {
                    return <div className="carParkingCard__container" key={index}>

                      <div className="carParkingCard">
                        <AiOutlinePlusCircle className="parking-icon" style={{ color: 'grey' }} />
                        <span style={{ color: 'grey' }}>Not Selected</span>
                      </div>


                    </div>
                  } else {

                    return <div className="carParkingCard__container" key={index}>
                      <div className="carParkingCard">
                        <AiFillCar className="parking-icon" />
                        <span>{parking?.carParkingTypeName} - {parking?.carParkingTypeCode}</span>
                        <span>INR {parking?.carParkingGrossPrice}</span>
                      </div>
                      <p className="remove"
                        onClick={() => {
                          setSelectedCarParkings((prev) => {
                            return prev.map((item, i) => {
                              if (i === index) {
                                return {
                                  carParkingTypeCode: '',
                                  carParkingTypeName: '',
                                  carParkingGrossPrice: ''
                                }
                              } else {
                                return item
                              }
                            })
                          })
                        }}
                      >Remove</p>
                    </div>

                  }

                })
              }

            </div>
            {/* <div className="car-park">
              <h4 className="title">
                <img src={carpark} alt="img" />
                {"  "}
                Car Parking
              </h4>

              <Box sx={{ width: 250 }}>
                <Slider
                  aria-label="Always visible"
                  defaultValue={0}
                  // getAriaValueText={valuetext}
                  step={50}
                  marks={marks}
                  valueLabelDisplay="off"
                  sx={{
                    color: "#CACACA",
                    height: "40%",
                  }}
                />
              </Box>
            </div>
        
            <div className="bike-park">
              <h4 className="title">
                <img src={carpark} alt="img" />
                {"  "}
                Bike Parking
              </h4>

              <Box sx={{ width: 250 }}>
                <Slider
                  aria-label="Always visible"
                  defaultValue={0}
                  // getAriaValueText={valuetext}
                  step={50}
                  marks={marks}
                  valueLabelDisplay="off"
                  sx={{
                    color: "#CACACA",
                    height: "40%",
                  }}
                />
              </Box>
            </div> */}
          </div>

          <div className="quotation-container__discount">
            <div className="offer">
              <h4 className="title">
                <img src={cashback} alt="img" />
                {"  "}
                Cashback
              </h4>
              <input type="number" />
            </div>
            <div className="legal-discount">
              <h4 className="title">
                <img src={discount} alt="img" />
                {"  "}
                Legal Discount
              </h4>
              <input type="number" value={legalDiscount} onChange={(e) => setLegalDiscount(e.target.value)} />
            </div>
          </div>

          <div className="quotation-container__btn">
            <button
              className="generate"
              onClick={() => {
                generateQuotation();
              }}
            >
              Generate <ArrowRightAltIcon />
            </button>
          </div>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal"
            open={popup}
            onClose={() => {
              setpopup(false)
              window.location.reload()
            }}
            closeAfterTransition
          >
            <Fade in={popup}>
              <div className="quotation-container__popup-container">
                <div className="img-body">
                  <img src={success} alt="img" />
                </div>
                <p className="heading">{res}</p>
                <div className="buttons">
                  <button className="btn1">
                    <a href={quotation?.quotationPDFLink} download={true} style={{ textDecoration: 'none', color: 'black' }}> Download <img src={download} alt="img" /></a>
                  </button>
                  <button className="btn2">
                    Share <img src={share} alt="img" />
                  </button>
                </div>
                <div className="email">

                  <div className="input">
                    <input type="email" placeholder="Enter E-mail Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <AiOutlineSend className="icon"
                      onClick={sendQuotationByEmail}
                    />
                  </div>

                  <button className="copy-link"
                    onClick={() => {
                      navigator.clipboard
                        .writeText(quotation?.quotationPDFLink)
                        .then(() => {
                          alert("successfully copied");
                        })
                        .catch(() => {
                          alert("something went wrong");
                        });
                    }}
                  >
                    <LinkIcon /> Copy Link
                  </button>
                </div>
              </div>
            </Fade>
          </Modal>


          {/* /////////////////Modal for spinner /////////// */}

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal"
            open={spinnerModal}
            onClose={() => setSpinnerModal(false)}
            closeAfterTransition
          >
            <Fade in={spinnerModal}>

              <div>
                <ClipLoader
                  color='#EE4B46'
                  loading={spinnerModal}
                  size={70}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            </Fade>
          </Modal>


        </div>
      </div >
    </>
  );
}

export default QuotationForm;
