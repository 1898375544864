import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import "./InitiateBooking.scss";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../utils/ModalStyles";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const InitiateBooking = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + userInfo.token;

  const [carParkings, setCarParkings] = useState([]);
  const [email, setEmail] = useState("");
  const [phno, setPhno] = useState("");
  const [bankLoanRequested, setBankLoanRequested] = useState(false);
  const [leads, setLeads] = useState([]);
  const [wpno, setWpNo] = useState("");
  const [bankList, setBankList] = useState([]);
  const [bankName, setBankName] = useState("");
  const [open, setOpen] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [quotation, setQuotation] = useState({});
  const [leadId, setLeadId] = useState("");
  const [salesCode, setSalesCode] = useState("");

  const [pError, setPError] = useState("");
  const [wpError, setWpError] = useState("");

  const [backToLogin, setBackToLogin] = useState(false);
  const [spinnerModal, setSpinnerModal] = useState(false);
  const [error, setError] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleAddButtonClick = () => {
    if (selectedOption && !selectedItems.includes(selectedOption)) {
      setSelectedItems([...selectedItems, selectedOption]);
      setSelectedOption(""); // Clear the selected option after adding
    }
  };
  const handleDeleteButtonClick = (itemToRemove) => {
    const updatedItems = selectedItems.filter((item) => item !== itemToRemove);
    setSelectedItems(updatedItems);
  };

  useEffect(() => {
    const expireTime = JSON.parse(
      window.atob(userInfo.token.split(".")[1])
    ).exp;

    if (new Date() >= expireTime * 1000) {
      sessionStorage.setItem(
        "previousPath",
        JSON.stringify(window.location.href)
      );
      navigate("/login");
    }
  }, [backToLogin]);

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/leadmgmt/lead/getListOfConvertedLead?applicationInitiated=false`,
        {
          headers: { Authorization: Token },
        }
      )
      .then((response) => {
        if (response.status === 401 || response.status === 403) {
          return setBackToLogin(true);
        }
        setLeads(response.data.leads);
        // console.log(response.data.leads);
      });
  }, []);

  const onChangeLead = (leadId) => {
    if (leadId === "") {
      setQuotation({});
      setCarParkings([]);
      return;
    }

    setLeadId(leadId);

    axios
      .get(
        `${BASE_URL}/api/v1/leadmgmt/quotation/getFinalQuotationByLeadId/${leadId}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        if (response.data.message) {
          setOpen(true);
          setQuotation({});
          setCarParkings([]);
          setErrMessage(response.data.message);
        } else {
          setQuotation(response.data.quotation);

          if (response.data.quotation.carParkings.length !== 0) {
            setCarParkings(response.data.quotation.carParkings);
          }
        }
      });
  };

  const listOfBanks = (e) => {
    e.preventDefault();

    if (e.target.value === "no") {
      return setBankLoanRequested(false);
    } else {
      setBankLoanRequested(true);
      axios
        .get(
          `${BASE_URL}/api/v1/FinanceMgmt/Bank/getListOfBank?isActive=true`,
          {
            headers: { Authorization: Token },
          }
        )
        .then((response) => {
          //console.log(response)
          if (response?.data?.status === true) {
            setBankList(response?.data?.banks);
          } else {
            setBankList([]);
            setError(response?.data?.message);
          }
        });
    }
  };

  const initiateBooking = (e) => {
    e.preventDefault();
    if (pError || wpError) {
      return;
    }

    setSpinnerModal(true);

    const bank = bankName.split(" - ")[0];

    let body = {
      quotationId: quotation.quotationId,
      bookingByUserName: userInfo.userName,
      bookingByUserFullName: userInfo.userFullName,
      isBankLoanRequested: bankLoanRequested,
      preferredBank: selectedItems,
      registeredMobile: phno,
      registeredWhatsapp: wpno,
      registeredEmail: email,
      salesCode: salesCode,
    };

    // if(phno){
    //     body.registeredMobile = phno
    // }
    // if(wpno){
    //     body.registeredWhatsapp = wpno
    // }
    // if(email){
    //     body.registeredEmail = email
    // }

    axios
      .post(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/createBookingApplication`,
        body,
        {
          headers: { Authorization: Token },
        }
      )
      .then((response) => {
        //console.log(response)
        setSpinnerModal(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(() => {
            navigate("/flatAllotment/manageapplications");
          });
        }
      })
      .catch((error) => {
        setSpinnerModal(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="initiatebooking">
      <Button navigateTo={"/home"} />

      <div className="row pt-3 justify-content-center">
        <div className="col-lg-8 col-sm-12">
          <h4>Initiate Booking</h4>
        </div>
      </div>
      <form
        onSubmit={(e) => {
          initiateBooking(e);
        }}
      >
        <div className="row pt-3 justify-content-center">
          <div className="col-4">
            <Form.Group controlId="leadid">
              <label>Lead</label>
              <Form.Control
                as="select"
                required
                onChange={(e) => onChangeLead(e.target.value)}
              >
                <option value="">Select a Lead</option>
                {leads.map((l, index) => (
                  <option value={l.leadID} key={index}>
                    {l.leadID + " : " + l.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-4">
            <label>Quotation Id</label>
            <input
              type="text"
              className="form-control"
              name="quotationId"
              id="quotationId"
              readOnly
              value={quotation.quotationId ? quotation.quotationId : ""}
            />
          </div>
        </div>
        <div className="row pt-3 justify-content-center">
          <div className="col-4">
            <label>Site Name</label>
            <input
              className="form-control"
              name="siteName"
              id="siteName"
              type="text"
              autoComplete="off"
              readOnly
              value={
                quotation.siteDetails?.siteName
                  ? quotation.siteDetails.siteName
                  : ""
              }
            />
          </div>
          <div className="col-4">
            <label>Phase Name</label>
            <input
              className="form-control"
              name="siteName"
              id="siteName"
              type="text"
              autoComplete="off"
              readOnly
              value={
                quotation.siteDetails?.phaseName
                  ? quotation.siteDetails.phaseName
                  : ""
              }
            />
          </div>
          .
        </div>
        <div className="row pt-3 justify-content-center">
          <div className="col-4">
            <label>Unit</label>
            <input
              className="form-control"
              name="unit"
              id="unit"
              type="text"
              readOnly
              value={quotation.unit?.unitName ? quotation.unit.unitName : ""}
            />
          </div>
          <div className="col-4">
            <label>BHK</label>
            <input
              className="form-control"
              name="bhk"
              id="bhk"
              type="text"
              readOnly
              value={quotation.unit?.bhk ? quotation.unit.bhk : ""}
            />
          </div>
        </div>

        <br />
        <div className="row justify-content-center">
          <div className="col-8">
            <h4>Car Parkings</h4>
          </div>
        </div>
        <br />

        <div className="row justify-content-center">
          {carParkings.length === 0 ? (
            <h5 style={{ color: "#EE4B46" }}>No Car Parking Opted</h5>
          ) : (
            <div className="col-5">
              {carParkings.map((c, index) => {
                return (
                  <input
                    key={index}
                    className="form-control"
                    readOnly
                    value={`${c.carParkingTypeName} - ${c.carParkingTypeCode}`}
                    style={{
                      marginBottom: "20px",
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>

        <br />

        <div className="row pt-3 justify-content-center">
          <div className="col-4">
            <label>Registered Mobile Number</label>
            <input
              type="number"
              className="form-control"
              name="Number"
              id="outlined-basic-phno"
              required
              value={phno}
              onChange={(e) => {
                if (e.target.value.length !== 0) {
                  if (e.target.value.length !== 10) {
                    setPError("Must be of 10 characters with numbers only");
                  } else {
                    setPError("");
                  }
                } else {
                  setPError("");
                }
                setPhno(e.target.value);
              }}
            />
            {pError && (
              <small id="phnoMessage" style={{ color: "#EE4B46" }}>
                {pError}
              </small>
            )}
          </div>
          <div className="col-4">
            <label>Registered Email</label>
            <input
              type="email"
              className="form-control"
              name="email"
              id="outlined-basic-email"
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="row pt-3 justify-content-center">
          <div className="col-4">
            <label>Registered Whatsapp Number</label>
            <input
              type="number"
              maxLength={10}
              className="form-control"
              name="Number"
              id="outlined-basic-phno"
              value={wpno}
              onChange={(e) => {
                if (e.target.value.length !== 0) {
                  if (e.target.value.length !== 10) {
                    setWpError("Must be of 10 characters with numbers only");
                  } else {
                    setWpError("");
                  }
                } else {
                  setWpError("");
                }
                setWpNo(e.target.value);
              }}
            />
            {wpError && (
              <small id="emailMessage" style={{ color: "#EE4B46" }}>
                {wpError}
              </small>
            )}
          </div>

          <div className="col-4">
            <label>Sales Code</label>
            <input
              type="text"
              className="form-control"
              name="salesCode"
              id="outlined-basic-salesCode"
              autoComplete="off"
              value={salesCode}
              onChange={(e) => setSalesCode(e.target.value)}
            />
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-8">
            <h4>Bank Loan Required</h4>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-8">
            <FormControl component="fieldset">
              <RadioGroup
                defaultValue="no"
                onChange={(e) => {
                  setBankLoanRequested(e.target.value);
                  listOfBanks(e);
                }}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <br />
        {bankLoanRequested && (
          <>
            {bankList?.length === 0 ? (
              <div className="row justify-content-center">
                <em style={{ color: "red", textAlign: "center" }}>{error}</em>
              </div>
            ) : (
              <>
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-3 col-sm-6">
                    <label>Preferred Loan Bank</label>
                    <select
                      className="form-control"
                      defaultValue=""
                      value={selectedOption}
                      onChange={handleSelectionChange}
                    >
                      <option value="">--Select--</option>

                      <option value="No Preferance">No Preferance</option>
                      {bankList?.map((bank, index) => (
                        <option value={bank.bankName} key={index}>
                          {`${bank.bankName} - ${bank.branchName}`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <button
                      type="button"
                      className="create__btn mt-4"
                      onClick={handleAddButtonClick}
                    >
                      Add Bank
                    </button>
                  </div>
                </div>
                <br />
                <div className="col justify-content-center align-items-center">
                  <div style={{ marginLeft: "20rem" }}>Selected Banks</div>
                  {selectedItems.map((item, index) => (
                    <div className="row justify-content-center align-items-center mt-2">
                      <div className="col-lg-3 col-sm-6">
                        <input
                          className="form-control"
                          readOnly
                          key={index}
                          value={item}
                        />
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <button
                          type="button"
                          className="create__btn "
                          onClick={() => handleDeleteButtonClick(item)}
                        >
                          Delete Bank
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}{" "}
          </>
        )}

        <br />
        <div className="row justify-content-center">
          <button type="submit" className="create__btn">
            Create
          </button>
        </div>
      </form>

      {/* /////////////////Modal for spinner /////////// */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={spinnerModal}
        onClose={() => setSpinnerModal(false)}
        closeAfterTransition
      >
        <Fade in={spinnerModal}>
          <div>
            <ClipLoader
              color="#EE4B46"
              loading={spinnerModal}
              size={70}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className="row justify-content-center">
              <div className="col-12">
                <h4 style={{ color: "red" }}>Quotation Failure !</h4>
                <h5>{errMessage}</h5>
              </div>
            </div>
            <br />
            <div className="row justify-content-center">
              <button
                className="backtoviewlead__btn"
                onClick={() => navigate(`/viewleads`)}
              >
                Back to View Lead
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default InitiateBooking;
