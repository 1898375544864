import React, { useState } from "react";
import { Box, Stepper, Step, StepLabel, StepIcon } from "@mui/material";
import "./StartOnboarding.scss";
import InitiateOnboarding from "../../components/StartOnboarding/InitiateOnboarding/InitiateOnboarding";
import DefineCTC from "../../components/StartOnboarding/DefineCTC/DefineCTC";
import SendOffer from "../../components/StartOnboarding/SendOffer/SendOffer";

const StartOnboarding = () => {
  const steps = ["Initiate Onboarding", "Define CTC", "Send Offer"];
  const [activeStep, setActiveStep] = useState(0);

  return (
    <div className="startonboarding">
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={CustomStepIcon}
                sx={{
                  color:
                    index === activeStep
                      ? "#EE4B46"
                      : activeStep > index
                      ? "green"
                      : "grey",
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="startonboarding__content">
          {activeStep === 0 && (
            <InitiateOnboarding setActiveStep={setActiveStep} />
          )}
          {activeStep === 1 && <DefineCTC setActiveStep={setActiveStep} />}
          {activeStep === 2 && <SendOffer setActiveStep={setActiveStep} />}
        </div>
      </Box>
    </div>
  );
};

const CustomStepIcon = (props) => {
  const { active, completed } = props;

  return (
    <StepIcon
      {...props}
      style={{ color: active || completed ? "inherit" : "#d3d3d3" }}
    >
      {completed ? <span style={{ color: "inherit" }}>✓</span> : null}
    </StepIcon>
  );
};

export default StartOnboarding;