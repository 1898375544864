import React, { useEffect, useState } from 'react'
import './Quotations.scss';
import MaterialTable, { MTableToolbar } from 'material-table';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { dateFormatting } from '../../utils/dateFormatting';

const ApprovedQuotations = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [siteList, setSiteList] = useState([])
    const [site, setSite] = useState('')
    const [phase, setPhase] = useState('')
    const [phaseList, setPhaseList] = useState([])
    const [QuotationList, setQuotationList] = useState([]);

    useEffect(() => {

        let url = `${BASE_URL}/api/v1/leadmgmt/quotation/getListOfQuotation?isFinalRequested=false&isFinalApproved=Approved&finalApproverEmpId=${userInfo?.employeeId}`;

        if (site) {
            url += `&siteCode=${site}`
        }

        if (phase) {
            url += `&phaseCode=${phase}`
        }

        axios.get(url, {
            headers: { Authorization: `bearer ${userInfo.token} ` }
        })
            .then((response) => {
                // console.log(response)
                setQuotationList(response.data.quotations)
            })

    }, [site, phase])


    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/Configuration/site/getAllSiteNames`, {
            headers: { Authorization: `bearer ${userInfo.token} ` }
        })
            .then((response) => {
                // console.log(response)
                if (response.data.status) {
                    setSiteList(response.data.siteList)
                } else {
                    setSiteList([])
                }
            })

    }, [])

    useEffect(() => {

        if (!site) {
            setPhase('')
            return;
        }

        axios.get(`${BASE_URL}/api/v1/Configuration/site/getSiteBySiteCode/${site}`, {
            headers: { Authorization: `bearer ${userInfo.token} ` }
        })
            .then((response) => {
                // console.log(response)
                if (response.data.status === true) {
                    setPhaseList(response.data.site.phases)
                } else {
                    setPhaseList([])
                }
            })

    }, [site])


    

    return (
        <div className='quotation-list'>

            <MaterialTable
                data={QuotationList}
                title="All Quotations"

                columns={[
                    {
                        title: "Quotation ID",
                        field: "quotationId"
                    },
                    {
                        title: "Lead ID",
                        field: "leadID"
                    },
                    {
                        title: "Unit Name",
                        render: (rowData) => (rowData?.unit?.unitName),
                        customSort: (a, b) => (a?.unit?.unitName < b?.unit?.unitName ? -1 : 1),
                    },
                    {
                        title: "BHK",
                        render: (rowData) => (rowData?.unit?.bhk),
                        customSort: (a, b) => (a?.unit?.bhk < b?.unit?.bhk ? -1 : 1),
                    },
                    {
                        title: "Quotation Date",
                        render: (rowData) => {
                            let res = ''

                            if (rowData.quotationDate) {
                                return res = dateFormatting(rowData.quotationDate)
                            } else {
                                return ''
                            }
                        },
                        customSort: (a, b) => (a.quotationDate < b.quotationDate ? -1 : 1),
                    },
                    {
                        title: "Generated By",
                        field: "qtnGeneratedBy",
                    },
                    {
                        title: "Site Name",
                        render: (rowData) => (rowData?.siteDetails?.siteName),
                        cusotmSort: (a, b) => (a?.siteDetails?.siteName < b?.siteDetails?.siteName ? -1 : 1),

                    },
                    {
                        title: "Phase Name",
                        render: (rowData) => (rowData?.siteDetails?.phaseName),
                        customSort: (a, b) => (a?.siteDetails?.phaseName < b?.siteDetails?.phaseName ? -1 : 1),
                    },
                    {
                        title: "Approver Name",
                        field: "finalApproverFullName"
                    },
                    {
                        title: "Approval Date",
                        render: (rowData) => {
                            return dateFormatting(rowData.finalApprovalDate)
                        },
                        customSort: (a, b) => (a.finalApprovalDate < b.finalApprovalDate ? -1 : 1),
                    }
                ]}


                options={{
                    search: window.innerWidth < 700 ? false : true,
                    searchFieldStyle: {
                        width: 180
                    },
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#EE4B46',
                        color: '#fff',
                    }
                }}

                actions={[
                    {
                        icon: "download",
                        tooltip: "Download Quotation",
                        onClick: (event, rowData) => {

                            const link = document.createElement("a");
                            link.download = `quotation.pdf`;
                            link.href = rowData.quotationPDFLink;
                            link.click();
                        },
                    },
                ]}

                components={{
                    Toolbar: (props) => (
                        <div className="filters text-center">
                            <MTableToolbar {...props} />

                            <FormControl
                                className="formControl"
                                variant='standard'
                                style={{
                                    marginTop: "-65px",
                                    marginLeft: '20px',
                                    width: '120px',

                                }}
                            >
                                <InputLabel id="demo-simple-select-helper-label">
                                    Site Name
                                </InputLabel>
                                <Select
                                    value={site}
                                    onChange={(e) => {
                                        setSite(e.target.value)
                                    }}
                                    className="selectEmpty"
                                    style={{
                                        textAlign: 'left'
                                    }}
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value="">
                                        Select a Site
                                    </MenuItem>
                                    {
                                        siteList?.map((site, index) => {
                                            return <MenuItem key={index} value={site.SiteCode}>{site.SiteName}</MenuItem>
                                        })

                                    }
                                </Select>
                            </FormControl>

                            <FormControl
                                className="formControl"
                                variant='standard'
                                style={{
                                    marginTop: "-65px",
                                    marginLeft: '20px',
                                    width: '120px',
                                }}
                            >
                                <InputLabel id="demo-simple-select-helper-label">
                                    Phase Name
                                </InputLabel>
                                <Select
                                    value={phase}
                                    disabled={!site}
                                    onChange={(e) => {
                                        setPhase(e.target.value)
                                    }}
                                    className="selectEmpty"
                                    style={{
                                        textAlign: 'left'
                                    }}
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value="">
                                        Select a Phase
                                    </MenuItem>
                                    {
                                        phaseList?.map((phase, index) => {
                                            return <MenuItem key={index} value={phase.phaseCode}>{phase.phaseName}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>

                        </div>
                    ),
                }}


            ></MaterialTable>

        </div>
    )
}

export default ApprovedQuotations;