import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../utils/config/url'
import axios from 'axios'
import { alpha, styled } from "@mui/material/styles";
import { Switch } from "@mui/material";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Swal from 'sweetalert2';


const RedSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: "#EE4B46",
        "&:hover": {
            backgroundColor: alpha("#EE4B46", theme.palette.action.hoverOpacity),
        },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#EE4B46",
    },
}));


const Applied = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const [salaryAdvanceList, setSalaryAdvanceList] = useState([])
    const [approvalModal, setApprovalModal] = useState(false)
    const [isApproved, setIsApproved] = useState(false)
    const [approvedAmount, setApprovedAmount] = useState('')
    const [reason, setReason] = useState('')
    const [salaryAdv, setSalaryAdv] = useState('')

    useEffect(() => {

       getListOfSalaryAdvance()

    }, [])

    const getListOfSalaryAdvance=()=>{

        axios.get(`${BASE_URL}/api/v1/hrmgmt/salaryAdvance/getListOfSalaryAdvance?status=Applied`,
        {
            headers: {
                Authorization: `Bearer ${userInfo?.token}`
            }
        })
        .then((response) => {
            // console.log(response.data)
            if (response.data.status) {
                setSalaryAdvanceList(response.data.SalaryAdvance)
            } else {
                setSalaryAdvanceList([])
            }
        })

    }

    const handleApproveAndReject = (e) => {

        e.preventDefault()

        let body = {
            employeeId: salaryAdv?.employeeId,
            currentEmployeeId: userInfo?.employeeId,
            status: isApproved ? 'Approved' : 'Rejected',
            salaryAdvanceId: salaryAdv?.salaryAdvanceId,
            approvedAmount: approvedAmount,
            approvalComment: reason,
        }

        axios.post(`${BASE_URL}/api/v1/hrmgmt/salaryAdvance/approveSalaryAdvance`, body,
            {
                headers: {
                    Authorization: `Bearer ${userInfo?.token}`
                }
            })
            .then((response) => {
                setApprovalModal(false)
                // console.log(response.data)
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                        // timer: 2000
                    })
                    .then(()=>{
                        getListOfSalaryAdvance()
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                        timer: 2000
                    })
                }
            })
            .catch((err) => {
                setApprovalModal(false)
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error,
                    // timer: 2000
                })
            })

    }

    const dateFormatting = (date) => {
        let d = new Date(date)
        let year = d.getFullYear()
        let month = d.getMonth() + 1
        let day = d.getDate()

        return `${day}/${month}/${year}`
    }

    // console.log(salaryAdv)


    return (
        <div>
            <MaterialTable
                data={salaryAdvanceList}
                title="Salary Advance List"
                columns={[

                    {
                        title: "Approval",
                        render: (rowData) => {
                            // return <RedSwitch
                            //     checked={isApproved ? true : false}
                            //     onChange={() => {
                            //         setIsApproved(!isApproved)
                            //         setSalaryAdv(rowData)
                            //         setApprovalModal(true)
                            //     }}
                            // />
                            return <div className='d-flex justify-content-between'>
                                <button className='salary-advance__btn mx-2'
                                    onClick={() => {
                                        setIsApproved(true)
                                        setSalaryAdv(rowData)
                                        setApprovalModal(true)
                                    }}
                                >Approve</button>
                                <button className='salary-advance__btn'
                                  onClick={() => {
                                    setIsApproved(false)
                                    setSalaryAdv(rowData)
                                    setApprovalModal(true)
                                }}
                                >Reject</button>
                            </div>
                        }
                    },

                    {
                        title: "Applied By",
                        render: (rowData) => {
                            return `${rowData?.employeeFullName || ''}`
                        }
                    },
                    { title: "Applied By EmpId", field: "employeeId" },

                    {
                        title: "Salary Advance Id",
                        field: 'salaryAdvanceId'
                    },
                    { title: 'Requested Amount', field: 'requestedAmount' },
                    { title: "No Of Installments", field: "noOfInstallments" },

                    { title: 'Request Reason', field: 'requestReason' },

                    {
                        title: "Application Date",
                        field: 'applicationDate',
                        render: rowData => (rowData?.applicationDate ? dateFormatting(rowData.applicationDate) : 'N/A')
                    },



                ]}

                // actions={[
                //     {
                //         icon: <button>Approve</button>,
                //         tooltip: 'Approve',
                //         // disabled: (userInfo?.userRole === 'Director' || userInfo?.userRole === "HR" || userInfo?.userRole === "Finance") ? false : true,
                //         // onClick: (event, rowData) => {
                //         //     navigate(`/viewemployees/${rowData.employeeId}`)
                //         // },
                //     },

                // ]}


                options={{
                    search: true,
                    searchFieldStyle: {
                        width: 180
                    },
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    actionsCellStyle: {
                        width: 120,
                        paddingLeft: 30,
                    },
                    sorting: true,
                    headerStyle: {
                        backgroundColor: "#EE4B46",
                        color: "#fff",
                        paddingLeft: "11px",
                    },
                }}


            ></MaterialTable >

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={approvalModal}
                onClose={() => {
                    setApprovalModal(false)
                    setIsApproved(salaryAdv?.status === 'Approved' ? true : false)
                }}
                closeAfterTransition
            >
                <Fade in={approvalModal}>

                    <form className='paper w-50'
                        onSubmit={(e) => handleApproveAndReject(e)}
                    >
                        <div className='row d-flex justify-content-center'>
                            <div className='col-4 text-center'>
                                <label>Requested Amount</label>
                                <input type="number" className='form-control' readOnly value={salaryAdv?.requestedAmount} />
                            </div>
                            <div className='col-4 text-center'>
                            <label>No. Of Installment</label>
                                <input type="number" className='form-control' readOnly value={salaryAdv?.noOfInstallments} />
                            </div>

                            {
                                isApproved
                                &&
                                <div className='col-4 text-center'>
                                    <label>Approved Amount</label>
                                    <input type="number" className='form-control'
                                        required
                                        value={approvedAmount} onChange={(e) => {
                                            setApprovedAmount(e.target.value)
                                        }} />
                                </div>}

                        </div>

                        <br />

                        <div className='row justify-content-center'>
                            <div className='col-10 text-center'>
                                <label>{isApproved ? 'Approval Comment' : 'Rejected Comment'}</label>
                                <textarea type="text" className='form-control'
                                    required
                                    value={reason} onChange={(e) => {
                                        setReason(e.target.value)
                                    }} />
                            </div>
                        </div>

                        <br />
                        <br />

                        <div className='row justify-content-center'>
                            <div className='col-6 text-center'>
                                <button className='payroll__btn'
                                    type='submit'
                                >Submit</button>
                            </div>
                        </div>
                    </form>

                </Fade>
            </Modal>


        </div>
    )
}

export default Applied