import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../utils/config/url';

const ChequeProcessing = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const chequeNo = useParams().chequeNo;
    const MICRNo = localStorage.getItem('MICRNo')

    const [status, setStatus] = useState('')
    const [sentToBank, setSentToBank] = useState('')
    const [depositorName, setDepositorName] = useState('')
    const [date, setDate] = useState('')
    const [accountNo, setAccountNo] = useState('')
    const [clearanceBankName, setClearanceBankName] = useState('')
    const [clearanceBankAccount, setClearanceBankAccount] = useState('')
    const [clearanceProcessed, setClearanceProcessed] = useState('')
    const [chequeCleared, setChequeCleared] = useState('')
    const [clearanceDate, setClearanceDate] = useState('')
    const [bounceReason, setBounceReason] = useState('')
    const [collectedBy, setCollectedBy] = useState('')
    const [image, setImage] = useState([])
    const [error, setError] = useState('')
    const [scan, setScan] = useState([])
    const [open, setOpen] = useState(false)
    const [photoIndex, setPhotoIndex] = useState(0)
    const [instrumentType, setInstrumentType] = useState('')
    const [chequeLetterLink, setChequeLetterLink] = useState('')
    const [generatedBy, setGeneratedBy] = useState('')
    const [generateDate, setGenerateDate] = useState('')
    const [docName, setDocName] = useState('')
    const [customerId, setCustomerId] = useState('ABCd')
    const [subCategory , setSubCategory] = useState('')


    useEffect(() => {
        getChequeByChequeNo()
    }, [])


    const getChequeByChequeNo = () => {
        axios.get(`${BASE_URL}/api/v1/FinanceMgmt/Cheque/getChequeByChequeNo?chequeNo=${chequeNo}&MICRNo=${MICRNo}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)
                const data = response.data.cheque[0]

                //setChequeNo(data.chequeNo)
                setSubCategory(data?.paymentSubCategory)
                data.collectedBy ? setCollectedBy(data.collectedBy) : setCollectedBy('')
                data.instrumentType ? setInstrumentType(data.instrumentType) : setInstrumentType('')
                setStatus(data.chequeStatus)

                if (data?.chequeScanLink) {
                    let scanList = [data.chequeScanLink]
                    setScan(scanList)
                } else {
                    setScan([])
                }

                data.sentToBank ? setSentToBank('Yes') : setSentToBank('No')
                setDepositorName(data.depositorName)
                setAccountNo(data.chequeAccountNo)
                setClearanceBankName(data.clearanceBankName)
                setClearanceBankAccount(data.clearanceBankAccount)
                setCustomerId(data.customerId)

                data.clearanceProcessed ? setClearanceProcessed('Yes') : setClearanceProcessed('No')
                data.chequeCleared ? setChequeCleared('Yes') : setChequeCleared('No')
                data.clearanceDate ? setClearanceDate(data.clearanceDate) : setClearanceDate('')
                data.bounceReason ? setBounceReason(data.bounceReason) : setBounceReason('')

                if (data.bankSubmitDate) {
                    let response = dateFormatting(data.bankSubmitDate)
                    setDate(response)
                } else {
                    setDate('')
                }

                if (data.clearanceDate) {
                    let response = dateFormatting(data.clearanceDate)
                    setClearanceDate(response)
                } else {
                    setClearanceDate('')
                }

                if (data.chequeAcknowledgementDoc.chequeAcknowledgementLink !== null) {
                    let d = data.chequeAcknowledgementDoc
                    setChequeLetterLink(data.chequeAcknowledgementDoc.chequeAcknowledgementLink)
                    setDocName(d.documentName)
                    setGeneratedBy(d.generatedByUserFullName)
                    let res = dateFormatting(d.generatedDate)
                    setGenerateDate(res)
                } else {
                    setChequeLetterLink('')
                }

            })
    }

    const dateFormatting = (date) => {
        let d = new Date(date)
        let y = d.getFullYear()
        let m = d.getMonth() + 1
        let today = d.getDate()

        if (m < 10) {
            m = `0${m}`
        }

        if (today < 10) {
            today = `0${today}`
        }

        let final = `${today}/${m}/${y}`
        //console.log(final)
        return final;
    }

    const uploadImage = (e) => {

        e.preventDefault()

        if (error) {
            return
        }

        if (image.length === 0) {
            // console.log(image)
            setError('Select a file')
            setTimeout(() => {
                setError('')
            }, 2000)
            return
        }

        let formData = new FormData();
        formData.append('file', image);
        formData.append('chequeNo', chequeNo)

        axios.post(`${BASE_URL}/api/v1/FinanceMgmt/Cheque/uploadChequeScan`,
            formData,
            { headers: { Authorization: `bearer ${userInfo.token}` } }
        )
            .then((response) => {

                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        document.getElementById('myfile').value = null;
                        window.location.reload()
                    })
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })

    }


    const chequeAcknowledgement = (e) => {

        e.preventDefault()

        let body = {
            chequeNo: chequeNo,
            MICRNo: MICRNo,
            customerId: customerId,
            chequeType: 'DemandPayment',
            generatedByUserFullName: userInfo?.userFullName,
            generatedByUserName: userInfo?.userName
        }


        axios.post(`${BASE_URL}/api/v1/FinanceMgmt/cheque/generateChequeAcknowledgement`,
            body,
            {
                headers: { Authorization: `bearer ${userInfo?.token}` }
            })
            .then((response) => {
                // console.log(response.data)
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: response.data.message,
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    }
                }).then(function () {

                getChequeByChequeNo()
                }
                )
            })
    }


    return (
        <div>
            <form>
                <div className="row container-fluid justify-content-center">
                    <div className="col-3">
                        <label>Collected By</label>
                        <input
                            readOnly
                            className="form-control"
                            value={collectedBy}
                        />
                    </div>

                    <div className="col-3">
                        <label>Status</label>
                        <input
                            readOnly
                            className="form-control"
                            value={status}
                        />
                    </div>

                    <div className="col-3">
                        <label>Instrument Type</label>
                        <input
                            readOnly
                            className="form-control"
                            value={instrumentType}
                        />
                    </div>
                </div>

                <br />

                <div className="row container-fluid justify-content-center">
                    <div className="col-3">
                        <label>Sent To Bank</label>
                        <input
                            readOnly
                            className="form-control"
                            value={sentToBank}
                        />
                    </div>
                    <div className="col-3">
                        <label>Depositor Name</label>
                        <input
                            className="form-control"
                            readOnly
                            value={depositorName}
                        />
                    </div>

                    <div className="col-3">
                        <label>Submission Date</label>
                        <input
                            className="form-control"
                            readOnly
                            value={date}
                        />
                    </div>
                </div>

                <br />

                <div className="row container-fluid justify-content-center">

                    <div className="col-4">
                        <label>Clearance Bank</label>
                        <input
                            className="form-control"
                            readOnly
                            value={clearanceBankName}
                        />
                    </div>

                    <div className="col-4">
                        <label>Bank Account</label>
                        <input
                            className="form-control"
                            readOnly
                            value={clearanceBankAccount}
                        />
                    </div>
                </div>

                <br />

                <div className="row container-fluid justify-content-center">
                    <div className="col-3">
                        <label>Clearance Processed</label>
                        <input
                            className="form-control"
                            readOnly
                            value={clearanceProcessed}
                        />
                    </div>

                    <div className="col-3">
                        <label>Cheque Cleared</label>
                        <input
                            className="form-control"
                            readOnly
                            value={chequeCleared}
                        />
                    </div>


                    <div className="col-3">
                        <label>Clearance Date</label>
                        <input
                            className="form-control"
                            readOnly
                            value={clearanceDate}
                        />
                    </div>
                </div>

                <br />

                <div className="row container-fluid justify-content-center">

                    <div className="col-6">
                        <label>Bounce Reason</label>
                        <input
                            className="form-control"
                            readOnly
                            value={bounceReason}
                        />
                    </div>
                </div>

                <br />
                <br />

                <div
                    style={{
                        backgroundColor: 'white',
                        borderRadius: '20px',
                        padding: '20px'
                    }}
                >
                    <h4
                        style={{ textAlign: 'center' }}
                    >Upload Cheque Scan</h4>
                    <br />

                    <div className="row container-fluid justify-content-center">
                        <div className="col-4"
                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <input type="file" id="myfile" name="myfile"
                                onChange={(e) => {
                                   
                                    if (e.target.files[0].type !== 'image/png' && e.target.files[0].type !== 'image/jpg' && e.target.files[0].type !== 'image/jpeg') {
                                        setError('File format must be either png , jpg , jpeg')
                                    } else {
                                        setImage(e.target.files[0])
                                    }

                                    setTimeout(() => {
                                        setError('')
                                    }, 2000)
                                }}
                            />
                        </div>

                        {error && <p><em style={{ color: '#EE4B46' }}>{error}</em></p>}

                        <div className='col-4'
                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <button className='upload'
                                onClick={(e) => uploadImage(e)}
                            >Upload</button>
                        </div>
                    </div>

                    <br />
                    <br />
                    <br />

                    {
                        scan.length !== 0
                        &&
                        <div className="row container-fluid justify-content-center">
                            <div className='col-10'
                                style={{
                                    height: '250px'
                                }}
                            >
                                {
                                    scan?.map((s, i) => {
                                        return <img src={s}
                                            key={i}
                                            className='image'
                                            style={{
                                                width: '110%',
                                                height: '100%',
                                                objectFit: 'contain'
                                            }}
                                            onClick={() => setOpen(true)}

                                        />
                                    })
                                }
                            </div>

                        </div>}

                </div>


                {open && (
                    <Lightbox
                        mainSrc={scan[photoIndex]}
                        nextSrc={scan[(photoIndex + 1) % scan.length]}
                        prevSrc={scan[(photoIndex + scan.length - 1) % scan.length]}
                        onCloseRequest={() => setOpen(false)}

                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + scan.length - 1) % scan.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % scan.length)
                        }
                    />

                )}

                <br />
                <br />

                <div
                    className="row container-fluid justify-content-center"
                    style={{
                        backgroundColor: 'white',
                        borderRadius: '20px',
                        padding: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <h4
                        style={{
                            fontWeight: 'bold'
                        }}
                    >Cheque Acknowledgement Letter</h4>

                    <br />
                    <br />

                    {
                        chequeLetterLink ?
                            <div
                                style={{ textAlign: 'center' }}
                            >
                                <p><b>Document Name : </b>{docName}</p>
                                <p><b>Generated By : </b>{generatedBy}</p>
                                <p><b>Generated Date : </b>{generateDate}</p>
                                <a href={chequeLetterLink}>Download Document</a>
                            </div>

                            :

                            <button
                                className="btn btn-user"
                                style={{
                                    color: 'white',
                                    backgroundColor : subCategory === "BookingPayment"  ? 'grey' : '#EE4B46',
                                    cursor : subCategory === "BookingPayment"  ? 'default' : 'pointer'
                                }}
                                disabled={subCategory === "BookingPayment"}
                                onClick={(e) => chequeAcknowledgement(e)}
                            >
                                Generate
                            </button>
                    }
                </div>

            </form>
        </div>
    )
}

export default ChequeProcessing