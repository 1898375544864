import React, { useEffect, useState } from 'react';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import './InvClaim.scss';
import { useStyles } from "../../utils/ModalStyles";
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

const Details = ({ claim }) => {

    const classes = useStyles();

    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const [approveRejectModal, setApproveRejectModal] = useState(false);
    const [debitPaymentModal, setDebitPaymentModal] = useState(false)
    const [approvalComments, setApprovalComments] = useState('')
    const [choice, setChoice] = useState('')
    const [settleClaimMode, setSettleClaimMode] = useState('')

    const [paymentMode, setPaymentMode] = useState('')
    const [paymentDate, setPaymentDate] = useState('')
    const [upi, setUpi] = useState('');
    const [account, setAccount] = useState('')
    const [bankName, setBankName] = useState('')



    const dateFormatting = (date) => {

        if (!date) return 'N/A';

        let str = new Date(date).toLocaleString('en-US', { timeZone: 'Asia/Jakarta' });

        let d = new Date(str).getDate();
        if (d < 10) {
            d = `0${d}`
        }
        let m = new Date(str).getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = new Date(str).getFullYear();
        return `${d}-${m}-${y}`
    }

    const handleApproveRejectClaim = () => {

        if (approvalComments == '') {
            return;
        }

        axios.patch(`${BASE_URL}/api/v1/hrmgmt/claims/claimApprovalByFinance`, {
            claimId: claim?.claimId,
            isApproved: choice === 'Approve' ? true : false,
            financeApprovalComments: approvalComments,
            financeApprover: userInfo.userName,
            financeApproverFullName: userInfo.userFullName,
            financeApproverEmpId: userInfo.employeeId
        }, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {

                setApproveRejectModal(false)

                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                    }).then(function () {
                        setApprovalComments('')
                        // getlistOfClaimsFinanceApproval();
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                        text: response.data.message
                    });
                }

            }).catch((err) => {

                setApproveRejectModal(false)

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                });
            })
    }


    const reset = () => {
        setChoice('')
        setApprovalComments('')
    }

    return (
        <div>
            <form className='mt-5'>
                <div className="row container-fluid justify-content-center">
                    <div className="col-6">
                        <label>Claim ID</label>
                        <input
                            readOnly
                            className="form-control"
                            value={claim?.claimId} />
                    </div>


                    <div className="col-6">
                        <label>Claim Amount</label>
                        <input
                            readOnly
                            className="form-control"
                            value={claim?.claimAmount} />
                    </div>

                </div>

                <br />

                <div className="row container-fluid justify-content-center">
                    <div className="col-4">
                        <label>Expense Date</label>
                        <input type="text"
                            readOnly
                            className="form-control"
                            value={dateFormatting(claim?.expenseDate)} />
                    </div>

                    <div className="col-4">
                        <label>Applied On</label>
                        <input type="text"
                            readOnly
                            className="form-control"
                            value={dateFormatting(claim?.expenseDate)} />
                    </div>

                    <div className='col-4'>
                        <label>Claim Type</label>
                        <input type="text"
                            readOnly
                            className="form-control"
                            value={claim?.claimType} />
                    </div>
                </div>

                <br />

                <div className="row container-fluid justify-content-center">
                    <div className="col-4">
                        <label>Raised By</label>
                        <input
                            readOnly
                            className="form-control"
                            value={claim?.raisedBy} />
                    </div>
                    <div className="col-4">
                        <label>Employee Id</label>
                        <input
                            readOnly
                            className="form-control"
                            value={claim?.raisedByEmpId} />
                    </div>
                    <div className="col-4">
                        <label>Claim Status</label>
                        <input
                            className="form-control"
                            readOnly
                            value={claim?.claimStatus}
                        />
                    </div>
                </div>

                <br />

                <div className="row container-fluid justify-content-center">
                    {
                        claim?.claimDescription
                        &&
                        <div className="col-6">
                            <label>Claim Description</label>
                            <input
                                class="form-control"
                                readOnly
                                value={claim?.claimDescription}
                            />
                        </div>
                    }

                </div>

                <br />

                <div className="row container-fluid justify-content-center">
                    <div className='col-4'>
                        <labe>Line Manager Employee Id</labe>
                        <input type='text'
                            readOnly
                            className='form-control'
                            value={claim?.lineMgrEmpId}
                        />
                    </div>

                    <div className="col-4">
                        <label>Line Manager Name</label>
                        <input
                            className="form-control"
                            readOnly
                            value={claim?.lineMgrFullName}
                        />
                    </div>

                    <div className="col-4">
                        <label>Approval Date</label>
                        <input
                            className="form-control"
                            readOnly
                            value={dateFormatting(claim?.lineMgrApprovalDate)}
                        />
                    </div>

                </div>

                <br />

                {
                    (claim?.claimStatus === 'AutoApproved' || claim?.claimStatus === 'ApprovedLineManager')
                    &&
                    <div className="row container-fluid justify-content-center">
                        <div className='col-8 d-flex justify-content-around align-items-center'>
                            <h5>Finance Approval</h5>
                            <button className='details__btn'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setChoice('Approve')
                                    setApproveRejectModal(true)
                                }}
                            >Approve</button>
                            <button className='details__btn'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setChoice('Reject')
                                    setApproveRejectModal(true)
                                }}
                            >Reject</button>
                        </div>
                    </div>
                }



                {
                    claim?.claimStatus === 'RejectedLineManager'
                    &&
                    <div className="row container-fluid justify-content-center">
                        <div className='col-4'>
                            <label>Rejection Date</label>
                            <textarea type="text"
                                readOnly
                                className='form-control'
                                value={dateFormatting(claim?.financeApprovalDate)} />
                        </div>

                        <div className='col-8'>
                            <label>Rejection Reason</label>
                            <textarea type="text"
                                readOnly
                                className='form-control'
                                value={claim?.lineMgrApprovalComments} />
                        </div>
                    </div>
                }

                {

                    (claim?.claimStatus === 'FinanceApproved' || claim?.claimStatus === 'FinanceRejected' || claim?.claimStatus === 'ClaimSettled')

                    &&

                    <>
                        <div className="row container-fluid justify-content-center">
                            <div className='col-4'>
                                <label>Finance Approver Name</label>
                                <input type="text"
                                    readOnly
                                    className='form-control'
                                    value={claim?.financeApproverFullName} />
                            </div>

                            <div className='col-4'>
                                <label>Claim {claim?.isApprovedFinance ? 'Approval' : 'Rejection'} Date</label>
                                <input type="text"
                                    readOnly
                                    className='form-control'
                                    value={dateFormatting(claim?.financeApprovalDate)} />
                            </div>
                        </div>

                        <br />

                        <div className="row container-fluid justify-content-center">
                            <div className='col-6'>
                                <label>{claim?.isApprovedFinance ? 'Approval' : 'Rejection'} Comments</label>
                                <input type="text"
                                    readOnly
                                    className='form-control'
                                    value={claim?.financeApprovalComments} />
                            </div>

                        </div>

                        {/* <br />

                        <div className="row container-fluid justify-content-center">
                            <div className='col-6'>
                                <Form.Group controlId="payment-category">
                                    <Form.Label>Settle Claims</Form.Label>
                                    <Form.Control as="select" required
                                        value={settleClaimMode}
                                        onChange={(e) => {
                                            setSettleClaimMode(e.target.value)
                                            // navigate(`/finance/claims/${claim.claimId}/add-debit-payment`)
                                            setDebitPaymentModal(true)
                                        }}
                                    >
                                        <option value="" disabled>Select a Mode</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Bank Transfer">Bank Transfer</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div> */}

                    </>

                }


                <br />

                {
                    claim.claimStatus === 'ClaimSettled'
                    &&
                    <>
                        <div className="row container-fluid justify-content-center">
                            <div className='col-4'>
                                <label>Settlement Date</label>
                                <input type="text"
                                    readOnly
                                    className='form-control'
                                    value={dateFormatting(claim?.financePaymentDate)} />
                            </div>

                            <div className='col-4'>
                                <label>Payment Mode</label>
                                <input type="text"
                                    readOnly
                                    className='form-control'
                                    value={claim?.financePaymentMode} />
                            </div>
                        </div>

                        <br />

                        <div className="row container-fluid justify-content-center">
                            <div className='col-4'>
                                <label>Transfer Id</label>
                                <input type="text"
                                    readOnly
                                    className='form-control'
                                // value={dateFormatting(claim?.financePaymentDate)} 
                                />
                            </div>

                            <div className='col-4'>
                                <label>Debit Payment Id</label>
                                <input type="text"
                                    readOnly
                                    className='form-control'
                                // value={claim?.financePaymentMode}
                                />
                            </div>
                        </div>
                    </>
                }

            </form>


            {/* ////////////////////  Modal for Approve/Reject Claim By Finance ///////////////// */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={approveRejectModal}
                onClose={() => setApproveRejectModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={approveRejectModal}>
                    <div className={classes.paper}>
                        <h5>Do you want to {choice} this Claim?</h5>
                        <br />
                        <div className="row container-fluid justify-content-center">
                            <div className='col-12'>
                                <label>{choice === 'Approve' ? 'Approval' : 'Rejection'} Comments <span style={{ color: 'red' }}>*</span></label>
                                <textarea type="text" className='form-control'
                                    required
                                    autoFocus
                                    value={approvalComments}
                                    onChange={(e) => setApprovalComments(e.target.value)}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row container-fluid justify-content-center">
                            <div className="col-6 text-right">
                                <button className="modal__btns" onClick={() => handleApproveRejectClaim()}>
                                    {choice}
                                </button>
                            </div>
                            <div className="col-6 text-left">
                                <button
                                    className="modal__btns"
                                    onClick={() => {
                                        setApproveRejectModal(false)
                                        reset()
                                    }}
                                    style={{
                                        backgroundColor: "white",
                                        color: "black",
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>


            {/* //////////////// Modal for debit payment ////////////////// */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={debitPaymentModal}
                onClose={() => setDebitPaymentModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={debitPaymentModal}>
                    <form className={classes.paper}
                        style={{ width: '50%' }}
                    >

                        {
                            claim?.claimId
                            &&
                            <div>
                                <h4 style={{ color: '#EE4B46', textAlign: 'center' }}>Amount Payable : Rs.{claim.claimAmount}</h4>
                                <br />
                            </div>
                        }

                        <br />

                        <div className="row justify-content-center">
                            <div className='col-6 text-left'>
                                <label>Payable To</label>
                                <input type="text" readOnly className='form-control'
                                    value={claim.raisedByFullName} />
                            </div>
                        </div>

                        <br />
                        {
                            settleClaimMode === 'Bank Transfer'
                            &&
                            <>
                                <div className="ow container-fluid justify-content-center">
                                    <div className='col-6 text-left'>
                                        <Form.Group controlId="payment-category">
                                            <Form.Label>Payment Mode</Form.Label>
                                            <Form.Control as="select" required
                                                value={paymentMode}
                                                onChange={(e) => {
                                                    setPaymentMode(e.target.value)
                                                }}
                                            >
                                                <option value="">Select a Mode</option>
                                                <option value="NEFT">NEFT</option>
                                                <option value="RTGS">RTGS</option>
                                                <option value="IMPS">IMPS</option>
                                                <option value="UPI">UPI</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </div>
                                </div>

                                <br />

                                {
                                    paymentMode === 'UPI'
                                        ?
                                        <div className="row container-fluid justify-content-center">
                                            <div className='col-6 text-left'>
                                                <label>UPI Id</label>
                                                <input type="text"
                                                    className='form-control'
                                                    value={upi}
                                                    onChange={(e) => setUpi(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="row container-fluid justify-content-center">
                                                <div className='col-6 text-left'>
                                                    <label>Account No.</label>
                                                    <input type="number"
                                                        className='form-control'
                                                        value={account}
                                                        onChange={(e) => setAccount(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <br />

                                            <div className="row container-fluid justify-content-center">
                                                <div className='col-6'>
                                                    <Form.Group controlId="payment-category">
                                                        <Form.Label>Bank Name</Form.Label>
                                                        <Form.Control as="select" required
                                                            value={bankName}
                                                            onChange={(e) => {
                                                                setBankName(e.target.value)
                                                            }}
                                                        >
                                                            <option value="">Select a option</option>
                                                            {/* <option value="NEFT">NEFT</option>
                        <option value="RTGS">RTGS</option>
                        <option value="IMPS">IMPS</option>
                        <option value="UPI">UPI</option> */}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </>
                                }
                            </>
                        }

                        <br />

                        <div className="row justify-content-center">
                            <div className='col-6 text-left'>
                                <label>Payment Date</label>
                                <input type="date" className='form-control'
                                    value={paymentDate} onChange={(e) => setPaymentDate(e.target.value)} />
                            </div>
                        </div>

                        <br />

                        <div className="row justify-content-center">
                            <div className='col-6 d-flex justify-content-between'>
                                <button className='add-debit-payment__btn'>Pay</button>
                                <button className='add-debit-payment__btn'
                                    style={{ backgroundColor: 'white', color: 'black', border: '1px solid black' }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setDebitPaymentModal(false)
                                        setSettleClaimMode('')
                                    }}
                                >Close</button>
                            </div>
                        </div>

                    </form>
                </Fade>
            </Modal>

        </div >

    )
}

export default Details