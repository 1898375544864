import React from 'react'
import './Procurements.scss';


const Procurements = () => {
    return (
        <div className='procurements'>
            <h2>Coming Soon</h2>
        </div>
    )
}

export default Procurements