import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { BASE_URL } from '../../../utils/config/url';
import '../StockManagement.scss';
import Button from '../../Button/Button';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useNavigate } from 'react-router-dom';

const ListOfBrand = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const navigate = useNavigate()
    
    const [brandList, setBrandList] = useState([]);

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/stockmgmtapi/brand/getListOfBrand`,
            {
                headers: { Authorization: `beraer ${userInfo.token}` }
            })
            .then((response) => {
                if (response.data.status === true) {
                    setBrandList(response.data.Brand)
                } else {
                    setBrandList([])
                }
            })
    }, [])

    const dateFormatting = (date) => {

        let str = new Date(date)

        let d = new Date(str).getDate();
        if (d < 10) {
            d = `0${d}`
        }
        let m = new Date(str).getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = new Date(str).getFullYear();

        return `${d}-${m}-${y}`
    }

    return (
        <div className='stock-mgmt'>

            <div className='row justify-content-between'>
                <Button navigateTo="/engineering/stockManagement" />
                <button className='stock-mgmt__btn'
                    onClick={() => navigate('/createBrand')}
                >Add Brand</button>
            </div>

            <br />
            <br />

            <MaterialTable
                data={brandList}
                title="List Of Brand"

                columns={[
                    {
                        title: "Brand Code",
                        field: "brandCode",
                        width: 50
                    },
                    {
                        title: "Brand Name",
                        field: "brandName",
                        width: 80
                    },
                    {
                        title: "Active",
                        render: (rowData) => (rowData.isActive ? 'Yes' : 'No'),
                        width: 50
                    },
                    {
                        title: "Description",
                        field: 'brandDescription',
                        width: 200
                    },
                    {
                        title: "Brand Creator",
                        field: 'createdByFullName',
                        width: 100
                    },
                    {
                        title: 'Created At',
                        render: (rowData) => (dateFormatting(rowData.createdDate)),
                        width: 100,
                        defaultSort: 'desc',
                        customSort: (a, b) => (a.createdDate < b.createdDate ? -1 : 1)
                    }

                ]}


                options={{
                    search: window.innerWidth < 700 ? false : true,
                    searchFieldStyle: {
                        width: 180
                    },
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#EE4B46',
                        color: '#fff',
                    }
                }}

                actions={[
                    {
                        icon: 'remove_red_eye',
                        tooltip: 'View Brand',
                        onClick: (event, rowData) => {
                            navigate(`/listOfBrand/${rowData.brandCode}`);
                        },
                    },

                ]}

            ></MaterialTable>

        </div>
    )
}

export default ListOfBrand