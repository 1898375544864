import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Button from '../../components/Button/Button';
import { BASE_URL } from '../../utils/config/url';
import './EditLeavePlan.scss';

const EditLeavePlan = () => {

    const leavePlanId = useParams().leavePlanId;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate();

    const [planName, setPlanName] = useState('');
    const [isFlexi, setIsFlexi] = useState(false);
    const [earnedLeave, setEarnedLeave] = useState('');
    const [casualLeave, setCasualLeave] = useState('');
    const [medicalLeave, setMedicalLeave] = useState('');
    const [description, setDescription] = useState('');
    const [lwp, setLwp] = useState(''); // [Leave Without Pay]
    const [weeklyFixed, setWeeklyFixed] = useState('');
    const [tempWeeklyFixed, setTempWeeklyFixed] = useState({});
    // console.log(tempWeeklyFixed)
    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/leavePlan/getLeavePlanByLeavePlanId/${leavePlanId}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {

                let plan = response.data.LeavePlan;

                setPlanName(plan.leavePlanName);
                setIsFlexi(plan.isFlexiPlan);
                setEarnedLeave(plan.earnedLeave);
                setCasualLeave(plan.casualLeave);
                setMedicalLeave(plan.medicalLeave);
                setLwp(plan?.lwp || 0);
                setDescription(plan.description);
                setWeeklyFixed(plan.isFlexiPlan?plan.flexiOff:plan.fixedOffDay)
                setTempWeeklyFixed({isFlexiPlan:plan.isFlexiPlan,value:plan.isFlexiPlan?plan.flexiOff:plan.fixedOffDay})
            })

    }, [])

    const editLeavePlan = (e) => {
        e.preventDefault();
        axios.put(`${BASE_URL}/api/v1/hrmgmt/leavePlan/updateLeavePlan`,
            {
                leavePlanId,
                update: {
                    isFlexiPlan: isFlexi,
                    earnedLeave,
                    casualLeave,
                    medicalLeave,
                    lwp,
                    leavePlanName: planName,
                    description,
                    [isFlexi?'flexiOff':'fixedOffDay']: weeklyFixed,
                }
            },
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {

                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    })
                        .then(() => {
                            navigate('/leavemanagement/listofleaveplan')
                        })
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }
    return (
        <div className='edit-leave-plan'>

            <Button navigateTo={1} />

            <br />
            <br />

            <h2 style={{ textAlign: 'center' }}>Update Leave Plan</h2>

            <br /> <br />

            <form onSubmit={(e) => editLeavePlan(e)}>

                <div className='row justify-content-center'>

                    <div className='col-4'>
                        <label>Leave Plan ID</label>
                        <input type="text" className='form-control'
                            required
                            value={leavePlanId}
                            readOnly
                        />
                    </div>

                    <div className='col-4'>
                        <label>Leave Plan Name</label>
                        <input type="text" className='form-control'
                            required
                            value={planName}
                            onChange={(e) => setPlanName(e.target.value)}
                        />
                    </div>

                    <div className='col-4'>

                        <Form.Group controlId="leadid">
                            <label>Is Flexi Plan</label>
                            <Form.Control as="select"
                                required
                                value={isFlexi}
                                onChange={(e) => {
                                    if (tempWeeklyFixed.isFlexiPlan===JSON.parse(e.target.value))  {
                                        setWeeklyFixed(tempWeeklyFixed.value)
                                    }else{
                                        setWeeklyFixed('')
                                    }
                                    setIsFlexi(JSON.parse(e.target.value))
                                }}
                            >
                                <option value="">Select a option</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Form.Control>
                        </Form.Group>
                    </div>

                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-4'>
                        <label>{isFlexi ? 'Weekly Fixed Off' : 'Weekly Fixed Off Day'}</label>
                        <input type={`${isFlexi?'number':'text'}`} className='form-control'
                            required
                            value={weeklyFixed}
                            onChange={(e) => setWeeklyFixed(e.target.value)}
                        />
                    </div>
                    <div className='col-4'>
                        <label>Earned Leave</label>
                        <input type="text" className='form-control'
                            required
                            value={earnedLeave}
                            onChange={(e) => setEarnedLeave(e.target.value)}
                        />
                    </div>

                    <div className='col-4'>
                        <label>Casual Leave</label>
                        <input type="text" className='form-control'
                            required
                            value={casualLeave}
                            onChange={(e) => setCasualLeave(e.target.value)}
                        />
                    </div>

                </div>

                <br />

                <div className='row justify-content-center'>

                    <div className='col-4'>
                        <label>Medical Leave</label>
                        <input type="text" className='form-control'
                            required
                            value={medicalLeave}
                            onChange={(e) => setMedicalLeave(e.target.value)}
                        />
                    </div>

                    <div className='col-4'>
                        <label>Leave Without Pay</label>
                        <input type="text" className='form-control'
                            required
                            value={lwp}
                            onChange={(e) => setLwp(e.target.value)}
                        />
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-8'>
                        <label>description</label>
                        <textarea type="text" className='form-control'
                            required
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>

                </div>

                <br /> <br />

                <div className='row justify-content-center'>
                    <button type='submit'>Update</button>
                </div>
            </form>

        </div>
    )
}

export default EditLeavePlan