import React, { useEffect, useState } from 'react'
import './ViewInSubCat.scss';
import { Nav } from 'react-bootstrap';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Details from '../../Pages/ViewInSubCat/Details';
import CatalogueItems from '../../Pages/ViewInSubCat/CatalogueItems';

const ViewInSubCat = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [tab, setTab] = useState('first')
    const [subCategory, setSubCategory] = useState({})
    const [change, setChange] = useState(false)
    const { subCategoryCode } = useParams()

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/stockmgmtapi/catSubCategory/getCatSubCategoryBySubCategoryCode/${subCategoryCode}`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status) {
                    setSubCategory(response.data.subCategory)
                }
            })
    }, [change])


    return (
        <div className='inv-subcat'>

            <Button navigateTo={1} />

            <br />
            <br />

            <div className="tabs">
                <Nav>
                    <Nav.Item
                        onClick={() => {
                            setTab('first')

                        }}
                    >
                        <Nav.Link className={tab === 'first' ? 'active' : 'inactive'} eventKey="first">
                            Details
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        onClick={() => {
                            setTab('second')
                        }}
                    >
                        <Nav.Link className={tab === 'second' ? 'active' : 'inactive'} eventKey="second">
                            Catalogues Items
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>

            {
                tab === 'first' && <Details subCategory={subCategory} setSubCategory={setSubCategory} change={change} setChange={setChange} />
            }

            {
                tab === 'second' && <CatalogueItems />
            }

        </div>
    )
}

export default ViewInSubCat
