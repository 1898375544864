import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import TicketDesc from "./TicketDesc";
import TicketResol from "./TicketResol";
import './InvDemand.scss';
import Button from "../../components/Button/Button";
import DemandTabs from '../../components/DemandTabs/DemandTabs';
import DemandDetails from "../../components/DemandDetails/DemandDetails";
import DemandPayments from "../../components/DemandPayments/DemandPayments";


function InvDemand() {

  const [tab, setTab] = useState(localStorage.getItem('ActiveKeyDemand'))
  const [demandInfo, setDemandInfo] = useState({})
  const [customerId, setCustomerId] = useState('')
  const [demandPath, setDemandPath] = useState('')

  const { id } = useParams();
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));


  // get comments and ticket data

  useEffect(() => {

    axios.get(`${BASE_URL}/api/v1/CustomerMgmt/Demand/getDemandByDemandId/${id}`, {
      headers: { Authorization: `bearer ${userInfo.token}` }
    })
      .then((response) => {

        setDemandInfo(response.data.demand)
        setCustomerId(response.data.demand.customerId)
      })

  }, [])

  useEffect(() => {
    let path = localStorage.getItem('DemandPath')
    if (path === 'demands') {
      setDemandPath(path)
    } else {

      setDemandPath(path.split('-'))

    }
  },[])

  return (
    <div className="invDemand">
      <Button navigateTo={demandPath === 'demands' ? '/demands' : `/viewindividualcustomer/${demandPath[1]}`} />
     
      <br />

      <div className="row container-fluid justify-content-center" >
        <div className="col-6">
          <h3
            style={{ textAlign: 'center' }}
          ><b>Show Individual Demand</b></h3>
        </div>
      </div>

      <br />

      <DemandTabs tabName={setTab} />
      <br />
      <br />

      {
        tab === 'first' && <DemandDetails data={demandInfo} />
      }

      {
        tab === 'second' && <DemandPayments data={demandInfo} />
      }

    </div>
  );
}

export default InvDemand;
