import React from "react";
import apartmentImg1 from "../../../assets/tableView/apartment-1.png";
import apartmentImg2 from "../../../assets/tableView/apartment-2.png";
import apartmentImg3 from "../../../assets/tableView/apartment-3.png";
import "./Door.scss";

export default function Door() {
  const data = [
    {
      heading: "WINDOWS",
      text: "Fully glazed Aluminium powder coated Sliding windows. Balcony, Aluminium Sliding Window with Flush door",
    },
    {
      heading: "DOORS",
      text: "Door Frame: Made of seasoned and treated Shaal wood Main Door: Polished Teak wood Laminated door with Godrej Double lock Fire Door: As per fire specification",
    },
    {
      heading: "BALCONY RAILINGS",
      text: "MS Grille railing",
    },
  ];

  return (
    <>
      <div className="door__images">
        <img src={apartmentImg1} alt="" />
        <div className="door__images__subcontainer">
          <img src={apartmentImg2} alt="" />

          <img src={apartmentImg3} alt="" />
        </div>
      </div>
      <div className="door__infoContainer">
        {data.map(({ heading, text }) => (
          <div className="door__infoContainer__info">
            <b>{heading}</b>
            <p>{text}</p>
          </div>
        ))}
      </div>
    </>
  );
}
