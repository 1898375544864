import React, { useEffect, useState } from "react";
import { useStyles } from "../../utils/ModalStyles";
import MaterialTable, { MTableToolbar } from "material-table";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import "./ListOfCarParking.scss";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import Button from "../../components/Button/Button";

const ListOfCarParking = () => {
  const classes = useStyles();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + userInfo.token;

  const [form, setForm] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [sc, setSc] = useState("");
  const [cpTypes, setCpTypes] = useState([]);
  const [ptc, setPtc] = useState("");
  const [alloted, setAlloted] = useState("");

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/Configuration/Site/getAllSiteNames`, {
        headers: { Authorization: Token },
      })
      .then((response) => {
        //console.log(response)
        setSiteData(response.data.siteList);
      });
  }, []);

  const getCarParkingTypeList = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      return;
    }
    console.log(e.target.value);
    axios
      .get(
        `${BASE_URL}/api/v1/Configuration/CarParkingType/getListOfCarParkingType/${e.target.value}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        // console.log(response)
        let list = [];
        response.data.carParkingTypeList.forEach((c) => {
          list.push({
            carParkingTypeName: c.carParkingTypeName,
            carParkingTypeCode: c.carParkingTypeCode,
          });
        });

        setCpTypes(list);
      });
  };

  useEffect(() => {
    if (sc === "") {
      setPtc("");
      setAlloted("");
    }

    if (sc === "" && ptc === "" && alloted === "") {
      axios
        .get(`${BASE_URL}/api/v1/Configuration/CarParking/getListOfCarParking`, {
          headers: { Authorization: Token },
        })
        .then((response) => {
          // console.log(response)
          setForm(response.data.carParkingList);
        });
    } else if (sc !== "" && ptc === "" && alloted === "") {
      axios
        .get(
          `${BASE_URL}/api/v1/Configuration/CarParking/getListOfCarParking?siteCode=${sc}`,
          {
            headers: { Authorization: Token },
          }
        )
        .then((response) => {
          //  console.log(response)
          setForm(response.data.carParkingList);
        });
    } else if (sc !== "" && ptc !== "" && alloted === "") {
      axios
        .get(
          `${BASE_URL}/api/v1/Configuration/CarParking/getListOfCarParking?siteCode=${sc}&carParkingTypeCode=${ptc}`,
          { headers: { Authorization: Token } }
        )
        .then((response) => {
          // console.log(response)
          setForm(response.data.carParkingList);
        });
    } else if (sc !== "" && ptc !== "" && alloted !== "") {
      axios
        .get(
          `${BASE_URL}/api/v1/Configuration/CarParking/getListOfCarParking?siteCode=${sc}&carParkingTypeCode=${ptc}&alloted=${alloted}`,
          { headers: { Authorization: Token } }
        )
        .then((response) => {
          //console.log(response);
          setForm(response.data.carParkingList);
        });
    }
  }, [sc, ptc, alloted]);

  const reset = () => {
    setSc("");
    setPtc("");
    setAlloted("");
  };

  // console.log(form)

  return (
    <div className="listofcarparking">
      <Button navigateTo={"/home"} />
      <br />
      <div className="row container-fluid-justify-content-center">
        <div className="col-12 mt-4">
          <h3>Car Parking</h3>
          <MaterialTable
            data={form}
            title=""
            columns={[
              { title: "Customer Id", field: "customerId" },
              { title: "Site Name", field: "siteName" },
              { title: "Car Parking Name", field: "carParkingName" },
              { title: "Phase Name", field: "phaseName" },
              { title: "Parking Type", field: "carParkingTypeName" },
              { title: "Alloted",
               field: "alloted" ,
              render: (rowData) => (rowData.alloted === true ? "Yes" : "No")},
            ]}
            options={{
              search: window.innerWidth < 700 ? false : true,
              searchFieldStyle: {
                width: 180,
              },
              actionsColumnIndex: 0,
              actionsCellStyle: {
                width: 100,
              },
              paging: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 20, 30],
              headerStyle: {
                backgroundColor: "#EE4B46",
                color: "#fff",
                paddingLeft: "11px",
              },
            }}
            actions={[
              (rowData) => ({
                icon: "remove_red_eye",
                tooltip: "View Car Parking",
              }),
              (rowData) => ({
                icon: "delete",
                tooltip: "Delete Car Parking",
              }),
            ]}
            components={{
              Toolbar: (props) => (
                <div className="filters text-left">
                  <MTableToolbar {...props} />

                  <FormControl
                    variant="standard"
                    className={classes.formControl}
                    style={{
                      marginTop: "-65px",
                      marginLeft: "15px",
                      width: "98px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Site Name
                    </InputLabel>
                    <Select
                      llabelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={sc}
                      onChange={(e) => {
                        setSc(e.target.value);
                        getCarParkingTypeList(e);
                      }}
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Site Name</MenuItem>
                      {siteData.map((s) => {
                        return (
                          <MenuItem key={s.SiteCode} value={s.SiteCode}>
                            {s.SiteName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <FormControl
                    variant="standard"
                    className={classes.formControl}
                    style={{ marginTop: "-65px",
                    marginLeft: "15px",
                    width: "120px", }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Parking Type
                    </InputLabel>
                    <Select llabelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                      value={ptc}
                      onChange={(e) => setPtc(e.target.value)}
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                      disabled={sc === "" ? true : false}
                    >
                      <MenuItem value="">Parking type</MenuItem>
                      {cpTypes.map((c) => {
                        return (
                          <MenuItem
                            key={c.carParkingTypeCode}
                            value={c.carParkingTypeCode}
                          >
                            {c.carParkingTypeName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  {window.innerWidth < 1000 && <br />}

                  <FormControl
                    variant="standard"
                    className={classes.formControl}
                    style={{
                      marginTop: window.innerWidth < 1000 ? "-30px" : "-65px",
                      marginLeft: "15px",
                      width: "80px"
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Alloted
                    </InputLabel>
                    <Select llabelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                      value={alloted}
                      onChange={(e) => setAlloted(e.target.value)}
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                      disabled={ptc === "" ? true : false}
                    >
                      <MenuItem value="">Alloted</MenuItem>
                      <MenuItem value="true">True</MenuItem>
                      <MenuItem value="false">False</MenuItem>
                    </Select>
                  </FormControl>

                  {window.innerWidth < 1100 && window.innerWidth > 1000 && (
                    <br />
                  )}

                  <FormControl
                    variant="standard"
                    className={classes.formControl}
                    style={{
                      marginTop: window.innerWidth < 1100 ? "-20px" : "-50px",
                      marginLeft: "15px",
                      width: "110px"
                    }}
                  >
                    <button
                      onClick={reset}
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid black",
                        padding: "5px",
                        borderRadius: "20px",
                      }}
                    >
                      Reset Filter
                    </button>
                  </FormControl>
                </div>
              ),
            }}
          ></MaterialTable>
        </div>
      </div>
    </div>
  );
};

export default ListOfCarParking;
