import React, { useState } from "react";
import "./InitiateOnboarding.scss";
import { Paper, Button } from "@mui/material";
import { BASE_URL } from "../../../utils/config/url";
import axios from "axios";
import Swal from "sweetalert2";

const InitiateOnboarding = ({ setActiveStep }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let hrManagerId = userInfo.employeeId;
  const [isTyping, setIsTyping] = useState(false);
  const [onboardingDetails, setOnboardingDetails] = useState({
    requirementDescription: "",
    employeeRole: "",
    orgRole: "",
    selectedEmployee: {
      employeeSalutation: "",
      employeeFirstName: "",
      employeeMiddleName: "",
      employeeLastName: "",
      employeeId: "",
      userName: "",
      employeePersonalEmail: "",
      employeePersonalMobile: "",
    },
    joiningDate: "",
    location: "",
    hrManagerId: hrManagerId,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setOnboardingDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const handleChangeSelectedEmployee = (e) => {
    const { name, value } = e.target;
    setOnboardingDetails((prevDetails) => ({
      ...prevDetails,
      selectedEmployee: {
        ...prevDetails.selectedEmployee,
        [name]: value,
      },
    }));
    if (name === "employeePersonalMobile" && String(value).length !== 10) {
      setIsTyping(true);
    }
  };
  const InitiateOnboarding = (e) => {
    e.preventDefault();

    axios
      .post(
        `${BASE_URL}/api/v1/hrmgmt/onboarding/initiateOnboarding`,
        onboardingDetails,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((res) => {
        console.log(res, "top");
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Onboarding Initiated Successfully",
          }).then(() => {
            localStorage.setItem(
              "onboardingdetails",
              JSON.stringify(res.data.onboardingdetails)
            );
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: res.data.error,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.error,
        });
      });
  };

  return (
    <div className="InitiateOnboarding">
      <Paper elevation={3} className="InitiateOnboarding__paper">
        <form onSubmit={InitiateOnboarding}>
          <div className="row justify-content-center">
            <div className="col-12">
              <label>Requirement Description</label>
              <input
                className="form-control"
                name="requirementDescription"
                type="text"
                value={onboardingDetails.requirementDescription}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <br />
          <div className="row justify-content-left">
            <div className="col-4">
              <label>Employee ID</label>
              <input
                className="form-control"
                name="employeeId"
                type="text"
                value={onboardingDetails.selectedEmployee.employeeId}
                onChange={handleChangeSelectedEmployee}
                required
              />
            </div>
            <div className="col-4">
              <label>Employee Role</label>
              <select
                className="form-control"
                value={onboardingDetails.employeeRole}
                onChange={handleChange}
                name="employeeRole"
                required
              >
                <option value="">Select a Role</option>
                <option value="Sales">Sales</option>
                <option value="HR">HR</option>
                <option value="Finance">Finance</option>
                <option value="Operations">Operations</option>
                <option value="Engineering">Engineering</option>
              </select>
            </div>
            <div className="col-4">
              <label>Organization Role</label>
              <input
                className="form-control"
                type="text"
                value={onboardingDetails.orgRole}
                onChange={handleChange}
                name="orgRole"
                required
              />
            </div>
          </div>
          <br />
          <div className="row justify-content-left">
            <div className="col-4">
              <label>User Name</label>
              <input
                className="form-control"
                type="text"
                value={onboardingDetails.selectedEmployee.userName}
                onChange={handleChangeSelectedEmployee}
                name="userName"
                required
              />
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-3">
              <label>Salutaion</label>
              <select
                className="form-control"
                value={onboardingDetails.selectedEmployee.employeeSalutation}
                onChange={handleChangeSelectedEmployee}
                name="employeeSalutation"
                required
              >
                <option value="">Select a Salutaion</option>
                <option value="Mr.">Mr.</option>
                <option value="Ms.">Ms.</option>
                <option value="Mrs.">Mrs.</option>
              </select>
            </div>
            <div className="col-3">
              <label>First Name</label>
              <input
                className="form-control"
                type="text"
                value={onboardingDetails.selectedEmployee.employeeFirstName}
                onChange={handleChangeSelectedEmployee}
                name="employeeFirstName"
                required
              />
            </div>
            <div className="col-3">
              <label>Middle Name</label>
              <input
                className="form-control"
                type="text"
                value={onboardingDetails.selectedEmployee.employeeMiddleName}
                onChange={handleChangeSelectedEmployee}
                name="employeeMiddleName"
              />
            </div>
            <div className="col-3">
              <label>Last Name</label>
              <input
                className="form-control"
                type="text"
                value={onboardingDetails.selectedEmployee.employeeLastName}
                onChange={handleChangeSelectedEmployee}
                name="employeeLastName"
                required
              />
            </div>
          </div>
          <br />
          <div className="row justify-content-left">
            <div className="col-4">
              <label>Personal Email</label>
              <input
                className="form-control"
                type="email"
                value={onboardingDetails.selectedEmployee.employeePersonalEmail}
                onChange={handleChangeSelectedEmployee}
                name="employeePersonalEmail"
                required
              />
            </div>
            <div className="col-4">
              <label>Personal Mobile</label>
              <input
                className="form-control"
                type="number"
                value={
                  onboardingDetails.selectedEmployee.employeePersonalMobile
                }
                onChange={handleChangeSelectedEmployee}
                name="employeePersonalMobile"
                required
              />
              {isTyping &&
                String(
                  onboardingDetails.selectedEmployee.employeePersonalMobile
                ).length !== 10 && (
                  <small className="mt-2 text-danger">
                    Mobile number must be of 10 digit
                  </small>
                )}
            </div>
          </div>
          <br />
          <div className="row justify-content-left">
            <div className="col-4">
              <label>Joining Date</label>
              <input
                className="form-control"
                type="date"
                value={onboardingDetails.joiningDate}
                onChange={handleChange}
                name="joiningDate"
                required
              />
            </div>
          </div>
          <br />
          <div className="row justify-content-left">
            <div className="col-12">
              <label>Location</label>
              <input
                className="form-control"
                type="text"
                value={onboardingDetails.location}
                onChange={handleChange}
                name="location"
                required
              />
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: "#EE4B46",
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor: "#ED4D2B",
                },
                textTransform: "none",
                fontSize: "18px",
              }}
            >
              Initiate Onboarding
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default InitiateOnboarding;
