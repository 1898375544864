import React, { useEffect, useState } from "react";
import "./CreateNewOffice.scss";
import Button from "../Button/Button";
import { BASE_URL } from "../../utils/config/url";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const CreateNewOffice = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();

  const [office, setOffice] = useState({
    officeCode: "",
    officeName: "",
    latitude: "",
    longitude: "",
    officeAddress: {
      fullAddress: "",
      landMark: "",
      city: "",
      state: "",
      pinCode: "",
    },
  });

  const createOffice = (e) => {
    e.preventDefault();
    axios
      .post(`${BASE_URL}/api/v1/hrmgmt/office/createOffice`, office, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            confirmButtonText: "Ok",
          }).then(() => {
            navigate("/office");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <div className="create-new-office">
      <Button navigateTo="/office" />
      <br />
      <br />
      <Paper elevation={4} className="create-new-office__paper">
        <h4>Create New Office</h4>
        <br />
        <form onSubmit={createOffice}>
          <div className="row justify-content-center">
            <div className="col-3">
              <label>Office Code</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Office Code"
                required
                onChange={(e) => {
                  setOffice({ ...office, officeCode: e.target.value });
                }}
              />
            </div>
            <div className="col-3">
              <label>Office Name</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Office Name"
                required
                onChange={(e) => {
                  setOffice({ ...office, officeName: e.target.value });
                }}
              />
            </div>
            <div className="col-3">
              <label>Office Latitude</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Office Latitude"
                required
                onChange={(e) => {
                  setOffice({ ...office, latitude: e.target.value });
                }}
              />
            </div>
            <div className="col-3">
              <label>Office Longitude</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Office Longitude"
                required
                onChange={(e) => {
                  setOffice({ ...office, longitude: e.target.value });
                }}
              />
            </div>
          </div>
          <br />
          <div className="row justify-content-start">
            <div className="col-6">
              <label>Office Full Address</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Office Full Address"
                required
                onChange={(e) => {
                  setOffice({
                    ...office,
                    officeAddress: {
                      ...office.officeAddress,
                      fullAddress: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="col-6">
              <label>Office Landmark</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Office Landmark"
                onChange={(e) => {
                  setOffice({
                    ...office,
                    officeAddress: {
                      ...office.officeAddress,
                      landMark: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
          <br />
          <div className="row justify-content-start">
            <div className="col-3">
              <label>Office City</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Office City"
                required
                onChange={(e) => {
                  setOffice({
                    ...office,
                    officeAddress: {
                      ...office.officeAddress,
                      city: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="col-3">
              <label>Office State</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Office State"
                required
                onChange={(e) => {
                  setOffice({
                    ...office,
                    officeAddress: {
                      ...office.officeAddress,
                      state: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="col-3">
              <label>Office Pincode</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Office Pincode"
                required
                onChange={(e) => {
                  setOffice({
                    ...office,
                    officeAddress: {
                      ...office.officeAddress,
                      pinCode: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <button className="create-new-office__btn" type="submit">
              Create Office
            </button>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default CreateNewOffice;
