import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button';
import { Nav } from 'react-bootstrap';
import './IndividualClaim.scss';
import Details from '../../components/InvClaim/Details';
import SupportingDoc from '../../components/InvClaim/SupportingDoc';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios'
import { useParams } from 'react-router-dom';
import ClaimPayment from '../../components/InvClaim/ClaimPayment';

const IndividualClaim = () => {

    const claimId = useParams().claimId;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [tab, setTab] = useState('first')
    const [claim, setClaim] = useState({})

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/claims/getClaimByClaimId/${claimId}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)
                if (response.data.status == true) {
                    setClaim(response.data.claim)
                }
            })

    }, [])

    return (
        <div className='inv-claim'>

            <Button navigateTo='/finance/claims' />

            <div className="tabs mt-5">
                <Nav>
                    <Nav.Item
                        onClick={() => {
                            // localStorage.setItem("ActiveKeyClaims", "first");
                            setTab('first')

                        }}
                    >
                        <Nav.Link className={tab === 'first' ? 'active' : 'inactive'} eventKey="first">
                            Details
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        onClick={() => {
                            // localStorage.setItem("ActiveKeyCheques", "second");
                            setTab('second')

                        }}
                    >
                        <Nav.Link className={tab === 'second' ? 'active' : 'inactive'} eventKey="second">
                            Supporting Docs
                        </Nav.Link>
                    </Nav.Item>

                    {
                        claim?.claimStatus === "FinanceApproved"
                        &&
                        <Nav.Item
                            onClick={() => {
                                // localStorage.setItem("ActiveKeyCheques", "second");
                                setTab('third')

                            }}
                        >
                            <Nav.Link className={tab === 'third' ? 'active' : 'inactive'} eventKey="second">
                                Claim Payment
                            </Nav.Link>
                        </Nav.Item>
                    }

                </Nav>
            </div>

            <br />

            {
                tab === 'first' && <Details claim={claim} />
            }

            {
                tab === 'second' && <SupportingDoc claim={claim} />
            }

            {
                (claim?.claimStatus === "FinanceApproved" && tab === 'third') && <ClaimPayment claim={claim} />
            }

        </div>
    )
}

export default IndividualClaim