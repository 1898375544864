import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import "./CarParking.scss";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useStyles } from "../../utils/ModalStyles";
import Swal from "sweetalert2";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: "10px",
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#EE4B46",
    color: "white",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px",
    width: "100",
  },
}));

const CarParking = () => {
  const classes = useStyles();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + userInfo.token;
  const siteCode = useParams().siteCode;
  const navigate = useNavigate();

  const [cp, setCp] = useState([]);
  const [phases, setPhases] = useState([]);
  const [cpt, setCpt] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState("");
  const [selectedCode, setSelectedCode] = useState("");
  const [cpName, setCpName] = useState("");
  const [modal, setModal] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/Configuration/CarParking/getListOfCarParking?siteCode=${siteCode}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        // console.log(response.data)
        setCp(response.data.carParkingList);
      })
      .then(() => {
        axios
          .get(
            `${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`,
            { headers: { Authorization: Token } }
          )
          .then((response) => {
            setPhases(response.data.site.phases);
          });
      })
      .then(() => {
        axios
          .get(
            `${BASE_URL}/api/v1/Configuration/CarParkingType/getListOfCarParkingType/${siteCode}`,
            { headers: { Authorization: Token } }
          )
          .then((response) => {
            const cptList = response.data.carParkingTypeList.map((cpt) => {
              const { carParkingTypeName, carParkingTypeCode } = cpt;
              return {
                carParkingTypeName: carParkingTypeName,
                carParkingTypeCode: carParkingTypeCode,
              };
            });

            setCpt(cptList);
          });
      });
  }, []);

  const handleFilter = (e, name) => {
    e.preventDefault();
    let pcode = "";
    let cptcode = "";

    if (name === "phase") {
      setSelectedPhase(e.target.value);
      if(e.target.value === "General"){
        pcode = "Gen"
      }

      phases.forEach((p) => {
        if (p.phaseName === e.target.value) {
          pcode = p.phaseCode;
        }
      });
    } else if (name === "car parking type") {
      setSelectedCode(e.target.value);

      cpt.forEach((c) => {
        if (c.carParkingTypeName === e.target.value) {
          cptcode = c.carParkingTypeCode;
        }
      });
    }

    axios
      .get(
        `${BASE_URL}/api/v1/Configuration/CarParking/getListOfCarParking?siteCode=${siteCode}&phaseCode=${pcode}&carParkingTypeCode=${cptcode}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        setCp(response.data.carParkingList);
      });
  };

  const handleDeleteCarParking = () => {
    axios
      .delete(
        `${BASE_URL}/api/v1/Configuration/CarParking/deleteCarParkingByCarParkingName/${cpName}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        setModal(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            axios
              .get(
                `${BASE_URL}/api/v1/Configuration/CarParking/getListOfCarParking?siteCode=${siteCode}`,
                { headers: { Authorization: Token } }
              )
              .then((response) => {
                setCp(response.data.carParkingList);
              });
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      });
  };

  return (
    <div className="carparking">
      <div className="carparking__btn">
        <button
          onClick={() =>
            navigate(`/configurations/site/addcarparking/${siteCode}`)
          }
        >
          Add Car Parking
        </button>

        <FormControl sx={{ m: 1, minWidth: 140, marginBottom: "10px" }}>
          <InputLabel
            id="demo-simple-select-helper-label"
            style={{ color: "black", fontSize: "18px", paddingTop: "5px" }}
          >
            Phases <FilterAltRoundedIcon style={{ marginLeft: "10px" }} />
          </InputLabel>

          <Select
            value={selectedPhase}
            input={<BootstrapInput />}
            onChange={(e) => handleFilter(e, "phase")}
          >
            <MenuItem value=" ">
              <em>None</em>
            </MenuItem>
            <MenuItem value="General">General</MenuItem>
            {phases.map((p) => {
              return <MenuItem value={p.phaseName}>{p.phaseName}</MenuItem>;
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 200, marginBottom: "10px" }}>
          <InputLabel
            id="demo-simple-select-helper-label"
            style={{ color: "black", fontSize: "15px", paddingTop: "5px" }}
          >
            Car Parking Type{" "}
            <FilterAltRoundedIcon style={{ marginLeft: "10px" }} />
          </InputLabel>

          <Select
            value={selectedCode}
            input={<BootstrapInput />}
            onChange={(e) => handleFilter(e, "car parking type")}
          >
            <MenuItem value=" ">
              <em>None</em>
            </MenuItem>
            {cpt.map((c) => {
              return (
                <MenuItem value={c.carParkingTypeName}>
                  {c.carParkingTypeName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>

      <MaterialTable
        data={cp}
        title="Car Parking "
        columns={[
          { title: "Car Parking Name", field: "carParkingName" },
          { title: "Phase Name", field: "phaseName" },
          { title: "Car Parking Type", field: "carParkingTypeName" },
          { title: "Status", field: "status" },
          {title: "Customer Id", field: "customerId"}
        ]}
        options={{
          search: true,
          actionsColumnIndex: 0,
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
          },
        }}
        actions={[
          (rowData) => ({
            icon: "remove_red_eye",
            tooltip: "Update Car Parking ",
            onClick: (event, rowData) => {
              navigate(
                `/configurations/site/${siteCode}/editcarparking/${rowData.carParkingName}`
              );
              // navigate(`/configurations/site/${siteCode}/editcarparking/${rowData.carParkingName}`)
            },
          }),
          (rowData) => ({
            icon: "delete",
            tooltip: "Delete Car Parking ",
            onClick: (event, rowData) => {
              setModal(true);
              setCpName(rowData.carParkingName);
            },
            disabled:
              userInfo.userRole === "ITAdmin" ||
              userInfo.userRole === "Director"
                ? false
                : true,
          }),
        ]}
      ></MaterialTable>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal}
        onClose={() => setModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <h6>Are you sure you want to delete this ?</h6>
            <br />
            <div className="row container-fluid justify-content-center">
              <div className="col-6 text-right">
                <button
                  className="modal__btns"
                  onClick={handleDeleteCarParking}
                >
                  Yes
                </button>
              </div>
              <div className="col-6 text-left">
                <button
                  className="modal__btns"
                  onClick={() => setModal(false)}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default CarParking;
