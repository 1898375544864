import MaterialTable, { MTableToolbar } from "material-table";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";
import React, { useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { GiGears } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Switch,
  createMuiTheme,
  ThemeProvider,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { red } from "@mui/material/colors";
import CustomSwitch from "../../components/CustomSwitch/CustomSwitch";
import FormControl from "@material-ui/core/FormControl";
import Button from "../../components/Button/Button";
const redColor = red[500];

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#EE4B46",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ViewCustomers = () => {
  const [site, setSite] = useState({
    siteC: "",
  });
  const [isActive, setIsActive] = useState("");
  const [siteData, setSiteData] = useState([]);

  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [checkActive, setCheckActive] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const classes = useStyles();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // get all site
  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/Configuration/Site/getAllSiteNames`, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((response) => {
        //console.log(response.data);
        setSiteData(response.data.siteList);
      });
  }, []);

  // get all customers
  useEffect(() => {
    // console.log(site.siteC);
    (async () => {
      const res = await axios.get(
        `${BASE_URL}/api/v1/CustomerMgmt/Customer/getListOfCustomers?siteCode=${site.siteC}&isActive=${isActive}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      );

      const data = res?.data?.customerList;
      setCustomers(data);
      //  console.log(data);
    })();
  }, [isChange, checkActive]);

  //   reset filter
  const reset = () => {
    setSite({
      siteC: "",
    });
    setIsActive("");
    setIsChange(!isChange);
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="outlet_bg">

        <div className="outlet_container">
         <Button navigateTo={'/home'}/>

          <div className="mt-5">
            <h3>Customers</h3>
            <MaterialTable
              data={customers}
              title=""
              actions={[
                {
                  icon: "remove_red_eye",
                  tooltip: "View Customer",
                  onClick: (event, rowData) => {
                    localStorage.setItem('ActiveKeyCustomer', 'first')
                    navigate(`/viewindividualcustomer/${rowData.customerId}`);
                  },
                },
              ]}
              columns={[
                {
                  title: "Customer ID",
                  defaultSort: "desc",
                  field: "customerId",
                  customSort: (a, b) =>
                    a.tableData.id < b.tableData.id ? -1 : 1,
                },
                {
                  title: "Full Name",
                  render: (rowData) =>
                    ` ${rowData.salutation} ${rowData.firstName} ${rowData.lastName}`,
                },

                { title: "Site Name", field: "siteDetails.siteName" },
                { title: "Unit Name", field: "unit.unitName" },
                { title: "Mobile", field: "registeredMobile" },
                {
                  title: "Active",
                  render: (rowData) =>
                    rowData.isActive === true ? "Yes" : "No",
                },
              ]}
              options={{
                search: window.innerWidth < 700 ? false : true,
                searchFieldStyle : {
                  width : 180
                },
                paging: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 20, 30],
                actionsColumnIndex: 0,
                headerStyle: {
                  backgroundColor: "#EE4B46",
                  color: "#fff",
                },
              }}
              components={{
                Toolbar: (props) => (
                  <div className="filters text-left">
                    <MTableToolbar {...props} />

                    <FormControl
                      className={classes.formControl}
                      style={{ marginTop: "-65px" }}
                    >
                      <InputLabel id="demo-simple-select-helper-label">
                        Site
                      </InputLabel>
                      <Select
                        value={site.siteC}
                        onChange={(e) => {
                          let siteValue = {};
                          siteValue.siteC = e.target.value;
                          site.siteC = siteValue.siteC;
                          setSite(site);
                          setIsChange(!isChange);
                        }}
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">All</MenuItem>
                        {siteData.map((s) => (
                          <MenuItem key={s.SiteCode} value={s.SiteCode}>
                            {s.SiteName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl
                      className={classes.formControl}
                      style={{ marginTop: "-65px" }}
                    >
                      <InputLabel id="demo-simple-select-helper-label">
                        Active
                      </InputLabel>
                      <Select
                        value={isActive}
                        onChange={(e) => {
                          setIsActive(e.target.value);
                          setIsChange(!isChange);
                        }}
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="true">True</MenuItem>
                        <MenuItem value="false">False</MenuItem>
                      </Select>
                    </FormControl>

                    {window.innerWidth < 1000 && <br />}

                    <FormControl
                      className={classes.formControl}
                      style={{ marginTop:  window.innerWidth < 1000 ? '-20px' : '-50px'  }}
                    >
                      <button
                        onClick={reset}
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "20px",
                          padding: "2px",
                        }}
                      >
                        Reset Filter
                      </button>
                    </FormControl>
                  </div>
                ),
              }}
            ></MaterialTable>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ViewCustomers;
