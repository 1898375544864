import { Paper } from '@mui/material'
import React, { useState } from 'react'
import { BASE_URL } from '../../utils/config/url'
import axios from 'axios'
import { AiFillDelete } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

const SpecialDeductions = ({ paySlip, setPaySlip }) => {


    const payslipNumber = useParams().payslipNumber
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const [specialDeduction, setSpecialDeduction] = useState(paySlip?.specialDeduction || { description: '', amount: 0 })
    const [spinnerModal, setSpinnerModal] = useState(false);


    const addDeduction = () => {

        setSpinnerModal(true)

        let body = {
            payslipNumber: payslipNumber,
            payslipStatus: paySlip?.payslipStatus,
            description: specialDeduction.description,
            amount: specialDeduction.amount
        };

        axios.put(`${BASE_URL}/api/v1/hrmgmt/paySlip/addSpecialDeduction`,
            body,
            {
                headers: { Authorization: `Bearer ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response)
                setSpinnerModal(false)
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                    })
                        .then(() => {
                            setPaySlip(response.data.paySlip)
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                    })
                }
            })
            .catch((err) => {
                setSpinnerModal(false)
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error,
                })
            })
    }


    return (
        <div>

            <Paper elevation={2} style={{ padding: "20px", marginTop: "40px", width: '70%', margin: 'auto' }}>

                <h4 className='text-center'>Special Deductions</h4>


                <div className='row justify-content-center mt-4'>
                    <div className='col-7'>
                        <label>Description</label>
                        <textarea type="text"
                            className='form-control'
                            value={specialDeduction.description}
                            onChange={(e) => {
                                setSpecialDeduction({ ...specialDeduction, description: e.target.value })
                            }}
                        />
                    </div>

                    <div className='col-3'>
                        <label>Amount</label>
                        <input type="number"
                            className='form-control'
                            value={specialDeduction.amount}
                            onChange={(e) => {
                                setSpecialDeduction({ ...specialDeduction, amount: e.target.value })
                            }}
                        />
                    </div>
                </div>



                <div className='row justify-content-center mt-4'>
                    <div className='col-6 text-center'>
                        <button className='generatedPaySlip__incentives__btn'
                            onClick={addDeduction}
                        >Re-Calculate</button>
                    </div>
                </div>

            </Paper>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={spinnerModal}
                onClose={() => setSpinnerModal(false)}
                closeAfterTransition
            >
                <Fade in={spinnerModal}>

                    <div>
                        <ClipLoader
                            color='#EE4B46'
                            loading={spinnerModal}
                            size={70}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                </Fade>
            </Modal>

        </div>
    )
}

export default SpecialDeductions
