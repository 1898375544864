import React from "react";
import apartmentImg1 from "../../../assets/tableView/apartment-1.png";
import apartmentImg2 from "../../../assets/tableView/apartment-2.png";
import apartmentImg3 from "../../../assets/tableView/apartment-3.png";
import "./Toilet.scss";

export default function Toilet() {
  const data = [
    "Sanitary ware of Hindware",
    "CP fittings of Hindware",
    "Electrical points for hairdryer, geyser and exhaust fan",
    "Anti-Skid Ceramic tiles flooring (Varmora/Kajaria)",
    "Wall tiles up to door height",
    "Plumbing provision for hot/cold water",
    "Concealed water line",
    "Water Proofing by Dr. Fixit",
  ];

  return (
    <>
      <div className="toilet__images">
        <img src={apartmentImg1} alt="" />
        <div className="toilet__images__subcontainer">
          <img src={apartmentImg2} alt="" />

          <img src={apartmentImg3} alt="" />
        </div>
      </div>
      <div className="toilet__infoContainer">
        <div className="toilet__infoContainer__info">
          {data.map((text) => (
            <p>{text}</p>
          ))}
        </div>
      </div>
    </>
  );
}
