import React from 'react'
import { Bar, Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const LeaveBarChart = ({ leaveLabel, leaveCount }) => {


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            // title: {
            //     display: true,
            //     text: 'Chart.js Bar Chart',
            // },
        },
    };



    return (
        <Bar
            data={{

                labels: leaveLabel,
                datasets: [{
                    label: 'Weekly Off',
                    data: leaveCount.map((leave) => (leave[0])),
                    backgroundColor: '#f78e8b',
                    borderWidth: 1
                },
                {
                    label: 'Casual Leave',
                    data: leaveCount.map((leave) => (leave[1])),
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
                {
                    label: 'Medical Leave',
                    data: leaveCount.map((leave) => (leave[2])),
                    backgroundColor: '#65f76d',
                },
                {
                    label: 'Earned Leave',
                    data: leaveCount.map((leave) => (leave[3])),
                    backgroundColor: '#fad264',
                },
                {
                    label: 'Leave Without Pay',
                    data: leaveCount.map((leave) => (leave[4])),
                    backgroundColor: '#a7e8e7',
                },
                {
                    label: 'Total',
                    data: leaveCount.map((leave) => (leave[5])),
                    backgroundColor: '#e8a7dd',
                }]

            }}

            height={100}
            width={500}
            options={options}

        />
    )
}

export default LeaveBarChart;