import React, { useEffect, useState } from 'react'
import './InOfficeReport.scss';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { Paper } from '@mui/material';
import Default_img from "../../assets/images/default_user.png";

const InOfficeReport = () => {

    const [report, setReport] = useState([]);

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/report/attendance/inOfficeReport`,
            {
                headers: { Authorization: `Bearer ${userInfo.token}` }
            })
            .then(async (response) => {
                setReport(response.data.report)
            })
    }, [])


    const dateFormatting = (input) => {

        if (input == 'NA' || !input) return 'NA';

        let date = new Date(input);
        // let date = new Date(new Date(input).setTime(new Date(input).getTime() - ((5*60*60*1000) + (30*60*1000))));
        let hrs = date.getHours();
        if (hrs < 10) hrs = `0${hrs}`
        let minutes = date.getMinutes();
        if (minutes < 10) minutes = `0${minutes}`

        return `${hrs}:${minutes} ${hrs > 12 ? 'PM' : 'AM'}`

    }


    return (
        <div className='in-office'>
            <h1 className='text-center'>In Office Report</h1>

            <div className='in-office__labels'>

                <div className='in-office__labels__label'>
                    <div className='color'
                        style={{ backgroundColor: '#1F9C24' }}
                    ></div>
                    <span>In Office</span>
                </div>

                <div className='in-office__labels__label'>
                    <div className='color'
                        style={{ backgroundColor: '#6D1F9C' }}
                    ></div>
                    <span>Left Office</span>
                </div>

                <div className='in-office__labels__label'>
                    <div className='color'
                        style={{ backgroundColor: '#3899F3' }}
                    ></div>
                    <span>Field Work</span>
                </div>

                <div className='in-office__labels__label'>
                    <div className='color'
                        style={{ backgroundColor: '#E28229' }}
                    ></div>
                    <span>WFH</span>
                </div>

                <div className='in-office__labels__label'>
                    <div className='color'
                        style={{ backgroundColor: '#E2C529' }}
                    ></div>
                    <span>On Leave</span>
                </div>

                <div className='in-office__labels__label'>
                    <div className='color'
                        style={{ backgroundColor: '#E14D4D' }}
                    ></div>
                    <span>Status Unknown</span>
                </div>

            </div>

            <div className='in-office__cards'>
                {
                    report?.length > 0
                    &&

                    report.map((item, index) => {
                        return <Paper elevation={2} style={{ padding: "20px", marginTop: "40px", borderRadius: '20px' }} className='in-office__card' key={index}>
                            <div className='in-office__card__top'>
                                <div className='in-office__card__top__left'>
                                    <img src={item.employeePhoto || Default_img} />
                                </div>

                                <div className='in-office__card__top__right'>
                                    <div className='status'>
                                        <div className='color'
                                            style={{
                                                backgroundColor: item.status === "In Office" ? '#1F9C24' :
                                                    item.status === "Left Office" ? '#6D1F9C' :
                                                        item.status === "Field Work" ? '#3899F3' :
                                                            item.status === "WFH" ? '#E28229' :
                                                                item.status === "On Leave" ? '#E2C529' : '#E14D4D'
                                            }}
                                        ></div>
                                        <span>{item.status}</span>
                                    </div>

                                    <div
                                        className='d-flex'
                                        style={{
                                            color: '#EE4B46', fontSize: '18px',
                                            fontWeight: 700
                                        }}>
                                        <div>
                                            {item?.employeeFirstName}
                                        </div>
                                        <div className='ml-2'>
                                            {item?.employeeMiddleName}
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            color: '#EE4B46', fontSize: '18px',
                                            fontWeight: 700
                                        }}
                                    >
                                        {item?.employeeLastName}
                                    </div>

                                    <b style={{ color: 'grey' }}>
                                        <span>{item?.employeeId}</span>
                                        <span style={{ marginLeft: '10px' }}>{item?.employeeRole}</span>
                                    </b>
                                </div>

                            </div>

                            <div className='in-office__card__bottom'>
                                {
                                    item.status != 'On Leave'
                                    &&
                                    <div className='in-office__card__bottom__timings'>
                                        <div>
                                            {item?.startTime && <span>Start : {dateFormatting(item?.startTime)}</span>}
                                        </div>

                                        <div>
                                            {item?.endTime && <span>End : {dateFormatting(item?.endTime)}</span>}
                                        </div>
                                    </div>
                                }
                                <div className='d-flex justify-content-between in-office__card__bottom__leave'>
                                    {item.half != undefined && <div className='text-center'>{item.half}</div>}
                                    {item?.leaveHalf && <div className='text-center'>Half : {item?.leaveHalf}</div>}
                                </div>

                                <div className='in-office__card__bottom__type'>
                                    {item?.status === "On Leave" ? item?.leaveType : item?.officeName}
                                </div>
                            </div>
                        </Paper>
                    })
                }
            </div>

        </div>
    )
}

export default InOfficeReport