import React, { useEffect, useState } from 'react'
import ApplicationTabs from '../../components/ApplicationTabs/ApplicationTabs'
import Button from '../../components/Button/Button';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import './ApplicationDetails.scss';
import Applicants from '../Applicants/Applicants';
import Considerations from '../Considerations/Considerations';
import AddBookingPayment from '../AddBookingPayment/AddBookingPayment';
import Documents from '../Documents/Documents';
import PaymentSchedule from '../PaymentSchedule/PaymentSchedule';
import SAR from '../SAR/SAR';

const ApplicationDetails = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const Token = 'bearer' + " " + userInfo.token
    const applicationId = useParams().applicationId

    const [tab, setTab] = useState(localStorage.getItem('ActiveKeyApplication'))

    const [data, setData] = useState({});

    useEffect(() => {
        axios
            .get(`${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/getBookingApplicationByApplicationId/${applicationId}`,
                { headers: { Authorization: Token } })
            .then((response) => {
                // console.log(response)
                setData(response.data.BookingApplication)
            })
    }, [])

    return (
        <div className='application_details'>

            <Button navigateTo={'/flatAllotment/manageapplications'} />

            <br /> <br />

            <ApplicationTabs tabName={setTab} />

            {tab === 'first' &&

                <div className="tab-card container-fluid">
                    <div className="row pt-3 justify-content-center">
                        <div className="col-8">
                            <div className="row">
                                <div>
                                    <h4
                                        className="mt-3 pl-2"
                                        style={{
                                            backgroundColor: "#EE4B46",
                                            borderRadius: "33px",
                                            color: "white",
                                            padding: 10
                                        }}
                                    >
                                        Application ID - {applicationId}{" "}
                                    </h4>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                    <br />
                    <div className="row justify-content-center">
                        <div className="col-4">
                            <label>Site Code</label>
                            <input
                                type="text"
                                className="form-control"
                                name="contact"
                                id="outlined-basic"
                                readOnly
                                value={data?.siteDetails?.siteCode}
                            />
                        </div>
                        <div className="col-4">
                            <label>Status</label>
                            <input
                                type="text"
                                className="form-control"
                                name="status"
                                id="outlined-basic"
                                readOnly
                                value={data.status}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="row justify-content-center">
                        <div className="col-4">
                            <label>Phase Name</label>
                            <input
                                type="text"
                                className="form-control"
                                name="contact"
                                id="outlined-basic"
                                readOnly
                                value={data?.siteDetails?.phaseName}
                            />
                        </div>
                        <div className="col-4">
                            <label>Phase Code</label>
                            <input
                                type="text"
                                className="form-control"
                                name="status"
                                id="outlined-basic"
                                readOnly
                                value={data?.siteDetails?.phaseCode}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="row justify-content-center">
                        <div className="col-8">
                            <label>Unit Name</label>
                            <input
                                type="text"
                                className="form-control"
                                name="text"
                                id="outlined-basic"
                                readOnly
                                value={data?.unit?.unitName}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="row justify-content-center">
                        <div className="col-4">
                            <label>Lead ID</label>
                            <input
                                type="text"
                                className="form-control"
                                name="leadid"
                                id="outlined-basic"
                                readOnly
                                value={data.leadID}
                            />
                        </div>
                        <div className="col-4">
                            <label>Lead Name</label>
                            <input
                                type="text"
                                className="form-control"
                                name="leadname"
                                id="outlined-basic"
                                readOnly
                                value={data?.leadName}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="row justify-content-center">
                        <div className="col-4">
                            <label>Booking By</label>
                            <input
                                type="text"
                                className="form-control"
                                name="BookingBy"
                                id="outlined-basic"
                                readOnly
                                value={data?.bookingByUserFullName}
                            />
                        </div>
                        <div className="col-4">
                            <label>Bank Loan</label>
                            <input
                                type="text"
                                className="form-control"
                                name="pincode"
                                id="outlined-basic"
                                readOnly
                                value={data?.isBankLoanRequested === true ? "Yes" : "No"}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="row justify-content-center">
                        <div className="col-4">
                            <label>Bounced Cheque No.</label>
                            <input
                                type="text"
                                className="form-control"
                                name="BookingBy"
                                id="outlined-basic"
                                readOnly
                                value={data.bouncedChequeNo}
                            />
                        </div>
                        <div className="col-4">
                            <label>Cheque Bounce  Count</label>
                            <input
                                type="text"
                                className="form-control"
                                name="pincode"
                                id="outlined-basic"
                                readOnly
                                value={data.chequeBounceCount}
                            />
                        </div>
                    </div>

                    <br />

                </div>
            }

            {tab === 'second' && <Applicants/>}

            {tab === 'third' && <Considerations />}

            {tab === 'fourth' && <AddBookingPayment />}

            {tab === 'fifth' && <PaymentSchedule />}

            {tab === 'sixth' && <Documents />}

            {tab === 'seventh' && <SAR />}


        </div>
    );
};

export default ApplicationDetails;
