import { Paper } from '@material-ui/core';
import React from 'react'
import './HRPolicies.scss';
import policies from './docs';
import Button from '../../components/Button/Button';

const HRPolicies = () => {
    return (
        <div className='policies'>

            <br />
            <Button navigateTo={1} />
            <br />

            <h1>HR Policies</h1>
            <br />

            {
                policies.map((policy, index) => {

                    return <Paper elevation={2}
                        key={index}
                        style={{
                            padding: "15px", width: window.innerWidth < 700 ? '100%' : '70%', margin: 'auto', marginBottom: '30px', borderRadius: '15px', height: '100px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around'
                        }}>

                        <div className="row justify-content-center">
                            <div className="col-6">
                                <p
                                    style={{ fontSize: window.innerWidth < 700 ? '15px' : '20px' }}
                                >{policy.title}</p>
                                {/* <br /> */}
                            </div>

                            <div className="col-6 d-flex justify-content-center align-items-center">
                                <a href={policy.link} target='_blank'
                                    style={{ color: 'white', textDecoration: 'none' }}><button className='btn'>Download</button></a>
                            </div>
                        </div>

                    </Paper>
                })
            }

        </div>
    )
}

export default HRPolicies