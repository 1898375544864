import { Switch, Paper } from "@mui/material";
import React from "react";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import { Modal, Fade } from "@mui/material";
import { MoonLoader } from "react-spinners";

const OnlineStampDuty = ({ unitHandover, setUnitHandover }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loader, setLoader] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [paymentInfo, setPaymentInfo] = React.useState({
    paymentDate: "",
    paymentAmount: "",
    paymentReceiptNo: "",
    file: "",
  });

  const onlineStampDutyPaid = (e) => {
    e.preventDefault();

    setLoader(true);

    let formData = new FormData();
    formData.append("handoverId", unitHandover.handoverId);
    formData.append("paidByUserName", userInfo.userName);
    formData.append("paidByUserFullName", userInfo.userFullName);

    for (let key in paymentInfo) {
      formData.append(key, paymentInfo[key]);
    }

    axios
      .put(
        `${BASE_URL}/api/v1/CustomerMgmt/handover/onlineStampDutyPaid`,
        formData,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);

        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setUnitHandover(response.data.handover);
          });
        }
      })
      .catch((err) => {
        setLoader(false);

        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error || err.response.data.message,
        });
      });
  };

  const dateFormatting = (input) => {
    if (!input) {
      return "N/A";
    }
    // console.log(input)
    let d = new Date(input);
    // console.log(d)

    let date = d.getDate();
    if (date < 10) {
      date = `0${date}`;
    }
    let m = d.getMonth() + 1;
    if (m < 10) {
      m = `0${m}`;
    }
    let y = d.getFullYear();

    let final;

    final = `${date}/${m}/${y}`;
    return final;
  };

  return (
    <>
      <Paper
        elevation={1}
        style={{
          padding: "30px 20px",
          width: "90%",
          margin: "auto",
        }}
      >
        <div className="row mb-3 mx-2 card-1">
          <div className="col-12 tab-card pt-3 pb-3 text-center">
            {!unitHandover?.onlineStampDutyPaid?.isPaid ? (
              userInfo.userRole !== "Operations" &&
              userInfo.userRole !== "Director" ? (
                <h5
                  style={{
                    textAlign: "center",
                  }}
                >
                  Online Stamp Duty Pending
                </h5>
              ) : (
                <>
                  <h4
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Online Stamp Duty
                  </h4>

                  <form
                    onSubmit={(e) => {
                      onlineStampDutyPaid(e);
                    }}
                  >
                    <div className="row justify-content-center mt-4">
                      <div className="col-6">
                        <label>Payment Amount</label>
                        <input
                          type="number"
                          required
                          className="form-control"
                          value={paymentInfo?.paymentAmount}
                          onChange={(e) =>
                            setPaymentInfo((prev) => {
                              return { ...prev, paymentAmount: e.target.value };
                            })
                          }
                        />
                      </div>

                      <div className="col-6">
                        <label>Payment Date</label>
                        <input
                          type="date"
                          required
                          className="form-control"
                          value={paymentInfo?.paymentDate}
                          onChange={(e) =>
                            setPaymentInfo((prev) => {
                              return { ...prev, paymentDate: e.target.value };
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center mt-4">
                      <div className="col-6">
                        <label>Payment Receipt No.</label>
                        <input
                          type="number"
                          required
                          className="form-control"
                          value={paymentInfo?.paymentReceiptNo}
                          onChange={(e) =>
                            setPaymentInfo((prev) => {
                              return {
                                ...prev,
                                paymentReceiptNo: e.target.value,
                              };
                            })
                          }
                        />
                      </div>

                      <div className="col-6">
                        <label>Payment Receipt Scan</label>
                        <input
                          type="file"
                          required
                          className="form-control"
                          onChange={(e) =>
                            setPaymentInfo((prev) => {
                              return { ...prev, file: e.target.files[0] };
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center mt-4">
                      <div className="col-6">
                        <button className="unit-handover__btns" type="submit">
                          Upload
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              )
            ) : (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Online Stamp Duty Paid
                  </p>
                  <br />
                  <div>
                    <p>
                      Payment Receipt :{" "}
                      <b>
                        <a
                          href={
                            unitHandover?.onlineStampDutyPaid
                              ?.paymentReceiptLink
                          }
                        >
                          Download
                        </a>
                      </b>
                    </p>
                    <p>
                      Payment Date:{" "}
                      <b>
                        {dateFormatting(
                          unitHandover?.onlineStampDutyPaid?.paymentDate
                        )}
                      </b>{" "}
                    </p>
                    <p>
                      Amount Paid:{" "}
                      <b>{unitHandover?.onlineStampDutyPaid?.paymentAmount}</b>
                    </p>
                    <p>
                      Paid By:{" "}
                      <b>
                        {unitHandover?.onlineStampDutyPaid?.paidByUserFullName}
                      </b>
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <br />
        <div className="text-center">
          <em style={{ color: "Red", fontSize: "10px" }}>
            This Sum is received and handled by Westroad, only for third party
            payments as per Customer's request and discretion
          </em>
        </div>
      </Paper>

      {/* //////////// Modal for spinner //////////// */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={loader}
        onClose={() => {
          setLoader(false);
        }}
        closeAfterTransition
      >
        <Fade in={loader}>
          <div>
            <MoonLoader
              color="red"
              className="spinner"
              loading={loader}
              size={70}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default OnlineStampDuty;
