import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";

const Rejected = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [salaryAdvanceList, setSalaryAdvanceList] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/salaryAdvance/getListOfSalaryAdvance?status=Rejected`,
        {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response.data)
        if (response.data.status) {
          setSalaryAdvanceList(response.data.SalaryAdvance);
        } else {
          setSalaryAdvanceList([]);
        }
      });
  }, []);

  const dateFormatting = (date) => {
    let d = new Date(date);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();

    return `${day}/${month}/${year}`;
  };
  console.log(salaryAdvanceList);
  return (
    <div>
      <MaterialTable
        data={salaryAdvanceList}
        title="Salary Advance List"
        columns={[
          {
            title: "Applied By",
            render: (rowData) => {
              return `${rowData?.employeeFullName || ""} (${
                rowData?.employeeId
              })`;
            },
          },
          { title: "Applied By EmpId", field: "employeeId" },

          {
            title: "Salary Advance Id",
            field: "salaryAdvanceId",
          },
          { title: "Requested Amount", field: "requestedAmount" },
          { title: "Request Reason", field: "requestReason" },
          {
            title: "Application Date",
            field: "applicationDate",
            render: (rowData) =>
              rowData?.applicationDate
                ? dateFormatting(rowData.applicationDate)
                : "N/A",
          },
          { title: "Rejected By Full Name ", field: "approvedByFullName" },
          { title: "Rejected By Emp Id", field: "approvedByEmpid" },

          { title: "Rejection Comment", field: "approvalComment" },

          {
            title: "Rejection Date",
            field: "approvalDate",
            render: (rowData) =>
              rowData?.approvalDate
                ? dateFormatting(rowData.approvalDate)
                : "N/A",
          },
        ]}
        // actions={[
        //     {
        //         icon: 'remove_red_eye',
        //         tooltip: 'View Employee',
        //         disabled: (userInfo?.userRole === 'Director' || userInfo?.userRole === "HR" || userInfo?.userRole === "Finance") ? false : true,
        //         onClick: (event, rowData) => {
        //             navigate(`/viewemployees/${rowData.employeeId}`)
        //         },
        //     },

        // ]}

        options={{
          search: true,
          searchFieldStyle: {
            width: 180,
          },
          actionsColumnIndex: 0,
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          actionsCellStyle: {
            width: 120,
            paddingLeft: 30,
          },
          sorting: true,
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
            paddingLeft: "11px",
          },
        }}

        // components={{
        //     Toolbar: (props) => (
        //         <div className="filters text-left">
        //             <MTableToolbar {...props} />

        //             <FormControl
        //                 variant='standard'
        //                 className="formControl" style={{ width: "120px", margin: '0 13px', marginTop: '-65px' }}>
        //                 <InputLabel id="demo-simple-select-helper-label" style={{ fontSize: "14px" }}>
        //                     Employee
        //                 </InputLabel>

        //                 <Select value={employee}
        //                     onChange={(e) => setEmployee(e.target.value)}

        //                     inputProps={{ "aria-label": "Without label" }}>
        //                     <MenuItem value=''>Select a employee</MenuItem>
        //                     {
        //                         employeeList?.map((employee, index) => {
        //                             return <MenuItem key={index} value={employee?.employeeId}>{employee?.employeeFullName}</MenuItem>
        //                         })
        //                     }

        //                 </Select>
        //             </FormControl>

        //             <FormControl
        //                 variant='standard'
        //                 className="formControl" style={{ width: "120px", margin: '0 13px', marginTop: '-65px' }}>
        //                 <InputLabel id="demo-simple-select-helper-label" style={{ fontSize: "14px" }}>
        //                     Year
        //                 </InputLabel>

        //                 <Select value={year}
        //                     onChange={(e) => setYear(e.target.value)}

        //                     inputProps={{ "aria-label": "Without label" }}>
        //                     <MenuItem value=''>Select a Year</MenuItem>
        //                     <MenuItem value={new Date().getFullYear()}>{new Date().getFullYear()}</MenuItem>
        //                     <MenuItem value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</MenuItem>

        //                 </Select>
        //             </FormControl>

        //             {window.innerWidth < 700 && <br />}

        //             <FormControl
        //                 variant='standard'
        //                 className="formControl"
        //                 disabled={!year}
        //                 style={{ width: "120px", margin: '0 13px', marginTop: '-65px' }}>
        //                 <InputLabel id="demo-simple-select-helper-label" style={{ fontSize: "14px" }}>
        //                     Month
        //                 </InputLabel>

        //                 <Select value={month}
        //                     onChange={(e) => setMonth(e.target.value)}

        //                     inputProps={{ "aria-label": "Without label" }}>
        //                     <MenuItem value=''>Select a Month</MenuItem>
        //                     <MenuItem value={1}>January</MenuItem>
        //                     <MenuItem value={2}>February</MenuItem>
        //                     <MenuItem value={3}>March</MenuItem>
        //                     <MenuItem value={4}>April</MenuItem>
        //                     <MenuItem value={5}>May</MenuItem>
        //                     <MenuItem value={6}>June</MenuItem>
        //                     <MenuItem value={7}>July</MenuItem>
        //                     <MenuItem value={8}>August</MenuItem>
        //                     <MenuItem value={9}>September</MenuItem>
        //                     <MenuItem value={10}>October</MenuItem>
        //                     <MenuItem value={11}>November</MenuItem>
        //                     <MenuItem value={12}>December</MenuItem>

        //                 </Select>
        //             </FormControl>

        //             <FormControl className="formControl" >
        //                 <button
        //                     onClick={reset}
        //                     style={{
        //                         backgroundColor: "white",
        //                         color: "black",
        //                         border: "1px solid black",
        //                         borderRadius: "20px",
        //                         padding: "5px",
        //                         width: "100px",
        //                         margin: "auto",
        //                         marginTop: '-50px'
        //                     }}
        //                 >
        //                     Reset Filter
        //                 </button>
        //             </FormControl>
        //         </div>
        //     ),
        // }}
      ></MaterialTable>
    </div>
  );
};

export default Rejected;
