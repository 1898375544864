import {makeStyles} from '@mui/styles';
// import { ThemeProvider } from '@mui/styles';

export const useStyles = makeStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {

      backgroundColor: '#FFFFFF',
      border: "2px solid #000",
      // boxShadow: theme.shadows[5],
      padding: 10,
      paddingTop : 30,
      paddingBottom : 30,
      maxWidth : 700
    },
    formControl: {
      margin: 20,
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: 10,
    },
  });