import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from '../../utils/config/url';
import { useStyles } from "../../utils/ModalStyles";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@material-ui/icons";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";

const FixedLegalCharges = () => {
  const classes = useStyles();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const siteCode = useParams().siteCode;
  const Token = "bearer" + " " + userInfo.token;
  const navigate = useNavigate();

  const [lg, setLg] = useState([]);
  const [selectedLg, setSelectedLg] = useState([]);
  const [deleteLg, setDeleteLg] = useState();

  const [type, setType] = useState("");
  const [bhk, setBhk] = useState("");
  const [gst, setGst] = useState("");
  const [amount, setAmount] = useState("");
  const [desc, setDesc] = useState("");
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);

  useEffect(() => {
    getAllLegalCharges();
  }, []);

  const getAllLegalCharges = () => {
    axios
      .get(
        `${BASE_URL}/api/v1/Configuration/Site/getAllLegalCharges/${siteCode}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        // console.log(response)
        setLg(response.data.legalCharges);
      });
  };

  const handleAddLegalCharges = (e) => {
    e.preventDefault();

    axios
      .post(
        `${BASE_URL}/api/v1/Configuration/Site/addLegalcharge`,
        {
          siteCode: siteCode,
          chargeType: type,
          bhk: bhk,
          amount: parseInt(amount),
          gst: parseInt(gst),
          description: desc,
        },
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        //console.log(response)

        if (response.status === 200) {
          setModal1(false);
          if (response.data.status === true) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: response.data.message,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then(function () {
              getAllLegalCharges();
              reset();
            });
          } else if (response.data.status === false) {
            setModal1(false);
            Swal.fire({
              icon: "error",
              title: "Error",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
              text: response.data.message,
            });
          }
        }
      });
  };

  const handelClose1 = () => {
    setModal1(false);
    reset();
  };

  const reset = () => {
    setType("");
    setBhk("");
    setAmount();
    setGst();
    setDesc("");
  };

  const handleEditLegalCharges = (e) => {
    e.preventDefault();

    const body = {
      ...selectedLg,
      siteCode: siteCode,
      amount: parseInt(selectedLg.amount),
      gst: parseInt(selectedLg.gst),
    };

    axios
      .put(`${BASE_URL}/api/v1/Configuration/Site/updateLegalCharges`, body, {
        headers: { Authorization: Token },
      })
      .then((response) => {
        setModal2(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getAllLegalCharges();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.err,
          });
        }
      });
  };

  const handleDeleteLegalCharge = () => {
    axios
      .delete(
        `${BASE_URL}/api/v1/Configuration/Site/deleteLegalCharge?siteCode=${deleteLg.siteCode}&bhk=${deleteLg.bhk}&chargeType=${deleteLg.chargeType}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        setModal3(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getAllLegalCharges();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.err,
          });
        }
      });
  };

  return (
    <div className="mt-2 container-fluid justify-content-center align-item-center">
      <div className="heading">
        <button
          className="addbtn"
          onClick={() => {
            setModal1(true);
          }}
        >
          Add
        </button>
      </div>
      <br />
      <table className="table">
        <thead style={{ backgroundColor: "#EE4B46", color: "#fff" }}>
          <tr>
            <th scope="col">Type</th>
            <th scope="col">Description</th>
            <th scope="col">BHK</th>
            <th scope="col">Amount</th>
            <th scope="col">GST</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {lg.map((l, index) => (
            <tr key={index}>
              <td>{l.chargeType}</td>
              <td>{l.description}</td>
              <td>{l.bhk}</td>
              <td>{l.amount}</td>
              <td>{l.gst}</td>

              <td>
                <EditIcon
                  style={{ marginRight: "30px" }}
                  onClick={() => {
                    setModal2(true);
                    lg.forEach((lg) => {
                      if (lg._id === l._id) {
                        setSelectedLg(l);
                      }
                    });
                  }}
                />

                <Delete
                  onClick={() => {
                    setModal3(true);
                    setDeleteLg({
                      siteCode: siteCode,
                      chargeType: l.chargeType,
                      bhk: l.bhk,
                    });
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal1}
        onClose={handelClose1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal1}>
          <div className={classes.paper}>
            <form
              method="POST"
              onSubmit={(e) => handleAddLegalCharges(e)}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="row container-fluid justify-content-center">
                <div className="col-6">
                  <Form.Group controlId="bhk">
                    <Form.Label>Type</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="">Select a Type</option>
                      <option value="Initial">Initial</option>
                      <option value="Handover">Handover</option>
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-6">
                  <Form.Group controlId="bhk">
                    <Form.Label>BHK</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      onChange={(e) => setBhk(e.target.value)}
                    >
                      <option value="">Select a BHK</option>
                      <option value="1BHK">1BHK</option>
                      <option value="2BHK">2BHK </option>
                      <option value="3BHK">3BHK</option>
                      <option value="4BHK">4BHK</option>
                      <option value="Duplex">Duplex</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>

              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-6">
                  <label>Amount</label>
                  <input
                    required
                    className="form-control"
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>

                <div className="col-6">
                  <label>
                    GST <em style={{ color: "#EE4B46" }}>(percentage)</em>
                  </label>
                  <input
                    required
                    className="form-control"
                    type="number"
                    value={gst}
                    onChange={(e) => setGst(e.target.value)}
                  />
                </div>
              </div>

              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-12">
                  <label>Description</label>
                  <textarea
                    required
                    className="form-control"
                    type="text"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </div>
              </div>

              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-12 text-center">
                  <button className="modal__btns" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>

      {/* ///////////////////// Modal for Editing Legal Charge ///////////////// */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal2}
        onClose={() => setModal2(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal2}>
          <div className={classes.paper}>
            <form
              method="POST"
              onSubmit={(e) => handleEditLegalCharges(e)}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="row container-fluid justify-content-center">
                <div className="col-6">
                  <label>Type</label>
                  <input
                    className="form-control"
                    value={selectedLg.chargeType}
                    readOnly
                  />
                </div>

                <div className="col-6">
                  <label>BHK</label>
                  <input
                    className="form-control"
                    value={selectedLg.bhk}
                    readOnly
                  />
                </div>
              </div>

              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-6">
                  <label>Amount</label>
                  <input
                    required
                    className="form-control"
                    type="number"
                    value={selectedLg.amount}
                    onChange={(e) =>
                      setSelectedLg((prev) => {
                        return {
                          ...prev,
                          amount: e.target.value,
                        };
                      })
                    }
                  />
                </div>

                <div className="col-6">
                  <label>
                    GST <em style={{ color: "#EE4B46" }}>(percentage)</em>
                  </label>
                  <input
                    required
                    className="form-control"
                    type="number"
                    value={selectedLg.gst}
                    onChange={(e) =>
                      setSelectedLg((prev) => {
                        return {
                          ...prev,
                          gst: e.target.value,
                        };
                      })
                    }
                  />
                </div>
              </div>

              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-12">
                  <label>Description</label>
                  <textarea
                    required
                    className="form-control"
                    type="text"
                    value={selectedLg.description}
                    onChange={(e) =>
                      setSelectedLg((prev) => {
                        return {
                          ...prev,
                          description: e.target.value,
                        };
                      })
                    }
                  />
                </div>
              </div>

              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-12 text-center">
                  <button className="modal__btns" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>

      {/* //////////////////////// Modal for Deleting Legal Charge ///////////////////// */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal3}
        onClose={() => setModal3(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal3}>
          <div className={classes.paper}>
            <h6>Are you sure you want to delete this ?</h6>
            <br />
            <div className="row container-fluid justify-content-center">
              <div className="col-6 text-right">
                <button
                  className="modal__btns"
                  onClick={handleDeleteLegalCharge}
                >
                  Yes
                </button>
              </div>
              <div className="col-6 text-left">
                <button
                  className="modal__btns"
                  onClick={(e) => {
                    e.preventDefault();
                    setModal3(false);
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default FixedLegalCharges;
