import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "./Quotation.scss";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import QuotationList from "../../components/Quotation/QuotationList";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import ClipLoader from "react-spinners/ClipLoader";

const GenerateQuotation = ({ siteNameList, leadID, invLead }) => {
  const [spinnerModal, setSpinnerModal] = useState(false);

  const [generate, setGenerate] = useState(false);
  const [siteName, setSiteName] = useState("");
  const [siteCode, setSiteCode] = useState("");
  const [phases, setPhases] = useState([]);
  const [phaseCode, setPhaseCode] = useState("");
  const [unit, setUnit] = useState("");
  const [unitLists, setUnitLists] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [carParking, setCarParking] = useState("No");
  const [parkingTypes, setParkingTypes] = useState([]);
  const [isAnyQuotationApproved, setIsAnyQuotationApproved] = useState(false);
  const [carParkings, setCarParkings] = useState([
    {
      carParkingTypeName: "",
      carParkingTypeCode: "",
      carParkingGrossPrice: "",
      siteCode: "",
      siteName: "",
      phaseCode: "",
      phaseName: "",
      carParkingGST: "",
      carParkingPrice: "",
      parkingCode: "",
      parkingName: "",
    },
  ]);
  const [quotations, setQuotations] = useState([]);

  const [change, setChange] = useState(false);
  const datas = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + datas.token;

  const leadId = useParams().leadId;

  // console.log(siteNameList)
  //get list of car parkings
  useEffect(() => {
    if (!siteCode) {
      setPhases();
      setPhaseCode("");
      setUnitLists();
      setUnit("");
      return setParkingTypes();
    }

    (async () => {
      const res = await axios.get(
        `${BASE_URL}/api/v1/Configuration/CarParkingType/getListOfCarParkingType/${siteCode}`,
        { headers: { Authorization: Token } }
      );
      const data = res?.data?.carParkingTypeList;
      setParkingTypes(data);
    })();
  }, [unit]);

  //get list of units
  useEffect(() => {
    if (!siteCode) {
      setPhaseCode("");
      setUnit("");
      setUnitLists();
      setPhases();
    }
    if (!phaseCode) {
      setUnit("");
      setUnitLists();
    }

    if (siteCode && phaseCode) {
      (async () => {
        const res = await axios.get(
          `${BASE_URL}/api/v1/Configuration/Unit/getlistofunit?siteCode=${siteCode}&phaseCode=${phaseCode}&status=Available`,
          { headers: { Authorization: Token } }
        );
        const data = res.data.unitList;
        setUnitLists(data);
      })();
    }
  }, [siteCode, phaseCode]);

  ///get site by code
  useEffect(() => {
    if (siteCode === undefined || siteCode === "") {
      setPhaseCode("");
      return setUnit("");
    }

    (async () => {
      const res = await axios.get(
        `${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`,
        { headers: { Authorization: Token } }
      );
      const data = res.data.site;
      const phaseList = data?.phases;
      setPhases(phaseList);
    })();
  }, [siteCode]);

  //get list of quotations
  useEffect(() => {
    getListOfQuotation();
  }, [change]);

  const getListOfQuotation = () => {
    (async () => {
      const res = await axios.get(
        `${BASE_URL}/api/v1/leadmgmt/quotation/getListOfQuotationByLeadId/${leadId}`,
        { headers: { Authorization: Token } }
      );
      const data = res.data.quotations;

      setQuotations(data);
    })();
  };
  useEffect(() => {
    // Check if any quotation is approved
    const anyQuotationApproved = quotations?.some(
      (quotation) => quotation?.isFinalApproved === "Approved"
    );
    setIsAnyQuotationApproved(anyQuotationApproved);
  }, [quotations]);

  //site change
  const handleSiteChange = (value) => {
    const separateValue = value.split(", ");

    setSiteName(separateValue[0]);
    setSiteCode(separateValue[1]);
    setPhaseCode("");
    setUnit("");
  };

  const addParkingType = (e) => {
    e.preventDefault();

    let values = [...carParkings];
    values.push({
      carParkingTypeName: "",
      carParkingTypeCode: "",
      carParkingGrossPrice: "",
      siteCode: "",
      siteName: "",
      phaseCode: "",
      phaseName: "",
      carParkingGST: "",
      carParkingPrice: "",
      parkingCode: "",
      parkingName: "",
    });

    setCarParkings(values);
  };

  const handleParkingTypeChange = (e, index) => {
    e.preventDefault();
    console.log(e.target.value);
    const data = e.target.value.split(",");    
    let values = [...carParkings];

    values[index].carParkingTypeName = data[0];
    values[index].carParkingTypeCode = data[1];
    values[index].carParkingGrossPrice = parseInt(data[2]);
    values[index].siteCode = data[3];
    values[index].siteName = data[4];
    values[index].phaseCode = data[5];
    values[index].phaseName = data[6];
    values[index].carParkingGST = parseInt(data[7]);
    values[index].carParkingPrice = parseInt(data[8]);
    values[index].parkingCode = data[9];
    values[index].parkingName = data[10];

    setCarParkings(values);
  };

  const deleteParkingType = (event, index) => {
    event.preventDefault();

    let values = [...carParkings];
    values.splice(index, 1);

    setCarParkings(values);
  };

  const reset = () => {
    setGenerate(false);
    setCarParkings([
      {
        carParkingTypeName: "",
        carParkingTypeCode: "",
        carParkingGrossPrice: "",
        siteCode: "",
        siteName: "",
        phaseCode: "",
        phaseName: "",
        carParkingGST: "",
        carParkingPrice: "",
        parkingCode: "",
        parkingName: "",
      },
    ]);
    setCarParking("");
    setParkingTypes([]);
    setPhases([]);
    setUnitLists([]);
    setSiteCode("");
    setPhaseCode("");
    setUnit("");
    setUnitLists();
  };

  //generate quotation
  const submit = (e) => {
    e.preventDefault();

    setSpinnerModal(true);

    let CarParkings;
    if (carParkings[0].carParkingTypeName === "") {
      CarParkings = [];
    } else {
      CarParkings = carParkings;
    }

    let body = {
      leadID: leadId,
      leadName: invLead?.name,
      unitName: unit,
      legalDiscount: parseInt(discount),
      carParkings: CarParkings,
      currentUserFullName: datas.userFullName,
    };

    axios
      .post(`${BASE_URL}/api/v1/leadmgmt/quotation/generateQuotation`, body, {
        headers: { Authorization: Token },
      })
      .then((response) => {
        // console.log(response)
        setSpinnerModal(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Quotation Successfully Generated",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(() => {
            reset();
            getListOfQuotation();
          });
        }
      })
      .catch((error) => {
        setSpinnerModal(false);
        // console.log(error);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="quote-container">
      <div className="quotation">
        <div className="">
          <button
            className="btn btn-user btn-block mx-auto"
            onClick={() => setGenerate(!generate)}
            // disabled={invLead.finalQuotation === true}
            disabled={
              isAnyQuotationApproved ||
              invLead.leadStatus != "InDiscussion" ||
              invLead?.isFinalApproved === "Approved"
            }
            style={{ color: "white" }}
          >
            Generate Quotation
          </button>
        </div>
        {generate && (
          <form className="customform" onSubmit={submit}>
            <div className="row justify-content-center mb-4">
              <div className="col-sm-12">
                <Form.Group controlId="site name">
                  <Form.Label>Site Name</Form.Label>
                  <Form.Control
                    required
                    onChange={(e) => handleSiteChange(e.target.value)}
                    as="select"
                  >
                    <option value="">Select a Site</option>
                    {siteNameList.map((ele, i) => (
                      <option
                        value={`${ele.SiteName}, ${ele.SiteCode}, ${ele}`}
                        key={i}
                      >
                        {ele.SiteName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>

            <div className="row justify-content-center mb-4">
              <div className="col-lg-6 col-sm-12">
                <Form.Group controlId="phase">
                  <Form.Label>Phase</Form.Label>
                  <Form.Control
                    required
                    value={phaseCode}
                    onChange={(e) => setPhaseCode(e.target.value)}
                    as="select"
                  >
                    <option value="">Select a phase</option>
                    {phases?.map((ele, i) => (
                      <option value={`${ele.phaseCode}`} key={i}>
                        {ele.phaseName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-lg-6 col-sm-12">
                <Form.Group controlId="unit">
                  <Form.Label>Unit</Form.Label>
                  <Form.Control
                    required
                    onChange={(e) => setUnit(e.target.value)}
                    value={unit}
                    as="select"
                  >
                    <option value="">Select a unit</option>
                    {unitLists?.map((ele, i) => (
                      <option value={`${ele.unitName}`} key={i}>
                        {ele.unitName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
            <div className="row justify-content-center mb-3">
              <div className="col-lg-6 col-sm-12">
                <label>Legal Charge Discount</label>
                <input
                  type="number"
                  defaultValue={0}
                  className="form-control"
                  name="Legal Charge Discount"
                  id="outlined-basic"
                  onChange={(e) => setDiscount(parseInt(e.target?.value))}
                />
                <br />
              </div>
            </div>
            <div className="mb-4">
              <div className="form-check form-check-inline">
                <span className="d-inline-block mr-3">
                  Car Parking required?{" "}
                </span>
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  required
                  value="Yes"
                  onClick={() => setCarParking("Yes")}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  required
                  value="No"
                  onClick={() => {
                    setCarParking("No");
                    setCarParkings([
                      {
                        carParkingTypeName: "",
                        carParkingTypeCode: "",
                        carParkingGrossPrice: 0,
                        siteCode: "",
                        siteName: "",
                        phaseCode: "",
                        phaseName: "",
                        carParkingGST: 0,
                        carParkingPrice: 0,
                        parkingCode: "",
                        parkingName: "",
                      },
                    ]);
                  }}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  No
                </label>
              </div>
            </div>

            {carParking === "Yes" && (
              <div>
                {carParkings?.map((r, index) => {
                  return (
                    <div className="row d-flex my-4" key={index}>
                      <div className="col-6">
                        <Form.Group controlId="car parking type">
                          <Form.Label>Car Parking Type</Form.Label>
                          <Form.Control
                            required
                            as="select"
                            // value={`${r.carParkingTypeName}-${r.carParkingTypeCode}-${r.carParkingGrossPrice}`}
                            defaultValue={r.carParkingTypeName}
                            onChange={(e) => {
                              handleParkingTypeChange(e, index);
                            }}
                          >
                            <option value="" selected>
                              Select a Car Parking Type{" "}
                            </option>
                            {parkingTypes?.map((p, i) => {
                              return (
                                <option
                                  key={i}
                                  value={`${p.carParkingTypeName},${p.carParkingTypeCode},${p.carParkingTypeGrossPrice},${p.siteCode},${p.siteName},${p.phaseCode},${p.phaseName},${p.carParkingGST},${p.carParkingPrice},${p.parkingCode},${p.parkingName}}`}
                                >
                                  {`${p.carParkingTypeName}`}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </div>

                      <div className="col-6">
                        <button
                          className="add-btn mt-4 mr-4"
                          onClick={(e) => {
                            addParkingType(e);
                          }}
                          style={{
                            display: index === 0 ? "inline-block" : "none",
                          }}
                        >
                          Add Row
                        </button>
                        <button
                          className="add-btn mt-4"
                          onClick={(event) => deleteParkingType(event, index)}
                          style={{
                            display: index === 0 ? "none" : "inline-block",
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            <br />

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-secondary btn-user btn-block mx-auto"
              >
                Generate
              </button>
            </div>
          </form>
        )}
      </div>
      <div className="mt-5">
        <QuotationList quotations={quotations} setChange={setChange} />
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={spinnerModal}
        onClose={() => setSpinnerModal(false)}
        closeAfterTransition
      >
        <Fade in={spinnerModal}>
          <div>
            <ClipLoader
              color="#EE4B46"
              loading={spinnerModal}
              size={70}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default GenerateQuotation;
