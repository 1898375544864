import React from 'react';
import "./SelfOnboard.scss";
import ViewWorkHistory from '../ViewWorkHistory/ViewWorkHistory';
import {Paper} from '@mui/material';

const UploadWorkHistory = ({employeeId,onBoardInfo, setActive}) => {
  return (
    <div className="selfOnboard">
      <Paper elevation={3} className="selfOnboard__paper">
        <ViewWorkHistory employeeId={employeeId} onBoardInfo={onBoardInfo}/>
        <br/>
        <div className="row justify-content-between">
          <div className="col-1">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setActive("edu");
              }}
            >
              Prev
            </button>
          </div>
          <div className="col-1">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setActive("signedOfferLtr");
              }}
            >
              Next
            </button>
          </div>
        </div>
      </Paper>
    </div>
  )
}

export default UploadWorkHistory