import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from '../../components/Button/Button';
import { BASE_URL } from '../../utils/config/url';
import './ViewUnitInspectionListType.scss';
import { Delete } from '@material-ui/icons'
import { useStyles } from '../../utils/ModalStyles';
import { BiUpArrowAlt,BiDownArrowAlt } from 'react-icons/bi'

const ViewUnitInspectionListType = () => {

    const classes = useStyles()
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const { inspectionListTypeId } = useParams()
    const navigate = useNavigate();

    const [siteName, setSiteName] = useState('')
    const [phaseName, setPhaseName] = useState('')
    const [bhk, setBhk] = useState('')
    const [listItems, setListItem] = useState([])
    const [serial, setSerial] = useState('')
    const [deleteModal, setDeleteModal] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [category, setCategory] = useState('')
    const [description, setDescription] = useState('')

    useEffect(() => {
        getUnitInspectionListType()
    }, [])


    const getUnitInspectionListType = () => {

        axios.get(`${BASE_URL}/api/v1/Configuration/UnitInspection/getUnitInspectionListType/${inspectionListTypeId}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)
                let data = response?.data?.unitInspectionListType
                setSiteName(data?.siteName)
                setPhaseName(data?.phaseName)
                setBhk(data?.bhk)
                setListItem(data?.listItems)
            })
    }

    const updateItemtoUnitInspectionListType = (e) => {
        e.preventDefault();

        axios.put(`${BASE_URL}/api/v1/Configuration/UnitInspection/updateItemtoUnitInspectionListType`, {
            InspectionListTypeId: inspectionListTypeId,
            description,
            category
        }, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)
                setAddModal(false)
                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        getUnitInspectionListType()
                        reset()
                    })
                }
            })
            .catch((err) => {
                setAddModal(false);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })

    }

    const deleteItemFromUnitInspectionListType = () => {

        axios.post(`${BASE_URL}/api/v1/Configuration/UnitInspection/deleteItemFromUnitInspectionListType`, {
            InspectionListTypeId: inspectionListTypeId,
            serial
        }, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)
                setDeleteModal(false)
                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        getUnitInspectionListType()
                        setSerial('')
                    })
                }
            })
            .catch((err) => {
                setDeleteModal(false);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })

    }

    const reset = () => {
        setCategory('')
        setDescription('')
    }


    return (
        <div className='view-unit-inspection-list-type'>

            <Button navigateTo={'/configurations/unitInspectionListType'} />

            <br /> <br />

            <form
                // onSubmit={(e) => createUnitInspectionListType(e)}
                style={{ width: window.innerWidth < 700 ? '100%' : '85%' }}
            >

                <div className='row justify-content-center'>
                    <div className='col-6'>
                        <label>Inspection List Type ID</label>
                        <input type="text" value={inspectionListTypeId} readOnly
                            className='form-control'
                        />
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>

                    <div className='col-4'>
                        <label>Site Name</label>
                        <input type="text" value={siteName} readOnly
                            className='form-control'
                        />
                    </div>

                    <div className='col-4'>
                        <label>Phase Name</label>
                        <input type="text" value={phaseName} readOnly
                            className='form-control'
                        />
                    </div>

                    <div className='col-4'>
                        <label>BHK</label>
                        <input type="text" value={bhk} readOnly
                            className='form-control'
                        />
                    </div>

                </div>
            </form>

            <br /><br />

            <div className='row justify-content-start'>
                <div className='col-6 d-flex justify-content-cenetr'>
                    <button className='view-unit-inspection-list-type__btn'
                        onClick={() => setAddModal(true)}
                    >Add List Item</button>
                </div>
            </div>

            <br />

            <table className="table">
                <thead
                    style={{ backgroundColor: "#EE4B46", color: "#fff" }}
                >
                    <tr>
                        <th scope="col">Serial</th>
                        <th scope="col" className='table__type'>
                            Type
                            <BiUpArrowAlt className='table__type__icon icon-up'
                                onClick={() => {
                                  
                                    const nextList = [...listItems];
                                    nextList.sort((a,b)=> a.category > b.category ? -1 : 1);
                                    setListItem(nextList);
                                   
                                }}
                            />
                            <BiDownArrowAlt className='table__type__icon icon-down'
                                onClick={() => {
                                    
                                    const nextList = [...listItems];
                                    nextList.sort((a,b)=> a.category > b.category ? 1 : -1);
                                    setListItem(nextList);
                                    
                                }}
                            />
                        </th>
                        <th scope="col">Description</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {listItems.map((l, index) => (
                        <tr key={index}>
                            <td>{l.serial}</td>
                            <td>{l.category}</td>
                            <td>{l.description}</td>

                            <td>
                                <Delete
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setSerial(l.serial)
                                        setDeleteModal(true)
                                    }}
                                />
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>

            {/* ///////////////////// Modal for Adding List Item ///////////////// */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={addModal}
                onClose={() => setAddModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={addModal}>
                    <div className={classes.paper}
                        style={{ width: '30%' }}
                    >
                        <form
                            method='POST'
                            onSubmit={(e) => updateItemtoUnitInspectionListType(e)}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>

                            <div className="row container-fluid justify-content-center">
                                <div className="col-12">
                                    <Form.Group controlId="bhk">
                                        <label>Category</label>
                                        <Form.Control as="select"
                                            required
                                            value={category}
                                            onChange={(e) => setCategory(e.target.value)}
                                        >
                                            <option value="">Select an option</option>
                                            <option value="CivilWork">CivilWork</option>
                                            <option value="Electrical">Electrical</option>
                                            <option value="Plumbing">Plumbing</option>
                                            <option value="Other">Other</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>


                            </div>

                            <br />

                            <div className="row container-fluid justify-content-center">
                                <div className="col-12">
                                    <label>Description</label>
                                    <textarea required
                                        className='form-control'
                                        type='text'
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)} />
                                </div>
                            </div>

                            <br />

                            <div className="row container-fluid justify-content-center">
                                <div className="col-6 text-center">
                                    <button
                                        className="modal__btns"
                                        type="submit"

                                    >
                                        Save
                                    </button>
                                </div>
                                <div className="col-6 text-center">
                                    <button
                                        className="modal__btns"
                                        type="click"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setAddModal(false)
                                            reset()
                                        }}
                                        style={{
                                            backgroundColor: 'white',
                                            color: 'black'
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>

            {/* //////////////////////// Modal for Deleting Inspection List Type ///////////////////// */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={deleteModal}>
                    <div className={classes.paper}>
                        <h4>Are you sure you want to delete the item ?</h4>
                        <br />
                        <div className="row container-fluid justify-content-center">
                            <div className="col-6 text-right">
                                <button
                                    className="modal__btns"
                                    onClick={deleteItemFromUnitInspectionListType}
                                >
                                    Yes
                                </button>
                            </div>
                            <div className="col-6 text-left">
                                <button
                                    className="modal__btns"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setDeleteModal(false)
                                    }}
                                    style={{
                                        backgroundColor: "white",
                                        color: "black",
                                    }}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>

        </div>
    )
}

export default ViewUnitInspectionListType;