import React, { useEffect, useState } from "react";
import "./ViewOffice.scss";
import { useParams } from "react-router-dom";
import "./ViewOffice.scss";
import Button from "../Button/Button";
import { BASE_URL } from "../../utils/config/url";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const ViewOffice = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { token } = userInfo;
  const officeCode = useParams().officeCode;
  const [office, setOffice] = useState();
  const [officeAddress, setOfficeAddress] = useState({
    fullAddress: "",
    landMark: "",
    city: "",
    state: "",
    pinCode: "",
  });
  const [loading, setLoading] = useState(true);

  const getOfficeByCode = async (officeCode, token) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/hrmgmt/office/getOfficeByOfficeCode/${officeCode}`,
        {
          headers: { Authorization: `bearer ${token}` },
        }
      );
      return response.data.Office;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  useEffect(() => {
    getOfficeByCode(officeCode, token).then((data) => {
      setOffice(data);
      setOfficeAddress({
        fullAddress: data.officeAddress.fullAddress,
        landMark: data.officeAddress.landMark,
        city: data.officeAddress.city,
        state: data.officeAddress.state,
        pinCode: data.officeAddress.pinCode,
      });
    });
  }, [loading]);

  const updateOffice = (e) => {
    e.preventDefault();
    axios
      .put(
        `${BASE_URL}/api/v1/hrmgmt/office/updateOffice`,
        {
          officeCode: officeCode,
          update: {
            officeAddress: {
              fullAddress: officeAddress.fullAddress,
              landMark: officeAddress.landMark,
              city: officeAddress.city,
              state: officeAddress.state,
              pinCode: officeAddress.pinCode,
            },
          },
        },
        {
          headers: { Authorization: `bearer ${token}` },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          Swal.fire({
            title: "Error",
            text: response.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
  };

  const generateQRCode = (e) => {
    e.preventDefault();
    axios
      .post(
        `${BASE_URL}/api/v1/hrmgmt/office/generateQRCode`,
        {
          officeCode: officeCode,
        },
        {
          headers: { Authorization: `bearer ${token}` },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              setLoading(!loading);
            }
          });
        } else {
          Swal.fire({
            title: "Error",
            text: response.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
  };

  const deleteQRCode = (e) => {
    e.preventDefault();
    axios
      .delete(`${BASE_URL}/api/v1/hrmgmt/office/deleteQRCode`, {
        data: { officeCode }, 
        headers: { Authorization: `bearer ${token}` },
      })
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              setLoading(!loading);
            }
          });
        } else {
          Swal.fire({
            title: "Error",
            text: response.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <div className="view-office">
      <Button navigateTo="/office" />
      <br />
      <br />
      <Paper elevation={4} className="view-office__paper">
        <h4>View Office</h4>
        <br />
        <div className="row justify-content-center">
          <div className="col-3">
            <label>Office Code</label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter Office Code"
              disabled
              value={office?.officeCode}
            />
          </div>
          <div className="col-3">
            <label>Office Name</label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter Office Name"
              disabled
              value={office?.officeName}
            />
          </div>
          <div className="col-3">
            <label>Office Latitude</label>
            <input
              className="form-control"
              type="number"
              placeholder="Enter Office Latitude"
              disabled
              value={office?.latitude}
            />
          </div>
          <div className="col-3">
            <label>Office Longitude</label>
            <input
              className="form-control"
              type="number"
              placeholder="Enter Office Longitude"
              disabled
              value={office?.longitude}
            />
          </div>
        </div>
        <br />
        <div className="row justify-content-start">
          <div className="col-6">
            <label>Office Full Address</label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter Office Full Address"
              value={officeAddress?.fullAddress}
              onChange={(e) => {
                setOfficeAddress({
                  ...officeAddress,
                  fullAddress: e.target.value,
                });
              }}
            />
          </div>
          <div className="col-6">
            <label>Office Landmark</label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter Office Landmark"
              value={officeAddress?.landMark}
              onChange={(e) => {
                setOfficeAddress({
                  ...officeAddress,
                  landMark: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <br />
        <div className="row justify-content-start">
          <div className="col-3">
            <label>Office City</label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter Office City"
              value={officeAddress?.city}
              onChange={(e) => {
                setOfficeAddress({
                  ...officeAddress,
                  city: e.target.value,
                });
              }}
            />
          </div>
          <div className="col-3">
            <label>Office State</label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter Office State"
              value={officeAddress?.state}
              onChange={(e) => {
                setOfficeAddress({
                  ...officeAddress,
                  state: e.target.value,
                });
              }}
            />
          </div>
          <div className="col-3">
            <label>Office Pincode</label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter Office Pincode"
              value={officeAddress?.pinCode}
              onChange={(e) => {
                setOfficeAddress({
                  ...officeAddress,
                  pinCode: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <button className="view-office__btn" onClick={updateOffice}>
            Update Office
          </button>
        </div>
      </Paper>
      <br />
      <Paper elevation={4} className="view-office__paper">
        <div className="row justify-content-between align-items-center">
          <div className="col-3">
            <h4>Generate QR Code</h4>
          </div>
          <div className="col-8">
            <div className="row justify-content-end align-items-center ml-4">
              <div className="col-3">
                <button
                  className="view-office__btn"
                  onClick={generateQRCode}
                  disabled={office?.qrCode !== "Not Generated"}
                  style={{
                    backgroundColor:
                      office?.qrCode !== "Not Generated" ? "grey" : "",
                  }}
                >
                  Generate QR Code
                </button>
              </div>
              <div className="col-3">
                <button
                  className="view-office__btn"
                  onClick={deleteQRCode}
                  disabled={office?.qrCode === "Not Generated"}
                  style={{
                    backgroundColor:
                      office?.qrCode === "Not Generated" ? "grey" : "",
                  }}
                >
                  Delete QR Code
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
        {office?.qrCode !== "Not Generated" && (
          <>
            <div className="row justify-content-center">
              <div
                className="col-6"
                style={{ width: "15.625rem", height: "15.625rem" }}
              >
                <img
                  src={office?.qrCode}
                  alt="QR Code"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="row justify-content-center col-6">
                <a href={office?.qrCode} download>
                  <button className="view-office__btn">Download QR Code</button>
                </a>
              </div>
            </div>
          </>
        )}
      </Paper>
    </div>
  );
};

export default ViewOffice;
