import React, { useEffect, useState } from "react";
import { IoMdArrowBack } from 'react-icons/io'
import Swal from "sweetalert2";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import { useNavigate } from "react-router-dom"
import './AddSiteForm.scss';
import Button from "../../components/Button/Button";

const AddSiteForm = () => {

  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const [siteCode, setSiteCode] = useState("");
  const [siteName, setSiteName] = useState("");
  const [gst , setGst] = useState('')
  const [companyName, setCompanyName] = useState("");
  const [bsNumber, setBsnumber] = useState("");
  const [siteDesc, setSiteDesc] = useState("");
  const [address, setAddress] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pin, setPin] = useState();
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [numberEF, setNumberEF] = useState();
  const [numberOfFloor, setNumberOfFloor] = useState();
  const [floorEscalationCharge, setFloorEscalationCharge] = useState();
  const [unitGSTPercentage, setUnitGSTPercentage] = useState();
  const [isValidRole, SetIsValidRole] = useState(true);
  const [pcError, setPcError] = useState('')
  const [scError, setScError] = useState('');

  const submit = (e) => {
    e.preventDefault();
    const Token = 'bearer' + " " + userInfo.token;
    const newPin = pin.toString()

    if (newPin.length > 6 || newPin.length < 6) {
      setPcError('Pincode must be of 6 digits')
      setTimeout(() => {
        setPcError('')
      }, 5000)
      return
    }

    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if (siteCode.length < 2 || siteCode.length > 4) {
      return setScError('Site Code must be greater than 1 but less than 5 characters')
    } else if (/\s/.test(siteCode)) {
      return setScError('Site Code cannot contain any white space')
    } else if (specialChars.test(siteCode)) {
      return setScError(`Site code can't contain any special characters`)
    } else if (/[a-z]/.test(siteCode)) {
      return setScError('Site Code can contain only numbers or uppercase letters')
    } else {
      setScError('')
    }


    axios.post(`${BASE_URL}/api/v1/Configuration/Site/addNewSite`,
      {
        siteName: siteName,
        siteDescription: siteDesc,
        siteAddress: {
          fullAddress: address,
          landmark: landmark,
          city: city,
          pinCode: pin,
          state: state,
        },
        siteActive: true,
        gstinNumber : gst,
        buildingPlanSanctionNo: bsNumber,
        siteCompanyName: companyName,
        siteCode: siteCode,
        noEscalationFloor: numberEF,
        noOfFloor: numberOfFloor,
        floorEscalationCharge: floorEscalationCharge,
        unitGSTPercentage: unitGSTPercentage

      },
      { headers: { 'Authorization': Token } })
      .then(response => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            }
          }).then(function () {
            window.location = "/configurations/site";
          })
        }
        else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message
          })
        }
      })
  }

  const reset = () => {
    setSiteCode("");
    setSiteName("");
    setCompanyName("");
    setBsnumber("");
    setSiteDesc("");
    setAddress("");
    setLandmark("");
    setPin();
    setCity("");
    setState("");
    setNumberEF();
    setNumberOfFloor();
    setFloorEscalationCharge();
    setUnitGSTPercentage();
  }

  useEffect(() => {
    checkRole()
  }, [])

  const checkRole = () => {

    if (userInfo.userRole === "Director" || userInfo.userRole === "ITAdmin") {
      SetIsValidRole(true);
    }
    else {
      SetIsValidRole(false);
      Swal.fire({
        icon: "error",
        title: "Sorry",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: "You do not have access to create New Site. Please contact your Senior Management",
      }).then(function () {
        navigate('/configurations/site')
      })
    }
  }

  return (
    <div className="addsite">

      <div className="addsite__btn">
        <Button navigateTo={'/configurations/site'} />
      </div>


      <div className="customform">
        <h2>Site Details</h2>
        <form onSubmit={submit} style={{ display: isValidRole ? 'block' : 'none' }}>
          <div className="row justify-content-center">
            <div className="col-4">
              <label>Site Code</label>
              <input
                type="text"
                className="form-control"
                name="siteCode"
                value={siteCode}
                required
                autoComplete="off"
                onChange={(e) => {
                  setSiteCode(e.target.value)

                  if (e.target.value.length === 1 || e.target.value.length > 4) {
                    console.log('enter')
                    setScError('Site Code must be greater than 1 but less than 5 characters')

                  } else if (/\s/.test(e.target.value)) {
                    setScError('Site Code cannot contain any white space')
                  } else if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(e.target.value)) {
                    setScError(`Site code can't contain any special characters`)
                  } else if (/[a-z]/.test(e.target.value)) {
                    setScError('Site Code can contain only numbers or uppercase letters')
                  } else {
                    setScError('')
                  }

                }}
              />

              {scError && <p style={{ fontSize: '15px', color: '#EE4B46', marginTop: '5px' }}>{scError}</p>}
            </div>
            <div className="col-4">
              <label>Site Name</label>
              <input
                type="text"
                className="form-control"
                name="siteName"
                value={siteName}
                autoComplete="off"
                required
                onChange={(e) => setSiteName(e.target.value)}
              />
            </div>
          </div>
          <br />

          <div className="row justify-content-center">
            <div className="col-4">
              <label>Site Company Name</label>
              <input
                type="text"
                className="form-control"
                name="siteCompanyName"
                value={companyName}
                autoComplete="off"
                required
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div className="col-4">
              <label>Building Plan Sanction No.</label>
              <input
                type="text"
                className="form-control"
                name="buildingPlanSanctionNo"
                value={bsNumber}
                autoComplete="off"
                required
                onChange={(e) => setBsnumber(e.target.value)}
              />
            </div>
            <div className="col-4">
              <label>GSTIN No.</label>
              <input
                type="text"
                className="form-control"
                name="siteCompanyName"
                value={gst}
                autoComplete="off"
                required
                onChange={(e) => setGst(e.target.value)}
              />
            </div>
          </div>
          <br />

          <div className="row justify-content-center">
            <div className="col-12">
              <label>Site Description</label>
              <textarea
                col='12'
                type="text"
                className="form-control"
                name="siteDescription"
                value={siteDesc}
                autoComplete="off"
                required
                onChange={(e) => setSiteDesc(e.target.value)}
              />
            </div>
          </div>
          <br />

          <div className="row justify-content-center">
            <div className="col-12">
              <label>Full Address</label>
              <textarea
                col='12'
                type="text"
                className="form-control"
                name="fullAddress"
                value={address}
                autoComplete="off"
                required
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
          <br />

          <div className="row justify-content-center">
            <div className="col-4">
              <label>Landmark</label>
              <input
                type="text"
                className="form-control"
                name="landmark"
                value={landmark}
                autoComplete="off"
                required
                onChange={(e) => setLandmark(e.target.value)}
              />
            </div>
            <div className="col-4">
              <label>Pincode</label>
              <input
                type="number"
                className="form-control"
                name="pinCode"
                value={pin}
                autoComplete="off"
                required
                onChange={(e) => {
                  if (e.target.value.length > 6 || e.target.value.length < 6) {
                    setPcError('Pincode must be of 6 digits')
                    setPin(e.target.value)
                  } else {
                    setPin(e.target.value)
                    setPcError('')
                  }
                }}
              />
              {pcError && <p style={{ color: '#EE4B46', marginTop: '10px' }}>{pcError}</p>}
            </div>
          </div>
          <br />

          <div className="row justify-content-center">
            <div className="col-4">
              <label>City</label>
              <input
                type="text"
                className="form-control"
                name="city"
                value={city}
                autoComplete="off"
                required
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="col-4">
              <label>State</label>
              <input
                type="text"
                className="form-control"
                name="state"
                value={state}
                autoComplete="off"
                required
                onChange={(e) => setState(e.target.value)}
              />
            </div>
          </div>
          <br />

          <div className="row justify-content-center">
            <div className="col-4">
              <label>Number of Escalation Floors</label>
              <input
                type="number"
                className="form-control"
                name="noEscalationFloor"
                value={numberEF}
                autoComplete="off"
                required
                onChange={(e) => setNumberEF(e.target.value)}
              />
            </div>
            <div className="col-4">
              <label>Number of Floors</label>
              <input
                type="number"
                className="form-control"
                name="noOfFloor"
                value={numberOfFloor}
                autoComplete="off"
                required
                onChange={(e) => setNumberOfFloor(e.target.value)}
              />
            </div>
          </div>
          <br />

          <div className="row justify-content-center">
            <div className="col-4">
              <label>Floor Escalation Charge</label>
              <input
                type="number"
                className="form-control"
                name="floorEscalationCharge"
                value={floorEscalationCharge}
                autoComplete="off"
                required
                onChange={(e) => setFloorEscalationCharge(e.target.value)}
              />
            </div>
            <div className="col-4">
              <label>Unit GST Percentage</label>
              <input
                type="number"
                className="form-control"
                name="unitGSTPercentage"
                value={unitGSTPercentage}
                autoComplete="off"
                required
                onChange={(e) => setUnitGSTPercentage(e.target.value)}
              />
            </div>
          </div>
          <br /><br />

          <div className="customform__btns">
            <button type="reset" style={{ backgroundColor: "white", color: "black", border: '1px solid black' }} onClick={reset}>Reset</button>
            <button >Save</button>
          </div>
        </form>
      </div>
    </div>

  );
}

export default AddSiteForm;