import { FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material'
import React, { useEffect } from 'react'
import axios from 'axios';
import { BASE_URL } from '../../../utils/config/url';
import MaterialTable from 'material-table';
import Button from '../../Button/Button';

const SearchCatalogueItem = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [text, setText] = React.useState('')
    const [category, setCategory] = React.useState('')
    const [categoryList, setCategoryList] = React.useState([])
    const [subCategory, setSubCategory] = React.useState('')
    const [subCategoryList, setSubCategoryList] = React.useState([])
    const [brand, setBrand] = React.useState('')
    const [brandList, setBrandList] = React.useState([])
    const [orderable, setOrderable] = React.useState('')
    const [upgradeItem, setUpgradeItem] = React.useState('')
    const [catalogueList, setCatalogueList] = React.useState([])


    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/stockmgmtapi/catCategory/getListOfCatCategory`,
            {
                headers: {
                    Authorization: `Bearer ${userInfo?.token}`
                }
            })
            .then(res => {
                // console.log(res.data)
                if (res.data.status) {
                    setCategoryList(res.data.CatelogueCategory)
                } else {
                    setCategoryList([])
                }
            })

    }, [])


    useEffect(() => {

        if (!category) {
            setSubCategoryList([])
            setSubCategory('')
            return;
        }

        let url = `${BASE_URL}/api/v1/stockmgmtapi/catSubCategory/getListOfCatSubCategory`;

        let filter = '';

        if (category) {
            filter += `?parentCategoryCode=${category}`
        }

        url += filter;

        axios.get(url,
            {
                headers: {
                    Authorization: `Bearer ${userInfo?.token}`
                }
            })
            .then(res => {
                // console.log(res.data)
                if (res.data.status) {
                    setSubCategoryList(res.data.CatSubCategory)
                } else {
                    setSubCategoryList([])
                }
            })

    }, [category])


    useEffect(() => {

        let url = `${BASE_URL}/api/v1/stockmgmtapi/brand/getListOfBrand`;

        axios.get(url,
            {
                headers: {
                    Authorization: `Bearer ${userInfo?.token}`
                }
            })
            .then(res => {
                // console.log(res.data)
                if (res.data.status) {
                    setBrandList(res.data.Brand)
                } else {
                    setBrandList([])
                }
            })

    }, [])


    const textSearch = () => {


        let url = `${BASE_URL}/api/v1/stockmgmtapi/catalougeItem/catalogueItemKeywordSearch?text=${text}`;

        axios.get(url,
            {
                headers: {
                    Authorization: `Bearer ${userInfo?.token}`
                }
            })
            .then(res => {
                // console.log(res.data)
                if (res.data.status) {
                    setCatalogueList(res.data.catalogueItems)
                } else {
                    setCatalogueList([])
                }
            })


    }


    const advanceSearch = () => {

        // console.log(orderable , upgradeItem)

        let url = `${BASE_URL}/api/v1/stockmgmtapi/catalougeItem/getListOfCatalogueItem?`;

        let filter = '';

        if (category) {
            filter += `categoryCode=${category}&`
        }

        if (subCategory) {
            filter += `subCategoryCode=${subCategory}&`
        }

        if (brand) {
            filter += `brandCode=${brand}&`
        }

        if (orderable !== '') {
            // console.log('here')
            filter += `isOrderable=${orderable}&`
        }

        if (upgradeItem !== '') {
            filter += `isUpgradeItem=${upgradeItem}&`
        }

        url += filter;

        axios.get(url,
            {
                headers: {
                    Authorization: `Bearer ${userInfo?.token}`
                }
            })
            .then(res => {
                // console.log(res.data)
                if (res.data.status) {
                    setCatalogueList(res.data.CatelogueItem)
                } else {
                    setCatalogueList([])
                }
            })

    }



    const dateFormatting = (date) => {

        let str = new Date(date)

        let d = new Date(str).getDate();
        if (d < 10) {
            d = `0${d}`
        }
        let m = new Date(str).getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = new Date(str).getFullYear();

        return `${d}-${m}-${y}`
    }

    const resetTextSearch = () => {
        setText('')
    }

    const resetAdvanceSearch = () => {
        setCategory('')
        setSubCategory('')
        setBrand('')
        setOrderable('')
        setUpgradeItem('')
    }

    return (
        <div className='stock-mgmt'>

            <div className="row justify-content-start">
                    <Button navigateTo="/engineering/stockManagement" />
            </div>

            <h2 className='text-center mt-4' style={{ color: '#EE4B46' }}>Search Catalogue Item</h2>

            <Paper elevation={2} style={{ padding: '20px', margin: '20px 0' }}>

                <h4 className='text-center'>Text Search</h4>
                <div className='row justify-content-center mt-4'>
                    <div className='col-8 mt-3'>
                        <input type="text" className='form-control'
                            placeholder='Search by Catalogue Item Name or Code'
                            style={{
                                padding: '5px'
                            }}
                            value={text} onChange={(e) => setText(e.target.value)} />
                    </div>

                    <div className='col-1 text-center'>
                        <button className='stock-mgmt__btn'
                            style={{
                                width: 'auto'
                            }}
                            onClick={textSearch}
                        >Search</button>
                    </div>
                    <div className='col-1 text-center'>
                        <button className='stock-mgmt__btn'
                            style={{
                                width: 'auto'
                            }}
                            onClick={resetTextSearch}
                        >Reset</button>
                    </div>
                </div>
            </Paper>


            <Paper elevation={2} style={{ padding: '20px', margin: '20px 0' }}>

                <h4 className='text-center'>Advance Search</h4>

                <div className='row justify-content-center mt-4'>

                    <div className='col-4 text-center'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Category</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={category}
                                label="Category"
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                <MenuItem value="">Select a option</MenuItem>
                                {
                                    categoryList?.map((category, index) => {
                                        return <MenuItem key={index} value={category?.categoryCode}>{category?.categoryName}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>

                    <div className='col-4 text-center'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sub Category</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={subCategory}
                                disabled={!category}
                                label="Sub-Category"
                                onChange={(e) => setSubCategory(e.target.value)}
                            >
                                <MenuItem value="">Select a option</MenuItem>
                                {
                                    subCategoryList?.map((subCategory, index) => {
                                        return <MenuItem key={index} value={subCategory?.subCategoryCode}>{subCategory?.subCategoryName}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>

                    <div className='col-4 text-center'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Brand</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={brand}
                                label="Brand"
                                onChange={(e) => setBrand(e.target.value)}
                            >
                                <MenuItem value="">Select a option</MenuItem>
                                {
                                    brandList?.map((brand, index) => {
                                        return <MenuItem key={index} value={brand?.brandCode}>{brand?.brandName}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>

                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-4 text-center'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Orderable</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={orderable}
                                label="Orderable"
                                onChange={(e) => setOrderable(e.target.value)}
                            >
                                <MenuItem value="">Select a option</MenuItem>
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className='col-4 text-center'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Upgrade Item</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={upgradeItem}
                                label="Upgrade-Item"
                                onChange={(e) => setUpgradeItem(e.target.value)}
                            >
                                <MenuItem value="">Select a option</MenuItem>
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='text-center stock-mgmt__btn'
                        onClick={advanceSearch}
                    >Search</div>
                    <div className='text-center stock-mgmt__btn'
                        onClick={resetAdvanceSearch}
                    >Reset</div>
                </div>

            </Paper>

            <br /><br />

            <MaterialTable
                data={catalogueList}
                title="List Of Catalogue Item"

                columns={[
                    {
                        title: "Catalogue Item Code",
                        field: "catalogueItemCode",
                        render: (rowData) => {
                            return <a href={`/listOfCatalogueItem/${rowData?.catalogueItemCode}`}
                            style={{color : '#EE4B46', fontWeight : 'bold'}}
                            >{rowData?.catalogueItemCode}</a>
                        }
                    },
                    {
                        title: "Catalogue Item Name",
                        field: "catalogueItemName"
                    },
                    {
                        title: "Category Code",
                        field: "categoryCode"
                    },
                    {
                        title: "Category Name",
                        field: "categoryName"
                    },
                    {
                        title: "SubCategory Code",
                        field: "subCategoryCode"
                    },
                    {
                        title: "SubCategory Name",
                        field: "subCategoryName"
                    },
                    {
                        title: "Brand Code",
                        field: "brandCode"
                    },
                    {
                        title: "Brand Name",
                        field: "brandName"
                    },
                    {
                        title: "Orderable",
                        render: (rowData) => (rowData.isOrderable ? 'Yes' : 'No')
                    },
                    {
                        title: "Active",
                        render: (rowData) => (rowData.isActive ? 'Yes' : 'No')
                    },
                    {
                        title: "Added By",
                        field: 'addedByFullName'
                    },
                    {
                        title: 'Created At',
                        render: (rowData) => (dateFormatting(rowData.creationDate)),
                        defaultSort: 'desc',
                        customSort: (a, b) => (a.creationDate < b.creationDate ? -1 : 1)
                    }

                ]}

                // actions={[
                //     {
                //         icon: 'remove_red_eye',
                //         tooltip: 'View Catalogue Item',
                //         onClick: (event, rowData) => {
                //             navigate(`/listOfCatalogueItem/${rowData.catalogueItemCode}`)
                //         }
                //     },
                // ]}


                options={{
                    search: window.innerWidth < 700 ? false : true,
                    searchFieldStyle: {
                        width: 180
                    },
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#EE4B46',
                        color: '#fff',
                    }
                }}

            ></MaterialTable>


        </div>
    )
}

export default SearchCatalogueItem