import React, { useEffect } from 'react'
import './EditCostCenter.scss';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { AiFillDelete } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom'
import Button from '../../components/Button/Button';
import { alpha, styled } from "@mui/material/styles";
import { Paper, Switch } from "@mui/material";

const RedSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: "#EE4B46",
        "&:hover": {
            backgroundColor: alpha("#EE4B46", theme.palette.action.hoverOpacity),
        },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#EE4B46",
    },
}));


const EditCostCenter = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const costCenterCode = useParams().costCenterCode;

    const [employeeList, setEmployeeList] = React.useState([])
    const [bankAccounts, setBankAccounts] = React.useState([])
    const [costCenterName, setCostCenterName] = React.useState('')
    const [costCenterShortName, setCostCenterShortName] = React.useState('')
    const [finYear, setFinYear] = React.useState('')
    const [costCenterType, setCostCenterType] = React.useState('')
    const [costCenterOwner, setCostCenterOwner] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [costCenterOrganisation, setCostCenterOrganisation] = React.useState('')
    const [costCenterOwnerEmployeeId, setCostCenterOwnerEmployeeId] = React.useState('')
    const [edit, setEdit] = React.useState(false)
    const [isActive, setIsActive] = React.useState(false)
    const [siteCode, setSiteCode] = React.useState('')
    const [phaseCode, setPhaseCode] = React.useState('')
    const [officeName, setOfficeName] = React.useState('')

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/financemgmt/costCenter/getCostCenterByCostCenterCode/${costCenterCode}`, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status) {
                    let costCenter = response.data.Cost_Center;
                    setIsActive(costCenter.isActive)
                    setCostCenterName(costCenter.costCenterName)
                    setCostCenterShortName(costCenter.costCenterShortName)
                    setFinYear(costCenter.finYear)
                    setCostCenterType(costCenter.costCenterType)
                    setCostCenterOwner(`${costCenter.costCenterOwnerEmployeeId},${costCenter.costCenterOwner}`)
                    setDescription(costCenter.costCenterNameDescription)
                    setCostCenterOrganisation(costCenter.costCenterOrganisation)
                    setBankAccounts(costCenter.bankAccounts)
                    setCostCenterOwnerEmployeeId(costCenter.costCenterOwnerEmployeeId)
                    costCenter?.siteCode && setSiteCode(costCenter.siteCode)
                    costCenter?.phaseCode && setPhaseCode(costCenter.phaseCode)
                    costCenter?.officeName && setOfficeName(costCenter.officeName)
                }
            })

    }, [])


    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        })
            .then((response) => {
                if (response.data.status) {
                    setEmployeeList(response.data.employees)
                } else {
                    setEmployeeList([])
                }
            })

    }, []);

    const toggleActiveCostCenter = (active) => {
        axios.post(`${BASE_URL}/api/v1/financeMgmt/costCenter/toggleActiveCostCenter?`,
            {
                costCenterCode,
                isActive: active
            },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message
                    })
                        .then(() => {
                            // window.location.href = '/finance/costCenters'
                            setEdit(false)
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message
                    })
                }
            })
            .catch((err) => {
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error
                })
            })

    }

    const addRow = (e) => {

        e.preventDefault()

        let item = {
            serial: bankAccounts.length,
            accountHolderName: '',
            bankName: '',
            accountNumber: '',
            branchName: '',
            IFSCCode: ''
        }
        // console.log(item)
        setBankAccounts([...bankAccounts, item])

    }

    const deleteRow = (index) => {
        let temp = [...bankAccounts]
        // console.log(temp)
        let items = temp.filter((item, i) => (item.serial !== index))

        setBankAccounts(items)

    }

    const handleChange = (e, index, key) => {
        let temp = [...bankAccounts]
        temp[index][key] = e.target.value
        setBankAccounts(temp)
    }

    const submitCostCenter = (e) => {

        e.preventDefault()

        let body = {
            costCenterCode,
            update: {
                costCenterName: costCenterName,
                costCenterShortName: costCenterShortName,
                finYear: finYear,
                costCenterType: costCenterType,
                costCenterOwner: costCenterOwner.split(',')[1],
                costCenterOwnerEmployeeId: costCenterOwner.split(',')[0],
                costCenterNameDescription: description,
                costCenterOrganisation: costCenterOrganisation,
                bankAccounts: bankAccounts
            }
        }

        if (costCenterType === 'Site') {
            body.update = {
                ...body.update,
                siteCode: siteCode
            }
        } else if (costCenterType === 'Phase') {
            body.update = {
                ...body.update,
                siteCode: siteCode,
                phaseCode: phaseCode
            }
        } else if (costCenterType === 'Office') {
            body.update = {
                ...body.update,
                officeName: officeName
            }
        }

        axios.put(`${BASE_URL}/api/v1/FinanceMgmt/costCenter/updateCostCenterByCostCenterCode`, body, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        })
            .then((response) => {
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message
                    })
                        .then(() => {
                            // window.location.href = '/finance/costCenters'
                            setEdit(false)
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message
                    })
                }
            })
            .catch((err) => {
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error
                })
            })
    }


    return (
        <div className='add-cost-center'>

            <div className='row justify-content-center mt-4'>
                <div className='col-4 text-left'>
                    <Button navigateTo='/finance/costCenters' />
                </div>

                <div className='col-4 text-center'>
                    <h3 className='text-center'
                        style={{ color: '#EE4B46' }}
                    >View/Edit Cost Center</h3>
                </div>

                <div className='col-4 text-center'>
                    <span>Archived</span>
                    <RedSwitch
                        checked={isActive === true ? true : false}
                        onChange={() => {
                            toggleActiveCostCenter(!isActive)
                            setIsActive(!isActive)
                        }}
                        disabled={
                            userInfo.userRole != "Director" &&
                            userInfo?.userRole != 'Finance'
                        }
                        style={{
                            color:
                                (userInfo.userRole != "Director" &&
                                    userInfo?.userRole != 'Finance')
                                    ? "grey"
                                    : "",
                        }}
                    />
                    <span>Active</span>
                </div>

            </div>

            <br /><br />

            <form
                onSubmit={(e) => {
                    submitCostCenter(e)
                }}
            >

                <div className='row justify-content-center'>
                    <div className='col-4'>
                        <label>Cost Center Code</label>
                        <input type="text" required className='form-control'
                            value={costCenterCode}
                            readOnly
                        />
                    </div>

                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-4'>
                        <label>Cost Center Name</label>
                        <input type="text" required className='form-control'
                            value={costCenterName}
                            readOnly={!edit}
                            onChange={(e) => setCostCenterName(e.target.value)}
                        />
                    </div>

                    <div className='col-4'>
                        <label>Cost Center Short Name</label>
                        <input type="text" required className='form-control'
                            value={costCenterShortName}
                            readOnly={!edit}
                            onChange={(e) => setCostCenterShortName(e.target.value)}
                        />
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-4'>
                        {
                            edit
                                ?
                                <Form.Group controlId="year">
                                    <Form.Label>Financial Year</Form.Label>
                                    <Form.Control as="select" required
                                        value={finYear}
                                        onChange={(e) => {
                                            setFinYear(e.target.value)
                                        }}
                                    >
                                        <option value="">Select a Year</option>
                                        <option value='2022-23'>2022-23</option>
                                        <option value='2023-24'>2023-24</option>
                                        <option value='2024-25'>2024-25</option>
                                    </Form.Control>
                                </Form.Group>
                                :
                                <>
                                    <label>Financial Year</label>
                                    <input type="text" required className='form-control' readOnly value={finYear} />
                                </>
                        }

                    </div>

                    <div className='col-4'>

                        {
                            edit
                                ?
                                <Form.Group controlId="type">
                                    <Form.Label>Cost Center Type</Form.Label>
                                    <Form.Control as="select" required
                                        value={costCenterType}
                                        onChange={(e) => {
                                            setCostCenterType(e.target.value)
                                        }}
                                    >
                                        <option value="">Select a Type</option>
                                        <option value='Site'>Site</option>
                                        <option value='Phase'>Phase</option>
                                        <option value='Office'>Office</option>
                                        <option value='Corporate'>Corporate</option>
                                        <option value="IT">IT</option>
                                        <option value="Initiatives">Initiatives</option>
                                    </Form.Control>
                                </Form.Group>
                                :
                                <>
                                    <label>Cost Center Type</label>
                                    <input type="text" required className='form-control' readOnly value={costCenterType} />
                                </>
                        }

                    </div>
                </div>

                {
                    (costCenterType === 'Site' || costCenterType === 'Phase')
                        ?
                        <>
                            <br />

                            <div className='row justify-content-center'>
                                <div className='col-4'>
                                    <label>Site Code</label>
                                    <input type="text" required className='form-control'
                                        readOnly={!edit}
                                        value={siteCode}
                                        onChange={(e) => setSiteCode(e.target.value)}
                                    />
                                </div>

                                {
                                    costCenterType === 'Phase' &&
                                    <div className='col-4'>
                                        <label>Phase Code</label>
                                        <input type="text" required className='form-control'
                                            readOnly={!edit}
                                            value={phaseCode}
                                            onChange={(e) => setPhaseCode(e.target.value)}
                                        />
                                    </div>
                                }

                            </div>
                        </>
                        :
                        costCenterType === 'Office'
                            ?
                            <>
                                <br />
                                <div className='row justify-content-center'>
                                    <div className='col-4'>
                                        <label>Office Name</label>
                                        <input type="text" required className='form-control'
                                            readOnly={!edit}
                                            value={officeName}
                                            onChange={(e) => setOfficeName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </>
                            :
                            null
                }

                <br />

                <div className='row justify-content-center'>
                    <div className='col-4'>
                        {
                            edit ?
                                <Form.Group controlId="owner">
                                    <Form.Label>Cost Center Owner</Form.Label>
                                    <Form.Control as="select" required
                                        value={costCenterOwner}
                                        onChange={(e) => {
                                            setCostCenterOwner(e.target.value)
                                        }}
                                    >
                                        <option value="">Select a option</option>
                                        {
                                            employeeList?.map((employee, index) => {
                                                return <option key={index} value={`${employee?.employeeId},${employee?.employeeFullName}`}>{employee?.employeeFullName}</option>
                                            })
                                        }
                                    </Form.Control>
                                </Form.Group>
                                :
                                <>
                                    <label>Cost Center Owner</label>
                                    <input type="text" required className='form-control' readOnly value={costCenterOwner.split(',')[1]} />
                                </>
                        }

                    </div>

                    <div className='col-4'>
                        <label>Cost Center Owner Employee Id</label>
                        < input type="text" className='form-control' readOnly value={costCenterOwner.split(',')[0]} />
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>

                    <div className='col-4'>
                        <label>Cost Center Description </label>
                        < textarea type="text" required className='form-control'
                            readOnly={!edit}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>

                    <div className='col-4'>
                        {
                            edit
                                ?
                                <Form.Group controlId="org">
                                    <Form.Label>Cost Center Organisation</Form.Label>
                                    <Form.Control as="select" required
                                        value={costCenterOrganisation}
                                        onChange={(e) => {
                                            setCostCenterOrganisation(e.target.value)
                                        }}
                                    >
                                        <option value="">Select a option</option>
                                        <option value="WRL">WRL</option>
                                        <option value="WRHIL">WRHIL</option>
                                        <option value="WRDPL">WRDPL</option>
                                    </Form.Control>
                                </Form.Group>
                                :
                                <>
                                    <label>Cost Center Organisation</label>
                                    <input type="text" required className='form-control' readOnly value={costCenterOrganisation} />
                                </>
                        }

                    </div>
                </div>

                <br />

                {
                    edit
                    &&
                    <div div className='row justify-content-center'>
                        <div className='col-6 text-center'>
                            <button className='add-cost-center__btn pt-2'
                                onClick={(e) => addRow(e)}
                            >Add Bank Accounts</button>
                        </div>
                    </div>
                }


                {
                    bankAccounts?.map((item, index) => {

                        return <Paper key={index} elevation={2} style={{ padding: "20px", margin: 'auto', marginTop: "40px", width: '90%' }}>
                            <div className='row justify-content-center mt-4' key={index}>
                                <div className='col-10 row d-flex flex-wrap text-center'>
                                    <div className='col-4 mt-2'>
                                        <label>Account Holder Name</label>
                                        <input type="text" className='form-control' required value={item?.accountHolderName}
                                            readOnly={!edit}
                                            onChange={(e) => handleChange(e, index, 'accountHolderName')}
                                        />
                                    </div>

                                    <div className='col-4 mt-2'>
                                        <label>Bank Name</label>
                                        <input type="text" className='form-control' required value={item?.bankName}
                                            readOnly={!edit}
                                            onChange={(e) => handleChange(e, index, 'bankName')}
                                        />
                                    </div>

                                    <div className='col-4 mt-2'>
                                        <label>Account Number</label>
                                        <input type="text" className='form-control' required value={item?.accountNumber}
                                            readOnly={!edit}
                                            onChange={(e) => handleChange(e, index, 'accountNumber')}
                                        />
                                    </div>

                                    <div className='col-4 mt-2'>
                                        <label>Branch Name</label>
                                        <input type="text" className='form-control' required value={item?.branchName}
                                            readOnly={!edit}
                                            onChange={(e) => handleChange(e, index, 'branchName')}
                                        />
                                    </div>

                                    <div className='col-4 mt-2'>
                                        <label>IFSC Code</label>
                                        <input type="text" className='form-control' required value={item?.IFSCCode}
                                            readOnly={!edit}
                                            onChange={(e) => handleChange(e, index, 'IFSCCode')}
                                        />
                                    </div>
                                </div>

                                {
                                    edit
                                    &&
                                    <div className='col-2 text-center mt-4'>
                                        <AiFillDelete style={{
                                            fontSize: '25px',
                                            cursor: 'pointer'
                                        }}
                                            onClick={() => deleteRow(index)}
                                        />
                                    </div>
                                }
                            </div>
                        </Paper>
                    })
                }


                <br />

                <div className='row justify-content-center'>
                    {
                        !edit
                            ?
                            <div className='col-6 text-center'>
                                <button className='add-cost-center__btn' type='click' onClick={(e) => {
                                    e.preventDefault()
                                    setEdit(true)
                                }}>Edit</button>
                            </div>
                            :
                            <>
                                <div className='col-6 text-center'>
                                    <button className='add-cost-center__btn' type='submit'>Submit</button>
                                </div>

                                <div className='col-6 text-center'>
                                    <button className='add-cost-center__btn' type='reset'
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setEdit(false)
                                        }}
                                    >Cancel</button>
                                </div>
                            </>

                    }

                </div>

            </form >

        </div >
    )
}

export default EditCostCenter