import React, { useEffect, useState } from 'react'
import './IndividualCheque.scss';
import '../../components/Tabs/Tabs.scss'
import Button from '../../components/Button/Button';
import { Nav } from 'react-bootstrap';
import { Category } from '@material-ui/icons';
import { BASE_URL } from '../../utils/config/url';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Details from '../../components/IndividualCheque/Details';
import ChequeProcessing from '../../components/IndividualCheque/ChequeProcessing';

const IndividualCheque = () => {

    const [tab, setTab] = useState(localStorage.getItem('ActiveKeyIndividualCheque'))
    const chequeNo = useParams().chequeNo;

    return (
        <div className='cheque'>

            <Button navigateTo={'/finance/chequebook'} />

            <br />

            <h3
                style={{ textAlign: 'center' }}
            >Credit Cheque - {chequeNo}</h3>

            <br />

            <div className="tabs">
                <Nav>
                    <Nav.Item
                        onClick={() => {
                            localStorage.setItem("ActiveKeyIndividualCheque", "first");
                            setTab('first')

                        }}
                    >
                        <Nav.Link className={tab === 'first' ? 'active' : 'inactive'} eventKey="first">
                            Details
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        onClick={() => {
                            localStorage.setItem("ActiveKeyIndividualCheque", "second");
                            setTab('second')

                        }}
                    >
                        <Nav.Link className={tab === 'second' ? 'active' : 'inactive'} eventKey="second">
                            Cheque Processing
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>

            <br />
            <br />


            {tab === 'first' && <Details />}
            {tab === 'second' && <ChequeProcessing />}

        </div>
    )
}

export default IndividualCheque