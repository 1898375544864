import { Paper } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import { useParams } from 'react-router-dom';

const SubCategories = () => {

    const { categoryCode } = useParams()
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [subCategories, setSubCategories] = useState([])
    const [message, setMessage] = useState('')

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/stockmgmtapi/catSubCategory/getListOfCatSubCategory?parentCategoryCode=${categoryCode}`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status === true) {
                    setSubCategories(response.data.CatSubCategory)
                    setMessage('')
                } else {
                    setSubCategories([])
                    setMessage(response.data.message)
                }
            })

    }, [])


    return (
        <div className='inc-cat-subcats'>

            {subCategories?.length > 0
                ?
                subCategories?.map((subCategory, index) => {

                    return <Paper
                        key={index}
                        elevation={3}
                        style={{
                            padding: '20px',
                            margin: '20px'
                        }}
                    >

                        <div className='row justify-content-center'>
                            <div className='col-4 d-flex flex-column'>
                                <label>Sub Category Code</label>
                                <a href={`/listOfSubCategory/${subCategory.subCategoryCode}`}
                                    style={{ cursor: 'pointer', color: '#EE4B46' }}
                                >
                                    <b> {subCategory?.subCategoryCode}</b>
                                </a>
                            </div>

                            <div className='col-4'>
                                <label>Sub Category Name</label>
                                <input type="text" className='form-control' value={subCategory?.subCategoryName} readOnly />
                            </div>

                            <div className='col-4'>
                                <label>active</label>
                                <input type="text" className='form-control' value={subCategory?.isActive} readOnly />
                            </div>
                        </div>

                    </Paper>

                })
                :
                <h4 className='text-center mt-5'>{message}</h4>
            }


        </div>
    )
}

export default SubCategories