import React, { useEffect, useState } from 'react'
import Paper from "@mui/material/Paper";
import { BASE_URL } from '../../utils/config/url';
import { Form } from 'react-bootstrap';
import axios from 'axios'
import Swal from 'sweetalert2';

const ClaimPayment = ({ claim }) => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [settleClaimMode, setSettleClaimMode] = useState('')
    const [costCenter, setCostCenter] = useState('')
    const [costCenterList, setCostCenterList] = useState([])
    const [finYear, setFinYear] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [employee, setEmployee] = useState('')
    const [transferMethod, setTransferMethod] = useState('')
    const [payerBankName, setPayerBankName] = useState('')
    // const [payerBankAccount, setPayerBankAccount] = useState('')
    const [payerBankAccounts, setPayerBankAccounts] = useState([])
    const [payerBankAccount, setPayerBankAccount] = useState('')


    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/financemgmt/costcenter/getListOfCostCenter`, {
            headers: {
                Authorization: `bearer ${userInfo.token}`
            }
        })
            .then((response) => {
                // console.log(response)
                if (response.data.status === true) {
                    setCostCenterList(response.data.Cost_Centers)
                } else {
                    setCostCenterList([])
                }
            })
    }, [])

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${claim?.raisedByEmpId}`, {
            headers: {
                Authorization: `bearer ${userInfo.token}`
            }
        })
            .then((response) => {
                // console.log(response)
                if (response.data.status === true) {
                    setEmployee(response.data.employee)
                } else {
                    setEmployee([])
                }
            })

    }, [])

    useEffect(() => {

        if (!costCenter) return;

        axios.get(`${BASE_URL}/api/v1/financemgmt/costCenter/getCostCenterByCostCenterCode/${costCenter.split(',')[0]}`,
            {
                headers: {
                    Authorization: `bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response)
                if (response.data.status) {
                    setPayerBankAccounts(response?.data?.Cost_Center?.bankAccounts)
                } else {
                    setPayerBankAccounts([])
                }
            })

    }, [costCenter])

    const settleClaim = () => {

        if (!costCenter || !finYear) return;

        if (settleClaimMode === 'Bank Transfer' && !transactionId) return;

        let body;

        if (settleClaimMode === 'Cash') {
            body = {
                payerEmployeeId: userInfo?.employeeId,
                financePaymentMode: settleClaimMode,
                costCenterCode: costCenter.split(',')[0],
                costCenterName: costCenter.split(',')[1],
                claimId: claim?.claimId,
                finYear: finYear,
            }
        } else {
            body = {
                payerEmployeeId: userInfo?.employeeId,
                financePaymentMode: settleClaimMode,
                costCenterCode: costCenter.split(',')[0],
                costCenterName: costCenter.split(',')[1],
                claimId: claim?.claimId,
                finYear: finYear,
                transactionId,
                transferMethod,
                payerBankName: payerBankAccount.split(',')[1],
                payerAccountNumber: payerBankAccount.split(',')[0],
                payerBranchName : payerBankAccount.split(',')[4],
                payeeBankName: employee?.salaryAccount?.bankName,
                payeeAccountNumber: employee?.salaryAccount?.accountNumber,
            }
        }


        axios.post(`${BASE_URL}/api/v1/financemgmt/payment/settleClaimPayment`,
            body,
            {
                headers: {
                    Authorization: `bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response)
                if (response.data.status === true) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                    })
                        .then(() => {
                            window.location.reload()
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                    })
                }
            })
            .catch((err) => {
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error,
                })
            })
    }

    return (
        <div>

            <div className="row container-fluid justify-content-center">
                <div className='col-6'>
                    <Form.Group controlId="payment-category">
                        <Form.Label>Settle Claims</Form.Label>
                        <Form.Control as="select" required
                            value={settleClaimMode}
                            onChange={(e) => {
                                setSettleClaimMode(e.target.value)
                            }}
                        >
                            <option value="" disabled>Select a Mode</option>
                            <option value="Cash">Cash</option>
                            <option value="Bank Transfer">Bank Transfer</option>
                        </Form.Control>
                    </Form.Group>
                </div>
            </div>

            {
                settleClaimMode === 'Cash'
                &&
                <Paper elevation={2} style={{ padding: "20px", width: '80%', margin: 'auto', marginTop: "40px" }}>

                    <div className='row container-fluid justify-content-center'>
                        <div className='col-6'>
                            <Form.Group controlId="cost center">
                                <Form.Label>Cost Center</Form.Label>
                                <Form.Control as="select" required
                                    value={costCenter}
                                    onChange={(e) => {
                                        setCostCenter(e.target.value)
                                    }}
                                >
                                    <option value="">Select a Center</option>
                                    {
                                        costCenterList?.map((center, index) => {
                                            return <option value={`${center?.costCenterCode},${center?.costCenterName}`}>{center?.costCenterName}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                            <p>{costCenter.split(',')[0]}</p>
                        </div>

                        <div className='col-6'>
                            <Form.Group controlId="financial year">
                                <Form.Label>Financial Year</Form.Label>
                                <Form.Control as="select" required
                                    value={finYear}
                                    onChange={(e) => {
                                        setFinYear(e.target.value)
                                    }}
                                >
                                    <option value="">Select a Year</option>
                                    <option>2022-2023</option>
                                    <option>2023-2024</option>
                                    <option>2024-2025</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                    </div>

                    <br />

                    <div className='row container-fluid justify-content-center'>
                        <div className='col-6'>
                            <label>Amount</label>
                            <input type="number" readOnly className='form-control'
                                value={claim?.claimAmount}
                            />
                        </div>
                    </div>

                    <br />

                    <div className='row container-fluid justify-content-center'>
                        <div className='col-4 text-center inv-claim__btns'>
                            <button
                                onClick={settleClaim}
                            >Submit</button>
                        </div>
                    </div>

                </Paper>
            }

            {
                settleClaimMode === 'Bank Transfer'
                &&
                <Paper elevation={2} style={{ padding: "20px", width: '80%', margin: 'auto', marginTop: "40px" }}>

                    <div className='row container-fluid justify-content-center'>
                        <div className='col-6'>
                            <Form.Group controlId="cost center">
                                <Form.Label>Cost Center</Form.Label>
                                <Form.Control as="select" required
                                    value={costCenter}
                                    onChange={(e) => {
                                        setCostCenter(e.target.value)
                                    }}
                                >
                                    <option value="">Select a Center</option>
                                    {
                                        costCenterList?.map((center, index) => {
                                            return <option value={`${center?.costCenterCode},${center?.costCenterName}`}>{center?.costCenterName}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                            <p>{costCenter.split(',')[0]}</p>
                        </div>

                        <div className='col-6'>
                            <Form.Group controlId="financial year">
                                <Form.Label>Financial Year</Form.Label>
                                <Form.Control as="select" required
                                    value={finYear}
                                    onChange={(e) => {
                                        setFinYear(e.target.value)
                                    }}
                                >
                                    <option value="">Select a Year</option>
                                    <option>2022-2023</option>
                                    <option>2023-2024</option>
                                    <option>2024-2025</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                    </div>

                    <br />

                    <div className='row container-fluid justify-content-center'>
                        <div className='col-6'>
                            <Form.Group controlId="financial year">
                                <Form.Label>Transfer Method</Form.Label>
                                <Form.Control as="select" required
                                    value={transferMethod}
                                    onChange={(e) => {
                                        setTransferMethod(e.target.value)
                                    }}
                                >
                                    <option value="">Select a Method</option>
                                    <option>NEFT</option>
                                    <option>RTGS</option>
                                    <option>IMPS</option>
                                </Form.Control>
                            </Form.Group>
                        </div>

                        <div className='col-6'>
                            <label>Amount</label>
                            <input type="number" readOnly className='form-control'
                                value={claim?.claimAmount}
                            />
                        </div>
                    </div>

                    <br />

                    <div className='row container-fluid justify-content-center'>
                        <div className='col-6'>
                            <label>Bank Account Number</label>
                            <input type="number" readOnly className='form-control'
                                value={employee?.salaryAccount?.accountNumber}
                            />
                        </div>

                        <div className='col-6'>
                            <label>Bank Account Name</label>
                            <input type="text" readOnly className='form-control'
                                value={employee?.salaryAccount?.bankName}
                            />
                        </div>
                    </div>

                    <br />

                    <div className='row container-fluid justify-content-center'>
                        <div className='col-6'>
                            <label>IFSC Code</label>
                            <input type="text" readOnly className='form-control'
                                value={employee?.salaryAccount?.IFSCCode}
                            />
                        </div>

                        <div className='col-6'>
                            <label>Branch Name</label>
                            <input type="text" readOnly className='form-control'
                                value={employee?.salaryAccount?.branchName}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="row container-fluid justify-content-center">

                        <div className='col-6'>
                            <Form.Group controlId="Site-Name">
                                <Form.Label>Payer Bank Account</Form.Label>
                                <Form.Control as="select" required
                                    value={payerBankAccount}
                                    onChange={(e) => {
                                        // let value = e.target.value;
                                        // setPayerBankName(value);
                                        // if (value === 'WESTROAD DEVELOPERS PVT LTD A/C') setPayerBankAccount('50442540521')
                                        // else if (value === 'WESTROAD HEIGHTS COLLECTION A/C') setPayerBankAccount('38861908851')
                                        if (e.target.value === '') setPayerBankAccount('');
                                        else {setPayerBankAccount(e.target.value)}
                                    }}
                                >
                                    <option value=''>Select a Bank</option>
                                    {/* <option value="WESTROAD DEVELOPERS PVT LTD A/C">WESTROAD DEVELOPERS PVT LTD A/C</option>
                                    <option value="WESTROAD HEIGHTS COLLECTION A/C">WESTROAD HEIGHTS COLLECTION A/C</option> */}
                                    {
                                        payerBankAccounts?.map((account, index) => {
                                            return <option key={index} value={`${account?.accountNumber},${account?.bankName},${account?.accountHolderName},${account?.IFSCCode},${account?.branchName}`}>{account?.accountNumber}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </div>

                        <div className='col-6'>
                            <label>Payer Bank Name</label>
                            <input type="text" className='form-control' required readOnly value={payerBankAccount ? payerBankAccount.split(',')[1] : ''} />
                        </div>

                    </div>

                    <br />

                    <div className="row container-fluid justify-content-center">

                        <div className='col-6'>
                            <label>Payer Account Holder Name</label>
                            <input type="text" className='form-control' required readOnly value={payerBankAccount ? payerBankAccount.split(',')[2] : ''} />
                        </div>

                        <div className='col-6'>
                            <label>Payer IFSC Code</label>
                            <input type="text" className='form-control' required readOnly value={payerBankAccount ? payerBankAccount.split(',')[3] : ''} />
                        </div>
                    </div>

                    <br />

                    <div className="row container-fluid justify-content-center">

                        <div className='col-6'>
                            <label>Payer Branch Name</label>
                            <input type="text" className='form-control' required readOnly value={payerBankAccount ? payerBankAccount.split(',')[4] : ''} />
                        </div>

                    </div>


                    <br />

                    <div className='row container-fluid justify-content-center'>
                        <div className='col-6'>
                            <label>Transaction Id</label>
                            <input type="text" className='form-control'
                                value={transactionId}
                                onChange={(e) => (setTransactionId(e.target.value))}
                            />
                        </div>
                    </div>

                    <br />

                    <div className='row container-fluid justify-content-center'>
                        <div className='col-4 text-center inv-claim__btns'>
                            <button
                                onClick={settleClaim}
                            >Submit</button>
                        </div>
                    </div>

                </Paper>
            }


        </div>
    )
}

export default ClaimPayment