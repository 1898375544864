import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import "./AddTeamMember.scss";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import Button from "../../components/Button/Button";
import { Switch, createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#EE4B46",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
});

const AddTeamMember = () => {
  const navigate = useNavigate();
  const [fName, setFName] = useState("");
  const [mName, setMName] = useState("");
  const [lName, setLName] = useState("");
  const [uName, setUName] = useState("");
  const [officialEmail, setOfficialEmail] = useState("");
  const [officialMobile, setOfficialMobile] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [personalMobile, setPersonalMobile] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState({});
  const [emergency, setEmergency] = useState({});
  const [isEmployee, setIsEmployee] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [role, setRole] = useState("");
  const [gender, setGender] = useState("");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const handleSubmit = (e) => {
    e.preventDefault();

    let body = {
      userName: uName,
      isEmployee: isEmployee,
      userFirstName: fName,
      userMiddleName: mName,
      userLastName: lName,
      officialMobile: officialMobile,
      officialEmail: officialEmail,
      isActive: true,
      userRole: role,
    };

    if (isEmployee === true) {
      body = {
        ...body,
        employeeId,
        personalMobile: personalMobile,
        personalEmail: personalEmail,
        employeeDOB: dob,
        emergencyContact: emergency,
        employeeAddress: address,
        gender:gender,
      };
    }

    axios
      .post(`${BASE_URL}/api/v1/user/addNewUser`, body, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((res) => {
        if (res.data.status === true) {
          Swal.fire({
            icon: "success",
            title: res.data.message,
            showConfirmButton: false,
            timer: 1900,
          }).then(() => {
            navigate("/viewuser");
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: res.data.message,
          });
        }

        // e.target.reset();
      })
      .catch((err) => {
        Swal.fire({
          icon: "warning",
          title: err.response.data.error,
        });
      });
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="outlet_bg">
        <div className="outlet_container">
          <Button navigateTo={1} />
          <div
            className="add_member_form"
            style={{ width: window.innerWidth < 700 ? "100%" : "80%" }}
          >
            <form onSubmit={handleSubmit}>
              <div className="member_details">
                <div className="user_info">
                  <label>User Name</label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setUName(e.target.value)}
                    className="bg-white"
                    required
                  />
                </div>
                <div className="user_info m-auto w-40">
                  <label>Is Employee ?</label>
                  <Switch
                    checked={isEmployee}
                    onChange={() => setIsEmployee(!isEmployee)}
                    color="primary"
                  />
                </div>
                <div className="user_info">
                  <label>First Name</label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setFName(e.target.value)}
                    className="bg-white"
                    required
                  />
                </div>
                <div className="user_info">
                  <label>Middle Name</label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setMName(e.target.value)}
                    className="bg-white"
                  />
                </div>
                <div className="user_info">
                  <label>Last Name</label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setLName(e.target.value)}
                    className="bg-white"
                    required
                  />
                </div>

                <div className="user_info">
                  <label>Official Mobile</label>
                  <Form.Control
                    type="number"
                    onChange={(e) => setOfficialMobile(e.target.value)}
                    className="bg-white"
                    required
                  />
                  {String(officialMobile).length !== 10 && (
                    <small className="mt-2 text-danger">
                      Mobile number must be of 10 digit
                    </small>
                  )}
                </div>
                <div className="user_info">
                  <label>Official Email</label>
                  <Form.Control
                    type="email"
                    onChange={(e) => setOfficialEmail(e.target.value)}
                    className="bg-white"
                    required
                  />
                </div>
                <div className="user_info">
                  <label>Role</label>
                  <Form.Control
                    as="select"
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="" className="text-secondary" selected>
                      Select a Role
                    </option>
                    <option value="Director"> Director</option>
                    <option value="Sales">Sales</option>
                    <option value="HR">HR</option>
                    <option value="Finance">Finance</option>
                    <option value="Operations">Operations</option>
                    <option value="Engineering">Engineering</option>
                    <option value="IT admin">IT admin</option>
                  </Form.Control>
                </div>

                {isEmployee == true && (
                  <>
                    <div className="user_info">
                      <label>Employee Id</label>
                      <Form.Control
                        type="text"
                        onChange={(e) => setEmployeeId(e.target.value)}
                        className="bg-white"
                        required
                      />
                    </div>

                    <div className="user_info">
                      <label>Gender</label>
                      <Form.Control
                        as="select"
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="" className="text-secondary" selected>
                          Select a Gener
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </Form.Control>
                    </div>

                    <div className="user_info">
                      <label>Personal Mobile</label>
                      <Form.Control
                        type="number"
                        onChange={(e) => setPersonalMobile(e.target.value)}
                        className="bg-white"
                      />
                      {String(personalMobile).length !== 10 && (
                        <small className="mt-2 text-danger">
                          Mobile number must be of 10 digit
                        </small>
                      )}
                    </div>
                    <div className="user_info">
                      <label>Personal Email</label>
                      <Form.Control
                        type="email"
                        onChange={(e) => setPersonalEmail(e.target.value)}
                        className="bg-white"
                        // required
                      />
                    </div>
                    <div className="user_info">
                      <label>Date of Birth</label>
                      <Form.Control
                        type="date"
                        onChange={(e) => setDob(e.target.value)}
                        className="bg-white"
                        required
                      />
                    </div>
                    <div className="user_info">
                      
                    </div>

                    <div className="card p-5">
                      <h5 className="text-center">Emergency Contact</h5>
                      <br />
                      <div className="user_info">
                        <label>Name</label>
                        <Form.Control
                          type="text"
                          required
                          onChange={(e) =>
                            setEmergency((prev) => {
                              return {
                                ...prev,
                                name: e.target.value,
                              };
                            })
                          }
                          className="bg-white"
                        />
                      </div>
                      <div className="user_info">
                        <label>Mobile</label>
                        <Form.Control
                          type="number"
                          required
                          onChange={(e) =>
                            setEmergency((prev) => {
                              return {
                                ...prev,
                                mobile: e.target.value,
                              };
                            })
                          }
                          className="bg-white"
                        />
                        {String(emergency.mobile).length !== 10 && (
                          <small className="mt-2 text-danger">
                            Mobile number must be of 10 digit
                          </small>
                        )}
                      </div>
                      <div className="user_info">
                        <label>Address</label>
                        <Form.Control
                          type="text"
                          required
                          onChange={(e) =>
                            setEmergency((prev) => {
                              return {
                                ...prev,
                                address: e.target.value,
                              };
                            })
                          }
                          className="bg-white"
                        />
                      </div>
                    </div>

                    <div className="card p-5">
                      <h5 className="text-center">Address</h5>
                      <br />
                      <div className="user_info">
                        <label>Full Address</label>
                        <Form.Control
                          type="text"
                          required
                          onChange={(e) =>
                            setAddress((prev) => {
                              return {
                                ...prev,
                                fullAddress: e.target.value,
                              };
                            })
                          }
                          className="bg-white"
                        />
                      </div>
                      <div className="user_info">
                        <label>Landmark</label>
                        <Form.Control
                          type="text"
                          required
                          onChange={(e) =>
                            setAddress((prev) => {
                              return {
                                ...prev,
                                landMark: e.target.value,
                              };
                            })
                          }
                          className="bg-white"
                        />
                      </div>
                      <div className="user_info">
                        <label>City</label>
                        <Form.Control
                          type="text"
                          required
                          onChange={(e) =>
                            setAddress((prev) => {
                              return {
                                ...prev,
                                city: e.target.value,
                              };
                            })
                          }
                          className="bg-white"
                        />
                      </div>
                      <div className="user_info">
                        <label>PinCode</label>
                        <Form.Control
                          type="number"
                          required
                          onChange={(e) =>
                            setAddress((prev) => {
                              return {
                                ...prev,
                                pinCode: e.target.value,
                              };
                            })
                          }
                          className="bg-white"
                        />
                        {String(address.pinCode).length !== 6 && (
                          <small className="mt-2 text-danger">
                            Pincode must be of 6 digits
                          </small>
                        )}
                      </div>
                      <div className="user_info">
                        <label>State</label>
                        <Form.Control
                          type="text"
                          required
                          onChange={(e) =>
                            setAddress((prev) => {
                              return {
                                ...prev,
                                state: e.target.value,
                              };
                            })
                          }
                          className="bg-white"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="user_form_btns">
                <button type="reset" className="reset_btn">
                  Reset
                </button>
                <button type="submit" className="create_btn">
                  Create User
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default AddTeamMember;
