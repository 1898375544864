import React from "react";
import "./OnboardEmpLogin.scss";
import OnboardEmpLoginForm from "./OnboardEmpLoginForm";


const OnboardEmpLogin = () => {

  return (
    <div className="onboardEmpLoginform_container">
      <OnboardEmpLoginForm/>
    </div>
  );
};

export default OnboardEmpLogin;