import axios from 'axios';
import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { BASE_URL } from '../../utils/config/url';
import PaidIcon from '@mui/icons-material/Paid';
import { GiRecycle } from 'react-icons/gi'
import { BsBank2 } from 'react-icons/bs'
import { GiConfirmed } from 'react-icons/gi';
import './CashDeposit.scss';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useStyles } from '../../utils/ModalStyles';
import { Backdrop, Fade, Modal } from '@mui/material';
import Swal from 'sweetalert2'
import { Form } from 'react-bootstrap';


const CashDeposit = () => {

    const classes = useStyles()
    const navigate = useNavigate()
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));


    const [deposit, setDeposit] = useState([]);
    const [open1, setOpen1] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [open3, setOpen3] = useState(false)
    const [open4, setOpen4] = useState(false)
    const [depositId, setDepositId] = useState('')
    const [category, setCategory] = useState('')
    const [subCategory, setSubCategory] = useState('')
    const [status, setStatus] = useState('')
    const [currentOwner, setCurrentOwner] = useState('')
    const [users, setUsers] = useState([])
    const [handoverTo, setHandoverTo] = useState([])
    const [banks, setBanks] = useState([])
    const [bank, setBank] = useState('')
    const [date, setDate] = useState('')
    const [depositor, setDepositor] = useState('')
    const [financeUserName, setFinanceUserName] = useState('')
    const [financeUserFullName, setFinanceUserFullName] = useState('')

    useEffect(() => {
        getListOfCashDeposits()
    }, [])

    const getListOfCashDeposits = () => {

        axios.get(`${BASE_URL}/api/v1/FinanceMgmt/CashDeposit/getListOfCashDeposit`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)
                setDeposit(response.data.cashDepositList)

            })
    }

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/user/getListOfUsers?isActive=true`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {

                let list = []
                response.data.userList.forEach((u) => {
                    if (u.userRole === 'Finance') {

                        return list.push(u)
                    }
                })

                setUsers(list)
            })
    }, [])

    useEffect(() => {

        if (category === '') {
            setSubCategory('')
        }

        if (category === '' && status === '') {
            setSubCategory('')
            return getListOfCashDeposits()
        }

        let params = [];

        if (category) {
            params.push('paymentCategory')
        }
        if (subCategory) {
            params.push('paymentSubCategory')
        }
        if (status) {
            params.push('depositStatus')
        }

        let url = []
        params.forEach((p, index) => {
            if (p === 'paymentCategory') {

                if (index === 0) {
                    url.push(`?${p}=${category}`)
                } else {
                    url.push(`&${p}=${category}`)

                }
            }
            if (p === 'paymentSubCategory') {
                if (index === 0) {
                    url.push(`?${p}=${subCategory}`)
                } else {
                    url.push(`&${p}=${subCategory}`)

                }
            }
            if (p === 'depositStatus') {

                if (index === 0) {
                    url.push(`?${p}=${status}`)
                } else {
                    url.push(`&${p}=${status}`)

                }
            }
        })

        axios.get(`${BASE_URL}/api/v1/FinanceMgmt/CashDeposit/getListOfCashDeposit${url.join('')}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {

                setDeposit(response.data.cashDepositList)
            })
    }, [category, subCategory, status])

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/FinanceMgmt/Bank/getListOfBank?isActive=true`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {

                setBanks(response.data.banks)
            })
    }, [])


    const handleModal1 = (e, category) => {
        e.preventDefault()

        let body = {}

        if (category === 'finance') {
            body = {
                cashDepositId: depositId,
                financeUserName: userInfo?.userName,
                financeUserFullName: userInfo?.userFullName
            }
        } else {
            const data = handoverTo.split(',')
            body = {
                cashDepositId: depositId,
                financeUserName: data[0],
                financeUserFullName: data[1],
                handOverCash: true
            }
        }


        axios.put(`${BASE_URL}/api/v1/FinanceMgmt/CashDeposit/cashReceivedByFinance`,
            body,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                setOpen1(false)
                setOpen2(false)

                //  console.log(response)

                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        setHandoverTo({})
                        getListOfCashDeposits()
                    })
                }
            })
            .catch((err) => {
                setOpen1(false)
                setOpen2(false)
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }


    const handleModal2 = (e) => {
        e.preventDefault()

        let arr = bank.split('-')

        let body = {
            cashDepositId: depositId,
            depositorName: depositor,
            depositBankName: arr[0],
            depositBankAccount: arr[1]
        }


        if (userInfo.userName != financeUserName) {
            setOpen3(false)
            setBank('')
            setDepositor('')
            setFinanceUserName('')

            return Swal.fire({
                icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
                text: `You cannot send money to Bank as the money is currently with  ${financeUserFullName} (${financeUserName})`
            })

        }

        axios.put(`${BASE_URL}/api/v1/FinanceMgmt/CashDeposit/cashsenttobank`,
            body,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {

                setOpen3(false)

                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        setBank('')
                        getListOfCashDeposits()
                    })
                }

            })
            .catch((err) => {

                setOpen3(false)
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })

    }


    const handleModal3 = (e) => {
        e.preventDefault()

        let arr = date.split('-')
        let finalDate = arr.reverse().join('/')

        let body = {
            cashDepositId: depositId,
            depositConfirmedBy: currentOwner,
            cashSubmittedDate: finalDate
        }


        axios.put(`${BASE_URL}/api/v1/FinanceMgmt/CashDeposit/cashdeposited`,
            body,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {

                setOpen4(false)

                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        //setBank('')
                        getListOfCashDeposits()
                        setDate('')
                    })
                }

            })
            .catch((err) => {

                setOpen4(false)
                setDate('')

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }

    const dateFormatting = (input, type) => {
        let d = new Date(input)
        let date = d.getDate()
        if (date < 10) {
            date = `0${date}`
        }
        let m = d.getMonth() + 1
        if (m < 10) {
            m = `0${m}`
        }
        let y = d.getFullYear()

        let final;

        if (type === 'original') {
            final = `${y}-${m}-${date}`
            return final
        } else {
            final = `${date}/${m}/${y}`
            return final
        }

    }

    const reset=()=>{
        setCategory('')
        setSubCategory('')
        setStatus('')
    }

    return (
        <div className='cashdeposit'>

            <div className='cashdeposit__buttons'>
                <Button navigateTo={'/home'} />
                <button className='cashdeposit__btn'
                    onClick={() => navigate('/finance/cashdeposit/receive-cash')}>Recieve cash</button>
            </div>

            <br />
            <br />

            <h4>Cash Deposits</h4>

            <MaterialTable
                data={deposit}
                title=''

                columns={
                    [
                        {
                            //title: userInfo?.userRole === 'Finance' ? 'Actions' : '',
                            title: 'Actions',
                            render: (rowData) => {
                                return <div className='icons'>
                                    <PaidIcon
                                        style={{
                                            color: rowData.depositStatus !== 'Cash Received' ? 'lightgrey' : 'black', cursor: rowData.depositStatus !== 'Cash Received' ? 'default' : 'pointer',
                                            display: userInfo.userRole === 'Finance' ? 'block' : 'none'
                                        }}
                                        onClick={() => {
                                            if (rowData.depositStatus === 'Cash Received') {
                                                setOpen1(true)
                                                setDepositId(rowData.cashDepositId)
                                            }
                                        }}
                                    />
                                    <GiRecycle
                                        className={rowData.depositStatus !== 'Received By Finance' || rowData.financeUserName != userInfo.userName ? 'icon-grey' : 'icon-black'}
                                        style={{ display: userInfo.userRole === 'Finance' ? 'block' : 'none' }}
                                        onClick={() => {
                                            if (rowData.depositStatus === 'Received By Finance' && rowData.financeUserName === userInfo.userName) {
                                                setOpen2(true)
                                                setDepositId(rowData.cashDepositId)
                                                setCurrentOwner(rowData.financeUserFullName)
                                            }
                                        }}
                                    />

                                    <BsBank2
                                        className={rowData.depositStatus !== 'Received By Finance' ? 'icon-grey' : 'icon-black'}
                                        style={{ display: userInfo.userRole === 'Finance' ? 'block' : 'none' }}
                                        onClick={() => {
                                            if (rowData.depositStatus === 'Received By Finance') {
                                                setOpen3(true)
                                                setDepositId(rowData.cashDepositId)
                                                setCurrentOwner(rowData?.financeUserFullName)
                                                setFinanceUserName(rowData.financeUserName)
                                                setFinanceUserFullName(rowData.financeUserFullName)
                                            }
                                        }}
                                    />
                                    <GiConfirmed
                                        className={rowData.depositStatus !== 'Sent to Bank' ? 'icon-grey' : 'icon-black'}
                                        style={{ display: userInfo.userRole === 'Finance' ? 'block' : 'none' }}
                                        onClick={() => {
                                            if (rowData.depositStatus === 'Sent to Bank') {
                                                setOpen4(true)
                                                setDepositId(rowData.cashDepositId)
                                            }
                                        }}
                                    />

                                </div>
                            }
                        },
                        { title: 'Desposit ID', field: 'cashDepositId' },
                        { title: 'Status', field: 'depositStatus' },
                        { title: 'Amount', field: 'cashAmount' },
                        {
                            title: 'Received By', field: 'recievedByUserFullName',
                        },
                        {
                            title: 'Received Date',
                            render: (rowData) => {
                                return !rowData.recievedDate ? "" : rowData.recievedDate.substring(8, 10) + "-" + rowData.recievedDate.substring(5, 7) + "-" + rowData.recievedDate.substring(0, 4)
                            },

                            customSort: (a, b) => (a.recievedDate < b.recievedDate ? -1 : 1),
                            defaultSort: 'desc'

                        },
                        { title: 'Category', field: 'paymentCategory' },
                        { title: 'Sub-Category', field: 'paymentSubCategory' },
                        {
                            title: 'Customer ID',
                            render: (rowData) => {
                                return rowData.customerId ? rowData.customerId : 'NA'
                            }
                        },
                        {
                            title: 'Application Id',
                            render: (rowData) => {
                                return rowData.applicationId ? rowData.applicationId : 'NA'
                            }
                        },
                        {
                            title: 'Money Receipt No.',
                            render: (rowData) => {
                                return rowData.moneyReceiptNo ? rowData.moneyReceiptNo : 'Not Generated'
                            }
                        },
                        {
                            title: 'Finance Received',
                            render: (rowData) => {
                                return rowData.receivedByFinance === true ? 'Yes' : 'No'
                            }
                        },
                        {
                            title: 'Date',
                            render: (rowData) => (!rowData.receivedByFinanceDate ? "" : rowData.receivedByFinanceDate.substring(8, 10) + "-" + rowData.receivedByFinanceDate.substring(5, 7) + "-" + rowData.receivedByFinanceDate.substring(0, 4)),
                            customSort: (a, b) => (a.receivedByFinanceDate < b.receivedByFinanceDate ? -1 : 1),
                        },

                        {
                            title: 'Finance Received By', field: 'financeUserFullName',
                        },
                        {
                            title: 'Sent To Bank',
                            render: (rowData) => {
                                return rowData.sentToBank === true ? 'Yes' : 'No'
                            }
                        },
                        {
                            title: 'bank Deposit Date',
                            render: (rowData) => (!rowData.bankDepositDate ? "" : rowData.bankDepositDate.substring(8, 10) + "-" + rowData.bankDepositDate.substring(5, 7) + "-" + rowData.bankDepositDate.substring(0, 4)),
                            customSort: (a, b) => (a.bankDepositDate < b.bankDepositDate ? -1 : 1),
                        },
                        { title: 'Depositor', field: 'depositorName' },
                        { title: 'Bank', field: 'depositBankName' },
                        {
                            title: 'Cash Deposited',
                            render: (rowData) => {
                                return rowData.cashDeposited === true ? 'Yes' : 'No'
                            }
                        },
                        { title: 'Deposit Confirmed By', field: 'depositConfirmedBy' },

                    ]
                }

                options={{
                    search: window.innerWidth < 700 ? false : true,
                    searchFieldStyle: {
                        width: 200
                    },
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#EE4B46',
                        color: '#fff',
                    },
                    sorting: true
                }}


                components={{
                    Toolbar: (props) => (
                        <div className="filters text-left">
                            <MTableToolbar {...props} />

                            <FormControl variant="standard"
                                className={classes.formControl}

                                style={{
                                    margin: '0px 10px',
                                    marginTop: "-65px",
                                    width: '150px',

                                }}
                            >
                                <InputLabel id="demo-simple-select-standard-label"
                                    style={{ fontSize: '13px' }}>
                                    Category
                                </InputLabel>
                                <Select llabelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                    value={category}
                                    onChange={(e) => {
                                        setCategory(e.target.value)
                                    }}
                                    className={classes.selectEmpty}
                                    style={{
                                        fontSize: '13px',
                                        textAlign: 'left'
                                    }}
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value="">
                                        Category
                                    </MenuItem>
                                    <MenuItem value='CustomerPayment'>Customer Payment</MenuItem>
                                    <MenuItem value='OtherPayment'>Other Payment</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl variant="standard"
                                // className={classes.formControl}
                                style={{
                                    margin: '0px 10px',
                                    marginTop: "-65px",
                                    width: '150px',
                                }}
                            >
                                <InputLabel id="demo-simple-select-standard-label"
                                    style={{ fontSize: '13px' }}>
                                    Sub Category
                                </InputLabel>
                                {
                                    category === 'CustomerPayment' ?
                                        <Select llabelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                            value={subCategory}
                                            onChange={(e) => setSubCategory(e.target.value)}
                                            className={classes.selectEmpty}
                                            inputProps={{ "aria-label": "Without label" }}
                                            style={{
                                                fontSize: '13px',
                                                textAlign: 'left'
                                            }}
                                            disabled={category === "" ? true : false}
                                        >
                                            <MenuItem value="">
                                                Sub Category
                                            </MenuItem>
                                            <MenuItem value='BookingPayment'>Booking Payment</MenuItem>
                                            <MenuItem value='DemandPayment'>Demand Payment</MenuItem>
                                            <MenuItem value='FinalPayment'>Final Payment</MenuItem>
                                        </Select>

                                        :

                                        <Select llabelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                            value={subCategory}
                                            onChange={(e) => setSubCategory(e.target.value)}
                                            className={classes.selectEmpty}
                                            inputProps={{ "aria-label": "Without label" }}
                                            disabled={category === "" ? true : false}
                                        >
                                            <MenuItem value="">
                                                Sub Category
                                            </MenuItem>
                                            <MenuItem value='MaterialsSold'>Materials Sold</MenuItem>
                                            <MenuItem value='OtherPayment'>Other Payment</MenuItem>
                                        </Select>
                                }

                            </FormControl>

                            {window.innerWidth < 1000 && <br />}

                            <FormControl variant="standard"
                                className={classes.formControl}

                                style={{
                                    margin: '0px 10px',
                                    marginTop: window.innerWidth < 1000 ? '-30px' : '-65px',
                                    width: '150px',

                                }}
                            >
                                <InputLabel id="demo-simple-select-standard-label"
                                    style={{ fontSize: '13px' }}>
                                    Deposit Status
                                </InputLabel>
                                <Select llabelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                    value={status}
                                    onChange={(e) => {
                                        setStatus(e.target.value)
                                    }}
                                    className={classes.selectEmpty}
                                    style={{
                                        fontSize: '13px',
                                        textAlign: 'left'
                                    }}
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value="">
                                        Status
                                    </MenuItem>
                                    <MenuItem value='Cash Received'>Cash Received</MenuItem>
                                    <MenuItem value='Received By Finance'>Received By Finance</MenuItem>
                                    <MenuItem value='Sent To Bank'>Sent To Bank</MenuItem>
                                    <MenuItem value='Deposit Confirmed'>Deposit Confirmed</MenuItem>
                                </Select>
                            </FormControl>

                            {window.innerWidth < 1100 && window.innerWidth > 1000 && <br />}

                            <FormControl className={classes.formControl}
                                style={{ marginTop: window.innerWidth < 1100 ? '-20px' : '-50px' }}>
                                <button
                                    onClick={reset}
                                    style={{
                                        backgroundColor: "white",
                                        color: "black",
                                        border: "1px solid black",
                                        borderRadius: "20px",
                                        padding: "5px",
                                        width: "150px",
                                        
                                    }}
                                >
                                    Reset Filter
                                </button>
                            </FormControl>

                        </div>
                    ),
                }}


            ></MaterialTable>

            {/*  Modal 1 */}
            <Modal
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
                className={classes.modal}
                open={open1}
                onClose={() => setOpen1(false)}
                closeAfterTransition
            >
                <Fade in={open1}>
                    <div className={classes.paper}>
                        <form

                            onSubmit={(e) => handleModal1(e, 'finance')}
                            style={{
                                display: 'flex',
                                // border : '2px solid green',
                                width: '120%',
                                flexDirection: 'column',
                                // alignItems: 'center'
                            }}>

                            <div className="row container-fluid justify-content-center">
                                <div className="col-12">
                                    <h5>Cash Received By Finance</h5>
                                </div>
                            </div>

                            <br />

                            <div className="row container-fluid justify-content-center">
                                <div className="col-12">
                                    <label>Received By</label>
                                    <input required
                                        className='form-control'
                                        type='text'
                                        value={userInfo.userFullName}
                                        readOnly
                                        style={{ width: '85%' }}
                                    />
                                </div>
                            </div>

                            <br />

                            <div className="row container-fluid justify-content-center">
                                <div className="col-6 text-center">
                                    <button
                                        className="modal__btns"
                                        type="submit"

                                    >
                                        Save
                                    </button>
                                </div>

                                <div className="col-6 text-center">
                                    <button
                                        className="modal__btns"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setOpen1(false)
                                        }}

                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>


            {/* Modal 2 */}
            <Modal
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
                className={classes.modal}
                open={open2}
                onClose={() => setOpen2(false)}
                closeAfterTransition
            >
                <Fade in={open2}>
                    <div className={classes.paper}>
                        <form

                            onSubmit={(e) => handleModal1(e, 'handover')}
                            style={{
                                display: 'flex',
                                // border : '2px solid green',
                                width: '100%',
                                flexDirection: 'column',
                                // alignItems: 'center'
                            }}>

                            <div className="row container-fluid justify-content-center">
                                <div className="col-12">
                                    <h5>Handover Cash</h5>
                                </div>
                            </div>

                            <br />

                            <div className="row container-fluid justify-content-center">
                                <div className="col-10">
                                    <label>Current Owner</label>
                                    <input required
                                        className='form-control'
                                        type='text'
                                        value={currentOwner}
                                        readOnly
                                    />
                                </div>

                                <div className='col-10 mt-3'>
                                    <Form.Group controlId="payment-category">
                                        <Form.Label>Handover To</Form.Label>
                                        <Form.Control as="select" required
                                            value={handoverTo}
                                            onChange={(e) => {
                                                setHandoverTo(e.target.value)
                                            }}
                                        >
                                            <option value="">Select a Name</option>
                                            {
                                                users?.map((u, index) => {
                                                    return <option key={index} value={[u?.userName, u?.userFullName]}>{u.userFullName}</option>
                                                })
                                            }

                                        </Form.Control>
                                    </Form.Group>
                                </div>
                            </div>

                            <br />

                            <div className="row container-fluid justify-content-center">
                                <div className="col-6 text-center">
                                    <button
                                        className="modal__btns"
                                        type="submit"

                                    >
                                        Save
                                    </button>
                                </div>

                                <div className="col-6 text-center">
                                    <button
                                        className="modal__btns"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setOpen2(false)
                                        }}

                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>


            {/* Modal 3 */}
            <Modal
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
                className={classes.modal}
                open={open3}
                onClose={() => {
                    setOpen3(false)
                    setBank('')
                    setDepositor('')
                }}
                closeAfterTransition
            >
                <Fade in={open3}>
                    <div className={classes.paper}>
                        <form

                            onSubmit={(e) => handleModal2(e)}
                            style={{
                                display: 'flex',
                                // border : '2px solid green',
                                width: '100%',
                                flexDirection: 'column',
                                // alignItems: 'center'
                            }}>

                            <div className="row container-fluid justify-content-center">
                                <div className="col-12">
                                    <h5>Cash Sent To Bank</h5>
                                </div>
                            </div>

                            <br />

                            <div className="row container-fluid justify-content-center">
                                <div className="col-10">
                                    <label>Depositor Name</label>
                                    <input required
                                        className='form-control'
                                        type='text'
                                        value={depositor}
                                        onChange={(e) => setDepositor(e.target.value)}

                                    />
                                </div>

                                <div className='col-10 mt-2'>
                                    <Form.Group controlId="payment-category">
                                        <Form.Label>Depositing Bank</Form.Label>
                                        <Form.Control as="select" required
                                            value={bank}
                                            onChange={(e) => {
                                                setBank(e.target.value)
                                            }}
                                        >
                                            <option value="">Select a Bank</option>
                                            <option value="WESTROAD DEVELOPERS PVT LTD A/C-50442540521">WESTROAD DEVELOPERS PVT LTD A/C</option>
                                            <option value="WESTROAD HEIGHTS COLLECTION A/C-38861908851">WESTROAD HEIGHTS COLLECTION A/C</option>

                                        </Form.Control>
                                    </Form.Group>
                                </div>
                            </div>

                            <br />

                            <div className="row container-fluid justify-content-center">
                                <div className="col-6 text-center">
                                    <button
                                        className="modal__btns"
                                        type="submit"

                                    >
                                        Save
                                    </button>
                                </div>

                                <div className="col-6 text-center">
                                    <button
                                        className="modal__btns"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setOpen3(false)
                                            setBank('')
                                            setDepositor('')
                                        }}

                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>

            {/* Modal 4 */}
            <Modal
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
                className={classes.modal}
                open={open4}
                onClose={() => setOpen4(false)}
                closeAfterTransition
            >
                <Fade in={open4}>
                    <div className={classes.paper}>
                        <form

                            onSubmit={(e) => handleModal3(e)}
                            style={{
                                display: 'flex',
                                // border : '2px solid green',
                                width: '110%',
                                flexDirection: 'column',
                                // alignItems: 'center'
                            }}>

                            <div className="row container-fluid justify-content-center">
                                <div className="col-12">
                                    <h3>Cash Deposit Confirmed</h3>
                                </div>
                            </div>

                            <br />

                            <div className="row container-fluid justify-content-center">
                                <div className="col-6">
                                    <label>Cash Submit Date</label>
                                    <input
                                        type={'date'}
                                        value={date}
                                        required
                                        onChange={(e) => setDate(e.target.value)}
                                    />
                                </div>
                            </div>

                            <br />

                            <div className="row container-fluid justify-content-center">
                                <h5 className='col-12'>Do you Confirm the Cash Deposit ?</h5>
                            </div>

                            <br />

                            <div className="row container-fluid justify-content-center">
                                <div className="col-6 text-center">
                                    <button
                                        className="modal__btns"
                                        type="submit"

                                    >
                                        Yes
                                    </button>
                                </div>

                                <div className="col-6 text-center">
                                    <button
                                        className="modal__btns"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setOpen4(false)
                                        }}

                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>


        </div >
    )
}

export default CashDeposit