import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {BASE_URL} from "../../utils/config/url";
import axios from "axios";
import {Modal, Backdrop, Fade} from "@material-ui/core";
import {Form} from "react-bootstrap";
import Swal from "sweetalert2";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const DelegateTask = ({taskID, open, setOpen, setChange}) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [userName, setUserName] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  //
  const handleClose = () => {
    setOpen(!open);
  };
  //
  const handleUser = (value) => {
    const separateValue = value.split(", ");
    setUserName(separateValue[0]);
    setUserFullName(separateValue[1]);
  };
  useEffect(() => {
    axios.get(`${BASE_URL}/api/v1/user/getListOfUsers?isActive=${true}`, {headers: {Authorization: `bearer ${userInfo.token}`}}).then((response) => {
      // console.log(response)
      setUsers(response.data.userList);
    });
  }, []);
  const delegateTask = (e) => {
    e.preventDefault();

    axios
      .post(
        `${BASE_URL}/api/v1/hrmgmt/task/delegateTaskToAnotherUser`,
        {
          taskID: taskID,
          userName: userName,
          userFullName: userFullName,
        },
        {headers: {Authorization: `bearer ${userInfo.token}`}}
      )
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          });
          setOpen(false);
          setChange((prev) => !prev);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      });
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Form.Group className="mb-3" controlId="status">
              <Form.Label>Delegate to</Form.Label>
              <Form.Control as="select" onChange={(e) => handleUser(e.target.value)}>
                <option>---Select an user---</option>
                {users?.map((single, index) => (
                  <option key={index} value={`${single?.userName}, ${single?.userFullName}`}>
                    {single?.userFullName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <div className="text-center mt-4">
              <button className="btn btn-secondary btn-user" disabled={!userName} onClick={delegateTask}>
                Save
              </button>
              &nbsp;&nbsp;
              <button
                className="btn btn-secondary btn-user"
                onClick={handleClose}
                style={{
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default DelegateTask;
