import { Navigate} from "react-router-dom";
import AddBrokerForm from "./AddBrokerForm.js";

const AddBroker=()=>{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if( userInfo.token === undefined ) {
    return <Navigate to="/login" noThrow />
  }
  else {
  return <AddBrokerForm/>;
  }
}

export default AddBroker;