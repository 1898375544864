import React, { useRef } from "react";
import "./TabletHeader.scss";
import { MdApartment } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { useLocation } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";

const TabletHeader = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const site = JSON.parse(localStorage.getItem("site"));
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const usePathName = () => {
    const location = useLocation();
    return location.pathname;
  };

  // console.log(usePathName());

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div className="tablet-header">
      {usePathName() === `/tabletView/dashboard/${site?.siteCode}` ? (
        <div className="tablet-header-icon mx-2">
          <AiFillHome className="icon" />
        </div>
      ) : (
        <div className="tablet-header-icon mx-2">
          <MdApartment className="icon" />
        </div>
      )}

    
      {usePathName() === `/tabletView/dashboard/${site?.siteCode}` ? (
        <span
          onClick={() => {
            navigate("/tabletView");
          }}
        >
          Back To Projects
        </span>
      ) : (
        <span
          onClick={() => {
            navigate(`/tabletView/dashboard/${site?.siteCode}`);
          }}
        >
          {site?.siteName}
        </span>
      )}
      {/*site?.siteName && <span>{site?.siteName}</span>*/}
      <div
        className="tablet-header-logged"
        onClick={() => {
          setOpen(true);
        }}
      >
        <img src={userInfo?.userPhoto} alt="" />
        {userInfo?.userName}
        <IoIosArrowDown />
      </div>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        id="popper"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  // onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={() => navigate("/")}>Back to ERP</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default TabletHeader;
