import React from "react";
import "./SelfOnboard.scss";
import { Paper } from "@mui/material";
import ViewEducation from "../ViewEducation/ViewEducation";

const UploadEducationDetails = ({ employeeId, onBoardInfo, setActive }) => {
  return (
    <div className="selfOnboard">
      <Paper elevation={3} className="selfOnboard__paper">
        <ViewEducation employeeId={employeeId} onBoardInfo={onBoardInfo} />
        <br/>
        <div className="row justify-content-between">
          <div className="col-1">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setActive("govtId");
              }}
            >
              Prev
            </button>
          </div>
          <div className="col-1">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setActive("work");
              }}
            >
              Next
            </button>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default UploadEducationDetails;
