import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import { BASE_URL } from "../../utils/config/url";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MaterialTable from "material-table";
import { Switch } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];
const LoanCalculator = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [bankList, setBankList] = useState([]);
  const [person, setPerson] = useState("general");
  const [rate, setRate] = useState("");
  const [name, setName] = useState("");
  const [principal, setPrincipal] = useState("");
  const [year, setYear] = useState("");
  const [data1, setData1] = useState({});
  const [data2, setData2] = useState([]);

  // get list of all bank
  useEffect(() => {
    (async () => {
      const res = await axios.get(
        `${BASE_URL}/api/v1/FinanceMgmt/Bank/getListOfBank?isActive=true`,
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      );
      const data = res?.data?.banks;
      // console.log(data);
      setBankList(data);
    })();
  }, []);

  const reset = () => {
    console.log("reset");
    setPrincipal("");
    setYear("");
    setPerson("general");
    setData1({});
    setData2([]);
  };
  const handleSubmit = () => {
    console.log(rate, name, principal, year);
    axios
      .post(
        `${BASE_URL}/api/v1/FinanceMgmt/SiteVisit/loanAmortizationSchedule`,
        {
          principal: principal,
          rate: rate,
          time: year,
          bankName: name,
        },
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      )
      .then((res) => {
        setData1(res.data);
        setData2(res.data.amortizationSchedule);
      });
  };
  return (
    <div className="outlet_bg">
      <div className="outlet_container">
        <div className="row justify-content-center my-4">
          <div className="col-lg-8">
            <h1>Home Loan Calculator</h1>
          </div>
        </div>
        <div className="row mt-3 container-fluid justify-content-center">
          <div className="col-lg-4 col-sm-12">
            <label>Principal Amount</label>
            <input
            step="any"
              type="number"
              class="form-control"
              name="amount"
              id="amount"
              value={principal}
              required
              onChange={(e) => setPrincipal(parseFloat(e.target.value))}
            />
          </div>

          <div className="col-lg-4 col-sm-12">
            <label>Year</label>
            <input
            step="any"
              type="number"
              class="form-control"
              name="year"
              id="year"
              value={year}
              required
              onChange={(e) => setYear(parseInt(e.target.value))}
            />
          </div>
        </div>
        <div className="row justify-content-center my-4">
          <div className="">
            <label>Bank</label>
            <Form.Control
              style={{ width: "340px" }}
              as="select"
              onChange={(e) => {
                const arr1 = e.target.value;
                const arr = arr1.split(", ");

                setRate(parseFloat(arr[0]));
                setName(arr[1]);
              }}
            >
              <option disabled selected className="text-secondary">
                Select a Bank
              </option>
              {bankList.map((ul, i) => (
                <option
                  value={`${
                    person === "general"
                      ? ul.rateOfInterest
                      : ul.rateOfInterestWomen
                  }, ${ul.bankName}`}
                >
                  {ul.bankName}
                </option>
              ))}
            </Form.Control>
          </div>
        </div>
        <div className="row my-4 justify-content-center">
          <div className="col-lg-3 ml-5">
            <FormControl>
              <FormLabel
                sx={{ color: "#000" }}
                id="demo-row-radio-buttons-group-label"
              >
                Applicant Type
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e) => setPerson(e.target.value)}
              >
                <FormControlLabel
                  value="general"
                  control={<Radio />}
                  label="General"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="row mt-4 justify-content-center">
          <div className=" d-flex">
            <button
              className="btn px-5 btn-secondary btn-user"
              type="reset"
              onClick={reset}
              style={{ backgroundColor: "white", color: "black" }}
            >
              Reset
            </button>
            <button
              className="btn px-5 btn-secondary btn-user"
              onClick={handleSubmit}
            >
              Calculate
            </button>
          </div>
        </div>
        <div className="my-5 mx-4">
          <h5 className="m-3">Emi Details</h5>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead sx={{ backgroundColor: "#EE4B46" }}>
                <TableRow style={{ color: "#fff" }}>
                  <TableCell align="center">Principal Amount</TableCell>
                  <TableCell align="right">Tenure</TableCell>
                  <TableCell align="right">Interest Rate</TableCell>
                  <TableCell align="right">Bank Name</TableCell>
                  <TableCell align="right">Emi Amount</TableCell>
                  <TableCell align="right"> Total Interest</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">{data1.principalAmount}</TableCell>
                  <TableCell align="right">{data1.tenure}</TableCell>
                  <TableCell align="right">{data1.interestRate}</TableCell>
                  <TableCell align="right">{data1.BankName}</TableCell>
                  <TableCell align="right">{data1.emiAmount}</TableCell>
                  <TableCell align="right">{data1.totalInterest}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="my-5">
          <MaterialTable
            data={data2}
            title={"Emi Schedule"}
            columns={[
              {
                title: "Payment Number",
                render: (rowData) => `${rowData.paymentNumber}`,
                defaultSort: "desc",
                field: "paymentNumber",
                customSort: (a, b) =>
                  a.tableData.id > b.tableData.id ? -1 : 1,
              },
              {
                title: "Principal Balance",
                render: (rowData) => `${rowData.principalBalance.toFixed(2)}`,
              },
              {
                title: "Principal Paid",
                render: (rowData) =>
                  `${rowData.principalPaymentRounded.toFixed(2)}`,
              },
              {
                title: "Interest Paid",
                render: (rowData) =>
                  `${rowData.interestPaymentRounded.toFixed(2)}`,
              },
              {
                title: "Accrued Interest",
                render: (rowData) => `${rowData.accInterestRounded.toFixed(2)}`,
              },
              {
                title: "Total Payment",
                render: (rowData) =>
                  `${(
                    rowData.principalPaymentRounded +
                    rowData.interestPaymentRounded
                  ).toFixed(2)}`,
              },
            ]}
            options={{
              search: true,
              actionsColumnIndex: 0,
              headerStyle: {
                backgroundColor: "#EE4B46",
                color: "#fff",
              },
            }}
          ></MaterialTable>
        </div>
      </div>
    </div>
  );
};

export default LoanCalculator;
