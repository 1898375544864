import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../utils/config/url";
import MaterialTable from "material-table";
import { useNavigate, useParams } from "react-router-dom";
import "./CarParkingType.scss";
import Swal from "sweetalert2";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const CarParkingType = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + userInfo.token;
  const siteCode = useParams().siteCode;
  const navigate = useNavigate();

  const [cpt, setCpt] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [cpCode, setCpCode] = useState("");

  useEffect(() => {
    getListOfCarParkingType();
  }, []);

  const getListOfCarParkingType = () => {
    axios
      .get(
        `${BASE_URL}/api/v1/Configuration/CarParkingType/getListOfCarParkingType/${siteCode}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        const carParkingType = response.data.carParkingTypeList.map((cpt) => {
          const {
            carParkingTypeName,
            carParkingTypeCode,
            carParkingTypeGrossPrice,
            carParkingTypeTotalCount,
            phaseName,
            carParkingBookedCount,
            carParkingCreatedCount,
          } = cpt;

          return {
            carParkingTypeName,
            carParkingTypeCode,
            carParkingTypeGrossPrice,
            carParkingTypeTotalCount,
            phaseName,
            carParkingBookedCount,
            carParkingCreatedCount,
          };
        });

        setCpt(carParkingType);
      });
  };

  const handleDelete = (code) => {
    axios
      .delete(
        `${BASE_URL}/api/v1/Configuration/CarParkingType/deleteCarParkingType?carParkingTypeCode=${code}&siteCode=${siteCode}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        setDeleteModal(false);
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getListOfCarParkingType();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      })
      .catch((err) => {
        setDeleteModal(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  return (
    <div className="carparkingtype">
      <div className="carparkingtype__btn">
        <button
          onClick={() =>
            navigate(`/configurations/site/addcarparkingtype/${siteCode}`)
          }
        >
          Add Car Parking Type
        </button>
      </div>

      <MaterialTable
        data={cpt}
        title="Car Parking Types"
        columns={[
          { title: "Car Parking Type", field: "carParkingTypeName" },
          { title: "car Parking Type Code", field: "carParkingTypeCode" },
          { title: "Phase Name", field: "phaseName" },
          { title: "Price", field: "carParkingTypeGrossPrice" },
          { title: "Total Count", field: "carParkingTypeTotalCount" },
          { title: "Parking Booked", field: "carParkingBookedCount" },
          { title: "Parking Created", field: "carParkingCreatedCount" },
        ]}
        options={{
          search: true,
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
          },
        }}
        actions={[
          (rowData) => ({
            icon: "remove_red_eye",
            tooltip: "Update Car Parking Type",
            onClick: (event, rowData) => {
              navigate(
                `/configurations/site/${siteCode}/editcarparkingtype/${rowData.carParkingTypeCode}`
              );
            },
          }),
          (rowData) => ({
            icon: "delete",
            tooltip: "Delete Car Parking Type",
            onClick: (event, rowData) => {
              setDeleteModal(true);
              setCpCode(rowData.carParkingTypeCode);
            },
            disabled:
              userInfo.userRole === "ITAdmin" ||
              userInfo.userRole === "Director"
                ? false
                : true,
          }),
        ]}
      ></MaterialTable>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        closeAfterTransition
      >
        <Fade in={deleteModal}>
          <div className="paper">
            <h6>Are you sure you want to delete {cpCode} ?</h6>
            <br />
            <div className="row container-fluid justify-content-center">
              <div className="col-6 text-right">
                <button
                  className="modal__btns"
                  onClick={() => handleDelete(cpCode)}
                >
                  Yes
                </button>
              </div>
              <div className="col-6 text-left">
                <button
                  className="modal__btns"
                  onClick={() => setDeleteModal(false)}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default CarParkingType;
