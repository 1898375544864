import Paper from "@mui/material/Paper";
import { formatDate } from "../../../utils/helper";
const Details = ({ employee }) => {

  console.log("employee", employee);

  const {
    employeeId,
    employeeFirstName,
    employeeLastName,
    employeeMiddleName,
    employeeRole,
    officialMobile,
    personalMobile,
    officialEmail,
    personalEmail,
    employeeDOB,
    jobLevel,
    designation,
    employeeAddress,
    emergencyContact,
  } = employee;
  let fullAddress, landMark, city, state, pinCode, address, mobile, name;
  if (employeeAddress) {
    ({ fullAddress, landMark, city, state, pinCode } = employeeAddress);
  }

  if (emergencyContact) {
    ({ address, mobile, name } = emergencyContact);
  }

  return (
    <div>
      <Paper elevation={4} style={{ padding: "1rem", marginTop: "2rem" }}>
        <br />
        <div className="row justify-content-center">
          <div className="col-3">
            <label>Employee ID</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={employeeId}
            />
          </div>
          <div className="col-3">
            <label>Employee First Name</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={employeeFirstName}
            />
          </div>
          <div className="col-3">
            <label>Employee Middle Name</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={employeeMiddleName}
            />
          </div>
          <div className="col-3">
            <label>Employee Last Name</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={employeeLastName}
            />
          </div>
        </div>
        <br />
        <div className="row justify-content-start">
          <div className="col-3">
            <label>Employee Role</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={employeeRole}
            />
          </div>
          <div className="col-3">
            <label>Official Mobile</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={officialMobile}
            />
          </div>
          <div className="col-3">
            <label>Personal Mobile</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={personalMobile}
            />
          </div>
          <div className="col-3">
            <label>Official Email</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={officialEmail}
            />
          </div>
        </div>
        <br />
        <div className="row justify-content-start">
          <div className="col-3">
            <label>Personal Email</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={personalEmail}
            />
          </div>
          <div className="col-3">
            <label>Employee DOB</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={formatDate(employeeDOB)}
            />
          </div>
          <div className="col-3">
            <label>Job Level</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={jobLevel}
            />
          </div>
          <div className="col-3">
            <label>Designation</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={designation}
            />
          </div>
        </div>
        <br />
        <div className="row justify-content-start">
          <div className="col-3">
            <label>Full Address</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={fullAddress}
            />
          </div>
          <div className="col-3">
            <label>Land Mark</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={landMark}
            />
          </div>
          <div className="col-3">
            <label>City</label>
            <input className="form-control" type="text" disabled value={city} />
          </div>
          <div className="col-3">
            <label>State</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={state}
            />
          </div>
        </div>
        <br />
        <div className="row justify-content-start">
          <div className="col-3">
            <label>Pin Code</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={pinCode}
            />
          </div>
        </div>
      </Paper>
      <br />
      <br />
      <Paper elevation={4} style={{ padding: "1rem" }}>
        <br />
        <h4>Emergency Contact Details </h4>
        <div className="row justify-content-center">
          <div className="col-3">
            <label>Name</label>
            <input className="form-control" type="text" disabled value={name} />
          </div>
          <div className="col-3">
            <label>Mobile</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={mobile}
            />
          </div>
          <div className="col-3">
            <label>Address</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={address}
            />
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default Details;
