import React from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import './AttendanceManagement.scss';

const AttendanceManagement = () => {

    const navigate = useNavigate();

    return (
        <div className='attendance-management'>

            <Button navigateTo={'/home'} />
            <br />
            <h2>Attendance Management</h2>
            <br />

            <div className='leave-management__btns-list'>

                <button className='leave-management__btns'
                    onClick={() => navigate('/attendanceManagement/addScanIn')}
                >Add Scan In Attendance</button>

                <button className='leave-management__btns'
                    onClick={() => navigate('/attendanceManagement/addWFH')}
                >Add WFH Entry</button>

                <button className='leave-management__btns'
                    onClick={() => navigate('/attendanceManagement/addFieldWork')}
                >Add Field Work Entry</button>

                {/* <button className='leave-management__btns'
                    onClick={() => navigate('/attendanceManagement/listOfAttendance')}
                >
                    Manage Attendance
                </button> */}

                <button className='leave-management__btns'
                    onClick={() => navigate('/attendanceManagement/attendanceApproval')}
                >Approve/Reject Attendance</button>


                <button className='leave-management__btns'
                    onClick={() => navigate('/attendanceManagement/attendanceReport')}
                >Employee Attendance Report</button>


            </div>

        </div>
    )
}

export default AttendanceManagement