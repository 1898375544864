import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../../utils/config/url';
import Swal from 'sweetalert2';
import { Switch } from '@mui/material';
import './ViewInvBrand.scss'
import Button from '../../components/Button/Button';
import { styled } from '@mui/material/styles';

const RedSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#EE4B46",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#EE4B46",
    },
}));

const ViewInvBrand = () => {

    const [edit, setEdit] = useState(false)
    const [brand, setBrand] = useState({})

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const brandCode = useParams().brandCode
    // console.log(brand)

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/stockmgmtapi/brand/getBrandByBrandCode/${brandCode}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                if (response.data.status === true) {
                    setBrand(response.data.Brand)
                } else {
                    setBrand({})
                }
            })

    }, [])

    const dateFormatting = (input) => {

        if (!input) return

        let date = new Date(input)
        let d = date.getDate()
        if (d < 10) d = `0${d}`
        let m = date.getMonth() + 1
        if (m < 10) m = `0${m}`
        let y = date.getFullYear()

        return `${y}-${m}-${d}`
    }

    const updateBrand = (e) => {
        e.preventDefault();

        // console.log(brand)

        axios.put(`${BASE_URL}/api/v1/stockmgmtapi/brand/updateBrandByBrandCode`,
            {
                brandCode: brand.brandCode,
                update: brand
            },
            {
                headers: {
                    Authorization: `bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                        timer: 2000
                    })
                        .then(() => {
                            setEdit(false)
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.message,
                        timer: 2000
                    })
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: err.response.data.error,
                    timer: 2000
                })
            })
    }


    return (
        <div className='inv-brand'>

            <Button navigateTo='/listOfBrand' />

            <br />

            <div className='row justify-content-center mb-5'>
                <div className='col-6 text-center'>
                    <h1
                        style={{
                            color: '#EE4B46'
                        }}
                    >View/Edit Brand</h1>
                </div>
            </div>

            <form
                onSubmit={(e) => updateBrand(e)}
            >

                <div className='row d-flex justify-content-center'>
                    <div className='col-6'>
                        <label>Brand Code</label>
                        <input type="text"
                            className='form-control'
                            value={brand.brandCode} readOnly />
                    </div>
                    <div className='col-6'>
                        <label>Brand Name</label>
                        <input type="text"
                            className='form-control'
                            value={brand.brandName} readOnly={!edit}
                            onChange={(e) => setBrand((prevbrand) => {
                                return {
                                    ...prevbrand,
                                    brandName: e.target.value
                                }
                            })}
                        />
                    </div>
                </div>

                <br />

                <div className='row d-flex justify-content-center'>

                    <div className='col-4 text-center mt-3'>
                        <label>Active</label>
                       
                            <RedSwitch
                                checked={brand?.isActive ? true : false}
                                disabled={!edit}
                                onChange={(e) => {
                                    setBrand((prevbrand) => {
                                        return {
                                            ...prevbrand,
                                            isActive: !prevbrand.isActive
                                        }
                                    })
                                }}
                            />
                       
                    </div>

                    <div className='col-6'>
                        <label>Description</label>
                        <textarea type="text"
                            className='form-control'
                            value={brand.brandDescription} readOnly={!edit}
                            onChange={(e) => setBrand((prevbrand) => {
                                return {
                                    ...prevbrand,
                                    brandDescription: e.target.value
                                }
                            })}
                        />
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>

                    <div className='col-6'>
                        <label>Created By</label>
                        <input type="text"
                            className='form-control'
                            value={brand.createdByFullName} readOnly
                            onChange={(e) => setBrand((prevbrand) => {
                                return {
                                    ...prevbrand,
                                    createdByFullName: e.target.value
                                }
                            })}
                        />
                    </div>

                    <div className='col-6'>
                        <label>Created On</label>
                        <input type="date"
                            className='form-control'
                            value={dateFormatting(brand?.createdDate)}
                            readOnly
                        />
                    </div>
                </div>

                <br />
                <br />


                {
                    edit === false
                        ?
                        <div className='row justify-content-center'>
                            <div className='col-6 text-center'>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setEdit(true)
                                    }}
                                >Edit</button>
                            </div>
                        </div>
                        :
                        <div className='row justify-content-center'>
                            <div className='col-6 text-center'>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setEdit(false)
                                    }}
                                    style={{
                                        backgroundColor: 'white',
                                        color: '#EE4B46',
                                        border: '2px solid #EE4B46'
                                    }}
                                >Cancel</button>
                            </div>
                            <div className='col-6 text-center'>
                                <button>Save</button>
                            </div>
                        </div>
                }


            </form>


        </div>
    )
}

export default ViewInvBrand