import React from 'react'
import './CashBack.scss';


const CashBack = () => {
    return (
        <div className='cashback'>
            <h2>Coming Soon</h2>
        </div>
    )
}

export default CashBack