import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import "./CashPayment.scss";
import MaterialTable, { MTableToolbar } from "material-table";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
const CashPayment = () => {
  const navigate = useNavigate();

  //table data
  const [cashPayments, setCashPayments] = useState([]);

  //filter list state data
  const [listPaymentType, setListPaymentType] = useState([]);
  const [listPayerUserFullName, setListPayerUserFullName] = useState([]);
  const [listCostCenter, setListCostCenter] = useState([]);

  //fitler states
  const [finYear, setFinYear] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [payerUserFullName, setPayerUserFullName] = useState("");
  const [costCenter, setCostCenter] = useState("");
  //get the userinfo
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    getListOfCashPayment();
  }, []);

  const getListOfCashPayment = async () => {
    const { data } = await axios.get(
      `${BASE_URL}/api/v1/FinanceMgmt/CashPayment/getListOfCashPayment`,
      {
        headers: { Authorization: `bearer ${userInfo.token}` },
      }
    );
    setCashPayments(data.listOfCashPayment);
  };

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true&employeeRole=Finance`,
        {
          headers: {
            Authorization: `bearer ${userInfo.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setListPayerUserFullName(response.data.employees);
        } else {
          setListPayerUserFullName([]);
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/financemgmt/costCenter/getListOfCostCenter?isAvtive=true`,
        {
          headers: {
            Authorization: `bearer ${userInfo.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setListCostCenter(response.data.Cost_Centers);
        } else {
          setListCostCenter([]);
        }
      });
  }, []);

  const handleReset = () => {
    setPaymentType("");
    setCostCenter("");
    setPayerUserFullName("");
    setFinYear("");
    getListOfCashPayment();
  };

  //filter function
  const handleFilter = async () => {
    let url = `${BASE_URL}/api/v1/FinanceMgmt/CashPayment/getListOfCashPayment?`;
    let filter = "";

    if (paymentType) {
      filter += `paymentType=${paymentType}&`;
    }

    if (costCenter) {
      filter += `costCenterCode=${costCenter}&`;
    }

    if (payerUserFullName) {
      filter += `payerEmpId=${payerUserFullName}&`;
    }

    if (finYear) {
      filter += `finYear=${finYear}&`;
    }

    url += filter;

    const { data } = await axios.get(url, {
      headers: { Authorization: `bearer ${userInfo.token}` },
    });
    setCashPayments(data.listOfCashPayment);
  };

  useEffect(() => {
    handleFilter();
  }, [paymentType, costCenter, payerUserFullName, finYear]);

  return (
    <div className="cashpayment">
      <div className="cashpayment__buttons">
        <Button navigateTo={"/home"} />
        <button
          className="cashdeposit__btn"
          onClick={() => navigate("/finance/cashpayment/receive-cash")}
        >
          Recieve cash
        </button>
      </div>
      <br />
      <br />
      <h4>Cash Payment</h4>
      <MaterialTable
        title=""
        data={cashPayments}
        columns={[
          { title: "Cash Payment ID", field: "cashPaymentId" },
          {
            title: "Payment Date",
            render: (rowData) => {
              return !rowData.paymentDate
                ? ""
                : rowData.paymentDate.substring(8, 10) +
                    "-" +
                    rowData.paymentDate.substring(5, 7) +
                    "-" +
                    rowData.paymentDate.substring(0, 4);
            },

            customSort: (a, b) => (a.paymentDate < b.paymentDate ? -1 : 1),
            defaultSort: "desc",
          },
          { title: "Financial Year", field: "finYear" },
          { title: "Cost Center", field: "costCenterName" },
          { title: "C.C. Code", field: "costCenterCode" },
          { title: "Payment Type", field: "paymentType" },

          { title: "Payee Name", field: "payeeFullName" },
          { title: "Amount", field: "amount" },
          { title: "Paid By", field: "payerUserFullName" },

        ]}
        options={{
          search: window.innerWidth < 700 ? false : true,
          searchFieldStyle: {
            width: 200,
          },
          actionsColumnIndex: 0,
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
          },
          sorting: true,
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ position: "relative", margin: "1rem 0" }}>
              <MTableToolbar {...props} />
              <div className="filters text-left">
                <FormControl
                  variant="standard"
                  className="formControl"
                  style={{
                    margin: "0px 10px",
                    marginTop: "-65px",
                    width: "150px",
                  }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Financial Year
                  </InputLabel>
                  <Select
                    id="demo-simple-select-standard"
                    value={finYear}
                    onChange={(e) => {
                      setFinYear(e.target.value);
                    }}
                    className={""}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">Financial Year</MenuItem>
                    {[
                      "2020-21",
                      "2021-22",
                      "2022-23",
                      "2023-24",
                      "2024-25",
                    ].map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="standard"
                  className="formControl"
                  style={{
                    margin: "0px 10px",
                    marginTop: "-65px",
                    width: "150px",
                  }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Payment Type
                  </InputLabel>
                  <Select
                    id="demo-simple-select-standard"
                    value={paymentType}
                    onChange={(e) => {
                      setPaymentType(e.target.value);
                    }}
                    className={""}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">Payment Type</MenuItem>
                    <MenuItem value="Claims">Claims</MenuItem>
                    <MenuItem value="Salary">Salary</MenuItem>
                    <MenuItem value="Labour Payment">Labour Payment</MenuItem>
                    <MenuItem value="Vendor Payment">Vendor Payment</MenuItem>
                    <MenuItem value="Petty Payments">Petty Payments</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                  </Select>
                </FormControl>

                {window.innerWidth < 1300 && (
                  <>
                    <br />
                  </>
                )}

                <FormControl
                  variant="standard"
                  className="formControl"
                  style={{
                    margin: "0px 10px",
                    marginTop: window.innerWidth < 1300 ? "-40px" : "-65px",
                    width: "10rem",
                  }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Payer Full Name
                  </InputLabel>
                  <Select
                    id="demo-simple-select-standard"
                    value={payerUserFullName}
                    onChange={(e) => {
                      setPayerUserFullName(e.target.value);
                    }}
                    className={""}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">Payer FullName</MenuItem>
                    {listPayerUserFullName?.map((payer, index) => {
                      return (
                        <MenuItem key={index} value={payer?.employeeId}>
                          {payer.employeeFullName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                {/* {window.innerWidth < 1300 && <br />} */}

                <FormControl
                  variant="standard"
                  className="formControl"
                  style={{
                    margin: "0px 10px",
                    marginTop: window.innerWidth < 1300 ? "-40px" : "-65px",
                  }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Cost Center
                  </InputLabel>
                  <Select
                    id="demo-simple-select-standard"
                    value={costCenter}
                    onChange={(e) => {
                      setCostCenter(e.target.value);
                    }}
                    className={""}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">Cost Center</MenuItem>
                    {listCostCenter?.map((center, index) => {
                      return (
                        <MenuItem key={index} value={center?.costCenterCode}>
                          {center?.costCenterName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                {/* {window.innerWidth < 1300 && <br />} */}

                <FormControl
                  style={{
                    margin: "0px 10px",
                    marginTop: window.innerWidth < 1300 ? "-30px" : "-55px",
                  }}
                >
                  <button onClick={handleReset} className="resetBtn">
                    Reset Filter
                  </button>
                </FormControl>
              </div>
            </div>
          ),
        }}
      ></MaterialTable>
    </div>
  );
};

export default CashPayment;
