import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button';
import { Form } from "react-bootstrap";
import './ReceiveCashForm.scss'
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


const ReceiveCashForm = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const navigate = useNavigate();

    const [category, setCategory] = useState('')
    const [subCategory, setSubCategory] = useState('')
    const [receivedDate, setReceivedDate] = useState('')
    const [amount, setAmount] = useState()
    const [customerIds, setCustomerIds] = useState([])
    const [customerId, setCustomerId] = useState('')
    const [description, setDescription] = useState('')
    const [siteCode, setSiteCode] = useState('')
    const [sites, setSites] = useState([])
    const [phaseCode, setPhaseCode] = useState('')
    const [phases, setPhases] = useState([])


    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/Configuration/Site/getAllSites`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)
                setSites(response.data.siteArray)
            })
    }, [])

    useEffect(() => {

        if (!siteCode || siteCode === ' ') {
            setPhaseCode('')
            setSiteCode('')
            return
        }

        axios.get(`${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                //console.log(response)
                setPhases(response.data.site.phases)
            })
    }, [siteCode])


    useEffect(() => {

        if (!siteCode || !phaseCode) {
            return
        }

        axios.get(`${BASE_URL}/api/v1/CustomerMgmt/Customer/getListOfCustomers?siteCode=${siteCode}&phaseCode=${phaseCode}&isActive=true`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                console.log(response)
                let arr = []
                response.data.customerList?.forEach((c) => {
                    arr.push(c.customerId)
                })

                setCustomerIds(arr)
            })

    }, [siteCode, phaseCode])


    const handleFormSubmit = (e) => {
        e.preventDefault()

        let body = {};

        if (description) {
            body = {
                paymentCategory: category,
                paymentSubCategory: subCategory,
                recievedByUserName: userInfo?.userName,
                recievedByUserFullName: userInfo?.userFullName,
                recievedDate: new Date(),
                cashAmount: parseInt(amount),
                paymentDescription: description,
                customerId: customerId,
                siteCode,
                phaseCode
            }
        } else {
            body = {
                paymentCategory: category,
                paymentSubCategory: subCategory,
                recievedByUserName: userInfo?.userName,
                recievedByUserFullName: userInfo?.userFullName,
                recievedDate: new Date(),
                customerId: customerId,
                siteCode,
                phaseCode,
                cashAmount: parseInt(amount),
            }
        }

        axios.post(`${BASE_URL}/api/v1/FinanceMgmt/CashDeposit/receivecash`,
            body,
            {
                headers: { Authorization: `bearer ${userInfo.token}` },
            },
        )
            .then((response) => {
                console.log(response)
                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: 'Form Submited Successfully',
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        navigate(-1)
                    })
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }

    return (
        <div className='receivecash'>

            <Button navigateTo={'/finance/cashdeposit'} />

            <br />
            <br />

            <div className="row container-fluid justify-content-center" >
                <div className="col-8">
                    <h2>Receive Cash Form</h2>
                </div>
            </div>
            <br />

            <form
                onSubmit={(e) => handleFormSubmit(e)}
            >

                <div className="row container-fluid justify-content-center">
                    <div className="col-4">
                        <Form.Group controlId="payment-category">
                            <Form.Label>Payment Category</Form.Label>
                            <Form.Control as="select" required
                                value={category}
                                onChange={(e) => {
                                    setCategory(e.target.value)
                                }}
                            >
                                <option value="">Select a Category</option>
                                <option value="CustomerPayment">Customer Payment</option>
                                <option value="OtherPayment">Other Payment</option>
                            </Form.Control>
                        </Form.Group>
                    </div>

                    {
                        category === 'CustomerPayment' ?
                            <div className='col-4'>
                                <Form.Group controlId="payment-sub-category">
                                    <Form.Label>Payment Sub-Category</Form.Label>
                                    <Form.Control as="select" required
                                        value={subCategory}
                                        onChange={(e) => {
                                            setSubCategory(e.target.value)
                                        }}
                                    >
                                        <option value="">Select a Sub Category</option>
                                        <option value="DemandPayment">Demand Payment</option>
                                        <option value="FinalPayment">Final Payment</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            : category === 'OtherPayment' ?
                                <div className='col-4'>
                                    <Form.Group controlId="payment-sub-category">
                                        <Form.Label>Payment Sub-Category</Form.Label>
                                        <Form.Control as="select" required
                                            value={subCategory}
                                            onChange={(e) => {
                                                setSubCategory(e.target.value)
                                            }}
                                        >
                                            <option value="">Select a Sub Category</option>
                                            <option value="MaterialsSold">Materials Sold</option>
                                            <option value="OtherPayment">Other Payment</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>

                                :

                                <div className='col-4'>
                                    <Form.Group controlId="payment-sub-category">
                                        <Form.Label>Payment Sub-Category</Form.Label>
                                        <Form.Control as="select" required
                                            value={subCategory}
                                            onChange={(e) => {
                                                setSubCategory(e.target.value)
                                            }}
                                        >
                                            <option value="">Select a Sub Category</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                    }

                </div>

                <br />

                <div className="row container-fluid justify-content-center">

                    <div className='col-4'>
                        <label>Cash Amount</label>
                        <input type='number' className='form-control' required
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)} />
                    </div>

                    <div className='col-4'>
                        <Form.Group controlId="Site-Name">
                            <Form.Label>Site Name</Form.Label>
                            <Form.Control as="select" required
                                value={siteCode}
                                onChange={(e) => {
                                    setSiteCode(e.target.value)
                                }}
                            >
                                <option value=" ">Select a Site</option>
                                {
                                    sites?.map((s, index) => {
                                        return <option key={index} value={s.siteCode}>{s.siteName}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>

                </div>

                <br />

                <div className="row container-fluid justify-content-center">


                    <div className='col-4'>
                        <Form.Group controlId="Site-Name">
                            <Form.Label>Phase Name</Form.Label>
                            <Form.Control as="select" required
                                value={phaseCode}
                                onChange={(e) => {
                                    setPhaseCode(e.target.value)
                                }}
                            >
                                <option value=" ">Select a Phase</option>
                                {
                                    phases?.map((p, index) => {
                                        return <option key={index} value={p.phaseCode}>{p.phaseName}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>

                    <div className='col-4'>
                        <Form.Group controlId="Site-Name">
                            <Form.Label>Customer ID</Form.Label>
                            <Form.Control as="select" required
                                value={customerId}
                                onChange={(e) => {
                                    setCustomerId(e.target.value)
                                }}
                            >
                                <option value="">Select a Customer ID</option>
                                {
                                    customerIds?.map((c, index) => {
                                        return <option key={index} value={c}>{c}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>

                <br />

                <div className="row container-fluid justify-content-center">
                    <div className='col-8'>
                        <label>Description</label>
                        <textarea className='form-control' value={description} placeholder="Description of Cash deposit"
                            onChange={(e) => setDescription(e.target.value)} />
                    </div>
                </div>

                <br />
                <br />
                <div className="row container-fluid justify-content-center">
                    <button type='submit' className='submit-btn'>Submit</button>
                </div>
            </form>

        </div>
    )
}

export default ReceiveCashForm
