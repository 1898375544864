import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import { BASE_URL } from "../../utils/config/url";
import TicketList from "./TicketList";
import Button from "../../components/Button/Button";

const Supports = () => {
  const [openTickets, setOpenTickets] = useState([]);
  const [closedTickets, setClosedTickets] = useState([]);
  const [active, setActive] = useState("al");
  const [change, setChange] = useState(false);
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userName = userInfo.userName;
  const userRole = userInfo.userRole;

  // get all open ticket
  // useEffect(() => {
  //   (async () => {
  //     const res = await axios.get(
  //       `${BASE_URL}/api/v1/CustomerMgmt/Ticket/getListOfTickets?isOpen=true`,
  //       {
  //         headers: { Authorization: `bearer ${userInfo.token}` },
  //       }
  //     );
  //     const data = res?.data?.tickets;
  //     setOpenTickets(data);

  //   })();
  // }, [change]);

  //  get all closed tickets
  // useEffect(() => {
  //   (async () => {
  //     const res = await axios.get(
  //       `${BASE_URL}/api/v1/CustomerMgmt/Ticket/getListOfTickets?isOpen=false`,
  //       { headers: { Authorization: `bearer ${userInfo.token}` } }
  //     );
  //     const data = res.data.tickets;
  //     setClosedTickets(data);
  //   })();
  // }, [change]);


  return (
    <div className="outlet_bg">
      <div className="outlet_container">
        <div className="d-flex justify-content-between align-items-center">
          <Button navigateTo={'/home'} />

          <button
            className="create_btn px-4 fw-bold"
            onClick={() => navigate("/addticket")}
          >
            Add Ticket
          </button>
        </div>
        <div className="profile_tabs">
          <button
            className={`${active === "al" ? "bg-red" : "bg-dark"}`}
            onClick={() => {
              setActive("al");
              setChange(!change)
            }}
          >
            Open Tickets
          </button>

          <button
            className={`${active === "cl" ? "bg-red" : "bg-dark"}`}
            onClick={() => {
              setActive("cl");
              setChange(!change)
            }}
          >
            Closed Tickets
          </button>
        </div>
        {/* tickets tabs  */}
        <div>
          {active === "al" && (
            <TicketList title="Open Tickets" tab={active} />
          )}

          {active === "cl" && (
            <TicketList
              title="Closed Tickets"
              tab={active}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Supports;
