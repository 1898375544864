import React, { useEffect, useState } from 'react'
import './PayRoll.scss';
import Nav from 'react-bootstrap/Nav';
import Generated from '../../components/PayRoll/Generated';
import Approved from '../../components/PayRoll/Approved';
import Rejected from '../../components/PayRoll/Rejected';
import Disbursed from '../../components/PayRoll/Disbursed';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import Swal from 'sweetalert2';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Form } from 'react-bootstrap';
import Button from '../../components/Button/Button';

const PayRoll = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    const [tab, setTab] = React.useState( userInfo?.userRole === 'Finance' ? 'approved' : 'generated')
    const [payRollModal, setPayRollModal] = useState(false);
    const [spinnerModal, setSpinnerModal] = useState(false);
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [runFor, setRunFor] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [listOfEmployee, setListOfEmployee] = useState([]);

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`,
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {

                // console.log(response)
                if (response.data.status) {
                    setListOfEmployee(response.data.employees)
                } else {
                    setListOfEmployee([])
                }

            })
            .catch((err) => {
                setListOfEmployee([])
            })

    }, [])

    const generateMonthlyPayroll = (e) => {

        e.preventDefault()

        if (!year || !month) return;

        let body = {
            year,
            month,
            currentUserEmpId: userInfo.employeeId
        }

        if (runFor === 'One Employee') {
            body = {
                ...body,
                employeeId
            }
        }

     

        let url = `${BASE_URL}/api/v1/hrmgmt/payslip/runMonthlyPayroll`;

        if (runFor === "One Employee") {
            url = `${BASE_URL}/api/v1/hrmgmt/payslip/generatePayrollByEmployee`
        }


        setPayRollModal(false)
        setSpinnerModal(true)
        axios.post(url,
            body
            , {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response)
                setSpinnerModal(false)
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                        .then(() => window.location.reload())
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                    })
                }
            })
            .catch((err) => {
                // console.log(err)
                setSpinnerModal(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error,
                })
            })
    }

    return (
        <div className='payroll'>

            <div className='row justify-content-between'>
                <div className='col- text-center'>
                    <Button navigateTo='/home' />
                </div>

                {
                    (userInfo?.userRole === 'Director' || userInfo?.userRole === 'HR')
                    &&
                    <div className='col-4 text-center'>
                        <button className='payroll__btn'
                            onClick={() => (setPayRollModal(true))}
                        >Generate Monthly Payroll</button>
                    </div>
                }

            </div>

            <br />
            <br />

            {
                (userInfo?.userRole != 'Director' && userInfo?.userRole != 'HR' && userInfo?.userRole != 'Finance')
                    ?
                    <h2 style={{ color: '#EE4B46', textAlign: 'center' }}>You do not have access to this section</h2>
                    :
                    <>
                        <div className="tabs mb-5">
                            <Nav>

                                {
                                    (userInfo?.userRole === 'Director' || userInfo?.userRole === 'HR')
                                    &&

                                    <Nav.Item
                                        onClick={() => {
                                            setTab('generated')
                                        }}
                                    >
                                        <Nav.Link className={tab === 'generated' ? 'active' : 'inactive'} eventKey="first">
                                            Generated Payslips
                                        </Nav.Link>
                                    </Nav.Item>
                                }

                                <Nav.Item
                                    onClick={() => {
                                        setTab('approved')
                                    }}
                                >
                                    <Nav.Link className={tab === 'approved' ? 'active' : 'inactive'} eventKey="second">
                                        HR Approved Payslips
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item
                                    onClick={() => {
                                        setTab("disbursed")
                                    }}
                                >
                                    <Nav.Link className={tab === 'disbursed' ? 'active' : 'inactive'} eventKey="fourth">
                                        Salary Disbursed
                                    </Nav.Link>
                                </Nav.Item>

                                {
                                    (userInfo?.userRole === 'Director' || userInfo?.userRole === 'HR')
                                    &&
                                    <Nav.Item
                                        onClick={() => {
                                            setTab('rejected')
                                        }}
                                    >
                                        <Nav.Link className={tab === 'rejected' ? 'active' : 'inactive'} eventKey="third">
                                            Rejected Payslips
                                        </Nav.Link>
                                    </Nav.Item>}

                            </Nav>

                        </div>

                        {tab === 'generated' && <Generated />}
                        {tab === 'approved' && <Approved />}
                        {tab === 'rejected' && <Rejected />}
                        {tab === 'disbursed' && <Disbursed />}

                    </>
            }

            {/* /////////////////Modal for spinner /////////// */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={spinnerModal}
                onClose={() => setSpinnerModal(false)}
                closeAfterTransition
            >
                <Fade in={spinnerModal}>

                    <div>
                        <ClipLoader
                            color='#EE4B46'
                            loading={spinnerModal}
                            size={70}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={payRollModal}
                onClose={() => {
                    setPayRollModal(false)
                    setYear('')
                    setMonth('')
                }}
                closeAfterTransition
            >
                <Fade in={payRollModal}>

                    <form className='paper w-50'
                        onSubmit={(e) => generateMonthlyPayroll(e)}
                    >
                        <div className='row d-flex justify-content-center'>
                            <div className='col-6 text-center'>
                                <Form.Group controlId="year">
                                    <Form.Label>Year</Form.Label>
                                    <Form.Control as="select" required
                                        value={year}
                                        onChange={(e) => {
                                            setYear(e.target.value)
                                        }}
                                    >
                                        <option value="">Select a Year</option>
                                        <option value='2022'>2022</option>
                                        <option value='2023'>2023</option>
                                        <option value='2024'>2024</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>

                            <div className='col-6 text-center'>
                                <Form.Group controlId="month">
                                    <Form.Label>Month</Form.Label>
                                    <Form.Control as="select" required
                                        value={month}
                                        onChange={(e) => {
                                            setMonth(e.target.value)
                                        }}
                                    >
                                        <option value="">Select a Month</option>
                                        <option value='1'>January</option>
                                        <option value='2'>February</option>
                                        <option value='3'>March</option>
                                        <option value='4'>April</option>
                                        <option value='5'>May</option>
                                        <option value='6'>June</option>
                                        <option value='7'>July</option>
                                        <option value='8'>August</option>
                                        <option value='9'>September</option>
                                        <option value='10'>October</option>
                                        <option value='11'>November</option>
                                        <option value='12'>December</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>

                        <br />

                        <div className='row justify-content-center'>
                            <div className='col-6'>
                                <Form.Group controlId="run for">
                                    <Form.Label>Run Payroll For</Form.Label>
                                    <Form.Control as="select" required
                                        value={runFor}
                                        onChange={(e) => {
                                            setRunFor(e.target.value)
                                        }}
                                    >
                                        <option value="">Select a Option</option>
                                        <option value='One Employee'>Individual Employee</option>
                                        <option value='All Employees'>All Employee</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>

                            {
                                runFor === 'One Employee'
                                &&
                                <div className='col-6'>
                                    <Form.Group controlId="employee">
                                        <Form.Label>Employee</Form.Label>
                                        <Form.Control as="select" required
                                            value={employeeId}
                                            onChange={(e) => {
                                                setEmployeeId(e.target.value)
                                            }}
                                        >
                                            <option value="">Select a Employee</option>
                                            {
                                                listOfEmployee.map((employee) => {
                                                    return <option value={employee?.employeeId}>{employee?.employeeFullName}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                            }
                        </div>

                        <br />
                        <br />

                        <div className='row justify-content-center'>
                            <div className='col-6 text-center'>
                                <button className='payroll__btn'
                                    type='submit'
                                >Generate</button>
                            </div>
                        </div>
                    </form>

                </Fade>
            </Modal>

        </div>
    )
}

export default PayRoll