import React, { useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import Swal from "sweetalert2";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import { useNavigate } from "react-router-dom";
import "./AddLoanBankForm.scss";
import Button from "../../components/Button/Button";

const AddSiteForm = () => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [bname, setBname] = useState("");
  const [baddress, setBaddress] = useState("");
  const [bcode, setBcode] = useState("");
  const [gi, setGi] = useState("");
  const [wi, setWi] = useState("");
  const [si, setSi] = useState("");
  const [branch, setBranch] = useState("");
  const [validated, setValidated] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [disp, setDisp] = useState("none");
  const [agent, setAgent] = useState([
    { name: "", contactNumber: "", whatsappNumber: "", emailId: "" },
  ]);
  // const [pcError, setPcError] = useState("");
  // const [scError, setScError] = useState("");

  const reset = () => {
    setBname("");
    setBaddress("");
    setBcode("");
    setGi("");
    setWi("");
    setBranch("");
  };

  useEffect(() => {
    checkRole();
  }, []);

  const checkRole = () => {
    if (userInfo.userRole === "Director" || userInfo.userRole === "ITAdmin") {
      setValidated(true);
    } else {
      setValidated(false);
      Swal.fire({
        icon: "error",
        title: "Sorry",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: "You do not have access to create New Loan Bank. Please contact your Senior Management",
      }).then(function () {
        navigate("/listofbanks");
      });
    }
  };


  const handleAG = (e) => {
    const values = [...agent];
    values.push({
      name: "",
      contactNumber: "",
      whatsappNumber: "",
      emailId: "",
    });
    setAgent(values);
  };

  const deleteAG = (e) => {
    const values = [...agent];
    values.pop();
    setAgent(values);
  };

  const handleAGChange = (index, event) => {
    const values = [...agent];
    if (event.target.name === "name") {
      values[index].name = event.target.value;
    } else if (event.target.name === "phone") {
      values[index].contactNumber = event.target.value;

      const regex = /^[0-9]{10}$/;

      if (regex.test(values[index].contactNumber)) {
        setValidated(true);
        setErrorMessage("");
      } else {
        setValidated(false);
        setErrorMessage(" Only 10 digits Number");
      }
      // console.log(values[index].contactNumber, validated, errorMessage);
    } else if (event.target.name === "whatsapp") {
      values[index].whatsappNumber = event.target.value;
    } else if (event.target.name === "email") {
      values[index].emailId = event.target.value;
    }
    setAgent(values);
  };

  const changeBcode = (e) => {
    setDisp("none");
    var value = e.target.value;
    setBcode(e.target.value);
    const regex = /^[A-Z0-9]{12}$/;

    var message = document.getElementById("bcodeMessage");
    if (regex.test(value)) {
      message.classList.remove("d-block");
      message.classList.add("d-none");
      setValidated(true);
    } else {
      message.classList.remove("d-none");
      message.classList.add("d-block");
      setValidated(false);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    const Token = "bearer" + " " + userInfo.token;
    if (validated === true) {
      axios
        .post(
          `${BASE_URL}/api/v1/FinanceMgmt/Bank/addBank`,
          {
            ifscCode: bcode,
            branchName: branch,
            bankName: bname,
            branchAddress: baddress,
            rateOfInterest: gi,
            rateOfInterestWomen: wi,
            poc: agent,
          },
          { headers: { Authorization: Token } }
        )
        .then((response) => {
          // console.log(response);

          if (response.data.status === true) {
            Swal.fire("Successfully!", "Bank Added!", "success");
            setDisp("block");
            navigate("/listofbanks");
          } else {
            Swal.fire("Something Wrong!", "Bank Not Added!", "error");
            setDisp("none");
          }
        });
    } else {
      Swal.fire("Please Fill all Field Correctly!", "Bank Not Added!", "error");
    }
  };
  return (
    <>
      <div className="addloanbank">
        <div className="mt-3 row container-fluid justify-content-center px-2">
          <div className="col-12">

           <Button navigateTo={'/listofbanks'}/>

          </div>
        </div>
        <form onSubmit={submit}>
          <div className="row mt-5 container-fluid justify-content-center">
            <div className="col-12">
              <h4>Add Bank</h4>
            </div>
          </div>
          <div className="row mt-3 container-fluid justify-content-center">
            <div className="col-lg-4 col-sm-12">
              <label>Bank Name</label>
              <input
                type="text"
                className="form-control"
                name="bankname"
                id="bankname"
                value={bname}
                required
                onChange={(e) => {
                  setBname(e.target.value);
                  setDisp("none");
                }}
              />
            </div>
            <div className="col-lg-4 col-sm-12">
              <label>IFS Code</label>
              <input
                type="text"
                className="form-control"
                name="bankcode"
                id="bankcode"
                value={bcode}
                required
                onChange={changeBcode}
              />
              <small id="bcodeMessage" className="text-danger d-none">
                Must be of 12 digits with capital letters and numbers only
                <br />
              </small>
            </div>
            <div className="col-lg-4 col-sm-12">
              <label>Branch Name</label>
              <input
                type="text"
                className="form-control"
                name="bankBranch"
                id="bankBranch"
                value={branch}
                required
                onChange={(e) => setBranch(e.target.value)}
              />
            </div>
          </div>
          <div className="row mt-3 container-fluid justify-content-center">
            <div className="col-lg-8  col-sm-12">
              <label>Branch Address</label>
              <input
                type="text"
                className="form-control"
                name="bankaddress"
                id="bankaddress"
                value={baddress}
                required
                onChange={(e) => setBaddress(e.target.value)}
              />
            </div>
          </div>

          <div className="row mt-3 container-fluid justify-content-center">
            <div className="col-lg-6 col-sm-12">
              <label>General Rate of Interest</label>
              <input
              step="any"
                type="number"
                className="form-control"
                name="gi"
                id="gi"
                value={gi}
                required
                onChange={(e) => {
                  setGi(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-6 col-sm-12">
              <label>Rate of Interest for Women</label>
              <input
                type="number"
                className="form-control"
                name="wi"
                id="wi"
                step="any"
                value={wi}
                required
                onChange={(e) => setWi(e.target.value)}
              />
            </div>
            <div className="col-lg-4 col-sm-12"></div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-lg-12 col-sm-12">
              <h5 className="pl-4">Point of Contact</h5>
              <br />
              {agent.map((agent, index) => {
                return (
                  <div className="row pl-4" key={index}>
                    <div className="col-9">
                      <div className="row">
                        <div className="col-3">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            id="name"
                            onChange={(event) => handleAGChange(index, event)}
                          />
                        </div>
                        <div className="col-3">
                          <label>Phone No.</label>
                          <input
                            type="number"
                            className="form-control"
                            name="phone"
                            id="phone"
                            onChange={(event) => handleAGChange(index, event)}
                          />
                          {errorMessage.length > 1 && (
                            <small
                              id="bcodeMessage"
                              className="text-danger"
                            >
                              Must be 10 digits of number.
                              <br />
                            </small>
                          )}
                        </div>
                        <div className="col-3">
                          <label>Whatsapp</label>
                          <input
                            type="number"
                            className="form-control"
                            name="whatsapp"
                            id="whatsapp"
                            onChange={(event) => handleAGChange(index, event)}
                          />
                        </div>
                        <div className="col-3">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            onChange={(event) => handleAGChange(index, event)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <button
                        className="add-btn mt-4"
                        onClick={() => handleAG()}
                      >
                        Add Row
                      </button>
                      &nbsp;&nbsp;
                      <button
                        className="add-btn mt-4"
                        onClick={() => deleteAG()}
                        style={{
                          display: index === 0 ? "none" : "inline-block",
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="row mt-4 container-fluid justify-content-center">
            <div className="text-center" style={{ display: disp }}>
              <em>IFSC Code already exists</em>
            </div>
            <div className="col-4 text-right">
              <button
                className="btn btn-secondary btn-user"
                type="reset"
                onClick={reset}
                style={{ backgroundColor: "white", color: "black" }}
              >
                Reset
              </button>
            </div>

            <div className="col-4">
              <button className="btn btn-secondary btn-user" type="submit">
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddSiteForm;
