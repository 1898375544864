import React, { useState } from "react";
import { BASE_URL } from "../../utils/config/url";
import westroad from '../../assets/images/westroad_logo.jpg';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./OnboardEmpLogin.scss";
import Swal from "sweetalert2";

const OnboardEmpLoginForm = () => {
  const [onboardingId, setOnboardingId] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [errorbol, setErrorbol] = useState(false);

  const ChangeOnboardingId = (e) => {
    setOnboardingId(e.target.value);
  };

  const changePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (onboardingId === "" || password === "") {
      setErrorbol(true);
      setError("Please enter Onboarding ID and Password");
    } else {
      setErrorbol(false);
      setError("");
      authenticate();
    }
  };

  const authenticate = async () => {
    try {
      const data = {
        onboardingId: onboardingId,
        onboardPwd: password,
      };
      const url = `${BASE_URL}/api/v1/auth/onboardAuth`;
      const response = await axios.post(url, data);
  
      if (response.status === 200) {
        if (response.data.onboarding.status === "SentToEmployee") {
          localStorage.setItem(
            "onBoardInfo",
            JSON.stringify({
              token: response.data.token,
              employee: response.data.employee,
              onboardingId: onboardingId,
              onboarding: response.data.onboarding,
            })
          );
          navigate("/onboarding");
        } else {
          handleLoginError(response.data.error);
        }
      } else {
        handleLoginError(response.data.error);
      }
    } catch (error) {
      handleLoginError(error.response.data.error);
    }
  };
  
  const handleLoginError = (errorMessage) => {
    console.log(errorMessage);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: errorMessage,
      showConfirmButton: true,
    }).then(function () {
      navigate("/Login");
    });
  };
  

  const navigate = useNavigate();

  return (
    <>
      <div className="onboardEmpLoginform">
        <img
          src={westroad}
          alt="westroad"
          className="onboardEmpLoginform__img"
        />

        <div className="onboardEmpLoginform__div">
          <input
            type="text"
            className="onboardEmpLoginform__input"
            id="Onboarding ID"
            value={onboardingId}
            name="Onboarding ID"
            onChange={ChangeOnboardingId}
            placeholder="Onboarding ID"
            autoFocus
            autoComplete="off"
          />
          <br />
          <input
            type="text"
            className="onboardEmpLoginform__input"
            id="password"
            value={password}
            name="password"
            onChange={changePassword}
            placeholder="Password"
            autoComplete="off"
          />

          {errorbol === true ? (
            <>
              <em
                style={{
                  color: "red",
                  size: "15px",
                  textAlign: "center",
                  margin: "10px",
                }}
              >
                {error}
              </em>
            </>
          ) : null}
          <br />
          <button
            type="submit"
            href=""
            onClick={handleLogin}
            style={{
              background: "#ee4b46",
              textAlign: "center",
            }}
            className="onboardEmpLoginform__btn"
          >
            Log In
          </button>
        </div>
      </div>
    </>
  );
};

export default OnboardEmpLoginForm;
