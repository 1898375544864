import React from 'react'
import './Alterations.scss';


const Alterations = () => {
    return (
        <div className='alterations'>
            <h2>Coming Soon</h2>
        </div>
    )
}

export default Alterations