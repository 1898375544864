import React from "react";
import apartmentImg1 from "../../../assets/tableView/apartment-1.png";
import apartmentImg2 from "../../../assets/tableView/apartment-2.png";
import apartmentImg3 from "../../../assets/tableView/apartment-3.png";
import "./MoreFeatures.scss";

export default function MoreFeatures() {
  const data = [
    "Adequate capacity standby generator by Kirloskar for common areas and services",
    "Adequate Generator power load for all apartments",
    "Car Parking-IPS Flooring",
    "Road interlocking Pavers",
    "Walkway Paver Tiles",
    "Common DTH Connectivity",
    "Intercom Connectivity",
  ];

  return (
    <>
      <div className="moreFeatures__images">
        <img src={apartmentImg1} alt="" />
        <div className="moreFeatures__images__subcontainer">
          <img src={apartmentImg2} alt="" />

          <img src={apartmentImg3} alt="" />
        </div>
      </div>
      <div className="moreFeatures__infoContainer">
        <div className="moreFeatures__infoContainer__info">
          {data.map((text) => (
            <p>{text}</p>
          ))}
        </div>
      </div>
    </>
  );
}
