import React, { useEffect, useState } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table';
import { BASE_URL } from '../../utils/config/url';
import Button from '../Button/Button';
import axios from 'axios';
import './Claims.scss';
import { useNavigate } from 'react-router-dom';
import {
    Switch,
    createMuiTheme,
    ThemeProvider,
    Select,
    InputLabel,
    MenuItem,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useStyles } from '../../utils/ModalStyles';


const ClaimsToBeSettled = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const navigate = useNavigate()
    const classes = useStyles();

    const [listOfClaims, setListOfClaims] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [employeeId, setEmployeeId] = useState('')


    useEffect(() => {

        let url = `${BASE_URL}/api/v1/hrmgmt/claims/getListOfClaims?claimStatus=FinanceApproved`;

        if (employeeId != '') {
            url += `&raisedByEmpId=${employeeId}`
        }


        axios.get(url, {
            headers: {
                Authorization: `bearer ${userInfo.token}`
            }
        })
            .then((response) => {

                if (response.data.status === true) {
                    setListOfClaims(response.data.listOfClaim)
                } else {
                    setListOfClaims([])
                }
            })

    }, [employeeId])

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                setEmployeeList(response.data.employees)
            })
    }, [])


    const dateFormatting = (date) => {

        let str = new Date(date).toLocaleString('en-US', { timeZone: 'Asia/Jakarta' });

        let d = new Date(str).getDate();
        if (d < 10) {
            d = `0${d}`
        }
        let m = new Date(str).getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = new Date(str).getFullYear();
        return `${d}-${m}-${y}`
    }


    const reset = () => {
        setEmployeeId('')
    }


    return (
        <div className='claims-comp'>


            <Button navigateTo={'/finance/claims'} />


            <h4 style={{ marginTop: '40px' }}>Claims To Be Settled</h4>
            <div className='claims-comp__table'>
                <MaterialTable
                    data={listOfClaims}
                    title=""
                    columns={[

                        { title: "Claim Id", field: "claimId" },
                        { title: "Raised By", field: "raisedByFullName" },
                        {
                            title: "Claim Type",
                            field: "claimType",
                        },
                        {
                            title: "Expense Date",
                            render: (rowData) => {

                                let res = '';
                                if (rowData.expenseDate) {
                                    res = dateFormatting(rowData.expenseDate)
                                }

                                return !rowData.expenseDate ? "" : res
                            },
                            // customSort: (a, b) => (a.expenseDate < b.expenseDate ? -1 : 1),
                            // defaultSort: "desc",
                        },
                        {
                            title: "Applied Date",
                            render: (rowData) => {

                                let res = '';
                                if (rowData.appliedDate) {
                                    res = dateFormatting(rowData.appliedDate)
                                }

                                return !rowData.appliedDate ? "" : res
                            },
                            // customSort: (a, b) => (a.appliedDate < b.appliedDate ? -1 : 1),
                            // defaultSort: "desc",
                        },

                        {
                            title: "Line Manager",
                            render: (rowData) => (rowData.isAutoApprovedLineMgr === true ? 'Auto Approved' : rowData.lineMgrFullName)
                        },
                        {
                            title: "Line Manager Approval",
                            render: (rowData) => {

                                let res = '';
                                if (rowData.lineMgrApprovalDate) {
                                    res = dateFormatting(rowData.lineMgrApprovalDate)
                                }

                                return !rowData.lineMgrApprovalDate ? "" : res
                            },
                            customSort: (a, b) => (a.lineMgrApprovalDate < b.lineMgrApprovalDate ? -1 : 1),
                            defaultSort: "desc",
                        },
                        {
                            title: 'Finance Approver Name',
                            field: "financeApproverFullName"
                        },
                        {
                            title: "Finance Approval Date",
                            render: (rowData) => {
                                let res = '';
                                if (rowData.financeApprovalDate) {
                                    res = dateFormatting(rowData.financeApprovalDate)
                                }

                                return !rowData.financeApprovalDate ? "" : res
                            },
                            customSort: (a, b) => (new Date(a.financeApprovalDate).getTime() < new Date(b.financeApprovalDate).getTime() ? -1 : 1),
                            defaultSort: "desc",
                        },

                    ]}

                    actions={[
                        {
                            icon: "remove_red_eye",
                            tooltip: "View Claim",
                            onClick: (event, rowData) => {
                                navigate(`/finance/claims/${rowData.claimId}`);
                            },
                        },
                    ]}

                    options={{
                        search: window.innerWidth < 700 ? false : true,
                        searchFieldStyle: {
                            width: 200,
                        },
                        paging: true,
                        pageSize: 20,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [5, 10, 20, 30],
                        actionsColumnIndex: 0,
                        headerStyle: {
                            backgroundColor: "#EE4B46",
                            color: "#fff",
                            whiteSpace: "nowrap",
                        },
                    }}

                    components={{
                        Toolbar: (props) => (
                            <div className="filters text-left">
                                <MTableToolbar {...props} />


                                <FormControl
                                    variant='standard'
                                    className="formControl"
                                    // style={{ marginTop: "-65px" }}
                                    style={{
                                        margin: '0px 13px',
                                        marginTop: "-65px",
                                        width: "98px",
                                    }}
                                >
                                    <InputLabel id="demo-simple-select-helper-label">
                                        Employee
                                    </InputLabel>
                                    <Select
                                        value={employeeId}
                                        onChange={(e) => {
                                            setEmployeeId(e.target.value);
                                        }}
                                        inputProps={{ "aria-label": "Without label" }}
                                    >
                                        <MenuItem value="">Select an otpion</MenuItem>
                                        {
                                            employeeList?.map((emp, index) => {
                                                return <MenuItem key={index} value={emp.employeeId}>{`${emp.employeeFirstName} ${emp.employeeLastName ? emp.employeeLastName : ''}`}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>


                                <FormControl
                                    className="formControl"
                                    style={{ marginTop: '-45px' }}
                                >
                                    <button
                                        onClick={reset}
                                        style={{
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid black",
                                            borderRadius: "20px",
                                            padding: "2px",
                                        }}
                                    >
                                        Reset Filter
                                    </button>
                                </FormControl>
                            </div>
                        ),
                    }}

                ></MaterialTable>
            </div>

        </div>
    )
}

export default ClaimsToBeSettled