import React, { useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import './AddFieldWork.scss';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';

const AddFieldWork = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate();

    const [inTime, setInTime] = useState('')
    const [outTime, setOutTime] = useState('')
    const [totalTime, setTotalTime] = useState('')
    const [remarks, setRemarks] = useState('')
    const [employeeList, setEmployeeList] = useState([])
    const [employeeId , setEmployeeId] = useState('')

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                if (response.data.status == true) {
                    // console.log(response)
                    setEmployeeList(response.data.employees)
                } else {
                    setEmployeeList([])
                }
            })

    }, [])

    useEffect(() => {

        if (inTime && outTime) {

            let start = inTime.split('T')
            let end = outTime.split('T')

            start = start[1].split(':')
            end = end[1].split(':')

            let hrs = end[0] - start[0]
            let mins = end[1] - start[1]

            if (mins < 0) {
                hrs--;
                mins = 60 - Math.abs(mins);
            }

            setTotalTime(`${hrs} hrs and ${mins} min`)
        }

    }, [inTime, outTime])

    const submitForm = (e) => {
        e.preventDefault();

        axios.post(`${BASE_URL}/api/v1/hrmgmt/attendance/addAttendanceRecordFieldWork`, {
            employeeId: employeeId,
            startTime: inTime,
            endTime: outTime,
            attendanceType: 'FieldWork',
            description: remarks
        }, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: 'Success',
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: response.data.message
                }).then(function () {
                    navigate('/attendanceManagement/listOfAttendance')
                })
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }

    const dateFormatting = (input, type) => {
        let d = new Date(input)
        let date = d.getDate()
        if (date < 10) {
            date = `0${date}`
        }
        let m = d.getMonth() + 1
        if (m < 10) {
            m = `0${m}`
        }
        let y = d.getFullYear()

        let final;

        if (type === 'original') {
            final = `${y}-${m}-${date}`
            return final
        } else {
            final = `${date}/${m}/${y}`
            return final
        }

    }

    return (
        <div className='add-fieldwork'>
            <Button navigateTo={'/attendanceManagement'} />
            <br />

            <div className='row justify-content-center'>
                <div className={window.innerWidth < 700 ? 'col-6 add-wfh__balance' : 'col-4 add-wfh__balance'}>
                    <p style={{ margin: 0, fontSize: window.innerWidth < 700 ? '14px' : '22px', fontWeight: '700', textAlign: 'center' }}>Field Work Attendance</p>
                </div>
            </div>

            <br /> <br />

            <form onSubmit={(e) => submitForm(e)}>

                <div className='row justify-content-center'>
                    <div className='col-4'>
                        <Form.Group controlId="employee">
                            <Form.Label>Employee</Form.Label>
                            <Form.Control as="select" required
                                value={employeeId}
                                onChange={(e) => {
                                    setEmployeeId(e.target.value)
                                }}
                            >
                                <option value="">Select a Employee</option>
                                {
                                    employeeList?.map((employee) => {
                                        return <option value={employee?.employeeId}>{employee?.employeeFullName}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>

                <br/>

                <div className='row justify-content-center'>
                    <div className={window.innerWidth < 700 ? 'col-6' : 'col-4'}>
                        <label>In Time</label>
                        <input id='calendar1'
                            className='form-control'
                            type='datetime-local'
                            required
                            value={inTime}
                            onChange={(e) => {
                                setInTime(e.target.value)
                            }}
                        />
                    </div>

                    <div className={window.innerWidth < 700 ? 'col-6' : 'col-4'}>
                        <label>Out Time</label>
                        <input id='calendar2'
                            className='form-control'
                            type='datetime-local'
                            required
                            value={outTime}
                            onChange={(e) => {
                                setOutTime(e.target.value)
                            }}
                        />
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className={window.innerWidth < 700 ? 'col-8' : 'col-6'}>
                        <label>Total Time</label>
                        <input type="text" className='form-control'
                            // placeholder='4hrs 30minutes'
                            readOnly
                            value={totalTime}
                        // onChange={(e) => setTotalTime(e.target.value)}
                        />
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className={window.innerWidth < 700 ? 'col-10' : 'col-6'}>
                        <label>Remarks</label>
                        <textarea type="text"
                            className='form-control'
                            required
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                        />
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>
                    <button className='add-wfh__btn' type='submit'
                        disabled={new Date(outTime).getTime() < new Date(inTime).getTime()}
                        style={{ backgroundColor: new Date(outTime).getTime() < new Date(inTime).getTime() ? 'grey' : '' }}
                    >Submit</button>
                </div>

            </form>
        </div>
    )
}

export default AddFieldWork