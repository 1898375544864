const policies = [
    {
        title: 'Westroad Code of Conduct',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque ullam suscipit totam ea quasi maxime?',
        link: 'https://westroad-bucket.s3.us-east-2.amazonaws.com/PolicyDoc/Westroad+Code+of+Conduct.pdf'
    },

    {
        title: 'Office Timing, Leave, Holiday',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque ullam suscipit totam ea quasi maxime?',
        link: 'https://westroad-bucket.s3.us-east-2.amazonaws.com/PolicyDoc/Office+Timing%2C+Leave%2C+Holiday.pdf'
    },

    {
        title: 'Social Media Policy',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque ullam suscipit totam ea quasi maxime?',
        link: 'https://westroad-bucket.s3.us-east-2.amazonaws.com/PolicyDoc/Social+Media+Policy.pdf'
    },

    {
        title: 'Holiday List 2023',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque ullam suscipit totam ea quasi maxime?',
        link: 'https://westroad-bucket.s3.us-east-2.amazonaws.com/PolicyDoc/Holiday+List+2023.pdf'
    },

    {
        title: 'Employee Benefit',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque ullam suscipit totam ea quasi maxime?',
        link: 'https://westroad-bucket.s3.us-east-2.amazonaws.com/PolicyDoc/employee+benefit.pdf'
    },

    {
        title: 'Prevention of Sexual Harassment Policy',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque ullam suscipit totam ea quasi maxime?',
        link: 'https://westroad-bucket.s3.us-east-2.amazonaws.com/PolicyDoc/POSH.pdf'
    }
]

module.exports = policies;
