import './Button.scss';
import {IoMdArrowBack} from 'react-icons/io'
import { useNavigate } from 'react-router-dom';

const Button = ({navigateTo}) => {

 const navigate = useNavigate()

  return (
    <div className="button">
    <button onClick={()=> navigateTo===1 ? navigate(-1) : navigate(`${navigateTo}`)}><IoMdArrowBack />Back</button>
</div>
  )
  }
export default Button