import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import TaskTable from "../../components/Task/TaskTable";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import "./Tasks.scss";
import ShowAdmin from "../Task/ShowAdmin";

const Tasks = () => {
  const [active, setActive] = useState("mot");
  const [myOpenTasks, setMyOpenTasks] = useState([]);
  const [allOpenTasks, setAllOpenTasks] = useState([]);
  const [myClosedTasks, setMyClosedTasks] = useState([]);
  const [allClosedTasks, setAllClosedTasks] = useState([]);

  const [change, setChange] = useState(false);
  const [clRole, setClRole] = useState("");
  const [clMember, setClMember] = useState("");
  const [clIsDue, setClIsDue] = useState("");
  const [opRole, setOpRole] = useState("");
  const [opMember, setOpMember] = useState("");
  const [opIsDue, setOpIsDue] = useState("");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userRole = userInfo.userRole;
  const navigate = useNavigate();

  useEffect(() => {
    //get my open tasks
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/task/getListOfTask?taskStatus=Open&userName=${userInfo.userName}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      )
      .then((response) => {
        setMyOpenTasks(response.data.taskList);
      });

    //get my closed tasks
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/task/getListOfTask?taskStatus=Closed&userName=${userInfo.userName}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      )
      .then((response) => {
        setMyClosedTasks(response.data.taskList);
      });
  }, [change]);

  //all open tasks with filtering
  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/task/getListOfTask?taskStatus=Open&taskRole=${opRole}&userName=${opMember}&taskDue=${
          opIsDue === "true" ? true : false
        }`,
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      )
      .then((response) => {
        setAllOpenTasks(response.data.taskList);
      });
  }, [opRole, opMember, opIsDue, change]);

  ///all closed tasks with filtering
  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/task/getListOfTask?taskStatus=Closed&taskRole=${clRole}&userName=${clMember}&taskDue=${
          clIsDue === "true" ? true : false
        }`,
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      )
      .then((response) => {
        setAllClosedTasks(response.data.taskList);
      });
  }, [clRole, clMember, clIsDue, change]);

  return (
    <div className="outlet_bg">
      {/* <Button navigateTo={1} /> */}

      <br />

      <div className="outlet_container">
        <h2 className="ml-3">Task List</h2>
        <div className="tabs">
          <button
            className={`${active === "ct" ? "bg-red" : "bg-dark"}`}
            onClick={() => {
              setActive("ct")
              navigate("/createtask")}}
          >
            Create Task
          </button>
          <button
            className={`${active === "mot" ? "bg-red" : "bg-dark"}`}
            onClick={() => {
              setActive("mot");
            }}
          >
            My Open Task
          </button>

          <button
            className={`${active === "mct" ? "bg-red" : "bg-dark"}`}
            onClick={() => {
              setActive("mct");
            }}
          >
            My Closed Task
          </button>

          <button
            className={` ${
              userRole === "Director"
                ? `${active === "aot" ? "bg-red d-block" : "bg-dark d-block"}`
                : "d-none"
            }`}
            onClick={() => {
              setActive("aot");
            }}
          >
            All Open Tasks
          </button>

          <button
            className={` ${
              userRole === "Director"
                ? `${active === "act" ? "bg-red d-block" : "bg-dark d-block"}`
                : "d-none"
            }`}
            onClick={() => {
              setActive("act");
            }}
          >
            All Closed Tasks
          </button>

          <button
            className={` ${
              userRole === "Director"
                ? `${active === "td" ? "bg-red d-block" : "bg-dark d-block"}`
                : "d-none"
            }`}
            onClick={() => {
              setActive("td");
              navigate("/taskDashboard");
            }}
          >
            Task Dashboard
          </button>
        </div>

       {/*} <div className="mt-5 d-flex">
          <button
            className="btn btn-secondary btn-user btn-block mx-auto"
            style={{ width: "150px" }}
            onClick={() => navigate("/createtask")}
          >
            Create Task
          </button>

          <button
            className="btn btn-secondary btn-user btn-block mx-auto"
            style={{ width: "150px" }}
            onClick={() => navigate("/taskDashboard")}
          >
            Show Admin
          </button>
          </div>*/}
        <div className="mt-5">
          {active === "mot" && (
            <TaskTable tasks={myOpenTasks} tab={active} setChange={setChange} />
          )}
          {active === "mct" && <TaskTable tasks={myClosedTasks} tab={active} />}
          {active === "act" && (
            <TaskTable
              tasks={allClosedTasks}
              tab={active}
              clRole={clRole}
              setClRole={setClRole}
              clMember={clMember}
              setClMember={setClMember}
              clIsDue={clIsDue}
              setClIsDue={setClIsDue}
            />
          )}
          {active === "aot" && (
            <TaskTable
              tasks={allOpenTasks}
              opRole={opRole}
              setOpRole={setOpRole}
              opMember={opMember}
              setOpMember={setOpMember}
              opIsDue={opIsDue}
              setOpIsDue={setOpIsDue}
              tab={active}
              setChange={setChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Tasks;
