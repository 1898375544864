import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import "./SendOffer.scss";
import { BASE_URL } from "../../../utils/config/url";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const SendOffer = ({ setActiveStep }) => {
  const onboardingdetails = JSON.parse(
    localStorage.getItem("onboardingdetails")
  );
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [file, setFile] = useState(null);
  const [DownloadCTC, setDownloadCTC] = useState(null);
  const [offerLetter, setOfferLetter] = useState(null); // [
  const [flag, setFlag] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/onboarding/getOnboardingById/${onboardingdetails?.onboardingId}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((res) => {
        localStorage.setItem(
          "onboardingdetails",
          JSON.stringify(res.data.onboarding)
        );
        setDownloadCTC(res.data.onboarding?.downloadCTC);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          showConfirmButton: true,
        });
      });
  }, []);


  const handleUploadOfferLetter = async (e) => {
    e.preventDefault();
    if (file === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Upload Offer Letter",
        showConfirmButton: true,
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("onboardingId", onboardingdetails?.onboardingId);
      const response = await axios.put(
        `${BASE_URL}/api/v1/hrmgmt/onboarding/uploadOfferLetter`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
        showConfirmButton: true,
      }).then(() => {
        localStorage.setItem(
          "onboardingdetails",
          JSON.stringify(response.data.onboardingdetails)
        );
        setOfferLetter(response.data.onboardingdetails?.offerLetter);
        setFlag(false);
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err.response.data.error,
        showConfirmButton: true,
      });
    }
  };

  const handleSendOffer = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/hrmgmt/onboarding/sendOfferLetter`,
        {
          onboardingId: onboardingdetails?.onboardingId,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
        showConfirmButton: true,
      }).then(() => {
        navigate("/list-of-onboardings");
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err.response.data.message,
        showConfirmButton: true,
      });
    }
  };

  return (
    <div className="sendoffer">
      <Paper elevation={3} className="sendoffer__paper">
        <br />
        <div className="row justify-content-end align-items-center mr-2">
          <a href={onboardingdetails?.sampleCTC} className="sendoffer__btn" style={{color:"white", cursor:"pointer"}}>
            Download CTC
          </a>
        </div>
        <br />
        <br />
        {offerLetter ? (
          <div className="row justify-content-center align-items-center">
            <h4>Offer Letter Uploaded</h4>
          </div>
        ) : (
          <form onSubmit={handleUploadOfferLetter}>
            <div className="row justify-content-center align-items-center">
              <div className="col-6">
                <label>Upload Offer Letter</label>
                <input
                  type="file"
                  className="form-control"
                  id="uploadOfferLetter"
                  accept="application/pdf"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
              <div className="col-4 mt-4">
                <button className="sendoffer__btn" type="submit">
                  Upload
                </button>
              </div>
            </div>
          </form>
        )}
        <br />
        <br />
        <br />
        <div className="row justify-content-end align-items-center mr-2">
          <button
            className="sendoffer__btn"
            disabled={flag}
            style={{ backgroundColor: flag ? "Grey" : "" }}
            type="button"
            onClick={handleSendOffer}
          >
            Send Offer
          </button>
        </div>
      </Paper>
    </div>
  );
};

export default SendOffer;