import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { BASE_URL } from '../../utils/config/url';
import { useParams, useNavigate } from 'react-router-dom';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import Select from '@mui/material/Select';
import InputBase from "@mui/material/InputBase";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from "@mui/material/styles";
import Swal from 'sweetalert2'
import axios from 'axios';
import './UnitTypes.scss';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: '10px',
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#EE4B46",
    color: "white",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px",
    width: "100"

  }
}));

const UnitTypes = () => {

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const siteCode = useParams().siteCode;
  const navigate = useNavigate();

  const [un, setUn] = useState("");
  const [bhk, setBhk] = useState("");
  const [ca, setCa] = useState("");
  const [ba, setBa] = useState("");
  const [bua, setBua] = useState("");
  const [sbua, setSbua] = useState("");
  const [unitTypes, setUnitTypes] = useState([]);
  const [phases, setPhases] = useState([])
  const [selectedPhase, setSelectedPhase] = useState('');
  const [filteredUnits, setFilteredUnits] = useState([])


  useEffect(() => {
    getListOfUnitTypes()
  }, [])

  const getListOfUnitTypes = () => {

    const Token = "bearer" + " " + userInfo.token;
    axios.get(`${BASE_URL}/api/v1/Configuration/UnitType/getListOfUnitType?siteCode=${siteCode}`,
      { headers: { Authorization: Token } })
      .then((response) => {

        const allUnitTypes = response.data.unitTypes.map((unitType) => {

          const { unitTypeName, phaseName, bhk, carpetArea, balconyArea, builtUpArea, superBuiltUpArea, unitTypeId } = unitType;
          setPhases((prev) => {
            if (!prev.includes(phaseName)) {
              return [...prev, phaseName]
            } else {
              return prev;
            }
          })
          return {
            unitTypeName,
            unitTypeId,
            phaseName,
            bhk,
            carpetArea,
            balconyArea,
            builtUpArea,
            superBuiltUpArea
          }

        })
        setUnitTypes(allUnitTypes)
        setFilteredUnits(allUnitTypes)
      })
  }

  const handleFilter = (e) => {

    if (e.target.value === " ") {
      setSelectedPhase('')
      return setUnitTypes(filteredUnits)
    }

    else {
      setSelectedPhase(e.target.value)

      let filteredUnitTypes = []
      filteredUnits.forEach((ut) => {

        if (ut.phaseName === e.target.value) {
          filteredUnitTypes.push(ut)
        }
      })

      setUnitTypes(filteredUnitTypes)
    }
  }

  const handleEditUnitType = (utn) => {
    navigate(`/configurations/site/${siteCode}/editunittype/${utn}`)
  }

  const handleDelete = (unitTypeName) => {

    setUn(unitTypeName)
    const Token = "bearer" + " " + userInfo.token;
    const unitTypeId = `${unitTypeName}-${siteCode}`

    axios.delete(`${BASE_URL}/api/v1/Configuration/UnitType/deleteUnitType?siteCode=${siteCode}&unitTypeName=${unitTypeName}`,
      { headers: { Authorization: Token } }
    )
      .then(response => {

        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            }
          }).then(function () {
            const newUnitTypes = unitTypes.filter((unitType) => {
              return unitType.unitTypeId !== unitTypeId
            })
            setUnitTypes(newUnitTypes)
          })
        }
        else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message
          })
        }
      })
  }

  return (
    <div className='unittype'>
      <div className="unittype__header">
        <div className='unittype__addunitbtn'>
          <button onClick={() => navigate(`/configurations/site/addunittype/${siteCode}`)}>Add Unit Type</button>
        </div>


        <FormControl sx={{ m: 1, minWidth: 140, marginBottom: '15px' }}>

          <InputLabel id="demo-simple-select-helper-label" style={{ color: 'black', fontSize: '18px', paddingTop: '5px' }}>
            Phases <FilterAltRoundedIcon style={{ marginLeft: '10px' }}
            />
          </InputLabel>

          <Select
            value={selectedPhase}
            input={<BootstrapInput />}
            onChange={(e) => handleFilter(e)}
          >
            <MenuItem value=" ">
              <em>None</em>
            </MenuItem>
            {phases.map((p , index) => {
              return <MenuItem value={p} key={index}>{p}</MenuItem>
            })}
          </Select>
        </FormControl>
      </div>


      <MaterialTable
        data={unitTypes}
        title="Unit Types"
        columns={[
          { title: "Type Name", field: "unitTypeName" },
          { title: "Phase Name", field: "phaseName" },
          { title: "BHK", field: "bhk" },
          { title: "Carpet", field: "carpetArea" },
          { title: "Balcony", field: "balconyArea" },
          { title: "Built Up", field: "builtUpArea" },
          { title: "Super Built Up", field: "superBuiltUpArea" },
        ]}

        options={{
          search: true,
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
          },
        }}

        actions={[
          (rowData) => ({
            icon: "remove_red_eye",
            tooltip: "Update Unit Type",
            onClick: (event, rowData) => {

              setUn(rowData.unitTypeName);
              setBhk(rowData.bhk);
              setCa(rowData.carpetArea);
              setBa(rowData.balconyArea);
              setBua(rowData.builtUpArea);
              setSbua(rowData.superBuiltUpArea);


              handleEditUnitType(rowData.unitTypeName)

            },
          }),
          (rowData) => ({
            icon: "delete",
            tooltip: "Delete Unit Type",
            onClick: (event, rowData) => {
              handleDelete(rowData.unitTypeName)
            },
            disabled:
              userInfo.userRole === "ITAdmin" ||
                userInfo.userRole === "Director"
                ? false
                : true,
          }),
        ]}
      ></MaterialTable>
    </div>
  )
}

export default UnitTypes