import axios from 'axios';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Button from '../../components/Button/Button';
import { BASE_URL } from '../../utils/config/url';
import './CreateLeavePlan.scss';

const CreateLeavePlan = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate();

    const [planName, setPlanName] = useState('');
    const [isFlexi, setIsFlexi] = useState('');
    const [earnedLeave, setEarnedLeave] = useState('');
    const [casualLeave, setCasualLeave] = useState('');
    const [medicalLeave, setMedicalLeave] = useState('');
    const [lwp, setLwp] = useState(''); // [Leave Without Pay]
    const [description, setDescription] = useState('');
    const [flexiOffPerWeek, setFlexiOffPerWeek] = useState();
    const [fixedOff, setFixedOff] = useState();

    const createLeavePlan = (e) => {
        e.preventDefault();

        let body = {
            isFlexiPlan: isFlexi,
            earnedLeave,
            casualLeave,
            medicalLeave,
            lwp,
            leavePlanName: planName,
            description
        }

        if (isFlexi === 'true') {
            body = {
                ...body,
                flexiOff: flexiOffPerWeek
            }
        }
        else if (isFlexi === 'false') {
            body = {
                ...body,
                fixedOffDay: fixedOff
            }
        }

        axios.post(`${BASE_URL}/api/v1/hrmgmt/leavePlan/createLeavePlan`,
            body
            ,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {

                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    })
                        .then(() => {
                            navigate('/leavemanagement/listofleaveplan')
                        })
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }

    return (
        <div className='create-leave-plan'>

            <Button navigateTo={'/leavemanagement/listofleaveplan'} />

            <br />
            <br />

            <h3 style={{ textAlign: 'center', color: '#EE4B46' }}>Create Leave Plan</h3>

            <br /> <br />

            <form onSubmit={(e) => createLeavePlan(e)}
                style={{ width: window.innerWidth < 700 ? '100%' : '85%' }}
            >

                <div className='row justify-content-center'>

                    <div className='col-4'>
                        <label>Leave Plan Name</label>
                        <input type="text" className='form-control'
                            required
                            value={planName}
                            onChange={(e) => setPlanName(e.target.value)}
                        />
                    </div>

                    <div className='col-4'>

                        <Form.Group controlId="leadid">
                            <label>Is Flexi Plan</label>
                            <Form.Control as="select"
                                required
                                onChange={(e) => setIsFlexi(e.target.value)}
                            >
                                <option value="">Select a option</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Form.Control>
                        </Form.Group>
                    </div>

                    {isFlexi === 'true'
                        ?
                        <div className='col-4'>
                            <label>Flexi Off Per Week</label>
                            <input type="number" className='form-control'
                                required
                                value={flexiOffPerWeek}
                                onChange={(e) => setFlexiOffPerWeek(parseInt(e.target.value))}
                            />
                        </div>
                        :
                        isFlexi === 'false'
                            ?
                            <div className='col-4'>
                                <label>Fixed Off</label>
                                <input type="text" className='form-control'
                                    required
                                    value={fixedOff}
                                    onChange={(e) => setFixedOff(e.target.value)}
                                />
                            </div>
                            :
                            null
                    }

                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-4'>
                        <label>Earned Leave</label>
                        <input type="text" className='form-control'
                            required
                            value={earnedLeave}
                            onChange={(e) => setEarnedLeave(e.target.value)}
                        />
                    </div>


                    <div className='col-4'>
                        <label>Casual Leave</label>
                        <input type="text" className='form-control'
                            required
                            value={casualLeave}
                            onChange={(e) => setCasualLeave(e.target.value)}
                        />
                    </div>

                </div>

                <br />

                <div className='row justify-content-center'>

                    <div className='col-4'>
                        <label>Medical Leave</label>
                        <input type="text" className='form-control'
                            required
                            value={medicalLeave}
                            onChange={(e) => setMedicalLeave(e.target.value)}
                        />
                    </div>

                    <div className='col-4'>
                        <label>Leave Without Pay</label>
                        <input type="text" className='form-control'
                            required
                            value={lwp}
                            onChange={(e) => setLwp(e.target.value)}
                        />
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-10'>
                        <label>description</label>
                        <textarea type="text" className='form-control'
                            required
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>

                </div>

                <br /> <br />

                <div className='row justify-content-center'>
                    <button type='submit'>Submit</button>
                </div>
            </form>
        </div>
    )
}

export default CreateLeavePlan