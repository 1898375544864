import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import { BASE_URL } from "../../utils/config/url";
import "./LeadDetails.scss";
import Swal from "sweetalert2";

const LeadDetails = ({ invLead, leadId, change, setChange }) => {
  const [userList, setUserList] = useState([]);
  const [userName, setUserName] = useState("");
  const [userFullName, setUserFullName] = useState("");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [assignee, setAssignee] = useState("");

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [leadPriority, setLeadPriority] = useState("");
  const [requirement, setRequirement] = useState("");
  const [budget, setBudget] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [leadStatus, setLeadStatus] = useState("");
  const [type, setType] = useState("");
  const [siteName, setSiteName] = useState({});
  const [bhk, setBhk] = useState("");
  const [siteList, setSiteList] = useState([]);
  const [leadPersonType, setLeadPersonType] = useState("");
  const [buyingReason, setBuyingReason] = useState("");
  const [homeLoanRequired, setHomeLoanRequired] = useState("");

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/configuration/site/getAllSiteNames`, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((response) => {
        if (response.data.status === true) setSiteList(response.data.siteList);
      });
  }, []);

  useEffect(() => {

    if (Object.keys(invLead).length != 0) {
      setName(invLead?.name);
      setPhone(invLead?.phone);
      invLead.whatsapp && setWhatsapp(invLead?.whatsapp);
      setEmail(invLead?.email);
      setAddress(invLead.address);
      setCity(invLead?.city);
      setPincode(invLead?.pincode);
      invLead?.leadReq ? setRequirement(invLead?.leadReq) : setRequirement("");
      setBudget(invLead?.leadBudget);
      setLeadPriority(invLead?.leadWeightage);
      setLeadPersonType(invLead?.leadPersonType);
      setBuyingReason(invLead?.buyingReason);
      setHomeLoanRequired(invLead?.homeLoanRequired);
    }
  }, [invLead]);

  useEffect(() => {
    (async () => {
      const res = await axios.get(
        `${BASE_URL}/api/v1/user/getListOfUsers?isActive=${true}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      );
      // console.log(res)
      const data = res?.data?.userList;
      setUserList(data);
    })();
  }, []);

  // console.log(userInfo);

  //assignee set
  useEffect(() => {
    if (invLead.length != 0) {
      setAssignee(invLead?.assigneeUserFullName);
      setBhk(invLead?.bhk);
      setLeadSource(invLead?.leadSource);
      setLeadStatus(invLead?.leadStatus);
      setType(invLead?.subType);

      // let siteNames = invLead?.site?.map((lead) => {
      //   return { siteName: lead?.siteName, siteCode: lead?.siteCode }
      // })

      setSiteName(`${invLead?.site?.siteName},${invLead?.site?.siteCode}`);
    }
  }, [invLead]);

  const handleUser = (value) => {
    const separateValue = value.split(", ");
    setUserFullName(separateValue[1]);
    setUserName(separateValue[0]);
  };

  const handleAssign = () => {
    axios
      .put(
        `${BASE_URL}/api/v1/leadmgmt/lead/assignlead`,
        {
          leadID: leadId,
          userName: userName,
          userFullName: userFullName,
          currentUserFullName: userInfo.userFullName,
          currentUserName: userInfo.userName,
        },
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      )
      .then((response) => {
        // console.log(response)
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(() => {
          setAssignee(userFullName);
          setUserName("");
          setUserFullName("");
          document.getElementById("assign").value = "";
          setChange(!change);
        });
      });
  };

  // console.log(invLead)

  const saveLead = () => {
    let body = {
      leadID: leadId,
      name: name,
      phone: phone,
      whatsapp: whatsapp,
      email: email,
      address: address,
      city: city,
      pincode: pincode,
      leadWeightage: leadPriority,
      leadReq: requirement,
      leadBudget: budget,
      site: {
        siteName: siteName.split(",")[0],
        siteCode: siteName.split(",")[1],
      },
      bhk: bhk,
      leadPersonType: leadPersonType,
      buyingReason: buyingReason,
      homeLoanRequired: homeLoanRequired,
    };

    axios
      .put(`${BASE_URL}/api/v1/leadmgmt/lead/updateLeadByLeadID`, body, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((response) => {
        // console.log(response)
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(() => {
            setChange((prev) => !prev);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err,
        });
      });
  };

  return (
    <>
      <div className="lead-details">
        <div className="px-3 py-4">
          <div className="d-flex justify-content-center align-items-center px-3 mb-4">
            <div className="create_btn pb-0">
              <h5>
                Lead ID - {leadId} : {invLead.leadStatus}
              </h5>
            </div>
            {/* <button className="close_btn bg-secondary">Close Lead</button> */}
          </div>
          {invLead?.isReOpenedLead && (
            <div className="text-center">
              <h4>
                Lead is Reopened, Old Lead ID :{" "}
                <a href={`/individuallead/${invLead?.oldLeadID}`}>
                  {invLead?.oldLeadID}
                </a>
              </h4>
            </div>
          )}

          <br />

          <div className="lead-info">
            <div
              className="row justify-content-center"
              style={{ width: "100%" }}
            >
              <div className="col-6">
                <label>Name</label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>

              <div className="col-6">
                <label>Contact No.</label>
                <Form.Control
                  type="number"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
            </div>

            <br />

            <div
              className="row justify-content-center"
              style={{ width: "100%" }}
            >
              <div className="col-6">
                <label>Whatsapp No.</label>
                <Form.Control
                  type="number"
                  value={whatsapp}
                  onChange={(e) => {
                    setWhatsapp(e.target.value);
                  }}
                />
              </div>
              <div className="col-6">
                <label>Email ID</label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>

            <br />

            <div
              className="row justify-content-center"
              style={{ width: "100%" }}
            >
              <div className="col-8">
                <label>Address</label>
                <Form.Control
                  type="text"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </div>
            </div>

            <br />

            <div
              className="row justify-content-center"
              style={{ width: "100%" }}
            >
              <div className="col-6">
                <label>City/District</label>
                <Form.Control
                  type="text"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              </div>

              <div className="col-6">
                <label>Pincode</label>
                <Form.Control
                  type="number"
                  value={pincode}
                  onChange={(e) => {
                    setPincode(e.target.value);
                  }}
                />
              </div>
            </div>

            <br />

            <div
              className="row justify-content-center"
              style={{ width: "100%" }}
            >
              <div className="col-6">
                <label>Lead Source</label>
                <Form.Control readOnly value={leadSource} />
              </div>

              <div className="col-6">
                <label>Sub Source</label>
                <Form.Control readOnly defaultValue={type} />
              </div>
            </div>

            <br />

            <div
              className="row justify-content-center"
              style={{ width: "100%" }}
            >
              <div className="col-3">
                <label>Lead Person Type</label>
                <Form.Control
                  as="select"
                  value={leadPersonType}
                  onChange={(e) => setLeadPersonType(e.target.value)}
                >
                  <option>Individual</option>
                  <option>Channel Partner</option>
                </Form.Control>
              </div>
              <div className="col-3">
                <label>Buying Reason</label>
                <Form.Control
                  as="select"
                  value={buyingReason}
                  onChange={(e) => setBuyingReason(e.target.value)}
                >
                  <option>Investment</option>
                  <option>Residential</option>
                </Form.Control>
              </div>
              <div className="col-3">
                <label>Home Loan Required</label>
                <Form.Control
                  as="select"
                  value={homeLoanRequired ? "Yes" : "No"}
                  onChange={(e) => {
                    setHomeLoanRequired(
                      e.target.value === "Yes" ? true : false
                    );
                  }}
                >
                  <option>Yes</option>
                  <option>No</option>
                </Form.Control>
              </div>
            </div>

            <br />

            {leadSource === "Channel Partner" && (
              <>
                <div
                  className="row justify-content-center"
                  style={{ width: "100%" }}
                >
                  <div className="col-6">
                    <label>Broker Name</label>
                    <Form.Control
                      readOnly
                      defaultValue={invLead?.broker?.brokerName || ""}
                    />
                  </div>

                  <div className="col-6">
                    <label>Broker Company</label>
                    <Form.Control
                      readOnly
                      defaultValue={invLead?.broker?.brokerCompany || ""}
                    />
                  </div>
                </div>

                <br />

                <div
                  className="row justify-content-center"
                  style={{ width: "100%" }}
                >
                  <div className="col-6">
                    <label>Broker Address</label>
                    <Form.Control
                      readOnly
                      defaultValue={invLead?.broker?.brokerAddress || ""}
                    />
                  </div>

                  <div className="col-6">
                    <label>Broker PAN</label>
                    <Form.Control
                      readOnly
                      defaultValue={invLead?.broker?.brokerPAN || ""}
                    />
                  </div>
                </div>

                <br />

                <div
                  className="row justify-content-center"
                  style={{ width: "100%" }}
                >
                  <div className="col-6">
                    <label>Broker RERA</label>
                    <Form.Control
                      readOnly
                      defaultValue={invLead?.broker?.brokerRERA || ""}
                    />
                  </div>

                  <div className="col-6">
                    <label>Broker Type</label>
                    <Form.Control
                      readOnly
                      defaultValue={invLead?.broker?.brokerType || ""}
                    />
                  </div>
                </div>

                <br />
              </>
            )}

            <div
              className="row justify-content-center"
              style={{ width: "100%" }}
            >
              <div className="col-6">
                <label>Lead Priority</label>
                <Form.Control
                  as="select"
                  value={leadPriority}
                  onChange={(e) => {
                    setLeadPriority(e.target.value);
                  }}
                >
                  <option>Hot</option>
                  <option>Normal</option>
                  <option>Cold</option>
                </Form.Control>
              </div>
            </div>

            <br />

            <div
              className="row justify-content-center"
              style={{ width: "100%" }}
            >
              <div className="col-4">
                <label>Select a Site</label>
                <Form.Control
                  as="select"
                  id="assign"
                  value={siteName}
                  onChange={(e) => {
                    if (!e.target.value) return;
                    setSiteName(e.target.value);
                  }}
                >
                  <option value="">None</option>
                  {siteList?.map((site, i) => {
                    return (
                      <option
                        option
                        key={i}
                        value={`${site?.SiteName},${site?.SiteCode}`}
                      >
                        {site?.SiteName}
                      </option>
                    );
                  })}
                </Form.Control>
              </div>

              <div className="col-4">
                <label>Select a BHK</label>
                <Form.Control
                  as="select"
                  id="assign"
                  value={bhk}
                  onChange={(e) => {
                    if (!e.target.value) return;
                    setBhk(e.target.value);
                  }}
                >
                  <option value="">None</option>
                  <option value="1BHK">1BHK</option>
                  <option value="2BHK">2BHK</option>
                  <option value="3BHK">3BHK</option>
                  <option value="4BHK">4BHK</option>
                  <option value="Duplex">Duplex</option>
                </Form.Control>
              </div>
            </div>

            <br />

            <div
              className="row justify-content-center"
              style={{ width: "100%" }}
            >
              <div className="col-6">
                <label>Requirement</label>
                <Form.Control
                  type="text"
                  value={requirement}
                  onChange={(e) => {
                    setRequirement(e.target.value);
                  }}
                />
              </div>
              <div className="col-6">
                <label>Budget</label>
                <Form.Control
                  type="text"
                  value={budget}
                  onChange={(e) => {
                    setBudget(e.target.value);
                  }}
                />
              </div>
            </div>

            <br />

            {/* <div className="row justify-content-center"
              style={{ width: '100%' }}
            >

              <div className="row justify-content-center"
                style={{ width: '100%' }}
              >
                <div className="col-4">
                  <label>Select a BHK</label>
                  <Form.Control as="select" id="assign"
                    onChange={(e) => {
                      if (!e.target.value) return
                      setBhk((prev) => {
                        return [...prev, e.target.value]
                      })
                    }}>
                    <option value="">None</option>
                    <option value='1BHK'>1BHK</option>
                    <option value='2BHK'>2BHK</option>
                    <option value='3BHK'>3BHK</option>
                    <option value='4BHK'>4BHK</option>
                    <option value='Duplex'>Duplex</option>
                  </Form.Control>
                </div>

                <div className="col-5">
                  <label>BHK</label>
                  <textarea type="text" className="form-control" readOnly
                    value={bhk?.map((bhk, index) => {
                      let res = ``;
                      res = `${bhk} `
                      return res
                    })}
                  />
                </div>
              </div>


            </div> */}

            <br />

            <div
              className="d-flex justify-content-center"
              style={{ width: "100%" }}
            >
              <button
                className="lead-details__btn"
                style={{ backgroundColor: "#EE4B46" }}
                disabled={invLead.leadStatus === "Closed-NoResponse"}
                onClick={(e) => {
                  saveLead(e);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="lead-details mb-5 py-3">
        <div
          className="user_info"
          style={{
            width: window.innerWidth < 700 ? "80%" : "60%",
            margin: "0 auto",
          }}
        >
          <label>Current Assignee</label>
          <Form.Control type="text" defaultValue={assignee} readOnly />
        </div>

        {(userInfo?.userRole === "Director" ||
          userInfo?.leadFullAccess === true) && (
          <div
            style={{
              width: window.innerWidth < 700 ? "80%" : "60%",
              margin: "0 auto",
            }}
            className="user_info pt-3"
          >
            <label>Assign To</label>
            <div className="d-flex align-items-center">
              <Form.Control
                as="select"
                id="assign"
                onChange={(e) => {
                  handleUser(e.target.value);
                }}
              >
                <option defaultValue=""></option>
                {userList?.map((user, i) => (
                  <option
                    key={i}
                    value={`${user?.userName}, ${user?.userFullName}`}
                  >
                    {user?.userFullName}
                  </option>
                ))}
              </Form.Control>

              <button
                className="create_btn ml-4 px-4"
                style={{ backgroundColor: "#EE4B46" }}
                onClick={handleAssign}
              >
                Assign
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LeadDetails;
