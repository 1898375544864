import React, { useEffect, useState } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table';
import { useNavigate, useParams } from 'react-router-dom';
import { useStyles } from '../../utils/ModalStyles';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from '../../components/Button/Button';
import './UnitInspectionListType.scss';

const UnitInspectionListType = () => {

    const navigate = useNavigate();
    const classes = useStyles()
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const Token = 'bearer' + ' ' + userInfo.token

    const [unitInspectionListType, setUnitInspectionListType] = useState([]);

    useEffect(() => {
        getListOfUnitInspectionListType()
    }, [])

    const getListOfUnitInspectionListType = () => {
        axios
            .get(`${BASE_URL}/api/v1/Configuration/UnitInspection/getListOfUnitInspectionListType`, {
                headers: { Authorization: Token },
            })
            .then((response) => {
                // console.log(response)
                setUnitInspectionListType(response.data.unitInspectionTypeList);
            });
    }



    return (
        <div className='unitInspectionListType'>

            <div className='row justify-content-between'>
                <Button navigateTo={'/home'} />
                <button
                    className='unitInspectionListType__btn'
                    onClick={()=>navigate('/configurations/unitInspectionListType/createUnitInspectionListType')}
                >Create Inspection List Type</button>
            </div>
            <br />
            <br />
            <h4>Unit Inspection List Type</h4>
            <div className="row container-fluid-justify-content-center">
                <div className="col-12 mt-2">
                    <MaterialTable
                        data={unitInspectionListType}
                        title=""
                        columns={[
                            { title: "Inspection List Type ID", field: "InspectionListTypeId" },
                            { title: "Site Code", field: "siteCode" },
                            { title: "Phase Code", field: "phaseCode" },
                            { title: "BHK", field: "bhk" },
                        ]}
                        options={{
                            search: window.innerWidth < 700 ? false : true,
                            searchFieldStyle: {
                                width: 180
                            },
                            actionsCellStyle: {
                                width: 120,
                                paddingLeft: 30
                            },
                            actionsColumnIndex: 0,
                            paging: true,
                            pageSize: 20,
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [5, 10, 20, 30],
                            headerStyle: {

                                backgroundColor: "#EE4B46",
                                color: "#fff",
                                paddingLeft: "11px",
                            },
                        }}


                        actions={[
                            {
                                icon: "remove_red_eye",
                                tooltip: "View Inspection List Type",
                                onClick: (event, rowData) => {
                                    navigate(`/configurations/unitInspectionListType/${rowData.InspectionListTypeId}`);
                                }
                            },
                            // {
                            //     icon: "delete",
                            //     tooltip: "Delete Inspection List Type",
                            //     onClick: (event, rowData) => {
                            //         setInspectionListTypeId(rowData.InspectionListTypeId)
                                  
                            //         // navigate(`/configurations/unitInspectionListType/${rowData.InspectionListTypeId}`);
                            //     }
                            // }
                        ]}
                    ></MaterialTable>
                </div>
            </div>


           


        </div>
    )
}

export default UnitInspectionListType;