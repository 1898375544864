import React, { useEffect } from 'react'
import './LimitApplication.scss';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { Paper } from '@mui/material';
import Button from '../../components/Button/Button';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';


const LimitApplication = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const [appliedAdvances, setAppliedAdvances] = React.useState([])
    const [modal, setModal] = React.useState(false)
    const [scanFile, setScanFile] = React.useState(null)
    const [applicationFile, setApplicationFile] = React.useState(null)
    const [type, setType] = React.useState('')
    const [employeeId, setEmployeeId] = React.useState('')
    const [approveModal, setApproveModal] = React.useState(false)
    const [approvalComment, setApprovalComment] = React.useState('')
    const [employee, setEmployee] = React.useState({})

    useEffect(() => {

        getListOfEmployeeAppliedSalaryAdvance()

    }, [])

    const getListOfEmployeeAppliedSalaryAdvance = () => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/salaryAdvance/getListOfEmployeeAppliedSalaryAdvance`, {
            headers: {
                Authorization: `Bearer ${userInfo?.token}`
            }
        })
            .then((response) => {
                if (response.data.status) {
                    setAppliedAdvances(response.data.List)
                } else {
                    setAppliedAdvances([])
                }
            })


    }

    const uploadFile = (e) => {

        e.preventDefault()

        let body = new FormData();
        body.append('employeeId', employeeId)
        body.append('fileType', type);
        body.append('file', type === 'cheque' ? scanFile : applicationFile);

        axios.put(`${BASE_URL}/api/v1/hrmgmt/salaryAdvance/uploadApprovalDocument `,
            body,
            {
                headers: {
                    Authorization: `Bearer ${userInfo?.token}`
                }
            })
            .then((response) => {
                setModal(false)
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message
                    })
                        .then(() => {
                            getListOfEmployeeAppliedSalaryAdvance()
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.message
                    })
                }
            })
            .catch((err) => {
                setModal(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: err.response.data.error
                })
            })
    }

    const approveLimit = (status) => {

        let body = {
            employeeId: employeeId,
            currentUserEmployeeId: userInfo.employeeId,
        }

        axios.post(`${BASE_URL}/api/v1/hrmgmt/salaryAdvance/approveSalaryAdvanceLimit `,
            body,
            {
                headers: {
                    Authorization: `Bearer ${userInfo?.token}`
                }
            })
            .then((response) => {
                setApproveModal(false)
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message
                    })
                        .then(() => {
                            getListOfEmployeeAppliedSalaryAdvance()
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.message
                    })
                }
            })
            .catch((err) => {
                setApproveModal(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: err.response.data.error
                })
            })

    }

    return (
        <div className='limit-app'>

            <div className='row justify-content-start mb-4'>
                <Button navigateTo='/salaryAdvance' />
            </div>

            {
                appliedAdvances.length === 0
                    ?
                    <h3 className='text-center'
                        style={{
                            color: '#EE4B46'
                        }}
                    >No Salary Advance Limit Applications Found</h3>
                    :

                    appliedAdvances?.map((item, index) => {

                        return <Paper key={index} elevation={2} style={{ padding: "20px", margin: 'auto', marginTop: "40px", width: '90%' }}>

                            <div className='row justify-content-center'>
                                <div className='col-4'>
                                    <label>Employee Id</label>
                                    <input type="text" readOnly value={item?.employeeId} className='form-control' />
                                </div>

                                <div className='col-4'>
                                    <label>Employee Full Name</label>
                                    <input type="text" readOnly value={item?.employeeFullName} className='form-control' />
                                </div>
                            </div>

                            <br />

                            <div className='row justify-content-center'>
                                <div className='col-4'>
                                    <label>Salary Advance Limit</label>
                                    <input type="text" readOnly value={item?.salaryAdvanceLimit} className='form-control' />
                                </div>
                            </div>

                            <br />

                            <div className='row justify-content-center'>
                                <div className='col-4'>
                                    <label>Salary Advance Cheque Scan</label>
                                    <br />
                                    {
                                        item?.salaryAdvanceChequeScan
                                            ?
                                            <a href={item?.salaryAdvanceChequeScan}>Document</a>
                                            :
                                            <>
                                                <b>Not Uploaded</b>
                                                <br />
                                                <button className='limit-app__btn mt-3'
                                                    onClick={() => {
                                                        setType('cheque')
                                                        setEmployeeId(item?.employeeId)
                                                        setModal(true)
                                                    }}
                                                >Upload Document</button>
                                            </>
                                    }
                                </div>

                                <div className='col-4'>
                                    <label>Salary Advance Application</label>
                                    <br />
                                    {
                                        item?.salaryAdvanceApplication
                                            ?
                                            <a href={item?.salaryAdvanceApplication}>Document</a>
                                            :
                                            <>
                                                <b>Not Uploaded</b>
                                                <br />
                                                <button className='limit-app__btn mt-3'
                                                    onClick={() => {
                                                        setType('application')
                                                        setEmployeeId(item?.employeeId)
                                                        setModal(true)
                                                    }}
                                                >Upload Document</button>
                                            </>
                                    }
                                </div>
                            </div>

                            <br />

                            <div className='row justify-content-center'>
                                {
                                    (item?.salaryAdvanceChequeScan && item?.salaryAdvanceApplication && userInfo?.userRole === 'Finance')
                                    &&
                                    <div className='col-2'>
                                        <button className='limit-app__btn'
                                            disabled={item?.salaryAdvanceStatus === 'Approved' || item?.salaryAdvanceStatus === 'Rejected'}
                                            // style={{backgroundColor : (item?.salaryAdvanceStatus === 'Approved' || item?.salaryAdvanceStatus === 'Rejected') ? 'grey' : ''}}
                                            onClick={() => {
                                                setEmployeeId(item?.employeeId)
                                                setEmployee(item)
                                                setApproveModal(true)
                                            }}
                                        >Approve</button>
                                    </div>
                                }
                            </div>


                        </Paper>

                    })
            }

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={modal}
                onClose={() => {
                    setModal(false)
                }}
                closeAfterTransition
            >
                <Fade in={modal}>

                    <form className='paper w-50'
                        onSubmit={(e) => uploadFile(e)}
                    >
                        <div className='row d-flex justify-content-center'>
                            <div className='col-12 text-center'>
                                <label>{type === 'application' ? 'Select Application File' : 'Select Cheque Scan File'}</label>
                                <input
                                    required
                                    type="file" onChange={(e) => {
                                        if (type === 'application') {
                                            setApplicationFile(e.target.files[0])
                                        } else {
                                            setScanFile(e.target.files[0])
                                        }
                                    }} />
                            </div>

                        </div>

                        <br />

                        <div className='row d-flex justify-content-center'>
                            <div className='col-6 text-center mt-3'>
                                <button className='limit-app__btn' type='submit'>Upload</button>
                            </div>
                        </div>

                    </form>

                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={approveModal}
                onClose={() => {
                    setApproveModal(false)
                }}
                closeAfterTransition
            >
                <Fade in={approveModal}>

                    <div className='paper'>
                        <div className='row d-flex justify-content-center'>
                            <div className='col-12 text-center'>
                                <h4>Are you sure you  want to Approve Salary Advance Limit Application of {employeeId} ?</h4>
                            </div>

                        </div>

                        <br />

                        <div className='row d-flex justify-content-center'>
                            <div className='col-6 text-center'>
                                <button className='limit-app__btn' type='click'
                                    onClick={() => approveLimit('Approved')}
                                >
                                    Approve
                                </button>
                            </div>
                            <div className='col-6 text-center'>
                                <button className='limit-app__btn' type='click'
                                    onClick={() => {
                                        // approveLimit('Rejected')
                                        setApproveModal(false)
                                        setEmployeeId('')
                                        setEmployee({})
                                    }}
                                >Cancel</button>
                            </div>
                        </div>

                    </div>

                </Fade>
            </Modal>


        </div>
    )
}

export default LimitApplication