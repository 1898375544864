import React from 'react'
import './AlterationRequests.scss';


const AlterationRequests = () => {
    return (
        <div className='alteration'>
            <h2>Coming Soon</h2>
        </div>
    )
}

export default AlterationRequests