import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import './ViewBankTransfer.scss';

const ViewBankTransfer = () => {

    const bankTransferId = useParams().bankTransferId;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [bankTransfer, setBankTransfer] = useState({});

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/FinanceMgmt/bankTransfer/getBankTransferByBankTransferId/${bankTransferId}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)
                setBankTransfer(response.data.bankTransfer)
            })

    }, [])

    const dateFormatting = (date) => {

        let str = new Date(date).toLocaleString('en-US', { timeZone: 'Asia/Jakarta' });

        let d = new Date(str).getDate();
        if (d < 10) {
            d = `0${d}`
        }
        let m = new Date(str).getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = new Date(str).getFullYear();
        return `${d}-${m}-${y}`
    }

    return (
        <div className='view-bank-transfer'>

            <Button navigateTo={'/finance/banktransfer'} />

            <br />
            <br />

            <div className="row container-fluid justify-content-center" >
                <div className="col-8">
                    <h3 className='text-center' style={{ color: '#EE4B46' }}>Bank Transfer - {bankTransferId}</h3>
                </div>
            </div>
            <br />

            <div className="row container-fluid justify-content-center">
                <div className="col-4">
                    <label >Payment Category</label>
                    <input type="text" className='form-control' readOnly value={bankTransfer.paymentCategory} />
                </div>

                <div className="col-4">
                    <label >Payment SubCategory</label>
                    <input type="text" className='form-control' readOnly value={bankTransfer.paymentSubCategory} />
                </div>

            </div>

            <br />

            <div className="row container-fluid justify-content-center">

                <div className='col-4'>
                    <label >Customer Id</label>
                    <input type="text" className='form-control' readOnly value={bankTransfer.customerId || 'N/A'} />
                </div>

                <div className='col-4'>
                    <label >Transfer Date</label>
                    <input type="text" className='form-control' readOnly value={dateFormatting(bankTransfer.transferDate)} />
                </div>

            </div>

            <br />

            <div className="row container-fluid justify-content-center">

                <div className='col-4'>
                    <label >Transfer Amount</label>
                    <input type="text" className='form-control' readOnly value={bankTransfer.transferAmount} />
                </div>

                <div className='col-4'>
                    <label >Transfer Method</label>
                    <input type="text" className='form-control' readOnly value={bankTransfer.transferMethod} />
                </div>

            </div>

            <br />

            <div className="row container-fluid justify-content-center">
                <div className='col-4'>
                    <label>Transfer Bank Name</label>
                    <input type="text" className='form-control' readOnly value={bankTransfer.transferBankName} />
                </div>

                <div className='col-4'>
                    <label>Transfer Bank Account</label>
                    <input type="text" className='form-control' readOnly value={bankTransfer.transferBankAccount} />
                </div>
            </div>

            <br />

            <div className="row container-fluid justify-content-center">
                <div className='col-5'>
                    <label>Deposit Bank Name</label>
                    <input type="text" className='form-control' readOnly value={bankTransfer.depositBankName} />
                </div>

                <div className='col-5'>
                    <label>Deposit Bank Account</label>
                    <input type="text" className='form-control' readOnly value={bankTransfer.depositBankAccount} />
                </div>

            </div>

            <br />


            {/* <div className="row container-fluid justify-content-center">

                <div className='col-3'>
                    <Form.Group controlId="Site-Name">
                        <Form.Label>Deposit Bank Name</Form.Label>
                        <Form.Control as="select" required
                            value={depositBankName}
                            onChange={(e) => {
                                let value = e.target.value;
                                setDepositBankName(value);
                                if (value === 'WESTROAD DEVELOPERS PVT LTD A/C') setDepositBankAccount('50442540521')
                                else if (value === 'WESTROAD HEIGHTS COLLECTION A/C') setDepositBankAccount('38861908851')

                            }}
                        >
                            <option value="">Select a Bank</option>
                            <option value="WESTROAD DEVELOPERS PVT LTD A/C">WESTROAD DEVELOPERS PVT LTD A/C</option>
                            <option value="WESTROAD HEIGHTS COLLECTION A/C">WESTROAD HEIGHTS COLLECTION A/C</option>
                        </Form.Control>
                    </Form.Group>
                </div>

                <div className='col-3'>
                    <label>Deposit Bank Account</label>
                    <input type="text" className='form-control' required readOnly value={depositBankAccount} />
                </div>

                <div className='col-3'>
                    <label>Transaction Id</label>
                    <input type="text" className='form-control' onChange={(e) => setTransactionId(e.target.value)} />
                </div>

            </div>

            <br /> */}

            <div className="row container-fluid justify-content-center">

                <div className='col-3'>
                    <label>Transaction Id</label>
                    <input type="text" className='form-control' readOnly value={bankTransfer.transactionId} />
                </div>

                <div className='col-6'>
                    <label>Transfer Comments</label>
                    <textarea type="text" className='form-control' readOnly value={bankTransfer.transferComments} />
                </div>

            </div>

        </div>
    )

}

export default ViewBankTransfer