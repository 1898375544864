import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { Form } from "react-bootstrap";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import Button from '../../components/Button/Button';
import Swal from "sweetalert2";
import './AddUnit.scss';

function AddUnit() {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate();
    const siteCode = useParams().siteCode
    const Token = 'bearer' + " " + userInfo.token

    const [ufloor, setUfloor] = useState()
    const [site, setSite] = useState([])
    const [phases, setPhases] = useState([]);
    const [unitTypeNames, setUnitTypeNames] = useState([]);
    const [modal1, setModal1] = useState(false)
    const [modal2, setModal2] = useState(false);
    const [isPlc, setIsPlc] = useState(false)
    const [plc, setPlc] = useState('')
    const [plct, setPlct] = useState('')
    const [isPt, setIsPt] = useState(false);
    const [pta, setPta] = useState('')
    const [selectedPhaseCode, setSelectedPhaseCode] = useState('');
    const [selectedUnitTypeName, setSelectedUnitTypeName] = useState('');
    const [bsfr, setBsfr] = useState('');
    const [noOfFloor, setNoOfFloor] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`,
            { headers: { Authorization: Token } })
            .then(response => {

                setSite(response.data.site.siteName)
                setNoOfFloor(response.data.site.noOfFloor)
            })
            .then(() => {
                getPhaseNameList()
            })
    }, [])


    const getPhaseNameList = () => {

        axios.get(`${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`,
            { headers: { Authorization: Token } })
            .then((response) => {
                setPhases(response.data.site.phases)
            })
    }

    const getUnitTypeList = (phaseCode) => {
        axios
            .get(`${BASE_URL}/api/v1/Configuration/UnitType/getListOfUnitType?siteCode=${siteCode}&phaseCode=${phaseCode}`,
                { headers: { Authorization: Token } })
            .then((response) => {
                //  console.log(response)
                const unitTypes = response.data.unitTypes;
                let unitTypeNameList = []

                unitTypes.map((ut) => {
                    unitTypeNameList.push(ut.unitTypeName)
                })

                setUnitTypeNames(unitTypeNameList)
            })
    }


    const handleAddUnit = (e) => {
        e.preventDefault()

        if (error) return;

        let uf = parseInt(ufloor)

        let body = {
            siteCode: siteCode,
            unitTypeName: selectedUnitTypeName,
            unitFloor: uf,
            baseSqFeetRate: bsfr,
            isPLC: isPlc,
            isPrivateTerrace: isPt
        }

        if (plc && !pta) {

            body = {
                ...body,
                preferredLocationCharge: plc,
                preferredLocationChargeType: plct
            }

        } else if (!plc && pta) {
            body = {
                ...body,
                privateTerraceArea: pta,
            }
        } else if (plc && pta) {
            body = {
                ...body,
                preferredLocationCharge: plc,
                preferredLocationChargeType: plct,
                privateTerraceArea: pta
            }
        }

        axios
            .post(`${BASE_URL}/api/v1/Configuration/Unit/addNewUnit`,
                body,
                { headers: { Authorization: Token } })
            .then(response => {
                // console.log(response)
                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: 'New unit successfully created',
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        navigate(-1)
                    })
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                        text: response.data.message
                    })
                }

            })
    }


    return (
        <div className="addunit">

            <Button navigateTo={1} />


            <div className="row container-fluid justify-content-center" >
                <div className="col-8">
                    <h2>Add Unit</h2>
                </div>
            </div>
            <br />
            <form onSubmit={(e) => handleAddUnit(e)}>
                <div className="row container-fluid justify-content-center">
                    <div className="col-4">
                        <label>Site Name</label>
                        <input
                            readOnly
                            className="form-control"
                            value={site} />
                    </div>

                    <div className="col-4">
                        <Form.Group controlId="unitphase">
                            <Form.Label>Unit Phase</Form.Label>
                            <Form.Control as="select" required
                                onChange={(e) => {
                                    phases.forEach((p) => {
                                        if (p.phaseName === e.target.value) {
                                            setSelectedPhaseCode(p.phaseCode)
                                            getUnitTypeList(p.phaseCode)
                                        }
                                    })
                                }}>
                                <option value="">Select a Unit Phase</option>
                                {phases.map((p, index) => {
                                    return <option key={index}>{p.phaseName}</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>
                <br />
                <div className="row container-fluid justify-content-center">
                    <div className="col-4">
                        <Form.Group controlId="unittype">
                            <Form.Label>Unit Type Name</Form.Label>
                            <Form.Control as="select" required
                                onChange={(e) => {
                                    setSelectedUnitTypeName(e.target.value)
                                }}>
                                <option value="">Select a Unit Type</option>
                                {unitTypeNames.map((ut, index) => {
                                    return <option key={index}>{ut}</option>
                                })

                                }

                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div className="col-4">
                        <label>Unit Floor</label>
                        <input
                            type="number"
                            className="form-control"
                            name="unitfloor"
                            id="unitfloor"
                            required
                            onChange={(e) => {
                                if (e.target.value > noOfFloor) {
                                    setError(`Can't be more than ${noOfFloor}`)
                                } else {
                                    setUfloor(e.target.value)
                                    setError('')
                                }
                            }}
                        />
                        <em style={{ color: '#EE4B46' }}>{error}</em>
                    </div>

                </div>
                <br />
                <div className="row container-fluid justify-content-center">
                    <div className="col-4">
                        <label>Base Price <em style={{ color: '#EE4B46' }}>(per sq. feet)</em></label>
                        <input
                            type="number"
                            className="form-control"
                            name="basePrice"
                            id="basePrice"
                            required
                            onChange={(e) => setBsfr(e.target.value)}
                        />
                    </div>
                </div>
                <br />
                <div className="row container-fluid justify-content-center">
                    <div className="col-8 plc">
                        <span
                            style={{ width: '30%' }}>Preferred Location Charge</span>
                        <span>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setIsPlc(true)
                                    setModal1(true)
                                }} >Yes</button>

                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setIsPlc(false)
                                    setModal1(false)
                                }}>No</button>
                        </span>

                    </div>
                </div>



                {modal1 && <div className="row container-fluid justify-content-center">
                    <div className="col-4">
                        <label>Preferred Loaction Charge <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                        <input
                            type="number"
                            className="form-control"
                            onChange={(e) => setPlc(e.target.value)}
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label>Preferred Loaction Charge Type</label>
                        <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setPlct(e.target.value)}
                            required
                        />
                    </div>
                </div>}




                <div className="row container-fluid justify-content-center">
                    <div className="col-8 plc">
                        <span
                            style={{ width: '30%' }}>Private Terrace</span>
                        <span>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setIsPt(true)
                                    setModal2(true)
                                }} >Yes</button>

                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setIsPt(false)
                                    setModal2(false)
                                }}>No</button>
                        </span>

                    </div>
                </div>

                {modal2 &&
                    <div className="row container-fluid justify-content-center">
                        <div className="col-8">
                            <label>Private Terrace Area <em style={{ color: '#EE4B46' }}>(sq. feet)</em></label>
                            <input
                                type="number"
                                className="form-control"
                                onChange={(e) => setPta(e.target.value)}
                                required
                            />
                        </div>
                    </div>}

                <br />

                <div className="addunit__btns" >
                    <button type="reset" style={{ backgroundColor: "white", color: "black", border: '1px solid black' }}>Reset</button>
                    <button type="submit">Add Unit</button>
                </div>
            </form>
        </div>
    );
}

export default AddUnit;