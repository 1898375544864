import React, { useEffect, useState } from 'react'
import Select from '@mui/material/Select';
import InputBase from "@mui/material/InputBase";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import axios from "axios";
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { BASE_URL } from "../../utils/config/url";
import { styled } from "@mui/material/styles";
import MaterialTable from "material-table";
import './AttendanceList.scss';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: '10px',
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "#EE4B46",
        color: "white",
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px",
        width: "100"

    }
}));

const AttendanceList = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const navigate = useNavigate();

    const [attendanceList, setAttendanceList] = useState([])
    const [attendanceStatus, setAttendanceStatus] = useState('')
    const [attendanceType, setAttendanceType] = useState('')

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/attendance/getListOfAttendanceRecord?employeeId=${userInfo.employeeId}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)
                setAttendanceList(response.data.listOfAttendance)
            })


    }, [])

    const handleFilter = (e, type) => {

        e.preventDefault();
        let url = []

        if (type === 'status' || attendanceStatus) {

            if (type === 'status') {
                url.push('attendanceStatus', '=', e.target.value)
            } else {
                url.push('attendanceStatus', '=', attendanceStatus)
            }
        }

        if (type === 'type' || attendanceType) {
            if (url.length != 0) {
                url.push('&')
            }

            if (type === 'type') {
                url.push('attendanceType', '=', e.target.value)
            } else {
                url.push('attendanceType', '=', attendanceType)
            }

        }

        axios.get(`${BASE_URL}/api/v1/hrmgmt/attendance/getListOfAttendanceRecord?employeeId=${userInfo.employeeId}&${url.join('')}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                setAttendanceList(response.data.listOfAttendance)
            })
    }

    const dateFormatting = (date) => {

        if(!date)return 'Not Added'

        let str = new Date(date)

        let d = new Date(str).getDate();
        if (d < 10) {
            d = `0${d}`
        }
        let m = new Date(str).getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = new Date(str).getFullYear();

        let h = new Date(str).getHours()
        if (h < 10) {
            h = `0${h}`
        }
        let min = new Date(str).getMinutes()
        if (min < 10) {
            min = `0${min}`
        }
        // console.log(h , min)
        return `${d}-${m}-${y},${h}:${min}`
    }

    return (
        <div className='attendance-list'>
            <Button navigateTo={'/attendanceManagement'} />

            <br /> <br /><br />

            <div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'right',
                        marginBottom: '20px',
                        marginTop: '-50px'
                    }}>

                    <FormControl sx={{ m: 1, minWidth: 185, marginBottom: '10px' }}>

                        <InputLabel id="demo-simple-select-helper-label" style={{ color: 'black', fontSize: '15px', paddingTop: '5px' }}>
                            Attendance Type <FilterAltRoundedIcon style={{ marginLeft: '2px' }}
                            />
                        </InputLabel>

                        <Select
                            value={attendanceType}
                            input={<BootstrapInput />}
                            onChange={(e) => {
                                setAttendanceType(e.target.value)
                                handleFilter(e, 'type')
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value='WFH'>WFH</MenuItem>
                            <MenuItem value='InOffice'>InOffice</MenuItem>
                            <MenuItem value='FieldWork'>FieldWork</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 192, marginBottom: '10px' }}>

                        <InputLabel id="demo-simple-select-helper-label" style={{ color: 'black', fontSize: '15px', paddingTop: '5px' }}>
                            Attendance Status<FilterAltRoundedIcon style={{ marginLeft: '3px' }}
                            />
                        </InputLabel>

                        <Select
                            value={attendanceStatus}
                            input={<BootstrapInput />}
                            onChange={(e) => {
                                setAttendanceStatus(e.target.value)
                                handleFilter(e, 'status')
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value='Initiated'>Initiated</MenuItem>
                            <MenuItem value='Approved'>Approved</MenuItem>
                            <MenuItem value='Rejected'>Rejected</MenuItem>
                        </Select>
                    </FormControl>

                </div>

                <h4>Attendance List</h4>
                <MaterialTable
                    data={attendanceList}
                    title=""
                    columns={[
                        { title: "Employee Id", field: "employeeId" },
                        { title: "Employee Name", field: "employeeName" },
                        { title: "Attendance Type", field: "attendanceType" },
                        { title: "Attendance Status", field: "attendanceStatus" },
                        { title: "Line Manager Id", field: "lineManagerId" },
                        { title: "Line Manager Name", field: "lineManagerName" },
                        {
                            title: "Start Time",
                            render: (rowData) => (dateFormatting(rowData.startTime))
                        },
                        {
                            title: "End Time",
                            render: (rowData) => (dateFormatting(rowData.endTime))
                        },
                        {
                            title: "Total Hours",
                            render: (rowData) => (`${rowData.totalHours} hrs`)
                        },

                    ]}
                    options={{
                        search: true,
                        searchFieldStyle: {
                            width: 180
                        },
                        paging: true,
                        pageSize: 20,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [5, 10, 20, 30],
                        headerStyle: {
                            backgroundColor: "#EE4B46",
                            color: "#fff",
                        },
                    }}
                    actions={[
                        {
                            icon: "remove_red_eye",
                            tooltip: "View Attendance",
                            onClick: (event, rowData) => {
                                // navigate(`/individuallead/${rowData.leadID}`);
                            },
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default AttendanceList