import React, { useEffect } from 'react'
import './ApplyBackendLeave.scss'
import Button from '../../components/Button/Button';
import { Form } from 'react-bootstrap';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { alpha, styled } from "@mui/material/styles";
import { Switch, Select, InputLabel, MenuItem } from "@mui/material";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


const RedSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: "#EE4B46",
        "&:hover": {
            backgroundColor: alpha("#EE4B46", theme.palette.action.hoverOpacity),
        },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#EE4B46",
    },
}));

const ApplyBackendLeave = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate();

    const [employees, setEmployees] = React.useState([])
    const [employeeId, setEmployeeId] = React.useState('')
    const [employee, setEmployee] = React.useState({})
    const [leaveType, setLeaveType] = React.useState('');
    const [leaveTypeName, setLeaveTypeName] = React.useState('');
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [totalDays, setTotalDays] = React.useState('');
    const [reason, setReason] = React.useState('');
    const [halfDay, setHalfDay] = React.useState(false)
    const [leaveHalf, setLeaveHalf] = React.useState('First')

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`,
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                if (response.data.status) {
                    setEmployees(response.data.employees)
                } else {
                    setEmployees([])
                }
            })


    }, []);


    useEffect(() => {

        if (!employeeId) return;

        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${employeeId}`,
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                console.log(response.data)
                if (response.data.status) {
                    setEmployee(response.data.employee)
                } else {
                    setEmployee([])
                }
            })

    }, [employeeId])

    useEffect(() => {

        if (startDate && endDate) {

            let start = new Date(startDate).getDate();
            let end = new Date(endDate).getDate();

            let total = end - start + 1;

            setTotalDays(`${total} days`);
        }

    }, [startDate, endDate])

    const applyLeave = (e) => {

        e.preventDefault();

        let availableBalance = employee?.leaveBalance[`${leaveTypeName}`]

        if (totalDays.split(' ')[0] > availableBalance) {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `You don't have enough ${leaveTypeName} balance`,
            })
        }

        let body;
        let url;

        if (halfDay) {
            url = `${BASE_URL}/api/v1/hrmgmt/leave/applyHalfDayLeave`
            body = {
                employeeId,
                leaveType: leaveType.split(',')[2],
                leaveHalf,
                leaveReason: reason,
                leaveDate: startDate,
            }
        } else {
            url = `${BASE_URL}/api/v1/hrmgmt/leave/applyLeave`
            body = {
                employeeId,
                leaveType: leaveType.split(',')[2],
                leaveReason: reason,
                leaveStartDate: startDate,
                leaveEndDate: endDate,
            }
        }

        axios.post(url, body,
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                    }).then(function () {
                        navigate('/leavemanagement')
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                    })
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error,
                })
            })

    }

    return (
        <div className='apply-backend-leave'>

            <div className='row justify-content-start'>
                <div className='col-2'>
                    <Button navigateTo="/leavemanagement" />
                </div>

                <div className='col-8 text-center mt-3'>
                    <h2 className='text-center'
                        style={{
                            color: '#EE4B46'
                        }}
                    >Apply Backend Leave</h2>
                </div>
            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6'>
                    <Form.Group controlId="employee">
                        <Form.Label>Employee</Form.Label>
                        <Form.Control as="select" required
                            value={employeeId}
                            onChange={(e) => {
                                setEmployeeId(e.target.value)
                            }}
                        >
                            <option value="">Select a option</option>
                            {employees.map((employee) => (
                                <option value={employee.employeeId}>{employee.employeeFullName}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </div>
            </div>

            <br />

            {
                employeeId
                &&
                <div className='row justify-content-center'>

                    <div className='apply-backend-leave__balance'>
                        Weekly Off - {employee?.leaveBalance?.weeklyOff}
                    </div>

                    <div className='apply-backend-leave__balance'>
                        Casual Leave - {employee?.leaveBalance?.casualLeave}
                    </div>

                    <div className='apply-backend-leave__balance'>
                        Earned Leave - {employee?.leaveBalance?.earnedLeave}
                    </div>

                    <div className='apply-backend-leave__balance'>
                        Medical Leave - {employee?.leaveBalance?.medicalLeave}
                    </div>

                    <div className='apply-backend-leave__balance'>
                        Leave without Pay - {employee?.leaveBalance?.lwp}
                    </div>

                </div>
            }


            <br />
            <br />


            <div className='row justify-content-center'>
                <div className='col-6'>
                    <Form.Group controlId="leave type">
                        <Form.Label>Leave Type</Form.Label>
                        <Form.Control as="select" required
                            value={leaveType}
                            onChange={(e) => {
                                setLeaveType(e.target.value)
                                setLeaveTypeName(e.target.value.split(',')[1])
                            }}
                        >
                            <option value="">Select a option</option>
                            <option value='Weekly Off,weeklyOff,WeeklyOff'>Weekly Off</option>
                            <option value='Casual Leave,casualLeave,CasualLeave'>Casual Leave</option>
                            <option value='Earned Leave,earnedLeave,EarnedLeave'>Earned Leave</option>
                            <option value='Medical Leave,medicalLeave,MedicalLeave'>Medical Leave</option>
                            <option value='Leave without Pay,lwp,LWP'>Leave without Pay</option>

                        </Form.Control>
                    </Form.Group>
                </div>
            </div>

            <br />

            {
                (Object.keys(employee).length > 0 && (employee?.leaveBalance[`${leaveTypeName}`] === 0))
                    ?
                    <h4 className='text-center'>You Cannot Apply {leaveType.split(',')[0]} because Balance is 0</h4>
                    :
                    (Object.keys(employee).length === 0 || leaveType === '')
                        ?
                        null
                        :
                        <form
                            onSubmit={(e) => applyLeave(e)}
                        >

                            <div className='row justify-content-center'>
                                <div className={window.innerWidth < 700 ? 'col-6' : 'col-4'}>
                                    <label>Start Date</label>
                                    <input id='calendar1'
                                        className='form-control'
                                        type='date'
                                        required
                                        value={startDate}
                                        onChange={(e) => {
                                            setStartDate(e.target.value)
                                        }}
                                    />
                                </div>

                                {
                                    !halfDay
                                    &&
                                    <div className={window.innerWidth < 700 ? 'col-6' : 'col-4'}>
                                        <label>End Date</label>
                                        <input id='calendar2'
                                            className='form-control'
                                            type='date'
                                            required
                                            value={endDate}
                                            onChange={(e) => {
                                                setEndDate(e.target.value)
                                            }}
                                        />
                                    </div>}
                            </div>

                            <br />

                            <div className='row justify-content-center'>
                                <div className={window.innerWidth < 700 ? 'col-6' : 'col-4'}>
                                    <label>Total Days</label>
                                    <input type="text" className='form-control'
                                        readOnly
                                        value={halfDay ? 0.5 : totalDays}
                                    />
                                </div>

                                <div className={window.innerWidth < 700 ? 'col-6 text-center mt-4' : 'col-4 text-center mt-4'}>
                                    <label>Half Day Leave</label>
                                    <RedSwitch
                                        checked={halfDay === true ? true : false}
                                        onChange={() => {
                                            if (!halfDay === true) {
                                                setTotalDays('0.5 days')
                                            }
                                            setHalfDay(!halfDay)
                                        }}
                                    />
                                </div>
                            </div>

                            {
                                halfDay
                                &&
                                <>
                                    <br />
                                    <div className='row justify-content-center'>
                                        <div className={window.innerWidth < 700 ? 'col-6 text-center' : 'col-4 text-center'}>
                                            <label>Leave Half</label>
                                            <br />
                                            <span>First</span>
                                            <RedSwitch
                                                checked={leaveHalf === 'First' ? false : true}
                                                onChange={() => setLeaveHalf((prev) => {
                                                    if (prev === 'First') {
                                                        return 'Second'
                                                    } else {
                                                        return 'First'
                                                    }
                                                })}
                                            />
                                            <span>Second</span>
                                        </div>

                                    </div>
                                </>
                            }

                            <br />

                            <div className='row justify-content-center'>
                                <div className={window.innerWidth < 700 ? 'col-10' : 'col-8'}>
                                    <label>Reason</label>
                                    <textarea type="text"
                                        className='form-control'
                                        required
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                    />
                                </div>
                            </div>

                            <br />

                            <div className='row justify-content-center'>
                                <button className='add-wfh__btn' type='submit'
                                    disabled={userInfo?.userRole != 'HR'}
                                    style={{ backgroundColor: userInfo?.userRole != 'HR' ? 'grey' : '' }}
                                >Submit</button>
                            </div>

                        </form>
            }


        </div>
    )
}

export default ApplyBackendLeave;
