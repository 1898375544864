import React from "react";
import apartmentImg1 from "../../../assets/tableView/apartment-1.png";
import apartmentImg2 from "../../../assets/tableView/apartment-2.png";
import apartmentImg3 from "../../../assets/tableView/apartment-3.png";
import "./Kitchen.scss";

export default function Kitchen() {
  return (
    <>
      <div className="kitchen__images">
        <img src={apartmentImg1} alt="" />
        <div className="kitchen__images__subcontainer">
          <img src={apartmentImg2} alt="" />

          <img src={apartmentImg3} alt="" />
        </div>
      </div>
      <div className="kitchen__infoContainer">
        <div className="kitchen__infoContainer__info">
          <b>Flooring: </b>
          <p>Anti-Skid Ceramic tiles</p>
        </div>
        <div className="kitchen__infoContainer__info">
          <p>
            Electrical points for Refrigerator, water filter, microwave, chimney
            and exhaust fan
          </p>
          <p>Granite counter platform</p>
          <p>Stainless steel sink</p>
          <p>
            Dado of ceramic tiles up to 3 ft. above the granite counter platform
          </p>
        </div>
      </div>
    </>
  );
}
