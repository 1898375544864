import React from 'react'

const ReportingDashboard = () => {
    return (
        <div className='handover'>
            <h2>Reporting Dashboard</h2>
            <br/>
            <br/>
            <h4>Coming Soon</h4>
        </div>
    )
}

export default ReportingDashboard