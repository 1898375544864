import React, { useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import Swal from "sweetalert2";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import { useNavigate } from "react-router-dom";
import "./AddBrokerForm.scss";
import Button from "../../components/Button/Button";

const AddBrokerForm = () => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [BrokerName, setBrokerName] = useState("");
  const [brokerAddress, setBrokerAddress] = useState("");
  const [brokerCompany, setBrokerCompany] = useState("");
  const [brokerPAN, setBrokerPAN] = useState("");
  const [brokerRERA, setBrokerRERA] = useState("");
  const [brokerType, setBrokerType] = useState("");
  const [validated, setValidated] = useState(true);
  //   const [errorMessage, setErrorMessage] = useState("");

  const reset = () => {
    setBrokerName("");
    setBrokerAddress("");
    setBrokerCompany("");
    setBrokerPAN("");
    setBrokerRERA("");
    setBrokerType("");
  };

  const submit = (e) => {
    e.preventDefault();
    const Token = "bearer" + " " + userInfo.token;
    if (validated === true) {
      axios
        .post(
          `${BASE_URL}/api/v1/leadmgmt/broker/addNewBroker`,
          {
            brokerPAN: brokerPAN,
            brokerName: BrokerName,
            brokerCompany: brokerCompany,
            brokerAddress: brokerAddress,
            brokerRERA: brokerRERA,
            brokerType: brokerType,
          },
          { headers: { Authorization: Token } }
        )
        .then((response) => {
          console.log(response);

          if (response.data.status === true) {
            Swal.fire("Successfully!", "Broker Added!", "success");
            navigate("/listofChannelPartner");
          } else {
            Swal.fire(
              {
                icon: "error",
                title: "Oops...",
                text: response.data.message,
              }
            );
          }
        });
    } else {
      Swal.fire(
        "Please Fill all Field Correctly!",
        "Broker Not Added!",
        "error"
      );
    }
  };

  return (
    <>
      <div className="add-broker">
        <div className="mt-3 row container-fluid justify-content-center px-2">
          <div className="col-12">
            <Button navigateTo={"/listofChannelPartner"} />
          </div>
        </div>
        <form onSubmit={submit} className="add-broker__form">
          <div className="row mt-5 container-fluid justify-content-center">
            <div className="col-12">
              <h4>Add Broker</h4>
            </div>
          </div>
          <div className="row mt-3 container-fluid justify-content-center">
            <div className="col-lg-6 col-sm-12">
              <label>Broker Name</label>
              <input
                type="text"
                class="form-control"
                name="brokername"
                id="brokername"
                value={BrokerName}
                required
                onChange={(e) => {
                  setBrokerName(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-6 col-sm-12">
              <label>Broker Company</label>
              <input
                type="text"
                class="form-control"
                name="brokercompany"
                id="brokercompany"
                value={brokerCompany}
                required
                onChange={(e) => setBrokerCompany(e.target.value)}
              />
            </div>
          </div>
          <div className="row mt-3 container-fluid justify-content-center">
            <div className="col-lg-6  col-sm-12">
              <label>Broker Address</label>
              <input
                type="text"
                class="form-control"
                name="brokeraddress"
                id="brokeraddress"
                value={brokerAddress}
                required
                onChange={(e) => setBrokerAddress(e.target.value)}
              />
            </div>
            <div className="col-lg-6  col-sm-12">
              <label>Broker Type</label>
              <select
                class="form-control"
                name="brokerType"
                id="brokerType"
                value={brokerType}
                onChange={(e) => setBrokerType(e.target.value)}
                required
              >
                <option value="" disabled>
                  Select a type
                </option>
                <option value="Individual">Individual</option>
                <option value="Company">Company</option>
              </select>
            </div>
          </div>

          <div className="row mt-3 container-fluid justify-content-center">
            <div className="col-lg-6 col-sm-12">
              <label>Broker PAN</label>
              <input
                type="text"
                class="form-control"
                name="brokerpan"
                id="brokerpan"
                value={brokerPAN}
                required
                onChange={(e) => {
                  if (e.target.value.length !== 10) {
                    setValidated(false);
                  } else {
                    setValidated(true);
                  }
                  setBrokerPAN(e.target.value.toUpperCase());
                  console.log(e.ta);
                }}
              />
              {brokerPAN.length !== 10 && brokerPAN.length !== 0 && (
                <small id="bcodeMessage" className="text-danger">
                  10 digit only.
                  <br />
                </small>
              )}
            </div>
            <div className="col-lg-6 col-sm-12">
              <label>Broker RERA</label>
              <input
                type="text"
                class="form-control"
                name="brokerrera"
                id="brokerrera"
                value={brokerRERA}
                onChange={(e) => setBrokerRERA(e.target.value)}
              />
            </div>
          </div>

          <div className="row mt-4 container-fluid justify-content-center">
            <div className="col-6 text-right">
              <button
                className="btn btn-secondary btn-user"
                type="reset"
                onClick={reset}
                style={{ backgroundColor: "white", color: "black" }}
              >
                Reset
              </button>
            </div>

            <div className="col-6">
              <button className="btn btn-secondary btn-user" type="submit">
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddBrokerForm;
