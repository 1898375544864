import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../utils/config/url";
import { useStyles } from "../../utils/ModalStyles";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";

const OtherCharges = () => {
  const classes = useStyles();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const siteCode = useParams().siteCode;
  const Token = "bearer" + " " + userInfo.token;

  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [oc, setOc] = useState([]);
  const [selectedOc, setSelectedOc] = useState({});
  const [name, setName] = useState("");
  const [amount, setAmount] = useState();
  const [gst, setGst] = useState();
  const [chargeType, setChargeType] = useState("");
  const [deleteId, setDeleteId] = useState();

  useEffect(() => {
    getAllOtherCharges();
  }, []);

  const getAllOtherCharges = () => {
    axios.get(`${BASE_URL}/api/v1/Configuration/Site/getAllOtherCharges/${siteCode}`, { headers: { Authorization: Token } }).then((response) => {
      //console.log(response);
      setOc(response.data.otherCharges);
    });
  };

  const handelClose1 = () => {
    setModal1(false);
    setName("");
    setAmount();
    setGst();
    setChargeType();
  };

  const handleAddOtherCharges = (e) => {
    e.preventDefault();
    axios
      .post(
        `${BASE_URL}/api/v1/Configuration/Site/addOtherCharge`,
        {
          siteCode: siteCode,
          name: name,
          amount: parseInt(amount),
          gst: parseInt(gst),
          type: chargeType,
        },
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        // console.log(response)
        setModal1(false);
        if (response.status === 200) {
          if (response.data.status === true) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: response.data.message,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then(function () {
              getAllOtherCharges();
              handelClose1();
            });
          } else if (response.data.status === false) {
            Swal.fire({
              icon: "error",
              title: "Error",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
              text: response.data.message,
            });
          }
        }
      });
  };

  const handleEditOtherCharges = (e) => {
    e.preventDefault();
    const body = {
      ...selectedOc,
      siteCode: siteCode,
      amount: parseInt(selectedOc.amount),
      gst: parseInt(selectedOc.gst),
    };
    //console.log(body)
    axios
      .put(`${BASE_URL}/api/v1/Configuration/Site/updateOtherCharge`, body, { headers: { Authorization: Token } })
      .then((response) => {
        setModal2(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getAllOtherCharges();
          });
        }
      })
      .catch((error) => {
        // console.log(response.message)
        setModal2(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: error,
        });
      });
  };

  const handleDeleteOtherCharge = () => {
    axios
      .delete(`${BASE_URL}/api/v1/Configuration/Site/deleteOtherCharge?siteCode=${siteCode}&id=${deleteId}`, { headers: { Authorization: Token } })
      .then((response) => {
        console.log(response);
        setModal3(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getAllOtherCharges();
          });
        }
      })
      .catch((error) => {
        setModal3(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          //text: response.data.err
        });
      });
  };

  return (
    <div className="mt-2 container-fluid justify-content-center">
      <br />
      <br />
      <div className="heading">
        <h4>Other Charges</h4>
        <button
          className="addbtn"
          onClick={() => {
            setModal1(true);
          }}
        >
          Add
        </button>
      </div>
      <br />
      <table className="table">
        <thead style={{ backgroundColor: "#EE4B46", color: "#fff" }}>
          <tr>
            <th scope="col">Charge Name</th>
            <th scope="col">Amount</th>
            <th scope="col">GST</th>
            <th scope="col">Charge Type</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {oc.map((o, index) => (
            <tr key={index}>
              <td>{o.name}</td>
              <td>{o.amount}</td>
              <td>{o.gst}</td>
              <td>{o.type}</td>

              <td>
                <EditIcon
                  style={{ marginRight: "30px" }}
                  onClick={() => {
                    setModal2(true);
                    oc.forEach((oc) => {
                      if (oc._id === o._id) {
                        setSelectedOc(o);
                      }
                    });
                  }}
                />

                <Delete
                  onClick={() => {
                    setModal3(true);
                    setDeleteId(o._id);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal1}
        onClose={handelClose1}
        closeAfterTransition
      >
        <Fade in={modal1}>
          <div className={classes.paper}>
            <form
              method="POST"
              onSubmit={(e) => handleAddOtherCharges(e)}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="row container-fluid justify-content-center">
                <div className="col-12">
                  <label>Charge Name</label>
                  <input className="form-control" autoFocus required type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
              </div>

              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-6">
                  <label>Amount</label>
                  <input required className="form-control" type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
                </div>

                <div className="col-6">
                  <label>
                    GST <em style={{ color: "#EE4B46" }}>(percentage)</em>
                  </label>
                  <input required className="form-control" type="number" value={gst} onChange={(e) => setGst(e.target.value)} />
                </div>
              </div>

              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-12">
                  <label>Charge Type :</label>
                  <input type="radio" required name="charge type" onClick={(e) => setChargeType("perSqFt")} style={{ marginLeft: "15px" }} /> Per Sq. Feet
                  <input type="radio" required name="charge type" onClick={(e) => setChargeType("Fixed")} style={{ marginLeft: "25px" }} /> Fixed
                </div>
              </div>

              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-12 text-center">
                  <button className="modal__btns" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>

      {/* ///////////////////// Modal for Editing Legal Charge ///////////////// */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal2}
        onClose={() => setModal2(false)}
        closeAfterTransition
      >
        <Fade in={modal2}>
          <div className={classes.paper}>
            <form
              method="POST"
              onSubmit={(e) => handleEditOtherCharges(e)}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="row container-fluid justify-content-center">
                <div className="col-12">
                  <label>Charge Name</label>
                  <input className="form-control" readOnly value={selectedOc.name} />
                </div>
              </div>

              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-6">
                  <label>Amount</label>
                  <input
                    required
                    className="form-control"
                    type="number"
                    value={selectedOc.amount}
                    onChange={(e) =>
                      setSelectedOc((prev) => {
                        return {
                          ...prev,
                          amount: e.target.value,
                        };
                      })
                    }
                  />
                </div>

                <div className="col-6">
                  <label>
                    GST <em style={{ color: "#EE4B46" }}>(percentage)</em>
                  </label>
                  <input
                    required
                    className="form-control"
                    type="number"
                    value={selectedOc.gst}
                    onChange={(e) =>
                      setSelectedOc((prev) => {
                        return {
                          ...prev,
                          gst: e.target.value,
                        };
                      })
                    }
                  />
                </div>
              </div>

              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-12">
                  <label>Charge Type :</label>
                  <input
                    type="radio"
                    required
                    name="charge type"
                    checked={selectedOc.type === "perSqFt"}
                    onClick={(e) =>
                      setSelectedOc((prev) => {
                        return {
                          ...prev,
                          type: "perSqFt",
                        };
                      })
                    }
                    style={{ marginLeft: "15px" }}
                  />{" "}
                  Per Sq. Feet
                  <input
                    type="radio"
                    required
                    name="charge type"
                    checked={selectedOc.type === "Fixed"}
                    onClick={(e) =>
                      setSelectedOc((prev) => {
                        return {
                          ...prev,
                          type: "Fixed",
                        };
                      })
                    }
                    style={{ marginLeft: "25px" }}
                  />{" "}
                  Fixed
                </div>
              </div>

              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-12 text-center">
                  <button className="modal__btns" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>

      {/* ////////////////////  Modal for Deleting Other Charges ///////////////// */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal3}
        onClose={() => setModal3(false)}
        closeAfterTransition
      >
        <Fade in={modal3}>
          <div className={classes.paper}>
            <h6>Are you sure you want to delete this ?</h6>
            <br />
            <div className="row container-fluid justify-content-center">
              <div className="col-6 text-right">
                <button className="modal__btns" onClick={handleDeleteOtherCharge}>
                  Yes
                </button>
              </div>
              <div className="col-6 text-left">
                <button
                  className="modal__btns"
                  onClick={() => setModal3(false)}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default OtherCharges;
