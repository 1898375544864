import React, { useEffect } from 'react'
import { BASE_URL } from '../../utils/config/url'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useState } from 'react'
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom'

const UploadVideos = ({ site }) => {

    // console.log(site)

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const siteCode = useParams().siteCode;

    const [videos, setVideos] = useState(site?.youtubeLinks)
    const [link, setLink] = useState('')
    const [description, setDescription] = useState('')
    const [duration, setDuration] = useState('')

    useEffect(() => {
        setVideos(site?.youtubeLinks)
    }, [site])



    const uploadVideo = () => {

        if (!link || !description || !duration) return

        // console.log(site?.siteCode)

        axios.put(`${BASE_URL}/api/v1/configuration/site/updateVideoLink`,
            {
                siteCode: siteCode,
                link,
                duration,
                comment: description
            },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Video Uploaded Successfully'
                    })
                        .then(() => {
                            setLink('')
                            setDescription('')
                            setDuration('')
                            setVideos(response?.data?.doc?.youtubeLinks)
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.message
                    })
                }
            })
            .catch((err) => {
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: err.response.data.error
                })
            })
    }

    const deleteVideo = (link) => {


        axios.put(`${BASE_URL}/api/v1/configuration/site/deleteVideoLink`,
            {
                siteCode: site?.siteCode,
                link,
            },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Video deleted Successfully'
                    })
                        .then(() => {
                            setVideos(response?.data?.doc?.youtubeLinks)
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.message
                    })
                }
            })
            .catch((err) => {
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: err.response.data.error
                })
            })

    }


    // console.log(videos)

    return (<div className='videos'>
        <Paper elevation={2} style={{ padding: '20px' }}>

            <h4>Youtube Videos</h4>

            <div className='row justify-content-between'>
                {/* <div className='col-6'>
                    <button className='generatedPaySlip__incentives__btn pt-2'
                        onClick={addRow}
                    >Add Row</button>
                </div> */}

                <div className='col-6'>
                    <b style={{ fontSize: '20px', color: 'red' }}>Only 5 Videos are allowed</b>
                </div>
            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Video Link</label>
                    <input type="text" className='form-control' value={link} onChange={(e) => setLink(e.target.value)} />
                </div>

                <div className='col-6'>
                    <label>Duration</label>
                    <input type="number" className='form-control' value={duration} onChange={(e) => setDuration(e.target.value)} />
                </div>

            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Description</label>
                    <textarea type="text" maxLength="50" className='form-control' value={description} onChange={(e) => {
                        const shouldSetValue = e.target.value.length <= 50
                        if(shouldSetValue)setDescription(e.target.value)
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: 'Maximum 50 characters are allowed'
                            })
                            }
                    }} />
                </div>

                <div className='col-6 text-center mt-3'>
                    <button
                        disabled={site?.youtubeLinks?.length === 5 ? true : false}
                        style={{
                            backgroundColor: site?.youtubeLinks?.length === 5 ? 'grey' : ''
                        }}
                        onClick={uploadVideo}
                    >Uplaod</button>
                </div>
            </div>

            <br />

            {
                videos?.map((item, index) => {

                    return <div className='row justify-content-center mt-4' key={index}>
                        <div className='col-4 text-center'>
                            <input type="text"
                                className='form-control'
                                readOnly
                                value={item?.link}
                            // onChange={(e) => handleChange(e, index, 'description')}
                            />
                        </div>

                        <div className='col-3 text-center'>
                            <input type="text"
                                className='form-control'
                                readOnly
                                value={item?.comment}
                            // onChange={(e) => handleChange(e, index, 'amount')}
                            />
                        </div>

                        <div className='col-3 text-center'>
                            <input type="number"
                                className='form-control'
                                readOnly
                                value={item?.duration}
                            // onChange={(e) => handleChange(e, index, 'amount')}
                            />
                        </div>

                        <div className='col-2 text-center'>
                            <button
                                onClick={() => deleteVideo(item?.link)}
                            >Delete</button>
                        </div>
                    </div>
                })
            }

        </Paper>
    </div>
    )
}

export default UploadVideos
