import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import { Form } from 'react-bootstrap';
import './Sales.scss';
import Swal from 'sweetalert2';
import { Delete } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const Sales = () => {

    const [siteList, setSiteList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [site, setSite] = useState('');
    const [employee, setEmployee] = useState('');
    const [delSiteCode, setDelSiteCode] = useState('');
    const [delUserName, setDelUserName] = useState('');
    const [deleteType, setDeleteType] = useState('');
    const [leadmgmtAssigneeList, setLeadmgmtAssigneeList] = useState([]);
    const [quotationAssigneeList, setQuotationAssigneeList] = useState([]);
    const [deleteAssigneeModal, setDeleteAssigneeModal] = useState(false);

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response.data);
                setEmployeeList(response.data.employees)
            })

    }, []);


    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/configuration/site/getAllSiteNames`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response.data);
                setSiteList(response.data.siteList)
            })

    }, []);

    useEffect(() => {
        getLeadMgmtAssigneeList();
        getQuotationAssigneeList();
    }, [])

    const getLeadMgmtAssigneeList = () => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/defaultassignee/getListOfDefaultAssignee?taskType=LeadAssignment&taskRole=Sales`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response.data);
                if (response.data.status === true) {
                    setLeadmgmtAssigneeList(response.data.assignee)
                } else {
                    setLeadmgmtAssigneeList([])
                }
            })

    }

    const getQuotationAssigneeList = () => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/defaultassignee/getListOfDefaultAssignee?taskType=QuotationApproval&taskRole=Sales`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response.data);
                if (response.data.status === true) {
                    setQuotationAssigneeList(response.data.assignee)
                } else {
                    setQuotationAssigneeList([])
                }
            })

    }

    const addAssignee = (taskType) => {
        let body = {
            employeeId: employee,
            taskType: taskType,
            taskRole: 'Sales',
            siteCode: site.split(',')[1],
            siteName: site.split(',')[0]
        }


        axios.post(`${BASE_URL}/api/v1/hrmgmt/defaultassignee/addDefaultAssignee`,
            body,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
              
                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    })
                        .then(() => {
                            setEmployee('')
                            setSite('')
                            if (taskType === 'LeadAssignment') {
                                getLeadMgmtAssigneeList();
                            } else {
                                getQuotationAssigneeList();
                            }


                        })
                }

            }).catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }

    const deleteAssignee = (taskType) => {

        let body = {
            userName: delUserName,
            taskType: taskType,
            taskRole: 'Sales',
            siteCode: delSiteCode,
        }

        axios.put(`${BASE_URL}/api/v1/hrmgmt/defaultassignee/deleteDefaultAssignee`,
            body,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                setDeleteAssigneeModal(false);
                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    })
                        .then(() => {
                            setEmployee('')
                            setSite('')
                            if (taskType === 'LeadAssignment') {
                                getLeadMgmtAssigneeList();
                            } else {
                                getQuotationAssigneeList();
                            }


                        })
                }

            }).catch((err) => {
                setDeleteAssigneeModal(false);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })

    }


    return (
        <div className='sales'>

            <h5 className='text-center mb-5'>Lead To Be Assigned</h5>

            <div className='row justify-content-center mb-5'>
                <div className='col-4 text-center'>
                    <Form.Group controlId="Site">
                        <Form.Label>Site</Form.Label>
                        <Form.Control required onChange={(e) => setSite(e.target.value)} as="select">
                            <option value="">Select a source</option>
                            {
                                siteList?.map((site, index) => {
                                    return <option key={index} value={`${site.SiteName},${site.SiteCode}`}>{site.SiteName}</option>
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </div>
                <div className='col-4 text-center'>
                    <Form.Group controlId="Site">
                        <Form.Label>Employee</Form.Label>
                        <Form.Control required onChange={(e) => setEmployee(e.target.value)} as="select">
                            <option value="">Select a source</option>
                            {
                                employeeList?.map((employee, index) => {
                                    return <option key={index} value={employee?.employeeId}>{employee?.employeeId}</option>
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </div>
                <div className='col-4 text-center'>
                    <button className='sales__btn mt-4'
                        onClick={() => addAssignee('LeadAssignment')}
                    >Add</button>
                </div>
            </div>

            {
                leadmgmtAssigneeList?.length === 0
                    ?
                    <h5 className='text-center mb-5'>No Assignee Found</h5>
                    :
                    <table className="sales__table mb-5">
                        <thead style={{ backgroundColor: "#EE4B46", color: "#fff" }}>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Site Name</th>
                                <th scope="col">Site Code</th>
                                <th scope='col'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leadmgmtAssigneeList?.map((lead, index) => (
                                <tr key={index}>
                                    <td>{lead.userName}</td>
                                    <td>{lead.siteName}</td>
                                    <td>{lead.siteName}</td>

                                    <td>

                                        <Delete
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setDeleteAssigneeModal(true)
                                                setDelSiteCode(lead.siteCode);
                                                setDelUserName(lead.userName);
                                                setDeleteType('LeadAssignment')
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

            }


            <h5 className='text-center mb-5'>Quotation Approval</h5>

            <div className='row justify-content-center mb-5'>
                <div className='col-4 text-center'>
                    <Form.Group controlId="Site">
                        <Form.Label>Site</Form.Label>
                        <Form.Control required onChange={(e) => setSite(e.target.value)} as="select">
                            <option value="">Select a source</option>
                            {
                                siteList?.map((site, index) => {
                                    return <option key={index} value={`${site.SiteName},${site.SiteCode}`}>{site.SiteName}</option>
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </div>
                <div className='col-4 text-center'>
                    <Form.Group controlId="Site">
                        <Form.Label>Employee</Form.Label>
                        <Form.Control required onChange={(e) => setEmployee(e.target.value)} as="select">
                            <option value="">Select a source</option>
                            {
                                employeeList?.map((employee, index) => {
                                    return <option key={index} value={employee?.employeeId}>{employee?.employeeId}</option>
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </div>
                <div className='col-4 text-center'>
                    <button className='sales__btn mt-4'
                        onClick={() => addAssignee('QuotationApproval')}
                    >Add</button>
                </div>
            </div>

            {
                quotationAssigneeList?.length === 0
                    ?
                    <h5 className='text-center mb-5'>No Assignee Found</h5>
                    :
                    <table className="sales__table mb-5">
                        <thead style={{ backgroundColor: "#EE4B46", color: "#fff" }}>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Site Name</th>
                                <th scope="col">Site Code</th>
                                <th scope='col'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {quotationAssigneeList?.map((quo, index) => (
                                <tr key={index}>
                                    <td>{quo.userName}</td>
                                    <td>{quo.siteName}</td>
                                    <td>{quo.siteName}</td>

                                    <td>

                                        <Delete
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setDeleteAssigneeModal(true)
                                                setDelSiteCode(quo.siteCode);
                                                setDelUserName(quo.userName);
                                                setDeleteType('QuotationApproval')
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

            }

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={deleteAssigneeModal}
                onClose={() => setDeleteAssigneeModal(false)}
                closeAfterTransition
            >
                <Fade in={deleteAssigneeModal}>
                    <div className="paper">
                        <h6>Are you sure you want to delete this Assignee ?</h6>
                        <br />
                        <div className="row container-fluid justify-content-center">
                            <div className="col-6 text-right">
                                <button
                                    className="modal__btns"
                                    onClick={()=>deleteAssignee(deleteType)}
                                >
                                    Yes
                                </button>
                            </div>
                            <div className="col-6 text-left">
                                <button
                                    className="modal__btns"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setDeleteAssigneeModal(false);
                                    }}
                                    style={{
                                        backgroundColor: "white",
                                        color: "black",
                                    }}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>

        </div>
    )
}

export default Sales