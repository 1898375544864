import { Paper } from '@mui/material';
import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { Modal, Fade } from "@mui/material";
import { MoonLoader } from "react-spinners";

const AgreementFinalVersion = ({ unitHandover, setUnitHandover }) => {

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const [loader, setLoader] = useState(false)
    const [uploadDate, setUploadDate] = useState(new Date())
    const [file, setFile] = useState(null)

    const uploadAgreementFinalVersion = (e) => {

        e.preventDefault();

        if (userInfo?.userRole != "Director" && userInfo?.userRole != "Operations") {
            return Swal.fire({
                icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
                text: "Only Director or Operations Department can perform this action",
            })
        }

        setLoader(true);

        let formData = new FormData();
        formData.append("file", file);
        // formData.append("uploadDate", uploadDate);
        formData.append("uploadedByUserFullName", userInfo.userFullName);
        formData.append("uploadedByUserName", userInfo.userName);
        formData.append("handoverId", unitHandover?.handoverId);

        axios
            .put(
                `${BASE_URL}/api/v1/CustomerMgmt/handover/agreementFinalVersion`,
                formData,
                {
                    headers: { Authorization: `bearer ${userInfo.token}` },
                }
            )
            .then((response) => {
                setLoader(false);
                if (response.data.status) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                    }).then(function () {
                        setUnitHandover(response.data.handover);
                    });
                }
            })
            .catch((err) => {
                setLoader(false);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error,
                });
            });

    }

    const dateFormatting = (input) => {

        if (!input) {
            return 'N/A'
        }
        // console.log(input)
        let d = new Date(input)
        // console.log(d)

        let date = d.getDate()
        if (date < 10) {
            date = `0${date}`
        }
        let m = d.getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = d.getFullYear()

        let final;

        final = `${date}/${m}/${y}`
        return final

    }

    return (
        <>
            <Paper elevation={1}
                style={{
                    padding: '30px 20px',
                    width: '90%',
                    margin: 'auto'
                }}>
                <div className="row mb-3 mx-2 card-1">
                    <div className="col-12 tab-card pt-3 pb-3 text-center">
                        {!unitHandover?.agreementFinalVersion?.agreementFinalVersionLink ? (
                            (userInfo.userRole !== "Operations" &&
                                userInfo.userRole !== "Director") ? (
                                <h5
                                    style={{
                                        paddingRight: "10px",
                                        marginRight: "5px",
                                        fontSize: "22px",
                                        fontWeight: "bold",
                                        paddingTop: "5px",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    Registration Deed Draft Final Version Not Uploaded
                                </h5>
                            ) : (
                                <>
                                    <h4
                                        style={{
                                            paddingRight: "10px",
                                            marginRight: "5px",
                                            fontSize: "22px",
                                            fontWeight: "bold",
                                            paddingTop: "5px",
                                            paddingLeft: "10px",
                                        }}
                                    >
                                        Registration Deed Draft - Final Version
                                    </h4>
                                    <br />
                                    <form onSubmit={(e) => uploadAgreementFinalVersion(e)}>
                                        <div
                                            style={{ display: "flex", alignItems: "center" }}
                                            className="row justify-content-center"
                                        >
                                            <div className="col-6">
                                                <input
                                                    type="file"
                                                    id="myfile"
                                                    name="myfile"
                                                    required
                                                    onChange={(e) =>
                                                        setFile(e.target.files[0])
                                                    }
                                                    style={{
                                                        backgroundColor: "white",
                                                        color: "black",
                                                    }}
                                                />
                                            </div>

                                            {/*<div className="col-4">
                                                <label>Upload Date</label>
                                                <input
                                                    type="date"
                                                    required
                                                    onChange={(e) =>
                                                        setUploadDate(e.target.value)
                                                    }
                                                    className="form-control"
                                                />

                                                </div>*/}
                                                <div className="col-4">
                                                    <button
                                                        // className="btn"
                                                        type="submit"
                                                    >
                                                        Upload
                                                    </button>
                                                </div>
                                        </div>

                                        
                                        
                                    </form>
                                </>
                            )
                        ) : (
                            <>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <p
                                        style={{
                                            paddingRight: "10px",
                                            marginRight: "5px",
                                            fontSize: "25px",
                                            fontWeight: "bold",
                                            paddingTop: "5px",
                                            paddingLeft: "10px",
                                        }}
                                    >
                                        Registration Deed Draft - Final Version
                                    </p>
                                    <br />
                                    <div>
                                        <p>Final Draft {' '}: {''}<b><a href={unitHandover?.agreementFinalVersion?.agreementFinalVersionLink}>Download</a></b></p>
                                        <p>Upload Date {' '}:{' '}<b>{dateFormatting(unitHandover?.agreementFinalVersion?.uploadedDate)}</b></p>
                                        <p>Uploaded By {' '}:{' '}<b>{unitHandover?.agreementFinalVersion?.uploadedByUserFullName}</b></p>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>

            </Paper>

            {/* //////////// Modal for spinner //////////// */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={loader}
                onClose={() => {
                    setLoader(false);
                }}
                closeAfterTransition
            >
                <Fade in={loader}>
                    <div>
                        <MoonLoader
                            color="red"
                            className="spinner"
                            loading={loader}
                            size={70}
                        />
                    </div>
                </Fade>
            </Modal>

        </>
    )
}

export default AgreementFinalVersion