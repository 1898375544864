import { useContext, useState } from 'react';
import { Context } from '../../utils/Context';
import Nav from 'react-bootstrap/Nav';
import './UnitTab.scss';

const UnitTab = ({ tabName }) => {

  const [tab, setTab] = useState(localStorage.getItem('ActiveKeyUnit'))

  return (
    <div className="unit-tabs">
      <Nav>

        <Nav.Item
          onClick={() => {
            localStorage.setItem("ActiveKeyUnit", "first");
            setTab('first')
            tabName('first')
          }}
        >
          <Nav.Link className={tab === 'first' ? 'active' : 'inactive'} eventKey="first">
            Unit Details
          </Nav.Link>
        </Nav.Item>

        <Nav.Item
          onClick={() => {
            localStorage.setItem("ActiveKeyUnit", "second");
            setTab('second')
            tabName('second')
          }}
        >
          <Nav.Link className={tab === 'second' ? 'active' : 'inactive'} eventKey="second">
            Unit Pricing
          </Nav.Link>
        </Nav.Item>

        <Nav.Item
          onClick={() => {
            localStorage.setItem("ActiveKeyUnit", "third");
            setTab('third')
            tabName('third')
          }}
        >
          <Nav.Link className={tab === 'third' ? 'active' : 'inactive'} eventKey="third">
            Unit Photos
          </Nav.Link>
        </Nav.Item>

        <Nav.Item
          onClick={() => {
            localStorage.setItem("ActiveKeyUnit", "fourth");
            setTab('fourth')
            tabName('fourth')
          }}
        >
          <Nav.Link className={tab === 'fourth' ? 'active' : 'inactive'} eventKey="fourth">
            Unit Inspection
          </Nav.Link>
        </Nav.Item>

        <Nav.Item
          onClick={() => {
            localStorage.setItem("ActiveKeyUnit", "fifth");
            setTab('fifth')
            tabName('fifth')
          }}
        >
          <Nav.Link className={tab === 'fifth' ? 'active' : 'inactive'} eventKey="fifth">
            Unit Handover
          </Nav.Link>
        </Nav.Item>


      </Nav>

    </div>
  )
}

export default UnitTab