import React from 'react'

const PayslipDetails = ({ paySlip }) => {
    return (
        <div className='approvedPaySlip__payslip-details'>
            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Employee Full Name</label>
                    <input type='text' className='form-control' readOnly value={paySlip?.employeeFullName} />
                </div>

                <div className='col-6'>
                    <label>Employee Id</label>
                    <input type='text' className='form-control' readOnly value={paySlip?.employeeId} />
                </div>
            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Payslip Number</label>
                    <input type='text' className='form-control' readOnly value={paySlip?.payslipNumber} />
                </div>

                <div className='col-6'>
                    <label>Gross Salary</label>
                    <input type='number' className='form-control' readOnly value={paySlip?.grossSalary} />
                </div>
            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Total Deduction</label>
                    <input type='number' className='form-control' readOnly value={paySlip?.totalDeduction} />
                </div>

                <div className='col-6'>
                    <label>Net Salary</label>
                    <input type='text' className='form-control' readOnly value={paySlip?.netSalary} />
                </div>
            </div>
        </div>
    )
}

export default PayslipDetails