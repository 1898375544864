import React, { useEffect } from 'react';
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url'
import Swal from 'sweetalert2';
import numWords from 'num-words'


const Details = ({ paySlip }) => {

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))

  const [modal, setModal] = React.useState(false)
  const [type, setType] = React.useState('')
  const [reason, setReason] = React.useState('');


  const handleVerifyAndReject = (e, type, reason) => {

    e.preventDefault()

    let url;
    let body = {
      payslipNumber: paySlip?.payslipNumber,
    }

    if (type === 'HR Verified') {
      url = `${BASE_URL}/api/v1/hrmgmt/payslip/paySlipHRVerified`
      body = {
        ...body,
        verifiedByEmpId: userInfo.employeeId
      }
    }

    if (type === 'Rejected') {
      url = `${BASE_URL}/api/v1/hrmgmt/payslip/rejectPaySlipByHR`
      body = {
        ...body,
        rejectedByEmpId: userInfo.employeeId,
        rejectionReason: reason
      }
    }

    axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    })
      .then((response) => {

        setModal(false)
        setType('')
        if (response.data.status === true) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          })
            .then(() => {
              window.location.href = '/payRoll'
            })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: response.data.message,
          })
        }

      })
      .catch((err) => {
        // console.log(err)
        setModal(false)
        setType('')
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.error,
        })
      })

  }

  return (
    <div className='generatedPaySlip__details'>

      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px", width: '90%', margin: 'auto' }}>

        <table>

          <thead>
            <tr>
              <th className='generatedPaySlip__details__head'
                style={{ width: '50%' }}
              >Salary Components</th>
              <th className='generatedPaySlip__details__head'
                style={{ width: '50%' }}
              >Deductions</th>
            </tr>
          </thead>

        </table>

        <div className='generatedPaySlip__details__data'>
          {/* left */}
          <div className='generatedPaySlip__details__data-left'>

            <div>
              <span>Basic Salary</span>
              <span>{paySlip?.monthlyBasic}</span>
            </div>

            <div>
              <span>Monthly HRA</span>
              <span>{paySlip?.monthlyHRA}</span>
            </div>

            <div>
              <span>Personal Allowance</span>
              <span>{paySlip?.monthlyPersonalAllowance}</span>
            </div>

            <div>
              <span>Travel Allowance</span>
              <span>{paySlip?.monthlyTravelAllowance}</span>
            </div>

            <div>
              <span>Meal Allowance</span>
              <span>{paySlip?.monthlyMealAllowance}</span>
            </div>

            <div>
              <span>Phone & Data Allowance</span>
              <span>{paySlip?.monthlyPhoneAndDataAllowance}</span>
            </div>

            <div>
              <span>Uniform & Laundry Allowance</span>
              <span>{paySlip?.monthlyUniformNLaundryAllowance}</span>
            </div>

            <div>
              <span>Annual Festival Bonus</span>
              <span>{paySlip?.annualFestivalBonus}</span>
            </div>

            <div>
              <span>Performance Bonus</span>
              <span>{paySlip?.monthlyPerformanceBonus}</span>
            </div>

            <div>
              <span>Total Incentives</span>
              <span>{paySlip?.totalIncentive}</span>
            </div>

            <div className='total-div'>
              <span
                className='total'
              >Gross Salary</span>
              <span
                className='total'
              >{paySlip?.grossSalary}</span>
            </div>

          </div>

          {/* right */}
          <div className='generatedPaySlip__details__data-right'>

            <div>
              <span>Monthly HRA Deduction</span>
              <span>{paySlip?.monthlyHRADeduction}</span>
            </div>

            <div>
              <span>Professional Tax</span>
              <span>{paySlip?.professionalTaxDeduction}</span>
            </div>

            <div>
              <span>Gap Deduction</span>
              <span>{paySlip?.gapDeduction}</span>
            </div>

            <div>
              <span>Late Deduction</span>
              <span>{paySlip?.lateDeduction}</span>
            </div>

            <div>
              <span>Absent Deduction</span>
              <span>{paySlip?.absentDeduction}</span>
            </div>

            <div>
              <span>Monthly Provident Fund Deduction</span>
              <span>{paySlip?.monthlyProvidentFundDeduction}</span>
            </div>

            <div>
              <span>Monthly Gratuity</span>
              <span>{paySlip?.monthlyGratuity}</span>
            </div>

            <div>
              <span>Salary Advance Deduction</span>
              <span>{paySlip?.salaryAdvanceDeduction}</span>
            </div>

            <div>
              <span>Special Deduction</span>
              <span>{paySlip?.specialDeduction?.amount}</span>
            </div>

            <div className='total-div'>
              <span className='total'>Total Deduction</span>
              <span className='total'>{paySlip?.totalDeduction}</span>
            </div>

          </div>

        </div>

        {/* total */}
        <div className='d-flex net-salary'>
          <span>Net Salary : </span>
          <span>INR {paySlip?.netSalary}</span>
        </div>
        <p className='net-salary pt-0'><span>{`( INR  ${paySlip?.netSalary < 0 ? 'negative' : ''} ${numWords(Math.abs(paySlip?.netSalary))})`}</span></p>

        <div className='row justify-content-center'>
          <div className='col-6 text-center'>
            <button className='generatedPaySlip__btn'
              disabled={paySlip?.payslipStatus === 'HR Verified'}
              style={{
                backgroundColor: paySlip?.payslipStatus === 'HR Verified' ? 'grey' : '',
              }}
              onClick={() => {
                setModal(true)
                setType('HR Verified')
              }}
            >HR Verified</button>
          </div>

          <div className='col-6 text-center'>
            <button className='generatedPaySlip__btn'
              disabled={paySlip?.payslipStatus === 'Rejected'}
              style={{
                backgroundColor: paySlip?.payslipStatus === 'Rejected' ? 'grey' : '',
              }}
              onClick={() => {
                setModal(true)
                setType('Rejected')
              }}
            >Rejected</button>
          </div>
        </div>

      </Paper>

      <br />
      <br />

      <Paper elevation={2} style={{ padding: "20px", margin: 'auto', width: '90%' }}>

        {

          paySlip?.incentives?.length === 0
            ?
            <h4 className='text-center'>Special Incentives : NULL</h4>
            :

            <>

              <h4 className='text-center'>Special Incentives</h4>

              {paySlip?.incentives?.map((item, index) => {

                return <div className='row justify-content-center mt-4' key={index}>
                  <div className='col-7 text-center'>
                    <input type="text" placeholder='description'
                      className='form-control'
                      value={item?.description}
                      readOnly
                    // onChange={(e) => handleChange(e, index, 'description')}
                    />
                  </div>

                  <div className='col-3 text-center'>
                    <input type="number" placeholder='Amount'
                      className='form-control'
                      value={item?.amount}
                      readOnly
                    // onChange={(e) => handleChange(e, index, 'amount')}
                    />
                  </div>

                </div>
              })}

            </>

        }


      </Paper>

      <br />
      <br />

      <Paper elevation={2} style={{ padding: "20px", margin: 'auto', width: '90%' }}>

        {
          !paySlip?.specialDeduction?.description
            ?
            <h4 className='text-center'>Special Deduction : NULL</h4>
            :
            <>

              <h4 className='text-center'>Special Deductions</h4>


              <div className='row justify-content-center mt-4'>
                <div className='col-7'>
                  <label>Description</label>
                  <textarea type="text"
                    className='form-control'
                    value={paySlip?.specialDeduction?.description}
                    readOnly
                  />
                </div>

                <div className='col-3'>
                  <label>Amount</label>
                  <input type="number"
                    className='form-control'
                    value={paySlip?.specialDeduction?.amount}
                    readOnly
                  />
                </div>
              </div>


            </>
        }

      </Paper>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={() => {
          setModal(false)
          setType('')
        }}
        closeAfterTransition
      >
        <Fade in={modal}>
          <div className="paper">
            <form
              onSubmit={(e) => {
                handleVerifyAndReject(e, type, reason)
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >

              <div className="row container-fluid justify-content-center mb-2">
                <div className='col-12'>
                  <h4>Are you sure you want to {type === 'HR Verified' ? 'Verify' : 'Reject'} this Payslip?</h4>
                </div>
              </div>

              {
                type === 'Rejected'
                &&
                <div className="row container-fluid justify-content-center mb-4">
                  <div className='col-12'>
                    <label>Rejection Reason</label>
                    <input type="text" required value={reason}
                      className='form-control'
                      onChange={(e) => setReason(e.target.value)} />
                  </div>
                </div>
              }

              <div className="row container-fluid justify-content-center">
                <div className="col-6 text-center">
                  <button className="modal__btns" type="submit">
                    Confirm
                  </button>
                </div>

                <div className="col-6 text-center">
                  <button className="modal__btns" type="click"
                    onClick={(e) => {
                      e.preventDefault()
                      setModal(false)
                      setType('')
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>

            </form>
          </div>
        </Fade>
      </Modal>

    </div>
  )
}

export default Details