import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import "./Warehouse.scss";
import MaterialTable, { MTableToolbar } from "material-table";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";

const Warehouse = () => {
    const navigate=useNavigate();
    //fetch data
    const [warehouse, setWarehouse] = useState([]);
    //filter category
    const [supervisor, setSupervisor] = useState('');
    const [isActive, setIsActive] = useState('');

    //filter list data
    const [listSupervisor, setListSupervisor] = useState([]);


    //get the userinfo
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    const handleReset = () => {
        setSupervisor('');
        setIsActive('');
        getListOfWarehouse();
    }


    useEffect(() => {
        getListOfWarehouse();
        getListOfEmployee();
    }, []);

   

    const getListOfWarehouse = async () => {
        const { data } = await axios.get(`${BASE_URL}/api/v1/stockmgmtapi/warehouse/getListOfWarehouse`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        });
        setWarehouse(data.WareHouse)
    }

    const getListOfEmployee = async () => {
        const { data } = await axios.get(`${BASE_URL}/api/v1/hrmgmt/Employee/getListOfEmployee?isActive=true`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        });
        data.employees.map(d => {
            setListSupervisor(prev => [...prev, { employeeId: d.employeeId, employeeFullName: d.employeeFullName }]);
        })

    }

    const handleFilterChange = async () => {

        const { data } = await axios.get(`${BASE_URL}/api/v1/stockmgmtapi/warehouse/getListOfWarehouse?isActive=${isActive}&whSupervisorEmpId=${supervisor}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        });
        setWarehouse(data.WareHouse);
    }
    useEffect(() => {
        handleFilterChange();
    }, [supervisor,isActive]);


    console.log(warehouse);

    return (
        <div className='warehouse'>
            <div className='warehouse__buttons'>
                <Button navigateTo={'/engineering/stockManagement'} />
            </div>
            <br />
            <br />
            <div className="heading__container">
                <h4>Ware House</h4>
               <button className="create__warehouse__btn" onClick={()=>navigate('create')}>Create Warehouse</button>
           </div>
            <MaterialTable
                title=''
                data={warehouse}
                columns={
                    [
                        { title: 'Warehouse ID', field: 'WHCode', render: (rowData) => <Link to={rowData.WHCode}>{rowData.WHCode}</Link> },

                        { title: 'Name', field: 'wareHouseName' },
                        { title: 'IsActive', field: 'isActive' },
                        { title: 'Created By', field: 'createdByFullName' },
                        {
                            title: 'Creation Date',
                            render: (rowData) => {
                                return !rowData.createdDate ? "" : rowData.createdDate.substring(8, 10) + "-" + rowData.createdDate.substring(5, 7) + "-" + rowData.createdDate.substring(0, 4)
                            },

                            customSort: (a, b) => (a.createdDate < b.createdDate ? -1 : 1),
                            defaultSort: 'desc'
                        },
                        { title: 'Superviser', field: 'whSupervisorFullName' },
                        { title: 'Site Engineer', field: 'siteEngineerFullName' },
                    ]
                }
                options={{
                    search: window.innerWidth < 700 ? false : true,
                    searchFieldStyle: {
                        width: 200
                    },
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#EE4B46',
                        color: '#fff',
                    },
                    sorting: true
                }}
                components={{
                    Toolbar: props => (
                        <div style={{ position: 'relative', margin: '1rem 0' }}>
                            <MTableToolbar {...props} />
                            <div className='filter__container'>
                                <FormControl variant="standard"
                                    style={{ width: '10rem' }}>
                                    <InputLabel id="demo-simple-select-standard-label">
                                        Supervisor
                                    </InputLabel>
                                    <Select
                                        id="demo-simple-select-standard"
                                        value={supervisor}
                                        onChange={(e) => {
                                            setSupervisor(e.target.value);
                                        }}
                                        className={""}
                                        inputProps={{ "aria-label": "Without label" }}
                                    >
                                        <MenuItem value="">
                                            Supervisor
                                        </MenuItem>
                                        {
                                            listSupervisor &&
                                            listSupervisor.map(list => <MenuItem key={list.employeeId} value={list.employeeId}>{list.employeeFullName}</MenuItem>)
                                        }
                                    </Select>

                                </FormControl>
                                <FormControl variant="standard"
                                    style={{ width: '10rem' }}>
                                    <InputLabel id="demo-simple-select-standard-label">
                                        Is Active
                                    </InputLabel>
                                    <Select
                                        id="demo-simple-select-standard"
                                        value={isActive}
                                        onChange={(e) => {
                                            setIsActive(e.target.value);
                                        }}
                                        className={""}
                                        inputProps={{ "aria-label": "Without label" }}
                                    >
                                        <MenuItem value="">
                                            Is Active
                                        </MenuItem>
                                        <MenuItem value={"true"}>True</MenuItem>
                                        <MenuItem value={"false"}>False</MenuItem>
                                    </Select>

                                </FormControl>
                                <FormControl style={{ marginTop: '.5rem' }}
                                >
                                    <button
                                        onClick={handleReset}
                                        className='resetBtn'
                                    >
                                        Reset Filter
                                    </button>
                                </FormControl>
                            </div>
                        </div>
                    )
                }}
            >

            </MaterialTable>
        </div>
    )
}

export default Warehouse