import { Switch, Paper } from '@mui/material'
import React, { useEffect } from 'react'
import Swal from 'sweetalert2';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { Modal, Fade } from "@mui/material";
import { MoonLoader } from "react-spinners";

const ReceiveFinalDDFromBank = ({ unitHandover, setUnitHandover }) => {

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const [loader, setLoader] = React.useState(false);
    const [receivedFromBankDate, setReceivedFromBankDate] = React.useState('')

    useEffect(() => {
        setReceivedFromBankDate(unitHandover?.ddDetails?.receivedFromBankDate)
    }, [unitHandover])


    const receiveFinalDD = (e) => {

        e.preventDefault();

        if (!receivedFromBankDate) return;

        setLoader(true);


        axios
            .put(
                `${BASE_URL}/api/v1/CustomerMgmt/handover/receiveFinalDD`,
                {
                    handoverId: unitHandover?.handoverId,
                    receivedFromBankDate: receivedFromBankDate,
                    receivedFromBankByUserName: userInfo?.userName,
                    receivedFromBankByUserFullName: userInfo?.userFullName
                },
                {
                    headers: { Authorization: `bearer ${userInfo.token}` },
                }
            )
            .then((response) => {
                setLoader(false);

                if (response.data.status) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                    }).then(function () {
                        setUnitHandover(response.data.handover);
                        window.location.reload();
                    });
                }
            })
            .catch((err) => {
                setLoader(false);

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error || err.response.data.message,
                });
            });

    }
    // console.log(unitHandover)

    const dateFormatting = (input) => {

        if (!input) {
            return 'N/A'
        }
        // console.log(input)
        let d = new Date(input)
        // console.log(d)

        let date = d.getDate()
        if (date < 10) {
            date = `0${date}`
        }
        let m = d.getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = d.getFullYear()

        let final;

        final = `${date}/${m}/${y}`
        return final

    }


    return (
        <>
            <Paper elevation={1}
                style={{
                    padding: '30px 20px',
                    width: '90%',
                    margin: 'auto'
                }}>
                <div className="row mb-3 mx-2 card-1">
                    <div className="col-12 tab-card pt-3 pb-3 text-center">
                        {!unitHandover?.ddDetails?.receivedFromBank ? (
                            (userInfo.userRole !== "Operations" &&
                                userInfo.userRole !== "Director") ? (
                                <h5
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    Final DD From Bank Pending
                                </h5>
                            ) : (
                                <>
                                    <h4
                                        style={{
                                            textAlign: "center"
                                        }}
                                    >
                                        Final DD From Bank
                                    </h4>

                                    <form
                                        onSubmit={(e) => {
                                            receiveFinalDD(e)
                                        }}
                                    >

                                        <div className='row justify-content-center align-items-center mt-4'>
                                        <div className='col-6'>
                                        <p>Received From Bank On</p>
                                        </div>
                                            <div className='col-6'>
                                                <input type="date" required className='form-control' value={receivedFromBankDate}
                                                    onChange={(e) => setReceivedFromBankDate(e.target.value)} />
                                            </div>
                                        </div>

                                        <div className='row justify-content-center mt-4'>
                                            <div className='col-6'>
                                                <button className='unit-handover__btns' type='submit'>Update</button>
                                            </div>
                                        </div>


                                    </form>


                                </>
                            )
                        ) : (
                            <>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <p
                                        style={{
                                            fontSize: "25px",
                                            fontWeight: "bold",
                                            textAlign: "center",
                                        }}
                                    >
                                        Received Final DD From Bank
                                    </p>
                                    <br />
                                    <div>
                                        <p>Receive Date:{" "}<b>{dateFormatting(unitHandover?.ddDetails?.receivedFromBankDate)}</b> </p>
                                        
                                        <p>Received By:{" "}<b>{unitHandover?.ddDetails?.receivedFromBankByUserFullName}</b></p>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Paper>


            {/* //////////// Modal for spinner //////////// */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={loader}
                onClose={() => {
                    setLoader(false);
                }}
                closeAfterTransition
            >
                <Fade in={loader}>
                    <div>
                        <MoonLoader
                            color="red"
                            className="spinner"
                            loading={loader}
                            size={70}
                        />
                    </div>
                </Fade>
            </Modal>

        </>
    )
}

export default ReceiveFinalDDFromBank;