import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import Swal from 'sweetalert2';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import image from '../../assets/images/image.png'
import doc from '../../assets/images/doc.png'
import customer from '../../assets/images/customer.png'
import westroad from '../../assets/images/westroad.png'

const Details = ({ ticket, setTicket, change, setChange }) => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const [employeeList, setEmployeeList] = useState([])
    const [assignee, setAssignee] = useState('')
    const [status, setStatus] = useState()
    const [resComment, setResComment] = useState('')
    const [attachmentFile, setAttachmentFile] = useState(null)
    const [desc, setDesc] = useState('')
    const [spinnerModal, setSpinnerModal] = useState(false);

    // console.log(ticket)
    const Departments = [
        { id: 1, name: "Sales" },
        { id: 2, name: "Operations" },
        { id: 3, name: "Finance" },
        { id: 4, name: "Engineering" },
        { id: 5, name: "Maintenance" },
    ];

    const Categories = [
        { id: "A", name: "Cost Sheet", departmentName: "Sales" },
        { id: "B", name: "Fittings and Fixtures", departmentName: "Sales" },
        { id: "C", name: "Others", departmentName: "Sales" },
        { id: "D", name: "Allotment Letter", departmentName: "Operations" },
        { id: "E", name: "Sale Agreement", departmentName: "Operations" },
        { id: "F", name: "Demand", departmentName: "Operations" },
        { id: "G", name: "Loan", departmentName: "Operations" },
        { id: "H", name: "Registration", departmentName: "Operations" },
        { id: "I", name: "Alteration", departmentName: "Operations" },
        { id: "J", name: "Possession", departmentName: "Operations" },
        { id: "K", name: "Electric Form", departmentName: "Operations" },
        { id: "L", name: "Others", departmentName: "Operations" },
        { id: "M", name: "Alteration", departmentName: "Engineering" },
        { id: "N", name: "Fittings and Fixtures", departmentName: "Engineering" },
        { id: "O", name: "Billing of Alteration Work", departmentName: "Engineering" },
        { id: "P", name: "Handover/ Possession", departmentName: "Engineering" },
        { id: "Q", name: "Others", departmentName: "Engineering" },
        { id: "R", name: "Demand", departmentName: "Finance" },
        { id: "S", name: "Money Receipt", departmentName: "Finance" },
        { id: "T", name: "Refund", departmentName: "Finance" },
        { id: "U", name: "Cancellation", departmentName: "Finance" },
        { id: "V", name: "Ledger Statement", departmentName: "Finance" },
        { id: "W", name: "Others", departmentName: "Finance" },
        { id: "X", name: "Fittings and Fixtures", departmentName: "Maintenance" },
        { id: "Y", name: "Warranty Claim", departmentName: "Maintenance" },
        { id: "Z", name: "Defect Liability", departmentName: "Maintenance" },
        { id: "AA", name: "Others", departmentName: "Maintenance" },
    ];


    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee`, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        })
            .then((response) => {
                if (response.data.status === true) {
                    setEmployeeList(response.data.employees)
                } else {
                    setEmployeeList([])
                }
            })
    }, [])

    useEffect(() => {
        setStatus(ticket?.status)
    }, [ticket])

    const updateTicket = (e) => {

        e.preventDefault();

        axios.put(`${BASE_URL}/api/v1/customermgmt/ticket/updateTicketByTicketId`,
            {
                ...ticket,
            },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                if (response.data.status === true) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Ticket Status Updated Successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })
                        .then(() => {
                            // setTicket(response.data.ticket)
                            setChange(!change)
                        })
                }
            })
            .catch((err) => {
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: err.response.data.error,
                    showConfirmButton: false,
                    timer: 1500
                })
            })

    }

    const updateTicketStatus = () => {


        let body = {
            ticketId: ticket?.ticketId,
            statusUpdatedByUserName: userInfo?.userName,
            statusUpdatedBy: userInfo?.userFullName,
            status
        }

        if (status === 'Resolved') {
            body.resolutionComment = resComment
        }

        axios.put(`${BASE_URL}/api/v1/customermgmt/ticket/updateTicketStatus`,
            body,
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status === true) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Ticket Status Updated Successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })
                        .then(() => {
                            // setTicket(response.data.ticket)
                            setChange(!change)
                        })
                }
            })
            .catch((err) => {
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: err.response.data.error,
                    showConfirmButton: false,
                    timer: 1500
                })
            })

    }


    const uploadAttachment = () => {

        if (!attachmentFile) return;
        setSpinnerModal(true)

        let formData = new FormData();
        formData.append('file', attachmentFile)
        formData.append('ticketId', ticket.ticketId)
        formData.append('description', desc)
        formData.append('uploadedBy', 'westroad')
        formData.append('uploadedByFullName', userInfo.userFullName)

        axios.put(`${BASE_URL}/api/v1/customermgmt/ticket/uploadResolvedTicketAttachment`, formData,
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response.data)
                setSpinnerModal(false)
                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 1500
                })
                    .then(() => {
                        setChange(!change)
                        setAttachmentFile(null)
                        setDesc('')
                        // setUploadedBy('')
                        document.getElementById('attfile').value = ''
                    })
            })
            .catch((err) => {
                setSpinnerModal(false)
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error,
                })
            })
    }

    const dateFormatting = (input) => {

        let date = new Date(input)
        let d = date.getDate()
        let m = new Date(date.getMonth()).toLocaleDateString('en-IN', { month: 'short' })
        let y = date.getFullYear()

        return `${d}-${m}-${y}`
    }

    const assigneTicket = () => {
        axios.post(`${BASE_URL}/api/v1/customermgmt/ticket/assignticket`,
            {
                ticketId: ticket?.ticketId,
                assignee,
                lastUpdatedBy: userInfo?.userName
            },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status === true) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                        showConfirmButton: false,
                    })
                        .then(() => {
                            // setTicket(response.data.ticket)
                            setChange(!change)
                        })
                }
            })
            .catch((err) => {
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: err.response.data.error,
                    showConfirmButton: false,
                })
            })

    }

    const deleteAttachment = (link) => {

        setSpinnerModal(true)

        axios.put(`${BASE_URL}/api/v1/customermgmt/ticket/deleteResolvedTicketAttachment`, {
            ticketId: ticket.ticketId,
            link: link
        },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                setSpinnerModal(false)
                // console.log(response.data)
                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 1500
                })
                    .then(() => {
                        setChange(!change)
                    })
            })
            .catch((err) => {
                setSpinnerModal(false)
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error,
                })
            })

    }


    return (
        <div className='inv-ticket__details'>

            <form
                className='inv-ticket__details__card'
                onSubmit={(e) => updateTicket(e)}
            >

                <div className="row justify-content-center">
                    <div className="col-4">
                        <label>Site Name</label>
                        <input type="text"
                            className="form-control"
                            readOnly
                            value={ticket?.siteName || ' '}
                        />
                    </div>
                    <div className="col-4">
                        <label>Customer Id</label>
                        <input type="text"
                            className="form-control"
                            readOnly
                            value={ticket?.customerId || ' '}
                        />
                    </div>
                </div>
                <br />
                <div className="row justify-content-center">
                    <div className="col-4">
                        <label>First Name</label>
                        <input type="text" className="form-control" value={ticket?.customerFirstName} disabled />
                    </div>
                    <div className="col-4">
                        <label>Middle Name</label>
                        <input type="text" className="form-control" value={ticket?.customerMiddleName} disabled />
                    </div>
                </div>
                <br />
                <div className="row justify-content-center">
                    <div className="col-4">
                        <label>Last Name</label>
                        <input type="text" className="form-control" value={ticket?.customerLastName} disabled />
                    </div>
                    {/* <div className="col-4">
              <label>Customer ID</label>
              <input type="text" className="form-control" value={customerId} disabled />
            </div> */}
                </div>
                <br />
                <div className="row justify-content-center">
                    <div className="col-4">
                        <label>Unit Name</label>
                        <input type="text" className="form-control" value={ticket?.unitName} disabled />
                    </div>
                    <div className="col-4">
                        <label>Phase Name</label>
                        <input type="text" className="form-control" value={ticket?.phaseName} disabled />
                    </div>
                </div>

                <br />
                <div className="row justify-content-center">
                    <div className="col-4">
                        <label>Ticket Type</label>
                        {
                            userInfo?.userName === ticket?.assignee
                                ?
                                <select
                                    class="form-control"
                                    id="tickettype"
                                    value={ticket?.ticketType}
                                    onChange={(e) => {
                                        setTicket((prev) => ({ ...prev, ticketType: e.target.value }))
                                    }}
                                    required
                                >
                                    <option selected>
                                        ---Select Ticket Type---
                                    </option>
                                    <option value="Complaint">Complaint</option>
                                    <option value="Service Request">Service Request</option>
                                </select>
                                :
                                <input className="form-control"
                                    type='text'
                                    value={ticket?.ticketType}
                                    disabled
                                />
                        }

                    </div>
                    <div className="col-4">
                        <label>Priority</label>
                        {
                            userInfo?.userName === ticket?.assignee
                                ?
                                <select
                                    class="form-control"
                                    id="ticketpriority"
                                    value={ticket?.ticketPriority}
                                    onChange={(e) => {
                                        setTicket((prev) => ({ ...prev, ticketPriority: e.target.value }))
                                    }}
                                    required
                                >
                                    <option selected>
                                        ---Select Ticket Type---
                                    </option>
                                    <option value="High">High</option>
                                    <option value="Normal">Normal</option>
                                </select>
                                :
                                <input className="form-control"
                                    type='text'
                                    value={ticket?.ticketPriority}
                                    disabled
                                />
                        }
                    </div>
                </div>

                <br />
                <div className="row justify-content-center">

                    <div className="col-4">
                        <label>Department</label>
                        {
                            userInfo?.userName === ticket?.assignee
                                ?
                                <select
                                    class="form-control"
                                    id="ticketdept"
                                    value={ticket?.ticketDepartment}
                                    onChange={(e) => {
                                        setTicket((prev) => ({ ...prev, ticketDepartment: e.target.value }))
                                    }}
                                >
                                    <option selected>
                                        ---Select Department---
                                    </option>
                                    <option>Sales</option>
                                    <option>Operations</option>
                                    <option>Finance</option>
                                    <option>Engineering</option>
                                    <option>Maintenance</option>
                                </select>
                                :
                                <input className="form-control" type='text' value={ticket?.ticketDepartment} disabled />
                        }
                    </div>

                    <div className="col-4">
                        <label>Ticket Category</label>
                        {
                            userInfo?.userName === ticket?.assignee
                                ?
                                <select
                                    class="form-control"
                                    id="ticketcat"
                                    value={ticket?.ticketCategory}
                                    onChange={(e) => {
                                        setTicket((prev) => ({ ...prev, ticketCategory: e.target.value }))
                                    }}
                                >
                                    <option selected>
                                        ---Select Ticket Category---
                                    </option>
                                    {Categories.filter((category) => category.departmentName === ticket?.ticketDepartment).map((category) => (
                                        <option key={category.id} value={category.name}>{category.name}</option>
                                    ))}
                                </select>
                                :
                                <input
                                    className="form-control"
                                    type='text'
                                    value={ticket?.ticketCategory}
                                    disabled
                                />
                        }
                    </div>
                    {/*  */}

                </div>
                <br />

                <div className="row justify-content-center">
                    <div className="col-4">
                        <label>Assignee</label>

                        <select
                            class="form-control"
                            id="ticketdept"
                            value={assignee}
                            onChange={(e) => {
                                setAssignee(e.target.value)
                            }}
                        >
                            <option selected>
                                ---Select a Assignee---
                            </option>
                            {
                                employeeList?.map((employee, index) => {
                                    return <option key={index} value={`${employee.userName}`}>{employee?.userName}</option>
                                })
                            }
                        </select>

                    </div>

                    <div className='col-4'>
                        <label>Current Assignee</label>
                        <input type="text" className="form-control" readOnly value={ticket?.assignee} />
                    </div>
                </div>

                <br />

                <div className="row justify-content-center">
                    <div className='col-4 text-center inv-lead__btns'>
                        <button
                            disabled={ticket.assignee != 'Unassigned' && userInfo?.userName != ticket?.assignee}
                            style={{
                                backgroundColor: (ticket.assignee != 'Unassigned' && userInfo?.userName != ticket?.assignee) ? 'grey' : ''
                            }}
                            type='click'
                            onClick={(e) => {
                                e.preventDefault()
                                if (!assignee) return;
                                assigneTicket()
                            }}
                        >Assign</button>
                    </div>
                </div>

                <br />

                <div className="row justify-content-center">
                    <div className="col-8">
                        <label>Description</label>
                        <textarea className="form-control" cols="30" rows="5"
                            value={ticket?.description}
                            onChange={(e) => {
                                setTicket((prev) => ({ ...prev, description: e.target.value }))
                            }}
                            disabled={userInfo?.userName != ticket?.assignee}
                        ></textarea>
                    </div>
                </div>

                <br />
                <div className="row container-fluid justify-content-center">
                    {/* <div className="col-4 text-right">
                        <button className="btn btn-secondary btn-user"
                            type="reset"
                            style={{ backgroundColor: "white", color: "black" }}
                            onClick={resetField}
                        >
                            Reset
                        </button>
                    </div> */}
                    <div className="col-6 text-center">
                        <button className="inv-ticket__btns" type="submit">
                            Update Ticket
                        </button>
                    </div>
                </div>

            </form>

            <div className='inv-ticket__details__statuscard mt-5'>

                <div className='row justify-content-center'>

                    <div className='col-6 text-center'>
                        <select
                            class="form-control"
                            id="ticketstatus"
                            value={status}
                            onChange={(e) => {
                                setStatus(e.target.value)
                            }}
                            required
                        >
                            <option selected>
                                ---Select Ticket Status---
                            </option>
                            <option value="In Queue">In Queue</option>
                            <option value="Assigned">Assigned</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Pending With Customer">Pending With Customer</option>
                            <option value="Resolved">Resolved</option>
                        </select>
                    </div>

                    <div className='col-4 text-center inv-ticket__btns'>
                        <button
                            disabled={ticket?.status === 'Resolved' || (status === 'Resolved' && resComment === '') || userInfo?.userName != ticket?.assignee}
                            style={{ backgroundColor: ticket?.status === 'Resolved' || (status === 'Resolved' && resComment === '') || userInfo?.userName != ticket?.assignee ? 'grey' : '' }}
                            onClick={updateTicketStatus}
                        >Update</button>
                    </div>

                </div>

                <br />

                {
                    status === 'Resolved'
                    &&
                    <div className='row justify-content-center'>
                        <div className='col-8'>
                            <label>Resolution Comment</label>
                            <textarea type="text"
                                className='form-control'
                                rows={4}
                                value={resComment} onChange={(e) => setResComment(e.target.value)} />
                        </div>
                    </div>
                }

            </div>

            {
                ticket?.status === 'Resolved'
                &&
                <>
                    <div className='inv-ticket__attachments__card flex-column'>

                        <h3 className='text-center'
                            style={{ color: "#EE4B46" }}
                        >Upload Attachment</h3>

                        <div className='row justify-content-between'>
                            <div className='col-6 text-center'>
                                <input type="file" name="attachment" id="attfile" onChange={(e) => setAttachmentFile(e.target.files[0])} />
                            </div>

                            <div className='col-6 text-center'>
                                <button
                                    disabled={!attachmentFile || !desc}
                                    style={{ backgroundColor: !attachmentFile || !desc ? 'grey' : '' }}
                                    onClick={uploadAttachment}>Upload</button>
                            </div>
                        </div>
                        <br />
                        <div className='row justify-content-between'>
                            <div className='col-8'>
                                <label>Description</label>
                                <textarea type="text" name="desc" id="desc"
                                    className='form-control'
                                    value={desc}
                                    onChange={(e) => setDesc(e.target.value)} />
                            </div>

                        </div>

                    </div>

                    <br />

                    {ticket?.resolvedTicketAttachment?.length > 0
                        &&
                        <h3 className='text-center'
                            style={{ color: "#EE4B46" }}
                        >Resolved Issue Attachments</h3>
                    }

                    {
                        ticket?.resolvedTicketAttachment?.map((tkt, index) => {
                            return (
                                <div key={index} className='row align-items-center inv-ticket__attachments__card'>

                                    <div className='col-2 text-center'>
                                        <img src={tkt.documentType === 'document' ? doc : image} />
                                    </div>

                                    <div className='col-5 d-flex flex-column justify-content-start'>
                                        <h4>
                                            {
                                                <a href={tkt.attachmentLink}
                                                    style={{ fontWeight: 800, color: 'black' }}
                                                >{tkt?.attachmentLink.split('/')[tkt?.attachmentLink.split('/').length - 1]}</a>
                                            }
                                        </h4>

                                        <div className='d-flex justify-content-between'>
                                            {tkt.uploadDate && <span>{dateFormatting(tkt.uploadDate)}</span>}
                                            {tkt.uploadedBy && <span>Uploaded By : {tkt.uploadedBy}</span>}
                                        </div>

                                        <p className='mt-2'>{tkt?.attachmentDesc}</p>
                                    </div>

                                    {tkt?.uploadedBy
                                        &&
                                        <div className='col-2 text-center'>
                                            <img src={tkt?.uploadedBy === 'customer' ? customer : westroad} />
                                        </div>
                                    }

                                    <div className='col-2 text-center'>
                                        <button
                                            onClick={() => {
                                                // setLink(tkt.attachmentLink)
                                                deleteAttachment(tkt.attachmentLink)

                                            }}
                                        >Delete</button>
                                    </div>

                                </div>
                            )
                        })
                    }
                </>
            }


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={spinnerModal}
                onClose={() => setSpinnerModal(false)}
                closeAfterTransition
            >
                <Fade in={spinnerModal}>

                    <div>
                        <ClipLoader
                            color='#EE4B46'
                            loading={spinnerModal}
                            size={70}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                </Fade>
            </Modal>

        </div>
    )
}

export default Details