import axios from 'axios';
import React, { useEffect, useState , CSSProperties } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { BASE_URL } from '../../utils/config/url';
import { Form } from 'react-bootstrap';
import './EditPaymentTerms.scss';
import '../AddPaymentTerms/AddPaymentTerms.scss';
import Swal from 'sweetalert2';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useStyles } from '../../utils/ModalStyles';
import ClipLoader from 'react-spinners/ClipLoader';

const EditPaymentTerms = () => {

    const classes = useStyles();

    const id = useParams().paymentTermsId;
    const siteCode = useParams().siteCode;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const Token = 'bearer' + " " + userInfo.token
    const navigate = useNavigate();

    const [sname, setSname] = useState([]);
    const [termItems, setTermItems] = useState([])
    const [pc, setPc] = useState('');
    const [spinnerModal, setSpinnerModal] = useState(false);


    useEffect(() => {

        axios
            .get(`${BASE_URL}/api/v1/Configuration/PaymentTerms/getPaymentTermsByPaymentTermsId/${id}`,
                { headers: { Authorization: Token } })
            .then((response) => {
                const p = response.data.paymentTerms;
                //console.log(p)
                setPc(p.phaseCode)
                setSname(p.siteName)
                setTermItems(p.termItems)

            })
    }, [])

    const handleTIChange = (index, event, sn) => {

        const values = [...termItems];
        values[index].serial = sn
        if (event.target.name === "desc") {
            values[index].description = event.target.value;
        }
        else if (event.target.name === "percentage") {
            values[index].percentage = event.target.value;
        }

        setTermItems(values);
    }

    const handleAddTermItem = (index, e) => {
        e.preventDefault()
        const values = [...termItems];
        values.push({ serial: "", description: "", percentage: "" })
        setTermItems(values);

    }

    const insertTI = (index, e) => {
        e.preventDefault()
        const values = [...termItems]
        values.splice(index + 1, 0, { serial: "", description: "", percentage: "" })
        setTermItems(values)
    }

    const deleteTI = (index, e) => {
        e.preventDefault()
        const values = [...termItems];
        values.splice(index, 1)
        setTermItems(values);

    }

    const handleUpdatePaymentTerm = (e) => {
        e.preventDefault()
        // console.log(termItems)
        let sum = 0;
        termItems.forEach((p) => {
            p.percentage = parseInt(p.percentage)
            sum += p.percentage;
        })



        if (sum !== 100) {
            return Swal.fire({
                icon: 'error',
                title: 'Ooops',
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                text: 'Summation of all Term Items must be equal to 100%'
            })
        }

        setSpinnerModal(true);

        axios.
            put(`${BASE_URL}/api/v1/Configuration/PaymentTerms/updatePaymentTermsItems`,
                {
                    paymentTermsId: id,
                    termItems: termItems
                },
                { headers: { Authorization: Token } })

            .then((response) => {
                //console.log(response)
                setSpinnerModal(false)
                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        showClass: {
                            popup: 'animate__animated animate__fadeInDown'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOutUp'
                        },
                        text: `Payment Term successfully added`
                    })
                        .then(() => {
                            // navigate(-1)
                        })
                }
            })
            .catch((error) => {
                setSpinnerModal(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops',
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    },
                    text: error.response.data.error
                })
            })
    }

    return (
        <div className='editpaymentterms'>
            <Button navigateTo={1} />

            <div className="row mt-5 justify-content-center">
                <div className="col-10">
                    <h3>Edit/Update Payment Term</h3>
                </div>
            </div>
            <br />
            <form className="addpaymentterm__form" onSubmit={(e) => handleUpdatePaymentTerm(e)}>
                <div className="row pt-3 justify-content-center">
                    <div className="col-4">
                        <label>Site Name</label>
                        <input className='form-control' value={sname} readOnly />
                    </div>
                    <div className="col-4">
                        <label>Site Code</label>
                        <input className='form-control' value={siteCode} readOnly />
                    </div>
                    <div className="col-4">
                        <label>Phase Code</label>
                        <input className='form-control' value={pc} readOnly />
                    </div>
                </div>
                <br />


                <div className="addpaymentterm__form__table">
                    <div className=" mt-2 container-fluid justify-content-center">
                        <div className='heading'>
                            <h4>
                                Term Items
                            </h4>
                        </div>


                        <br />

                        {
                            termItems.map((termItems, index) => {
                                return (
                                    <div className="row pl-4" key={index}>
                                        <div className="col-9">
                                            <div className="row">
                                                <div className="col-2">
                                                    <label>Serial No.</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="slno"
                                                        id="slno"
                                                        required
                                                        value={index + 1}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-8">
                                                    <label>Description</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="desc"
                                                        id="desc"
                                                        required
                                                        autoComplete='off'
                                                        value={termItems.description}
                                                        onChange={(event) => handleTIChange(index, event, index + 1)}
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <label>Percentage</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="percentage"
                                                        id="percentage"
                                                        required
                                                        value={termItems.percentage}
                                                        onChange={(event) => handleTIChange(index, event, index + 1)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <button className="add-btn mt-4"
                                                onClick={(e) => handleAddTermItem(index, e)}
                                                style={{ display: index === 0 ? "inline-block" : "none" }}
                                            >Add Row</button>
                                            &nbsp;&nbsp;
                                            <button className="add-btn mt-4"
                                                onClick={(e) => insertTI(index, e)}
                                                style={{ display: index === 0 ? "none" : "inline-block" }}>
                                                Insert
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button className="add-btn mt-4"
                                                onClick={(e) => deleteTI(index, e)}
                                                style={{ display: index === 0 ? "none" : "inline-block" }}>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>


                <div className="addpaymentterm__btns" >
                    <button type="submit">Update</button>
                    <br />
                    <p style={{ color: 'red' }}>* Minimum 3 rows required</p>

                </div>

            </form>

            {/* /////////////////Modal for Payment Term spinner /////////// */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={spinnerModal}
                onClose={() => setSpinnerModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={spinnerModal}>

                    <div>
                        <ClipLoader
                            color='#EE4B46'
                            loading={spinnerModal}
                            size={70}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                </Fade>
            </Modal>

        </div>
    )
}

export default EditPaymentTerms