import React from 'react'

const PaySlipDetails = ({ paySlip }) => {

    const dateFormatting = (input) => {

        if (!input) return;

        let date = new Date(input)
        if (date < 10) {
            return `0${date}`
        }

        let month = new Date(input).getMonth() + 1
        if (month < 10) {
            return `0${month}`
        }

        let year = new Date(input).getFullYear()

        return `${date}/${month}/${year}`
    }

    return (
        <div>
            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Rejected By Employee Id</label>
                    <input type='text' className='form-control' value={paySlip?.rejectedByEmpId} readOnly />
                </div>

                <div className='col-6'>
                    <label>Rejected By Full Name</label>
                    <input type='text' className='form-control' value={paySlip?.rejectedByFullName} readOnly />
                </div>
            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Rejection Date</label>
                    <input type='text' className='form-control' value={dateFormatting(paySlip?.rejectedDate)} readOnly />
                </div>

                <div className='col-6'>
                    <label>Rejection Reason</label>
                    <textarea type='text' className='form-control' value={paySlip?.rejectionReason} readOnly />
                </div>
            </div>

        </div>
    )
}

export default PaySlipDetails