import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import "./ChangeApplicantOperation.scss";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import { useStyles } from "../../utils/ModalStyles";
import Button from '../../components/Button/Button';


const ChangeApplicantOperation = () => {
  const classes = useStyles();

  const applicationId = useParams().applicationId;
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + userInfo.token;

  const [applicants, setApplicants] = useState([]);
  const [fname, setFname] = useState("");
  const [data, setData] = useState({});
  const [applicantId, setApplicantId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/getBookingApplicationByApplicationId/${applicationId}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        // console.log(response.data.BookingApplication)
        setData(response.data.BookingApplication);
      });
  }, []);

  useEffect(() => {
    getListOfApplicants();
  }, []);

  const getListOfApplicants = () => {
    axios
      .get(
        `${BASE_URL}/api/v1/CustomerMgmt/Applicant/getListofApplicants?applicationId=${applicationId}`,
        { headers: { Authrorization: Token } }
      )
      .then((response) => {
        const a = response.data.applicantList;
        // console.log(a)
        setApplicants(response.data.applicantList);
        setFname(a.firstName);
      });
  };

  const editApplicant = (id, e) => {
    e.preventDefault();

    let body;
    applicants.forEach((app) => {
      if (app.applicantId === id) body = app;
    });

    axios
      .put(
        `${BASE_URL}/api/v1/CustomerMgmt/Applicant/updateApplicantByApplicantId`,
        body,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getListOfApplicants();
            // window.location.reload();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.error,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const deleteApplicant = (e) => {
    e.preventDefault();

    axios
      .delete(
        `${BASE_URL}/api/v1/CustomerMgmt/Applicant/deleteApplicant?applicantId=${applicantId}&applicationId=${applicationId}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setDeleteModal(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getListOfApplicants();
            setApplicantId("");
            // window.location.reload();
          });
        }
      })
      .catch((err) => {
        setDeleteModal(false);
        setApplicantId("");

        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  return (
    <div className="ViewApplicantOperation">

    <Button navigateTo={`/flatAllotment/manageapplications/${applicationId}`} />
      <br />
      <div className="row justify-content-center">
        <button
          className={
            data?.status === "Handed Over to Operations" && applicants.length < 3
              ? "ViewApplicantOperation__form__btn"
              : "ViewApplicantOperation__disabled"
          }
          onClick={() =>
            navigate(
              `../addApplicantOperation/AddApplicant`
            )
          }
          disabled={
            data?.status !== "Handed Over to Operations" || applicants.length >= 3
              ? true
              : false
          }
        >
          Add Applicant
        </button>
      </div>
      {/* // */}
      <br />
      {applicants?.map((a, index) => {
        let ad = a.applicantAddress;
        let cd = a.correspondentAddress;

        return (
          <div className="ViewApplicantOperation__form" key={index}>
            <form>
              <br />
              <div className="row justify-content-center">
                <div className="col-4">
                  <label>Application ID</label>
                  <input
                    type="text"
                    className="form-control"
                    value={a.applicationId}
                    readOnly
                  />
                </div>
                <div className="col-4">
                  <label>Applicant ID</label>
                  <input
                    type="text"
                    className="form-control"
                    value={a.applicantId}
                    readOnly
                  />
                </div>
                <div className="col-4">
                  <label>Applicant Type</label>
                  <input
                    type="text"
                    className="form-control"
                    value={a.applicantType}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-3">
                  <Form.Group controlId="salutation">
                    <Form.Label>Salutation</Form.Label>
                    <Form.Control
                      as="select"
                      value={a.salutation}
                      required
                      onChange={(e) => {
                        setApplicants((prev) => {
                          return prev.map((ap) => {
                            if (ap.applicationId === a.applicationId) {
                              return Object.assign(ap, {
                                salutation: e.target.value,
                              });
                            }
                          });
                        });
                      }}
                    >
                      <option disabled>Select a Salutation</option>
                      <option value="Mr.">Mr.</option>
                      <option value="Mrs.">Mrs.</option>
                      <option value="Ms.">Ms.</option>
                      <option value="Dr.">Dr.</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-3">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    id="firstName"
                    required
                    value={a.firstName}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicationId === a.applicationId) {
                            return Object.assign(ap, {
                              firstName: e.target.value,
                            });
                          }
                        });
                      });
                    }}
                  />
                </div>
                <div className="col-3">
                  <label>Middle Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="middleName"
                    id="middleName"
                    value={a.middleName}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicationId === a.applicationId) {
                            return Object.assign(ap, {
                              middleName: e.target.value,
                            });
                          }
                        });
                      });
                    }}
                  />
                </div>
                <div className="col-3">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    id="lastName"
                    required
                    value={a.lastName}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicationId === a.applicationId) {
                            return Object.assign(ap, {
                              lastName: e.target.value,
                            });
                          }
                        });
                      });
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-4">
                  <label>Spouse Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="sname"
                    id="sname"
                    required
                    value={a.spouseName}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicationId === a.applicationId) {
                            return Object.assign(ap, {
                              spouseName: e.target.value,
                            });
                          }
                        });
                      });
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Father's Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="fname"
                    id="fname"
                    required
                    value={a.fatherName}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicationId === a.applicationId) {
                            return Object.assign(ap, {
                              fatherName: e.target.value,
                            });
                          }
                        });
                      });
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Religion</label>
                  <input
                    type="text"
                    className="form-control"
                    name="religion"
                    id="religion"
                    required
                    value={a.religion}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicationId === a.applicationId) {
                            return Object.assign(ap, {
                              religion: e.target.value,
                            });
                          }
                        });
                      });
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-4">
                  <Form.Group controlId="gender">
                    <Form.Label>Gender</Form.Label>
                    <Form.Control
                      as="select"
                      required
                      value={a.applicantGender}
                      onChange={(e) => {
                        setApplicants((prev) => {
                          return prev.map((ap) => {
                            if (ap.applicationId === a.applicationId) {
                              return Object.assign(ap, {
                                applicantGender: e.target.value,
                              });
                            }
                          });
                        });
                      }}
                    >
                      <option value="">Select a Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-4">
                  <label>Nationality</label>
                  <input
                    type="text"
                    className="form-control"
                    name="nationality"
                    id="nationality"
                    required
                    value={a.nationality}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicationId === a.applicationId) {
                            return Object.assign(ap, {
                              nationality: e.target.value,
                            });
                          }
                        });
                      });
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Mobile</label>
                  <input
                    type="number"
                    className="form-control"
                    name="mobile"
                    id="mobile"
                    required
                    value={a.applicantMobile}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicationId === a.applicationId) {
                            return Object.assign(ap, {
                              applicantMobile: e.target.value,
                            });
                          }
                        });
                      });
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-4">
                  <label>Whatsapp</label>
                  <input
                    type="number"
                    className="form-control"
                    name="whatsapp"
                    id="whatsapp"
                    value={a.applicantWhatsapp}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicationId === a.applicationId) {
                            return Object.assign(ap, {
                              applicantWhatsapp: e.target.value,
                            });
                          }
                        });
                      });
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    required
                    value={a.applicantEmail}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicationId === a.applicationId) {
                            return Object.assign(ap, {
                              applicantEmail: e.target.value,
                            });
                          }
                        });
                      });
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Aadhar</label>
                  <input
                    type="number"
                    className="form-control"
                    name="aa"
                    id="aa"
                    required
                    value={a.applicantAadhar}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicationId === a.applicationId) {
                            return Object.assign(ap, {
                              applicantAadhar: e.target.value,
                            });
                          }
                        });
                      });
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-4">
                  <label>PAN</label>
                  <input
                    type="text"
                    className="form-control"
                    name="pan"
                    id="pan"
                    required
                    value={a.applicantPAN}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicationId === a.applicationId) {
                            return Object.assign(ap, {
                              applicantPAN: e.target.value,
                            });
                          }
                        });
                      });
                    }}
                  />
                </div>
                <div className="col-4">
                  <Form.Group controlId="gender">
                    <Form.Label>Occupation Type</Form.Label>
                    <Form.Control
                      as="select"
                      required
                      value={a.occupationType}
                      onChange={(e) => {
                        setApplicants((prev) => {
                          return prev.map((ap) => {
                            if (ap.applicationId === a.applicationId) {
                              return Object.assign(ap, {
                                occupationType: e.target.value,
                              });
                            }
                          });
                        });
                      }}
                    >
                      <option value="">Select an Occupation Type</option>
                      <option value="Salaried">Salaried</option>
                      <option value="Self Employed">Self Employed</option>
                      <option value="Business">Business</option>
                      <option value="Other">Other</option>
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-4">
                  <label>Occupation</label>
                  <input
                    type="text"
                    className="form-control"
                    name="oc"
                    id="oc"
                    required
                    value={a.occupation}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicationId === a.applicationId) {
                            return Object.assign(ap, {
                              occupation: e.target.value,
                            });
                          }
                        });
                      });
                    }}
                  />
                </div>
              </div>
              <br />

              <div className="row justify-content-center">
                <div className="col-12">
                  <h4>Applicant Address</h4>
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-6">
                  <label>Full Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="fa1"
                    id="fa1"
                    required
                    value={ad?.fullAddress}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicantId === a.applicantId) {
                            let applicantAddress = {
                              ...ap.correspondentAddress,
                              fullAddress: e.target.value,
                            };

                            return Object.assign(ap, {
                              applicantAddress,
                            });
                          } else return ap;
                        });
                      });
                    }}
                  />
                </div>
                <div className="col-6">
                  <label>Landmark</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lm1"
                    id="lm1"
                    required
                    value={ad?.landmark}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicantId === a.applicantId) {
                            let applicantAddress = {
                              ...ap.correspondentAddress,
                              landmark: e.target.value,
                            };

                            return Object.assign(ap, {
                              applicantAddress,
                            });
                          } else {
                            return ap;
                          }
                        });
                      });
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-4">
                  <label>City/Town/Village</label>
                  <input
                    type="text"
                    className="form-control"
                    name="ct1"
                    id="ct1"
                    required
                    value={ad?.city}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap?.applicantId === a.applicantId) {
                            let applicantAddress = {
                              ...ap.correspondentAddress,
                              city: e.target.value,
                            };

                            return Object.assign(ap, {
                              applicantAddress,
                            });
                          } else return ap;
                        });
                      });
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Pincode</label>
                  <input
                    type="number"
                    className="form-control"
                    name="pc1"
                    id="pc1"
                    required
                    value={ad?.pinCode}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicantId === a.applicantId) {
                            let applicantAddress = {
                              ...ap.correspondentAddress,
                              pinCode: e.target.value,
                            };

                            return Object.assign(ap, {
                              applicantAddress,
                            });
                          } else return ap;
                        });
                      });
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>State</label>
                  <input
                    type="text"
                    className="form-control"
                    name="st1"
                    id="st1"
                    value={ad?.state}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicantId === a.applicantId) {
                            let applicantAddress = {
                              ...ap.correspondentAddress,
                              state: e.target.value,
                            };

                            return Object.assign(ap, {
                              applicantAddress,
                            });
                          } else return ap;
                        });
                      });
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-4">
                  <label>Post Office</label>
                  <input
                    type="text"
                    className="form-control"
                    name="st1"
                    id="st1"
                    value={ad?.postOffice}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicantId === a.applicantId) {
                            let applicantAddress = {
                              ...ap.correspondentAddress,
                              postOffice: e.target.value,
                            };

                            return Object.assign(ap, {
                              applicantAddress,
                            });
                          } else return ap;
                        });
                      });
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Police Station</label>
                  <input
                    type="text"
                    className="form-control"
                    name="st1"
                    id="st1"
                    value={ad?.policeStation}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicantId === a.applicantId) {
                            let applicantAddress = {
                              ...ap.correspondentAddress,
                              policeStation: e.target.value,
                            };

                            return Object.assign(ap, {
                              applicantAddress,
                            });
                          } else return ap;
                        });
                      });
                    }}
                  />
                </div>
              </div>

              <br />
              <br />

              <div className="row justify-content-center">
                <div className="col-12">
                  <h4>Correspondent Address</h4>
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-6">
                  <label>Full Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="fa2"
                    id="fa2"
                    value={cd?.fullAddress}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicantId === a.applicantId) {
                            let correspondentAddress = {
                              ...ap.correspondentAddress,
                              fullAddress: e.target.value,
                            };
                            return Object.assign(ap, {
                              correspondentAddress,
                            });
                          } else {
                            return ap;
                          }
                        });
                      });
                    }}
                  />
                </div>
                <div className="col-6">
                  <label>Landmark</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lm2"
                    id="lm2"
                    value={cd?.landmark}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicantId === a.applicantId) {
                            let correspondentAddress = {
                              ...ap.correspondentAddress,
                              landmark: e.target.value,
                            };
                            return Object.assign(ap, {
                              correspondentAddress,
                            });
                          } else return ap;
                        });
                      });
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-4">
                  <label>City/Town/Village</label>
                  <input
                    type="text"
                    className="form-control"
                    name="ct2"
                    id="ct2"
                    value={cd?.city}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicantId === a.applicantId) {
                            let correspondentAddress = {
                              ...ap.correspondentAddress,
                              city: e.target.value,
                            };
                            return Object.assign(ap, {
                              correspondentAddress,
                            });
                          } else return ap;
                        });
                      });
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Pincode</label>
                  <input
                    type="number"
                    className="form-control"
                    name="pc2"
                    id="pc2"
                    value={cd?.pinCode}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicantId === a.applicantId) {
                            let correspondentAddress = {
                              ...ap.correspondentAddress,
                              pinCode: e.target.value,
                            };
                            return Object.assign(ap, {
                              correspondentAddress,
                            });
                          } else return ap;
                        });
                      });
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>State</label>
                  <input
                    type="text"
                    className="form-control"
                    name="st2"
                    id="st2"
                    value={cd?.state}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicantId === a.applicantId) {
                            let correspondentAddress = {
                              ...ap.correspondentAddress,
                              state: e.target.value,
                            };
                            return Object.assign(ap, {
                              correspondentAddress,
                            });
                          } else return ap;
                        });
                      });
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-4">
                  <label>Post Office</label>
                  <input
                    type="text"
                    className="form-control"
                    name="st1"
                    id="st1"
                    value={cd?.postOffice}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicantId === a.applicantId) {
                            let correspondentAddress = {
                              ...ap.correspondentAddress,
                              postOffice: e.target.value,
                            };
                            return Object.assign(ap, {
                              correspondentAddress,
                            });
                          } else return ap;
                        });
                      });
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Police Station</label>
                  <input
                    type="text"
                    className="form-control"
                    name="st1"
                    id="st1"
                    value={cd?.policeStation}
                    onChange={(e) => {
                      setApplicants((prev) => {
                        return prev.map((ap) => {
                          if (ap.applicantId === a.applicantId) {
                            let correspondentAddress = {
                              ...ap.correspondentAddress,
                              policeStation: e.target.value,
                            };
                            return Object.assign(ap, {
                              correspondentAddress,
                            });
                          } else return ap;
                        });
                      });
                    }}
                  />
                </div>
              </div>

              <br />
              <br />

              <div className="row justify-content-center mt-2">
                <div className="col-6 text-center">
                  {a?.applicantType === "First Applicant" && (
                    <button
                      className={
                        data?.status === "Handed Over to Operations"
                          ? "ViewApplicantOperation__form__btn"
                          : "ViewApplicantOperation__disabled"
                      }
                      disabled={
                        data?.status !== "Handed Over to Operations" ? true : false
                      }
                      onClick={(e) => {
                        editApplicant(a.applicantId, e);
                      }}
                    >
                      Edit Applicant
                    </button>
                  )}
                </div>

                <div className="col-6">
                  {a?.applicantType === "First Applicant" && (
                    <button
                      className={
                        data?.status === "Handed Over to Operations"
                          ? "ViewApplicantOperation__form__btn"
                          : "ViewApplicantOperation__disabled"
                      }
                      disabled={
                        data?.status !== "Handed Over to Operations" ? true : false
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setApplicantId(a.applicantId);
                        setDeleteModal(true);
                      }}
                    >
                      Delete Applicant
                    </button>
                  )}
                </div>
              </div>

              <div className="row justify-content-center mt-2">
                <div className="col-6 text-center">
                  {a?.applicantType != "First Applicant" && (
                    <button
                      className={
                        data?.status === "Handed Over to Operations"
                          ? "ViewApplicantOperation__form__btn"
                          : "ViewApplicantOperation__disabled"
                      }
                      disabled={
                        data?.status !== "Handed Over to Operations" ? true : false
                      }
                      onClick={(e) => {
                        editApplicant(a.applicantId, e);
                      }}
                    >
                      Edit Applicant
                    </button>
                  )}
                </div>

                <div className="col-6">
                  {a?.applicantType != "First Applicant" && (
                    <button
                      className={
                        data?.status === "Handed Over to Operations"
                          ? "ViewApplicantOperation__form__btn"
                          : "ViewApplicantOperation__disabled"
                      }
                      disabled={
                        data?.status !== "Handed Over to Operations" ? true : false
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setApplicantId(a.applicantId);
                        setDeleteModal(true);
                      }}
                    >
                      Delete Applicant
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        );
      })}

      {/* ////////////Modal for deleting Applicant ////////////// */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
          setApplicantId("");
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={deleteModal}>
          <form className={classes.paper} onSubmit={(e) => deleteApplicant(e)}>
            <h4 className="text-center">
              Do you want to delete the Applicant - {applicantId} ?
            </h4>
            <br />

            <div className="row justify-content-center">
              <div className="col-4">
                <button className="unitphoto__btn" type="sumbit">
                  Yes
                </button>
              </div>

              <div className="col-4">
                <button
                  className="unitphoto__btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteModal(false);
                    setApplicantId("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Fade>
      </Modal>
    </div>
  );
};

export default ChangeApplicantOperation;
