import React, { useState, useEffect } from 'react'
import './AddDebitPayment.scss';
import Button from '../../components/Button/Button';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';

const AddDebitPayment = () => {

    const [claim, setClaim] = useState({})
    const [paymentMode, setPaymentMode] = useState('')
    const [paymentDate, setPaymentDate] = useState('')
    const [upi, setUpi] = useState('');
    const [account, setAccount] = useState('')
    const [bankName, setBankName] = useState('')

    const { claimId } = useParams();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    useEffect(() => {

        if (!claimId) return;

        axios.get(`${BASE_URL}/api/v1/hrmgmt/claims/getClaimByClaimId/${claimId}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                console.log(response)
                if (response.data.status == true) {
                    setClaim(response.data.claim)
                }
            })

    }, [])


    return (
        <div className='add-debit-payment'>

            <div className='row justify-content-between'>
                <Button navigateTo={1} />
            </div>

            <br />
            <h2 className='text-center'>Add Debit Payment</h2>
            <br />

            <form>

                {
                    claimId
                    &&
                    <div>
                        <h4 style={{ color: '#EE4B46' }}>Amount : Rs.{claim.claimAmount}</h4>
                        <br />
                    </div>
                }

                <br />

                <div className="row justify-content-center">
                    <div className='col-md-4 col-6 text-left'>
                        <label>Payable To</label>
                        <input type="text" readOnly className='form-control'
                            value={claim.raisedByFullName} />
                    </div>
                </div>

                <br />

                <div className="row container-fluid justify-content-center">
                    <div className='col-md-4 col-6 text-left'>
                        <Form.Group controlId="payment-category">
                            <Form.Label>Payment Mode</Form.Label>
                            <Form.Control as="select" required
                                value={paymentMode}
                                onChange={(e) => {
                                    setPaymentMode(e.target.value)
                                }}
                            >
                                <option value="">Select a Mode</option>
                                <option value="NEFT">NEFT</option>
                                <option value="RTGS">RTGS</option>
                                <option value="IMPS">IMPS</option>
                                <option value="UPI">UPI</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>

                <br />

                {
                    paymentMode === 'UPI'
                        ?
                        <div className="row container-fluid justify-content-center">
                            <div className='col-4 text-left'>
                                <label>UPI Id</label>
                                <input type="text"
                                    className='form-control'
                                    value={upi}
                                    onChange={(e) => setUpi(e.target.value)}
                                />
                            </div>
                        </div>
                        :
                        <div className="row container-fluid justify-content-center">
                            <div className='col-4 text-left'>
                                <label>Account No.</label>
                                <input type="number"
                                    className='form-control'
                                    value={account}
                                    onChange={(e) => setAccount(e.target.value)}
                                />
                            </div>
                            <div className='col-4'>
                                <Form.Group controlId="payment-category">
                                    <Form.Label>Bank Name</Form.Label>
                                    <Form.Control as="select" required
                                        value={bankName}
                                        onChange={(e) => {
                                            setBankName(e.target.value)
                                        }}
                                    >
                                        <option value="">Select a option</option>
                                        {/* <option value="NEFT">NEFT</option>
                                        <option value="RTGS">RTGS</option>
                                        <option value="IMPS">IMPS</option>
                                        <option value="UPI">UPI</option> */}
                                    </Form.Control>
                                </Form.Group>
                            </div>
                          
                        </div>
                }

                <br/>

                <div className="row justify-content-center">
                    <div className='col-md-4 col-6 text-left'>
                        <label>Payment Date</label>
                        <input type="date" className='form-control'
                            value={paymentDate} onChange={(e) => setPaymentDate(e.target.value)} />
                    </div>
                </div>

                <br />

                <div className="row justify-content-center">
                    <div className='col-4 d-flex justify-content-between'>
                        <button className='add-debit-payment__btn'>Pay</button>
                        <button className='add-debit-payment__btn'>Close</button>
                    </div>
                </div>

            </form>
        </div>
    )
}

export default AddDebitPayment;