import React, { useState } from "react";
import "./Specification.scss";
import SpecificationSidebar from "../../../components/SpecificationSidebar/SpecificationSidebar";
import Construction from "../../../components/Specification/Construction/Construction";
import InYourApartment from "../../../components/Specification/InYourApartment/InYourApartment";
import Kitchen from "../../../components/Specification/Kitchen/Kitchen";
import Toilet from "../../../components/Specification/Toilet/Toilet";
import Electricity from "../../../components/Specification/Electricity/Electricity";
import Door from "../../../components/Specification/Door/Door";
import CommonArea from "../../../components/Specification/CommonArea/CommonArea";
import MoreFeatures from "../../../components/Specification/MoreFeatures/MoreFeatures";
import Highlights from "../Amenities/Highlights";
import Back from "../Back/Back";

export default function Specification() {
  const [selectedTab, setSelectedTab] = useState("Construction");
  const [amenities, setamenities] = useState(true);
  const site = JSON.parse(localStorage.getItem("site"));

  return (
    <div className="specification">
      
  {/*<Back navigateTo={`/tabletView/dashboard/${site?.siteCode}`} />*/}

      <h3>Amenities and Specifications</h3>

      <div className="specification__nav">
        <span
          className={`${amenities ? "specification__nav--active" : ""}`}
          onClick={() => setamenities(true)}
        >
          Amenities
        </span>
        <span
          className={`${!amenities ? "specification__nav--active" : ""}`}
          onClick={() => setamenities(false)}
        >
          Specifications
        </span>
      </div>
      {amenities ? (
        <Highlights />
      ) : (
        <div className="specification__hero">
          <SpecificationSidebar
            active={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <div className="specification__hero__details">
            {selectedTab === "Construction" && <Construction />}
            {selectedTab === "In your Apartment" && <InYourApartment />}
            {selectedTab === "Kitchen" && <Kitchen />}
            {selectedTab === "WC/Toilets" && <Toilet />}
            {selectedTab === "Electricity" && <Electricity />}
            {selectedTab === "Doors and Windows" && <Door />}
            {selectedTab === "Common Areas" && <CommonArea />}
            {selectedTab === "More Features" && <MoreFeatures />}
          </div>
        </div>
      )}
    </div>
  );
}
