import axios from 'axios'
import MaterialTable, { MTableToolbar } from 'material-table'
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../utils/config/url'
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material'


const Disbursed = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const [disbursed, setDisbursed] = useState([])
    const [employeeList, setEmployeeList] = useState([])
    const [employee, setEmployee] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')

    useEffect(() => {

        let m = month;
        if (!year) {
            m = ''
            setMonth('')
        }

        let url = `${BASE_URL}/api/v1/hrmgmt/paySlip/getListOfPaySlips?payslipStatus=Salary%20Disbursed&`

        let filter = '';

        if (employee) {
            filter += `employeeId=${employee}&`
        }

        if (year) {
            filter += `year=${year}&`
        }

        if (m) {
            filter += `month=${m}&`
        }

        url += filter;

        axios.get(url,
            {
                headers: { Authorization: `Bearer ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response)
                if (response.data.status === true) {
                    setDisbursed(response.data.payslipList)
                } else {
                    setDisbursed([])
                }
            })
    }, [employee, year, month])

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`,
            {
                headers: { Authorization: `Bearer ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response)
                if (response.data.status === true) {
                    setEmployeeList(response.data.employees)
                } else {
                    setEmployeeList([])
                }
            })
    }, [])

    const dateFormatting = (input) => {

        if (!input) return 'N/A';

        let date = new Date(input).getDate();

        if (date < 10) {
            date = `0${date}`
        }

        let year = new Date(input).getFullYear();
        let month = new Date(input).getMonth() + 1;

        if (month < 10) {
            month = `0${month}`
        }

        return `${date}/${month}/${year}`
    }

    const reset = () => {
        setEmployee('')
        setYear('')
        setMonth('')
    }

    return (
        <div className='payroll-table'>

            <MaterialTable
                data={disbursed}
                title=""
                columns={[

                    {
                        title: "PaySlip No.",
                        render: (rowData) => {
                            return <a href={`/payRoll/disbursed/${rowData.payslipNumber}`}>{rowData.payslipNumber}</a>
                        }
                    },
                    {
                        title: "Employee Id",
                        field: 'employeeId'
                    },
                    { title: "Employee Full Name", field: "employeeFullName" },
                    {
                        title: "Month & Year",
                        render: (rowData) => (`${rowData?.monthName || ''} ${rowData?.year}`)
                    },
                    {
                        title: "Disbursement Date",
                        render: (rowData) => (dateFormatting(rowData?.disbursementDate)),
                        defaultSort: 'desc',
                        customSort: (a, b) => (new Date(a.disbursementDate).getTime() - new Date(b.disbursementDate).getTime())
                    },
                    {
                        title: 'Disbursement Mode',
                        field: 'disbursementMode'
                    },
                    {
                        title: 'Disbursed By',
                        field: 'disbursedByFullName'
                    },
                    { title: "Gross Salary", field: "grossSalary" },
                    { title: "Total Deduction", field: "totalDeduction" },
                    { title: "Net Salary", field: "netSalary" },

                ]}

                // actions={[
                //     {
                //         icon: 'remove_red_eye',
                //         tooltip: 'View Employee',
                //         disabled: (userInfo?.userRole === 'Director' || userInfo?.userRole === "HR" || userInfo?.userRole === "Finance") ? false : true,
                //         onClick: (event, rowData) => {
                //             navigate(`/viewemployees/${rowData.employeeId}`)
                //         },
                //     },

                // ]}


                options={{
                    search: true,
                    searchFieldStyle: {
                        width: 180
                    },
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    actionsCellStyle: {
                        width: 120,
                        paddingLeft: 30,
                    },
                    sorting: true,
                    headerStyle: {
                        backgroundColor: "#EE4B46",
                        color: "#fff",
                        paddingLeft: "11px",
                    },
                }}


                components={{
                    Toolbar: (props) => (
                        <div className="filters text-left">
                            <MTableToolbar {...props} />

                            <FormControl
                                variant='standard'
                                className="formControl" style={{ width: "120px", margin: '0 13px', marginTop: '-65px' }}>
                                <InputLabel id="demo-simple-select-helper-label" style={{ fontSize: "14px" }}>
                                    Employee
                                </InputLabel>

                                <Select value={employee}
                                    onChange={(e) => setEmployee(e.target.value)}

                                    inputProps={{ "aria-label": "Without label" }}>
                                    <MenuItem value=''>Select a employee</MenuItem>
                                    {
                                        employeeList?.map((employee, index) => {
                                            return <MenuItem key={index} value={employee?.employeeId}>{employee?.employeeFullName}</MenuItem>
                                        })
                                    }

                                </Select>
                            </FormControl>

                            <FormControl
                                variant='standard'
                                className="formControl" style={{ width: "120px", margin: '0 13px', marginTop: '-65px' }}>
                                <InputLabel id="demo-simple-select-helper-label" style={{ fontSize: "14px" }}>
                                    Year
                                </InputLabel>

                                <Select value={year}
                                    onChange={(e) => setYear(e.target.value)}

                                    inputProps={{ "aria-label": "Without label" }}>
                                    <MenuItem value=''>Select a Year</MenuItem>
                                    <MenuItem value={new Date().getFullYear()}>{new Date().getFullYear()}</MenuItem>
                                    <MenuItem value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</MenuItem>


                                </Select>
                            </FormControl>


                            {window.innerWidth < 700 && <br />}

                            <FormControl
                                variant='standard'
                                disabled={!year}
                                className="formControl" style={{ width: "120px", margin: '0 13px', marginTop: '-65px' }}>
                                <InputLabel id="demo-simple-select-helper-label" style={{ fontSize: "14px" }}>
                                    Month
                                </InputLabel>

                                <Select value={month}
                                    onChange={(e) => setMonth(e.target.value)}

                                    inputProps={{ "aria-label": "Without label" }}>
                                    <MenuItem value=''>Select a Month</MenuItem>
                                    <MenuItem value={1}>January</MenuItem>
                                    <MenuItem value={2}>February</MenuItem>
                                    <MenuItem value={3}>March</MenuItem>
                                    <MenuItem value={4}>April</MenuItem>
                                    <MenuItem value={5}>May</MenuItem>
                                    <MenuItem value={6}>June</MenuItem>
                                    <MenuItem value={7}>July</MenuItem>
                                    <MenuItem value={8}>August</MenuItem>
                                    <MenuItem value={9}>September</MenuItem>
                                    <MenuItem value={10}>October</MenuItem>
                                    <MenuItem value={11}>November</MenuItem>
                                    <MenuItem value={12}>December</MenuItem>

                                </Select>
                            </FormControl>

                            <FormControl className="formControl" >
                                <button
                                    onClick={reset}
                                    style={{
                                        backgroundColor: "white",
                                        color: "black",
                                        border: "1px solid black",
                                        borderRadius: "20px",
                                        padding: "5px",
                                        width: "100px",
                                        margin: "auto",
                                        marginTop: '-50px'
                                    }}
                                >
                                    Reset Filter
                                </button>
                            </FormControl>
                        </div>
                    ),
                }}

            ></MaterialTable >

        </div>
    )

}

export default Disbursed