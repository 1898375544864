import MaterialTable from "material-table";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";
import React, { useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { GiGears } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  Backdrop,
  Fade,
  Switch,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import { red } from "@mui/material/colors";
import CustomSwitch from "../../components/CustomSwitch/CustomSwitch";
import Button from "../../components/Button/Button";
const redColor = red[500];

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#EE4B46",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ViewTeamMembers = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState("");
  const [open, setOpen] = useState(false);
  const [checkActive, setCheckActive] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const classes = useStyles();
  const [allUser, setAllUser] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  // get all users
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/user/getListOfUsers`, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      });
      const data = res?.data?.userList;
      // console.log(data);
      const allData = data.map((d) => {
        const { isActive } = d;
        return isActive;
      });
      setUsers(data);
      setAllUser(allData);
    })();
  }, [isChange, checkActive]);

  const handleClose = () => {
    setOpen(false);
  };
  //   user activated / deactivated

  const handleDeactive = (userName, userActive) => {
    console.log(checkActive);
    axios
      .put(
        `${BASE_URL}/api/v1/user/deactivateUserByUserName`,
        {
          userName: userName,
          isActive: userActive,
        },
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      )
      .then((response) => {
        // console.log(response);
        setIsChange(!isChange);
        if (response.data.user.isActive === true) {
          Swal.fire(" Successfully!", "User Activated!", "success");
        } else {
          Swal.fire("Successfully!", "User Deactivated!", "error");
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="outlet_bg">
        <div className="outlet_container">

          <Button navigateTo={'/home'} />

          <div className="mt-5">
            <div className="row justify-content-between">
              <div className="col-4">
                <p style={{ fontSize: '20px' }}>View ERP Users</p>
              </div>

              {
                (userInfo.userRole === "Director" || userInfo.userRole === "HR")
                &&
                <div className="col-6 justify-content-end">
                  <button
                    className="btn btn-user"
                    style={{ color: 'white', marginLeft:"12rem" }}
                    onClick={() => navigate('/addmember')}
                  >Create ERP User</button>
                </div>
              }

            </div>

            <MaterialTable
              data={users}
              title="Users"

              actions={[
                (rowData) => ({
                  icon: () => (
                    <Switch checked={rowData.isActive} color="primary" />
                  ),
                  tooltip: rowData.isActive ? "Deactivate User" : "Activate User",
                  disabled:
                    userInfo.userRole === "Director" ||
                      userInfo.userRole === "ITAdmin"
                      ? false
                      : true,
                  onClick: (event, rowData) => {
                    setCheckActive(rowData.isActive);
                    setUsername(rowData.userName);
                    setOpen(true);
                    handleDeactive(rowData.userName, rowData.isActive);
                  },
                }),

                {
                  icon: "remove_red_eye",
                  tooltip: "View/Edit User",
                  disabled:
                    userInfo.userRole === "Director" ||
                      userInfo.userRole === "ITAdmin"
                      ? false
                      : true,
                  onClick: (event, rowData) => {
                    navigate(`/viewindividualuser/${rowData.userName}`);
                  },
                },
              ]}

              columns={[
                {
                  title: "Employee Id",
                  defaultSort: "desc",
                  field: "employeeId",
                  customSort: (a, b) =>
                    a.tableData.id < b.tableData.id ? -1 : 1,
                },
                { title: "Full Name", field: "userFullName" },
                { title: "Username", field: "userName" },
                { title: "Mobile", field: "userOfficialMobile" },
                { title: "Email", field: "userOfficialEmail" },
                { title: "Role", field: "userRole" },
                {
                  title: "Active",
                  render: (rowData) =>
                    rowData.isActive === true ? "Yes" : "No",
                },
              ]}
              options={{
                search: true,
                searchFieldStyle: {
                  width: 180
                },
                actionsColumnIndex: 0,
                paging: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 20, 30],
                headerStyle: {
                  backgroundColor: "#EE4B46",
                  color: "#fff",
                },
              }}

            ></MaterialTable>
          </div>
          {/* <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.paper}>
                            <h6 style={{ display: checkActive ? 'none' : 'block' }}>Are you sure want to reactivate this user?</h6>
                            <h6 style={{ display: checkActive ? 'block' : 'none' }}>Are you sure you want to deactivate this user ?</h6>
                            <br />
                            <div className="text-center">
                                <button className="btn btn-secondary btn-user" onClick={deactivate}>
                                    Confirm
                                </button>
                                &nbsp;&nbsp;
                                <button className="btn btn-secondary btn-user" onClick={handleClose} style={{ backgroundColor: "white", color: "black" }}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Fade>
                </Modal> */}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ViewTeamMembers;
