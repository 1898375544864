import React from 'react'
import { GrDocumentPdf, GrDocumentText } from 'react-icons/gr'
import { AiOutlineFileWord } from 'react-icons/ai'
import { BsCardImage } from 'react-icons/bs';
import './InvClaim.scss';

const Doc = ({ doc }) => {

    const dateFormatting = (date) => {

        if (date === undefined) return;

        let str = new Date(date).toLocaleString('en-US', { timeZone: 'Asia/Jakarta' });

        let d = new Date(str).getDate();
        if (d < 10) {
            d = `0${d}`
        }
        let m = new Date(str).getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = new Date(str).getFullYear();
        return `${d}/${m}/${y}`
    }

    return (
        <div
            className='doc'
        >
            <div className='doc__icon'>

                {(doc.docType === 'jpg' || doc.docType === 'jpeg' || doc.docType === 'png')
                    ?

                    <BsCardImage className='icon' />

                    :
                    (doc.docType === 'pdf')
                        ?
                        <GrDocumentPdf className='icon' />
                        :
                        (doc.docType === 'doc' || doc.docType === 'docx')
                            ?
                            <AiOutlineFileWord className='icon' />
                            :
                            <GrDocumentText className='icon' />
                }


            </div>
            <div className='doc__desc'>
                <div>
                    <b>Doc Name : </b><span>{doc?.docName}</span>
                </div>
                <div>
                    <b>Doc Link : </b><a href={doc?.docLink}>Doc Link</a>
                </div>

                <div>
                    <b>Upload Date : </b><span>{dateFormatting(doc?.uploadDate)}</span>
                </div>

            </div>
        </div>
    )
}

export default Doc