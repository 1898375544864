import axios from 'axios';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button';
import { BASE_URL } from '../../utils/config/url';
import './ListOfLeaveBalance.scss';

const ListOfLeaveBalance = () => {

    const [ListOfLeaveBalance, setListOfLeaveBalance] = useState([]);

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/leavePlan/getLeaveBalanceList`, {
            headers: { Authorization: `bearer ${userInfo.token}` },
        })
            .then((response) => {
                // console.log(response)
                if (response.data.status === true) {
                    setListOfLeaveBalance(response.data.leaveBalanceList)
                }
            })
    }, [])

    return (
        <div className='leave-balance'>

            <Button navigateTo={'/leavemanagement'} />

            <br /><br />

            <div className='row'>
                <div className='col-12'>
                <h4>List Of Leave Balance</h4>
                    <MaterialTable
                        data={ListOfLeaveBalance}
                        title=""
                        columns={
                            [
                                {
                                    title: 'Employee Name',
                                    render: (rowData) => (`${rowData.employeeFirstName} ${rowData.employeeLastName}`)
                                },
                                { title: 'Leave Plan Id', field: "leavePlanId" },
                                { title: 'Leave Plan Name', field: 'leavePlanName' },
                                {
                                    title: 'Weekly Off',
                                    render: (rowData) => (rowData.leaveBalance.weeklyOff)
                                },
                                {
                                    title: 'Earned Leave',
                                    render: (rowData) => (rowData.leaveBalance.earnedLeave)
                                },
                                {
                                    title: 'Casual Leave',
                                    render: (rowData) => (rowData.leaveBalance.casualLeave)
                                },
                                {
                                    title: 'Medical Leave',
                                    render: (rowData) => (rowData.leaveBalance.medicalLeave)
                                },
                                {   title:"Leave Without Pay",
                                    render:(rowData)=>(rowData.leaveBalance.lwp)
                                },
                            ]
                        }
                        options={{
                            search: true,
                            searchFieldStyle: {
                                width: 180
                            },
                            actionsColumnIndex: 0,
                            actionsCellStyle: {
                                width: 100
                            },
                            paging: true,
                            pageSize: 20,
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [5, 10, 20, 30],
                            headerStyle: {
                                backgroundColor: '#EE4B46',
                                color: '#fff',
                                paddingLeft: '11px'

                            }
                        }}

                    // actions={[
                    //     (rowData) => ({
                    //         icon: "remove_red_eye",
                    //         tooltip: "Update Unit Type",
                    //     }),
                    //     (rowData) => ({
                    //         icon: "delete",
                    //         tooltip: "Delete Unit Type",
                    //     })
                    // ]}


                    ></MaterialTable>
                </div>
            </div>


        </div>
    )
}

export default ListOfLeaveBalance