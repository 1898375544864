import axios from 'axios';
import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { BASE_URL } from '../../utils/config/url';
import './SiteVisits.scss';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useStyles } from '../../utils/ModalStyles';
import Paper from '@mui/material/Paper';
import { Bar, Pie } from "react-chartjs-2";
import { Form } from 'react-bootstrap';

const SiteVisits = () => {

    const classes = useStyles()
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const [siteVisits, setSiteVisits] = useState([])
    const [status, setStatus] = useState('');
    const [siteList, setSiteList] = useState([]);
    const [site, setSite] = useState('')
    const [statusReport, setStatusReport] = useState([])
    const [statusNames, setStatusNames] = useState([])
    const [contactPersonReport, setContactPersonReport] = useState([])
    const [contactPersonNames, setContactPersonNames] = useState([])
    const [contactPersonBg, setContactPersonBg] = useState([])
    const [noOfDays, setNoOfDays] = useState('')
    const [siteCode, setSiteCode] = useState('')
    const [noOfDays2, setNoOfDays2] = useState('')
    const [siteCode2, setSiteCode2] = useState('')

    const navigate = useNavigate()

    // useEffect(() => {
    //     axios.get(`${BASE_URL}/api/v1/leadmgmt/siteVisit/getListOfSiteVisit`, {
    //         headers: { Authorization: `bearer ${userInfo.token} ` }
    //     })
    //         .then((response) => {
    //             // console.log(response)
    //             setSiteVisits(response.data.siteVisitList)
    //         })
    // }, [])


    useEffect(() => {

        let url = `${BASE_URL}/api/v1/leadmgmt/siteVisit/siteVisitListFilter`
        let filter = '';

        if (status) {
            if (filter === '') {
                filter += `?status=${status}`
            } else {
                filter += `&status=${status}`
            }
        }

        if (site) {
            if (filter === '') {
                filter += `?siteCode=${site}`
            } else {
                filter += `&siteCode=${site}`
            }
        }

        url += filter;

        axios.get(url, {
            headers: { Authorization: `bearer ${userInfo.token} ` }
        })
            .then((response) => {
                // console.log(response)
                if (response.data.status === true) {
                    setSiteVisits(response.data.SiteVisit)
                } else {
                    setSiteVisits([])
                }

            })
    }, [status, site])


    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/configuration/site/getAllSiteNames`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response.data);
                setSiteList(response.data.siteList)
            })

    }, []);

    useEffect(() => {

        let url = `${BASE_URL}/api/v1/leadmgmt/siteVisit/siteVisitStatusReport`;

        let filter = '';

        if (noOfDays) {
            if (filter === '') {
                filter += `?noOfDays=${noOfDays}`
            } else {
                filter += `&noOfDays=${noOfDays}`
            }
        }

        if (siteCode) {
            if (filter === '') {
                filter += `?siteCode=${siteCode}`
            } else {
                filter += `&siteCode=${siteCode}`
            }
        }

        url += filter;

        axios.get(url, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response.data);
                // setStatusReport(response.data.report)
                let data = response?.data?.report?.map((data) => {
                    return data.count;
                });
                setStatusReport(data);

                let names = response?.data?.report.map((data) => {
                    return data._id;
                });

                setStatusNames(names);

            })
    }, [noOfDays, siteCode])

    useEffect(() => {

        let url = `${BASE_URL}/api/v1/leadmgmt/siteVisit/siteVisitBySalesExecutiveReport`;

        let filter = '';

        if (noOfDays2) {
            if (filter === '') {
                filter += `?noOfDays=${noOfDays2}`
            } else {
                filter += `&noOfDays=${noOfDays2}`
            }
        }

        if (siteCode2) {
            if (filter === '') {
                filter += `?siteCode=${siteCode2}`
            } else {
                filter += `&siteCode=${siteCode2}`
            }
        }

        url += filter;

        axios.get(url, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response.data);
                // setContactPersonReport(response.data.report)
                let data = response?.data?.report?.map((data) => {
                    return data.count;
                });
                setContactPersonReport(data);

                let names = response?.data?.report.map((data) => {
                    return data._id;
                });

                setContactPersonNames(names);

                let colors = response?.data?.report.map((val) => {
                    return (
                        "#" +
                        "0123456789abcdef"
                            .split("")
                            .map(function (v, i, a) {
                                return i > 5 ? null : a[Math.floor(Math.random() * 16)];
                            })
                            .join("")
                    );
                });

                setContactPersonBg(colors);
            })
    }, [noOfDays2, siteCode2])


    const dateFormatting = (date) => {

        let str = new Date(date)

        let d = new Date(str).getDate();
        if (d < 10) {
            d = `0${d}`
        }
        let m = new Date(str).getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = new Date(str).getFullYear();

        return `${d}-${m}-${y}`
    }


    return (
        <div className='sitevisits'>

            <div className='row justify-content-between'>

                <div className='col-2'>
                    <Button navigateTo={'/home'} />
                </div>

                <div className='col-8'>
                    <h3 className='text-start'>Site Visit Dashboard</h3>
                </div>

            </div>


            <Paper elevation={2} style={{ padding: '20px', marginTop: '40px' }}>
                <div className='row justify-content-center'>

                    <div className='col-5'>
                        <div className='col-12'>
                            <h4 className='text-center'>Site Visits By Status</h4>
                            <div className="row justify-content-center">

                                <div className="col-4">
                                    <Form.Group controlId="Days">
                                        <Form.Label>Days</Form.Label>
                                        <Form.Control
                                            as="select"
                                            required
                                            value={noOfDays}
                                            onChange={(e) => {
                                                setNoOfDays(e.target.value);
                                            }}
                                        >
                                            <option value="">Days</option>
                                            <option value={2}>Last 2 Days</option>
                                            <option value={90}>90</option>
                                            <option value={180}>180</option>
                                            <option value={365}>365</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>

                                <div className="col-8">
                                    <Form.Group controlId="year">
                                        <Form.Label>Site</Form.Label>
                                        <Form.Control
                                            as="select"
                                            required
                                            value={siteCode}
                                            onChange={(e) => {
                                                setSiteCode(e.target.value);
                                            }}
                                        >
                                            <option value="">Select a option</option>
                                            {
                                                siteList?.map((site, index) => {
                                                    return <option key={index} value={site?.SiteCode}>{site?.SiteName}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </div>

                            </div>
                        </div>
                        <div className='col-12'>
                            {statusReport.length === 0 ? (
                                <h6 className="text-center mt-5">NO SITE VISITS FOUND</h6>
                            ) : (
                                <Pie
                                    data={{
                                        labels: statusNames,
                                        datasets: [
                                            {
                                                data: statusReport,
                                                backgroundColor: ['#42a1f5', '#f5a742', '#f542a1', '#42f5a1'],
                                                borderWidth: 1,
                                            },
                                        ],
                                    }}
                                    width={200}
                                    height={250}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                    }}
                                />
                            )}
                        </div>
                    </div>


                    <div className='col-7'>
                        <div className='col-12'>
                            <h4 className='text-center'>Site Visits By Contact Person</h4>
                            <div className="row justify-content-center">

                                <div className="col-6">
                                    <Form.Group controlId="Days">
                                        <Form.Label>Days</Form.Label>
                                        <Form.Control
                                            as="select"
                                            required
                                            value={noOfDays2}
                                            onChange={(e) => {
                                                setNoOfDays2(e.target.value);
                                            }}
                                        >
                                            <option value="">Days</option>
                                            <option value={2}>Last 2 Days</option>
                                            <option value={90}>90</option>
                                            <option value={180}>180</option>
                                            <option value={365}>365</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>

                                <div className="col-6">
                                    <Form.Group controlId="year">
                                        <Form.Label>Site</Form.Label>
                                        <Form.Control
                                            as="select"
                                            required
                                            value={siteCode2}
                                            onChange={(e) => {
                                                setSiteCode2(e.target.value);
                                            }}
                                        >
                                            <option value="">Select a option</option>
                                            {
                                                siteList?.map((site, index) => {
                                                    return <option key={index} value={site?.SiteCode}>{site?.SiteName}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </div>

                            </div>
                        </div>
                        <div className='col-12'>
                            {contactPersonReport.length === 0 ? (
                                <h6 className="text-center mt-5">NO SITE VISITS FOUND</h6>
                            ) : (
                                <Pie
                                    data={{
                                        labels: contactPersonNames,
                                        datasets: [
                                            {
                                                data: contactPersonReport,
                                                backgroundColor: contactPersonBg,
                                                borderWidth: 1,
                                            },
                                        ],
                                    }}
                                    width={200}
                                    height={250}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>

            </Paper>

            <br />
            <br />

            <MaterialTable
                data={siteVisits}
                title="Sites Visit"

                columns={[
                    {
                        title: "Lead ID",
                        render: (rowData) => (<a
                            onClick={() => navigate(`/individuallead/${rowData.leadID}`)}
                            style={{ cursor: 'pointer', color: '#42a1f5' }}
                        >{rowData.leadID}</a>)
                    },
                    { title: "Site Visit ID", field: "siteVisitId" },
                    { title: "Site Name", field: "siteName" },
                    { title: "Contact Person", field: "contactPerson" },
                    {
                        title: "Contact Person No.",
                        field: "contactPersonMobile",
                    },
                    {
                        title: "Site Visit Date",
                        render: (rowData) => {
                            let res = ''

                            if (rowData.siteVisitDate) {
                                return res = dateFormatting(rowData.siteVisitDate)
                            } else {
                                return ''
                            }
                        },
                        customSort: (a, b) => (new Date(a.siteVisitDate) > new Date(b.siteVisitDate) ? 1 : -1),
                        defaultSort: 'desc'
                    },
                    {
                        title: "Time",
                        render: (rowData) => rowData.siteVisitTime,
                        customSort: (a, b) => (a.siteVisitTime < b.siteVisitTime ? -1 : 1),
                    },
                    { title: "Status", field: "status" },
                ]}


                options={{
                    search: window.innerWidth < 700 ? false : true,
                    searchFieldStyle: {
                        width: 180
                    },
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#EE4B46',
                        color: '#fff',
                    }
                }}

                components={{
                    Toolbar: (props) => (
                        <div className="filters text-center">
                            <MTableToolbar {...props} />

                            <FormControl
                                className="formControl"
                                variant='standard'
                                style={{
                                    marginTop: "-65px",
                                    marginLeft: '20px',
                                    width: '120px',
                                }}
                            >
                                <InputLabel id="demo-simple-select-helper-label"
                                    style={{ fontSize: '18px' }}>
                                    Status
                                </InputLabel>
                                <Select
                                    value={status}
                                    onChange={(e) => {
                                        setStatus(e.target.value)
                                    }}
                                    className="selectEmpty"
                                    style={{
                                        fontSize: '18px',
                                        textAlign: 'left'
                                    }}
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value="">
                                        Status
                                    </MenuItem>
                                    <MenuItem value='Scheduled'>Scheduled</MenuItem>
                                    <MenuItem value='ReScheduled'>ReScheduled</MenuItem>
                                    <MenuItem value="Cancelled">Cancelled</MenuItem>
                                    <MenuItem value="Completed">Completed</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl
                                className="formControl"
                                variant='standard'
                                style={{
                                    marginTop: "-65px",
                                    marginLeft: '20px',
                                    width: '120px',
                                }}
                            >
                                <InputLabel id="demo-simple-select-helper-label"
                                    style={{ fontSize: '18px' }}>
                                    Site
                                </InputLabel>
                                <Select
                                    value={site}
                                    onChange={(e) => {
                                        setSite(e.target.value)
                                    }}
                                    className="selectEmpty"
                                    style={{
                                        fontSize: '18px',
                                        textAlign: 'left'
                                    }}
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value="">
                                        Select a Site
                                    </MenuItem>
                                    {
                                        siteList.map((site, index) => {
                                            return <MenuItem key={index} value={site?.SiteCode}>{site?.SiteName}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>

                        </div>
                    ),
                }}


            ></MaterialTable>


        </div>
    )
}

export default SiteVisits