import React, { useEffect, useState } from 'react'
import './ViewInvCategory.scss'
import { Nav } from 'react-bootstrap';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Details from '../../Pages/ViewInvCategory/Details';
import SubCategories from '../../Pages/ViewInvCategory/SubCategories';
import Button from '../../components/Button/Button';
import CatalogueItems from './CatalogueItems';

const ViewInvCategory = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [tab, setTab] = useState('first')
    const [category, setCategory] = useState({})
    const [change, setChange] = useState(false)
    const { categoryCode } = useParams()

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/stockmgmtapi/catCategory/getCatCategoryByCategoryCode/${categoryCode}`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                if (response.data.status) {
                    setCategory(response.data.Category)
                }
            })
    }, [change])

    return (
        <div className='inv-cat'>

            <Button navigateTo="/listOfCategory" />

            <br />
            <br />

            <div className="tabs">
                <Nav>
                    <Nav.Item
                        onClick={() => {
                            setTab('first')

                        }}
                    >
                        <Nav.Link className={tab === 'first' ? 'active' : 'inactive'} eventKey="first">
                            Details
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        onClick={() => {
                            setTab('second')
                        }}
                    >
                        <Nav.Link className={tab === 'second' ? 'active' : 'inactive'} eventKey="second">
                            Sub Categories
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        onClick={() => {
                            setTab('third')
                        }}
                    >
                        <Nav.Link className={tab === 'third' ? 'active' : 'inactive'} eventKey="third">
                            Catalogue Items
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>

            {
                tab === 'first' && <Details category={category} setCategory={setCategory} change={change} setChange={setChange} />
            }

            {
                tab === 'second' && <SubCategories />
            }

            {
                tab === 'third' && <CatalogueItems />
            }

        </div>
    )
}

export default ViewInvCategory