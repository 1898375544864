import React, { useState } from "react";
import House from "../../../assets/images/Rectangle 15.png";
import playArea from "../../../assets/images/Rectangle 19.png";
import powerBackup from "../../../assets/images/Rectangle 23.png";
import gymnasium from "../../../assets/images/Rectangle 17.png";
import bbq from "../../../assets/images/Rectangle 20.png";
import intercom from "../../../assets/images/Rectangle 24.png";
import parkingArea from "../../../assets/images/Rectangle 16.png";
import garden from "../../../assets/images/Rectangle 21.png";
import security from "../../../assets/images/Rectangle 25.png";

import games from "../../../assets/images/Rectangle 18.png";
import swimmingPool from "../../../assets/images/Rectangle 22.png";
import wifi from "../../../assets/images/Rectangle 705.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

// import Outdoor from "../../assets/images/Rectangle 19.png";
// import Safety from "../../assets/images/Highlights/png/shield.png";
// import Energy from "../../assets/images/Highlights/png/lightbulb.png";
// import Health from "../../assets/images/Highlights/png/healthcare.png";
// import Water from "../../assets/images/Highlights/png/save-water.png";
// import Waste from "../../assets/images/Highlights/png/recycling-bin.png";
// import Services from "../../assets/images/Highlights/png/handshake.png";

import "./Highlights.scss";

const Highlights = () => {
  return (
    <div className="hightlights">
      {/* <div
        className="heading"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-duration="1500"
      >
        <h1>Highlights</h1>
        <p>Residential Flat Features</p>
      </div> */}

      <div className="row body">

        <div className="col-3 card-container">
          <div className="card">
            <div className="front">
              <img src={House} alt="img" />
              <span>
                State of Art
                <br />
                Community Hall <ArrowRightAltIcon />
              </span>
            </div>
            <div className="back">
              <img src={House} alt="img" />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Temporibus officiis ex provident necessitatibus aperiam dolorem
                molestiae rerum doloremque consequuntur repellat!
              </p>
            </div>
          </div>
        </div>

        <div className="col-3 card-container">
          <div className="card">
            <div className="front">
              <img src={parkingArea} alt="img" />
              <span>
                Parking Area <ArrowRightAltIcon />
              </span>
            </div>
            <div className="back">
              <img src={parkingArea} alt="img" />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Temporibus officiis ex provident necessitatibus aperiam dolorem
                molestiae rerum doloremque consequuntur repellat!
              </p>
            </div>
          </div>
        </div>

        <div className="col-3 card-container">
          <div className="card">
            <div className="front">
              <img src={gymnasium} alt="img" />
              <span>
                Gymnasium <ArrowRightAltIcon />
              </span>
            </div>
            <div className="back">
              <img src={gymnasium} alt="img" />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Temporibus officiis ex provident necessitatibus aperiam dolorem
                molestiae rerum doloremque consequuntur repellat!
              </p>
            </div>
          </div>
        </div>

        <div className="col-3 card-container">
          <div className="card">
            <div className="front">
              <img src={games} alt="img" />
              <span>
                Indoor games hall <ArrowRightAltIcon />
              </span>
            </div>
            <div className="back">
              <img src={games} alt="img" />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Temporibus officiis ex provident necessitatibus aperiam dolorem
                molestiae rerum doloremque consequuntur repellat!
              </p>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div className="row body">

        <div className="col-3 card-container">
          <div className="card">
            <div className="front">
              <img src={playArea} alt="img" />
              <span>
                {" "}
                Kid's play area <ArrowRightAltIcon />
              </span>
            </div>
            <div className="back">
              <img src={playArea} alt="img" />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Temporibus officiis ex provident necessitatibus aperiam dolorem
                molestiae rerum doloremque consequuntur repellat!
              </p>
            </div>
          </div>
        </div>

        <div className="col-3 card-container">
          <div className="card">
            <div className="front">
              <img src={garden} alt="img" />
              <span>
                Rooftop Graden <ArrowRightAltIcon />
              </span>
            </div>
            <div className="back">
              <img src={garden} alt="img" />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Temporibus officiis ex provident necessitatibus aperiam dolorem
                molestiae rerum doloremque consequuntur repellat!
              </p>
            </div>
          </div>
        </div>

        <div className="col-3 card-container">
          <div className="card">
            <div className="front">
              <img src={bbq} alt="img" />
              <span>
                Rooftop BBQ <ArrowRightAltIcon />
              </span>
            </div>
            <div className="back">
              <img src={bbq} alt="img" />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Temporibus officiis ex provident necessitatibus aperiam dolorem
                molestiae rerum doloremque consequuntur repellat!
              </p>
            </div>
          </div>
        </div>

        <div className="col-3 card-container">
          <div className="card">
            <div className="front">
              <img src={swimmingPool} alt="img" />
              <span>
                Rooftop Swiming Pool <ArrowRightAltIcon />
              </span>
            </div>
            <div className="back">
              <img src={swimmingPool} alt="img" />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Temporibus officiis ex provident necessitatibus aperiam dolorem
                molestiae rerum doloremque consequuntur repellat!
              </p>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div className="row body">
        <div className="col-3 card-container">
          <div className="card">
            <div className="front">
              <img src={powerBackup} alt="img" />
              <span>
                Power Backup <ArrowRightAltIcon />
              </span>
            </div>
            <div className="back">
              <img src={powerBackup} alt="img" />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Temporibus officiis ex provident necessitatibus aperiam dolorem
                molestiae rerum doloremque consequuntur repellat!
              </p>
            </div>
          </div>
        </div>

        <div className="col-3 card-container">
          <div className="card">
            <div className="front">
              <img src={security} alt="img" />
              <span>
                24 hour security <ArrowRightAltIcon />
              </span>
            </div>
            <div className="back">
              <img src={security} alt="img" />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Temporibus officiis ex provident necessitatibus aperiam dolorem
                molestiae rerum doloremque consequuntur repellat!
              </p>
            </div>
          </div>
        </div>

        <div className="col-3 card-container">
          <div className="card">
            <div className="front">
              <img src={intercom} alt="img" />
              <span>
                Intercom <ArrowRightAltIcon />
              </span>
            </div>
            <div className="back">
              <img src={intercom} alt="img" />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Temporibus officiis ex provident necessitatibus aperiam dolorem
                molestiae rerum doloremque consequuntur repellat!
              </p>
            </div>
          </div>
        </div>

        <div className="col-3 card-container">
          <div className="card">
            <div className="front">
              <img src={wifi} alt="img" />
              <span>
                Free Wifi <ArrowRightAltIcon />
              </span>
            </div>
            <div className="back">
              <img src={wifi} alt="img" />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Temporibus officiis ex provident necessitatibus aperiam dolorem
                molestiae rerum doloremque consequuntur repellat!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Highlights;
