import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import "./AddCarParkingType.scss";
import { BASE_URL } from "../../utils/config/url";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";

const AddCarParkingType = () => {
  const siteCode = useParams().siteCode;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + userInfo.token;
  const navigate = useNavigate();

  const [siteName, setSiteName] = useState("");
  const [phases, setPhases] = useState([]);
  const [phaseName, setPhaseName] = useState("");
  const [phaseCode, setPhaseCode] = useState("");
  const [parkingCode, setParkingCode] = useState("");
  const [price, setPrice] = useState("");
  const [total, setTotal] = useState("");
  const [parkingName, setParkingName] = useState("");
  const [cpError, setCpError] = useState("");

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        setSiteName(response.data.site.siteName);
        setPhases(response.data.site.phases);
      });
  }, []);

  const handleAddCarParkingType = (e) => {
    e.preventDefault();

    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if (parkingCode.length < 2 || parkingCode.length > 4) {
      return setCpError(
        "Parking Code must be greater than 1 but less than 5 characters"
      );
    } else if (/\s/.test(parkingCode)) {
      return setCpError("Parking Code cannot contain any white space");
    } else if (specialChars.test(parkingCode)) {
      return setCpError(`Parking code can't contain any special characters`);
    } else if (/[a-z]/.test(parkingCode)) {
      return setCpError(
        "Parking Code can contain only numbers or uppercase letters"
      );
    } else {
      setCpError("");
    }

    axios
      .post(
        `${BASE_URL}/api/v1/Configuration/CarParkingType/addCarParkingType`,
        {
          siteName: siteName,
          siteCode: siteCode,
          phaseCode: phaseCode,
          phaseName: phaseName,
          parkingName: parkingName,
          parkingCode: parkingCode,
          carParkingTypeGrossPrice: price,
          carParkingTypeTotalCount: total,
        },
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            navigate(-1);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      });
  };

  return (
    <div className="addcarparkingtype">
      <Button navigateTo={1} />

      <div className="header__container">
        <div>
          <h3>Add Car Parking Type</h3>
        </div>
        <div>
          <span className="addcarparkingtype__headers">
            Site Name - {siteName}
          </span>
          <span className="addcarparkingtype__headers">
            Site Code - {siteCode}
          </span>
        </div>
      </div>

      <form
        className="addcarparkingtype__form"
        onSubmit={(e) => handleAddCarParkingType(e)}
      >
        <div className="row container-fluid justify-content-center">
          <div className="col-5">
            <Form.Group controlId="bhk">
              <Form.Label>Phase Name</Form.Label>
              <Form.Control
                required
                as="select"
                value={phaseName}
                onChange={(e) => {
                  if (e.target.value === "") {
                    return setPhaseCode("");
                  } else if (e.target.value === "General") {
                    setPhaseCode("Gen");
                    setPhaseName("General");
                  }
                  phases.forEach((p) => {
                    if (p.phaseName === e.target.value) {
                      setPhaseCode(p.phaseCode);
                      setPhaseName(e.target.value);
                    }
                  });
                }}
              >
                <option value="">Select a Phase Name</option>
                <option value="General">General</option>
                {phases.map((p, index) => {
                  return <option key={index}>{p.phaseName}</option>;
                })}
              </Form.Control>
            </Form.Group>
          </div>

          <div className="col-5">
            <label>Phase Code</label>
            <input className="form-control" readOnly value={phaseCode} />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className="col-5">
            <label>Parking Name</label>
            <input
              className="form-control"
              required
              type="text"
              value={parkingName}
              onChange={(e) => setParkingName(e.target.value)}
            />
          </div>

          <div className="col-5">
            <label>Parking Code</label>
            <input
              className="form-control"
              required
              type="text"
              value={parkingCode}
              onChange={(e) => {
                setParkingCode(e.target.value);
                if (e.target.value.length === 1 || e.target.value.length > 4) {
                  // console.log('enter')
                  setCpError(
                    "Site Code must be greater than 1 but less than 5 characters"
                  );
                } else if (/\s/.test(e.target.value)) {
                  setCpError("Site Code cannot contain any white space");
                } else if (
                  /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(e.target.value)
                ) {
                  setCpError(`Site code can't contain any special characters`);
                } else if (/[a-z]/.test(e.target.value)) {
                  setCpError(
                    "Site Code can contain only numbers or uppercase letters"
                  );
                } else {
                  setCpError("");
                }
              }}
            />
            {cpError && (
              <em
                style={{ fontSize: "15px", color: "#EE4B46", marginTop: "5px" }}
              >
                {cpError}
              </em>
            )}
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className="col-5">
            <label>Total Count</label>
            <input
              className="form-control"
              required
              type="number"
              value={total}
              onChange={(e) => setTotal(e.target.value)}
            />
          </div>

          <div className="col-5">
            <label>Price</label>
            <input
              className="form-control"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </div>

        <br />
        <br />

        <div className="row container-fluid justify-content-center">
          <div className="col-10 addcarparkingtype__btns">
            <button
              type="reset"
              style={{
                border: "1px solid black",
                backgroundColor: "white",
                color: "black",
              }}
              onClick={() => setPhaseCode("")}
            >
              Reset
            </button>
            <button type="submit">Add</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCarParkingType;
