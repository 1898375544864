import IndividualSiteForm from './IndividualSiteForm';
import { Navigate} from "react-router-dom"

function IndividualSitePage() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  if( userInfo.token === undefined ) {
    return <Navigate to="/login" noThrow />
  }
  else {
  return <IndividualSiteForm/>;
  }
}

export default IndividualSitePage;