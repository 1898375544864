import axios from "axios";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../utils/config/url";

const CustomerReceipts = ({ moneyReceipts, setChange, change }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <div>
      <div className="mt-5">
        <MaterialTable
          data={moneyReceipts}
          title={"Money Receipts"}
          columns={[
            { title: "Receipt No", field: "moneyReceiptNo" },
            {
              title: "Receipt Date",
              defaultSort: "desc",
              render: (rowData) => (!rowData.moneyReceiptDate ? "" : rowData.moneyReceiptDate.substring(8, 10) + "-" + rowData.moneyReceiptDate.substring(5, 7) + "-" + rowData.moneyReceiptDate.substring(0, 4) + ", " + rowData.moneyReceiptDate.substring(11, 13) + ":" + rowData.moneyReceiptDate.substring(14, 16)),
              customSort: (a, b) => (a.moneyReceiptDate < b.moneyReceiptDate ? -1 : 1),
            },
            { title: "Receipt Amount", field: "totalAmount" },
            { title: "GST", field: "subTotalGST" },
            { title: "Payment Category", field: "paymentCategory" },
            { title: "Payment Sub Category", field: "paymentSubCategory" },
            { title: "Transaction Mode", field: "transactionMode" },
            { title: "Status", field: "status" },
          ]}
          options={{
            search: true,
            headerStyle: {
              backgroundColor: "#EE4B46",
              color: "#fff",
            },
          }}
          actions={[
            (rowData) => ({
              icon: "download",
              tooltip: "download PDF",
              onClick: (event, rowData) => {
                const link = document.createElement("a");
                link.download = `Money Receipt.pdf`;
                link.href = rowData.moneyReceiptLink;
                link.click();
              },
              disabled: rowData.status !== "ReceiptGenerated",
            }),
          ]}
        />
      </div>
    </div>
  );
};

export default CustomerReceipts;
