import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import { Form } from "react-bootstrap";
import "./AddApplicant.scss";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useStyles } from "../../utils/ModalStyles";

const AddApplicant = () => {
  const classes = useStyles();
  const applicationId = useParams().applicationId;
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + userInfo.token;

  const [fa1, setFa1] = useState("");
  const [lm1, setLm1] = useState("");
  const [ct1, setCt1] = useState("");
  const [pc1, setPc1] = useState("");
  const [st1, setSt1] = useState("");
  const [po1, setPo1] = useState("");
  const [ps1, setPs1] = useState("");

  const [fa2, setFa2] = useState("");
  const [lm2, setLm2] = useState("");
  const [ct2, setCt2] = useState("");
  const [pc2, setPc2] = useState("");
  const [st2, setSt2] = useState("");
  const [po2, setPo2] = useState("");
  const [ps2, setPs2] = useState("");

  const [cfa2, setCfa2] = useState("");
  const [clm2, setClm2] = useState("");
  const [cct2, setCct2] = useState("");
  const [cst2, setCst2] = useState("");
  const [cpc2, setCpc2] = useState("");
  const [cpo2, setCpo2] = useState("");
  const [cps2, setCps2] = useState("");

  const [newApplicant, setNewApplicant] = useState({});
  const [sameAddress, setSameAddress] = useState(false);

  const [mobileErr, setMobileErr] = useState("");
  const [whatsappErr, setWhatsappErr] = useState("");
  const [panErr, setPanErr] = useState("");
  const [aadharErr, setAadharErr] = useState("");
  const [pinCodeErr, setPinCodeErr] = useState("");
  const [pinCode2Err, setPinCode2Err] = useState("");

  const [spinnerModal, setSpinnerModal] = useState(false);
  const [applicationNo, setApplicationNo] = useState(
    localStorage.getItem("ApplicationNo")
  );

  const saveApplicant = (e) => {
    e.preventDefault();

    const data = newApplicant;
    if (data?.applicantMobile?.length !== 10) {
      setMobileErr("Mobile no. must be of 10 digits");

      if (data?.applicantWhatsapp && data?.applicantWhatsapp?.length !== 10) {
        setWhatsappErr("Whatsapp no. must be of 10 digits");
      }
      if (data?.applicantPAN?.length !== 10) {
        setPanErr("PAN no. must be 10 characters");
      }
      if (data?.applicantAadhar?.length !== 12) {
        setAadharErr("Aadhar no. must of 12 digits");
      }
      if (pc1?.length !== 6 || pc2?.length !== 6) {
        setPinCodeErr("Pincode must be of 6 digits");
        if (pc2?.length !== 6 || cpc2?.length !== 6) {
          setPinCode2Err("Pincode must be of 6 digits");
        }
      }
    } else {
      let body;
      if (sameAddress) {
        body = {
          ...newApplicant,
          applicationId: applicationId,
          applicantAddress: {
            fullAddress: fa2,
            landmark: lm2,
            state: st2,
            city: ct2,
            pinCode: pc2,
            postOffice: po2,
            policeStation: ps2,
          },
          correspondentAddress: {
            fullAddress: fa2,
            landmark: lm2,
            state: st2,
            city: ct2,
            pinCode: pc2,
            postOffice: po2,
            policeStation: ps2,
          },
        };
      } else {
        body = {
          ...newApplicant,
          applicationId: applicationId,
          applicantAddress: {
            fullAddress: fa1,
            landmark: lm1,
            state: st1,
            city: ct1,
            pinCode: pc1,
            postOffice: po1,
            policeStation: ps1,
          },
          correspondentAddress: {
            fullAddress: cfa2,
            landmark: clm2,
            state: cst2,
            city: cct2,
            pinCode: cpc2,
            postOffice: cpo2,
            policeStation: cps2,
          },
        };
      }

      setSpinnerModal(true);

      axios
        .post(
          `${BASE_URL}/api/v1/CustomerMgmt/Applicant/createNewApplicant`,
          body,
          { headers: { Authorization: Token } }
        )
        .then((response) => {
          // console.log(response)

          setSpinnerModal(false);
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: response.data.message,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then(function () {
              let applicationNo = localStorage.getItem("ApplicationNo");
              navigate(`/flatAllotment/manageapplications/${applicationNo}`);
            });
          } else {
            setSpinnerModal(false);
            Swal.fire({
              icon: "error",
              title: "Error",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
              text: response.data.message,
            });
          }
        })
        .catch((err) => {
          setSpinnerModal(false);
          // console.log('fail')

          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: err,
          });
        });
    }
  };

  return (
    <div className="addapplicant">
      <Button
        navigateTo={`/flatAllotment/manageapplications/${applicationNo}`}
      />
      <br />
      <br />

      <div className="addapplicant__form">
        <form onSubmit={(e) => saveApplicant(e)}>
          <br />
          <div className="row justify-content-center">
            <div className="col-6">
              <label>Application ID</label>
              <input
                type="text"
                class="form-control"
                readOnly
                value={applicationId}
              />
            </div>

            <div className="col-6">
              <Form.Group controlId="salutation">
                <Form.Label>Applicant Type</Form.Label>
                <Form.Control
                  as="select"
                  required
                  onChange={(e) => {
                    setNewApplicant((prev) => {
                      return {
                        ...prev,
                        applicantType: e.target.value,
                      };
                    });
                  }}
                >
                  <option value="">Select a Type</option>
                  <option value="First Applicant">First Applicant</option>
                  <option value="Co-Applicant 1">Co-Applicant 1</option>
                  <option value="Co-Applicant 2">Co-Applicant 2</option>
                </Form.Control>
              </Form.Group>
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-3">
              <Form.Group controlId="salutation">
                <Form.Label>Salutation</Form.Label>
                <Form.Control
                  as="select"
                  required
                  onChange={(e) => {
                    setNewApplicant((prev) => {
                      return {
                        ...prev,
                        salutation: e.target.value,
                      };
                    });
                  }}
                >
                  <option value="">Select a Salutation</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Dr.">Dr.</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-3">
              <label>First Name</label>
              <input
                type="text"
                class="form-control"
                name="firstName"
                id="firstName"
                required
                onChange={(e) => {
                  setNewApplicant((prev) => {
                    return {
                      ...prev,
                      firstName: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div className="col-3">
              <label>Middle Name</label>
              <input
                type="text"
                class="form-control"
                name="middleName"
                id="middleName"
                onChange={(e) => {
                  setNewApplicant((prev) => {
                    return {
                      ...prev,
                      middleName: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div className="col-3">
              <label>Last Name</label>
              <input
                type="text"
                class="form-control"
                name="lastName"
                id="lastName"
                required
                onChange={(e) => {
                  setNewApplicant((prev) => {
                    return {
                      ...prev,
                      lastName: e.target.value,
                    };
                  });
                }}
              />
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <label>Spouse Name</label>
              <input
                type="text"
                class="form-control"
                name="sname"
                id="sname"
                onChange={(e) => {
                  setNewApplicant((prev) => {
                    return {
                      ...prev,
                      spouseName: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div className="col-4">
              <label>Father's Name</label>
              <input
                type="text"
                class="form-control"
                name="fname"
                id="fname"
                required
                onChange={(e) => {
                  setNewApplicant((prev) => {
                    return {
                      ...prev,
                      fatherName: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div className="col-4">
              <label>Religion</label>
              <input
                type="text"
                class="form-control"
                name="religion"
                id="religion"
                required
                onChange={(e) => {
                  setNewApplicant((prev) => {
                    return {
                      ...prev,
                      religion: e.target.value,
                    };
                  });
                }}
              />
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <Form.Group controlId="gender">
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  as="select"
                  required
                  onChange={(e) => {
                    setNewApplicant((prev) => {
                      return {
                        ...prev,
                        applicantGender: e.target.value,
                      };
                    });
                  }}
                >
                  <option value="">Select a Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-4">
              <label>Nationality</label>
              <input
                type="text"
                class="form-control"
                name="nationality"
                id="nationality"
                required
                onChange={(e) => {
                  setNewApplicant((prev) => {
                    return {
                      ...prev,
                      nationality: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div className="col-4">
              <label>Mobile</label>
              <input
                type="number"
                class="form-control"
                name="mobile"
                id="mobile"
                required
                autoComplete="off"
                onChange={(e) => {
                  if (e.target.value.length !== 10) {
                    return setMobileErr("Mobile no. must be of 10 digits");
                  } else {
                    setMobileErr("");
                    setNewApplicant((prev) => {
                      return {
                        ...prev,
                        applicantMobile: e.target.value,
                      };
                    });
                  }
                }}
              />
              {mobileErr && <em style={{ color: "#EE4B46" }}>{mobileErr}</em>}
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <label>Whatsapp</label>
              <input
                type="number"
                class="form-control"
                name="whatsapp"
                id="whatsapp"
                onChange={(e) => {
                  if (e.target.value.length !== 10) {
                    setWhatsappErr("Whatsapp no. must be of 10 digits");
                  } else {
                    setWhatsappErr("");
                    setNewApplicant((prev) => {
                      return {
                        ...prev,
                        applicantWhatsapp: e.target.value,
                      };
                    });
                  }
                }}
              />
              {whatsappErr && (
                <em style={{ color: "#EE4B46" }}>{whatsappErr}</em>
              )}
            </div>
            <div className="col-4">
              <label>Email</label>
              <input
                type="email"
                class="form-control"
                name="email"
                id="email"
                onChange={(e) => {
                  setNewApplicant((prev) => {
                    return {
                      ...prev,
                      applicantEmail: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div className="col-4">
              <label>Aadhar</label>
              <input
                type="number"
                class="form-control"
                name="aa"
                id="aa"
                required
                onChange={(e) => {
                  if (e.target.value.length !== 12) {
                    setAadharErr("Aadhar no. must be 12 digits");
                  } else {
                    setAadharErr("");
                    setNewApplicant((prev) => {
                      return {
                        ...prev,
                        applicantAadhar: e.target.value,
                      };
                    });
                  }
                }}
              />
              {aadharErr && <em style={{ color: "#EE4B46" }}>{aadharErr}</em>}
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <label>PAN</label>
              <input
                type="text"
                class="form-control"
                name="pan"
                id="pan"
                required
                onChange={(e) => {
                  if (e.target.value.length !== 10) {
                    setPanErr("PAN no. must be 10 characters");
                  } else if (/[a-z]/.test(e.target.value)) {
                    setPanErr("PAN no. must have only capital letters");
                  } else if (
                    /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
                      e.target.value
                    )
                  ) {
                    setPanErr(
                      "PAN no. can have only numbers and capital letters"
                    );
                  } else {
                    setPanErr("");
                    setNewApplicant((prev) => {
                      return {
                        ...prev,
                        applicantPAN: e.target.value,
                      };
                    });
                  }
                }}
              />
              {panErr && <em style={{ color: "#EE4B46" }}>{panErr}</em>}
            </div>
            <div className="col-4">
              <Form.Group controlId="salutation">
                <Form.Label>Occupation Type</Form.Label>
                <Form.Control
                  as="select"
                  required
                  onChange={(e) => {
                    setNewApplicant((prev) => {
                      return {
                        ...prev,
                        occupationType: e.target.value,
                      };
                    });
                  }}
                >
                  <option value="">Select an Occupation Type</option>
                  <option value="Salaried">Salaried</option>
                  <option value="Self Employed">Self Employed</option>
                  <option value="Business">Business</option>
                  <option value="Other">Other</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-4">
              <label>Occupation</label>
              <input
                type="text"
                class="form-control"
                name="oc"
                id="oc"
                required
                onChange={(e) => {
                  setNewApplicant((prev) => {
                    return {
                      ...prev,
                      occupation: e.target.value,
                    };
                  });
                }}
              />
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-12">
              <h4>Applicant Address</h4>
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-6">
              <label>Full Address</label>
              <input
                type="text"
                class="form-control"
                name="fa1"
                id="fa1"
                required
                onChange={(e) => {
                  setFa2(e.target.value);
                  setFa1(e.target.value);
                }}
              />
            </div>
            <div className="col-6">
              <label>Landmark</label>
              <input
                type="text"
                class="form-control"
                name="lm1"
                id="lm1"
                required
                onChange={(e) => {
                  setLm2(e.target.value);
                  setLm1(e.target.value);
                }}
              />
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <label>City/Town/Village</label>
              <input
                type="text"
                class="form-control"
                name="ct1"
                id="ct1"
                required
                onChange={(e) => {
                  setCt2(e.target.value);
                  setCt1(e.target.value);
                }}
              />
            </div>
            <div className="col-4">
              <label>Pincode</label>
              <input
                type="number"
                class="form-control"
                name="pc1"
                id="pc1"
                required
                onChange={(e) => {
                  if (e.target.value.length !== 6) {
                    setPinCodeErr("Pincode must be 6 digits");
                    setPc2(e.target.value);
                    setPc1(e.target.value);
                  } else {
                    setPinCodeErr("");
                    setPc2(e.target.value);
                    setPc1(e.target.value);
                  }
                }}
              />
              {pinCodeErr && <em style={{ color: "#EE4B46" }}>{pinCodeErr}</em>}
            </div>
            <div className="col-4">
              <label>State</label>
              <input
                type="text"
                class="form-control"
                name="st1"
                id="st1"
                required
                onChange={(e) => {
                  setSt2(e.target.value);
                  setSt1(e.target.value);
                }}
              />
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <label>Post Office</label>
              <input
                type="text"
                class="form-control"
                name="st1"
                id="st1"
                required
                onChange={(e) => {
                  setPo2(e.target.value);
                  setPo1(e.target.value);
                }}
              />
            </div>
            <div className="col-4">
              <label>Police Station</label>
              <input
                type="text"
                class="form-control"
                name="st1"
                id="st1"
                required
                onChange={(e) => {
                  setPs2(e.target.value);
                  setPs1(e.target.value);
                }}
              />
            </div>
          </div>

          <br />
          <br />

          <div className="row justify-content-start">
            <div className="col-8">
              <label>
                Correspondent Address is same as Primary Address : &nbsp; &nbsp;
                &nbsp;
                <input
                  type="radio"
                  required
                  name="sameAddress"
                  onClick={() => setSameAddress(true)}
                />{" "}
                Yes &nbsp; &nbsp; &nbsp;
                <input
                  type="radio"
                  required
                  name="sameAddress"
                  onClick={() => setSameAddress(false)}
                  defaultChecked
                />{" "}
                No
              </label>
            </div>
          </div>

          <br />
          <br />

          {!sameAddress ? (
            <>
              <div className="row justify-content-center">
                <div className="col-12">
                  <h4>Correspondent Address</h4>
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-6">
                  <label>Full Address</label>
                  <input
                    type="text"
                    class="form-control"
                    name="fa2"
                    id="fa2"
                    required
                    value={cfa2}
                    onChange={(e) => {
                      setCfa2(e.target.value);
                    }}
                  />
                </div>
                <div className="col-6">
                  <label>Landmark</label>
                  <input
                    type="text"
                    class="form-control"
                    name="lm2"
                    id="lm2"
                    required
                    value={clm2}
                    onChange={(e) => {
                      setClm2(e.target.value);
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-4">
                  <label>City/Town/Village</label>
                  <input
                    type="text"
                    class="form-control"
                    name="ct2"
                    id="ct2"
                    required
                    value={cct2}
                    onChange={(e) => {
                      setCct2(e.target.value);
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Pincode</label>
                  <input
                    type="number"
                    class="form-control"
                    name="pc2"
                    id="pc2"
                    required
                    value={cpc2}
                    onChange={(e) => {
                      if (e.target.value.length !== 6) {
                        setPinCode2Err("Pincode must be 6 digits");
                        setCpc2(e.target.value);
                      } else {
                        setPinCode2Err("");
                        setCpc2(e.target.value);
                      }
                    }}
                  />
                  {pinCode2Err && (
                    <em style={{ color: "#EE4B46" }}>{pinCode2Err}</em>
                  )}
                </div>
                <div className="col-4">
                  <label>State</label>
                  <input
                    type="text"
                    class="form-control"
                    name="st2"
                    id="st2"
                    required
                    value={cst2}
                    onChange={(e) => {
                      setCst2(e.target.value);
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-4">
                  <label>Post Office</label>
                  <input
                    type="text"
                    class="form-control"
                    name="st1"
                    id="st1"
                    required
                    value={cpo2}
                    onChange={(e) => {
                      setCpo2(e.target.value);
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Police Station</label>
                  <input
                    type="text"
                    class="form-control"
                    name="st1"
                    id="st1"
                    required
                    value={cps2}
                    onChange={(e) => {
                      setCps2(e.target.value);
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row justify-content-center">
                <div className="col-12">
                  <h4>Correspondent Address</h4>
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-6">
                  <label>Full Address</label>
                  <input
                    type="text"
                    class="form-control"
                    name="fa2"
                    id="fa2"
                    required
                    value={fa2}
                    onChange={(e) => {
                      setFa2(e.target.value);
                    }}
                  />
                </div>
                <div className="col-6">
                  <label>Landmark</label>
                  <input
                    type="text"
                    class="form-control"
                    name="lm2"
                    id="lm2"
                    required
                    value={lm2}
                    onChange={(e) => {
                      setLm2(e.target.value);
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-4">
                  <label>City/Town/Village</label>
                  <input
                    type="text"
                    class="form-control"
                    name="ct2"
                    id="ct2"
                    required
                    value={ct2}
                    onChange={(e) => {
                      setCt2(e.target.value);
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Pincode</label>
                  <input
                    type="number"
                    class="form-control"
                    name="pc2"
                    id="pc2"
                    required
                    value={pc2}
                    onChange={(e) => {
                      if (e.target.value.length !== 6) {
                        setPinCodeErr("Pincode must be of 6 digits");
                        setPc2(e.target.value);
                      } else {
                        setPc2(e.target.value);
                        setPinCodeErr("");
                      }
                    }}
                  />
                  {pinCodeErr && (
                    <em style={{ color: "#EE4B46" }}>{pinCodeErr}</em>
                  )}
                </div>
                <div className="col-4">
                  <label>State</label>
                  <input
                    type="text"
                    class="form-control"
                    name="st2"
                    id="st2"
                    required
                    value={st2}
                    onChange={(e) => {
                      setSt2(e.target.value);
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-4">
                  <label>Post Office</label>
                  <input
                    type="text"
                    class="form-control"
                    name="st1"
                    id="st1"
                    required
                    value={po2}
                    onChange={(e) => {
                      setPo2(e.target.value);
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Police Station</label>
                  <input
                    type="text"
                    class="form-control"
                    name="st1"
                    id="st1"
                    required
                    value={ps2}
                    onChange={(e) => {
                      setPs2(e.target.value);
                    }}
                  />
                </div>
              </div>
            </>
          )}

          <br />
          <br />

          <div className="mt-2">
            <div className="col-12 text-center">
              <button className="addapplicant__form__btn">Add Applicant</button>
            </div>
          </div>
        </form>
      </div>

      {/* /////////////////Modal for Payment Term spinner /////////// */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={spinnerModal}
        onClose={() => setSpinnerModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={spinnerModal}>
          <div>
            <ClipLoader
              color="#EE4B46"
              loading={spinnerModal}
              size={70}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default AddApplicant;
