import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './SalaryAdvance.scss'
import { BASE_URL } from '../../utils/config/url'
import Button from '../../components/Button/Button';
import { Nav } from 'react-bootstrap'
import Applied from '../../components/SalaryAdvance/Applied'
import HRApproved from '../../components/SalaryAdvance/HRApproved'
import OpenSalaryAdvance from '../../components/SalaryAdvance/OpenSalaryAdvance'
import Rejected from '../../components/SalaryAdvance/Rejected'
import { useNavigate } from 'react-router-dom'

const SalaryAdvance = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const [tab, setTab] = React.useState(userInfo?.userRole === 'Finance' ? 'HR Approved' : 'Applied')
    const navigate = useNavigate()

    return (
        <div className='salary-advance'>

            <div className='row justify-content-between mb-4'>
                <div className='col-4'>
                    <Button navigateTo='/home' />
                </div>

                <div className='col-4 text-center'>
                    <button className='salary-advance__btn'
                    onClick={()=>{
                        navigate('/limitApplications')
                    }}
                    >Applications for Limit</button>
                </div>
            </div>

            {
                (userInfo?.userRole != 'Director' && userInfo?.userRole != 'HR' && userInfo?.userRole != 'Finance')
                    ?
                    <h2 style={{ color: '#EE4B46', textAlign: 'center' }}>You do not have access to this section</h2>
                    :
                    <>
                        <div className="tabs mb-5">
                            <Nav>

                                {
                                    (userInfo?.userRole === 'Director' || userInfo?.userRole === 'HR')
                                    &&

                                    <Nav.Item
                                        onClick={() => {
                                            setTab('Applied')
                                        }}
                                    >
                                        <Nav.Link className={tab === 'Applied' ? 'active' : 'inactive'} eventKey="first">
                                            Applied
                                        </Nav.Link>
                                    </Nav.Item>
                                }

                                <Nav.Item
                                    onClick={() => {
                                        setTab('HR Approved')
                                    }}
                                >
                                    <Nav.Link className={tab === 'HR Approved' ? 'active' : 'inactive'} eventKey="second">
                                        HR Approved
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item
                                    onClick={() => {
                                        setTab("Open Salary Advance")
                                    }}
                                >
                                    <Nav.Link className={tab === 'Open Salary Advance' ? 'active' : 'inactive'} eventKey="fourth">
                                        Open Salary Advance
                                    </Nav.Link>
                                </Nav.Item>

                                {
                                    (userInfo?.userRole === 'Director' || userInfo?.userRole === 'HR')
                                    &&
                                    <Nav.Item
                                        onClick={() => {
                                            setTab('Rejected')
                                        }}
                                    >
                                        <Nav.Link className={tab === 'Rejected' ? 'active' : 'inactive'} eventKey="third">
                                            Rejected
                                        </Nav.Link>
                                    </Nav.Item>}

                            </Nav>

                        </div>

                        {tab === 'Applied' && <Applied />}
                        {tab === 'HR Approved' && <HRApproved />}
                        {tab === 'Open Salary Advance' && <OpenSalaryAdvance />}
                        {tab === 'Rejected' && <Rejected />}

                    </>
            }


        </div>
    )
}

export default SalaryAdvance