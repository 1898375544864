
export const dateFormatting = (date) => {
    let str = new Date(date);

    let istOptions = {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };

    let istDate = str.toLocaleString('en-IN', istOptions);

    return istDate.replace(',', ' ');
};