import React, { useState, useEffect } from "react"
import { useNavigate , useParams } from "react-router-dom"
import { Form } from "react-bootstrap";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import Swal from "sweetalert2";
import Button from '../../components/Button/Button';
import './AddUnitType.scss';

function AddUnitType() {

    const navigate = useNavigate();
    const siteCode = useParams().siteCode;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    
    const [un, setUn] = useState("")
    const [bhk, setBhk] = useState("")
    const [ca, setCa] = useState("")
    const [ba, setBa] = useState("")
    const [bua, setBua] = useState("")
    const [sbua, setSbua] = useState("")
    const [pc, setPc] = useState("")
    const [pn, setPn] = useState("")
    const [phase, setPhase] = useState([])
    const [siteInfo, setSiteInfo] = useState({ siteId: '', siteName: '' });

    const changePhase = (e) => {
        var phaseName = e.target.value  
        
      if(phaseName === ''){
          return setPc('')
      }else{
        phase.forEach((p)=>{
            if(p.phaseName === phaseName){
                return setPc(p.phaseCode)
            }
        })
      }
       
        setPn(phaseName)
    }

    const addUnitType = (e) => {
        const Token = "bearer" + " " + userInfo.token;

        e.preventDefault()
        axios
            .post(`${BASE_URL}/api/v1/Configuration/UnitType/addUnitType`,
                {
                    siteCode: siteCode,
                    siteName: siteInfo.siteName,
                    unitTypeName: un,
                    bhk: bhk,
                    carpetArea: ca,
                    balconyArea: ba,
                    builtUpArea: bua,
                    superBuiltUpArea: sbua,
                    phaseCode: pc,
                    phaseName: pn
                },
                { headers: { 'Authorization': Token } })

            .then(response => {
               
              if(response.data.status===true){
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    showClass: {
                      popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                      popup: "animate__animated animate__fadeOutUp",
                    }
                  }).then(function() {
                      setPc('')
                   navigate(-1)
                  })
              }else if(response.data.status === false){
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                      popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                      popup: "animate__animated animate__fadeOutUp",
                    },
                    text: 'The combination of Site Code and Unit Type Name is not unique.'
                  })
              }
                
            })
            .catch((err)=>{
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                      popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                      popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.data.error
                  })
            })
    }


    useEffect(() => {
        const Token = "bearer" + " " + userInfo.token;

        axios.get(`${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`,
            { headers: { Authorization: Token } })
            .then(response => {
               
                const site = response.data.site
                setSiteInfo({ siteName: site.siteName })
                setPhase(site.phases)

            })

    }, [])

    return (
        <div className="addunittype">
            <Button navigateTo={1} />
            <div className="addunittype__form">
                <div className="addunittype__form__title">
                    <h2>Add Unit Type</h2>
                </div>

                <div className="addunittype__form__info">
                    <div>Site Name - {siteInfo.siteName}</div>
                    <div>Site Code - {siteCode}</div>
                </div>

                <form onSubmit={addUnitType}>
                    <div className="row container-fluid justify-content-center">
                        <div className="col-4">
                            <label>Unit Type Name</label>
                            <input
                                required
                                type="text"
                                className="form-control"
                                name="unitname"
                                id="unitname"
                                autoComplete="off"
                                onChange={(e) => setUn(e.target.value)}
                            />
                        </div>
                        <div className="col-4">
                            <Form.Group controlId="bhk">
                                <Form.Label>BHK</Form.Label>
                                <Form.Control required as="select" onChange={(e) => setBhk(e.target.value)}>
                                    <option value="">Select a BHK</option>
                                    <option value="1BHK">1BHK</option>
                                    <option value="2BHK">2BHK </option>
                                    <option value="3BHK">3BHK</option>
                                    <option value="4BHK">4BHK</option>
                                    <option value="Duplex">Duplex</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                    </div>
                    <br />
                    <div className="row container-fluid justify-content-center">
                        <div className="col-4">
                            <label>Carpet Area</label>
                            <input
                                required
                                type="number"
                                className="form-control"
                                name="carpetarea"
                                id="carpetarea"
                                onChange={(e) => setCa(e.target.value)}
                            />
                        </div>
                        <div className="col-4">
                            <label>Balcony Area</label>
                            <input
                                required
                                type="number"
                                className="form-control"
                                name="balconyarea"
                                id="balconyarea"
                                onChange={(e) => setBa(e.target.value)}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="row container-fluid justify-content-center">
                        <div className="col-4">
                            <label>Built Up Area</label>
                            <input
                                required
                                type="number"
                                className="form-control"
                                name="builtuparea"
                                id="builtuparea"
                                onChange={(e) => setBua(e.target.value)}
                            />
                        </div>
                        <div className="col-4">
                            <label>Super Built Up Area</label>
                            <input
                                required
                                type="number"
                                className="form-control"
                                name="sbuarea"
                                id="sbuarea"
                                onChange={(e) => setSbua(e.target.value)}
                            />
                        </div>
                    </div>
                    <br />

                    <div className="row container-fluid justify-content-center">
                        <div className="col-4">
                            <Form.Group controlId="phasename">
                                <Form.Label>Phase Name</Form.Label>
                                <Form.Control required as="select" onChange={(e)=>changePhase(e)}>
                                    <option selected value="">Select a Phase</option>
                                    {phase.map((p)=>{
                                       return <option>{p.phaseName}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className="col-4">
                            <label>Phase Code</label>
                            <div className="phasecode">
                                {pc}
                            </div>
                        </div>
                    </div>


                    <div className="addunittype__form__btns">
                        <button type='reset' style={{ backgroundColor: 'white', border: '1px solid black', color: 'black' }}
                            onClick={() => { 
                                setPn('') 
                                setPc('')}}>
                                    Reset
                                    </button>
                        <button>Add Unit Type</button>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default AddUnitType;