import React, { useEffect, useState } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useParams } from 'react-router-dom';
import { useStyles } from '../../utils/ModalStyles';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import Button from '../../components/Button/Button';
import './ListOfFlats.scss';

const ListOfFlats = () => {

  const navigate = useNavigate();
  const classes = useStyles()
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const Token = 'bearer' + ' ' + userInfo.token

  const [form, setForm] = useState([]);
  const [sc, setSc] = useState("");
  const [siteData, setSiteData] = useState([]);
  const [status, setStatus] = useState("");
  const [phases, setPhases] = useState([])
  const [pc, setPc] = useState('')

  useEffect(() => {
    getListOfFlats()
  }, [])

  const getListOfFlats = () => {
    axios
      .get(`${BASE_URL}/api/v1/Configuration/Unit/getListOfUnit`, {
        headers: { Authorization: Token },
      })
      .then((response) => {
        // console.log(response)
        setForm(response.data.unitList);
      });
  }


  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/Configuration/Site/getAllSiteNames`, {
        headers: { Authorization: Token },
      })
      .then((response) => {
        //console.log(response)
        setSiteData(response.data.siteList);
      });
  }, []);


  // useEffect(() => {

  //   if (sc === "" && pc === "" && status === "") {
  //     axios
  //       .get(`${BASE_URL}/api/v1/Configuration/Unit/getlistofunit`, {
  //         headers: { Authorization: Token },
  //       })
  //       .then((response) => {
  //         // console.log(response)
  //         setForm(response.data.unitList);

  //       });
  //   }
  //   else if (sc !== "" && pc === "" && status === "") {
  //     axios
  //       .get(`${BASE_URL}/api/v1/Configuration/Unit/getlistofunit?siteCode=${sc}`, {
  //         headers: { Authorization: Token },
  //       })
  //       .then((response) => {
  //         // console.log(response)
  //         setForm(response.data.unitList);
  //       });
  //   }
  //   else if (sc !== "" && pc !== "" && status === "") {

  //     axios
  //       .get(
  //         `${BASE_URL}/api/v1/Configuration/Unit/getlistofunit?siteCode=${sc}&phaseCode=${pc}`,
  //         { headers: { Authorization: Token } }
  //       )
  //       .then((response) => {
  //         //console.log(response)
  //         setForm(response.data.unitList);
  //       });
  //   }
  //   else if (sc !== "" && pc !== "" && status !== "") {
  //     axios
  //       .get(
  //         `${BASE_URL}/api/v1/Configuration/Unit/getlistofunit?siteCode=${sc}&phaseCode=${pc}&status=${status}`,
  //         { headers: { Authorization: Token } }
  //       )
  //       .then((response) => {
  //         //console.log(response);
  //         setForm(response.data.unitList);
  //       });
  //   }
  // }, [sc, pc, status])

  useEffect(() => {

    if (sc === '') {
      setPc('')
    }

    if (sc === '' && status === '') {
      setPc('')
      return getListOfFlats()
    }
    // console.log(category, subCategory, status)
    let params = [];

    if (sc) {
      params.push('siteCode')
    }
    if (pc) {
      params.push('phaseCode')
    }
    if (status) {
      params.push('status')
    }

    let url = []
    params.forEach((p, index) => {
      if (p === 'siteCode') {

        if (index === 0) {
          url.push(`?${p}=${sc}`)
        } else {
          url.push(`&${p}=${sc}`)

        }
      }
      if (p === 'phaseCode') {
        if (index === 0) {
          url.push(`?${p}=${pc}`)
        } else {
          url.push(`&${p}=${pc}`)

        }
      }
      if (p === 'status') {

        if (index === 0) {
          url.push(`?${p}=${status}`)
        } else {
          url.push(`&${p}=${status}`)

        }
      }

    })

    //  console.log(url.join(''))

    axios
      .get(`${BASE_URL}/api/v1/Configuration/Unit/getListOfUnit${url.join('')}`, {
        headers: { Authorization: Token },
      })
      .then((response) => {
        // console.log(response)
        if (response.data.unitList) {
          setForm(response.data.unitList);
        } else {
          setForm([])
        }
      });

  }, [sc, pc, status])

  const getPhaseList = (e) => {
    e.preventDefault()
    if (e.target.value === "") {
      return
    }

    axios
      .get(`${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${e.target.value}`,
        { headers: { Authorization: Token } })
      .then((response) => {
        // console.log(response)
        setPhases(response.data.site.phases)
      })
  }

  const reset = () => {
    setSc('')
    setPc('')
    setStatus('')
  }


  return (
    <div className='listofflats'>

      <Button navigateTo={'/home'} />
      <br />
      <br />
      <h3>Units</h3>
      <div className="row container-fluid-justify-content-center">
        <div className="col-12 mt-2">
          <MaterialTable
            data={form}
            title=""
            columns={[
              { title: "Status", field: "status" },
              { title: "Unit Name", field: "unitName" },
              { title: "Unit Type", field: "unitTypeName" },

              { title: "Unit Floor", field: "unitFloor" },
              { title: "Unit Phase", field: "phaseName" },
              { title: "On Hold", field: "unitOnHoldApproval",
              render: rowData => rowData?.unitOnHoldApproval ? "Yes" : "No", },
              { title: "BHK", field: "bhk" },
            ]}
            options={{
              search: window.innerWidth < 700 ? false : true,
              searchFieldStyle: {
                width: 180
              },
              actionsCellStyle: {
                width: 120,
                paddingLeft: 30
              },
              actionsColumnIndex: 0,
              paging: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 20, 30],
              headerStyle: {

                backgroundColor: "#EE4B46",
                color: "#fff",
                paddingLeft: "11px",
              },
            }}

            components={{
              Toolbar: (props) => (
                <div className="filters text-left">
                  <MTableToolbar {...props} />

                  <FormControl variant="standard"
                    className={classes.formControl}
                    style={{
                      margin: '0px 13px',
                      marginTop: "-65px",
                      width: "100px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label"
                      style={{ fontSize: '15px' }}>
                      Site Name
                    </InputLabel>
                    <Select labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                      value={sc}
                      onChange={(e) => {
                        setSc(e.target.value)
                        setPc('')
                        getPhaseList(e)
                      }}
                      style={{
                        fontSize: '15px',
                        textAlign: 'left'
                      }}
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">
                        Site Name
                      </MenuItem>
                      {siteData.map((s) => (
                        <MenuItem key={s.SiteCode} value={s.SiteCode}>
                          {s.SiteName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl variant="standard"
                    className={classes.formControl}
                    style={{
                      margin: '0px 13px',
                      marginTop: "-65px",
                      width: "150px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Phase
                    </InputLabel>
                    <Select llabelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                      value={pc}
                      onChange={(e) => setPc(e.target.value)}
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                      disabled={sc === "" ? true : false}
                    >
                      <MenuItem value="">
                        Phase
                      </MenuItem>
                      {
                        phases.map((p) => {
                          return <MenuItem key={p._id} value={p.phaseCode}>{p.phaseName}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>

                  {window.innerWidth < 1000 && <br />}

                  <FormControl variant="standard"
                    className={classes.formControl}
                    style={{
                      margin: '0px 13px',
                      marginTop: window.innerWidth < 1000 ? '-30px' : '-65px',
                      width: '200px',
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Status
                    </InputLabel>
                    <Select llabelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">
                        Status
                      </MenuItem>
                      <MenuItem value="Available">Available</MenuItem>
                      <MenuItem value="On Hold">On Hold</MenuItem>
                      <MenuItem value="Booking Initiated">Booking Initiated</MenuItem>
                      <MenuItem value="Alloted">Alloted</MenuItem>
                      <MenuItem value="Handover Initiated">Handover Initiated</MenuItem>
                      <MenuItem value="Registraion Done">Registraion Done</MenuItem>
                      <MenuItem value="In Maintanance">In Maintanance</MenuItem>
                    </Select>
                  </FormControl>

                  {window.innerWidth < 1100 && window.innerWidth > 1000 && <br />}

                  <FormControl
                    className={classes.formControl}
                    style={{ marginTop: window.innerWidth < 1100 ? '-20px' : '-50px'  }}
                  >
                    <button
                      onClick={reset}
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        border: '1px solid black',
                        borderRadius: '20px',
                        padding: '2px'
                      }}
                    >
                      Reset Filter
                    </button>
                  </FormControl>
                </div>
              ),
            }}

            actions={[
              {
                icon: "remove_red_eye",
                tooltip: "View Unit",
                onClick: (event, rowData) => {
                  localStorage.setItem("ActiveKeyUnit", "first");
                  navigate(`/flatAllotment/flats/${rowData.unitName}`);
                }
              }
            ]}
          ></MaterialTable>
        </div>
      </div>

    </div>
  )
}

export default ListOfFlats