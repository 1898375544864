import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../utils/config/url';
import MaterialTable from 'material-table';
import '../PaymentTerms/PaymentTerms.scss';

const PaymentSchedule = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const Token = 'bearer' + " " + userInfo.token
    const applicationId = useParams().applicationId
    const navigate = useNavigate()
    const [paymentSchedule, setPaymentSchedule] = useState([])

    useEffect(() => {
        axios
            .get(`${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/getBookingApplicationByApplicationId/${applicationId}`,
                { headers: { Authorization: Token } })
            .then((response) => {

                setPaymentSchedule(response.data.BookingApplication.paymentSchedule)
            })
    }, [])


    return (
        <div className='paymentTerms'
            style={{ marginTop: '50px' }}>

            <MaterialTable
                data={paymentSchedule}
                title="Payment Schedule"
                columns={
                    [
                        {
                            title: 'Serial No.', field: 'serial', cellStyle: {
                                paddingLeft: '40px'
                            }
                        },
                        { title: 'Description', field: 'description' },
                        {
                            title: 'Percentage', field: 'percentage', cellStyle: {
                                paddingLeft: '45px'
                            }
                        },
                        { title: 'Item Total', field: 'itemTotal' },
                        { title: 'Cumulative Total', field: 'cumulativeTotal' },
                    ]
                }
                options={{
                    search: true,
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#EE4B46',
                        color: '#fff',
                    },
                }}

            ></MaterialTable>
        </div>
    )
}

export default PaymentSchedule