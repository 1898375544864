import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button';
import { Form } from "react-bootstrap";
import './ReceiveChequeForm.scss'
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const ReceiveChequeForm = () => {

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const navigate = useNavigate();

  const [category, setCategory] = useState('')
  const [subCategory, setSubCategory] = useState('')
  const [receivedDate, setReceivedDate] = useState('')
  const [amount, setAmount] = useState()
  const [description, setDescription] = useState('')
  const [cheque, setCheque] = useState('')
  const [bankname, setbankname] = useState('')
  const [accountNo, setAccountNo] = useState()
  const [issuedTo, setIssuedTo] = useState('')
  const [issuedBy, setIssuedBy] = useState('')
  const [micrNo, setMicrNo] = useState('')
  const [error, setError] = useState('')
  const [error2, setError2] = useState('')

  const [siteCode, setSiteCode] = useState('')
  const [sites, setSites] = useState([])
  const [phaseCode, setPhaseCode] = useState('')
  const [phases, setPhases] = useState([])
  const [customerIds, setCustomerIds] = useState([])
  const [customerId, setCustomerId] = useState('')
  const [instrumentType, setInstrumentType] = useState('')


  useEffect(() => {
    axios.get(`${BASE_URL}/api/v1/Configuration/Site/getAllSites`, {
      headers: { Authorization: `bearer ${userInfo.token}` }
    })
      .then((response) => {
        // console.log(response)
        setSites(response.data.siteArray)
      })
  }, [])

  useEffect(() => {

    if (!siteCode || siteCode === ' ') {
      setPhaseCode('')
      setSiteCode('')
      return
    }

    axios.get(`${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`, {
      headers: { Authorization: `bearer ${userInfo.token}` }
    })
      .then((response) => {
        //console.log(response)
        setPhases(response.data.site.phases)
      })
  }, [siteCode])


  useEffect(() => {

    if (!siteCode || !phaseCode) {
      return
    }

    axios.get(`${BASE_URL}/api/v1/CustomerMgmt/Customer/getListOfCustomers?siteCode=${siteCode}&phaseCode=${phaseCode}&isActive=true`,
      {
        headers: { Authorization: `bearer ${userInfo.token}` }
      })
      .then((response) => {
        //console.log(response)
        let arr = []
        response.data.customerList?.forEach((c) => {
          arr.push(c.customerId)
        })

        setCustomerIds(arr)
      })

  }, [siteCode, phaseCode])


  const handleFormSubmit = (e) => {
    e.preventDefault()

    if (error || error2) {
      return
    }

    let body = {};

    body = {
      chequeNo: cheque,
      chequeStatus: 'Received',
      paymentType: 'Credit',
      paymentCategory: category,
      paymentSubCategory: subCategory,
      chequeBankName: bankname,
      chequeAccountNo: accountNo,
      MICRNo: micrNo,
      chequeDate: receivedDate,
      issuedTo: issuedTo,
      issuedBy: issuedBy,
      chequeAmount: parseInt(amount),
      chequeDescription: description,
      customerId: customerId,
      siteCode,
      phaseCode,
      collectedBy: userInfo.userFullName,
      instrumentType: instrumentType
    }


    // console.log(body)

    axios.post(`${BASE_URL}/api/v1/FinanceMgmt/Cheque/receivecheque`,
      body,
      {
        headers: { Authorization: `bearer ${userInfo.token}` },
      },
    )
      .then((response) => {

        // console.log(response)

        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: 'Form Submitted Successfully',
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            }
          }).then(function () {
            navigate(-1)
          })
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message
          })
        }
      })
      .catch((err) => {

        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error
        })
      })
  }
  // console.log(new Date(receivedDate))

  return (
    <div className='receivecash'>

      <Button navigateTo={1} />

      <br />
      <br />

      <div className="row container-fluid justify-content-center" >
        <div className="col-4">
          <h2>Receive Cheque Form</h2>
        </div>
      </div>

      <br />
      <br />

      <form
        onSubmit={(e) => handleFormSubmit(e)}
      >

        <div className="row container-fluid justify-content-center">

          <div className='col-4'>
            <label>Received Date</label>
            <input type='date' className='form-control' required
              value={receivedDate}
              onChange={(e) => {
                setReceivedDate(e.target.value)
              }} />
          </div>


          <div className="col-4">
            <Form.Group controlId="payment-category">
              <Form.Label>Payment Category</Form.Label>
              <Form.Control as="select" required
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value)
                }}
              >
                <option value="">Select a Category</option>
                <option value="CustomerPayment">Customer Payment</option>
                <option value="OtherPayment">Other Payment</option>
              </Form.Control>
            </Form.Group>
          </div>

          {
            category === 'CustomerPayment' ?
              <div className='col-4'>
                <Form.Group controlId="payment-sub-category">
                  <Form.Label>Payment Sub-Category</Form.Label>
                  <Form.Control as="select" required
                    value={subCategory}
                    onChange={(e) => {
                      setSubCategory(e.target.value)
                    }}
                  >
                    <option value="">Select a Sub Category</option>
                    <option value="DemandPayment">Demand Payment</option>
                    <option value="FinalPayment">Final Payment</option>
                  </Form.Control>
                </Form.Group>
              </div>
              : category === 'OtherPayment' ?
                <div className='col-4'>
                  <Form.Group controlId="payment-sub-category">
                    <Form.Label>Payment Sub-Category</Form.Label>
                    <Form.Control as="select" required
                      value={subCategory}
                      onChange={(e) => {
                        setSubCategory(e.target.value)
                      }}
                    >
                      <option value="">Select a Sub Category</option>
                      <option value="MaterialsSold">Materials Sold</option>
                      <option value="OtherPayment">Other Payment</option>
                    </Form.Control>
                  </Form.Group>
                </div>

                :

                <div className='col-4'>
                  <Form.Group controlId="payment-sub-category">
                    <Form.Label>Payment Sub-Category</Form.Label>
                    <Form.Control as="select" required
                      value={subCategory}
                      onChange={(e) => {
                        setSubCategory(e.target.value)
                      }}
                    >
                      <option value="">Select a Sub Category</option>
                    </Form.Control>
                  </Form.Group>
                </div>
          }

        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className='col-3'>
            <label>Bank Name</label>
            <input type='text' className='form-control' required
              value={bankname}
              onChange={(e) => {
                setbankname(e.target.value)
              }} />
          </div>

          <div className='col-3'>
            <label>Account No.</label>
            <input type='number' className='form-control' required
              value={accountNo}
              onChange={(e) => {
                setAccountNo(e.target.value)
              }} />
          </div>

          <div className='col-3'>
            <label >Cheque No.</label>
            <input
              type={'text'}
              required
              className='form-control'
              value={cheque}
              onChange={(e) => {
                if (e.target.value.length !== 6) {
                  setError('Cheque No. must be of 6 digits')
                  setCheque(e.target.value)
                } else {
                  setError('')
                  setCheque(e.target.value)
                }
              }}
            />
            <p style={{ color: '#EE4B46', paddingTop: '5px' }}><em>{error}</em></p>
          </div>

          <div className='col-3'>
            <label>MICR No.</label>
            <input type='number' className='form-control' required
              value={micrNo}
              onChange={(e) => {
                if (e.target.value.length !== 9) {
                  setError2('MICR No. must be of 9 digits')
                  setMicrNo(e.target.value)
                } else {
                  setError2('')
                  setMicrNo(e.target.value)
                }

              }} />
            <p style={{ color: '#EE4B46', paddingTop: '5px' }}><em>{error2}</em></p>

          </div>

        </div>

        <br />

        <div className="row container-fluid justify-content-center">

          <div className='col-4'>
            <label>Amount</label>
            <input type='number' className='form-control' required
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value)
              }} />
          </div>

          <div className='col-4'>
            <Form.Group controlId="payment-sub-category">
              <Form.Label>Issued To</Form.Label>
              <Form.Control as="select" required
                value={issuedTo}
                onChange={(e) => {
                  let value = e.target.value;
                  setIssuedTo(value);
                }}
              >
                <option value="">Select a Name</option>
                <option value="WESTROAD DEVELOPERS PVT LTD A/C">WESTROAD DEVELOPERS PVT LTD A/C</option>
                <option value="WESTROAD HEIGHTS COLLECTION A/C">WESTROAD HEIGHTS COLLECTION A/C</option>
              </Form.Control>
            </Form.Group>
          </div>

          <div className='col-4'>
            <label>Issued By</label>
            <input type='text' className='form-control' required
              value={issuedBy}
              onChange={(e) => {
                setIssuedBy(e.target.value)
              }} />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className='col-4'>
            <Form.Group controlId="Site-Name">
              <Form.Label>Site Name</Form.Label>
              <Form.Control as="select" required
                value={siteCode}
                onChange={(e) => {
                  setSiteCode(e.target.value)
                }}
              >
                <option value=" ">Select a Site</option>
                {
                  sites?.map((s, index) => {
                    return <option key={index} value={s.siteCode}>{s.siteName}</option>
                  })
                }
              </Form.Control>
            </Form.Group>
          </div>

          <div className='col-4'>
            <Form.Group controlId="Site-Name">
              <Form.Label>Phase Name</Form.Label>
              <Form.Control as="select" required
                value={phaseCode}
                onChange={(e) => {
                  setPhaseCode(e.target.value)
                }}
              >
                <option value=" ">Select a Phase</option>
                {
                  phases?.map((p, index) => {
                    return <option key={index} value={p.phaseCode}>{p.phaseName}</option>
                  })
                }
              </Form.Control>
            </Form.Group>
          </div>

          <div className='col-4'>
            <Form.Group controlId="Site-Name">
              <Form.Label>Customer ID</Form.Label>
              <Form.Control as="select" required
                value={customerId}
                onChange={(e) => {
                  setCustomerId(e.target.value)
                }}
              >
                <option value="">Select a Customer ID</option>
                {
                  customerIds?.map((c, index) => {
                    return <option key={index} value={c}>{c}</option>
                  })
                }
              </Form.Control>
            </Form.Group>
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">

          <div className='col-4'>
            <Form.Group controlId="payment-sub-category">
              <Form.Label>Instrument Type</Form.Label>
              <Form.Control as="select" required
                value={instrumentType}
                onChange={(e) => {
                  setInstrumentType(e.target.value)
                }}
              >
                <option value="">Select an Option</option>
                <option value="Cheque">Cheque</option>
                <option value="DemandDraft">Demand Draft</option>
              </Form.Control>
            </Form.Group>
          </div>

          <div className='col-8'>
            <label>Description</label>
            <textarea className='form-control' value={description} placeholder="Description of Cheque "
              onChange={(e) => setDescription(e.target.value)} />
          </div>
        </div>

        <br />
        <br />
        <div className="row container-fluid justify-content-center">
          <button type='submit' className='submit-btn'>Submit</button>
        </div>
      </form>

    </div>
  )
}

export default ReceiveChequeForm