import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { dateFormatting } from '../../utils/dateFormatting';
import { Fade, FormControl, InputLabel, MenuItem, Modal, Select } from '@mui/material';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const ListOfPendingCompOff = () => {

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const navigate = useNavigate();

    const [compOffList, setCompOffList] = useState([])
    const [modal, setModal] = useState(false)
    const [compOffId, setCompOffId] = useState('')
    const [approvalComment, setApprovalComment] = useState('')
    const [isApproved, setIsApproved] = useState(false)

    useEffect(() => {

        getListOfCompOff()

    }, [])

    const getListOfCompOff = () => {

        let url = `${BASE_URL}/api/v1/hrmgmt/compOff/getListOfCompOff?lineManagerId=${userInfo?.employeeId}&compOffStatus=Applied`;

        axios.get(url,
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status) {
                    setCompOffList(response.data.compOffList)
                } else {
                    setCompOffList([])
                }
            })

    }

    const approvalOrRejectCompOff = (e) => {
        e.preventDefault();

        axios.put(`${BASE_URL}/api/v1/hrmgmt/compOff/approveCompOff`,
            {
                compOffId,
                approvalComment,
                isApproved
            },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                setModal(false)
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message
                    })
                        .then(() => {
                            getListOfCompOff();
                            setApprovalComment('')
                            setCompOffId('')
                            setIsApproved(false)
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message
                    })
                }
            })
            .catch((err) => {
                // console.log(err)
                setModal(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error
                })
            })

    }

    return (
        <div
            style={{
                width: '100%',
                margin: '0 auto'
            }}
        >
            <MaterialTable
                data={compOffList}
                title=""

                columns={[
                    {
                        title: "",
                        render: (rowData) => (
                            <div className='d-flex'>
                                <button
                                    className='compOff__btn'
                                    disabled={rowData.compOffStatus === 'Approved' || rowData.compOffStatus === 'Rejected'}
                                    style={{
                                        backgroundColor: rowData.compOffStatus === 'Approved' || rowData.compOffStatus === 'Rejected' ? 'grey' : '#EE4B46'
                                    }}
                                    onClick={() => {
                                        setModal(true)
                                        setCompOffId(rowData.compOffId)
                                        setIsApproved(true)
                                    }}
                                >
                                    Approve
                                </button>

                                <button
                                    className='compOff__btn ml-2'
                                    disabled={rowData.compOffStatus === 'Approved' || rowData.compOffStatus === 'Rejected'}
                                    style={{
                                        backgroundColor: rowData.compOffStatus === 'Approved' || rowData.compOffStatus === 'Rejected' ? 'grey' : '#EE4B46'
                                    }}
                                    onClick={() => {
                                        setModal(true)
                                        setCompOffId(rowData.compOffId)
                                    }}
                                >
                                    Reject
                                </button>
                            </div>
                        )
                    },
                    { title: "CompOff ID", field: "compOffId" },
                    { title: "Status", field: "compOffStatus" },
                    {
                        title: "Type",
                        field: "compOffType",
                    },
                    { title: "Raise By", field: "raisedByFullName" },
                    { title: "Raise By EmpId", field: "raisedByEmpId" },
                    {
                        title: "Applied At",
                        render: (rowData) => (dateFormatting(rowData.appliedDate))
                    },
                    {
                        title: "Comp Off Date",
                        render: (rowData) => (dateFormatting(rowData.compOffDate))
                    },
                    { title: "Line Manager", field: "lineManagerFullName" },
                ]}

                actions={[
                    {
                        icon: 'remove_red_eye',
                        tooltip: "Edit",
                        onClick: (event, rowData) => {
                            navigate('/leavemanagement/compOff/' + rowData.compOffId)
                        },
                    },
                ]}


                options={{
                    search: window.innerWidth < 700 ? false : true,
                    searchFieldStyle: {
                        width: 180,
                    },
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    actionsColumnIndex: 0,
                    headerStyle: {
                        backgroundColor: "#EE4B46",
                        color: "#fff",
                    },
                }}

            // components={{
            //     Toolbar: (props) => (
            //         <div className="filters text-left">
            //             <MTableToolbar {...props} />

            //             <FormControl
            //                 variant="standard"
            //                 className="formControl"
            //                 style={{
            //                     margin: "0px 10px",
            //                     marginTop: "-65px",
            //                     width: "90px",
            //                 }}
            //             >
            //                 <InputLabel id="demo-simple-select-standard-label">
            //                     Status
            //                 </InputLabel>
            //                 <Select
            //                     llabelId="demo-simple-select-standard-label"
            //                     id="demo-simple-select-standard"
            //                     value={status}
            //                     onChange={(e) => {
            //                         setStatus(e.target.value);
            //                     }}
            //                     className="selectEmpty"
            //                     style={{
            //                         fontSize: "13px",
            //                         textAlign: "left",
            //                     }}
            //                     inputProps={{ "aria-label": "Without label" }}
            //                 >
            //                     <MenuItem value="">Select a option</MenuItem>
            //                     <MenuItem value="Applied">Applied</MenuItem>
            //                     <MenuItem value="Approved">Approved</MenuItem>
            //                     <MenuItem value="Rejected">Rejected</MenuItem>
            //                 </Select>
            //             </FormControl>

            //             <FormControl
            //                 variant="standard"
            //                 className="formControl"
            //                 style={{
            //                     margin: "0px 10px",
            //                     marginTop: "-65px",
            //                     width: "120px",
            //                 }}
            //             >
            //                 <InputLabel id="demo-simple-select-standard-label">
            //                     Employee
            //                 </InputLabel>
            //                 <Select
            //                     llabelId="demo-simple-select-standard-label"
            //                     id="demo-simple-select-standard"
            //                     value={employee}
            //                     onChange={(e) => setEmployee(e.target.value)}
            //                     className="selectEmpty"
            //                     inputProps={{ "aria-label": "Without label" }}
            //                     style={{
            //                         fontSize: "13px",
            //                         textAlign: "left",
            //                     }}
            //                 >
            //                     <MenuItem value="">Select a employee</MenuItem>
            //                     {
            //                         employeeList?.map((employee, index) => {
            //                             return <MenuItem key={index} value={employee?.employeeId}>{employee?.employeeFullName}</MenuItem>
            //                         })
            //                     }
            //                 </Select>
            //             </FormControl>

            //             {window.innerWidth < 1100 && <br />}

            //             <FormControl
            //                 variant="standard"
            //                 className="formControl"
            //                 style={{
            //                     margin: "0px 10px",
            //                     marginTop: "-65px",
            //                     width: "90px",
            //                 }}
            //             >
            //                 <InputLabel id="demo-simple-select-standard-label">
            //                     Days
            //                 </InputLabel>
            //                 <Select
            //                     llabelId="demo-simple-select-standard-label"
            //                     id="demo-simple-select-standard"
            //                     value={day}
            //                     onChange={(e) => {
            //                         setDay(e.target.value);
            //                     }}
            //                     className="selectEmpty"
            //                     style={{
            //                         fontSize: "13px",
            //                         textAlign: "left",
            //                     }}
            //                     inputProps={{ "aria-label": "Without label" }}
            //                 >
            //                     <MenuItem value="">Select a option</MenuItem>
            //                     <MenuItem value="">All</MenuItem>
            //                     <MenuItem value={30}>30</MenuItem>
            //                     <MenuItem value={90}>90</MenuItem>
            //                     <MenuItem value={365}>365</MenuItem>
            //                 </Select>
            //             </FormControl>

            //             <FormControl
            //                 className="formControl"
            //                 style={{
            //                     marginTop: "-50px",
            //                     marginRight: "110px",
            //                     marginLeft: "10px",
            //                 }}
            //             >
            //                 <button
            //                     onClick={reset}
            //                     style={{
            //                         backgroundColor: "white",
            //                         color: "black",
            //                         border: "1px solid black",
            //                         borderRadius: "20px",
            //                         padding: "2px 7px",
            //                     }}
            //                 >
            //                     Reset Filter
            //                 </button>
            //             </FormControl>
            //         </div>
            //     ),
            // }}

            ></MaterialTable>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={modal}
                onClose={() => setModal(false)}
                closeAfterTransition
            >
                <Fade in={modal}>
                    <form className="paper">
                        <h6>Are you sure you want to {isApproved === true ? 'Approve' : 'Reject'} {compOffId} ?</h6>
                        <br />
                        <div className="row container-fluid justify-content-center">
                            <div className='col-12'>
                                <label>{isApproved ? 'Approval Comment' : 'Rejection Comment'}</label>
                                <input type="text" className='form-control' required value={approvalComment}
                                    onChange={(e) => setApprovalComment(e.target.value)}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row container-fluid justify-content-center">
                            <div className="col-6 text-right">
                                <button className="modal__btns"
                                    onClick={(e) => {
                                        approvalOrRejectCompOff(e)
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                            <div className="col-6 text-left">
                                <button
                                    className="modal__btns"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setModal(false)
                                        setCompOffId('')
                                    }}
                                    style={{
                                        backgroundColor: "white",
                                        color: "black",
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </Fade>
            </Modal>

        </div>
    )
}

export default ListOfPendingCompOff;