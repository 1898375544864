import React from 'react'
import Paper from "@mui/material/Paper";

const SalaryStructure = ({ salaryAccount }) => {
  const { accountNumber } = salaryAccount
  return (
    <div>
      <Paper elevation={4} style={{ padding: '1rem', marginTop: '2rem' }} >
        <br />
        <div className="row justify-content-start">
          <div className="col-3">
            <label>Account Number</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={accountNumber}
            />
          </div>
        </div>
      </Paper>
      <br />
    </div>
  )
}

export default SalaryStructure