import axios from "axios";
import React, { useEffect } from "react";
import { BASE_URL } from "../../utils/config/url";
import { useParams } from "react-router-dom";

const Details = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  let salaryAdvanceId = useParams().salaryAdvanceId;
  const [salaryAdvance, setSalaryAdvance] = React.useState({});

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/salaryAdvance/getSalaryAdvanceById/${salaryAdvanceId}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setSalaryAdvance(response.data.SalaryAdvance);
        } else {
          setSalaryAdvance({});
        }
      });
  }, []);

  const dateFormatting = (input) => {
    if (!input) return "N/A";

    let date = new Date(input).getDate();
    if (date < 10) {
      date = "0" + date;
    }

    let month = new Date(input).getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }

    const year = new Date(input).getFullYear();

    return `${date}/${month}/${year}`;
  };

  return (
    <div className="inv-salary-advance__details">
      <div className="row justify-content-center">
        <div className="col-6">
          <label>Salary Advance Id</label>
          <input
            type="text"
            readOnly
            className="form-control"
            value={salaryAdvance?.salaryAdvanceId}
          />
        </div>
      </div>

      <br />

      <div className="row justify-content-center">
        <div className="col-6">
          <label>Status</label>
          <input
            type="text"
            readOnly
            className="form-control"
            value={salaryAdvance?.status}
          />
        </div>

        <div className="col-6">
          <label>Application Date</label>
          <input
            type="text"
            readOnly
            className="form-control"
            value={dateFormatting(salaryAdvance?.applicationDate)}
          />
        </div>
      </div>

      <br />

      <div className="row justify-content-center">
        <div className="col-8">
          <label>Request Reason</label>
          <textarea
            type="text"
            readOnly
            className="form-control"
            value={salaryAdvance?.requestReason}
          />
        </div>
      </div>

      <br />

      <div className="row justify-content-center">
        <div className="col-6">
          <label>Requested Amount</label>
          <input
            type="text"
            readOnly
            className="form-control"
            value={salaryAdvance?.requestedAmount}
          />
        </div>

        <div className="col-6">
          <label>Approved Amount</label>
          <input
            type="text"
            readOnly
            className="form-control"
            value={salaryAdvance?.approvedAmount}
          />
        </div>
      </div>

      <br />

      <div className="row justify-content-center">
        <div className="col-6">
          <label>Approved By</label>
          <input
            type="text"
            readOnly
            className="form-control"
            value={salaryAdvance?.approvedByFullName}
          />
        </div>

        <div className="col-6">
          <label>Approval Date</label>
          <input
            type="text"
            readOnly
            className="form-control"
            value={dateFormatting(salaryAdvance?.approvalDate)}
          />
        </div>
      </div>

      <br />

      <div className="row justify-content-center">
        <div className="col-8">
          <label>Approval Reason</label>
          <textarea
            type="text"
            readOnly
            className="form-control"
            value={salaryAdvance?.approvalComment}
          />
        </div>
      </div>

      <br />

      <div className="row justify-content-center">
        <div className="col-6">
          <label>No. Of Installments</label>
          <input
            type="text"
            readOnly
            className="form-control"
            value={salaryAdvance?.noOfInstallments}
          />
        </div>

        <div className="col-6">
          <label>Paid Installments</label>
          <input
            type="text"
            readOnly
            className="form-control"
            value={salaryAdvance?.paidInstallments}
          />
        </div>
      </div>

      <br />

      <div className="row justify-content-center">
        <div className="col-6">
          <label>Installment Amount</label>
          <input
            type="text"
            readOnly
            className="form-control"
            value={salaryAdvance?.installmentAmount}
          />
        </div>

        <div className="col-6">
          <label>Remaining Installments</label>
          <input
            type="text"
            readOnly
            className="form-control"
            value={salaryAdvance?.remainingAmount}
          />
        </div>
      </div>
    </div>
  );
};

export default Details;
