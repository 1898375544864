import React, { useEffect } from 'react'
import './GeneratedPaySlip.scss'
import Nav from 'react-bootstrap/Nav';
import Button from '../../components/Button/Button';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Details from '../../components/GeneratedPaySlip/Details';
import AddIncentives from '../../components/GeneratedPaySlip/AddIncentives';
import AbsentAndLateDays from '../../components/GeneratedPaySlip/AbsentAndLateDays';
import SpecialDeductions from '../../components/GeneratedPaySlip/SpecialDeductions';

const GeneratedPaySlip = () => {

    const [tab, setTab] = React.useState('Details')
    const [paySlip, setPaySlip] = React.useState({})

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const payslipNumber = useParams().payslipNumber

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/payslip/getPayslipByPayslipNumber?payslipNumber=${payslipNumber}&payslipStatus=Payslip%20Generated`,
        {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        })
            .then((response) => {
                // console.log(response)
                setPaySlip(response.data.payslip)
            })

    }, [])

    return (
        <div className='generatedPaySlip'>

            <div className='row justify-content-between'>

                <div className='col-2 text-center'>
                    <Button navigateTo='/payRoll' />
                </div>

                <div className='col-10 text-center'>
                    <div className='row d-flex justify-content-evenly py-2'>

                        <div className='col-4'>
                            <h5><b>Employee : </b>{paySlip?.employeeFullName}</h5>
                        </div>

                        <div className='col-4'>
                            <h5><b>Month : </b>{paySlip?.monthName} {`(${paySlip?.noOfDaysInMonth} Days)`}</h5>
                        </div>

                        <div className='col-3'>
                            <h5><b>Year : </b>{paySlip?.year}</h5>
                        </div>

                    </div>
                </div>

            </div>

            <br />
            <br />

            <div className="tabs mb-5">
                <Nav>
                    <Nav.Item
                        onClick={() => {
                            setTab('Details')
                        }}
                    >
                        <Nav.Link className={tab === 'Details' ? 'active' : 'inactive'} eventKey="first">
                            Details
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        onClick={() => {
                            setTab('Add Incentives')
                        }}
                    >
                        <Nav.Link className={tab === 'Add Incentives' ? 'active' : 'inactive'} eventKey="second">
                            Add Incentives
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        onClick={() => {
                            setTab('Special Deduction')
                        }}
                    >
                        <Nav.Link className={tab === 'Special Deduction' ? 'active' : 'inactive'} eventKey="third">
                            Special Deduction
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        onClick={() => {
                            setTab("Adjustments")
                        }}
                    >
                        <Nav.Link className={tab === 'Adjustments' ? 'active' : 'inactive'} eventKey="fourth">
                            Adjust Absent & Late Days
                        </Nav.Link>
                    </Nav.Item>

                </Nav>

            </div>

            {tab === 'Details' && <Details paySlip={paySlip} />}
            {tab === 'Add Incentives' && <AddIncentives paySlip={paySlip} setPaySlip={setPaySlip} />}
            {tab === 'Adjustments' && <AbsentAndLateDays paySlip={paySlip} setPaySlip={setPaySlip} />}
            {tab === 'Special Deduction' && <SpecialDeductions paySlip={paySlip} setPaySlip={setPaySlip}/>}

        </div>
    )
}

export default GeneratedPaySlip