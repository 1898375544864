import React, { useEffect, useState } from "react";
import "./FollowUp.scss";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";
import ActionIcons from "./ActionIcons";
import { Modal, Fade, Backdrop } from "@mui/material";
import { useStyles } from "../../utils/ModalStyles";

const FollowUp = ({ setChange, change }) => {
  const classes = useStyles();
  const leadId = useParams().leadId;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + userInfo.token;
  const navigate = useNavigate();

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [comment, setComment] = useState("");
  const [leadConnects, setLeadConnects] = useState([]);
  const [open, setOpen] = useState(false);
  const [purpose, setPurpose] = useState("");
  const [closeComment, setCloseComment] = useState("");
  const [createBtn, setCreateBtn] = useState(true);

  const [followUpCount, setFollowUpCount] = useState("");
  const [followupBtn, setFollowupBtn] = useState(true);
  const [discussionBtn, setDiscussionBtn] = useState(true);
  const [closeLeadBtn, setCloseLeadBtn] = useState(true);
  const [closureReason, setClosureReason] = useState("");
  const [nextFollowupDate, setNextFollowupDate] = useState("");
  const [readonlyReason, setReadonlyReason] = useState(false);
  const [leadStatus, setLeadStatus] = useState("");
  const [lead, setLead] = useState("");

  const [reopenModal, setReopenModal] = useState(false);
  const [reopenComment, setReopenComment] = useState("");

  let now = new Date();
  let d = now.getDate();
  if (d < 10) {
    d = "0" + d;
  }

  let m = now.getMonth() + 1;
  if (m < 10) {
    m = "0" + m;
  }

  let y = now.getFullYear();
  let today = y + "-" + m + "-" + d;

  useEffect(() => {
    getLeadConnectByLeadID();
  }, []);

  const getLeadConnectByLeadID = () => {
    axios
      .get(
        `${BASE_URL}/api/v1/leadmgmt/lead/getLeadConnectByLeadID/${leadId}`,
        {
          headers: { Authorization: Token },
        }
      )
      .then((response) => {
        setLeadConnects(response?.data?.leadConnect);
        response?.data?.leadConnect.forEach((l) => {
          if (l.isOpen) {
            setCreateBtn(false);
          }
        });
      });
  };

  useEffect(() => {
    getLeadByLeadId();
  }, []);

  const getLeadByLeadId = () => {
    axios
      .get(`${BASE_URL}/api/v1/leadmgmt/lead/getLeadByLeadId/${leadId}`, {
        headers: { Authorization: Token },
      })
      .then((response) => {
        // console.log(response)
        setLead(response.data.lead);
        setFollowUpCount(response.data.lead.followUpCount);

        const nextDate = dateFormatting(response.data.lead.nextFollowUpDate);
        setNextFollowupDate(nextDate);

        setLeadStatus(response.data.lead.leadStatus);

        if (response.data.lead.leadStatus === "InDiscussion") {
          setFollowupBtn(false);
          setDiscussionBtn(false);
        }
        if (response.data.lead.followUpCount === 3) {
          setFollowUpCount(response.data.lead.followUpCount);
        }
        if (response.data.lead.closureDate) {
          setFollowupBtn(false);
          setDiscussionBtn(false);
          setCloseLeadBtn(false);
          setReadonlyReason(true);
          setClosureReason(response.data.lead.closureReason);
        }
      });
  };

  const openLeadConnect = (e) => {
    e.preventDefault();
    const body = {
      leadID: leadId,
      note: comment,
      connectDate: date,
      connectTime: time,
      userName: userInfo.userName,
      userFullName: userInfo.userFullName,
    };

    axios
      .post(`${BASE_URL}/api/v1/leadmgmt/lead/openLeadConnect`, body, {
        headers: { Authorization: Token },
      })
      .then((response) => {
        // console.log(response)
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Successfully Opened a Lead Connect",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            document.getElementById("form").reset();
            getLeadConnectByLeadID();
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err,
        });
      });
  };

  const closeLeadConnect = (e) => {
    e.preventDefault();

    axios
      .post(
        `${BASE_URL}/api/v1/leadmgmt/lead/closeLeadConnect`,
        {
          leadID: leadId,
          connectCloseComment: closeComment,
          userName: userInfo.userName,
          userFullName: userInfo.userFullName,
        },
        {
          headers: { Authorization: Token },
        }
      )
      .then((response) => {
        // console.log(response)
        setOpen(false);

        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Successfully closed Lead Connect",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setCreateBtn(true);
            setCloseComment("");
            getLeadConnectByLeadID();
          });
        }
      })
      .catch((err) => {
        setOpen(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err,
        });
      });
  };

  const changeFollowUpCount = (e, discussion) => {
    e.preventDefault();
    axios
      .put(
        `${BASE_URL}/api/v1/leadmgmt/lead/leadFollowUp`,
        {
          leadID: leadId,
          userName: userInfo.userName,
          userFullName: userInfo.userFullName,
          inDiscussion: discussion,
        },
        {
          headers: { Authorization: Token },
        }
      )
      .then((response) => {
        // console.log(response)
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setChange(!change);
            getLeadByLeadId();
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err,
        });
      });
  };

  const closeLead = (e) => {
    e.preventDefault();

    const status = closureReason.split(":")[1];
    const reason = closureReason.split(":")[0];

    axios
      .post(
        `${BASE_URL}/api/v1/leadmgmt/lead/closeLeadByLeadId`,
        {
          leadID: leadId,
          closureReason: reason,
          closureStatus: status,
          closureComments: closeComment,
          currentUserName: userInfo.userName,
          currentUserFullName: userInfo.userFullName,
        },
        {
          headers: { Authorization: Token },
        }
      )
      .then((response) => {
        // console.log(response)
        if (response.data.status === true) {
          setOpen(false);

          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setCloseComment("");
            setPurpose("");
            getLeadByLeadId();
            setChange(!change);
            window.location.reload();
          });
        }
      })
      .catch((err) => {
        setOpen(false);
        setCloseComment("");
        setPurpose("");
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  ///////// Date Formatting //////////

  const dateFormatting = (date) => {
    if (!date) return "N/A";

    let str = new Date(date);

    let d = new Date(str).getDate();
    if (d < 10) {
      d = `0${d}`;
    }
    let m = new Date(str).getMonth() + 1;
    if (m < 10) {
      m = `0${m}`;
    }
    let y = new Date(str).getFullYear();

    return `${d}-${m}-${y}`;
  };

  const reOpenLead = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.post(
        `${BASE_URL}/api/v1/leadmgmt/lead/reOpenLead`,
        {
          oldLeadID: leadId,
          comment: reopenComment,
          commentByCurrentUserName: userInfo.userName,
          commentByCurrentUserFullName: userInfo.userFullName,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      );

      setReopenModal(false);
      if (res.data.status === true) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(
          
          function () {
          setReopenComment("");
        },
        window.location.reload()
        );
      }
    } catch (err) {
      setReopenModal(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: err.response.data.error,
      });
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center mt-5 follow_up">
      <div className="row container-fluid justify-content-center follow_up_card">
        <h3>Lead Follow Up</h3>
        <br />

        <form
          // id="followup-form"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          onSubmit={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
        >
          <div className="row container justify-content-center my-5">
            <div className="col-6">
              <label>Follow up Count</label>
              <input
                type="text"
                readOnly
                value={followUpCount}
                className="form-control"
              />
            </div>

            <div className="col-6">
              <label>Next Follow up Date</label>
              <input
                type="text"
                readOnly
                value={nextFollowupDate && nextFollowupDate}
                className="form-control"
              />
            </div>
          </div>

          <div className="row container justify-content-center my-0">
            <div className="col-6 d-flex justify-content-center">
              <button
                type="submit"
                className="follow-up__btn"
                onClick={(e) => changeFollowUpCount(e, false)}
                disabled={
                  !followupBtn ||
                  (leadStatus != "Assigned" &&
                    leadStatus != "InFollowUp" &&
                    leadStatus !== "Reopened")
                }
                style={{
                  backgroundColor:
                    (!followupBtn ||
                      (leadStatus != "Assigned" &&
                        leadStatus != "InFollowUp" &&
                        leadStatus !== "Reopened")) &&
                    "lightgrey",
                }}
              >
                Follow Up Done
              </button>
            </div>

            <div className="col-6 d-flex justify-content-center">
              <button
                type="submit"
                className="follow-up__btn"
                onClick={(e) => changeFollowUpCount(e, true)}
                disabled={!discussionBtn || followUpCount < 1}
                style={{
                  backgroundColor:
                    (!discussionBtn || followUpCount < 1) && "lightgrey",
                }}
              >
                In Discussion
              </button>
            </div>
          </div>

          <div className="row container justify-content-center my-5">
            <div className="col-6">
              <Form.Label>Closure Reason</Form.Label>
              {leadStatus === "InFollowUp" && !readonlyReason ? (
                <Form.Control
                  required
                  as="select"
                  onChange={(e) => setClosureReason(e.target.value)}
                >
                  {followUpCount === 1 ? (
                    <>
                      <option value="">-- Select --</option>
                      <option value="No Requirement:Closed-WrongLead">
                        No Requirement
                      </option>
                      <option value="Closed-WrongLead:Closed-WrongLead">
                        Wrong Lead
                      </option>
                      <option value="Budget Issue:Closed-WrongLead">
                        Budget Issue
                      </option>
                      <option value="Location Not Suitable:Closed-WrongLead">
                        Location Not Suitable
                      </option>
                    </>
                  ) : (
                    <>
                      <option value="">-- Select --</option>
                      <option value="No Requirement:Closed-WrongLead">
                        No Requirement
                      </option>
                      <option value="Closed-WrongLead:Closed-WrongLead">
                        Wrong Lead
                      </option>
                    </>
                  )}
                </Form.Control>
              ) : leadStatus === "InDiscussion" && !readonlyReason ? (
                <Form.Control
                  required
                  as="select"
                  onChange={(e) => setClosureReason(e.target.value)}
                >
                  <option value="">-- Select --</option>
                  <option value="Converted to Sale:Closed-Converted">
                    Converted to Sale
                  </option>
                  <option value="Flat not of choice:Closed-DeadLead">
                    Flat not of choice
                  </option>
                  <option value="Budget Issue:Closed-DeadLead">
                    Budget Issue
                  </option>
                  <option value="Loan Issue:Closed-DeadLead">Loan Issue</option>
                  <option value="No Requirement:Closed-DeadLead">
                    No Requirement
                  </option>
                  <option value="Location Not Suitable:Closed-DeadLead">
                    Location Not Suitable
                  </option>
                </Form.Control>
              ) : readonlyReason ? (
                <input
                  readOnly
                  defaultValue={closureReason}
                  className="form-control"
                />
              ) : (
                <Form.Control
                  required
                  as="select"
                  onChange={(e) => setClosureReason(e.target.value)}
                >
                  <option value="">-- Select --</option>
                  <option value="Converted to Sale:Closed-Converted">
                    Converted to Sale
                  </option>
                  <option value="Flat not of choice:Closed-DeadLead">
                    Flat not of choice
                  </option>
                  <option value="Budget Issue:Closed-DeadLead">
                    Budget Issue
                  </option>
                  <option value="Loan Issue:Closed-DeadLead">Loan Issue</option>
                  <option value="No Requirement:Closed-DeadLead">
                    No Requirement
                  </option>
                  <option value="Location Not Suitable:Closed-DeadLead">
                    Location Not Suitable
                  </option>
                </Form.Control>
              )}
            </div>

            <div className="col-6 d-flex justify-content-center">
              <button
                className="follow-up__btn"
                disabled={!closeLeadBtn || followUpCount < 1}
                style={{
                  backgroundColor:
                    (!closeLeadBtn || followUpCount < 1) && "lightgrey",
                  marginTop: "20px",
                }}
                onClick={() => {
                  setOpen(true);
                  setPurpose("Close Lead");
                }}
              >
                Close Lead
              </button>
            </div>
          </div>
        </form>

        <br />
        <br />

        {(leadStatus === "Closed-DeadLead" ||
          leadStatus === "Closed-WrongLead" ||
          leadStatus === "Closed-NoResponse" ||
          leadStatus === "Reopened") && (
          <>
            <div className="row container justify-content-center mb-5">
              <div className="col-6 text-center">
                <button
                  className="follow-up__btn"
                  onClick={() => setReopenModal(true)}
                  disabled={leadStatus === "Reopened"}
                  style={{
                    backgroundColor:
                      leadStatus === "Reopened" ? "lightgrey" : "",
                  }}
                >
                  Re-Open Lead
                </button>
              </div>
            </div>
            {leadStatus === "Reopened" && (
              <div className="row container justify-content-center">
                <div className="col-6 text-center">
                  <h4>The Reopened Lead is - <a href={`/individuallead/${lead?.newLeadID}`}>{lead?.newLeadID}</a></h4>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <br />

      <div className="row container-fluid justify-content-center follow_up_card">
        <h2>Lead Connect</h2>
        <br />

        <form
          id="form"
          style={{ width: "100%" }}
          onSubmit={(e) => openLeadConnect(e)}
        >
          <div className="row container-fluid justify-content-center">
            <div className="col-6">
              <label>Connect Date</label>
              <input
                className="form-control"
                type="date"
                required
                min={today}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>

            <div className="col-6">
              <Form.Label>Connect Time</Form.Label>
              <Form.Control
                required
                as="select"
                onChange={(e) => setTime(e.target.value)}
              >
                <option value="">Select a Time</option>
                <option value="9AM-10AM">9AM-10AM</option>
                <option value="10AM-11AM">10AM-11AM</option>
                <option value="11AM-12PM">11AM-12PM</option>
                <option value="12PM-01PM">12PM-01PM</option>
                <option value="01PM-02PM">01PM-02PM</option>
                <option value="02PM-03PM">02PM-03PM</option>
                <option value="03PM-04PM">03PM-04PM</option>
                <option value="04PM-05PM">04PM-05PM</option>
                <option value="05PM-06PM">05PM-06PM</option>
                <option value="06PM-07PM">06PM-07PM</option>
                <option value="07PM-08PM">07PM-08PM</option>
              </Form.Control>
            </div>
          </div>

          <br />

          <div className="row container-fluid justify-content-center">
            <div className="col-8">
              <label>Comment</label>
              <textarea
                className="form-control"
                type="text"
                onChange={(e) => setComment(e.target.value)}
              />
            </div>

            <div className="col-4">
              <br />
              <button
                type="submit"
                className="follow-up__btn"
                disabled={!createBtn}
                style={{ backgroundColor: !createBtn && "lightgrey" }}
              >
                Create Connect
              </button>
            </div>
          </div>

          <br />
          <br />
        </form>

        <div
          className="row container-fluid justify-content-center"
          style={{
            width: "100%",
            margin: "auto",
          }}
        >
          <table className="table">
            <thead style={{ backgroundColor: "#EE4B46", color: "#fff" }}>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
                <th scope="col">Comment</th>
              </tr>
            </thead>
            <tbody>
              {leadConnects.map((l, index) => {
                return (
                  <tr key={index}>
                    <td>{dateFormatting(l.connectDate)}</td>
                    <td>{l.connectTime}</td>
                    <td>{l.isOpen ? "Open" : "Close"}</td>
                    <td>
                      <ActionIcons
                        setOpen={setOpen}
                        setPurpose={setPurpose}
                        lead={l}
                      />
                    </td>
                    <td>{l.note}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* ////////// Modal to Re open a lead////////// */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={reopenModal}
        onClose={() => setReopenModal(false)}
        closeAfterTransition
      >
        <Fade in={reopenModal}>
          <div className="paper" style={{ width: "30vw" }}>
            <form
              method="POST"
              onSubmit={(e) => reOpenLead(e)}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="row container-fluid justify-content-center">
                <div className="col-12">
                  <label>Enter Re-Open Comment</label>
                  <textarea
                    className="form-control"
                    required
                    type="text"
                    style={{ height: "100px" }}
                    onChange={(e) => setReopenComment(e.target.value)}
                  />
                </div>
              </div>
              <br />

              <button className="modal__btns" type="submit">
                Re-Open
              </button>
            </form>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={open}
        onClose={() => {
          setOpen(false);
          setPurpose("");
        }}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className="paper" style={{ width: "30vw" }}>
            <form
              method="POST"
              onSubmit={(e) =>
                purpose === "Close Lead" ? closeLead(e) : closeLeadConnect(e)
              }
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="row container-fluid justify-content-center">
                <div className="col-12">
                  <label>Any Comments ?</label>
                  <textarea
                    className="form-control"
                    type="text"
                    style={{ height: "100px" }}
                    onChange={(e) => setCloseComment(e.target.value)}
                  />
                </div>
              </div>
              <br />

              <button className="modal__btns" type="submit">
                {purpose}
              </button>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default FollowUp;
