import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Button from '../../components/Button/Button';
import './CompOff.scss';
import '../../components/Tabs/Tabs.scss';
import ListOfAllCompOff from '../../components/CompOff/ListOfAllCompOff';
import ListOfPendingCompOff from '../../components/CompOff/ListOfPendingCompOff';

const CompOff = () => {

    const [tab, setTab] = useState('first')


    return (
        <div className='transactions'>
            <Button navigateTo={'/leavemanagement'} />

            <br/>

            <div className="tabs">
                <Nav>
                    <Nav.Item
                        onClick={() => {
                            setTab('first')
                        }}
                    >
                        <Nav.Link className={tab === 'first' ? 'active' : 'inactive'} eventKey="first">
                            All CompOff
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        onClick={() => {
                            setTab('second')
                        }}
                    >
                        <Nav.Link className={tab === 'second' ? 'active' : 'inactive'} eventKey="second">
                            Pending Approval
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>

            <br />
            <br />
            {tab === 'first' && <ListOfAllCompOff />}
            {tab === 'second' && <ListOfPendingCompOff />}
        </div>
    )
}

export default CompOff