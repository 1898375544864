import axios from "axios";
import MaterialTable from "material-table";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";
import ActionModal from "./ActionModal";
import "./LeadDetails.scss";

const SiteList = ({
  sv,
  userList,
  siteNameList,
  setChange,
  leadStatus,
  invLead,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [toggle, setToggle] = useState(false);
  const [svDate, setSvDate] = useState("");
  const [svTime, setSvTime] = useState("");
  const [contactN, setContactN] = useState("");
  const [cEmpId, setCEmpId] = useState("");
  const [open, setOpen] = useState(false);
  const [svId, setSvid] = useState("");
  const [minDate, setMinDate] = useState("");

  const { leadId } = useParams();

  const handleContactPerson = (value) => {
    const separateValue = value.split(",");
    setContactN(separateValue[0]);
    setCEmpId(separateValue[1]);
  };

  useEffect(() => {
    let date = new Date();
    let year = new Date(date).getFullYear();
    let month = new Date(date).getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = new Date(date).getDate();
    if (day < 10) {
      day = `0${day}`;
    }

    let final = `${year}-${month}-${day}`;
    setMinDate(final);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    let body = {
      siteVisitDate: svDate,
      siteVisitTime: svTime,
      contactPersonEmployeeId: cEmpId,
      leadID: leadId,
      siteCode: invLead?.site?.siteCode,
      siteName: invLead?.site?.siteName,
      userFullName: userInfo.userFullName,
    };

    axios
      .post(
        `${BASE_URL}/api/v1/leadmgmt/siteVisit/addSitevisitByLeadId`,
        body,
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Successfully Added",
          timer: 1900,
          showConfirmButton: false,
        });
        e.target.reset();
        setContactN("");
        setToggle(false);
        setContactN("");
        setChange((prev) => !prev);
      });
  };
  return (
    <div className="user_details p-4 mt-4">
      <div className="d-flex justify-content-center mb-3">
        <button
          onClick={() => setToggle(!toggle)}
          disabled={leadStatus != "InDiscussion"}
          style={{ backgroundColor: leadStatus != "InDiscussion" && "grey" }}
          className="create_btn px-3"
        >
          Schedule A Visit
        </button>
      </div>
      {toggle && (
        <div className="schedule_toggle">
          <form onSubmit={handleSubmit}>
            <div className="details_inputs">
              <div className="user_info">
                <label>Date</label>
                <Form.Control
                  type="date"
                  required
                  min={minDate}
                  onChange={(e) => {
                    setSvDate(e.target.value);
                  }}
                />
              </div>

              <div className="user_info">
                <label>Time</label>
                <Form.Control
                  required
                  as="select"
                  onChange={(e) => {
                    setSvTime(e.target.value);
                  }}
                >
                  <option value="">Select a Time</option>
                  <option value="9AM-10AM">9AM-10AM</option>
                  <option value="10AM-11AM">10AM-11AM</option>
                  <option value="11AM-12PM">11AM-12PM</option>
                  <option value="12PM-01PM">12PM-01PM</option>
                  <option value="01PM-02PM">01PM-02PM</option>
                  <option value="02PM-03PM">02PM-03PM</option>
                  <option value="03PM-04PM">03PM-04PM</option>
                  <option value="04PM-05PM">04PM-05PM</option>
                  <option value="05PM-06PM">05PM-06PM</option>
                  <option value="06PM-07PM">06PM-07PM</option>
                  <option value="07PM-08PM">07PM-08PM</option>
                </Form.Control>
              </div>
            </div>
            <div className="single_input">
              <div className="user_info">
                <label>Contact Person</label>
                <Form.Control
                  required
                  as="select"
                  onChange={(e) => {
                    handleContactPerson(e.target.value);
                  }}
                >
                  <option value="">Select a Contact Person</option>
                  {userList?.map((ele, i) => (
                    <option
                      key={i}
                      value={`${ele.userOfficialMobile},${ele.employeeId}`}
                    >
                      {ele.userFullName}
                    </option>
                  ))}
                </Form.Control>
              </div>
            </div>
            <div className="details_inputs">
              <div className="user_info">
                <label>Site Name</label>
                {/* <Form.Control
                  required
                  as="select"
                  onChange={(e) => {
                    setSite(e.target.value);
                  }}
                >
                  <option value="">Select a Site</option>
                  {siteNameList.map((ele, i) => (
                    <option value={`${ele.SiteName}, ${ele.SiteCode}`} key={i}>
                      {ele.SiteName}
                    </option>
                  ))}
                </Form.Control> */}
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={invLead?.site?.siteName}
                />
              </div>
              <div className="user_info">
                <label>Contact Person No.</label>
                <Form.Control
                  required
                  type="number"
                  value={contactN}
                  readOnly
                  disabled
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-centent-center">
              <div className="d-flex mt-4 mx-auto">
                <button
                  type="button"
                  onClick={() => {
                    setContactN("");
                  }}
                  className="reset_btn px-3"
                >
                  Reset
                </button>
                <button type="submit" className="create_btn ml-4 px-3">
                  Schedule
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      <br />

      <h4>Site Visit details</h4>
      <MaterialTable
        title=""
        data={sv.SiteVisit}
        columns={[
          { title: "Site Visit ID", field: "siteVisitId" },
          {
            title: "Site Code",
            field: "siteCode",
          },
          {
            title: "Site Name",
            field: "siteName",
          },
          { title: "Contact Person", field: "contactPerson" },
          {
            title: "Contact Person No.",
            field: "contactPersonMobile",
          },
          {
            title: "Date",
            render: (rowData) =>
              rowData.siteVisitDate.substring(8, 10) +
              "-" +
              rowData.siteVisitDate.substring(5, 7) +
              "-" +
              rowData.siteVisitDate.substring(0, 4),
            customSort: (a, b) => (a.siteVisitDate < b.siteVisitDate ? -1 : 1),
          },
          {
            title: "Time",
            render: (rowData) => rowData.siteVisitTime,
            customSort: (a, b) => (a.siteVisitTime < b.siteVisitTime ? -1 : 1),
          },
          { title: "Status", field: "status" },
        ]}
        options={{
          search: true,
          searchFieldStyle: {
            width: 180,
          },
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
          },
        }}
        actions={[
          (rowData) => ({
            icon: "edit",
            tooltip: "Update SiteVisit",
            onClick: (event, rowData) => {
              setOpen(true);
              setSvid(rowData.siteVisitId);
            },
            disabled:
              rowData.status === "Completed" || rowData.status === "Cancelled"
                ? true
                : false,
          }),
        ]}
      />
      <ActionModal
        open={open}
        setOpen={setOpen}
        svid={svId}
        setChange={setChange}
        leadId={leadId}
      />
    </div>
  );
};

export default SiteList;
