import React from 'react'
import './StockManagement.scss';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';

const StockManagement = () => {

    const navigate = useNavigate();

    return (
        <div className='stock-mgmt'>

            <div className='row justify-content-start'>
                <Button navigateTo='/home' />
            </div>

            <h2 className='text-center mt-4'>Stock Management</h2>

            <div className='row d-flex justify-content-center align-items-center flex-wrap mt-5'>

                <button className='stock-mgmt__btn'
                    onClick={() => navigate('/listOfCategory')}
                >List of Category</button>

                <button className='stock-mgmt__btn'
                    onClick={() => navigate('/listOfSubCategory')}
                >List of Sub Category</button>

                <button className='stock-mgmt__btn'
                    onClick={() => navigate('/listOfBrand')}
                >List of Brand</button>

                <button className='stock-mgmt__btn'
                    onClick={() => navigate('/listOfCatalogueItem')}
                >List of Catalogue Item</button>

                <button className='stock-mgmt__btn'
                    onClick={() => navigate('/searchCatalogueItem')}
                >Search Catalogue Item</button>

                <button className='stock-mgmt__btn'
                // onClick={() => navigate('/listOfCatalogueItem')}
                >Inventory</button>

                <button className='stock-mgmt__btn'
                // onClick={() => navigate('/listOfCatalogueItem')}
                >Procurement Request</button>

                <button className='stock-mgmt__btn'
                // onClick={() => navigate('/listOfCatalogueItem')}
                >Purchase Order</button>

                <button className='stock-mgmt__btn'
                // onClick={() => navigate('/listOfCatalogueItem')}
                >Delivery Note</button>

                <button className='stock-mgmt__btn'
                    onClick={() => navigate('warehouse')}
                >Warehouse</button>

            </div>


        </div>
    )
}

export default StockManagement