import React, { useState } from 'react'
import './Quotations.scss';
import Nav from 'react-bootstrap/Nav';
import Button from '../../components/Button/Button';
import AllQuotations from '../../components/Quotations/AllQuotations';
import PendingApproval from '../../components/Quotations/PendingApproval';
import ApprovedQuotations from '../../components/Quotations/ApprovedQuotations';

const Quotations = () => {

    const [tab, setTab] = useState('first')

    return (
        <div className='quotations'>

            <Button navigateTo={'/home'} />

            <br />
            <br />

            <div className="tabs">
                <Nav>
                    <Nav.Item
                        onClick={() => {
                            setTab('first')
                        }}
                    >
                        <Nav.Link className={tab === 'first' ? 'active' : 'inactive'} eventKey="first">
                            All Quotations
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        onClick={() => {
                            setTab('second')
                        }}
                    >
                        <Nav.Link className={tab === 'second' ? 'active' : 'inactive'} eventKey="second">
                            Pending Approval
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        onClick={() => {
                            setTab('third')
                        }}
                    >
                        <Nav.Link className={tab === 'third' ? 'active' : 'inactive'} eventKey="third">
                            Approved Quotations
                        </Nav.Link>
                    </Nav.Item>


                </Nav>

            </div>

            {tab === 'first' && <AllQuotations />}

            {tab === 'second' && <PendingApproval />}

            {tab === 'third' && <ApprovedQuotations />}

        </div>
    )
}

export default Quotations;