import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";
import { useState, useContext, useEffect } from "react";
import Tabs from "../../components/Tabs/Tabs";
import "./IndividualSiteForm.scss";
import "../../components/Tabs/Tabs";
import SiteConfigurations from "../SiteConfigurations/SiteConfigurations";
import UnitTypes from "../UnitTypes/UnitTypes";
import Unit from "../Unit/Unit";
import CarParkingType from "../CarParkingType/CarParkingType";
import CarParking from "../CarParking/CarParking";
import PaymentTerms from "../PaymentTerms/PaymentTerms";
import CollectionAccount from "../CollectionAccount/CollectionAccount";
import Paper from "@mui/material/Paper";
import Button from "../../components/Button/Button";
import UploadVideos from "./UploadVideos";

const IndividualSiteForm = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();
  const siteCode = useParams().siteCode;

  /////////////////////////// Tab State //////////////////////
  const [tab, setTab] = useState(localStorage.getItem("ActiveKeySite"));

  const [sn, setSn] = useState("");
  const [sc, setSc] = useState("");
  const [gst, setGst] = useState("");
  const [desc, setDesc] = useState("");
  const [adr, setAdr] = useState("");
  const [lm, setLm] = useState("");
  const [pc, setPc] = useState("");
  const [ct, setCt] = useState("");
  const [st, setSt] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [disp, setDisp] = useState("none");
  const [scn, setScn] = useState("");
  const [bpsn, setBpsn] = useState("");
  const [sw, setSw] = useState("");
  const [siteId, setSiteId] = useState("");
  const [pcError, setPcError] = useState("");
  const [pcError2, setPcError2] = useState("");
  const [gmap, setGmap] = useState("");

  const [ofcAdr, setOfcAdr] = useState("");
  const [ofcLm, setOfcLm] = useState("");
  const [ofcPc, setOfcPc] = useState("");
  const [ofcCt, setofcCt] = useState("");
  const [ofcSt, setOfcSt] = useState("");
  const [ofcGmap, setOfcGmap] = useState("");
  const [ofcEmail, setOfcEmail] = useState("");
  const [ofcPhone, setOfcPhone] = useState("");
  const [listofEngineers, setListofEngineers] = useState([]);
  const [listofFinance, setListofFinance] = useState([]); // [{id: 1, name: "abc"}
  const [site, setSite] = useState({});
  const [siteEngineerDetails, setSiteEngineerDetails] = useState({
    employeeFullName: "",
    employeeId: "",
    officialEmail: "",
    officialMobile: "",
  }); // {id: 1, name: "abc"}
  const [siteFinanceDetails, setSiteFinanceDetails] = useState({
    employeeFullName: "",
    employeeId: "",
    officialEmail: "",
    officialMobile: "",
  }); // {id: 1, name: "abc"}
  const [siteEngineer, setSiteEngineer] = useState(); // {id: 1, name: "abc"}
  const [siteFinance, setSiteFinance] = useState(); // {id: 1, name: "abc"}
  const Token = "bearer" + " " + userInfo.token;

  const [legalAddress, setLegalAddress] = useState({
    fullAddress: "",
    landmark: "",
    city: "",
    pinCode: "",
    state: "",
    postOffice: "",
    policeStation: "",
  });
  const [pcError3, setPcError3] = useState("");

  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/Employee/getListOfEmployee?isActive=true&employeeRole=Engineering`,
        {
          headers: {
            Authorization: Token,
          },
        }
      )
      .then((res) => {
        setListofEngineers(res.data.employees);
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/Employee/getListOfEmployee?isActive=true&employeeRole=Finance`,
        {
          headers: {
            Authorization: Token,
          },
        }
      )
      .then((res) => {
        setListofFinance(res.data.employees);
      });
  }, []);

  // console.log(listofEngineers);

  const assignSiteEngineer = (e) => {
    e.preventDefault();
    axios
      .put(
        `${BASE_URL}/api/v1/Configuration/site/updateSiteEngineer`,
        {
          siteCode: siteCode,
          employeeId: siteEngineer,
        },
        {
          headers: {
            Authorization: Token,
          },
        }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          confirmButtonText: "OK",
          text: res.data.message,
        }).then(function () {
          window.location.reload();
        });
      });
  };
  const assignFinanceContact = (e) => {
    e.preventDefault();
    axios
      .put(
        `${BASE_URL}/api/v1/Configuration/site/updateFinanceContact`,
        {
          siteCode: siteCode,
          employeeId: siteFinance,
        },
        {
          headers: {
            Authorization: Token,
          },
        }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          confirmButtonText: "OK",
          text: res.data.message,
        }).then(function () {
          window.location.reload();
        });
      });
  };

  const editSite = (e) => {
    e.preventDefault();

    if (pcError || pcError2 || phoneError || pcError3) {
      return;
    }
    //console.log(typeof (ofcPc))
    const Token = "bearer" + " " + userInfo.token;
    axios
      .put(
        `${BASE_URL}/api/v1/Configuration/Site/updateSiteBySiteCode`,
        {
          siteCode: siteCode,
          siteUpdate: {
            siteName: sn,
            website: sw,
            siteCode: sc,
            gstinNumber: gst,
            siteCompanyName: scn,
            buildingPlanSanctionNo: bpsn,
            siteDescription: desc,
            siteAddress: {
              fullAddress: adr,
              landmark: lm,
              pinCode: parseInt(pc),
              city: ct,
              state: st,
              googleMapLink: gmap,
              email: email,
              phoneNo: phone,
            },
            officeAddress: {
              fullAddress: ofcAdr,
              landmark: ofcLm,
              city: ofcCt,
              state: ofcSt,
              pinCode: parseInt(ofcPc),
              googleMapLink: ofcGmap,
              email: ofcEmail,
              phoneNo: ofcPhone,
            },
            legalAddress: legalAddress,
          },
        },
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            // window.location = "/dashboard/configurations/site";
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      });
  };

  useEffect(() => {
    const Token = "bearer" + " " + userInfo.token;
    axios
      .get(
        `${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        // console.log(response.data.site);
        const siteInfo = response.data.site;

        setSite(siteInfo);

        const {
          siteCode,
          siteName,
          siteRERANo,
          gstinNumber,
          siteCompanyName,
          buildingPlanSanctionNo,
          siteDescription,
          siteAddress,
          officeAddress,
          siteId,
        } = siteInfo;

        setSc(siteCode);
        setSn(siteName);
        setGst(gstinNumber);
        setScn(siteCompanyName);
        setBpsn(buildingPlanSanctionNo);
        setSw(siteInfo?.website);
        setDesc(siteDescription);

        //  site Address
        setAdr(siteAddress.fullAddress);
        setLm(siteAddress.landmark);
        siteAddress.pinCode === null ? setPc("") : setPc(siteAddress.pinCode);
        setCt(siteAddress.city);
        setSt(siteAddress.state);
        setGmap(siteAddress.googleMapLink);
        setEmail(siteAddress.email);
        setPhone(siteAddress.phoneNo);

        // Office Address
        setOfcAdr(officeAddress.fullAddress);
        setOfcLm(officeAddress.landmark);
        officeAddress.pinCode === null
          ? setOfcPc("")
          : setOfcPc(officeAddress.pinCode);
        setOfcSt(officeAddress.state);
        setofcCt(officeAddress.city);
        setOfcGmap(officeAddress.googleMapLink);
        setOfcEmail(officeAddress.email);
        setOfcPhone(officeAddress.phoneNo);
        setSiteId(siteId);
        setSiteEngineerDetails({
          employeeFullName: siteInfo?.siteEngineer?.employeeFullName,
          employeeId: siteInfo?.siteEngineer?.employeeId,
          officialEmail: siteInfo?.siteEngineer?.officialEmail,
          officialMobile: siteInfo?.siteEngineer?.officialMobile,
        });
        setSiteFinanceDetails({
          employeeFullName: siteInfo?.financeContact?.employeeFullName,
          employeeId: siteInfo?.financeContact?.employeeId,
          officialEmail: siteInfo?.financeContact?.officialEmail,
          officialMobile: siteInfo?.financeContact?.officialMobile,
        });

        setLegalAddress(siteInfo?.legalAddress);
      });
  }, []);

  return (
    <div className="individualsite">
      <Button navigateTo={"/configurations/site"} />

      <br />

      <Tabs tabName={setTab} />

      {tab === "first" && (
        <>
          <span className="individualsite__sitename">
            Site Code - {siteCode}
          </span>

          <div className="individualsite__form">
            <div className="row justify-content-center">
              <div className="col-6">
                <label>Site Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="sitename"
                  id="sitename"
                  value={sn}
                  onChange={(e) => {
                    setSn(e.target.value);
                    setDisp("none");
                  }}
                />
              </div>
            </div>
            <br />
            <div className="row justify-content-center">
              <div className="col-4">
                <label>Site Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="scn"
                  id="scn"
                  value={scn}
                  onChange={(e) => {
                    setScn(e.target.value);
                    setDisp("none");
                  }}
                />
              </div>
              <div className="col-4">
                <label>Site Website</label>
                <input
                  type="text"
                  className="form-control"
                  name="sw"
                  id="sw"
                  value={sw}
                  onChange={(e) => {
                    setSw(e.target.value);
                    setDisp("none");
                  }}
                />
              </div>
              <div className="col-4">
                <label>Building Plan Sanction No.</label>
                <input
                  type="text"
                  className="form-control"
                  name="bpsn"
                  id="sitename"
                  value={bpsn}
                  onChange={(e) => {
                    setBpsn(e.target.value);
                    setDisp("none");
                  }}
                />
              </div>
            </div>
            <br />
            <div className="row justify-content-center">
              <div className="col-4">
                <label>GSTIN Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="gst"
                  id="gst"
                  value={gst}
                  onChange={(e) => {
                    setGst(e.target.value);
                    setDisp("none");
                  }}
                />
              </div>
              <div className="col-8">
                <label>Site Description</label>
                <input
                  type="text"
                  className="form-control"
                  name="desc"
                  id="desc"
                  value={desc}
                  onChange={(e) => {
                    setDesc(e.target.value);
                    setDisp("none");
                  }}
                />
              </div>
            </div>

            <br />

            <Paper elevation={2} style={{ padding: "20px" }}>
              <h4>Site Address</h4>
              <br />
              <div className="row justify-content-center">
                <div className="col-12">
                  <label>Full Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="fulladdress"
                    id="fulladdress"
                    value={adr}
                    onChange={(e) => {
                      setAdr(e.target.value);
                      setDisp("none");
                    }}
                  />
                </div>
              </div>

              <br />

              <div className="row justify-content-center">
                <div className="col-6">
                  <label>Landmark</label>
                  <input
                    type="text"
                    className="form-control"
                    name="landmark"
                    id="landmark"
                    value={lm}
                    onChange={(e) => {
                      setLm(e.target.value);
                      setDisp("none");
                    }}
                  />
                </div>
                <div className="col-6">
                  <label>Pincode</label>
                  <input
                    type="number"
                    className="form-control"
                    name="pincode"
                    id="pincode"
                    value={pc}
                    onChange={(e) => {
                      if (
                        e.target.value.length > 6 ||
                        e.target.value.length < 6
                      ) {
                        if (e.target.value.length === 0) {
                          setPcError("");
                          setPc(e.target.value);
                        } else {
                          setPcError("Pincode must be of 6 digits");
                          setPc(e.target.value);
                        }
                      } else {
                        setPc(e.target.value);
                        setPcError("");
                      }
                    }}
                  />
                  {pcError && <p style={{ color: "#EE4B46" }}>{pcError}</p>}
                </div>
              </div>

              <br />

              <div className="row justify-content-center">
                <div className="col-4">
                  <label>City</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    id="city"
                    value={ct}
                    onChange={(e) => {
                      setCt(e.target.value);
                      setDisp("none");
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>State</label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    id="state"
                    value={st}
                    onChange={(e) => {
                      setSt(e.target.value);
                      setDisp("none");
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Google Map Link</label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    id="state"
                    autoComplete="off"
                    value={gmap}
                    onChange={(e) => {
                      setGmap(e.target.value);
                      setDisp("none");
                    }}
                  />
                </div>
              </div>

              <br />

              <div className="row justify-content-center">
                <div className="col-4">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setDisp("none");
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Phone No.</label>
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    id="phone"
                    autoComplete="off"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                      setDisp("none");
                      let reg = new RegExp("^[0-9]*$");
                      if (
                        e.target.value.length !== 10 ||
                        !reg.test(e.target.value)
                      ) {
                        setPhoneError("Phone no. must be 10 digits long");
                      } else {
                        setPhoneError("");
                      }
                    }}
                  />
                  {phoneError && (
                    <em style={{ marginTop: "10px", color: "#EE4B46" }}>
                      {phoneError}
                    </em>
                  )}
                </div>
              </div>
            </Paper>

            <br />

            <Paper elevation={2} style={{ padding: "20px" }}>
              <h4>Office Address</h4>
              <br />
              <div className="row justify-content-center">
                <div className="col-12">
                  <label>Full Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="fulladdress"
                    id="fulladdress"
                    value={ofcAdr}
                    onChange={(e) => {
                      setOfcAdr(e.target.value);
                      setDisp("none");
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-6">
                  <label>Landmark</label>
                  <input
                    type="text"
                    className="form-control"
                    name="landmark"
                    id="landmark"
                    value={ofcLm}
                    onChange={(e) => {
                      setOfcLm(e.target.value);
                      setDisp("none");
                    }}
                  />
                </div>

                <div className="col-6">
                  <label>Pincode</label>
                  <input
                    type="number"
                    className="form-control"
                    name="pincode"
                    id="pincode"
                    autoComplete="off"
                    value={ofcPc}
                    onChange={(e) => {
                      if (
                        e.target.value.length > 6 ||
                        e.target.value.length < 6
                      ) {
                        if (e.target.value.length === 0) {
                          setPcError2("");
                          setOfcPc(e.target.value);
                        } else {
                          setPcError2("Pincode must be of 6 digits");
                          setOfcPc(e.target.value);
                        }
                      } else {
                        setOfcPc(e.target.value);
                        setPcError2("");
                      }
                    }}
                  />
                  {pcError2 && <p style={{ color: "#EE4B46" }}>{pcError2}</p>}
                </div>
              </div>
              <br />

              <div className="row justify-content-center">
                <div className="col-4">
                  <label>City</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    id="city"
                    value={ofcCt}
                    onChange={(e) => {
                      setofcCt(e.target.value);
                      setDisp("none");
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>State</label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    id="state"
                    value={ofcSt}
                    onChange={(e) => {
                      setOfcSt(e.target.value);
                      setDisp("none");
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Google Map Link</label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    id="state"
                    autoComplete="off"
                    value={ofcGmap}
                    onChange={(e) => {
                      setOfcGmap(e.target.value);
                      setDisp("none");
                    }}
                  />
                </div>
              </div>

              <br />

              <div className="row justify-content-center">
                <div className="col-4">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    id="city"
                    value={ofcEmail}
                    onChange={(e) => {
                      setOfcEmail(e.target.value);
                      setDisp("none");
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Phone No.</label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    id="state"
                    value={ofcPhone}
                    onChange={(e) => {
                      setOfcPhone(e.target.value);
                      setDisp("none");
                      let reg = new RegExp("^[0-9]*$");
                      if (
                        e.target.value.length !== 10 ||
                        !reg.test(e.target.value)
                      ) {
                        setPhoneError("Phone no. must be 10 digits long");
                      } else {
                        setPhoneError("");
                      }
                    }}
                  />
                  {phoneError && (
                    <em style={{ marginTop: "10px", color: "#EE4B46" }}>
                      {phoneError}
                    </em>
                  )}
                </div>
              </div>
            </Paper>

            <br />

            <Paper elevation={2} style={{ padding: "20px" }}>
              <h4>Legal Address</h4>
              <br />
              <div className="row justify-content-center">
                <div className="col-12">
                  <label>Full Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="fulladdress"
                    id="fulladdress"
                    value={legalAddress?.fullAddress}
                    onChange={(e) => {
                      setLegalAddress({
                        ...legalAddress,
                        fullAddress: e.target.value,
                      });
                      setDisp("none");
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-6">
                  <label>Landmark</label>
                  <input
                    type="text"
                    className="form-control"
                    name="landmark"
                    id="landmark"
                    value={legalAddress?.landmark}
                    onChange={(e) => {
                      setLegalAddress({
                        ...legalAddress,
                        landmark: e.target.value,
                      });
                      setDisp("none");
                    }}
                  />
                </div>

                <div className="col-6">
                  <label>Pincode</label>
                  <input
                    type="number"
                    className="form-control"
                    name="pincode"
                    id="pincode"
                    autoComplete="off"
                    value={legalAddress?.pinCode}
                    onChange={(e) => {
                      if (e.target.value.length != 6) {
                        setPcError3("Pincode must be of 6 digits");
                      } else {
                        setPcError3("");
                      }

                      setLegalAddress({
                        ...legalAddress,
                        pinCode: e.target.value,
                      });
                    }}
                  />
                  {pcError3 && <p style={{ color: "#EE4B46" }}>{pcError3}</p>}
                </div>
              </div>
              <br />

              <div className="row justify-content-center">
                <div className="col-6">
                  <label>City</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    id="city"
                    value={legalAddress?.city}
                    onChange={(e) => {
                      setLegalAddress({
                        ...legalAddress,
                        city: e.target.value,
                      });
                      setDisp("none");
                    }}
                  />
                </div>
                <div className="col-6">
                  <label>State</label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    id="state"
                    value={legalAddress?.state}
                    onChange={(e) => {
                      setLegalAddress({
                        ...legalAddress,
                        state: e.target.value,
                      });
                      setDisp("none");
                    }}
                  />
                </div>
              </div>

              <br />

              <div className="row justify-content-center">
                <div className="col-6">
                  <label>Post Office</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    id="city"
                    value={legalAddress?.postOffice}
                    onChange={(e) => {
                      setLegalAddress({
                        ...legalAddress,
                        postOffice: e.target.value,
                      });
                      setDisp("none");
                    }}
                  />
                </div>
                <div className="col-6">
                  <label>Police Station</label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    id="state"
                    value={legalAddress?.policeStation}
                    onChange={(e) => {
                      setLegalAddress({
                        ...legalAddress,
                        policeStation: e.target.value,
                      });
                      setDisp("none");
                    }}
                  />
                </div>
              </div>
            </Paper>

            <br />

            <Paper elevation={2} style={{ padding: "20px" }}>
              <h4>Update Site Engineer</h4>
              <br />
              <div className="row justify-content-center align-items-center">
                <div className="col-6">
                  <label>Site Engineer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="siteengineer"
                    id="siteengineer"
                    value={siteEngineerDetails?.employeeFullName}
                    disabled
                  />
                </div>
                <div className="col-6">
                  <label>Site Engineer Employee Id</label>
                  <input
                    type="text"
                    className="form-control"
                    name="siteengineer"
                    id="siteengineer"
                    value={siteEngineerDetails?.employeeId}
                    disabled
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center align-items-center">
                <div className="col-6">
                  <label>Site Engineer Official Email</label>
                  <input
                    type="text"
                    className="form-control"
                    name="siteengineer"
                    id="siteengineer"
                    value={siteEngineerDetails?.officialEmail}
                    disabled
                  />
                </div>
                <div className="col-6">
                  <label>Site Engineer Official Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="siteengineer"
                    id="siteengineer"
                    value={siteEngineerDetails?.officialMobile}
                    disabled
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center align-items-center">
                <div className="col-6">
                  <label>Site Engineer Name</label>
                  <select
                    className="form-control"
                    name="siteengineer"
                    id="siteengineer"
                    onChange={(e) => {
                      setSiteEngineer(e.target.value);
                    }}
                  >
                    <option value="">Select a Site Engineer</option>
                    {listofEngineers.map((item) => {
                      return (
                        <option key={item.employeeId} value={item.employeeId}>
                          {item.employeeFullName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-6 mt-4">
                  <button
                    onClick={assignSiteEngineer}
                    disabled={
                      userInfo?.userRole !== "Director" &&
                      userInfo?.userRole !== "Operations"
                    }
                    style={{
                      backgroundColor:
                        userInfo?.userRole !== "Director" &&
                        userInfo?.userRole !== "Operations"
                          ? "grey"
                          : "",
                    }}
                  >
                    Assign
                  </button>
                </div>
              </div>
            </Paper>
            <br />

            <Paper elevation={2} style={{ padding: "20px" }}>
              <h4>Update Finance Contact</h4>
              <br />
              <div className="row justify-content-center align-items-center">
                <div className="col-6">
                  <label>Finance Contact Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="financecontact"
                    id="financecontact"
                    value={siteFinanceDetails?.employeeFullName}
                    disabled
                  />
                </div>
                <div className="col-6">
                  <label>Finance Contact Employee Id</label>
                  <input
                    type="text"
                    className="form-control"
                    name="financecontact"
                    id="financecontact"
                    value={siteFinanceDetails?.employeeId}
                    disabled
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center align-items-center">
                <div className="col-6">
                  <label>Finance Contact Official Email</label>
                  <input
                    type="text"
                    className="form-control"
                    name="financecontact"
                    id="financecontact"
                    value={siteFinanceDetails?.officialEmail}
                    disabled
                  />
                </div>
                <div className="col-6">
                  <label>Finance Contact Official Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="financecontact"
                    id="financecontact"
                    value={siteFinanceDetails?.officialMobile}
                    disabled
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center align-items-center">
                <div className="col-6">
                  <label>Finance Contact Name</label>
                  <select
                    className="form-control"
                    name="financecontact"
                    id="financecontact"
                    onChange={(e) => {
                      setSiteFinance(e.target.value);
                    }}
                  >
                    <option value="">Select a Finance Contact</option>
                    {listofFinance.map((item) => {
                      return (
                        <option key={item.employeeId} value={item.employeeId}>
                          {item.employeeFullName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-6 mt-4">
                  <button
                    onClick={assignFinanceContact}
                    disabled={
                      userInfo?.userRole !== "Director" &&
                      userInfo?.userRole !== "Operations"
                    }
                    style={{
                      backgroundColor:
                        userInfo?.userRole !== "Director" &&
                        userInfo?.userRole !== "Operations"
                          ? "grey"
                          : "",
                    }}
                  >
                    Assign
                  </button>
                </div>
              </div>
            </Paper>

            <br />

            <div className="text-center" style={{ display: disp }}>
              <em>All details saved succesfully!</em>
            </div>
            <div className="individualsite__form__btn">
              <button onClick={editSite}>Save</button>
            </div>
          </div>

          <UploadVideos site={site} />
        </>
      )}

      {tab === "second" && <SiteConfigurations />}

      {tab === "third" && <UnitTypes />}

      {tab === "fourth" && <Unit />}

      {tab === "fifth" && <CarParkingType />}

      {tab === "sixth" && <CarParking />}

      {tab === "seventh" && <PaymentTerms />}

      {tab === "eighth" && <CollectionAccount />}
    </div>
  );
};

export default IndividualSiteForm;
