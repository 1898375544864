import React, { useEffect, useState } from 'react'
import './BankTransfer.scss';
import MaterialTable, { MTableToolbar } from "material-table";
import Button from '../../components/Button/Button';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useStyles } from '../../utils/ModalStyles';
import { useNavigate } from 'react-router-dom';

const BankTransfer = () => {

    const classes = useStyles()
    const navigate = useNavigate()
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));

    const [banktransfer, setBankTransfer] = useState([]);
    const [category, setCategory] = useState('')
    const [subCategory, setSubCategory] = useState('')
    const [method, setMethod] = useState('')

    useEffect(() => {
        getListOfBankTransfer()
    }, [])

    const getListOfBankTransfer = () => {

        axios.get(`${BASE_URL}/api/v1/FinanceMgmt/bankTransfer/getListOfBankTransfer`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((res) => {
                // console.log(res);
                setBankTransfer(res.data.bankTransferList)
            })
    }

    useEffect(() => {

        if (category === '') {
            setSubCategory('')
        }

        if (category === '' && method === '') {
            setSubCategory('')
            return getListOfBankTransfer()
        }

        let params = [];

        if (category) {
            params.push('paymentCategory')
        }
        if (subCategory) {
            params.push('paymentSubCategory')
        }
        if (method) {
            params.push('transferMethod')
        }

        let url = []
        params.forEach((p, index) => {
            if (p === 'paymentCategory') {

                if (index === 0) {
                    url.push(`?${p}=${category}`)
                } else {
                    url.push(`&${p}=${category}`)

                }
            }
            if (p === 'paymentSubCategory') {
                if (index === 0) {
                    url.push(`?${p}=${subCategory}`)
                } else {
                    url.push(`&${p}=${subCategory}`)

                }
            }
            if (p === 'transferMethod') {

                if (index === 0) {
                    url.push(`?${p}=${method}`)
                } else {
                    url.push(`&${p}=${method}`)

                }
            }
        })


        axios.get(`${BASE_URL}/api/v1/FinanceMgmt/bankTransfer/getListOfBankTransfer${url.join('')}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {

                setBankTransfer(response.data.bankTransferList)
            })

    }, [category, subCategory, method])

    const reset = () => {
        setCategory('')
        setSubCategory('')
        setMethod('')
    }

    return (
        <div className='banktransfer'>

            <div className="banktransfer__btns">
                <Button navigateTo={'/home'} />
                <button
                    className='banktransfer__btn'
                    style={{ backgroundColor: '#EE4B46', color: 'white' }}
                    onClick={() => navigate('/finance/banktransfer/bank-transfer-form')}
                >Add Bank Transfer
                </button>
            </div>

            <br />
            <br />

            <h4>Bank Transfer</h4>

            <MaterialTable
                data={banktransfer}
                title=""

                columns={
                    [
                        {title : "Bank Transfer Id" , field : "bankTransferId"},
                        { title: 'Payment Category', field: 'paymentCategory' },
                        { title: 'Payment Sub-Category', field: 'paymentSubCategory' },
                        {
                            title: 'Transfer Date',
                            render: (rowData) => {
                                return !rowData.transferDate ? "" : rowData.transferDate.substring(8, 10) + "-" + rowData.transferDate.substring(5, 7) + "-" + rowData.transferDate.substring(0, 4)
                            },

                            customSort: (a, b) => (a.transferDate < b.transferDate ? -1 : 1),
                            defaultSort: 'desc'
                        },
                        { title: 'Transfer Amount', field: 'transferAmount' },
                        { title: 'Transfer Method', field: 'transferMethod' },
                        { title: 'Customer ID', field: 'customerId' },
                        { title: 'Application ID', field: 'applicationId' },
                        { title: 'Money Receipt No.', field: 'moneyReceiptNo' },
                    ]
                }

                options={{
                    search: window.innerWidth < 700 ? false : true,
                    searchFieldStyle: {
                        width: 180
                    },
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#EE4B46',
                        color: '#fff',
                    },
                    sorting: true
                }}

                actions={[
                    {
                        icon: 'remove_red_eye',
                        tooltip: 'View Bank Transfer',
                        onClick: (event, rowData) => {
                            // setTab('first')
                            navigate(`/finance/banktransfer/${rowData?.bankTransferId}`);
                            // localStorage.setItem('ActiveKeySite', 'first')
                        },
                    },

                ]}

                components={{
                    Toolbar: (props) => (
                        <div className="filters text-left">
                            <MTableToolbar {...props} />

                            <FormControl variant='standard'
                                className={classes.formControl}

                                style={{
                                    margin: '0px 10px',
                                    marginTop: "-65px",
                                    width: '150px',

                                }}
                            >
                                <InputLabel id="demo-simple-select-standard-label"
                                    style={{ fontSize: '13px' }}>
                                    Category
                                </InputLabel>
                                <Select llabelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                    value={category}
                                    onChange={(e) => {
                                        setCategory(e.target.value)
                                    }}
                                    className={classes.selectEmpty}
                                    style={{
                                        fontSize: '13px',
                                        textAlign: 'left'
                                    }}
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value="">
                                        Category
                                    </MenuItem>
                                    <MenuItem value='CustomerPayment'>Customer Payment</MenuItem>
                                    <MenuItem value='OtherPayment'>Other Payment</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl variant='standard'
                                // className={classes.formControl}
                                style={{
                                    margin: '0px 10px',
                                    marginTop: "-65px",
                                    width: '150px',
                                }}
                            >
                                <InputLabel id="demo-simple-select-standard-label"
                                    style={{ fontSize: '13px' }}>
                                    Sub-Category
                                </InputLabel>
                                {
                                    category === 'CustomerPayment' ?
                                        <Select llabelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                            value={subCategory}
                                            onChange={(e) => setSubCategory(e.target.value)}
                                            className={classes.selectEmpty}
                                            inputProps={{ "aria-label": "Without label" }}
                                            style={{
                                                fontSize: '13px',
                                                textAlign: 'left'
                                            }}
                                            disabled={category === "" ? true : false}
                                        >
                                            <MenuItem value="">
                                                Sub Category
                                            </MenuItem>
                                            <MenuItem value='BookingPayment'>Booking Payment</MenuItem>
                                            <MenuItem value='DemandPayment'>Demand Payment</MenuItem>
                                            <MenuItem value='FinalPayment'>Final Payment</MenuItem>
                                        </Select>

                                        :

                                        <Select llabelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                            value={subCategory}
                                            onChange={(e) => setSubCategory(e.target.value)}
                                            className={classes.selectEmpty}
                                            inputProps={{ "aria-label": "Without label" }}
                                            disabled={category === "" ? true : false}
                                        >
                                            <MenuItem value="">
                                                Sub Category
                                            </MenuItem>
                                            <MenuItem value='MaterialsSold'>Materials Sold</MenuItem>
                                            <MenuItem value='OtherPayment'>Other Payment</MenuItem>
                                        </Select>
                                }

                            </FormControl>

                            {window.innerWidth < 1000 && <br />}

                            <FormControl variant='standard'
                                className={classes.formControl}

                                style={{
                                    margin: '0px 10px',
                                    marginTop: window.innerWidth < 1000 ? '-40px' : '-65px',
                                    width: '120px',

                                }}
                            >
                                <InputLabel id="demo-simple-select-standard-label"
                                    style={{ fontSize: '13px' }}>
                                    Transfer Method
                                </InputLabel>
                                <Select llabelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                    value={method}
                                    onChange={(e) => {
                                        setMethod(e.target.value)
                                    }}
                                    className={classes.selectEmpty}
                                    style={{
                                        fontSize: '13px',
                                        textAlign: 'left'
                                    }}
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value="">
                                        Transfer Method
                                    </MenuItem>
                                    <MenuItem value='NEFT'>NEFT</MenuItem>
                                    <MenuItem value='UPI'>UPI</MenuItem>
                                    <MenuItem value='RTGS'>RTGS</MenuItem>
                                    <MenuItem value='IMPS'>IMPS</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl style={{ marginTop: window.innerWidth < 1000 ? '-30px' : '-50px'}}>
                                <button
                                    onClick={reset}
                                    style={{
                                        backgroundColor: "white",
                                        color: "black",
                                        border: "1px solid black",
                                        borderRadius: "20px",
                                        padding: "5px",
                                        width : '150px'
                                    }}
                                >
                                    Reset Filter
                                </button>
                            </FormControl>

                        </div>
                    ),
                }}

            ></MaterialTable>

        </div >
    )
}

export default BankTransfer