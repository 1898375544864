import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Button from "../../components/Button/Button";
import "./Transactions.scss";
import "../../components/Tabs/Tabs.scss";
import CreditTransactions from "../../components/Transactions/CreditTransactions/CreditTransactions";
import DebitTransactions from "../../components/Transactions/DebitTransactions/DebitTransactions";
import {
  Modal,
  Fade,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";
import MoonLoader from "react-spinners/MoonLoader";

const Transactions = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [tab, setTab] = useState(localStorage.getItem("ActiveKeyTransaction"));
  const [modal, setModal] = useState(false);
  const [financialYear, setFinancialYear] = useState("");
  const [transactionMode, setTransactionMode] = useState("");
  const [costCenterList, setCostCenterList] = useState([]);
  const [costCenterCode, setCostCenterCode] = useState("");
  const [siteList, setSiteList] = useState([]);
  const [siteCode, setSiteCode] = useState("");
  const [document, setDocument] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/financemgmt/costCenter/getListOfCostCenter`, {
        headers: {
          Authorization: `bearer ${userInfo.token}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          setCostCenterList(response.data.Cost_Centers);
        } else {
          setCostCenterList([]);
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/configuration/site/getAllSites`, {
        headers: {
          Authorization: `bearer ${userInfo.token}`,
        },
      })
      .then((response) => {
        // console.log(response)
        if (response.data.status) {
          setSiteList(response.data.siteArray);
        } else {
          setSiteList([]);
        }
      });
  }, []);

  const downloadExcel = (e) => {
    e.preventDefault();

    setModal(false);
    setLoader(true);

    let url = "";
    if (tab === "first") {
      url = `${BASE_URL}/api/v1/financemgmt/transaction/debitTransactionExcelReport?`;
    } else if (tab === "second") {
      url = `${BASE_URL}/api/v1/financemgmt/transaction/creditTransactionExcelReport?`;
    }

    if (financialYear) {
      url += `finYear=${financialYear}&`;
    }

    if (transactionMode) {
      url += `transactionMode=${transactionMode}&`;
    }

    if (costCenterCode) {
      url += `costCenterCode=${costCenterCode}`;
    }
    if (siteCode) {
      url += `siteCode=${siteCode}`;
    }

    axios
      .get(url, {
        headers: {
          Authorization: `bearer ${userInfo.token}`,
        },
      })
      .then((response) => {
        setLoader(false);
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            html: `Download the File : <a href='${response.data.document}'>Download</a>`,
          }).then(() => {
            setDocument(response.data.document);
            reset();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.error,
        });
      });
  };

  const reset = () => {
    setFinancialYear("");
    setTransactionMode("");
    setCostCenterCode("");
    setSiteCode("");
  };

  return (
    <div className="transactions">
      <div className="row justify-content-between">
        <div className="col-6">
          <Button navigateTo={"/home"} />
        </div>
        <div className="col-6 d-flex justify-content-end">
          <button className="transactions__btn" onClick={() => setModal(true)}>
            Download as Excel
          </button>
        </div>
      </div>
      <br /> <br />
      <div className="tabs">
        <Nav>
          <Nav.Item
            onClick={() => {
              localStorage.setItem("ActiveKeyTransaction", "first");
              setTab("first");
            }}
          >
            <Nav.Link
              className={tab === "first" ? "active" : "inactive"}
              eventKey="first"
            >
              Debit Transactions
            </Nav.Link>
          </Nav.Item>

          <Nav.Item
            onClick={() => {
              localStorage.setItem("ActiveKeyTransaction", "second");
              setTab("second");
            }}
          >
            <Nav.Link
              className={tab === "second" ? "active" : "inactive"}
              eventKey="second"
            >
              Credit Transactions
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <br />
      <br />
      {tab === "first" && <DebitTransactions />}
      {tab === "second" && <CreditTransactions />}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={() => {
          setModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={modal}>
          <form className="paper" onSubmit={(e) => downloadExcel(e)}>
            <h5 className="text-center mb-4">
              Download {tab === "first" ? "Debit" : "Credit"} Excel Report.
            </h5>

            <div className="row justify-content-center">
              <div className="col-6">
                <FormControl
                  variant="standard"
                  style={{
                    width: "200px",
                  }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Financial Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="Financial Year"
                    style={{
                      fontSize: "13px",
                      textAlign: "left",
                    }}
                    value={financialYear}
                    onChange={(e) => setFinancialYear(e.target.value)}
                  >
                    <MenuItem value="">Financial Year</MenuItem>
                    <MenuItem value="2023-24">2023-24</MenuItem>
                    <MenuItem value="2022-23">2022-23</MenuItem>
                    <MenuItem value="2021-22">2021-22</MenuItem>
                    <MenuItem value="2020-21">2020-21</MenuItem>
                    <MenuItem value="2019-20">2019-20</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col-6">
                <FormControl
                  variant="standard"
                  style={{
                    width: "200px",
                  }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Transaction Mode
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="Transaction Mode"
                    style={{
                      fontSize: "13px",
                      textAlign: "left",
                    }}
                    value={transactionMode}
                    onChange={(e) => setTransactionMode(e.target.value)}
                  >
                    <MenuItem value="">Transaction Mode</MenuItem>
                    <MenuItem value="Cash">Cash</MenuItem>
                    <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                    <MenuItem value="Cheque">Cheque</MenuItem>
                    <MenuItem value="Demant Draft">Demand Draft</MenuItem>
                    <MenuItem value="Payment Voucher">Payment Voucher</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              {tab === "first" ? (
                <div className="col-6">
                  <FormControl
                    variant="standard"
                    style={{
                      width: "180px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Cost Centre
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label="Cost Centre"
                      style={{
                        fontSize: "13px",
                        textAlign: "left",
                      }}
                      value={costCenterCode}
                      onChange={(e) => setCostCenterCode(e.target.value)}
                    >
                      <MenuItem value="">Cost Centre</MenuItem>
                      {costCenterList?.map((centre, index) => {
                        return (
                          <MenuItem key={index} value={centre?.costCenterCode}>
                            {centre?.costCenterName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              ) : (
                <div className="col-6">
                  <FormControl
                    variant="standard"
                    style={{
                      width: "180px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Site
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label="Site"
                      style={{
                        fontSize: "13px",
                        textAlign: "left",
                      }}
                      value={siteCode}
                      onChange={(e) => setSiteCode(e.target.value)}
                    >
                      <MenuItem value="">Site</MenuItem>
                      {siteList?.map((site, index) => {
                        return (
                          <MenuItem key={index} value={site?.siteCode}>
                            {site?.siteName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>

            <br />

            <div className="row justify-content-center">
              <div className="col-6 text-center">
                <button className="transactions__btn" type="sumbit">
                  Generate
                </button>
              </div>

              <div className="col-6 text-center">
                <button
                  className="transactions__btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setModal(false);
                    reset();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Fade>
      </Modal>
      {/* //////////// Modal for spinner //////////// */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={loader}
        onClose={() => {
          setLoader(false);
        }}
        closeAfterTransition
      >
        <Fade in={loader}>
          <div>
            <MoonLoader
              color="red"
              className="spinner"
              loading={loader}
              size={70}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Transactions;
