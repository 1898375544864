import MaterialTable, { MTableToolbar } from "material-table";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";
import React, { useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import Button from "../../components/Button/Button";
import { useStyles } from '../../utils/ModalStyles';
import { Delete } from '@material-ui/icons'
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Form } from "react-bootstrap";



const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#EE4B46",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
});


const ListOfSalesCode = () => {

  const classes = useStyles()
  const navigate = useNavigate();
  const [salesCode, setSalesCode] = useState([]);
  const [checkActive, setCheckActive] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false)
  const [code, setCode] = useState('')

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));


  // get all sales code
  useEffect(() => {

    getListOfSalesCode()

  }, [isChange, checkActive]);

  const getListOfSalesCode = async () => {
    const res = await axios.get(
      `${BASE_URL}/api/v1/leadmgmt/SalesCode/getListOfSalesCode`,
      {
        headers: { Authorization: `bearer ${userInfo.token}` },
      }
    );
    const data = res?.data?.salesCodeList;
    
    setSalesCode(data);
  }

  const handleDeleteSalesCode = () => {

    axios.delete(`${BASE_URL}/api/v1/leadmgmt/SalesCode/deleteSalesCode/${code}`, {
      headers: { Authorization: `bearer ${userInfo.token}` }
    })
      .then((response) => {

        setDeleteModal(false)
        if (response.status === 200) {

          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            }
          }).then(function () {
            getListOfSalesCode()
          })
        }
      })
      .catch((err) => {
        setDeleteModal(false)
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error
        })
      })
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="outlet_bg">
        <div className="outlet_container">
          <div className="d-flex justify-content-between align-items-center">
            <Button navigateTo={'/home'} />

            <button
              className="create_btn px-4 fw-bold"
              onClick={() => navigate("/createsalescode")}
            >
              Create Sales Code
            </button>
          </div>
          <div className="mt-5">
            <MaterialTable
              data={salesCode}
              title="Sales Code"

              columns={[

                { title: "Sales Code", field: "salesCode" },
                { title: "Sales Code Title", field: "salesCodeTitle" },
                { title: "Sales Code Description", field: "salesCodeDescription" },
                {
                  title: "Start Date",
                  render: (rowData) =>
                    rowData.startDate.substring(8, 10) +
                    "-" +
                    rowData.startDate.substring(5, 7) +
                    "-" +
                    rowData.startDate.substring(0, 4),
                  customSort: (a, b) => (a.startDate < b.startDate ? -1 : 1),
                },
                {
                  title: "End Date",
                  render: (rowData) =>
                    rowData.endDate.substring(8, 10) +
                    "-" +
                    rowData.endDate.substring(5, 7) +
                    "-" +
                    rowData.endDate.substring(0, 4),
                  customSort: (a, b) => (a.endDate < b.endDate ? -1 : 1),
                },
                {
                  title: "Units",
                  render: (rowData) => (rowData?.unitName.join(','))
                }
              ]}

              actions={[
                {
                  icon: 'delete',
                  tooltip: 'Delete Sales Code',
                  onClick: (event, rowData) => {
                    setDeleteModal(true)
                    setCode(rowData?.salesCode)
                  },
                },
              ]}

              options={{
                search: true,
                searchFieldStyle: {
                  width: 180
                },
                actionsColumnIndex: 0,
                paging: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 20, 30],
                headerStyle: {
                  backgroundColor: "#EE4B46",
                  color: "#fff",
                },
              }}
            ></MaterialTable>
          </div>
        </div>
      </div>

      {/* //////////////////////// Modal for Deleting Sales Code ///////////////////// */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={deleteModal}>
          <div className={classes.paper}>
            <h6>Are you sure you want to delete {code} ?</h6>
            <br />
            <div className="row container-fluid justify-content-center">
              <div className="col-6 text-right">
                <button
                  className="modal__btns"
                  onClick={handleDeleteSalesCode}
                >
                  Yes
                </button>
              </div>
              <div className="col-6 text-left">
                <button
                  className="modal__btns"
                  onClick={(e) => {
                    e.preventDefault()
                    setDeleteModal(false)
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
};

export default ListOfSalesCode;
