import React, { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useNavigate } from 'react-router-dom';
import { MenuItem, FormControl, InputLabel, Select, Modal, Fade } from '@mui/material'
import Swal from 'sweetalert2';

const SalaryAdvance = ({ employee }) => {

    // console.log(employee)

    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const [openAdvances, setOpenAdvances] = useState([]);
    const [availablePercentage, setAvailablePercentage] = useState(0);
    const [availedPercentage, setAvailedPercentage] = useState(0);
    const [noOfOpenSalaryAdvance, setNoOfOpenSalaryAdvance] = useState(0);
    const [status, setStatus] = useState('')
    const [applyLimitModal, setApplyLimitModal] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)

    useEffect(() => {

        let total = employee?.availableSalaryAdvance + employee?.availedSalaryAdvance;
        let available = (employee?.availableSalaryAdvance / total) * 100;
        let availed = (employee?.availedSalaryAdvance / total) * 100;

        setAvailablePercentage(available);
        setAvailedPercentage(availed);

    }, [employee])

    useEffect(() => {

        getListOfSalaryAdvance();

    }, [employee, status]);

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/SalaryAdvance/getNoOfEmployeeOpenSalaryAdvance?employeeId=${userInfo?.employeeId}`,
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                }
            })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status) {
                    setNoOfOpenSalaryAdvance(response.data.noOfOpenSalaryAdvances);
                } else {
                    setNoOfOpenSalaryAdvance(0)
                }
            })
    }, [])

    const getListOfSalaryAdvance = () => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/SalaryAdvance/getListOfSalaryAdvance?employeeId=${userInfo?.employeeId}&status=${status}`,
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                }
            })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status) {
                    setOpenAdvances(response.data.SalaryAdvance)
                } else {
                    setOpenAdvances([])
                }
            })


    }

    const reset = () => {
        setStatus('')
    }

    const applyForLimit = () => {

        axios.post(`${BASE_URL}/api/v1/hrmgmt/salaryAdvance/applyForSalaryAdvanceLimit`,
            {
                employeeId: employee?.employeeId,
                isUpdate: isUpdate
            },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                }
            })
            .then((response) => {
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                    })
                        .then(() => {
                            window.location.reload()
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                    })
                }
            })
            .catch((err) => {
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error,
                })
            })


    }


    return (
        <div>

            <h4 className="text-center mb-5">
                <b>Salary Advance</b>
            </h4>

            <div className='row justify-content-start'
                style={{
                    width: '90%',
                    margin: 'auto',
                }}
            >
                <div className='col-6 text-center'>
                    <div className='d-flex align-items-center justify-content-start'>
                        <div
                            style={{ backgroundColor: '#EE4B46', width: '15px', height: '15px', borderRadius: '50%', border: '1px solid black', marginRight: '10px' }}
                        ></div>
                        <span>Availed Limit : {employee?.availedSalaryAdvance}</span>
                    </div>

                    <div className='d-flex align-items-center justify-content-start'>
                        <div
                            style={{ backgroundColor: 'white', width: '15px', height: '15px', borderRadius: '50%', border: '1px solid black', marginRight: '10px' }}
                        ></div>
                        <span>Available Limit : {employee?.availableSalaryAdvance}</span>
                    </div>

                </div>
            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6 d-flex flex-column'>
                    <div className='salary__bar p-0'>

                        <div
                            style={{ flex: `${availablePercentage}`, backgroundColor: 'white' }}
                        >
                            {/* Available Limit : {employee?.availableSalaryAdvance} */}
                        </div>

                        <div
                            style={{ backgroundColor: '#EE4B46', flex: `${availedPercentage}` }}
                        >
                            {/* Availed Limit : {employee?.availedSalaryAdvance} */}
                        </div>
                    </div>
                    <b>
                        Total Limit : {employee?.salaryAdvanceLimit}
                    </b>
                </div>

                <div className='col-3 text-center'>
                    <h5>Open Advances : {noOfOpenSalaryAdvance}</h5>
                </div>

                <div className='col-3 text-center'>

                    {
                        employee?.salaryAdvanceStatus === 'Not Eligible'
                            ?
                            <button className='salary__btn salary__btn-disabled'
                                disabled
                            >Not Eligible For Salary Advance</button>
                            :
                            employee?.salaryAdvanceStatus === 'Not Applied'
                                ?
                                <button className='salary__btn'
                                    onClick={() => {
                                        setApplyLimitModal(true)
                                    }}
                                >Apply For Limit</button>
                                :
                                employee.salaryAdvanceStatus === 'Applied'
                                    ?
                                    <button className='salary__btn salary__btn-disabled'
                                        disabled
                                    >Limit Under Process</button>
                                    :
                                    <button className='salary__btn'
                                        onClick={() => {
                                            navigate('/applySalaryAdvance')
                                        }}
                                    >Apply Salary Advance</button>
                    }

                </div>
            </div>

            <br /><br />

            {
                <>
                    <h4>Open Salary Advances</h4>
                    <MaterialTable
                        data={openAdvances}
                        title=""

                        columns={
                            [
                                {
                                    title: 'Salary Advance ID',
                                    render: (rowData) => {
                                        return <a href={`/salaryAdvance/${rowData?.salaryAdvanceId}`}>{rowData?.salaryAdvanceId}</a>
                                    }
                                },
                                { title: 'Status', field: 'status' },
                                { title: 'Total Amount', field: 'approvedAmount' },
                                { title: 'Remaining Amount', field: 'remainingAmount' },
                                {
                                    title: 'No. Of Installments', field: 'noOfInstallments'
                                },

                                { title: 'Remaining Installments', render: (rowData) => ((rowData?.noOfInstallments - rowData?.paidInstallments) || '0') },

                            ]
                        }

                        options={{
                            search: true,
                            paging: true,
                            pageSize: 20,
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [5, 10, 20, 30],
                            actionsColumnIndex: 0,
                            headerStyle: {
                                backgroundColor: '#EE4B46',
                                color: '#fff',
                            }
                        }}

                        // actions={[
                        //     {
                        //         icon: 'remove_red_eye',
                        //         tooltip: 'View Site',
                        //         // onClick: (event, rowData) => {
                        //         //     setTab('first')
                        //         //     navigate(`/configurations/site/${rowData.siteCode}`);

                        //         // },
                        //     },

                        // ]}

                        components={{
                            Toolbar: (props) => (
                                <div className="filters text-left">
                                    <MTableToolbar {...props} />

                                    <FormControl
                                        variant='standard'
                                        className="formControl" style={{ width: "120px", margin: '0 13px', marginTop: '-65px' }}>
                                        <InputLabel id="demo-simple-select-helper-label" style={{ fontSize: "14px" }}>
                                            Status
                                        </InputLabel>

                                        <Select value={status}
                                            onChange={(e) => setStatus(e.target.value)}

                                            inputProps={{ "aria-label": "Without label" }}>
                                            <MenuItem value=''>Select a Status</MenuItem>
                                            <MenuItem value=''>All</MenuItem>
                                            <MenuItem value="Applied">Applied</MenuItem>
                                            <MenuItem value="Approved">Approved</MenuItem>
                                            <MenuItem value="Rejected">Rejected</MenuItem>
                                            <MenuItem value="InQueue">InQueue</MenuItem>
                                            <MenuItem value="Repaid">Repaid</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl className="formControl" >
                                        <button
                                            onClick={reset}
                                            style={{
                                                backgroundColor: "white",
                                                color: "black",
                                                border: "1px solid black",
                                                borderRadius: "20px",
                                                padding: "5px",
                                                width: "100px",
                                                margin: "auto",
                                                marginTop: '-50px'
                                            }}
                                        >
                                            Reset Filter
                                        </button>
                                    </FormControl>
                                </div>
                            ),
                        }}

                    ></MaterialTable>
                </>

            }


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={applyLimitModal}
                onClose={() => {
                    setApplyLimitModal(false)
                }}
                closeAfterTransition
            >
                <Fade in={applyLimitModal}>

                    <div className='paper p-3'>

                        <h5 className='text-center'>Are you sure you want to Apply for Salary Advance Limit ?</h5>

                        <br />

                        <div className='row d-flex justify-content-center'>
                            <div className='col-6 text-center'>
                                <button
                                    className='salary__btn'
                                    onClick={() => {
                                        applyForLimit()
                                        setApplyLimitModal(false)
                                    }}
                                >Yes</button>
                            </div>
                            <div className='col-6 text-center'>
                                <button
                                    className='salary__btn'
                                    onClick={() => {
                                        setApplyLimitModal(false)
                                    }}
                                >No</button>
                            </div>
                        </div>

                    </div>

                </Fade>
            </Modal>

        </div>
    )
}

export default SalaryAdvance