import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateOutlet = () => {

    // const [isExpired, setIsExpired] = useState(false)
    // const [user, setUser] = useState({});

    const user = JSON.parse(localStorage.getItem('userInfo'));
    let isExpired = false;
    sessionStorage.setItem('path', JSON.stringify(window.location.href))

    if (user?.token) {
        const expireTime = JSON.parse(window.atob(user.token.split('.')[1])).exp;

        if (new Date() >= expireTime * 1000) {
            isExpired = true;
        }

    }


    return (!user?.token || isExpired ? <Navigate to='/login' /> : <Outlet />)
};

export default PrivateOutlet;