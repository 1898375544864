import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import FinalBtn from "./FinalBtn";
import {dateFormatting} from "../../utils/dateFormatting";

const QuotationList = ({ quotations, setChange }) => {

  const [approved, setApproved] = useState(false)

  useEffect(() => {
    quotations?.forEach((q) => {
      if (q.isFinalApproved) {
        return setApproved(true)
      }
    })
  }, [quotations])



  return (
    <div>
      <MaterialTable
        data={quotations}
        title="Quotations"
        columns={[
          { title: "Quotation Id", field: "quotationId" },
          { title: "Unit Name", field: "unit.unitName" },
          { title: "BHK", field: "unit.bhk" },

          {
            title: "Quotation Date",
            defaultSort: "desc",
            render: (rowData) => {
              let res = '';
              if (rowData.quotationDate) {
                res = dateFormatting(rowData.quotationDate)
                return res
              } else {
                return ''
              }
            },
            customSort: (a, b) => (a.quotationDate < b.quotationDate ? -1 : 1),
          },
          {
            title: 'Generated By', field: "qtnGeneratedBy"
          },
          {
            title: "Final Review",
            render: (rowData) => (<FinalBtn quotation={rowData} setChange={setChange} approved={approved} setApproved={setApproved} />),
          },
        ]}
        options={{
          search: true,
          searchFieldStyle: {
            width: 180
          },
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
          },
        }}
        actions={[
          {
            icon: "download",
            tooltip: "Download Quotation",
            onClick: (event, rowData) => {

              const link = document.createElement("a");
              link.download = `quotation.pdf`;
              link.href = rowData.quotationPDFLink;
              link.click();
            },
          },
        ]}
      />
    </div >
  );
};

export default QuotationList;
