import React, { useState, useEffect } from "react";
import "./SelfOnboard.scss";
import { Paper } from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";

const UploadGovtId = ({ setActive, employeeId, onBoardInfo }) => {
  const [aadhar, setAadhar] = useState("");
  const [aadharLink, setAadharLink] = useState("");
  const [pan, setPan] = useState("");
  const [panLink, setPanLink] = useState("");
  const [passportNo, setPassportNo] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [placeOfIssue, setPlaceOfIssue] = useState("");
  const [countryOfIssue, setCountryOfIssue] = useState("");

  const [passportLink, setPassportLink] = useState("");

  const [aadharFile, setAadharFile] = useState("");
  const [panFile, setPanFile] = useState("");
  const [passportFile, setPassportFile] = useState("");

  const [changedDoc, setChangedDoc] = useState("");
  const [changedFile, setChangedFile] = useState("");
  const [loading, setLoading] = useState(false);

  //////// error variables ///////
  const [aadharError, setAddharError] = useState("");
  const [panError, setPanError] = useState("");

  const dateFormatFromUTCToLocal = (input) => {
    let date = new Date(input).getDate();
    if (date < 10) {
      date = `0${date}`;
    }

    let month = new Date(input).getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }

    let year = new Date(input).getFullYear();
    if (year < 10) {
      year = `0${year}`;
    }

    return `${year}-${month}-${date}`;
  };

  useEffect(() => {
    let isMounted = true;

    const getEmployeeById = async () => {
      setLoading(false);
      try {
        const res = await axios.get(
          `${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${employeeId}`,
          {
            headers: {
              Authorization: `Bearer ${onBoardInfo.token}`,
            },
          }
        );

        if (isMounted) {
          if (res.data.status === true) {
            setAadhar(res?.data?.employee?.employeeAadhar === 0 ? "" : res?.data?.employee?.employeeAadhar);
            setPan(res?.data?.employee?.employeePAN);
            setPassportNo(res?.data?.employee?.employeePassport?.passportNo);
            setIssueDate(
              res?.data?.employee?.employeePassport?.issueDate === null ? "" :
              dateFormatFromUTCToLocal(
                res?.data?.employee?.employeePassport?.issueDate
              )
            );
            setExpiryDate(
              res?.data?.employee?.employeePassport?.expiryDate === null ? "" :
              dateFormatFromUTCToLocal(
                res?.data?.employee?.employeePassport?.expiryDate
              )
            );
            setPlaceOfIssue(
              res?.data?.employee?.employeePassport?.placeOfIssue
            );
            setCountryOfIssue(
              res?.data?.employee?.employeePassport?.countryOfIssue
            );
            setAadharLink(res?.data?.employee?.employeeAadharScan);
            setPanLink(res?.data?.employee?.employeePANScan);
            setPassportLink(
              res?.data?.employee?.employeePassport?.passportScan
            );
          }
        }
      } catch (error) {
        console.error("Error fetching employee details:", error);
        // Handle the error as needed
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    };

    getEmployeeById();

    return () => {
      isMounted = false; // Set isMounted to false when the component is unmounted
    };
  }, [loading]);

  const uploadDoc = async () => {
    try {
      let formData = new FormData();
      formData.append("employeeId", employeeId);

      if (changedFile === "Aadhar") {
        formData.append("documentType", "aadhar");
        formData.append("file", aadharFile);
        formData.append("employeeAadhar", aadhar);
      }

      if (changedFile === "PAN") {
        formData.append("documentType", "pan");
        formData.append("file", panFile);
        formData.append("employeePAN", pan);
      }
      if (changedFile === "Passport") {
        formData.append("documentType", "passport");
        formData.append("file", passportFile);
        formData.append("passportNo", passportNo);
        formData.append("issueDate", issueDate);
        formData.append("expiryDate", expiryDate);
        formData.append("placeOfIssue", placeOfIssue);
        formData.append("countryOfIssue", countryOfIssue);
      }

      let response = await axios.put(
        `${BASE_URL}/api/v1/hrmgmt/Employee/uploadGovtId`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${onBoardInfo.token}`,
          },
        }
      );

      if (response.data.status === true) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(() => {
          setAadharFile("");
          setPanFile("");
          setPassportFile("");
          setChangedDoc("");
          setChangedFile("");
          setLoading(true);
          let files = document.querySelectorAll('input[type="file"]');
          files.forEach((file) => (file.value = ""));

          // window.location.reload();
        });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: err.response.data.error,
      });
    }
  };
  return (
    <div className="selfOnboard">
      <Paper elevation={3} className="selfOnboard__paper">
        <h4 className="text-left">Employee Aadhar</h4>

        <div className="row justify-content-center">
          <div className="col-4 text-center">
            <label>Aadhar No.</label>
            <input
              type="text"
              className="form-control"
              required
              value={aadhar}
              onChange={(e) => {
                setAadhar(e.target.value);
                setChangedDoc("Aadhar");

                if (e.target.value.length !== 12) {
                  setAddharError("Aadhar no. should be of 12 digits");
                } else if (/^[0-9]+$/.test(e.target.value) === false) {
                  setAddharError("Aadhar no. should contain only numbers");
                } else {
                  setAddharError("");
                }
              }}
            />

            {aadharError && <em style={{ color: "#EE4B46" }}>{aadharError}</em>}
          </div>

          <div className="col-4 text-center mt-4">
            <a href={aadharLink} download>
              <button
                type="button"
                disabled={!aadharLink}
                style={{ backgroundColor: !aadharLink ? "grey" : "" }}
              >
                Download Aadhar
              </button>
            </a>
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-4 text-center">
            <input
              type="file"
              name="aadhar-file"
              accept=".pdf"
              className="form-control"
              onChange={(e) => {
                setAadharFile(e.target.files[0]);
                setChangedFile("Aadhar");
              }}
            />
          </div>

          <div className="col-4 text-center">
            <button className="view-docs__btn" onClick={uploadDoc}>
              Upload Aadhar Details
            </button>
          </div>
        </div>
      </Paper>

      <br />

      <Paper elevation={3} className="selfOnboard__paper">
        <h4 className="text-left">Employee PAN</h4>

        <div className="row justify-content-center">
          <div className="col-4 text-center">
            <label>PAN No.</label>
            <input
              type="text"
              className="form-control"
              required
              value={pan}
              onChange={(e) => {
                setPan(e.target.value);
                setChangedDoc("PAN");
                if (e.target.value.length !== 10) {
                  setPanError("PAN no. should be of 10 digits");
                } else if (/^[A-Z0-9]+$/.test(e.target.value) === false) {
                  setPanError(
                    "PAN no. should contain only numbers and uppercase letters"
                  );
                } else {
                  setPanError("");
                }
              }}
            />

            {panError && <em style={{ color: "#EE4B46" }}>{panError}</em>}
          </div>

          <div className="col-4 text-center mt-4">
            <a href={panLink} download>
              <button
                type="button"
                disabled={!panLink}
                style={{ backgroundColor: !panLink ? "grey" : "" }}
              >
                Download Pan
              </button>
            </a>
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-4 text-center">
            <input
              type="file"
              name="pan-file"
              accept=".pdf"
              className="form-control"
              onChange={(e) => {
                setPanFile(e.target.files[0]);
                setChangedFile("PAN");
              }}
            />
          </div>

          <div className="col-4 text-center">
            <button className="view-docs__btn" onClick={uploadDoc}>
              Upload Pan Details
            </button>
          </div>
        </div>
      </Paper>

      <br />

      <Paper elevation={3} className="selfOnboard__paper">
        <h4 className="text-left">Employee Passport</h4>

        <div className="row justify-content-center">
          <div className="col-4 text-center">
            <label>Passport No.</label>
            <input
              type="text"
              className="form-control"
              value={passportNo}
              onChange={(e) => {
                setPassportNo(e.target.value);
                setChangedDoc("Passport");
              }}
            />
          </div>

          <div className="col-4 text-center">
            <label>Upload Passport Scan</label>
            <input
              type="file"
              name="passport-file"
              accept=".pdf"
              className="form-control"
              onChange={(e) => {
                setPassportFile(e.target.files[0]);
                setChangedFile("Passport");
              }}
            />
          </div>

          <div className="col-4 text-center mt-4">
            <a href={passportLink} download>
              <button
                type="button"
                disabled={!passportLink}
                style={{ backgroundColor: !passportLink ? "grey" : "" }}
              >
                Download Passport
              </button>
            </a>
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-4 text-center">
            <label>Issue Date</label>
            <input
              type="date"
              className="form-control"
              value={issueDate}
              onChange={(e) => {
                setIssueDate(e.target.value);
                setChangedDoc("Passport");
              }}
            />
          </div>
          <div className="col-4 text-center">
            <label>Expiry Date</label>
            <input
              type="date"
              className="form-control"
              value={expiryDate}
              onChange={(e) => {
                setExpiryDate(e.target.value);
                setChangedDoc("Passport");
              }}
            />
          </div>
          <div className="col-4 text-center"></div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="col-4 text-center">
            <label>Place of Issue</label>
            <input
              type="text"
              className="form-control"
              value={placeOfIssue}
              onChange={(e) => {
                setPlaceOfIssue(e.target.value);
                setChangedDoc("Passport");
              }}
            />
          </div>
          <div className="col-4 text-center">
            <label>Country of Issue</label>
            <input
              type="text"
              className="form-control"
              value={countryOfIssue}
              onChange={(e) => {
                setCountryOfIssue(e.target.value);
                setChangedDoc("Passport");
              }}
            />
          </div>
          <div className="col-4 text-center mt-4">
            <button className="view-docs__btn" onClick={uploadDoc}>
              Upload Passport Details
            </button>
          </div>
        </div>
      </Paper>
      <div className="row justify-content-between">
        <div className="col-1">
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setActive("emp-dtls");
            }}
          >
            Prev
          </button>
        </div>
        <div className="col-1">
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setActive("edu");
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadGovtId;
