import Button from "../../components/Button/Button";
import { Form } from "react-bootstrap";
import "./EditUnitType.scss";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";
import { Paper } from "@mui/material";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import { MdDelete } from "react-icons/md";
import { Modal, Backdrop, Fade } from "@mui/material";

const EditUnitType = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const unitTypeName = useParams().unitType;
  const siteCode = useParams().siteCode;
  const navigate = useNavigate();

  //////////////// STATES /////////////////

  const [bhk, setBhk] = useState("");
  const [ca, setCa] = useState("");
  const [ba, setBa] = useState("");
  const [bua, setBua] = useState("");
  const [sbua, setSbua] = useState("");
  const [floorPlan, setFloorPlan] = useState("");
  const [threedPlan, setThreedPlan] = useState("");
  const [salesImage, setSalesImage] = useState("");

  const [unitType, setUnitType] = useState("");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [photoIndex2, setPhotoIndex2] = useState(0);
  const [index, setIndex] = useState(0);

  const [fpImages, setFpImages] = useState([]);
  const [tdImages, setTdImages] = useState([]);
  const [images, setImages] = useState([]);
  const [deleteImage, setDeleteImage] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    getUnitTypeByUnitTypeName();
  }, []);

  const getUnitTypeByUnitTypeName = () => {
    const Token = "bearer" + " " + userInfo.token;

    axios
      .get(
        `${BASE_URL}/api/v1/Configuration/UnitType/getUnitTypeByUnitTypeName?unitTypeName=${unitTypeName}&siteCode=${siteCode}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        setUnitType(response?.data?.unitType);
        response?.data?.unitType?.floorPlan != undefined &&
          setFpImages((prev) => {
            return [response?.data?.unitType?.floorPlan];
          });

        response?.data?.unitType?.threedPlan != undefined &&
          setTdImages((prev) => {
            return [response?.data?.unitType?.threedPlan];
          });

        response?.data?.unitType?.salesImages?.length > 0 &&
          setImages([...response?.data?.unitType?.salesImages]);

        const { bhk, balconyArea, carpetArea, builtUpArea, superBuiltUpArea } =
          response.data.unitType;

        setBhk(bhk);
        setCa(carpetArea);
        setBa(balconyArea);
        setBua(builtUpArea);
        setSbua(superBuiltUpArea);
      });
  };

  // console.log(images)

  const handleEditUnitType = () => {
    const Token = "bearer" + " " + userInfo.token;

    axios
      .put(
        `${BASE_URL}/api/v1/Configuration/UnitType/updateUnitType`,
        {
          unitTypeName: unitTypeName,
          siteCode: siteCode,
          superBuiltUpArea: sbua,
          builtUpArea: bua,
          bhk: bhk,
          carpetArea: ca,
          balconyArea: ba,
        },
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            navigate(-1);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: error.data.error,
        });
      });
  };

  const uploadImage = (type) => {
    if (type === "floorPlan" && !floorPlan) {
      return;
    }

    if (type === "threedPlan" && !threedPlan) {
      return;
    }

    if (type === "salesImages" && !salesImage) {
      return;
    }

    let formData = new FormData();
    formData.append(
      "file",
      type === "floorPlan"
        ? floorPlan
        : type === "threedPlan"
        ? threedPlan
        : salesImage
    );
    formData.append("imageType", type);
    formData.append("unitTypeName", unitTypeName);
    formData.append("siteCode", siteCode);

    axios
      .put(`${BASE_URL}/api/v1/Configuration/UnitType/uploadImage`, formData, {
        headers: {
          Authorization: "bearer" + " " + userInfo.token,
        },
      })
      .then((response) => {
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          }).then(() => {
            // setFloorPlan('')
            // setThreedPlan('')
            // setSalesImage('')
            window.location.reload();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const deleteUnitPhoto = (e) => {
    e.preventDefault();

    axios
      .put(
        `${BASE_URL}/api/v1/Configuration/UnitType/deleteImage`,
        {
          unitTypeName: unitTypeName,
          photoLink: deleteImage,
          siteCode: siteCode,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setDeleteModal(false);

        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setDeleteImage("");
            getUnitTypeByUnitTypeName();
          });
        }
      })
      .catch((err) => {
        setDeleteModal(false);

        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  return (
    <div className="editunittype">
      <Button navigateTo={1} />

      <div className="editunittype__form">
        <h3>Edit Unit Type</h3>
        <div className="unittypename">Unit Type Name - {unitTypeName}</div>

        <div className="row container-fluid justify-content-center">
          <div className="col-5">
            <Form.Group controlId="bhk">
              <Form.Label>BHK</Form.Label>
              <Form.Control
                required
                as="select"
                value={bhk}
                onChange={(e) => setBhk(e.target.value)}
              >
                <option value="1BHK">1BHK</option>
                <option value="2BHK">2BHK </option>
                <option value="3BHK">3BHK</option>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="col-5">
            <label>Carpet Area</label>
            <input
              type="number"
              className="form-control"
              value={ca}
              onChange={(e) => setCa(e.target.value)}
            />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className="col-5">
            <label>Balcony Area</label>
            <input
              type="number"
              className="form-control"
              value={ba}
              onChange={(e) => setBa(e.target.value)}
            />
          </div>
          <div className="col-5">
            <label>Built Up Area</label>
            <input
              type="number"
              className="form-control"
              value={bua}
              onChange={(e) => setBua(e.target.value)}
            />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className="col-5">
            <label>Super Built Up Area</label>
            <input
              type="number"
              className="form-control"
              value={sbua}
              onChange={(e) => setSbua(e.target.value)}
            />
          </div>
        </div>

        <button onClick={handleEditUnitType}>Save</button>
      </div>

      <br />

      <Paper
        elevation={2}
        style={{ padding: "20px", width: "90%", margin: "auto" }}
      >
        <div className="row justify-content-start">
          <div className="col-6 text-center">
            <label>Upload Floor Plan Image</label>
            <input
              type="file"
              className="form-control"
              htmlFor="floorPlan-image"
              onChange={(e) => setFloorPlan(e.target.files[0])}
            />
          </div>

          <div className="col-3 text-center">
            <button
              className="button mt-4"
              onClick={() => uploadImage("floorPlan")}
            >
              Upload
            </button>
          </div>

          <br />

          <div className="PlanImageContainer">
            {fpImages?.map((i, index) => {
              return (
                <span
                  className="image"
                  key={index}
                  id={`image-container-${index}`}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    id="imageHolder"
                    src={i}
                    alt="images"
                    onClick={() => {
                      setOpen(true);
                    }}
                  />
                </span>
              );
            })}
          </div>
        </div>

        <br />
        <br />

        <div className="row justify-content-start">
          <div className="col-6 text-center">
            <label>Upload 3D Plan Image</label>
            <input
              type="file"
              className="form-control"
              htmlFor="floorPlan-image"
              onChange={(e) => setThreedPlan(e.target.files[0])}
            />
          </div>

          <div className="col-3 text-center">
            <button
              className="button mt-4"
              onClick={() => uploadImage("threedPlan")}
            >
              Upload
            </button>
          </div>

          <div className="PlanImageContainer">
            {tdImages?.map((i, index) => {
              return (
                <span
                  className="image"
                  key={index}
                  id={`image-container-${index}`}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    id="imageHolder"
                    src={i}
                    alt="images"
                    onClick={() => {
                      setOpen2(true);
                    }}
                  />
                </span>
              );
            })}
          </div>
        </div>
      </Paper>

      <br />

      <Paper
        elevation={2}
        style={{ padding: "20px", width: "90%", margin: "auto" }}
      >
        <div className="row justify-content-center">
          <div className="col-6 text-center">
            <label>Upload Sales Images</label>
            <input
              type="file"
              className="form-control"
              htmlFor="floorPlan-image"
              onChange={(e) => setSalesImage(e.target.files[0])}
            />
            <em style={{ color: "red", textAlign: "center" }}>
              Can't Upload more than 5 Images
            </em>
          </div>

          <div className="col-4 text-center">
            <button
              className="button mt-4"
              disabled={unitType?.salesImages?.length === 5}
              style={{
                backgroundColor:
                  unitType?.salesImages?.length === 5 ? "grey" : "",
              }}
              onClick={() => uploadImage("salesImages")}
            >
              Upload
            </button>
          </div>

          {/* {
            images?.length > 0
            &&
            <div className='col-2 text-center mt-4'>
              <span
                style={{ color: '#EE4B46', cursor: 'pointer' }}
                onClick={() => {
                  setOpen3(true)
                }}
              >View Images</span>
            </div>
          } */}
        </div>

        <br />

        <div className="unitphoto__images">
          {images.map((i, index) => {
            return (
              <span
                className="image"
                key={index}
                id={`image-container-${index}`}
              >
                <img
                  src={i}
                  alt="images"
                  onClick={() => {
                    setOpen3(true);
                    setIndex(index);
                  }}
                />
                <MdDelete
                  style={{
                    fontSize: "28px",
                    marginLeft: "90%",
                    color: "black",
                    cursor: "pointer",
                  }}
                  id={`delete-icon-${index}`}
                  onClick={(e) => {
                    setDeleteModal(true);
                    setDeleteImage(i);
                  }}
                />
              </span>
            );
          })}
        </div>
      </Paper>

      {open && (
        <Lightbox
          mainSrc={fpImages[photoIndex]}
          nextSrc={fpImages[(photoIndex + 1) % fpImages.length]}
          prevSrc={
            fpImages[(photoIndex + fpImages.length - 1) % fpImages.length]
          }
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + fpImages.length - 1) % fpImages.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % fpImages.length)
          }
        />
      )}

      {open2 && (
        <Lightbox
          mainSrc={tdImages[photoIndex2]}
          nextSrc={tdImages[(photoIndex2 + 1) % tdImages.length]}
          prevSrc={
            tdImages[(photoIndex2 + tdImages.length - 1) % tdImages.length]
          }
          onCloseRequest={() => setOpen2(false)}
          onMovePrevRequest={() =>
            setPhotoIndex2(
              (photoIndex2 + tdImages.length - 1) % tdImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex2((photoIndex2 + 1) % tdImages.length)
          }
        />
      )}

      {open3 && (
        <Lightbox
          mainSrc={images[index]}
          nextSrc={images[(index + 1) % images.length]}
          prevSrc={images[(index + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen3(false)}
          onMovePrevRequest={() =>
            setIndex((index + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setIndex((index + 1) % images.length)}
        />
      )}

      {/* ////////////Modal for deleting Photots ////////////// */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={deleteModal}>
          <form className="paper" onSubmit={(e) => deleteUnitPhoto(e)}>
            <h4 className="text-center">Do you want to delete this photo ?</h4>
            <br />

            <div className="row justify-content-center">
              <div className="col-4">
                <button className="unitphoto__btn" type="sumbit">
                  Yes
                </button>
              </div>

              <div className="col-4">
                <button
                  className="unitphoto__btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteModal(false);
                    setDeleteImage("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Fade>
      </Modal>
    </div>
  );
};

export default EditUnitType;
