import { Paper } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import { BASE_URL } from '../../utils/config/url';

const LeadOpenAgeReport = ({ siteCode }) => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [leadDuration, setLeadDuration] = useState('')
    const [leadCount, setLeadCount] = useState('')
    const [leadBg, setLeadBg] = useState('')

    useEffect(() => {
        let url = `${BASE_URL}/api/v1/leadmgmt/leadReport/getOpenLeadAgeReport?siteCode=${siteCode}`;

        axios
            .get(url, {
                headers: { Authorization: `bearer ${userInfo.token}` },
            })
            .then((response) => {
                // console.log(response)
                let data = response?.data?.openLeadAgeReport?.map((data) => {
                    return data.count;
                });
                setLeadCount(data);

                let names = response?.data?.openLeadAgeReport?.map((data) => {
                    return data.label;
                });

                setLeadDuration(names);

                let colors = response?.data?.openLeadAgeReport.map((val) => {
                    return (
                        "#" +
                        "0123456789abcdef"
                            .split("")
                            .map(function (v, i, a) {
                                return i > 5 ? null : a[Math.floor(Math.random() * 16)];
                            })
                            .join("")
                    );
                });

                setLeadBg(colors);
            });
    }, [siteCode]);


    return (
        <Paper celevation={2} style={{ padding: "20px", marginTop: "40px" }}>
            <h4 className="text-center">Open Lead Age</h4>

            <div className="row mt-3">
                <div className="col-12">
                    {leadCount.length === 0 ? (
                        <h4 className="text-center">NO LEADS FOUND</h4>
                    ) : (
                        <Pie
                            data={{
                                labels: leadDuration,
                                datasets: [
                                    {
                                        // label: 'Task Open Due Counts',
                                        data: leadCount,
                                        backgroundColor: leadBg,
                                        borderWidth: 1,
                                    },
                                ],
                            }}
                            width={200}
                            height={250}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                            }}
                        />
                    )}
                </div>
            </div>
        </Paper>
    )
}

export default LeadOpenAgeReport