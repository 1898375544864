import { Switch, Paper } from "@mui/material";
import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import { Modal, Fade } from "@mui/material";
import { MoonLoader } from "react-spinners";
import { Form } from "react-bootstrap";
import { dateFormatting } from "../../utils/dateFormatting";

const FinalUnitInspection = ({ unitHandover, setUnitHandover }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loader, setLoader] = React.useState(false);
  const [siteEngineerInfo, setSiteEngineerInfo] = React.useState({
    allottedSiteEngineerUserName: "",
    allottedSiteEngineerUserFullName: "",
    allottedSiteEngineerEmpId: "",
    appointmentDate: "",
  });
  const [file, setFile] = React.useState("");
  const [employeeList, setEmployeeList] = React.useState([]);
  const [employee, setEmployee] = React.useState("");
  const [keyDetails, setKeyDetails] = React.useState("");
  const [rescheduleModal, setRescheduleModal] = React.useState(false);

  // Set the minimum date to tomorrow
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);
  const tomorrowDate = tomorrow.toISOString().split("T")[0];

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?employeeRole=Engineering&isActive=true`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setEmployeeList(response.data.employees);
        } else {
          setEmployeeList([]);
        }
      })
      .catch((err) => {
        setEmployeeList([]);
      });
  }, []);

  const initiateFinalInspection = (e) => {
    e.preventDefault();
    setRescheduleModal(false);
    setLoader(true);

    axios
      .put(
        `${BASE_URL}/api/v1/CustomerMgmt/handover/initiateFinalInspection`,
        {
          handoverId: unitHandover?.handoverId,
          allottedSiteEngineerUserName:
            siteEngineerInfo?.allottedSiteEngineerUserName,
          allottedSiteEngineerUserFullName:
            siteEngineerInfo?.allottedSiteEngineerUserFullName,
          allottedSiteEngineerEmpId:
            siteEngineerInfo?.allottedSiteEngineerEmpId,
          appointmentDate: siteEngineerInfo?.appointmentDate,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);

        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setUnitHandover(response.data.handover);
          });
        }
      })
      .catch((err) => {
        setLoader(false);

        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error || err.response.data.message,
        });
      });
  };

  const finalInspectionDone = (e) => {
    e.preventDefault();

    setLoader(true);

    let formData = new FormData();
    formData.append("file", file);
    formData.append("handoverId", unitHandover?.handoverId);
    formData.append("keyDetails", keyDetails);

    axios
      .put(
        `${BASE_URL}/api/v1/CustomerMgmt/handover/finalInspectionDone`,
        formData,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);

        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setUnitHandover(response.data.handover);
          });
        }
      })
      .catch((err) => {
        setLoader(false);

        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error || err.response.data.message,
        });
      });
  };

  return (
    <>
      <Paper
        elevation={1}
        style={{
          padding: "30px 20px",
          width: "90%",
          margin: "auto",
        }}
      >
        <div className="row mb-3 mx-2 card-1">
          <div
            className="row justify-content-center col-12"
            style={{ position: "relative" }}
          >
            <h4 className="text-center">Final Unit Inspection</h4>
            <h6 style={{ position: "absolute", right: 0 }}>
              Inspection Count : {unitHandover?.finalUnitInspection?.count}
            </h6>
          </div>

          <div className="col-12 d-flex justify-content-center tab-card pt-3 pb-3 text-center">
            {/* card-1 */}
            {unitHandover?.finalUnitInspection?.appointmentDone === false && (
              <form
                className="col-6 row"
                onSubmit={(e) => initiateFinalInspection(e)}
              >
                <div className="col-12 mt-4">
                  <Form.Group controlId="employee">
                    <Form.Label>Site Engineer</Form.Label>
                    <Form.Control
                      as="select"
                      required
                      value={employee}
                      onChange={(e) => {
                        setEmployee(e.target.value);
                        setSiteEngineerInfo((prev) => {
                          return {
                            ...prev,
                            allottedSiteEngineerEmpId:
                              e.target.value.split(",")[0],
                            allottedSiteEngineerUserName:
                              e.target.value.split(",")[1],
                            allottedSiteEngineerUserFullName:
                              e.target.value.split(",")[2],
                          };
                        });
                      }}
                    >
                      <option value="">Select Site Engineer</option>
                      {employeeList?.map((employee) => {
                        return (
                          <option
                            value={`${employee?.employeeId},${employee?.userName},${employee?.employeeFullName}`}
                          >
                            {employee?.employeeFullName}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-12 mt-4">
                  <label>Appointment Date</label>
                  <input
                    type="date"
                    id="appointmentDate"
                    required
                    min={tomorrowDate}
                    className="form-control"
                    value={siteEngineerInfo?.appointmentDate}
                    onChange={(e) => {
                      setSiteEngineerInfo((prev) => {
                        return { ...prev, appointmentDate: e.target.value };
                      });
                    }}
                  />
                </div>

                <br />
                <br />

                <div className="col-12 mt-4">
                  <button
                    disabled={userInfo?.userRole !== "Engineering"}
                    style={{
                      backgroundColor:
                        userInfo?.userRole !== "Engineering"
                          ? "Grey"
                          : "#EE4B46",
                    }}
                    className="unit-handover__btns"
                    type="submit"
                  >
                    Initiate Final Inspection
                  </button>
                </div>
              </form>
            )}

            {unitHandover?.finalUnitInspection?.appointmentDone && (
              <div className="col-7">
                <h5 className="text-center" style={{ color: "#EE4B46" }}>
                  Final Unit Inspection Initiated
                </h5>
                <div className="col-12 text-center">
                  <p>
                    <b>Appointment Date : </b>
                    {dateFormatting(
                      unitHandover?.finalUnitInspection?.appointmentDate
                    )}
                  </p>
                  <p>
                    <b>Site Engineer : </b>
                    {
                      unitHandover?.finalUnitInspection
                        ?.allottedSiteEngineerUserFullName
                    }
                  </p>
                </div>
                {!unitHandover?.finalUnitInspection?.inspectionDone && (
                  <div className="col-7 text-center ml-5">
                    <label className="ml-5">Key Details</label>
                    <input
                      type="text"
                      required
                      className="form-control ml-3"
                      value={keyDetails}
                      onChange={(e) => setKeyDetails(e.target.value)}
                    />
                  </div>
                )}
              </div>
            )}

            {/* card-2 */}
            {unitHandover?.finalUnitInspection?.appointmentDone &&
              !unitHandover?.finalUnitInspection?.inspectionDone && (
                <div className="col-7">
                  <form
                    className="col-12"
                    onSubmit={(e) => finalInspectionDone(e)}
                  >
                    <div className="col-12 mt-4">
                      <label>Proof of Seal</label>
                      <input
                        type="file"
                        required
                        className="form-control"
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                    </div>
                    <br />

                    <div className="row mt-4">
                      {userInfo?.userRole === "Engineering" && (
                        <>
                          <button className="unit-handover__btns" type="submit">
                            Complete Unit Inspection
                          </button>
                          <button
                            className="unit-handover__btns ml-2"
                            type="button"
                            onClick={() => setRescheduleModal(true)}
                            disabled={
                              unitHandover?.finalUnitInspection?.count >= 2 &&
                              userInfo?.userRole !== "Director"
                            }
                            style={{
                              backgroundColor:
                                unitHandover?.finalUnitInspection?.count >=
                                  2 && userInfo?.userRole !== "Director"
                                  ? "Grey"
                                  : "#EE4B46",
                            }}
                          >
                            Re-Schedule Inspection
                          </button>
                        </>
                      )}
                    </div>
                  </form>
                </div>
              )}

            {unitHandover?.finalUnitInspection?.inspectionDone && (
              <div className="col-6">
                <h5 className="text-center" style={{ color: "#EE4B46" }}>
                  Final Unit Inspection Done
                </h5>
                <div className="col-12 text-left">
                  <p>
                    <b>Door Seal Photo : </b>
                    <a href={unitHandover?.finalUnitInspection?.sealPhotoLink}>
                      Document
                    </a>
                  </p>
                </div>
                <div className="col-12 text-center">
                  <p>
                    <b>Key Details : </b>
                    {unitHandover?.keyDetails}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </Paper>

      {/* //////////// Modal for reschedule //////////// */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={rescheduleModal}
        onClose={() => {
          setRescheduleModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={rescheduleModal}>
          <Paper elevation={3} className="modal__body" sx={{ width: "35vw" }}>
            <div className="modal__container">
              <div className="modal__heading">
                <h2 className="text-center mt-2">Re-Schedule Inspection</h2>
              </div>

              <form
                className="col-12"
                onSubmit={(e) => initiateFinalInspection(e)}
              >
                <div className="col-12 mt-4">
                  <Form.Group controlId="employee">
                    <Form.Label>Site Engineer</Form.Label>
                    <Form.Control
                      as="select"
                      required
                      value={employee}
                      onChange={(e) => {
                        setEmployee(e.target.value);
                        setSiteEngineerInfo((prev) => {
                          return {
                            ...prev,
                            allottedSiteEngineerEmpId:
                              e.target.value.split(",")[0],
                            allottedSiteEngineerUserName:
                              e.target.value.split(",")[1],
                            allottedSiteEngineerUserFullName:
                              e.target.value.split(",")[2],
                          };
                        });
                      }}
                    >
                      <option value="">Select Site Engineer</option>
                      {employeeList?.map((employee) => (
                        <option
                          key={employee?.employeeId}
                          value={`${employee?.employeeId},${employee?.userName},${employee?.employeeFullName}`}
                        >
                          {employee?.employeeFullName}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-12 mt-4">
                  <label>Appointment Date</label>
                  <input
                    type="date"
                    id="appointmentDate"
                    required
                    min={tomorrowDate}
                    className="form-control"
                    value={siteEngineerInfo?.appointmentDate}
                    onChange={(e) => {
                      setSiteEngineerInfo((prev) => {
                        return { ...prev, appointmentDate: e.target.value };
                      });
                    }}
                  />
                </div>

                <br />
                <br />

                <div className="row justify-content-center mt-1 mb-4">
                  <button className="unit-handover__btns" type="submit">
                    Re-Schedule Inspection
                  </button>
                </div>
              </form>
            </div>
          </Paper>
        </Fade>
      </Modal>
      {/* //////////// Modal for spinner //////////// */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={loader}
        onClose={() => {
          setLoader(false);
        }}
        closeAfterTransition
      >
        <Fade in={loader}>
          <div>
            <MoonLoader
              color="red"
              className="spinner"
              loading={loader}
              size={70}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default FinalUnitInspection;
