import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import './LeaveManagement.scss';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import { Paper } from '@mui/material';
import LeaveBarChart from './LeaveBarChart';
import CompOffBarChart from './CompOffBarChart';
import { Form } from 'react-bootstrap';
import { Pie } from "react-chartjs-2";

const LeaveManagement = () => {

    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [monthwiseLeaveLabel, setMonthwiseLeaveLabel] = useState([])
    const [monthwiseLeaveCount, setMonthwiseLeaveCount] = useState([])
    const [monthwiseCompOffLabel, setMonthwiseCompOffLabel] = useState([])
    const [monthwiseCompOffCount, setMonthwiseCompOffCount] = useState([])

    const [days, setDays] = useState(90)
    const [employee, setEmployee] = useState('')
    const [employeeList, setEmployeeList] = useState([])
    const [leaveCountByType, setLeaveCountByType] = useState([])
    const [leaveCountByTypeId, setLeaveCountByTypeId] = useState([])
    const [leaveCountByTypeBg, setLeaveCountByTypeBg] = useState([])

    const [leaveCountByMonth, setLeaveCountByMonth] = useState([])

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {

                // console.log(response)

                setEmployeeList(response.data.employees);

            })


    }, [])

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/report/leave/leaveCountByMonth`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)
                if (response.data.status) {
                    setLeaveCountByMonth(response.data.leaveReport)
                } else {
                    setLeaveCountByMonth({})
                }
            })

    }, [])

    useEffect(() => {

        let url = `${BASE_URL}/api/v1/hrmgmt/report/leave/leaveReportByEmployee?`

        let filter = '';

        if (days) {
            filter += `days=${days}&`
        }

        if (employee) {
            filter += `employeeId=${employee}&`
        }

        url += filter;

        axios.get(url, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)

                if (response.data.status === true) {

                    let data = response?.data?.leaveReport.map((data) => {
                        return data.count;
                    });

                    setLeaveCountByType(data);

                    let names = response?.data?.leaveReport.map((data) => {
                        return data._id;
                    });

                    setLeaveCountByTypeId(names);

                    let colors = response?.data?.leaveReport.map((val) => {
                        return (
                            "#" +
                            "0123456789abcdef"
                                .split("")
                                .map(function (v, i, a) {
                                    return i > 5 ? null : a[Math.floor(Math.random() * 16)];
                                })
                                .join("")
                        );
                    });

                    setLeaveCountByTypeBg(colors);

                }

            })

    }, [days, employee])

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/report/leave/monthwiseLeaveReport`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {

                // console.log(response)

                let names = response?.data?.monthwiseLeaveReport.map((data) => {
                    return data.monthName;
                });

                setMonthwiseLeaveLabel(names);

                let data = response?.data?.monthwiseLeaveReport.map((data) => {
                    return [data.WeeklyOff, data.CasualLeave, data.MedicalLeave, data.EarnedLeave, data.LWP, data.total];
                });

                setMonthwiseLeaveCount(data);

            })

    }, [])

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/report/compOff/monthwiseCompOffReport`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {

                // console.log(response)

                let names = response?.data?.monthwiseCompOffReport.map((data) => {
                    return data.monthName;
                });

                setMonthwiseCompOffLabel(names);

                let data = response?.data?.monthwiseCompOffReport.map((data) => {
                    return [data.Approved, data.Rejected, data.total];
                });

                setMonthwiseCompOffCount(data);

            })

    }, [])


    return (
        <div className='leave-management'>

            <Button navigateTo={'/home'} />

            <h3 className='text-center my-5' style={{ color: '#EE4B46' }}>Leave Management</h3>


            <div className='leave-management__btns-list'>


                <button className='leave-management__btns'
                    onClick={() => navigate('/leavemanagement/listofleaveplan')}
                >List of Leave Plan</button>



                <button className='leave-management__btns'
                    onClick={() => navigate('/leavemanagement/createleaveplan')}
                >Create New Leave Plan</button>



                <button className='leave-management__btns'>
                    <a href="https://westroad-bucket.s3.us-east-2.amazonaws.com/PolicyDoc/Holiday+List+2023.pdf"
                        target='_blank'
                        style={{ textDecoration: 'none', color: 'white' }}
                    >
                        Holiday list
                    </a>
                </button>



                <button className='leave-management__btns'
                    onClick={() => navigate('/leavemanagement/listofopenleaverequests')}
                >Open Leave Requests</button>


                <button className='leave-management__btns'
                    onClick={() => navigate('/leavemanagement/listofapprovedleaves')}
                >Approved Leaves</button>

                <button className='leave-management__btns'
                    onClick={() => navigate('/leavemanagement/listofleavebalance')}
                >List of Leave Balance</button>

                <button className='leave-management__btns'
                    onClick={() => navigate('/leavemanagement/applyBackendLeave')}
                >Apply Backend Leave</button>

                <button className='leave-management__btns'
                    onClick={() => navigate('/leavemanagement/compoff')}
                >Comp Off</button>

            </div>



            {/* <br />

            <div className='row justify-content-center'>

                <div className='col-6 text-center'>
                    <button className='leave-management__btns'
                        onClick={() => navigate('/leavemanagement/listofleaveplan')}
                    >List of Leave Plan</button>
                </div>
            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6 text-center'>
                    <h5>Create New Leave Plan</h5>
                </div>

            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6 text-center'>
                    <h5>Holiday list</h5>
                </div>

            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6 text-center'>
                    <h5>Open Leave Requests</h5>
                </div>

            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6 text-center'>
                    <h5>Approved Leaves</h5>
                </div>

            </div> */}

            <Paper elevation={2} style={{ padding: "20px", margin: "40px" }}>

                <h4 className="text-center mb-4">Leave Report Current Month</h4>

                <div className="row d-flex">

                    <div className="col-4">
                        <span>Leave Applied</span>
                        <div className="lead-mgmt__leadData">
                            {leaveCountByMonth?.Applied}
                        </div>
                    </div>
                    <div className="col-4">
                        <span>Leave Approved</span>
                        <div className="lead-mgmt__leadData">{leaveCountByMonth?.Approved}</div>
                    </div>
                    <div className="col-4">
                        <span>Leave Rejected</span>
                        <div className="lead-mgmt__leadData">
                            {leaveCountByMonth?.Rejected}
                        </div>

                    </div>
                    <div className="col-4">
                        <span>Leave Open Request</span>
                        <div className="lead-mgmt__leadData">
                            {leaveCountByMonth?.OpenRequest}
                        </div>

                    </div>
                </div>
            </Paper>

            <Paper elevation={2} style={{ padding: "20px", margin: "40px" }}>
                <h4 className="text-center">Leave Approved By Employee Report</h4>

                <div className='row justify-content-between'>

                    <div className='col-4'>
                        <Form.Group controlId="days">
                            <Form.Label
                                style={{ fontSize: '20px' }}
                            >Days</Form.Label>
                            <Form.Control
                                as="select"
                                required
                                value={days}
                                onChange={(e) => {
                                    setDays(e.target.value)
                                }}
                            >
                                <option value={90}>90 days</option>
                                <option value={365}>365 days</option>
                            </Form.Control>
                        </Form.Group>
                    </div>

                    <div className='col-4'>
                        <Form.Group controlId="employee">
                            <Form.Label
                                style={{ fontSize: '20px' }}
                            >Employee</Form.Label>
                            <Form.Control
                                as="select"
                                required
                                value={employee}
                                onChange={(e) => {
                                    setEmployee(e.target.value)
                                }}
                            >
                                <option value=''>Select an Employee</option>
                                {
                                    employeeList.map((employee, index) => {
                                        return <option
                                            key={index}
                                            value={employee?.employeeId}>{employee?.employeeFullName}</option>
                                    })
                                }

                            </Form.Control>
                        </Form.Group>
                    </div>

                </div>

                <br />

                <div className="row">
                    <div className="col-12">
                        {leaveCountByType.length === 0 ? (
                            <h4 className="text-center mt-4"
                                style={{ color: "#EE4B46" }}
                            >NO LEAVE FOUND</h4>
                        ) : (
                            <Pie
                                data={{
                                    labels: leaveCountByTypeId,
                                    datasets: [
                                        {
                                            // label: '# Open ans Assigned Leads',
                                            data: leaveCountByType,
                                            backgroundColor: leaveCountByTypeBg,
                                            borderWidth: 1,
                                        },
                                    ],
                                }}
                                width={200}
                                height={250}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                }}
                            />
                        )}
                    </div>
                </div>
            </Paper>

            <Paper elevation={2} style={{ padding: "20px", margin: "40px" }}>
                <h4 className="text-center">Leave Approved Monthwise</h4>

                <div className="row">
                    <div className="col-12">
                        <LeaveBarChart leaveLabel={monthwiseLeaveLabel} leaveCount={monthwiseLeaveCount} />
                    </div>
                </div>
            </Paper>

            <Paper elevation={2} style={{ padding: "20px", margin: "40px" }}>
                <h4 className="text-center">Comp Off Approved Monthwise</h4>

                <div className="row">
                    <div className="col-12">
                        <CompOffBarChart compOffLabel={monthwiseCompOffLabel} compOffCount={monthwiseCompOffCount} />
                    </div>
                </div>
            </Paper>

        </div >
    )
}

export default LeaveManagement