import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import MaterialTable from 'material-table';
import { useNavigate, useParams } from 'react-router-dom';
import './PaymentTerms.scss';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import Select from '@mui/material/Select';
import InputBase from "@mui/material/InputBase";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from "@mui/material/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useStyles } from '../../utils/ModalStyles';
import Swal from 'sweetalert2';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: '10px',
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "#EE4B46",
        color: "white",
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px",
        width: "100"

    }
}));

const PaymentTerms = () => {

    const classes = useStyles();

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const Token = 'bearer' + " " + userInfo.token
    const siteCode = useParams().siteCode;
    const navigate = useNavigate()

    const [paymentTerms, setPaymentTerms] = useState([])
    const [filteredTerms, setFilteredTerms] = useState([])
    const [phases, setPhases] = useState([])
    const [selectedPhase, setSelectedPhase] = useState('')
    const [pid, setPid] = useState();
    const [modal, setModal] = useState(false);

    useEffect(() => {

        getListOfPaymentTerms()

    }, [])


    const getListOfPaymentTerms = () => {
        axios
            .get(`${BASE_URL}/api/v1/Configuration/PaymentTerms/getListOfPaymentTerms/?siteCode=${siteCode}`,
                { headers: { Authorization: Token } })
            .then(response => {
                // console.log(response)
                const ptList = response.data.paymentTermsList.map((pt) => {
                    const { paymentTermsId,
                        phaseCode,
                        siteName,
                        updatedAt } = pt

                    return {
                        paymentTermsId,
                        phaseCode,
                        siteName,
                        updatedAt
                    }
                })

                setPaymentTerms(ptList)
                setFilteredTerms(ptList)
            })
            .then(() => {
                axios
                    .get(`${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`,
                        { headers: { Authorization: Token } })
                    .then((response) => {
                        setPhases(response.data.site.phases)
                    })
            })
    }

    const handleFilter = (e) => {

        if (e.target.value === " ") {
            setSelectedPhase('')
            return setPaymentTerms(filteredTerms)
        }

        else {
            setSelectedPhase(e.target.value)

            phases.forEach((p) => {
                if (p.phaseName === e.target.value) {

                    let filteredPaymentTerms = []

                    filteredTerms.forEach((ft) => {

                        if (ft.phaseCode === p.phaseCode) {
                            filteredPaymentTerms.push(ft)
                        }
                    })

                    setPaymentTerms(filteredPaymentTerms)


                }
            })

        }
    }


    const handleDeleteTerm = () => {
        axios
            .delete(`${BASE_URL}/api/v1/Configuration/PaymentTerms/deletePaymentTermsByPaymentTermsId/${pid}`,
                { headers: { Authorization: Token } })
            .then((response) => {
                setModal(false)
                if (response.data.status === true) {


                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        getListOfPaymentTerms()
                    })
                } else if (response.data.status === false) {

                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                        text: response.data.message
                    })
                }

            })
    }

    return (
        <div className='paymentTerms'>
            <div className='paymentTerms__btn'>
                <button onClick={() => navigate(`/configurations/site/addpaymentterm/${siteCode}`)}>Add Payment Terms</button>

                <FormControl sx={{ m: 1, minWidth: 140, marginBottom: '10px' }}>

                    <InputLabel id="demo-simple-select-helper-label" style={{ color: 'black', fontSize: '18px', paddingTop: '5px' }}>
                        Phases <FilterAltRoundedIcon style={{ marginLeft: '10px' }}
                        />
                    </InputLabel>

                    <Select
                        value={selectedPhase}
                        input={<BootstrapInput />}
                        onChange={(e) => handleFilter(e)}
                    >
                        <MenuItem value=" ">
                            <em>None</em>
                        </MenuItem>
                        {phases.map((p) => {
                            return <MenuItem value={p.phaseName}>{p.phaseName}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </div>

            <br />

            <MaterialTable
                data={paymentTerms}
                title="Payment Terms"
                columns={
                    [
                        { title: 'Site Name', field: 'siteName' },
                        { title: 'Phase Code', field: 'phaseCode' },
                        { title: 'Term Id', field: 'paymentTermsId' },
                        {
                            title: 'Updated At',
                            render: (rowData) => rowData.updatedAt.substring(8, 10) + "-" + rowData.updatedAt.substring(5, 7) + "-" + rowData.updatedAt.substring(0, 4),
                            customSort: (a, b) => a.updatedAt < b.updatedAt ? -1 : 1
                        },


                    ]
                }
                options={{
                    search: true,
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#EE4B46',
                        color: '#fff',
                    }
                }}

                actions={[
                    {
                        icon: 'remove_red_eye',
                        tooltip: 'View Payment Term',
                        onClick: (event, rowData) => {
                            navigate(`/configurations/site/${siteCode}/editpaymentterms/${rowData.paymentTermsId}`)
                        },
                    },

                    (rowData) => ({
                        icon: "delete",
                        tooltip: "Delete Payment Term",
                        onClick: (event, rowData) => {
                            setPid(rowData.paymentTermsId)
                            setModal(true)
                        },
                        disabled:
                            userInfo.userRole === "ITAdmin" ||
                                userInfo.userRole === "Director"
                                ? false
                                : true,
                    }),

                ]}

            ></MaterialTable>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modal}
                onClose={() => setModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modal}>
                    <div className={classes.paper}>
                        <h6>Are you sure you want to delete this ?</h6>
                        <br />
                        <div className="row container-fluid justify-content-center">
                            <div className="col-6 text-right">
                                <button
                                    className="modal__btns"
                                    onClick={handleDeleteTerm}
                                >
                                    Yes
                                </button>
                            </div>
                            <div className="col-6 text-left">
                                <button
                                    className="modal__btns"
                                    onClick={() => setModal(false)}
                                    style={{
                                        backgroundColor: "white",
                                        color: "black",
                                    }}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>

        </div>
    )
}

export default PaymentTerms