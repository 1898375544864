import React from 'react'
import Header from './Header'
import Sidebar from './Sidebar'

const Dashboard = () => {
  return (
    <>
      <Header/>
      <Sidebar/>
    </>
  )
}

export default Dashboard;