import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";


const TicketList = ({ title, tab }) => {

  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));


  const [siteList, setSiteList] = useState([])
  const [siteCode, setSiteCode] = useState("")
  const [siteCode2, setSiteCode2] = useState("")
  const [ticketType, setTicketType] = useState("")
  const [ticketType2, setTicketType2] = useState("")
  const [department, setDepartment] = useState("")
  const [department2, setDepartment2] = useState("")
  const [categoryList, setCategoryList] = useState(['cat1', 'cat2']);
  const [category, setCategory] = useState("")
  const [category2, setCategory2] = useState("")
  const [userList, setUserList] = useState([])
  const [assignee, setAssignee] = useState("")
  const [assignee2, setAssignee2] = useState("")
  const [openTickets, setOpenTickets] = useState([])
  const [closedTickets, setClosedTickets] = useState([])

  useEffect(() => {
    (async () => {

      let url = `${BASE_URL}/api/v1/CustomerMgmt/Ticket/getListOfTickets?isOpen=true`;
      let filter = '';

      if (siteCode) {
        filter += `&siteCode=${siteCode}`
      }

      if (assignee) {
        filter += `&assignee=${assignee}`
      }

      if (ticketType) {
        filter += `&ticketType=${ticketType}`
      }

      if (department) {
        filter += `&ticketDepartment=${department}`
      }

      if (category) {
        filter += `&ticketCategory=${category}`
      }

      url += filter;


      const res = await axios.get(
        url,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      );
      const data = res?.data?.tickets;
      setOpenTickets(data);

    })();
  }, [siteCode, assignee, ticketType, department, category]);


  useEffect(() => {
    (async () => {

      let url = `${BASE_URL}/api/v1/CustomerMgmt/Ticket/getListOfTickets?status=Closed,Resolved`;
      let filter = '';

      if (siteCode2) {
        filter += `&siteCode=${siteCode2}`
      }

      if (assignee2) {
        filter += `&assignee=${assignee2}`
      }

      if (ticketType2) {
        filter += `&ticketType=${ticketType2}`
      }

      if (department2) {
        filter += `&ticketDepartment=${department2}`
      }

      if (category2) {
        filter += `&ticketCategory=${category2}`
      }

      url += filter;

      const res = await axios.get(
        url,
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      );
      const data = res.data.tickets;
      setClosedTickets(data);
    })();
  }, [siteCode2, assignee2, ticketType2, department2, category2]);


  useEffect(() => {
    axios.get(`${BASE_URL}/api/v1/configuration/site/getAllSiteNames`, {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      }
    })
      .then((response) => {

        if (response.data.status) {
          setSiteList(response.data.siteList)
        } else {
          setSiteList([])
        }
      })
  }, [])

  useEffect(() => {
    axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`, {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      }
    })
      .then((response) => {

        if (response.data.status) {
          setUserList(response.data.employees)
        } else {
          setUserList([])
        }
      })
  }, [])

  const reset = () => {
    setSiteCode("")
    setAssignee("")
    setTicketType("")
    setDepartment("")
    setCategory("")
  }

  const reset2 = () => {
    setSiteCode2("")
    setAssignee2("")
    setTicketType2("")
    setDepartment2("")
    setCategory2("")
  }

  return (
    <div className="mt-5">
      {/* <h4 className="pb-4">Tickets</h4> */}
      <div className="mt-5">
        <h4>{title}</h4>
        {tab === "al" && (
          <MaterialTable
            data={openTickets}
            title=""
            columns={[
              { title: "Ticket Id", field: "ticketId" },
              { title: "Ticket Type", field: "ticketType" },
              { title: "Assignee", render: (rowData) => rowData.assigneeFullName || "Unassigned" },
              { title: "Customer Id", field: "customerId" },
              { title: "Unit Name", field: "unitName" },
              { title: "Department", field: "ticketDepartment" },
              { title: "Category", field: "ticketCategory" },
              {
                title: "Last Updated At",
                defaultSort: "desc",
                render: (rowData) =>
                  !rowData.updatedAt
                    ? ""
                    : rowData.updatedAt.substring(8, 10) +
                    "-" +
                    rowData.updatedAt.substring(5, 7) +
                    "-" +
                    rowData.updatedAt.substring(0, 4) +
                    ", " +
                    rowData.updatedAt.substring(11, 13) +
                    ":" +
                    rowData.updatedAt.substring(14, 16),
                customSort: (a, b) => (a.updatedAt < b.updatedAt ? -1 : 1),
              },
            ]}
            options={{
              search: window.innerWidth < 700 ? false : true,
              searchFieldStyle: {
                width: 180,
              },
              paging: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 20, 30],
              headerStyle: {
                backgroundColor: "#EE4B46",
                color: "#fff",
              },
            }}
            actions={[
              {
                icon: "remove_red_eye",
                tooltip: "Edit Ticket",
                onClick: (event, rowData) => {
                  navigate(`/individualticket/${rowData.ticketId}`);
                },
              },
            ]}

            components={{
              Toolbar: (props) => (
                <div className="filters text-left">
                  <MTableToolbar {...props} />
                  <FormControl
                    variant="standard"
                    className="formControl"
                    style={{
                      marginTop: "-65px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Site
                    </InputLabel>
                    <Select
                      value={siteCode}
                      onChange={(e) => setSiteCode(e.target.value)}
                      className="selectEmpty"
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Select a site</MenuItem>
                      {
                        siteList?.map((site, index) => {
                          return <MenuItem value={site.SiteCode} key={index}>{site.SiteName}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>

                  <FormControl
                    variant="standard"
                    className="formControl"
                    style={{
                      marginTop: "-65px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Assignee
                    </InputLabel>
                    <Select
                      value={assignee}
                      onChange={(e) => setAssignee(e.target.value)}
                      className="selectEmpty"
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Select a user</MenuItem>
                      {userList?.map((user, index) => (
                        <MenuItem value={user.userName} key={index}>
                          {user.userName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    variant="standard"
                    className="formControl"
                    style={{
                      marginTop: "-65px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Ticket Type
                    </InputLabel>
                    <Select
                      value={ticketType}
                      onChange={(e) => setTicketType(e.target.value)}
                      className="selectEmpty"
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Select a type</MenuItem>
                      <MenuItem value="Complaint">Complaint</MenuItem>
                      <MenuItem value="Service Request">Service Request</MenuItem>
                    </Select>
                  </FormControl>

                  {window.innerWidth < 1000 && <br />}

                  <FormControl
                    variant="standard"
                    className="formControl"
                    style={{
                      marginTop: window.innerWidth < 1000 ? "-20px" : "-65px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Department
                    </InputLabel>
                    <Select
                      value={department}
                      onChange={(e) => setDepartment(e.target.value)}
                      className="selectEmpty"
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Select a department</MenuItem>
                      <MenuItem value="Sales">Sales</MenuItem>
                      <MenuItem value="Operations">Operations</MenuItem>
                      <MenuItem value="Finance">Finance</MenuItem>
                      <MenuItem value="Engineering">Engineering</MenuItem>
                      <MenuItem value="Maintenance">Maintenance</MenuItem>

                    </Select>
                  </FormControl>

                  <FormControl
                    variant="standard"
                    className="formControl"
                    style={{
                      marginTop: window.innerWidth < 1000 ? "-20px" : "-65px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Category
                    </InputLabel>

                    {
                      department === "Sales"
                        ?
                        <Select
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                          className="selectEmpty"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="">Select a category</MenuItem>
                          <MenuItem value="Cost Sheet">Cost Sheet</MenuItem>
                          <MenuItem value="Fittings and Fixtures">Fittings and Fixtures</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                        :
                        department === "Operations"
                          ?
                          <Select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            className="selectEmpty"
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Select a category</MenuItem>
                            <MenuItem value="Allotment Letter">Allotment Letter</MenuItem>
                            <MenuItem value="Sale Agreement">Sale Agreement</MenuItem>
                            <MenuItem value="Demand">Demand</MenuItem>
                            <MenuItem value="Loan">Loan</MenuItem>
                            <MenuItem value="Registration">Registration</MenuItem>
                            <MenuItem value="Alteration">Alteration</MenuItem>
                            <MenuItem value="Possession">Possession</MenuItem>
                            <MenuItem value="Electric Form">Electric Form</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                          </Select>
                          :
                          department === "Finance"
                            ?
                            <Select
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                              className="selectEmpty"
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value="">Select a category</MenuItem>
                              <MenuItem value="Demand">Demand</MenuItem>
                              <MenuItem value="Money Receipt">Money Receipt</MenuItem>
                              <MenuItem value="Refund">Refund</MenuItem>
                              <MenuItem value="Cancellation">Cancellation</MenuItem>
                              <MenuItem value="Ledger Statement">Ledger Statement</MenuItem>
                              <MenuItem value="Others">Others</MenuItem>
                            </Select>
                            :
                            department === "Engineering"
                              ?
                              <Select
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                className="selectEmpty"
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">Select a category</MenuItem>
                                <MenuItem value="Alteration">Alteration</MenuItem>
                                <MenuItem value="Fittings and Fixtures">Fittings and Fixtures</MenuItem>
                                <MenuItem value="Billing of Alteration Work">Billing of Alteration Work</MenuItem>
                                <MenuItem value="Handover/Possession">Handover/Possession</MenuItem>
                                <MenuItem value="Others">Others</MenuItem>
                              </Select>
                              :
                              department === "Maintenance"
                                ?
                                <Select
                                  value={category}
                                  onChange={(e) => setCategory(e.target.value)}
                                  className="selectEmpty"
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="">Select a category</MenuItem>
                                  <MenuItem value="Fittings and Fixtures">Fittings and Fixtures</MenuItem>
                                  <MenuItem value="Warranty Claim">Warranty Claim</MenuItem>
                                  <MenuItem value="Defect Liability">Defect Liability</MenuItem>
                                  <MenuItem value="Others">Others</MenuItem>
                                </Select>
                                :
                                <Select
                                  value={category}
                                  onChange={(e) => setCategory(e.target.value)}
                                  className="selectEmpty"
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="">Select a category</MenuItem>
                                </Select>
                    }

                  </FormControl>

                  <FormControl
                    className="formControl"
                    style={{
                      marginTop: window.innerWidth < 1000 ? "-15px" : "-50px",
                    }}
                  >
                    <button
                      onClick={reset}
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid black",
                        borderRadius: "20px",
                        padding: "2px",
                      }}
                    >
                      Reset Filter
                    </button>
                  </FormControl>
                </div>
              ),
            }}

          />
        )}

        {tab === "cl" && (
          <MaterialTable
            data={closedTickets}
            title=""
            columns={[
              { title: "Ticket Id", field: "ticketId" },
              { title: "Ticket Type", field: "ticketType" },
              { title: "Assignee", render: (rowData) => rowData.assigneeFullName || "Unassigned" },
              { title: "Customer Id", field: "customerId" },
              { title: "Unit Name", field: "unitName" },
              { title: "Department", field: "ticketDepartment" },
              { title: "Category", field: "ticketCategory" },
              {
                title: "Last Updated At",
                defaultSort: "desc",
                render: (rowData) =>
                  !rowData.updatedAt
                    ? ""
                    : rowData.updatedAt.substring(8, 10) +
                    "-" +
                    rowData.updatedAt.substring(5, 7) +
                    "-" +
                    rowData.updatedAt.substring(0, 4) +
                    ", " +
                    rowData.updatedAt.substring(11, 13) +
                    ":" +
                    rowData.updatedAt.substring(14, 16),
                customSort: (a, b) => (a.updatedAt < b.updatedAt ? -1 : 1),
              },
            ]}
            options={{
              search: window.innerWidth < 700 ? false : true,
              searchFieldStyle: {
                width: 180,
              },
              paging: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 20, 30],
              headerStyle: {
                backgroundColor: "#EE4B46",
                color: "#fff",
              },
            }}

            actions={[
              {
                icon: "remove_red_eye",
                tooltip: "Edit Ticket",
                onClick: (event, rowData) => {
                  navigate(`/individualticket/${rowData.ticketId}`);
                },
              },
            ]}

            components={{
              Toolbar: (props) => (
                <div className="filters text-left">
                  <MTableToolbar {...props} />
                  <FormControl
                    variant="standard"
                    className="formControl"
                    style={{
                      marginTop: "-65px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Site
                    </InputLabel>
                    <Select
                      value={siteCode2}
                      onChange={(e) => setSiteCode2(e.target.value)}
                      className="selectEmpty"
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Select a site</MenuItem>
                      {
                        siteList?.map((site, index) => {
                          return <MenuItem value={site.SiteCode} key={index}>{site.SiteName}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>

                  <FormControl
                    variant="standard"
                    className="formControl"
                    style={{
                      marginTop: "-65px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Assignee
                    </InputLabel>
                    <Select
                      value={assignee2}
                      onChange={(e) => setAssignee2(e.target.value)}
                      className="selectEmpty"
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Select a user</MenuItem>
                      {userList?.map((user, index) => (
                        <MenuItem value={user.userName} key={index}>
                          {user.userName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    variant="standard"
                    className="formControl"
                    style={{
                      marginTop: "-65px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Ticket Type
                    </InputLabel>
                    <Select
                      value={ticketType2}
                      onChange={(e) => setTicketType2(e.target.value)}
                      className="selectEmpty"
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Select a type</MenuItem>
                      <MenuItem value="Complaint">Complaint</MenuItem>
                      <MenuItem value="Service Request">Service Request</MenuItem>
                    </Select>
                  </FormControl>

                  {window.innerWidth < 1000 && <br />}

                  <FormControl
                    variant="standard"
                    className="formControl"
                    style={{
                      marginTop: window.innerWidth < 1000 ? "-20px" : "-65px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Department
                    </InputLabel>
                    <Select
                      value={department2}
                      onChange={(e) => setDepartment2(e.target.value)}
                      className="selectEmpty"
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Select a department</MenuItem>
                      <MenuItem value="Sales">Sales</MenuItem>
                      <MenuItem value="Operations">Operations</MenuItem>
                      <MenuItem value="Finance">Finance</MenuItem>
                      <MenuItem value="Engineering">Engineering</MenuItem>
                      <MenuItem value="Maintainance">Maintainance</MenuItem>
                      <MenuItem value="Customer Care">Customer Care</MenuItem>

                    </Select>
                  </FormControl>

                  <FormControl
                    variant="standard"
                    className="formControl"
                    style={{
                      marginTop: window.innerWidth < 1000 ? "-20px" : "-65px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Category
                    </InputLabel>

                    {
                      department2 === "Sales"
                        ?
                        <Select
                          value={category2}
                          onChange={(e) => setCategory2(e.target.value)}
                          className="selectEmpty"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="">Select a category</MenuItem>
                          <MenuItem value="Cost Sheet">Cost Sheet</MenuItem>
                          <MenuItem value="Fittings and Fixtures">Fittings and Fixtures</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                        :
                        department2 === "Operations"
                          ?
                          <Select
                            value={category2}
                            onChange={(e) => setCategory2(e.target.value)}
                            className="selectEmpty"
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Select a category</MenuItem>
                            <MenuItem value="Allotment Letter">Allotment Letter</MenuItem>
                            <MenuItem value="Sale Agreement">Sale Agreement</MenuItem>
                            <MenuItem value="Demand">Demand</MenuItem>
                            <MenuItem value="Loan">Loan</MenuItem>
                            <MenuItem value="Registration">Registration</MenuItem>
                            <MenuItem value="Alteration">Alteration</MenuItem>
                            <MenuItem value="Possession">Possession</MenuItem>
                            <MenuItem value="Electric Form">Electric Form</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                          </Select>
                          :
                          department2 === "Finance"
                            ?
                            <Select
                              value={category2}
                              onChange={(e) => setCategory2(e.target.value)}
                              className="selectEmpty"
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value="">Select a category</MenuItem>
                              <MenuItem value="Demand">Demand</MenuItem>
                              <MenuItem value="Money Receipt">Money Receipt</MenuItem>
                              <MenuItem value="Refund">Refund</MenuItem>
                              <MenuItem value="Cancellation">Cancellation</MenuItem>
                              <MenuItem value="Ledger Statement">Ledger Statement</MenuItem>
                              <MenuItem value="Others">Others</MenuItem>
                            </Select>
                            :
                            department2 === "Engineering"
                              ?
                              <Select
                                value={category2}
                                onChange={(e) => setCategory2(e.target.value)}
                                className="selectEmpty"
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">Select a category</MenuItem>
                                <MenuItem value="Alteration">Alteration</MenuItem>
                                <MenuItem value="Fittings and Fixtures">Fittings and Fixtures</MenuItem>
                                <MenuItem value="Billing of Alteration Work">Billing of Alteration Work</MenuItem>
                                <MenuItem value="Handover/Possession">Handover/Possession</MenuItem>
                                <MenuItem value="Others">Others</MenuItem>
                              </Select>
                              :
                              department2 === "Maintenance"
                                ?
                                <Select
                                  value={category2}
                                  onChange={(e) => setCategory2(e.target.value)}
                                  className="selectEmpty"
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="">Select a category</MenuItem>
                                  <MenuItem value="Fittings and Fixtures">Fittings and Fixtures</MenuItem>
                                  <MenuItem value="Warranty Claim">Warranty Claim</MenuItem>
                                  <MenuItem value="Defect Liability">Defect Liability</MenuItem>
                                  <MenuItem value="Others">Others</MenuItem>
                                </Select>
                                :
                                <Select
                                  value={category2}
                                  onChange={(e) => setCategory2(e.target.value)}
                                  className="selectEmpty"
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="">Select a category</MenuItem>
                                </Select>
                    }

                  </FormControl>


                  <FormControl
                    className="formControl"
                    style={{
                      marginTop: window.innerWidth < 1000 ? "-15px" : "-50px",
                    }}
                  >
                    <button
                      onClick={reset2}
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid black",
                        borderRadius: "20px",
                        padding: "2px",
                      }}
                    >
                      Reset Filter
                    </button>
                  </FormControl>
                </div>
              ),
            }}

          />
        )}

        {/* <AssignModal open={open} setOpen={setOpen} lId={lId} setChange={setChange}></AssignModal> */}
      </div>
    </div>
  );
};

export default TicketList;
