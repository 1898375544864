import React, { useEffect, useState } from 'react'
import './HandOver.scss';
import Button from '../../components/Button/Button';
import axios from 'axios';
import MaterialTable, { MTableToolbar } from 'material-table';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { BASE_URL } from '../../utils/config/url';
import { useNavigate } from 'react-router-dom';

const HandOver = () => {

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const navigate = useNavigate()

    const [handoverList, setHandoverList] = useState([])
    const [status, setStatus] = useState('')
    const [siteList, setSiteList] = useState([])
    const [siteCode, setSiteCode] = useState('')
    const [phaseList, setPhaseList] = useState([])
    const [phaseCode, setPhaseCode] = useState('')

    useEffect(() => {

        let url = `${BASE_URL}/api/v1/customermgmt/handover/getListOfUnitHandover?`;

        if (status) {
            url += `status=${status}&`
        }

        if (siteCode) {
            url += `siteCode=${siteCode}&`
        }

        if (phaseCode) {
            url += `phaseCode=${phaseCode}&`
        }

        axios.get(url,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                if (response.data.status) {
                    setHandoverList(response.data.handoverList)
                } else {
                    setHandoverList([])
                }
            })
    }, [status, siteCode, phaseCode])

    useEffect(() => {

        let url = `${BASE_URL}/api/v1/configuration/site/getAllSites`;

        axios.get(url,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                if (response.data.status) {
                    setSiteList(response.data.siteArray)
                } else {
                    setSiteList([])
                }
            })
    }, [])

    const reset = () => {
        setStatus('')
        setSiteCode('')
        setPhaseCode('')
        setPhaseList([])
    }

    return (
        <div className='handover'>
            <Button navigateTo={'/home'} />

            <br />

            <div className="mt-5">
                <h3>Handover</h3>
                <MaterialTable
                    data={handoverList}
                    title=""

                    columns={[
                        { title: "Handover Id", field: "handoverId" },
                        { title: "Customer Id", field: "customerId" },
                        {
                            title: "Unit Name",
                            field: 'unitName'
                        },
                        { title: "Site Name", field: "siteName" },
                        { title: "Phase Name", field: "phaseName" },
                        { title: "Status", field: "status" },
                        { title: "Relationship Manager", field: "relationshipManagerFullName" },
                    ]}

                    options={{
                        search: window.innerWidth < 700 ? false : true,
                        searchFieldStyle: {
                            width: 180
                        },
                        paging: true,
                        pageSize: 20,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [5, 10, 20, 30],
                        headerStyle: {
                            backgroundColor: "#EE4B46",
                            color: "#fff",
                        },
                    }}

                    actions={[
                        {
                            icon: 'remove_red_eye',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => {
                                navigate(`/handover/${rowData.handoverId}`)
                            }
                        }
                    ]}

                    components={{
                        Toolbar: props => (
                            <div className="filters text-left">
                                <MTableToolbar {...props} />
                                <FormControl className="formControl"
                                    style={{ marginTop: "-65px", width: "8rem", marginLeft: "15px" }}>

                                    <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>

                                    <Select value={status} onChange={(e) => setStatus(e.target.value)}
                                        className="selectEmpty"
                                        variant='standard'
                                        inputProps={{ "aria-label": "Without label" }}>
                                        <MenuItem value="">Status</MenuItem>
                                        <MenuItem value="Initiated">Initiated</MenuItem>
                                        <MenuItem value="Ready For Registration">Ready For Registration</MenuItem>
                                        <MenuItem value="Registration Done">Registration Done</MenuItem>
                                        <MenuItem value="Handover Done">Handover Done</MenuItem>
                                    </Select>

                                </FormControl>

                                <FormControl className="formControl"
                                    style={{ marginTop: "-65px", width: "8rem", marginLeft: "15px" }}>
                                    <InputLabel id="demo-simple-select-helper-label">Site</InputLabel>
                                    <Select value={siteCode} onChange={(e) => {
                                        setSiteCode(e.target.value)
                                        setPhaseList(siteList?.find(site => site.siteCode === e.target.value)?.phases)
                                    }}
                                        variant='standard'
                                        className="selectEmpty"
                                        inputProps={{ "aria-label": "Without label" }}>
                                        <MenuItem value="">Select a Site</MenuItem>
                                        {
                                            siteList?.map((site, index) => {
                                                return <MenuItem key={index} value={site?.siteCode}>{site?.siteName}</MenuItem>
                                            })
                                        }

                                    </Select>
                                </FormControl>

                                {window.innerWidth < 1000 && <br />}

                                <FormControl className="formControl"
                                    style={{ marginTop: window.innerWidth < 1000 ? '-30px' : '-65px' }}>
                                    <InputLabel id="demo-simple-select-helper-label">Phase</InputLabel>
                                    <Select value={phaseCode} onChange={(e) => setPhaseCode(e.target.value)}
                                        className="selectEmpty"
                                        variant='standard'
                                        disabled={siteCode === "" ? true : false}
                                        inputProps={{ "aria-label": "Without label" }}>
                                        <MenuItem value="">Select a Phase</MenuItem>
                                        {
                                            phaseList?.map((phase, index) => {
                                                return <MenuItem key={index} value={phase?.phaseCode}>{phase?.phaseName}</MenuItem>
                                            })
                                        }

                                    </Select>
                                </FormControl>

                                <FormControl className="formControl"
                                    style={{ marginTop: window.innerWidth < 1000 ? '-30px' : '-50px' }}>
                                    <button
                                        onClick={reset}
                                        style={{
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid black",
                                            borderRadius: "20px",
                                            padding: "4px",
                                            margin: "5px 0px 0px 15px",
                                        }}
                                    >
                                        Reset Filter
                                    </button>
                                </FormControl>

                            </div>
                        ),
                    }}
                />
            </div>

        </div>
    )
}

export default HandOver