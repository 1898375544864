import React, { useEffect, useState } from 'react'
import './GenerateLeadReport.scss';
import Paper from '@mui/material/Paper';
import { Form } from 'react-bootstrap';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useStyles } from '../../utils/ModalStyles';
import { AiFillCloseCircle } from 'react-icons/ai';
import ClipLoader from 'react-spinners/ClipLoader';

const GenerateLeadReport = ({ siteCode, siteName }) => {

    const classes = useStyles();

    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [employee, setEmployee] = useState('');
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [selectedEmployeesIds, setSelectedEmployeesIds] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [generatedModal, setGeneratedModal] = useState(false);
    const [docLink, setDocLink] = useState('')
    const [spinnerModal, setSpinnerModal] = useState(false);

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response.data.employees)
                setEmployeeList(response.data.employees)

            })
    }, [])

    const generateLeadReport = () => {

        if (!month || !year) {
            return Swal.fire({
                icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
                text: 'Please select Month and Year'
            })
        }

        setSpinnerModal(true)

        axios.post(`${BASE_URL}/api/v1/leadMgmt/leadReport/sendLeadExportExcel`,

            {
                month: parseInt(month.split(',')[0]),
                year: parseInt(year),
                sendTo: selectedEmployeesIds,
                siteCode: siteCode,
                employeeId : userInfo.employeeId
            }, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {

                setSpinnerModal(false)

                if (response.data.status === true) {
                    setGeneratedModal(true);
                    setDocLink(response.data.document)
                }

            })
            .catch((err) => {

                setSpinnerModal(false);


                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })

    }

    const reset = () => {
        setSelectedEmployees([])
        setSelectedEmployeesIds([])
        setMonth('')
        setYear('')
        setEmployee('')
        setDocLink('')
    }

    return (
        <div className='generate-leadreport'>

            <Paper elevation={2} style={{ padding: '20px' }}>


                <h3 className='text-center'>Generate Monthly Lead Report</h3>


                <div className='row justify-content-center mt-4'>
                    <div className='col-4'>
                        <label>Project</label>
                        <input type='text'
                            className='form-control'
                            readOnly
                            value={siteName}
                        />
                    </div>
                </div>

                <div className='row justify-content-center mt-4'>

                    <div className='col-md-4 col-6'>
                        <Form.Group controlId="month">
                            <Form.Label>Month</Form.Label>
                            <Form.Control as="select" required
                                value={month}
                                onChange={(e) => {
                                    setMonth(e.target.value)
                                }}
                            >
                                <option value="">Select a Month</option>
                                <option value='1,January'>January</option>
                                <option value='2,February'>February</option>
                                <option value='3,March'>March</option>
                                <option value='4,April'>April</option>
                                <option value='5,May'>May</option>
                                <option value='6,June'>June</option>
                                <option value='7,July'>July</option>
                                <option value='8,August'>August</option>
                                <option value='9,September'>September</option>
                                <option value='10,October'>October</option>
                                <option value='11,November'>November</option>
                                <option value='12,December'>December</option>
                            </Form.Control>
                        </Form.Group>
                    </div>

                    <div className='col-md-4 col-6'>
                        <Form.Group controlId="year">
                            <Form.Label>Year</Form.Label>
                            <Form.Control as="select" required
                                value={year}
                                onChange={(e) => {
                                    setYear(e.target.value)
                                }}
                            >
                                <option value="">Select a Year</option>
                                <option value='2020'>2020</option>
                                <option value='2021'>2021</option>
                                <option value='2022'>2022</option>
                                <option value='2023'>2023</option>
                                <option value='2024'>2024</option>
                                <option value='2025'>2025</option>
                                <option value='2026'>2026</option>
                                <option value='2027'>2027</option>
                                <option value='2028'>2028</option>
                                <option value='2029'>2029</option>
                                <option value='2030'>2030</option>

                            </Form.Control>
                        </Form.Group>
                    </div>

                </div>

                <div className='row justify-content-center mt-3'>

                    <div className='col-md-4 col-6'>
                        <Form.Group controlId="employee">
                            <Form.Label>Employee</Form.Label>
                            <Form.Control as="select" required
                                value={employee}
                                onChange={(e) => {
                                    setEmployee(e.target.value)
                                }}
                            >
                                <option value="">Add Employee</option>
                                {
                                    employeeList?.map((employee, index) => {
                                        return <option key={index} value={`${employee.employeeId}, ${employee.employeeFirstName} ${employee.employeeLastName}`}>{employee.employeeFirstName} {employee.employeeLastName}</option>
                                    })
                                }

                            </Form.Control>
                        </Form.Group>
                    </div>

                    <div className='col-4 text-center'>
                        <button className='generate-leadreport__btn mt-4'
                            onClick={() => {

                                if (employee === '') return;

                                setSelectedEmployees((prev) => {
                                    return [...prev, employee.split(',')[1]]
                                })
                                setSelectedEmployeesIds((prev) => {
                                    return [...prev, employee.split(',')[0]]
                                })
                                setEmployee('')
                            }}
                        >Add Employee</button>
                    </div>
                </div>

                <div className='row justify-content-center mt-3'>
                    <div className='col-md-8 col-12'>
                        <label>Selected Employees</label>
                        <textarea className='form-control' readOnly
                            value={
                                selectedEmployees
                            }
                        ></textarea>
                    </div>
                </div>

                <div className='row justify-content-center mt-5'>
                    <div className='col-6 text-center'>
                        <button className='generate-leadreport__btn'
                            onClick={generateLeadReport}
                        >Generate</button>
                    </div>
                </div>


            </Paper>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={generatedModal}
                onClose={() => setGeneratedModal(false)}
                closeAfterTransition
            >
                <Fade in={generatedModal}>
                    <div className="paper">

                        <div className='row justify-content-end'>
                            <div className='col-2 d-flex align-items-center'>
                                <span>Close</span>
                                <AiFillCloseCircle
                                    className='generate-leadreport__icon'
                                    onClick={() => {
                                        setGeneratedModal(false)
                                        reset()
                                    }}
                                />
                            </div>
                        </div>


                        <div className="row container-fluid justify-content-center">

                            <div className='col-10 text-center'>
                                {
                                    selectedEmployeesIds.length === 0
                                        ?
                                        <h4>Lead Report for month of {month.split(',')[1]} {year} is generated.</h4>
                                        :
                                        <h4>Lead Report for month of {month.split(',')[1]} {year} is generated and sent to the below employees.</h4>
                                }

                            </div>



                            {
                                selectedEmployees.length != 0
                                &&
                                <div className='col-8 text-center mt-3'>
                                    <textarea className='form-control' readOnly
                                        value={
                                            selectedEmployees
                                        }
                                    ></textarea>
                                </div>
                            }



                            <div className="col-6 text-center mt-5">
                                <a href={docLink} download>
                                    <button
                                        className="generate-leadreport__btn"
                                    // onClick={() => setModal3(false)}
                                    >
                                        Download
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>


            {/* /////////////////Modal for spinner /////////// */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={spinnerModal}
                onClose={() => setSpinnerModal(false)}
                closeAfterTransition
            >
                <Fade in={spinnerModal}>

                    <div>
                        <ClipLoader
                            color='#EE4B46'
                            loading={spinnerModal}
                            size={70}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                </Fade>
            </Modal>


        </div>
    )

}

export default GenerateLeadReport;