import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ViewInvEmployee.scss";
import { BASE_URL } from "../../utils/config/url";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import { Switch, Select, InputLabel, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormControl } from "@mui/material";
import { useStyles } from "../../utils/ModalStyles";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";

const RedSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#EE4B46",
    "&:hover": {
      backgroundColor: alpha("#EE4B46", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#EE4B46",
  },
}));

const ViewInvEmployee = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const classes = useStyles();
  let employeeId = useParams().employeeId;

  const [employee, setEmployee] = useState({});
  const [leavePlan, setLeavePlan] = useState();
  const [leavePlanList, setLeavePlanList] = useState([]);
  const [lineManagerList, setLineManagerList] = useState([]);
  const [lineMgr, setLineMgr] = useState("");
  const [lineMgrUsername, setLineMgrUsername] = useState("");
  const [canWFH, setCanWFH] = useState("");
  const [leadFullAccess, setLeadFullAccess] = useState(false);
  const [eligibleSalaryAdvance, setEligibleSalaryAdvance] = useState(false);
  const [eligibleForSalaryAdvance, setEligibleForSalaryAdvance] =
    useState(false);
  const [eligibleMenstruationLeave, setEligibleMenstruationLeave] =
    useState(false);
  const [claimLimit, setClaimLimit] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [personalMobile, setPersonalMobile] = useState("");
  const [multiplier, setMultiplier] = useState();
  const [viewMultiplier, setViewMultiplier] = useState();
  const [quarterProvided, setQuarterProvided] = useState("");

  const [orgRole, setOrgRole] = useState("");
  const [employeeFullName, setEmployeeFullName] = useState("");
  const [employeeDOB, setEmployeeDOB] = useState("");
  const [HRMngrList, setHRMngrList] = useState([]);
  const [HRMngr, setHRMngr] = useState("");
  const [HRMngrUserName, setHRMngrUserName] = useState("");

  useEffect(() => {
    getEmployeeByEmployeeId();
  }, []);

  const getEmployeeByEmployeeId = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${employeeId}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      );
  
      // Assuming response.data.employee is an object
      const employee = response.data.employee;
  
      setEmployee(employee);
  
      if (employee?.employeeMiddleName != null) {
        setEmployeeFullName(
          `${employee?.employeeFirstName} ${employee?.employeeMiddleName} ${employee?.employeeLastName}`
        );
      } else {
        setEmployeeFullName(
          `${employee?.employeeFirstName} ${employee?.employeeLastName}`
        );
      }
  
      let dob = new Date(employee.employeeDOB);
  
      let date = new Date(dob).getDate();
      if (date < 10) date = `0${date}`;
      let month = new Date(dob).getMonth() + 1;
      if (month < 10) month = `0${month}`;
      let year = new Date(dob).getFullYear();
  
      dob = `${year}-${month}-${date}`;
      setEmployeeDOB(dob);
      setCanWFH(employee.canWFH);
      setQuarterProvided(employee?.quarterProvided);
      setLeadFullAccess(employee?.leadFullAccess);
      setEligibleForSalaryAdvance(employee?.eligibleSalaryAdvance);
      setEligibleMenstruationLeave(employee?.eligibleMenstruationLeave);
      setClaimLimit(employee?.currentMonthAutoApproveLimit);
      setViewMultiplier(employee?.salaryAdvanceMultiplier);
      employee?.personalEmail && setPersonalEmail(employee?.personalEmail);
      employee.personalMobile && setPersonalMobile(employee?.personalMobile);
      employee?.orgRole && setOrgRole(employee?.orgRole);
      getLineMgrUserName(employee?.lineManagerId);
      getHRMgrUserName(employee?.hrManagerId);
    } catch (error) {
      console.error('Error fetching employee data:', error);
      // Handle error as needed, e.g., show an error message to the user
    }
  };
  

  const getLineMgrUserName = (lineMgrId) => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${lineMgrId}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLineMgrUsername(response.data.employee.userName);
      });
  };
  const getHRMgrUserName = (HRMgrId) => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${HRMgrId}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setHRMngrUserName(response.data.employee.userName);
      });
  };


  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/hrmgmt/leavePlan/getListOfLeavePlan`, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((response) => {
        setLeavePlanList(response.data.leaveplanList);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee`, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((response) => {
        if (response.data.status === true) {
          setLineManagerList(response.data.employees);
        }
      });
  }, []);

  useEffect(() => {
    axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?employeeRole=HR`, {
      headers: { Authorization: `bearer ${userInfo.token}` },})
      .then((response) => {
        if (response.data.status === true) {
          setHRMngrList(response.data.employees);
        }
      });
  }, []);


  const updateEmployee = () => {
    if (eligibleSalaryAdvance === true) {
      axios
        .put(
          `${BASE_URL}/api/v1/hrmgmt/employee/updateEmployee`,
          {
            employeeId: employeeId,
            update: {
              canWFH,
              quarterProvided: quarterProvided,
              leadFullAccess,
              eligibleSalaryAdvance,
              eligibleMenstruationLeave,
              salaryAdvanceMultiplier: multiplier,
              currentMonthAutoApproveLimit: claimLimit,
              personalEmail,
              personalMobile,
              orgRole,
              salaryAdvanceStatus: "Not Applied",
            },
          },
          {
            headers: { Authorization: `bearer ${userInfo.token}` },
          }
        )
        .then((response) => {
          if (response.data.status === true) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: response.data.message,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then(function () {
              getEmployeeByEmployeeId();
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: err.response.data.error,
          });
        });
    } else {
      axios
        .put(
          `${BASE_URL}/api/v1/hrmgmt/employee/updateEmployee`,
          {
            employeeId: employeeId,
            update: {
              canWFH,
              quarterProvided: quarterProvided,
              leadFullAccess,
              eligibleSalaryAdvance,
              eligibleMenstruationLeave,
              salaryAdvanceMultiplier: multiplier,
              currentMonthAutoApproveLimit: claimLimit,
              personalEmail,
              personalMobile,
              orgRole,
            },
          },
          {
            headers: { Authorization: `bearer ${userInfo.token}` },
          }
        )
        .then((response) => {
          if (response.data.status === true) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: response.data.message,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then(function () {
              getEmployeeByEmployeeId();
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: err.response.data.error,
          });
        });
    }
  };

  const toggleEmployeeActive = () => {
    axios
      .put(
        `${BASE_URL}/api/v1/hrmgmt/employee/activateNewEmployee`,
        {
          employeeId: employee.employeeId,
          activate: employee.isActive ? false : true,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getEmployeeByEmployeeId();
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const assignLineManager = () => {
    if (!lineMgr) {
      return Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: "Please select a line manager",
      });
    }

    axios
      .put(
        `${BASE_URL}/api/v1/hrmgmt/employee/assignLineManager`,
        {
          employeeId: employee.employeeId,
          lineManagerId: lineMgr,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getEmployeeByEmployeeId();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const assignHRManager = () => {
    if (!HRMngr) {
      return Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: "Please select a HR manager",
      });
    }


    axios
      .post(
        `${BASE_URL}/api/v1/hrmgmt/employee/assignHRManager`,
        {
          employeeId: employee.employeeId,
          hrManagerEmpId: HRMngr,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getEmployeeByEmployeeId();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const generateRegisterCode = () => {
    axios
      .put(
        `${BASE_URL}/api/v1/hrmgmt/employee/generateRegisterCode`,
        {
          employeeId: employee.employeeId,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getEmployeeByEmployeeId();
          });
        }
      });
  };

  const assignLeavePlan = () => {
    if (!leavePlan) {
      return Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: "Please select a Leave Plan",
      });
    }

    axios
      .put(
        `${BASE_URL}/api/v1/hrmgmt/leavePlan/assignLeavePlanToEmployee`,
        {
          employeeId: employee.employeeId,
          leavePlanId: leavePlan,
          eligibleMenstruationLeave: eligibleMenstruationLeave,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getEmployeeByEmployeeId();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const dateFormatting = (date) => {
    if (!date) {
      return "N/A";
    }

    let str = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Jakarta",
    });

    let d = new Date(str).getDate();
    if (d < 10) {
      d = `0${d}`;
    }
    let m = new Date(str).getMonth() + 1;
    if (m < 10) {
      m = `0${m}`;
    }
    let y = new Date(str).getFullYear();
    return `${d}-${m}-${y}`;
  };

  return (
    <div className="inv-Emp">
      <Paper
        elevation={2}
        style={{ padding: "20px" }}
        className="w-100 m-auto mb-5"
      >
        <div className="row justify-content-center">
          <div className="col-4">
            <label>Employee Id</label>
            <input
              type="text"
              className="form-control"
              value={employeeId}
              disabled
            />
          </div>

          <div className="col-4">
            <label>Username</label>
            <input
              type="text"
              className="form-control"
              value={employee?.userName}
              disabled
            />
          </div>

          <div className="col-4">
            <label>Is Active</label>
            <input
              type="text"
              className="form-control"
              value={employee?.isActive ? "Active" : "Inactive"}
              disabled
            />
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-4">
            <label>Access Role</label>
            <input
              type="text"
              className="form-control"
              value={employee?.employeeRole || ""}
              disabled
            />
          </div>

          <div className="col-4">
            <label>Organizational Role</label>
            <input
              type="text"
              className="form-control"
              value={orgRole}
              onChange={(e) => setOrgRole(e.target.value)}
            />
          </div>
        </div>
      </Paper>

      <br />

      <Paper
        elevation={2}
        style={{ padding: "20px" }}
        className="w-100 m-auto mb-5"
      >
        <div className="row justify-content-center">
          <div className="col-4">
            <label>First Name</label>
            <input
              type="text"
              className="form-control"
              value={employee?.employeeFirstName}
              readOnly
            />
          </div>

          {employee?.employeeMiddleName && (
            <div className="col-4">
              <label>Middle Name</label>
              <input
                type="text"
                className="form-control"
                value={employee?.employeeMiddleName}
                readOnly
              />
            </div>
          )}

          <div className="col-4">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control"
              value={employee?.employeeLastName}
              readOnly
            />
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-md-4 col-4">
            <label>Official Phone No.</label>
            <input
              type="number"
              className="form-control"
              value={employee?.officialMobile}
              readOnly
            />
          </div>

          <div className="col-md-4 col-4">
            <label>Official Email</label>
            <input
              type="email"
              className="form-control"
              value={employee?.officialEmail}
              readOnly
            />
          </div>
          <div className="col-md-4 col-4">
            <label>Gender</label>
            <input
              type="text"
              className="form-control"
              value={employee?.gender}
              readOnly
            />
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          {employee.personalMobile && (
            <div className="col-md-4 col-6 mb-3">
              <label>Personal Phone No.</label>
              <input
                type="number"
                className="form-control"
                value={employee?.personalMobile}
              />
            </div>
          )}

          {employee.personalEmail && (
            <div className="col-md-4 col-6 mb-3">
              <label>Personal Email</label>
              <input
                type="email"
                className="form-control"
                value={employee?.personalEmail}
              />
            </div>
          )}
        </div>
        <div className="row justify-content-center">
          {/*<div className="col-4">
            <label>Date of Joining</label>
            <input
              type="text"
              className="form-control"
              value={dateFormatting(employee?.employeeDOJ)}
              readOnly
            />
          </div>

          <div className="col-4">
            <label>Organization Name</label>
            <input
              type="text"
              className="form-control"
              value={employee?.organization?.organizationName || ""}
              readOnly
            />
          </div>

          <div className="col-4">
            <label>Organization Code</label>
            <input
              type="text"
              className="form-control"
              value={employee?.organization?.organizationCode || ""}
              readOnly
            />
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-md-4 col-6">
            <label>PAN</label>
            <input
              type="number"
              className="form-control"
              value={employee?.employeePAN}
              readOnly
            />
          </div>

          <div className="col-md-4 col-6">
            <label>Aadhar</label>
            <input
              type="email"
              className="form-control"
              value={employee?.employeeAadhar}
              readOnly
            />
          </div>*/}
        </div>
      </Paper>

      <br />

      <Paper
        elevation={2}
        style={{ padding: "20px" }}
        className="w-100 m-auto mb-5"
      >
        <div className="row justify-content-center">
          <div className="col-md-4 col-6">
            <label>Line Manager Full Name</label>
            <input
              type="text"
              className="form-control"
              value={employee?.lineManagerName || "Unassigned"}
              readOnly
            />
          </div>

          <div className="col-md-4 col-6">
            <label>Line Manager UserName</label>
            <input
              type="text"
              className="form-control"
              value={lineMgrUsername || "Unassigned"}
              readOnly
            />
          </div>
        </div>

        <br />

        {(userInfo?.userRole === "Director" || userInfo?.userRole === "HR") && (
          <div className="row justify-content-center">
            <div className="col-md-4 col-6">
              <FormControl
                variant="standard"
                className="formControl"
                style={{ marginTop: "-10px", width: "180px" }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  Line Mananger
                </InputLabel>
                <Select
                  value={lineMgr}
                  onChange={(e) => {
                    setLineMgr(e.target.value);
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">Select a Line Manager</MenuItem>
                  {lineManagerList?.map((manager, index) => {
                    return (
                      <MenuItem key={index} value={manager?.employeeId}>
                        {manager?.employeeFirstName} {manager?.employeeLastName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-4 col-6 mt-2">
              <button className="inv-Emp__btn" onClick={assignLineManager}>
                Assign Line Mananger
              </button>
            </div>
          </div>
        )}
      </Paper>

      <br />

      <Paper
        elevation={2}
        style={{ padding: "20px" }}
        className="w-100 m-auto mb-5"
      >
        <div className="row justify-content-center">
          <div className="col-md-4 col-6">
            <label>HR Manager Full Name</label>
            <input
              type="text"
              className="form-control"
              value={employee?.hrManagerName || "Unassigned"}
              readOnly
            />
          </div>

          <div className="col-md-4 col-6">
            <label>HR Manager UserName</label>
            <input
              type="text"
              className="form-control"
              value={HRMngrUserName || "Unassigned"}
              readOnly
            />
          </div>
        </div>

        <br />

        {(userInfo?.userRole === "Director") && (
          <div className="row justify-content-center">
            <div className="col-md-4 col-6">
              <FormControl
                variant="standard"
                className="formControl"
                style={{ marginTop: "-10px", width: "180px" }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  HR Mananger
                </InputLabel>
                <Select
                  value={HRMngr}
                  onChange={(e) => {
                    setHRMngr(e.target.value);
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">Select a HR Manager</MenuItem>
                  {HRMngrList?.map((manager, index) => {
                    return (
                      <MenuItem key={index} value={manager?.employeeId}>
                        {manager?.employeeFirstName} {manager?.employeeLastName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-4 col-6 mt-2">
              <button className="inv-Emp__btn" onClick={assignHRManager}>
                Assign HR Mananger
              </button>
            </div>
          </div>
        )}
      </Paper>

      <br />

      <Paper
        elevation={2}
        style={{ padding: "20px" }}
        className="w-100 m-auto mb-5"
      >
        <div className="row justify-content-center">
          <div className="col-4">
            <button
              className="inv-Emp__btn"
              disabled={
                userInfo.userRole !== "Director" && userInfo?.userRole != "HR"
              }
              style={{
                backgroundColor:
                  userInfo.userRole != "Director" && userInfo?.userRole != "HR"
                    ? "grey"
                    : "",
              }}
              onClick={toggleEmployeeActive}
            >
              {employee.isActive ? "Deactive Employee" : "Activate Employee"}
            </button>
          </div>

          <div className="col-4">
            <button
              className="inv-Emp__btn"
              disabled={
                userInfo.userRole != "Director" &&
                userInfo.userRole != lineMgrUsername &&
                userInfo?.userRole != "HR"
              }
              style={{
                backgroundColor:
                  userInfo.userRole != "Director" &&
                  userInfo.userRole != lineMgrUsername &&
                  userInfo?.userRole != "HR"
                    ? "grey"
                    : "",
              }}
              onClick={generateRegisterCode}
            >
              Send Registration Code
            </button>
          </div>
        </div>
      </Paper>

      <br />

      <Paper
        elevation={2}
        style={{ padding: "20px" }}
        className="w-100 m-auto mb-5"
      >
        <div className="row justify-content-center">
          <div className="col-4">
            <label>Can WFH</label>
            <RedSwitch
              checked={canWFH === true ? true : false}
              onChange={() => setCanWFH(!canWFH)}
              disabled={
                userInfo.userRole != "Director" &&
                userInfo.userRole != lineMgrUsername &&
                userInfo?.userRole != "HR"
              }
              style={{
                color:
                  userInfo.userRole != "Director" &&
                  userInfo.userRole != lineMgrUsername &&
                  userInfo?.userRole != "HR"
                    ? "grey"
                    : "",
              }}
            />
          </div>

          <div className="col-4">
            <label>Lead Full Access</label>
            <RedSwitch
              checked={leadFullAccess === true ? true : false}
              onChange={() => setLeadFullAccess(!leadFullAccess)}
              disabled={
                userInfo.userRole != "Director" &&
                userInfo.userRole != lineMgrUsername &&
                userInfo?.userRole != "HR"
              }
              style={{
                color:
                  userInfo.userRole != "Director" &&
                  userInfo.userRole != lineMgrUsername &&
                  userInfo?.userRole != "HR"
                    ? "grey"
                    : "",
              }}
            />
          </div>

          <div className="col-4">
            <label>Quarter Provided</label>
            <RedSwitch
              checked={quarterProvided === true ? true : false}
              onChange={() => setQuarterProvided(!quarterProvided)}
              disabled={
                userInfo.userRole != "Director" &&
                userInfo.userRole != lineMgrUsername &&
                userInfo?.userRole != "HR"
              }
              style={{
                color:
                  userInfo.userRole != "Director" &&
                  userInfo.userRole != lineMgrUsername &&
                  userInfo?.userRole != "HR"
                    ? "grey"
                    : "",
              }}
            />
          </div>
        </div>

        <br />

        <div className="row justify-content-start">
          <div className="col-4 mt-4">
            <label>Eligible For Salary Advance</label>
            {eligibleForSalaryAdvance === true ? (
              <RedSwitch
                checked={eligibleForSalaryAdvance}
                disabled
                style={{
                  color: "grey",
                }}
              />
            ) : (
              <RedSwitch
                checked={eligibleSalaryAdvance === true ? true : false}
                onChange={() =>
                  setEligibleSalaryAdvance(!eligibleSalaryAdvance)
                }
                disabled={
                  userInfo.userRole != "Director" &&
                  userInfo.userRole != lineMgrUsername &&
                  userInfo?.userRole != "HR"
                }
                style={{
                  color:
                    userInfo.userRole != "Director" &&
                    userInfo.userRole != lineMgrUsername &&
                    userInfo?.userRole != "HR"
                      ? "grey"
                      : "",
                }}
              />
            )}
          </div>
          <div className="col-3">
            {eligibleForSalaryAdvance ? (
              <>
                <label>Multiplier</label>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={viewMultiplier}
                  style={{ width: "180px" }}
                />
                </>
            ) : (
              <Form.Group controlId="employee">
                <Form.Label>Multiplier</Form.Label>
                <Form.Control
                  as="select"
                  required
                  value={multiplier}
                  onChange={(e) => {
                    setMultiplier(e.target.value);
                  }}
                  disabled={eligibleForSalaryAdvance === true}
                >
                  <option value="">Select a multiplier</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </Form.Control>
              </Form.Group>
            )}
          </div>
        </div>
      </Paper>

      <br />

      <Paper
        elevation={2}
        style={{ padding: "20px" }}
        className="w-100 m-auto mb-5"
      >
        <div className="row justify-content-center align-items-center">
          {employee?.leavePlanName && (
            <div className="col-4">
              <label>Leave Plan Name</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={employee?.leavePlanName}
                style={{ width: "180px" }}
              />
            </div>
          )}
          <div className="col-4">
            <FormControl
              variant="standard"
              className="formControl"
              style={{ marginTop: "-10px", width: "180px" }}
            >
              <InputLabel id="demo-simple-select-helper-label">
                Select Leave Plan
              </InputLabel>
              <Select
                value={leavePlan}
                onChange={(e) => {
                  setLeavePlan(e.target.value);
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">Select a Plan</MenuItem>
                {leavePlanList?.map((plan, index) => {
                  return (
                    <MenuItem key={index} value={plan?.leavePlanId}>
                      {plan?.leavePlanName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div className="col-4">
            <button
              className="inv-Emp__btn"
              onClick={assignLeavePlan}
              disabled={
                userInfo.userRole != "Director" &&
                userInfo.userRole != lineMgrUsername &&
                userInfo?.userRole != "HR"
              }
              style={{
                backgroundColor:
                  userInfo.userRole != "Director" &&
                  userInfo.userRole != lineMgrUsername &&
                  userInfo?.userRole != "HR"
                    ? "grey"
                    : "",
              }}
            >
              Assign Leave Plan
            </button>
          </div>
        </div>
        <br />
        <div className="row align-items-center">
          <div className="col-8">
            <label>Eligible for Menstruation Leave</label>
            <RedSwitch
              checked={eligibleMenstruationLeave === true ? true : false}
              onChange={() =>
                setEligibleMenstruationLeave(!eligibleMenstruationLeave)
              }
              disabled={
                userInfo.userRole != "Director" && userInfo?.userRole != "HR"
              }
              style={{
                color:
                  userInfo.userRole != "Director" && userInfo?.userRole != "HR"
                    ? "grey"
                    : "",
              }}
            />
          </div>
        </div>
      </Paper>
      <br />
      <Paper
        elevation={2}
        style={{ padding: "20px" }}
        className="w-100 m-auto mb-5"
      >
        <div className="row justify-content-center">
          <div className="col-md-6 col-7">
            <label>Monthly Claim Auto Approval Limit</label>
            <input
              value={claimLimit}
              onChange={(e) => setClaimLimit(e.target.value)}
              className="form-control"
              disabled={
                userInfo.userRole != "Director" &&
                userInfo.userRole != lineMgrUsername &&
                userInfo?.userRole != "HR"
              }
            />
          </div>
        </div>
      </Paper>

      <br />

      <div className="row justify-content-center">
        <div className="col-4 text-center">
          <button className="inv-Emp__btn" onClick={updateEmployee}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewInvEmployee;
