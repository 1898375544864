import React, { useEffect, useRef, useState, useContext } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Tooltip from "@material-ui/core/Tooltip";
import { AiOutlineCalculator } from "react-icons/ai";
import { BsBellFill } from "react-icons/bs";
import { GoTasklist } from "react-icons/go";
import logo from "../../assets/images/appbar_logo.jpg";
import Default_img from "../../assets/images/default_user.png";
import { useNavigate, Link } from "react-router-dom";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import "./dashboard.scss";
import "./header.scss";

import { Menu, Close } from "@material-ui/icons";
import { Context } from "../../utils/Context";
import { Drawer} from "@mui/material";
import Box from "@mui/material/Box";
import SendAnnouncement from "../Notification/SendAnnouncement";
import ShowNotifications from "../Notification/ShowNotifications";



const Header = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [active, setActive] = useState("notification");

  const [open, setOpen] = useState(false);
  const [avatar, setAvatar] = useState("");
  const anchorRef = useRef(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const navigate = useNavigate();
  const logout = (e) => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const token = "bearer" + " " + userInfo.token;
    axios
      .get(`${BASE_URL}/api/v1/user/getUserByUserName/${userInfo.userName}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        if (response.data.user.userPhoto === null) setAvatar("");
        else setAvatar(response.data.user.userPhoto);
      })
      .catch((err) => {
        if (
          err?.response?.statusText === "Unauthorized" ||
          err?.response?.status === 401
        ) {
          navigate("/login");
        }
      });
  }, []);

  const screenSize = window.innerWidth;
  const { setMenu, menu } = useContext(Context);

  return (
    <div className="header">
      {screenSize > 1000 && (
        <img
          src={logo}
          alt="logo"
          className="header__logo"
          onClick={() => navigate("/home")}
        />
      )}

      {screenSize < 1000 && menu && (
        <Menu className="header__menu" onClick={() => setMenu(!menu)} />
      )}

      {screenSize < 1000 && !menu && (
        <Close className="header__menu" onClick={() => setMenu(!menu)} />
      )}

      <div className="header__right">
        <Tooltip title="EMI Calculator">
          <Link to="/homeloancalculator" className="icon-link">
            <AiOutlineCalculator />
          </Link>
        </Tooltip>
        <Tooltip title="Notification">
          <Link to="/" className="icon-link">
            <BsBellFill onClick={() => setIsDrawerOpen(true)} />
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={() => {
                setIsDrawerOpen(false);
              }}
            >
              <Box sx={{ width: 350, textAlign: "center" }} role="presentation">
            
                <div className="Notification">
                  <button className={`${active === "notification" ? "bg-red" : "bg-dark"}`}
                  onClick={() => setActive("notification")}>
                    Notifications
                  </button>
                  <button className={`${active === "announcement" ? "bg-red" : "bg-dark"}`}
                  onClick={() => setActive("announcement")}>
                    Announcements
                  </button>
                </div>

                {active === "notification" && <ShowNotifications />}
                {active === "announcement" && <SendAnnouncement setActive={setActive}/>}
              </Box>
            </Drawer>
          </Link>
        </Tooltip>
        <Tooltip title="Task list">
          <Link to="/tasklist" className="icon-link">
            <GoTasklist
              onClick={() => {
                localStorage.setItem("TaskActiveKey", "first");
              }}
            />
          </Link>
        </Tooltip>
        <button
          className="header__right__button"
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          {screenSize > 1000 && (
            <span> {userInfo?.userFullName}&nbsp;&nbsp;</span>
          )}
          <img
            style={{ height: "35px", borderRadius: "50%", width: "35px" }}
            src={avatar || Default_img}
            alt=""
          />
        </button>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={() => navigate("/myprofile")}>
                      My Profile
                    </MenuItem>

                    {
                     ( userInfo?.userRole === 'Director' || userInfo?.userRole === 'Sales')
                      &&
                      <MenuItem onClick={() => navigate("/tabletView")}>
                      Switch to Tablet view</MenuItem>
                    }

                    {userInfo.userRole === "Director" ? (
                      <div>
                        <MenuItem onClick={() => navigate("/teamappraisal")}>
                          Team Appraisal
                        </MenuItem>
                      </div>
                    ) : (
                      <div>
                        <MenuItem onClick={() => navigate("/myappraisal")}>
                          My Appraisals
                        </MenuItem>
                      </div>
                    )}
                    <MenuItem onClick={() => navigate("/hrpolicies")}>
                      HR Policies
                    </MenuItem>
                    <MenuItem onClick={logout}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

export default Header;
