import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../utils/config/url'
import './ShowAdmin.scss';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import Select from '@mui/material/Select';
import InputBase from "@mui/material/InputBase";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from "@mui/material/styles";
import Button from '../../components/Button/Button';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2'
import WeeklyTaskClosureCount from './WeeklyTaskClosureCount';
ChartJS.register(...registerables);

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: '10px',
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "#EE4B46",
        color: "white",
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px",
        width: "100"

    }
}));

const ShowAdmin = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const [selectedStatus1, setSelectedStatus1] = useState('Open')
    const [selectedStatus2, setSelectedStatus2] = useState('Open')

    const [taskTypeLabels, setTaskTypeLabels] = useState([])
    const [taskTypeCount, setTaskTypeCount] = useState([])

    const [taskRoleLabels, setTaskRoleLabels] = useState([])
    const [taskRoleCount, setTaskRoleCount] = useState([])

    const [taskOpenDueLabels, setTaskOpenDueLabels] = useState([])
    const [taskOpenDueCount, setTaskOpenDueCount] = useState([])

    const [taskClosedDueLabels, setTaskClosedDueLabels] = useState([])
    const [taskClosedDueCount, setTaskClosedDueCount] = useState([])

    const [taskUserNameLabels, setTaskUserNameLabels] = useState([])
    const [taskUserNameCount, setTaskUserNameCount] = useState([])
    

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/task/taskCountByTasktype?chartFilter=Open`,
            { headers: { Authorization: `bearer ${userInfo.token}` } })
            .then((response) => {
                // console.log(response.data.taskCountByTasktype)
                let labels = [];
                let counts = [];
                response?.data?.taskCountByTasktype?.forEach((t) => {
                    labels.push(t._id)
                    counts.push(t?.count)
                })

                setTaskTypeLabels(labels)
                setTaskTypeCount(counts)
            })
    }, [])

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/task/taskCountByTaskrole?chartFilter=Open`,
            { headers: { Authorization: `bearer ${userInfo.token}` } })
            .then((response) => {
                // console.log(response)
                let labels = [];
                let counts = [];
                response?.data?.taskCountByTaskRole?.forEach((t) => {
                    labels.push(t._id)
                    counts.push(t?.count)
                })

                setTaskRoleLabels(labels)
                setTaskRoleCount(counts)
            })
    }, [])

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/task/taskCountDueVsNoDue`,
            { headers: { Authorization: `bearer ${userInfo.token}` } })
            .then((response) => {
                // console.log(response)

                let openDueCountTrue = 0
                let openDueCountFalse = 0

                response?.data?.taskOpenDue?.forEach((t) => {

                    //  openDueLabel.push(t?._id)
                    if (t._id === true) {
                        openDueCountTrue += t.count
                    } else {
                        openDueCountFalse += t.count
                    }
                })

                // setTaskOpenDueLabels(openDueLabel)
                let countArr1 = [openDueCountTrue, openDueCountFalse]
                setTaskOpenDueCount(countArr1)

                // let closedDueLabel = [];
                let closedDueCountTrue = 0;
                let closedDueCountFalse = 0;
                response?.data?.taskClosedDue?.forEach((t) => {


                    if (t._id === true) {
                        closedDueCountTrue += t.count
                    } else {
                        closedDueCountFalse += t.count
                    }
                })

                let countArr2 = [closedDueCountTrue, closedDueCountFalse]
                setTaskClosedDueCount(countArr2)

                let userNameLabel = []
                let userNameCount = []
                response?.data?.taskDueByUserName?.forEach((t) => {
                    userNameLabel.push(t._id)
                    userNameCount.push(t.count)
                })

                setTaskUserNameLabels(userNameLabel)
                setTaskUserNameCount(userNameCount)
            })
    }, [])



    const handleFilter1 = (e) => {
        e.preventDefault()

        setSelectedStatus1(e.target.value)

        axios.get(`${BASE_URL}/api/v1/hrmgmt/task/taskCountByTasktype?chartFilter=${e.target.value}`,
            { headers: { Authorization: `bearer ${userInfo.token}` } })
            .then((response) => {
                // console.log(response)
                let labels = [];
                let counts = [];
                response?.data?.taskCountByTasktype?.forEach((t) => {
                    labels.push(t._id)
                    counts.push(t?.count)
                })

                setTaskTypeLabels(labels)
                setTaskTypeCount(counts)

            })

    }


    const handleFilter2 = (e) => {
        e.preventDefault()

        setSelectedStatus2(e.target.value)

        axios.get(`${BASE_URL}/api/v1/hrmgmt/task/taskCountByTaskrole?chartFilter=${e.target.value}`,
            { headers: { Authorization: `bearer ${userInfo.token}` } })
            .then((response) => {
                // console.log(response)
                let labels = [];
                let counts = [];
                response?.data?.taskCountByTaskRole?.forEach((t) => {
                    labels.push(t._id)
                    counts.push(t?.count)
                })

                setTaskRoleLabels(labels)
                setTaskRoleCount(counts)

            })

    }

    // console.log(taskTypeCount , taskTypeLabels)
    return (
        <div className='showAdmin'>

            <Button navigateTo={'/tasklist'}/>

            <h1>Admin Graphs</h1>

            {/*  first chart */}
            <div className='chart'>

                <div className='chart__header'>
                    <h4>Task Count By Task Type</h4>
                    <FormControl sx={{ m: 1, minWidth: 140, marginBottom: '10px' }}>

                        <InputLabel id="demo-simple-select-helper-label" style={{ color: 'black', fontSize: '18px', paddingTop: '5px' }}>
                            Status <FilterAltRoundedIcon style={{ marginLeft: '10px' }}
                            />
                        </InputLabel>

                        <Select
                            value={selectedStatus1}
                            input={<BootstrapInput />}
                            onChange={(e) => handleFilter1(e)}
                        >
                            <MenuItem value='Open'>Open</MenuItem>
                            <MenuItem value='thirtyDays'>Closed in last 30 Days</MenuItem>
                            <MenuItem value='oneYear'>Closed in last 1 year</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <Bar
                    data={{
                        labels: taskTypeLabels,
                        datasets: [{
                            label: 'Task Count By Type',
                            data: taskTypeCount,
                            backgroundColor: selectedStatus1 === 'Open' ? 'lightgrey' : selectedStatus1 === 'thirtyDays' ? 'lightgreen' : 'lightblue',
                            //borderWidth: 1
                        }]

                    }}
                    height={100}
                    width={500}
                    options={{
                        responsive: true,
                        maintainAspectRatio: true
                    }}
                />

            </div>

            {/* second chart */}
            <div className='chart'>

                <div className='chart__header'>
                    <h4>Task Count By Task Role</h4>
                    <FormControl sx={{ m: 1, minWidth: 140, marginBottom: '10px' }}>

                        <InputLabel id="demo-simple-select-helper-label" style={{ color: 'black', fontSize: '18px', paddingTop: '5px' }}>
                            Status <FilterAltRoundedIcon style={{ marginLeft: '10px' }}
                            />
                        </InputLabel>

                        <Select
                            value={selectedStatus2}
                            input={<BootstrapInput />}
                            onChange={(e) => handleFilter2(e)}
                        >
                            <MenuItem value='Open'>Open</MenuItem>
                            <MenuItem value='thirtyDays'>Closed in last 30 Days</MenuItem>
                            <MenuItem value='oneYear'>Closed in last 1 year</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <Bar
                    data={{
                        labels: taskRoleLabels,
                        datasets: [{
                            label: 'Task Count By Role',
                            data: taskRoleCount,
                            backgroundColor: selectedStatus2 === 'Open' ? 'lightgrey' : selectedStatus2 === 'thirtyDays' ? 'lightgreen' : 'lightblue',
                            //borderWidth: 1
                        }]

                    }}
                    height={100}
                    width={500}
                    options={{
                        responsive: true,
                        maintainAspectRatio: true
                    }}
                />

            </div>

            {/* thrd and fourth chart */}
            <div className='charts'>
                <h4> Open Due Tasks</h4>

                <div className='chart'>
                    <Pie
                        data={{
                            labels: ['true', 'false'],
                            datasets: [{
                                // label: 'Task Open Due Counts',
                                data: taskOpenDueCount,
                                backgroundColor: ['red', 'green']
                                //borderWidth: 1
                            }]

                        }}
                        width={200}
                        height={250}

                        options={{
                            responsive: true,
                            maintainAspectRatio: false
                        }}
                    />
                </div>

                <h4> Closed Due Tasks</h4>
                <div className='chart'>

                    <Pie
                        data={{
                            labels: ['true', 'false'],
                            datasets: [{
                                // label: 'Task Open Due Counts',
                                data: taskClosedDueCount,
                                backgroundColor: ['red', 'green']
                                //borderWidth: 1
                            }]

                        }}
                        width={200}
                        height={250}

                        options={{
                            responsive: true,
                            maintainAspectRatio: false
                        }}
                    />
                </div>

            </div>

            {/*  fifth chart */}
            <div className='chart'>
                <h4> Task Count By Username</h4>
                <Bar
                    data={{
                        labels: taskUserNameLabels,
                        datasets: [{
                            label: 'Task Count By Username',
                            data: taskUserNameCount,
                            backgroundColor: 'lightgrey'
                            //borderWidth: 1
                        }]

                    }}
                    height={100}
                    width={500}
                    options={{
                        responsive: true,
                        maintainAspectRatio: true
                    }}
                />
            </div>

            {/* sixth chart */}
            <WeeklyTaskClosureCount />

        </div>
    )
}

export default ShowAdmin