import React from 'react'
import './Utils.scss';


const Utils = () => {
    return (
        <div className='utils'>
            <h2>Coming Soon</h2>
        </div>
    )
}


export default Utils