import React from "react";
import "./ShowEduDetails.scss";
import { MdDelete } from "react-icons/md";
import { Paper } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../../utils/config/url";
import Swal from "sweetalert2";

const ShowEduDetails = ({ education, employeeId, setLoading, userInfo }) => {
  if (!education) {
    return null;
  }

  const deleteEducation = async (id) => {
    try {
     
      const res = await axios.put(
        `${BASE_URL}/api/v1/hrmgmt/Employee/deleteEducationalDetails`,
        {
          employeeId,
          id,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Education details deleted successfully",
        text: res.data.message,
        showConfirmButton: true,
      }).then(function () {
        setLoading((prev) => !prev);
      });
    } catch (error) {
      console.error("Error deleting education details:", error);
      Swal.fire({
        icon: "error",
        title: "Error deleting education details",
        text: error.response.data.message,
        showConfirmButton: true,
      });
      // Handle the error as needed
    }
  };
  return (
    <Paper elevation={3} className="showedudetails">
      <div className="row justify-content-center">
        <div className="col-3">
          <b>Qualification:</b> {education?.qualification}
        </div>
        <div className="col-4">
          <b>School/Institute:</b> {education?.schoolInstitute}
        </div>
        <div className="col-3">
          <b>Degree Scan:</b>{" "}
          {education?.degreeScan ? (
            <a href={education?.degreeScan}>Download</a>
          ) : (
            "Not Added"
          )}
        </div>
        <div className="col-1">
          <MdDelete
            onClick={(e) => {
              e.preventDefault();
              Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ee4b46",
                cancelButtonColor: "grey",
                confirmButtonText: "Yes, delete it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteEducation(education?._id);
                }
              });
            }}
            style={{ cursor: "pointer", color: "red", fontSize: "20px", float: "right", marginTop: "5px", marginRight: "5px"}}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-3">
          <b>Score Type:</b> {education?.scoreType}
        </div>
        <div className="col-4">
          <b>Board/University:</b> {education?.universityBoard}
        </div>
        <div className="col-3">
          <b>Marksheet Scan:</b>{" "}
          {education?.marksheetScan ? (
            <a href={education?.marksheetScan}>Download</a>
          ) : (
            "Not Added"
          )}
        </div>
        <div className="col-1"></div>
      </div>
      <div className="row justify-content-center">
        <div className="col-3">
          <b>Score:</b> {education?.score}
        </div>
        <div className="col-4">
          <b>Year of Passing:</b> {education?.yearOfPassing}
        </div>
        <div className="col-3">
          <b>Country:</b> {education?.country}
        </div>
        <div className="col-1"></div>
      </div>
      <div className="row justify-content-center">
        <div className="col-3">
          <b>City:</b> {education?.city}
        </div>
        <div className="col-4">
          <b>State:</b> {education?.State}
        </div>
        <div className="col-3"></div>
        <div className="col-1"></div>
      </div>
    </Paper>
  );
};

export default ShowEduDetails;
