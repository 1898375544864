import { Switch, Paper } from '@mui/material'
import React from 'react'
import Swal from 'sweetalert2';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { Modal, Fade } from "@mui/material";
import { MoonLoader } from "react-spinners";

const RegistrarAppointment = ({ unitHandover, setUnitHandover }) => {

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const [loader, setLoader] = React.useState(false);


    const [registrarInfo, setRegistrarInfo] = React.useState({
        registrarName: "",
        registrarRole: "",
        registrationOffice: "",
        registrationOfficeAddress: "",
        proposedRegistrationDate: "",
        appointmentConfirmedByUserName: userInfo?.userName,
        appointmentConfirmedByUserFullName: userInfo?.userFullName
    })

    const registrarAppointment = (e) => {

        e.preventDefault()

        setLoader(true);

        axios
            .put(
                `${BASE_URL}/api/v1/CustomerMgmt/handover/registrarAppointment`,
                {
                    ...registrarInfo,
                    handoverId: unitHandover?.handoverId
                },
                {
                    headers: { Authorization: `bearer ${userInfo.token}` },
                }
            )
            .then((response) => {
                setLoader(false);

                if (response.data.status) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                    }).then(function () {
                        setUnitHandover(response.data.handover);
                    });
                }
            })
            .catch((err) => {
                setLoader(false);

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error || err.response.data.message,
                });
            });

    }

    const dateFormatting = (input) => {

        if (!input) {
            return 'N/A'
        }
        // console.log(input)
        let d = new Date(input)
        // console.log(d)

        let date = d.getDate()
        if (date < 10) {
            date = `0${date}`
        }
        let m = d.getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = d.getFullYear()

        let final;

        final = `${date}/${m}/${y}`
        return final

    }


    return (
        <>
            <Paper elevation={1}
                style={{
                    padding: '30px 20px',
                    width: '90%',
                    margin: 'auto'
                }}>
                <div className="row mb-3 mx-2 card-1">
                    <div className="col-12 tab-card pt-3 pb-3 text-center">
                        {!unitHandover?.registrarAppointment?.registrarAppointmentDone ? (
                            (userInfo.userRole !== "Operations" &&
                                userInfo.userRole !== "Director") ? (
                                <h5
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    Registrar Appointment Pending
                                </h5>
                            ) : (
                                <>
                                    <h4
                                        style={{
                                            textAlign: "center"
                                        }}
                                    >
                                        Registrar Appointment & Date
                                    </h4>

                                    <form
                                        onSubmit={(e) => {
                                            registrarAppointment(e)
                                        }}
                                    >

                                        <div className='row justify-content-center mt-4'>
                                            <div className='col-6'>
                                                <label>Registrar Name</label>
                                                <input type="text" required className='form-control' value={registrarInfo?.registrarName}
                                                    onChange={(e) => setRegistrarInfo((prev) => {
                                                        return { ...prev, registrarName: e.target.value }
                                                    })} />
                                            </div>

                                            <div className='col-6'>
                                                <label>Designation</label>
                                                <input type="text" required className='form-control' value={registrarInfo?.registrarRole}
                                                    onChange={(e) => setRegistrarInfo((prev) => {
                                                        return { ...prev, registrarRole: e.target.value }
                                                    })} />
                                            </div>
                                        </div>

                                        <div className='row justify-content-center mt-4'>
                                            <div className='col-6'>
                                                <label>Registrar Office</label>
                                                <input type="text" required className='form-control' value={registrarInfo?.registrationOffice}
                                                    onChange={(e) => setRegistrarInfo((prev) => {
                                                        return { ...prev, registrationOffice: e.target.value }
                                                    })} />
                                            </div>

                                            <div className='col-6'>
                                                <label>Registrar Office Address</label>
                                                <input type="text" required className='form-control'
                                                    onChange={(e) => setRegistrarInfo((prev) => {
                                                        return { ...prev, registrationOfficeAddress: e.target.value }
                                                    })} />
                                            </div>
                                        </div>

                                        <div className='row justify-content-center mt-4'>
                                            <div className='col-6'>
                                                <label>Proposed Registration Date</label>
                                                <input type="date" required className='form-control' value={registrarInfo?.proposedRegistrationDate}
                                                    onChange={(e) => setRegistrarInfo((prev) => {
                                                        return { ...prev, proposedRegistrationDate: e.target.value }
                                                    })} />
                                            </div>

                                        </div>

                                        <div className='row justify-content-center mt-4'>
                                            <div className='col-6'>
                                                <button className='unit-handover__btns' type='submit'>Update</button>
                                            </div>
                                        </div>


                                    </form>


                                </>
                            )
                        ) : (
                            <>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <p
                                        style={{
                                            fontSize: "25px",
                                            fontWeight: "bold",
                                            textAlign: "center",
                                        }}
                                    >
                                        Registrar Appointment Confirmed
                                    </p>
                                    <br />
                                    <div>
                                        <p>Proposed Registration Date:{" "}<b>{dateFormatting(unitHandover?.registrarAppointment?.proposedRegistrationDate)}</b> </p>
                                        <p> Registrar Name:{" "}<b>{unitHandover?.registrarAppointment?.registrarName}</b></p>
                                        <p> Designation:{" "}<b>{unitHandover?.registrarAppointment?.registrarRole}</b></p>
                                        <p> Registration Office:{" "}<b>{unitHandover?.registrarAppointment?.registrationOffice}</b></p>
                                        <p> Registration Office Address :{" "}<b>{unitHandover?.registrarAppointment?.registrationOfficeAddress}</b></p>
                                        <p>Appointment Confirmed By:{" "}<b>{unitHandover?.registrarAppointment?.appointmentConfirmedByUserFullName}</b></p>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Paper>


            {/* //////////// Modal for spinner //////////// */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={loader}
                onClose={() => {
                    setLoader(false);
                }}
                closeAfterTransition
            >
                <Fade in={loader}>
                    <div>
                        <MoonLoader
                            color="red"
                            className="spinner"
                            loading={loader}
                            size={70}
                        />
                    </div>
                </Fade>
            </Modal>

        </>
    )
}

export default RegistrarAppointment;