import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios'
import { BASE_URL } from '../../utils/config/url';
import './Considerations.scss';


const Considerations = () => {

    const applicationId = useParams().applicationId;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const Token = 'bearer' + " " + userInfo.token


    const [data, setData] = useState({});
    const [carParkings, setCarParkings] = useState([])
    const [otherCharges, setOtherCharges] = useState([])

    useEffect(() => {
        axios
            .get(`${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/getBookingApplicationByApplicationId/${applicationId}`,
                { headers: { Authorization: Token } })
            .then((response) => {

                setData(response.data.BookingApplication)
                setCarParkings(response.data.BookingApplication.carParkings)
                setOtherCharges(response.data.BookingApplication.otherCharges)
            })
    }, [])


    return (
        <div className='considerations'>

            <div className="tab-card card1">
                <h4>Total Application Consideration</h4>
                <br />
                <div className="row justify-content-center">
                    <div className="col-md-4 col-sm-12">
                        <label>Application Gross Price</label>
                        <input
                            type="number"
                            class="form-control"
                            name="agp"
                            id="agp"
                            readOnly
                            value={data.grossTotalConsideration}
                        />
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <label>Application GST Amount</label>
                        <input
                            type="number"
                            class="form-control"
                            name="aga"
                            id="aga"
                            readOnly
                            value={data.gstTotalConsideration}
                        />
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <label>Application Total Price</label>
                        <input
                            type="number"
                            class="form-control"
                            name="atp"
                            id="atp"
                            readOnly
                            value={data.totalConsideration}
                        />
                    </div>
                </div>
            </div>

            <br />
            <br />

            <div className="tab-card card2" >
                <h4>Unit Consideration</h4>
                <br />

                < div className='base__info'>
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-12">
                            <label>Unit Name</label>
                            <input
                                type="text"
                                class="form-control"
                                name="unitName"
                                id="unitName"
                                readOnly
                                value={data.unit?.unitName}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label>BHK</label>
                            <input
                                type="text"
                                class="form-control"
                                name="cbhk"
                                id="cbhk"
                                readOnly
                                value={data.unit?.bhk}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label>Phase Name</label>
                            <input
                                type="text"
                                class="form-control"
                                name="phaseName"
                                id="phaseName"
                                readOnly
                                value={data.siteDetails?.phaseName}
                            />
                        </div>
                    </div>
                    <br />

                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-12">
                            <label>Carpet Area (In Sq Ft)</label>
                            <input
                                type="number"
                                class="form-control"
                                name="carpetArea"
                                id="carpetArea"
                                readOnly
                                value={data.unit?.carpetArea}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label>Balcony Area</label>
                            <input
                                type="number"
                                class="form-control"
                                name="balconyArea"
                                id="balconyArea"
                                readOnly
                                value={data.unit?.balconyArea}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label>Private Terrace(In Sq Ft)</label>
                            <input
                                type="number"
                                class="form-control"
                                name="pt"
                                id="pt"
                                readOnly
                                value={data.unit?.privateTerraceArea}
                            />
                        </div>
                    </div>
                    <br />

                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-12">
                            <label>Built Up Area</label>
                            <input
                                type="number"
                                class="form-control"
                                name="builtUpArea"
                                id="builtUpArea"
                                readOnly
                                value={data.unit?.builtUpArea}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label>Super Built Up Area</label>
                            <input
                                type="number"
                                class="form-control"
                                name="superBuiltUpArea"
                                id="superBuiltUpArea"
                                readOnly
                                value={data.unit?.superBuiltUpArea}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label>Unit Floor</label>
                            <input
                                type="number"
                                class="form-control"
                                name="unitFloor"
                                id="unitFloor"
                                readOnly
                                value={data.unit?.unitFloor}
                            />
                        </div>
                    </div>
                </div>

                <br />

                <div className='unit__info'>
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-12">
                            <label>Base Sq. Feet Rate</label>
                            <input
                                type="number"
                                class="form-control"
                                name="bsr"
                                id="bsr"
                                readOnly
                                value={data.unit?.baseSqFeetRate}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label>Base Gross Price</label>
                            <input
                                type="number"
                                class="form-control"
                                name="ugp"
                                id="ugp"
                                readOnly
                                value={data.unit?.baseUnitGrossPrice}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-12">
                            <label>Base Price GST</label>
                            <input
                                type="number"
                                class="form-control"
                                name="ugp"
                                id="ugp"
                                readOnly
                                value={data.unit?.baseUnitPriceGST}
                            />
                        </div>

                        <div className="col-md-4 col-sm-12">
                            <label>Base Price Total</label>
                            <input
                                type="number"
                                class="form-control"
                                name="ugp"
                                id="ugp"
                                readOnly
                                value={data.unit?.baseUnitPrice}
                            />
                        </div>
                    </div>

                </div>

                <br />


                <div className='floor__info'>
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-12">
                            <label>Floor Escalation Charge Rate</label>
                            <input
                                type="number"
                                class="form-control"
                                name="floorEscalationChargeAmount"
                                id="floorEscalationChargeAmount"
                                readOnly
                                value={data.unit?.floorEscalationCharge}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label>Floor Escalation Charge Amount</label>
                            <input
                                type="number"
                                class="form-control"
                                name="floorEscalationChargeAmount"
                                id="floorEscalationChargeAmount"
                                readOnly
                                value={data.unit?.floorEscalationChargeAmount}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-12">
                            <label>Floor Escalation Charge GST</label>
                            <input
                                type="number"
                                class="form-control"
                                name="floorEscalationChargeGST"
                                id="floorEscalationChargeGST"
                                readOnly
                                value={data.unit?.floorEscalationChargeGST}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label>Floor Escalation Charge Total</label>
                            <input
                                type="number"
                                class="form-control"
                                name="floorEscalationChargeTotal"
                                id="floorEscalationChargeTotal"
                                readOnly
                                value={data.unit?.floorEscalationChargeTotal}
                            />
                        </div>
                    </div>
                </div>


                <br />


                <div className='plc__info'>
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-12">
                            <label>Preferred Location Charge Rate</label>
                            <input
                                type="number"
                                class="form-control"
                                name="ugp"
                                id="ugp"
                                readOnly
                                value={data.unit?.preferredLocationCharge}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label>Preferred Location Charge Amount</label>
                            <input
                                type="number"
                                class="form-control"
                                name="ugp"
                                id="ugp"
                                readOnly
                                value={data.unit?.preferredLocationChargeAmount}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-12">
                            <label>Preferred Location Charge GST</label>
                            <input
                                type="number"
                                class="form-control"
                                name="preferredLocationChargeGST"
                                id="preferredLocationChargeGST"
                                readOnly
                                value={data.unit?.preferredLocationChargeGST}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label>Preferred Location Charge Total</label>
                            <input
                                type="number"
                                class="form-control"
                                name="preferredLocationChargeTotal"
                                id="preferredLocationChargeTotal"
                                readOnly
                                value={data.unit?.preferredLocationChargeTotal}
                            />
                        </div>
                    </div>

                </div>

                <br />

                <div className='terrace__info'>
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-12">
                            <label>Private Terrace Charge Rate</label>
                            <input
                                type="number"
                                class="form-control"
                                name="privateTerraceChargeAmount"
                                id="privateTerraceChargeAmount"
                                readOnly
                                value={data.unit?.privateTerraceChargeRate}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <lable>Private Terrace Charge Amount</lable>
                            <input
                                type="number"
                                class="form-control"
                                name="privateTerraceChargeAmount"
                                id="privateTerraceChargeAmount"
                                readOnly
                                value={data.unit?.privateTerraceChargeAmount}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-12">
                            <label>Private Terrace Charge GST</label>
                            <input
                                type="number"
                                class="form-control"
                                name="privateTerraceChargeGST"
                                id="privateTerraceChargeGST"
                                readOnly
                                value={data.unit?.privateTerraceChargeGST}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label>Private Terrace Charge Total</label>
                            <input
                                type="number"
                                class="form-control"
                                name="ptct"
                                id="ptct"
                                readOnly
                                value={data.unit?.privateTerraceChargeTotal}
                            />
                        </div>
                    </div>

                </div>

                <br />

                <div className='unit__info'>
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-12">
                            <label>Chargeable Square feet Rate</label>
                            <input
                                type="number"
                                class="form-control"
                                name="ugp"
                                id="ugp"
                                readOnly
                                value={data.unit?.chargeableSqFeetRate}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label>Unit Gross Price</label>
                            <input
                                type="number"
                                class="form-control"
                                name="ugp"
                                id="ugp"
                                readOnly
                                value={data.unit?.unitGrossPrice}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-12">
                            <label>Unit GST Amount</label>
                            <input
                                type="number"
                                class="form-control"
                                name="uga"
                                id="uga"
                                readOnly
                                value={data.unit?.unitGstAmount}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label>Unit Total Price</label>
                            <input
                                type="number"
                                class="form-control"
                                name="utp"
                                id="utp"
                                readOnly
                                value={data.unit?.unitPrice}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <br />


            <div className="tab-card card3">
                <div className="mt-2 container-fluid justify-content-center">
                    <h4>Car Parking Consideration</h4>
                    <br />
                    <table className="table">
                        <thead
                            style={{ backgroundColor: "#EE4B46", color: "#fff" }}
                        >
                            <tr>
                                <th scope="col">Car Parking Type</th>
                                <th scope="col">Car Parking Gross Price</th>
                                <th scope="col">Car Parking GST</th>
                                <th scope="col">Car Parking Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {carParkings?.map((c, index) => (
                                <tr key={index}>
                                    <td>{c.carParkingTypeName}</td>
                                    <td>{c.carParkingGrossPrice}</td>
                                    <td>{c.carParkingGST}</td>
                                    <td>{c.carParkingPrice}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <br />

                    <div className="row justify-content-center">
                        <br />
                        <div className="col-4">
                            <label>Car Parking Gross Total</label>
                            <input
                                class="form-control"
                                type="number"
                                name="otherChargesSubTotal"
                                id="otherChargesSubTotal"
                                readOnly
                                value={data.carParkingGrossTotal}
                            />
                        </div>
                        <div className="col-4">
                            <label>Car Parking GST</label>
                            <input
                                class="form-control"
                                type="number"
                                name="otherChargesGST"
                                id="otherChargesGST"
                                readOnly
                                value={data.carParkingGSTTotal}
                            />
                        </div>
                        <div className="col-4">
                            <label>Car Parking Total</label>
                            <input
                                class="form-control"
                                type="number"
                                name="otherChargesTotal"
                                id="otherChargesTotal"
                                readOnly
                                value={data.carParkingTotal}
                            />
                        </div>
                    </div>
                </div>
                <br />
            </div>

            <br />
            <br />

            <div className='tab-card card4'>
                <div className="mt-2 container-fluid justify-content-center">
                    <h4>Other Charges Consideration</h4>
                    <br />
                    <table class="table">
                        <thead
                            style={{ backgroundColor: "#EE4B46", color: "#fff" }}
                        >
                            <tr>
                                <th scope="col">Charge Name</th>
                                <th scope="col">Charge Gross Amount</th>
                                <th scope="col">Charge GST</th>
                                <th scope='col'>Charge Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {otherCharges.map((o) => (
                                <tr>
                                    <td>{o.name}</td>
                                    <td>{o.grossAmount}</td>
                                    <td>{o.gstAmount}</td>
                                    <td>{o.totalAmount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <br />

                    <div className="row justify-content-center">
                        <div className="col-4">
                            <label>Other Charges Gross Total</label>
                            <input
                                class="form-control"
                                type="number"
                                name="otherChargesSubTotal"
                                id="otherChargesSubTotal"
                                readOnly
                                value={data.otherChargesGrossTotal}
                            />
                        </div>
                        <div className="col-4">
                            <label>Other Charges GST</label>
                            <input
                                class="form-control"
                                type="number"
                                name="otherChargesGST"
                                id="otherChargesGST"
                                readOnly
                                value={data.otherChargesGST}
                            />
                        </div>
                        <div className="col-4">
                            <label>Other Charges Total</label>
                            <input
                                class="form-control"
                                type="number"
                                name="otherChargesTotal"
                                id="otherChargesTotal"
                                readOnly
                                value={data.otherChargesTotal}
                            />
                        </div>
                    </div>
                </div>
                <br />
            </div>


            <br />

        </div>
    )
}

export default Considerations