import React from 'react'
import { BiLogoFacebook, BiLogoInstagram } from 'react-icons/bi'
import { GrMail } from 'react-icons/gr'
import { PiPhoneCallFill } from 'react-icons/pi'
import './Footer.scss';
import BottomLogo from '../BottomLogo/BottomLogo';
import { useLocation } from 'react-router-dom';


const Footer = () => {

    const pathname = useLocation().pathname;
   
    return (

        <div className='footer'
        style={{
            height : pathname == '/tabletView' ? '30px' : '100px'
        }}
        >

            {
                pathname != '/tabletView'
                &&
                <BottomLogo />
            }
            <div className='footer-info'>

                <div className='footer__left'>© 2023 Westroad - All rights reserved.</div>

                <div className='footer__right'>
                    <BiLogoFacebook className='icon' />
                    <BiLogoInstagram className='icon' />
                    <GrMail className='icon' />
                    <PiPhoneCallFill className='icon' />
                </div>
            </div>
        </div>
    )
}

export default Footer