import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../utils/config/url';
import BarCharts from "./BarCharts";
import { Pie } from "react-chartjs-2";
import Paper from "@mui/material/Paper";
import Button from '../../components/Button/Button';
import './StatusDashboard.scss';
import { Form } from 'react-bootstrap';


const StatusDashboard = () => {

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const [statusApplicationsLabel, setStatusApplicationLabel] = useState([]);
    const [statusApplications, setStatusApplications] = useState([]);
    const [statusApplicationsBg, setStatusApplicationsBg] = useState([]);

    const [usernameApplicationsLabel, setUsernameApplicationLabel] = useState([]);
    const [usernameApplications, setUsernameApplications] = useState([]);
    const [usernameApplicationsBg, setUsernameApplicationsBg] = useState([]);

    const [monthlyApplicationCount, setMonthlyApplicationCount] = useState([]);
    const [monthName, setMonthName] = useState([]);

    const [siteList, setSiteList] = useState([]);
    const [siteCode, setSiteCode] = useState("");
    const [siteName, setSiteName] = useState("")

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/Configuration/site/getAllSites`,
            {
                headers: {
                    Authorization: `bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response.data.siteArray)
                setSiteList(response.data.siteArray)
            })

    }, [])

    useEffect(() => {
        let url = `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/getApplicationsByStatus`;

        if (siteCode) {
            url += `?siteCode=${siteCode}`
        }

        axios
            .get(url, {
                headers: { Authorization: `bearer ${userInfo.token}` },
            })
            .then((response) => {
                // console.log(response)
                if (response.data.status) {
                    let data = response?.data?.report.map((data) => {
                        return data.count;
                    });
                    setStatusApplications(data);

                    let names = response?.data?.report.map((data) => {
                        return data._id;
                    });

                    setStatusApplicationLabel(names);

                    let colors = response?.data?.report.map((val) => {
                        return (
                            "#" +
                            "0123456789abcdef"
                                .split("")
                                .map(function (v, i, a) {
                                    return i > 5 ? null : a[Math.floor(Math.random() * 16)];
                                })
                                .join("")
                        );
                    });

                    setStatusApplicationsBg(colors);
                } else {
                    setStatusApplications([]);
                    setStatusApplicationLabel([]);
                    setStatusApplicationsBg([]);
                }

            });
    }, [siteCode]);

    useEffect(() => {
        let url = `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/getApplicationsByUsername`;

        if (siteCode) {
            url += `?siteCode=${siteCode}`
        }

        axios
            .get(url, {
                headers: { Authorization: `bearer ${userInfo.token}` },
            })
            .then((response) => {
                console.log(response)
                if (response.data.status) {
                    let data = response?.data?.report.map((data) => {
                        return data.count;
                    });
                    setUsernameApplications(data);

                    let names = response?.data?.report.map((data) => {
                        return data._id;
                    });

                    setUsernameApplicationLabel(names);

                    let colors = response?.data?.report.map((val) => {
                        return (
                            "#" +
                            "0123456789abcdef"
                                .split("")
                                .map(function (v, i, a) {
                                    return i > 5 ? null : a[Math.floor(Math.random() * 16)];
                                })
                                .join("")
                        );
                    });

                    setUsernameApplicationsBg(colors);
                } else {
                    setUsernameApplications([]);
                    setUsernameApplicationLabel([]);
                    setUsernameApplicationsBg([]);
                }

            });
    }, [siteCode]);

    useEffect(() => {
        axios
            .get(`${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/getMonthwiseBookingApplications`, {
                headers: { Authorization: `bearer ${userInfo.token}` },
            })
            .then((response) => {
                console.log(response)
                // setMonthlyLeadCount(response.data.monthwiseLeadCount);

                let monthlyCount = response.data.report.map((data) => {
                    return Object.values(data[0])[0];
                });

                setMonthlyApplicationCount(monthlyCount);

                let monthName = response.data.report.map((data) => {
                    return Object.keys(data[0])[0];
                });

                setMonthName(monthName);
            });
    }, []);


    // console.log(statusApplicationsLabel)
    // console.log(usernameApplicationsLabel)

    return (
        <div className='statusDashboard'>

            <Button navigateTo={'/flatAllotment/manageapplications'} />

            <br />

            <h1 className='text-center'>Status Dashboard</h1>

            <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>

                <div className='row justify-content-center'>

                    <div className="col-3">
                        <Form.Group controlId="employee">
                            <Form.Label
                                style={{ fontSize: '20px' }}
                            >Project</Form.Label>
                            <Form.Control
                                as="select"
                                required
                                value={`${siteCode},${siteName}`}
                                onChange={(e) => {
                                    setSiteCode(e.target.value.split(',')[0]);
                                    setSiteName(e.target.value.split(',')[1])
                                }}
                            >
                                <option value="">Select a site</option>
                                {
                                    siteList?.map((site, index) => {
                                        return <option value={`${site?.siteCode},${site?.siteName}`} key={index}
                                        >{site?.siteName}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>

                </div>

                <div className="row justify-content-between">

                    <div className="col-4">
                        <h4 className="text-center">Applications By Status</h4>
                        {statusApplications.length === 0 ? (
                            <h6 className="text-center">NO REPORT FOUND</h6>
                        ) : (
                            <Pie
                                data={{
                                    labels: statusApplicationsLabel,
                                    datasets: [
                                        {
                                            // label: '# Open ans Assigned Leads',
                                            data: statusApplications,
                                            backgroundColor: statusApplicationsBg,
                                            borderWidth: 1,
                                        },
                                    ],
                                }}
                                width={200}
                                height={250}
                            // options={{
                            //     responsive: true,
                            //     maintainAspectRatio: false,
                            // }}
                            />
                        )}
                    </div>

                    <div className="col-4 text-center">
                        <h4 className="text-center">Applications By Username</h4>
                        {usernameApplications.length === 0 ? (
                            <h6 className="text-center">NO REPORT FOUND</h6>
                        ) : (
                            <Pie
                                data={{
                                    labels: usernameApplicationsLabel,
                                    datasets: [
                                        {
                                            // label: '# Open ans Assigned Leads',
                                            data: usernameApplications,
                                            backgroundColor: usernameApplicationsBg,
                                            borderWidth: 1,
                                        },
                                    ],
                                }}
                                width={200}
                                height={250}
                            // options={{
                            //     responsive: true,
                            //     maintainAspectRatio: false,
                            // }}
                            />
                        )}
                    </div>

                </div>

            </Paper>

            <br />

            <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
                <h4 className="text-center">Applications Closed Monthwise</h4>

                <div className="row">
                    <div className="col-12">
                        <BarCharts monthName={monthName} monthCount={monthlyApplicationCount} />
                    </div>
                </div>
            </Paper>

        </div>
    )
}

export default StatusDashboard