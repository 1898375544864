import React, { useState, useEffect } from "react";
import "./SelfOnboard.scss";
import { Paper } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";

const UploadSignedOfferLetter = ({ setActive, employeeId, onBoardInfo }) => {
  const [signedOfferLtr, setSignedOfferLtr] = useState("");
  const [loading, setLoading] = useState(false);
  const [signedSupportingDocs, setSignedSupportingDocs] = useState("");
  const [selfOnboardEnable, setSelfOnboardEnable] = useState(false);
  const [downloadSignedOfferLtr, setDownloadSignedOfferLtr] = useState("");
  const [downloadSignedSupportingDocs, setDownloadSignedSupportingDocs] =
    useState("");

  useEffect(() => {
    let isMounted = true;

    const getOnboardingById = async () => {
      setLoading(false);
      try {
        const res = await axios.get(
          `${BASE_URL}/api/v1/hrmgmt/onboarding/getOnboardingById/${onBoardInfo.onboardingId}`,
          {
            headers: {
              Authorization: `Bearer ${onBoardInfo.token}`,
            },
          }
        );

        if (isMounted) {
          if (res.data.status === true) {
            setDownloadSignedOfferLtr(res?.data?.onboarding?.signedOfferLetter);
            setDownloadSignedSupportingDocs(
              res?.data?.onboarding?.signedSupportingDocs
            );
          }
        }
      } catch (error) {
        console.error("Error fetching onboarding details:", error);
        // Handle the error as needed
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    };

    getOnboardingById();

    return () => {
      isMounted = false; // Set isMounted to false when the component is unmounted
    };
  }, [loading]);

  const completeSelfOnboarding = () => {
    Swal.fire({
      title: "Are you sure you want to complete your self onboarding?",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#ee4b46",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${BASE_URL}/api/v1/hrmgmt/onboarding/completeSelfOnboarding`,
            {
              onboardingId: onBoardInfo?.onboardingId,
            },
            {
              headers: {
                Authorization: `Bearer ${onBoardInfo?.token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              Swal.fire({
                title: "Self Onboarding Completed Successfully",
                icon: "success",
              }).then(() => {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = "/login";
              });
            } else {
              Swal.fire({
                title: "Failed to complete your self onboarding",
                icon: "error",
                showConfirmButton: true,
              });
            }
          });
      }
    });
  };

  const UploadSignedOfferLetter = () => {
    if (!signedOfferLtr) {
      Swal.fire({
        title: "Please upload signed offer letter",
        icon: "error",
      });
      return;
    }
    if (!signedSupportingDocs) {
      Swal.fire({
        title: "Please upload signed supporting documents",
        icon: "error",
      });
      return;
    }
    const formData = new FormData();
    formData.append("onboardingId", onBoardInfo?.onboardingId);
    formData.append("signedOfferLetter", signedOfferLtr);
    formData.append("signedSupportingDocs", signedSupportingDocs);
    axios
      .put(
        `${BASE_URL}/api/v1/hrmgmt/onboarding/uploadOfferLetterNSupportingDocs`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${onBoardInfo?.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          Swal.fire({
            title: "Signed Documents Uploaded Successfully",
            icon: "success",
          });
          setLoading(true);
        } else {
          Swal.fire({
            title: "Failed to upload signed documents",
            icon: "error",
          });
        }
      });
  };
  return (
    <div className="selfOnboard">
      <Paper elevation={3} className="selfOnboard__paper">
        <div className="selfOnboard__paper__content">
          <div className="row justify-content-center">
            <div className="col-4">
              <label>Upload Signed Offer Letter</label>
              <input
                type="file"
                className="form-control"
                id="signed-offer-letter"
                accept=".pdf"
                onChange={(e) => setSignedOfferLtr(e.target.files[0])}
              />
            </div>
            <div className="col-4 mt-4">
              <a href={downloadSignedOfferLtr} download>
                <button
                  type="button"
                  disabled={!downloadSignedOfferLtr}
                  style={{
                    backgroundColor: !downloadSignedOfferLtr ? "grey" : "",
                  }}
                >
                  Download Signed Offer Letter
                </button>
              </a>
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <label>Upload Signed Supporting Documents</label>
              <input
                type="file"
                className="form-control"
                accept=".pdf"
                id="signed-supporting-docs"
                onChange={(e) => setSignedSupportingDocs(e.target.files[0])}
              />
            </div>
            <div className="col-4 mt-4">
              <a href={downloadSignedSupportingDocs} download>
                <button
                  type="button"
                  disabled={!downloadSignedSupportingDocs}
                  style={{
                    backgroundColor: !downloadSignedSupportingDocs
                      ? "grey"
                      : "",
                  }}
                >
                  Download Signed Supporting Documents
                </button>
              </a>
            </div>
          </div>
          <br />
          <br />
          <div className="row justify-content-center">
            <div className="col-3">
              <button type="button" onClick={UploadSignedOfferLetter}>
                Upload
              </button>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="row justify-content-center align-items-center">
          <div className="pr-2">
            <input
              type="checkbox"
              onChange={(e) => setSelfOnboardEnable(e.target.checked)}
              style={{ width: "20px", height: "20px" }}
            />
          </div>
          <div className="col-8">
            <label>
              I hereby confirm that all the documents and information that i
              have provided is true to my knowledge. I also authorise Westroad
              to perform background check in case of any discrepancy Westroad
              has full right to revoke my employment
            </label>
          </div>
        </div>

        <br />
        <div className="row justify-content-between">
          <div className="col-1">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setActive("edu");
              }}
            >
              Prev
            </button>
          </div>
          <div className="col-3">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                completeSelfOnboarding();
              }}
              disabled={!selfOnboardEnable || !downloadSignedOfferLtr}
              style={{
                backgroundColor:
                  !selfOnboardEnable || !downloadSignedOfferLtr ? "grey" : "",
              }}
            >
              Complete Self Onboarding
            </button>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default UploadSignedOfferLetter;
