import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { BASE_URL } from '../../utils/config/url';
import MaterialTable from "material-table";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from 'react-icons/io'
import { Add } from '@material-ui/icons';
import './SiteTable.scss';
import { Context } from '../../utils/Context';
import { useStyles } from '../../utils/ModalStyles';
import Button from "../../components/Button/Button";

const SiteTable = () => {

    const classes = useStyles();

    const { setTab } = useContext(Context);
    const [site, setSite] = useState([])

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const Token = 'bearer' + " " + userInfo.token
    const navigate = useNavigate();

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/Configuration/Site/getAllSites`,
            { headers: { 'Authorization': Token } })
            .then(response => {

                const sites = response.data.siteArray.map((site) => {
                    const { siteId, siteName, siteRERANo, siteActive, siteAddress, siteCode } = site
                    var modifiedActive = ""
                    if (siteActive === true) {
                        modifiedActive = "Yes"
                    }
                    else if (siteActive === false) {
                        modifiedActive = "No"
                    }

                    var modifiedAddress = siteAddress.fullAddress + ", " + siteAddress.landmark + ", " + siteAddress.city + "-" + siteAddress.pinCode + ", " + siteAddress.state

                    return {
                        siteId,
                        siteName,
                        siteRERANo,
                        siteCode,
                        siteActive: modifiedActive,
                        siteAddress: modifiedAddress,

                    };
                })
                setSite(sites)
            })
    }, [])


    return (
        <div className="sitetables">

            <div className="sitetable__btns">
                <Button navigateTo={'/home'} />
                <button
                    className='sitetable__btn'
                    style={{ backgroundColor: '#EE4B46', color: 'white' }}
                    onClick={() => navigate('/configurations/site/add-site')}
                ><Add />Add New Site</button>
            </div>

            <br /> <br/>

            <MaterialTable
                data={site}
                title="Sites"

                columns={
                    [
                        { title: 'Site ID', field: 'siteId' },
                        { title: 'Site Name ', field: 'siteName' },
                        {
                            title: 'Full Address', field: 'siteAddress', cellStyle: {
                                width: 380,
                                // minWidth: 350,

                            },
                            headerStyle: {
                                width: 350,
                                //minWidth: 350,

                            }
                        },
                        { title: 'Site Active', field: 'siteActive' },

                    ]
                }

                options={{
                    search: true,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    actionsColumnIndex: 0,
                    headerStyle: {
                        backgroundColor: '#EE4B46',
                        color: '#fff',
                        textAlign: 'center',
                    },
                    cellStyle: {
                        textAlign: 'center',
                    },
                }}

                actions={[
                    {
                        icon: 'remove_red_eye',
                        tooltip: 'View Site',
                        onClick: (event, rowData) => {
                            setTab('first')
                            navigate(`/configurations/site/${rowData.siteCode}`);
                            localStorage.setItem('ActiveKeySite', 'first')
                        },
                    },

                ]}

            ></MaterialTable>


        </div>
    );

}

export default SiteTable;