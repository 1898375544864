import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useState } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FormControl from "@material-ui/core/FormControl";
import { useStyles } from "../../utils/ModalStyles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import DelegateTask from "./DelegateTask";
import CloseTask from "./CloseTask";

const TaskTable = ({ tasks, tab, clRole, setClRole,
  clMember, setClMember, clIsDue, setClIsDue,
  opRole, setOpRole, opMember, setOpMember,
  opIsDue, setOpIsDue, setChange }) => {

  const classes = useStyles();

  const [memberList, setMemberList] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [taskID, setTaskID] = useState("");
  const roles = ["Sales", "Operations", "Engineering", "Finance", "Director"];
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    getMemeberList()
  }, [])


  const getMemeberList = () => {

    axios
      .get(`${BASE_URL}/api/v1/user/getListOfUsers`, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((response) => {
        // console.log(response)
        setMemberList(response.data.userList);
      });

  };


  //reset filter
  const reset = () => {
    if (tab === "act") {
      setClRole("");
      setClMember("");
      setClIsDue("");
    } else {
      setOpRole("");
      setOpMember("");
      setOpIsDue("");
    }
  };


  return (
    <div>

      <br />
      {tab === "mot" && (
        <MaterialTable
          data={tasks}
          title="My Open Tasks"
          columns={[
            {
              title: "Task Title",
              field: "taskTitle",
              cellStyle: {
                width: 180
              }
            },
            {
              title: "Task Description",
              render: (rowData) => {
                let arr = rowData?.taskDescription.split(' ')
                return arr.map((val) => {
                  if (val.includes('LI-')) {
                    return <a href={`/individuallead/${val}`}>{`${val} `}</a>
                  } else {
                    return <span>{`${val} `}</span>
                  }
                })

              },
              cellStyle: {
                width: 250
              }
            },
            { title: "Task Type", field: "taskType" },
            { title: "Task Category", field: "taskCategory" },
            { title: "Department", field: "taskRole" },
            { title: "Status", field: "taskStatus" },
            {
              title: "Due Date",
              render: (rowData) => <span style={{ color: rowData.taskDue === true ? "red" : "green" }}>{!rowData.dueDate ? "" : rowData.dueDate.substring(8, 10) + "-" + rowData.dueDate.substring(5, 7) + "-" + rowData.dueDate.substring(0, 4)}</span>,
              customSort: (a, b) => (a.dueDate < b.dueDate ? -1 : 1),
            },
            { title: "Name", field: "userFullName" },
            {
              title: "Created At",
              render: (rowData) => <span style={{ color: rowData.createdAt === true ? "red" : "green" }}>{!rowData.createdAt ? "" : rowData.createdAt.substring(8, 10) + "-" + rowData.createdAt.substring(5, 7) + "-" + rowData.createdAt.substring(0, 4)}</span>,
              customSort: (a, b) => (a.createdAt < b.createdAt ? -1 : 1),
              defaultSort: "asc"
            }
          ]}

          options={{
            search: true,
            searchFieldStyle: {
              width: 120,
            },
            paging: true,
            pageSize: 20,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 20, 30],
            headerStyle: {
              backgroundColor: "#EE4B46",
              color: "#fff",
            },
          }}
          actions={[
            (rowData) => ({
              icon: "close",
              tooltip: "Close Task",
              onClick: (event, rowData) => {
                setOpen2(true);
                setTaskID(rowData.taskID);
              },
              hidden: rowData.taskCategory !== "Manual" ? true : false,
            }),
            {
              icon: ArrowDownwardIcon,
              tooltip: "Delegate Task",
              onClick: (event, rowData) => {
                setOpen(true);
                setTaskID(rowData.taskID);
              },
            },
          ]}
        ></MaterialTable>
      )
      }

      {/* show list of my closed tasks  */}
      {
        tab === "mct" && (
          <MaterialTable
            data={tasks}
            title="My Closed Tasks"
            columns={[
              {
                title: "Task Title",
                field: 'taskTitle',
                width: "100px"
              },
              {
                title: "Task Description",
                render: (rowData) => {
                  let arr = rowData?.taskDescription.split(' ')
                  return arr.map((val) => {
                    if (val.includes('LI-')) {
                      return <a href={`/individuallead/${val}`}>{`${val} `}</a>
                    } else {
                      return <span>{`${val} `}</span>
                    }
                  })

                },
                cellStyle: {
                  width: "20%",
                },
              },
              { title: "Task Type", field: "taskType" },
              { title: "Task Category", field: "taskCategory" },
              { title: "Closed Reason", field: "closedReason" },

              {
                title: "Closed Date",
                render: (rowData) => (!rowData.closedDate ? "" : rowData.closedDate.substring(8, 10) + "-" + rowData.closedDate.substring(5, 7) + "-" + rowData.closedDate.substring(0, 4)),
                customSort: (a, b) => (a.closedDate < b.closedDate ? -1 : 1),
              },
              { title: "Name", field: "userFullName" },
              { title: "Closed By", field: "closedByUserFullName" },
            ]}
            options={{
              search: true,
              paging: true,
              searchFieldStyle: {
                width: 120,
              },
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 20, 30],
              headerStyle: {
                backgroundColor: "#EE4B46",
                color: "#fff",
              },
            }}
          ></MaterialTable>
        )
      }


      {/* show list of all open task  */}

      {
        tab === "aot" && (
          <>
          <h4>All Open Tasks</h4>
          <MaterialTable
            data={tasks}
            title=""
            columns={[
              { title: "Task Title", field: "taskTitle" },
              {
                title: "Task Description",
                render: (rowData) => {
                  let arr = rowData?.taskDescription.split(' ')
                  return arr.map((val) => {
                    if (val.includes('LI-')) {
                      return <a href={`/individuallead/${val}`}>{`${val} `}</a>
                    } else {
                      return <span>{`${val} `}</span>
                    }
                  })

                },
                cellStyle: {
                  width: "25%",
                },
              },
              {
                title: "Task Type",
                field: "taskType",
                cellStyle: {
                  width: "20%",
                },
              },
              { title: "Task Category", field: "taskCategory" },
              { title: "Department", field: "taskRole" },
              { title: "Status", field: "taskStatus" },
              {
                title: "Due Date",
                render: (rowData) => <span style={{ color: rowData.taskDue === true ? "red" : "green" }}>{!rowData.dueDate ? "" : rowData.dueDate.substring(8, 10) + "-" + rowData.dueDate.substring(5, 7) + "-" + rowData.dueDate.substring(0, 4)}</span>,
                customSort: (a, b) => (a.dueDate < b.dueDate ? -1 : 1),
              },
              { title: "Name", field: "userFullName" },
              {
                title: "Created At",
                render: (rowData) => <span style={{ color: rowData.createdAt === true ? "red" : "green" }}>{!rowData.createdAt ? "" : rowData.createdAt.substring(8, 10) + "-" + rowData.createdAt.substring(5, 7) + "-" + rowData.createdAt.substring(0, 4)}</span>,
                customSort: (a, b) => (a.createdAt < b.createdAt ? -1 : 1),
                defaultSort: "asc"
              }

            ]}
            options={{
              search: window.innerWidth < 700 ? false : true,
              searchFieldStyle: {
                width: 150,
              },
              paging: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 20, 30],
              headerStyle: {
                backgroundColor: "#EE4B46",
                color: "#fff",
              },
            }}
            actions={[
              (rowData) => ({
                icon: "close",
                tooltip: "Close Task",
                onClick: (event, rowData) => {
                  setOpen2(true);
                  setTaskID(rowData.taskID);
                },
                hidden: rowData.taskCategory !== "Manual" ? true : false,
              }),
              {
                icon: ArrowDownwardIcon,
                tooltip: "Delegate Task",
                onClick: (event, rowData) => {
                  setOpen(true);
                  setTaskID(rowData.taskID);
                },
              },
            ]}
            components={{
              Toolbar: (props) => (
                <div className="filters text-left">
                  <MTableToolbar {...props} />
                 
                  <FormControl className="formControl" style={{ marginTop:  '-50px', width: '150px' }}>
                    <InputLabel id="demo-simple-select-helper-label">Task Role</InputLabel>
                    <Select
                      value={opRole}
                      onChange={(e) => setOpRole(e.target.value)}
                      className="selectEmpty"
                      inputProps={{ "aria-label": "Without label" }}>
                      <MenuItem value="">Task Role</MenuItem>
                      {roles.map((s, i) => (
                        <MenuItem key={i} value={s}>
                          {s}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className="formControl" style={{ marginTop:  '-50px', width: '150px' }}>
                    <InputLabel id="demo-simple-select-helper-label">Team Member</InputLabel>
                    <Select
                      value={opMember}
                      onChange={(e) => setOpMember(e.target.value)}
                      className="selectEmpty"
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Team Member</MenuItem>
                      {memberList.map((m, i) => {
                        return (
                          <MenuItem key={i} value={m.userName}>
                            {m.userFullName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  {window.innerWidth < 1000 && <br />}

                  <FormControl className="formControl" style={{ marginTop: window.innerWidth < 1000 ? '-10px' : '-50px', width: "150px" }}>
                    <InputLabel id="demo-simple-select-helper-label">Is Due</InputLabel>
                    <Select
                      value={opIsDue}
                      onChange={(e) => setOpIsDue(e.target.value)}
                      className="selectEmpty"
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Is Due</MenuItem>
                      <MenuItem value="true">true</MenuItem>
                      <MenuItem value="false">false</MenuItem>
                    </Select>
                  </FormControl>



                  <FormControl className="formControl" style={{ marginTop: window.innerWidth < 1000 ? '5px' : '-40px' }}>
                    <button
                      onClick={reset}
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid black",
                        borderRadius: "20px",
                        padding: "2px",
                      }}
                    >
                      Reset Filter
                    </button>
                  </FormControl>
                </div>
              ),
            }}
          ></MaterialTable>
          </>
        )
      }


      {/* show list of all closed tasks with filtering*/}
      {
        tab === "act" && (

          <>
            <h4>All Closed Tasks</h4>

            <MaterialTable
              data={tasks}
              title=""
              columns={[
                { title: "Task Title", field: "taskTitle" },
                {
                  title: "Task Description",
                  render: (rowData) => {
                    let arr = rowData?.taskDescription.split(' ')
                    return arr.map((val) => {
                      if (val.includes('LI-')) {
                        return <a href={`/individuallead/${val}`}>{`${val} `}</a>
                      } else {
                        return <span>{`${val} `}</span>
                      }
                    })

                  },
                  cellStyle: {
                    width: "25%",
                  },
                },
                { title: "Task Type", field: "taskType" },
                { title: "Task Category", field: "taskCategory" },
                { title: "Closed Reason", field: "closedReason" },

                {
                  title: "Closed Date",
                  render: (rowData) => (!rowData.closedDate ? "" : rowData.closedDate.substring(8, 10) + "-" + rowData.closedDate.substring(5, 7) + "-" + rowData.closedDate.substring(0, 4)),
                  customSort: (a, b) => (a.closedDate < b.closedDate ? -1 : 1),
                },
                { title: "Name", field: "userFullName" },
                { title: "Closed By", field: "closedByUserFullName" },
              ]}
              options={{
                search: window.innerWidth < 700 ? false : true,
                searchFieldStyle: {
                  width: 150
                },
                paging: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 20, 30],
                headerStyle: {
                  backgroundColor: "#EE4B46",
                  color: "#fff",
                },
              }}
              components={{
                Toolbar: (props) => (
                  <div className="filters text-left">
                    <MTableToolbar {...props} />
                    <FormControl className="formControl" style={{ marginTop: '-50px', width: '150px' }}>
                      <InputLabel id="demo-simple-select-helper-label">Task Role</InputLabel>
                      <Select
                        value={clRole}
                        onChange={(e) => setClRole(e.target.value)}
                        className="selectEmpty"
                        inputProps={{ "aria-label": "Without label" }}>
                        <MenuItem value="">Task Role</MenuItem>
                        {roles.map((s, i) => (
                          <MenuItem key={i} value={s}>
                            {s}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl className="formControl" style={{ marginTop: '-50px', width: '150px' }}>
                      <InputLabel id="demo-simple-select-helper-label">Team Member</InputLabel>
                      <Select
                        value={clMember}
                        onChange={(e) => setClMember(e.target.value)}
                        className="selectEmpty"
                        inputProps={{ "aria-label": "Without label" }} >
                        <MenuItem value="">Team Member</MenuItem>
                        {memberList.map((m, i) => {
                          return (
                            <MenuItem key={i} value={m.userName}>
                              {m.userFullName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    {window.innerWidth < 1000 && <br />}

                    <FormControl className="formControl" style={{ marginTop: window.innerWidth < 1000 ? '-10px' : '-50px', width: '150px' }}>
                      <InputLabel id="demo-simple-select-helper-label">Is Due</InputLabel>
                      <Select
                        value={clIsDue}
                        onChange={(e) => setClIsDue(e.target.value)}
                        className="selectEmpty"
                        inputProps={{ "aria-label": "Without label" }}>
                        <MenuItem value="">Is Due</MenuItem>
                        <MenuItem value="true">true</MenuItem>
                        <MenuItem value="false">false</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl className="formControl" style={{ marginTop: window.innerWidth < 1000 ? '5px' : '-40px', width: '120px' }}>
                      <button
                        onClick={reset}
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "20px",
                          padding: "2px",
                        }}
                      >
                        Reset Filter
                      </button>
                    </FormControl>
                  </div>
                ),
              }}
            ></MaterialTable>
          </>
        )
      }
      <div>
        <DelegateTask open={open} setOpen={setOpen} taskID={taskID} setChange={setChange} />
      </div>
      <div>
        <CloseTask open2={open2} setOpen2={setOpen2} taskID={taskID} setChange={setChange} />
      </div>
    </div >
  );
};

export default TaskTable;
