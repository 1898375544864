import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Stack, Switch, Typography } from "@mui/material";
import FixedLegalCharges from "../../components/FixedLegalCharges/FixedLegalCharges";
import PercentageLegalCharges from "../../components/PercentageLegalCharges/PercentageLegalCharges";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import { useParams } from "react-router";


const LegalCharges = () => {
  const { siteCode } = useParams();
  const [isFixed, setIsFixed] = useState();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + userInfo.token;

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` }
        })
      .then((response) => {
        // console.log(response.data.site.legalChargeType);
        if (response.data.site.legalChargeType === "Fixed") {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      }
      );
  }, []);

  const handleSwitch = () => {
    setIsFixed(!isFixed);
    // console.log(!isFixed);
  };

  const handleChange = () => {
    if (isFixed === true) {
      axios
        .put(
          `${BASE_URL}/api/v1/Configuration/site/updateSiteBySiteCode`,
          {
            siteCode,
            siteUpdate: {
              legalChargeType: "Fixed",
            }
          },
          {
            headers: { Authorization: Token },
          }
        )
        .then((response) => {
          // console.log(response.data);
        })
    }
    else {
      axios
        .put(
          `${BASE_URL}/api/v1/Configuration/site/updateSiteBySiteCode`,
          {
            siteCode,
            siteUpdate: {
              legalChargeType: "Percentage",
            }
          },
          {
            headers: { Authorization: Token },
          }
        )
        .then((response) => {
          // console.log(response.data);
        });
    }
  };

  return (
    <div>
      <div className="row ml-3">
        <h4>Legal Charges</h4>
        <Form.Group className="ml-5">
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6" component="body1">
              Percentage
            </Typography>
            <Switch
              checked={isFixed}
              onClick={handleSwitch}
              onChange={handleChange} />
            <Typography variant="h6" component="body1">
              Fixed
            </Typography>
          </Stack>
        </Form.Group>
      </div>

      {isFixed ? <FixedLegalCharges /> : <PercentageLegalCharges />}
    </div>
  );
};

export default LegalCharges;
