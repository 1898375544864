import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Swal from "sweetalert2"
import { BASE_URL } from "../../utils/config/url";
import MaterialTable from "material-table";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { Add } from "@material-ui/icons";
import "./BankTable.scss";
import { Context } from "../../utils/Context";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useStyles } from "../../utils/ModalStyles";
import Button from "../../components/Button/Button";

const BankTable = () => {
  const classes = useStyles();

  const { setTab } = useContext(Context);
  const [bank, setBank] = useState([]);
  const [isChange, setIsChange] = useState(false)
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + userInfo.token;
  const navigate = useNavigate();
  const [isChanges, setIsChanges] = useState(false);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/FinanceMgmt/Bank/getListOfBank?isActive=true`, {
        headers: { Authorization: Token },
      })
      .then((response) => {

        const banks = response?.data?.banks?.map((bank) => {
          const {
            bankName,
            ifscCode,
            branchName,
            rateOfInterest,
            rateOfInterestWomen,
            rateOfInterestSenior,
            updatedAt,
          } = bank;
          const date = new Date(updatedAt);
          const fullDate = date.toISOString().slice(0, 10);

          return {
            bankName,
            ifscCode,
            branchName,
            rateOfInterest,
            rateOfInterestWomen,
            rateOfInterestSenior,
            updatedAt,
            fullDate,
          };
        });
        setBank(banks);
      });

  }, [isChange]);

  // delete bank
  const handleDelete = (ifscCode) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${BASE_URL}/api/v1/FinanceMgmt/bank/deleteBankByIFSCCode/${ifscCode}`, {
            headers: { Authorization: Token },
          })
          .then((response) => {
            console.log(response);
            const errorMessage = response.data.message
            if (response.data.status === true) {
              setIsChange(!isChange)
              Swal.fire(
                'Deleted!',
                `${errorMessage}`,
                'success'
              )
            } else {
              Swal.fire(
                'Not Deleted!',
                `${errorMessage}`,
                'error'
              )
            }
          });

      }
    })


  }

  return (
    <div className="banktable">
      <div className="banktable__btns">
        <Button navigateTo={'/home'}/>
        <button
          style={{ backgroundColor: "#EE4B46", color: "white" }}
          onClick={() => navigate("/addloanbank")}
        >
          <Add />
          Add Bank
        </button>
      </div>
      <div className="banktable__table">
        <MaterialTable
          data={bank}
          title="Banks"
          actions={[
            {
              icon: "delete",
              tooltip: "Delete Bank",
              onClick: (event, rowData) => {
                console.log(rowData);
                handleDelete(rowData.ifscCode);
              },
            },
            {
              icon: "remove_red_eye",
              tooltip: "View Bank",
              onClick: (event, rowData) => {
                navigate(`/individuabank/${rowData.ifscCode}`);
              },
            },

          ]}
          columns={[
            { title: "Bank Name", field: "bankName" },
            { title: "IFS Code", field: "ifscCode" },
            { title: "Branch Name", field: "branchName" },
            { title: "ROI", field: "rateOfInterest" },
            { title: "ROI Women", field: "rateOfInterestWomen" },

            { title: "Updated At", field: "fullDate" },
          ]}
          options={{
            search: true,
            actionsColumnIndex: 0,
            paging: true,
            pageSize: 20,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 20, 30],
            headerStyle: {
              backgroundColor: "#EE4B46",
              color: "#fff",
            },
          }}
        ></MaterialTable>
      </div>
    </div>
  );
};

export default BankTable;
