import { Switch, Paper } from '@mui/material'
import React from 'react'
import Swal from 'sweetalert2';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { Modal, Fade } from "@mui/material";
import { MoonLoader } from "react-spinners";

const StampPaperProcurement = ({ unitHandover, setUnitHandover }) => {

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const [loader, setLoader] = React.useState(false);
    const [modal, setModal] = React.useState(false);

    const stampPaperProcurementHandler = () => {

        setModal(false);

        if (userInfo?.userRole != "Director" && userInfo?.userRole != "Operations") {
            return Swal.fire({
                icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
                text: "Only Director or Operations Department can perform this action",
            })
        }

        setLoader(true);

        axios
            .put(
                `${BASE_URL}/api/v1/CustomerMgmt/handover/stampPaperProcurement`,
                {
                    handoverId: unitHandover?.handoverId,
                    operationsUserName: userInfo.userName,
                    operationsUserFullName: userInfo.userFullName,
                    operationsEmpId: userInfo.employeeId,
                },
                {
                    headers: { Authorization: `bearer ${userInfo.token}` },
                }
            )
            .then((response) => {
                setLoader(false);
                if (response.data.status) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                    }).then(function () {
                        setUnitHandover(response.data.handover);
                    });
                }
            })
            .catch((err) => {
                setLoader(false);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error,
                });
            });

    }

    const dateFormatting = (input) => {

        if (!input) {
            return 'N/A'
        }
        // console.log(input)
        let d = new Date(input)
        // console.log(d)

        let date = d.getDate()
        if (date < 10) {
            date = `0${date}`
        }
        let m = d.getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = d.getFullYear()

        let final;

        final = `${date}/${m}/${y}`
        return final

    }


    return (
        <>
            <Paper elevation={1}
                style={{
                    padding: '30px 20px',
                    width: '90%',
                    margin: 'auto'
                }}>
                <div className="row mb-3 mx-2 card-1">
                    <div className="col-12 tab-card pt-3 pb-3 text-center">
                        {!unitHandover?.stampPaperProcurement?.isProcured ? (
                            (userInfo.userRole !== "Operations" &&
                                userInfo.userRole !== "Director") ? (
                                <h5
                                    style={{
                                        paddingRight: "10px",
                                        marginRight: "5px",
                                        fontSize: "22px",
                                        fontWeight: "bold",
                                        paddingTop: "5px",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    Stamp Paper Procurement Pending
                                </h5>
                            ) : (
                                <>
                                    <div className='row justify-content-center'>
                                        <h4
                                            style={{
                                                paddingRight: "10px",
                                                marginRight: "5px",
                                                fontSize: "22px",
                                                fontWeight: "bold",
                                                paddingTop: "5px",
                                                paddingLeft: "10px",
                                            }}
                                        >
                                            Stamp Paper Procurement
                                        </h4>

                                        <div className='col-5 text-center'>
                                            <Switch
                                                checked={unitHandover?.stampPaperProcurement?.isProcured || false}
                                                color="primary"
                                                disabled={unitHandover?.stampPaperProcurement?.isProcured}
                                                onChange={() => setModal(true)}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        ) : (
                            <>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <p
                                        style={{
                                            paddingRight: "10px",
                                            marginRight: "5px",
                                            fontSize: "25px",
                                            fontWeight: "bold",
                                            paddingTop: "5px",
                                            paddingLeft: "10px",
                                        }}
                                    >
                                        Stamp Paper Procurement
                                    </p>
                                    <br />
                                    <div>
                                        <p>Procurement Date:{" "}
                                            <b>{dateFormatting(unitHandover?.stampPaperProcurement?.procurementDate)}</b> </p>
                                        <p>Procured By :{" "}<b>{unitHandover?.stampPaperProcurement?.operationsUserFullName}</b></p>

                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Paper>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={modal}
                onClose={() => {
                    setModal(false)
                }}
                closeAfterTransition
            >
                <Fade in={modal}>
                    <div className="paper">

                        <h3 className='text-center'>You want to Mark Stamp Procurement Done</h3>

                        <br />

                        <div className="row justify-content-center">
                            <div className="col-6 text-right">
                                <button className="modal__btns"
                                    type='click'
                                    onClick={stampPaperProcurementHandler}
                                >
                                    Yes
                                </button>
                            </div>
                            <div className="col-6 text-left">
                                <button
                                    className="modal__btns"
                                    onClick={() => {
                                        setModal(false)
                                    }}
                                    style={{
                                        backgroundColor: "white",
                                        color: "black",
                                    }}
                                >
                                    No
                                </button>
                            </div>
                        </div>

                    </div>
                </Fade>
            </Modal>

            {/* //////////// Modal for spinner //////////// */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={loader}
                onClose={() => {
                    setLoader(false);
                }}
                closeAfterTransition
            >
                <Fade in={loader}>
                    <div>
                        <MoonLoader
                            color="red"
                            className="spinner"
                            loading={loader}
                            size={70}
                        />
                    </div>
                </Fade>
            </Modal>

        </>
    )
}

export default StampPaperProcurement