import { Switch, Paper } from "@mui/material";
import React from "react";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import { Modal, Fade } from "@mui/material";
import { MoonLoader } from "react-spinners";

const DemandPaymentClearance = ({ unitHandover, setUnitHandover }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loader, setLoader] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [finalDDAmount, setFinalDDAmount] = React.useState(0);
  const [error, setError] = React.useState("");
  const [error2, setError2] = React.useState("");
  const [ddDetails, setDdDetails] = React.useState({
    demandDraftNo: "",
    demandDraftAccount: "",
    demandDraftMICR: "",
    demandDraftDate: "",
    ddBankName: "",
    file: "",
  });

  const financeClearanceHandler = (e) => {
    e.preventDefault();

    setModal(false);

    // if (userInfo?.userRole !== "Finance") {
    //   return Swal.fire({
    //     icon: "error",
    //     title: "Error",
    //     showClass: {
    //       popup: "animate__animated animate__fadeInDown",
    //     },
    //     hideClass: {
    //       popup: "animate__animated animate__fadeOutUp",
    //     },
    //     text: "Only Finance Department can perform this action",
    //   });
    // }

    setLoader(true);

    let formData = new FormData();
    formData.append("handoverId", unitHandover.handoverId);
    formData.append("isPaid", true);
    formData.append("finalDDAmount", finalDDAmount);
    formData.append("financeUserName", userInfo.userName);
    formData.append("financeUserFullName", userInfo.userFullName);
    formData.append("financeEmpId", userInfo.employeeId);

    if (unitHandover?.isFinalPaymentByDD) {
      for (let key in ddDetails) {
        if (ddDetails[key] === "") continue;
        formData.append(key, ddDetails[key]);
      }
    }

    axios
      .put(
        `${BASE_URL}/api/v1/CustomerMgmt/handover/financeClearance`,
        formData,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setUnitHandover(response.data.handover);
            window.location.reload();
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  // console.log(unitHandover);

  const dateFormatting = (input) => {
    if (!input) {
      return "N/A";
    }
    // console.log(input)
    let d = new Date(input);
    // console.log(d)

    let date = d.getDate();
    if (date < 10) {
      date = `0${date}`;
    }
    let m = d.getMonth() + 1;
    if (m < 10) {
      m = `0${m}`;
    }
    let y = d.getFullYear();

    let final;

    final = `${date}/${m}/${y}`;
    return final;
  };

  return (
    <>
      <Paper
        elevation={1}
        style={{
          padding: "30px 20px",
          width: "90%",
          margin: "auto",
        }}
      >
        <div className="row mb-3 mx-2 card-1">
          <div className="col-12 tab-card pt-3 pb-3 text-center">
            {!unitHandover?.demandPaymentConfirmation?.isPaid ? (
              userInfo.userRole !== "Finance" &&
              userInfo.userRole !== "Director" ? (
                <h5
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Demand Payment Clearance Pending
                </h5>
              ) : (
                <>
                  <h4
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Demand Payment Clearance
                  </h4>
                  {unitHandover?.isFinalPaymentByDD ? (
                    <>
                      <div className="row justify-content-center mt-4">
                        <div className="col-5">
                          <label>Final DD Amount</label>
                          <input
                            type="number"
                            required
                            className="form-control"
                            value={finalDDAmount}
                            onChange={(e) => setFinalDDAmount(e.target.value)}
                          />
                        </div>

                        <div className="col-5 mt-3">
                          <button
                            className="unit-handover__btns"
                            disabled={userInfo.userRole !== "Finance"}
                            onClick={(e) => {
                              if (unitHandover?.isFinalPaymentByDD === true)
                                setModal(true);
                            }}
                            style={{
                              backgroundColor:
                                userInfo.userRole !== "Finance"
                                  ? "grey"
                                  : "E46C5E",
                              cursor:
                                userInfo.userRole !== "Finance"
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row justify-content-center mt-4">
                        <div className="col-5">
                          <label>Final DD Amount</label>
                          <input
                            type="number"
                            required
                            className="form-control"
                            value={finalDDAmount}
                            readOnly
                          />
                        </div>

                        <div className="col-5 mt-3">
                          <button
                            className="unit-handover__btns"
                            disabled={userInfo.userRole !== "Finance"}
                            onClick={(e) => financeClearanceHandler(e)}
                            style={{
                              backgroundColor:
                                userInfo.userRole !== "Finance"
                                  ? "grey"
                                  : "E46C5E",
                              cursor:
                                userInfo.userRole !== "Finance"
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )
            ) : (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      paddingRight: "10px",
                      marginRight: "5px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      paddingTop: "5px",
                      paddingLeft: "10px",
                    }}
                  >
                    Demand Payment Clearance
                  </p>
                  <br />
                  <div>
                    {unitHandover?.isFinalPaymentByDD ? (
                      <>
                        <p>
                          Demand Draft Scan:{" "}
                          <b>
                            <a href={unitHandover?.ddDetails?.ddScanLink}>
                              Document
                            </a>
                          </b>
                        </p>
                        <p>
                          Demand Draft No:{" "}
                          <b>{unitHandover?.ddDetails?.demandDraftNo}</b>
                        </p>
                        <p>
                          Demand Draft MICR:{" "}
                          <b>{unitHandover?.ddDetails?.demandDraftMICR}</b>
                        </p>
                        <p>
                          {" "}
                          Demand Draft Amount:{" "}
                          <b>{unitHandover?.ddDetails?.ddAmount}</b>
                        </p>
                        <p>
                          Date:{" "}
                          <b>
                            {dateFormatting(
                              unitHandover?.ddDetails?.demandDraftDate
                            )}
                          </b>{" "}
                        </p>
                        <p>
                          {" "}
                          Bank Name:{" "}
                          <b>{unitHandover?.ddDetails?.ddBankName}</b>
                        </p>
                        <p>
                          Clearance Confirmed By:{" "}
                          <b>
                            {
                              unitHandover?.demandPaymentConfirmation
                                ?.financeUserFullName
                            }
                          </b>
                        </p>
                      </>
                    ) : (
                      <>
                        <p>
                          {" "}
                          Final DD Amount:{" "}
                          <b>{unitHandover?.demandPaymentConfirmation?.finalDDAmount}</b>
                        </p>
                        <p>
                          Clearance Confirmed By:{" "}
                          <b>
                            {
                              unitHandover?.demandPaymentConfirmation
                                ?.financeUserFullName
                            }
                          </b>
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Paper>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={() => {
          setModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={modal}>
          <form
            className="paper"
            onSubmit={(e) => {
              financeClearanceHandler(e);
            }}
          >
            <h3 className="text-center">
              Please Provide the following Details
            </h3>
            <br />

            <div className="row justify-content-center">
              <div className="col-6">
                <label>DD A/C</label>
                <input
                  type="number"
                  required
                  className="form-control"
                  value={ddDetails?.demandDraftAccount}
                  onChange={(e) => {
                    setDdDetails((prev) => {
                      return { ...prev, demandDraftAccount: e.target.value };
                    });
                  }}
                />
              </div>
              <div className="col-6">
                <label>Demand Draft No.</label>
                <input
                  type="number"
                  required
                  className="form-control"
                  value={ddDetails?.demandDraftNo}
                  onChange={(e) => {
                    if (e.target.value.length !== 6) {
                      setError("Demaand Draft No. must be of 6 digits");
                      setDdDetails((prev) => {
                        return { ...prev, demandDraftNo: e.target.value };
                      });
                    } else {
                      setError("");
                      setDdDetails((prev) => {
                        return { ...prev, demandDraftNo: e.target.value };
                      });
                    }
                  }}
                />
                <p style={{ color: "#EE4B46", paddingTop: "5px" }}>
                  <em>{error}</em>
                </p>
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              <div className="col-6">
                <label>Demand Draft MICR</label>
                <input
                  type="number"
                  required
                  className="form-control"
                  value={ddDetails?.demandDraftMICR}
                  onChange={(e) => {
                    if (e.target.value.length !== 9) {
                      setError2("Demaand Draft MICR must be of 9 digits");
                      setDdDetails((prev) => {
                        return { ...prev, demandDraftMICR: e.target.value };
                      });
                    } else {
                      setError2("");
                      setDdDetails((prev) => {
                        return { ...prev, demandDraftMICR: e.target.value };
                      });
                    }
                  }}
                />
                <p style={{ color: "#EE4B46", paddingTop: "5px" }}>
                  <em>{error2}</em>
                </p>
              </div>

              <div className="col-6">
                <label>Demand Draft Date</label>
                <input
                  type="date"
                  required
                  className="form-control"
                  value={ddDetails?.demandDraftDate}
                  onChange={(e) => {
                    setDdDetails((prev) => {
                      return { ...prev, demandDraftDate: e.target.value };
                    });
                  }}
                />
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              <div className="col-9">
                <label>Demand Draft Scan</label>
                <input
                  type="file"
                  required
                  className="form-control"
                  onChange={(e) => {
                    setDdDetails((prev) => {
                      return { ...prev, file: e.target.files[0] };
                    });
                  }}
                />
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              <div className="col-6">
                <label>Bank Name</label>
                <input
                  type="text"
                  required
                  className="form-control"
                  value={ddDetails?.ddBankName}
                  onChange={(e) => {
                    setDdDetails((prev) => {
                      return { ...prev, ddBankName: e.target.value };
                    });
                  }}
                />
              </div>
              <div className="col-6">
                <label>DD Amount</label>
                <input
                  type="number"
                  required
                  className="form-control"
                  value={finalDDAmount}
                  readOnly
                />
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              <div className="col-6 text-right">
                <button className="modal__btns" type="submit">
                  Yes
                </button>
              </div>
              <div className="col-6 text-left">
                <button
                  className="modal__btns"
                  onClick={(e) => {
                    e.preventDefault();
                    setModal(false);
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </form>
        </Fade>
      </Modal>

      {/* //////////// Modal for spinner //////////// */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={loader}
        onClose={() => {
          setLoader(false);
        }}
        closeAfterTransition
      >
        <Fade in={loader}>
          <div>
            <MoonLoader
              color="red"
              className="spinner"
              loading={loader}
              size={70}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default DemandPaymentClearance;
