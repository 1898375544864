import React, { useState, useEffect } from "react";
import bhk1 from "../../../assets/tableView/bhk-1.png";
import bhk2 from "../../../assets/tableView/bhk-2.png";
import bhk3 from "../../../assets/tableView/bhk-3.png";
import rightArrow from "../../../assets/tableView/right-arrow-2.png";
import "./UnitFinder.scss";
import { BASE_URL } from "../../../utils/config/url";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Back from "../Back/Back";

export default function UnitFinder() {

  const site = JSON.parse(localStorage.getItem('site'))
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const navigate = useNavigate()

  const [selectedPhase, setSelectedPhase] = useState("");
  const [selectedBhk, setSelectedBhk] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const [bhk, setBhk] = useState([])
  const [types, setTypes] = useState([])


  useEffect(() => {

    // console.log(selectedPhase)

    if (selectedPhase === "") return

    axios.get(`${BASE_URL}/api/v1/configuration/site/getBHKTypes?siteCode=${site?.siteCode}&phaseCode=${selectedPhase}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`
        }
      })
      .then((response) => {
        if (response.data.status) {
          setBhk(response.data.bhkTypes)
        } else {
          setBhk([])
        }
      })
      .catch((err) => {
        setBhk([])
      })
  }, [selectedPhase])

  useEffect(() => {

    // console.log(selectedBhk)
    if (selectedBhk === "") return

    axios.get(`${BASE_URL}/api/v1/configuration/unitType/getListOfUnitType?siteCode=${site?.siteCode}&phaseCode=${selectedPhase}&bhk=${selectedBhk}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`
        }
      })
      .then((response) => {
        if (response.data.status) {
          setTypes(response.data.unitTypes)
        } else {
          setTypes([])
        }
      })
      .catch((err) => {
        setTypes([])
      })
  }, [selectedBhk])

  return (
    <div className="unitFinder">

  {/*<Back navigateTo={`/tabletView/dashboard/${site?.siteCode}`} /> */}

      <h3 className="unitFinder__heading">Unit Finder</h3>

      <div className="unitFinder__phases">
        <span className="unitFinder__phases__heading">Phases</span>

        <div className="unitFinder__phases__options">
          {site?.phases?.map((phase) => (
            <div
              key={phase?.phaseCode}
              className={`unitFinder__phases__option ${selectedPhase === phase?.phaseCode
                ? "unitFinder__phases__option--active"
                : ""
                }`}
              onClick={() => {
                // console.log(phase?.phaseCode)
                setSelectedPhase(phase?.phaseCode)
              }}
            >
              <span>{phase?.phaseName}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="unitFinder__bhk">
        <span className="unitFinder__bhk__heading">BHK</span>

        <div className="unitFinder__bhk__options">

          {
            bhk.length === 0
              ?
              <>
                <div className="unitFinder__bhk__option">
                  <img src={bhk1} alt="" />
                  <span>1 BHK</span>
                </div>

                <div className="unitFinder__bhk__option">
                  <img src={bhk2} alt="" />
                  <span>2 BHK</span>
                </div>

                <div className="unitFinder__bhk__option">
                  <img src={bhk3} alt="" />
                  <span>3 BHK</span>
                </div>

                <div className="unitFinder__bhk__option">
                  <img src={bhk1} alt="" />
                  <span>4 BHK</span>
                </div>

                <div className="unitFinder__bhk__option">
                  <img src={bhk2} alt="" />
                  <span>Duplex</span>
                </div>
              </>
              :

              bhk?.map((bhk, index) => (
                <div
                  key={index}
                  className={`unitFinder__bhk__option ${selectedBhk === bhk ? "unitFinder__bhk__option--active" : ""
                    }`}
                  onClick={() => setSelectedBhk(bhk)}
                >
                  <img src={bhk === '1BHK' ?
                    bhk1 : bhk === '2BHK' ?
                      bhk2 : bhk === '3BHK' ?
                        bhk3 : bhk === '4BHK' ?
                          bhk1 : bhk === 'Duplex' ?
                            bhk2 : bhk1
                  } alt="" />
                  <span>{bhk}</span>
                </div>
              ))

          }

        </div>
      </div>

      <div className="unitFinder__type">
        <span className="unitFinder__type__heading">Type</span>

        <div className="unitFinder__type__options">
          {types?.map((type, index) => (
            <div
              key={index}
              className={`unitFinder__type__option ${selectedType === type?.unitTypeName ? "unitFinder__type__option--active" : ""
                }`}
              onClick={() => setSelectedType(type?.unitTypeName)}
            >
              <span>{type?.unitTypeName}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="unitFinder__searchBtn">
        <button
          disabled={selectedPhase === "" || selectedBhk === "" || selectedType === ""}
          style={{
            backgroundColor: (selectedPhase === "" || selectedBhk === "" || selectedType === "") ? 'grey' : ''
          }}
          onClick={() => {
            navigate(`/tabletView/dashboard/${site?.siteCode}/unitFinder/${selectedType}`)
          }}
        >
          Search <img src={rightArrow} alt="" />
        </button>
      </div>
    </div>
  );
}
