import React, { useEffect, useState } from "react";
import { Switch, createMuiTheme, ThemeProvider } from "@material-ui/core";
import Swal from "sweetalert2";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useStyles } from "../../utils/ModalStyles";
import "./InvPaymentTerm.scss";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import { useParams } from "react-router-dom";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#EE4B46",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
});

const CheckList = ({ paymentTerms, setPaymentTerms }) => {
  // console.log(paymentTerms)

  const classes = useStyles();

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [serial, setSerial] = useState("");
  const [phaseHandOverCompleted, setPhaseHandOverCompleted] = useState("");
  const [checklist, setChecklist] = useState([]);
  const [addCheckListItem, setAddCheckListItem] = useState([]);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const id = useParams().paymentTermId;

  useEffect(() => {
    setPhaseHandOverCompleted(paymentTerms?.phaseHandOverCompleted);
  }, [paymentTerms]);

  useEffect(() => {
    getCommonFeatureChecklist();
  }, [paymentTerms]);

  const getCommonFeatureChecklist = () => {
    axios
      .get(
        `${BASE_URL}/api/v1/Configuration/PaymentTerms/getCommonFeatureChecklist/${id}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((res) => {
        console.log(res);
        setChecklist(res.data.commonFeatureChecklist || []);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const createCommonFeatureChecklist = () => {
    if (
      userInfo?.userRole != "Engineering" &&
      userInfo?.userRole != "Director"
    ) {
      return Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: "Only Engineering and Director can add checklist items",
      });
    }

    if (addCheckListItem?.length == 0) return;
    // console.log(checklist)
    let length = checklist.length + 1;
    let newList = addCheckListItem.map((item) => {
      item.serial = length;
      length++;
      return item;
    });
    let commonFeatureChecklist = [...checklist, ...newList];

    axios
      .post(
        `${BASE_URL}/api/v1/Configuration/PaymentTerms/createCommonFeatureChecklist`,
        {
          commonFeatureChecklist,
          paymentTermsId: id,
        },
        {
          headers: {
            Authorization: `bearer ${userInfo?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(() => {
            setPaymentTerms(response?.data?.PaymentTerm);
            setAddCheckListItem([]);
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const updateCommonFeatureChecklist = () => {
    if (
      userInfo?.userRole != "Engineering" &&
      userInfo?.userRole != "Director"
    ) {
      return Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: "Only Engineering and Director can add checklist items",
      });
    }

    if (checklist.length == 0) {
      return Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: "No checklist items found",
      });
    }

    axios
      .put(
        `${BASE_URL}/api/v1/Configuration/PaymentTerms/inspectCommonFeature`,
        {
          paymentTermsId: id,
          inspectedByUserName: userInfo.userName,
          inspectedByUserFullName: userInfo.userFullName,
          serial: serial,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setModal(false);
        //console.log(response)

        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(function () {
          setSerial("");
          getCommonFeatureChecklist();
        });
      })
      .catch((err) => {
        setModal(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const phaseReady = () => {
    axios
      .put(
        `${BASE_URL}/api/v1/Configuration/PaymentTerms/phaseReady`,
        {
          paymentTermsId: id,
          engineerSignOffUserName: userInfo.userName,
          engineerSignOffUserFullName: userInfo.userFullName,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setModal2(false);
        console.log(response);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(function () {
          getCommonFeatureChecklist();
          setPhaseHandOverCompleted(true);
        });
      })
      .catch((err) => {
        setModal2(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const dateFormatting = (input, type) => {
    let d = new Date(input);
    let date = d.getDate();
    if (date < 10) {
      date = `0${date}`;
    }
    let m = d.getMonth() + 1;
    if (m < 10) {
      m = `0${m}`;
    }
    let y = d.getFullYear();

    let final;

    if (type === "original") {
      final = `${y}-${m}-${date}`;
      return final;
    } else {
      final = `${date}/${m}/${y}`;
      return final;
    }
  };

  const addItem = () => {
    let length = addCheckListItem.length;
    setAddCheckListItem((prev) => {
      return [...prev, { serial: length + 1, description: "" }];
    });
  };

  const updateItem = (e, index) => {
    let list = addCheckListItem?.map((item) => {
      if (item.serial === index + 1) {
        return { ...item, description: e.target.value };
      } else {
        return item;
      }
    });
    setAddCheckListItem(list);
  };

  const deleteItem = (index) => {
    // console.log(index);
    let list = addCheckListItem.filter((item) => {
      return item.serial != index + 1;
    });

    list = list?.map((item, i) => {
      item.serial = i + 1;
      return item;
    });

    // console.log(list)

    setAddCheckListItem(list);
  };

  return (
    <div className="checklist">
      <br />
      <br />

      <div className="row justify-content-center">
        <div className="col-3">
          <label>Phase Handover Initiated</label>
          <input
            type="text"
            className="form-control"
            readOnly
            value={paymentTerms?.phaseHandOverStarted}
          />
        </div>

        <div className="col-3">
          <label>Phase Handover Initiated By</label>
          <input
            type="text"
            className="form-control"
            readOnly
            value={paymentTerms?.phaseHandoverInitiatedByUserFullName}
          />
        </div>

        <div className="col-3">
          <label>Initiation Date</label>
          <input
            type="text"
            className="form-control"
            readOnly
            value={
              paymentTerms?.phaseHandoverStartDate != undefined
                ? dateFormatting(paymentTerms?.phaseHandoverStartDate)
                : paymentTerms?.phaseHandoverStartDate
            }
          />
        </div>
      </div>

      <br />
      <br />

      <ThemeProvider theme={theme}>
        <div className="row mb-3 mx-2 card-1">
          <div className="col-12 tab-card pt-3 pb-3 text-center">
            <h3 style={{ textAlign: "center" }}>
              <b>Common Feature Handover Checklist</b>
            </h3>

            <br />

            <div className="row justify-content-center">
              <button
                className="checklist__btn"
                disabled={
                  paymentTerms?.phaseHandOverStarted === true ||
                  phaseHandOverCompleted
                }
                style={{
                  backgroundColor:
                    (paymentTerms?.phaseHandOverStarted ||
                      phaseHandOverCompleted) &&
                    "grey",
                }}
                onClick={addItem}
              >
                Add Row
              </button>
            </div>

            <br />

            {addCheckListItem?.map((item, index) => {
              return (
                <div className="row justify-content-center mt-3">
                  <div className="col-6">
                    <label>Description</label>
                    <input
                      type="text"
                      className="form-control"
                      value={item?.description}
                      onChange={(e) => updateItem(e, index)}
                    />
                  </div>

                  <div className="col-3 text-center mt-4">
                    <button
                      className="checklist__btn"
                      onClick={createCommonFeatureChecklist}
                    >
                      Add Item
                    </button>
                  </div>

                  <div className="col-3 text-center mt-4">
                    <button
                      className="checklist__btn"
                      onClick={() => {
                        deleteItem(index);
                        // setAddCheckListItem({})
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              );
            })}

            {/* {
                            addCheckListItem?.length > 0
                            &&
                            <div className='row justify-content-center'>
                                <div className='col-6'>
                                    <label>Description</label>
                                    <input type="text" className='form-control' value={addCheckListItem?.description} onChange={(e) => setAddCheckListItem((prev) => {
                                        return { ...prev, description: e.target.value }
                                    })} />
                                </div>

                                <div className='col-3 text-center'>
                                    <button className='checklist__btn'
                                        onClick={createCommonFeatureChecklist}
                                    >Add Item</button>
                                </div>

                                <div className='col-3 text-center'>
                                    <button className='checklist__btn'
                                        onClick={() => {
                                            setAddCheckListItem({})
                                        }}
                                    >Cancel</button>
                                </div>
                            </div>
                        } */}

            <br />

            {checklist?.map((list) => {
              return (
                <div className="row justify-content-center">
                  <div className="col-1">
                    <h5>{list.serial}.</h5>
                  </div>

                  <div className="col-6">
                    <h5>{list.description}</h5>
                  </div>

                  <div className="col-3">
                    {list?.isInspected ? (
                      <Switch checked={list?.isInspected} color="primary" />
                    ) : (
                      <Switch
                        checked={list?.isInspected}
                        color="primary"
                        onChange={(e) => {
                          setSerial(list.serial);
                          setModal(true);
                        }}
                      />
                    )}
                  </div>

                  <br />
                  <br />
                </div>
              );
            })}

            <br />
            <br />

            <button
              className="checklist__btn"
              onClick={() => setModal2(true)}
              disabled={
                paymentTerms?.phaseHandOverStarted === true ||
                phaseHandOverCompleted
              }
              style={{
                backgroundColor:
                  (paymentTerms?.phaseHandOverStarted ||
                    phaseHandOverCompleted) &&
                  "grey",
              }}
            >
              Phase Ready
            </button>
          </div>
        </div>
      </ThemeProvider>

      <br />

      {paymentTerms.phaseHandOverCompleted && (
        <div className="row justify-content-center">
          <div className="col-4">
            <label>Sign Off By</label>
            <input
              type="text"
              className="form-control"
              value={paymentTerms?.engineerSignOffUserFullName}
              readOnly
            />
          </div>

          <div className="col-4">
            <label>Sign Off Date</label>
            <input
              type="text"
              className="form-control"
              value={dateFormatting(paymentTerms?.engineerSignOffDate)}
              readOnly
            />
          </div>
        </div>
      )}

      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
        open={modal}
        onClose={() => {
          setModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={modal}>
          <div
            className={classes.paper}
            style={{
              minWidth: "300px",
              minHeight: "180px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="row justify-content-center">
              <h3 className="col-10">
                <b>Are you sure ?</b>
              </h3>
            </div>

            <br />

            <div className="row container-fluid justify-content-center m-auto">
              <div className="col-6 text-right">
                <button
                  className="modal__btns"
                  onClick={updateCommonFeatureChecklist}
                >
                  Yes
                </button>
              </div>
              <div className="col-6 text-left">
                <button
                  className="modal__btns"
                  onClick={() => {
                    setModal(false);
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
        open={modal2}
        onClose={() => {
          setModal2(false);
        }}
        closeAfterTransition
      >
        <Fade in={modal2}>
          <div
            className={classes.paper}
            style={{
              minWidth: "300px",
              minHeight: "180px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="row justify-content-center">
              <h3 className="col-10">
                <b>Are you sure ?</b>
              </h3>
            </div>

            <br />

            <div className="row container-fluid justify-content-center m-auto">
              <div className="col-6 text-right">
                <button className="modal__btns" onClick={phaseReady}>
                  Yes
                </button>
              </div>
              <div className="col-6 text-left">
                <button
                  className="modal__btns"
                  onClick={() => {
                    setModal2(false);
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default CheckList;
