import MaterialTable from "material-table";
import React from "react";
import Paper from "@mui/material/Paper";
import "./CustomerDetail.scss";

const CustomerAllotment = ({invCustomer}) => {
 
  let arr = [];
  arr.push(invCustomer);

  return (
    <div className="customerAllotment">
      <Paper elevation={2} style={{padding: "20px"}}>
        <h4>Site Details</h4>
        <br />

        <div className="row justify-content-center">
          <div className="col-xl-5 col-sm-10">
            <label>Site Code</label>
            <input className="form-control" value={invCustomer?.siteDetails?.siteCode} readOnly />
          </div>
          <div className="col-xl-5 col-sm-10">
            <label>Site Name</label>
            <input className="form-control" value={invCustomer?.siteDetails?.siteName} readOnly />
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-xl-5 col-sm-10">
            <label>Phase Code</label>
            <input className="form-control" value={invCustomer?.siteDetails?.phaseCode} readOnly />
          </div>
          <div className="col-xl-5 col-sm-10">
            <label>Phase Name</label>
            <input className="form-control" value={invCustomer?.siteDetails?.phaseName} readOnly />
          </div>
        </div>
      </Paper>

      <br />
      <br />

      <Paper elevation={2} style={{padding: "20px"}}>
        <h4>Unit Details</h4>
        <br />

        <div className="row justify-content-center">
          <div className="col-xl-4 col-sm-8">
            <label>Unit name</label>
            <input className="form-control" value={invCustomer?.unit?.unitName} readOnly />
          </div>
          <div className="col-xl-4 col-sm-8">
            <label>Unit Type Name</label>
            <input className="form-control" value={invCustomer?.unit?.unitTypeName} readOnly />
          </div>
          <div className="col-xl-4 col-sm-8">
            <label>Unit Floor</label>
            <input className="form-control" value={invCustomer?.unit?.unitFloor} readOnly />
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-xl-4 col-sm-8">
            <label>BHK</label>
            <input className="form-control" value={invCustomer?.unit?.bhk} readOnly />
          </div>
          <div className="col-xl-4 col-sm-8">
            <label>Carpet Area</label>
            <input className="form-control" value={invCustomer?.unit?.carpetArea} readOnly />
          </div>
          <div className="col-xl-4 col-sm-8">
            <label>Balcony Area</label>
            <input className="form-control" value={invCustomer?.unit?.balconyArea} readOnly />
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-xl-4 col-sm-8">
            <label>Builtup Area</label>
            <input className="form-control" value={invCustomer?.unit?.builtUpArea} readOnly />
          </div>
          <div className="col-xl-4 col-sm-8">
            <label>Private Terrace Area</label>
            <input className="form-control" value={invCustomer?.unit?.privateTerraceArea} readOnly />
          </div>
          <div className="col-xl-4 col-sm-8">
            <label>Super Builtup Area</label>
            <input className="form-control" value={invCustomer?.unit?.superBuiltUpArea} readOnly />
          </div>
        </div>
      </Paper>

      <br />
      <br />

      {
        invCustomer?.carParkings?.length === 0 ?

          <h3><em style={{ color: '#EE4B46' }}> No Car Parking Opted</em></h3>

          :

          invCustomer?.carParkings?.map((c , index) => {
            return <Paper key={index} elevation={2} style={{ padding: '20px' }}>
            <h4>Car Parking Details</h4>
            <br />

            <div className="row justify-content-center">
              <div className="col-xl-5 col-sm-10">
                <label>Car Parking Type Code</label>
                <input className="form-control" value={c?.carParkingTypeCode} readOnly />
              </div>
              <div className="col-xl-5 col-sm-10">
                <label>Car Parking Type Name</label>
                <input className="form-control" value={c?.carParkingTypeName} readOnly />
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              <div className="col-xl-5 col-sm-10">
                <label>Car Parking Name</label>
                <input className="form-control" value={c?.carParkingName} readOnly />
              </div>
              <div className="col-xl-5 col-sm-10">
                <label>is Alloted</label>
                <input className="form-control" value={c?.isAlloted} readOnly />
              </div>
            </div>
          </Paper>
        })
      }
    </div>
  );
};

export default CustomerAllotment;
