import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../utils/config/url";
import { Switch, createMuiTheme, ThemeProvider, Select, InputLabel, MenuItem, capitalize } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Button from "../../components/Button/Button";
import {dateFormatting} from '../../utils/dateFormatting'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#EE4B46",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const MoneyReceipts = () => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [moneyReceipts, setMoneyReceipts] = useState([]);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [mode, setMode] = useState("");
  const [status, setStatus] = useState("");
  const [change, setChange] = useState(false);
  const classes = useStyles();
  const subCat1 = ["Booking Payment", "Part Payment", "Final Payment"];
  const subCat2 = ["Materials Sold", "Other Payment"];


  //   reset filter
  const reset = () => {
    setCategory("");
    setSubCategory("");
    setMode("");
    setStatus("");
  };


  ///filtering moneyreciept
  useEffect(() => {

    if (category === "" && subCategory === "" && mode === "" && status === "") {
      axios.get(`${BASE_URL}/api/v1/FinanceMgmt/MoneyReceipt/getListOfMoneyReceipt`,
        { headers: { Authorization: `bearer ${userInfo.token}` } })
        .then((response) => {

          setMoneyReceipts(response?.data?.moneyReceiptList);
        });
    } else if (category !== "" && subCategory === "" && mode === "" && status === "") {
      axios.get(`${BASE_URL}/api/v1/FinanceMgmt/MoneyReceipt/getListOfMoneyReceipt?paymentCategory=${category}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } })
        .then((response) => {

          setMoneyReceipts(response?.data?.moneyReceiptList);
        });
    } else if (category !== "" && subCategory !== "" && mode === "" && status === "") {
      axios.get(`${BASE_URL}/api/v1/FinanceMgmt/MoneyReceipt/getListOfMoneyReceipt?paymentCategory=${category}&paymentSubCategory=${subCategory}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } })
        .then((response) => {

          setMoneyReceipts(response?.data?.moneyReceiptList);
        });
    } else if (category !== "" && subCategory !== "" && mode !== "" && status === "") {
      axios.get(`${BASE_URL}/api/v1/FinanceMgmt/MoneyReceipt/getListOfMoneyReceipt?paymentCategory=${category}&paymentSubCategory=${subCategory}&transactionMode=${mode}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } })
        .then((response) => {

          setMoneyReceipts(response?.data?.moneyReceiptList);
        });
    } else if (category !== "" && subCategory !== "" && mode !== "" && status !== "") {
      axios.get(`${BASE_URL}/api/v1/FinanceMgmt/MoneyReceipt/getListOfMoneyReceipt?paymentCategory=${category}&paymentSubCategory=${subCategory}&transactionMode=${mode}&status=${status}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } })
        .then((response) => {

          setMoneyReceipts(response?.data?.moneyReceiptList);
        });
    }



  }, [category, subCategory, mode, status, change]);


  return (
    <ThemeProvider theme={theme}>
      <div className="outlet_bg">

        <div className="outlet_container">
          <Button navigateTo={'/home'}/>

          <div className="mt-5">
            <h3>Money Receipts</h3>

            <MaterialTable

              data={moneyReceipts}
              title=''
              columns={[

                { title: "Money Receipt No", field: "moneyReceiptNo" },
                { title: "Transaction Mode", field: "transactionMode" },
                { title: "Amount", field: "totalAmount" },
                {
                  title: "Customer",
                  render: (rowData) => {
                    return rowData.customerId ? rowData.customerId : 'NA'
                  }
                },
                { title: "Payment Category", field: "paymentCategory" },
                { title: "Payment Sub Category", field: "paymentSubCategory" },

                {
                  title: "Date",
                  defaultSort: "desc",
                  render: (rowData) =>
                    !rowData.moneyReceiptDate
                      ? ""
                      : dateFormatting(rowData.moneyReceiptDate),
                  customSort: (a, b) =>
                    a.moneyReceiptDate < b.moneyReceiptDate ? -1 : 1,
                },
              ]}

              options={{
                search: window.innerWidth < 700 ? false : true,
                searchFieldStyle: {
                  width: 150
                },
                paging: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 20, 30],
                headerStyle: {
                  backgroundColor: "#EE4B46",
                  color: "#fff",
                },
              }}
              actions={[
                (rowData) => ({
                  icon: "download",
                  tooltip: "Download PDF",
                  onClick: (event, rowData) => {
                    const link = document.createElement("a");
                    link.download = `Money Receipt.pdf`;
                    link.href = rowData.moneyReceiptLink;
                    link.click();
                  },
                }),
              ]}
              components={{
                Toolbar: (props) => (
                  <>
                    <div className="filters text-left"
                      style={{ marginBottom: "-20px" }}
                    >
                      <MTableToolbar {...props} />

                      <FormControl className={classes.formControl}
                        style={{ marginTop: "-65px" }}
                      >
                        <InputLabel style={{ fontSize: "15px" }} id="demo-simple-select-helper-label">
                          Payment Category
                        </InputLabel>
                        <Select value={category}
                          onChange={(e) => setCategory(e.target.value)}
                          className={classes.selectEmpty}
                          inputProps={{ "aria-label": "Without label" }}>
                          <MenuItem value="">Payment Category</MenuItem>
                          <MenuItem value="Customer Payment">Customer Payment</MenuItem>
                          <MenuItem value="Other Payment">Other Payment</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl className={classes.formControl} style={{ marginTop: "-65px" }}>
                        <InputLabel style={{ fontSize: "15px" }} id="demo-simple-select-helper-label">
                          Sub Category
                        </InputLabel>
                        <Select value={subCategory}
                          onChange={(e) => setSubCategory(e.target.value)}
                          className={classes.selectEmpty}
                          inputProps={{ "aria-label": "Without label" }}
                          disabled={category === "" ? true : false}>
                          <MenuItem value="">Sub-Category</MenuItem>
                          {category === "Customer Payment" &&
                            subCat1?.map((s, i) => (
                              <MenuItem value={s} key={i}>
                                {s}
                              </MenuItem>
                            ))}
                          {category === "Other Payment" &&
                            subCat2?.map((s, i) => (
                              <MenuItem value={s} key={i}>
                                {s}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>

                      {window.innerWidth < 1000 && <br />}

                      <FormControl className={classes.formControl}
                        style={{ marginTop: window.innerWidth < 1000 ? '-35px' : '-65px' }}>
                        <InputLabel style={{ fontSize: "15px" }} id="demo-simple-select-helper-label">
                          Transaction Mode
                        </InputLabel>
                        <Select value={mode} onChange={(e) => setMode(e.target.value)} className={classes.selectEmpty} inputProps={{ "aria-label": "Without label" }} disabled={subCategory === "" ? true : false}>
                          <MenuItem value="">Transaction Mode</MenuItem>
                          <MenuItem value="Cheque">Cheque</MenuItem>
                          <MenuItem value="Cash">Cash</MenuItem>
                          <MenuItem value="DD">DD</MenuItem>
                          <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                        </Select>
                      </FormControl>

                    </div>

                        <br />
                        <br />
                                 
                    <div className="filters text-left"
                      style={{ marginBottom: "-0px" }}
                    >

                      {/* //status  */}
                      <FormControl className={classes.formControl} 
                      style={{ marginTop: window.innerWidth < 1000 ? '-35px' : '-65px' }}>
                        <InputLabel style={{ fontSize: "15px" }} id="demo-simple-select-helper-label">
                          Status
                        </InputLabel>
                        <Select value={status} onChange={(e) => setStatus(e.target.value)} className={classes.selectEmpty} inputProps={{ "aria-label": "Without label" }} disabled={mode === "" ? true : false}>
                          <MenuItem value="">Status</MenuItem>
                          <MenuItem value="Initiated">Initiated</MenuItem>
                          <MenuItem value="Receipt Generated">Receipt Generated</MenuItem>
                          <MenuItem value="Cancelled">Cancelled</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl className={classes.formControl}
                       style={{ marginTop: window.innerWidth < 1000 ? '-20px' : '-50px' }}>
                        <button
                          onClick={reset}
                          style={{
                            backgroundColor: "white",
                            color: "black",
                            border: "1px solid black",
                            borderRadius: "20px",
                            padding: "2px",
                          }}
                        >
                          Reset Filter
                        </button>
                      </FormControl>

                    </div>
                  </>
                ),
              }}
            />

            {/* <AssignModal open={open} setOpen={setOpen} lId={lId} setChange={setChange}></AssignModal> */}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default MoneyReceipts;
