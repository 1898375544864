import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../utils/config/url";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useStyles } from "../../../utils/ModalStyles";
import "../../../index.scss";

const CreditTransactions = () => {
  const classes = useStyles();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [credit, setCredit] = useState([]);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [disclaimer, setDisclaimer] = useState(
    " If date range not selected, table shows Transaction Entry for last 90 days only"
  );
  const [disclaimer2, setDisclaimer2] = useState("");

  useEffect(() => {
    getListOfCreditTransaction();
  }, []);

  const getListOfCreditTransaction = () => {
    axios
      .get(
        `${BASE_URL}/api/v1/FinanceMgmt/Transaction/getListOfCreditTransaction`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        // console.log(response)
        setCredit(response.data.creditTransactionList);
      });
  };

  // useEffect(() => {

  //     console.log(category, subCategory, startDate, endDate)
  //     axios.get(`${BASE_URL}/api/v1/FinanceMgmt/Transaction/getListOfCreditTransaction?transactionCategory=${category}
  //     &transactionSubCategory=${subCategory}
  //     &transStartDate=${startDate}&transEndDate=${endDate}`, {
  //         headers: { Authorization: `bearer ${userInfo.token}` }
  //     })
  //         .then((response) => {
  //             console.log(response)
  //         })
  // }, [category, subCategory, startDate, endDate])

  useEffect(() => {
    if (category === "") {
      setSubCategory("");
    }

    if (category === "" && startDate === "" && endDate === "") {
      setSubCategory("");
      return getListOfCreditTransaction();
    }
    // console.log(category, subCategory, status)
    let params = [];

    if (category) {
      params.push("transactionCategory");
    }
    if (subCategory) {
      params.push("transactionSubCategory");
    }
    if (startDate) {
      params.push("transStartDate");
    }
    if (endDate) {
      params.push("transEndDate");
      setDisclaimer2("");
    }
    if (startDate && !endDate) {
      return setDisclaimer2("Select an End Date");
    }

    let url = [];
    params.forEach((p, index) => {
      if (p === "transactionCategory") {
        if (index === 0) {
          url.push(`?${p}=${category}`);
        } else {
          url.push(`&${p}=${category}`);
        }
      }
      if (p === "transactionSubCategory") {
        if (index === 0) {
          url.push(`?${p}=${subCategory}`);
        } else {
          url.push(`&${p}=${subCategory}`);
        }
      }
      if (p === "transStartDate") {
        let arr = startDate.split("-");
        let finalDate = arr.reverse().join("/");

        if (index === 0) {
          url.push(`?${p}=${finalDate}`);
        } else {
          url.push(`&${p}=${finalDate}`);
        }
      }
      if (p === "transEndDate") {
        let arr = endDate.split("-");
        let finalDate = arr.reverse().join("/");

        if (index === 0) {
          url.push(`?${p}=${finalDate}`);
        } else {
          url.push(`&${p}=${finalDate}`);
        }
      }
    });
    // console.log(url.join(""));

    axios
      .get(
        `${BASE_URL}/api/v1/FinanceMgmt/Transaction/getListOfCreditTransaction${url.join(
          ""
        )}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        //console.log(response)
        setCredit(response.data.creditTransactionList);
      });
  }, [category, subCategory, startDate, endDate]);

  const reset = () => {
    setCategory("");
    setSubCategory("");
    setStartDate("");
    setEndDate("");
  };

  return (
    <div className="credit">
      <MaterialTable
        data={credit}
        title=""
        columns={[
          { title: "Transaction ID", field: "transId" },

          {
            title: "Transaction Date",
            render: (rowData) => {
              return !rowData.transDate
                ? ""
                : rowData.transDate.substring(8, 10) +
                    "-" +
                    rowData.transDate.substring(5, 7) +
                    "-" +
                    rowData.transDate.substring(0, 4);
            },
            customSort: (a, b) => (a.transDate < b.transDate ? -1 : 1),
          },
          { title: "Category", field: "transactionCategory" },
          {
            title: "Sub Category",
            field: "transactionSubCategory",
          },
          { title: "Payment Mode", field: "transactionMode" },
          { title: "Amount", field: "transactionAmount" },
          { title: "Bank", field: "transactionBank" },
          { title: "Comments", field: "transactionComments" },
        ]}
        options={{
          search: window.innerWidth < 700 ? false : true,
          searchFieldStyle: {
            width: 180,
          },
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          actionsColumnIndex: 0,
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
          },
        }}
        components={{
          Toolbar: (props) => (
            <div className="filters text-left">
              <MTableToolbar {...props} />

              <FormControl
                variant="standard"
                className="formControl"
                style={{
                  margin: "0px 10px",
                  marginTop: "-65px",
                  width: "150px",
                }}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Category
                </InputLabel>
                <Select
                  llabelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setSubCategory("");
                  }}
                  className="selectEmpty"
                  style={{
                    fontSize: "13px",
                    textAlign: "left",
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">Category</MenuItem>
                  <MenuItem value="CustomerPayment">Customer Payment</MenuItem>
                  <MenuItem value="OtherPayment">Other Payment</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                variant="standard"
                className="formControl"
                style={{
                  margin: "0px 10px",
                  marginTop: "-65px",
                  width: "150px",
                }}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Sub Category
                </InputLabel>
                {category === "CustomerPayment" ? (
                  <Select
                    llabelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                    className="selectEmpty"
                    inputProps={{ "aria-label": "Without label" }}
                    style={{
                      fontSize: "13px",
                      textAlign: "left",
                    }}
                    disabled={category === "" ? true : false}
                  >
                    <MenuItem value="">Sub Category</MenuItem>
                    <MenuItem value="BookingPayment">Booking Payment</MenuItem>
                    <MenuItem value="DemandPayment">Demand Payment</MenuItem>
                    <MenuItem value="FinalPayment">Final Payment</MenuItem>
                  </Select>
                ) : (
                  <Select
                    llabelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                    className="selectEmpty"
                    inputProps={{ "aria-label": "Without label" }}
                    disabled={category === "" ? true : false}
                  >
                    <MenuItem value="">Sub Category</MenuItem>
                    <MenuItem value="MaterialsSold">Materials Sold</MenuItem>
                    <MenuItem value="OtherPayment">Other Payment</MenuItem>
                  </Select>
                )}
              </FormControl>

              {window.innerWidth < 1100 && <br />}

              <FormControl
                className="formControl"
                style={{
                  margin: "0px 10px",
                  marginTop: window.innerWidth < 1100 ? "-35px" : "-60px",
                  // width: '10vw',
                }}
              >
                <label
                  style={{
                    margin: "0",
                    fontSize: "12px",
                    textAlign: "left",
                    color: "grey",
                  }}
                >
                  Start Date
                </label>
                <input
                  type="date"
                  style={{
                    border: "2px solid lightgrey",
                    padding: "0px 10px",
                    fontSize: "12px",
                  }}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </FormControl>

              <FormControl
                className="formControl"
                style={{
                  margin: "0px 10px",
                  marginTop: window.innerWidth < 1100 ? "-35px" : "-60px",
                  width: "120px",
                }}
              >
                <label
                  style={{
                    margin: "0",
                    fontSize: "12px",
                    textAlign: "left",
                    color: "grey",
                  }}
                >
                  End Date
                </label>
                <input
                  type="date"
                  style={{
                    border: "2px solid lightgrey",
                    padding: "0px 10px",
                    fontSize: "12px",
                  }}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </FormControl>

              {window.innerWidth < 1200 && (
                <>
                  <br /> <br /> <br />
                </>
              )}

              <FormControl
                className="formControl"
                style={{
                  marginTop: "-50px",
                  marginRight: "110px",
                  marginLeft: "10px",
                }}
              >
                <button
                  onClick={reset}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                    borderRadius: "20px",
                    padding: "2px 7px",
                  }}
                >
                  Reset Filter
                </button>
              </FormControl>

              <p
                style={{
                  marginLeft: "10px",
                  marginTop: "-15px",
                  color: "#EE4B46",
                }}
              >
                <em>{disclaimer}</em>
              </p>

              <p
                style={{
                  marginLeft: "10px",
                  marginTop: "-10px",
                  color: "#EE4B46",
                }}
              >
                <em>{disclaimer2}</em>
              </p>
            </div>
          ),
        }}
      ></MaterialTable>
    </div>
  );
};

export default CreditTransactions;
