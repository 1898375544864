import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../utils/config/url';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { MdDelete } from 'react-icons/md';
import {
    Modal,
    Backdrop,
    Fade,
} from "@mui/material";
import { useStyles } from '../../utils/ModalStyles';
import './UnitPhoto.scss'

const UnitPhoto = () => {

    const classes = useStyles()
    const unitName = useParams().unitname;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const Token = 'bearer' + " " + userInfo.token;

    const [photo, setPhoto] = useState('')
    const [images, setImages] = useState([])
    const [open, setOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0)
    const [deleteImage, setDeleteImage] = useState('');
    const [deleteModal, setDeleteModal] = useState(false)

    useEffect(() => {
        getListOfUnitPhoto()
    }, [])

    const getListOfUnitPhoto = () => {
        axios
            .get(`${BASE_URL}/api/v1/Configuration/Unit/getListOfUnitPhoto/${unitName}`,
                { headers: { Authorization: Token } })
            .then((response) => {
                // console.log(response)
                if (response.data.status === false) {
                    setImages([])
                } else {
                    const imageList = response.data.unitPhoto.map((p) => {
                        return p.link
                    })

                    setImages(imageList)
                }

            })
    }

    // console.log(images)

    const uploadPhoto = (e) => {
        e.preventDefault()

        let formData = new FormData();
        formData.append('file', photo);
        formData.append('unitName', unitName)
        formData.append('comment', 'photo')

        axios.post(`${BASE_URL}/api/v1/Configuration/Unit/uploadUnitPhoto`,
            formData,
            { headers: { Authorization: Token } })
            .then((response) => {

                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        document.getElementById('unitPhoto').value = null;
                        setPhoto('')
                        getListOfUnitPhoto()
                    })
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                        text: response.data.error
                    })
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }

    const deleteUnitPhoto = (e) => {
        e.preventDefault();

        axios.patch(`${BASE_URL}/api/v1/Configuration/Unit/deleteUnitPhoto?unitName=${unitName}&photoLink=${deleteImage}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                setDeleteModal(false)

                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        setDeleteImage('')
                        getListOfUnitPhoto()
                    })
                }
            })
            .catch((err) => {
                setDeleteModal(false)

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }


    return (
        <div className='unitphoto'>
            <form onSubmit={(e) => { uploadPhoto(e) }}>
                <input type="file" id="unitPhoto" name="filename" accept='.jpg , .jpeg , .png' onChange={(e) => setPhoto(e.target.files[0])} />
                <button className='unitphoto__btn' type='submit'>Upload</button>
            </form>

            <div className='unitphoto__images'>
                
                {images.map((i, index) => {

                    return <span className="image" key={index} id={`image-container-${index}`}>
                    
                        <img src={i} alt='images' onClick={() => {
                            setOpen(true)
                            setPhotoIndex(index)
                        }} />
                        <MdDelete style={{ fontSize: '28px', marginLeft: '90%', color: 'black', cursor: 'pointer' }}
                            id={`delete-icon-${index}`}
                            onClick={(e) => {
                                setDeleteModal(true)
                                setDeleteImage(i)
                            }}
                        />
                    </span>
                })}
            </div>

            {open && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setOpen(false)}

                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                    }
                />

            )}

            {/* ////////////Modal for deleting Photots ////////////// */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={deleteModal}
                onClose={() => {
                    setDeleteModal(false)
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={deleteModal}>
                    <form className={classes.paper} onSubmit={(e) => deleteUnitPhoto(e)}>

                        <h4 className='text-center'>Do you want to delete this photo ?</h4>
                        <br />

                        <div className='row justify-content-center'>

                            <div className='col-4'>
                                <button className='unitphoto__btn' type='sumbit'>Yes</button>
                            </div>

                            <div className='col-4'>
                                <button className='unitphoto__btn'
                                    onClick={(e) => { e.preventDefault(); setDeleteModal(false); setDeleteImage('') }}>Cancel</button>
                            </div>
                        </div>

                    </form>
                </Fade>
            </Modal>

        </div>
    )
}

export default UnitPhoto;