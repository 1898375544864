import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";
import Form from "react-bootstrap/Form";
import "./Documents.scss";
import { Modal, Backdrop, Fade } from "@mui/material";
import { useStyles } from "../../utils/ModalStyles";
import { MoonLoader } from "react-spinners";

const Documents = () => {
  const classes = useStyles();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const appId = useParams().applicationId;
  const navigate = useNavigate();

  const [appDraftLink, setAppDraftLink] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [docName, setDocName] = useState("");
  const [generatedBy, setGeneratedBy] = useState("");
  const [generatedDate, setGeneratedDate] = useState("");

  const [appFormScanLink, setAppFormScanLink] = useState("");
  const [uploadDate, setUploadDate] = useState("");
  const [uploadedBy, setUploadedBy] = useState("");
  const [uploadDocName, setUploadDocName] = useState("");
  const [appStatus, setAppStatus] = useState("");
  const [file, setFile] = useState("");
  const [safFile, setSAFFile] = useState("");

  const [panScanLink, setPanScanLink] = useState("");
  const [panDocName, setPanDocName] = useState("");
  const [panUploadedBy, setPanUploadedBy] = useState("");
  const [panUploadDate, setPanUploadDate] = useState("");
  const [panFile, setPanFile] = useState("");

  const [adharScanLink, setAdharScanLink] = useState("");
  const [adharDocName, setAdharDocName] = useState("");
  const [adharploadedBy, setAdharUploadedBy] = useState("");
  const [adharUploadDate, setAdharUploadDate] = useState("");
  const [adharFile, setAdharFile] = useState("");

  const [salesDraftLink, setSalesDraftLink] = useState("");
  const [salesDocName, setSalesDocName] = useState("");
  const [salesGeneratedBy, setSalesGeneratedBy] = useState("");
  const [salesGeneratedDate, setSalesGeneratedDate] = useState("");

  const [provisionalDraftLink, setProvisionalDraftLink] = useState("");
  const [provisionalDocName, setProvisionalDocName] = useState("");
  const [provisionalGeneratedBy, setProvisionalGeneratedBy] = useState("");
  const [provisionalDate, setProvisionalDate] = useState("");

  const [courier, setCourier] = useState("");
  const [trackingId, setTrackingId] = useState("");
  const [provisionalFile, setProvisionalFile] = useState("");
  const [provisionalScanLink, setProvisionalScanLink] = useState("");
  const [provisionalScanDocName, setProvisionalScanDocName] = useState("");
  const [provisionalScanUploadDate, setProvisionalScanUploadDate] =
    useState("");
  const [provisionalScanUploadedBy, setProvisionalScanUploadedBy] =
    useState("");
  const [uploadSAFModal, setUploadSAFModal] = useState(false);
  const [moneyReceiptLink, setMoneyReceiptLink] = useState("");
  const [moneyreceiptNo, setMoneyReceiptNo] = useState("");
  const [moneyReceiptDate, setMoneyReceiptDate] = useState("");
  const [salesScanLink, setSalesScanLink] = useState("");
  const [salesScanDocName, setSalesScanDocName] = useState("");
  const [salesScanUploadedBy, setSalesScanUploadedBy] = useState("");
  const [salesScanUploadDate, setSalesScanUploadDate] = useState("");
  const [salesScanFile, setSalesScanFile] = useState("");
  const [handOverToOperations, setHandoverToOperations] = useState(false);
  const [handOverToOperationsDate, setHandOverToOperationsDate] = useState("");
  const [bookingByUserName, setBookingByUserName] = useState("");
  const [deleteApplicantModal, setDeleteApplicantModal] = useState(false);
  const [regenerateBookingDocumentsModal, setRegenerateBookingDocumentsModal] =
    useState(false);
  const [relationshipManagerEmployeeId, setRelationshipManagerEmployeeId] =
    useState("");
  const [relationshipManagerFullName, setRelationshipManagerFullName] =
    useState("");
  const [selectedRelationshipManager, setSelectedRelationshipManager] =
    useState("");
  const [employeeList, setEmployeeList] = useState([]);

  const [loader, setLoader] = useState(false);

  var today = new Date();

  var tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 0);
  var dd = tomorrow.getDate();
  var mm = tomorrow.getMonth() + 1; //January is 0!
  var yyyy = tomorrow.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  tomorrow = yyyy + "-" + mm + "-" + dd;

  useEffect(() => {
    getBookingApplicationByApplicationId();
  }, []);

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          setEmployeeList(response.data.employees);
        }
      });
  }, []);

  const getBookingApplicationByApplicationId = () => {
    axios
      .get(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/getBookingApplicationByApplicationId/${appId}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        // console.log(response)
        let data = response.data.BookingApplication;

        if (data.applicationFormDraft.applicationFormDraftLink) {
          setAppDraftLink(data.applicationFormDraft.applicationFormDraftLink);
          setPaymentMode(data?.bookingPaymentMode);
          setDocName(data?.applicationFormDraft.documentName);
          setGeneratedBy(data?.applicationFormDraft.generatedByUserFullName);

          let res = dateFormatting(data.applicationFormDraft.generatedDate);
          setGeneratedDate(res);
        } else {
          setAppDraftLink("");
          setPaymentMode(data?.bookingPaymentMode);
        }

        // Form Scan //
        setAppStatus(data.status);

        if (data.applicationFormScan.applicationFormScanLink !== null) {
          setAppFormScanLink(data.applicationFormScan.applicationFormScanLink);
          setUploadDocName(data.applicationFormScan.documentName);
          setUploadedBy(data.applicationFormScan.uploadedByUserFullName);
          let res = dateFormatting(data.applicationFormScan.uploadDate);
          setUploadDate(res);
        }

        // PANcard //

        if (data.customerPAN.PANCardScanLink !== null) {
          let d = data.customerPAN;
          setPanScanLink(d.PANCardScanLink);
          setPanDocName(d.documentName);
          setPanUploadedBy(d.uploadedByUserFullName);
          let res = dateFormatting(d.uploadDate);
          setPanUploadDate(res);
        } else {
          setPanScanLink("");
        }

        // Customer Aadhar Card //

        if (data.customerAadhar.aadharCardScanLink !== null) {
          let d = data.customerAadhar;
          setAdharScanLink(d.aadharCardScanLink);
          setAdharDocName(d.documentName);
          setAdharUploadedBy(d.uploadedByUserFullName);
          let res = dateFormatting(d.uploadDate);
          setAdharUploadDate(res);
        } else {
          setAdharScanLink("");
        }

        // Sales Agreement Draft //

        if (data.saleAgreementDraft.saleAgreementDraftLink !== null) {
          let d = data.saleAgreementDraft;
          setSalesDraftLink(d.saleAgreementDraftLink);
          setSalesDocName(d.documentName);
          setSalesGeneratedBy(d.generatedByUserFullName);
          let res = dateFormatting(d.generatedDate);
          setSalesGeneratedDate(res);
        }

        // Provisonal Draft Letter //

        if (
          data.provisionalAllotmentLetterDraft.provisionalAllotmentDraftLink !==
          null
        ) {
          let d = data.provisionalAllotmentLetterDraft;
          setProvisionalDraftLink(d.provisionalAllotmentDraftLink);
          setProvisionalDocName(d.documentName);
          setProvisionalGeneratedBy(d.generatedByUserFullName);
          let res = dateFormatting(d.generatedDate);
          setProvisionalDate(res);
        } else {
          setProvisionalDraftLink("");
        }

        //provisional letter Scan

        if (
          data.provisionalAllotmentLetterScan
            .provisionalAllotmentLetterScanLink !== null
        ) {
          let d = data.provisionalAllotmentLetterScan;

          setProvisionalScanLink(d.provisionalAllotmentLetterScanLink);
          setProvisionalScanUploadedBy(d.uploadedByUserFullName);
          setProvisionalScanDocName(d.documentName);
          setCourier(d?.courierName);
          setTrackingId(d?.trackingId);

          let res = dateFormatting(d.uploadDate);
          setProvisionalScanUploadDate(res);
        } else {
          setProvisionalScanLink("");
        }

        // Money Receipt Link

        setMoneyReceiptLink(data?.moneyReceiptLink);
        setMoneyReceiptNo(data?.moneyReceiptNo);
        let val = dateFormatting(data?.moneyReceiptDate);
        setMoneyReceiptDate(val);

        //Hand over to Operations
        setHandoverToOperations(data?.handOverToOperations);
        data?.bookingByUserName &&
          setBookingByUserName(data?.bookingByUserName);

        let res = dateFormatting(data?.handOverToOperationsDate);
        setHandOverToOperationsDate(res);

        // Relationship Manager
        if (data?.relationshipManager != "Not Assigned") {
          console.log("yess");
          setRelationshipManagerEmployeeId(data?.relationshipManagerEmployeeId);
          setRelationshipManagerFullName(data?.relationshipManagerFullName);
        }

        // Sales Agreement Scan //

        if (
          data.registeredSaleAgreementScan.RegisteredSaleAgreements3Link !==
          null
        ) {
          let d = data.registeredSaleAgreementScan;
          setSalesScanLink(d.RegisteredSaleAgreements3Link);
          setSalesScanDocName(d.documentName);
          setSalesScanUploadedBy(d.uploadedByUserFullName);
          let res = dateFormatting(d.uploadDate);
          setSalesScanUploadDate(res);
        } else {
          setSalesScanLink("");
        }
      });
  };

  const dateFormatting = (input) => {
    let d = new Date(input);
    let date = d.getDate();
    if (date < 10) {
      date = `0${date}`;
    }
    let m = d.getMonth() + 1;
    if (m < 10) {
      m = `0${m}`;
    }
    let y = d.getFullYear();

    let final = `${date}/${m}/${y}`;
    return final;
  };

  const uploadSAF = (e) => {
    e.preventDefault();
    setUploadSAFModal(false);
    setLoader(true);

    let formData = new FormData();
    formData.append("file", safFile);
    formData.append("applicationId", appId);
    formData.append("uploadedByUserName", userInfo.userName);
    formData.append("uploadedByUserFullName", userInfo.userFullName);

    axios
      .post(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/uploadApplicationScan`,
        formData,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        // console.log(response)
        setLoader(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getBookingApplicationByApplicationId();
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });

  };

  const generateDraft = () => {
    setLoader(true);

    axios
      .post(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/generateBookingApplication`,
        {
          applicationId: appId,
          currentUserName: userInfo.userName,
          currentUserFullName: userInfo.userFullName,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getBookingApplicationByApplicationId();
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const uploadAFS = (e) => {
    e.preventDefault();

    setLoader(true);

    let formData = new FormData();
    formData.append("file", file);
    formData.append("applicationId", appId);
    formData.append("uploadedByUserName", userInfo.userName);
    formData.append("uploadedByUserFullName", userInfo.userFullName);

    axios
      .post(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/uploadApplicationScan`,
        formData,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        // console.log(response)
        setLoader(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getBookingApplicationByApplicationId();
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const uploadCP = (e) => {
    e.preventDefault();

    setLoader(true);

    let formData = new FormData();
    formData.append("file", panFile);
    formData.append("applicationId", appId);
    formData.append("uploadedByUserName", userInfo.userName);
    formData.append("uploadedByUserFullName", userInfo.userFullName);

    axios
      .post(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/uploadPANCardScan`,
        formData,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getBookingApplicationByApplicationId();
          });
        }
      })
      .catch((err) => {
        setLoader(false);

        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const uploadCA = (e) => {
    e.preventDefault();

    setLoader(true);

    let formData = new FormData();
    formData.append("file", adharFile);
    formData.append("applicationId", appId);
    formData.append("uploadedByUserName", userInfo.userName);
    formData.append("uploadedByUserFullName", userInfo.userFullName);

    axios
      .post(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/uploadAadharCardScan`,
        formData,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getBookingApplicationByApplicationId();
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const generateProvisionalDraft = () => {
    setLoader(true);
    axios
      .post(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/generateProvisionalAllotmentLetter`,

        {
          applicationId: appId,
          currentUserName: userInfo.userName,
          currentUserFullName: userInfo.userFullName,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        //   console.log(response)
        setLoader(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getBookingApplicationByApplicationId();
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const uploadPALS = (e) => {
    e.preventDefault();
    setLoader(true);
    let formData = new FormData();
    formData.append("file", provisionalFile);
    formData.append("applicationId", appId);
    formData.append("courierName", courier);
    formData.append("trackingId", trackingId);
    formData.append("uploadedByUserName", userInfo.userName);
    formData.append("uploadedByUserFullName", userInfo.userFullName);

    axios
      .post(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/uploadProvisionalAllotmentLetterScan`,

        formData,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        // console.log(response)
        setLoader(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getBookingApplicationByApplicationId();
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const handoverToOperations = () => {
    setLoader(true);
    axios
      .put(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/handoverToOperations`,
        {
          applicationId: appId,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getBookingApplicationByApplicationId();
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const assignRelationshipManager = () => {
    if (selectedRelationshipManager) {
      setLoader(true);
      axios
        .put(
          `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/assignRelationshipManager`,
          {
            applicationId: appId,
            relationshipManagerEmployeeId:
              selectedRelationshipManager.split(",")[2],
            relationshipManagerFullName:
              selectedRelationshipManager.split(",")[1],
            relationshipManager: selectedRelationshipManager.split(",")[0],
            currentUserEmpId: userInfo?.employeeId,
          },
          {
            headers: { Authorization: `bearer ${userInfo.token}` },
          }
        )
        .then((response) => {
          setLoader(false);
          if (response.data.status === true) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: response.data.message,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then(function () {
              setSelectedRelationshipManager("");
              getBookingApplicationByApplicationId();
            });
          }
        })
        .catch((err) => {
          setLoader(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: err.response.data.error,
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: "Please select Relationship Manager",
      });
    }
  };

  const deleteApplicantAndCustomer = () => {
    setLoader(true);

    axios
      .put(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/deleteCustNApplicant`,
        {
          applicationId: appId,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);
        setDeleteApplicantModal(false);
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getBookingApplicationByApplicationId();
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const regenerateBookingDocuments = () => {
    setLoader(true);

    axios
      .put(
        `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/regenerateBookingDocuments`,
        {
          applicationId: appId,
          currentUserFullName: userInfo.userFullName,
          currentUserName: userInfo.userName,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);
        setRegenerateBookingDocumentsModal(false);
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getBookingApplicationByApplicationId();
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  return (
    <div className="documents">
      <div className="row justify-content-center mb-3 mx-2 card-1">
        <div
          className="row justify-content-center mb-3 mx-2 card-1"
          style={{ backgroundColor: "#F9F8F8" }}
        >
          <>
            {appDraftLink ? (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      paddingRight: "10px",
                      marginRight: "5px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      paddingTop: "5px",
                      paddingLeft: "10px",
                    }}
                  >
                    Application Form Draft
                  </p>
                  <br />
                  <div>
                    <p>
                      <b>Document Name : </b>
                      {docName}
                    </p>
                    <p>
                      <b>Generated By : </b>
                      {generatedBy}
                    </p>
                    <p>
                      <b>Generated Date : </b>
                      {generatedDate}
                    </p>
                    <a href={appDraftLink}>View Document</a>
                  </div>
                  <br />
                  <button
                    className="documents__btn"
                    disabled={
                      (userInfo?.userRole !== "Director" &&
                        userInfo?.userRole !== "Sales") ||
                      appStatus !== "Booking Initiated"
                    }
                    style={{
                      display:
                        userInfo?.userRole === "Director" ||
                        userInfo?.userRole === "Sales"
                          ? "block"
                          : "none",
                      backgroundColor:
                        (userInfo?.userRole !== "Director" &&
                          userInfo?.userRole !== "Sales") ||
                        appStatus !== "Booking Initiated"
                          ? "grey"
                          : "",
                    }}
                    onClick={generateDraft}
                  >
                    Re-Generate Application Form Draft
                  </button>
                </div>
              </>
            ) : (
              <div>
                {/* {(userInfo?.userRole !== 'Operations' && userInfo?.userRole !== 'Director') &&
                                <h5><b>Application Form Draft Not Generated</b></h5>} */}
                <h5>
                  <b>Application Form Draft Not Generated</b>
                </h5>
                <br />
                <button
                  className="documents__btn"
                  style={{
                    // display: userInfo?.userRole === 'Operations' || userInfo?.userRole === 'Director' ? 'block' : 'none',
                    backgroundColor:
                      (userInfo?.userRole !== "Director" &&
                        userInfo?.userRole !== "Sales") ||
                      !paymentMode
                        ? "grey"
                        : "",
                  }}
                  disabled={
                    (userInfo?.userRole !== "Director" &&
                      userInfo?.userRole !== "Sales") ||
                    paymentMode === ""
                  }
                  onClick={generateDraft}
                >
                  Generate Application Form Draft
                </button>
              </div>
            )}
          </>
        </div>

        <br />

        <div
          className="row mb-3 mx-2 card-5"
          style={{ backgroundColor: "#F9F8F8" }}
        >
          <div className="col-12 tab-card pt-3 pb-3 text-center">
            {moneyReceiptLink ? (
              <>
                <h4
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Money Receipt For Booking Payment
                </h4>
                <br />
                <div>
                  <p>
                    <b>Money Receipt No : </b>
                    {moneyreceiptNo}
                  </p>
                  <p>
                    <b>Money Receipt Date: </b>
                    {moneyReceiptDate}
                  </p>
                  <p>
                    <b>Money Receipt : </b>
                    <a href={moneyReceiptLink}>View Document</a>
                  </p>
                </div>
              </>
            ) : (
              <>
                <h4
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Money Receipt not generated
                </h4>
              </>
            )}
          </div>
        </div>

        <br />

        <div
          className="row mb-3 mx-2 card-2"
          style={{ backgroundColor: "#F9F8F8" }}
        >
          <div className="col-12 tab-card text-center">
            {!appFormScanLink ? (
              <>
                <h4
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Signed Application Form Not Received
                </h4>
                <br />

                {(userInfo?.userRole === "Sales" ||
                  userInfo?.userRole === "Director") && (
                  <form onSubmit={(e) => uploadAFS(e)}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <input
                        className="form-control-file"
                        type="file"
                        id="myfile"
                        name="myfile"
                        accept="application/pdf"
                        required
                        onChange={(e) => setFile(e.target.files[0])}
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                      />
                      <button
                        className="btn btn-user"
                        type="submit"
                        disabled={appStatus !== "Booking Initiated"}
                        style={{
                          display:
                            userInfo?.userRole === "Sales" ||
                            userInfo?.userRole === "Director"
                              ? "block"
                              : "none",
                          backgroundColor:
                            appStatus !== "Booking Initiated" && "grey",
                          cursor:
                            appStatus !== "Booking Initiated"
                              ? "defualt"
                              : "pointer",
                          color: "white",
                        }}
                      >
                        Upload Document
                      </button>
                    </div>
                    {/* <div className="row justify-content-center">
                            <div className="col-4">
                                <label>Sales Agreement Appointment Date</label>
                                <input
                                    className="form-control"
                                    type="date"
                                    readOnly
                                    required
                                //onChange={(e) => setSaad(e.target.value)}
                                />
                            </div>
                        </div> */}
                  </form>
                )}
              </>
            ) : (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      paddingRight: "10px",
                      marginRight: "5px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      paddingTop: "5px",
                      paddingLeft: "10px",
                    }}
                  >
                    Application Form Scan Copy
                  </p>
                  <br />
                  <div>
                    <p>
                      <b>Document Name : </b>
                      {uploadDocName}
                    </p>
                    <p>
                      <b>Uploaded By : </b>
                      {uploadedBy}
                    </p>
                    <p>
                      <b>Upload Date : </b>
                      {uploadDate}
                    </p>
                    <a href={appFormScanLink}>View Document</a>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <br />

        <div
          className="row mb-3 mx-2 card-5"
          style={{ backgroundColor: "#F9F8F8" }}
        >
          <div className="col-12 tab-card pt-3 pb-3 text-center">
            {handOverToOperations === true ? (
              <>
                <h4
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Booking Application handed over to Operations
                </h4>
                <br />
                <div>
                  <p>
                    <b>Hand Over Date: </b>
                    {handOverToOperationsDate}
                  </p>
                </div>
              </>
            ) : (
              <>
                <h4
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Booking Application not handed over to Operations
                </h4>

                {userInfo?.userName === bookingByUserName && (
                  <button
                    className="documents__btn mt-4"
                    disabled={appStatus === "Handed Over to Operations"}
                    style={{
                      backgroundColor:
                        appStatus === "Handed Over to Operations" && "grey",
                    }}
                    onClick={handoverToOperations}
                  >
                    Handover To Operations
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <br />

      <div className="row justify-content-center mb-3 mx-2 card-5">
        <div
          className="row mb-3 mx-2 card-5"
          style={{ backgroundColor: "#F9F8F8" }}
        >
          <div className="col-12 tab-card pt-3 pb-3 text-center">
            {relationshipManagerEmployeeId ? (
              <>
                <h4
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Relationship Manager
                </h4>
                <br />
                <div>
                  <p>
                    <b>Relationship Manager Full Name : </b>
                    {relationshipManagerFullName}
                  </p>
                  <p>
                    <b>Relationship Manager Employee Id : </b>
                    {relationshipManagerEmployeeId}
                  </p>
                </div>
              </>
            ) : (
              <>
                <h4
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Relationship Manager
                </h4>

                <div className="row justify-content-center mt-4">
                  <div className="col-6 text-center">
                    <Form.Group controlId="payment mode">
                      <Form.Label>Employee</Form.Label>
                      <Form.Control
                        as="select"
                        value={selectedRelationshipManager}
                        onChange={(e) =>
                          setSelectedRelationshipManager(e.target.value)
                        }
                        required
                      >
                        <option value="">Select an Option</option>
                        {employeeList?.map((employee, index) => {
                          if (
                            employee.employeeRole === "Operations" &&
                            employee.isActive === true
                          ) {
                            return (
                              <option
                                key={index}
                                value={`${employee?.userName},${employee?.employeeFullName},${employee?.employeeId}`}
                              >
                                {employee.employeeFullName}
                              </option>
                            );
                          }
                        })}
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-6 text-center">
                    <button
                      className="documents__btn mt-4"
                      disabled={
                        (userInfo?.userRole !== "Director" &&
                          userInfo?.userRole !== "Operations") ||
                        appStatus !== "Handed Over to Operations"
                      }
                      style={{
                        backgroundColor:
                          (userInfo?.userRole !== "Director" &&
                            userInfo?.userRole !== "Operations") ||
                          appStatus !== "Handed Over to Operations"
                            ? "grey"
                            : "",
                      }}
                      onClick={assignRelationshipManager}
                    >
                      Assign
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <br />

        <div
          className="row mb-3 mx-2 card-3"
          style={{ backgroundColor: "#F9F8F8" }}
        >
          <div className="col-12 tab-card pt-3 pb-3 text-center">
            {!adharScanLink ? (
              <>
                <h4
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Customer Aadhar Card Not Received
                </h4>
                <br />

                {(userInfo?.userRole === "Operations" ||
                  userInfo?.userRole === "Director") && (
                  <form
                    style={{ display: "flex" }}
                    onSubmit={(e) => uploadCA(e)}
                  >
                    <input
                      className="form-control-file"
                      type="file"
                      id="myfile"
                      name="myfile"
                      required
                      onChange={(e) => setAdharFile(e.target.files[0])}
                      style={{ backgroundColor: "white", color: "black" }}
                    />
                    <button
                      className="btn btn-user"
                      type="submit"
                      disabled={appStatus !== "Handed Over to Operations"}
                      style={{
                        display:
                          userInfo?.userRole === "Operations" ||
                          userInfo?.userRole === "Director"
                            ? "block"
                            : "none",
                        color: "white",
                        backgroundColor:
                          appStatus !== "Handed Over to Operations" && "grey",
                      }}
                    >
                      Upload Document
                    </button>
                  </form>
                )}
              </>
            ) : (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      paddingRight: "10px",
                      marginRight: "5px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      paddingTop: "5px",
                      paddingLeft: "10px",
                    }}
                  >
                    Customer Aadhar
                  </p>
                  <br />
                  <div>
                    <p>
                      <b>Document Name : </b>
                      {adharDocName}
                    </p>
                    <p>
                      <b>Uploaded By : </b>
                      {adharploadedBy}
                    </p>
                    <p>
                      <b>Upload Date : </b>
                      {adharUploadDate}
                    </p>
                    <a href={adharScanLink}>View Document</a>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <br />

        <div
          className="row mb-3 mx-2 card-4"
          style={{ backgroundColor: "#F9F8F8" }}
        >
          <div className="col-12 tab-card pt-3 pb-3 text-center">
            {!panScanLink ? (
              <>
                <h4
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Customer PAN Not Received
                </h4>
                <br />

                {(userInfo?.userRole === "Operations" ||
                  userInfo?.userRole === "Director") && (
                  <form
                    style={{ display: "flex" }}
                    onSubmit={(e) => uploadCP(e)}
                  >
                    <input
                      className="form-control-file"
                      type="file"
                      id="myfile"
                      name="myfile"
                      required
                      onChange={(e) => setPanFile(e.target.files[0])}
                      style={{ backgroundColor: "white", color: "black" }}
                    />
                    <button
                      className="btn btn-user"
                      type="submit"
                      disabled={appStatus !== "Handed Over to Operations"}
                      style={{
                        display:
                          userInfo?.userRole === "Operations" ||
                          userInfo?.userRole === "Director"
                            ? "block"
                            : "none",
                        color: "white",
                        backgroundColor:
                          appStatus !== "Handed Over to Operations" && "grey",
                      }}
                    >
                      Upload Document
                    </button>
                  </form>
                )}
              </>
            ) : (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      paddingRight: "10px",
                      marginRight: "5px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      paddingTop: "5px",
                      paddingLeft: "10px",
                    }}
                  >
                    Customer PAN
                  </p>
                  <br />
                  <div>
                    <p>
                      <b>Document Name : </b>
                      {panDocName}
                    </p>
                    <p>
                      <b>Uploaded By : </b>
                      {panUploadedBy}
                    </p>
                    <p>
                      <b>Upload Date : </b>
                      {panUploadDate}
                    </p>
                    <a href={panScanLink}>View Document</a>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <br />

        <div
          className="row justify-content-center mb-3 mx-2 card-1"
          style={{ backgroundColor: "#F9F8F8" }}
        >
          <>
            {provisionalDraftLink ? (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      paddingRight: "10px",
                      marginRight: "5px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      paddingTop: "5px",
                      paddingLeft: "10px",
                    }}
                  >
                    Provisional Allotment Letter Draft
                  </p>
                  <br />
                  <div>
                    <p>
                      <b>Document Name : </b>
                      {provisionalDocName}
                    </p>
                    <p>
                      <b>Generated By : </b>
                      {provisionalGeneratedBy}
                    </p>
                    <p>
                      <b>Generated Date : </b>
                      {provisionalDate}
                    </p>
                    <a href={provisionalDraftLink}>View Document</a>
                  </div>
                </div>
              </>
            ) : (
              <div>
                {userInfo?.userRole !== "Operations" &&
                userInfo?.userRole !== "Director" ? (
                  <h5
                    style={{
                      paddingRight: "10px",
                      marginRight: "5px",
                      fontSize: "22px",
                      fontWeight: "bold",
                      paddingTop: "5px",
                      paddingLeft: "10px",
                    }}
                  >
                    Provisional Allotment Letter Draft Not Generated
                  </h5>
                ) : (
                  <>
                    <h5
                      style={{
                        paddingRight: "10px",
                        marginRight: "5px",
                        fontSize: "22px",
                        fontWeight: "bold",
                        paddingTop: "5px",
                        paddingLeft: "10px",
                      }}
                    >
                      Provisional Allotment Letter Draft
                    </h5>

                    <br />

                    <button
                      className="documents__btn"
                      style={{
                        display:
                          userInfo?.userRole === "Operations" ||
                          userInfo?.userRole === "Director"
                            ? "block"
                            : "none",
                        backgroundColor:
                          appStatus !== "Handed Over to Operations" && "grey",
                      }}
                      disabled={appStatus !== "Handed Over to Operations"}
                      onClick={generateProvisionalDraft}
                    >
                      Generate Provisional Allotment Letter
                    </button>
                  </>
                )}
              </div>
            )}
          </>
        </div>

        <br />

        <div
          className="row mb-3 mx-2 card-8"
          style={{ backgroundColor: "#F9F8F8" }}
        >
          <div className="col-12 tab-card pt-3 pb-3 text-center">
            {!provisionalScanLink ? (
              userInfo?.userRole !== "Operations" &&
              userInfo?.userRole !== "Director" ? (
                <h5
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Provisional Allotment Letter Scan Not Uploaded
                </h5>
              ) : (
                <>
                  <h4
                    style={{
                      paddingRight: "10px",
                      marginRight: "5px",
                      fontSize: "22px",
                      fontWeight: "bold",
                      paddingTop: "5px",
                      paddingLeft: "10px",
                    }}
                  >
                    Provisional Allotment Letter Scan Copy
                  </h4>
                  <br />
                  <form onSubmit={(e) => uploadPALS(e)}>
                    <div style={{ display: "flex" }}>
                      <input
                        className="form-control-file"
                        type="file"
                        id="myfile"
                        name="myfile"
                        accept="application/pdf"
                        required
                        onChange={(e) => setProvisionalFile(e.target.files[0])}
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                      />
                      <button
                        className="btn btn-user"
                        type="submit"
                        disabled={!provisionalDraftLink}
                        style={{
                          backgroundColor: !provisionalDraftLink && "grey",
                          cursor: !provisionalDraftLink ? "defualt" : "pointer",
                          color: "white",
                        }}
                      >
                        Upload Document
                      </button>
                    </div>
                    <br />
                    <br />
                    <div
                      style={{ display: "flex" }}
                      className="row justify-content-center"
                    >
                      <div className="col-5">
                        <Form.Group controlId="payment mode">
                          <Form.Label>Courier Name</Form.Label>
                          <Form.Control
                            as="select"
                            value={courier}
                            onChange={(e) => setCourier(e.target.value)}
                            required
                          >
                            <option value="">Select an Option</option>
                            <option value="DTDC">DTDC</option>
                            <option value="Delhivery">Delhivery</option>
                            <option value="Indian Post">Indian Post</option>
                            <option value="Delivered In-Person">
                              Delivered In-Person
                            </option>
                          </Form.Control>
                        </Form.Group>
                      </div>

                      {courier !== "Delivered In-Person" && (
                        <div className="col-5">
                          <label>Tracking ID</label>
                          <input
                            className="form-control"
                            type="text"
                            required
                            value={trackingId}
                            onChange={(e) => setTrackingId(e.target.value)}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </>
              )
            ) : (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      paddingRight: "10px",
                      marginRight: "5px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      paddingTop: "5px",
                      paddingLeft: "10px",
                    }}
                  >
                    Provisional Allotment Letter Scan Copy
                  </p>
                  <br />
                  <div>
                    <p>
                      <b>Document Name : </b>
                      {provisionalScanDocName}
                    </p>
                    <p>
                      <b>Uploaded By : </b>
                      {provisionalScanUploadedBy}
                    </p>
                    <p>
                      <b>Upload Date : </b>
                      {provisionalScanUploadDate}
                    </p>
                    <p>
                      <b>Courier : </b>
                      {courier}
                    </p>
                    <p>
                      <b>Tracking ID : </b>
                      {trackingId}
                    </p>
                    <a href={provisionalScanLink}>View Document</a>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <br />

      <div className="row mb-3 mx-2 card-5">
        <div className="col-12 tab-card pt-3 pb-3 text-center">
          <h5
            style={{
              paddingRight: "10px",
              marginRight: "5px",
              fontSize: "22px",
              fontWeight: "bold",
              paddingTop: "5px",
              paddingLeft: "10px",
            }}
          >
            Update Customer and Applicant
          </h5>
          <div className="text-center">
            <em style={{ color: "red" }}>
              Use only if customer Add, Edit or Delete Applicant after
              Application is handed over to Operations. Take proper approval
              from customer in email before proceeding.
            </em>
          </div>
        </div>
        <div
          className="row justify-content-center align-items-center mt-4"
          style={{ width: "100%" }}
        >
          <div className="col-4 text-center">
            <button
              className="documents__btn"
              disabled={
                (userInfo?.userRole !== "Operations" &&
                  userInfo?.userRole !== "Director") ||
                appStatus !== "Handed Over to Operations"
              }
              style={{
                backgroundColor:
                  (userInfo?.userRole !== "Operations" &&
                    userInfo?.userRole !== "Director") ||
                  appStatus !== "Handed Over to Operations"
                    ? "grey"
                    : "",
              }}
              onClick={() => setDeleteApplicantModal(true)}
            >
              1. Delete Applicant and Customer
            </button>
          </div>
        </div>
        <br />
        <div
          className="row justify-content-center align-items-center mt-4"
          style={{ width: "100%" }}
        >
          <div className="col-4 text-center">
            <button
              className="documents__btn"
              disabled={
                (userInfo?.userRole !== "Operations" &&
                  userInfo?.userRole !== "Director") ||
                appStatus !== "Handed Over to Operations"
              }
              style={{
                backgroundColor:
                  (userInfo?.userRole !== "Operations" &&
                    userInfo?.userRole !== "Director") ||
                  appStatus !== "Handed Over to Operations"
                    ? "grey"
                    : "",
              }}
              onClick={() => {
                navigate(`./changeApplicantOperation`);
              }}
            >
              2. Add new applicant
            </button>
          </div>
        </div>
        <br />
        <div
          className="row justify-content-center align-items-center mt-4"
          style={{ width: "100%" }}
        >
          <div className="col-4 text-center">
            <button
              className="documents__btn"
              disabled={
                (userInfo?.userRole !== "Operations" &&
                  userInfo?.userRole !== "Director") ||
                appStatus !== "Handed Over to Operations"
              }
              style={{
                backgroundColor:
                  (userInfo?.userRole !== "Operations" &&
                    userInfo?.userRole !== "Director") ||
                  appStatus !== "Handed Over to Operations"
                    ? "grey"
                    : "",
              }}
              onClick={() => setRegenerateBookingDocumentsModal(true)}
            >
              3. Regenerate documents
            </button>
          </div>
        </div>
        <br />
        <div
          className="row justify-content-center align-items-center mt-4"
          style={{ width: "100%" }}
        >
          <div className="col-6 text-center">
            <button
              className="documents__btn"
              disabled={
                (userInfo?.userRole !== "Operations" &&
                  userInfo?.userRole !== "Director") ||
                appStatus !== "Handed Over to Operations"
              }
              style={{
                backgroundColor:
                  (userInfo?.userRole !== "Operations" &&
                    userInfo?.userRole !== "Director") ||
                  appStatus !== "Handed Over to Operations"
                    ? "grey"
                    : "",
              }}
              onClick={() => setUploadSAFModal(true)}
            >
              4. Upload Signed Application Form
            </button>
          </div>
        </div>
      </div>

      <br />

      {/* Modal for Delete Applicant and Customer */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={deleteApplicantModal}
        onClose={() => {
          setDeleteApplicantModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={deleteApplicantModal}>
          <div className="paper text-center" style={{ width: "30vw" }}>
            <h5>Are you sure you want to delete Applicant and Customer?</h5>
            <br />
            <div className="row justify-content-center">
              <div className="col-4">
                <button
                  className="btn btn-user"
                  onClick={deleteApplicantAndCustomer}
                >
                  Yes
                </button>
              </div>
              <div className="col-4">
                <button
                  className="btn btn-user"
                  onClick={() => {
                    setDeleteApplicantModal(false);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      {/*Modal for Regenerate Documents*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={regenerateBookingDocumentsModal}
        onClose={() => {
          setRegenerateBookingDocumentsModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={regenerateBookingDocumentsModal}>
          <div className="paper text-center" style={{ width: "30vw" }}>
            <h5>Are you sure you want to regenerate documents?</h5>
            <br />
            <div className="row justify-content-center">
              <div className="col-4">
                <button
                  className="btn btn-user"
                  onClick={regenerateBookingDocuments}
                >
                  Yes
                </button>
              </div>
              <div className="col-4">
                <button
                  className="btn btn-user"
                  onClick={() => {
                    setRegenerateBookingDocumentsModal(false);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      {/* Modal for Upload Signed Application Form Scan Copy */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={uploadSAFModal}
        onClose={() => {
          setUploadSAFModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={uploadSAFModal}>
          <div className="paper text-center" style={{ width: "30vw" }}>
            <h5>Upload Signed Application Form Scan Copy</h5>
            <br />
            <form onSubmit={(e) => uploadSAF(e)}>
              <div style={{ display: "flex" }}>
                <input
                  className="form-control-file"
                  type="file"
                  id="myfile"
                  name="myfile"
                  accept="application/pdf"
                  required
                  onChange={(e) => setSAFFile(e.target.files[0])}
                  style={{ backgroundColor: "white", color: "black" }}
                />
                <button
                  className="btn btn-user"
                  type="submit"
                  disabled={appStatus !== "Handed Over to Operations"}
                  style={{
                    backgroundColor:
                      appStatus !== "Handed Over to Operations" && "grey",
                    cursor:
                      appStatus !== "Handed Over to Operations"
                        ? "defualt"
                        : "pointer",
                    color: "white",
                  }}
                >
                  Upload Document
                </button>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>

      {/* //////////// Modal for spinner //////////// */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={loader}
        onClose={() => {
          setLoader(false);
        }}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //     timeout: 500,
        // }}
      >
        <Fade in={loader}>
          <div>
            <MoonLoader
              color="red"
              className="spinner"
              loading={loader}
              size={70}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Documents;
