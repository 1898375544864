import React from "react";
import apartmentImg1 from "../../../assets/tableView/apartment-1.png";
import apartmentImg2 from "../../../assets/tableView/apartment-2.png";
import apartmentImg3 from "../../../assets/tableView/apartment-3.png";
import "./Electricity.scss";

export default function Electricity() {
  const data = [
    "Split AC points in Master Bedroom",
    "Central MCB (Häger Reputed Brand from Germany Europe) Entrance doorbell point",
    "Adequate 16 & 5 amp points in living, dining, toilet, kitchen and all bedrooms",
    "Electrical points for TV, computer, DTH Cable, TV, Telephone and broadband in living and dining room",
    "Washing machine provision is given at balcony with 15 amps electrical point",
    "Modular switches of Anchor-Panasonic",
  ];

  return (
    <>
      <div className="electricity__images">
        <img src={apartmentImg1} alt="" />
        <div className="electricity__images__subcontainer">
          <img src={apartmentImg2} alt="" />

          <img src={apartmentImg3} alt="" />
        </div>
      </div>
      <div className="electricity__infoContainer">
        <div className="electricity__infoContainer__info">
          {data.map((text) => (
            <p>{text}</p>
          ))}
        </div>
      </div>
    </>
  );
}
