import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Button from "../../components/Button/Button";
import { BASE_URL } from '../../utils/config/url'
import { useNavigate } from "react-router-dom";
import './AddTicket.scss';


const AddTicket = () => {
  const [customerId, setCustomerId] = useState("");
  const [customers, setCustomers] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [midName, setMidName] = useState("");
  const [type, setType] = useState("");
  const [sites, setSites] = useState([]);
  const [site, setSite] = useState("");
  const [siteCode, setSiteCode] = useState("");
  const [unit, setUnit] = useState("");
  const [bhk, setBhk] = useState("");
  const [phase, setPhase] = useState("");
  const [phaseCode, setPhaseCode] = useState("");
  
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState("")


  const datas = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + datas.token;
  const navigate = useNavigate();

  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const Departments = [
    {id: 1, name: "Sales"},
    {id: 2, name: "Operations"},
    {id: 3, name: "Finance"},
    {id: 4, name: "Engineering"},
    {id: 5, name: "Maintenance"},
  ];

  const Categories = [
    {id: "A", name: "Cost Sheet", departmentName: "Sales"},
    {id: "B", name: "Fittings and Fixtures", departmentName: "Sales"},
    {id: "C", name: "Others", departmentName: "Sales"},
    {id: "D", name: "Allotment Letter", departmentName: "Operations"},
    {id: "E", name: "Sale Agreement", departmentName: "Operations"},
    {id: "F", name: "Demand", departmentName: "Operations"},
    {id: "G", name: "Loan", departmentName: "Operations"},
    {id: "H", name: "Registration", departmentName: "Operations"},
    {id: "I", name: "Alteration", departmentName: "Operations"},
    {id: "J", name: "Possession", departmentName: "Operations"},
    {id: "K", name: "Electric Form", departmentName: "Operations"},
    {id: "L", name: "Others", departmentName: "Operations"},
    {id: "M", name: "Alteration", departmentName: "Engineering"},
    {id: "N", name: "Fittings and Fixtures", departmentName: "Engineering"},
    {id: "O", name: "Billing of Alteration Work", departmentName: "Engineering"},
    {id: "P", name: "Handover/ Possession", departmentName: "Engineering"},
    {id: "Q", name: "Others", departmentName: "Engineering"},
    {id: "R", name: "Demand", departmentName: "Finance"},
    {id: "S", name: "Money Receipt", departmentName: "Finance"},
    {id: "T", name: "Refund", departmentName: "Finance"},
    {id: "U", name: "Cancellation", departmentName: "Finance"},
    {id: "V", name: "Ledger Statement", departmentName: "Finance"},
    {id: "W", name: "Others", departmentName: "Finance"},
    {id: "X", name: "Fittings and Fixtures", departmentName: "Maintenance"},
    {id: "Y", name: "Warranty Claim", departmentName: "Maintenance"},
    {id: "Z", name: "Defect Liability", departmentName: "Maintenance"},
    {id: "AA", name: "Others", departmentName: "Maintenance"},
  ];

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
    setSelectedCategory("");
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

 
  ///create ticket
  const submit = (e) => {
    e.preventDefault();
    axios
      .post(
        `${BASE_URL}/api/v1/customermgmt/ticket/createNewTicket`,
        {
          ticketType: type,
          ticketCategory: selectedCategory,
          description: description,
          customerId: customerId,
          siteCode: siteCode,
          siteName: site,
          phaseCode: phaseCode,
          phaseName: phase,
          unitName: unit,
          bhk: bhk,
          ticketPriority: priority,
          ticketDepartment: selectedDepartment
        },
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        // console.log(response);
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            navigate("/support");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      })
      .cathc((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      })
  };
  //


  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/Configuration/Site/getAllSiteNames`, {
        headers: { Authorization: Token },
      })
      .then((response) => {
        setSites(response.data.siteList);
      });
  }, []);


  useEffect(() => {
    if (siteCode) {
      axios
        .get(`${BASE_URL}/api/v1/CustomerMgmt/Customer/getListOfCustomers?siteCode=${siteCode}&isActive=${true}`, {
          headers: { Authorization: Token },
        })
        .then((response) => {
          setCustomers(response.data.customerList);
        });
    }
  }, [siteCode]);
  //


  useEffect(() => {
    if (customerId) {
      axios
        .get(`${BASE_URL}/api/v1/CustomerMgmt/customer/getCustomerByCustomerId/${customerId}`, {
          headers: { Authorization: Token },
        })
        .then((response) => {
          const customer = response.data.customer;
          // console.log(customer);
          setFirstName(customer?.firstName);
          setLastName(customer?.lastName);
          setMidName(customer?.middleName);
          setPhase(customer?.siteDetails?.phaseName);
          setPhaseCode(customer?.siteDetails?.phaseCode);
          setUnit(customer?.unit?.unitName);
          setBhk(customer?.unit?.bhk);
        });
    }
  }, [customerId]);


  //reset
  const resetField = () => {
    setSite("");
    setSiteCode("");
    setCustomerId("");
    setFirstName("");
    setLastName("");
    setMidName("");
    setUnit("");
    setPhase("");
    setType("");
    selectedCategory("");
    setDescription("");
    setPriority("");
    setSelectedDepartment("");
  };


  const handleSite = (v) => {
    const value = v.split(", ");
    setSiteCode(value[0]);
    setSite(value[1]);
  };

  return (
    <div className="outlet_bg">
      <div className="outlet_container">
        <Button navigateTo={1} />
        <form className="customform" onSubmit={submit}>
          <div className="row justify-content-center">
            <div className="col-4">
              <label>Site Name</label>
              <select
                class="form-control"
                id="site"
                onChange={(e) => {
                  handleSite(e.target.value);
                }}
              >
                <option selected>
                  ---Select Site---
                </option>
                {sites?.map((s, i) => (
                  <option value={`${s?.SiteCode}, ${s?.SiteName}`}>{s.SiteName}</option>
                ))}
              </select>
            </div>
            <div className="col-4">
              <label>Customer Id</label>
              <select class="form-control" id="customer" onChange={(e) => setCustomerId(e.target.value)}>
                <option selected>
                  ---Select Customer---
                </option>
                {customers?.map((s, i) => (
                  <option value={s?.customerId}>{s.customerId}</option>
                ))}
              </select>
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <label>First Name</label>
              <input type="text" className="form-control" value={firstName} disabled />
            </div>
            <div className="col-4">
              <label>Middle Name</label>
              <input type="text" className="form-control" value={midName} disabled />
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <label>Last Name</label>
              <input type="text" className="form-control" value={lastName} disabled />
            </div>
            {/* <div className="col-4">
              <label>Customer ID</label>
              <input type="text" className="form-control" value={customerId} disabled />
            </div> */}
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <label>Unit Name</label>
              <input type="text" className="form-control" value={unit} disabled />
            </div>
            <div className="col-4">
              <label>Phase Name</label>
              <input type="text" className="form-control" value={phase} disabled />
            </div>
          </div>

          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <label>Ticket Type</label>
              <select
                class="form-control"
                id="tickettype"
                onChange={(e) => {
                  // handleClearCat(e.target.value);
                  setType(e.target.value)
                }}
                required
              >
                <option selected>
                  ---Select Ticket Type---
                </option>
                <option value="Complaint">Complaint</option>
                <option value="Service Request">Service Request</option>
              </select>
            </div>
            <div className="col-4">
              <label>Priority</label>
              <select
                class="form-control"
                id="ticketpriority"
                onChange={(e) => {
                  // handleClearCat(e.target.value);
                  setPriority(e.target.value)
                }}
                required
              >
                <option selected>
                  ---Select Ticket Priority---
                </option>
                <option value="High">High</option>
                <option value="Normal">Normal</option>
              </select>
            </div>
          </div>
          <br />
          <div className="row justify-content-center">

            <div className="col-4">
              <label>Department</label>
              <select
                class="form-control"
                id="ticketdept"
                onChange={handleDepartmentChange}
                value={selectedDepartment}
              >
                <option selected>
                  ---Select Department---
                </option>
                {Departments.map((department) => (
                  <option key={department.id} value={department.name}>{department.name}</option>
                ))}
              </select>
            </div>

            <div className="col-4">
              <label>Ticket Category</label>
              <select
                class="form-control"
                id="ticketcat"
                onChange={handleCategoryChange}
                value={selectedCategory}
              >
                <option selected>
                  ---Select Ticket Category---
                </option>
                {Categories.filter((category) => category.departmentName === selectedDepartment).map((category) => (
                  <option key={category.id} value={category.name}>{category.name}</option>
                ))}
              </select>
            </div>
            {/*  */}

          </div>
          <br />

          <div className="row justify-content-center">
            <div className="col-8">
              <label>Description</label>
              <textarea className="form-control" cols="30" rows="5" onChange={(e) => setDescription(e.target.value)} required></textarea>
            </div>
          </div>

          <br />
          <div className="row container-fluid justify-content-center">
            <div className="col-4 text-right">
              <button className="btn btn-secondary btn-user"
                type="reset"
                style={{ backgroundColor: "white", color: "black" }}
                onClick={resetField}
              >
                Reset
              </button>
            </div>
            <div className="col-4">
              <button className="btn btn-secondary btn-user" type="submit">
                Add Ticket
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTicket;
