import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";

const CustomerDetails = ({ invCustomer, setChange, change }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  //console.log(invCustomer)

  const [salutation, setSalutation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  useEffect(() => {
    setSalutation(invCustomer.salutation);
    setFirstName(invCustomer.custFirstName);
    setMiddleName(invCustomer.custMiddleName);
    setLastName(invCustomer.custLastName);
    setEmail(invCustomer.custRegEmail);
    setMobile(invCustomer.custRegMobile);
  }, []);

  // edit and save
  const saveCustomer = (e) => {
    axios
      .put(
        `${BASE_URL}/api/v1/CustomerMgmt/Customer/updateCustomerByCustomerId`,
        {
          customerId: invCustomer.customerId,
          salutation: salutation,
          firstName: firstName,
          middleName: middleName,
          lastName: lastName,
          registeredEmail: email,
          registeredMobile: mobile,
        },
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setChange(!change);
          Swal.fire({
            icon: "success",
            title: "success",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        } else {
          Swal.fire({
            icon: "failure",
            title: "failure",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      });
  };

  return (
    <div>
      <div className="user_details mt-4">
        <div className="px-3 py-4">
          <div className="lead_details">
            <div className="d-flex justify-content-between align-items-center px-3 mb-4">
              <div className="pb-0">
                <h5>Customer Details</h5>
              </div>
              {/* <button className="close_btn bg-secondary">Close Lead</button> */}
            </div>

            <div className="shadow p-4 my-4 rounded">
              <div className="row">
                <div className="col-xl-6 col-sm-12">
                  <div className="user_info">
                    <label>Customer Id</label>
                    <Form.Control
                      type="text"
                      value={invCustomer?.customerId}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <div className="user_info">
                    <label>Application Id</label>
                    <Form.Control
                      type="text"
                      defaultValue={invCustomer?.applicationId}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-xl-3 col-sm-6">
                  <div className="user_info">
                    <Form.Label>Salutation</Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue={invCustomer?.salutation}
                      disabled
                      onChange={(e) => setSalutation(e.target.value)}
                    >
                      <option>{invCustomer?.salutation}</option>
                      <option value="Mr.">Mr.</option>
                      <option value="Mrs.">Mrs.</option>
                      <option value="Ms.">Ms.</option>
                      <option value="Dr.">Dr.</option>
                    </Form.Control>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="user_info">
                    <label>First Name</label>
                    <Form.Control
                      type="text"
                      defaultValue={invCustomer?.firstName}
                      disabled
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="user_info">
                    <label>Middle Name</label>
                    <Form.Control
                      type="text"
                      defaultValue={invCustomer?.middleName}
                      disabled
                      onChange={(e) => {
                        setMiddleName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="user_info">
                    <label>Last Name</label>
                    <Form.Control
                      type="text"
                      defaultValue={invCustomer?.lastName}
                      disabled
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className="col-xl-6 col-sm-12">
                  <div className="user_info">
                    <label>Customer Mobile</label>
                    <Form.Control
                      type="number"
                      defaultValue={invCustomer?.registeredMobile}
                      onChange={(e) => {
                        setMobile(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <div className="user_info">
                    <label>Customer Email</label>
                    <Form.Control
                      type="email"
                      value={invCustomer?.registeredEmail}
                      readOnly
                      className="bg-white"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="d-flex justify-content-center">
                <button
                  className="save_btn"
                  style={{ backgroundColor: "#EE4B46" }}
                  onClick={(e) => {
                    saveCustomer(e);
                  }}
                >
                  Save
                </button>
              </div>
            </div>

            {/*full Address  */}
            <div className="d-flex mt-5 px-3">
              <div className=" pb-0">
                <h5>Customer Address </h5>
              </div>
              {/* <button className="close_btn bg-secondary">Close Lead</button> */}
            </div>
            <div className="shadow p-4 my-4">
              <div className="">
                <div className="row">
                  <div className="col-xl-4 col-sm-8">
                    <div className="user_info">
                      <Form.Label>Full Address</Form.Label>
                      <Form.Control
                        type="text"
                        value={invCustomer?.customerAddress?.fullAddress}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-8">
                    <div className="user_info">
                      <label>Landmark</label>
                      <Form.Control
                        type="text"
                        value={invCustomer?.customerAddress?.landmark}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-8">
                    <div className="user_info">
                      <label>State</label>
                      <Form.Control
                        type="text"
                        value={invCustomer?.customerAddress?.state}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="row my-3">
                  <div className="col-xl-3 col-sm-6">
                    <div className="user_info">
                      <label>City</label>
                      <Form.Control
                        type="text"
                        value={invCustomer?.customerAddress?.city}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-6">
                    <div className="user_info">
                      <Form.Label>Pin Code</Form.Label>
                      <Form.Control
                        type="text"
                        value={invCustomer?.customerAddress?.pinCode}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-6">
                    <div className="user_info">
                      <label>Post Officee</label>
                      <Form.Control
                        type="text"
                        value={invCustomer?.customerAddress?.postOffice}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-6">
                    <div className="user_info">
                      <label>Police Station</label>
                      <Form.Control
                        type="text"
                        value={invCustomer?.customerAddress?.policeStation}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* customer coresspondence address  */}
            <div className="d-flex justify-content-between align-items-center px-3 mt-5">
              <div className=" pb-0">
                <h5>Customer Correspondent Address </h5>
              </div>
              {/* <button className="close_btn bg-secondary">Close Lead</button> */}
            </div>
            <div className="shadow p-4 my-4">
              <div className="row ">
                <div className="col-xl-4 col-sm-8">
                  <div className="user_info">
                    <Form.Label>Full Address</Form.Label>
                    <Form.Control
                      type="text"
                      value={invCustomer?.correspondentAddress?.fullAddress}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-8">
                  <div className="user_info">
                    <label>Landmark</label>
                    <Form.Control
                      type="text"
                      value={invCustomer?.correspondentAddress?.landmark}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-8">
                  <div className="user_info">
                    <label>State</label>
                    <Form.Control
                      type="text"
                      value={invCustomer?.correspondentAddress?.state}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="row my-3">
                <div className="col-xl-3 col-sm-6">
                  <div className="user_info">
                    <label>City</label>
                    <Form.Control
                      type="text"
                      value={invCustomer?.correspondentAddress?.city}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="user_info">
                    <Form.Label>Pin Code</Form.Label>
                    <Form.Control
                      type="text"
                      value={invCustomer?.correspondentAddress?.pinCode}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="user_info">
                    <label>Post Officee</label>
                    <Form.Control
                      type="text"
                      value={invCustomer?.correspondentAddress?.postOffice}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="user_info">
                    <label>Police Station</label>
                    <Form.Control
                      type="text"
                      value={invCustomer?.correspondentAddress?.policeStation}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Customer Personal Data */}
            <div className="d-flex justify-content-between align-items-center px-3 mt-5">
              <div className=" pb-0">
                <h5>Customer Personal Data </h5>
              </div>
            </div>

            <div className="shadow p-4 my-4">
              <div className="row ">
                <div className="col-xl-4 col-sm-8">
                  <div className="user_info">
                    <Form.Label>Occupation Type</Form.Label>
                    <Form.Control
                      type="text"
                      value={invCustomer?.occupationType}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-8">
                  <div className="user_info">
                    <Form.Label>Occupation</Form.Label>
                    <Form.Control
                      type="text"
                      value={invCustomer?.occupation}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-8">
                  <div className="user_info">
                    <label>Customer Registered Mobile</label>
                    <Form.Control
                      type="text"
                      value={invCustomer?.registeredMobile}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="row my-3 justify-content-center">
                <div className="col-xl-4 col-sm-8">
                  <div className="user_info">
                    <label> Customer Registered Email</label>
                    <Form.Control
                      type="text"
                      value={invCustomer?.registeredEmail}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-sm-8">
                  <div className="user_info">
                    <Form.Label>Aadhar</Form.Label>
                    <Form.Control
                      type="text"
                      value={invCustomer?.customerAadhar}
                      readOnly
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-sm-8">
                  <div className="user_info">
                    <label>PAN</label>
                    <Form.Control
                      type="text"
                      value={invCustomer?.customerPAN}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
