import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../utils/config/url'
import { useParams } from 'react-router-dom'
import '../../Pages/CompOff/CompOff.scss';
import Button from '../Button/Button';


const InvCompOff = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const compOffId = useParams().compOffId;

    const [compOff, setCompOff] = useState({})

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/compOff/getCompOffByCompOffId/${compOffId}`,
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                if (response.data.status) {
                    setCompOff(response.data.compOff)
                } else {
                    setCompOff({})
                }
            })

    }, [])

    const handleInputChange = (e, field) => {

        setCompOff({
            ...compOff,
            [field]: e.target.value
        })

    }



    const dateFormatting = (input) => {
        if (!input) return;

        let date = new Date(input).getDate();
        if (date < 10) {
            date = `0${date}`
        }
        let month = new Date(input).getMonth() + 1;
        if (month < 10) {
            month = `0${month}`
        }
        let year = new Date(input).getFullYear();

        return `${year}-${month}-${date}`
    }

    return (
        <form className='compOff'>

            <Button navigateTo="/leavemanagement/compOff" />

            <br />

            <h3 className='text-center'
                style={{
                    color: '#EE4B46'
                }}
            >CompOff Id ~ {compOffId}</h3>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Comp Off Status</label>
                    <input type="text" className='form-control' value={compOff?.compOffStatus}
                        onChange={(e) => handleInputChange(e, 'compOffStatus')}
                        readOnly
                    />
                </div>

                <div className='col-6'>
                    <label>Comp Off Type</label>
                    <input type="text" className='form-control' value={compOff?.compOffType}
                        onChange={(e) => handleInputChange(e, 'compOffType')}
                        readOnly
                    />
                </div>
            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Comp Off Date</label>
                    <input type="date" className='form-control' value={dateFormatting(compOff?.compOffDate)}
                        onChange={(e) => handleInputChange(e, 'compOffDate')}
                        readOnly
                    />
                </div>

                <div className='col-6'>
                    <label>Applied Date</label>
                    <input type="date" className='form-control' value={dateFormatting(compOff?.appliedDate) || ''}
                        onChange={(e) => handleInputChange(e, 'appliedDate')}
                        readOnly
                    />
                </div>
            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-8'>
                    <label>Comp Off Description</label>
                    <textarea type="text" className='form-control' value={compOff?.compOffDescription}
                        onChange={(e) => handleInputChange(e, 'compOffDescription')}
                        readOnly
                    />
                </div>
            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Raised By FullName</label>
                    <input type="text" className='form-control' value={compOff?.raisedByFullName}
                        onChange={(e) => handleInputChange(e, 'raisedByFullName')}
                        readOnly
                    />
                </div>

                <div className='col-6'>
                    <label>Raised By Employee Id</label>
                    <input type="text" className='form-control' value={compOff?.raisedByEmpId}
                        onChange={(e) => handleInputChange(e, 'raisedByEmpId')}
                        readOnly
                    />
                </div>
            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Line Manager FullName</label>
                    <input type="text" className='form-control' value={compOff?.lineManagerFullName}
                        onChange={(e) => handleInputChange(e, 'lineManagerFullName')}
                        readOnly
                    />
                </div>

                <div className='col-6'>
                    <label>Line Manager Employee Id</label>
                    <input type="text" className='form-control' value={compOff?.lineManagerEmpId}
                        onChange={(e) => handleInputChange(e, 'lineManagerEmpId')}
                        readOnly
                    />
                </div>
            </div>

            <br />

            {
                (compOff?.compOffStatus === 'Approved' || compOff?.compOffStatus === 'Rejected')
                &&
                <div className='row justify-content-center'>
                    <div className='col-3'>
                        <label>{compOff?.compOffStatus === 'Approved' ? 'Approval' : 'Rejection'} Date</label>
                        <input type="date" className='form-control' value={dateFormatting(compOff?.approvalDate)}
                            readOnly
                            onChange={(e) => handleInputChange(e, 'approvalDate')}
                        />
                    </div>

                    <div className='col-6'>
                        <label>{compOff?.compOffStatus === 'Approved' ? 'Approval' : 'Rejection'} Comment</label>
                        <textarea type="text" className='form-control' value={compOff?.approvalComment}
                            readOnly
                        />
                    </div>
                </div>
            }

            <br />

            {/* <div className='row justify-content-center'>
                <div className='col-6 text-center'>
                    <button className='compOff__btn'
                    
                    >Submit</button>
                </div>
            </div> */}

        </form>
    )
}

export default InvCompOff;
