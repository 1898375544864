import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../utils/config/url'
import axios from 'axios'
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Swal from 'sweetalert2';
import { Form } from 'react-bootstrap';

const HRApproved = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const [salaryAdvanceList, setSalaryAdvanceList] = useState([])
    const [disburseModal, setDisburseModal] = useState(false)
    const [salaryAdvance, setSalaryAdvance] = useState({})
    const [employee, setEmployee] = useState({})
    const [costCenter, setCostCenter] = useState('')
    const [costCenterList, setCostCenterList] = useState([])
    const [finYear, setFinYear] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [transferMethod, setTransferMethod] = useState('')
    const [payerBankName, setPayerBankName] = useState('')
    const [salaryAdv, setSalaryAdv] = useState('')
    const [payerBankAccounts, setPayerBankAccounts] = useState([])
    const [payerBankAccount, setPayerBankAccount] = useState('')


    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/financemgmt/costcenter/getListOfCostCenter`, {
            headers: {
                Authorization: `bearer ${userInfo.token}`
            }
        })
            .then((response) => {
                // console.log(response)
                if (response.data.status === true) {
                    setCostCenterList(response.data.Cost_Centers)
                } else {
                    setCostCenterList([])
                }
            })
    }, [])


    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/salaryAdvance/getListOfSalaryAdvance?status=Approved`,
            {
                headers: {
                    Authorization: `Bearer ${userInfo?.token}`
                }
            })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status) {
                    setSalaryAdvanceList(response.data.SalaryAdvance)
                } else {
                    setSalaryAdvanceList([])
                }
            })


    }, [])


    useEffect(() => {

        if (!costCenter) return;

        axios.get(`${BASE_URL}/api/v1/financemgmt/costCenter/getCostCenterByCostCenterCode/${costCenter.split(',')[0]}`,
            {
                headers: {
                    Authorization: `bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response)
                if (response.data.status) {
                    setPayerBankAccounts(response?.data?.Cost_Center?.bankAccounts)
                } else {
                    setPayerBankAccounts([])
                }
            })

    }, [costCenter])


    useEffect(() => {

        // console.log(salaryAdv)

        if (!salaryAdv?.employeeId) return;

        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${salaryAdv?.employeeId}`,
            {
                headers: {
                    Authorization: `Bearer ${userInfo?.token}`
                }
            })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status) {
                    setEmployee(response.data.employee)
                } else {
                    setEmployee({})
                }
            })

    }, [salaryAdv])

    const dateFormatting = (date) => {
        let d = new Date(date)
        let year = d.getFullYear()
        let month = d.getMonth() + 1
        let day = d.getDate()

        return `${day}/${month}/${year}`
    }

    const handleDisburse = (e) => {

        e.preventDefault();

        axios.patch(`${BASE_URL}/api/v1/financeMgmt/payment/disburseSalaryAdvance`,
            {
                salaryAdvanceId: salaryAdv?.salaryAdvanceId,
                payeeEmployeeId: salaryAdv?.employeeId,
                payerEmployeeId: userInfo?.employeeId,
                transferMethod,
                transactionId,
                payerBankName: payerBankAccount.split(',')[1],
                payerAccountNumber: payerBankAccount.split(',')[0],
                payerBranchName: payerBankAccount.split(',')[4],
                costCenterName: costCenter.split(',')[1],
                costCenterCode: costCenter.split(',')[0],
                finYear: finYear,
                amount: salaryAdv?.approvedAmount
            }, {
            headers: {
                Authorization: `Bearer ${userInfo?.token}`
            }
        })
            .then((response) => {

                setDisburseModal(false)
                // console.log(response.data)
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                        timer: 2000
                    })
                        .then(() => {
                            setSalaryAdvanceList(response.data.salaryAdvance)
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                        timer: 2000
                    })
                }
            })
            .catch((err) => {
                setDisburseModal(false)
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error,
                    timer: 2000
                })
            })
    }


    return (
        <div>
            <MaterialTable
                data={salaryAdvanceList}
                title="Salary Advance List"
                columns={[
                    {
                        title: "Action",
                        render: rowData => <button className='salary-advance__btn'
                            onClick={() => {
                                setSalaryAdv(rowData)
                                setDisburseModal(true)
                            }}
                        >Disburse</button>
                    },

                    {
                        title: "Applied By",
                        render: (rowData) => {
                            return `${rowData?.employeeFullName || ''} (${rowData?.employeeId})`
                        }
                    },
                    { title: "Applied By EmpId", field: "employeeId" },

                    {
                        title: "Salary Advance Id",
                        field: 'salaryAdvanceId'
                    },
                    { title: 'Approved Amount', field: 'approvedAmount' },
                    { title: "Approved By Full Name", field: "approvedByFullName" },
                    { title: "Approved By Employee Id", field: "approvedByEmpid" },


                    { title: 'Approval Comment', field: 'approvalComment' },

                    {
                        title: "Approval Date",
                        field: 'approvalDate',
                        render: rowData => (rowData?.approvalDate ? dateFormatting(rowData.approvalDate) : 'N/A')
                    },



                ]}

                // actions={[
                //     {
                //         icon: 'remove_red_eye',
                //         tooltip: 'View Employee',
                //         disabled: (userInfo?.userRole === 'Director' || userInfo?.userRole === "HR" || userInfo?.userRole === "Finance") ? false : true,
                //         onClick: (event, rowData) => {
                //             navigate(`/viewemployees/${rowData.employeeId}`)
                //         },
                //     },

                // ]}


                options={{
                    search: true,
                    searchFieldStyle: {
                        width: 180
                    },
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    actionsCellStyle: {
                        width: 120,
                        paddingLeft: 30,
                    },
                    sorting: true,
                    headerStyle: {
                        backgroundColor: "#EE4B46",
                        color: "#fff",
                        paddingLeft: "11px",
                    },
                }}


            ></MaterialTable >

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={disburseModal}
                onClose={() => {
                    setDisburseModal(false)
                }}
                closeAfterTransition
            >
                <Fade in={disburseModal}>

                    <form className='paper w-50'
                        onSubmit={(e) => handleDisburse(e)}
                    >
                        <div className='row d-flex justify-content-center'>
                            <div className='col-6 text-center'>
                                <label>Bank Name</label>
                                <input type="text" className='form-control' readOnly value={employee?.salaryAccount?.bankName} />
                            </div>

                            <div className='col-6 text-center'>
                                <label>Account Number</label>
                                <input type="number" className='form-control' readOnly value={employee?.salaryAccount?.accountNumber} />
                            </div>

                        </div>

                        <br />

                        <div className='row d-flex justify-content-center'>
                            <div className='col-6 text-center'>
                                <label>IFSC code</label>
                                <input type="text" className='form-control' readOnly value={employee?.salaryAccount?.IFSCCode} />
                            </div>

                            <div className='col-6 text-center'>
                                <label>Branch Name</label>
                                <input type="text" className='form-control' readOnly value={employee?.salaryAccount?.branchName} />
                            </div>

                        </div>


                        <br />

                        <div className='row d-flex justify-content-center'>
                            <div className='col-6 text-center'>
                                <label>Approved Amount</label>
                                <input type="number" className='form-control' readOnly value={salaryAdv?.approvedAmount} />
                            </div>

                            <div className='col-6 text-center'>
                                <label>Transaction Id</label>
                                <input type="text" className='form-control' value={transactionId}
                                    onChange={(e) => {
                                        setTransactionId(e.target.value)
                                    }}
                                />
                            </div>

                        </div>

                        <br />

                        <div className='row container-fluid justify-content-center'>
                            <div className='col-6'>
                                <Form.Group controlId="cost center">
                                    <Form.Label>Cost Center</Form.Label>
                                    <Form.Control as="select" required
                                        value={costCenter}
                                        onChange={(e) => {
                                            setCostCenter(e.target.value)
                                        }}
                                    >
                                        <option value="">Select a Center</option>
                                        {
                                            costCenterList?.map((center, index) => {
                                                return <option value={`${center?.costCenterCode},${center?.costCenterName}`}>{center?.costCenterName}</option>
                                            })
                                        }
                                    </Form.Control>
                                </Form.Group>
                                <p>{costCenter.split(',')[0]}</p>
                            </div>

                            <div className='col-6'>
                                <Form.Group controlId="financial year">
                                    <Form.Label>Financial Year</Form.Label>
                                    <Form.Control as="select" required
                                        value={finYear}
                                        onChange={(e) => {
                                            setFinYear(e.target.value)
                                        }}
                                    >
                                        <option value="">Select a Year</option>
                                        <option value="2022-23">2022-2023</option>
                                        <option value="2023-24">2023-2024</option>
                                        <option value="2024-25">2024-2025</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>


                        {/* <div className="row container-fluid justify-content-center">

                            <div className='col-6'>
                                <Form.Group controlId="Site-Name">
                                    <Form.Label>Payer Bank Name</Form.Label>
                                    <Form.Control as="select" required
                                        value={payerBankName}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            setPayerBankName(value);
                                            if (value === 'WESTROAD DEVELOPERS PVT LTD A/C') setPayerBankAccount('50442540521')
                                            else if (value === 'WESTROAD HEIGHTS COLLECTION A/C') setPayerBankAccount('38861908851')

                                        }}
                                    >
                                        <option value="">Select a Bank</option>
                                        <option value="WESTROAD DEVELOPERS PVT LTD A/C">WESTROAD DEVELOPERS PVT LTD A/C</option>
                                        <option value="WESTROAD HEIGHTS COLLECTION A/C">WESTROAD HEIGHTS COLLECTION A/C</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>

                            <div className='col-6'>
                                <label>Payer Bank Account</label>
                                <input type="text" className='form-control' required readOnly value={payerBankAccount} />
                            </div>

                        </div> */}

                        <br />

                        <div className="row container-fluid justify-content-center">

                            <div className='col-6'>
                                <Form.Group controlId="Site-Name">
                                    <Form.Label>Payer Bank Account</Form.Label>
                                    <Form.Control as="select" required
                                        value={payerBankAccount}
                                        onChange={(e) => {
                                            if (e.target.value === '') setPayerBankAccount('');
                                            else { setPayerBankAccount(e.target.value) }
                                        }}
                                    >
                                        <option value=''>Select a Bank</option>
                                        {
                                            payerBankAccounts?.map((account, index) => {
                                                return <option key={index} value={`${account?.accountNumber},${account?.bankName},${account?.accountHolderName},${account?.IFSCCode},${account?.branchName}`}>{account?.accountNumber}</option>
                                            })
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </div>

                            <div className='col-6'>
                                <label>Payer Bank Name</label>
                                <input type="text" className='form-control' required readOnly value={payerBankAccount ? payerBankAccount.split(',')[1] : ''} />
                            </div>

                        </div>

                        <br />

                        <div className="row container-fluid justify-content-center">

                            <div className='col-6'>
                                <label>Payer Account Holder</label>
                                <input type="text" className='form-control' required readOnly value={payerBankAccount ? payerBankAccount.split(',')[2] : ''} />
                            </div>

                            <div className='col-6'>
                                <label>Payer IFSC Code</label>
                                <input type="text" className='form-control' required readOnly value={payerBankAccount ? payerBankAccount.split(',')[3] : ''} />
                            </div>
                        </div>

                        <br />

                        <div className="row container-fluid justify-content-center">

                            <div className='col-6'>
                                <label>Payer Branch Name</label>
                                <input type="text" className='form-control' required readOnly value={payerBankAccount ? payerBankAccount.split(',')[4] : ''} />
                            </div>

                        </div>


                        <br />


                        <div className='row container-fluid justify-content-center'>
                            <div className='col-6'>
                                <Form.Group controlId="financial year">
                                    <Form.Label>Transfer Method</Form.Label>
                                    <Form.Control as="select" required
                                        value={transferMethod}
                                        onChange={(e) => {
                                            setTransferMethod(e.target.value)
                                        }}
                                    >
                                        <option value="">Select a Method</option>
                                        <option>NEFT</option>
                                        <option>RTGS</option>
                                        <option>IMPS</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>

                        </div>


                        <br />
                        <br />

                        <div className='row justify-content-center'>
                            <div className='col-6 text-center'>
                                <button className='payroll__btn'
                                    type='submit'
                                >Submit</button>
                            </div>
                        </div>
                    </form>

                </Fade>
            </Modal>


        </div>
    )
}

export default HRApproved