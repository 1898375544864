import axios from "axios";
import MaterialTable from "material-table";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";

const ListOfComments = ({ invLead, userInfo, setChange, change, leadId }) => {

  const [commentText, setCommentText] = useState("");

  const handleAddComment = () => {
    axios.post(
      `${BASE_URL}/api/v1/leadmgmt/lead/addCommentByLeadId`,
      {
        leadID: invLead.leadID,
        comment: {
          comment: commentText,
          commentedBy: userInfo.userFullName,
          commentType: "Manual",
        },
      },
      { headers: { Authorization: `bearer ${userInfo.token}` } }
    ).then(res => {

      if (res.data.status) {
        setChange(!change)
        setCommentText("")
        Swal.fire(" Successfully!", "Comment Added!", "success");
      }
      else {
        Swal.fire(" Something Wrong!", "Comment Not Added!", "error");
      }
    });

  };

  const dateFormatting = (date) => {

    let str = new Date(date)

    let d = new Date(str).getDate();
    if (d < 10) {
      d = `0${d}`
    }
    let m = new Date(str).getMonth() + 1;
    if (m < 10) {
      m = `0${m}`
    }
    let y = new Date(str).getFullYear();

    return `${d}-${m}-${y}`
  }


  return (
    <div className="user_details p-4 mt-4">
      <div className="row  justify-content-center">
        <div className="user_info col-lg-8 ">
          <textarea
            type="text"
            className="form-control my-2"
            rows="2"
            value={commentText}
            style={{ resize: "none" }}
            onChange={(e) => setCommentText(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 d-flex justify-content-end mb-3">
          <button onClick={handleAddComment} className="create_btn px-3">
            Add Comment
          </button>
        </div>
      </div>
      <MaterialTable
        title="Comments"
        data={invLead.comments}
        columns={[
          {
            title: "Comment",
            render: (rowData) => rowData.comment,
          },
          {
            title: "Commented At",
            render: (rowData) => {
              if (rowData.commentTime) {
                return dateFormatting(rowData.commentTime)
              } else {
                return ''
              }
            },
            customSort: (a, b) => (a.commentTime < b.commentTime ? -1 : 1),
            defaultSort: 'desc'
          },
          {
            title: "Commented By",
            render: (rowData) => rowData.commentedBy,
          },
          {
            title: "Comment Type",
            render: (rowData) => rowData.commentType,
          },
        ]}

        options={{
          search: true,
          searchFieldStyle: {
            width: 'auto'
          },
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
          },
        }}
      />
    </div>
  );
};

export default ListOfComments;
