import { Navigate} from "react-router-dom";
import AddSiteForm from "./AddSiteForm.js";

const AddSite=()=>{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if( userInfo.token === undefined ) {
    return <Navigate to="/login" noThrow />
  }
  else {
  return <AddSiteForm/>;
  }
}

export default AddSite;