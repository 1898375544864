import axios from "axios";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";
import { dateFormatting } from "../../utils/dateFormatting";

const CustomerFinance = ({ invCustomer, setChange, change }) => {
  const [ifscCode, setIfscCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [branch, setBranch] = useState("");
  const [validated, setValidated] = useState(false);
  const [loanBankName, setLoanBankName] = useState("");
  const [loanBranchName, setLoanBranchName] = useState("");
  const [loanApplicationNo, setLoanApplicationNo] = useState("");
  const [loanApplicationDate, setLoanApplicationDate] = useState("");
  const [preferredBank, setPreferredBank] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loanSanctioned, setLoanSanctioned] = useState(false);

  // finance tab
  // save bank details
  const handleIfsc = (e) => {
    let value = e.target.value;
    setIfscCode(value);
    const regex = /^[A-Z0-9]{12}$/;
    if (regex.test(value)) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  };

  useEffect(() => {
    setIfscCode(invCustomer?.custBankAc?.IFSCCode);
    setBankName(invCustomer?.custBankAc?.bankName);
    setAccountNo(invCustomer?.custBankAc?.accountNo);
    setBranch(invCustomer?.custBankAc?.branch);
    setValidated(true);
  }, [change, invCustomer]);

  const handleLoanSanctioned = (e) => {
    e.preventDefault();
    const Token = "bearer" + " " + userInfo.token;
    axios
      .put(
        `${BASE_URL}/api/v1/customermgmt/customer/updateCustomerByCustomerId`,
        {
          customerId: invCustomer?.customerId,
          loanApplicationStatus: "Sanctioned",
          loanSanctionDate: loanSanctioned,
        },
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        //console.log(response.data.status);
        if (response?.data?.status === true) {
          setChange(!change);
          Swal.fire(
            "Successfully!",
            "Loan Application Details Updated!",
            "success"
          );
        } else {
          Swal.fire("Something Wrong!", "Details Not Updated!", "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loanApplied = (e) => {
    e.preventDefault();
    const Token = "bearer" + " " + userInfo.token;
    axios
      .put(
        `${BASE_URL}/api/v1/customermgmt/customer/updateCustomerByCustomerId`,
        {
          customerId: invCustomer?.customerId,
          loanBankName: loanBankName,
          loanBranchName: loanBranchName,
          loanApplicationNo: loanApplicationNo,
          loanApplicationDate: loanApplicationDate,
          loanApplicationStatus: "Applied",
          isLoanApplied: true,
        },
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        //console.log(response.data.status);
        if (response?.data?.status === true) {
          setChange(!change);
          Swal.fire(
            "Successfully!",
            "Loan Application Details Updated!",
            "success"
          );
        } else {
          Swal.fire("Something Wrong!", "Details Not Updated!", "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveBankDetails = (e) => {
    e.preventDefault();
    const Token = "bearer" + " " + userInfo.token;
    if (validated === true) {
      axios
        .put(
          `${BASE_URL}/api/v1/CustomerMgmt/Customer/updateCustomerBankDetails`,
          {
            customerId: invCustomer.customerId,
            custBankAc: {
              accountNo: accountNo,
              bankName: bankName,
              branch: branch,
              IFSCCode: ifscCode,
            },
          },
          { headers: { Authorization: Token } }
        )
        .then((response) => {
          //console.log(response.data.status);
          if (response?.data?.status === true) {
            setChange(!change);
            Swal.fire("Successfully!", "Customer Details Updated!", "success");
          } else {
            Swal.fire("Something Wrong!", "Details Not Updated!", "error");
          }
        });
    } else {
      Swal.fire(
        "Please Fill all Field Correctly!",
        "Bank Not Updated!",
        "error"
      );
    }
  };

  return (
    <div className="my-5 px-2">
      <div className=" shadow-sm p-3 border">
        <div className="row mb-4 justify-content-center">
          <div className="col-lg-4 col-sm-12">
            <label>Payment Term Id</label>
            <input
              type="text"
              className="form-control"
              name="paymentTerm"
              id="paymentTerm"
              value={invCustomer.paymentTermsId}
              readOnly
            />
          </div>
        </div>

        <div className="row mb-4 justify-content-center">
          <div className="col-lg-6 col-sm-12">
            <label>Booking Amount</label>
            <input
              type="number"
              className="form-control"
              name="bookingAmount"
              id="bookingAmount"
              value={invCustomer.bookingAmount}
              readOnly
            />
          </div>
          <div className="col-lg-6 col-sm-12">
            <label>Sale Agreement Demand</label>
            <input
              type="number"
              className="form-control"
              name="saleAgeementDemand"
              id="saleAgeementDemand"
              value={invCustomer.saleAgeementDemandTotal}
              readOnly
            />
          </div>
        </div>
      </div>

      <br />

      <div className=" shadow-sm p-3 border mt-2">
        <div className="row mb-4 justify-content-center">
          <div className="col-lg-4 col-sm-12">
            <label>Construction Demand Pending</label>
            <input
              type="number"
              className="form-control"
              name="constructionDemandPending"
              id="constructionDemandPending"
              value={invCustomer?.constructionDemandPending}
              readOnly
            />
          </div>

          <div className="col-lg-4 col-sm-12">
            <label>Construction Demand Paid</label>
            <input
              type="number"
              className="form-control"
              name="constructionDemandPending"
              id="constructionDemandPending"
              value={
                invCustomer?.constructionDemandTotal -
                invCustomer?.constructionDemandPending
              }
              readOnly
            />
          </div>

          <div className="col-lg-4 col-sm-12">
            <label>Construction Demand Total</label>
            <input
              type="number"
              className="form-control"
              name="constructionDemandPending"
              id="constructionDemandPending"
              value={invCustomer?.constructionDemandTotal}
              readOnly
            />
          </div>
        </div>

        <div className="row mb-4 justify-content-center">
          <div className="col-lg-4 col-sm-12">
            <label> Late Fees Demand Pending</label>
            <input
              type="number"
              className="form-control"
              name="constructionDemandPending"
              id="constructionDemandPending"
              value={invCustomer?.lateFeesPending}
              readOnly
            />
          </div>

          <div className="col-lg-4 col-sm-12">
            <label>Late Fees Demand Paid</label>
            <input
              type="number"
              className="form-control"
              name="constructionDemandTotal"
              id="constructionDemandTotal"
              value={invCustomer?.lateFeesTotal - invCustomer?.lateFeesPending}
              readOnly
            />
          </div>
          <div className="col-lg-4 col-sm-12">
            <label>Late Fees Demand Total</label>
            <input
              type="number"
              className="form-control"
              name="constructionDemandTotal"
              id="constructionDemandTotal"
              value={invCustomer?.lateFeesTotal}
              readOnly
            />
          </div>
        </div>

        <div className="row mb-4 justify-content-center">
          <div className="col-lg-4 col-sm-12">
            <label>Ad Hoc Demand Pending</label>
            <input
              type="number"
              className="form-control"
              name="adHocDemandPending"
              id="adHocDemandPending"
              value={invCustomer?.adHocDemandPending}
              readOnly
            />
          </div>

          <div className="col-lg-4 col-sm-12">
            <label>Ad Hoc Demand Paid</label>
            <input
              type="number"
              className="form-control"
              name="adHocDemandPending"
              id="adHocDemandPending"
              value={
                invCustomer?.adHocDemandTotal - invCustomer?.adHocDemandPending
              }
              readOnly
            />
          </div>

          <div className="col-lg-4 col-sm-12">
            <label>Ad Hoc Demand Total</label>
            <input
              type="number"
              className="form-control"
              name="adHocDemandTotal"
              id="adHocDemandTotal"
              value={invCustomer?.adHocDemandTotal}
              readOnly
            />
          </div>
        </div>

        {/* card 2  */}
        <div className="row  mb-4 justify-content-center">
          <div className="col-lg-4 col-sm-12">
            <label>Cheque Bounce Pending</label>
            <input
              type="number"
              className="form-control"
              name="gstTotal"
              id="gstTotal"
              value={invCustomer?.chequeBouncePending}
              readOnly
            />
          </div>

          <div className="col-lg-4 col-sm-12">
            <label>Cheque Bounce Paid</label>
            <input
              type="number"
              className="form-control"
              name="gstPending"
              id="gstPending"
              value={
                invCustomer?.chequeBounceTotal -
                invCustomer?.chequeBouncePending
              }
              readOnly
            />
          </div>

          <div className="col-lg-4 col-sm-12">
            <label>Cheque Bounce Total</label>
            <input
              type="number"
              className="form-control"
              name="gstPending"
              id="gstPending"
              value={invCustomer?.chequeBounceTotal}
              readOnly
            />
          </div>
        </div>
      </div>

      {/* card 3  */}
      <div className="shadow-sm p-3 border mt-5">
        <div className="row  justify-content-center  my-5">
          <div className="col-lg-4 col-sm-12">
            <label>GST Paid</label>
            <input
              type="number"
              className="form-control"
              name="gstTotal"
              id="gstTotal"
              value={invCustomer?.gstPaid}
              readOnly
            />
          </div>

          <div className="col-lg-4 col-sm-12">
            <label>GST Total</label>
            <input
              type="number"
              className="form-control"
              name="gstTotal"
              id="gstTotal"
              value={invCustomer?.gstTotal}
              readOnly
            />
          </div>

          <div className="col-lg-4 col-sm-12">
            <label>Deposit Amount</label>
            <input
              type="number"
              className="form-control"
              name="depositAmount"
              id="depositAmount"
              value={invCustomer?.depositAmount}
              readOnly
            />
          </div>
        </div>
      </div>

      {/* card 4  */}
      <div className="shadow-sm p-3 border mt-5">
        <div className="row  justify-content-center mb-5">
          <div className="col-lg-3 col-sm-12">
            <label> Total Demand Pending </label>
            <input
              type="number"
              className="form-control"
              name="cumulitiveDemandTotal"
              id="cumulitiveDemandTotal"
              value={invCustomer?.pendingDemandTotal}
              readOnly
            />
          </div>

          <div className="col-lg-4 col-sm-12">
            <label>Total Payment On Demand</label>
            <input
              type="number"
              className="form-control"
              name="cumulitivePaymentTotal"
              id="cumulitivePaymentTotal"
              value={invCustomer?.cumulitivePaymentTotal}
              readOnly
            />
          </div>
          <div className="col-lg-4 col-sm-12">
            <label>Total Demand (incl. Booking Amount) </label>
            <input
              type="number"
              className="form-control"
              name="cumulitivePaymentTotal"
              id="cumulitivePaymentTotal"
              value={
                invCustomer?.cumulitiveDemandTotal + invCustomer?.bookingAmount
              }
              readOnly
            />
          </div>
        </div>
      </div>

      {/* Loan Details  */}
      <div className="shadow-sm p-3 border mt-5">
        <h4 className="my-3">
          <b>Loan Details</b>
        </h4>
        {!invCustomer?.isBankLoanRequested ? (
          <div className="row  justify-content-center">
            <h5>
              <b>No Loan Requested</b>
            </h5>
          </div>
        ) : (
          <div>
            {!invCustomer?.isLoanApplied ? (
              <>
                <div className="row  justify-content-center">
                  <h5>
                    Preferred Bank :{" "}
                    <b>{invCustomer?.preferredBank?.join(", ")}</b>
                  </h5>
                </div>
                <br />
                <div className="row  justify-content-center">
                  <h5>
                    <b>Connect Customer for Processing the Loan Application</b>
                  </h5>
                  <br />
                  <br />
                  <form onSubmit={loanApplied}>
                    <div className="row  justify-content-center m-2">
                      <div className="col-lg-6 col-sm-12">
                        <label>Loan Bank Name</label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={loanBankName}
                          onChange={(e) => setLoanBankName(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <label>Loan Branch Name</label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={loanBranchName}
                          onChange={(e) => setLoanBranchName(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6 col-sm-12 mt-3">
                        <label>Loan Application No.</label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={loanApplicationNo}
                          onChange={(e) => setLoanApplicationNo(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6 col-sm-12 mt-3">
                        <label>Loan Application Date</label>
                        <input
                          type="date"
                          class="form-control"
                          value={loanApplicationDate}
                          onChange={(e) =>
                            setLoanApplicationDate(e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="d-flex justify-content-center">
                        <button
                          className="save_btn"
                          style={{ backgroundColor: "#EE4B46", width: "200px" }}
                          type="submit"
                        >
                          Loan Applied
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            ) : (
              <>
                <form onSubmit={handleLoanSanctioned}>
                  <div className="row  justify-content-center m-2">
                    <div className="col-lg-6 col-sm-12">
                      <label>Loan Bank Name</label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={invCustomer?.loanBankName}
                      />
                    </div>

                    <div className="col-lg-6 col-sm-12">
                      <label>Loan Branch Name</label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={invCustomer?.loanBranchName}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 mt-3">
                      <label>Loan Application No.</label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={invCustomer?.loanApplicationNo}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 mt-3">
                      <label>Loan Application Date</label>
                      <input
                        type="text"
                        class="form-control"
                        value={dateFormatting(invCustomer?.loanApplicationDate)}
                        readOnly
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 mt-3">
                      <label>Loan Application Status</label>
                      <input
                        type="text"
                        class="form-control"
                        value={invCustomer?.loanApplicationStatus}
                        readOnly
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 mt-3">
                      {invCustomer?.loanApplicationStatus === "Applied" &&
                        invCustomer?.isLoanApplied && (
                          <>
                            <label>Loan Sanctioned Date</label>
                            <input
                              type="date"
                              class="form-control"
                              value={loanSanctioned}
                              onChange={(e) =>
                                setLoanSanctioned(e.target.value)
                              }
                              required
                            />
                          </>
                        )}
                      {invCustomer?.loanApplicationStatus === "Sanctioned" &&(
                          <>
                            <label>Loan Sanctioned Date</label>
                            <input
                              type="text"
                              class="form-control"
                              value={dateFormatting(invCustomer?.loanSanctionDate)}
                              readOnly
                            />
                          </>
                        )}
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="d-flex justify-content-center">
                      <button
                        className="save_btn"
                        style={{ backgroundColor: invCustomer?.loanApplicationStatus === "Sanctioned" ? "Grey" : "#EE4B46", width: "200px" }}
                        type="submit"
                        disabled={invCustomer?.loanApplicationStatus === "Sanctioned"}
                      >
                        Loan Sanctioned
                      </button>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        )}
      </div>
      {/* bank details  */}
      <div className="shadow-sm p-3 border mt-5">
        <h4 className="my-3">
          <b>Bank Details</b>
        </h4>
        <form onSubmit={saveBankDetails}>
          <div className="row  justify-content-center">
            <div className="col-lg-6 col-sm-12">
              <label>IFSC Code </label>
              <input
                type="text"
                className="form-control"
                required
                defaultValue={invCustomer?.custBankAc?.IFSCCode || ""}
                onChange={handleIfsc}
              />
              {!validated && ifscCode.length > 0 && (
                <small id="bcodeMessage" className="text-danger">
                  Must be of 11 digits with capital letters and numbers only
                  <br />
                </small>
              )}
            </div>

            <div className="col-lg-6 col-sm-12">
              <label>Account No</label>
              <input
                type="number"
                className="form-control"
                required
                defaultValue={invCustomer?.custBankAc?.accountNo || ""}
                // value={accountNo}
                onChange={(e) => setAccountNo(e.target.value)}
              />
            </div>
            <div className="col-lg-12 mt-3 col-sm-12">
              <label>Bank Name </label>
              <input
                type="text"
                className="form-control"
                required
                defaultValue={invCustomer?.custBankAc?.bankName || ""}
                onChange={(e) => setBankName(e.target.value)}
                // value={bankName}
              />
            </div>
            <div className="col-lg-12 mt-3 col-sm-12">
              <label>Branch </label>
              <input
                defaultValue={invCustomer?.custBankAc?.branch || ""}
                type="text"
                class="form-control"
                onChange={(e) => setBranch(e.target.value)}
                required
                // value={branch}
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="d-flex justify-content-center">
              <button
                className="save_btn"
                style={{ backgroundColor: "#EE4B46" }}
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerFinance;
