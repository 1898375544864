import React from "react";
import Paper from "@mui/material/Paper";
import { formatDate } from "../../../utils/helper";

const Documents = ({
  employeeAadhar,
  employeeAadharScan,
  employeePAN,
  employeePANScan,
  employeePassport,
}) => {
  const {
    passportNo,
    passportScan,
    issueDate,
    expiryDate,
    placeOfIssue,
    countryOfIssue,
  } = employeePassport;
  return (
    <div>
      <Paper elevation={4} style={{ padding: "1rem", marginTop: "2rem" }}>
        <br />
        <div className="row justify-content-start align-items-center">
          <div className="col-3">
            <label>Aadhar Number</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={employeeAadhar}
            />
          </div>
          <div className="col-3 mt-4">
            <a href={employeeAadharScan} target="_blank" rel="noreferrer">
              <button >Download Aadhar</button>
            </a>
          </div>
          <div className="col-3">
            <label>PAN Number</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={employeePAN}
            />
          </div>
          <div className="col-3 mt-4">
            <a href={employeePANScan} target="_blank" rel="noreferrer">
              <button >Download Pan</button>
            </a>
          </div>
        </div>
      </Paper>
      <br />
      <Paper elevation={4} style={{ padding: "1rem", marginTop: "2rem" }}>
        <br />
        <div className="row justify-content-start">
          <div className="col-3">
            <label>Passport Number</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={passportNo}
            />
          </div>
          <div className="col-3">
            <label>Place of Issue</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={placeOfIssue}
            />
          </div>
          <div className="col-3">
            <label>Country of Issue</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={countryOfIssue}
            />
          </div>
        </div>
        <br />
        <div className="row justify-content-start">
          <div className="col-3">
            <label>Issue Date</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={formatDate(issueDate)}
            />
          </div>
          <div className="col-3">
            <label>Expiry Date</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={formatDate(expiryDate)}
            />
          </div>
          <div className="col-3 mt-4">
            <a href={passportScan} target="_blank" rel="noreferrer">
              <button disabled={passportScan === null ? true : false} style={{backgroundColor: passportScan === null ? "grey" : ""}} >Download Passport</button>
            </a>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default Documents;
