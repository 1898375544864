import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { Form } from 'react-bootstrap';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import urgent from '../../assets/images/urgent-priority.png';
import high from '../../assets/images/high-priority.png';


const RedSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#EE4B46",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#EE4B46",
    },
}));

const SendAnnouncement = ({ setActive }) => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [isChecked, setIsChecked] = useState(false);
    const [department, setDepartment] = useState('');
    const [notifBody, setNotifBody] = useState('');
    const [notifHeadline, setNotifHeadline] = useState('');
    const [error, setError] = useState('')
    const [apiError, setApiError] = useState('')
    const [priority, setPriority] = useState('Normal')

    const sendAnnouncement = () => {
        if (isChecked && !department) {
            setError('Select Department')
            return;
        }

        if (notifHeadline === '' || notifBody === '') {
            setError('Please fill all the fields')
            return;
        }

        setError('')

        let body = {
            notificationAudience: isChecked ? 'Role' : 'Organisation',
            notificationHeadline: notifHeadline,
            notificationBody: notifBody,
            notificationPriority: priority
        }

        if (isChecked) {
            body.employeeRole = department
        }

        // console.log(body)

        axios.post(`${BASE_URL}/api/v1/hrmgmt/notification/createNotificationEmployee`,
            body,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                if (response.data.status === true) {
                    setActive('notification')
                }
            })
            .catch((err) => {
                setApiError(err.response.data.error)
            })

    }

    return (
        <div className="col-md-12">

            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Send Announcement</h4>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 pr-1">

                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>Organisation</Typography>
                                <RedSwitch
                                    checked={isChecked}
                                    onClick={() => {
                                        setIsChecked(!isChecked)
                                    }}
                                />
                                <Typography>Department</Typography>
                            </Stack>
                            {isChecked &&
                                <>
                                    <div className="form-group mt-1">
                                        <Form.Control as="select"
                                            onChange={(e) => setDepartment(e.target.value)}
                                        >
                                            <option
                                                value=''
                                                className='text-secondary'
                                                selected
                                            >Select a Department</option>
                                            <option value="Director"> Director</option>
                                            <option value="Sales">Sales</option>
                                            <option value="HR">HR</option>
                                            <option value="Finance">Finance</option>
                                            <option value="Operations">Operations</option>
                                            <option value="Engineering">Engineering</option>
                                            <option value="IT admin">IT admin</option>
                                        </Form.Control>
                                    </div>
                                </>
                            }

                            <br/>

                            <div className='row d-flex justify-content-between'>

                                {/* <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={priority}
                                            onClick={(e) => setPriority(e.target.value)}
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="Normal" control={<Radio
                                                style={{ color: '#EE4B46' }}
                                            />} label="Normal" />
                                            <FormControlLabel value="High" control={<Radio style={{ color: '#EE4B46' }} />} label="High" />
                                            <FormControlLabel value="Urgent" control={<Radio style={{ color: '#EE4B46' }} />} label="Urgent" />
                                        </RadioGroup> */}

                                <div className="col-4 text-center">
                                    <img src={urgent} alt="" style={{ border: '1px solid blue', cursor: 'pointer', backgroundColor : priority === 'Normal' ? 'grey' : '' }}
                                        onClick={() => {
                                            setPriority('Normal')
                                        }}
                                    />
                                </div>

                                <div className="col-4 text-center">
                                    <img src={high} alt="" style={{ cursor: 'pointer' , backgroundColor : priority === 'High' ? 'grey' : ''}}
                                        onClick={() => {
                                            setPriority('High')
                                        }}
                                    />
                                </div>

                                <div className="col-4 text-center">
                                    <img src={urgent} alt="" style={{ cursor: 'pointer' , backgroundColor : priority === 'Urgent' ? 'grey' : '' }}
                                        onClick={() => {
                                            setPriority('Urgent')
                                        }}
                                    />
                                </div>

                            </div>

                            <br/>

                            <div className="form-group">
                                <label>Headline</label>
                                <textarea className="form-control" rows="3" placeholder="Write your Headline here"
                                    value={notifHeadline}
                                    onChange={(e) => {
                                        setNotifHeadline(e.target.value)
                                    }}
                                ></textarea>
                            </div>

                            <div className="form-group">
                                <label>Announcement</label>
                                <textarea className="form-control" rows="5" placeholder="Write your announcement here"
                                    value={notifBody}
                                    onChange={(e) => {
                                        setNotifBody(e.target.value)
                                    }}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="update ml-auto mr-auto">
                            <button type="submit"
                                onClick={sendAnnouncement}
                                className="btn btn-primary btn-round"
                                style={{ backgroundColor: '#EE4B46' }}
                            >Send Announcement</button>
                        </div>
                    </div>
                </div>
            </div>

            {error && <em style={{ color: '#EE4B46' }}>{error}</em>}
            {apiError && <em style={{ color: '#EE4B46' }}>{apiError}</em>}

        </div>
    )

};

export default SendAnnouncement;
