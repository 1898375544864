export const jobLevels = [
    {
        "level": "L1",
        "designation": "Executive Trainee",
        "travelAllowance": 300,
        "mealAllowance": 300,
        "phoneAndDateAllowance": 250,
        "medicalInsurance": 3587,
    },
    {
        "level": "L2",
        "designation": "Executive",
        "travelAllowance": 400,
        "mealAllowance": 350,
        "phoneAndDateAllowance": 250,
        "medicalInsurance": 4102,
    },
    {
        "level": "L3",
        "designation": "Manager",
        "travelAllowance": 500,
        "mealAllowance": 400,
        "phoneAndDateAllowance": 250,
        "medicalInsurance": 4964,
    },
    {
        "level": "L4",
        "designation": "Senior Manager",
        "travelAllowance": 750,
        "mealAllowance": 500,
        "phoneAndDateAllowance": 500,
        "medicalInsurance": 5481,
    },

    {
        "level": "E1",
        "designation": "Jr. Engineer",
        "travelAllowance": 300,
        "mealAllowance": 300,
        "phoneAndDateAllowance": 250,
        "medicalInsurance": 3587,
    },
    {
        "level": "E2",
        "designation": "Engineer",
        "travelAllowance": 400,
        "mealAllowance": 350,
        "phoneAndDateAllowance": 250,
        "medicalInsurance": 4102,
    },
    {
        "level": "E3",
        "designation": "Asst. Senior Engineer",
        "travelAllowance": 500,
        "mealAllowance": 400,
        "phoneAndDateAllowance": 250,
        "medicalInsurance": 4964,
    },
    {
        "level": "E4",
        "designation": "Senior Engineer",
        "travelAllowance": 750,
        "mealAllowance": 500,
        "phoneAndDateAllowance": 500,
        "medicalInsurance": 5481,
    },
    {
        "level": "M1",
        "designation": "Asst. VP",
        "travelAllowance": 3000,
        "mealAllowance": 3000,
        "phoneAndDateAllowance": 1500,
        "medicalInsurance": 7893,

    },
    {
        "level": "M2A",
        "designation": "VP",
        "travelAllowance": 4000,
        "mealAllowance": 3000,
        "phoneAndDateAllowance": 2000,
        "medicalInsurance": 8456,
    },
    {
        "level": "M2B",
        "designation": "Director",
        "travelAllowance": 5000,
        "mealAllowance": 3000,
        "phoneAndDateAllowance": 4000,
        "medicalInsurance": 11243,
    }


]
