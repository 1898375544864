import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button';
import MaterialTable from 'material-table';
import './ConfigPaymentTerms.scss';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import { useNavigate } from 'react-router-dom';


const ConfigPaymentTerms = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const navigate = useNavigate()

    const [paymentTerms, setPaymentTerms] = useState([])

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/Configuration/PaymentTerms/getListOfPaymentTerms`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                setPaymentTerms(response.data.paymentTermsList)
            })
    }, [])

    return (
        <div className='config'>

            <Button navigateTo={'/home'} />

            <br />
            <br />

            <MaterialTable
                data={paymentTerms}
                title="Payment Terms"
                columns={
                    [
                        { title: 'Payment Terms Id', field: 'paymentTermsId' },
                        { title: 'Site Name', field: 'siteName' },
                        { title: 'Current Milestone', field: 'currentMileStone' },
                        { title: 'Total Number of Milestones', field: 'termItems.length' },

                    ]
                }
                options={{
                    search: true,
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#EE4B46',
                        color: '#fff',
                    }
                }}

                actions={[
                    {
                        icon: 'remove_red_eye',
                        tooltip: 'View Payment Term',
                        onClick: (event, rowData) => {
                            navigate(`/configurations/paymentTerms/${rowData.paymentTermsId}`)
                        },
                    },

                    // (rowData) => ({
                    //     icon: "delete",
                    //     tooltip: "Delete Payment Term",
                    //     onClick: (event, rowData) => {
                    //         setPid(rowData.paymentTermsId)
                    //         setModal(true)
                    //     },
                    //     disabled:
                    //         userInfo.userRole === "ITAdmin" ||
                    //             userInfo.userRole === "Director"
                    //             ? false
                    //             : true,
                    // }),

                ]}

            ></MaterialTable>

        </div>
    )
}

export default ConfigPaymentTerms