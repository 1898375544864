import React from 'react'
import { Bar, Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const LeaveBarChart = ({ compOffLabel, compOffCount }) => {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            // title: {
            //     display: true,
            //     text: 'Chart.js Bar Chart',
            // },
        },
    };



    return (
        <Bar
            data={{

                labels: compOffLabel,
                datasets: [{
                    label: 'Approved',
                    data: compOffCount.map((compOff) => (compOff[0])),
                    backgroundColor: '#f78e8b',
                    borderWidth: 1
                },
                {
                    label: 'Rejected',
                    data: compOffCount.map((compOff) => (compOff[1])),
                    backgroundColor: '#35a2eb',
                },
                {
                    label: 'Total',
                    data: compOffCount.map((compOff) => (compOff[2])),
                    backgroundColor: '#65f76d',
                }]

            }}

            height={100}
            width={500}
            options={options}

        />
    )
}

export default LeaveBarChart;