import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import MaterialTable, { MTableToolbar } from "material-table";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useStyles } from "../../utils/ModalStyles";
import { BASE_URL } from "../../utils/config/url";
import "./ListOfEmployees.scss";

const ListOfEmployees = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();

  const [employeeList, setEmployeeList] = useState([]);
  const [isActive, setIsActive] = useState(true);

  const reset = () => {
    setIsActive(true);
  };

  useEffect(() => {
    const source = axios.CancelToken.source(); // Create a cancel token source
  
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=${isActive}`,
          {
            headers: { Authorization: `bearer ${userInfo.token}` },
            cancelToken: source.token // Pass the cancel token to the request
          }
        );
        if (response.data.status === true) {
          setEmployeeList(response?.data?.employees);
        } else {
          setEmployeeList([]);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message); // Log the cancellation
        } else {
          // Handle other errors
        }
      }
    };
  
    fetchData(); // Call the asynchronous function
  
    return () => {
      // Cleanup function
      source.cancel('Request canceled'); // Cancel the request when the component unmounts
    };
  }, [isActive, userInfo.token]); // Include dependencies in the dependency array
  
  

  return (
    <div className="list-of-employees">
      <div className="list-of-employees__btns">
        <Button navigateTo={"/home"} />

        <div>
          {(userInfo?.userRole === "Director" ||
            userInfo?.userRole === "HR") && (
            <button
              className="list-of-employees__btns__btn mr-3"
              onClick={() => navigate("/create-salary-structure")}
            >
              Create Salary Structure
            </button>
          )}
          <button
            className="list-of-employees__btns__btn"
            onClick={() => navigate("/uploaddocument")}
          >
            Document Management
          </button>
        </div>
      </div>

      <br />
      <br />

      <h4>Employees</h4>

      <MaterialTable
        data={employeeList}
        title=""
        columns={[
          { title: "Employee ID", field: "employeeId" },
          {
            title: "Full Name",
            field: "employeeFullName",
            // render: (rowData) => (`${rowData?.employeeFirstName} ${rowData?.employeeMiddleName ? rowData?.employeeMiddleName : ''} ${rowData?.employeeLastName}`
            // ),
            // customFilterAndSearch: (term, rowData) => (rowData.employeeFirstName + ' ' + rowData.employeeMiddleName + ' ' + rowData.employeeLastName).indexOf(term) != -1
          },
          { title: "Role", field: "employeeRole" },
          { title: "Phone No.", field: "officialMobile" },
          { title: "Email Id", field: "officialEmail" },
        ]}
        actions={[
          {
            icon: "remove_red_eye",
            tooltip: "View Employee",
            disabled:
              userInfo?.userRole === "Director" ||
              userInfo?.userRole === "HR" ||
              userInfo?.userRole === "Finance"
                ? false
                : true,
            onClick: (event, rowData) => {
              navigate(`/viewemployees/${rowData.employeeId}`);
            },
          },
        ]}
        options={{
          search: true,
          searchFieldStyle: {
            width: 180,
          },
          actionsColumnIndex: 0,
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          actionsCellStyle: {
            width: 120,
            paddingLeft: 30,
          },
          sorting: true,
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
            paddingLeft: "11px",
          },
        }}
        components={{
          Toolbar: (props) => (
            <div className="filters text-left">
              <MTableToolbar {...props} />

              <FormControl
                variant="standard"
                className="formControl"
                style={{ width: "120px", margin: "0 13px", marginTop: "-65px" }}
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  style={{ fontSize: "14px" }}
                >
                  Is Active
                </InputLabel>

                <Select
                  value={isActive}
                  onChange={(e) => setIsActive(e.target.value)}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>

              {window.innerWidth < 700 && <br />}

              <FormControl className="formControl">
                <button
                  onClick={reset}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                    borderRadius: "20px",
                    padding: "5px",
                    width: "150px",
                    margin: "auto",
                    marginTop: "-50px",
                  }}
                >
                  Reset Filter
                </button>
              </FormControl>
            </div>
          ),
        }}
      ></MaterialTable>
    </div>
  );
};

export default ListOfEmployees;
