import React from "react";
import apartmentImg1 from "../../../assets/tableView/apartment-1.png";
import apartmentImg2 from "../../../assets/tableView/apartment-2.png";
import apartmentImg3 from "../../../assets/tableView/apartment-3.png";
import "./InYourApartment.scss";
export default function InYourApartment() {
  const data = [
    {
      heading: "WALL FINISHING",
      text: "POP/JK Wall Putty over plaster",
    },
    {
      heading: "FLOORING",
      text: "Master Bedroom / Other Bedroom / Living / Dining : Vitrified tiles by Varmora / Kajaria",
    },
  ];

  return (
    <>
      <div className="apartment__images">
        <img src={apartmentImg1} alt="" />
        <div className="apartment__images__subcontainer">
          <img src={apartmentImg2} alt="" />

          <img src={apartmentImg3} alt="" />
        </div>
      </div>
      <div className="apartment__infoContainer">
        {data.map(({ heading, text }) => (
          <div className="apartment__infoContainer__info">
            <b>{heading}</b>
            <p>{text}</p>
          </div>
        ))}
      </div>
    </>
  );
}
