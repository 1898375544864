import React, { useEffect, useState } from "react";
import { Switch, createMuiTheme, ThemeProvider } from "@material-ui/core";
import "./UnitHandover.scss";
import { useStyles } from "../../utils/ModalStyles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import Swal from "sweetalert2";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import RegisteredAgreementScan from "./RegisteredAgreementScan";
import StampPaperProcurement from "./StampPaperProcurement";
import AgreementFinalVersion from "./AgreementFinalVersion";
import DemandPaymentClearance from "./DemandPaymentClearance";
import ValuationEVerification from "./ValuationVerification";
import RegistrationAgreementDraft from "./RegistrationAgreementDraft";
import OnlineStampDuty from "./OnlineStampDuty";
import RegistrarAppointment from "./RegistrarAppointment";
import { Modal, Fade } from "@mui/material";
import { MoonLoader } from "react-spinners";
import ReceiveFinalDDFromBank from "./ReceiveFinalDDFromBank";
import FinalUnitInspection from "./FinalUnitInspection";
import { dateFormatting } from "../../utils/dateFormatting";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#EE4B46",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
});

const UnitHandover = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { unitname } = useParams();

  /////////////// unit /////////////
  const [unit, setUnit] = useState({});
  const [unitHandover, setUnitHandover] = useState({});

  const [modal, setModal] = useState(false);
  const [registrarAppointmentModal, setRegistrarAppointmentModal] =
    useState(false);
  const [serial, setSerial] = useState("");
  const [stampDutyPaidByCustomer, setStampDutyPaidByCustomer] = useState("");
  const [miscRegistrationCost, setMiscRegistrationCost] = useState();
  const [paymentFile, setPaymentFile] = useState("");
  const [registrationDeedScanLink, setRegistrationDeedScanLink] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [registrationDeed, setRegistrationDeed] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [uploadFile, setUploadFile] = useState("");
  const [stampDutyFees, setStampDutyFees] = useState();
  const [registrationFees, setRegistrationFees] = useState();
  const [collectionDate, setCollectionDate] = useState();
  const [collectMiscFees, setCollectMiscFees] = useState(false);
  const [loader, setLoader] = useState(false);

  ///variables for Extra work settlemnet///
  const [totalAmount, setTotalAmount] = useState();
  const [settlementDate, setSettlementDate] = useState();

  // //////variables for Registrar Appointment ///////
  const [registrarName, setRegistrarName] = useState();
  const [registrationLocation, setRegistrationLocation] = useState();
  const [registrationComment, setRegistrationComment] = useState();
  const [appointmentDate, setAppointmentDate] = useState();

  //////// variables for generate and upload possession letter /////////
  const [uploadPossessionFile, setUploadPossessionFile] = useState("");
  const [generatePossessionModal, setGeneratePossessionModal] = useState(false);
  const [physicalPossessionDate, setPhysicalPossessionDate] = useState("");
  const [possessionLetterInfo, setPossessionLetterInfo] = useState({
    uploadedByUserName: userInfo.userName,
    uploadedByUserFullName: userInfo.userFullName,
    possessionLetterDate: "",
    physicalPossessionDate: "",
    file: "",
  });

  //////// variables for mark handover complete //////////
  const [handoverCompleteModal, setHandoverCompleteModal] = useState(false);

  const [keyDetails, setKeyDetails] = useState("");
  const [isFinalPaymentByDD, setIsFinalPaymentByDD] = useState(false);

  useEffect(() => {
    getUnitByUnitName();
  }, []);

  useEffect(() => {
    getHandoverByHandoverId();
  }, [unit]);

  const getUnitByUnitName = () => {
    axios
      .get(
        `${BASE_URL}/api/v1/Configuration/Unit/getunitbyunitname/${unitname}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      )
      .then((response) => {
        // console.log(response?.data?.unit)
        if (response?.data?.status) {
          setUnit(response?.data?.unit);
        } else {
          setUnit({});
        }
      })
      .catch(() => {
        setUnit({});
      });
  };

  const getHandoverByHandoverId = () => {
    if (!unit?.handoverId) return;

    axios
      .get(
        `${BASE_URL}/api/v1/customermgmt/handover/getHandoverByHandoverId/${unit?.handoverId}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      )
      .then((response) => {
        // console.log(response?.data?.handover);
        if (response?.data?.status) {
          setUnitHandover(response?.data?.handover);
        } else {
          setUnitHandover({});
        }
      })
      .catch((err) => {
        setUnitHandover({});
      });
  };

  useEffect(() => {
    let arr = unitname.split("-");
    let customer = arr.join(".");
    setCustomerId(customer);
  }, []);

  useEffect(() => {
    setStampDutyPaidByCustomer(
      unit?.stampDutyCollection?.stampDutyPaidByCustomer
    );
  }, []);

  const toggleSwitch = async (e) => {
    e.preventDefault();

    let body = {};
    let url = "";
    let type = "put";

    if (serial === "settleExtraWork") {
      if (
        userInfo?.userRole != "Director" &&
        userInfo?.userRole != "Operations"
      ) {
        setModal(false);
        setSettlementDate("");
        setTotalAmount("");

        return Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: "Only Director and Operations can settle Legal Cost",
        });
      }
      url = `${BASE_URL}/api/v1/customermgmt/handover/alterationUpgradeSettlement`;
      body = {
        handoverId: unit?.handoverId,
        totalAmount: parseInt(totalAmount),
        settlementDate: settlementDate,
        settledByUserName: userInfo.userName,
        settledByUserFullName: userInfo.userFullName,
      };
    } else if (serial === "settleLegalCost") {
      if (
        userInfo?.userRole != "Director" &&
        userInfo?.userRole != "Operations"
      ) {
        setModal(false);
        setTotalAmount("");
        setSettlementDate("");
        return Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: "Only Director and Operations can settle Legal Cost",
        });
      }
      url = `${BASE_URL}/api/v1/customermgmt/handover/legalCostSettlement`;
      body = {
        handoverId: unit?.handoverId,
        totalAmount: parseInt(totalAmount),
        settlementDate: settlementDate,
        settledByUserName: userInfo.userName,
        settledByUserFullName: userInfo.userFullName,
      };
    } else if (serial === "stampDuty") {
      if (
        userInfo?.userRole != "Director" &&
        userInfo?.userRole != "Operations"
      ) {
        return Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: "Only Director and Operations can settle Legal Cost",
        });
      }
      body = {
        handoverId: unit?.handoverId,
        collectionDate: collectionDate,
        collectedByUserName: userInfo.userName,
        collectedByUserFullName: userInfo.userFullName,
        stampDutyPaidByCustomer: stampDutyPaidByCustomer,
        collectMiscFees: collectMiscFees,
        miscRegistrationCost: parseInt(miscRegistrationCost),
      };
      if (stampDutyPaidByCustomer) {
        body.stampDuty = parseInt(stampDutyFees);
        body.registrationFees = parseInt(registrationFees);
      }
      url = `${BASE_URL}/api/v1/customermgmt/handover/stampDutyCollection`;
    } else if (serial === "customerAcknowledgement") {
      url = `${BASE_URL}/api/v1/Configuration/Unit/customerAcknowledgementOnRegistrationDate`;
      body = {
        unitName: unit?.unitName,
        verifiedByUserName: userInfo.userName,
        verifiedByUserFullName: userInfo.userFullName,
      };
    } else if (serial === "registrationCompletion") {
      url = `${BASE_URL}/api/v1/Configuration/Unit/markRegistrationComplete`;
      type = "post";

      body = new FormData();
      body.append("unitName", unit?.unitName);
      body.append("file", registrationDeedScanLink);
      body.append("registrationDate", registrationDate);
      body.append("registrationNo", parseInt(registrationDeed));
      body.append("uploadedByUserName", userInfo.userName);
      body.append("uploadedByUserFullName", userInfo.userFullName);
    }

    if (type === "put") {
      axios
        .put(url, body, {
          headers: { Authorization: `bearer ${userInfo.token}` },
        })
        .then((response) => {
          setModal(false);
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: response.data.message,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then(function () {
              setSerial("");
              setStampDutyPaidByCustomer("");
              setMiscRegistrationCost("");
              setStampDutyFees("");
              setRegistrationFees("");
              setTotalAmount("");
              setSettlementDate("");
              getHandoverByHandoverId();
            });
          }
        })
        .catch((err) => {
          setModal(false);
          setSettlementDate("");
          setTotalAmount("");
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: err.response.data.error,
          });
        });
    } else if (type === "post") {
      axios
        .post(url, body, {
          headers: { Authorization: `bearer ${userInfo.token}` },
        })
        .then((response) => {
          setModal(false);
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: response.data.message,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then(function () {
              setSerial("");
              setRegistrationDate("");
              setRegistrationDeedScanLink("");
              setRegistrationDeed("");
              getUnitByUnitName();
            });
          }
        })
        .catch((err) => {
          setModal(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: err.response.data.error,
          });
        });
    }
  };

  const uploadPaymentMemo = () => {
    let formData = new FormData();
    formData.append("file", paymentFile);
    formData.append("unitName", unit?.unitName);
    formData.append("uploadedByUserName", userInfo.userName);
    formData.append("uploadedByUserFullName", userInfo.userFullName);

    axios
      .post(
        `${BASE_URL}/api/v1/Configuration/Unit/uploadPaymentMemo`,
        formData,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setPaymentFile("");
            let element = document.querySelector("#paymentMemoFile");
            element.value = "";
            getUnitByUnitName();
          });
        }
      })
      .catch((err) => {
        setPaymentFile("");
        let element = document.querySelector("#paymentMemoFile");
        element.value = "";
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const keysHandover = (e) => {
    if (userInfo?.userRole !== "Operations") {
      Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: "Only Operations can receive the keys",
      });
      return;
    }

    e.preventDefault();
    axios
      .put(
        `${BASE_URL}/api/v1/CustomerMgmt/handover/handoverKeysToOperations`,
        {
          handoverId: unitHandover?.handoverId,
          receivedByUserName: userInfo?.userName,
          receivedByUserFullName: userInfo?.userFullName,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        // setLoader(false);

        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setUnitHandover(response.data.handover);
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error || err.response.data.message,
        });
      });
  };

  const generateChecklist = () => {
    axios
      .post(
        `${BASE_URL}/api/v1/Configuration/Unit/generateHandOverChecklist`,
        {
          customerId,
          unitName: unit?.unitName,
          generatedByUserName: userInfo.userName,
          generatedByUserFullName: userInfo.userFullName,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getUnitByUnitName();
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const uploadChecklist = () => {
    let body = new FormData();
    body.append("unitName", unit?.unitName);
    body.append("file", uploadFile);
    body.append("uploadedByUserName", userInfo.userName);
    body.append("uploadedByUserFullName", userInfo.userFullName);

    axios
      .post(
        `${BASE_URL}/api/v1/Configuration/Unit/uploadSignedHandoverChecklist`,
        body,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setUploadFile("");
            setPaymentFile("");
            let element = document.querySelector("#handoverChecklistFile");
            element.value = "";
            getUnitByUnitName();
          });
        }
      })
      .catch((err) => {
        setUploadFile("");
        setPaymentFile("");
        let element = document.querySelector("#handoverChecklistFile");
        element.value = "";
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const generatePossessionLetter = () => {
    // if (keyDetails == "") return;

    if (
      userInfo?.userRole != "Director" &&
      userInfo?.userRole != "Operations"
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: "Only Director and Operations can generate Possession Letter",
      });
    }

    setLoader(true);

    axios
      .post(
        `${BASE_URL}/api/v1/customermgmt/handover/generatePossessionLetter`,
        {
          handoverId: unitHandover?.handoverId,
          generatedByUserName: userInfo.userName,
          generatedByUserFullName: userInfo.userFullName,
          keyDetails,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);

        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getUnitByUnitName();
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error || err.response.data.message,
        });
      });
  };

  const uploadPossessionLetter = (e) => {
    e.preventDefault();

    if (
      userInfo?.userRole != "Director" &&
      userInfo?.userRole != "Operations"
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: "Only Director and Operations can generate Possession Letter",
      });
    }

    setLoader(true);

    let body = new FormData();
    body.append("handoverId", unitHandover?.handoverId);
    for (let key in possessionLetterInfo) {
      body.append(key, possessionLetterInfo[key]);
    }

    axios
      .put(
        `${BASE_URL}/api/v1/customermgmt/handover/uploadSignedPossesionLetter`,
        body,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            let element = document.querySelector("#possessionLetterFile");
            element.value = "";
            getUnitByUnitName();
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        let element = document.querySelector("#possessionLetterFile");
        element.value = "";
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const reset = () => {
    setStampDutyPaidByCustomer("");
    setStampDutyFees("");
    setRegistrationFees("");
    setMiscRegistrationCost("");
  };

  const registrarAppointment = (e) => {
    e.preventDefault();

    axios
      .post(
        `${BASE_URL}/api/v1/Configuration/Unit/registrarAppointment`,
        {
          unitName: unit?.unitName,
          registrarName,
          registrationComment,
          registrationLocation,
          appointmentDate: appointmentDate,
          appointmentConfirmedByUserName: userInfo.userName,
          appointmentConfirmedByUserFullName: userInfo.userFullName,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setRegistrarAppointmentModal(false);

        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            resetRegistrarAppointment("");
            getUnitByUnitName();
          });
        }
      })
      .catch((err) => {
        setRegistrarAppointmentModal(false);

        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const resetRegistrarAppointment = () => {
    setRegistrarName("");
    setSerial("");
    setRegistrationComment("");
    setRegistrationLocation("");
    setAppointmentDate("");
  };

  const markUnitHandoverComplete = () => {
    axios
      .post(
        `${BASE_URL}/api/v1/Configuration/Unit/markUnitHandoverComplete`,
        {
          unitName: unitname,
          bookingByUserName: userInfo.userName,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setHandoverCompleteModal(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(() => {
          getUnitByUnitName();
        });
      })
      .catch((err) => {
        setHandoverCompleteModal(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };
  const addKeyDetails = () => {
    axios
      .put(
        `${BASE_URL}/api/v1/Configuration/Unit/addKeyDetails`,
        {
          unitName: unitname,
          keyDetails,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(() => {
          // setKeyDetails('')
          getUnitByUnitName();
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const initiateUnitHandover = () => {
    setLoader(true);
    axios
      .post(
        `${BASE_URL}/api/v1/customermgmt/handover/initiateUnitHandover`,
        {
          unitName: unitname,
          isFinalPaymentByDD,
          currentUserEmployeeId: userInfo.employeeId,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(() => {
          // setKeyDetails('')
          getUnitByUnitName();
        });
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  return (
    <div className="unit-handover">
      {unit?.status === "Available" ||
      unit?.status === "Booking Initiated" ||
      unit?.status === "Booking In Progress" ? (
        <p style={{ textAlign: "center" }}>
          <em style={{ color: "#EE4B46" }}>Unit is not ready for Handover</em>
        </p>
      ) : (
        <ThemeProvider theme={theme}>
          {unit?.status != "Handover Initiated" &&
          unit?.status != "Registration Complete" &&
          unit?.status != "Possession Provided" &&
          unit?.status != "In Maintanance" ? (
            <Paper
              elevation={1}
              style={{
                padding: "30px 20px",
                width: "90%",
                margin: "auto",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="col-3 mt-4">
                  <label className="mr-2">Is Final Payment By DD</label>
                  <Switch
                    checked={isFinalPaymentByDD}
                    color="primary"
                    onChange={() => setIsFinalPaymentByDD(!isFinalPaymentByDD)}
                  />
                </div>

                <div className="col-4">
                  <button
                    className="unit-handover__btns my-3 mb-5"
                    disabled={
                      userInfo.userRole != "Director" &&
                      userInfo.userRole != "Operations"
                    }
                    style={{
                      backgroundColor:
                        userInfo.userRole != "Director" &&
                        userInfo.userRole != "Operations"
                          ? "grey"
                          : "",
                    }}
                    onClick={() => initiateUnitHandover()}
                  >
                    Initiate Unit Handover
                  </button>
                </div>
              </div>
            </Paper>
          ) : (
            <div>
              <h2 className="text-center mr-5">
                <b>Handover Checklist</b>
              </h2>

              <br />
              <br />

              {/* final unit Inspection */}
              <FinalUnitInspection
                unitHandover={unitHandover}
                setUnitHandover={setUnitHandover}
              />

              <br />
              <br />
              {/*Hand Over Keys to Operations */}
              <Paper
                elevation={1}
                style={{
                  padding: "30px 20px",
                  width: "90%",
                  margin: "auto",
                }}
              >
                <h3 className="text-center">Handover Keys to Operations</h3>
                <br />
                <div className="row justify-content-center align-items-center">
                  <div className="col-2 text-center">
                    <h5>Keys Received</h5>
                  </div>

                  <div className="col-2 text-center">
                    <Switch
                      disabled={
                        userInfo?.userRole !== "Operations" ||
                        unitHandover?.finalUnitInspection?.inspectionDone !==
                          true
                      }
                      checked={
                        unitHandover?.keyHandoverOperations?.isReceived || false
                      }
                      color="primary"
                      onChange={(e) => {
                        if (
                          unitHandover?.keyHandoverOperations?.isReceived ===
                          true
                        ) {
                          return;
                        }
                        keysHandover(e);
                      }}
                    />
                  </div>
                </div>
              </Paper>

              <br />
              <br />
              <Paper
                elevation={1}
                style={{
                  padding: "30px 20px",
                  width: "90%",
                  margin: "auto",
                }}
              >
                <div className="row justify-content-center">
                  <div className="col-5 text-center">
                    <label>Handover Id</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={unitHandover?.handoverId}
                    />
                  </div>

                  <div className="col-5 text-center">
                    <label>Unit Name</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={unitHandover?.unitName}
                    />
                  </div>
                </div>

                <br />

                <div className="row justify-content-center">
                  <div className="col-5 text-center">
                    <label>Site Name</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={unitHandover?.siteName}
                    />
                  </div>

                  <div className="col-5 text-center">
                    <label>Phase Name</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={unitHandover?.phaseName}
                    />
                  </div>
                </div>
                <br />

                <div className="row justify-content-center">
                  <div className="col-5 text-center">
                    <label>Is Final Payment By DD</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={unitHandover?.isFinalPaymentByDD ? "Yes" : "No"}
                    />
                  </div>
                </div>
              </Paper>

              <br />
              <br />

              <Paper
                elevation={1}
                style={{
                  padding: "30px 20px",
                  width: "90%",
                  margin: "auto",
                }}
              >
                <div className="row justify-content-center">
                  <div className="col-6">
                    <label>Handover Initiated By</label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={unitHandover?.handoverInitiatedByFullName}
                    />
                  </div>

                  <div className="col-6">
                    <label>Handover Initiation Date</label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={dateFormatting(
                        unitHandover?.handoverInitiationDate
                      )}
                    />
                  </div>
                </div>

                <br />

                <div className="row justify-content-center">
                  <div className="col-6">
                    <label>Relationship Manager</label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={unitHandover?.relationshipManagerFullName}
                    />
                  </div>

                  <div className="col-6">
                    <label>Handover Status</label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={unitHandover?.status}
                    />
                  </div>
                </div>
              </Paper>

              <br />
              <br />

              {/* demand payment clearnce */}
              <DemandPaymentClearance
                unitHandover={unitHandover}
                setUnitHandover={setUnitHandover}
              />

              <br />
              <br />

              <Paper
                elevation={1}
                style={{
                  padding: "30px 20px",
                  width: "90%",
                  margin: "auto",
                }}
              >
                <div className="row justify-content-center">
                  <div className="col-5 text-center">
                    <h5>Alteration Upgrade settlement</h5>
                  </div>

                  <div className="col-5 text-center">
                    <Switch
                      checked={
                        unitHandover?.alterationUpgradeSettlement?.isSettled ||
                        false
                      }
                      color="primary"
                      onChange={(e) => {
                        if (
                          unitHandover?.alterationUpgradeSettlement
                            ?.isSettled === true
                        ) {
                          return;
                        }
                        setModal(true);
                        setSerial("settleExtraWork");
                      }}
                    />
                  </div>
                </div>

                <br />

                {unitHandover?.alterationUpgradeSettlement?.isSettled ==
                  true && (
                  <div className="row container-fluid justify-content-center text-left">
                    <div className="col-3">
                      <label>Settled By</label>
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={
                          unitHandover?.alterationUpgradeSettlement
                            ?.settledByUserFullName
                        }
                      />
                    </div>

                    <div className="col-3">
                      <label>Total Amount</label>
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={
                          unitHandover?.alterationUpgradeSettlement?.totalAmount
                        }
                      />
                    </div>

                    <div className="col-3">
                      <label>Settlement Date</label>
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={dateFormatting(
                          unitHandover?.alterationUpgradeSettlement
                            ?.settlementDate
                        )}
                      />
                    </div>

                    <br />
                  </div>
                )}
                <div className="text-center">
                  <em style={{ color: "Red", fontSize: "10px" }}>
                    This Sum is received and handled by Westroad, only for third
                    party payments as per Customer's request and discretion
                  </em>
                </div>
              </Paper>

              <br />
              <br />
              {/*Payment of Legal Cost Settlement*/}
              <Paper
                elevation={1}
                style={{
                  padding: "30px 20px",
                  width: "90%",
                  margin: "auto",
                }}
              >
                <div className="row justify-content-center">
                  <div className="col-5 text-center">
                    <h5>Payment of Legal Cost settlement</h5>
                  </div>

                  <div className="col-5 text-center">
                    <Switch
                      checked={
                        unitHandover?.legalCostSettlement?.isSettled || false
                      }
                      color="primary"
                      onChange={(e) => {
                        if (
                          unitHandover?.legalCostSettlement?.isSettled === true
                        ) {
                          return;
                        }
                        setModal(true);
                        setSerial("settleLegalCost");
                      }}
                    />
                  </div>
                </div>

                <br />

                {unitHandover?.legalCostSettlement?.isSettled == true && (
                  <div className="row container-fluid justify-content-center text-left">
                    <div className="col-3">
                      <label>Settled By</label>
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={
                          unitHandover?.legalCostSettlement
                            ?.settledByUserFullName
                        }
                      />
                    </div>

                    <div className="col-3">
                      <label>Total Amount</label>
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={unitHandover?.legalCostSettlement?.totalAmount}
                      />
                    </div>

                    <div className="col-3">
                      <label>Settlement Date</label>
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={dateFormatting(
                          unitHandover?.legalCostSettlement?.settlementDate
                        )}
                      />
                    </div>

                    <br />
                  </div>
                )}
                <div className="text-center">
                  <em style={{ color: "Red", fontSize: "10px" }}>
                    This Sum is received and handled by Westroad, only for third
                    party payments as per Customer's request and discretion
                  </em>
                </div>
              </Paper>

              <br />
              <br />

              {/* Valuation E-Verification */}
              <ValuationEVerification
                unitHandover={unitHandover}
                setUnitHandover={setUnitHandover}
              />

              <br />
              <br />

              {/*Stamp Duty Fees Collected*/}
              <Paper
                elevation={1}
                style={{
                  padding: "30px 20px",
                  width: "90%",
                  margin: "auto",
                }}
              >
                <div className="row justify-content-center">
                  <div className="col-5 text-center">
                    <h5>Stamp Duty Fees Collected</h5>
                  </div>

                  <div className="col-5 text-center">
                    <Switch
                      checked={
                        unitHandover?.stampDutyCollection?.collectStampDuty
                          ? true
                          : false
                      }
                      color="primary"
                      onChange={(e) => {
                        if (
                          unitHandover?.stampDutyCollection
                            ?.collectStampDuty === true
                        ) {
                          return;
                        }
                        setModal(true);
                        setSerial("stampDuty");
                      }}
                    />
                  </div>
                </div>

                <br />

                {unitHandover?.stampDutyCollection?.collectStampDuty ==
                  true && (
                  <>
                    <div className="row container-fluid justify-content-center text-left">
                      <div className="col-3">
                        <label>Collected By</label>
                        <input
                          type="text"
                          readOnly
                          className="form-control"
                          value={
                            unitHandover?.stampDutyCollection
                              ?.collectedByUserFullName
                          }
                        />
                      </div>

                      <div className="col-3">
                        <label>Collection Date</label>
                        <input
                          type="text"
                          readOnly
                          className="form-control"
                          value={dateFormatting(
                            unitHandover?.stampDutyCollection?.collectionDate
                          )}
                        />
                      </div>

                      <div className="col-3">
                        <label>Stamp Duty Directly Paid? </label>
                        <input
                          type="text"
                          readOnly
                          className="form-control"
                          value={
                            unitHandover?.stampDutyCollection
                              .stampDutyPaidByCustomer === false
                              ? "No"
                              : "Yes"
                          }
                        />
                      </div>

                      <div className="col-3">
                        <label>Misc. Fees Directly Paid</label>
                        <input
                          type="text"
                          readOnly
                          className="form-control"
                          value={
                            unitHandover?.stampDutyCollection
                              ?.collectMiscFees === false
                              ? "No"
                              : "Yes"
                          }
                        />
                      </div>
                    </div>

                    <br />

                    <div className="row container-fluid justify-content-center text-left">
                      {unitHandover?.stampDutyCollection
                        ?.stampDutyPaidByCustomer && (
                        <>
                          <div className="col-3">
                            <label>Stamp Duty</label>
                            <input
                              type="text"
                              readOnly
                              className="form-control"
                              value={
                                unitHandover?.stampDutyCollection?.stampDuty
                              }
                            />
                          </div>

                          <div className="col-3">
                            <label>Registration Fees</label>
                            <input
                              type="text"
                              readOnly
                              className="form-control"
                              value={
                                unitHandover?.stampDutyCollection
                                  ?.registrationFees
                              }
                            />
                          </div>
                        </>
                      )}
                      {unitHandover?.stampDutyCollection?.collectMiscFees ==
                        true && (
                        <div className="col-3">
                          <label>Misc Registration Cost</label>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            value={
                              unitHandover?.stampDutyCollection
                                ?.miscRegistrationCost
                            }
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
                <br />
                <div className="text-center">
                  <em style={{ color: "Red", fontSize: "10px" }}>
                    This Sum is received and handled by Westroad, only for third
                    party payments as per Customer's request and discretion
                  </em>
                </div>
              </Paper>

              <br />
              <br />

              {/* Online Stamp Duty */}
              <OnlineStampDuty
                unitHandover={unitHandover}
                setUnitHandover={setUnitHandover}
              />

              <br />
              <br />

              {/* stamp paper procurement */}
              <StampPaperProcurement
                unitHandover={unitHandover}
                setUnitHandover={setUnitHandover}
              />

              <br />
              <br />

              {/* Registration Agreement Draft */}
              <RegistrationAgreementDraft
                unitHandover={unitHandover}
                setUnitHandover={setUnitHandover}
              />

              <br />
              <br />

              {/* agreement final version */}
              <AgreementFinalVersion
                unitHandover={unitHandover}
                setUnitHandover={setUnitHandover}
              />

              <br />
              <br />

              {/* Registrar Appointment */}
              <RegistrarAppointment
                unitHandover={unitHandover}
                setUnitHandover={setUnitHandover}
              />

              <br />
              <br />

              {/* Upload registered Agreement Scan */}
              <RegisteredAgreementScan
                unitHandover={unitHandover}
                setUnitHandover={setUnitHandover}
              />

              <br />
              <br />

              {/* generate possession letter */}
              <Paper
                elevation={1}
                style={{
                  padding: "30px 20px",
                  width: "90%",
                  margin: "auto",
                }}
              >
                <h4 className="text-center">Generate Possession Letter</h4>

                {unitHandover?.unitPossession?.generatePossessionLetter ==
                  null && (
                  <div className="row justify-content-center">
                    {/*<div className="col-4 text-center mt-2">
                      <label>Key Details</label>
                      <input
                        className="form-control"
                        value={keyDetails}
                        onChange={(e) => setKeyDetails(e.target.value)}
                      />
                  </div>*/}

                    <div className="col-6 text-center mt-3">
                      <button
                        className="unit-handover__btns"
                        onClick={() => generatePossessionLetter()}
                        disabled={unitHandover?.status != "Registration Done"}
                        style={{
                          backgroundColor:
                            unitHandover?.status != "Registration Done"
                              ? "grey"
                              : "",
                        }}
                      >
                        Generate
                      </button>
                    </div>
                  </div>
                )}

                <br />

                {unitHandover?.unitPossession?.generatePossessionLetter !=
                  null && (
                  <div className="row justify-content-center">
                    <div className="col-12 text-center">
                      {/*<p>
                        <b>Key Details : </b>
                        {unitHandover?.keyDetails}
                  </p>*/}
                      <p>
                        Generated By :{" "}
                        <b>
                          {
                            unitHandover?.unitPossession
                              ?.generatedByUserFullName
                          }
                        </b>
                      </p>
                      <p>
                        Possession Letter Draft:{" "}
                        <a
                          href={
                            unitHandover?.unitPossession
                              ?.generatePossessionLetter
                          }
                        >
                          <b>Download</b>
                        </a>
                      </p>
                    </div>
                  </div>
                )}
              </Paper>

              <br />
              <br />

              {/* upload signed possession letter */}
              <Paper
                elevation={1}
                style={{
                  padding: "30px 20px",
                  width: "90%",
                  margin: "auto",
                }}
              >
                <h4 className="text-center">Upload Signed Possession Letter</h4>

                {unitHandover?.unitPossession?.uploadPossessionLetter ==
                  "Not Uploaded" && (
                  <form onSubmit={(e) => uploadPossessionLetter(e)}>
                    <div className="row justify-content-center  mt-4">
                      <div className="col-6">
                        <label>Possession Letter Date</label>
                        <input
                          type="date"
                          required
                          className="form-control"
                          value={possessionLetterInfo?.possessionLetterDate}
                          onChange={(e) => {
                            setPossessionLetterInfo((prev) => {
                              return {
                                ...prev,
                                possessionLetterDate: e.target.value,
                              };
                            });
                          }}
                        />
                      </div>

                      <div className="col-6">
                        <label>Possession Letter Scan</label>
                        <input
                          type="file"
                          required
                          id="possessionLetterFile"
                          name="filename"
                          onChange={(e) => {
                            setPossessionLetterInfo((prev) => {
                              return { ...prev, file: e.target.files[0] };
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center  mt-4">
                      <div className="col-6">
                        <label>Physical Possession Date</label>
                        <input
                          type="date"
                          required
                          className="form-control"
                          value={possessionLetterInfo?.physicalPossessionDate}
                          onChange={(e) => {
                            setPossessionLetterInfo((prev) => {
                              return {
                                ...prev,
                                physicalPossessionDate: e.target.value,
                              };
                            });
                          }}
                        />
                      </div>

                      <div className="col-6 text-center">
                        <button
                          disabled={
                            userInfo?.userRole !== "Director" &&
                            userInfo?.userRole !== "Operations"
                          }
                          style={{
                            backgroundColor:
                              userInfo?.userRole !== "Director" &&
                              userInfo?.userRole !== "Operations"
                                ? "grey"
                                : "",
                          }}
                          className="unit-handover__btns"
                          type="submit"
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                  </form>
                )}

                <br />

                {unitHandover?.unitPossession?.uploadPossessionLetter !=
                  "Not Uploaded" && (
                  <div className="row justify-content-center">
                    <div className="col-12 text-center">
                      <p>
                        <b>Possession Letter Date : </b>
                        {dateFormatting(
                          unitHandover?.unitPossession?.possessionLetterDate
                        )}
                      </p>
                      <p>
                        <b>Physical Possession Date : </b>
                        {dateFormatting(
                          unitHandover?.unitPossession?.physicalPossessionDate
                        )}
                      </p>
                      <p>
                        <b>Maintenance Start Date : </b>
                        {dateFormatting(
                          unitHandover?.unitPossession?.maintenanceStartDate
                        )}
                      </p>
                      <p>
                        <b>Uploaded By : </b>
                        {unitHandover?.unitPossession?.uploadedByUserFullName}
                      </p>
                      <a
                        href={
                          unitHandover?.unitPossession?.uploadPossessionLetter
                        }
                      >
                        View Document
                      </a>
                    </div>
                  </div>
                )}
              </Paper>

              <br />
              <br />

              {/* Receive Final DD From Bank */}
              {unitHandover?.isFinalPaymentByDD && (
                <ReceiveFinalDDFromBank
                  unitHandover={unitHandover}
                  setUnitHandover={setUnitHandover}
                />
              )}

              <br />
              <br />

              {/* 
                                    <Paper elevation={1}
                                        style={{
                                            padding: '30px 20px',
                                            width: '80%',
                                            margin: 'auto'
                                        }}>
                                        <div className='row justify-content-center'>
                                            <div className='col-4 text-center'>
                                                <h5>Upload Payment Memo</h5>
                                            </div>

                                            <div className='col-4 text-center'>
                                                <input type="file" id="paymentMemoFile" name="filename" onChange={(e) => setPaymentFile(e.target.files[0])} />
                                            </div>

                                            <div className='col-3 text-center'>
                                                <button className='unit-handover__btns'
                                                    onClick={uploadPaymentMemo}
                                                >
                                                    Upload
                                                </button>
                                            </div>

                                        </div>

                                        <br />

                                        {
                                            unit?.paymentMemo
                                            &&
                                            <div className='row container-fluid justify-content-center text-left'>
                                                <div className='col-3'>
                                                    <label>Uploaded By</label>
                                                    <input type="text" readOnly className='form-control' value={unit?.paymentMemo?.uploadedByUserFullName} />
                                                </div>

                                                <div className='col-3'>
                                                    <label>Upload Date</label>
                                                    <input type="text" readOnly className='form-control' value={dateFormatting(unit?.paymentMemo?.uploadDate)} />
                                                </div>

                                                <div className='col-3'>
                                                    <label>Document Link</label>
                                                    <br />
                                                    <a href={unit?.paymentMemo?.paymentMemoLink}>Link</a>
                                                </div>

                                                <br />
                                            </div>
                                        }
                                    </Paper>

                                    <br />
                                    <br />

                                    <Paper elevation={1}
                                        style={{
                                            padding: '30px 20px',
                                            width: '80%',
                                            margin: 'auto'
                                        }}>
                                        <div className='row justify-content-center'>
                                            <div className='col-5 text-center'>
                                                <h5>Registrar Appointment</h5>
                                            </div>

                                            <div className='col-5 text-center'>
                                                <Switch
                                                    checked={unit?.registrarAppointment?.registrarAppointmentDone || false}
                                                    color="primary"
                                                    onChange={(e) => {
                                                        if (unit?.registrarAppointment?.registrarAppointmentDone === true) {
                                                            return;
                                                        }
                                                        setRegistrarAppointmentModal(true)
                                                        setSerial('registrarAppointment')
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <br />

                                        {unit?.registrarAppointment?.registrarAppointmentDone
                                            &&
                                            <>
                                                <div className='row container-fluid justify-content-center text-left'>
                                                    <div className='col-3'>
                                                        <label>Registrar Name</label>
                                                        <input type="text" readOnly className='form-control' value={unit?.registrarAppointment.registrarName} />
                                                    </div>

                                                    <div className='col-3'>
                                                        <label>Registartion Comment</label>
                                                        <input type="text" readOnly className='form-control' value={unit?.registrarAppointment.registrationComment} />
                                                    </div>
                                                </div>

                                                <br />

                                                <div className='row container-fluid justify-content-center text-left'>
                                                    <div className='col-3'>
                                                        <label>Registration Location</label>
                                                        <input type="text" readOnly className='form-control' value={unit?.registrarAppointment.registrationLocation} />
                                                    </div>

                                                    <div className='col-3'>
                                                        <label>Appointment Date</label>
                                                        <input type="text" readOnly className='form-control' value={dateFormatting(unit?.registrarAppointment.appointmentDate)} />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </Paper>

                                    <br />
                                    <br />

                                    <Paper elevation={1}
                                        style={{
                                            padding: '30px 20px',
                                            width: '80%',
                                            margin: 'auto'
                                        }}>
                                        <div className='row justify-content-center'>
                                            <div className='col-5 text-left'>

                                                <h5>Registration deed draft , Payment Memo and Registration date acknowledged and confirmed by customer.</h5>

                                            </div>

                                            <div className='col-5 text-center'>
                                                <Switch
                                                    checked={unit?.registrationPreparation?.isCustomerAcknowledged || false}
                                                    color="primary"
                                                    onChange={(e) => {
                                                        if (unit?.registrationPreparation?.isCustomerAcknowledged === true) {
                                                            return;
                                                        }
                                                        setModal(true)
                                                        setSerial('customerAcknowledgement')
                                                    }}
                                                />
                                            </div>

                                        </div>

                                        <br />

                                        {
                                            unit?.registrationPreparation?.isCustomerAcknowledged
                                            &&
                                            <div className='row container-fluid justify-content-center text-left'>
                                                <div className='col-3'>
                                                    <label>Verified By</label>
                                                    <input type="text" readOnly className='form-control' value={unit?.registrationPreparation.verifiedByUserFullName} />
                                                </div>

                                                <div className='col-3'>
                                                    <label>Acknowledgement Date</label>
                                                    <input type="text" readOnly className='form-control' value={dateFormatting(unit?.registrationPreparation.acknowledgementDate)} />
                                                </div>

                                                <br />
                                            </div>
                                        }
                                    </Paper>

                                    <br />
                                    <br />

                                    <Paper elevation={1}
                                        style={{
                                            padding: '30px 20px',
                                            width: '80%',
                                            margin: 'auto'
                                        }}>
                                        <div className='row justify-content-center'>
                                            <div className='col-5 text-center'>
                                                <h5>Registration Completion</h5>
                                            </div>

                                            <div className='col-5 text-center'>
                                                <Switch
                                                    checked={unit?.registrationCompletion?.registrationAgreementScan != null ? true : false}
                                                    color="primary"
                                                    onChange={(e) => {
                                                        if (unit?.registrationPreparation?.registrationAgreementScan) {
                                                            return;
                                                        }
                                                        setModal(true)
                                                        setSerial('registrationCompletion')
                                                    }}
                                                />
                                            </div>

                                            <br />
                                            <br />

                                        </div>

                                        {unit?.registrationCompletion?.registrationAgreementScan
                                            &&
                                            <div className='row container-fluid justify-content-center text-left'>
                                                <div className='col-3'>
                                                    <label>Uploaded By</label>
                                                    <input type="text" readOnly className='form-control' value={unit?.registrationCompletion.uploadedByUserFullName} />
                                                </div>

                                                <div className='col-3'>
                                                    <label>Registration Date</label>
                                                    <input type="text" readOnly className='form-control' value={dateFormatting(unit?.registrationCompletion.registrationDate)} />
                                                </div>

                                                <div className='col-3'>
                                                    <label>Registration Deed No.</label>
                                                    <input type="text" readOnly className='form-control' value={unit?.registrationCompletion.registrationNo} />
                                                </div>

                                                <div className='col-3'>
                                                    <label>Registration Deed Scan Link</label>
                                                    <br />
                                                    <a href={unit?.registrationCompletion?.registrationAgreementScan}>Document Link</a>
                                                </div>

                                                <br />
                                            </div>}
                                    </Paper>

                                    <br />
                                    <br />

                                    <Paper elevation={1}
                                        style={{
                                            padding: '30px 20px',
                                            width: '80%',
                                            margin: 'auto'
                                        }}>
                                        <div className='row justify-content-center'>

                                            <div className='col-5 text-center mt-2'>
                                                <h5>
                                                    Generate Handover Checklist
                                                </h5>
                                            </div>
                                            <div className='col-3 text-center'>
                                                <button
                                                    className='unit-handover__btns'
                                                    onClick={generateChecklist}
                                                    disabled={unit?.handOverChecklist}
                                                    style={{ backgroundColor: unit?.handOverChecklist ? 'grey' : '' }}
                                                >Generate</button>
                                            </div>

                                        </div>

                                        <br />

                                        {unit?.handOverChecklist
                                            &&

                                            <div className='row justify-content-center'>
                                                <div className='col-12 text-center'>
                                                    <p><b>Generated By : </b>{unit?.handOverChecklist.generatedByUserFullName}</p>
                                                    <a href={unit?.handOverChecklist.generatedChecklist}>View Document</a>
                                                </div>
                                            </div>

                                        }

                                    </Paper>

                                    <br />
                                    <br />

                                    <Paper elevation={1}
                                        style={{
                                            padding: '30px 20px',
                                            width: '80%',
                                            margin: 'auto'
                                        }}>
                                        <div className='row justify-content-center'>

                                            <div className='col-5 text-center mt-2'>
                                                <h5>Upload Signed Handover Checklist</h5>
                                            </div>

                                            <div className='col-3 text-center mt-2'>

                                                <input type="file" id="handoverChecklistFile" name="filename" onChange={(e) => setUploadFile(e.target.files[0])} />

                                            </div>

                                            <div className='col-3 text-center'>
                                                <button
                                                    className='unit-handover__btns'
                                                    onClick={uploadChecklist}
                                                >Upload</button>
                                            </div>

                                        </div>

                                        <br />

                                        {unit?.handOverChecklist?.uploadedByUserFullName
                                            &&
                                            <div className='row justify-content-center'>
                                                <div className='col-12 text-center'>
                                                    <p><b>Uploaded By : </b>{unit?.handOverChecklist.uploadedByUserFullName}</p>
                                                    <a href={unit?.handOverChecklist.uploadScanChecklist}>View Document</a>
                                                </div>
                                            </div>
                                        }

                                    </Paper>

                                    <br />
                                    <br />

                                    <Paper elevation={1}
                                        style={{
                                            padding: '30px 20px',
                                            width: '80%',
                                            margin: 'auto'
                                        }}>
                                        <div className='row justify-content-center'>

                                            <div className='col-5 text-center mt-2'>
                                                {
                                                    unit.keyDetails ?
                                                        <h5>Key Details</h5>
                                                        :
                                                        <h5>
                                                            Add Key Details
                                                        </h5>
                                                }

                                            </div>

                                        </div>

                                        <br />

                                        {
                                            unit.keyDetails
                                                ?
                                                <div className='row justify-content-center'>
                                                    <div className='col-4 text-center mt-2'>
                                                        <input className='form-control' value={unit?.keyDetails} readOnly disabled />
                                                    </div>
                                                </div>
                                                :
                                                <div className='row justify-content-center'>
                                                    <div className='col-4 text-center mt-2'>
                                                        <input className='form-control' value={keyDetails}
                                                            onChange={(e) => setKeyDetails(e.target.value)} />
                                                    </div>
                                                    <div className='col-3 text-center'>

                                                        <button
                                                            className='unit-handover__btns'
                                                            onClick={addKeyDetails}
                                                        >Save</button>
                                                    </div>
                                                </div>
                                        }

                                        <br />


                                    </Paper>

                                    <br />
                                    <br />


                                    <Paper elevation={1}
                                        style={{
                                            padding: '30px 20px',
                                            width: '80%',
                                            margin: 'auto'
                                        }}>
                                        <div className='row justify-content-center'>

                                            <div className='col-5 text-center mt-2'>
                                                <h5>Upload Signed Possession Letter</h5>
                                            </div>

                                            <div className='col-3 text-center mt-2'>
                                                <input type="file" id="possessionLetterFile" name="filename" onChange={(e) => setUploadPossessionFile(e.target.files[0])} />
                                            </div>

                                            <div className='col-3 text-center'>
                                                <button
                                                    className='unit-handover__btns'
                                                    onClick={uploadPossessionLetter}
                                                >Upload</button>
                                            </div>

                                        </div>

                                        <br />

                                        {unit?.unitPossession?.uploadPossessionLetter
                                            &&
                                            <div className='row justify-content-center'>
                                                <div className='col-12 text-center'>
                                                    <p><b>Uploaded By : </b>{unit?.unitPossession.uploadedByUserFullName}</p>
                                                    <a href={unit?.unitPossession.uploadPossessionLetter}>View Document</a>

                                                </div>
                                            </div>
                                        }

                                    </Paper>

                                    <br />
                                    <br />
                                */}
              {/*
              <div className="row w-100 justify-content-center">
                <div className="col-12 d-flex justify-content-center mr-4">
                  <button
                    className="unit-handover__btns"
                    // disabled={!unit?.unitPossession?.uploadPossessionLetter || unit?.unitPossession?.uploadPossessionLetter === "Not Uploaded"}
                    disabled={unit?.status === "In Maintanance"}
                    // style={{ backgroundColor: (!unit?.unitPossession?.uploadPossessionLetter || unit?.unitPossession?.uploadPossessionLetter === "Not Uploaded") ? 'grey' : '' }}
                    style={{
                      backgroundColor:
                        unit?.status === "In Maintanance" ? "grey" : "",
                    }}
                    onClick={() => setHandoverCompleteModal(true)}
                  >
                    Mark Handover Complete
                  </button>
                  </div>
                */}
            </div>
          )}
        </ThemeProvider>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={() => setModal(false)}
        closeAfterTransition
      >
        <Fade in={modal}>
          <div className="paper" style={{ minWidth: "50vw" }}>
            {serial === "registrationCompletion" ? (
              <form onSubmit={(e) => toggleSwitch(e)}>
                <div className="row d-flex justify-content-center">
                  <div className="col-6">Registration Deed Scan</div>

                  <div className="col-6">
                    <input
                      type="file"
                      id="myFile"
                      name="filename"
                      className="form-control"
                      required
                      onChange={(e) =>
                        setRegistrationDeedScanLink(e.target.files[0])
                      }
                    />
                  </div>
                </div>

                <br />

                <div className="row d-flex justify-content-center">
                  <div className="col-6">Registration Date</div>
                  <div className="col-6  d-flex justify-content-end">
                    <input
                      type="date"
                      value={registrationDate}
                      required
                      className="form-control"
                      onChange={(e) => setRegistrationDate(e.target.value)}
                    />
                  </div>
                </div>

                <br />

                <div className="row d-flex justify-content-center">
                  <div className="col-6">Registration Deed No.</div>
                  <div className="col-6  d-flex justify-content-end">
                    <input
                      type="number"
                      required
                      className="form-control"
                      value={registrationDeed}
                      onChange={(e) => setRegistrationDeed(e.target.value)}
                    />
                  </div>
                </div>

                <br />

                <div className="row d-flex justify-content-center">
                  <div
                    className="col-4"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className="btn"
                      style={{
                        color: "white",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setModal(false);
                        setRegistrationDate("");
                        setRegistrationDeed("");
                        setRegistrationDeedScanLink("");
                        setSerial("");
                        let element = document.querySelector("#myFile");
                        element.value = "";
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div
                    className="col-4"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className="btn"
                      style={{
                        color: "white",
                      }}
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <>
                <h5 style={{ textAlign: "center" }}>
                  Are you sure you want to mark this complete ?
                </h5>

                <form onSubmit={(e) => toggleSwitch(e)}>
                  <br />

                  {serial === "stampDuty" && (
                    <>
                      <p style={{ textAlign: "center" }}>
                        Is Stamp Duty paid by Customer?
                      </p>
                      <div className="row container-fluid justify-content-center">
                        <div className="col-12 d-flex justify-content-center">
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              value={stampDutyPaidByCustomer}
                              // onChange={(e) => setStampDutyPaidByCustomer(e.target.value)}
                              readOnly
                              required
                            >
                              <FormControlLabel
                                value={true}
                                onChange={(e) =>
                                  setStampDutyPaidByCustomer(true)
                                }
                                control={
                                  <Radio
                                    sx={{
                                      color: "#EE4B46",
                                      "&.Mui-checked": {
                                        color: "#EE4B46",
                                      },
                                    }}
                                  />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value={false}
                                onChange={(e) =>
                                  setStampDutyPaidByCustomer(false)
                                }
                                control={
                                  <Radio
                                    sx={{
                                      color: "#EE4B46",
                                      "&.Mui-checked": {
                                        color: "#EE4B46",
                                      },
                                    }}
                                  />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>

                      {stampDutyPaidByCustomer && (
                        <div className="row justify-content-center">
                          <div className="col-6">
                            <label>Stamp Duty Fees</label>
                            <input
                              type="number"
                              className="form-control"
                              value={stampDutyFees}
                              onChange={(e) => setStampDutyFees(e.target.value)}
                            />
                          </div>

                          <div className="col-6">
                            <label>Registration Fees</label>
                            <input
                              type="number"
                              className="form-control"
                              value={registrationFees}
                              onChange={(e) =>
                                setRegistrationFees(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      )}

                      <br />

                      <div className="row container-fluid justify-content-center">
                        <p style={{ textAlign: "center" }}>
                          Is Misc. Fees paid by Customer?
                        </p>
                        <div className="col-12 d-flex justify-content-center">
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              value={collectMiscFees}
                              // onChange={(e) => setStampDutyPaidByCustomer(e.target.value)}
                              readOnly
                              required
                            >
                              <FormControlLabel
                                value={true}
                                onChange={(e) =>
                                  setCollectMiscFees(e.target.value)
                                }
                                control={
                                  <Radio
                                    sx={{
                                      color: "#EE4B46",
                                      "&.Mui-checked": {
                                        color: "#EE4B46",
                                      },
                                    }}
                                  />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value={false}
                                onChange={(e) =>
                                  setCollectMiscFees(e.target.value)
                                }
                                control={
                                  <Radio
                                    sx={{
                                      color: "#EE4B46",
                                      "&.Mui-checked": {
                                        color: "#EE4B46",
                                      },
                                    }}
                                  />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>

                      <br />

                      <div className="row justify-content-center">
                        <div className="col-6">
                          <label>Misc. Registration Cost</label>
                          <input
                            type="number"
                            required
                            className="form-control"
                            value={miscRegistrationCost}
                            onChange={(e) =>
                              setMiscRegistrationCost(e.target.value)
                            }
                          />
                        </div>

                        <div className="col-6">
                          <label>Collection Date</label>
                          <input
                            type="date"
                            required
                            className="form-control"
                            value={collectionDate}
                            onChange={(e) => setCollectionDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {(serial === "settleExtraWork" ||
                    serial === "settleLegalCost") && (
                    <>
                      <div className="row justify-content-center">
                        <div className="col-6">
                          <label>Total Amount</label>
                          <input
                            type="number"
                            required
                            className="form-control"
                            value={totalAmount}
                            onChange={(e) => setTotalAmount(e.target.value)}
                          />
                        </div>
                        <div className="col-6">
                          <label>Settlment Date</label>
                          <input
                            type="date"
                            required
                            className="form-control"
                            value={settlementDate}
                            onChange={(e) => setSettlementDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <br />
                  <div className="row container-fluid justify-content-center">
                    <div className="col-6 text-right">
                      <button className="modal__btns" type="submit">
                        Yes
                      </button>
                    </div>
                    <div className="col-6 text-left">
                      <button
                        className="modal__btns"
                        onClick={(e) => {
                          e.preventDefault();
                          setModal(false);
                          reset();
                          setTotalAmount("");
                          setSettlementDate("");
                        }}
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </Fade>
      </Modal>

      {/* ////////// Modal for marking handover complete ///////// */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={handoverCompleteModal}
        onClose={() => setHandoverCompleteModal(false)}
        closeAfterTransition
      >
        <Fade in={handoverCompleteModal}>
          <div className="paper">
            <h4>Are you sure you want to mark this Complete?</h4>
            <br />
            <div className="row container-fluid justify-content-center">
              <div className="col-6 text-right">
                <button
                  className="modal__btns"
                  onClick={markUnitHandoverComplete}
                >
                  Yes
                </button>
              </div>
              <div className="col-6 text-left">
                <button
                  className="modal__btns"
                  onClick={(e) => {
                    e.preventDefault();
                    setHandoverCompleteModal(false);
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      {/* /////////////// Modal for Registrar Appointment //////////// */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={registrarAppointmentModal}
        onClose={() => {
          setRegistrarAppointmentModal(false);
          // resetRegistrarAppointment()
        }}
        closeAfterTransition
      >
        <Fade in={registrarAppointmentModal}>
          <form className="paper" onSubmit={(e) => registrarAppointment(e)}>
            <h3 className="text-center">Registrar Appointment</h3>

            <div className="row justify-content-center">
              <div className="col-6">
                <label>Registrar Name</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={registrarName}
                  onChange={(e) => setRegistrarName(e.target.value)}
                />
              </div>

              <div className="col-6">
                <label>Registration Comments</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={registrationComment}
                  onChange={(e) => setRegistrationComment(e.target.value)}
                />
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              <div className="col-6">
                <label>Registration Loaction</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={registrationLocation}
                  onChange={(e) => setRegistrationLocation(e.target.value)}
                />
              </div>

              <div className="col-6">
                <label>Appointment Date</label>
                <input
                  type="date"
                  className="form-control"
                  required
                  value={appointmentDate}
                  onChange={(e) => setAppointmentDate(e.target.value)}
                />
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              <div className="col-6 text-right">
                <button className="modal__btns" type="submit">
                  Yes
                </button>
              </div>
              <div className="col-6 text-left">
                <button
                  className="modal__btns"
                  onClick={(e) => {
                    e.preventDefault();
                    setRegistrarAppointmentModal(false);
                    resetRegistrarAppointment();
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </form>
        </Fade>
      </Modal>

      {/* /////////////// Modal for Generate Possession Letter ////////// */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={generatePossessionModal}
        onClose={() => setGeneratePossessionModal(false)}
        closeAfterTransition
      >
        <Fade in={generatePossessionModal}>
          <div className="paper" style={{ minWidth: "25%" }}>
            <div className="row justify-content-center">
              <div className="col-12">
                <label>Physical Possession Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={physicalPossessionDate}
                  onChange={(e) => setPhysicalPossessionDate(e.target.value)}
                />
              </div>
            </div>
            <br />
            <div className="row justify-content-center">
              <div className="col-6 text-center">
                <button
                  className="modal__btns"
                  onClick={generatePossessionLetter}
                >
                  Save
                </button>
              </div>
              <div className="col-6 text-center">
                <button
                  className="modal__btns"
                  onClick={(e) => {
                    e.preventDefault();
                    setGeneratePossessionModal(false);
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      {/* //////////// Modal for spinner //////////// */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={loader}
        onClose={() => {
          setLoader(false);
        }}
        closeAfterTransition
      >
        <Fade in={loader}>
          <div>
            <MoonLoader
              color="red"
              className="spinner"
              loading={loader}
              size={70}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default UnitHandover;
