import React, { useEffect, useState } from 'react'
import Button from '../Button/Button';
import './Claims.scss';
import MaterialTable, { MTableToolbar } from 'material-table';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
    Switch,
    createMuiTheme,
    ThemeProvider,
    Select,
    InputLabel,
    MenuItem,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { useStyles } from "../../utils/ModalStyles";
import FormControl from "@mui/material/FormControl";
import Swal from "sweetalert2";


const ClaimsToBeApproved = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const navigate = useNavigate()
    const classes = useStyles();

    const [listOfClaims, setListOfClaims] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [employeeId, setEmployeeId] = useState('')
    const [claimType, setClaimType] = useState('')
    const [claimId, setClaimId] = useState('')
    const [approveRejectModal, setApproveRejectModal] = useState(false);
    const [approvalComments, setApprovalComments] = useState('')


    useEffect(() => {

        getlistOfClaimsFinanceApproval()

    }, [employeeId, claimType])

    const getlistOfClaimsFinanceApproval = () => {

        let url = `${BASE_URL}/api/v1/hrmgmt/claims/listOfClaimsFinanceApproval`;

        if (employeeId != '') {
            url += `?raisedByEmpId=${employeeId}`
        }



        axios.get(url, {
            headers: {
                Authorization: `bearer ${userInfo.token}`
            }
        })
            .then((response) => {

                if (response.data.status === true) {
                    setListOfClaims(response.data.listOfClaims)
                } else {
                    setListOfClaims([])
                }
            })

    }


    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                setEmployeeList(response.data.employees)
            })
    }, [])


    const dateFormatting = (date) => {

        let str = new Date(date).toLocaleString('en-US', { timeZone: 'Asia/Jakarta' });

        let d = new Date(str).getDate();
        if (d < 10) {
            d = `0${d}`
        }
        let m = new Date(str).getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = new Date(str).getFullYear();
        return `${d}-${m}-${y}`
    }

    const reset = () => {

        setEmployeeId('')
    }


    const handleApproveRejectClaim = (isApproved) => {

        if (approvalComments == '') {
            return;
        }

        axios.patch(`${BASE_URL}/api/v1/hrmgmt/claims/claimApprovalByFinance`, {
            claimId,
            isApproved,
            financeApprovalComments: approvalComments,
            financeApprover: userInfo.userName,
            financeApproverFullName: userInfo.userFullName,
            financeApproverEmpId: userInfo.employeeId
        }, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {

                setApproveRejectModal(false)

                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                    }).then(function () {
                        setApprovalComments('')
                        getlistOfClaimsFinanceApproval();
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                        text: response.data.message
                    });
                }

            }).catch((err) => {

                setApproveRejectModal(false)

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                });
            })
    }

    return (
        <div className='claims-comp'>

            <Button navigateTo={'/finance/claims'} />

            <h4 style={{ marginTop: '40px' }}>Claims To Be Approved</h4>
            <div className='claims-comp__table'>
                <MaterialTable
                    data={listOfClaims}
                    title=""
                    columns={[
                        {
                            title: "Approve/Reject",
                            render: (rowData) => (<button className='claims-comp__btn'

                                onClick={() => {
                                    setClaimId(rowData.claimId)
                                    setApproveRejectModal(true)
                                }}>Approve/Reject</button>)
                        },
                        { title: "Claim Id", field: "claimId" },
                        { title: "Raised By", field: "raisedByFullName" },
                        { title: "Claim Status", field: "claimStatus" },
                        {
                            title: "Claim Type",
                            field: "claimType",
                        },
                        {
                            title: "Applied Date",
                            render: (rowData) => {

                                let res = '';
                                if (rowData.appliedDate) {
                                    res = dateFormatting(rowData.appliedDate)
                                }

                                return !rowData.appliedDate ? "" : res
                            },
                            customSort: (a, b) => (new Date(a.appliedDate).getTime() < new Date(b.appliedDate).getTime() ? -1 : 1),
                            defaultSort: "desc",
                        },

                        {
                            title: "Expense Date",
                            render: (rowData) => {

                                let res = '';
                                if (rowData.expenseDate) {
                                    res = dateFormatting(rowData.expenseDate)
                                }

                                return !rowData.expenseDate ? "" : res
                            },
                            // customSort: (a, b) => (a.expenseDate < b.expenseDate ? -1 : 1),
                            // defaultSort: "desc",
                        },
                        {
                            title: "Line Manager",
                            render: (rowData) => (rowData.claimStatus === "AutoApproved" ? 'Auto Approved' : rowData.lineMgrFullName)
                        },
                        {
                            title: "Line Manager Approval",
                            render: (rowData) => {

                                let res = '';
                                if (rowData.lineMgrApprovalDate) {
                                    res = dateFormatting(rowData.lineMgrApprovalDate)
                                }

                                return !rowData.lineMgrApprovalDate ? "" : res
                            },
                            customSort: (a, b) => (a.lineMgrApprovalDate < b.lineMgrApprovalDate ? -1 : 1),
                            defaultSort: "desc",
                        },

                    ]}

                    actions={[
                        {
                            icon: "remove_red_eye",
                            tooltip: "View Claim",
                            onClick: (event, rowData) => {
                                navigate(`/finance/claims/${rowData.claimId}`);
                            },
                        },
                    ]}

                    options={{
                        search: window.innerWidth < 700 ? false : true,
                        searchFieldStyle: {
                            width: 200,
                        },
                        paging: true,
                        pageSize: 20,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [5, 10, 20, 30],
                        actionsColumnIndex: 0,
                        headerStyle: {
                            backgroundColor: "#EE4B46",
                            color: "#fff",
                            whiteSpace: "nowrap",
                        },
                    }}

                    components={{
                        Toolbar: (props) => (
                            <div className="filters text-left">
                                <MTableToolbar {...props} />


                                <FormControl
                                    variant='standard'
                                    className="formControl"
                                    style={{
                                        margin: '0px 13px',
                                        marginTop: "-65px"
                                    }}
                                >
                                    <InputLabel id="demo-simple-select-helper-label">
                                        Employee
                                    </InputLabel>
                                    <Select
                                        value={employeeId}
                                        onChange={(e) => {
                                            setEmployeeId(e.target.value);
                                        }}
                                        inputProps={{ "aria-label": "Without label" }}
                                    >
                                        <MenuItem value="">Select an otpion</MenuItem>
                                        {
                                            employeeList?.map((emp, index) => {
                                                return <MenuItem key={index} value={emp.employeeId}>{`${emp.employeeFirstName} ${emp.employeeLastName ? emp.employeeLastName : ''}`}</MenuItem>
                                            })
                                        }


                                    </Select>
                                </FormControl>


                                <FormControl
                                    className="formControl"
                                    style={{ marginTop: '-45px' }}
                                >
                                    <button
                                        onClick={reset}
                                        style={{
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid black",
                                            borderRadius: "20px",
                                            padding: "2px",
                                        }}
                                    >
                                        Reset Filter
                                    </button>
                                </FormControl>
                            </div>
                        ),
                    }}

                ></MaterialTable>
            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={approveRejectModal}
                onClose={() => setApproveRejectModal(false)}
                closeAfterTransition
            >
                <Fade in={approveRejectModal}>
                    <div className="paper">
                        <h5>Do you want to Approve or Reject this Claim?</h5>
                        <br />
                        <div className="row container-fluid justify-content-center">
                            <div className='col-md-8 col-12'>
                                <label>Approval Comments <span style={{ color: 'red' }}>*</span></label>
                                <input type="text" className='form-control'
                                    required
                                    autoFocus
                                    value={approvalComments}
                                    onChange={(e) => setApprovalComments(e.target.value)}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row container-fluid justify-content-center">
                            <div className="col-6 text-right">
                                <button className="modal__btns" onClick={() => handleApproveRejectClaim(true)}>
                                    Approve
                                </button>
                            </div>
                            <div className="col-6 text-left">
                                <button
                                    className="modal__btns"
                                    onClick={() => handleApproveRejectClaim(false)}
                                    style={{
                                        backgroundColor: "white",
                                        color: "black",
                                    }}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>

        </div >
    )
}

export default ClaimsToBeApproved