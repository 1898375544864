import React, { useEffect, useState } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table';
import { BASE_URL } from '../../utils/config/url';
import Button from '../Button/Button';
import axios from 'axios';
import './Claims.scss';
import {
    Switch,
    createMuiTheme,
    ThemeProvider,
    Select,
    InputLabel,
    MenuItem,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import FormControl from "@mui/material/FormControl";
import { useStyles } from '../../utils/ModalStyles';


const ListOfAllClaims = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const navigate = useNavigate()
    const classes = useStyles();

    const [listOfClaims, setListOfClaims] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [employeeId, setEmployeeId] = useState('')
    const [claimStatus, setClaimStatus] = useState('')
    const [claimType, setClaimType] = useState('')


    // useEffect(() => {

    //     axios.get(`${BASE_URL}/api/v1/hrmgmt/claims/getListOfClaims`, {
    //         headers: {
    //             Authorization: `bearer ${userInfo.token}`
    //         }
    //     })
    //         .then((response) => {

    //             if (response.data.status === true) {
    //                 setListOfClaims(response.data.listOfClaim)
    //             }
    //         })

    // }, [])

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {

                setEmployeeList(response.data.employees)
            })
    }, [])

    useEffect(() => {


        let url = `${BASE_URL}/api/v1/hrmgmt/claims/getListOfClaims`;

        if (claimStatus != '') {
            url += `?claimStatus=${claimStatus}`
        }

        if (employeeId != '') {
            if (!url.split('?')[1]) {
                url += `?raisedByEmpId=${employeeId}`
            } else {
                url += `&raisedByEmpId=${employeeId}`
            }
        }

        if (claimType != '') {

            if (!url.split('?')[1]) {
                url += `?claimType=${claimType}`
            } else {
                url += `&claimType=${claimType}`
            }

        }


        axios.get(url, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {

                if (response.data.status === true) {

                    setListOfClaims(response.data.listOfClaim)
                } else {
                    setListOfClaims([])
                }
            })

    }, [claimStatus, claimType, employeeId])


    const dateFormatting = (date) => {

        if (!date) return 'N/A'
        // let str = new Date(date).toLocaleString('en-US', { timeZone: 'Asia/Jakarta' });
        let str = new Date(date);

        let d = new Date(str).getDate();
        if (d < 10) {
            d = `0${d}`
        }
        let m = new Date(str).getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = new Date(str).getFullYear();
        return `${d}-${m}-${y}`
    }

    const reset = () => {
        setClaimStatus('')
        setClaimType('')
        setEmployeeId('')
    }

    return (
        <div className='claims-comp'>

            <Button navigateTo={'/finance/claims'} />
            <br />

            <h4 style={{ marginTop: '40px' }}>All Claims</h4>
            <div className='claims-comp__table'>
                <MaterialTable
                    data={listOfClaims}
                    title=""
                    columns={[

                        { title: "Claim Id", field: "claimId" },
                        { title: "Raised By", field: "raisedByFullName" },
                        { title: "Claim Status", field: "claimStatus" },
                        {
                            title: "Claim Type",
                            field: "claimType",
                        },
                        {
                            title: "Applied Date",
                            render: (rowData) => {

                                let res = '';
                                if (rowData.appliedDate) {
                                    res = dateFormatting(rowData.appliedDate)
                                }

                                return !rowData.appliedDate ? "" : res
                            },
                            customSort: (a, b) => (new Date(a.appliedDate).getTime() < new Date(b.appliedDate).getTime() ? -1 : 1),
                            defaultSort: "desc",
                        },

                        {
                            title: "Expense Date",
                            render: (rowData) => {

                                let res = '';
                                if (rowData.expenseDate) {
                                    res = dateFormatting(rowData.expenseDate)
                                }

                                return !rowData.expenseDate ? "" : res
                            },
                            // customSort: (a, b) => (a.expenseDate < b.expenseDate ? -1 : 1),
                            // defaultSort: "desc",
                        },
                        {
                            title: "Line Manager",
                            render: (rowData) => (rowData.claimStatus === "AutoApproved" ? 'Auto Approved' : rowData.lineMgrFullName)
                        },
                        {
                            title: "Line Manager Approval",
                            render: (rowData) => {

                                let res = '';
                                if (rowData.lineMgrApprovalDate) {
                                    res = dateFormatting(rowData.lineMgrApprovalDate)
                                }

                                return !rowData.lineMgrApprovalDate ? "" : res
                            },
                            customSort: (a, b) => (a.lineMgrApprovalDate < b.lineMgrApprovalDate ? -1 : 1),
                            defaultSort: "desc",
                        },
                        {
                            title: 'Finance Approver Name',
                            render: (rowData) => (rowData.financeApproverFullName ? rowData?.financeApproverFullName : 'N/A'),
                            // field: "financeApproverFullName"
                        },
                        {
                            title: 'Finance Approval',
                            render: (rowData) => (rowData.isApprovedFinance === true ? 'Yes' : 'No')
                        }

                    ]}

                    actions={[
                        {
                            icon: "remove_red_eye",
                            tooltip: "View Claim",
                            onClick: (event, rowData) => {
                                navigate(`/finance/claims/${rowData.claimId}`);
                            },
                        },
                    ]}

                    options={{
                        search: window.innerWidth < 700 ? false : true,
                        searchFieldStyle: {
                            width: 200,
                        },
                        paging: true,
                        pageSize: 20,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [5, 10, 20, 30],
                        actionsColumnIndex: 0,
                        headerStyle: {
                            backgroundColor: "#EE4B46",
                            color: "#fff",
                            whiteSpace: "nowrap",
                        },
                    }}

                    components={{
                        Toolbar: (props) => (
                            <div className="filters text-left">
                                <MTableToolbar {...props} />

                                <FormControl
                                    variant='standard'
                                    className="formControl"
                                    style={{ margin: '0 13px', marginTop: "-65px", width: '115px' }}
                                >
                                    <InputLabel id="demo-simple-select-helper-label">
                                        Claim Status
                                    </InputLabel>
                                    <Select
                                        value={claimStatus}
                                        onChange={(e) => {
                                            setClaimStatus(e.target.value);
                                        }}
                                        inputProps={{ "aria-label": "Without label" }}
                                    >
                                        <MenuItem value="">Select an otpion</MenuItem>
                                        <MenuItem value="LineManagerApproval">Line Manager Approval</MenuItem>
                                        <MenuItem value="AutoApproved">Auto Approved</MenuItem>
                                        <MenuItem value="ApprovedLineManager">Approved By LineManager</MenuItem>
                                        <MenuItem value="RejectedLineManager">Rejected By LineManager</MenuItem>
                                        <MenuItem value="FinanceApproved">Approved By Finance</MenuItem>
                                        <MenuItem value="FinanceRejected">Rejected By Finance</MenuItem>
                                        <MenuItem value="ClaimSettled">Claim Settled</MenuItem>


                                    </Select>
                                </FormControl>

                                <FormControl
                                    variant='standard'
                                    className="formControl"
                                    style={{ margin: '0 13px', marginTop: "-65px" }}
                                >
                                    <InputLabel id="demo-simple-select-helper-label">
                                        Claim Type
                                    </InputLabel>
                                    <Select
                                        value={claimType}
                                        onChange={(e) => {
                                            setClaimType(e.target.value);
                                        }}
                                        inputProps={{ "aria-label": "Without label" }}
                                    >
                                        <MenuItem value="">Select an option</MenuItem>
                                        <MenuItem value="Travel">Travel</MenuItem>
                                        <MenuItem value="Food">Food</MenuItem>
                                        <MenuItem value="Fuel">Fuel</MenuItem>
                                        <MenuItem value="Office Items">Office Items</MenuItem>
                                        <MenuItem value="Services">Services</MenuItem>
                                        <MenuItem value="Other">Other</MenuItem>
                                    </Select>
                                </FormControl>


                                <FormControl
                                    variant='standard'
                                    className="formControl"
                                    style={{ margin: '0 13px', marginTop: window.innerWidth < 500 ? '-50px' : "-65px" }}
                                >
                                    <InputLabel id="demo-simple-select-helper-label">
                                        Employee
                                    </InputLabel>
                                    <Select
                                        value={employeeId}
                                        onChange={(e) => {
                                            setEmployeeId(e.target.value);
                                        }}
                                        inputProps={{ "aria-label": "Without label" }}
                                    >
                                        <MenuItem value="">Select an otpion</MenuItem>
                                        {
                                            employeeList?.map((emp, index) => {
                                                return <MenuItem key={index} value={emp.employeeId}>{`${emp.employeeFirstName} ${emp.employeeLastName ? emp.employeeLastName : ''}`}</MenuItem>
                                            })
                                        }


                                    </Select>
                                </FormControl>

                                {window.innerWidth < 1000 && <br />}

                                <FormControl
                                    variant='standard'
                                    className="formControl"
                                    style={{ marginTop: window.innerWidth < 1000 ? '-20px' : '-50px' }}
                                >
                                    <button
                                        onClick={reset}
                                        style={{
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid black",
                                            borderRadius: "20px",
                                            padding: "2px",
                                        }}
                                    >
                                        Reset Filter
                                    </button>
                                </FormControl>
                            </div>
                        ),
                    }}


                ></MaterialTable>
            </div>

        </div>
    )
}

export default ListOfAllClaims