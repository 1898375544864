import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import LeadDetails from "../../components/InvLead/LeadDetails";
import SiteList from "../../components/InvLead/SiteList";
import GenerateQuotation from "../Quotation/GenerateQuotation";
import { BASE_URL } from "../../utils/config/url";
import Nav from 'react-bootstrap/Nav';
import '../../components/Tabs/Tabs.scss'
import './ViewIndividualCustomer.scss';
import ListOfComments from "../ListOfComments/ListOfComments";
import FollowUp from "../FollowUp/FollowUp";
import CustomerDetails from "./CustomerDetails";
import CustomerFinance from "./CustomerFinance";
import CustomerAllotment from "./CustomerAllotment";
import DemandList from "../Demands/DemandList";
import CustomerReceipts from "./CustomerReceipts";
import LatePayments from "./LatePayments";
import Button from "../../components/Button/Button";
import CoApplicants from "./CoApplicants";
import CarParkings from "./CarParkings";

const ViewIndividualCustomer = () => {
  const [invCustomer, setInvCustomer] = useState({});
  const [change, setChange] = useState(false);
  const [moneyReceipts, setMoneyReceipts] = useState([]);
  const [demands, setDemands] = useState([]);
  const [latePayments, setLatePayments] = useState([])
  const [processesLatePayments, setProcessedLatePayments] = useState([])

  const id = useParams().customerId;
  const [tab, setTab] = useState(localStorage.getItem('ActiveKeyCustomer'));
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // get individual customer
  // /api/v1/CustomerMgmt/Applicant/getCoApplicants/APF-31
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/CustomerMgmt/Customer/getCustomerByCustomerId/${id}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } });
      const data = res.data?.customer;
      setInvCustomer(data);
      setLatePayments(data.latePayments)
      setProcessedLatePayments(data?.processedlatePayments)

    })();
  }, [change]);

  // get list of site visit
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/leadmgmt/siteVisit/getListOfSiteVisit?leadId=${""}`, { headers: { Authorization: `bearer ${userInfo.token}` } });

      const data = res.data;
      // setSv(data);
    })();
  }, [change]);


  // get list of user
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/user/getListOfUsers?isActive=true`, { headers: { Authorization: `bearer ${userInfo.token}` } });
      const data = res.data.userList;
      // setUserList(data);
    })();
  }, []);


  ///get all demands
  useEffect(() => {
    axios.get(`${BASE_URL}/api/v1/CustomerMgmt/Demand/getlistofdemands?customerId=${id}`,
      {
        headers:
          { Authorization: `bearer ${userInfo.token}` }
      })
      .then((response) => {
        setDemands(response.data.demandList);
      });
  }, []);



  //get all money receipts

  useEffect(() => {
    axios.get(`${BASE_URL}/api/v1/FinanceMgmt/MoneyReceipt/getListOfMoneyReceipt?customerId=${id}`,
      { headers: { Authorization: `bearer ${userInfo.token}` } })
      .then((response) => {
        setMoneyReceipts(response?.data?.moneyReceiptList);
        //console.log(response?.data?.moneyReceiptList);
      });
  }, []);

  // console.log(invCustomer)

  return (
    <div className="invCustomer" style={{ height: "auto" }}>

      <Button navigateTo={'/viewcustomers'} />

      <br />
      <br />

      <div className="tabs">
        <Nav>

          <Nav.Item
            onClick={() => {
              localStorage.setItem("ActiveKeyCustomer", "first");
              setTab('first')
            }}
          >
            <Nav.Link className={tab === 'first' ? 'active' : 'inactive'} eventKey="first">
              Customer Details
            </Nav.Link>
          </Nav.Item>

          <Nav.Item
            onClick={() => {
              localStorage.setItem("ActiveKeyCustomer", "second");
              setTab('second')
            }}
          >
            <Nav.Link className={tab === 'second' ? 'active' : 'inactive'} eventKey="second">
              Allotment
            </Nav.Link>
          </Nav.Item>

          <Nav.Item
            onClick={() => {
              localStorage.setItem("ActiveKeyCustomer", "third");
              setTab('third')
            }}
          >
            <Nav.Link className={tab === 'third' ? 'active' : 'inactive'} eventKey="third">
              Demands
            </Nav.Link>
          </Nav.Item>

          <Nav.Item
            onClick={() => {
              localStorage.setItem("ActiveKeyCustomer", "fourth");
              setTab('fourth')
            }}
          >
            <Nav.Link className={tab === 'fourth' ? 'active' : 'inactive'} eventKey="fourth">
              Finance
            </Nav.Link>
          </Nav.Item>

          <Nav.Item
            onClick={() => {
              localStorage.setItem("ActiveKeyCustomer", "fifth");
              setTab('fifth')
            }}
          >
            <Nav.Link className={tab === 'fifth' ? 'active' : 'inactive'} eventKey="fifth">
              Money Receipts
            </Nav.Link>
          </Nav.Item>

          <Nav.Item
            onClick={() => {
              localStorage.setItem("ActiveKeyCustomer", "sixth");
              setTab('sixth')
            }}
          >
            <Nav.Link className={tab === 'sixth' ? 'active' : 'inactive'} eventKey="sixth"
              onClick={() => {
                localStorage.setItem("ActiveKeyCustomer", "sixth");
                setTab('sixth')
              }}
            >
              Late Payments
            </Nav.Link>
          </Nav.Item>

          <Nav.Item
            onClick={() => {
              localStorage.setItem("ActiveKeyCustomer", "seventh");
              setTab('seventh')
            }}
          >
            <Nav.Link className={tab === 'seventh' ? 'active' : 'inactive'} eventKey="seventh"
              onClick={() => {
                localStorage.setItem("ActiveKeyCustomer", "seventh");
                setTab('seventh')
              }}
            >
              Co-Applicants
            </Nav.Link>
          </Nav.Item>

          <Nav.Item
            onClick={() => {
              localStorage.setItem("ActiveKeyCustomer", "eighth");
              setTab('eighth')
            }}
          >
            <Nav.Link className={tab === 'eighth' ? 'active' : 'inactive'} eventKey="eighth"
              onClick={() => {
                localStorage.setItem("ActiveKeyCustomer", "eighth");
                setTab('eighth')
              }}
            >
              Car Parkings
            </Nav.Link>
          </Nav.Item>

        </Nav>
      </div>

      <br />
      <br />

      {tab === "first" && <CustomerDetails invCustomer={invCustomer} change={change} setChange={setChange} />}
      {tab === "second" && <CustomerAllotment invCustomer={invCustomer} />}
      {tab === "third" && <DemandList demands={demands} tab={tab} title="Demands" />}
      {tab === "fourth" && <CustomerFinance setChange={setChange} change={change} invCustomer={invCustomer} />}
      {tab === "fifth" && <CustomerReceipts setChange={setChange} change={change} moneyReceipts={moneyReceipts} />}
      {tab === "sixth" && <LatePayments latePayments={latePayments} processedlatePayments={processesLatePayments} />}
      {tab === "seventh" && <CoApplicants invCustomer={invCustomer} />}
      {tab === 'eighth' && <CarParkings invCustomer={invCustomer} setInvCustomer={setInvCustomer}/>}

    </div>
  );
};

export default ViewIndividualCustomer;
