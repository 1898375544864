import React, { useEffect } from "react";
import HomepageComponent from "./HomepageComponent";
import { Navigate } from "react-router-dom"

const Homepage = () => {
  if (localStorage.getItem('token') === undefined) {
    return <Navigate to="/login" noThrow />
  }
  else {
    return <HomepageComponent />;
  }
}

export default Homepage;