import { useNavigate, useParams } from 'react-router-dom';
import { IoMdArrowBack } from 'react-icons/io';
import UnitTab from '../../components/UnitTab/UnitTab'
import EditUnitPricing from '../EditUnitPricing/EditUnitPricing';
import './EditUnit.scss';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import Swal from 'sweetalert2';
import UnitPhoto from '../UnitPhoto/UnitPhoto';
import UnitInspection from '../UnitInspection/UnitInspection';
import UnitHandover from '../UnitHandover/UnitHandover';

const EditUnit = () => {

  const [unitTab, setUnitTab] = useState(localStorage.getItem("ActiveKeyUnit"))

  const navigate = useNavigate();
  const unitName = useParams().unitname;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [uf, setUf] = useState('');
  const [bhk, setBhk] = useState('')
  const [ca, setCa] = useState('')
  const [ba, setBa] = useState('')
  const [bua, setBua] = useState('')
  const [pt, setPt] = useState('')
  const [sbua, setSbua] = useState('')
  const [unitInfo, setUnitInfo] = useState('');

  useEffect(() => {
    const Token = 'bearer' + " " + userInfo.token;

    axios.get(`${BASE_URL}/api/v1/Configuration/Unit/getunitbyunitname/${unitName}`,
      { headers: { Authorization: Token } })
      .then((response) => {

        const u = response.data.unit;
       
        const { unitName,
          status,
          siteCode,
          siteName,
          phaseCode,
          phaseName,
          unitTypeName,
          baseSqFeetRate,
          unitOnHoldApproval,
          holdTillDate,
          unitOnHoldBy } = u

        setUf(u.unitFloor)
        setBhk(u.bhk)
        setCa(u.carpetArea)
        setBa(u.balconyArea)
        setBua(u.builtUpArea)
        setSbua(u.superBuiltUpArea)
        setPt(u.privateTerraceArea)
        setUnitInfo({
          unitName: unitName,
          status: status,
          siteCode: siteCode,
          siteName: siteName,
          phaseCode: phaseCode,
          phaseName: phaseName,
          unitTypeName: unitTypeName,
          baseSqFeetRate: baseSqFeetRate,
          unitOnHoldApproval: unitOnHoldApproval,
          holdTillDate: holdTillDate,
          unitOnHoldBy: unitOnHoldBy
        })

      })
  }, [])


  const handleEditUnit = (e) => {
    e.preventDefault();
    const Token = 'bearer' + " " + userInfo.token;

    axios.put(`${BASE_URL}/api/v1/Configuration/Unit/updateUnitByUnitName`,
      {
        unitName: unitInfo.unitName,
        update: {
          bhk: bhk,
          carpetArea: ca,
          balconyArea: ba,
          builtUpArea: bua,
          superBuiltUpArea: sbua,
          privateTerraceArea: pt
        }

      },
      { headers: { Authorization: Token } })
      .then((response) => {
        //console.log(response)
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            }
          }).then(function () {
            window.location.reload()
          })
        }
        else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.error
          })
        }

      })

  }

  return (
    <div className='editunit'>
      <div className="editunit__btn">
        <button
          onClick={() => {
            navigate(-1)
            setUnitTab('first')
          }}
        >
          <IoMdArrowBack />
          Back
        </button>
      </div>

      <UnitTab tabName={setUnitTab} />

      {unitTab === 'first' && <form className='editunit__form' method='POST' onSubmit={(e) => handleEditUnit(e)}>

        <div className="row container-fluid justify-content-center">
          <div className='col-4'>
            <label>Unit Name</label>
            <input type='text' value={unitInfo.unitName} className='form-control' readOnly />
          </div>

          <div className='col-4'>
            <label>Status</label>
            <input type='text' value={unitInfo.status} className='form-control' readOnly />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className='col-4'>
            <label>Site Code</label>
            <input type='text' value={unitInfo.siteCode} className='form-control' readOnly />
          </div>

          <div className='col-4'>
            <label>Site Name</label>
            <input type='text' value={unitInfo.siteName} className='form-control' readOnly />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className='col-4'>
            <label>Phase Code</label>
            <input type='text' value={unitInfo.phaseCode} className='form-control' readOnly />
          </div>

          <div className='col-4'>
            <label>Phase Name</label>
            <input type='text' value={unitInfo.phaseName} className='form-control' readOnly />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className='col-4'>
            <label>Unit Type Name</label>
            <input type='text' value={unitInfo.unitTypeName}
              className='form-control' readOnly />
          </div>
          <div className='col-4'>
            <label>Unit On Hold By</label>
            <input type='text' value={unitInfo.unitOnHoldByFullName ? unitInfo.unitOnHoldByFullName : 'N/A'}
              className='form-control' readOnly />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className='col-4'>
            <label>Unit Floor</label>
            <input type='text' value={uf} className='form-control' onChange={(e) => setUf(e.target.value)} required readOnly />
          </div>

          <div className='col-4'>
            <label>BHK</label>
            <input type='text' value={bhk} className='form-control'
              onChange={(e) => setBhk(e.target.value)} />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className='col-4'>
            <label>Carpet Area</label>
            <input type='number' value={ca} className='form-control'
              onChange={(e) => setCa(parseInt(e.target.value))} />
          </div>

          <div className='col-4'>
            <label>Balcony Area</label>
            <input type='number' value={ba} className='form-control'
              onChange={(e) => setBa(parseInt(e.target.value))} />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className='col-4'>
            <label>Built up Area</label>
            <input type='number' value={bua} className='form-control'
              onChange={(e) => setBua(parseInt(e.target.value))} />
          </div>

          <div className='col-4'>
            <label>Super Built Up Area</label>
            <input type='number' value={sbua} className='form-control'
              onChange={(e) => setSbua(parseInt(e.target.value))} />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className='col-4'>
            <label>Private Terrace Area</label>
            <input type='number' value={pt} className='form-control'
              onChange={(e) => setPt(parseInt(e.target.value))} />
          </div>

          <div className='col-4'>
            <label>Unit onHold Approval</label>
            <input type='text' value={unitInfo.unitOnHoldApproval} className='form-control' readOnly />
          </div>
        </div>

        <br />

        {unitInfo.unitOnHoldApproval &&

          <div className="row container-fluid justify-content-center">
            <div className='col-4'>
              <label>Hold Till Date</label>
              <input type='text' value={unitInfo.holdTillDate} className='form-control' readOnly />
            </div>

            <div className='col-4'>
              <label>Unit OnHold By</label>
              <input type='text' value={unitInfo.unitOnHoldBy} className='form-control' readOnly />
            </div>
          </div>}

        <div className="editunit__form__btn">
          <button >Save</button>
        </div>

      </form >}

      {unitTab === 'second' && <EditUnitPricing/>}

      {unitTab === 'third' && <UnitPhoto />}

      {unitTab === 'fourth' && <UnitInspection />}

      {unitTab === 'fifth' && <UnitHandover />}

    </div >
  )
}

export default EditUnit