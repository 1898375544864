import React, { useState } from "react";
import { BASE_URL } from '../../utils/config/url'
import westroad from '../../assets/images/westroad_logo.jpg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './LoginPage.scss';

const LoginForm = () => {

  const [userName, setuserName] = useState('')
  const [otp, setOtp] = useState('')
  const [error, setError] = useState('')
  const [errorbol, setErrorbol] = useState(false)
  const [getOTP, setGetOTP] = useState(false)

  const changeUser = (e) => {
    setuserName(e.target.value)

  }

  const changeOtp = (e) => {
    setOtp(e.target.value)

  }

  const getOTPapi = () => {
    axios.post(`${BASE_URL}/api/v1/auth/sendOTP`, { userName: userName })
      .then((response) => {
        if (response.data.otpSent === true) {
          setGetOTP(true)
          setErrorbol(false)
        }
        else if (response.data.otpSent === false) {
          setError(response.data.message)
          setGetOTP(false)
          setErrorbol(true)

        }
      })
  }

  const navigate = useNavigate();

  const validateOTP = (e) => {
    e.preventDefault()
    axios.post(`${BASE_URL}/api/v1/auth/validateotp`,
      {
        userName: userName,
        OTP: otp * 1
      }
    )
      .then((response) => {

        if (response.data.otpValidated === true) {

          axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${response.data.employeeId}`, {
            headers: { Authorization: `bearer ${response.data.token}` }
          })
            .then((res) => {

              localStorage.setItem('userInfo', JSON.stringify({
                token: response.data.token,
                userFullName: response.data.userFullName,
                userName: response.data.userName,
                userRole: response.data.userRole,
                userPhoto: response.data.userPhoto,
                userEmail: response.data.userEmail,
                userMobile: response.data.userMobile,
                employeeId: response.data.employeeId,
                canWFH: res.data.employee.canWFH,
                leadFullAccess: res.data.employee.leadFullAccess,
              }))

              let path = JSON.parse(sessionStorage.getItem('path'));
              let previousPath = JSON.parse(sessionStorage.getItem('previousPath'))

              if (previousPath) {
                window.location = previousPath
              } else if (path) {
                window.location = path;
              }
              else navigate('/home')

            })

        }
        else if (response.data.otpValidated === false) {

          setError(response.data.message)
          setErrorbol(true)
        }

      })

  }


  return (
    //jsx-a11y/anchor-is-valid
    <>

      <div className="loginform">
        <img src={westroad} alt="westroad" className="loginform__img" />

        <div className="loginform__div">
          <input type="text"
            className="loginform__input"
            id="username" value={userName}
            name="username"
            onChange={changeUser}
            placeholder="username"
            autoFocus
            autoComplete="off"
            style={{ display: !getOTP ? 'block' : 'none' }} />

          <input type="number"
            className="loginform__input"
            id="otp" value={otp}
            name="Enter OTP"
            onChange={changeOtp}
            placeholder="Enter OTP"
            autoFocus
            autoComplete="off"
            style={{ display: getOTP ? 'block' : 'none' }} />


          {
            errorbol === true ?
              <>
                <p style={{ color: "red", size: "15px", textAlign: 'center' }}><em>{error}</em></p>
              </> :
              null
          }


          {!getOTP && <button type="submit" href="" onClick={getOTPapi} style={{
            background: "#ee4b46",
            textAlign: "center",
            marginTop: '50px'
          }}
            className="loginform__btn">
            Get OTP
          </button>}





          {getOTP && <button type="submit" href="" onClick={validateOTP} className="loginform__btn" style={{
            background: "#ee4b46",
            textAlign: "center",
            marginTop: '50px'
          }} >
            Validate OTP
          </button>}





          {getOTP && <button type="submit" href="" onClick={getOTPapi} className="loginform__btn" style={{
            background: "#ee4b46",
            textAlign: "center",
            margin: '30px'
          }} >
            Resend OTP
          </button>}


        </div>
      </div>



    </>
  );
}


export default LoginForm;
