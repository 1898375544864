import React from 'react'
import { Bar, Pie } from 'react-chartjs-2';


const BarCharts = ({monthName , monthCount}) => {
    return (
        <Bar
            data={{

                labels: monthName,
                datasets: [{
                    label: 'Application Count',
                    data: monthCount,
                    backgroundColor: '#f78e8b',
                    borderWidth: 1
                }]

            }}

            height={100}
            width={500}
            options={{
                responsive: true,
                maintainAspectRatio: true
            }}

        />
    )
}

export default BarCharts