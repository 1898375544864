import React, { useEffect } from 'react'
import './AddCostCenter.scss';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { AiFillDelete } from 'react-icons/ai';
import Swal from 'sweetalert2';
import Button from '../../components/Button/Button';
import { Paper } from '@mui/material';

const AddCostCenter = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [employeeList, setEmployeeList] = React.useState([])
    const [bankAccounts, setBankAccounts] = React.useState([])
    const [costCenterName, setCostCenterName] = React.useState('')
    const [costCenterShortName, setCostCenterShortName] = React.useState('')
    const [finYear, setFinYear] = React.useState('')
    const [costCenterType, setCostCenterType] = React.useState('')
    const [costCenterOwner, setCostCenterOwner] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [costCenterOrganisation, setCostCenterOrganisation] = React.useState('')
    const [siteCode, setSiteCode] = React.useState('')
    const [phaseCode, setPhaseCode] = React.useState('')
    const [officeName, setOfficeName] = React.useState('')
    const [shortNameError, setShortNameError] = React.useState('')

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        })
            .then((response) => {
                if (response.data.status) {
                    setEmployeeList(response.data.employees)
                } else {
                    setEmployeeList([])
                }
            })

    }, []);

    const addRow = (e) => {

        e.preventDefault()

        let item = {
            serial: bankAccounts.length,
            accountHolderName: '',
            bankName: '',
            accountNumber: '',
            branchName: '',
            IFSCCode: ''
        }
        // console.log(item)
        setBankAccounts([...bankAccounts, item])

    }

    const deleteRow = (index) => {
        let temp = [...bankAccounts]
        // console.log(temp)
        let items = temp.filter((item, i) => (item.serial !== index))

        setBankAccounts(items)

    }

    const handleChange = (e, index, key) => {
        let temp = [...bankAccounts]
        temp[index][key] = e.target.value
        setBankAccounts(temp)
    }

    const submitCostCenter = (e) => {

        e.preventDefault()

        if (shortNameError) return;

        let body = {
            costCenterName: costCenterName,
            costCenterShortName: costCenterShortName,
            finYear: finYear,
            costCenterType: costCenterType,
            costCenterOwner: costCenterOwner.split(',')[1],
            costCenterOwnerEmployeeId: costCenterOwner.split(',')[0],
            costCenterNameDescription: description,
            costCenterOrganisation: costCenterOrganisation,
            bankAccounts: bankAccounts
        }

        if (costCenterType === 'Project') {
            body = {
                ...body,
                siteCode: siteCode
            }
        } else if (costCenterType === 'Phase') {
            body = {
                ...body,
                siteCode: siteCode,
                phaseCode: phaseCode
            }
        } else if (costCenterType === 'Office') {
            body = {
                ...body,
                officeName: officeName
            }
        }

        axios.post(`${BASE_URL}/api/v1/FinanceMgmt/costCenter/createNewCostCenter`, body, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        })
            .then((response) => {
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message
                    })
                        .then(() => {
                            window.location.href = '/finance/costCenters'
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message
                    })
                }
            })
            .catch((err) => {
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
            })
    }


    return (
        <div className='add-cost-center'>

            <div className='row justify-content-start mb-4'>
                <Button navigateTo="/finance/costCenters" />
            </div>

            <form
                onSubmit={(e) => {
                    submitCostCenter(e)
                }}
            >

                <h3 className='text-center'
                    style={{ color: '#EE4B46' }}
                >Add Cost Center</h3>

                <br /><br />

                <div className='row justify-content-center'>
                    <div className='col-4'>
                        <label>Cost Center Name</label>
                        <input type="text" required className='form-control'
                            value={costCenterName}
                            onChange={(e) => setCostCenterName(e.target.value)}
                        />
                    </div>

                    <div className='col-4'>
                        <label>Cost Center Short Name</label>
                        <input type="text" required className='form-control'
                            value={costCenterShortName}
                            onChange={(e) => {

                                // console.log((/^[A-Z]+$/).test(e.target.value))

                                if (e.target.value.length > 6 || (/^[A-Z]+$/).test(e.target.value) === false) {
                                    setShortNameError('6 Chars & Capital Letters only')
                                } else {
                                    setShortNameError('')
                                }
                                setCostCenterShortName(e.target.value)

                            }}
                        />
                        {shortNameError && <p style={{ color: '#EE4B46' }}>{shortNameError}</p>}
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-4'>
                        <Form.Group controlId="year">
                            <Form.Label>Financial Year</Form.Label>
                            <Form.Control as="select" required
                                value={finYear}
                                onChange={(e) => {
                                    setFinYear(e.target.value)
                                }}
                            >
                                <option value="">Select a Year</option>
                                <option value='2022-23'>2022-23</option>
                                <option value='2023-24'>2023-24</option>
                                <option value='2024-25'>2024-25</option>
                            </Form.Control>
                        </Form.Group>
                    </div>

                    <div className='col-4'>
                        <Form.Group controlId="type">
                            <Form.Label>Cost Center Type</Form.Label>
                            <Form.Control as="select" required
                                value={costCenterType}
                                onChange={(e) => {
                                    setCostCenterType(e.target.value)
                                }}
                            >
                                <option value="">Select a Type</option>
                                <option value='Project'>Project</option>
                                <option value='Phase'>Phase</option>
                                <option value='Office'>Office</option>
                                <option value='Corporate'>Corporate</option>
                                <option value="IT">IT</option>
                                <option value="Initiatives">Initiatives</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>

                {
                    (costCenterType === 'Project' || costCenterType === 'Phase')
                        ?
                        <>
                            <br />

                            <div className='row justify-content-center'>
                                <div className='col-4'>
                                    <label>Site Code</label>
                                    <input type="text" required className='form-control'
                                        value={siteCode}
                                        onChange={(e) => setSiteCode(e.target.value)}
                                    />
                                </div>

                                {
                                    costCenterType === 'Phase' &&
                                    <div className='col-4'>
                                        <label>Phase Code</label>
                                        <input type="text" required className='form-control'
                                            value={phaseCode}
                                            onChange={(e) => setPhaseCode(e.target.value)}
                                        />
                                    </div>
                                }

                            </div>
                        </>
                        :
                        costCenterType === 'Office'
                            ?
                            <>
                                <br />
                                <div className='row justify-content-center'>
                                    <div className='col-4'>
                                        <label>Office Name</label>
                                        <input type="text" required className='form-control'
                                            value={officeName}
                                            onChange={(e) => setOfficeName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </>
                            :
                            null
                }

                <br />

                <div className='row justify-content-center'>
                    <div className='col-4'>
                        <Form.Group controlId="owner">
                            <Form.Label>Cost Center Owner</Form.Label>
                            <Form.Control as="select" required
                                value={costCenterOwner}
                                onChange={(e) => {
                                    setCostCenterOwner(e.target.value)
                                }}
                            >
                                <option value="">Select a option</option>
                                {
                                    employeeList?.map((employee, index) => {
                                        return <option key={index} value={`${employee?.employeeId},${employee?.employeeFullName}`}>{employee?.employeeFullName}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>

                    <div className='col-4'>
                        <label>Cost Center Owner Employee Id</label>
                        < input type="text" className='form-control' readOnly value={costCenterOwner.split(',')[0]} />
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>

                    <div className='col-4'>
                        <label>Cost Center Description </label>
                        < textarea type="text" required className='form-control' value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>

                    <div className='col-4'>
                        <Form.Group controlId="org">
                            <Form.Label>Cost Center Organisation</Form.Label>
                            <Form.Control as="select" required
                                value={costCenterOrganisation}
                                onChange={(e) => {
                                    setCostCenterOrganisation(e.target.value)
                                }}
                            >
                                <option value="">Select a option</option>
                                <option value="WRL">WRL</option>
                                <option value="WRHIL">WRHIL</option>
                                <option value="WRDPL">WRDPL</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-6 text-center'>
                        <button className='add-cost-center__btn pt-2'
                            onClick={(e) => addRow(e)}
                        >Add Bank Accounts</button>
                    </div>
                </div>


                {
                    bankAccounts?.map((item, index) => {

                        return <Paper key={index} elevation={2} style={{ padding: "20px", margin: 'auto', marginTop: "40px", width: '90%' }}>
                            <div className='row justify-content-center mt-4' key={index}>
                                <div className='col-10 row d-flex flex-wrap text-center'>
                                    <div className='col-4 mt-2'>
                                        <label>Account Holder Name</label>
                                        <input type="text" className='form-control' required value={item?.accountHolderName}
                                            onChange={(e) => handleChange(e, index, 'accountHolderName')}
                                        />
                                    </div>

                                    <div className='col-4 mt-2'>
                                        <label>Bank Name</label>
                                        <input type="text" className='form-control' required value={item?.bankName}
                                            onChange={(e) => handleChange(e, index, 'bankName')}
                                        />
                                    </div>

                                    <div className='col-4 mt-2'>
                                        <label>Account Number</label>
                                        <input type="text" className='form-control' required value={item?.accountNumber}
                                            onChange={(e) => handleChange(e, index, 'accountNumber')}
                                        />
                                    </div>

                                    <div className='col-4 mt-2'>
                                        <label>Branch Name</label>
                                        <input type="text" className='form-control' required value={item?.branchName}
                                            onChange={(e) => handleChange(e, index, 'branchName')}
                                        />
                                    </div>

                                    <div className='col-4 mt-2'>
                                        <label>IFSC Code</label>
                                        <input type="text" className='form-control' required value={item?.IFSCCode}
                                            onChange={(e) => handleChange(e, index, 'IFSCCode')}
                                        />
                                    </div>
                                </div>

                                <div className='col-2 text-center mt-4'>
                                    <AiFillDelete style={{
                                        fontSize: '25px',
                                        cursor: 'pointer'
                                    }}
                                        onClick={() => deleteRow(index)}
                                    />
                                </div>
                            </div>
                        </Paper>
                    })
                }


                <br />

                <div className='row justify-content-center'>
                    <div className='col-6 text-center'>
                        <button className='add-cost-center__btn' type='submit'
                        disabled={shortNameError}
                        style={{backgroundColor : shortNameError ? 'grey' : ''}}
                        >Submit</button>
                    </div>
                </div>

            </form >

        </div >
    )
}

export default AddCostCenter
