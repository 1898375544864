import React from "react";
import "./ViewEducation.scss";
import AddNewEducationDetails from "./AddNewEducationDetails/AddNewEducationDetails";
import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import { useParams } from "react-router-dom";
import ShowEduDetails from "./AddNewEducationDetails/ShowEduDetails/ShowEduDetails";
const ViewEducation = ({ employeeId: propEmployeeId, onBoardInfo }) => {
  const [education, setEducation] = useState([]);
  const { employeeId: paramEmployeeId } = useParams();
  const [loading, setLoading] = useState(false);
  const employeeId = propEmployeeId ? propEmployeeId : paramEmployeeId;

  let userInfo;
  if (onBoardInfo) {
    userInfo = onBoardInfo;
  } else {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  }


  useEffect(() => {
    let isMounted = true;

    const getEmployeeEducationDetails = async () => {
      try {
        const res = await axios.get(
          `${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${employeeId}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );

        if (isMounted) {
          setEducation(res.data.employee.educationalDetails);
        }
      } catch (error) {
        console.error("Error fetching employee education details:", error);
        // Handle the error as needed
      }
    };

    getEmployeeEducationDetails();

    return () => {
      isMounted = false; // Set isMounted to false when the component is unmounted
    };
  }, [loading]);

  return (
    <div className="education">
      <br />
      <AddNewEducationDetails setLoading={setLoading} userInfo={userInfo} employeeId={employeeId} />
      <br />
      {education.length !== 0 ? (
        education.map((education, index) => {
          return (
            <ShowEduDetails
              key={index}
              education={education}
              employeeId={employeeId}
              setLoading={setLoading}
              userInfo={userInfo}
            />
          );
        })
      ) : (
        <p>No education details found</p>
      )}
    </div>
  );
};

export default ViewEducation;
