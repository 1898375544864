import React, { useEffect, useState } from 'react'
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useStyles } from '../../utils/ModalStyles'
import Form from 'react-bootstrap/Form'
import './AddBookingPayment.scss';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Paper from '@mui/material/Paper';
import { dateFormatting } from '../../utils/dateFormatting';

const AddBookingPayment = () => {

    const classes = useStyles()

    const [bpm, setBpm] = useState(false);
    const [bookingPaymentMode, setBookingPaymentMode] = useState("");
    let [arr, setArr] = useState([]);
    const [neft, setNeft] = useState(false);
    const [brn, setBrn] = useState("");
    const [td, setTd] = useState('')
    const [ta, setTa] = useState()
    const [tb, setTb] = useState('')
    const [tc, setTc] = useState('')
    const [tam, setTam] = useState('')
    const [cad, setCad] = useState(false)
    const [cs, setCs] = useState(false)
    const [cashAckDoc, setCashAckDoc] = useState(false);
    const [funded, setFunded] = useState(false)
    // const [pv, setPv] = useState(false)
    const [baa, setBaa] = useState(false)
    const [open, setOpen] = useState(false)
    const [cq, setCq] = useState(false)
    const [spinner, setSpinner] = useState("none");

    const [paymentMode, setPaymentMode] = useState('null')
    const [fundedBy, setFundedBy] = useState('')
    const [pv, setPv] = useState(false)

    const [issuedBy, setIssuedBy] = useState('')
    const [issuedTo, setIssuedTo] = useState('')
    const [status, setStatus] = useState('')
    const [customerId, setCustomerId] = useState('')
    const [chequeNo, setChequeNo] = useState('')
    const [bankName, setBankName] = useState('')
    const [accountNo, setAccountNo] = useState('')
    const [date, setDate] = useState('')
    const [amount, setAmount] = useState()
    const [Micr, setMicr] = useState('')
    const [chequeAcknowledgementLink, setChequeAcknowledgementLink] = useState('')
    const [amountError, setAmountError] = useState('')
    const [accountError, setAccountError] = useState('')

    const [fundedByPerson, setFundedByPerson] = useState('')
    const [Pan, setPan] = useState('')
    const [Relationship, setRelationship] = useState('')

    const [chequeError, setChequeError] = useState('')
    const [MicrError, setMicrError] = useState('')
    const [panError, setPanError] = useState('')
    const [selected, setSelected] = useState(false);

    const [receivedBy, setReceivedBy] = useState('')
    const [receivedDate, setReceivedDate] = useState('')

    // /////////bank Transfer variables //////////////
    const [transactionId, setTransactionId] = useState('');
    const [bookingAmount, setBookingAmount] = useState();
    const [transferMethod, setTransferMethod] = useState('');
    const [transferBankName, setTransferBankName] = useState('');
    const [transferBankAccount, setTransferBankAccount] = useState('');
    const [upiId, setUpiId] = useState('');
    const [transferDate, setTransferDate] = useState('');
    const [transferComments, setTransferComments] = useState('');
    const [transferredBy, setTransferredBy] = useState('');
    const [depositBankName, setDepositBankName] = useState('');
    const [depositBankAccount, setDepositBankAccount] = useState('');
    const [bookingApplication, setBookingApplication] = useState({});
    const [flag, setFlag] = useState();
    const [pMode, setPMode] = useState('');

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const appId = useParams().applicationId;
    var today = new Date();

    var tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() - 30);
    var dd = tomorrow.getDate();
    var mm = tomorrow.getMonth() + 1; //January is 0!
    var yyyy = tomorrow.getFullYear();
    if (dd < 10) {
        dd = "0" + dd;
    }
    if (mm < 10) {
        mm = "0" + mm;
    }

    tomorrow = yyyy + "-" + mm + "-" + dd;

    var maxdate = new Date(today);
    maxdate.setDate(maxdate.getDate() + 7);
    var dd2 = maxdate.getDate();
    var mm2 = maxdate.getMonth() + 1;
    var yyyy2 = maxdate.getFullYear();
    if (dd2 < 10) {
        dd2 = "0" + dd2;
    }
    if (mm2 < 10) {
        mm2 = "0" + mm2;
    }

    maxdate = yyyy2 + "-" + mm2 + "-" + dd2;

    useEffect(() => {
        bookingApplicationData();
    }, []);

    const bookingApplicationData = () => {
        axios
            .get(`${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/getBookingApplicationByApplicationId/${appId}`, {
                headers: { Authorization: `bearer ${userInfo.token}` },
            })
            .then((response) => {
                // console.log(response)
                setBookingApplication(response.data.BookingApplication)

                setCustomerId(response?.data?.BookingApplication?.customerId)
                setStatus(response?.data?.BookingApplication?.status)
                setPMode(response?.data?.BookingApplication?.bookingPaymentMode)
                

                if (response.data.BookingApplication.bookingPaymentMode !== "Not Added") {
                    setSelected(true);
                    setPaymentMode(response.data.BookingApplication.bookingPaymentMode);
                    let mode = response.data.BookingApplication.bookingPaymentMode;
                    if (mode === "Cheque" || mode === "DemandDraft") {
                        getChequeDetails(response.data.BookingApplication);
                    } else if (mode === "Cash") {
                        getCashDetails(response.data.BookingApplication);
                    }
                    else if (mode === 'BankTransfer') {
                        getBankTransferDetails(response.data.BookingApplication)
                    }
                }
            });
    };

    const getChequeDetails = (data) => {
        setChequeNo(data.chequeDetails?.chequeNo);
        setMicr(data.chequeDetails?.MICRNo);
        setBankName(data.chequeDetails?.chequeBankName);
        setAccountNo(data.chequeDetails?.chequeAccountNo);
        setAmount(data.chequeDetails?.bookingAmount);
        setIssuedBy(data.chequeDetails?.issuedBy);
        setIssuedTo(data.chequeDetails?.issuedTo)
        setFundedBy(data?.notFundedSelf ? "other" : "self");
        setFundedByPerson(data?.fundedBy);
        setPan(data?.fundedByPAN);
        setRelationship(data?.fundedByRelation);

        data.chequeAcknowledgementDoc.chequeAcknowledgementLink ? setChequeAcknowledgementLink(data.chequeAcknowledgementDoc.chequeAcknowledgementLink) : setChequeAcknowledgementLink("");

        let Chkdate = dateFormatting(data.chequeDetails?.chequeDate)
        setDate(Chkdate);
    };

    const getCashDetails = (data) => {
        //  console.log(data)
        setPaymentMode(data?.bookingPaymentMode);
        setReceivedBy(data?.CashDetails.receivedBy);
        setAmount(data?.bookingAmount);
        setFundedBy(data?.notFundedSelf ? "other" : "self");
        setFundedByPerson(data?.fundedBy);
        setPan(data?.fundedByPAN);
        setRelationship(data?.fundedByRelation);
        let res = dateFormatting(data?.CashDetails.receivedDate)
        setReceivedDate(res)
    };

    const getBankTransferDetails = (data) => {

        setPaymentMode(data?.bookingPaymentMode);
        setTransactionId(data.BankTransferDetails.transactionId);
        setBookingAmount(data.BankTransferDetails.bookingAmount);
        setTransferMethod(data.BankTransferDetails.transferMethod);
        setTransferBankName(data.BankTransferDetails.transferBankName);
        setTransferBankAccount(data.BankTransferDetails.transferBankAccount);
        setTransferDate(dateFormatting(data.BankTransferDetails.transDate));
        setTransferComments(data.BankTransferDetails.transferComments);
        setTransferredBy(data.BankTransferDetails.transferredBy);
        setDepositBankName(data.BankTransferDetails.depositBankName);
        setDepositBankAccount(data.BankTransferDetails.depositBankAccount);
        setFundedBy(data?.notFundedSelf ? "other" : "self");
        setFundedByPerson(data?.fundedBy);
        setPan(data?.fundedByPAN);
        setRelationship(data?.fundedByRelation);
        data.BankTransferDetails.upiId && setUpiId(data.BankTransferDetails.upiId)
    }

    const addBookingPayment = (e) => {
        e.preventDefault();

        if (chequeError || MicrError || panError || accountError || amountError) {
            return Swal.fire({
                icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
                text: 'Please fill the details properly',
            });
        }

        let body = {};
        let arr = date.split("-");
        let finalDate = arr.reverse().join("/");

        if (paymentMode === "Cheque" || paymentMode === "DemandDraft") {
            setFlag(true);
            if (fundedBy === "other") {
                body = {
                    applicationId: appId,
                    bookingPaymentMode: paymentMode,
                    notFundedSelf: true,
                    fundedBy: fundedByPerson,
                    fundedByPAN: Pan,
                    fundedByRelation: Relationship,
                    bookingAmount: parseInt(amount),
                    chequeNo: chequeNo,
                    MICRNo: Micr,
                    collectedBy: userInfo.userFullName,
                    chequeBankName: bankName,
                    chequeAccountNo: accountNo,
                    chequeDate: date,
                    issuedBy: issuedBy,
                    issuedTo: issuedTo,
                    userName: userInfo?.userName,
                    userFullName: userInfo?.userFullName,
                };
            } else {
                body = {
                    applicationId: appId,
                    bookingPaymentMode: paymentMode,
                    notFundedSelf: false,
                    // fundedBy: fundedByPerson,
                    // fundedByPAN: Pan,
                    // fundedByRelation: Relationship,
                    bookingAmount: parseInt(amount),
                    chequeNo: chequeNo,
                    MICRNo: Micr,
                    collectedBy: userInfo.userFullName,
                    chequeBankName: bankName,
                    chequeAccountNo: accountNo,
                    chequeDate: date,
                    issuedBy: issuedBy,
                    issuedTo: issuedTo,
                    userName: userInfo?.userName,
                    userFullName: userInfo?.userFullName,
                };
            }
        } else if (paymentMode === "Cash") {
            return addBookingPaymentForCash(date, body);

        }
        else if (paymentMode === 'BankTransfer') {
            return addBookingPaymentForBankTransfer(body)
        }

        //  console.log(body)

        axios
            .post(`${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/addBookingPayment`,
                body,
                {
                    headers: { Authorization: `bearer ${userInfo.token}` }
                })
            .then((response) => {
                //  console.log(response)
                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                    }).then(function () {
                        // navigate(-1)
                        bookingApplicationData();
                    });
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error,
                });
            });
    };

    const addBookingPaymentForCash = (date, body) => {
        if (fundedBy === "other") {
            body = {
                applicationId: appId,
                bookingPaymentMode: paymentMode,
                //receivedDate: date,
                recievedByUserName: userInfo.userName,
                recievedByUserFullName: userInfo.userFullName,
                bookingAmount: parseInt(amount),
                //issuedBy: issuedBy,
                notFundedSelf: true,
                fundedBy: fundedByPerson,
                fundedByPAN: Pan,
                fundedByRelation: Relationship,
            };
        } else {
            body = {
                applicationId: appId,
                bookingPaymentMode: paymentMode,
                // receivedDate: date,
                recievedByUserName: userInfo.userName,
                recievedByUserFullName: userInfo.userFullName,
                bookingAmount: parseInt(amount),
                // issuedBy: issuedBy,
                notFundedSelf: false,
            };
        }

        axios
            .post(`${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/addBookingPayment`,
                body,
                { headers: { Authorization: `bearer ${userInfo.token}` } })
            .then((response) => {
                // console.log(response)
                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                    }).then(function () {
                        // navigate(-1)
                        bookingApplicationData();
                    });
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error,
                });
            });
    };

    const addBookingPaymentForBankTransfer = (body) => {

        body = {
            applicationId: appId,
            bookingPaymentMode: paymentMode,
            bookingAmount: parseInt(bookingAmount),
            transferBankName,
            transferBankAccount,
            transferComments,
            transferMethod: transferMethod,
            transferredBy,
            transferDate: transferDate,
            transactionId,
            depositBankName,
            depositBankAccount,
            financeUserName: userInfo.userName,
            finnaceUserFullName: userInfo.userFullName,
            notFundedSelf: false
        }

        if (upiId) {
            body = { ...body, upiId }
        }
        if (fundedBy === "other") {
            body = {
                ...body,
                notFundedSelf: true,
                fundedBy: fundedByPerson,
                fundedByPAN: Pan,
                fundedByRelation: Relationship,
            };
        }

        // console.log(body)

        axios
            .post(`${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/addBookingPayment`,
                body,
                { headers: { Authorization: `bearer ${userInfo.token}` } })
            .then((response) => {
                // console.log(response)
                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                    }).then(function () {
                        // navigate(-1)
                        bookingApplicationData();
                    });
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error,
                });
            });
    }

    const chequeAcknowledgement = (e) => {
        e.preventDefault();

        axios
            .post(
                `${BASE_URL}/api/v1/FinanceMgmt/Cheque/generateChequeAcknowledgement`,
                {
                    chequeType: "BookingPayment",
                    applicationId: appId,
                    chequeNo: chequeNo,
                    customerId: customerId,
                    MICRNo: Micr,
                    generatedByUserFullName: userInfo.userFullName,
                    generatedByUserName: userInfo.userName,
                },
                {
                    headers: { Authorization: `bearer ${userInfo.token}` },
                }
            )
            .then((response) => {
                if (response?.data?.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                    }).then(function () {
                        bookingApplicationData();
                    });
                }
            })
            .catch((err) => {

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error,
                });

            })
    };


    return (
        <div className='add_booking_payment'>
            <br />
            <br />


            <>
                {selected && <h5
                    style={{ textAlign: 'center' }}
                >Booking Payment Mode - <b>{paymentMode}</b></h5>}

                <br />

                {selected === false &&
                    <div className="row justify-content-center">
                        <div className="col-4">
                            <Form.Group controlId="payment mode">
                                <Form.Label>Booking Payment Mode</Form.Label>
                                <Form.Control as="select"
                                    value={paymentMode}
                                    onChange={(e) => setPaymentMode(e.target.value)}
                                >
                                    <option value='null'>Select a Payment Mode</option>
                                    <option value='Cheque'>Cheque</option>
                                    <option value='DemandDraft'>Demand Draft</option>
                                    <option value='BankTransfer'>Bank Transfer</option>
                                    <option value='Cash'>Cash</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                    </div>}

                <Paper elevation={2} style={{ padding: '20px', marginTop: '40px' }}>
                    <form
                        onSubmit={(e) => addBookingPayment(e)}
                        style={{
                            display:
                                paymentMode === "null" ? "none" : "block",
                        }}
                    >


                        {(paymentMode === 'Cheque' || paymentMode === 'DemandDraft') && (
                            <>
                                <br />
                                <div className="row justify-content-center">
                                    <div className="col-3">
                                        <label>Cheque No. :</label>
                                        <input type="text" className="form-control"
                                            required
                                            readOnly={selected ? true : false}
                                            value={chequeNo}
                                            onChange={(e) => {
                                                if (e.target.value.length !== 6) {
                                                    setChequeError('Cheque No. must be of 6 digits')
                                                } else {
                                                    setChequeError('')
                                                }
                                                setChequeNo(e.target.value)
                                            }}
                                        />
                                        <p><em style={{ color: '#EE4B46' }}>{chequeError}</em></p>
                                    </div>

                                    <div className="col-3">
                                        <label> MICR :</label>
                                        <input type="text" className="form-control"
                                            required
                                            readOnly={selected ? true : false}
                                            value={Micr}
                                            onChange={(e) => {
                                                if (e.target.value.length !== 9) {
                                                    setMicrError('MICR no. must be of 9 digits')
                                                } else {
                                                    setMicrError('')
                                                }
                                                setMicr(e.target.value)
                                            }}
                                        />
                                        <p><em style={{ color: '#EE4B46' }}>{MicrError}</em></p>
                                    </div>


                                    <div className="col-3">
                                        <label> Bank Name :</label>
                                        <input type="text" className="form-control"
                                            required
                                            readOnly={selected ? true : false}
                                            value={bankName}
                                            onChange={(e) => setBankName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <br />

                                <div className="row justify-content-center">
                                    <div className="col-4">
                                        <label> Cheque Account No. :</label>
                                        <input type="text" className="form-control"
                                            readOnly={selected ? true : false}
                                            required
                                            value={accountNo}
                                            onChange={(e) => setAccountNo(e.target.value)}
                                        />
                                    </div>
                                    {selected === true
                                        ?
                                        <div>
                                            <label>Cheque Date :</label>
                                            <input
                                                type={selected ? 'text' : 'date'}
                                                readOnly={selected ? true : false}
                                                required
                                                value={date}
                                                className="form-control" />
                                        </div>
                                        :
                                        <div className="col-4">
                                            <label>Cheque Date :</label>
                                            <input
                                                type="date"
                                                min={tomorrow}
                                                max={maxdate}
                                                readOnly={selected ? true : false}
                                                required
                                                value={date}
                                                onChange={(e) => setDate(e.target.value)}
                                                className="form-control" />
                                        </div>
                                    }
                                </div>
                                <br />
                                <div className="row justify-content-center">

                                    {
                                        selected === true
                                            ?
                                            <div className="col-4">
                                                <label>Isued To :</label>
                                                <input type="text" className="form-control"
                                                    readOnly={selected ? true : false}
                                                    required
                                                    value={issuedTo}
                                                />
                                            </div>

                                            :

                                            <div className='col-4'>
                                                <Form.Group controlId="payment-sub-category">
                                                    <Form.Label>Issued To</Form.Label>
                                                    <Form.Control as="select" required
                                                        value={issuedTo}
                                                        onChange={(e) => {
                                                            setIssuedTo(e.target.value)
                                                        }}
                                                    >
                                                        <option value="">Select a Name</option>
                                                        <option value="WESTROAD DEVELOPERS PVT LTD A/C">WESTROAD DEVELOPERS PVT LTD A/C</option>
                                                        <option value="WESTROAD HEIGHTS COLLECTION A/C">WESTROAD HEIGHTS COLLECTION A/C</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </div>

                                    }

                                    <div className="col-4">
                                        <label>Issued By :</label>
                                        <input type="text" className="form-control"
                                            readOnly={selected ? true : false}
                                            required
                                            value={issuedBy}
                                            onChange={(e) => setIssuedBy(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <br />

                                <div className="row justify-content-center">
                                    <div className="col-4">
                                        <label>Booking Amount :</label>
                                        <input type="number" className="form-control"
                                            readOnly={selected ? true : false}
                                            required
                                            value={amount}
                                            onChange={(e) => {
                                                if (e.target.value < 10000) {
                                                    setAmountError(`Booking amount allowed :
                                                         Minimum - 10000`)
                                                } else {
                                                    setAmountError('')
                                                }
                                                setAmount(e.target.value)
                                            }}
                                        />
                                        {amountError && <p style={{ color: '#EE4B46', marginTop: '5px' }}>{
                                            amountError
                                        }</p>}
                                    </div>
                                </div>

                                {/* /////////////// Generate Cheque Receipt Button / link //////////////////// */}
                                {/* {cad === false ? (
                            <>
                                <br />
                                <div className="row justify-content-center">
                                    <div className="col-4">
                                        <button
                                            className="btn btn-secondary btn-user"
                                        // onClick={generateChequeReceipt}
                                        >
                                            Generate Cheque Receipt
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <br />
                                <div className="row justify-content-center">
                                    <div className="col-4">
                                        <h6>
                                            <a
                                                // href={cadLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                View Cheque Receipt Acknowledgement
                                            </a>
                                        </h6>
                                    </div>
                                </div>
                            </>
                        )} */}
                            </>
                        )}


                        {paymentMode === 'Cash' && (
                            <>
                                <br />
                                <div className="row justify-content-center">

                                    {selected &&
                                        <>
                                            <div className="col-3">
                                                <label>Received By :</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    readOnly={selected ? true : false}
                                                    value={receivedBy}
                                                    onChange={(e) => setReceivedBy(e.target.value)}
                                                />
                                            </div>

                                            <div className='col-3'>
                                                <label>Received Date :</label>
                                                <input
                                                    className="form-control"
                                                    readOnly={selected ? true : false}
                                                    value={receivedDate}
                                                />
                                            </div>
                                        </>
                                    }

                                    <div className="col-3">
                                        <label>Booking Amount :</label>
                                        <input type="number" className="form-control"
                                            readOnly={selected ? true : false}
                                            value={amount}
                                            onChange={(e) => {
                                                if (e.target.value < 10000) {
                                                    setAmountError(`Booking amount allowed :
                                                         Minimum - 10000`)
                                                } else {
                                                    setAmountError('')
                                                }
                                                setAmount(e.target.value)
                                            }}
                                        />
                                        {amountError && <p style={{ color: '#EE4B46', marginTop: '5px' }}><em>{amountError}</em></p>}
                                    </div>

                                </div>

                                <br />
                            </>
                        )}

                        {paymentMode === 'BankTransfer'
                            &&
                            <>
                                <br />
                                <div className='row  justify-content-center'>
                                    <div className='col-3'>
                                        <label>Transaction Id</label>
                                        <input
                                            type='text'
                                            readOnly={selected ? true : false}
                                            required
                                            className='form-control'
                                            value={transactionId}
                                            onChange={(e) => setTransactionId(e.target.value)}
                                        />
                                    </div>

                                    <div className='col-3'>
                                        <label>Booking Amount</label>
                                        <input
                                            type='number'
                                            readOnly={selected ? true : false}
                                            required
                                            className='form-control'
                                            value={bookingAmount}
                                            onChange={(e) => setBookingAmount(e.target.value)}
                                        />
                                    </div>

                                    <div className='col-3'>
                                        {selected
                                            ?
                                            <>
                                                <label>Transfer Method</label>
                                                <input
                                                    type='text'
                                                    readOnly
                                                    required
                                                    className='form-control'
                                                    value={transferMethod}
                                                />
                                            </>
                                            :
                                            <Form.Group controlId="transfer-method">
                                                <Form.Label>Transfer Method</Form.Label>
                                                <Form.Control as="select" required
                                                    className='form-control'

                                                    value={transferMethod}
                                                    onChange={(e) => {
                                                        setTransferMethod(e.target.value)
                                                    }}
                                                >
                                                    <option value="">Select a method</option>
                                                    <option value="UPI">UPI</option>
                                                    <option value="NEFT">NEFT</option>
                                                    <option value="RTGS">RTGS</option>
                                                    <option value="IMPS">IMPS</option>
                                                </Form.Control>
                                            </Form.Group>
                                        }
                                    </div>


                                </div>

                                <br />

                                <div className='row  justify-content-center'>
                                    <div className='col-3'>
                                        <label>Transfer Bank Name</label>
                                        <input
                                            type='text'
                                            readOnly={selected ? true : false}
                                            required
                                            className='form-control'
                                            value={transferBankName}
                                            onChange={(e) => setTransferBankName(e.target.value)}
                                        />
                                    </div>

                                    <div className='col-3'>
                                        <label>Transfer Bank Account</label>
                                        <input
                                            type='text'
                                            readOnly={selected ? true : false}
                                            required
                                            className='form-control'
                                            value={transferBankAccount}
                                            onChange={(e) => setTransferBankAccount(e.target.value)}
                                        />
                                    </div>

                                    {transferMethod === 'UPI'
                                        &&
                                        <div className='col-3'>
                                            <label>UPI Id</label>
                                            <input
                                                type='text'
                                                readOnly={selected ? true : false}
                                                required
                                                className='form-control'
                                                value={upiId}
                                                onChange={(e) => setUpiId(e.target.value)}
                                            />
                                        </div>}

                                </div>

                                <br />

                                <div className='row  justify-content-center'>
                                    <div className='col-3'>
                                        <label>Transfer Date</label>
                                        <input
                                            type={selected ? 'text' : 'date'}
                                            readOnly={selected ? true : false}
                                            required
                                            className='form-control'
                                            value={transferDate}
                                            onChange={(e) => setTransferDate(e.target.value)}
                                        />
                                    </div>

                                    <div className='col-3'>
                                        <label>Transfer Comments</label>
                                        <input
                                            type='text'
                                            readOnly={selected ? true : false}
                                            required
                                            className='form-control'
                                            value={transferComments}
                                            onChange={(e) => setTransferComments(e.target.value)}
                                        />
                                    </div>

                                    <div className='col-3'>
                                        <label>Transferred By</label>
                                        <input
                                            type='text'
                                            readOnly={selected ? true : false}
                                            required
                                            className='form-control'
                                            value={transferredBy}
                                            onChange={(e) => setTransferredBy(e.target.value)}
                                        />
                                    </div>

                                </div>

                                <br />

                                <div className='row  justify-content-center'>
                                    <div className='col-4'>
                                        {selected
                                            ?
                                            <>
                                                <label>Deposit Bank Name</label>
                                                <input
                                                    type='text'
                                                    readOnly
                                                    required
                                                    className='form-control'
                                                    value={depositBankName}
                                                />
                                            </>
                                            :
                                            <Form.Group controlId="deposit-bankname">
                                                <Form.Label>Deposit Bank Name</Form.Label>
                                                <Form.Control as="select" required
                                                    value={depositBankName}
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        setDepositBankName(value);
                                                        if (value === 'WESTROAD DEVELOPERS PVT LTD A/C') setDepositBankAccount('50442540521')
                                                        else if (value === 'WESTROAD HEIGHTS COLLECTION A/C') setDepositBankAccount('38861908851')
                                                    }}
                                                >
                                                    <option value="">Select a Bank</option>
                                                    <option value="WESTROAD DEVELOPERS PVT LTD A/C">WESTROAD DEVELOPERS PVT LTD A/C</option>
                                                    <option value="WESTROAD HEIGHTS COLLECTION A/C">WESTROAD HEIGHTS COLLECTION A/C</option>
                                                </Form.Control>
                                            </Form.Group>}
                                    </div>

                                    <div className='col-4'>
                                        <label>Deposit Bank Account</label>
                                        <input
                                            type='text'
                                            readOnly
                                            required
                                            className='form-control'
                                            value={depositBankAccount}
                                        />
                                    </div>

                                </div>
                            </>
                        }

                        <br />

                        <div className="row justify-content-center">
                            <div className='col-8'>
                                <label>
                                    Funded By : &nbsp; &nbsp; &nbsp;
                                    <input type='radio' name='fundedBy'
                                        checked={fundedBy === 'self'}
                                        disabled={selected ? true : false}
                                        onChange={() => setFundedBy('self')}
                                        required value={fundedBy} /> Self &nbsp; &nbsp; &nbsp;


                                    <input type='radio' name='fundedBy'
                                        onChange={() => setFundedBy('other')}
                                        checked={fundedBy === 'other'}
                                        disabled={selected ? true : false}
                                    /> Other
                                </label>
                            </div>
                        </div>

                        {fundedBy === 'other' && (
                            <>
                                <br />
                                <div className="row justify-content-center">
                                    <div className="col-4">
                                        <label>Funded By</label>
                                        <input type="text" className="form-control"
                                            readOnly={selected ? true : false}
                                            required
                                            value={fundedByPerson}
                                            onChange={(e) => setFundedByPerson(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <label>Funded By PAN</label>
                                        <input type="text" className="form-control"
                                            readOnly={selected ? true : false}
                                            required
                                            value={Pan}
                                            onChange={(e) => {
                                                if (e.target.value.length !== 10) {
                                                    setPanError('PAN no. must be 10 characters')
                                                }
                                                else if (/[a-z]/.test(e.target.value)) {
                                                    setPanError('PAN no. must have only capital letters')
                                                }
                                                else if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(e.target.value)) {
                                                    setPanError('PAN no. can have only numbers and capital letters')
                                                } else {
                                                    setPanError('')
                                                }
                                                setPan(e.target.value)
                                            }}
                                        />
                                        <p><em style={{ color: '#EE4B46' }}>{panError}</em></p>
                                    </div>
                                </div>
                                <br />
                                <div className="row justify-content-center">
                                    <div className="col-4">
                                        <label>Relationship</label>
                                        <input type="text" className="form-control"
                                            readOnly={selected ? true : false}
                                            required
                                            value={Relationship}
                                            onChange={(e) => setRelationship(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </>
                        )}

                        <br />

                        {!selected &&
                            <div className="row justify-content-center">
                                <button className='add_booking_payment__btn' type='submit'>Submit</button>
                            </div>}

                        <br />

                    </form>
                </Paper>

                {
                    (pMode === 'Cheque' || flag === true)
                    &&
                    <Paper elevation={2} style={{ padding: '20px', marginTop: '20px' }}>
                        {
                            bookingApplication?.chequeAcknowledgementDoc?.chequeAcknowledgementLink == null
                            &&
                        <div className='row d-flex flex-column justify-content-center align-items-center'>

                            <div className='col-6 text-center'>
                                <button
                                    className='add_booking_payment__btn'
                                    onClick={chequeAcknowledgement}
                                >Generate Cheque Acknowledgement Letter</button>
                            </div>

                        </div>

                        }

                        {
                            bookingApplication?.chequeAcknowledgementDoc?.chequeAcknowledgementLink != null
                            &&
                            <div className='row d-flex justify-content-around align-items-center'>
                                <h6>Document Link : <a href={bookingApplication?.chequeAcknowledgementDoc?.chequeAcknowledgementLink}>
                                    Link
                                </a></h6>
                                <h6>Generated Date : <b>{dateFormatting(bookingApplication?.chequeAcknowledgementDoc?.generatedDate)}</b></h6>
                                <h6>Generated By : <b>{bookingApplication?.chequeAcknowledgementDoc?.generatedByUserFullName}</b></h6>
                            </div>
                        }


                    </Paper>
                }


                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={() => setOpen(false)}
                    closeAfterTransition
                >
                    <Fade in={open}>
                        <div className={classes.paper}>
                            <br />
                            <div className="row">
                                <p>Are you sure you want to validate payment ?</p>
                            </div>
                            <div className="row container-fluid justify-content-center">
                                <div className="col-4 text-right">
                                    <button
                                        className='add_booking_payment__btn'
                                        style={{
                                            backgroundColor: "white",
                                            color: "black",
                                            border: '1px solid black'
                                        }}
                                        onClick={() => setOpen(false)}
                                    >
                                        No
                                    </button>
                                </div>
                                &nbsp;&nbsp;
                                <div className="col-4">
                                    <button
                                        className='add_booking_payment__btn'
                                    // onClick={validate}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                            <br />
                            <div className="row container-fluid justify-content-center">
                                <div
                                    className="spinner-border text-dark"
                                    role="status"
                                    style={{ display: spinner }}
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>

            </>



        </div>
    )
}

export default AddBookingPayment
