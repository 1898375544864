import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Button from '../../components/Button/Button';
import { BASE_URL } from '../../utils/config/url';
import './CreateUnitInspectionListType.scss';

const CreateUnitInspectionListType = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate();

    const [siteCode, setSiteCode] = useState('')
    const [phaseCode, setPhaseCode] = useState('')
    const [siteName, setSiteName] = useState('')
    const [phaseName, setPhaseName] = useState('')
    const [bhk, setBhk] = useState('')

    const [siteList, setSiteList] = useState([])
    const [phaseList, setPhaseList] = useState([])

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/Configuration/Site/getAllSites`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)
                setSiteList(response.data.siteArray)
            })
    }, [])

    useEffect(() => {

        if (!siteCode || siteCode === ' ') {
            setPhaseCode('')
            setSiteCode('')
            return
        }

        axios.get(`${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                //console.log(response)
                setPhaseList(response.data.site.phases)
            })

    }, [siteCode])


    const createUnitInspectionListType = (e) => {
        e.preventDefault();

        let body = {
            siteCode,
            siteName,
            phaseCode,
            phaseName,
            bhk
        }


        axios.post(`${BASE_URL}/api/v1/Configuration/UnitInspection/createUnitInspectionListType`,
            body
            ,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response)
                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    })
                        .then(() => {
                            navigate('/configurations/unitInspectionListType')
                        })
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }

    const reset = () => {
        setSiteCode('')
        setSiteName('')
        setPhaseCode('')
        setPhaseName('')
        setBhk('')
        setPhaseList([])
    }

    return (
        <div className='create-unit-inspection-list-type'>

            <Button navigateTo={'/configurations/unitInspectionListType'} />

            <br />
            <br />

            <h4 style={{ textAlign: 'center', color: '#EE4B46' }}>Create Unit Inspection List Type</h4>

            <br /> <br />

            <form
                onSubmit={(e) => createUnitInspectionListType(e)}
                style={{ width: window.innerWidth < 700 ? '100%' : '85%' }}
            >

                <div className='row justify-content-center'>

                    <div className='col-4'>

                        <Form.Group controlId="siteName">
                            <label>Site Name</label>
                            <Form.Control as="select"
                                required
                                onChange={(e) => {
                                    let val = e.target.value.split('-')
                                    setSiteCode(val[0])
                                    setSiteName(val[1])
                                }}
                            >
                                <option value="">Select a option</option>
                                {
                                    siteList?.map((site, index) => {
                                        return <option key={index} value={`${site?.siteCode}-${site?.siteName}`}>{site?.siteName}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>

                </div>

                <br />

                <div className='row justify-content-center'>

                    <div className='col-4'>

                        <Form.Group controlId="phaseName">
                            <label>Phase Name</label>
                            <Form.Control as="select"
                                required
                                onChange={(e) => {
                                    let val = e.target.value.split('-')
                                    setPhaseCode(val[0])
                                    setPhaseName(val[1])
                                }}
                            >
                                <option value="">Select a option</option>
                                {
                                    phaseList?.map((phase, index) => {
                                        return <option key={index} value={`${phase?.phaseCode}-${phase?.phaseName}`}>{phase?.phaseName}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>

                </div>

                <br />

                <div className='row justify-content-center'>

                    <div className='col-4'>

                        <Form.Group controlId="bhk">
                            <label>BHK</label>
                            <Form.Control as="select"
                                required
                                onChange={(e) => setBhk(e.target.value)}
                            >
                                <option value="">Select a option</option>
                                <option value="1BHK">1BHK</option>
                                <option value="2BHK">2BHK</option>
                                <option value="3BHK">3BHK</option>
                                <option value="Duplex">Duplex</option>
                            </Form.Control>
                        </Form.Group>
                    </div>

                </div>

                <br /> <br />

                <div className='row justify-content-center'>
                    <div className='col-3 d-flex justify-content-center'>
                        <button type='submit'>Submit</button>
                    </div>
                    {/* <div className='col-3 d-flex justify-content-center'>
                        <button type='reset'
                            onClick={(e) => {
                                e.preventDefault()
                                reset()
                            }}
                        >Reset</button>
                    </div> */}
                </div>
            </form>
        </div>
    )
}

export default CreateUnitInspectionListType;