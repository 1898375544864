import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";
import { useParams } from "react-router-dom";
import "./SiteConfigurations.scss";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import { Delete } from "@material-ui/icons";
import uniqid from "uniqid";
import LegalCharges from "./LegalCharges";
import OtherCharges from "./OtherCharges";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SiteConfigurations = () => {
  const classes = useStyles();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const siteCode = useParams().siteCode;
  const Token = "bearer" + " " + userInfo.token;
  const navigate = useNavigate();

  ////////////////////////// States ///////////////////////////
  const [fec, setFec] = useState("");
  const [disp, setDisp] = useState("none");
  const [buaf, setBuaf] = useState("");
  const [sbuaf, setSbuaf] = useState("");
  const [ugp, setUgp] = useState("");
  const [nof, setNof] = useState("");
  const [nef, setNef] = useState("");
  const [disp1, setDisp1] = useState("none");
  const [minBookingAmount, setMinBookingAmount] = useState();
  const [addPhase, setAddPhase] = useState([]);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [deletePhase, setDeletePhase] = useState("");
  const [pName, setPName] = useState("");
  const [pCode, setPCode] = useState("");
  const [pcError, setPcError] = useState("");
 

  ///////////////// functions ////////////////////////
  const handleDeletePhase = () => {
    const phase = deletePhase;
    const Token = "bearer" + " " + userInfo.token;
    axios
      .delete(
        `${BASE_URL}/api/v1/Configuration/Site/deletePhase?phaseCode=${phase.phaseCode}&siteCode=${siteCode}`,
        { headers: { Authorization: Token } }
      )
      .then((res) => {
        const newPhase = addPhase.filter((onephase) => {
          return onephase.id !== phase.id;
        });

        setAddPhase(newPhase);
        setModal2(false);
      });
  };

  const handleAddPhase = (e) => {
    e.preventDefault();
    if (!pCode || !pName) {
      return setModal1(false);
    }

    if (pCode.length === 1 || pCode.length > 4) {
      return setPcError(
        "Site Code must be greater than 1 but less than 5 characters"
      );
    } else if (/\s/.test(pCode)) {
      return setPcError("Site Code cannot contain any white space");
    } else if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pCode)) {
      return setPcError(`Site code can't contain any special characters`);
    } else if (/[a-z]/.test(pCode)) {
      return setPcError(
        "Site Code can contain only numbers or uppercase letters"
      );
    } else {
      setPcError("");
    }

    const Token = "bearer" + " " + userInfo.token;
    axios
      .post(
        `${BASE_URL}/api/v1/Configuration/Site/addPhase`,
        {
          siteCode: siteCode,
          addPhase: {
            phaseCode: pCode,
            phaseName: pName,
          },
        },

        { headers: { Authorization: Token } }
      )
      .then((response) => {
        // console.log(response)
        if (response.data.status === true) {
          setModal1(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setAddPhase((prev) => [
              ...prev,
              { phaseName: pName, phaseCode: pCode, id: uniqid() },
            ]);

            setPName("");
            setPCode("");
          });
        } else if (response.data.status === false) {
          setModal1(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      });
  };

  const handleClose1 = (e) => {
    e.preventDefault();
    setModal1(false);
  };

  const handleDeleteClick = (phase) => {
    setDeletePhase(phase);
    setModal2(true);
  };

  ///////////////////// data fetching //////////////////////////////
  const editSite1 = (e) => {
    const Token = "bearer" + " " + userInfo.token;
    axios
      .put(
        `${BASE_URL}/api/v1/Configuration/Site/updateSiteBySiteCode`,
        {
          siteCode: siteCode,
          siteUpdate: {
            floorEscalationCharge: fec,
            builtUpAreaFactor: buaf,
            superBuiltUpAreaFactor: sbuaf,
            unitGSTPercentage: ugp,
            noOfFloor: nof,
            noEscalationFloor: nef,
            minBookingAmount: minBookingAmount,
            phases: addPhase,
          },
        },
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          }).then(function () {});
        } else {
          Swal.fire({
            icon: "error",
            title: "Failure",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          }).then();
        }
      });
  };

  useEffect(() => {
    const Token = "bearer" + " " + userInfo.token;
    axios
      .get(`${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`, {
        headers: { Authorization: Token },
      })
      .then((response) => {
        const siteInfo = response.data.site;
        const {
          floorEscalationCharge,
          builtUpAreaFactor,
          superBuiltUpAreaFactor,
          unitGSTPercentage,
          noOfFloor,
          noEscalationFloor,
          minBookingAmount,
          phases,
        } = siteInfo;

        setFec(floorEscalationCharge);
        setBuaf(builtUpAreaFactor);
        setSbuaf(superBuiltUpAreaFactor);
        setUgp(unitGSTPercentage);
        setNof(noOfFloor);
        setNef(noEscalationFloor);
        setMinBookingAmount(minBookingAmount);
        const newPhases = phases.map((p) => {
          return {
            phaseCode: p.phaseCode,
            phaseName: p.phaseName,
            id: uniqid(),
          };
        });
        setAddPhase(newPhases);
      });
  }, []);

  //////////////////////////// JSX ////////////////////////////////
  return (
    <div className="siteconfigurations">
      <div className="siteconfigurations__form">
        <div className="row justify-content-center">
          <div className="col-4">
            <label>Floor Escalation Charge</label>
            <input
              type="number"
              className="form-control"
              name="fec"
              id="fec"
              value={fec}
              onChange={(e) => {
                setFec(e.target.value);
                setDisp("none");
              }}
            />
          </div>
          <div className="col-4">
            <label>Unit GST Percentage</label>
            <input
              type="number"
              className="form-control"
              name="ugp"
              id="ugp"
              value={ugp}
              onChange={(e) => {
                setUgp(e.target.value);
                setDisp("none");
              }}
            />
          </div>
        </div>
        <br />

        <div className="row justify-content-center">
          <div className="col-4">
            <label>No. Of Floor</label>
            <input
              type="number"
              className="form-control"
              name="nof"
              id="nof"
              value={nof}
              onChange={(e) => {
                setNof(e.target.value);
                setDisp("none");
              }}
            />
          </div>
          <div className="col-4">
            <label>No. Of Escalation Floor</label>
            <input
              type="number"
              className="form-control"
              name="nef"
              id="nef"
              value={nef}
              onChange={(e) => {
                setNef(e.target.value);
                setDisp("none");
              }}
            />
          </div>
        </div>
        <br />
        {/* save button  */}
        <div className="siteconfigurations__form__btn">
          <button
            onClick={editSite1}
            disabled={
              userInfo.userRole === "ITAdmin" ||
              userInfo.userRole === "Director"
                ? false
                : true
            }
          >
            Save
          </button>
        </div>

      
        <div className="siteconfigurations__form__table">
          <div className="mt-2 container-fluid justify-content-center">
            <div className="heading">
              <h4>Phases</h4>
              <button
                className="addbtn"
                onClick={() => {
                  setModal1(true);
                }}
              >
                Add
              </button>
            </div>
            <br />
            <table className="table">
              <thead style={{ backgroundColor: "#EE4B46", color: "#fff" }}>
                <tr>
                  <th scope="col">Phase Name</th>
                  <th scope="col">Phase Code</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {addPhase.map((p, index) => (
                  <tr key={index}>
                    <td>{p.phaseName}</td>
                    <td>{p.phaseCode}</td>

                    <td>
                      <Delete onClick={() => handleDeleteClick(p)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={modal1}
              onClose={handleClose1}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={modal1}>
                <div className={classes.paper}>
                  <form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div className="row container-fluid justify-content-center">
                      <div className="col-6">
                        <label>Phase Name</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setPName(e.target.value)}
                          required
                        />
                      </div>

                      <div className="col-6">
                        <label>Phase Code</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            setPCode(e.target.value);
                            if (
                              e.target.value.length === 1 ||
                              e.target.value.length > 4
                            ) {
                              console.log("enter");
                              setPcError(
                                "Site Code must be greater than 1 but less than 5 characters"
                              );
                            } else if (/\s/.test(e.target.value)) {
                              setPcError(
                                "Site Code cannot contain any white space"
                              );
                            } else if (
                              /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
                                e.target.value
                              )
                            ) {
                              setPcError(
                                `Site code can't contain any special characters`
                              );
                            } else if (/[a-z]/.test(e.target.value)) {
                              setPcError(
                                "Site Code can contain only numbers or uppercase letters"
                              );
                            } else {
                              setPcError("");
                            }
                          }}
                        />
                        {pcError && (
                          <p
                            style={{
                              fontSize: "15px",
                              color: "#EE4B46",
                              marginTop: "5px",
                            }}
                          >
                            {pcError}
                          </p>
                        )}
                      </div>
                    </div>
                    <br />

                    <div className="row container-fluid justify-content-center">
                      <div className="col-6 text-right">
                        <button
                          className="modal__btns"
                          type="submit"
                          onClick={(e) => handleAddPhase(e)}
                        >
                          Save
                        </button>
                      </div>
                      <div className="col-6 text-left">
                        <button
                          className="modal__btns"
                          style={{ backgroundColor: "white", color: "black" }}
                          onClick={(e) => handleClose1(e)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Fade>
            </Modal>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={modal2}
              onClose={() => setModal2(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={modal2}>
                <div className={classes.paper}>
                  <h6>Are you sure you want to delete this ?</h6>
                  <br />
                  <div className="row container-fluid justify-content-center">
                    <div className="col-6 text-right">
                      <button
                        className="modal__btns"
                        onClick={handleDeletePhase}
                      >
                        Yes
                      </button>
                    </div>
                    <div className="col-6 text-left">
                      <button
                        className="modal__btns"
                        onClick={() => setModal2(false)}
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </Fade>
            </Modal>
          </div>

          <br />

          {/* //////////////////////////// Legal Charges /////////////////// */}
          <LegalCharges />

          {/* /////////////////////// Other Charges ///////////////////// */}
          <OtherCharges />
        </div>
      </div>
    </div>
  );
};

export default SiteConfigurations;
