import { Switch } from '@material-ui/core';
import React from 'react';

const CustomSwitch = ({data}) => {
    return (
       <>
       {
           data.map((d,i)=>{
           console.log(i);<Switch color="primary" />})
       }
       </>
    );
};

export default CustomSwitch;