import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { dateFormatting } from '../../utils/dateFormatting';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ListOfAllCompOff = () => {

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const navigate = useNavigate();


    const [compOffList, setCompOffList] = useState([])
    const [status, setStatus] = useState('')
    const [employeeList, setEmployeeList] = useState([])
    const [employee, setEmployee] = useState('')
    const [day, setDay] = useState('')

    useEffect(() => {

        let url = `${BASE_URL}/api/v1/hrmgmt/compOff/getListOfCompOff?lineManagerId=${userInfo?.employeeId}`;

        let filter = '';

        if (status) {
            filter += `&compOffStatus=${status}`
        }

        if (employee) {
            filter += `&raisedByEmpId=${employee}`
        }

        if (day) {
            filter += `&days=${day}`
        }

        url += filter;

        axios.get(url,
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status) {
                    setCompOffList(response.data.compOffList)
                } else {
                    setCompOffList([])
                }
            })

    }, [status, employee, day])

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`,
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status) {
                    setEmployeeList(response.data.employees)
                } else {
                    setEmployeeList([])
                }
            })

    }, [])

    const reset = () => {
        setStatus('')
        setEmployee('')
        setDay('')
    }


    return (
        <div
            style={{
                width: '100%',
                margin: '0 auto'
            }}
        >
            <MaterialTable
                data={compOffList}
                title=""
                columns={[
                    { title: "CompOff ID", field: "compOffId" },
                    { title: "Status", field: "compOffStatus" },
                    {
                        title: "Type",
                        field: "compOffType",
                    },
                    { title: "Raise By", field: "raisedByFullName" },
                    { title: "Raise By EmpId", field: "raisedByEmpId" },
                    {
                        title: "Applied At",
                        render: (rowData) => (dateFormatting(rowData.appliedDate))
                    },
                    {
                        title: "Comp Off Date",
                        render: (rowData) => (dateFormatting(rowData.compOffDate))
                    },
                    { title: "Line Manager", field: "lineManagerFullName" },
                ]}
                options={{
                    search: window.innerWidth < 700 ? false : true,
                    searchFieldStyle: {
                        width: 180,
                    },
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    actionsColumnIndex: 0,
                    headerStyle: {
                        backgroundColor: "#EE4B46",
                        color: "#fff",
                    },
                }}

                actions={[
                    {
                        icon: 'remove_red_eye',
                        tooltip: "Edit",
                        onClick: (event, rowData) => {
                            navigate('/leavemanagement/compOff/' + rowData.compOffId)
                        },
                    },
                ]}

                components={{
                    Toolbar: (props) => (
                        <div className="filters text-left">
                            <MTableToolbar {...props} />

                            <FormControl
                                variant="standard"
                                className="formControl"
                                style={{
                                    margin: "0px 10px",
                                    marginTop: "-65px",
                                    width: "90px",
                                }}
                            >
                                <InputLabel id="demo-simple-select-standard-label">
                                    Status
                                </InputLabel>
                                <Select
                                    llabelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={status}
                                    onChange={(e) => {
                                        setStatus(e.target.value);
                                    }}
                                    className="selectEmpty"
                                    style={{
                                        fontSize: "13px",
                                        textAlign: "left",
                                    }}
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value="">Select a option</MenuItem>
                                    <MenuItem value="Applied">Applied</MenuItem>
                                    <MenuItem value="Approved">Approved</MenuItem>
                                    <MenuItem value="Rejected">Rejected</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl
                                variant="standard"
                                className="formControl"
                                style={{
                                    margin: "0px 10px",
                                    marginTop: "-65px",
                                    width: "120px",
                                }}
                            >
                                <InputLabel id="demo-simple-select-standard-label">
                                    Employee
                                </InputLabel>
                                <Select
                                    llabelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={employee}
                                    onChange={(e) => setEmployee(e.target.value)}
                                    className="selectEmpty"
                                    inputProps={{ "aria-label": "Without label" }}
                                    style={{
                                        fontSize: "13px",
                                        textAlign: "left",
                                    }}
                                >
                                    <MenuItem value="">Select a employee</MenuItem>
                                    {
                                        employeeList?.map((employee, index) => {
                                            return <MenuItem key={index} value={employee?.employeeId}>{employee?.employeeFullName}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>

                            {window.innerWidth < 1100 && <br />}

                            <FormControl
                                variant="standard"
                                className="formControl"
                                style={{
                                    margin: "0px 10px",
                                    marginTop: "-65px",
                                    width: "90px",
                                }}
                            >
                                <InputLabel id="demo-simple-select-standard-label">
                                    Days
                                </InputLabel>
                                <Select
                                    llabelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={day}
                                    onChange={(e) => {
                                        setDay(e.target.value);
                                    }}
                                    className="selectEmpty"
                                    style={{
                                        fontSize: "13px",
                                        textAlign: "left",
                                    }}
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value="">Select a option</MenuItem>
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={90}>90</MenuItem>
                                    <MenuItem value={365}>365</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl
                                className="formControl"
                                style={{
                                    marginTop: "-50px",
                                    marginRight: "110px",
                                    marginLeft: "10px",
                                }}
                            >
                                <button
                                    onClick={reset}
                                    style={{
                                        backgroundColor: "white",
                                        color: "black",
                                        border: "1px solid black",
                                        borderRadius: "20px",
                                        padding: "2px 7px",
                                    }}
                                >
                                    Reset Filter
                                </button>
                            </FormControl>
                        </div>
                    ),
                }}

            ></MaterialTable>
        </div>
    )
}

export default ListOfAllCompOff