import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const OnboardOutlet = () => {
    const user = JSON.parse(localStorage.getItem('onBoardInfo'));
    let isExpired = false;

    if (user?.token) {
        const expireTime = JSON.parse(window.atob(user.token.split('.')[1])).exp;

        if (new Date() >= expireTime * 1000) {
            isExpired = true;
        }

    }

    return (!user?.token || isExpired ? <Navigate to='/onboardLogin' /> : <Outlet />)
}

export default OnboardOutlet