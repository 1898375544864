import { Switch } from '@material-ui/core'
import React, { useState } from 'react'
import axios from 'axios'
import { BASE_URL } from '../../utils/config/url'
import Swal from 'sweetalert2'
import { styled } from '@mui/material/styles';

const RedSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#EE4B46",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#EE4B46",
    },
}));

const Details = ({ subCategory, setSubCategory, change, setChange }) => {

    const [edit, setEdit] = useState(false)
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // console.log(category)

    const dateFormatting = (input) => {

        if (!input) return

        let date = new Date(input)
        let d = date.getDate()
        if (d < 10) d = `0${d}`
        let m = date.getMonth() + 1
        if (m < 10) m = `0${m}`
        let y = date.getFullYear()

        return `${y}-${m}-${d}`
    }

    const updateCategory = (e) => {
        e.preventDefault();

        // console.log(category)

        axios.put(`${BASE_URL}/api/v1/stockmgmtapi/catSubCategory/updateCatSubCategoryBySubCategoryCode`,
            {
                subCategoryCode: subCategory.subCategoryCode,
                update: subCategory
            },
            {
                headers: {
                    Authorization: `bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                        timer: 2000
                    })
                        .then(() => {
                            setChange(!change)
                            setEdit(false)
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.message,
                        timer: 2000
                    })
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: err.response.data.error,
                    timer: 2000
                })
            })
    }

    return (
        <div className='inv-subcat__details'>

            <form
                onSubmit={(e) => updateCategory(e)}
            >

                <div className='row d-flex justify-content-center'>
                    <div className='col-6'>
                        <label>Sub Category Code</label>
                        <input type="text"
                            className='form-control'
                            value={subCategory.subCategoryCode} readOnly />
                    </div>
                    <div className='col-6'>
                        <label>Category Name</label>
                        <input type="text"
                            className='form-control'
                            value={subCategory.subCategoryName} readOnly={!edit}
                            onChange={(e) => setSubCategory((prevCategory) => {
                                return {
                                    ...prevCategory,
                                    subCategoryName: e.target.value
                                }
                            })}
                        />
                    </div>
                </div>

                <br />

                <div className='row d-flex justify-content-center'>

                    <div className='col-4 text-center mt-3'>
                        <label>Active</label>
                        <RedSwitch
                            // inputProps={{ 'aria-label': 'controlled' }}
                            checked={subCategory?.isActive ? true : false}
                            disabled={!edit}
                            onChange={(e) => {
                                setSubCategory((prevCategory) => {
                                    return {
                                        ...prevCategory,
                                        isActive: !prevCategory.isActive
                                    }
                                })
                            }}
                        />
                    </div>

                    <div className='col-6'>
                        <label>Description</label>
                        <textarea type="text"
                            className='form-control'
                            value={subCategory.subCategoryDescription} readOnly={!edit}
                            onChange={(e) => setSubCategory((prevCategory) => {
                                return {
                                    ...prevCategory,
                                    subCategoryDescription: e.target.value
                                }
                            })}
                        />
                    </div>
                </div>

                <br />

                <div className='row justify-content-center'>

                    <div className='col-6'>
                        <label>Created By</label>
                        <input type="text"
                            className='form-control'
                            value={subCategory.subCategoryCreator} readOnly
                            onChange={(e) => setSubCategory((prevCategory) => {
                                return {
                                    ...prevCategory,
                                    subCategoryCreator: e.target.value
                                }
                            })}
                        />
                    </div>

                    <div className='col-6'>
                        <label>Created On</label>
                        <input type="date"
                            className='form-control'
                            value={dateFormatting(subCategory?.creationDate)}
                            readOnly
                        />
                    </div>
                </div>

                <br />
                <br />


                {
                    edit === false
                        ?
                        <div className='row justify-content-center'>
                            <div className='col-6 text-center'>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setEdit(true)
                                    }}
                                >Edit</button>
                            </div>
                        </div>
                        :
                        <div className='row justify-content-center'>
                            <div className='col-6 text-center'>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setEdit(false)
                                        setChange(!change)
                                    }}
                                    style={{
                                        backgroundColor: 'white',
                                        color: '#EE4B46',
                                        border: '2px solid #EE4B46'
                                    }}
                                >Cancel</button>
                            </div>
                            <div className='col-6 text-center'>
                                <button>Save</button>
                            </div>
                        </div>
                }


            </form>

        </div>
    )
}

export default Details
