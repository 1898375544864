import React, { useEffect, useState } from 'react'
import './LeadFunnel.scss';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import { Form } from 'react-bootstrap';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa'

const LeadFunnels = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const [report, setReport] = useState({});
    const [siteList, setSiteList] = useState([]);
    const [siteCode, setSiteCode] = useState('')

    useEffect(() => {

        let url = `${BASE_URL}/api/v1/leadmgmt/leadReport/leadFunnel`;

        if (siteCode) {
            url += `?siteCode=${siteCode}`
        }

        axios.get(url,
            {
                headers: {
                    Authorization: `bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response);
                if (response.data.status) {
                    setReport(response.data.report)
                } else {
                    setReport({})
                }

            })

    }, [siteCode]);

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/configuration/site/getAllSiteNames`,
            {
                headers: {
                    Authorization: `bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                console.log(response);
                if (response.data.status) {
                    setSiteList(response.data.siteList)
                } else {
                    setSiteList([])
                }

            })

    }, [])


    return (
        <div className='lead-funnel'>

            <div className='row justify-content-end w-100'>
                <div className='col-6'>
                    <Form.Group controlId="site">
                        <Form.Label>Site</Form.Label>
                        <Form.Control as="select" required
                            value={siteCode}
                            onChange={(e) => {
                                setSiteCode(e.target.value)
                            }}
                        >
                            <option value="">Select a site</option>
                            {
                                siteList?.map((site, index) => {
                                    return <option key={index} value={site?.SiteCode}>{site?.SiteName}</option>
                                })
                            }

                        </Form.Control>
                    </Form.Group>
                </div>
            </div>

            <br />
            <br />

            {/* <div className='information justify-content-center'>
                <div className='info'>
                    <div className='d-flex flex-column'>
                        <span>Closed-DeadLead & No Site Visits : {report?.SiteVisit?.dropout?.ClosedDeadLead}</span>
                    </div>
                    <div className='d-flex flex-column align-items-center'>

                        <FaArrowUp className='icon' />
                    </div>
                </div>
            </div> */}


            <div className='stages'>
                <div className='stage stage-1'>
                    <div className='stage-1__funnel-part'>
                        <span>Leads Created</span>
                        <span>{report?.LeadsCreated}</span>
                    </div>
                </div>


                <div className='info'
                    style={{
                        position: 'absolute',
                        bottom: '25px',
                        left: '70px'
                    }}
                >
                    <div className='d-flex flex-column align-items-center'>
                        <FaArrowDown className='icon' />
                    </div>
                    <div className='d-flex flex-column'>
                        <span>Closed Wrong Lead : {report?.InDiscussion?.dropout?.ClosedWrongLead}</span>
                        <span>Closed No Response : {report?.InDiscussion?.dropout?.ClosedNoResponse}</span>
                    </div>
                </div>


                <div className='stage stage-2'>
                    <div className='stage-2__funnel-part'>
                        <span>In Discussion</span>
                        <span>{report?.InDiscussion?.InDiscussion}</span>
                    </div>
                </div>

                <div className='info'
                    style={{
                        position: 'absolute',
                        top: '25px',
                        left: '170px'
                    }}
                >
                    <div className='d-flex flex-column'>
                        <span>Closed Dead Lead & No Site Visits : {report?.SiteVisit?.dropout?.ClosedDeadLead}</span>
                    </div>
                    <div className='d-flex flex-column align-items-center'>

                        <FaArrowUp className='icon' />
                    </div>
                </div>


                <div className='stage stage-3'>
                    <div className='stage-3__funnel-part'>
                        <span>Site Visits</span>
                        <span>{report?.SiteVisit?.SiteVisit}</span>
                    </div>
                </div>

                <div className='info'
                    style={{
                        position: 'absolute',
                        bottom: '50px',
                        left: '380px'
                    }}
                >
                    <div className='d-flex flex-column align-items-center'>
                        <FaArrowDown className='icon' />
                    </div>
                    <div className='d-flex flex-column info-data'>
                        <span>Closed Dead Lead : {report?.ClosedConverted?.dropout?.ClosedDeadLead}</span>
                        <span>Current InDiscussion : {report?.ClosedConverted?.dropout?.currentInDiscussion}</span>
                    </div>
                </div>

                <div className='stage stage-4'>
                    <div className='stage-4__funnel-part'>
                        <span>Converted</span>
                        <span>{report?.ClosedConverted?.ClosedConverted}</span>
                    </div>

                </div>

            </div>

            <div className='information'>

                {/* <div className='info'
                    style={{
                        marginTop: '-10px'
                    }}
                >
                    <div className='d-flex flex-column align-items-center'>
                        <FaArrowDown className='icon' />
                    </div>
                    <div className='d-flex flex-column'>
                        <span>Closed-WrongLead : {report?.InDiscussion?.dropout?.ClosedWrongLead}</span>
                        <span>Closed-NoResponse : {report?.InDiscussion?.dropout?.ClosedNoResponse}</span>
                    </div>
                </div> */}


                {/* <div className='info'
                    style={{
                        marginTop: '-70px'
                    }}
                >
                    <div className='d-flex flex-column align-items-center'>
                        <FaArrowDown className='icon' />
                    </div>
                    <div className='d-flex flex-column info-data'>
                        <span>Closed-DeadLead : {report?.ClosedConverted?.dropout?.ClosedDeadLead}</span>
                        <span>Current InDiscussion : {report?.SiteVisit?.dropout?.currentInDiscussion}</span>
                    </div>
                </div> */}


            </div>


        </div >
    )
}

export default LeadFunnels