import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button'
import './IndividualTicket.scss'
import axios from 'axios'
import { BASE_URL } from '../../utils/config/url'
import { useParams } from 'react-router-dom'
import { Nav } from 'react-bootstrap';
import Details from '../../components/IndividualTicket/Details';
import Attachments from '../../components/IndividualTicket/Attachments'
import Comments from '../../components/IndividualTicket/Comments'


const IndividualTicket = () => {

    const ticketId = useParams().ticketId;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [ticket, setTicket] = useState({})
    const [tab, setTab] = useState('details')
    const [change, setChange] = useState(false)



    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/customermgmt/ticket/getTicketByTicketId/${ticketId}`,
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response.data)
                setTicket(response.data.ticket)
            })

    }, [change])

    return (
        <div className='inv-ticket'>

            <div className='row justify-content-start'>

                <div className='col-4'>
                    <Button navigateTo='/support' />
                </div>

                <div className='col-3 inv-ticket__header ml-5'>
                    <div style={{width:"100%", textAlign: "center"}}>
                    <b>Ticket ID: {ticket.ticketId}</b><br/>
                    <b>Status : {ticket?.status}</b>
                    </div>
                </div>
            </div>


            <div className="tabs">
                <Nav>
                    <Nav.Item
                        onClick={() => {
                            setTab('details')

                        }}
                    >
                        <Nav.Link className={tab === 'details' ? 'active' : 'inactive'} eventKey="first">
                            Details
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        onClick={() => {
                            setTab('attachments')

                        }}
                    >
                        <Nav.Link className={tab === 'attachments' ? 'active' : 'inactive'} eventKey="second">
                            Attachments
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item
                        onClick={() => {
                            setTab('comments')

                        }}
                    >
                        <Nav.Link className={tab === 'comments' ? 'active' : 'inactive'} eventKey="second">
                            Comments
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>

            <br />

            {
                tab === 'details' && <Details ticket={ticket} setTicket={setTicket} change={change} setChange={setChange}/>
            }

            {
                tab === 'attachments' && < Attachments ticket={ticket} setTicket={setTicket} change={change} setChange={setChange} />
            }

            {
                tab === 'comments' && <Comments ticket={ticket} setTicket={setTicket} change={change} setChange={setChange}/>
            }

        </div>
    )
}

export default IndividualTicket