import React, { useEffect, useState } from "react";
import "./LeadMgmtDashboard.scss";
import Button from "../../components/Button/Button";
import Paper from "@mui/material/Paper";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { Form } from "react-bootstrap";
import BarCharts from "./BarCharts";
import LeadOpenAgeReport from './LeadOpenAgeReport'
import GenerateLeadReport from "../../components/GenerateLeadReport/GenerateLeadReport";
import LeadFunnels from "./LeadFunnel";
ChartJS.register(...registerables);

const LeadMgmtDashboard = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();

  const [leadData, setLeadData] = useState({});
  const [openAssignedLeads, setOpenAssignedLeads] = useState([]);
  const [openAssignedAssignee, setOpenAssignedAssignee] = useState([]);
  const [openAssignedBg, setOpenAssignedBg] = useState([]);
  const [closedLeadCount, setClosedLeadCount] = useState([]);
  const [closedLeadAssignee, setClosedLeadAssignee] = useState([]);
  const [closedLeadBg, setClosedLeadBg] = useState([]);
  const [year, setYear] = useState();
  const [month, setMonth] = useState("");
  const [closedConverted, setClosedConverted] = useState("");
  const [monthlyLeadCount, setMonthlyLeadCount] = useState([]);
  const [monthName, setMonthName] = useState([]);

  const [sourceMonth, setSourceMonth] = useState("");
  const [sourceYear, setSourceYear] = useState("");
  const [leadStatus, setLeadStatus] = useState("");
  const [leadSourceCount, setLeadSourceCount] = useState([]);
  const [leadSourceLabel, setLeadSourceLabel] = useState([]);

  const [closureYear, setClosureYear] = useState("");
  const [closureMonth, setClosureMonth] = useState("");
  const [closureCount, setClosureCount] = useState([]);
  const [closureLabel, setClosureLabel] = useState([]);

  const [siteList, setSiteList] = useState([]);
  const [siteCode, setSiteCode] = useState("WH");
  const [siteName, setSiteName] = useState("Westroad Heights")

  useEffect(() => {
    axios.get(`${BASE_URL}/api/v1/Configuration/site/getAllSites`,
      {
        headers: {
          Authorization: `bearer ${userInfo.token}`
        }
      })
      .then((response) => {
        // console.log(response.data.siteArray)
        setSiteList(response.data.siteArray)
      })

  }, [])

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/leadmgmt/leadReport/leadDashboardDetails?siteCode=${siteCode}`, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((response) => {
        // console.log(response)
        setLeadData({
          unassignedLeads: response.data.unassignedLeads,
          openLeads: response.data.openLeads,
          leadsCreatedToday: response.data.leadsCreatedToday,
          openSiteVisit: response.data.openSiteVisit,
          completedSiteVisit: response.data.completedSiteVisit,
          scheduledSiteVisit: response.data.scheduledSiteVisit
        });
      });
  }, [siteCode]);

  useEffect(() => {
    let url = `${BASE_URL}/api/v1/leadmgmt/leadReport/openLeadAssignmentPieChart?siteCode=${siteCode}`;

    axios
      .get(url, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((response) => {
        // console.log(response)
        let data = response?.data?.report.map((data) => {
          return data.leadCount;
        });
        setOpenAssignedLeads(data);

        let names = response?.data?.report.map((data) => {
          return data._id;
        });

        setOpenAssignedAssignee(names);

        let colors = response?.data?.report.map((val) => {
          return (
            "#" +
            "0123456789abcdef"
              .split("")
              .map(function (v, i, a) {
                return i > 5 ? null : a[Math.floor(Math.random() * 16)];
              })
              .join("")
          );
        });

        setOpenAssignedBg(colors);
      });
  }, [siteCode]);

  useEffect(() => {
    let url = `${BASE_URL}/api/v1/leadmgmt/leadReport/closedLeadCountPieChart?siteCode=${siteCode}`;

    if (year) {
      url += `&year=${parseInt(year)}`;
    }

    if (month) {
      url += `&month=${parseInt(month)}`;
    }

    if (closedConverted) {
      url += `&closedConverted=${closedConverted}`;
    }

    axios
      .get(url, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((response) => {
        // console.log(response)
        let data = response?.data?.closedLeads.map((data) => {
          return data.count;
        });
        setClosedLeadCount(data);

        let names = response?.data?.closedLeads.map((data) => {
          return data._id;
        });

        setClosedLeadAssignee(names);

        let colors = response?.data?.closedLeads.map((val) => {
          return (
            "#" +
            "0123456789abcdef"
              .split("")
              .map(function (v, i, a) {
                return i > 5 ? null : a[Math.floor(Math.random() * 16)];
              })
              .join("")
          );
        });

        setClosedLeadBg(colors);
      });
  }, [year, month, closedConverted, siteCode]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/leadmgmt/leadReport/monthWiseLeadCount?siteCode=${siteCode}`, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((response) => {
        // console.log(response)
        setMonthlyLeadCount(response.data.monthwiseLeadCount);

        let monthlyCount = response.data.monthwiseLeadCount.map((data) => {
          return Object.values(data[0])[0];
        });

        setMonthlyLeadCount(monthlyCount);

        let monthName = response.data.monthwiseLeadCount.map((data) => {
          return Object.keys(data[0])[0];
        });

        setMonthName(monthName);
      });
  }, [siteCode]);

  useEffect(() => {
    let url = `${BASE_URL}/api/v1/leadmgmt/leadReport/leadCountBySource?siteCode=${siteCode}`;

    if (sourceYear) {
      url += `&year=${parseInt(sourceYear)}`;
    }

    if (sourceMonth) {
      url += `&month=${parseInt(sourceMonth)}`;
    }

    if (leadStatus) {
      url += `&leadStatus=${leadStatus}`;
    }


    axios
      .get(url, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((response) => {
        // console.log(response)
        let data = response?.data?.leadCountBySource.map((data) => {
          return data.count;
        });
        setLeadSourceCount(data);

        let names = response?.data?.leadCountBySource.map((data) => {
          return data._id;
        });

        setLeadSourceLabel(names);
      });
  }, [sourceYear, sourceMonth, leadStatus, siteCode]);

  useEffect(() => {
    let url = `${BASE_URL}/api/v1/leadmgmt/leadReport/closedLeadCountByReason?siteCode=${siteCode}`;

    if (closureYear) {
      url += `&year=${parseInt(closureYear)}`;
    }

    if (closureMonth) {
      url += `&month=${parseInt(closureMonth)}`;
    }

    axios
      .get(url, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((response) => {
        // console.log(response)
        let data = response?.data?.closedLeadCountByReason.map((data) => {
          return data.count;
        });
        setClosureCount(data);

        let names = response?.data?.closedLeadCountByReason.map((data) => {
          return data._id;
        });

        setClosureLabel(names);
      });
  }, [closureYear, closureMonth, siteCode]);

  return (
    <div className="lead-mgmt">
      <div className="d-flex align-items-center">
        <Button navigateTo="/home" style={{ flex: 0.2 }} />

        <h3 className="text-center" style={{ flex: 0.8 }}>
          Lead Analysis Dashboard
        </h3>


        <div className="col-3">
          <Form.Group controlId="employee">
            <Form.Label
              style={{ fontSize: '20px' }}
            >Project</Form.Label>
            <Form.Control
              as="select"
              required
              value={`${siteCode},${siteName}`}
              onChange={(e) => {
                setSiteCode(e.target.value.split(',')[0]);
                setSiteName(e.target.value.split(',')[1])
              }}
            >

              {
                siteList?.map((site, index) => {
                  return <option value={`${site?.siteCode},${site?.siteName}`} key={index}
                  >{site?.siteName}</option>
                })
              }
            </Form.Control>
          </Form.Group>
        </div>
      </div>

      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <div className="row">
          <div className="col-3">
            <span>Unassigned Leads</span>
            <div className="lead-mgmt__leadData">
              {leadData?.unassignedLeads}
            </div>
          </div>
          <div className="col-3">
            <span>Open Leads</span>
            <div className="lead-mgmt__leadData">{leadData?.openLeads}</div>
          </div>
          <div className="col-3">
            <span>Leads Created Today</span>
            <div className="lead-mgmt__leadData">
              {leadData?.leadsCreatedToday}
            </div>
          </div>

          <div className="col-3 d-flex flex-column">
            <button
              className="lead-mgmt__btn"
              onClick={() => navigate("/viewleads")}
            >
              List of Leads
            </button>
          </div>
          <div className="col-3 mt-2">
            <span>Open Site Visits</span>
            <div className="lead-mgmt__leadData">{leadData?.openSiteVisit}</div>
          </div>
          <div className="col-3 mt-2">
            <span>Site Visits Scheduled Today</span>
            <div className="lead-mgmt__leadData">
              {leadData?.scheduledSiteVisit}
            </div>
          </div>
          <div className="col-3 mt-2">
            <span>Site Visits Completed Today</span>
            <div className="lead-mgmt__leadData">
              {leadData?.completedSiteVisit}
            </div>
          </div>

          <div className="col-3 mt-2 d-flex flex-column">
            <button
              className="lead-mgmt__btn"
              onClick={() => navigate("/addlead")}
            >
              Add Lead
            </button>
          </div>
        </div>
      </Paper>

      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <h4 className="text-center">Open and Assigned Leads</h4>
        <div className="row">
          <div className="col-12">
            {openAssignedLeads.length === 0 ? (
              <h4 className="text-center">NO LEADS FOUND</h4>
            ) : (
              <Pie
                data={{
                  labels: openAssignedAssignee,
                  datasets: [
                    {
                      // label: '# Open ans Assigned Leads',
                      data: openAssignedLeads,
                      backgroundColor: openAssignedBg,
                      borderWidth: 1,
                    },
                  ],
                }}
                width={200}
                height={250}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            )}
          </div>
        </div>
      </Paper>

      <Paper celevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <h4 className="text-center">Closed Leads</h4>
        <div className="row justify-content-center">
          <div className="col-3">
            <Form.Group controlId="year">
              <Form.Label>Year</Form.Label>
              <Form.Control
                as="select"
                required
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
              >
                <option value="">Select a Year</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                <option value="2029">2029</option>
                <option value="2030">2030</option>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="col-3">
            <Form.Group controlId="year">
              <Form.Label>Month</Form.Label>
              <Form.Control
                as="select"
                required
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
              >
                <option value="">Select a Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">Novemeber</option>
                <option value="12">December</option>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="col-3">
            <Form.Group controlId="employee">
              <Form.Label>Converted to Sales?</Form.Label>
              <Form.Control
                as="select"
                required
                value={closedConverted}
                onChange={(e) => {
                  setClosedConverted(e.target.value);
                }}
              >
                <option value="">Select a option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Form.Control>
            </Form.Group>
          </div>

        </div>
        <div className="row mt-3">
          <div className="col-12">
            {closedLeadCount.length === 0 ? (
              <h4 className="text-center">NO LEADS FOUND</h4>
            ) : (
              <Pie
                data={{
                  labels: closedLeadAssignee,
                  datasets: [
                    {
                      // label: 'Task Open Due Counts',
                      data: closedLeadCount,
                      backgroundColor: closedLeadBg,
                      borderWidth: 1,
                    },
                  ],
                }}
                width={200}
                height={250}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            )}
          </div>
        </div>
      </Paper>

      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <h4 className="text-center">Leads Created Monthwise</h4>

        <div className="row">
          <div className="col-12">
            <BarCharts leadLabel={monthName} leadCount={monthlyLeadCount} />
          </div>
        </div>
      </Paper>

      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <h4 className="text-center">Lead Count By Source</h4>

        <div className="row justify-content-center">
          <div className="col-3">
            <Form.Group controlId="year">
              <Form.Label>Year</Form.Label>
              <Form.Control
                as="select"
                required
                value={sourceYear}
                onChange={(e) => {
                  setSourceYear(e.target.value);
                }}
              >
                <option value="">Select a Year</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                <option value="2029">2029</option>
                <option value="2030">2030</option>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="col-3">
            <Form.Group controlId="year">
              <Form.Label>Month</Form.Label>
              <Form.Control
                as="select"
                required
                value={sourceMonth}
                onChange={(e) => {
                  setSourceMonth(e.target.value);
                }}
              >
                <option value="">Select a Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">Novemeber</option>
                <option value="12">December</option>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="col-3">
            <Form.Group controlId="employee">
              <Form.Label>Lead Status</Form.Label>
              <Form.Control
                as="select"
                required
                value={leadStatus}
                onChange={(e) => {
                  setLeadStatus(e.target.value);
                }}
              >
                <option value="">Select a option</option>
                <option value="Open">Open</option>
                <option value="Closed">Closed</option>
                <option value="Closed-Converted">Converted to Sale</option>
              </Form.Control>
            </Form.Group>
          </div>

        </div>

        <div className="row">
          <div className="col-12">
            <BarCharts
              leadLabel={leadSourceLabel}
              leadCount={leadSourceCount}
            />
          </div>
        </div>
      </Paper>

      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <h4 className="text-center">Lead Count By Closure Reason</h4>

        <div className="row justify-content-center">
          <div className="col-3">
            <Form.Group controlId="year">
              <Form.Label>Year</Form.Label>
              <Form.Control
                as="select"
                required
                value={closureYear}
                onChange={(e) => {
                  setClosureYear(e.target.value);
                }}
              >
                <option value="">Select a Year</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                <option value="2029">2029</option>
                <option value="2030">2030</option>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="col-3">
            <Form.Group controlId="year">
              <Form.Label>Month</Form.Label>
              <Form.Control
                as="select"
                required
                value={closureMonth}
                onChange={(e) => {
                  setClosureMonth(e.target.value);
                }}
              >
                <option value="">Select a Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">Novemeber</option>
                <option value="12">December</option>
              </Form.Control>
            </Form.Group>
          </div>

        </div>

        <div className="row">
          <div className="col-12">
            <BarCharts leadLabel={closureLabel} leadCount={closureCount} />
          </div>
        </div>
      </Paper>

      <LeadOpenAgeReport siteCode={siteCode} />

      <GenerateLeadReport siteCode={siteCode} siteName={siteName} />

      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>

        <h4 className="text-center"
        >Lead Funnel</h4>
        <LeadFunnels />
      </Paper>

    </div>
  );
};

export default LeadMgmtDashboard;
