import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Button from '../../components/Button/Button';
import { BASE_URL } from '../../utils/config/url';
import './AddPaymentTerms.scss';

const AddPaymentTerms = () => {

    const siteCode = useParams().siteCode;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const Token = 'bearer' + " " + userInfo.token
    const navigate = useNavigate();

    const [sname, setSname] = useState([]);
    const [sid, setSid] = useState('')
    const [sn, setSn] = useState(1)
    const [per, setPer] = useState()
    const [desc, setDesc] = useState()
    const [phaseCodes, setPhaseCodes] = useState([])
    const [selectedPhaseCode, setSelectedPhaseCode] = useState('');
    const [termItems, setTermItems] = useState([
        { serial: '', description: "", percentage: "" }
    ])


    const submit = (e) => {
        e.preventDefault()

        if (termItems.length < 3) {
            return
        }

        //console.log(termItems)
        axios
            .post(`${BASE_URL}/api/v1/Configuration/PaymentTerms/generatePaymentTermsBySiteCode`,
                {
                    siteCode: siteCode,
                    siteName: sname,
                    phaseCode: selectedPhaseCode,
                    termItems: termItems
                },
                { headers: { 'Authorization': Token } })
            .then(response => {
                console.log(response)
                if (response.status === 200) {

                    if (response.data.status === false) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Ooops',
                            showClass: {
                                popup: 'animate__animated animate__fadeInDown'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__fadeOutUp'
                            },
                            text: response.data.message
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            showClass: {
                                popup: 'animate__animated animate__fadeInDown'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__fadeOutUp'
                            },
                            text: `Payment Term successfully added`
                        })
                            .then(() => {
                                navigate(-1)
                            })
                    }
                }
            })
    }

    const handleTIChange = (index, event, sn) => {
        console.log(sn)
        const values = [...termItems];
        values[index].serial = sn
        if (event.target.name === "desc") {
            values[index].description = event.target.value;
        }
        else if (event.target.name === "percentage") {
            values[index].percentage = parseInt(event.target.value);
        }

        setTermItems(values);
    }

    const handleAddTermItem = (index, e) => {
        e.preventDefault()
        const values = [...termItems];
        values.push({ serial: "", description: "", percentage: "" })
        setTermItems(values);

    }

    const insertTI = (index, e) => {
        e.preventDefault()
        const values = [...termItems]
        values.splice(index + 1, 0, { serial: "", description: "", percentage: "" })
        setTermItems(values)
    }


    const deleteTI = (index, e) => {
        e.preventDefault()
        const values = [...termItems];
        values.splice(index, 1)
        setTermItems(values);

    }

    const reset = () => {
        setTermItems([
            { serial: '', description: "", percentage: "" }
        ])
    }

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`,
            { headers: { Authorization: Token } })
            .then(response => {

                setSname(response.data.site.siteName)
                setSid(response.data.site.siteId)

                const pcList = []
                response.data.site.phases.forEach((p) => {
                    pcList.push(p.phaseCode)
                })

                setPhaseCodes(pcList)
            })

    }, [])


    return (
        <div className='addpaymentterm'>

            <Button navigateTo={1} />

            <div className="row mt-5 justify-content-center">
                <div className="col-10">
                    <h3>Add Payment Term</h3>
                </div>
            </div>
            <br />
            <form className="addpaymentterm__form" onSubmit={(e) => submit(e)}>
                <div className="row pt-3 justify-content-center">
                    <div className="col-4">
                        <label>Site Name</label>
                        <input className='form-control' value={sname} readOnly />
                    </div>
                    <div className="col-4">
                        <label>Site Code</label>
                        <input className='form-control' value={siteCode} readOnly />
                    </div>
                    <div className="col-4">
                        <Form.Group controlId="phaseCode">
                            <Form.Label>Phase Code</Form.Label>
                            <Form.Control as="select" required
                                onChange={(e) => {
                                    setSelectedPhaseCode(e.target.value)
                                }}>
                                <option value="">Select a Phase Code</option>
                                {
                                    phaseCodes.map((p, index) => (
                                        <option key={index}>{p}</option>
                                    ))
                                }


                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>
                <br />


                <div className="addpaymentterm__form__table">
                    <div className=" mt-2 container-fluid justify-content-center">
                        <div className='heading'>
                            <h4>
                                Term Items
                            </h4>
                        </div>


                        <br />

                        {
                            termItems.map((termItems, index) => {
                                return (
                                    <div className="row" style={{marginBottom : '10px' }} key={index}>
                                        <div className="col-9">
                                            <div className="row">
                                                <div className="col-2">
                                                    <label>SNo.</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="slno"
                                                        id="slno"
                                                        required
                                                        value={index + 1}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-8">
                                                    <label>Description</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="desc"
                                                        id="desc"
                                                        required
                                                        autoFocus
                                                        autoComplete='off'
                                                        value={termItems.description}
                                                        onChange={(event) => handleTIChange(index, event, parseInt(index + 1))}
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <label>Percentage</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="percentage"
                                                        id="percentage"
                                                        required
                                                        value={termItems.percentage}
                                                        onChange={(event) => handleTIChange(index, event, parseInt(index + 1))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div >
                                            <button className="add-btn mt-4"
                                                onClick={(e) => handleAddTermItem(index, e)}
                                                style={{ display: index === 0 ? "inline-block" : "none" }}
                                            >Add Row</button>
                                            &nbsp;&nbsp;
                                            <button className="add-btn mt-4"
                                                onClick={(e) => insertTI(index, e)}
                                                style={{ display: index === 0 ? "none" : "inline-block" }}>
                                                Insert
                                            </button>
                                            &nbsp; &nbsp; &nbsp;
                                            <button className="add-btn mt-4"
                                                onClick={(e) => deleteTI(index, e)}
                                                style={{ display: index === 0 ? "none" : "inline-block" }}>
                                                Delete
                                            </button>
                                        </div>
                                      
                                    </div>
                                )
                            })
                        }
                      

                    </div>
                </div>


                <div className="addpaymentterm__btns" >
                    <button type="reset" onClick={reset} style={{ backgroundColor: "white", color: "black", border: '1px solid black' }}>Reset</button>
                    <button type="submit">Add</button>
                    <br />
                    <p style={{ color: 'red' }}>* Minimum 3 rows required</p>

                </div>

            </form>
        </div>
    )
}

export default AddPaymentTerms