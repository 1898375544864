import axios from "axios";
import React, { useEffect, useState } from "react";
import "./FinalBtn.scss";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";

const FinalBtn = ({ quotation, setChange, approved, setApproved }) => {

  const { isFinalRequested, isFinalApproved } = quotation;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const datas = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + datas.token;

  //apply for final quotation
  const handleApply = () => {
    axios
      .post(
        `${BASE_URL}/api/v1/leadmgmt/quotation/applyQuotationFinal`,
        {
          quotationId: quotation.quotationId,
          userName: userInfo.userName,
          userFullName: userInfo.userFullName,
        },
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Successfully Applied for finalize quotation!",
            timer: 1900,
            showConfirmButton: false,
          });
          setChange((prev) => !prev);
        } else {
          Swal.fire({
            icon: "warning",
            title: "Oops!! there is an error in applying for finalize quotation",
            timer: 1900,
            showConfirmButton: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "warning",
          title: "Oops!! there is an error in applying for finalize quotation",
          timer: 1900,
          showConfirmButton: false,
        });
      });
  };


  ///approved quotation
  const handleApprove = () => {
    axios
      .post(
        `${BASE_URL}/api/v1/leadmgmt/quotation/markQuotationFinal`,
        {
          quotationId: quotation.quotationId,
          isApproved: true,
          userName: userInfo.userName,
          userFullName: userInfo.userFullName,
        },
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Approved Quotation Successfully!",
            timer: 1900,
            showConfirmButton: false,
          })
            .then(() => {
              setChange((prev) => !prev);
              setApproved(true)
            })
        } else {
          Swal.fire({
            icon: "warning",
            title: "Oops!! there is an error in approving quotation",
            timer: 1900,
            showConfirmButton: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "warning",
          title: "Oops!! there is an error in approving quotation",
          timer: 1900,
          showConfirmButton: false,
        });
      });
  };


  ///reject qoutation
  const handleReject = () => {
    Swal.fire({
      title: "Are you sure you want to reject this quotation?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${BASE_URL}/api/v1/leadmgmt/quotation/markQuotationFinal`,
            {
              quotationId: quotation.quotationId,
              isApproved: false,
              userName: userInfo.userName,
              userFullName: userInfo.userFullName,
            },
            { headers: { Authorization: Token } }
          )
          .then((response) => {
            if (response.data.status) {
              Swal.fire({
                icon: "success",
                title: "Rejected Quotation Successfully!",
                timer: 1900,
                showConfirmButton: false,
              });
              setChange((prev) => !prev);
            } else {
              Swal.fire({
                icon: "warning",
                title: "Oops!! there is an error in rejecting quotation",
                timer: 1900,
                showConfirmButton: false,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "warning",
              title: "Oops!! there is an error in rejecting quotation",
              timer: 1900,
              showConfirmButton: false,
            });
          });
      }
    });
  };


  return (
    <div>

      {
        (isFinalRequested === false && isFinalApproved === 'NA')
        &&
        <button className="final_btn bg-dark px-2" onClick={handleApply}
        >
          Send for Final Review
        </button>
      }

      {
        (isFinalRequested === true && isFinalApproved === "NA")
          ?
          <b style={{color : 'grey'}}>Pending Approval with {quotation?.finalApproverFullName}</b>
          :
          (isFinalRequested === false && isFinalApproved === "Rejected")
            ?
            <b style={{color : 'red'}}>Rejected By {quotation?.finalApproverFullName}</b>
            :
            (isFinalRequested === false && isFinalApproved === "Approved")
              ?
              <b style={{color : 'green'}}>Approved By {quotation?.finalApproverFullName}</b>
              :
              null
      }

    </div>
  );
};

export default FinalBtn;
