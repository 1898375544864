import React, { useState } from "react";
import Button from "../../components/Button/Button";
import ViewInvEmployee from "../../components/ViewInvEmployee/ViewInvEmployee";
import ViewSalaryStructure from "../../components/ViewSalaryStructure/ViewSalaryStructure";
import ViewDocuments from "../../components/ViewDocuments/ViewDocuments";
import ViewEducation from "../../components/ViewEducation/ViewEducation";

import "./ViewInvEmployees.scss";
import ViewWorkHistory from "../../components/ViewWorkHistory/ViewWorkHistory";

const ViewInvEmployees = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [active, setActive] = useState("dtl");
  const userRole = userInfo.userRole;

  return (
    <div className="invEmp">
      <div className="invEmp__container">
        <div className="row justify-content-between mb-2">
          <Button navigateTo={"/viewemployees"} className='col-2' />
          <div className="invEmp__container__tabs col-11">
            <button
              className={`${active === "dtl" ? "bg-red" : "bg-dark"}`}
              onClick={() => {
                setActive("dtl");
              }}
            >
              Details
            </button>
            <button
              className={`${active === "dc" ? "bg-red" : "bg-dark"}`}
              onClick={() => {
                setActive("dc");
              }}
            >
              Documents
            </button>
            {(userRole === "Director" || userRole === 'HR' || userRole === 'Finance') && (
              <button
                className={`${userRole === "Director" || userRole === 'HR' || userRole === 'Finance'
                    ? `${active === "sal" ? "bg-red d-block" : "bg-dark d-block"
                    }`
                    : "d-none"
                  }`}
                onClick={() => {
                  setActive("sal");
                }}
              >
                CTC
              </button>
            )}
            <button
              className={`${active === "edu" ? "bg-red" : "bg-dark"}`}
              onClick={() => {
                setActive("edu");
              }}
            >
              Education
            </button>
            <button
              className={`${active === "work" ? "bg-red" : "bg-dark"}`}
              onClick={() => {
                setActive("work");
              }}
            >
              Work History
            </button>
          </div>
        </div>
        {active === "dtl" && <ViewInvEmployee />}
        {active === "dc" && <ViewDocuments />}
        {active === "sal" && <ViewSalaryStructure />}
        {active === "edu" && <ViewEducation />}
        {active === "work" && <ViewWorkHistory />}
    
      
      </div>
    </div>
  );
};

export default ViewInvEmployees;
