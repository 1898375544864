import React, { useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import '../Tabs/Tabs.scss';

const ApplicationTabs = ({ tabName }) => {

    const [tab, setTab] = useState(localStorage.getItem('ActiveKeyApplication'))

    return (
        <div className="tabs">
            <Nav>

                <Nav.Item className='mr-1 mt-1'
                    onClick={() => {
                        localStorage.setItem("ActiveKeyApplication", "first");
                        setTab('first')
                        tabName('first')
                    }}
                >
                    <Nav.Link className={tab === 'first' ? 'active' : 'inactive'} eventKey="first">
                        Details
                    </Nav.Link>
                </Nav.Item>

                <Nav.Item className='mr-1 mt-1'
                    onClick={() => {
                        localStorage.setItem("ActiveKeyApplication", "second");
                        setTab('second')
                        tabName('second')
                    }}
                >
                    <Nav.Link className={tab === 'second' ? 'active' : 'inactive'} eventKey="second">
                        Applicants
                    </Nav.Link>
                </Nav.Item>

                <Nav.Item className='mr-1 mt-1'
                    onClick={() => {
                        localStorage.setItem("ActiveKeyApplication", "third");
                        setTab('third')
                        tabName('third')
                    }}
                >
                    <Nav.Link className={tab === 'third' ? 'active' : 'inactive'} eventKey="third">
                        Considerations
                    </Nav.Link>
                </Nav.Item>

                <Nav.Item className='mr-1 mt-1'
                    onClick={() => {
                        localStorage.setItem("ActiveKeyApplication", "fifth");
                        setTab('fifth')
                        tabName('fifth')
                    }}
                >
                    <Nav.Link className={tab === 'fifth' ? 'active' : 'inactive'} eventKey="fifth">
                        Payment Schedule
                    </Nav.Link>
                </Nav.Item>

                <Nav.Item className='mr-1 mt-1'
                    onClick={() => {
                        localStorage.setItem("ActiveKeyApplication", "fourth");
                        setTab('fourth')
                        tabName('fourth')
                    }}
                >
                    <Nav.Link className={tab === 'fourth' ? 'active' : 'inactive'} eventKey="fourth">
                        Booking Payment
                    </Nav.Link>
                </Nav.Item>

                <Nav.Item className='mr-1 mt-1'
                    onClick={() => {
                        localStorage.setItem("ActiveKeyApplication", "sixth");
                        setTab('sixth')
                        tabName('sixth')
                    }}
                >
                    <Nav.Link className={tab === 'sixth' ? 'active' : 'inactive'} eventKey="sixth">
                        Documents
                    </Nav.Link>
                </Nav.Item>

                <Nav.Item className='mr-1 mt-1'
                    onClick={() => {
                        localStorage.setItem("ActiveKeyApplication", "seventh");
                        setTab('seventh')
                        tabName('seventh')
                    }}
                >
                    <Nav.Link className={tab === 'seventh' ? 'active' : 'inactive'} eventKey="seventh">
                        Sale Agreement Registration
                    </Nav.Link>
                </Nav.Item>

            </Nav>
            {/* <div className={unitTab==='first' ? 'active' : 'inactive'} onClick={()=>setUnitTab('first')}>
          Unit Details
        </div>
  
        */}

        </div>
    )
}

export default ApplicationTabs