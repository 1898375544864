import { useParams } from 'react-router-dom'
import './ViewWarehouse.scss'
import Button from '../../components/Button/Button';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import ClipLoader from "react-spinners/ClipLoader";
import Swal from 'sweetalert2';

const ViewWarehouse = () => {
    const warehouseId = useParams().warehouseId;
    //get the userinfo
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    //state for storing the data

    //fetching the details state
    const [isLoading, setIsLoading] = useState(true);
    const [warehouseDetails, setWarehouseDetails] = useState(null);

    //list supervisor
    const [listSupervisor, setListSupervisor] = useState([]);

    //state variable
    const [supervisor, setSupervisor] = useState('')
    const [engineer, setEngineer] = useState('')
    const [wareHouseName, setWareHouseName] = useState('')
    const [isActive, setIsActive] = useState('')
    const [createdBy, setCreatedBy] = useState('')
    const [wareHouseDescription, setWareHouseDescription] = useState('')

    //state if supervisor updated
    const [isSupervisorChanged, setIsSupervisorChanged] = useState(false);
    const [isEngineerChanged, setIsEngineerChanged] = useState(false);

    //loading state for updating
    const [loading, setLoading] = useState(false)
    const [isWarehouseDetailsChanged, setIsWarehouseDetailsChanged] = useState(false);


    //fetching warehouse details
    const getWarehouseByWHCode = async () => {
        const { data } = await axios.get(`${BASE_URL}/api/v1/stockmgmtapi/warehouse/getWarehouseByWHCode/${warehouseId}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        });
        setWarehouseDetails(data.WareHouse)
        setWareHouseName(data.WareHouse.wareHouseName)
        setIsActive(data.WareHouse.isActive)
        setCreatedBy(data.WareHouse.createdBy)
        setWareHouseDescription(data.WareHouse.wareHouseDescription)
        setIsLoading(false);
    }

    //fetching all the supervisor list
    const getListOfEmployee = async () => {
        const { data } = await axios.get(`${BASE_URL}/api/v1/hrmgmt/Employee/getListOfEmployee?isActive=true`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
        setListSupervisor(data.employees)
    }




    const handleSave = async () => {
        setLoading(true);
        const formData = {
            WHCode: warehouseId,
            update: {
                wareHouseName,
                isActive,
                createdBy,
                wareHouseDescription
            }
        };

        try {
            const { data } = await axios.put(`${BASE_URL}/api/v1/stockmgmtapi/warehouse/updateWarehouseByWHCode`, formData, {
                headers: { Authorization: `bearer ${userInfo.token}` }
            });
            setIsWarehouseDetailsChanged(true);
            setLoading(false);
            Swal.fire({
                icon: "success",
                title: "Success",
                text: data.message,
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                }
            })
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
                text: error.data.error,
            });
        }
    }


    const handleSupervisionAssign = async () => {
        if (supervisor === '') {
            alert("Assign Supervisor")
            return;
        }
        try {
            const { data } = await axios.put(`${BASE_URL}/api/v1/stockmgmtapi/warehouse/assignSupervisorToWarehouse`, { WHCode: warehouseId, whSupervisorEmpId: supervisor }, {
                headers: { Authorization: `bearer ${userInfo.token}` }
            });
            setIsSupervisorChanged(true);
            Swal.fire({
                icon: "success",
                title: "Success",
                text: data.message,
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                }
            })
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
                text: error.data.error,
            });
        }
    }


    const handleEngineerAssign = async () => {
        if (engineer === '') {
            Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "Assign Engineer",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                }
            })
            return;
        }
        try {
            const { data } = await axios.put(`${BASE_URL}/api/v1/stockmgmtapi/warehouse/assignSiteEngineerToWarehouse`, { WHCode: warehouseId, siteEngineerEmpId: engineer }, {
                headers: { Authorization: `bearer ${userInfo.token}` }
            });
            setIsEngineerChanged(true);
            Swal.fire({
                icon: "success",
                title: "Success",
                text: data.message,
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                }
            })
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
                text: error.response.data.error,
            });
        }
    }



    useEffect(() => {
        getWarehouseByWHCode();
        getListOfEmployee();
        setEngineer('')
        setSupervisor('')
    }, [isSupervisorChanged, isEngineerChanged]);

    return (
        <div className='viewwarehouse'>
            <div className='warehouse__buttons'>
                <Button navigateTo={'/engineering/stockManagement/warehouse'} />
            </div>
            {
                !warehouseDetails ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}> <ClipLoader
                    color={'#EE4B46'}
                    loading={isLoading}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                /></div> :
                    <div className='card__container'>
                        <div className="first__card">
                            <div className='heading'>
                                <span>Warehouse Code: {warehouseId}</span>
                            </div>
                            <div className='row__container'>
                                <div className="first__row">
                                    <div className='input__field'>
                                        <label htmlFor="name">Name</label>
                                        <input type="text" name="name" id="name" value={wareHouseName} onChange={(e) => setWareHouseName(e.target.value)} />
                                    </div>
                                    <div className='input__field'>
                                        <label htmlFor="isActive">Is Active</label>
                                        <select name="isActive" id="isActive" value={isActive}
                                            onChange={(e) => setIsActive(e.target.value)}
                                        >
                                            <option value="true">True</option>
                                            <option value="false">False</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="second__row">
                                    <div>
                                        <label htmlFor="createdByFullName">Created By</label>
                                        <input type="text" name="createdByFullName" id="createdByFullName" value={createdBy}
                                            onChange={(e) => setCreatedBy(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="whSupervisorFullName">Supervisor Name</label>
                                        <input type="text" name="whSupervisorFullName" id="whSupervisorFullName" value={warehouseDetails.whSupervisorFullName} readOnly />
                                    </div>
                                    <div>
                                        <label htmlFor="siteEngineerFullName">Site Engineer</label>
                                        <input type="text" name="siteEngineerFullName" id="siteEngineerFullName" value={warehouseDetails.siteEngineerFullName}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="third__row">
                                    <label htmlFor="Description">Description</label>
                                    <input type="text" name="Description" id="Description" value={wareHouseDescription}
                                        onChange={(e) => setWareHouseDescription(e.target.value)}
                                    />
                                </div>
                                <div className='btn__container'>
                                    {
                                        loading ? <ClipLoader
                                            color={'#EE4B46'}
                                            loading={loading}
                                            size={50}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        /> : <button className='saveBtn' onClick={handleSave}>Save</button>
                                    }

                                </div>
                            </div>
                        </div>


                        <div className="second__card">
                            <h2>Assign Warehouse Supervisor</h2>
                            <div className='content'>
                                <div>
                                    <label htmlFor="whSupervisorFullName">Current Supervisor</label>
                                    <input type="text" name="whSupervisorFullName" id="whSupervisorFullName" readOnly value={warehouseDetails.whSupervisorFullName} />
                                </div>
                                <div className='assign__superviser'>
                                    <div>
                                        <label htmlFor="Assign Supervisor">Assign Supervisor</label>
                                        <div className='assignBtn__container'>
                                            <select name="Assign Supervisor" id="Assign Supervisor" onChange={(e) => setSupervisor(e.target.value)} >
                                                <option value="">Select</option>
                                                {
                                                    listSupervisor.map(list =>
                                                        <option key={list.employeeId}
                                                            value={list.employeeId}
                                                        >
                                                            {list.employeeFullName}</option>)
                                                }
                                            </select>
                                            <button className='assignBtn' onClick={handleSupervisionAssign}>Assign</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="second__card">
                            <h2>Assign Site Engineer</h2>
                            <div className='content'>
                                <div>
                                    <label htmlFor="whEngineerFullName">Current Engineer</label>
                                    <input type="text" name="whEngineerFullName" id="whEngineerFullName" readOnly value={warehouseDetails.siteEngineerFullName} />
                                </div>
                                <div className='assign__superviser'>
                                    <div>
                                        <label htmlFor="Assign Supervisor">Assign Engineer</label>
                                        <div className='assignBtn__container'>
                                            <select name="Assign Supervisor" id="Assign Supervisor"
                                                value={engineer}
                                                onChange={(e) => setEngineer(e.target.value)} >
                                                <option value="">Select</option>
                                                {
                                                    listSupervisor.map(list =>
                                                        <option key={list.employeeId}
                                                            value={list.employeeId}
                                                        >
                                                            {list.employeeFullName}</option>)
                                                }
                                            </select>
                                            <button className='assignBtn' onClick={handleEngineerAssign}>Assign</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
            }
        </div>
    )
}

export default ViewWarehouse