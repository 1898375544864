import React, { useEffect, useState } from "react";
import "./CreateSalaryStructure.scss";
import Button from "../../components/Button/Button";
import { BASE_URL } from "../../utils/config/url";
import { jobLevels } from "../../utils/config/jobLevel";
import { Form } from "react-bootstrap";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Swal from "sweetalert2";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useNavigate } from "react-router-dom";

const CreateSalaryStructure = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [createEnabled, setCreateEnabled] = useState(false);

  const [employeeList, setEmployeeList] = useState([]);
  const [employee, setEmployee] = useState("");
  const [fullName, setFullName] = useState("");
  const [role, setRole] = useState("");
  const [jobLevel, setJobLevel] = useState("");
  const [designation, setDesignation] = useState("");
  const [basic, setBasic] = useState(0);
  const [financialYear, setFinancialYear] = useState("");
  const [personalAllowance, setPersonalAllowance] = useState(0);
  const [uniformNLaundryAllowance, setUniformNLaundryAllowance] = useState(0);
  const [perfBonus, setPerfBonus] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState({});
  const [monthTotal, setMonthTotal] = useState(0);
  const [totalCTC, setTotalCTC] = useState(0);
  const [isPF, setIsPF] = useState(true);
  const [modal, setModal] = useState(false);

  const generateFinancialYears = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Adding 1 since getMonth() returns 0-based index
    const startYear = currentMonth >= 4 ? currentYear : currentYear - 1;
    const options = [];
    for (let i = 0; i <= 2; i++) {
      const financialYearStart = startYear + i;
      const financialYearEnd = financialYearStart + 1;
      options.push(
        <option
          key={`${financialYearStart}-${financialYearEnd}`}
          value={`${financialYearStart}-${financialYearEnd}`}
        >
          {`${financialYearStart}-${financialYearEnd}`}
        </option>
      );
    }
    return options;
  };

  useEffect(() => {

    let lev = {};
    jobLevels.forEach((level) => {
      if (level.level === jobLevel.split(",")[0]) {
        lev = level;
      }
    });

    setSelectedLevel(lev);
  }, [jobLevel]);

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          setEmployeeList(response.data.employees);
        }
      });
  }, []);

  useEffect(() => {
    let month = 0;
    let year = 0;

    if (basic) {
      month += parseInt(basic);
      month += parseInt(basic * (5 / 10)); // HRA
      month += parseInt(selectedLevel?.mealAllowance);
      month += parseInt(selectedLevel?.travelAllowance);
      month += parseInt(selectedLevel?.phoneAndDateAllowance);

      year += parseInt(basic * 12);
      year += parseInt(basic * (5 / 10)) * 12;
      year += parseInt(selectedLevel?.mealAllowance) * 12;
      year += parseInt(selectedLevel?.travelAllowance) * 12;
      year += parseInt(selectedLevel?.phoneAndDateAllowance) * 12;
      year += parseInt(selectedLevel?.medicalInsurance); //medical Insurance
      year += parseInt(basic); //Year festival bonus
    }

    if (isPF) {
      month += parseInt(basic * (12 / 100)); //PF
      month += parseInt(basic * (4.81 / 100)); // Gratuity

      year += parseInt(basic * (12 / 100)) * 12;
      year += parseInt(basic * (4.81 / 100)) * 12;
    }

    if (personalAllowance) {
      month += parseInt(personalAllowance);
      year += parseInt(personalAllowance) * 12;
    }
    if (uniformNLaundryAllowance) {
      month += parseInt(uniformNLaundryAllowance);
      year += parseInt(uniformNLaundryAllowance) * 12;
    }

    if (perfBonus) {
      month += parseInt(perfBonus);
      year += parseInt(perfBonus) * 12;
    }

    setMonthTotal(month);
    setTotalCTC(year);
  }, [basic, personalAllowance, uniformNLaundryAllowance, perfBonus, isPF]);

  const getEmployeeByEmpId = async (empId) => {
    try {
      let res = await axios.get(
        `${BASE_URL}/api/v1/hrmgmt/Employee/getEmployeeByEmployeeId/${empId}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      );

      if (res.data.status === true) {
        setFullName(res.data.employee.employeeFullName);
        setRole(res.data.employee.orgRole);
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: err.response.data.error,
      });
    }
  };

  const checkCTC = async () => {
    if (employee === "" || financialYear === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: "Please select Employee Id and Financial Year",
      });
      return;
    }
    getEmployeeByEmpId(employee);
    try {
      let res = await axios.get(
        `${BASE_URL}/api/v1/hrmgmt/salarystructure/checkCTCAvailabity?employeeId=${employee}&financialYear=${financialYear}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      );

      if (res.data.status === false) {
        setMessage(res.data.message);
        setCreateEnabled(true);
      } else {
        setMessage(res.data.message);
        setCreateEnabled(false);
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: err.response.data.error,
      });
    }
  };

  const createSalaryStructure = async () => {
    if(!jobLevel){
      setModal(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: "Please select Job Level",
      });
      return;
    }
    try {
      let body = {
        employeeId: employee,
        employeeFullName: fullName,
        employeeRole: role,

        jobLevel: jobLevel.split(",")[0],
        designation: jobLevel.split(",")[1],
        financialYear: financialYear,

        monthlyBasic: parseInt(basic),
        yearlyBasic: parseInt(basic) * 12,
        monthlyHRA: parseInt(basic * (5 / 10)),
        yearlyHRA: parseInt(basic * (5 / 10)) * 12,

        monthlyPersonalAllowance: parseInt(personalAllowance),
        yearlyPersonalAllowance: parseInt(personalAllowance) * 12,
        monthlyUniformNLaundryAllowance: parseInt(uniformNLaundryAllowance),
        yearlyUniformNLaundryAllowance: parseInt(uniformNLaundryAllowance) * 12,
        monthlyTravelAllowance: parseInt(selectedLevel?.travelAllowance),
        yearlyTravelAllowance: parseInt(selectedLevel?.travelAllowance) * 12,
        monthlyMealAllowance: parseInt(selectedLevel?.mealAllowance),
        yearlyMealAllowance: parseInt(selectedLevel?.mealAllowance) * 12,
        monthlyPhoneAndDataAllowance: parseInt(
          selectedLevel?.phoneAndDateAllowance
        ),
        yearlyPhoneAndDataAllowance:
          parseInt(selectedLevel?.phoneAndDateAllowance) * 12,
        medicalPremium: parseInt(selectedLevel?.medicalInsurance),

        monthlyPerformanceBonus: parseInt(perfBonus),
        yearlyPerformanceBonus: parseInt(perfBonus) * 12,
        monthlyFestivalBonus: parseInt(basic / 12),
        yearlyFestivalBonus: parseInt(basic),
        eligibleForPF: isPF,
        monthlyTotal: monthTotal,
        annualCTC: totalCTC,
      };

      if (isPF) {
        body = {
          ...body,
          monthlyProvidentFund: parseInt(basic * (12 / 100)),
          yearlyProvidentFund: parseInt(basic * (12 / 100)) * 12,
          monthlyGratuity: parseInt(basic * (4.81 / 100)),
          yearlyGratuity: parseInt(basic * (4.81 / 100)) * 12,
        };
      }

      let res = await axios.post(
        `${BASE_URL}/api/v1/hrmgmt/salarystructure/createNewSalaryStructure`,
        body,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      );

      setModal(false);

      if (res.data.status === true) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(function () {
          navigate(`/viewemployees/${employee.split(",")[0]}`);
        });
      }
    } catch (err) {
      setModal(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: err.response.data.error,
      });
    }
  };

  return (
    <div className="create-salary-structure">
      <Button navigateTo="/viewemployees" />

      <div className="row justify-content-start mt-5">
        <div className="col-4">
          <label>Employee Id</label>
          <input
            type="text"
            className="form-control"
            value={employee}
            onChange={(e) => setEmployee(e.target.value)}
          />
        </div>

        <div className="col-4">
          <label>Employee Full Name</label>
          <input
            type="text"
            readOnly
            value={fullName}
            className="form-control"
          />
        </div>

        <div className="col-4">
          <label>Financial Year</label>
          <select
            className="form-control"
            value={financialYear}
            onChange={(e) => setFinancialYear(e.target.value)}
          >
            <option value="">Select Financial Year</option>
            {generateFinancialYears()}
          </select>
        </div>
      </div>

      <div className="row justify-content-start mt-3 align-items-center">
        <div className="col-1">
          <button className="create-salary-structure__btn" onClick={checkCTC}>
            Check
          </button>
        </div>
        <div className="col-6 ml-2">
          {createEnabled ? (
            <em style={{ color: "green" }}>{message}</em>
          ) : (
            <em style={{ color: "red" }}>{message}</em>
          )}
        </div>
      </div>

      <div className="row justify-content-start mt-3">
        <div className="col-4">
          <label>Organization Role</label>
          <input type="text" readOnly value={role} className="form-control" />
        </div>

        <div className="col-4">
          <Form.Group controlId="Job Role">
            <Form.Label>Job Level</Form.Label>
            <Form.Control
              as="select"
              required
              value={jobLevel}
              onChange={(e) => {
                setJobLevel(e.target.value);
                setDesignation(e.target.value.split(",")[1]);
              }}
            >
              <option value="">Select a Level</option>
              {jobLevels?.map((level, index) => {
                return (
                  <option
                    key={index}
                    value={`${level?.level},${level?.designation}`}
                  >
                    {level?.level}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </div>

        <div className="col-4">
          <label>Designation</label>
          <input
            type="text"
            value={designation}
            readOnly
            className="form-control"
          />
        </div>
      </div>

      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <div className="row justify-content-center create-salary-structure__headers">
          <div className="col-6">Component</div>
          <div className="col-3">Monthly</div>
          <div className="col-3">Yearly</div>
        </div>

        <br />

        <div className="row create-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Basic & HRA</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center create-salary-structure__components">
            <h5>Basic Salary</h5>
            <h5>HRA</h5>
          </div>

          <div className="col-3">
            <input
              type="text"
              className="form-control"
              value={basic}
              onChange={(e) => setBasic(e.target.value)}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={parseInt(basic * (5 / 10))}
            />
          </div>

          <div className="col-3">
            <input
              type="text"
              className="form-control"
              readOnly
              value={basic * 12}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={parseInt(basic * (5 / 10)) * 12}
            />
          </div>
        </div>

        <hr />

        <div className="row create-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Allowances</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center create-salary-structure__components">
            <h5>Personal Allowance</h5>
            <h5>Uniform and Laundry Allowance</h5>
            <h5>Meal Allowance</h5>
            <h5>Travel Allowance</h5>
            <h5>Phone & Date Allowance</h5>
          </div>

          <div className="col-3">
            <input
              type="text"
              className="form-control"
              value={personalAllowance}
              onChange={(e) => setPersonalAllowance(e.target.value)}
            />
            <br />
            <input
              type="text"
              className="form-control"
              value={uniformNLaundryAllowance}
              onChange={(e) => setUniformNLaundryAllowance(e.target.value)}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={selectedLevel?.mealAllowance}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={selectedLevel?.travelAllowance}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={selectedLevel?.phoneAndDateAllowance}
            />
          </div>

          <div className="col-3">
            <input
              type="text"
              className="form-control"
              readOnly
              value={personalAllowance * 12}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={uniformNLaundryAllowance * 12}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={selectedLevel?.mealAllowance * 12}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={selectedLevel?.travelAllowance * 12}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={selectedLevel?.phoneAndDateAllowance * 12}
            />
          </div>
        </div>

        <hr />

        <div className="row create-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Retirals & Insurance</em>
            <FormGroup
              style={{
                display: "flex",
                justifyContent: "center",
                width: "200px",
                margin: "auto",
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={isPF}
                    onChange={() => setIsPF(!isPF)}
                  />
                }
                label="PF"
              />
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center create-salary-structure__components">
            {isPF && (
              <>
                <h5>Provident Fund</h5>
                <h5>Gratuity</h5>
              </>
            )}
            <h5>Medical Insurance Premium</h5>
          </div>

          <div className="col-3">
            {isPF && (
              <>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={parseInt(basic * (12 / 100))}
                />
                <br />
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={parseInt(basic * (4.81 / 100))}
                />
              </>
            )}
          </div>

          <div className="col-3">
            {isPF && (
              <>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={parseInt(basic * (12 / 100)) * 12}
                />
                <br />
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={parseInt(basic * (4.81 / 100)) * 12}
                />
                <br />
              </>
            )}

            <input
              type="text"
              className="form-control"
              readOnly
              value={selectedLevel?.medicalInsurance}
            />
          </div>
        </div>

        <hr />

        <div className="row create-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Bonus</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center create-salary-structure__components">
            <h5>Performance Bonus</h5>
            <h5>Yearly Festival Bonus</h5>
          </div>

          <div className="col-3">
            <input
              type="text"
              className="form-control"
              value={perfBonus}
              onChange={(e) => setPerfBonus(e.target.value)}
            />
          </div>

          <div className="col-3">
            <input
              type="text"
              className="form-control"
              readOnly
              value={perfBonus * 12}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={basic}
            />
          </div>
        </div>

        <hr />

        <div className="row create-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Total</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center create-salary-structure__components">
            <h5>Monthly Total</h5>
            <h5>Annual CTC</h5>
          </div>

          <div className="col-6">
            <input
              type="text"
              className="form-control"
              readOnly
              value={monthTotal}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={totalCTC}
            />
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-6 text-center">
            <button
              className="create-salary-structure__btn"
              onClick={() => setModal(true)}
              disabled={!createEnabled}
              style={{ backgroundColor: createEnabled ? "" : "grey" }}
            >
              CREATE
            </button>
          </div>
        </div>
      </Paper>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={() => setModal(false)}
        closeAfterTransition
      >
        <Fade in={modal}>
          <div className="paper">
            <h4 className="text-center">Are you sure ?</h4>
            <br />
            <div className="row container-fluid justify-content-center">
              <div className="col-6 text-right">
                <button className="modal__btns" onClick={createSalaryStructure}>
                  Yes
                </button>
              </div>
              <div className="col-6 text-left">
                <button
                  className="modal__btns"
                  onClick={() => setModal(false)}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default CreateSalaryStructure;
