import axios from 'axios';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../utils/config/url';
import {
    Modal,
    Backdrop,
    Fade,
    Switch,
    createMuiTheme,
    ThemeProvider,
} from "@material-ui/core";
import './UnitInspection.scss';
import { useStyles } from '../../utils/ModalStyles';
import Swal from 'sweetalert2';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#757ce8",
            main: "#EE4B46",
            dark: "#002884",
            contrastText: "#fff",
        },
    },
});

const UnitInspection = () => {

    const classes = useStyles();

    const unitName = useParams().unitname;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [checkList, setCheckList] = useState('');
    const [message, setMessage] = useState('')
    const [civil, setCivil] = useState([]);
    const [plumbing, setPlumbing] = useState([]);
    const [electrical, setElectrical] = useState([]);
    const [other, setOther] = useState([])
    const [list, setList] = useState([civil, plumbing, electrical, other])
    const [status, setStatus] = useState('')
    const [serial, setSerial] = useState('')
    const [unit, setUnit] = useState({})
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);

    var width = window.innerWidth;

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/Configuration/Unit/getUnitByUnitName/${unitName}`,
            { headers: { Authorization: `bearer ${userInfo.token}` } })
            .then((response) => {

                setUnit(response.data.unit);

            })
    }, [])

    const getUnitByUnitName = () => {

        return axios.get(`${BASE_URL}/api/v1/Configuration/Unit/getUnitByUnitName/${unitName}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response);
                let unit = response?.data?.unit
                return {
                    siteCode: unit.siteCode,
                    phaseCode: unit.phaseCode,
                    bhk: unit.bhk,
                    unitName: unit.unitName
                }

            })
    }

    useEffect(() => {

        // if (unit?.status === 'Booking In Progress' || unit?.status === 'Available' || unit?.status === 'Booking Initiated') {
        //     setMessage(`Unit is not yet Alloted. Can't Initiate Inspection.`)
        //     return;
        // }

        getUnitInspectionChecklist();

    }, [])

    const getUnitInspectionChecklist = () => {

        axios.get(`${BASE_URL}/api/v1/Configuration/UnitInspection/getUnitInspectionChecklist/${unitName}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {

                if (response.data.status === true) {
                    setCheckList(response.data.unitInspectionList)
                    setStatus(response.data.unitInspectionList.status);
                    setMessage('')
                    let list = response.data.unitInspectionList.listItems;
                    setPlumbing([]);
                    setElectrical([])
                    setCivil([])
                    setOther([])

                    list.forEach((item) => {

                        if (item.category === 'Plumbing') {

                            setPlumbing((prev) => [...prev, item])
                        }

                        else if (item.category === 'CivilWork') {
                            setCivil((prev) => [...prev, item])
                        }

                        else if (item.category === "Electrical") {
                            setElectrical((prev) => [...prev, item])
                        }

                        else if (item.category === "Other") {
                            setOther((prev) => [...prev, item])
                        }

                    })
                } else {

                    getUnitInspectionListType();

                }
            })
    }


    const getUnitInspectionListType = () => {

        axios.get(`${BASE_URL}/api/v1/Configuration/UnitInspection/getUnitInspectionListType?siteCode=${unit?.siteCode}&phaseCode=${unit?.phaseCode}&bhk=${unit?.bhk}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                setMessage('')
            })
            .catch((err) => {
                setMessage(err.response.data.error.message)
            })
    }


    useEffect(() => {

        setList([civil, electrical, plumbing, other])

    }, [civil, electrical, plumbing, other])


    const initiateUnitInspection = async () => {

        let body = await getUnitByUnitName();

        axios.post(`${BASE_URL}/api/v1/Configuration/UnitInspection/initiateUnitInspection`,
            body,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response);

                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        getUnitInspectionChecklist();
                    })
                }
            })
            .catch((err) => {

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }

    const markUnitInspection = () => {

        axios.put(`${BASE_URL}/api/v1/Configuration/UnitInspection/markUnitInspection`, {
            unitName,
            serial,
            inspectedByUserName: userInfo.userName,
            inspectedByUserFullName: userInfo.userFullName
        }, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {

                setModal(false);
                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        getUnitInspectionChecklist();
                    })
                }
            })
            .catch((err) => {
                setModal(false)
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }


    const markPhaseReady = () => {

        axios.post(`${BASE_URL}/api/v1/Configuration/UnitInspection/markUnitReady`, {
            unitName,
            engineerSignOffUserName: userInfo.userName,
            engineerSignOffUserFullName: userInfo.userFullName
        }, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {

                setModal2(false)
                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        getUnitInspectionChecklist();
                    })
                }

            }).catch((err) => {

                setModal2(false)
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })

            })
    }

    const dateFormatting = (input, type) => {
        let d = new Date(input)
        let date = d.getDate()
        if (date < 10) {
            date = `0${date}`
        }
        let m = d.getMonth() + 1
        if (m < 10) {
            m = `0${m}`
        }
        let y = d.getFullYear()

        let final;

        if (type === 'original') {
            final = `${y}-${m}-${date}`
            return final
        } else {
            final = `${date}/${m}/${y}`
            return final
        }

    }

    return (

        <div className='inspection'>
            <ThemeProvider theme={theme}>

                <br />

                {
                    message && <p><em style={{ color: "#EE4B46", fontSize: '2 ,0px' }}>{message}</em></p>
                }

                <br />
                <br />

                {
                    checkList
                        ?
                        <div className='row justify-content-center'>


                            <div className='col-12 row justify-content-center mb-4'>

                                <div className='col-3'>
                                    <label>Unit Name</label>
                                    <input type="text" className='form-control' readOnly value={checkList.unitName} />
                                </div>

                                <div className='col-3'>
                                    <label>Unit Ready ?</label>
                                    <input type="text" className='form-control' readOnly value={checkList.isUnitReady} />
                                </div>

                            </div>

                            <div className='col-12 row justify-content-center mb-4'>

                                <div className='col-3'>
                                    <label>List Type Id</label>
                                    <input type="text" className='form-control' readOnly value={checkList.listTypeId ? checkList.listTypeId : 'N/A'} />
                                </div>

                                <div className='col-3'>
                                    <label>Status</label>
                                    <input type="text" className='form-control' readOnly value={checkList.status ? checkList.status : 'N/A'} />
                                </div>
                            </div>

                            <div className='col-12 row justify-content-center mb-5'>

                                <div className='col-3'>
                                    <label>Unit Ready Date</label>
                                    <input type="text" className='form-control' readOnly value={checkList.unitReadyDate ? dateFormatting(checkList.unitReadyDate) : 'N/A'} />
                                </div>

                                <div className='col-3'>
                                    <label>Engineer Sign Off By</label>
                                    <input type="text" className='form-control' readOnly value={checkList.engineerSignOffUserFullName ? checkList.engineerSignOffUserFullName : 'N/A'} />
                                </div>

                            </div>



                            {
                                list.map((l, index) => {

                                    if (l.length !== 0) {

                                        return <div key={index} className={width < 930 ? 'col-11' : 'col-10'}>

                                            <MaterialTable
                                                data={l}
                                                title={l[0].category}

                                                columns={
                                                    [
                                                        { title: 'Category', field: 'category' },
                                                        { title: 'Description ', field: 'description' },
                                                        { title: 'Is Completed ? ', field: 'isCompleted' },
                                                        {
                                                            title: 'Completion Date ',
                                                            render: (rowData) => { return rowData.completionDate ? dateFormatting(rowData.completionDate) : 'N/A' }
                                                        },
                                                        { title: 'Inspected By ', render: (rowData) => (rowData.inspectedByUserFullName ? rowData.inspectedByUserFullName : 'N/A') }
                                                    ]
                                                }

                                                options={{
                                                    search: true,
                                                    paging: true,
                                                    pageSize: 20,
                                                    emptyRowsWhenPaging: false,
                                                    pageSizeOptions: [5, 10, 20, 30],
                                                    actionsColumnIndex: -1,
                                                    headerStyle: {
                                                        backgroundColor: '#EE4B46',
                                                        color: '#fff',
                                                    }
                                                }}

                                                actions={[
                                                    (rowData) => ({
                                                        icon: () => (
                                                            <Switch checked={rowData.isCompleted} color="primary" />
                                                        ),
                                                        tooltip: rowData.isCompleted ? "" : "Mark Completed",
                                                        disabled: rowData.isCompleted,
                                                        onClick: (event, rowData) => {
                                                            setSerial(rowData.serial)
                                                            setModal(true)
                                                        }
                                                    }),


                                                ]}

                                            ></MaterialTable>

                                            <br />
                                            <br />

                                        </div>

                                    }
                                })
                            }


                            <div className='col-12 row justify-content-center mb-5'>
                                <button className='inspection__btn'
                                    disabled={status === 'InspectionDone'}
                                    style={{
                                        backgroundColor: status === 'InspectionDone' && 'grey',
                                        color: status === 'InspectionDone' && 'white'
                                    }}
                                    onClick={() => setModal2(true)}
                                >Unit Ready</button>
                            </div>
                        </div>
                        :
                        <div className='row justify-content-center'>
                            <button className='inspection__btn'
                                // disabled={message ? true : false}
                                // style={{ backgroundColor: message ? 'grey' : '#EE4B46' }}
                                onClick={initiateUnitInspection}
                            >Initiate Unit Inspection</button>
                        </div>
                }



                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={modal}
                    onClose={() => setModal(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={modal}>
                        <div className={classes.paper}>
                            <h6>Are you sure you want to mark this complete ?</h6>
                            <br />
                            <div className="row container-fluid justify-content-center">
                                <div className="col-6 text-right">
                                    <button className="modal__btns"
                                        onClick={markUnitInspection}
                                    >
                                        Yes
                                    </button>
                                </div>
                                <div className="col-6 text-left">
                                    <button
                                        className="modal__btns"
                                        onClick={() => setModal(false)}
                                        style={{
                                            backgroundColor: "white",
                                            color: "black",
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={modal2}
                    onClose={() => setModal2(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={modal2}>
                        <div className={classes.paper}>
                            <h6>{`Are you sure you want to mark ${unitName} Phase ready ?`}</h6>
                            <br />
                            <div className="row container-fluid justify-content-center">
                                <div className="col-6 text-right">
                                    <button className="modal__btns"
                                        onClick={markPhaseReady}
                                    >
                                        Yes
                                    </button>
                                </div>
                                <div className="col-6 text-left">
                                    <button
                                        className="modal__btns"
                                        onClick={() => setModal2(false)}
                                        style={{
                                            backgroundColor: "white",
                                            color: "black",
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>

            </ThemeProvider >
        </div>
    )
}

export default UnitInspection