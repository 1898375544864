import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import Button from '../../components/Button/Button';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useNavigate, useParams } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const CatalogueItems = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const navigate = useNavigate()
    const categoryCode = useParams().categoryCode;

    const [catalogueList, setCatalogueList] = useState([]);
    const [subcategory, setSubcategory] = useState('')
    const [subCategoryList, setSubCategoryList] = useState([])

    useEffect(() => {

        let url = `${BASE_URL}/api/v1/stockmgmtapi/catalougeItem/getListOfCatalogueItem?categoryCode=${categoryCode}`;

        let filter = '';

        if (subcategory) {
            filter = `&subCategoryCode=${subcategory}`
        }

        url += filter

        axios.get(url,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status === true) {
                    setCatalogueList(response.data.CatelogueItem)
                } else {
                    setCatalogueList([])
                }
            })
    }, [subcategory])

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/stockmgmtapi/catSubCategory/getListOfCatSubCategory?parentCategoryCode=${categoryCode}`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response)
                if (response.data.status === true) {
                    setSubCategoryList(response.data.CatSubCategory)
                } else {
                    setSubCategoryList([])
                }
            })
    }, [])


    return (
        <div className='mt-5'>

            <MaterialTable
                data={catalogueList}
                title="List Of Catalogue Item"

                columns={[
                    {
                        title: "Catalogue Item Code",
                        field: "catalogueItemCode",
                        render: (rowData) => {
                            return <a href={`/listOfCatalogueItem/${rowData?.catalogueItemCode}`}
                                style={{
                                    color: '#EE4B46',
                                    fontWeight: 700
                                }}
                            >{rowData?.catalogueItemCode}</a>
                        }
                    },
                    {
                        title: "Catalogue Item Name",
                        field: "catalogueItemName"
                    },
                    {
                        title: "SubCategory Name",
                        field: "subCategoryName"
                    },
                    {
                        title: "Orderable",
                        render: (rowData) => (rowData.isOrderable ? 'Yes' : 'No')
                    },
                    {
                        title: "Is Upgrade Item",
                        render: (rowData) => (rowData.isUpgradeItem ? 'Yes' : 'No')
                    },

                ]}

                // actions={[
                //     {
                //         icon: 'remove_red_eye',
                //         tooltip: 'View Catalogue Item',
                //         onClick: (event, rowData) => {
                //             navigate(`/listOfCatalogueItem/${rowData.catalogueItemCode}`)
                //         }
                //     },
                // ]}


                options={{
                    search: window.innerWidth < 700 ? false : true,
                    searchFieldStyle: {
                        width: 180
                    },
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#EE4B46',
                        color: '#fff',
                    }
                }}


                components={{
                    Toolbar: (props) => (
                        <div className="filters text-center">
                            <MTableToolbar {...props} />

                            <FormControl variant="standard"
                                className="formControl"
                                style={{
                                    margin: '0px 13px',
                                    marginTop: "-65px",
                                    width: "120px",
                                }}
                            >
                                <InputLabel id="demo-simple-select-standard-label"
                                    style={{ fontSize: '15px' }}>
                                    Subcategory
                                </InputLabel>
                                <Select labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={subcategory}
                                    onChange={(e) => {
                                        setSubcategory(e.target.value)
                                    }}
                                    style={{
                                        fontSize: '15px',
                                        textAlign: 'left'
                                    }}
                                    className="selectEmpty"
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value="">
                                        Sub Category
                                    </MenuItem>
                                    {subCategoryList.map((s) => (
                                        <MenuItem key={s.subCategoryCode} value={s.subCategoryCode}>
                                            {s.subCategoryName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </div>
                    ),
                }}

            ></MaterialTable>

        </div>
    )

}

export default CatalogueItems