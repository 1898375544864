import React, { useState, useEffect } from "react";
import "./OnboardEmpHome.scss";
import logo from "../../assets/images/westroad_logo.jpg";
import { PiSignOutBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import PersonalDetails from "../../components/SelfOnboard/PersonalDetails";
import UploadGovtId from "../../components/SelfOnboard/UploadGovtId";
import UploadEducationDetails from "../../components/SelfOnboard/UploadEducationDetails";
import UploadWorkHistory from "../../components/SelfOnboard/UploadWorkHistory";
import UploadSignedOfferLetter from "../../components/SelfOnboard/UploadSignedOfferLetter";
import Swal from "sweetalert2";

const OnboardEmpHome = () => {
  const onBoardInfo = JSON.parse(localStorage.getItem("onBoardInfo"));
  const { employeeId } = onBoardInfo?.employee;
  const [active, setActive] = useState("emp-dtls");
  const navigate = useNavigate();
  const logout = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to logout?",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#ee4b46",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/onboardLogin");
      }
    });
  };

  

  // console.log(employee, "employee")

  

  return (
    <div className="onboardhome">
      <div className="onboardhome__header">
        <div className="onboardhome__header__left">
          <img src={logo} alt="Westroad Logo" />
        </div>
        <div className="onboardhome__header__right">
          <div className="onboardhome__header__right__name">
            {onBoardInfo?.employee?.employeeFullName}
          </div>
          <div className="onboardhome__header__right__logout">
            <PiSignOutBold onClick={logout} />
          </div>
        </div>
      </div>
      <div className="onboardhome__content">
        <button
          className={`${active === "emp-dtls" ? "bg-red" : "bg-dark"}`}
          onClick={() => setActive("emp-dtls")}
        >
          Personal Details
        </button>
        <button
          className={`${active === "govtId" ? "bg-red" : "bg-dark"}`}
          onClick={() => setActive("govtId")}
        >
          Upload Govt Id
        </button>
        <button
          className={`${active === "edu" ? "bg-red" : "bg-dark"}`}
          onClick={() => setActive("edu")}
        >
          Upload Education Details
        </button>
        <button
          className={`${active === "work" ? "bg-red" : "bg-dark"}`}
          onClick={() => setActive("work")}
        >
          Upload Work History
        </button>
        <button
          className={`${active === "signedOfferLtr" ? "bg-red" : "bg-dark"}`}
          onClick={() => setActive("signedOfferLtr")}
        >
          Upload Signed Offer Letter
        </button>
      </div>
      {active === "emp-dtls" && (
        <PersonalDetails
          onBoardInfo={onBoardInfo}
          setActive={setActive}
          employeeId={employeeId}
        />
      )}
      {active === "govtId" && (
        <UploadGovtId setActive={setActive} employeeId={employeeId} onBoardInfo={onBoardInfo} />
      )}
      {active === "edu" && <UploadEducationDetails setActive={setActive} employeeId={employeeId} onBoardInfo={onBoardInfo} />}
      {active === "work" && <UploadWorkHistory setActive={setActive} employeeId={employeeId} onBoardInfo={onBoardInfo} />}
      {active === "signedOfferLtr" && (
        <UploadSignedOfferLetter setActive={setActive} employeeId={employeeId} onBoardInfo={onBoardInfo} />
      )}
    </div>
  );
};

export default OnboardEmpHome;
