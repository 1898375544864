import React, { useEffect, useState } from "react";
import "./EditCarParkingType.scss";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import Button from "../../components/Button/Button";
import Swal from "sweetalert2";

const EditCarParkingType = () => {
  const siteCode = useParams().siteCode;
  const carParkingTypeCode = useParams().carParkingTypeCode;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + userInfo.token;
  const navigate = useNavigate();

  const [sname, setSname] = useState("");
  const [phases, setPhases] = useState([]);
  const [pn, setPn] = useState("");
  const [pc, setPc] = useState("");
  const [cpt, setCpt] = useState("");
  const [cptc, setCptc] = useState("");
  const [price, setPrice] = useState("");
  const [total, setTotal] = useState("");
  const [desc, setDesc] = useState("");
  const [parkingBooked, setParkingBooked] = useState("");
  const [parkingCreated, setParkingCreated] = useState("");
  const [GrossPrice, setGrossPrice] = useState("");
  const [GST, setGST] = useState("");
  const [GSTPercentage, setGSTPercentage] = useState("");

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/Configuration/CarParkingType/getCarParkingTypeByCode?carParkingTypeCode=${carParkingTypeCode}&siteCode=${siteCode}`,
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        const data = response.data.carParkingType;
        setPn(data.phaseName);
        setPc(data.phaseCode);
        setCpt(data.carParkingTypeName);
        setCptc(data.carParkingTypeCode);
        setGrossPrice(data.carParkingTypeGrossPrice);
        setTotal(data.carParkingTypeTotalCount);
        setDesc(data.parkingTypeDescription);
        setSname(data.siteName);
        setParkingBooked(data.carParkingBookedCount);
        setParkingCreated(data.carParkingCreatedCount);
        setGST(data.carParkingGST);
        setGSTPercentage(data.carParkingGSTPercentage);
        setPrice(data.carParkingPrice);
      })
      .then(() => {
        axios
          .get(
            `${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`,
            { headers: { Authorization: Token } }
          )
          .then((response) => {
            setPhases(response.data.site.phases);
          });
      });
  }, []);

  const handleEditCarParkingType = (e) => {
    e.preventDefault();

    axios
      .put(
        `${BASE_URL}/api/v1/Configuration/CarParkingType/updateCarParkingType`,
        {
          carParkingTypeCode: cptc,
          siteCode: siteCode,
          carParkingTypeGrossPrice: GrossPrice,
          carParkingTypeTotalCount: total,
        },
        { headers: { Authorization: Token } }
      )
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            navigate(-1);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.error,
          });
        }
      });
  };

  return (
    <div className="editcarparkingtype">
      <Button navigateTo={1} />

      <div className="editcarparkingtype__heading">
        <h3>View/Edit Car Parking Type</h3>
      </div>

      <form
        className="addcarparkingtype__form"
        onSubmit={(e) => handleEditCarParkingType(e)}
      >
        <div className="row container-fluid justify-content-center">
          <div className="col-5">
            <label>Site Name</label>
            <input
              className="form-control"
              readOnly
              type="text"
              value={sname}
            />
          </div>

          <div className="col-5">
            <label>Site Code</label>
            <input
              className="form-control"
              readOnly
              type="text"
              value={siteCode}
            />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className="col-5">
            <Form.Group controlId="bhk">
              <Form.Label>Phase Name</Form.Label>
              <Form.Control required as="input" value={pn} readOnly />
            </Form.Group>
          </div>

          <div className="col-5">
            <label>Phase Code</label>
            <input className="form-control" readOnly value={pc} />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className="col-5">
            <label>Car Parking Type</label>
            <input className="form-control" type="text" value={cpt} readOnly />
          </div>

          <div className="col-5">
            <label>Car Parking Type Code</label>
            <input className="form-control" readOnly type="text" value={cptc} />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className="col-5">
            <label>Total Count</label>
            <input
              className="form-control"
              required
              type="number"
              value={total}
              onChange={(e) => setTotal(e.target.value)}
            />
          </div>
        </div>
        <br />
        <div className="row container-fluid justify-content-center">
          <div className="col-5">
            <label>Gross Price</label>
            <input
              className="form-control"
              type="number"
              required
              value={GrossPrice}
              onChange={(e) => setGrossPrice(e.target.value)}
            />
          </div>
          <div className="col-5">
            <label>GST</label>
            <input
              className="form-control"
              type="number"
              readOnly
              value={GST}
            />
          </div>
        </div>
        <br/>
        <div className="row container-fluid justify-content-center">
          <div className="col-5">
            <label>GST Percentage</label>
            <input
              className="form-control"
              type="number"
              readOnly
              value={GSTPercentage}
            />
          </div>
          <div className="col-5">
            <label>Price</label>
            <input
              className="form-control"
              type="number"
              readOnly
              value={price}
            />
          </div>
        </div>
        <br />
        <div className="row container-fluid justify-content-center">
          <div className="col-5">
            <label>Parking Booked</label>
            <input
              className="form-control"
              type="number"
              value={parkingBooked}
              readOnly
            />
          </div>

          <div className="col-5">
            <label>Parking Created</label>
            <input
              className="form-control"
              type="number"
              value={parkingCreated}
              readOnly
            />
          </div>
        </div>

        <br />
        <br />

        <div className="row container-fluid justify-content-center">
          <div className="col-10 addcarparkingtype__btns">
            <button type="submit">Update</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditCarParkingType;
