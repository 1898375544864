import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {BASE_URL} from "../../utils/config/url";
import axios from "axios";
import {Modal, Backdrop, Fade} from "@material-ui/core";
import {Form} from "react-bootstrap";
import Swal from "sweetalert2";
//style for modal
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const CloseTask = ({taskID, open2, setOpen2, setChange}) => {
  const classes = useStyles();
  const [reason, setReason] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  //
  const handleClose = () => {
    setOpen2(!open2);
  };
  //
  const closeTask = (e) => {
    e.preventDefault();

    axios
      .post(
        `${BASE_URL}/api/v1/hrmgmt/task/closeTaskByTaskId`,
        {
          taskID: taskID,
          userName: userInfo.userName,
          userFullName: userInfo.userFullName,
          closedReason: reason,
        },
        {headers: {Authorization: `bearer ${userInfo.token}`}}
      )
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          });
          setOpen2(false);
          setChange((prev) => !prev);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      });
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open2}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open2}>
          <div className={classes.paper}>
            <Form.Group className="mb-3" controlId="status">
              <Form.Label>Closure Reason</Form.Label>
              <Form.Control as="select" onChange={(e) => setReason(e.target.value)}>
                <option>---Select a Reason---</option>
                <option value="Task Done">Task Done</option>
                <option value="Task Cancelled">Task Cancelled</option>
                <option value="Dependency Issue">Dependency Issue</option>
              </Form.Control>
            </Form.Group>
            <div className="text-center mt-4">
              <button className="btn btn-secondary btn-user" disabled={!reason} onClick={closeTask}>
                Close Task
              </button>
              &nbsp;&nbsp;
              <button
                className="btn btn-secondary btn-user"
                onClick={handleClose}
                style={{
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default CloseTask;
