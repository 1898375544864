import axios from 'axios';
import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../utils/config/url';
import '../../components/Tabs/Tabs.scss';
import { FormControl } from '@mui/material';
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useStyles } from '../../utils/ModalStyles';


const DebitChequeBook = () => {

    const classes = useStyles()
    const [cheques, setCheques] = useState([]);
    const [category, setCategory] = useState('')
    const [subCategory, setSubCategory] = useState()
  
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  
    useEffect(() => {
  
      getListOfCheque()
  
    }, [])
  
  
    const getListOfCheque = () => {
  
      axios.get(`${BASE_URL}/api/v1/FinanceMgmt/Cheque/getlistofcheque?paymentType=Debit`, {
        headers: { Authorization: `bearer ${userInfo.token}` }
      })
        .then((response) => {
          // console.log(response)
          setCheques(response.data.chequeList)
        })
    }
  
  
    useEffect(() => {
  
      setCheques([])
  
      if (category === '') {
        setSubCategory('')
        return getListOfCheque()
      }
  
      // console.log(category, subCategory, status)
      let params = [];
  
      if (category) {
        params.push('paymentCategory')
      }
      if (subCategory) {
        params.push('paymentSubCategory')
      }
  
  
      let url = []
      params.forEach((p, index) => {
        if (p === 'paymentCategory') {
          url.push(`&${p}=${category}`)
        }
        if (p === 'paymentSubCategory') {
          url.push(`&${p}=${subCategory}`)
        }
      })
  
      let final = url.join('')
  
      axios.get(`${BASE_URL}/api/v1/FinanceMgmt/Cheque/getlistofcheque?paymentType=Debit${final}`, {
        headers: { Authorization: `bearer ${userInfo.token}` }
      })
        .then((response) => {
          // console.log(response)
          if (response.data.chequeList) {
            setCheques(response.data.chequeList)
          } else {
            setCheques([])
          }
        })
  
    }, [category, subCategory])
  
    const reset = () => {
      setCategory('')
      setSubCategory('')
    }  
    
  
    const dateFormatting = (date) => {
  
      let str = new Date(date).toLocaleString('en-US', { timeZone: 'Asia/Jakarta' });
  
      let d = new Date(str).getDate();
      if (d < 10) {
        d = `0${d}`
      }
      let m = new Date(str).getMonth() + 1;
      if (m < 10) {
        m = `0${m}`
      }
      let y = new Date(str).getFullYear();
      return `${d}-${m}-${y}`
    }
  
    return (
      <div>
        <br />
  
        <h4>Debit Cheques</h4>
  
        <MaterialTable
          data={cheques}
          title=""
          columns={[
            { title: "Cheque No.", field: "chequeNo" },
            {title: "MICR No.", field: "MICRNo"},
            { title: "Bank", field: "chequeBankName" },
            {
                title: "Cheque Date",
                render: (rowData) => {
    
                  let res = '';
                  if (rowData.chequeDate) {
                    res = dateFormatting(rowData.chequeDate)
                  }
    
                  return !rowData.chequeDate ? "" : res
                },
                customSort: (a, b) => (a.chequeDate < b.chequeDate ? -1 : 1),
                defaultSort: "desc",
              },
            { title: "Amount", field: "chequeAmount" },
            { title: "Cheque Status", field: "chequeStatus" },
            {
              title: "Payment Category",
              field: "paymentCategory",
            },
            {
              title: "Payment Sub Category",
              field: "paymentSubCategory",
            },
          ]}
          options={{
            search: window.innerWidth < 700 ? false : true,
            searchFieldStyle: {
              width: 200,
            },
            paging: true,
            pageSize: 20,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 20, 30],
            headerStyle: {
              backgroundColor: "#EE4B46",
              color: "#fff",
              whiteSpace: "nowrap",
            },
          }}
          components={{
            Toolbar: (props) => (
              <div className="filters text-left">
                <MTableToolbar {...props} />
  
                <FormControl variant="standard"
                  className={classes.formControl}
                  style={{
                    margin: "0px 10px",
                    marginTop: "-65px",
                    width: "150px",
                  }}
                >
                  <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>
                    Category
                  </InputLabel>
                  <Select llabelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                    value={category}
                    onChange={(e) => {
                      setCategory(e.target.value);
                      setSubCategory("");
                    }}
                    className={classes.selectEmpty}
                    style={{
                      fontSize: "13px",
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">Category</MenuItem>
                    <MenuItem value="CustomerPayment">Customer Payment</MenuItem>
                    <MenuItem value="OtherPayment">Other Payment</MenuItem>
                  </Select>
                </FormControl>
  
                <FormControl variant="standard"
                  // className={classes.formControl}
                  style={{
                    margin: "0px 10px",
                    marginTop: "-65px",
                    width: "150px",
                  }}
                >
                  <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>
                    Sub Category
                  </InputLabel>
                  {category === "CustomerPayment" ? (
                    <Select llabelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                      value={subCategory}
                      onChange={(e) => setSubCategory(e.target.value)}
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                      style={{
                        fontSize: "13px",
                        textAlign: "left",
                      }}
                      disabled={category === "" ? true : false}
                    >
                      <MenuItem value="">Sub Category</MenuItem>
                      <MenuItem value="BookingPayment">Booking Payment</MenuItem>
                      <MenuItem value="DemandPayment">Demand Payment</MenuItem>
                      <MenuItem value="FinalPayment">Final Payment</MenuItem>
                    </Select>
                  ) : (
                    <Select value={subCategory} onChange={(e) => setSubCategory(e.target.value)} className={classes.selectEmpty} inputProps={{ "aria-label": "Without label" }} disabled={category === "" ? true : false}>
                      <MenuItem value="">Sub Category</MenuItem>
                      <MenuItem value="MaterialsSold">Materials Sold</MenuItem>
                      <MenuItem value="OtherPayment">Other Payment</MenuItem>
                    </Select>
                  )}
                </FormControl>
  
                {window.innerWidth < 1000 && <br />}
  
                <FormControl className={classes.formControl}
                  style={{ marginTop: window.innerWidth < 1000 ? '-20px' : '-50px',marginLeft: '7px' }}>
                  <button
                    onClick={reset}
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      border: "1px solid black",
                      borderRadius: "20px",
                      padding: "5px",
                    }}
                  >
                    Reset Filter
                  </button>
                </FormControl>
              </div>
            ),
          }}
        ></MaterialTable>
      </div>
    );
  }
  
  export default DebitChequeBook