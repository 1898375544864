import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../utils/config/url";
import { jobLevels } from "../../../utils/config/jobLevel";
import { Form } from "react-bootstrap";
import axios from "axios";
import {Paper,Switch, FormGroup, FormControlLabel, Modal, Fade} from "@mui/material";
import Swal from "sweetalert2";
import "./DefineCTC.scss";


const DefineCTC = ({ setActiveStep }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const employeeInfo = JSON.parse(localStorage.getItem("onboardingdetails"));
  const [jobLevel, setJobLevel] = useState("");
  const [designation, setDesignation] = useState("");
  const [basic, setBasic] = useState(0);
  const [financialYear, setFinancialYear] = useState("");
  const [personalAllowance, setPersonalAllowance] = useState(0);
  const [uniformNLaundryAllowance, setUniformNLaundryAllowance] = useState(0);
  const [perfBonus, setPerfBonus] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState({});
  const [monthTotal, setMonthTotal] = useState(0);
  const [totalCTC, setTotalCTC] = useState(0);
  const [isPF, setIsPF] = useState(true);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let year = new Date().getFullYear();

    let month = new Date().getMonth();

    if (month + 1 < 4) {
      year = year - 1;
    }

    setFinancialYear(`${year}-${year + 1}`);
  }, []);

  useEffect(() => {
    // console.log(jobLevel)
    let lev = {};
    jobLevels.forEach((level) => {
      if (level.level === jobLevel.split(",")[0]) {
        lev = level;
      }
    });

    setSelectedLevel(lev);
  }, [jobLevel]);

  useEffect(() => {
    let month = 0;
    let year = 0;

    if (!isNaN(parseInt(basic))) {
      month += parseInt(basic);
      month += parseInt(basic * (5 / 10)); // HRA
      month += parseInt(selectedLevel?.mealAllowance) || 0;
      month += parseInt(selectedLevel?.travelAllowance) || 0;
      month += parseInt(selectedLevel?.phoneAndDateAllowance) || 0;

      year += parseInt(basic * 12);
      year += parseInt(basic * (5 / 10)) * 12;
      year += parseInt(selectedLevel?.mealAllowance) * 12 || 0;
      year += parseInt(selectedLevel?.travelAllowance) * 12 || 0;
      year += parseInt(selectedLevel?.phoneAndDateAllowance) * 12 || 0;
      year += parseInt(selectedLevel?.medicalInsurance) || 0; //medical Insurance
      year += parseInt(basic); //Year festival bonus
    }

    if (!isNaN(parseInt(isPF))) {
      month += parseInt(basic * (12 / 100)); //PF
      month += parseInt(basic * (4.81 / 100)); // Gratuity

      year += parseInt(basic * (12 / 100)) * 12;
      year += parseInt(basic * (4.81 / 100)) * 12;
    }

    if (!isNaN(parseInt(personalAllowance))) {
      month += parseInt(personalAllowance);
      year += parseInt(personalAllowance) * 12;
    }
    if (!isNaN(parseInt(uniformNLaundryAllowance))) {
      month += parseInt(uniformNLaundryAllowance);
      year += parseInt(uniformNLaundryAllowance) * 12;
    }

    if (!isNaN(parseInt(perfBonus))) {
      month += parseInt(perfBonus);
      year += parseInt(perfBonus) * 12;
    }

    setMonthTotal(month);
    setTotalCTC(year);
  }, [
    basic,
    personalAllowance,
    uniformNLaundryAllowance,
    perfBonus,
    isPF,
    selectedLevel,
  ]);

  const createSalaryStructure = async () => {
    if(!jobLevel){
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select a job level",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
      setModal(false);
      return
      
    }
    setLoading(true);
    try {
      let body = {
        employeeId: employeeInfo?.selectedEmployee?.employeeId,
        employeeFullName: employeeInfo?.selectedEmployee?.employeeFullName,
        employeeRole: employeeInfo?.employeeRole,
        onboardingId: employeeInfo?.onboardingId,

        jobLevel: jobLevel.split(",")[0],
        designation: jobLevel.split(",")[1],
        financialYear: financialYear,

        monthlyBasic: parseInt(basic),
        yearlyBasic: parseInt(basic) * 12,
        monthlyHRA: parseInt(basic * (5 / 10)),
        yearlyHRA: parseInt(basic * (5 / 10)) * 12,

        monthlyPersonalAllowance: parseInt(personalAllowance),
        yearlyPersonalAllowance: parseInt(personalAllowance) * 12,
        monthlyUniformNLaundryAllowance: parseInt(uniformNLaundryAllowance),
        yearlyUniformNLaundryAllowance: parseInt(uniformNLaundryAllowance) * 12,
        monthlyTravelAllowance: parseInt(selectedLevel?.travelAllowance),
        yearlyTravelAllowance: parseInt(selectedLevel?.travelAllowance) * 12,
        monthlyMealAllowance: parseInt(selectedLevel?.mealAllowance),
        yearlyMealAllowance: parseInt(selectedLevel?.mealAllowance) * 12,
        monthlyPhoneAndDataAllowance: parseInt(
          selectedLevel?.phoneAndDateAllowance
        ),
        yearlyPhoneAndDataAllowance:
          parseInt(selectedLevel?.phoneAndDateAllowance) * 12,
        medicalPremium: parseInt(selectedLevel?.medicalInsurance),

        monthlyPerformanceBonus: parseInt(perfBonus),
        yearlyPerformanceBonus: parseInt(perfBonus) * 12,
        monthlyFestivalBonus: parseInt(basic / 12),
        yearlyFestivalBonus: parseInt(basic),
        eligibleForPF: isPF,
        monthlyTotal: monthTotal,
        annualCTC: totalCTC,
        isOnboarding: true,
      };

      if (isPF) {
        body = {
          ...body,
          monthlyProvidentFund: parseInt(basic * (12 / 100)),
          yearlyProvidentFund: parseInt(basic * (12 / 100)) * 12,
          monthlyGratuity: parseInt(basic * (4.81 / 100)),
          yearlyGratuity: parseInt(basic * (4.81 / 100)) * 12,
        };
      }

      let res = await axios.post(
        `${BASE_URL}/api/v1/hrmgmt/salarystructure/createNewSalaryStructure`,
        body,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      );

      setLoading(false);

      setModal(false);

      if (res.data.status === true) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(function () {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      }
    } catch (err) {
      setLoading(false);
      setModal(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: err.response.data.error,
      });
    }
  };
  return (
    <div className="define-CTC">
      <div className="row justify-content-start">
        <div className="col-4">
          <label>Employee Id</label>
          <input
            type="text"
            className="form-control"
            value={employeeInfo?.selectedEmployee?.employeeId}
            readOnly
          />
        </div>

        <div className="col-4">
          <Form.Group controlId="Job Role">
            <Form.Label>Job Level</Form.Label>
            <Form.Control
              as="select"
              required
              value={jobLevel}
              onChange={(e) => {
                setJobLevel(e.target.value);
                setDesignation(e.target.value.split(",")[1]);
              }}
            >
              <option value="">Select a Level</option>
              {jobLevels?.map((level, index) => {
                return (
                  <option
                    key={index}
                    value={`${level?.level},${level?.designation}`}
                  >
                    {level?.level}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </div>

        <div className="col-4">
          <label>Designation</label>
          <input
            type="text"
            value={designation}
            readOnly
            className="form-control"
          />
        </div>
      </div>

      <div className="row justify-content-start mt-3">
        <div className="col-4">
          <label>Employee Full Name</label>
          <input
            type="text"
            readOnly
            value={employeeInfo?.selectedEmployee?.employeeFullName}
            className="form-control"
          />
        </div>

        <div className="col-4">
          <label>Organization Role</label>
          <input
            type="text"
            readOnly
            value={employeeInfo?.orgRole}
            className="form-control"
          />
        </div>

        <div className="col-4">
          <label>Financial Year</label>
          <input
            type="text"
            readOnly
            value={financialYear}
            className="form-control"
          />
        </div>
      </div>

      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <div className="row justify-content-around define-CTC__headers">
          <div className="col-4">Component</div>
          <div className="col-3">Monthly</div>
          <div className="col-3">Yearly</div>
        </div>

        <br />

        <div className="row define-CTC__titles">
          <div className="col-6 text-center">
            <em>Basic & HRA</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center define-CTC__components">
            <h5>Basic Salary</h5>
            <h5 style={{ marginTop: "2.5rem" }}>HRA</h5>
          </div>

          <div className="col-3 mr-5">
            <input
              type="text"
              className="form-control"
              value={basic}
              onChange={(e) => setBasic(e.target.value)}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={parseInt(basic * (5 / 10))}
            />
          </div>

          <div className="col-3">
            <input
              type="text"
              className="form-control"
              readOnly
              value={basic * 12}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={parseInt(basic * (5 / 10)) * 12}
            />
          </div>
        </div>

        <hr />

        <div className="row define-CTC__titles">
          <div className="col-6 text-center">
            <em>Allowances</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center define-CTC__components">
            <h5>Personal Allowance</h5>
            <h5 style={{ marginTop: "38.4px" }}>
              Uniform and Laundry Allowance
            </h5>
            <h5 style={{ marginTop: "38.4px" }}>Meal Allowance</h5>
            <h5 style={{ marginTop: "38.4px" }}>Travel Allowance</h5>
            <h5 style={{ marginTop: "38.4px" }}>Phone & Date Allowance</h5>
          </div>

          <div className="col-3 mr-5">
            <input
              type="text"
              className="form-control"
              value={personalAllowance}
              onChange={(e) => setPersonalAllowance(e.target.value)}
            />
            <br />
            <input
              type="text"
              className="form-control"
              value={uniformNLaundryAllowance}
              onChange={(e) => setUniformNLaundryAllowance(e.target.value)}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={
                selectedLevel?.mealAllowance ? selectedLevel?.mealAllowance : 0
              }
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={
                selectedLevel?.travelAllowance
                  ? selectedLevel?.travelAllowance
                  : 0
              }
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={
                selectedLevel?.phoneAndDateAllowance
                  ? selectedLevel?.phoneAndDateAllowance
                  : 0
              }
            />
          </div>

          <div className="col-3">
            <input
              type="text"
              className="form-control"
              readOnly
              value={personalAllowance * 12}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={uniformNLaundryAllowance * 12}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={
                selectedLevel?.mealAllowance
                  ? selectedLevel?.mealAllowance * 12
                  : 0
              }
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={
                selectedLevel?.travelAllowance
                  ? selectedLevel?.travelAllowance * 12
                  : 0
              }
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={
                selectedLevel?.phoneAndDateAllowance
                  ? selectedLevel?.phoneAndDateAllowance * 12
                  : 0
              }
            />
          </div>
        </div>

        <hr />

        <div className="row define-CTC__titles">
          <div className="col-6 text-center">
            <em>Retirals & Insurance</em>
            <FormGroup
              style={{
                display: "flex",
                justifyContent: "center",
                width: "200px",
                margin: "auto",
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked
                    checked={isPF}
                    onChange={() => setIsPF(!isPF)}
                  />
                }
                label="PF"
              />
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center define-CTC__components">
            {isPF && (
              <>
                <h5>Provident Fund</h5>
                <h5 style={{ marginTop: "38.4px" }}>Gratuity</h5>
              </>
            )}
            <h5 style={{ marginTop: "38.4px" }}>Medical Insurance Premium</h5>
          </div>

          <div className="col-3 mr-5">
            {isPF && (
              <>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={parseInt(basic * (12 / 100))}
                />
                <br />
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={parseInt(basic * (4.81 / 100))}
                />
              </>
            )}
          </div>

          <div className="col-3">
            {isPF && (
              <>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={parseInt(basic * (12 / 100)) * 12}
                />
                <br />
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={parseInt(basic * (4.81 / 100)) * 12}
                />
                <br />
              </>
            )}
            {!isPF && <br />}
            <input
              type="text"
              className="form-control"
              readOnly
              value={selectedLevel?.medicalInsurance}
            />
          </div>
        </div>

        <hr />

        <div className="row define-CTC__titles">
          <div className="col-6 text-center">
            <em>Bonus</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center define-CTC__components">
            <h5>Performance Bonus</h5>
            <h5 style={{ marginTop: "38.4px" }}>Yearly Festival Bonus</h5>
          </div>

          <div className="col-3 mr-5">
            <input
              type="text"
              className="form-control"
              value={perfBonus}
              onChange={(e) => setPerfBonus(e.target.value)}
            />
          </div>

          <div className="col-3">
            <input
              type="text"
              className="form-control"
              readOnly
              value={perfBonus * 12}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={basic}
            />
          </div>
        </div>

        <hr />

        <div className="row define-CTC__titles">
          <div className="col-6 text-center">
            <em>Total</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center define-CTC__components">
            <h5>Monthly Total</h5>
            <h5 style={{ marginTop: "38.4px" }}>Annual CTC</h5>
          </div>

          <div className="col-6">
            <input
              type="text"
              className="form-control"
              readOnly
              value={monthTotal}
            />
            <br />
            <input
              type="text"
              className="form-control"
              readOnly
              value={totalCTC}
            />
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-6 text-center">
            <button className="define-CTC__btn" onClick={() => setModal(true)}>
              Define CTC
            </button>
          </div>
        </div>
      </Paper>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={() => setModal(false)}
        closeAfterTransition
      >
        <Fade in={modal}>
          <div className="paper">
            <h4 className="text-center">Are you sure ?</h4>
            <br />
            <div className="row container-fluid justify-content-center">
              <div className="col-6 text-right">
                <button className="modal__btns" onClick={createSalaryStructure}>
                  Yes
                </button>
              </div>
              <div className="col-6 text-left">
                <button
                  className="modal__btns"
                  onClick={() => setModal(false)}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default DefineCTC;