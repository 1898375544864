import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { BASE_URL } from '../../../utils/config/url';
import '../StockManagement.scss';
import Button from '../../Button/Button';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { InputLabel, Switch } from '@mui/material'
import { FormControl } from '@mui/material';

const RedSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#EE4B46",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#EE4B46",
    },
}));

const ListOfCategory = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const navigate = useNavigate()

    const [categoryList, setCategoryList] = useState([]);
    const [showType, setShowType] = useState('all')

    useEffect(() => {

        let url = `${BASE_URL}/api/v1/stockmgmtapi/catCategory/getListOfCatCategory`
        let params = ''

        if(showType === 'active'){
            params += `?isActive=true`
        }else{
            params = ''
        }

        url += params;
        
        axios.get(url,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                if (response.data.status === true) {
                    setCategoryList(response.data.CatelogueCategory)
                } else {
                    setCategoryList([])
                }
            })
    }, [showType])

    const dateFormatting = (date) => {

        let str = new Date(date)

        let d = new Date(str).getDate();
        if (d < 10) {
            d = `0${d}`
        }
        let m = new Date(str).getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = new Date(str).getFullYear();

        return `${d}-${m}-${y}`
    }

    return (
        <div className='stock-mgmt'>

            <div className='row justify-content-between'>
                <Button navigateTo="/engineering/stockManagement" />
                <button className='stock-mgmt__btn'
                    onClick={() => navigate('/createCategory')}
                >Add Category</button>
            </div>

            <br />
            <br />

            <MaterialTable
                data={categoryList}
                title="List Of Category"

                columns={[
                    {
                        title: "Category Code",
                        field: "categoryCode"
                    },
                    {
                        title: "Category Name",
                        field: "categoryName"
                    },
                    {
                        title: "Active",
                        render: (rowData) => (rowData.isActive ? 'Yes' : 'No')
                    },
                    {
                        title: "Category Creator",
                        field: 'categoryCreatorFullName'
                    },
                    {
                        title: 'Created At',
                        render: (rowData) => (dateFormatting(rowData.createdAt)),
                        defaultSort: 'desc',
                        customSort: (a, b) => (a.createdAt < b.createdAt ? -1 : 1)
                    }

                ]}

                actions={[
                    {
                        icon: 'remove_red_eye',
                        tooltip: 'View Category',
                        onClick: (event, rowData) => {
                            navigate(`/listOfCategory/${rowData.categoryCode}`)
                        }
                    },
                ]}


                components={{
                    Toolbar: (props) => (
                        <div className="filters text-center">
                            <MTableToolbar {...props} />


                            <FormControl variant="standard"
                                className="formControl"
                                style={{
                                    marginTop: "-65px",
                                    width: "250px",
                                }}
                            >

                                <div
                                className='d-flex align-items-center mt-4'
                               >
                                <span>Show All</span>
                                <RedSwitch
                                    checked={showType === 'all' ? false : true}
                                    onChange={(e) => {
                                        setShowType((prev) => {
                                            return prev === 'all' ? 'active' : 'all'
                                        })
                                    }}
                                />

                                <span>Show Active</span>
                            </div>

                            </FormControl>

                        </div>
                    ),
                }}


                options={{
                    search: window.innerWidth < 700 ? false : true,
                    searchFieldStyle: {
                        width: 180
                    },
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#EE4B46',
                        color: '#fff',
                    }
                }}

            ></MaterialTable>

        </div>
    )
}

export default ListOfCategory