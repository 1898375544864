import React, { useEffect, useState } from 'react'
import axios from 'axios';
import './OpenLeaveRequests.scss';
import { BASE_URL } from '../../utils/config/url';
import MaterialTable from 'material-table';
import Button from '../../components/Button/Button';
import Modal from "@mui/material/Modal";
import Fade from "@mui/material//Fade";
import { useStyles } from '../../utils/ModalStyles';
import Swal from 'sweetalert2';
import ClipLoader from 'react-spinners/ClipLoader';

const OpenLeaveRequests = () => {

    const classes = useStyles();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    const [OpenLeaveRequests, setOpenLeaveRequests] = useState([]);
    const [leaveId, setLeaveId] = useState('');
    const [modal1, setModal1] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [isRejected, setIsRejected] = useState(false)
    const [rejectionReason, setRejectionReason] = useState('')
    const [spinnerModal, setSpinnerModal] = useState(false);

    useEffect(() => {

        getListOfOpenLeaveRequests();

    }, [])

    const getListOfOpenLeaveRequests = () => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/leave/getListOfLeave?applicationStatus=Applied&lineManagerId=${userInfo.employeeId}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)
                if (response.data.status === true) {
                    setOpenLeaveRequests(response.data.leaveList)
                } else {
                    setOpenLeaveRequests([])
                }
            })
    }


    const approveLeave = (e) => {
        e.preventDefault();

        setModal1(false)
        setSpinnerModal(true)

        axios.post(`${BASE_URL}/api/v1/hrmgmt/leave/approveLeave`, {
            leaveId,
            isApproved: true
        }, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                setSpinnerModal(false)
                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    })
                        .then(() => {
                            reset()
                            getListOfOpenLeaveRequests()
                        })
                }
            })
            .catch((err) => {
                setSpinnerModal(false)
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }

    const rejectLeave = (e) => {
        e.preventDefault();

        setModal2(false)
        setSpinnerModal(true)

        axios.post(`${BASE_URL}/api/v1/hrmgmt/leave/approveLeave`, {
            leaveId,
            isApproved: false,
            rejectionReason
        }, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                setSpinnerModal(false)
                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    })
                        .then(() => {
                            reset()
                            getListOfOpenLeaveRequests()
                        })
                }
            })
            .catch((err) => {
                setSpinnerModal(false)
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }


    const reset = () => {
        setRejectionReason('')
        setIsRejected(false)
        setLeaveId('')
    }

    const dateFormatting = (input, type) => {

        if (!input) {
            return 'N/A'
        }

        let d = new Date(input)
        let date = d.getDate()
        if (date < 10) {
            date = `0${date}`
        }
        let m = d.getMonth() + 1
        if (m < 10) {
            m = `0${m}`
        }
        let y = d.getFullYear()

        let final;

        if (type === 'original') {
            final = `${y}-${m}-${date}`
            return final
        } else {
            final = `${date}/${m}/${y}`
            return final
        }

    }


    return (
        <div className='open-leave-requests'>

            <Button navigateTo={'/leavemanagement'} />
            <br />
            <br />
            <br />

            <h4>Open Leave Requests</h4>

            <MaterialTable
                data={OpenLeaveRequests}
                title=""
                columns={[
                    {
                        title: "Action",
                        render: (rowData) => (
                            <div className='open-leave-requests__btns'>
                                <button
                                    style={{ backgroundColor: rowData.applicationStatus != 'Applied' ? 'grey' : '#EE4B46', fontSize: '14px' }}
                                    disabled={rowData.applicationStatus != 'Applied'}
                                    onClick={() => {
                                        setLeaveId(rowData.leaveId)
                                        setModal1(true)
                                    }}>Approve</button>

                                <button
                                    style={{ backgroundColor: rowData.applicationStatus != 'Applied' ? 'grey' : '#EE4B46', fontSize: '14px' }}
                                    disabled={rowData.applicationStatus != 'Applied'}
                                    onClick={() => {
                                        setLeaveId(rowData.leaveId)
                                        setModal2(true)
                                    }}>Reject</button>
                            </div>
                        )
                    },
                    { title: "Employee Name", field: "employeeName" },
                    { title: "Line Mananger Name", field: "lineManagerName" },
                    {
                        title: "Leave Start Date",
                        render: (rowData) => dateFormatting(rowData.leaveStartDate)
                    },
                    {
                        title: "Half Day (Start)",
                        render: (rowData) => (rowData.isHaldDayStart ? 'Yes' : 'No')
                    },
                    {
                        title: 'Leave End Date',
                        render: (rowData) => dateFormatting(rowData.leaveEndDate)
                    },
                    {
                        title: 'Half Day (End)',
                        render: (rowData) => (rowData.isHalfDayEnd ? 'Yes' : 'No')
                    },
                    {
                        title: 'No of Days', field: 'noOfDays'
                    },
                    {
                        title: 'Leave Reason', field: 'leaveReason'
                    },
                    {
                        title: 'Leave Type', field: 'leaveType'
                    },
                    {
                        title: 'Application Status', field: 'applicationStatus'
                    },
                    {
                        title: 'Application Date',
                        render: (rowData) => dateFormatting(rowData.applicationDate),
                        customSort: (a, b) => (new Date(a.applicationDate).getTime() < new Date(b.applicationDate).getTime() ? -1 : 1),
                        defaultSort: 'desc'
                    },

                ]}

                options={{
                    search: true,
                    searchFieldStyle: {
                        width: 180
                    },
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    headerStyle: {
                        backgroundColor: "#EE4B46",
                        color: "#fff",
                    },
                }}


            ></MaterialTable>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={modal1}
                onClose={() => setModal1(false)}
                closeAfterTransition
            >
                <Fade in={modal1}>
                    <div className="paper">
                        <h3 className='text-center'>Do you Approve this Leave ?</h3>
                        <br />

                        <form onSubmit={(e) => approveLeave(e)}>
                            <div className="row container-fluid justify-content-center">
                                <div className="col-6 text-right">
                                    <button
                                        type='submit'
                                        className="modal__btns"
                                    >
                                        Yes
                                    </button>
                                </div>
                                <div className="col-6 text-left">
                                    <button
                                        className="modal__btns"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setModal1(false)
                                        }}
                                        style={{
                                            backgroundColor: "white",
                                            color: "black",
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={modal2}
                onClose={() => setModal2(false)}
                closeAfterTransition
            >
                <Fade in={modal2}>
                    <div className="paper">
                        <h3 className='text-center'>Do you Reject this Leave ?</h3>
                        <br />

                        {
                            isRejected === false
                                ?
                                <form>
                                    <div className="row container-fluid justify-content-center">
                                        <div className="col-6 text-right">
                                            <button
                                                type='submit'
                                                className="modal__btns"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setIsRejected(true)
                                                }}
                                            >
                                                Yes
                                            </button>
                                        </div>
                                        <div className="col-6 text-left">
                                            <button
                                                className="modal__btns"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setModal2(false)
                                                }}
                                                style={{
                                                    backgroundColor: "white",
                                                    color: "black",
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                :


                                <form onSubmit={(e) => rejectLeave(e)}
                                    style={{ width: '300px' }}
                                >
                                    <div className='row justify-content-center'>

                                        <div className='col-12'>
                                            <label>Rejection Reason</label>
                                            <input type="text" className='form-control'
                                                required
                                                onChange={(e) => setRejectionReason(e.target.value)} />
                                        </div>

                                    </div>
                                    <br />
                                    <div className='row container-fluid justify-content-between'>

                                        <div className='col-4'>
                                            <button type='submit'
                                                className="modal__btns"
                                            >Submit</button>
                                        </div>

                                        <div className='col-4'>
                                            <button
                                                className="modal__btns"
                                                style={{
                                                    backgroundColor: "white",
                                                    color: "black",
                                                }}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setModal2(false);
                                                    setTimeout(() => {
                                                        reset()
                                                    }, 200)

                                                }}>Cancel</button>
                                        </div>
                                    </div>
                                </form>
                        }

                    </div>
                </Fade>
            </Modal>

            {/* /////////////////Modal for spinner /////////// */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={spinnerModal}
                onClose={() => setSpinnerModal(false)}
                closeAfterTransition
            >
                <Fade in={spinnerModal}>

                    <div>
                        <ClipLoader
                            color='#EE4B46'
                            loading={spinnerModal}
                            size={70}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                </Fade>
            </Modal>

        </div >
    )
}

export default OpenLeaveRequests