import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftShort } from 'react-icons/bs';
import Details from '../../components/MyProfile/Details/Details';
import InvUserDoc from '../../components/ViewIndividualUser/InvUserDoc';
import Salary from '../../components/MyProfile/Details/Salary';
import './MyProfile.scss';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';


const MyProfile = () => {
    const [activeMenu, setActiveMenu] = useState('dt');
    const [user, setUser] = useState({});
    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    useEffect(() => {
        (async () => {
            const res = await axios.get(`${BASE_URL}/api/v1/user/getUserByUserName/${userInfo.userName}`,
                { headers: { Authorization: `bearer ${userInfo.token}` } }
            );
            const data = res.data.user;
            setUser(data)
        })()
    }, [])
    return (
        <div className='my_profile'>
            <div className='profile_container'>
                <div className="pt-5">
                    <button
                        className='back_btn'
                        onClick={() => navigate(-1)}
                    >
                        <span><BsArrowLeftShort className='back_icon' /></span>
                        Back
                    </button>
                    <div className="profile_tabs">
                        <button
                            className={`${activeMenu === "dt" ? "bg-red" : "bg-dark"}`}
                            onClick={() => {
                                setActiveMenu('dt');
                            }}
                        >
                            Details
                        </button>
                        <button
                            className={`${activeMenu === "doc" ? "bg-red" : "bg-dark"}`}
                            onClick={() => {
                                setActiveMenu('doc');
                            }}
                        >
                            Documents
                        </button>
                        <button
                            className={`${activeMenu === "sal" ? "bg-red" : "bg-dark"}`}
                            onClick={() => {
                                setActiveMenu('sal');
                            }}
                        >
                            Salary
                        </button>
                    </div>
                </div>
                {/* nested layout here  */}
                <div style={{ marginTop: "50px" }}>
                    {activeMenu === "dt" && <Details />}
                    {activeMenu === "doc" && <InvUserDoc user={user}/>}
                    {activeMenu === "sal" && <Salary />}
                </div>
            </div>
        </div>
    );
};

export default MyProfile;