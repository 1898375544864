import React, { useEffect, useState } from "react";
import { Paper, FormGroup, FormControlLabel, Switch } from "@mui/material";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../utils/config/url";
import Form from 'react-bootstrap/Form';
import './Salary.scss';
import { DatePicker } from "@mui/x-date-pickers";
import SalaryAdvance from "../SalaryAdvance";

const Salary = () => {
  const RedSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#EE4B46",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#EE4B46",
    },
  }));

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));


  const [jobRole, setJobRole] = useState("");
  const [designation, setDesignation] = useState("");
  const [employee, setEmployee] = useState({});

  const [salaryAccount, setSalaryAccount] = useState({});
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [IFSCCode, setIFSCCode] = useState("");

  const [basicMonthly, setBasicMonthly] = useState(0);
  const [basicYearly, setBasicYearly] = useState(0);
  const [financialYear, setFinancialYear] = useState("");
  const [hraMonthly, setHraMonthly] = useState(0);
  const [hraYearly, setHraYearly] = useState(0);
  const [monthlyPersonalAllowance, setMonthlyPersonalAllowance] = useState(0);
  const [yearlyPersonalAllowance, setYearlyPersonalAllowance] = useState(0);
  const [monthlyUniformNLaundryAllowance, setMonthlyUniformNLaundryAllowance] =
    useState(0);
  const [yearlyUniformNLaundryAllowance, setYearlyUniformNLaundryAllowance] =
    useState(0);
  const [monthlyMealAllowance, setMonthlyMealAllowance] = useState(0);
  const [yearlyMealAllowance, setYearlyMealAllowance] = useState(0);
  const [monthlyTravelAllowance, setMonthlyTravelAllowance] = useState(0);
  const [yearlyTravelAllowance, setYearlyTravelAllowance] = useState(0);
  const [monthlyPhoneAndDataAllowance, setMonthlyPhoneAndDataAllowance] =
    useState(0);
  const [yearlyPhoneAndDataAllowance, setYearlyPhoneAndDataAllowance] =
    useState(0);
  const [monthlyProvidentFund, setMonthlyProvidentFund] = useState(0);
  const [yearlyProvidentFund, setYearlyProvidentFund] = useState(0);
  const [monthlyGratuity, setMonthlyGratuity] = useState(0);
  const [yearlyGratuity, setYearlyGratuity] = useState(0);
  const [yearlyFestivalBonus, setYearlyFestivalBonus] = useState(0);

  const [monthlyPerfBonus, setMonthlyPerfBonus] = useState(0);
  const [yearlyPerfBonus, setYearlyPerfBonus] = useState(0);
  const [monthTotal, setMonthTotal] = useState(0);
  const [totalCTC, setTotalCTC] = useState(0);
  const [isPF, setIsPF] = useState(false);
  const [ctcDownload, setCtcDownload] = useState(false);
  const [medicalInsurancePremium, setMedicalInsurancePremium] = useState(0);
  const [payslipsForYearSelected, setPayslipsForYearSelected] = useState([]);
  const [year, setYear] = useState('');

  // year scroller

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setSelectedMonth("");
  };

  // Generate a list of years from 2000 to the current year
  const years = Array.from(
    { length: new Date().getFullYear() - 2000 + 1 },
    (_, index) => 2000 + index
  );

  // fetch the year data from dropdown api

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${userInfo.employeeId}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        const emp = res.data.employee;
        const salary = emp.salaryAccount;
        setEmployee(emp)
        setDesignation(emp.designation);
        setJobRole(emp.employeeRole);
        setAccountNumber(salary.accountNumber);
        setAccountHolderName(salary.accountHolderName);
        setBankName(salary.bankName);
        setIFSCCode(salary.IFSCCode);
        setBranchName(salary.branchName);
      }
      );
  }, []);

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/salarystructure/getSalaryStructureByEmployeeId/${userInfo.employeeId}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        const ctc = res.data.salaryStructure;
        setBasicMonthly(ctc?.monthlyBasic);
        setBasicYearly(ctc?.yearlyBasic);
        setFinancialYear(ctc?.financialYear);
        setHraMonthly(ctc?.monthlyHRA);
        setHraYearly(ctc?.yearlyHRA);
        setMonthlyPersonalAllowance(ctc?.monthlyPersonalAllowance);
        setYearlyPersonalAllowance(ctc?.yearlyPersonalAllowance);
        setMonthlyUniformNLaundryAllowance(
          ctc?.monthlyUniformNLaundryAllowance
        );
        setYearlyUniformNLaundryAllowance(
          ctc?.yearlyUniformNLaundryAllowance
        );
        setMonthlyMealAllowance(ctc?.monthlyMealAllowance);
        setYearlyMealAllowance(ctc?.yearlyMealAllowance);
        setMonthlyTravelAllowance(ctc?.monthlyTravelAllowance);
        setYearlyTravelAllowance(ctc?.yearlyTravelAllowance);
        setMonthlyPhoneAndDataAllowance(ctc?.monthlyPhoneAndDataAllowance);
        setYearlyPhoneAndDataAllowance(ctc?.yearlyPhoneAndDataAllowance);
        setMonthlyProvidentFund(ctc?.monthlyProvidentFund);
        setYearlyProvidentFund(ctc?.yearlyProvidentFund);
        setMonthlyGratuity(ctc?.monthlyGratuity);
        setYearlyGratuity(ctc?.yearlyGratuity);
        setYearlyFestivalBonus(ctc?.yearlyFestivalBonus);
        setMonthlyPerfBonus(ctc?.monthlyPerformanceBonus
        );
        setYearlyPerfBonus(ctc?.yearlyPerformanceBonus
        );
        setMonthTotal(ctc?.monthlyTotal);
        setTotalCTC(ctc?.annualCTC);
        setIsPF(ctc?.eligibleForPF);
        setMedicalInsurancePremium(ctc?.medicalPremium);
        setCtcDownload(ctc?.ctcDocumentLink)

      }
      );
  }, []);

  useEffect(() => {

    if (!year) return;

    axios.get(`${BASE_URL}/api/v1/hrmgmt/paySlip/getListOfPaySlips?employeeId=${employee?.employeeId}&year=${year}&payslipStatus=Salary Disbursed`, {
      headers: { Authorization: `Bearer ${userInfo.token}` }
    })
      .then((response) => {
        // console.log(response)
        if (response.data.status) {
          setPayslipsForYearSelected(response.data.payslipList)
        } else {
          setPayslipsForYearSelected([])
        }
      })

  }, [year])

  const handleMonthClick = (month) => {
    axios
      .get(
        "https://api-test.westroad.in/api/v1/hrmgmt/paySlip/getListOfPaySlips?employeeId=WR-30",
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((response) => {
        const monthpayslip = response.data;
        const blob = new Blob([JSON.stringify(monthpayslip)], {
          type: "application/json",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Payslip_${selectedYear}_${month}.json`;
        a.click();
      })
      .catch((err) => {
        console.log("Error Downloading Payslips", err);
      });
  };

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${userInfo.employeeId}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        const emp = res.data.employee;
        const salary = emp.salaryAccount;
        setDesignation(emp.designation);
        setJobRole(emp.employeeRole);
        setAccountNumber(salary.accountNumber);
        setAccountHolderName(salary.accountHolderName);
        setBankName(salary.bankName);
        setIFSCCode(salary.IFSCCode);
        setBranchName(salary.branchName);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/salarystructure/getSalaryStructureByEmployeeId/${userInfo.employeeId}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        const ctc = res.data.salaryStructure;
        setBasicMonthly(ctc.monthlyBasic);
        setBasicYearly(ctc.yearlyBasic);
        setFinancialYear(ctc.financialYear);
        setHraMonthly(ctc.monthlyHRA);
        setHraYearly(ctc.yearlyHRA);
        setMonthlyPersonalAllowance(ctc.monthlyPersonalAllowance);
        setYearlyPersonalAllowance(ctc.yearlyPersonalAllowance);
        setMonthlyUniformNLaundryAllowance(ctc.monthlyUniformNLaundryAllowance);
        setYearlyUniformNLaundryAllowance(ctc.yearlyUniformNLaundryAllowance);
        setMonthlyMealAllowance(ctc.monthlyMealAllowance);
        setYearlyMealAllowance(ctc.yearlyMealAllowance);
        setMonthlyTravelAllowance(ctc.monthlyTravelAllowance);
        setYearlyTravelAllowance(ctc.yearlyTravelAllowance);
        setMonthlyPhoneAndDataAllowance(ctc.monthlyPhoneAndDataAllowance);
        setYearlyPhoneAndDataAllowance(ctc.yearlyPhoneAndDataAllowance);
        setMonthlyProvidentFund(ctc.monthlyProvidentFund);
        setYearlyProvidentFund(ctc.yearlyProvidentFund);
        setMonthlyGratuity(ctc.monthlyGratuity);
        setYearlyGratuity(ctc.yearlyGratuity);
        setYearlyFestivalBonus(ctc.yearlyFestivalBonus);
        setMonthlyPerfBonus(ctc.monthlyPerformanceBonus);
        setYearlyPerfBonus(ctc.yearlyPerformanceBonus);
        setMonthTotal(ctc.monthlyTotal);
        setTotalCTC(ctc.annualCTC);
        setIsPF(ctc.eligibleForPF);
        setMedicalInsurancePremium(ctc.medicalPremium);
        setCtcDownload(ctc.ctcDocumentLink);
      });
  }, []);

  return (
    <div className="salary">
      {/* Designation and Role*/}
      <Paper elevation={2} style={{ padding: "20px", marginTop: "20px" }}>
        <h4 className="text-center mb-4">
          <b>Employee Details</b>
        </h4>
        <div className="row justify-content-start">
          <div className="col-4 ">
            <label>Employee Id</label>
            <input
              type="text"
              readOnly
              value={userInfo.employeeId}
              className="form-control"
            />
          </div>

          <div className="col-4 ">
            <label>Designation</label>
            <input
              type="text"
              readOnly
              value={designation}
              className="form-control"
            />
          </div>

          <div className="col-4 ">
            <label>Job Role</label>
            <input
              type="text"
              readOnly
              value={jobRole}
              className="form-control"
            />
          </div>
        </div>
      </Paper>

      {/* Salary A/C*/}

      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <h4 className="text-center mb-4">
          <b>Salary Account Details</b>
        </h4>

        <div className="row justify-content-start">
          <div className="col-4">
            <label>Account No.</label>
            <input
              type="text"
              readOnly
              value={accountNumber}
              className="form-control"
            />
          </div>

          <div className="col-4">
            <label>Account Holder Name</label>
            <input
              type="text"
              readOnly
              value={accountHolderName}
              className="form-control"
            />
          </div>

          <div className="col-4">
            <label>Bank Name</label>
            <input
              type="text"
              readOnly
              value={bankName}
              className="form-control"
            />
          </div>
        </div>

        <div className="row justify-content-start mt-3">
          <div className="col-4">
            <label>Branch Name</label>
            <input
              type="text"
              readOnly
              value={branchName}
              className="form-control"
            />
          </div>

          <div className="col-4">
            <label>IFSC Code</label>
            <input
              type="text"
              readOnly
              value={IFSCCode}
              className="form-control"
            />
          </div>
        </div>
      </Paper>

      {/* Salary Advance*/}
      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <SalaryAdvance employee={employee} />
      </Paper>

      {/* CTC*/}
      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <h4 className="text-center mb-4">
          <b>My CTC</b>
        </h4>
        <div className="row justify-content-start view-salary-structure__headers">
          <div className="col-6">Component</div>
          <div className="col-3">Monthly</div>
          <div className="col-3">Yearly</div>
        </div>

        <br />

        <div className="row view-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Basic & HRA</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center view-salary-structure__components">
            <h5>Basic Salary</h5>
            <h5>HRA</h5>
          </div>

          <div className="col-3">
            <input
              type="number"
              readOnly
              value={basicMonthly}
              className="form-control"
            />
            <br />
            <input
              type="number"
              value={hraMonthly}
              className="form-control"
              readOnly
            />
          </div>

          <div className="col-3">
            <input
              type="number"
              value={basicYearly}
              className="form-control"
              readOnly
            />
            <br />
            <input
              type="number"
              value={hraYearly}
              className="form-control"
              readOnly
            />
          </div>
        </div>

        <hr />

        <div className="row view-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Allowances</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center view-salary-structure__components">
            <h5>Personal Allowance</h5>
            <h5>Uniform and Laundry Allowance</h5>
            <h5>Meal Allowance</h5>
            <h5>Travel Allowance</h5>
            <h5>Phone & Date Allowance</h5>
          </div>

          <div className="col-3">
            <input
              type="number"
              value={monthlyPersonalAllowance}
              className="form-control"
              readOnly
            />
            <br />
            <input
              type="number"
              value={monthlyUniformNLaundryAllowance}
              className="form-control"
              readOnly
            />
            <br />
            <input
              type="number"
              value={monthlyMealAllowance}
              className="form-control"
              readOnly
            />
            <br />
            <input
              type="number"
              value={monthlyTravelAllowance}
              className="form-control"
              readOnly
            />
            <br />
            <input
              type="number"
              value={monthlyPhoneAndDataAllowance}
              className="form-control"
              readOnly
            />
          </div>

          <div className="col-3">
            <input
              type="number"
              value={yearlyPersonalAllowance}
              className="form-control"
              readOnly
            />
            <br />
            <input
              type="number"
              value={yearlyUniformNLaundryAllowance}
              className="form-control"
              readOnly
            />
            <br />
            <input
              type="number"
              value={yearlyMealAllowance}
              className="form-control"
              readOnly
            />
            <br />
            <input
              type="number"
              value={yearlyTravelAllowance}
              className="form-control"
              readOnly
            />
            <br />
            <input
              type="number"
              value={yearlyPhoneAndDataAllowance}
              className="form-control"
              readOnly
            />
          </div>
        </div>

        <hr />

        <div className="row view-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Retirals & Insurance</em>
            <FormGroup
              style={{
                display: "flex",
                justifyContent: "center",
                width: "200px",
                margin: "auto",
              }}
            >
              <FormControlLabel
                control={<RedSwitch checked={isPF} />}
                label="PF"
              />
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center view-salary-structure__components">
            {isPF && (
              <>
                <h5>Provident Fund</h5>
                <h5>Gratuity</h5>
              </>
            )}
            <h5>Medical Insurance Premium</h5>
          </div>

          <div className="col-3">
            {isPF && (
              <>
                <input
                  type="number"
                  value={monthlyProvidentFund}
                  className="form-control"
                  readOnly
                />
                <br />
                <input
                  type="number"
                  value={monthlyGratuity}
                  className="form-control"
                  readOnly
                />
              </>
            )}
          </div>

          <div className="col-3">
            {isPF && (
              <>
                <input
                  type="number"
                  value={yearlyProvidentFund}
                  className="form-control"
                  readOnly
                />
                <br />
                <input
                  type="number"
                  value={yearlyGratuity}
                  className="form-control"
                  readOnly
                />
                <br />
              </>
            )}

            <input
              type="number"
              value={medicalInsurancePremium}
              className="form-control"
              readOnly
            />
          </div>
        </div>

        <hr />

        <div className="row view-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Bonus</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center view-salary-structure__components">
            <h5>Performance Bonus</h5>
            <h5>Yearly Festival Bonus</h5>
          </div>

          <div className="col-3">
            <input
              type="number"
              value={monthlyPerfBonus}
              readOnly
              className="form-control"
            />
          </div>

          <div className="col-3">
            <input
              type="number"
              value={yearlyPerfBonus}
              className="form-control"
              readOnly
            />
            <br />
            <input
              type="number"
              value={yearlyFestivalBonus}
              className="form-control"
              readOnly
            />
          </div>
        </div>

        <hr />

        <div className="row view-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Total</em>
          </div>
        </div>

        <div className="row">
          <div className="col-4 text-center view-salary-structure__components">
            <h5>Monthly Total</h5>
            <h5>Annual CTC</h5>
          </div>

          <div className="col-4">
            <input
              type="number"
              value={monthTotal}
              className="form-control"
              readOnly
            />
            <br />
            <input
              type="number"
              value={totalCTC}
              className="form-control"
              readOnly
            />
          </div>
          <a
            href={ctcDownload}
            download="Salary Structure"
            target="_blank"
            className="col-1 text-center mt-5"
            rel="noreferrer"
          >
            <button
              className="view-salary-structure__btn mt-3"
              style={{ fontSize: "15px" }}
              disabled={!ctcDownload}
            >
              Download
            </button>
          </a>
        </div>
      </Paper>

      {/* Pay Slip*/}
      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <h2 className="text-center mb-4">
          <b>Payslip</b>
        </h2>

        <div className="row justify-content-end">
          {/* <div className="col-4 text-center">
            <b>Employee : {employee?.employeeId}</b>
          </div> */}

          <div className="col-4 text-center">
            <Form.Group controlId="Year">
              <Form.Label>Year</Form.Label>
              <Form.Control as="select" required
                value={year}
                onChange={(e) => {
                  setYear(e.target.value)
                }}
              >
                <option value="">Select a Year</option>
                <option>2023</option>
                <option>2024</option>
                <option>2025</option>
              </Form.Control>
            </Form.Group>
          </div>

        </div>

        {
          (payslipsForYearSelected.length === 0 && year != '')
            ?
            <h4 className="text-center mt-4">No Payslips Found for Year {year}</h4>
            :
            <div className="row d-flex justify-content-center flex-wrap mt-4">
              {
                payslipsForYearSelected?.map((payslip, index) => {
                  return <Paper elevation={2} className="text-center salary__payslip" style={{ padding: "20px" }} key={index}>
                    <a href={payslip?.payslipS3Link || ''}>
                      <p>Payslip Number : <b>{payslip?.payslipNumber}</b></p>
                      <div className="d-flex justify-content-center">
                        <p>Year : <b>{payslip?.year}</b></p>
                        <p className="ml-2">Month : <b>{payslip?.monthName}</b></p>
                      </div>
                      <p>Net Pay : <b>{payslip?.netSalary}</b></p>
                    </a>
                  </Paper>
                })
              }

            </div>
        }


      </Paper>
    </div>
  );
};

export default Salary;
