import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../../utils/config/url";
import { Form } from "react-bootstrap";
import "./UserInfo.scss";
const UserInfo = ({ userAuth, setUpdate, update }) => {
  const [userInfo, setUserInfo] = useState({});
  const [pinCode, setPinCode] = useState(700117);
  const [aadhar, setAadhar] = useState(123456789012);
  const [pan, setPan] = useState("ALPPB5305D");
  // get user update info
  useEffect(() => {
    (async () => {
      const res = await axios.get(
        `${BASE_URL}/api/v1/hrmgmt/Employee/getEmployeeByEmployeeId/${userAuth.employeeId}`,
        { headers: { Authorization: `bearer ${userAuth.token}` } }
      );
      const data = await res.data.employee;
      setUserInfo({
        userName: data?.userName,
        userMobile: data?.officialMobile,
        userDOB: data?.employeeDOB
          ? new Date(data?.employeeDOB).toISOString().slice(0, 10)
          : new Date().toISOString().slice(0, 10),
        userAddress: data?.employeeAddress.fullAddress,
        userLandMark: data?.employeeAddress.landMark,
        userCity: data?.employeeAddress.city,
        userState: data?.employeeAddress.state,
        userPinCode: data?.employeeAddress.pinCode,
        userAadhar: data?.employeeAadhar,
        userPAN: data?.employeePAN,
      });
    })();
  }, [update]);
  // console.log(userInfo);
  const handleChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newData = { ...userInfo };
    newData[field] = value;
    setUserInfo(newData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      String(pinCode).length !== 6 ||
      String(aadhar).length !== 12 ||
      pan.length !== 10
    ) {
      Swal.fire({
        icon: "warning",
        title: "Please Input Valid Information!",
      });
      return;
    } else {
      axios
        .put(
          `${BASE_URL}/api/v1/hrmgmt/Employee/updateEmployee`,
          {
            employeeId: userAuth.employeeId,
            update: {
              employeeAadhar: aadhar,
              employeePAN: pan,
              employeeDOB: userInfo.userDOB,
              employeeAddress: {
                fullAddress: userInfo.userAddress,
                landMark: userInfo.userLandMark,
                city: userInfo.userCity,
                state: userInfo.userState,
                pinCode: pinCode,
              },
            },
          },
          { headers: { Authorization: `bearer ${userAuth.token}` } }
        )
        .then((res) => {
          if (res.data.status) {
            Swal.fire({
              icon: "success",
              title: "All Details Saved Succesfully!",
              showConfirmButton: false,
              timer: 2000,
            });
            setUpdate(!update);
          } else {
            Swal.fire({
              icon: "error",
              title:
                "Oops there is an error. Kindly contact the developer team!",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops there is an error. Kindly contact the developer team!",
          });
        });
    }
  };
  return (
    <div className="mt-3">
      <form onSubmit={handleSubmit} className="doc_form">
        <div className="d-flex justify-content-center">
          <div className="details">
            <div className="user_info">
              <label>DOB</label>
              <Form.Control
                onChange={handleChange}
                type="date"
                name="userDOB"
                defaultValue={userInfo.userDOB}
              />
            </div>
            <div className="user_info">
              <label>Address</label>
              <Form.Control
                onChange={handleChange}
                type="text"
                name="userAddress"
                defaultValue={userInfo.userAddress}
              />
            </div>
            <div className="user_info">
              <label>Landmark</label>
              <Form.Control
                onChange={handleChange}
                type="text"
                name="userLandMark"
                defaultValue={userInfo.userLandMark}
              />
            </div>
            <div className="user_info">
              <label>City</label>
              <input
                onChange={handleChange}
                type="text"
                name="userCity"
                defaultValue={userInfo.userCity}
              />
            </div>
            <div className="user_info">
              <label>Pincode</label>
              <Form.Control
                onChange={(e) => setPinCode(e.target.value)}
                type="number"
                defaultValue={userInfo.userPinCode}
              />
              {String(pinCode).length !== 6 && (
                <small className="mt-2 text-danger">
                  Pincode must be of 6 digits
                </small>
              )}
            </div>
            <div className="user_info">
              <label>State</label>
              <Form.Control
                onChange={handleChange}
                type="text"
                name="userState"
                defaultValue={userInfo.userState}
              />
            </div>
            <div className="user_info">
              <label>Aadhar</label>
              <Form.Control
                onChange={(e) => setAadhar(e.target.value)}
                type="number"
                defaultValue={userInfo.userAadhar}
              />
              {String(aadhar).length !== 12 && (
                <small className="mt-2 text-danger">
                  Adhar must be of 12 digits
                </small>
              )}
            </div>
            <div className="user_info">
              <label>PAN</label>
              <Form.Control
                onChange={(e) => setPan(e.target.value)}
                type="text"
                defaultValue={userInfo.userPAN}
              />
              {pan.length !== 10 && (
                <small className="mt-2 text-danger">
                  PAN must be of 10 digits
                </small>
              )}
            </div>
          </div>
        </div>
        <button className="save_btn" type="submit">
          Save
        </button>
      </form>
    </div>
  );
};

export default UserInfo;
