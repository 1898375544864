import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import { useParams } from 'react-router-dom'
import "./ViewInvEmpOnboarding.scss";
import axios from 'axios'
import { BASE_URL } from "../../utils/config/url";
import Details from '../../components/ViewInvEmpOnboarding/Details/Details'
import SalaryStructure from "../../components/ViewInvEmpOnboarding/SalaryStructure/SalaryStructure";
import Education from "../../components/ViewInvEmpOnboarding/Education/Education";
import WorkHistory from "../../components/ViewInvEmpOnboarding/WorkHistory/WorkHistory";
import Documents from "../../components/ViewInvEmpOnboarding/Documents/Documents";
import OnBoardingFormalities from "../../components/ViewInvEmpOnboarding/OnBoardingFormalities/OnBoardingFormalities";
import HRApprovalCheckclist from "../../components/ViewInvEmpOnboarding/ HRApprovalCheckclist/ HRApprovalCheckclist";
const ViewInvEmployees = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { onboardingId } = useParams();
  const [active, setActive] = useState("details");
  const userRole = userInfo.userRole;
  const [employee, setEmployee] = useState({
    employeeId: "",
    employeeFirstName: "",
    employeeLastName: "",
    employeeMiddleName: '',
    employeeRole: "",
    officialMobile: "",
    personalMobile: "",
    officialEmail: "",
    personalEmail: "",
    employeeDOB: '',
    jobLevel: '',
    designation: "",
    employeeAddress: {
      fullAddress: "",
      landMark: "",
      city: "",
      state: "",
      pincode: ""
    },
    emergencyContact: {
      name: "",
      mobile: "",
      address: ""
    },
    employeeAadhar: '',
    employeeAadharScan: '',
    employeePAN: '',
    employeePANScan: '',
    educationalDetails: [
      {
        qualification: "",
        schoolInstitute: "",
        universityBoard: "",
        score: '',
        scoreType: "",
        yearOfPassing: "",
        city: "",
        state: "",
        country: "",
        degreeScan: "",
        marksheetScan: "",
      }
    ],
    previousEmployerDetails: [
      {
        employerName: "",
        designation: "",
        department: "",
        dateOfJoining: "",
        dateOfLeaving: "",
        lastCTC: "",
        experienceLetterScan: "",
        payslipsScan: "",
        additionalDocumentScan: ""
      }
    ],
    employeePassport: {
      passportNo: "",
      passportScan: "",
      issueDate: "",
      expiryDate: "",
      placeOfIssue: "",
      countryOfIssue: ""
    },
    salaryAccount: {
      accountNumber: ''
    }
  });
  const getEmployeeById = async (employeeId) => {
    const { data } = await axios.get(`${BASE_URL}/api/v1/hrmgmt/Employee/getEmployeeByEmployeeId/${employeeId}`, {
      headers: {
        Authorization: `Bearer ${userInfo?.token}`,
      },
    })
    setEmployee(data.employee);
  }
  const getListOfOnboarding = async () => {
    const { data } = await axios.get(`${BASE_URL}/api/v1/hrmgmt/onboarding/getOnboardingById/${onboardingId}`, {
      headers: {
        Authorization: `Bearer ${userInfo?.token}`,
      },
    })
    const employeeId = data.onboarding.selectedEmployee.employeeId;
    await getEmployeeById(employeeId);
  }
  useEffect(() => {
    getListOfOnboarding();
  }, []);


  return (
    <div className="onboardingInvEmp">
      <div className="onboardingInvEmp__container">
        <div className="row justify-content-between mb-2">
          <Button navigateTo={"/list-of-onboardings"} className="col-2" />
          <div className="onboardingInvEmp__container__tabs col-12 mt-3">
            <button
              className={`${active === "details" ? "bg-red" : "bg-dark"}`}
              onClick={() => {
                setActive("details");
              }}
            >
              Details
            </button>
            <button
              className={`${active === "salary structure" ? "bg-red" : "bg-dark"}`}
              onClick={() => {
                setActive("salary structure");
              }}
            >
              Salary Structure
            </button>
            {(userRole === "Director" ||
              userRole === "HR" ||
              userRole === "Finance") && (
                <button
                  className={`${userRole === "Director" ||
                    userRole === "HR" ||
                    userRole === "Finance"
                    ? `${active === "education" ? "bg-red d-block" : "bg-dark d-block"
                    }`
                    : "d-none"
                    }`}
                  onClick={() => {
                    setActive("education");
                  }}
                >
                  Education
                </button>
              )}
            {(userRole === "Director" ||
              userRole === "HR" ||
              userRole === "Finance") && (
                <button
                  className={`${userRole === "Director" ||
                    userRole === "HR" ||
                    userRole === "Finance"
                    ? `${active === "work history" ? "bg-red d-block" : "bg-dark d-block"
                    }`
                    : "d-none"
                    }`}
                  onClick={() => {
                    setActive("work history");
                  }}
                >
                  Work History
                </button>
              )}
            <button
              className={`${active === "documents" ? "bg-red" : "bg-dark"}`}
              onClick={() => {
                setActive("documents");
              }}
            >
              Documents
            </button>
            <button
              className={`${active === "formalities" ? "bg-red" : "bg-dark"}`}
              onClick={() => {
                setActive("formalities");
              }}
            >
              Onboarding Formalities
            </button>
            {
              userRole === "HR" && (
                <button
                  className={`${active === "checklist" ? "bg-red" : "bg-dark"}`}
                  onClick={() => {
                    setActive("checklist");
                  }}
                >
                  HR Approval Checkclist
                </button>
              )
            }
          </div>
        </div>
      </div>
      <div>
        {
          active === 'details' && <Details employee={employee} />
        }
        {
          active === 'salary structure' && <SalaryStructure salaryAccount={employee.salaryAccount} />
        }
        {
          active === 'education' && <Education educationalDetails={employee.educationalDetails} />
        }
        {
          active === 'work history' && <WorkHistory previousEmployerDetails={employee.previousEmployerDetails} />
        }
        {
          active === 'documents' && (
            <Documents
              employeeAadhar={employee.employeeAadhar}
              employeeAadharScan={employee.employeeAadharScan}
              employeePAN={employee.employeePAN}
              employeePANScan={employee.employeePANScan}
              employeePassport={employee.employeePassport}
            />
          )
        }
        {
          active === 'formalities' && <OnBoardingFormalities />
        }
        {
          active === 'checklist' && <div>HR Approval Checkclist</div>
        }
        {
          active === 'hr-approval-checklist' && <HRApprovalCheckclist />
        }
      </div>
    </div>
  );
};

export default ViewInvEmployees;