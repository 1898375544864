import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import { BASE_URL } from "../../utils/config/url";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import { styled } from "@mui/material/styles";
import { useStyles } from "../../utils/ModalStyles";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import "./AttendanceApproval.scss";
import Swal from "sweetalert2";
import { Paper } from "@mui/material";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: "10px",
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#EE4B46",
    color: "white",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px",
    width: "100",
  },
}));

const AttendanceApproval = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const classes = useStyles();

  const [attendanceType, setAttendanceType] = useState("");
  const [attendanceList, setAttendanceList] = useState([]);
  const [attendanceId, setAttendanceId] = useState("");
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  useEffect(() => {
    getListOfAttendanceRecord();
  }, []);

  const getListOfAttendanceRecord = () => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/attendance/getListOfAttendanceRecord?lineManagerId=${userInfo.employeeId}&attandanceStatus=Initiated`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        // console.log(response)
        setAttendanceList(response.data.listOfAttendance);
      });
  };

  const handleFilter = (e) => {
    e.preventDefault();

    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/attendance/getListOfAttendanceRecord?lineManagerId=${userInfo.employeeId}&attendanceType=${e.target.value}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setAttendanceList(response.data.listOfAttendance);
      });
  };

  const approveAttendance = (e) => {
    e.preventDefault();

    axios
      .post(
        `${BASE_URL}/api/v1/hrmgmt/attendance/attendanceApproval`,
        {
          attendanceId,
          isApproved: true,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setModal1(false);
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(() => {
            reset();
            getListOfAttendanceRecord();
          });
        }
      })
      .catch((err) => {
        setModal1(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const rejectAttendance = (e) => {
    e.preventDefault();

    axios
      .post(
        `${BASE_URL}/api/v1/hrmgmt/attendance/attendanceApproval`,
        {
          attendanceId,
          isApproved: false,
          rejectionReason,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setModal2(false);
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(() => {
            reset();
            getListOfAttendanceRecord();
          });
        }
      })
      .catch((err) => {
        setModal2(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const reset = () => {
    setAttendanceId("");
    setRejectionReason();
    setIsRejected(false);
  };

  const dateFormatting = (date) => {
    let str = new Date(date);

    let istOptions = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    let istDate = str.toLocaleString("en-IN", istOptions);

    return istDate.replace(",", " ");
  };

  return (
    <div className="attendance-approval">
      <Button navigateTo={"/attendanceManagement"} />
      <br />
      <h3>Approve/Reject Attendance</h3>
      <br /> <br />
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "20px",
            marginTop: "-50px",
          }}
        >
          <FormControl sx={{ m: 1, minWidth: 185, marginBottom: "10px" }}>
            <InputLabel
              id="demo-simple-select-helper-label"
              style={{ color: "black", fontSize: "15px", paddingTop: "5px" }}
            >
              Attendance Type{" "}
              <FilterAltRoundedIcon style={{ marginLeft: "2px" }} />
            </InputLabel>

            <Select
              value={attendanceType}
              input={<BootstrapInput />}
              onChange={(e) => {
                setAttendanceType(e.target.value);
                handleFilter(e);
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="WFH">WFH</MenuItem>
              <MenuItem value="InOffice">InOffice</MenuItem>
              <MenuItem value="FieldWork">FieldWork</MenuItem>
            </Select>
          </FormControl>
        </div>

        <MaterialTable
          data={attendanceList}
          title=""
          columns={[
            {
              title: "Action",
              render: (rowData) => (
                <div className="attendance-approval__btns">
                  {rowData.attendanceStatus !== "Approved" &&
                  rowData.attendanceStatus !== "Rejected" ? (
                    <>
                      <button
                        disabled={
                          rowData.attendanceStatus === "Approved" ||
                          rowData.attendanceStatus === "Rejected"
                        }
                        style={{
                          backgroundColor:
                            rowData.attendanceStatus === "Approved" ||
                            rowData.attendanceStatus === "Rejected"
                              ? "grey"
                              : "",
                        }}
                        onClick={() => {
                          setAttendanceId(rowData.attendanceId);
                          setModal1(true);
                        }}
                      >
                        Approve
                      </button>
                    </>
                  ) : (
                    <div></div>
                  )}

                  {rowData.attendanceStatus !== "Approved" &&
                  rowData.attendanceStatus !== "Rejected" ? (
                    <>
                      <button
                        disabled={
                          rowData.attendanceStatus === "Approved" ||
                          rowData.attendanceStatus === "Rejected"
                        }
                        style={{
                          backgroundColor:
                            rowData.attendanceStatus === "Approved" ||
                            rowData.attendanceStatus === "Rejected"
                              ? "grey"
                              : "",
                        }}
                        onClick={() => {
                          setAttendanceId(rowData.attendanceId);
                          setModal2(true);
                        }}
                      >
                        Reject
                      </button>
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>
              ),
            },

            { title: "Attendance Id", field: "attendanceId" },
            { title: "Employee Id", field: "employeeId" },
            { title: "Employee Name", field: "employeeName" },
            { title: "Attendance Type", field: "attendanceType" },
            { title: "Attendance Status", field: "attendanceStatus" },
            { title: "Line Manager Id", field: "lineManagerId" },
            { title: "Line Manager Name", field: "lineManagerName" },
            {
              title: "Start Time",
              render: (rowData) => dateFormatting(rowData.startTime),
            },
            {
              title: "End Time",
              render: (rowData) => dateFormatting(rowData.endTime),
            },
            {
              title: "Total Hours",
              render: (rowData) => {
                const totalHours = rowData.totalHours;
                const utcDate = new Date(totalHours);
                const istOptions = {
                  timeZone: "Asia/Kolkata",
                  hour: "numeric",
                  minute: "numeric",
                };
                const istDate = utcDate.toLocaleString("en-IN", istOptions);

                // Calculate hours and minutes
                const hours = Math.floor(totalHours);
                const minutes = Math.round((totalHours - hours) * 60);

                // Format the result
                const formattedTotalHours = `${hours}h ${minutes}m`;

                return formattedTotalHours;
              },
            },
          ]}
          options={{
            search: true,
            searchFieldStyle: {
              width: 180,
            },
            paging: true,
            pageSize: 20,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 20, 30],
            headerStyle: {
              backgroundColor: "#EE4B46",
              color: "#fff",
            },
          }}
        />
      </>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal1}
        onClose={() => setModal1(false)}
        closeAfterTransition
      >
        <Fade in={modal1}>
          <div className="paper">
            <h3 className="text-center">
              Do you want to Approve this Attendance ?
            </h3>
            <br />

            <form onSubmit={(e) => approveAttendance(e)}>
              <div className="row container-fluid justify-content-center">
                <div className="col-6 text-right">
                  <button type="submit" className="modal__btns">
                    Yes
                  </button>
                </div>
                <div className="col-6 text-left">
                  <button
                    className="modal__btns"
                    onClick={(e) => {
                      e.preventDefault();
                      setModal1(false);
                    }}
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
            {/*                                
                                <form onSubmit={(e) => approveOrRejectLeave(e)}
                                    style={{ width: '300px' }}
                                >
                                    <div className='row justify-content-center'>

                                        <div className='col-12'>
                                            <label>Rejection Reason</label>
                                            <input type="text" className='form-control'
                                                required
                                                onChange={(e) => setRejectionReason(e.target.value)} />
                                        </div>

                                    </div>
                                    <br />
                                    <div className='row container-fluid justify-content-between'>

                                        <div className='col-4'>
                                            <button type='submit'
                                                className="modal__btns"
                                            >Submit</button>
                                        </div>

                                        <div className='col-4'>
                                            <button
                                                className="modal__btns"
                                                style={{
                                                    backgroundColor: "white",
                                                    color: "black",
                                                }}
                                                onClick={(e) => {

                                                    setModal(false);
                                                    setTimeout(() => {
                                                        reset()
                                                    }, 200)

                                                }}>Cancel</button>
                                        </div>
                                    </div>
                                </form> */}
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal2}
        onClose={() => setModal2(false)}
        closeAfterTransition
      >
        <Fade in={modal2}>
          <div className="paper">
            <h3 className="text-center">
              Do you want to Reject this Attendance ?
            </h3>
            <br />

            {isRejected === false ? (
              <form>
                <div className="row container-fluid justify-content-center">
                  <div className="col-6 text-right">
                    <button
                      type="submit"
                      className="modal__btns"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsRejected(true);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                  <div className="col-6 text-left">
                    <button
                      className="modal__btns"
                      onClick={(e) => {
                        e.preventDefault();
                        setModal2(false);
                      }}
                      style={{
                        backgroundColor: "white",
                        color: "black",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <form
                onSubmit={(e) => rejectAttendance(e)}
                style={{ width: "300px" }}
              >
                <div
                  className="row container-fluid justify-content-center"
                  style={{ marginLeft: "9rem" }}
                >
                  <div className="col-12 text-center">
                    <label>Rejection Reason</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => setRejectionReason(e.target.value)}
                    />
                  </div>
                </div>
                <br />
                <div
                  className="row container-fluid justify-content-between"
                  style={{ marginLeft: "9rem" }}
                >
                  <div className="col-4">
                    <button type="submit" className="modal__btns">
                      Submit
                    </button>
                  </div>

                  <div className="col-4">
                    <button
                      className="modal__btns"
                      style={{
                        backgroundColor: "white",
                        color: "black",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setModal2(false);
                        setTimeout(() => {
                          reset();
                        }, 200);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default AttendanceApproval;
