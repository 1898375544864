import { Form } from "react-bootstrap";
import React, { useState, useEffect, useCallback } from "react";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import Cookies from "js-cookie";
import { BASE_URL } from "../../utils/config/url";
import "./AddLeadForm.scss";
import Swal from "sweetalert2";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

function AddLeadForm() {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileUniqueMessage, setMobileUniqueMessage] = useState("");
  const [mobileUniqueStatus, setMobileUniqueStatus] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [source, setSource] = useState("");
  const [status, setStatus] = useState("New Lead");
  const [type, setType] = useState("Hot");
  const [siteName, setSiteName] = useState("");
  const [requirement, setRequirement] = useState("");
  const [budget, setBudget] = useState("");
  const [subType, setSubType] = useState("");
  const [emailValidated, setEmailValidated] = useState(true);
  const [phoneValidated, setPhoneValidated] = useState(true);
  const [wpValidated, setWpValidated] = useState(true);
  const [pinValidated, setPinValidated] = useState(true);
  const [siteList, setSiteList] = useState([]);
  const [flat, setFlat] = useState([""]);
  const [site, setSite] = useState("");
  const [bhk, setBhk] = useState("");
  const [brokerNameList, setBrokerNameList] = useState([]);
  const [brokerName, setBrokerName] = useState("");
  const [brokerCompany, setBrokerCompany] = useState("");
  const [brokerPan, setBrokerPan] = useState("");
  const [brokerAddress, setBrokerAddress] = useState("");
  const [brokerRera, setBrokerRera] = useState("");
  const [brokerType, setBrokerType] = useState("");
  const [disp, setDisp] = useState("none");
  const [message, setMessage] = useState("");
  const [spinnerModal, setSpinnerModal] = useState(false);
  const [leadPersonType, setLeadPersonType] = useState("");
  const [buyingReason, setBuyingReason] = useState("");
  const [homeLoanRequired, setHomeLoanRequired] = useState("");

  let navigate = useNavigate();
  const datas = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + datas.token;
  const addSite = (index, event) => {
    const values = [...site];
    values.push({ siteCode: "", siteName: "" });
    setSite(values);
  };

  const handleSiteChange = (index, event) => {
    const values = [...site];
    const str = event.target.value + " ";

    values[index].siteCode = str.split(" ")[0];
    values[index].siteName = str.substring(
      str.indexOf(" ") + 1,
      str.lastIndexOf(" ")
    );
    setSite(values);
  };

  const deleteSite = (index, event) => {
    const values = [...site];
    values.pop();
    setSite(values);
  };

  const addFlat = (index, event) => {
    const values = [...flat];
    values.push("");
    setFlat(values);
  };

  const handleFlatChange = (index, event) => {
    const values = [...flat];
    const str = event.target.value;
    values[index] = str;
    setFlat(values);
  };

  const deleteFlat = (index, event) => {
    const values = [...flat];
    values.pop();
    setFlat(values);
  };

  const changeBrokerPan = (e) => {
    var val = e.target.value;
    setBrokerPan(e.target.value);
    var regex = /^[A-Z0-9]{10}$/;
    var message = document.getElementById("panMessage");
    if (regex.test(val)) {
      message.classList.remove("d-block");
      message.classList.add("d-none");
    } else {
      message.classList.remove("d-none");
      message.classList.add("d-block");
    }
  };

  const submit = (e) => {
    e.preventDefault();

    setSpinnerModal(true);

    if (mobile === "") {
      setPhoneValidated(true);
    }
    if (whatsapp === "") {
      setWpValidated(true);
    }
    if (email === "") {
      setEmailValidated(true);
    }
    if (pincode === "") {
      setPinValidated(true);
    }

    let siteName = site.split(",")[1];
    let siteCode = site.split(",")[0];

    let siteData = { siteName, siteCode };

    // if (emailValidated === true && phoneValidated === true && wpValidated === true && pinValidated === true) {
    const datas = JSON.parse(localStorage.getItem("userInfo"));
    const Token = "bearer" + " " + datas.token;
    const body = {
      name: name,
      phone: mobile,
      whatsapp: whatsapp,
      email: email,
      address: address,
      city: city,
      pincode: pincode,
      leadSource: source,
      subType: subType,
      leadWeightage: type,
      site: siteData,
      leadBudget: budget,
      leadReq: requirement,
      bhk: bhk,
      currentUserName: datas.userName,
      currentUserFullName: datas.userFullName,
      broker: {
        brokerName: brokerName,
        brokerCompany: brokerCompany,
        brokerPAN: brokerPan,
        brokerAddress: brokerAddress,
        brokerRERA: brokerRera,
        brokerType: brokerType,
      },
      leadPersonType: leadPersonType,
      buyingReason: buyingReason,
      homeLoanRequired: homeLoanRequired,
    };

    axios
      .post(`${BASE_URL}/api/v1/leadmgmt/lead/addLead`, body, {
        headers: { Authorization: Token },
      })
      .then((response) => {
        setSpinnerModal(false);
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            navigate("/viewleads");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        setSpinnerModal(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: error,
        });
      });
    // }
  };

  const PhNo = (e) => {
    let val = e.target.value;

    setMobile(val);

    let message = document.getElementById("phnoMessage");
    if (val.length === 10) {
      message.classList.remove("d-block");
      message.classList.add("d-none");

      setPhoneValidated(true);
    } else {
      message.classList.remove("d-none");
      message.classList.add("d-block");
      setPhoneValidated(false);
    }
  };

  const wpno = (e) => {
    let val = e.target.value;

    setWhatsapp(val);

    let message = document.getElementById("wpnoMessage");
    if (val.length === 10) {
      message.classList.remove("d-block");
      message.classList.add("d-none");

      setWpValidated(true);
    } else {
      message.classList.remove("d-none");
      message.classList.add("d-block");
      setWpValidated(false);
    }
  };

  const pinValidation = (e) => {
    let val = e.target.value;
    setPincode(val);

    let message = document.getElementById("pinMessage");
    if (val.length === 6) {
      message.classList.remove("d-block");
      message.classList.add("d-none");
      setPinValidated(true);
    } else {
      message.classList.remove("d-none");
      message.classList.add("d-block");
      setPinValidated(false);
    }
  };

  const EmailVal = (e) => {
    var val1 = e.target.value;
    setEmail(val1);
    var regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var message = document.getElementById("emailMessage");
    if (regex.test(val1) || val1 === "") {
      message.classList.remove("d-block");
      message.classList.add("d-none");
      setEmailValidated(true);
    } else {
      message.classList.remove("d-none");
      message.classList.add("d-block");
      setEmailValidated(false);
    }
  };

  const phoneValidation = () => {
    const datas = JSON.parse(localStorage.getItem("userInfo"));
    const Token = "bearer" + " " + datas.token;
    axios
      .get(`${BASE_URL}/api/v1/leadmgmt/lead/checkExistingPhone/${mobile}`, {
        headers: { Authorization: Token },
      })
      .then((response) => {
        setMobileUniqueMessage(response.data.message);
        setMobileUniqueStatus(response.data.status);
      });
  };

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/Configuration/Site/getAllSiteNames`, {
        headers: { Authorization: Token },
      })
      .then((response) => {
        setSiteList(response.data.siteList);
      });
    (async () => {
      const { data } = await axios.get(
        `${BASE_URL}/api/v1/leadmgmt/broker/getListOfBroker?brokerActive=true`,
        {
          headers: { Authorization: Token },
        }
      );
      data.List.map((broker) => setBrokerNameList((prev) => [...prev, broker]));
    })();
  }, []);

  useEffect(() => {
    if (brokerName) {
      const brokerDetails = brokerNameList.find(
        (broker) => broker.brokerName === brokerName
      );
      if (brokerDetails) {
        setBrokerCompany(brokerDetails.brokerCompany);
        setBrokerPan(brokerDetails.brokerPAN);
        setBrokerRera(brokerDetails.brokerRERA);
        setBrokerAddress(brokerDetails.brokerAddress);
        setBrokerType(brokerDetails.brokerType);
      }
    }
  }, [brokerName, brokerNameList]);

  return (
    <div className="addlead">
      <Button navigateTo={"/viewleads"} />

      <form className="customform" onSubmit={submit}>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-sm-12">
            <h1>Add a Lead</h1>
            <br />
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              id="outlined-basic"
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="col-lg-8 col-sm-12">
            <label>Contact No.</label>
            <input
              type="number"
              className="form-control"
              name="contact"
              id="outlined-basic-phno"
              onChange={PhNo}
              required
            />
            <small id="phnoMessage" className="text-danger d-none">
              Must be of 10 characters with numbers only
            </small>
          </div>

          <div
            //className="col-8"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "3%",
            }}
          >
            <div>
              <button
                type="button"
                className="addlead__btns"
                onClick={() => phoneValidation()}
              >
                Check Phone Number
              </button>
            </div>

            <div>
              <button
                className="addlead__btns"
                type="reset"
                onClick={() => {
                  setMobileUniqueMessage("");
                  setMobileUniqueStatus("");
                }}
              >
                Clear Form
              </button>
            </div>
          </div>
        </div>

        {mobileUniqueMessage && (
          <p
            style={{
              marginTop: "2%",
              textAlign: "right",
              width: "80%",
              color: mobileUniqueStatus ? "lightseagreen" : "#EE4B46",
            }}
          >
            {mobileUniqueMessage}
          </p>
        )}

        <br />

        <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-6">
            <label>Whatsapp No.</label>
            <input
              type="number"
              className="form-control"
              name="whatsapp"
              id="outlined-basic-wpno"
              onChange={wpno}
            />
            <small id="wpnoMessage" className="text-danger d-none">
              Must be of 10 characters with numbers only
            </small>
          </div>
        </div>

        <br />
        <div className="row justify-content-center">
          <div className="col-lg-8 col-sm-12">
            <label>Email ID</label>
            <input
              type="email"
              className="form-control"
              name="email"
              id="outlined-basic-email"
              onChange={EmailVal}
            />
            <small id="emailMessage" className="text-danger d-none">
              Enter Valid Email
            </small>
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="col-lg-8 col-sm-12">
            <label>Address</label>
            <input
              type="text"
              className="form-control"
              name="address"
              id="outlined-basic"
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-6">
            <label>City/District</label>
            <input
              type="text"
              className="form-control"
              name="city"
              id="outlined-basic"
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <label>Pincode</label>
            <input
              type="number"
              className="form-control"
              name="pincode"
              id="outlined-basic-pin"
              onChange={pinValidation}
            />
            <small id="pinMessage" className="text-danger d-none">
              Must be of 6 characters with numbers only
            </small>
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-6">
            <Form.Group controlId="lead source">
              <Form.Label>Lead Source</Form.Label>
              <Form.Control
                required
                onChange={(e) => setSource(e.target.value)}
                as="select"
              >
                <option value="">Select a source</option>
                <option value="Web Portal">Web Portal</option>
                <option value="Newspaper/Magazine">Newspaper/Magazine</option>
                <option value="Hoarding">Hoarding</option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="Social Media">Social Media</option>
                <option value="Referral">Referral</option>
                <option value="Walk In">Walk In</option>
                <option value="Channel Partner">Channel Partner</option>
              </Form.Control>
            </Form.Group>
          </div>

          {source === "Newspaper/Magazine" ? (
            <>
              <div className="col-lg-4 col-sm-6">
                <Form.Group controlId="exampleForm.ControlSelect3">
                  <Form.Label>Newspaper Name</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    onChange={(e) => setSubType(e.target.value)}
                  >
                    <option value="">-- Select one --</option>
                    <option>Times Of India</option>
                    <option>Ananda Bazar</option>
                    <option>EiSamay</option>
                    <option>Bartaman</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </>
          ) : null}

          {source === "Web Portal" ? (
            <>
              <div className="col-lg-4 col-sm-6">
                <Form.Group controlId="exampleForm.ControlSelect4">
                  <Form.Label>Sub Source</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    onChange={(e) => setSubType(e.target.value)}
                  >
                    <option value="">Select a Type</option>
                    <option>99 Acres</option>
                    <option>Housing.com</option>
                    <option>Proptiger</option>
                    <option>Magicbricks</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </>
          ) : null}

          {source === "Digital Marketing" ? (
            <>
              <div className="col-lg-4 col-sm-6">
                <Form.Group controlId="exampleForm.ControlSelect4">
                  <Form.Label>Sub Source</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    onChange={(e) => setSubType(e.target.value)}
                  >
                    <option value="">Select a Type</option>
                    <option>Website</option>
                    <option>Busfarm</option>
                    <option>Google Ad</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </>
          ) : null}

          {source === "Hoarding" ? (
            <>
              <div className="col-lg-4 col-sm-6">
                <Form.Group controlId="exampleForm.ControlSelect4">
                  <Form.Label>Sub Source</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    onChange={(e) => setSubType(e.target.value)}
                  >
                    <option value="">Select a Type</option>
                    <option>Road Marker</option>
                    <option>Hoarding</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </>
          ) : null}

          {source === "Social Media" ? (
            <>
              <div className="col-lg-4 col-sm-6">
                <Form.Group controlId="exampleForm.ControlSelect4">
                  <Form.Label>Sub Source</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    onChange={(e) => setSubType(e.target.value)}
                  >
                    <option value="">Select a Type</option>
                    <option>Facebook</option>
                    <option>Instagram</option>
                    <option>Youtube</option>
                    <option>LinkedIn</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </>
          ) : null}

          {source === "Referral" ? (
            <>
              <div className="col-lg-4 col-sm-6">
                <Form.Group controlId="exampleForm.ControlSelect4">
                  <Form.Label>Sub Source</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    onChange={(e) => setSubType(e.target.value)}
                  >
                    <option value="">Select a Type</option>
                    <option>Client</option>
                    <option>Bank</option>
                    <option>Contractor/Vendor</option>
                    <option>Previous Project Clients</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </>
          ) : null}

          {source === "Walk In" ? (
            <>
              <div className="col-lg-4 col-sm-6">
                <Form.Group controlId="exampleForm.ControlSelect4">
                  <Form.Label>Sub Source</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    onChange={(e) => setSubType(e.target.value)}
                  >
                    <option value="">Select a Type</option>
                    <option>Corporate Office</option>
                    <option>Site Office</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </>
          ) : null}
          {source === "Channel Partner" ? (
            <>
              <div className="col-lg-4 col-sm-6">
                <Form.Group controlId="exampleForm.ControlSelect4">
                  <Form.Label>Sub Source</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    onChange={(e) => setSubType(e.target.value)}
                  >
                    <option value="">Select a Type</option>
                    <option>Individual</option>
                    <option>Company</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </>
          ) : null}
        </div>

        {source === "Channel Partner" ? (
          <>
            <br />
            <div className="row justify-content-center">
              <div className="col-lg-4 col-sm-6">
                <label>Broker Name</label>
                <Form.Control
                  required
                  as="select"
                  onChange={(e) => setBrokerName(e.target.value)}
                >
                  <option value="">Select</option>
                  {[...new Set(brokerNameList)].map(({ brokerName, _id }) => (
                    <option key={_id} value={brokerName}>
                      {brokerName}
                    </option>
                  ))}
                </Form.Control>
              </div>
              <div className="col-lg-4 col-sm-6">
                <label>Broker Company</label>
                <input
                  type="text"
                  className="form-control"
                  name="BrokerCompany"
                  id="outlined-basic"
                  value={brokerCompany}
                  onChange={(e) => setBrokerCompany(e.target.value)}
                />
              </div>
            </div>
          </>
        ) : null}

        {source === "Channel Partner" ? (
          <>
            <br />
            <div className="row justify-content-center">
              <div className="col-lg-4 col-sm-6">
                <label>Broker PAN</label>
                <input
                  type="text"
                  className="form-control"
                  value={brokerPan}
                  name="BrokerPan"
                  id="outlined-basic"
                  onChange={changeBrokerPan}
                  required
                />
                <small id="panMessage" className="text-danger d-none">
                  Must be 10 characters with capitals and numbers only
                </small>
              </div>
              <div className="col-lg-4 col-sm-6">
                <label>Broker RERA</label>
                <input
                  type="text"
                  className="form-control"
                  value={brokerRera}
                  name="BrokerRera"
                  id="outlined-basic"
                  onChange={(e) => setBrokerRera(e.target.value)}
                />
              </div>
            </div>
          </>
        ) : null}

        <br/>

        <div className="row justify-content-center">
          <div className="col-lg-3 col-sm-4">
            <Form.Group controlId="lead person type">
              <Form.Label>Lead Person Type</Form.Label>
              <Form.Control
                required
                onChange={(e) => setLeadPersonType(e.target.value)}
                as="select"
              >
                <option value="">Select an Option</option>
                <option value="Individual">Individual</option>
                <option value="Channel Partner">Channel Partner</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-lg-3 col-sm-4">
            <Form.Group controlId="buying reason">
              <Form.Label>Buying Reason</Form.Label>
              <Form.Control
                required
                onChange={(e) => setBuyingReason(e.target.value)}
                as="select"
              >
                <option value="">Select an Option</option>
                <option value="Investment">Investment</option>
                <option value="Residential">Residential</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-lg-3 col-sm-4">
            <Form.Group controlId="home loan required">
              <Form.Label>Home Loan Required</Form.Label>
              <Form.Control
                required
                onChange={(e) => setHomeLoanRequired(e.target.value)}
                as="select"
              >
                <option value="">Select an Option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Form.Control>
            </Form.Group>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-4">
            <div className="row d-flex">
              <div className="col-12">
                <Form.Group controlId="sites">
                  <Form.Label style={{ marginTop: "12px" }}>Site</Form.Label>
                  <Form.Control
                    required
                    value={site}
                    onChange={(event) => setSite(event.target.value)}
                    as="select"
                  >
                    <option value="">Select a site</option>
                    {siteList?.map((s, i) => {
                      return (
                        <option key={i} value={s.SiteCode + "," + s.SiteName}>
                          {s.SiteName}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="col-4">
            {flat?.map((s, index) => {
              return (
                <div className="row d-flex" key={index}>
                  <div className="col-12">
                    <Form.Group controlId="flat">
                      <Form.Label style={{ marginTop: "12px" }}>
                        Flat Type
                      </Form.Label>
                      <Form.Control
                        required
                        value={bhk}
                        onChange={(event) => setBhk(event.target.value)}
                        as="select"
                      >
                        <option value="">Select a Flat Type</option>
                        <option value="1BHK">1BHK</option>
                        <option value="2BHK">2BHK</option>
                        <option value="3BHK">3BHK</option>
                        <option value="4BHK">4BHK</option>
                        <option value="Duplex">Duplex</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  {/* <div className="col-6">
                    <button className="add-btn mt-4" onClick={() => (flat[0].length > 0 ? addFlat() : null)} style={{ display: index === 4 ? "none" : "inline-block" }}>
                      Add Row
                    </button>

                    <button className="add-btn mt-4" onClick={() => deleteFlat()} style={{ display: index === 0 ? "none" : "inline-block" }}>
                      Delete
                    </button>
                  </div> */}
                </div>
              );
            })}
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-6">
            <Form.Group controlId="exampleForm.ControlSelect2">
              <Form.Label style={{ marginTop: "10px" }}>
                Lead Priority
              </Form.Label>
              <Form.Control
                required
                onChange={(e) => setType(e.target.value)}
                as="select"
              >
                <option value="">Select a priority</option>
                <option value="Hot">Hot</option>
                <option value="Normal">Normal</option>
                <option value="Cold">Cold</option>
              </Form.Control>
            </Form.Group>
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-6">
            <label>Other Requirement</label>
            <input
              type="text"
              className="form-control"
              name="requirement"
              id="outlined-basic"
              onChange={(e) => setRequirement(e.target.value)}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <label>Budget</label>
            <input
              type="text"
              className="form-control"
              name="budget"
              id="outlined-basic"
              onChange={(e) => setBudget(e.target.value)}
              required
            />
          </div>
        </div>
        <br />
        {/* <div className="text-center" style={{ display: disp }}>
          <em style={{ color: "red" }}>{message}</em>
        </div> */}
        <br />

        <div
          style={{
            width: "30%",
            margin: "auto",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button type="submit" className="addlead__btns">
            Submit
          </button>

          <button
            type="reset"
            className="addlead__btns"
            style={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid black",
            }}
            onClick={() => setSource()}
          >
            Reset
          </button>
        </div>
      </form>

      {/* /////////////////Modal for spinner /////////// */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={spinnerModal}
        onClose={() => setSpinnerModal(false)}
        closeAfterTransition
      >
        <Fade in={spinnerModal}>
          <div>
            <ClipLoader
              color="#EE4B46"
              loading={spinnerModal}
              size={70}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default AddLeadForm;
