import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import MaterialTable, { MTableToolbar } from "material-table";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../utils/ModalStyles";
import "./ManageApplications.scss";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";

const ManageApplications = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + userInfo.token;

  const [form, setForm] = useState([]);
  const [status, setStatus] = useState("");
  const [sites, setSites] = useState([]);
  const [snf, setSnf] = useState("");
  const [bpm, setBpm] = useState("");

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/Configuration/Site/getAllSiteNames`, {
        headers: { Authorization: Token },
      })
      .then((response) => {
        // console.log(response)
        setSites(response.data.siteList);
      });
  }, []);

  useEffect(() => {
    if (status === "" && snf === "" && bpm === "") {
      axios
        .get(
          `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/getListOfApplication`,
          { headers: { Authorization: Token } }
        )
        .then((response) => {
          // console.log(response.data)
          setForm(response.data.applicationList);
        });
    } else if (status === "" && snf !== "") {
      axios
        .get(
          `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/getListOfApplication?siteCode=${snf}`,
          { headers: { Authorization: Token } }
        )
        .then((response) => {
          // console.log(response.data)
          setForm(response.data.applicationList);
        });
    } else if (status !== "" && snf === "") {
      axios
        .get(
          `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/getListOfApplication?status=${status}`,
          { headers: { Authorization: Token } }
        )
        .then((response) => {
          // console.log(response.data)
          setForm(response.data.applicationList);
        });
    } else {
      axios
        .get(
          `${BASE_URL}/api/v1/CustomerMgmt/BookingApplication/getListOfApplication?siteCode=${snf}&&status=${status}&&bookingPaymentMode=${bpm}`,
          { headers: { Authorization: Token } }
        )
        .then((response) => {
          // console.log(response.data)
          setForm(response.data.applicationList);
        });
    }
  }, [status, snf, bpm]);

  const reset = () => {
    setStatus("");
    setSnf("");
    setBpm("");
  };

  return (
    <div className="listofbooking">
      <div className="row justify-content-start">
        <Button navigateTo={"/home"} />
        <button
          className="listofbooking__btn"
          onClick={() => {
            navigate("/flatAllotment/manageapplications/statusDashboard");
          }}
        >
          Status Dashboard
        </button>
      </div>

      <br />

      <div className="row container-fluid-justify-content-center">
        <div className="col-12 mt-4">
          <h3>Application Forms</h3>
          <MaterialTable
            data={form}
            title=""
            columns={[
              {
                title: "Application Id",
                field: "applicationId",
                defaultSort: "desc",
                customSort: (a, b) =>
                  a.applicationId.toLowerCase() < b.applicationId.toLowerCase
                    ? -1
                    : 1,
              },
              { title: "Unit Name", field: "unit[unitName]" },
              { title: "Car Parking Number", field: "carParkings.length" },
              { title: "Status", field: "status" },
              { title: "Booking By", field: "bookingByUserFullName" },
              {
                title: "Bank Loan",
                field: "isBankLoanRequested",
                render: (rowData) =>
                  rowData?.isBankLoanRequested ? "Yes" : "No",
              },
            ]}
            options={{
              search: window.innerWidth < 700 ? false : true,
              searchFieldStyle: {
                width: 180,
              },
              actionsColumnIndex: 0,
              paging: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 20, 30],
              actionsCellStyle: {
                width: 120,
                paddingLeft: 30,
              },
              sorting: true,
              headerStyle: {
                backgroundColor: "#EE4B46",
                color: "#fff",
                paddingLeft: "11px",
              },
            }}
            components={{
              Toolbar: (props) => (
                <div className="filters text-left">
                  <MTableToolbar {...props} />

                  <FormControl
                    variant="standard"
                    className={classes.formControl}
                    style={{
                      marginTop: "-65px",
                      marginLeft: "15px",
                      width: "85px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Status
                    </InputLabel>
                    <Select
                      llabelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="all" disabled>
                        All
                      </MenuItem>
                      <MenuItem value="Booking Initiated">
                        Booking Initiated
                      </MenuItem>
                      <MenuItem value="Applicant Added">
                        Applicant Added
                      </MenuItem>
                      <MenuItem value="Booking Payment Received">
                        Booking Payment Received
                      </MenuItem>
                      <MenuItem value="Application Form Generated">
                        Application Form Generated
                      </MenuItem>
                      <MenuItem value="Application Form Received">
                        Application Form Received
                      </MenuItem>
                      <MenuItem value="Provisional Allotment Letter Draft Generated">
                        Provisional Allotment Letter Draft Generated
                      </MenuItem>
                    </Select>
                  </FormControl>

                  {/* <br /> */}

                  <FormControl
                    variant="standard"
                    className={classes.formControl}
                    style={{
                      marginTop: "-65px",
                      marginLeft: "15px",
                      width: "100px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Site Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={snf}
                      onChange={(e) => setSnf(e.target.value)}
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="" disabled>
                        Site Name
                      </MenuItem>
                      {sites.map((t) => (
                        <MenuItem key={t.SiteCode} value={t.SiteCode}>
                          {t.SiteName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {window.innerWidth < 1000 && <br />}

                  <FormControl
                    variant="standard"
                    className={classes.formControl}
                    style={{
                      marginTop: window.innerWidth < 1000 ? "-30px" : "-65px",
                      marginLeft: "15px",
                      width: "180px",
                    }}
                  >
                    <InputLabel
                      id="demo-simple-select-standard-label"
                      style={{ fontSize: "14px" }}
                    >
                      Booking Payment Mode
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={bpm}
                      onChange={(e) => setBpm(e.target.value)}
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="" disabled>
                        Booking Payment Mode
                      </MenuItem>
                      <MenuItem value={"Not Added"}>Not Added</MenuItem>
                      <MenuItem value={"Bank Transfer"}>Bank Transfer</MenuItem>
                      <MenuItem value={"Cheque-DD"}>ChequeDD</MenuItem>
                      <MenuItem value={"Cash"}>Cash</MenuItem>
                    </Select>
                  </FormControl>

                  {window.innerWidth < 1100 && window.innerWidth > 1000 && (
                    <br />
                  )}

                  <FormControl
                    className={classes.formControl}
                    style={{
                      marginTop: window.innerWidth < 1100 ? "-20px" : "-50px",
                      marginLeft: "15px",
                    }}
                  >
                    <button
                      onClick={reset}
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid black",
                        borderRadius: "20px",
                        padding: "5px",
                        width: "150px",
                        margin: "auto",
                      }}
                    >
                      Reset Filter
                    </button>
                  </FormControl>
                </div>
              ),
            }}
            actions={[
              (rowData) => ({
                icon: "remove_red_eye",
                tooltip: "View application",
                onClick: (event, rowData) => {
                  navigate(
                    `/flatAllotment/manageapplications/${rowData.applicationId}`
                  );
                  localStorage.setItem("ActiveKeyApplication", "first");
                  localStorage.setItem("ApplicationNo", rowData.applicationId);
                },
              }),
            ]}
          ></MaterialTable>
        </div>
      </div>
    </div>
  );
};

export default ManageApplications;
