import React from 'react'
import './TDS.scss';


const TDS = () => {
    return (
        <div className='TDS'>
            <h2>Coming Soon</h2>
        </div>
    )
}

export default TDS