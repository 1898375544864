import React from 'react'
import './ListOfBooking.scss';


const ListOfBooking = () => {
    return (
        <div className='list-of-booking'>
            <h2>Coming Soon</h2>
        </div>
    )
}

export default ListOfBooking;