import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import MaterialTable from "material-table";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";
import "./Office.scss";

const Office = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();
  const [officeList, setOfficeList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
   
    axios
      .get(`${BASE_URL}/api/v1/hrmgmt/office/getListOfOffice`, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((response) => {
        if (response.data.status === true) {
          setOfficeList(response?.data?.officeList);
        } else {
          setOfficeList([]);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          confirmButtonText: "Ok",
        });
      });
  }, [loading]);

  return (
    <div className="list-of-office">
      <div className="list-of-office__btns">
        <Button navigateTo={"/home"} />

        <div>
          {(userInfo?.userRole === "Director" ||
            userInfo?.userRole === "HR") && (
            <button
              className="list-of-office__btns__btn mr-1"
              onClick={() => navigate("/office/create-new-office")}
            >
              Create New Office
            </button>
          )}
        </div>
      </div>

      <br />
      <br />

      <h4>List of Office</h4>

      <MaterialTable
        data={officeList}
        title=""
        columns={[
          { title: "Office Code", field: "officeCode" },
          {
            title: "Office Name",
            field: "officeName",
          },
          { title: "Office Address", field: "officeAddress.fullAddress" },
          { title: "Latitude", field: "latitude" },
          { title: "Longitude", field: "longitude" },
        ]}
        actions={[
          {
            icon: "remove_red_eye",
            tooltip: "View Office",
            disabled:
              userInfo?.userRole === "Director" || userInfo?.userRole === "HR"
                ? false
                : true,
            onClick: (event, rowData) => {
              navigate(`/office/${rowData.officeCode}`);
            },
          },
          {
            icon: "delete",
            tooltip: "Delete Office",
            disabled:
              userInfo?.userRole === "Director" || userInfo?.userRole === "HR"
                ? false
                : true,
            onClick: (event, rowData) => {
              Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  axios
                    .delete(`${BASE_URL}/api/v1/hrmgmt/office/deleteOffice`, {
                      data: { officeCode: rowData.officeCode },
                      headers: { Authorization: `bearer ${userInfo.token}` },
                    })
                    .then((response) => {
                      if (response.data.status === true) {
                        Swal.fire({
                          title: "Success",
                          text: response.data.message,
                          icon: "success",
                          confirmButtonText: "Ok",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setLoading(!loading);
                          }
                        });
                      }
                    });
                }
              });
            },
          },
        ]}
        options={{
          search: true,
          searchFieldStyle: {
            width: 180,
          },
          actionsColumnIndex: 0,
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          actionsCellStyle: {
            width: 120,
            paddingLeft: 30,
          },
          sorting: true,
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
            paddingLeft: "11px",
          },
        }}
      ></MaterialTable>
    </div>
  );
};

export default Office;
