import React, { useEffect } from 'react'
import Button from '../../Button/Button'
import '../StockManagement.scss';
import { useState } from 'react';
import { BASE_URL } from '../../../utils/config/url';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form } from 'react-bootstrap';

const CreateSubCategory = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate();

    const [subCategoryCode, setSubCategoryCode] = useState('');
    const [subCategoryName, setSubCategoryName] = useState('');
    const [subCategoryDescription, setSubCategoryDescription] = useState('');
    const [parentCategoryCode, setParentCategoryCode] = useState('')
    const [categoryList, setCategoryList] = useState([])
    const [error, setError] = useState(false)

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/stockmgmtapi/catCategory/getListOfCatCategory`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                if (response.data.status === true) {
                    // console.log(response.data.CatelogueCategory)
                    setCategoryList(response.data.CatelogueCategory)
                } else {
                    setCategoryList([])
                }
            })

    }, [])


    const createSubCategory = (e) => {

        e.preventDefault();

        axios.post(`${BASE_URL}/api/v1/stockmgmtapi/catSubCategory/createCatSubCategory`,
            {
                subCategoryCode,
                subCategoryName,
                subCategoryDescription,
                parentCategoryCode: parentCategoryCode.split(',')[1],
                subCategoryCreator: userInfo.userName,
                subategoryCreatorFullName: userInfo.userFullName
            },
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {

                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: 'Form Submitted Successfully',
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        navigate('/listOfSubCategory')
                    })
                }

            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })

    }


    return (
        <div className='stock-mgmt'>

            <div className='row justify-content-start'>
                <Button navigateTo={1} />
            </div>

            <h2 className='text-center mt-5'>Create Sub Category</h2>

            <form className='container mt-5'
                onSubmit={(e) => (createSubCategory(e))}
            >

                <div className='row m-auto container-fluid justify-content-center'>

                    <div className='col-6 text-left'>
                        <label>Sub-Category Code</label>
                        <input type="text" className='form-control'
                            required
                            value={subCategoryCode}
                            onChange={(e) => {
                                let regex = /^[A-Z]{3,4}$/

                                if (regex.test(e.target.value)) {
                                    setError(false)
                                } else {
                                    setError(true)
                                }
                                setSubCategoryCode(e.target.value)
                            }}
                        />
                        {error && <em style={{ color: '#EE4B46' }}>Sub-Category Code Must consist of 3 or 4 Capital Characters</em>}
                    </div>

                    <div className='col-6 text-left'>
                        <label>Sub-Category Name</label>
                        <input type="text" className='form-control'
                            required
                            value={subCategoryName}
                            onChange={(e) => setSubCategoryName(e.target.value)}
                        />
                    </div>

                </div>

                <br />

                <div className='row m-auto container-fluid justify-content-center'>
                    <div className='col-6 text-left'>
                        <Form.Group controlId="Category Code">
                            <Form.Label>Parent Category</Form.Label>
                            <Form.Control required onChange={(e) => setParentCategoryCode(e.target.value)} as="select">
                                <option value="">Select a source</option>
                                {
                                    categoryList?.map((category, index) => {
                                        return <option key={index} value={`${category.categoryName},${category.categoryCode}`}>{category?.categoryName} - {category?.categoryCode}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>

                <br />

                <div className='row m-auto container-fluid justify-content-center'>

                    <div className='col-9 text-left'>
                        <label>Description</label>
                        <textarea type="text"
                            className='form-control'
                            required
                            value={subCategoryDescription}
                            onChange={(e) => setSubCategoryDescription(e.target.value)}
                        />
                    </div>

                </div>

                <div className='row justify-content-center mt-5'>
                    <div className='col-6 text-center'>
                        <button className='stock-mgmt__btn'
                            type='submit'
                        >Create</button>
                    </div>
                </div>

            </form>

        </div>
    )
}

export default CreateSubCategory