import React, { useEffect, useState } from 'react'
import './DemandDetails.scss';
import {
    Switch,
    createMuiTheme,
    ThemeProvider,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useStyles } from '../../utils/ModalStyles';
import Form from 'react-bootstrap/Form'
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import Swal from 'sweetalert2';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#757ce8",
            main: "#EE4B46",
            dark: "#002884",
            contrastText: "#fff",
        },
    },
});

const DemandDetails = ({ data }) => {

    const classes = useStyles();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [genDate, setGenDate] = useState('')
    const [dueDate, setDueDate] = useState('')
    const [postageStatus, setPostageStatus] = useState(false)
    const [open, setOpen] = useState(false)
    const [courier, setCourier] = useState('')
    const [trackingId, setTrackingId] = useState('')
    const [sendDate, setSendDate] = useState('')
    // const [data , setData] = useState({})

    useEffect(() => {

        let res = dateFormatting(data?.demandGenerationDate)
        setGenDate(res)

        let res2 = dateFormatting(data?.dueDate)
        setDueDate(res2)

    }, [data])


    useEffect(() => {
        if (Object.keys(data).length !== 0) {
            setPostageStatus(data?.sendPost)
            setCourier(data?.courierName)
            setTrackingId(data?.trackingId)
            let res = dateFormatting(data?.sendDate)
            setSendDate(res)
        } else {
            setPostageStatus(false)
        }
    }, [data])

    const dateFormatting = (input) => {

        let d = new Date(input)
        let date = d.getDate()
        if (date < 10) {
            date = `0${date}`
        }
        let m = d.getMonth() + 1
        if (m < 10) {
            m = `0${m}`
        }
        let y = d.getFullYear()

        let final = `${date}/${m}/${y}`
        return final
    }

    const changePostageStatus = (e) => {
        e.preventDefault()

        let finalDate = dateFormatting(sendDate)

        let body = {
            demandId: data?.demandId,
            courierName: courier,
            trackingId: trackingId,
            sendDate: finalDate
        }

        axios.put(`${BASE_URL}/api/v1/CustomerMgmt/Demand/updateCourierDetails`,
            body,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {

                setOpen(false)

                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        reset()
                        window.location.reload()
                    })
                }
            })
            .catch((err) => {
                setOpen(false)
                reset()
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })
    }

    const reset = () => {
        setCourier('')
        setTrackingId('')
        setSendDate('')
    }

    return (
        <>
            <form className='form'>

                <div className="row container-fluid justify-content-center">
                    <div className="col-4">
                        <label>Demand ID</label>
                        <input
                            readOnly
                            className="form-control"
                            value={data?.demandId} />
                    </div>

                    <div className="col-4">
                        <label>Status</label>
                        <input
                            readOnly
                            className="form-control"
                            value={data?.demandStatus} />
                    </div>

                    <div className="col-4">
                        <label>Customer ID</label>
                        <input
                            readOnly
                            className="form-control"
                            value={data?.customerId} />
                    </div>
                </div>

                <br />

                <div className="row container-fluid justify-content-center">
                    <div className="col-4">
                        <label>Demand Type</label>
                        <input
                            readOnly
                            className="form-control"
                            value={data?.demandType} />
                    </div>

                    <div className="col-4">
                        <label>Demand Generation Date</label>
                        <input
                            readOnly
                            className="form-control"
                            value={genDate} />
                    </div>
                </div>

                <br />


                <div className="row container-fluid justify-content-center">
                    <div className="col-4">
                        <label>Due Date</label>
                        <input
                            readOnly
                            className="form-control"
                            value={dueDate} />
                    </div>

                    <div className="col-4">
                        <label>Delayed ?</label>
                        <input
                            readOnly
                            className="form-control"
                            value={data?.isDelayed} />
                    </div>

                    <div className="col-4">
                        <label>Paid ?</label>
                        <input
                            readOnly
                            className="form-control"
                            value={data?.isPaid} />
                    </div>
                </div>

                <br />

                <div className="row container-fluid justify-content-center">
                    <div className="col-6">
                        <label>Description</label>
                        <textarea
                            readOnly
                            className="form-control"
                            value={data?.description} />
                    </div>

                    <div className="col-4">
                        <br/><br/>
                        <label>Demand Letter Link - </label>

                        <a href={data?.demandLetterLink}
                            style={{ marginLeft: '10px' }}
                        >Click Here</a>
                    </div>
                </div>

                <br />
                <br />

                <ThemeProvider theme={theme}>

                    <div className='row container-fluid justify-content-center'
                        style={{ paddingLeft: '100px' }}>
                        <div className='col-4'>
                            <h5>Postage Status</h5>
                        </div>

                        <div className='col-4'>
                            <Switch checked={postageStatus} color="primary"
                                onChange={(e) => {
                                    if (postageStatus === true) {
                                        return
                                    }
                                    setOpen(true)
                                }}
                            />
                        </div>
                    </div>

                    <br />

                    {
                        postageStatus
                        &&

                        <div className='row container-fluid justify-content-center'>
                            <div className='col-4'>
                                <label>Courier Name</label>
                                <input type="text" value={courier} readOnly className='form-control' />
                            </div>

                            <div className='col-4'>
                                <label>Tracking ID</label>
                                <input type="text" value={trackingId} className='form-control' readOnly />
                            </div>

                            <div className='col-4'>
                                <label>Send Date</label>
                                <input type="text" value={sendDate} className='form-control' readOnly />
                            </div>
                        </div>
                    }

                </ThemeProvider>

            </form>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={() => {
                    setPostageStatus(false)
                    setOpen(false)
                    reset()
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <form
                            method='POST'
                            onSubmit={(e) => changePostageStatus(e)}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>

                            <div className="row container-fluid justify-content-center">
                                <div className="col-6">
                                    <Form.Group controlId="payment mode">
                                        <Form.Label>Courier Name</Form.Label>
                                        <Form.Control as="select"
                                            value={courier}
                                            onChange={(e) => setCourier(e.target.value)}
                                            required
                                        >
                                            <option value=''>Select an Option</option>
                                            <option value='DTDC'>DTDC</option>
                                            <option value='Delhivery'>Delhivery</option>
                                            <option value='Indian Post'>Indian Post</option>
                                            <option value='Delivered In-Person'>Delivered In-Person</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>

                                <div className="col-6">
                                    <label>Tracking ID</label>
                                    <input className='form-control'
                                        required
                                        type='text'
                                        value={trackingId}
                                        onChange={(e) => setTrackingId(e.target.value)}
                                    />
                                </div>
                            </div>

                            <br />

                            <div className="row container-fluid justify-content-center">
                                <div className="col-6">
                                    <label>Send Date</label>
                                    <input required
                                        className='form-control'
                                        type='date'
                                        value={sendDate}
                                        onChange={(e) => setSendDate(e.target.value)}
                                    />
                                </div>

                            </div>

                            <br />

                            <div className="row container-fluid justify-content-center">
                                <div className="col-6 text-center">
                                    <button
                                        className="modal__btns"
                                        onClick={() => {
                                            setPostageStatus(false)
                                            setOpen(false)
                                            reset()
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                                <div className="col-6 text-center">
                                    <button
                                        className="modal__btns"
                                        type="submit"
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>

        </>
    )
}

export default DemandDetails