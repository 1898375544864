import React, { useState } from 'react'
import image from '../../assets/images/image.png'
import doc from '../../assets/images/doc.png'
import customer from '../../assets/images/customer.png'
import westroad from '../../assets/images/westroad.png'
import { BASE_URL } from '../../utils/config/url'
import axios from 'axios'
import Swal from 'sweetalert2'
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';


const Attachments = ({ ticket, setTicket, change, setChange }) => {
    // console.log(ticket)

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [attachmentFile, setAttachmentFile] = useState(null)
    const [desc, setDesc] = useState('')
    const [uploadedBy, setUploadedBy] = useState('')
    const [spinnerModal, setSpinnerModal] = useState(false);
    // const [link, setLink] = useState('')


    const dateFormatting = (input) => {

        let date = new Date(input)
        let d = date.getDate()
        let m = new Date(date.getMonth()).toLocaleDateString('en-IN', { month: 'short' })
        let y = date.getFullYear()

        return `${d}-${m}-${y}`
    }

    const uploadAttachment = () => {

        if (!attachmentFile) return;
        setSpinnerModal(true)

        let formData = new FormData();
        formData.append('file', attachmentFile)
        formData.append('ticketId', ticket.ticketId)
        formData.append('description', desc)
        formData.append('uploadedBy', 'westroad')
        formData.append('uploadedByFullName', userInfo.userFullName)

        axios.put(`${BASE_URL}/api/v1/customermgmt/ticket/uploadTicketAttachment`, formData,
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response.data)
                setSpinnerModal(false)
                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 1500
                })
                    .then(() => {
                        setChange(!change)
                        setAttachmentFile(null)
                        setDesc('')
                        // setUploadedBy('')
                        document.getElementById('attfile').value = ''
                    })
            })
            .catch((err) => {
                setSpinnerModal(false)
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error,
                })
            })
    }


    const deleteAttachment = (link) => {

        axios.put(`${BASE_URL}/api/v1/customermgmt/ticket/deleteTicketAttachment`, {
            ticketId: ticket.ticketId,
            link: link
        },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response.data)
                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 1500
                })
                    .then(() => {
                        setChange(!change)
                    })
            })
            .catch((err) => {
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error,
                })
            })

    }

    return (
        <div className='inv-ticket__attachments'>


            <div className='inv-ticket__attachments__card flex-column'>

                <h3 className='text-center'
                    style={{ color: "#EE4B46" }}
                >Upload Attachment</h3>

                <div className='row justify-content-between'>
                    <div className='col-6 text-center'>
                        <input type="file" name="attachment" id="attfile" onChange={(e) => setAttachmentFile(e.target.files[0])} />
                    </div>

                    <div className='col-6 text-center'>
                        <button
                            disabled={!attachmentFile || !desc}
                            style={{ backgroundColor: !attachmentFile || !desc  ? 'grey' : '' }}
                            onClick={uploadAttachment}>Upload</button>
                    </div>
                </div>
                <br />
                <div className='row justify-content-between'>
                    <div className='col-8'>
                        <label>Description</label>
                        <textarea type="text" name="desc" id="desc"
                            className='form-control'
                            value={desc}
                            onChange={(e) => setDesc(e.target.value)} />
                    </div>

                    {/* <div className='col-4'>

                        <label>Uploaded By</label>
                        <select name="uploadedBy" id="uploadedBy"
                            className='form-control'
                            value={uploadedBy}
                            onChange={(e) => setUploadedBy(e.target.value)}>
                            <option value=''>Select a option</option>
                            <option value="customer">Customer</option>
                            <option value="westroad">Westroad</option>

                        </select>

                    </div> */}

                </div>

            </div>




            {
                ticket?.ticketAttachment?.map((tkt, index) => {
                    return (
                        <div key={index} className='row align-items-center inv-ticket__attachments__card'>

                            <div className='col-2 text-center'>
                                <img src={tkt.documentType === 'document' ? doc : image} />
                            </div>

                            <div className='col-5 d-flex flex-column justify-content-start'>
                                <h4>
                                    {
                                        <a href={tkt.attachmentLink}
                                            style={{ fontWeight: 800, color: 'black' }}
                                        >{tkt?.attachmentLink.split('/')[tkt?.attachmentLink.split('/').length - 1]}</a>
                                    }
                                </h4>

                                <div className='d-flex justify-content-between'>
                                    {tkt.uploadDate && <span>{dateFormatting(tkt.uploadDate)}</span>}
                                    {tkt.uploadedBy && <span>Uploaded By : {tkt.uploadedBy}</span>}
                                </div>

                                <p className='mt-2'>{tkt?.attachmentDesc}</p>
                            </div>

                            {tkt?.uploadedBy
                                &&
                                <div className='col-2 text-center'>
                                    <img src={tkt?.uploadedBy === 'customer' ? customer : westroad} />
                                </div>
                            }

                            <div className='col-2 text-center'>
                                <button
                                    onClick={() => {
                                        // setLink(tkt.attachmentLink)
                                        deleteAttachment(tkt.attachmentLink)

                                    }}
                                >Delete</button>
                            </div>

                        </div>
                    )
                })
            }


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={spinnerModal}
                onClose={() => setSpinnerModal(false)}
                closeAfterTransition
            >
                <Fade in={spinnerModal}>

                    <div>
                        <ClipLoader
                            color='#EE4B46'
                            loading={spinnerModal}
                            size={70}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                </Fade>
            </Modal>


        </div>
    )
}

export default Attachments
