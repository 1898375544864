import React, { useContext, useState } from 'react'
import { MdApartment } from 'react-icons/md'
import { TabletContext } from '../../../utils/TabletContext';
import { IoIosArrowRoundForward, IoIosArrowDown } from 'react-icons/io';
import { BsMap } from 'react-icons/bs'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import iconic from '../../../assets/tableView/iconic-logo.png';
import brochure from '../../../assets/tableView/brochure.png';
import amenities from '../../../assets/tableView/amenities.png';
import unitFinder from '../../../assets/tableView/unit-finder.png';
import videos from '../../../assets/tableView/videos.png';
import carousel from '../../../assets/tableView/carousel.png';
import './ProjectDashboard.scss';

import { Fade, Modal } from '@mui/material';
import mapImg from '../../../assets/icons/ProjectDashboard/map.svg';
import storeFrontImg from '../../../assets/icons/ProjectDashboard/storefront (1).svg';
import ProjectModal from '../../../components/ProjectDashboard/ProjectModal';

import { useNavigate } from 'react-router-dom'

const ProjectDashboard = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const site = JSON.parse(localStorage.getItem('site'))


    // console.log(project)
    //console.log(window.screen.width)

    const [options, setOptions] = useState([
        { img: videos, name: 'Videos', path: 'videos' },
        { img: brochure, name: 'Brochure', path: 'brochure' },
        { img: unitFinder, name: 'Unit Finder', path: 'unitfinder' },
        { img: amenities, name: 'Amenities', path: 'amenities' },
    ])

    const [swiperRef, setSwiperRef] = useState(null);
    const [modalTab, setModalTab] = useState(false);
    const [activeTab, setActiveTab] = useState(0)
    //console.log(activeTab)

    const navigate = useNavigate()


    return (
        <div className='pd'>

            <div className='pd_top'>

                <div className='pd_top_left'>
                    <div className='image'>
                        <img src={iconic} alt="" />
                        <div className='d-flex flex-column mx-1'>
                            <span id="display_font">{site?.siteName?.split(' ')[0]}</span>
                            <span id="display_font">{site?.siteName?.split(' ')[1]}</span>
                        </div>
                    </div>


                    <div className='pd_top_left__loc' onClick={() => setModalTab(true)} ><BsMap /> Location & Nearby Places</div>


                    <div className='buttons'>
                        <button className='btn1'>Project Plan <IoIosArrowRoundForward /></button>
                        <button className='btn2'
                            onClick={() => {
                                navigate(`/tabletView/dashboard/${site?.siteCode}/quotation`)
                            }}
                        >Quotation <IoIosArrowRoundForward /></button>
                    </div>

                </div>

                <div className='pd_top_right'>

                    <Swiper
                        onSwiper={setSwiperRef}
                        slidesPerView={1}
                        centeredSlides={false}
                        spaceBetween={2}
                        pagination={{ clickable: true }}
                        navigation={true}
                        modules={[Navigation, Pagination]}
                        className="mySwiper"
                    >
                        {/* <div className='swiper__container'> */}
                        <SwiperSlide>
                            <img src={carousel} alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={carousel} alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={carousel} alt="" />
                        </SwiperSlide>
                        {/* </div> */}
                    </Swiper>

                </div>


            </div>

            <div className='pd_bottom'>

                {
                    options?.map((option, index) => {
                        return <div className='pd_bottom__option' key={index}
                            onClick={() => {
                                navigate(`/tabletView/dashboard/${site?.siteCode}/${option?.path}`)
                            }}
                        >
                            <img src={option.img} alt="" />
                            <span
                                onClick={() => {
                                    navigate(`/tabletView/dashboard/${site?.siteCode}/${option?.path}`)
                                }}
                            >{option.name} <IoIosArrowRoundForward /></span>
                        </div>
                    })
                }
            </div>

            <Modal
                className="modal"
                open={modalTab}
                onClose={() => setModalTab(false)}
                closeAfterTransition
            >
                <Fade in={modalTab}>
                    <div style={{ width: '80%' }}>
                        <div className='pd_top_left__modal'>
                            <div className='pd_top_left__modal__tabs'>
                                <div className={`pd_top_left__modal__tabs__tab ${activeTab === 0 ? 'active' : ''}`} onClick={() => setActiveTab(0)}>
                                    <img src={storeFrontImg} alt='Near By Places' />
                                    <h6>View Nearby Places</h6>
                                </div>
                                <div className={`pd_top_left__modal__tabs__tab ${activeTab === 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>
                                    <img src={mapImg} alt='map' />
                                    <h6>Map View</h6>
                                </div>
                            </div>
                            <div className='pd_top_left__modal__container'
                                style={{ height: '100%' }}
                            >
                                {
                                    activeTab === 0 ? <ProjectModal /> :
                                        <div className="pd_top_left__modal__container__map">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14719.678230102898!2d88.4697760217041!3d22.73123112890618!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f899208d568647%3A0x5f81f4251f3fb0a6!2sLarica%20Township!5e0!3m2!1sen!2sin!4v1697211618355!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{ borderRadius: '1.2rem' }} allowfullscreen="" aria-hidden="false" tabindex="0"
                                            ></iframe>
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>

        </div >
    )
}

export default ProjectDashboard