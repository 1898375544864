import React, { useEffect, useState } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import { useNavigate } from 'react-router-dom';

const Active = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const [costCenterList, setCostCenterList] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/financeMgmt/costCenter/getListOfCostCenter?isActive=true`, {
            headers: {
                Authorization: `Bearer ${userInfo?.token}`
            }
        })
            .then((response) => {
                if (response.data.status) {
                    setCostCenterList(response.data.Cost_Centers)
                } else {
                    setCostCenterList([])
                }
            })

    }, [])

    return (
        <div>

            <MaterialTable
                data={costCenterList}
                title=""
                columns={[

                    {
                        title: "Cost Center Code",
                        field: 'costCenterCode'
                    },
                    {
                        title: "Cost Center Name",
                        field: 'costCenterName'
                    },
                    { title: "Financial Year", field: "finYear" },
                    { title: "Cost Center Type", field: "costCenterType" },
                    { title: "Cost Center Owner", field: "costCenterOwner" },

                ]}

                actions={[
                    {
                        icon: 'remove_red_eye',
                        tooltip: 'View Cost Center',
                        // disabled: (userInfo?.userRole === 'Director' || userInfo?.userRole === "HR" || userInfo?.userRole === "Finance") ? false : true,
                        onClick: (event, rowData) => {
                            navigate(`/finance/costCenters/${rowData.costCenterCode}`)
                        },
                    },

                ]}


                options={{
                    search: true,
                    searchFieldStyle: {
                        width: 180
                    },
                    actionsColumnIndex: 0,
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [5, 10, 20, 30],
                    actionsCellStyle: {
                        width: 120,
                        paddingLeft: 30,
                    },
                    sorting: true,
                    headerStyle: {
                        backgroundColor: "#EE4B46",
                        color: "#fff",
                        paddingLeft: "11px",
                    },
                }}


            ></MaterialTable >

        </div>
    )
}

export default Active