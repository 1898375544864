import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BASE_URL } from '../../utils/config/url'
import axios from 'axios'
import { Paper } from '@mui/material'

const CatalogueItems = () => {

    const { subCategoryCode } = useParams()
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [catalogueItems, setCatalogueItems] = useState([])
    const [message, setMessage] = useState('')

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/stockmgmtapi/catalougeItem/getListOfCatalogueItem?subCategoryCode=${subCategoryCode}`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status === true) {
                    setCatalogueItems(response.data.CatelogueItem)
                    setMessage('')
                } else {
                    setCatalogueItems([])
                    setMessage(response.data.message)
                }
            })

    }, [])


    return (
        <div className='inv-subcat-catalogue'>

            {catalogueItems?.length > 0
                ?
                catalogueItems?.map((catalogueItem, index) => {

                    return <Paper
                        key={index}
                        elevation={3}
                        style={{
                            padding: '20px',
                            margin: '20px'
                        }}
                    >
                        {console.log(catalogueItem.cataloguegueItemName)}
                        <div className='row justify-content-center'>
                            <div className='col-4 d-flex flex-column'>
                                <label>Catalogue Item Code</label>
                                <a href={`/listOfCatalogueItem/${catalogueItem.catalogueItemCode}`}
                                    style={{ cursor: 'pointer', color: '#EE4B46' }}
                                >
                                    <b> {catalogueItem?.catalogueItemCode}</b>
                                </a>
                            </div>

                            <div className='col-4'>
                                <label>Catalogue Item Name</label>
                                <input type="text" className='form-control' value={catalogueItem.catalogueItemName} readOnly />
                            </div>

                            <div className='col-4'>
                                <label>Orderable</label>
                                <input type="text" className='form-control' value={catalogueItem.isOrderable} readOnly />
                            </div>
                        </div>

                    </Paper>

                })
                :
                <h4 className='text-center mt-5'>{message}</h4>
            }


        </div>
    )

}

export default CatalogueItems