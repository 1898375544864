import React, { useEffect, useState } from "react";
import axios from "axios";
import "./InvUserDoc.scss";
import { BASE_URL } from "../../utils/config/url";
import { useParams } from "react-router-dom";

const InvUserDoc = ({ user }) => {
  const [employeeDetails, setEmployeeDetails] = useState({});
  const employeeId = user.employeeId;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
    (() => {
      axios
        .get(
          `${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${employeeId}`,
          {
            headers: { Authorization: `bearer ${userInfo.token}` },
          }
        )
        .then((response) => setEmployeeDetails(response?.data?.employee))
        .catch((e) => console.error(e));
    })();
  }, []);
  console.log("emp", employeeDetails);
  return (
    <div className="mt-5">
      <div className={window.innerWidth < 700 ? "p-2" : "p-4"}>
        <div
          style={{ borderRadius: "20px" }}
          className="user_doc py-5 mb-3 text-center"
        >
          <h4 className="mb-3">Aadhar Card</h4>

          {employeeDetails?.employeeAadharScan ? (
            <>
              <p>
                Aadhar Number: {employeeDetails?.employeeAadhar} <br />
              </p>
              <a
                href={employeeDetails.employeeAadharScan}
                target="_blank"
                rel="noreferrer"
              >
                View Document
              </a>
            </>
          ) : (
            <p>Document Not Uploaded</p>
          )}
        </div>
        <div className="user_doc py-5 mb-3 text-center">
          <h4 className="mb-3">PAN Card</h4>
          {employeeDetails?.employeePANScan ? (
            <>
              <p>Pan Card Number: {employeeDetails?.employeePAN}</p>
              <a
                href={employeeDetails.employeePANScan}
                target="_blank"
                rel="noreferrer"
              >
                View Document
              </a>
            </>
          ) : (
            <p>Document Not Uploaded</p>
          )}
        </div>
        <div className="user_doc py-5 mb-3 text-center">
          <h4 className="mb-3">Appointment Letter</h4>
          {employeeDetails?.employeeAppointmentLetterScan ? (
            <>
              <p>
                Appointment Date:{" "}
                {new Date(employeeDetails?.appointmentDate).toLocaleDateString(
                  "en-in",
                  {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                  }
                )}
              </p>
              <a
                href={employeeDetails.employeeAppointmentLetterScan}
                target="_blank"
                rel="noreferrer"
              >
                View Document
              </a>
            </>
          ) : (
            <p>Document Not Uploaded</p>
          )}
        </div>
        <div className="user_doc py-5 text-center">
          <h4 className="mb-3">Offer Letter</h4>
          {employeeDetails?.employeeOfferLetterScan ? (
            <>
              <p>
                Offer Letter Date:{" "}
                {new Date(employeeDetails?.offerDate).toLocaleDateString(
                  "en-in",
                  {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                  }
                )}
              </p>
              <a
                href={employeeDetails.employeeOfferLetterScan}
                target="_blank"
                rel="noreferrer"
              >
                View Document
              </a>
            </>
          ) : (
            <p>Document Not Uploaded</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvUserDoc;
