import { Typography } from "@mui/material";
import "./PercentageLegalCharges.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";

const PercentageLegalCharges = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { siteCode } = useParams();
 

  const [legalChargePercentage, setLegalChargePercentage] = useState("");

  const handleChange = (e) => {
    setLegalChargePercentage(e.target.value);
  };


  useEffect(() => {  
    axios
      .get(
        `${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        // console.log(response.data);
        setLegalChargePercentage(response.data.site.legalChargePercentage);
      });
  }, []);


  const handleSave = (e) => {
    e.preventDefault();
    axios
      .post(
        `${BASE_URL}/api/v1/Configuration/site/updateLegalChargePercentage`,
        {
          siteCode,
          legalChargePercentage
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        console.log(response.data);
        Swal.fire({
          icon: "success",
          title: "Successfully Updated Legal Charge Percentage",
          showConfirmButton: true,
        });
      });
  };

  return (
    <div>
      <div className="row col-12 mt-2 justify-content-start align-items-center text-center">
        <div className="col-6 align-items-center">
          <Typography className="m-0" variant="h6" component="span">
            Legal Charge Percentage (of Market Value)
          </Typography>
        </div>
        <input
        type="number"
          className="col-1 form-control"
          value={legalChargePercentage}
          onChange={handleChange}
        />
        <span className="ml-2">%</span>
        <div className="save_bt align-items-center">
          <button onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default PercentageLegalCharges;
