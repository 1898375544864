import React, { useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import '../Tabs/Tabs.scss';

const DemandTabs = ({ tabName }) => {

    const [tab, setTab] = useState(localStorage.getItem('ActiveKeyDemand'))

    return (
        <div className="tabs">
            <Nav>

                <Nav.Item
                    onClick={() => {
                        localStorage.setItem("ActiveKeyDemand", "first");
                        setTab('first')
                        tabName('first')
                    }}
                >
                    <Nav.Link className={tab === 'first' ? 'active' : 'inactive'} eventKey="first">
                        Details
                    </Nav.Link>
                </Nav.Item>

                <Nav.Item
                    onClick={() => {
                        localStorage.setItem("ActiveKeyDemand", "second");
                        setTab('second')
                        tabName('second')
                    }}
                >
                    <Nav.Link className={tab === 'second' ? 'active' : 'inactive'} eventKey="second">

                       Payments

                    </Nav.Link>
                </Nav.Item>

            </Nav>

        </div>
    )
}

export default DemandTabs;