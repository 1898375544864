import React, { useState, useEffect } from 'react';
import axios from "axios";
import { BsArrowLeftShort } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../utils/config/url';
import Swal from "sweetalert2"
import './UploadDocument.scss';
import Button from '../../components/Button/Button';

const UploadDocument = () => {
    const [userList, setUserList] = useState([]);
    const [doc, setDoc] = useState(null);
    const [sMember, setSMember] = useState('');
    const [docType, setDocType] = useState('');
    const documentTypes = [
        { docValue: "PAN Card", docType: "userPAN" },
        { docValue: "Aadhar Card", docType: "userAadhar" },
        { docValue: "Appointment Letter", docType: "AppointmentLetter" },
        { docValue: "Offer Letter", docType: "OfferLetter" }
    ]

    const [panNo, setPanNo] = useState('')
    const [aadharNo, setAadharNo] = useState('')

    const selectedDocType = docType.split(", ");
    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));


    useEffect(() => {
        (async () => {
            const res = await axios.get(`${BASE_URL}/api/v1/user/getListOfUsers?isActive=${true}`,
                { headers: { Authorization: `bearer ${userInfo.token}` } });
            const data = res.data.userList
            setUserList(data)
        })()
    }, [])


    const handleSaveDoc = (e) => {

        e.preventDefault();

        if (doc === null) {
            Swal.fire({
                icon: "warning",
                title: "Select a Document First!"
            })
            return;
        }


        const data = new FormData();
        data.append("file", doc);
        data.append("type", selectedDocType[0]);
        data.append("userName", sMember.split(',')[0]);
        axios.post(`${BASE_URL}/api/v1/util/uploaduserdocument`, data,
            { headers: { Authorization: `bearer ${userInfo.token}` } }
        ).then(res => {
            // console.log(res)
            Swal.fire({
                icon: 'success',
                title: 'Document Successfully Uploaded',
                showConfirmButton: false,
                timer: 1900
            }).then(() => {

                updateEmployee()

            })

        }).catch(err => {
            Swal.fire({
                icon: "error",
                title: err.response.data.error
            })
        })
    }


    const updateEmployee = () => {

        axios.put(`${BASE_URL}/api/v1/hrmgmt/employee/updateEmployee`,
            {
                employeeId: sMember.split(',')[1],
                update: {
                    employeePAN: panNo,
                    employeeAadhar: aadharNo
                }
            },
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response.data);
                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    })
                        .then(() => {
                            setSMember('')
                            setDocType('')
                        })
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })

    }

    

    return (
        <div className='outlet_bg'>
            <div className="outlet_container">
                <Button navigateTo={'/home'} />
                <h3 className="my-5" style={{ color: '#EE4B46' }}>Upload Documents</h3>
                <div className='upload_doc_action'>

                    <div className='upload_doc_action_div'>
                        <p className="mb-1">Team Members</p>
                        <select
                            value={sMember}
                            onChange={(e) => setSMember(e.target.value)}>
                            <option
                                // disabled
                                selected
                                className='text-secondary'
                            >
                                -- Select Team Member --
                            </option>
                            {userList.map((ul, i) => <option value={`${ul.userName},${ul.employeeId}`} key={i}>{ul.userFullName}</option>)}
                        </select>
                    </div>

                    <div className='upload_doc_action_div'>
                        <p className="mb-1">Document Type</p>
                        <select
                            value={docType}
                            onChange={(e) => setDocType(e.target.value)}>
                            <option
                                // disabled
                                selected
                                className='text-secondary'
                            >
                                -- Select Document Type --
                            </option>
                            {
                                documentTypes.map((doc, i) =>
                                    <option value={`${doc.docType}, ${doc.docValue}`} key={i}>{doc.docValue}</option>)
                            }
                        </select>
                    </div>

                </div>

                {sMember && docType &&
                    <div className="doc_upload">
                        <h4 className='text-center'>{selectedDocType[1]}</h4>
                        {
                            docType === 'userPAN, PAN Card' || docType === 'userAadhar, Aadhar Card'
                                ?
                                <form className='upload_action'>
                                    <div className='d-flex'>
                                        <input onChange={(e) => setDoc(e.target.files[0])} type="file" required />
                                        <input
                                            className='form-control w-50'
                                            onChange={(e) => {
                                                if (docType === 'userPAN, PAN Card')
                                                    setPanNo(e.target.value)
                                                else setAadharNo(e.target.value)
                                            }}
                                            type="text"
                                            required
                                            placeholder={docType === 'userPAN, PAN Card' ? 'Enter PAN No.' : 'Enter Aadhar Card No.'}
                                        />
                                    </div>
                                    <br />
                                    <div>
                                        <button onClick={(e)=>handleSaveDoc(e)} className="create_btn">Save Document</button>
                                    </div>
                                </form>
                                :
                                <div className='upload_action'>
                                    <div>
                                        <input onChange={(e) => setDoc(e.target.files[0])} type="file" />
                                        <button onClick={(e)=>handleSaveDoc(e)} className="create_btn">Save Document</button>
                                    </div>
                                </div>
                        }



                    </div>}
            </div>
        </div>
    );
};

export default UploadDocument;