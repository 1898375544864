import MaterialTable, { MTableToolbar } from "material-table";
import FormControl from "@mui/material/FormControl";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStyles } from "../../utils/ModalStyles";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";

const DemandList = ({
  openDemands,
  title,
  tab,
  setChange,
  closedDemands,
  openCust,
  setOpenCust,
  openType,
  setOpenType,
  closedCust,
  setClosedCust,
  setClosedType,
  closedType,
  demands,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const customerId = useParams().customerId;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  //reset filter
  const reset1 = () => {
    setOpenCust("");
    setOpenType("");
  };
  const reset2 = () => {
    setClosedCust("");
    setClosedType("");
  };

  const dateFormatting = (date) => {
    let str = new Date(date);

    let istOptions = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    let istDate = str.toLocaleString("en-IN", istOptions);

    return istDate.replace(",", " ");
  };

  //get list of customers
  useEffect(() => {
    (async () => {
      const res = await axios.get(
        `${BASE_URL}/api/v1/CustomerMgmt/Customer/getListOfCustomers?isActive=${true}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      );
      const data = res.data.customerList;
      setCustomers(data);
    })();
  }, [openType, closedType]);

  return (
    <div className="mt-5">
      {/* <h4 className="pb-4">Tickets</h4> */}
      <div className="mt-5">
        {tab === "od" && (
          <>
            <h4>Open Demands</h4>
            <MaterialTable
              data={openDemands}
              title=""
              columns={[
                { title: "Demand Id", field: "demandId" },
                { title: "Demand Type", field: "demandType" },
                { title: "Demand Description", field: "description" },

                { title: "Customer Id", field: "customerId" },

                {
                  title: "Demand Date",
                  defaultSort: "desc",
                  render: (rowData) =>
                    !rowData.demandGenerationDate
                      ? ""
                      : dateFormatting(rowData.demandGenerationDate),
                  customSort: (a, b) =>
                    a.demandGenerationDate < b.demandGenerationDate ? -1 : 1,
                },
                {
                  title: "Due Date",
                  defaultSort: "desc",
                  render: (rowData) =>
                    !rowData.dueDate ? "" : dateFormatting(rowData.dueDate),
                  customSort: (a, b) => (a.dueDate < b.dueDate ? -1 : 1),
                },
              ]}
              options={{
                search: window.innerWidth < 700 ? false : true,
                searchFieldStyle: {
                  width: 180,
                },
                paging: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 20, 30],
                headerStyle: {
                  backgroundColor: "#EE4B46",
                  color: "#fff",
                },
              }}
              actions={[
                {
                  icon: "remove_red_eye",
                  tooltip: "View Demand",
                  onClick: (event, rowData) => {
                    localStorage.setItem("ActiveKeyDemand", "first");
                    localStorage.setItem("DemandPath", "demands");
                    navigate(`/individualDemand/${rowData.demandId}`);
                  },
                },
              ]}
              components={{
                Toolbar: (props) => (
                  <div className="filters text-left">
                    <MTableToolbar {...props} />
                    <FormControl
                      variant="standard"
                      className={classes.formControl}
                      style={{
                        marginTop: "-65px",
                        width: "120px",
                        marginLeft: "15px",
                      }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Demand Type
                      </InputLabel>
                      <Select
                        value={openType}
                        onChange={(e) => setOpenType(e.target.value)}
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">Demand Type</MenuItem>
                        <MenuItem value="SaleAgreement">
                          Sale Agreement
                        </MenuItem>
                        <MenuItem value="PostSaleAgreement">
                          Post Sale Agreement
                        </MenuItem>
                        <MenuItem value="BasicConstructionCharge">
                          Basic Construction Charge
                        </MenuItem>
                        <MenuItem value="ChequeBounceCharge">
                          Cheque Bounce Charge
                        </MenuItem>
                        <MenuItem value="LatePaymentFee">
                          Late Payment Fee
                        </MenuItem>
                        <MenuItem value="AdHocDemand">AdHoc Demand</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl
                      variant="standard"
                      className={classes.formControl}
                      style={{
                        marginTop: "-65px",
                        width: "120px",
                        marginLeft: "15px",
                      }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Customer
                      </InputLabel>
                      <Select
                        value={openCust}
                        onChange={(e) => setOpenCust(e.target.value)}
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">Customer</MenuItem>
                        {customers?.map((s, i) => (
                          <MenuItem value={s.customerId} key={i}>
                            {s.salutation}
                            {s.firstName}
                            {s.lastName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {window.innerWidth < 1000 && <br />}

                    <FormControl
                      className={classes.formControl}
                      style={{
                        marginTop: window.innerWidth < 1000 ? "-20px" : "-50px",
                        marginLeft: "15px",
                      }}
                    >
                      <button
                        onClick={reset1}
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "20px",
                          padding: "2px",
                        }}
                      >
                        Reset Filter
                      </button>
                    </FormControl>
                  </div>
                ),
              }}
            />
          </>
        )}

        {tab === "cd" && (
          <>
            <h4>Closed Demands</h4>
            <MaterialTable
              data={closedDemands}
              title=""
              columns={[
                { title: "Demand Id", field: "demandId" },
                { title: "Demand Type", field: "demandType" },
                { title: "Demand Description", field: "description" },

                { title: "Customer Id", field: "customerId" },

                {
                  title: "Demand Date",
                  defaultSort: "desc",
                  render: (rowData) =>
                    !rowData.demandGenerationDate
                      ? ""
                      : dateFormatting(rowData.demandGenerationDate),
                  customSort: (a, b) =>
                    a.demandGenerationDate < b.demandGenerationDate ? -1 : 1,
                },
                {
                  title: "Payment Date",
                  defaultSort: "desc",
                  render: (rowData) =>
                    !rowData.paymentDate
                      ? ""
                      : dateFormatting(rowData.paymentDate),
                  customSort: (a, b) =>
                    a.paymentDate < b.paymentDate ? -1 : 1,
                },
              ]}
              options={{
                search: window.innerWidth < 700 ? false : true,
                searchFieldStyle: {
                  width: 180,
                },
                paging: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 20, 30],
                headerStyle: {
                  backgroundColor: "#EE4B46",
                  color: "#fff",
                },
              }}
              actions={[
                {
                  icon: "remove_red_eye",
                  tooltip: "View Demand",
                  onClick: (event, rowData) => {
                    localStorage.setItem("ActiveKeyDemand", "first");
                    localStorage.setItem("DemandPath", "demands");
                    navigate(`/individualDemand/${rowData.demandId}`);
                  },
                },
              ]}
              components={{
                Toolbar: (props) => (
                  <div className="filters text-left">
                    <MTableToolbar {...props} />
                    <FormControl
                      variant="standard"
                      className={classes.formControl}
                      style={{
                        marginTop: "-65px",
                        width: "120px",
                        marginLeft: "15px",
                      }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Demand Type
                      </InputLabel>
                      <Select
                        value={closedType}
                        onChange={(e) => setClosedType(e.target.value)}
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">Demand Type</MenuItem>
                        <MenuItem value="SaleAgreement">
                          Sale Agreement
                        </MenuItem>
                        <MenuItem value="PostSaleAgreement">
                          Post Sale Agreement
                        </MenuItem>
                        <MenuItem value="BasicConstructionCharge">
                          Basic Construction Charge
                        </MenuItem>
                        <MenuItem value="ChequeBounceCharge">
                          Cheque Bounce Charge
                        </MenuItem>
                        <MenuItem value="LatePaymentFee">
                          Late Payment Fee
                        </MenuItem>
                        <MenuItem value="AdHocDemand">AdHoc Demand</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl
                      variant="standard"
                      className={classes.formControl}
                      style={{
                        marginTop: "-65px",
                        width: "120px",
                        marginLeft: "15px",
                      }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Customer
                      </InputLabel>
                      <Select
                        value={closedCust}
                        onChange={(e) => setClosedCust(e.target.value)}
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">Customer</MenuItem>
                        {customers?.map((s, i) => (
                          <MenuItem value={s.customerId} key={i}>
                            {s.salutation}
                            {s.firstName}
                            {s.lastName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {window.innerWidth < 1000 && <br />}

                    <FormControl
                      className={classes.formControl}
                      style={{
                        marginTop: window.innerWidth < 1000 ? "-20px" : "-50px",
                        marginLeft: "15px",
                      }}
                    >
                      <button
                        onClick={reset2}
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "20px",
                          padding: "2px",
                        }}
                      >
                        Reset Filter
                      </button>
                    </FormControl>
                  </div>
                ),
              }}
            />
          </>
        )}

        {tab === "third" && (
          <MaterialTable
            data={demands}
            title={title}
            columns={[
              { title: "Demand Id", field: "demandId" },

              {
                title: "Demand Generation Date",
                defaultSort: "desc",
                render: (rowData) =>
                  !rowData.demandGenerationDate
                    ? ""
                    : dateFormatting(rowData.demandGenerationDate),
                customSort: (a, b) =>
                  a.demandGenerationDate < b.demandGenerationDate ? -1 : 1,
              },
              {
                title: "Due Date",
                defaultSort: "desc",
                render: (rowData) =>
                  !rowData.dueDate ? "" : dateFormatting(rowData.dueDate),
                customSort: (a, b) => (a.dueDate < b.dueDate ? -1 : 1),
              },
              { title: "Demand Type", field: "demandType" },
              { title: "Total Amount", field: "demandAmount" },
              { title: "Paid", field: "paidAmount" },
            ]}
            options={{
              search: true,
              headerStyle: {
                backgroundColor: "#EE4B46",
                color: "#fff",
              },
            }}
            actions={[
              {
                icon: "remove_red_eye",
                tooltip: "View Demand",
                onClick: (event, rowData) => {
                  localStorage.setItem("ActiveKeyDemand", "first");
                  localStorage.setItem("DemandPath", `customer-${customerId}`);
                  navigate(`/individualDemand/${rowData.demandId}`);
                },
              },
            ]}
          ></MaterialTable>
        )}
      </div>
    </div>
  );
};

export default DemandList;
