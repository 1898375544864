import React from 'react'
import westroadLogo from '../../../assets/images/westroad_logo.jpg';

const BottomLogo = () => {
    return (
        <div className='bottom-logo'>
            <img src={westroadLogo} alt="" />
        </div>
    )
}

export default BottomLogo