import React from 'react'
import './DebitPayments.scss';
import Button from '../../components/Button/Button';
import { useNavigate  } from 'react-router-dom';

const DebitPayments = () => {

    const navigate = useNavigate();
    
    return (
        <div className='debit'>

            <div className='row d-flex justify-content-between'>
                <Button navigateTo={1} />
                <button className='debit__btn'
                onClick={()=>navigate('/finance/debit-payments/add-debit-payment')}
                >Add Debit Payment</button>
            </div>
        </div>
    )
}

export default DebitPayments