import React, {useEffect, useState} from "react";
import {IoMdArrowBack} from "react-icons/io";
import axios from "axios";
import {BASE_URL} from "../../utils/config/url";
import {useNavigate} from "react-router-dom";
import Button from "../../components/Button/Button";
import {Form} from "react-bootstrap";
import Swal from "sweetalert2";

const CreateTask = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [users, setUsers] = useState([]);
  const [taskType, setTaskType] = useState("");
  const [taskRole, setTaskRole] = useState("");
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [taskDueDate, setTaskDueDate] = useState("");
  const [member, setMember] = useState("Self");
  const [userName, setUserName] = useState(userInfo.userName);
  const [userFullname, setUserFullname] = useState(userInfo.userFullName);

  const navigate = useNavigate();
  const changeUser = (e) => {
    var user = e.target.value;
    if (user === "1") {
      setMember("Self");
      setUserFullname(userInfo.userFullName);
      setUserName(userInfo.userName);
    } else {
      setUserFullname(user.substring(0, user.lastIndexOf(" ")));
      setUserName(user.substring(user.lastIndexOf(" ") + 1));
    }
  };
  useEffect(() => {
    axios.get(`${BASE_URL}/api/v1/user/getListOfUsers?isActive=${true}`, {headers: {Authorization: `bearer ${userInfo.token}`}}).then((response) => {
      // console.log(response)
      setUsers(response.data.userList);
    });
  }, []);
  const createTask = (e) => {
    e.preventDefault();
    axios
      .post(
        `${BASE_URL}/api/v1/hrmgmt/task/createNewTask`,
        {
          taskStatus: "Open",
          taskType: taskType,
          taskCategory: "Manual",
          taskRole: taskRole,
          taskTitle: taskTitle,
          taskDescription: taskDescription,
          dueDate: taskDueDate,
          userFullName: userFullname,
          userName: userName,
          createdBy: userInfo.userName,
          createdByFullName: userInfo.userFullName,
        },
        {headers: {Authorization: `bearer ${userInfo.token}`}}
      )
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            navigate("/tasklist");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      });
  };

  return (
    <div className="outlet_bg">
      <div className="outlet_container">
        <Button navigateTo={1} />
        <div className="row justify-content-center">
          <div className="col-8">
            <h4>Create task</h4>
          </div>
        </div>
        <br />
        <form>
          <div className="row justify-content-center">
            <div className="col-4">
              <label>Task Type</label>
              <select
                class="form-control"
                id="taskType"
                onChange={(e) => {
                  setTaskType(e.target.value);
                }}
              >
                <option selected disabled={true}>
                  ---Select Task Type---
                </option>
                <option default value="Manual Task">
                  Manual Task
                </option>
                <option value="Follow Up">Follow Up</option>
                <option value="Reminder">Reminder</option>
              </select>
            </div>
            <div className="col-4">
              <label>Task Title</label>
              <input type="text" class="form-control" name="taskTitle" id="taskTitle" onChange={(e) => setTaskTitle(e.target.value)} />
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <label>Task Role</label>
              <select
                class="form-control"
                id="taskType"
                style={{marginBottom: 20}}
                onChange={(e) => {
                  setTaskRole(e.target.value);
                }}
              >
                <option selected disabled={true}>
                  ---Select Task Role---
                </option>
                <option default value="Director">
                  Director
                </option>
                <option value="Sales">Sales</option>
                <option value="Engineering">Engineering</option>
                <option value="Operations">Operations</option>
                <option value="Finance">Finance</option>
              </select>
            </div>
            <div className="col-4"></div>
          </div>
          <div className="row justify-content-center">
            <div className="col-8">
              <label>Task Description</label>
              <input type="text" class="form-control" name="taskDescription" id="taskDescription" onChange={(e) => setTaskDescription(e.target.value)} />
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <label>Due Date</label>
              <input type="date" class="form-control" name="taskDueDate" id="taskDueDate" onChange={(e) => setTaskDueDate(e.target.value)} />
            </div>
            <div className="col-4"></div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <input type="radio" name="radio-group" value="1" onClick={(e) => changeUser(e)} defaultChecked />
              <label className="mr-3">Self</label>
              <input type="radio" name="radio-group" value="2" onClick={() => setMember("Other")} />
              <label>Other Team Member</label>
            </div>
            <div className="col-4"></div>
          </div>
          {member === "Other" && (
            <div className="row justify-content-center mt-3">
              <div className="col-4">
                <Form.Group controlId="userName">
                  <Form.Label>Other Team Member</Form.Label>
                  <Form.Control as="select" onChange={(e) => changeUser(e)}>
                    <option>Select a member</option>
                    {users.map((u, i) => (
                      <option key={i} value={u.userFullName + " " + u.userName}>
                        {u.userFullName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-4"></div>
            </div>
          )}
          <br />
          <div className="row container-fluid justify-content-center">
            <div className="col-4 text-right">
              <button className="btn btn-secondary btn-user" type="reset" style={{backgroundColor: "white", color: "black"}}>
                Reset
              </button>
            </div>
            <div className="col-4">
              <button className="btn btn-secondary btn-user" onClick={createTask}>
                Create Task
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateTask;
