import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../utils/config/url";

const Details = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const chequeNo = useParams().chequeNo;
  const MICRNo = localStorage.getItem("MICRNo");

  const [status, setStatus] = useState("");
  const [instrumentType, setInstrumentType] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentCategory, setPaymentCategory] = useState("");
  const [paymentSubCategory, setPaymentSubCategory] = useState("");
  const [bankname, setBankname] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [date, setDate] = useState("");
  const [amount, setAmount] = useState("");
  const [issuedBy, setIssuedBy] = useState("");
  const [issuedTo, setIssuedTo] = useState("");
  const [appId, setAppId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [description, setDescription] = useState("");
  const [receiptNo, setReceiptNo] = useState("");
  const [MICRno, setMICRNO] = useState("");

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/FinanceMgmt/Cheque/getChequeByChequeNo?chequeNo=${chequeNo}&MICRNo=${MICRNo}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        //console.log(response)
        const data = response.data.cheque[0];

        //setChequeNo(data.chequeNo)
        setStatus(data.chequeStatus);
        setInstrumentType(data.instrumentType);
        setPaymentType(data.paymentType);
        setPaymentCategory(data.paymentCategory);
        setPaymentSubCategory(data.paymentSubCategory);
        setBankname(data.chequeBankName);
        setAccountNo(data.chequeAccountNo);
        data.MICRNo ? setMICRNO(data.MICRNo) : setMICRNO("");
        setAmount(data.chequeAmount);
        setIssuedBy(data.issuedBy);
        setIssuedTo(data.issuedTo);
        setReceiptNo(data?.moneyReceiptNo);
        data.applicationId && setAppId(data.applicationId);
        data.customerId && setCustomerId(data.customerId);
        data.chequeDescription && setDescription(data.chequeDescription);

        let d = new Date(data.chequeDate);
        let y = d.getFullYear();
        let m = d.getMonth() + 1;
        let today = d.getDate();

        if (m < 10) {
          m = `0${m}`;
        }

        if (today < 10) {
          today = `0${today}`;
        }

        let final = `${today}-${m}-${y}`;
        setDate(final);
      });
  }, []);

  return (
    <div>
      <form>
        <div className="row container-fluid justify-content-center">
          <div className="col-4">
            <label>Status</label>
            <input readOnly className="form-control" value={status} />
          </div>

          <div className="col-4">
            <label>Cheque No.</label>
            <input readOnly className="form-control" value={chequeNo} />
          </div>

          <div className="col-4">
            <label>MICR No.</label>
            <input readOnly className="form-control" value={MICRno} />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className="col-3">
            <label>Instrument Type</label>
            <input readOnly className="form-control" value={instrumentType} />
          </div>
          <div className="col-3">
            <label>Payment Type</label>
            <input readOnly className="form-control" value={paymentType} />
          </div>
          <div className="col-3">
            <label>Payment Category</label>
            <input readOnly className="form-control" value={paymentCategory} />
          </div>
          <div className="col-3">
            <label>Payment Sub Category</label>
            <input class="form-control" readOnly value={paymentSubCategory} />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className="col-3">
            <label>Bank</label>
            <input class="form-control" readOnly value={bankname} />
          </div>

          <div className="col-3">
            <label>Account No.</label>
            <input class="form-control" readOnly value={accountNo} />
          </div>

          <div className="col-3">
            <label>Cheque Date</label>
            <input class="form-control" readOnly value={date} />
          </div>

          <div className="col-3">
            <label>Amount</label>
            <input class="form-control" readOnly value={amount} />
          </div>
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          <div className="col-4">
            <label>Issued By</label>
            <input class="form-control" readOnly value={issuedBy} />
          </div>

          <div className="col-4">
            <label>Issued To</label>
            <input class="form-control" readOnly value={issuedTo} />
          </div>

          {receiptNo && (
            <div className="col-4">
              <label>Money Receipt No.</label>
              <input class="form-control" readOnly value={receiptNo} />
            </div>
          )}
        </div>

        <br />

        <div className="row container-fluid justify-content-center">
          {paymentCategory === "CustomerPayment" &&
          paymentSubCategory === "BookingPayment" ? (
            <div className="col-4">
              <label>Application Id</label>
              <input class="form-control" readOnly value={appId} />
            </div>
          ) : (
            <div className="col-4">
              <label>Customer Id</label>
              <input class="form-control" readOnly value={customerId} />
            </div>
          )}

          <div className="col-4">
            <label>Description</label>
            <input class="form-control" readOnly value={description} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Details;
