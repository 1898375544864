import React, { useEffect } from 'react'
import axios from 'axios'
import { BASE_URL } from '../../../utils/config/url'
import './VideoGallery.scss';
import { useState } from 'react';
import play from '../../../assets/tableView/play.png'
import { useNavigate } from 'react-router-dom'
import Back from '../Back/Back';

const VideoGallery = () => {

    const site = JSON.parse(localStorage.getItem('site'))
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate()

    const [videos1, setVideos1] = useState([])
    const [videos2, setVideos2] = useState([])
    const [selectedVideo, setSelectedVideo] = useState('')
    const [wordLimit, setWordLimit] = useState(15)

    useEffect(()=>{
        if(window.screen.width < 1600)setWordLimit(13)
        else setWordLimit(17)
    })

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/configuration/site/getListOfVideo/${site?.siteCode}`,
            {
                headers: {
                    Authorization: `bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response)
                if (response.data.status) {
                    let temp = []
                    let temp2 = []
                    response.data.videos.length > 3 ? temp = response.data.videos.slice(0, 3) : temp = response.data.videos
                    response.data.videos.length > 3 ? temp2 = response.data.videos.slice(3) : temp2 = []

                    getVideoThumbnails(temp, temp2)

                } else {
                    setVideos1([])
                    setVideos2([])
                }
            })
            .catch((err) => {
                setVideos1([])
                setVideos2([])
            })

    }, [])

    const getVideoThumbnails = (temp, temp2) => {
        // https://img.youtube.com/vi/<insert-youtube-video-id-here>/sddefault.jpg
        // console.log(temp, temp2)
        // console.log(temp[0].link.split('/')[3])

        temp = temp.map((video) => {
            let id = video.link.split('?')[0].split('/')[3]
            return { ...video, thumbnail: `https://img.youtube.com/vi/${id}/0.jpg`, videoId: id }
        })


        temp2 = temp2.map((video) => {
            let id = video.link.split('?')[0].split('/')[3]
            return { ...video, thumbnail: `https://img.youtube.com/vi/${id}/0.jpg`, videoId: id }
        })

        setVideos1(temp)
        setVideos2(temp2)

    }

    function revealVideo(div) {
        document.getElementById(div).style.display = 'block';
    }


    function hideVideo(div) {
        setSelectedVideo('')
        document.getElementById(div).style.display = 'none';
    }

    return (
        <div className='video-gallery'>

           {/*} <Back navigateTo={`/tabletView/dashboard/${site?.siteCode}`}/>*/}

            <div className='video-gallery__conatiner'>
                <h1>Videos</h1>
                <div className='video-gallery__conatiner__videos'>

                    {
                        (videos1.length === 0 && videos2.length === 0)
                        &&
                        <h1>No Videos Found</h1>
                    }

                    {
                        videos1?.map((video, index) => {
                            return <div className='video-gallery__conatiner__videos-video1' key={video?._id}
                                onClick={() => {
                                    setSelectedVideo(video?.videoId)
                                    revealVideo('video')
                                }}
                            >
                                <img src={video?.thumbnail} alt="" />
                                <img src={play} alt="play-btn" className='play' />
                                <div className='duration'>{video?.duration} mins</div>
                                <div className='comment'>{video?.comment.split(' ').slice(0 , wordLimit).join(' ')}...</div>
                            </div>
                        })
                    }

                </div>

                <div className='video-gallery__conatiner__videos'>
                    {
                        videos2?.map((video, index) => {
                            return <div className='video-gallery__conatiner__videos-video2' key={video?._id}
                                onClick={() => {
                                    setSelectedVideo(video?.videoId)
                                    revealVideo('video')
                                }}
                            >
                                <img src={video?.thumbnail} alt="" />
                                <img src={play} alt="play-btn" className='play' />
                                <div className='duration'>{video?.duration} mins</div>
                                <div className='comment'>{video?.comment}</div>
                            </div>
                        })
                    }
                </div>

            </div>

            <div id="video" className="lightbox" onClick={() => { hideVideo('video', 'youtube') }}>
                <div className="lightbox-container">
                    <div className="lightbox-content">

                        {/* <button onClick={() => { hideVideo('video', 'youtube') }} className="lightbox-close">
                            Close | ✕
                        </button> */}
                        <div className="video-container">

                            <iframe width="960" height="540" src={`https://www.youtube.com/embed/${selectedVideo}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>


                        </div>

                    </div>
                </div>
            </div>

        </div >
    )
}

export default VideoGallery