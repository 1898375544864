import { Paper } from "@mui/material";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import { Modal, Fade } from "@mui/material";
import { MoonLoader } from "react-spinners";

const RegisteredAgreementScan = ({ unitHandover, setUnitHandover }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loader, setLoader] = useState(false);

  const [regUnitAgreementDetails, setRegUnitAgreementDetails] = useState({
    file: "",
    registrationDate: "",
    bookNo: "",
    volumeNo: "",
    pageStart: "",
    pageEnd: "",
    registrationNumber: "",
    year: "",
    registrarName: "",
    registrarPost: "",
    registrarOffice: "",
    uploadedByUserName: userInfo.userName,
    uploadedByUserFullName: userInfo.userFullName,
  });

  const uploadRegisteredUnitAgreement = (e) => {
    e.preventDefault();

    setLoader(true);
    let formData = new FormData();
    formData.append("handoverId", unitHandover?.handoverId);

    for (let key in regUnitAgreementDetails) {
      formData.append(key, regUnitAgreementDetails[key]);
    }

    axios
      .put(
        `${BASE_URL}/api/v1/CustomerMgmt/handover/uploadRegisteredUnitAgreement`,
        formData,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setUnitHandover(response.data.handover);
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const dateFormatting = (input) => {
    if (!input) {
      return "N/A";
    }
    // console.log(input)
    let d = new Date(input);
    // console.log(d)

    let date = d.getDate();
    if (date < 10) {
      date = `0${date}`;
    }
    let m = d.getMonth() + 1;
    if (m < 10) {
      m = `0${m}`;
    }
    let y = d.getFullYear();

    let final;

    final = `${date}/${m}/${y}`;
    return final;
  };

  return (
    <>
      <Paper
        elevation={1}
        style={{
          padding: "30px 20px",
          width: "90%",
          margin: "auto",
        }}
      >
        <div className="row mb-3 mx-2 card-1">
          <div className="col-12 tab-card pt-3 pb-3 text-center">
            {!unitHandover?.registeredUnitAgreementScan
              ?.registeredUnitAgreements3Link ? (
              userInfo.userRole !== "Operations" &&
              userInfo.userRole !== "Director" ? (
                <h5
                  style={{
                    paddingRight: "10px",
                    marginRight: "5px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Registration Agreement Not Uploaded
                </h5>
              ) : (
                <>
                  <h4
                    style={{
                      paddingRight: "10px",
                      marginRight: "5px",
                      fontSize: "22px",
                      fontWeight: "bold",
                      paddingTop: "5px",
                      paddingLeft: "10px",
                    }}
                  >
                    Registration Agreement & Details
                  </h4>
                  <br />
                  <form onSubmit={(e) => uploadRegisteredUnitAgreement(e)}>
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="row justify-content-center"
                    >
                      <div className="col-4">
                        <label>Registration Date</label>
                        <input
                          type="date"
                          required
                          onChange={(e) =>
                            setRegUnitAgreementDetails({
                              ...regUnitAgreementDetails,
                              registrationDate: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </div>

                      <div className="col-4">
                      
                        <input
                          type="file"
                          id="myfile"
                          name="myfile"
                          required
                          onChange={(e) =>
                            setRegUnitAgreementDetails({
                              ...regUnitAgreementDetails,
                              file: e.target.files[0],
                            })
                          }
                          style={{
                            backgroundColor: "white",
                            color: "black",
                          }}
                        />
                      </div>
                    </div>

                    <br />
                    {/* from here */}
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="row justify-content-center"
                    >
                      <div className="col-4">
                        <label>Book No.</label>
                        <input
                          type="text"
                          required
                          onChange={(e) =>
                            setRegUnitAgreementDetails({
                              ...regUnitAgreementDetails,
                              bookNo: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </div>

                      <div className="col-4">
                        <label>Volumn No.</label>
                        <input
                          type="text"
                          required
                          onChange={(e) =>
                            setRegUnitAgreementDetails({
                              ...regUnitAgreementDetails,
                              volumeNo: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </div>
                    </div>

                    <br />

                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="row justify-content-center"
                    >
                      <div className="col-4">
                        <label>Page Start</label>
                        <input
                          type="text"
                          required
                          onChange={(e) =>
                            setRegUnitAgreementDetails({
                              ...regUnitAgreementDetails,
                              pageStart: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </div>

                      <div className="col-4">
                        <label>Page End</label>
                        <input
                          type="text"
                          required
                          onChange={(e) =>
                            setRegUnitAgreementDetails({
                              ...regUnitAgreementDetails,
                              pageEnd: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </div>
                    </div>

                    <br />

                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="row justify-content-center"
                    >
                      <div className="col-4">
                        <label>Registration Number</label>
                        <input
                          type="text"
                          required
                          onChange={(e) =>
                            setRegUnitAgreementDetails({
                              ...regUnitAgreementDetails,
                              registrationNumber: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </div>

                      <div className="col-4">
                        <label>Year</label>
                        <input
                          type="text"
                          required
                          onChange={(e) =>
                            setRegUnitAgreementDetails({
                              ...regUnitAgreementDetails,
                              year: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </div>
                    </div>

                    <br />

                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="row justify-content-center"
                    >
                      <div className="col-4">
                        <label>Registrar Name</label>
                        <input
                          type="text"
                          required
                          onChange={(e) =>
                            setRegUnitAgreementDetails({
                              ...regUnitAgreementDetails,
                              registrarName: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </div>

                      <div className="col-4">
                        <label>Registrar Post</label>
                        <input
                          type="text"
                          required
                          onChange={(e) =>
                            setRegUnitAgreementDetails({
                              ...regUnitAgreementDetails,
                              registrarPost: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </div>

                      <div className="col-4">
                        <label>Registrar Office</label>
                        <input
                          type="text"
                          required
                          onChange={(e) =>
                            setRegUnitAgreementDetails({
                              ...regUnitAgreementDetails,
                              registrarOffice: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </div>
                    </div>

                    <br />
                    {/* till here */}

                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="row justify-content-center"
                    >
                      <div className="col-4">
                        <button
                          className="btn btn-user"
                          type="submit"
                          disabled={
                            unitHandover?.status !== "Ready For Registration"
                          }
                          style={{
                            color: "white",
                            backgroundColor:
                              unitHandover?.status ===
                                "Ready for Registration" ? "grey" : "EE4B46",
                            cursor:
                              unitHandover?.status ===
                                "Ready for Registration" && "default",
                          }}
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              )
            ) : (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      paddingRight: "10px",
                      marginRight: "5px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      paddingTop: "5px",
                      paddingLeft: "10px",
                    }}
                  >
                    Registered Deed for Unit
                  </p>
                  <br />
                  <div>
                    <p>
                      Book No.:{" "}
                      <b>{unitHandover?.registeredUnitAgreementScan?.bookNo}</b>
                      , Volume No.:{" "}
                      <b>
                        {unitHandover?.registeredUnitAgreementScan?.volumeNo}
                      </b>
                      , Pages from{" "}
                      <b>
                        {unitHandover?.registeredUnitAgreementScan?.pageStart}
                      </b>{" "}
                      to{" "}
                      <b>
                        {unitHandover?.registeredUnitAgreementScan?.pageEnd}
                      </b>{" "}
                      Being No.{" "}
                      <b>
                        {
                          unitHandover?.registeredUnitAgreementScan
                            ?.registrationNumber
                        }
                      </b>{" "}
                      of Year{" "}
                      <b>{unitHandover?.registeredUnitAgreementScan?.year}</b>
                      <br />
                      <br />
                      Registrar Name:{" "}
                      <b>
                        {
                          unitHandover?.registeredUnitAgreementScan
                            ?.registrarName
                        }
                      </b>
                      <br />
                      <br />
                      Registrar Post:{" "}
                      <b>
                        {
                          unitHandover?.registeredUnitAgreementScan
                            ?.registrarPost
                        }
                      </b>
                      <br />
                      <br />
                      Registrar office:{" "}
                      <b>
                        {
                          unitHandover?.registeredUnitAgreementScan
                            ?.registrarOffice
                        }
                      </b>
                      <br />
                      <br />
                      Registration Date :{" "}
                      <b>
                        {dateFormatting(
                          unitHandover?.registeredUnitAgreementScan
                            ?.registrationDate
                        )}
                      </b>
                      <br /> <br />
                      Agreement Link :{" "}
                      <b>
                        <a
                          href={
                            unitHandover?.registeredUnitAgreementScan
                              ?.registeredUnitAgreements3Link
                          }
                        >
                          Download
                        </a>
                      </b>{" "}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Paper>

      {/* //////////// Modal for spinner //////////// */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={loader}
        onClose={() => {
          setLoader(false);
        }}
        closeAfterTransition
      >
        <Fade in={loader}>
          <div>
            <MoonLoader
              color="red"
              className="spinner"
              loading={loader}
              size={70}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default RegisteredAgreementScan;
