export const convertCamelCaseToTitleCase=(text)=> {
    if(!text){
      return ''
    }
    // Split the text by uppercase letters
    const words = text.split(/(?=[A-Z])/);
  
    // Convert the first letter of each word to uppercase and the rest to lowercase
    const titleCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  
    // Join the words with a space
    const titleCaseText = titleCaseWords.join(' ');
  
    return titleCaseText;
  }

export function formatDate(dateString) {
    let date = new Date(dateString);

    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let year = date.getFullYear();

    let formattedDate = day + '-' + month + '-' + year;

    return formattedDate; // Outputs: 12-02-2024
}