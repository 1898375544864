import React from 'react'
import MaterialTable from "material-table";

const LatePayments = ({ latePayments, processedlatePayments }) => {
    
    return (
        <div>

            <br />
            <br />

            <MaterialTable
                data={latePayments}
                title={<p>Late Payments - <b>Unbilled</b></p>}//"Late Payments - Unbilled"

                columns={
                    [
                        { title: 'Demand ID', field: 'demandId' },
                        { title: 'Demand Type ', field: 'demandType' },
                        {
                            title: 'Date',
                            render: (rowData) => (!rowData.calculationDate ? "" : rowData.calculationDate.substring(8, 10) + "-" + rowData.calculationDate.substring(5, 7) + "-" + rowData.calculationDate.substring(0, 4) + ", " + rowData.calculationDate.substring(11, 13) + ":" + rowData.calculationDate.substring(14, 16)),
                            customSort: (a, b) => (a.calculationDate < b.calculationDate ? -1 : 1),
                        },
                        { title: 'Amount', field: 'latePaymentAmount' },

                    ]
                }
                options={{
                    search: true,
                    actionsColumnIndex: 0,
                    headerStyle: {
                        backgroundColor: '#EE4B46',
                        color: '#fff',
                    }
                }}


            ></MaterialTable>

            <br />
            <br />

            <MaterialTable
                data={processedlatePayments}
                title={<p>Late Payments - <b> Demand Generated</b></p>} //"Late Payments - Demand Generated"

                columns={
                    [
                        { title: 'Demand ID', field: 'demandId' },
                        { title: 'Demand Type ', field: 'demandType' },
                        {
                            title: 'Date',
                            render: (rowData) => (!rowData.calculationDate ? "" : rowData.calculationDate.substring(8, 10) + "-" + rowData.calculationDate.substring(5, 7) + "-" + rowData.calculationDate.substring(0, 4) + ", " + rowData.calculationDate.substring(11, 13) + ":" + rowData.calculationDate.substring(14, 16)),
                            customSort: (a, b) => (a.calculationDate < b.calculationDate ? -1 : 1),
                        },
                        { title: 'Amount', field: 'latePaymentAmount' },

                    ]
                }
                options={{
                    search: true,
                    actionsColumnIndex: 0,
                    headerStyle: {
                        backgroundColor: '#EE4B46',
                        color: '#fff',
                    }
                }}


            ></MaterialTable>
        </div>
    )
}

export default LatePayments