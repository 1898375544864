import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";

const CoApplicants = ({ invCustomer }) => {
  const [coApplicants, setCoApplicants] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  //get Co-applicants of the customer
  useEffect(() => {
    (async () => {
      const res = await axios.get(
        `${BASE_URL}/api/v1/CustomerMgmt/Applicant/getCoApplicants/${invCustomer?.applicationId}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      );
      if (res.data.status === true) {
        // console.log(res.data)
        setCoApplicants(res.data.applicantList);
      }
    })();
  }, [invCustomer]);

  return (
    <div className="coApplicant">
      {coApplicants?.length === 0 ? (
        <h3 style={{ textAlign: "center", color: "#EE4B4C" }}>
          No Co-Applicant Available
        </h3>
      ) : (
        coApplicants?.map((a, index, key) => {
          let ad = a.applicantAddress;
          let cd = a.correspondentAddress;

          return (
            <Paper
              elevation={2}
              style={{
                padding: "20px",
                width: "90%",
                margin: "auto",
                borderRadius: "20px",
              }}
              key={key}
            >
              <h3 style={{ color: "#EE4B4C" }}>
                Co-Applicant {index + 1} Data
              </h3>

              <br />
              <br />

              <div className="row justify-content-center">
                <div className="col-xl-4 col-sm-8">
                  <label>Application ID</label>
                  <input
                    type="text"
                    className="form-control"
                    value={a.applicationId}
                    readOnly
                  />
                </div>
                <div className="col-xl-4 col-sm-8">
                  <label>Applicant ID</label>
                  <input
                    type="text"
                    className="form-control"
                    value={a.applicantId}
                    readOnly
                  />
                </div>
                <div className="col-xl-4 col-sm-8">
                  <label>Applicant Type</label>
                  <input
                    type="text"
                    className="form-control"
                    value={a.applicantType}
                    readOnly
                  />
                </div>
              </div>

              <br />

              <div className="row justify-content-center">
                <div className="col-xl-3 col-sm-6">
                  <label>Salutation</label>
                  <input
                    type="text"
                    className="form-control"
                    value={a.salutation}
                    readOnly
                  />
                </div>
                <div className="col-xl-3 col-sm-6">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    id="firstName"
                    required
                    value={a.firstName}
                    readOnly
                  />
                </div>

                <div className="col-xl-3 col-sm-6">
                  <label>Middle Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="middleName"
                    id="middleName"
                    required
                    value={a.middleName}
                    readOnly
                  />
                </div>
                <div className="col-xl-3 col-sm-6">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    id="lastName"
                    required
                    value={a.lastName}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-xl-4 col-sm-8">
                  <label>Spouse Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="sname"
                    id="sname"
                    required
                    value={a.spouseName}
                    readOnly
                  />
                </div>
                <div className="col-xl-4 col-sm-8">
                  <label>Father's Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="fname"
                    id="fname"
                    required
                    value={a.fatherName}
                    readOnly
                  />
                </div>
                <div className="col-xl-4 col-sm-8">
                  <label>Religion</label>
                  <input
                    type="text"
                    className="form-control"
                    name="religion"
                    id="religion"
                    required
                    value={a.religion}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-xl-4 col-sm-8">
                  <label>Gender</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={a.applicantGender}
                  />
                </div>
                <div className="col-xl-4 col-sm-8">
                  <label>Nationality</label>
                  <input
                    type="text"
                    className="form-control"
                    name="nationality"
                    id="nationality"
                    required
                    value={a.nationality}
                    readOnly
                  />
                </div>
                <div className="col-xl-4 col-sm-8">
                  <label>Mobile</label>
                  <input
                    type="number"
                    className="form-control"
                    name="mobile"
                    id="mobile"
                    required
                    value={a?.applicantMobile}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-xl-4 col-sm-8">
                  <label>Whatsapp</label>
                  <input
                    type="number"
                    className="form-control"
                    name="whatsapp"
                    id="whatsapp"
                    value={a.applicantWhatsapp}
                    readOnly
                  />
                </div>
                <div className="col-xl-4 col-sm-8">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    required
                    value={a.applicantEmail}
                    readOnly
                  />
                </div>
                <div className="col-xl-4 col-sm-8">
                  <label>Aadhar</label>
                  <input
                    type="number"
                    className="form-control"
                    name="aa"
                    id="aa"
                    required
                    value={a.applicantAadhar}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-xl-4 col-sm-8">
                  <label>PAN</label>
                  <input
                    type="text"
                    className="form-control"
                    name="pan"
                    id="pan"
                    required
                    value={a.applicantPAN}
                    readOnly
                  />
                </div>
                <div className="col-xl-4 col-sm-8">
                  <label>Occupation Type</label>
                  <input
                    type="text"
                    className="form-control"
                    name="oct"
                    id="oct"
                    required
                    value={a.occupationType}
                    readOnly
                  />
                </div>
                <div className="col-xl-4 col-sm-8">
                  <label>Occupation</label>
                  <input
                    type="text"
                    className="form-control"
                    name="oc"
                    id="oc"
                    required
                    value={a.occupation}
                    readOnly
                  />
                </div>
              </div>

              <br />
              <br />

              {/* Applicant Address */}
              <div className="row justify-content-center">
                <div className="col-12">
                  <h4>Applicant Address</h4>
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-xl-6 col-sm-12">
                  <label>Full Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="fa1"
                    id="fa1"
                    required
                    value={ad.fullAddress}
                    readOnly
                  />
                </div>
                <div className="col-xl-6 col-sm-12">
                  <label>Landmark</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lm1"
                    id="lm1"
                    required
                    value={ad.landmark}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-xl-4 col-sm-8">
                  <label>City</label>
                  <input
                    type="text"
                    className="form-control"
                    name="ct1"
                    id="ct1"
                    required
                    value={ad.city}
                    readOnly
                  />
                </div>
                <div className="col-xl-4 col-sm-8">
                  <label>Pincode</label>
                  <input
                    type="number"
                    className="form-control"
                    name="pc1"
                    id="pc1"
                    required
                    value={ad.pinCode}
                    readOnly
                  />
                </div>
                <div className="col-xl-4 col-sm-8">
                  <label>State</label>
                  <input
                    type="text"
                    className="form-control"
                    name="st1"
                    id="st1"
                    value={ad.state}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-xl-4 col-sm-8">
                  <label>Post Office</label>
                  <input
                    type="text"
                    className="form-control"
                    name="st1"
                    id="st1"
                    value={ad.postOffice}
                    readOnly
                  />
                </div>
                <div className="col-xl-4 col-sm-8">
                  <label>Police Station</label>
                  <input
                    type="text"
                    className="form-control"
                    name="st1"
                    id="st1"
                    value={ad.policeStation}
                    readOnly
                  />
                </div>
              </div>

              <br />
              <br />

              <div className="row justify-content-center">
                <div className="col-12">
                  <h4>Correspondent Address</h4>
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-xl-6 col-sm-12">
                  <label>Full Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="fa2"
                    id="fa2"
                    value={cd.fullAddress}
                    readOnly
                  />
                </div>
                <div className="col-xl-6 col-sm-12">
                  <label>Landmark</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lm2"
                    id="lm2"
                    value={cd.landmark}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-xl-4 col-sm-8">
                  <label>City</label>
                  <input
                    type="text"
                    className="form-control"
                    name="ct2"
                    id="ct2"
                    value={cd.city}
                    readOnly
                  />
                </div>
                <div className="col-xl-4 col-sm-8">
                  <label>Pincode</label>
                  <input
                    type="number"
                    className="form-control"
                    name="pc2"
                    id="pc2"
                    value={cd.pinCode}
                    readOnly
                  />
                </div>
                <div className="col-xl-4 col-sm-8">
                  <label>State</label>
                  <input
                    type="text"
                    className="form-control"
                    name="st2"
                    id="st2"
                    value={cd.state}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-xl-4 col-sm-8">
                  <label>Post Office</label>
                  <input
                    type="text"
                    className="form-control"
                    name="st1"
                    id="st1"
                    value={cd.postOffice}
                    readOnly
                  />
                </div>
                <div className="col-xl-4 col-sm-8">
                  <label>Police Station</label>
                  <input
                    type="text"
                    className="form-control"
                    name="st1"
                    id="st1"
                    value={cd.policeStation}
                    readOnly
                  />
                </div>
              </div>
            </Paper>
          );
        })
      )}
    </div>
  );
};

export default CoApplicants;
