import { Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { dateFormatting } from '../../utils/dateFormatting';
import Swal from 'sweetalert2';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import { Form } from 'react-bootstrap';


const CarParkings = ({ invCustomer , setInvCustomer}) => {

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    // console.log(invCustomer)
    const [modal, setModal] = useState(false)
    const [spinnerModal, setSpinnerModal] = useState(false)
    const [siteList, setSiteList] = useState([])
    const [siteCode, setSiteCode] = useState('')
    const [cpList, setCpList] = useState([])
    const [cpName, setCpName] = useState('')
    const [phaseList, setPhaseList] = useState([])
    const [phaseCode, setPhaseCode] = useState([]);
    const [cptList, setCptList] = useState([]);
    const [cptCode, setCptCode] = useState([]);
    const [phaseName, setPhaseName] = useState('');
    const [siteName, setSiteName] = useState('');
    const [carParkingTypeName, setCarParkingTypeName] = useState('');
    const [carParkingTypeCode, setCarParkingTypeCode] = useState('');
    const [id, setId] = useState('')

    // get all site name
    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/Configuration/Site/getAllSites`, {
            headers: { Authorization: `bearer ${userInfo.token}` },
        })
            .then((res) => {
                if (res.data.status) {
                    // console.log(res)
                    setSiteList(res.data.siteArray);
                } else {
                    setSiteList([])
                }
            })

    }, []);

    useEffect(() => {

        if (siteCode) {

            let site = {};

            siteList?.forEach((site) => {
                if (site?.siteCode === siteCode) {
                    setPhaseList(site?.phases)
                }
            })

            // setPhaseList(site?.phases)

            axios.get(`${BASE_URL}/api/v1/Configuration/carparkingtype/getListOfCarParkingType/${siteCode}`, {
                headers: { Authorization: `bearer ${userInfo.token}` },
            })
                .then((response) => {
                    if (response.data.status) {
                        setCptList(response.data.carParkingTypeList)
                    } else {
                        setCptList([])
                    }
                })
        } else {
            setPhaseList([])
            setCptList([])
        }


    }, [siteCode]);

    // console.log(phaseList, cptList)

    const searchCarParkings = () => {

        if (!siteCode || !phaseCode || !carParkingTypeCode) return;

        axios.get(`${BASE_URL}/api/v1/Configuration/carParking/getListOfCarParking?siteCode=${siteCode}&phaseCode=${phaseCode}&status="Available"&carParkingTypeCode=${carParkingTypeCode}`, {
            headers: { Authorization: `bearer ${userInfo.token}` },
        })
            .then((response) => {
                if (response?.data?.status) {
                    setCpList(response?.data?.carParkingList)
                } else {
                    setCpList([])
                }
            })
            .catch((err) => {
                setCpList([])
            })

    }

    // console.log(cpList)

    const reset = () => {
        setPhaseList([])
        setCptList([])
        setCpList([])
        setPhaseCode('')
        setCptCode('')
        setCpName('')
        setSiteCode('')
    }

    const allotCarParking = () => {

        if (!cpName) return;

        setModal(false)
        setSpinnerModal(true)

        axios.put(`${BASE_URL}/api/v1/customermgmt/customer/allotCarParking`, {
            customerId: invCustomer?.customerId,
            carParkingName: cpName.split(',')[0],
            allottedBy: userInfo?.userName,
            _id: id
        }, {
            headers: { Authorization: `bearer ${userInfo.token}` },
        })
            .then((response) => {
                setSpinnerModal(false)
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                        .then(() => {
                            setInvCustomer(response.data.customer)
                            reset()
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                    })
                }
            })
            .catch((err) => {
                setSpinnerModal(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error,
                })
            })

    }

    return (
        <div>


           {/* <Paper elevation={2} style={{ padding: "20px" }}>
                <h4>Car Parking Details</h4>

                <br />

                <div className="row justify-content-center">
                    <div className="col-xl-5 col-sm-10">
                        <label>Car Parking Gross Total</label>
                        <input className="form-control" value={invCustomer?.carParkingGrossTotal} readOnly />
                    </div>
                    <div className="col-xl-5 col-sm-10">
                        <label>Car Parking GST</label>
                        <input className="form-control" value={invCustomer?.carParkingGSTTotal} readOnly />
                    </div>
                </div>

                <br />

                <div className="row justify-content-center">
                    <div className="col-xl-5 col-sm-10">
                        <label>No. of Car Parkings</label>
                        <input className="form-control" value={invCustomer?.carParkings?.length} readOnly />
                    </div>
                    <div className="col-xl-5 col-sm-10">
                        <label>Total Car Parking Cost</label>
                        <input className="form-control" value={invCustomer?.carParkingTotal} readOnly />
                    </div>
                </div>
            </Paper>

    <br /> */}
            {invCustomer?.carParkings?.length === 0 && <h5 className='text-center'>No Car Parking Available</h5>}

            {
                invCustomer?.carParkings?.map((carParking, index) => {
                    return <Paper key={index} elevation={2} style={{ padding: "20px" }}>
                    <div className='row justify-content-between align-items-center'> 
                        <h4 className='ml-5'>Car Parking Name : <b style={{ color: '#EE4B46'}}>{carParking?.carParkingName}</b></h4>

                        {
                            carParking?.isAlloted === false
                            &&
                            <>

                                <div className="row justify-content-center mr-5">
                                    <div className="col-12 text-center">
                                        <button onClick={() => {
                                            setSiteName(carParking?.siteName)
                                            setPhaseName(carParking?.phaseName)
                                            setCarParkingTypeName(carParking?.carParkingTypeName)
                                            setSiteCode(carParking?.siteCode)
                                            setPhaseCode(carParking?.phaseCode)
                                            setCarParkingTypeCode(carParking?.carParkingTypeCode)
                                            setId(carParking?._id)
                                            setModal(true)
                                        }}>Allot Car Parking</button>
                                    </div>
                                </div>
                            </>
                            
                        }
                       
                    </div>

                        <br />

                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-sm-10">
                                <label>Car Parking Type Code</label>
                                <input className="form-control" value={carParking?.carParkingTypeCode} readOnly />
                            </div>
                            <div className="col-xl-5 col-sm-10">
                                <label>Car Parking Type Name</label>
                                <input className="form-control" value={carParking?.carParkingTypeName} readOnly />
                            </div>
                        </div>

                        <br />

                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-sm-10">
                                <label>Car Parking Gross Price</label>
                                <input className="form-control" value={carParking?.carParkingGrossPrice} readOnly />
                            </div>
                            <div className="col-xl-5 col-sm-10">
                                <label>Car Parking GST</label>
                                <input className="form-control" value={carParking?.carParkingGST} readOnly />
                            </div>
                        </div>

                        <br />

                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-sm-10">
                                <label>Car Parking Total Price</label>
                                <input className="form-control" value={carParking?.carParkingPrice} readOnly />
                            </div>
                            <div className="col-xl-5 col-sm-10">
                                <label>Alloted By</label>
                                <input className="form-control" value={carParking?.allottedByFullName} readOnly />
                            </div>
                        </div>

                        <br />

                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-sm-10">
                                <label>Car Parking Allotment Date</label>
                                <input className="form-control" value={dateFormatting(carParking?.allotmentDate)} readOnly />
                            </div>
                        </div>

                    </Paper>
                })
            }

            {/* /////////////////Modal for spinner /////////// */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={spinnerModal}
                onClose={() => setSpinnerModal(false)}
                closeAfterTransition
            >
                <Fade in={spinnerModal}>

                    <div>
                        <ClipLoader
                            color='#EE4B46'
                            loading={spinnerModal}
                            size={70}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                </Fade>
            </Modal>

            {/* /////////////// Modal for car parking allotment ////////// */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={modal}
                onClose={() => {
                    setModal(false)
                    reset()
                }}
                closeAfterTransition
            >
                <Fade in={modal}>

                    <div className='paper w-50'>
                        <div className='row d-flex justify-content-center'>
                            <div className='col-6 text-center'>
                                <Form.Group controlId="siteName">
                                    <Form.Label>Site</Form.Label>
                                    <Form.Control as="input" required
                                        value={siteName}
                                        readOnly
                                    /> 
                                </Form.Group>
                            </div>

                            <div className='col-6 text-center'>
                                <Form.Group controlId="phaseName">
                                    <Form.Label>Phase</Form.Label>
                                    <Form.Control as="input" required
                                        value={phaseName}
                                        readOnly
                                    />
                                </Form.Group>
                            </div>
                        </div>

                        <br />

                        <div className='row justify-content-center'>
                            <div className='col-6'>
                                <Form.Group controlId="run for">
                                    <Form.Label>Car Parking Type</Form.Label>
                                    <Form.Control as="input" required
                                        value={carParkingTypeName}
                                        readOnly
                                    />
                                </Form.Group>
                            </div>


                            <div className='col-6 mt-4'>
                                <button className='invCustomer__btn'
                                    onClick={searchCarParkings}
                                >Search Car Parkings</button>
                            </div>

                        </div>

                        <br />

                        {
                            cpList.length === 0
                                ?
                                <h5 className='text-center'>No Car Parkings Found</h5>
                                :
                                <div className='row justify-content-center'>
                                    <div className='col-6'>
                                        <Form.Group controlId="run for">
                                            <Form.Label>Car Parking</Form.Label>
                                            <Form.Control as="select" required
                                                value={cpName}
                                                onChange={(e) => {
                                                    setCpName(e.target.value)
                                                }}
                                            >
                                                <option value="">Select a Option</option>
                                                {
                                                    cpList?.map((cp, i) => {
                                                        return <option key={i} value={`${cp?.carParkingName},${cp?._id}`}>{cp?.carParkingName}</option>
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </div>

                                </div>
                        }

                        <br />
                        <br />

                        <div className='row justify-content-center'>
                            <div className='col-6 text-center'>
                                <button className='payroll__btn'
                                    type='click'
                                    onClick={allotCarParking}
                                >Allot Car Parking</button>
                            </div>
                        </div>
                    </div>

                </Fade>
            </Modal>

        </div>
    )
}

export default CarParkings;