import React from 'react'
import './ActionIcons.scss';


const ActionIcons = ({ setOpen, setPurpose, lead }) => {
  return (
    <div>
      <button className={lead.isOpen === false ? 'close__btn__disable' : 'close__btn'}
        disabled={lead.isOpen === false}
        onClick={(e) => {
          setOpen(true)
          setPurpose('Close Lead Connect')
        }}
      >Close</button>
    </div>
  )
}

export default ActionIcons