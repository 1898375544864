import React, { useState } from "react";
import "./AddNewEducationDetails.scss";
import { FaPlusCircle } from "react-icons/fa";
import { AiFillCheckCircle } from "react-icons/ai";
import upload from "../../../assets/images/upload.png";
import axios from "axios";
import { BASE_URL } from "../../../utils/config/url";
import Swal from "sweetalert2";
import { convertCamelCaseToTitleCase } from "../../../utils/helper";


const AddNewEducationDetails = ({setLoading, userInfo, employeeId}) => {
  const [showForm, setShowForm] = useState(false);

  const [error, setError] = useState({});
  const [educationDetail, setEducationDetail] = useState({
    qualification: "",
    schoolInstitute: "",
    universityBoard: "",
    scoreType: "",
    score: "",
    yearOfPassing: "",
    city: "",
    State: "",
    country: "",
    degreeScan: "",
    marksheetScan: "",
  });


  const validateAllInputs = () => {
    let isValid = true;
    for (let key in educationDetail) {
      if (
        educationDetail[key] === "" &&
        key !== "degreeScan" &&
        key !== "marksheetScan"
      ) {
        isValid = false;
        setError((prev) => ({ ...prev, [key]: `Required` }));
      } else {
        setError((prev) => ({ ...prev, [key]: "" }));
      }
    }
    return isValid;
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setEducationDetail((prevDetail) => ({
      ...prevDetail,
      [id]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { id, files } = e.target;
    // Assuming you only want the first file selected
    setEducationDetail((prevDetail) => ({
      ...prevDetail,
      [id]: files[0],
    }));
  };

  const handleAddNewEducationDetails = () => {
    setShowForm(!showForm);
  };
  const Reset = () => {
    setEducationDetail({
      qualification: "",
      schoolInstitute: "",
      universityBoard: "",
      scoreType: "",
      score: "",
      yearOfPassing: "",
      city: "",
      State: "",
      country: "",
      degreeScan: "",
      marksheetScan: "",
    });
    setShowForm(false);
  };

  const handleUploadDetails = (e) => {
    e.preventDefault();
    if (!validateAllInputs()) {
      return;
    }
    if (
      educationDetail.degreeScan === "" ||
      educationDetail.marksheetScan === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please upload Degree and Marksheet Scan",
      });
      return;
    }
    const formData = new FormData();
    formData.append("qualification", educationDetail.qualification);
    formData.append("schoolInstitute", educationDetail.schoolInstitute);
    formData.append("universityBoard", educationDetail.universityBoard);
    formData.append("scoreType", educationDetail.scoreType);
    formData.append("score", educationDetail.score);
    formData.append("yearOfPassing", educationDetail.yearOfPassing);
    formData.append("city", educationDetail.city);
    formData.append("State", educationDetail.State);
    formData.append("country", educationDetail.country);
    formData.append("degreeScan", educationDetail.degreeScan);
    formData.append("marksheetScan", educationDetail.marksheetScan);
    formData.append("employeeId", employeeId);

    axios
      .put(
        `${BASE_URL}/api/v1/hrmgmt/employee/addEducationalDetails`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        }).then(function () {
          Reset();
          setLoading((prev) => !prev);
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        });
      });
  };

  return (
    <div className="AddNewEducationDetails">
      <button
        type="button" // Correct the type attribute to "button"
        onClick={handleAddNewEducationDetails}
        className="AddNewEducationDetails__addEducationBtn"
      >
        Add New Education Details <FaPlusCircle style={{ marginLeft: "5px" }} />
      </button>
      {showForm && (
        <>
          <form className={`AddNewEducationDetails__form`}>
            <div className="row justify-content-center">
              <div className="col-2">
                <label htmlFor="qualification">Qualification*</label>
                <select
                  value={educationDetail.qualification}
                  onChange={handleInputChange}
                  className="form-control"
                  id="qualification"
                >
                  <option value="">Select Qualification</option>
                  <option value="10th">10th</option>
                  <option value="12th">12th</option>
                  <option value="Graduation">Graduation</option>
                  <option value="Post Graduation">Post Graduation</option>
                  <option value="Diploma">Diploma</option>
                </select>
                <span className="error">
                  {error?.qualification &&
                    convertCamelCaseToTitleCase(error.qualification)}
                </span>
              </div>
              <div className="col-5">
                <label htmlFor="SchoolInstitute">School / Institute*</label>
                <input
                  value={educationDetail.schoolInstitute}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control"
                  id="schoolInstitute"
                />
                <span className="error">
                  {error?.schoolInstitute &&
                    convertCamelCaseToTitleCase(error.schoolInstitute)}
                </span>
              </div>
              <div className="col-5">
                <label htmlFor="universityBoard">Board / University*</label>
                <input
                  value={educationDetail.universityBoard}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control"
                  id="universityBoard"
                />
                <span className="error">
                  {error?.universityBoard &&
                    convertCamelCaseToTitleCase(error.universityBoard)}
                </span>
              </div>
            </div>
            <br />
            <div className="row justify-content-center">
              <div className="col-2">
                <label htmlFor="scoreType">Score Type*</label>
                <select
                  value={educationDetail.scoreType}
                  onChange={handleInputChange}
                  className="form-control"
                  id="scoreType"
                >
                  <option value="">Select Score Type*</option>
                  <option value="Percentage">Percentage</option>
                  <option value="CGPA">CGPA</option>
                </select>
                <span className="error">
                  {error?.scoreType &&
                    convertCamelCaseToTitleCase(error.scoreType)}
                </span>
              </div>
              <div className="col-2">
                <label htmlFor="score">Score*</label>
                <input
                  value={educationDetail.score}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control"
                  id="score"
                />
                <span className="error">
                  {error?.score && convertCamelCaseToTitleCase(error.score)}
                </span>
              </div>
              <div className="col-2">
                <label htmlFor="yearOfPassing">Year of Passing*</label>
                <input
                  value={educationDetail.yearOfPassing}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control"
                  id="yearOfPassing"
                />
                <span className="error">
                  {error?.yearOfPassing &&
                    convertCamelCaseToTitleCase(error.yearOfPassing)}
                </span>
              </div>
              <div className="col-3">
                <label htmlFor="city">City*</label>
                <input
                  value={educationDetail.city}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control"
                  id="city"
                />
                <span className="error">
                  {error?.city && convertCamelCaseToTitleCase(error.city)}
                </span>
              </div>
              <div className="col-3">
                <label htmlFor="State">State*</label>
                <input
                  value={educationDetail.State}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control"
                  id="State"
                />
                <span className="error">
                  {error?.State && convertCamelCaseToTitleCase(error.State)}
                </span>
              </div>
            </div>
            <br />
            <div className="row justify-content-start">
              <div className="col-3">
                <label htmlFor="country">Country*</label>
                <input
                  value={educationDetail.country}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control"
                  id="country"
                />
                <span className="error">
                  {error?.country && convertCamelCaseToTitleCase(error.country)}
                </span>
              </div>
            </div>

            <div className="AddNewEducationDetails__form__container">
              <label className="AddNewEducationDetails__form__container__upload">
                {educationDetail.degreeScan === "" && (
                  <>
                    <img
                      style={{ width: "50px", margin: "auto" }}
                      src={upload}
                      alt=""
                    />
                    <p>Upload Degree Scan</p>
                    <small>(only pdf supported)</small>
                  </>
                )}
                {educationDetail.degreeScan !== "" && (
                  <div className="row justify-content-center align-items-center mt-4">
                    <AiFillCheckCircle size={30} color="green" />
                    <p>{educationDetail.degreeScan.name}</p>
                  </div>
                )}

                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChange}
                  id="degreeScan"
                ></input>
              </label>
              <label className="AddNewEducationDetails__form__container__upload">
                {educationDetail.marksheetScan === "" && (
                  <>
                    <img
                      style={{ width: "50px", margin: "auto" }}
                      src={upload}
                      alt=""
                    />
                    <p>Upload Marksheet Scan</p>
                    <small>(only pdf supported)</small>
                  </>
                )}
                {educationDetail.marksheetScan !== "" && (
                  <div className="row justify-content-center align-items-center mt-4">
                    <AiFillCheckCircle size={30} color="green" />
                    <p>{educationDetail.marksheetScan.name}</p>
                  </div>
                )}

                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChange}
                  id="marksheetScan"
                ></input>
              </label>
            </div>
            <div className={`AddNewEducationDetails__form__Btn`}>
              <div className={`AddNewEducationDetails__form__Btn__uploadBtn`}>
                <button type="button" onClick={handleUploadDetails}>
                  Upload Educational Details
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default AddNewEducationDetails;
