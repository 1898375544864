import React, { useEffect, useState } from "react";
import "./InvPaymentTerm.scss";
import Button from "../../components/Button/Button";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import { useParams } from "react-router-dom";
import { Switch, createMuiTheme, ThemeProvider } from "@material-ui/core";
import Swal from "sweetalert2";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useStyles } from "../../utils/ModalStyles";
import CheckList from "./CheckList";
import { MoonLoader } from "react-spinners";
import { dateFormatting } from "../../utils/dateFormatting";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#EE4B46",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
});

const InvPaymentTerm = () => {
  const classes = useStyles();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const paymentTermId = useParams().paymentTermId;

  const [sname, setSname] = useState("");
  const [paymentTerms, setPaymentTerms] = useState({});
  const [termItems, setTermItems] = useState([]);
  const [pc, setPc] = useState("");
  const [siteCode, setSiteCode] = useState("");
  const [paymentTermsId, setPaymentTermsId] = useState("");
  const [currentMilestone, setCurrentMilestone] = useState("");
  const [phaseHandOver, setPhasehandOver] = useState("");
  const [milestoneDate, setMilestoneDate] = useState("");
  const [serial, setSerial] = useState("");
  const [loader, setLoader] = useState(false);

  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  const [file, setFile] = useState("");

  let maxdate = new Date();

  let dd2 = maxdate.getDate();
  let mm2 = maxdate.getMonth() + 1;
  let yyyy2 = maxdate.getFullYear();
  if (dd2 < 10) {
    dd2 = "0" + dd2;
  }
  if (mm2 < 10) {
    mm2 = "0" + mm2;
  }

  maxdate = yyyy2 + "-" + mm2 + "-" + dd2;

  useEffect(() => {
    getPaymentTermsByPaymentTermsId();
  }, []);

  const getPaymentTermsByPaymentTermsId = () => {
    axios
      .get(
        `${BASE_URL}/api/v1/Configuration/PaymentTerms/getPaymentTermsByPaymentTermsId/${paymentTermId}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        // console.log(response)

        let pt = response.data.paymentTerms;

        setPaymentTerms(pt);

        setSname(pt.siteName);
        setSiteCode(pt.siteCode);
        setPc(pt?.phaseCode);
        setPaymentTermsId(pt.paymentTermsId);
        setCurrentMilestone(pt.currentMileStone);
        setPhasehandOver(pt.phaseHandOverStarted);
        setTermItems(pt.termItems);
      });
  };

  const markPaymentTermComplete = () => {
    let date = dateFormatting(milestoneDate);

    let body = {
      paymentTermsId: paymentTermId,
      serial: serial,
      completionMarkedByFullName: userInfo.userFullName,
      completionMarkedByUserName: userInfo.userName,
      milestoneCompletionDate: date,
    };

    // console.log(body)
    setModal2(false);
    setLoader(true);

    axios
      .put(
        `${BASE_URL}/api/v1/Configuration/PaymentTerms/markPaymentTermItemDone`,
        body,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        setLoader(false);
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setSerial("");
            setMilestoneDate("");

            getPaymentTermsByPaymentTermsId();
          });
        }
      })
      .catch((err) => {
        setSerial("");
        setMilestoneDate("");
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const initiatePhaseHandover = () => {
    axios
      .put(
        `${BASE_URL}/api/v1/configuration/paymentTerms/InitiatePhaseHandover`,
        {
          paymentTermsId: paymentTermId,
          phaseHandoverInitiatedByUserName: userInfo.userName,
          phaseHandoverInitiatedByUserFullName: userInfo.userFullName,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            getPaymentTermsByPaymentTermsId();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const uploadMileStonePicture = (e, serial) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("file", file);
    formData.append("paymentTermsId", paymentTermId);
    formData.append("serial", serial);

    axios
      .put(
        `${BASE_URL}/api/v1/Configuration/PaymentTerms/uploadMileStonePicture`,
        formData,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setFile("");
            getPaymentTermsByPaymentTermsId();
          });
        }
      })
      .catch((err) => {
        setFile("");
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  return (
    <div className="Invpaymentterms">
      <Button navigateTo={"/configurations/paymentTerms"} />

      <br />
      <br />

      <div className="Invpaymentterms__form">
        <div className="row pt-3 justify-content-center">
          <div className="col-4">
            <label>Site Name</label>
            <input className="form-control" value={sname} readOnly />
          </div>
          <div className="col-4">
            <label>Site Code</label>
            <input className="form-control" value={siteCode} readOnly />
          </div>
          <div className="col-4">
            <label>Phase Code</label>
            <input className="form-control" value={pc} readOnly />
          </div>
        </div>

        <br />

        <div className="row pt-3 justify-content-center">
          <div className="col-4">
            <label>Payment Terms ID</label>
            <input className="form-control" value={paymentTermsId} readOnly />
          </div>

          <div className="col-4">
            <label>Current Milestone</label>
            <input className="form-control" value={currentMilestone} readOnly />
          </div>
        </div>

        <div className="Invpaymentterms__form__table">
          <div className=" mt-2 container-fluid justify-content-center">
            <div className="heading">
              <h4>Term Items</h4>
            </div>

            {termItems.map((termItem, index) => {
              return (
                <div className="row pt-4 justify-content-center" key={index}>
                  <div className="col-8">
                    <div className="row">
                      <div className="col-2">
                        <label>S No.</label>
                        <input
                          type="number"
                          className="form-control"
                          name="slno"
                          id="slno"
                          required
                          value={index + 1}
                          readOnly
                        />
                      </div>

                      <div className="col-8">
                        <label>Description</label>
                        <input
                          type="text"
                          className="form-control"
                          name="desc"
                          id="desc"
                          required
                          autoComplete="off"
                          value={termItem.description}
                          readOnly
                        />
                      </div>

                      <div className="col-2 p-0">
                        <label>Percentage</label>
                        <input
                          type="number"
                          className="form-control"
                          name="percentage"
                          id="percentage"
                          required
                          value={termItem.percentage}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-1 p-0">
                    <br />
                    <ThemeProvider theme={theme}>
                      {termItem.completionStatus === true ? (
                        <>
                          <Switch checked color="primary" />
                        </>
                      ) : index + 1 === 11 ? (
                        <></>
                      ) : (
                        <Switch
                          checked={termItem.completionStatus}
                          color="primary"
                          onChange={(e) => {
                            if (index === termItems.length - 1) {
                              let incompleted = termItems.find((item) => {
                                return item?.completionStatus === false;
                              });
                              if (
                                incompleted ||
                                (userInfo?.userRole != "Engineering" &&
                                  userInfo?.userRole != "Director")
                              ) {
                                return;
                              }
                              initiatePhaseHandover();
                            } else {
                              setSerial(index + 1);
                              setModal1(true);
                            }
                          }}
                        />
                      )}
                    </ThemeProvider>
                  </div>
                  <div className="col-3 p-0">
                    {termItem.completionStatus === true && (
                      <>
                        <label>Completed by and Date</label>
                        <input
                          className="form-control"
                          type="text"
                          readOnly
                          value={
                            termItem.completionStatus === true
                              ? `${
                                  termItem.completionMarkedByFullName
                                } on ${dateFormatting(
                                  termItem.milestoneCompletionDate
                                )}`
                              : ""
                          }
                        />
                      </>
                    )}
                  </div>

                  {termItems.completionStatus === true && (
                    <div className="row pt-4 pb-5 container-fluid">
                      <div className="col-3">
                        <label>Milestone Completion Date</label>
                        <input
                          className="form-control"
                          type="text"
                          readOnly
                          value={dateFormatting(
                            termItems.milestoneCompletionDate
                          )}
                        />
                      </div>

                      <div className="col-3">
                        <label>Completion Marked By</label>
                        <input
                          className="form-control"
                          type="text"
                          readOnly
                          value={termItems.completionMarkedByFullName}
                        />
                      </div>

                      <div className="col-6">
                        {termItems.milestonePictureLink === null ? (
                          <>
                            <form
                              onSubmit={(e) =>
                                uploadMileStonePicture(e, index + 1)
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingTop: "30px",
                              }}
                            >
                              <input
                                className="form-control-file"
                                type="file"
                                id="myfile"
                                name="myfile"
                                required
                                onChange={(e) => setFile(e.target.files[0])}
                              />

                              <button className="modal__btns" type="submit">
                                Upload
                              </button>
                            </form>
                          </>
                        ) : (
                          <>
                            <br />
                            <br />
                            <a href={termItems.milestonePictureLink}>
                              View Picture
                            </a>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal1}
        onClose={() => {
          setSerial("");
          setMilestoneDate("");
          setModal1(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal1}>
          <div className={classes.paper}>
            <form
              className="container"
              style={{
                width: "120%",
                paddingRight: "50px",
              }}
              onSubmit={(e) => {
                e.preventDefault();
                setModal2(true);
                setModal1(false);
              }}
            >
              <div className="row container-fluid justify-content-center">
                <div className="col-12">
                  <h5>Milestone Completion Date</h5>
                  <input
                    type="date"
                    className="form-control"
                    max={maxdate}
                    value={milestoneDate}
                    required
                    onChange={(e) => setMilestoneDate(e.target.value)}
                  />
                </div>
              </div>

              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-6">
                  <button className="modal__btns" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal2}
        onClose={() => {
          setSerial("");
          setMilestoneDate("");
          setModal2(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal2}>
          <div className={classes.paper}>
            <h5>Are you sure you want to mark this milestone complete ?</h5>
            <br />
            <div className="row container-fluid justify-content-center">
              <div className="col-6 text-right">
                <button
                  className="modal__btns"
                  onClick={markPaymentTermComplete}
                >
                  Yes
                </button>
              </div>
              <div className="col-6 text-left">
                <button
                  className="modal__btns"
                  onClick={() => {
                    setSerial("");
                    setMilestoneDate("");
                    setModal2(false);
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      {/* //////////// Modal for spinner //////////// */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={loader}
        onClose={() => {
          setLoader(false);
        }}
        closeAfterTransition
      >
        <Fade in={loader}>
          <div>
            <MoonLoader
              color="red"
              className="spinner"
              loading={loader}
              size={70}
            />
          </div>
        </Fade>
      </Modal>

      <CheckList
        paymentTerms={paymentTerms}
        setPaymentTerms={setPaymentTerms}
      />
    </div>
  );
};

export default InvPaymentTerm;
