import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BASE_URL } from '../../utils/config/url'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { Bar } from 'react-chartjs-2'
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: '10px',
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "#EE4B46",
        color: "white",
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px",
        width: "100"

    }
}));

const WeeklyTaskClosureCount = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    const [taskLabel, setTaskLabel] = useState([]);
    const [userName, setUserName] = useState('');
    const [userList, setUserList] = useState([]);
    const [taskCount, setTaskCount] = useState([]);

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/user/getListofUsers?isActive=true`,
            {
                headers: {
                    Authorization: `bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status) {
                    setUserList(response.data.userList)
                } else {
                    setUserList([])
                }
            })

    }, [])

    useEffect(() => {

        let url = `${BASE_URL}/api/v1/hrmgmt/task/weeklyTaskClosureCount?`;

        if (userName) {
            url += `username=${userName}`
        }

        axios.get(url,
            {
                headers: {
                    Authorization: `bearer ${userInfo.token}`
                }
            })
            .then((response) => {
                // console.log(response.data)
                if (response.data.status) {
                    let labels = [];
                    let counts = [];
                    response?.data?.report?.forEach((t) => {
                        labels.push(t.weekNo)
                        counts.push({ created: t.createdTasks, closed: t.closedTasks })
                    })

                    setTaskLabel(labels)
                    setTaskCount(counts)
                } else {
                    setTaskLabel([])
                    setTaskCount([])
                }
            })

    }, [userName])

    return (
        <div className='chart'>

            <div className='chart__header'>
                <h4>Created Task vs Closed Task Count</h4>
                <FormControl sx={{ m: 1, minWidth: 140, marginBottom: '10px' }}>

                    <InputLabel id="demo-simple-select-helper-label" style={{ color: 'black', fontSize: '18px', paddingTop: '5px' }}>
                        Username <FilterAltRoundedIcon style={{ marginLeft: '10px' }}
                        />
                    </InputLabel>

                    <Select
                        value={userName}
                        input={<BootstrapInput />}
                        onChange={(e) => setUserName(e.target.value)}
                    >
                        <MenuItem value=''>Select</MenuItem>
                        {
                            userList?.map((user, index) => {
                                return <MenuItem key={index} value={user?.userName}>{user?.userName}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </div>

            <Bar
                data={{
                    labels: taskLabel,
                    datasets: [{
                        label: 'Created Task',
                        data: taskCount.map((task) => (task?.created)),
                        backgroundColor: '#f78e8b',
                        borderWidth: 1
                    },
                    {
                        label: 'Closed Task',
                        data: taskCount.map((task) => (task?.closed)),
                        backgroundColor: '#65f76d',
                    }]

                }}

                height={100}
                width={500}
                options={{
                    responsive: true,
                    maintainAspectRatio: true
                }}
            />

        </div>
    )
}

export default WeeklyTaskClosureCount