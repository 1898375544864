import { useState } from 'react';
import './ProjectModa.scss';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import airplanemode from '../../assets/icons/ProjectDashboard/airplanemode_active.svg';
import train from '../../assets/icons/ProjectDashboard/directions_subway.svg';
import historyEduImg from '../../assets/icons/ProjectDashboard/history_edu.svg';
import schoolImg from '../../assets/icons/ProjectDashboard/school.svg';
import localMallImg from '../../assets/icons/ProjectDashboard/local_mall.svg';
import storeFrontImg from '../../assets/icons/ProjectDashboard/storefront.svg';
import localCafeImg from '../../assets/icons/ProjectDashboard/local_cafe.svg';
import restaurantImg from '../../assets/icons/ProjectDashboard/restaurant.svg';




const ProjectModal = () => {
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    return (
        <div className='projectModal'>
            <h2>Nearby Places</h2>
            <div className='projectModal__tabs'>
                <Box sx={{ width: '100%' }}>
                    <Tabs
                        sx={{
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#F9211A',
                                height: '.21rem',
                                borderRadius: '4px'
                            },
                            '& .MuiTab-root.Mui-selected': {
                                color: '#606060'
                            },
                            '& .MuiButtonBase-root': {
                                color: '#A9A8A8',
                                fontWeight: 700,
                                fontSize: '15px',
                                textTransform: 'unset'
                            }
                        }}
                        value={tabValue}
                        onChange={handleChange}
                        aria-label="basic tabs example">
                        <Tab label="Connectivity" {...a11yProps(0)} />
                        <Tab label="Education" {...a11yProps(1)} />
                        <Tab label="Services" {...a11yProps(2)} />
                        <Tab label="Shopping & Dine" {...a11yProps(3)} />
                        <Tab label="Landmarks" {...a11yProps(4)} />
                    </Tabs>
                    <CustomTabPanel value={tabValue} index={0}>
                        <div className='projectModal__tabs__tab'>
                            <div className='projectModal__tabs__tab__item'>
                                <img src={airplanemode} alt="airplanemode" />
                                <div>
                                    <h6>Kolkata International Airport</h6>
                                    <span>22 min</span>
                                </div>
                            </div>
                            <hr />
                            <div className='projectModal__tabs__tab__item'>
                                <img src={train} alt="train" />
                                <div>
                                    <h6>Barasat Station</h6>
                                    <span>3 min</span>
                                </div>
                            </div>
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                        <div className='projectModal__tabs__tab'>
                            <div className='projectModal__tabs__tab__item'>
                                <img src={historyEduImg} alt="airplanemode" />
                                <div>
                                    <h6>Adamas University & World School</h6>
                                    <span>1 min</span>
                                </div>
                            </div>
                            <hr />
                            <div className='projectModal__tabs__tab__item'>
                                <img src={schoolImg} alt="train" />
                                <div>
                                    <h6>Proposed Barasat Medical College</h6>
                                    <span>1 min</span>
                                </div>
                            </div>
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={3}>
                        <div>
                            <h5>Supermarkets & Malls</h5>
                            <div className='projectModal__tabs__tab'>
                                <div className='projectModal__tabs__tab__item'>
                                    <img src={historyEduImg} alt="airplanemode" />
                                    <div>
                                        <h6>Adamas University & World School</h6>
                                        <span>1 min</span>
                                    </div>
                                </div>
                                <hr />
                                <div className='projectModal__tabs__tab__item'>
                                    <img src={schoolImg} alt="train" />
                                    <div>
                                        <h6>Proposed Barasat Medical College</h6>
                                        <span>1 min</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <h5>Dining</h5>
                            <div className='projectModal__tabs__tab'>
                                <div className='projectModal__tabs__tab__item'>
                                    <img src={localCafeImg} alt="localCafeImg" />
                                    <div>
                                        <h6>DYU Art Cafe</h6>
                                        <span>3 min</span>
                                    </div>
                                </div>
                                <hr />
                                <div className='projectModal__tabs__tab__item'>
                                    <img src={restaurantImg} alt="restaurantImg" />
                                    <div>
                                        <h6>Paradise Biryani</h6>
                                        <span>4 min</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CustomTabPanel>
                </Box>
            </div>
        </div>
    )
}

export default ProjectModal;

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box py={2} height='30rem' >
                    {children}
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}