import React from 'react'
import { useParams } from 'react-router-dom'
import Doc from './Doc';

const SupportingDoc = ({ claim }) => {

    const { claimId } = useParams();
    console.log(claim)

    return (
        <div className='mt-3'>

            <h4 className='text-center' style={{ color: `#EE4B46`, fontWeight: 700 }}>{claimId}</h4>

            <div className='d-flex flex-column justify-content-center mt-4'>

                {
                    claim?.supportingDocument?.length === 0
                        ?
                        <p style={{ textAlign: 'center', fontSize: '20px' }}><em>No Supporting document is added by Employee</em></p>
                        :
                        <>
                            {
                                claim?.supportingDocument?.map((doc) => {

                                   return <Doc doc={doc} />

                                })
                            }


                        </>
                }


            </div>

        </div>
    )
}

export default SupportingDoc