import React from 'react'
import MaterialTable from "material-table";

const DemandPayments = ({ data }) => {

    return (
        <>
            <form className='form'>

                <div className="row container-fluid justify-content-center">
                    <div className="col-4">
                        <label>Total Demand</label>
                        <input
                            readOnly
                            className="form-control"
                            value={data?.demandAmount} />
                    </div>

                    <div className="col-4">
                        <label>Sub Total</label>
                        <input
                            readOnly
                            className="form-control"
                            value={data?.subTotalAmount} />
                    </div>

                    <div className="col-4">
                        <label>GST</label>
                        <input
                            readOnly
                            className="form-control"
                            value={data?.gstAmount} />
                    </div>
                </div>

                <br />

                <div className="row container-fluid justify-content-center">

                    <div className="col-4">
                        <label>GST Percentage</label>
                        <input
                            readOnly
                            className="form-control"
                            value={data?.gstPercentage} />
                    </div>

                    <div className="col-4">
                        <label>Amount Paid</label>
                        <input
                            readOnly
                            className="form-control"
                            value={data?.paidAmount} />
                    </div>

                    <div className="col-4">
                        <label>Amount Remaining</label>
                        <input
                            readOnly
                            className="form-control"
                            value={data?.remainderAmount} />
                    </div>
                </div>

                <br />
                <br />

                <div style={{ width: '95%', margin: 'auto' }}>
                    <MaterialTable
                        data={data?.payments}
                        title="Payments"
                        columns={
                            [
                                { title: 'Receipt No.', field: 'moneyReceiptNo' },
                                { title: 'Payment Mode', field: 'paymentMode' },
                                { title: 'Amount', field: 'paymentAmount' },
                                {
                                    title: 'Payment Date',
                                    render: (rowData) => (!rowData.paymentDate ? "" : rowData.paymentDate.substring(8, 10) + "-" + rowData.paymentDate.substring(5, 7) + "-" + rowData.paymentDate.substring(0, 4) + ", " + rowData.paymentDate.substring(11, 13) + ":" + rowData.paymentDate.substring(14, 16)),
                                    customSort: (a, b) => (a.paymentDate < b.paymentDate ? -1 : 1),
                                }
                            ]
                        }
                        options={{
                            search: true,
                            actionsColumnIndex: 0,
                            headerStyle: {
                                backgroundColor: '#EE4B46',
                                color: '#fff',
                            }
                        }}


                    ></MaterialTable>
                </div>
            </form>

        </>

    )
}

export default DemandPayments