import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Button from "../../components/Button/Button";
import { BASE_URL } from "../../utils/config/url";
import { useNavigate } from "react-router-dom";

const AddSalesCode = () => {
  const [salesCode, setSalesCode] = useState("");
  const [salesCodeTitle, setSalesCodeTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [unitName, setUnitName] = useState([""]);
  const [description, setDescription] = useState("");
  const datas = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + datas.token;
  const navigate = useNavigate();

  ///add sales code
  const submit = (e) => {
    e.preventDefault(); 
   const startNDate = new Date(startDate).toLocaleDateString('en-GB'); 
   const endNDate = new Date(endDate).toLocaleDateString('en-GB'); 

   console.log(startNDate,endNDate);
    axios
      .post(
        `${BASE_URL}/api/v1/leadmgmt/SalesCode/createNewSalesCode`,
        {
          salesCode: salesCode,
          salesCodeTitle: salesCodeTitle,
          salesCodeDescription: description,
          unitName: unitName,
          startDate: startNDate,
          endDate: endNDate,
        },
        { headers: { Authorization: Token } }
      )
      .then((response) => {
       
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Sales Code Created Successfully!",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            navigate("/salescode");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: "Sorry! There is an error",
          });
        }
      });
  };
  //

  //reset
  const resetField = () => {
    setSalesCode("");
    setSalesCodeTitle("");
    setDescription("");
    setStartDate("");
    setEndDate("");
    setUnitName([""]);
  };

  const handlAddUnit = (e) => {
    const values = [...unitName];
    values.push(e.target.value);
    setUnitName(values);
  };

  const deleteUnit = (e) => {
    const values = [...unitName];
    values.pop();
    setUnitName(values);
  };

  const handleUnitChange = (index, event) => {
    const values = [...unitName];
    values[index] = event.target.value;
    setUnitName(values);
  };

  return (
    <div className="outlet_bg">
      <div className="outlet_container">
        <Button navigateTo={'/salescode'} />
        <form className="customform" onSubmit={submit}>
          <div className="row justify-content-center">
            <div className="col-4">
              <label>Sales Code</label>
              <input
                type="text"
                className="form-control"
                value={salesCode}
                onChange={(e) => setSalesCode(e.target.value)}
                required
              />
            </div>
            <div className="col-4">
              <label>Sales Code Title</label>
              <input
                type="text"
                className="form-control"
                value={salesCodeTitle}
                onChange={(e) => setSalesCodeTitle(e.target.value)}
                required
              />
            </div>
          </div>
          <br />

          {unitName.map((unit, index) => {
            return (
              <div className="row justify-content-center">
                <div className="col-4">
                  <label>Unit Name</label>
                  <input
                    type="text"
                    class="form-control"
                    name="name"
                    id="name"
                    value={unitName[index]}
                    required
                    onChange={(event) => {
                      handleUnitChange(index, event);
                    }}
                  />
                </div>
                <div className="col-4">
                  <button
                    className="add-btn mt-4"
                    onClick={(e) => handlAddUnit(e)}
                  >
                    Add Row
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="add-btn mt-4"
                    onClick={() => deleteUnit()}
                    style={{
                      display: index === 0 ? "none" : "inline-block",
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })}

          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <label>Start Date</label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </div>
            <div className="col-4">
              <label>End Date</label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => setEndDate(e.target.value)}
                
              />
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-8">
              <label>Description</label>
              <textarea
                className="form-control"
                cols="30"
                rows="5"
                onChange={(e) => setDescription(e.target.value)}
                required
              ></textarea>
            </div>
          </div>

          <br />
          <div className="row container-fluid justify-content-center">
            <div className="col-4 text-right">
              <button
                className="btn btn-secondary btn-user"
                type="reset"
                style={{ backgroundColor: "white", color: "black" }}
                onClick={resetField}
              >
                Reset
              </button>
            </div>
            <div className="col-4">
              <button className="btn btn-secondary btn-user" type="submit">
                Add Sales Code
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSalesCode;
