import React, { useState, useEffect } from "react";
import arrowBack from "../../../assets/tableView/arrow_back.png";
import unitImg1 from "../../../assets/tableView/unit-img-1.png";
import unitImg2 from "../../../assets/tableView/unit-img-2.png";
import unitImg3 from "../../../assets/tableView/unit-img-3.png";
import unitImg4 from "../../../assets/tableView/unit-img-4.png";
import unitImg5 from "../../../assets/tableView/unit-img-5.png";
import networkImg from "../../../assets/tableView/network.png";
import blueprintImg from "../../../assets/tableView/blueprint.png";
import terraceImg from "../../../assets/tableView/terrace.png";
import balconyImg from "../../../assets/tableView/antique-balcony.png";
import arrowRightRed from "../../../assets/tableView/right-arrow-red.png";
import arrowRightWhite from "../../../assets/tableView/right-arrow-white.png";
import "./UnitTablet.scss";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../utils/config/url";
import axios from "axios";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app



export default function UnitTablet() {

  const navigate = useNavigate()
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const site = JSON.parse(localStorage.getItem('site'))
  const { unitTypeName } = useParams()

  const [unitType, setUnitType] = useState({})
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [photoIndex, setPhotoIndex] = useState(0)
  const [photoIndex2, setPhotoIndex2] = useState(0)


  const [fpImages, setFpImages] = useState([])
  const [tdImages, setTdImages] = useState([])
  const [siImages1, setSiImages1] = useState([])
  const [siImages2, setSiImages2] = useState([])
  const [siImages3, setSiImages3] = useState([])


  useEffect(() => {


    axios.get(`${BASE_URL}/api/v1/configuration/unitType/getUnitTypeByUnitTypeName?siteCode=${site?.siteCode}&unitTypeName=${unitTypeName}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`
        }
      })
      .then((response) => {
        // console.log(response.data.unitType)
        if (response.data.status) {
          setUnitType(response.data.unitType)
          response?.data?.unitType?.floorPlan != undefined && setFpImages((prev) => {
            return [response?.data?.unitType?.floorPlan]
          })

          response?.data?.unitType?.threedPlan != undefined && setTdImages((prev) => {
            return [response?.data?.unitType?.threedPlan]
          })

          let salesImages = response?.data?.unitType?.salesImages

          if (salesImages.length > 0 && salesImages.length <= 2) {
            setSiImages1(salesImages.slice(0))
          } else if (salesImages.length > 0 && salesImages.length > 2) {
            setSiImages1(salesImages.slice(0, 2))
          }

          if (salesImages.length > 2 && salesImages.length <= 4) {
            setSiImages2(salesImages.slice(2))
          } else if (salesImages.length > 2 && salesImages.length > 4) {
            setSiImages2(salesImages.slice(2, 4))
          }

          if (salesImages.length > 4) {
            setSiImages3(salesImages.slice(4))
          }

        } else {
          setUnitType([])
          setFpImages([])
          setTdImages([])
          setSiImages1([])
          setSiImages2([])
        }
      })
      .catch((err) => {
        setUnitType([])
        setFpImages([])
        setTdImages([])
        setSiImages1([])
        setSiImages2([])
      })

  }, [])

  return (
    <div className="unitTablet">


      {/*<h3 className="unitTablet__heading">Unit</h3>*/}
    <div style={{display:"flex"}}>
      <div className="unitTablet__props">
        <span>{unitType?.phaseName}</span>
        <span>{unitType?.bhk}</span>
        <span>{unitType?.unitTypeName}</span>
      </div>
      <div style={{margin:"2.5% 0 0 45%"}}>
      <span className="unitTablet__back"
        onClick={() => {
          navigate(`/tabletView/dashboard/${site?.siteCode}/unitFinder`)
        }}
      >
        <img src={arrowBack} alt="" /> Back To Finder
      </span>
      </div>
    </div>

      <div className="unitTablet__hero">
        <div className="unitTablet__hero__images">

          <div className="unitTablet__hero__images__container">
            {
              siImages1?.map((image, index) => {
                return <div className="image">
                  <img key={index} src={image} alt="" />
                </div>
              })
            }
            {/* <img src={unitImg1} alt="" />
            <img src={unitImg3} alt="" /> */}
          </div>

          <div className="unitTablet__hero__images__container">
            {
              siImages2?.map((image, index) => {
                return <div className="image">
                  <img key={index} src={image} alt="" />
                </div>
              })
            }
            {/* <img src={unitImg2} alt="" />
            <img src={unitImg4} alt="" /> */}
          </div>

          <div className="unitTablet__hero__images__container">
            {
              siImages3?.map((image, index) => {
                return <div className="image"
                style={{width : '98%'}}
                >
                  <img key={index} src={image} alt="" />
                </div>
              })
            }
          </div>
          {/* <img src={unitImg5} alt="" /> */}
        </div>

        <div className="unitTablet__hero__details">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec
            luctus neque. Maecenas rhoncus ullamcorper tortor eu laoreet.
            Aliquam scelerisque dignissim elit, vel imperdiet ipsum cursus.
          </p>

          <div className="unitTablet__hero__details__unitInfo">
            <div className="unitTablet__hero__details__unitInfo__container">
              <img src={networkImg} alt="" />
              <div>
                <span>Carpet Area:</span>
                <b>{unitType?.carpetArea} sqft</b>
              </div>
            </div>
            <div className="unitTablet__hero__details__unitInfo__container">
              <img src={blueprintImg} alt="" />
              <div>
                <span>Built Up Area:</span>
                <b>{unitType?.builtUpArea} sqft</b>
              </div>
            </div>
            <div className="unitTablet__hero__details__unitInfo__container">
              <img src={balconyImg} alt="" />
              <div>
                <span>Balcony Area:</span>
                <b>{unitType?.balconyArea} sqft</b>
              </div>
            </div>
            <div className="unitTablet__hero__details__unitInfo__container">
              <img src={terraceImg} alt="" />
              <div>
                <span>Super BuiltUp Area:</span>
                <b>{unitType?.superBuiltUpArea} sqft</b>
              </div>
            </div>
          </div>
          <div className="unitTablet__hero__details__actions">
            <button
              onClick={() => {
                setOpen(true)
              }}
            >
              Floor Plan <img src={arrowRightRed} alt="" />
            </button>
            <button
              onClick={() => {
                setOpen2(true)
              }}
            >
              3D View <img src={arrowRightRed} alt="" />
            </button>
            <button
              onClick={() => {
                navigate(`/tabletView/dashboard/${site?.siteCode}/quotation`)
              }}
            >
              Quotation <img src={arrowRightWhite} alt="" />
            </button>
          </div>
        </div>
      </div>

      {open && (
        <Lightbox
          mainSrc={fpImages[photoIndex]}
          nextSrc={fpImages[(photoIndex + 1) % fpImages.length]}
          prevSrc={fpImages[(photoIndex + fpImages.length - 1) % fpImages.length]}
          onCloseRequest={() => setOpen(false)}

          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + fpImages.length - 1) % fpImages.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % fpImages.length)
          }
        />

      )}

      {open2 && (
        <Lightbox
          mainSrc={tdImages[photoIndex2]}
          nextSrc={tdImages[(photoIndex2 + 1) % tdImages.length]}
          prevSrc={tdImages[(photoIndex2 + tdImages.length - 1) % tdImages.length]}
          onCloseRequest={() => setOpen2(false)}

          onMovePrevRequest={() =>
            setPhotoIndex2((photoIndex2 + tdImages.length - 1) % tdImages.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex2((photoIndex2 + 1) % tdImages.length)
          }
        />

      )}



    </div>
  );
}
