import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import LeadDetails from "../../components/InvLead/LeadDetails";
import SiteList from "../../components/InvLead/SiteList";
import GenerateQuotation from "../Quotation/GenerateQuotation";
import { BASE_URL } from "../../utils/config/url";
import ListOfComments from "../ListOfComments/ListOfComments";
import FollowUp from "../FollowUp/FollowUp";
import HoldUnit from "../../components/HoldUnit/HoldUnit";
import Button from '../../components/Button/Button';
import "./InvLead.scss";

const InvLead = () => {
  const [invLead, setInvLead] = useState([]);
  const [sv, setSv] = useState([]);
  const [userList, setUserList] = useState([]);
  const [siteNameList, setSiteNameList] = useState([]);
  const [change, setChange] = useState(false);
  const { leadId } = useParams();
  const [active, setActive] = useState("ld");
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // get individual leads
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/leadmgmt/lead/getLeadByLeadId/${leadId}`, { headers: { Authorization: `bearer ${userInfo.token}` } });
      const data = res.data.lead;
      setInvLead(data);
    })();
  }, [change]);

  // get list of site visit
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/leadmgmt/siteVisit/getListOfSiteVisitByLeadId/${leadId}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } });
      const data = res.data;
      setSv(data);
    })();
  }, [change]);

  // get list of user
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/user/getListOfUsers?isActive=true`, { headers: { Authorization: `bearer ${userInfo.token}` } });
      const data = res.data.userList;
      setUserList(data);
    })();
  }, []);

  // get all site name
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/Configuration/Site/getAllSiteNames`, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      });
      const data = res.data.siteList;
      setSiteNameList(data);
    })();
  }, []);

  return (
    <div className="invLead">
      <div className="invLead__container">
        {/* <button className="back_btn" onClick={() => navigate('/viewleads')}>
          <span>
            <BsArrowLeftShort className="back_icon" />
          </span>
          Back
        </button> */}
        <Button navigateTo='/viewleads' />
        <div className="invLead__container__tabs">
          <button
            className={`${active === "ld" ? "bg-red" : "bg-dark"}`}
            onClick={() => {
              setActive("ld");
            }}
          >
            Lead Details
          </button>
          <button
            className={`${active === "sv" ? "bg-red" : "bg-dark"}`}
            onClick={() => {
              setActive("sv");
            }}
          >
            Site Visit
          </button>
          <button
            className={`${active === "qt" ? "bg-red" : "bg-dark"}`}
            onClick={() => {
              setActive("qt");
            }}
          >
            Quotation
          </button>
          <button
            className={`${active === "cmnt" ? "bg-red" : "bg-dark"}`}
            onClick={() => {
              setActive("cmnt");
            }}
          >
            Comments
          </button>
          <button
            className={`${active === "flw" ? "bg-red" : "bg-dark"}`}
            onClick={() => {
              setActive("flw");
            }}
          >
            Follow Up
          </button>
          <button
            className={`${active === "hu" ? "bg-red" : "bg-dark"}`}
            onClick={() => {
              setActive("hu");
            }}
          >
            Hold Unit
          </button>
        </div>

        {active === "ld" && <LeadDetails invLead={invLead} leadId={leadId} change={change} setChange={setChange} />}
        {active === "sv" && <SiteList sv={sv} userList={userList} siteNameList={siteNameList} setChange={setChange}
          invLead={invLead}
          leadStatus={invLead.leadStatus} />}
        {active === "cmnt" && <ListOfComments sv={sv} userInfo={userInfo} setChange={setChange} change={change} invLead={invLead} />}

        {active === "qt" && <GenerateQuotation siteNameList={siteNameList} leadId={leadId} invLead={invLead} />}
        {active === "flw" && <FollowUp change={change} setChange={setChange} />}
        {active === "hu" && <HoldUnit invLead={invLead} change={change} setChange={setChange} />}
      </div>
    </div>
  );
};

export default InvLead;
