import React from 'react'

const PaymentDetails = ({ paySlip }) => {



    const dateFormatting = (input) => {

        if (!input) return 'N/A';

        let date = new Date(input).getDate()
        if (date < 10) {
            date = `0${date}`
        }

        let month = new Date(input).getMonth() + 1
        if (month < 10) {
            month = `0${month}`
        }

        let year = new Date(input).getFullYear();
        
        return `${date}/${month}/${year}`
    }

    return (
        <div>
            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Disbursement Date</label>
                    <input type='text' className='form-control' value={dateFormatting(paySlip?.disbursementDate)} readOnly />
                </div>

                <div className='col-6'>
                    <label>Bank Payment Id</label>
                    <input type='text' className='form-control' value={paySlip?.bankPaymentId} readOnly />
                </div>
            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Disbursed By</label>
                    <input type='text' className='form-control' value={paySlip?.disbursedBy} readOnly />
                </div>

                <div className='col-6'>
                    <label>Disbursed By Full Name</label>
                    <input type='text' className='form-control' value={paySlip?.disbursedByFullName} readOnly />
                </div>
            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Disbursed BY Employee Id</label>
                    <input type='text' className='form-control' value={paySlip?.disbursedByEmpId} readOnly />
                </div>

                <div className='col-6'>
                    <label>Bank Account Number</label>
                    <input type='text' className='form-control' value={paySlip?.bankAccountNumber} readOnly />
                </div>
            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Bank Name</label>
                    <input type='text' className='form-control' value={paySlip?.bankName} readOnly />
                </div>

                <div className='col-6'>
                    <label>Branch Name</label>
                    <input type='text' className='form-control' value={paySlip?.bankBranch} readOnly />
                </div>
            </div>

            <br />

            <div className='row justify-content-center'>
                <div className='col-6'>
                    <label>Bank IFSC</label>
                    <input type='text' className='form-control' value={paySlip?.bankIFSC} readOnly />
                </div>

            </div>

        </div>
    )
}

export default PaymentDetails
