import React from "react";
import "./ShowWorkHistory.scss";
import { MdDelete } from "react-icons/md";
import { Paper } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../utils/config/url";
import Swal from "sweetalert2";
import { dateFormatting } from "../../../utils/dateFormatting";

const ShowWorkHistory = ({ work,  employeeId, setLoading, userInfo }) => {
  if (!work) {
    return null;
  }

  const deletework = async (id) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/api/v1/hrmgmt/Employee/deletePreviousEmploymentDetails`,
        {
          employeeId,
          id,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Work Details deleted successfully",
        text: res.data.message,
        showConfirmButton: true,
      }).then(function () {
        setLoading((prev) => !prev);
      });
    } catch (error) {
      console.error("Error deleting Work details:", error);
      Swal.fire({
        icon: "error",
        title: "Error deleting work details",
        text: error.response.data.message,
        showConfirmButton: true,
      });
      // Handle the error as needed
    }
  };
  return (
    <Paper elevation={3} className="ShowWorkHistory">
      <div className="row justify-content-center">
        <div className="col-3">
          <b>Employer Name:</b> {work?.employerName}
        </div>
        <div className="col-4">
          <b>Date of Joining:</b> {dateFormatting(work?.dateOfJoining)}
        </div>
        <div className="col-3">
          <b>Experience Letter:</b>{" "}
          {work?.experienceLetterScan ? (
            <a href={work?.experienceLetterScan}>Download</a>
          ) : (
            "Not Added"
          )}
        </div>
        <div className="col-1">
          <MdDelete
            onClick={(e) => {
              e.preventDefault();
              Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ee4b46",
                cancelButtonColor: "grey",
                confirmButtonText: "Yes, delete it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  deletework(work?._id);
                }
              });
            }}
            style={{
              cursor: "pointer",
              color: "red",
              fontSize: "20px",
              float: "right",
              marginTop: "5px",
              marginRight: "5px",
            }}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-3">
          <b>Designation:</b> {work?.designation}
        </div>
        <div className="col-4">
          <b>Date of Leaving:</b> {dateFormatting(work?.dateOfLeaving)}
        </div>
        <div className="col-3">
          <b>Pay Slips:</b>{" "}
          {work?.payslipsScan ? (
            <a href={work?.payslipsScan}>Download</a>
          ) : (
            "Not Added"
          )}
        </div>
        <div className="col-1"></div>
      </div>
      <div className="row justify-content-center">
        <div className="col-3">
          <b>Department:</b> {work?.department}
        </div>
        <div className="col-4">
          <b>Last CTC:</b> {work?.lastCTC}
        </div>
        <div className="col-3">
          <b>Additional Documents:</b>{" "}
          {work?.additionalDocumentScan ? (
            <a href={work?.additionalDocumentScan}>Download</a>
          ) : (
            "Not Added"
          )}
        </div>
        <div className="col-1"></div>
      </div>
    </Paper>
  );
};

export default ShowWorkHistory;
