import React from 'react'
import Button from '../../Button/Button'
import '../StockManagement.scss';
import { useState, useEffect } from 'react';
import { BASE_URL } from '../../../utils/config/url';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form } from 'react-bootstrap';

const CreateCatalogueItem = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate();

    const [catalogueItemName, setCatalogueItemName] = useState('');
    const [catalogueItemDescription, setCatalogueItemDescription] = useState('');
    const [categoryList, setCategoryList] = useState([])
    const [category, setCategory] = useState('');
    const [subCategoryList, setSubCategoryList] = useState([])
    const [subCategory, setSubCategory] = useState('')
    const [brandList, setBrandList] = useState([])
    const [brand, setBrand] = useState('')
    const [measurementUnit, setMeasurementUnit] = useState('')

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/stockmgmtapi/catCategory/getListOfCatCategory?isActive=true`,
            {
                headers: { Authorization: `bearer ${userInfo?.token}` }
            })
            .then((response) => {
                if (response.data.status === true) {
                    setCategoryList(response.data.CatelogueCategory)
                } else {
                    setCategoryList([])
                }
            })

    }, [])

    useEffect(() => {

        if (!category) {
            setSubCategory('')
            return;
        }

        let code = category.split(',')[1]

        axios.get(`${BASE_URL}/api/v1/stockmgmtapi/catSubCategory/getListOfCatSubCategory?parentCategoryCode=${code}&isActive=true`,
            {
                headers: { Authorization: `bearer ${userInfo?.token}` }
            })
            .then((response) => {
                if (response.data.status === true) {
                    setSubCategoryList(response.data.CatSubCategory)
                } else {
                    setSubCategoryList([])
                }
            })

    }, [category])

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/stockmgmtapi/brand/getListOfBrand?isActive=true`,
            {
                headers: { Authorization: `bearer ${userInfo?.token}` }
            })
            .then((response) => {
                if (response.data.status === true) {
                    setBrandList(response.data.Brand)
                } else {
                    setBrandList([])
                }
            })

    }, [])

    const CreateCatalogueItem = (e) => {

        e.preventDefault();

        axios.post(`${BASE_URL}/api/v1/stockmgmtapi/catalougeItem/createNewCatalogueItem`,
            {
                categoryCode: category.split(',')[1],
                categoryName: category.split(',')[0],
                subCategoryCode: subCategory.split(',')[1],
                subCategoryName: subCategory.split(',')[0],
                brandCode: brand.split(',')[1],
                brandName: brand.split(',')[0],
                measurementUnit,
                catalogueItemName: catalogueItemName,
                catalogueItemDescription,
                addedBy: userInfo?.userName,
                addedByFullName: userInfo?.userFullName,
                addedByEmpId: userInfo?.employeeId
            },
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {

                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: 'Form Submitted Successfully',
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        }
                    }).then(function () {
                        navigate('/listOfCatalogueItem')
                    })
                }

            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })
            })

    }


    return (
        <div className='stock-mgmt'>

            <div className='row justify-content-start'>
                <Button navigateTo={1} />
            </div>

            <h2 className='text-center mt-5'>Create Catalogue Item</h2>

            <form className='container mt-5'
                onSubmit={(e) => (CreateCatalogueItem(e))}
            >

                <div className='row m-auto container-fluid justify-content-center'>

                    <div className='col-6 text-left'>
                        <label>Catalogue Item Name</label>
                        <input type="text" className='form-control'
                            required
                            value={catalogueItemName}
                            onChange={(e) => setCatalogueItemName(e.target.value)}
                        />
                    </div>

                    <div className='col-6 text-left'>
                        <Form.Group controlId="Category">
                            <Form.Label>Category</Form.Label>
                            <Form.Control required
                                value={category}
                                onChange={(e) => setCategory(e.target.value)} as="select">
                                <option value="">Select a Category</option>
                                {
                                    categoryList?.map((category, index) => {
                                        return <option key={index} value={`${category.categoryName},${category.categoryCode}`}>
                                            {category.categoryName}
                                        </option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>

                </div>

                <br />

                <div className='row m-auto container-fluid justify-content-center'>
                    <div className='col-6 text-left'>
                        <Form.Group controlId="Category">
                            <Form.Label>Sub Category</Form.Label>
                            <Form.Control required
                                disabled={!category}
                                value={subCategory}
                                onChange={(e) => setSubCategory(e.target.value)} as="select">
                                <option value="">Select a Sub-Category</option>
                                {
                                    subCategoryList?.map((subCategory, index) => {
                                        return <option key={index} value={`${subCategory.subCategoryName},${subCategory.subCategoryCode}`}>
                                            {subCategory.subCategoryName}
                                        </option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>

                    <div className='col-6 text-left'>
                        <Form.Group controlId="Category">
                            <Form.Label>Brand</Form.Label>
                            <Form.Control required
                                value={brand}
                                onChange={(e) => setBrand(e.target.value)} as="select">
                                <option value="">Select a Brand</option>
                                {
                                    brandList?.map((brand, index) => {
                                        return <option key={index} value={`${brand.brandName},${brand.brandCode}`}>
                                            {brand.brandName}
                                        </option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>

                <br />

                <div className='row m-auto container-fluid justify-content-center'>
                    <div className='col-6 text-left'>
                        <label>Measurement Unit</label>
                        <input type="text" className='form-control'
                            required
                            value={measurementUnit}
                            onChange={(e) => setMeasurementUnit(e.target.value)}
                        />
                    </div>
                </div>

                <br />

                <div className='row m-auto container-fluid justify-content-center'>

                    <div className='col-9 text-left'>
                        <label>Catalogue Item Description</label>
                        <input type="text" className='form-control'
                            required
                            value={catalogueItemDescription}
                            onChange={(e) => setCatalogueItemDescription(e.target.value)}
                        />
                    </div>

                </div>

                <div className='row justify-content-center mt-5'>
                    <div className='col-6 text-center'>
                        <button className='stock-mgmt__btn'
                            style={{ width: 'auto' }}
                            type='submit'
                        >Create</button>
                    </div>
                </div>

            </form>

        </div>
    )
}

export default CreateCatalogueItem