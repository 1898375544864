import React, { useState } from "react";
import "./AddWorkHistory.scss";
import { FaPlusCircle } from "react-icons/fa";
import { AiFillCheckCircle } from "react-icons/ai";
import upload from "../../../assets/images/upload.png";
import axios from "axios";
import { BASE_URL } from "../../../utils/config/url";
import Swal from "sweetalert2";
import { convertCamelCaseToTitleCase } from "../../../utils/helper";


const AddWorkHistory = ({setLoading, userInfo, employeeId}) => {
  const [showForm, setShowForm] = useState(false);
  
  const [error, setError] = useState({});
  const [prevEmpDetail, setPrevEmpDetail] = useState({
    employerName: "",
    designation: "",
    department: "",
    dateOfJoining: "",
    dateOfLeaving: "",
    lastCTC: "",
    experienceLetterScan: "",
    payslipsScan: "",
    additionalDocumentScan: "",
  });

  const validateAllInputs = () => {
    let isValid = true;
    for (let key in prevEmpDetail) {
      if (
        prevEmpDetail[key] === "" &&
        key !== "experienceLetterScan" &&
        key !== "payslipsScan" &&
        key !== "additionalDocumentScan"
      ) {
        isValid = false;
        setError((prev) => ({ ...prev, [key]: `Required` }));
      } else {
        setError((prev) => ({ ...prev, [key]: "" }));
      }
    }
    return isValid;
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setPrevEmpDetail((prevDetail) => ({
      ...prevDetail,
      [id]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { id, files } = e.target;
    // Assuming you only want the first file selected
    setPrevEmpDetail((prevDetail) => ({
      ...prevDetail,
      [id]: files[0],
    }));
  };

  const handleAddPrevEmp = () => {
    setShowForm(!showForm);
  };
  const Reset = () => {
    setPrevEmpDetail({
      employerName: "",
      designation: "",
      department: "",
      dateOfJoining: "",
      dateOfLeaving: "",
      lastCTC: "",
      experienceLetterScan: "",
      payslipsScan: "",
      additionalDocumentScan: "",
    });
    setShowForm(false);
  };

  const handleUploadDetails = (e) => {
    e.preventDefault();
    if (!validateAllInputs()) {
      return;
    }
    if (
      prevEmpDetail.experienceLetterScan === "" ||
      prevEmpDetail.payslipsScan === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please upload Experience Letter and Payslip Scan",
      });
      return;
    }
    const formData = new FormData();
    formData.append("employerName", prevEmpDetail.employerName);
    formData.append("designation", prevEmpDetail.designation);
    formData.append("department", prevEmpDetail.department);
    formData.append("dateOfJoining", prevEmpDetail.dateOfJoining);
    formData.append("dateOfLeaving", prevEmpDetail.dateOfLeaving);
    formData.append("lastCTC", prevEmpDetail.lastCTC);
    formData.append("experienceLetterScan", prevEmpDetail.experienceLetterScan);
    formData.append("payslipsScan", prevEmpDetail.payslipsScan);
    formData.append(
      "additionalDocumentScan",
      prevEmpDetail.additionalDocumentScan
    );
    formData.append("employeeId", employeeId);

    axios
      .put(
        `${BASE_URL}/api/v1/hrmgmt/Employee/addPreviousEmploymentDetails`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        }).then(function () {
          Reset();
          setLoading((prev) => !prev);
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        });
      });
  };

  return (
    <div className="AddPrevEmp">
      <button
        type="button" // Correct the type attribute to "button"
        onClick={handleAddPrevEmp}
        className="AddPrevEmp__addEducationBtn"
      >
        Add Work History <FaPlusCircle style={{ marginLeft: "5px" }} />
      </button>
      {showForm && (
        <>
          <form className={`AddPrevEmp__form`}>
            <div className="row justify-content-center">
              <div className="col-4">
                <label htmlFor="employerName">Employer Name*</label>
                <input
                  value={prevEmpDetail.employerName}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control"
                  id="employerName"
                />
                <span className="error">
                  {error?.employerName &&
                    convertCamelCaseToTitleCase(error.employerName)}
                </span>
              </div>
              <div className="col-4">
                <label htmlFor="designation">Designation*</label>
                <input
                  value={prevEmpDetail.designation}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control"
                  id="designation"
                />
                <span className="error">
                  {error?.designation &&
                    convertCamelCaseToTitleCase(error.designation)}
                </span>
              </div>
              <div className="col-4">
                <label htmlFor="department">Department*</label>
                <input
                  value={prevEmpDetail.department}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control"
                  id="department"
                />
                <span className="error">
                  {error?.department &&
                    convertCamelCaseToTitleCase(error.department)}
                </span>
              </div>
            </div>
            <br />
            <div className="row justify-content-center">
              <div className="col-4">
                <label htmlFor="dateOfJoining">Date of Joining*</label>
                <input
                  value={prevEmpDetail.dateOfJoining}
                  onChange={handleInputChange}
                  type="date"
                  className="form-control"
                  id="dateOfJoining"
                />
                <span className="error">
                  {error?.dateOfJoining &&
                    convertCamelCaseToTitleCase(error.dateOfJoining)}
                </span>
              </div>
              <div className="col-4">
                <label htmlFor="dateOfLeaving">Date of Leaving*</label>
                <input
                  value={prevEmpDetail.dateOfLeaving}
                  onChange={handleInputChange}
                  type="date"
                  className="form-control"
                  id="dateOfLeaving"
                />
                <span className="error">
                  {error?.dateOfLeaving &&
                    convertCamelCaseToTitleCase(error.dateOfLeaving)}
                </span>
              </div>
              <div className="col-4">
                <label htmlFor="lastCTC">Last CTC*</label>
                <input
                  value={prevEmpDetail.lastCTC}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control"
                  id="lastCTC"
                />
                <span className="error">
                  {error?.lastCTC && convertCamelCaseToTitleCase(error.lastCTC)}
                </span>
              </div>
            </div>
            <br />

            <div className="AddPrevEmp__form__container">
              <label className="AddPrevEmp__form__container__upload">
                {prevEmpDetail.experienceLetterScan === "" && (
                  <>
                    <img
                      style={{ width: "50px", margin: "auto" }}
                      src={upload}
                      alt=""
                    />
                    <p>Upload Experience Letter</p>
                    <small>(only pdf supported)</small>
                  </>
                )}
                {prevEmpDetail.experienceLetterScan !== "" && (
                  <div className="row justify-content-center align-items-center mt-4">
                    <AiFillCheckCircle size={30} color="green" />
                    <p>{prevEmpDetail.experienceLetterScan.name}</p>
                  </div>
                )}

                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChange}
                  id="experienceLetterScan"
                ></input>
              </label>
              <label className="AddPrevEmp__form__container__upload">
                {prevEmpDetail.payslipsScan === "" && (
                  <>
                    <img
                      style={{ width: "50px", margin: "auto" }}
                      src={upload}
                      alt=""
                    />
                    <p>Upload Payslips</p>
                    <small>(only pdf supported)</small>
                  </>
                )}
                {prevEmpDetail.payslipsScan !== "" && (
                  <div className="row justify-content-center align-items-center mt-4">
                    <AiFillCheckCircle size={30} color="green" />
                    <p>{prevEmpDetail.payslipsScan.name}</p>
                  </div>
                )}

                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChange}
                  id="payslipsScan"
                ></input>
              </label>
              <label className="AddPrevEmp__form__container__upload">
                {prevEmpDetail.additionalDocumentScan === "" && (
                  <>
                    <img
                      style={{ width: "50px", margin: "auto" }}
                      src={upload}
                      alt=""
                    />
                    <p>Upload Additional Documents</p>
                    <small>(only pdf supported)</small>
                  </>
                )}
                {prevEmpDetail.additionalDocumentScan !== "" && (
                  <div className="row justify-content-center align-items-center mt-4">
                    <AiFillCheckCircle size={30} color="green" />
                    <p>{prevEmpDetail.additionalDocumentScan.name}</p>
                  </div>
                )}

                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChange}
                  id="additionalDocumentScan"
                ></input>
              </label>
            </div>
            <div className={`AddPrevEmp__form__Btn`}>
              <div className={`AddPrevEmp__form__Btn__uploadBtn`}>
                <button type="button" onClick={handleUploadDetails}>
                  Upload Work History
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default AddWorkHistory;
