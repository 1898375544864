import { useNavigate, useParams } from 'react-router-dom';
import { IoMdArrowBack } from 'react-icons/io';
import UnitTab from '../../components/UnitTab/UnitTab'
import UnitPricing from '../UnitPricing/UnitPricing';
import '../EditUnit/EditUnit.scss';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import Swal from 'sweetalert2';
import UnitPhoto from '../UnitPhoto/UnitPhoto';
import UnitInspection from '../UnitInspection/UnitInspection';
import UnitHandover from '../UnitHandover/UnitHandover';

const FlatDetails = () => {

  var today = new Date();
  var tomorrow = new Date(today);

  tomorrow.setDate(tomorrow.getDate() + 0);
  var dd = tomorrow.getDate();
  var mm = tomorrow.getMonth() + 1; //January is 0!
  var yyyy = tomorrow.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  tomorrow = yyyy + "-" + mm + "-" + dd;

  var maxdate = new Date(today);
  maxdate.setDate(maxdate.getDate() + 7);
  var dd2 = maxdate.getDate();
  var mm2 = maxdate.getMonth() + 1;
  var yyyy2 = maxdate.getFullYear();
  if (dd2 < 10) {
    dd2 = "0" + dd2;
  }
  if (mm2 < 10) {
    mm2 = "0" + mm2;
  }

  maxdate = yyyy2 + "-" + mm2 + "-" + dd2;

  const [unitTab, setUnitTab] = useState(localStorage.getItem("ActiveKeyUnit"))

  const navigate = useNavigate();
  const unitName = useParams().unitname;

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const Token = 'bearer' + " " + userInfo.token;

  const [uf, setUf] = useState('');
  const [bhk, setBhk] = useState('')
  const [ca, setCa] = useState('')
  const [ba, setBa] = useState('')
  const [bua, setBua] = useState('')
  const [pt, setPt] = useState('')
  const [sbua, setSbua] = useState('')

  const [unitInfo, setUnitInfo] = useState({
    unitName: unitName,
    status: '',
    siteCode: '',
    siteName: '',
    phaseCode: '',
    phaseName: '',
    unitTypeName: '',
    baseSqFeetRate: '',
    unitOnHoldApproval: '',
    holdTillDate: '',
    unitOnHoldBy: '',
    isOnHold: ''
  });

  const [date, setDate] = useState('')
  const [checkDateSurpass, setCheckDateSurpass] = useState(false)
  const [clickedRequestOnHold, setClickedRequestOnHold] = useState(false)
  const [approveRequest, setApproveRequest] = useState()
  const [rejectRequest, setRejectRequest] = useState()

  // useEffect(() => {
  //   if (date === null || date === '') {
  //     return
  //   }

  //   if (tomorrow === date) {

  //     setCheckDateSurpass(true)
  //     releaseUnitOnHold()
  //   } else {

  //     setCheckDateSurpass(false)
  //   }
  // }, [date])

  useEffect(() => {

    axios.get(`${BASE_URL}/api/v1/Configuration/Unit/getunitbyunitname/${unitName}`,
      { headers: { Authorization: Token } })
      .then((response) => {

        const u = response.data.unit;
       
        const { unitName,
          status,
          siteCode,
          siteName,
          phaseCode,
          phaseName,
          unitTypeName,
          baseSqFeetRate,
          unitOnHoldApproval,
          holdTillDate,
          isOnHold,
          unitOnHoldBy } = u

        setUf(u.unitFloor)
        setBhk(u.bhk)
        setCa(u.carpetArea)
        setBa(u.balconyArea)
        setBua(u.builtUpArea)
        setSbua(u.superBuiltUpArea)
        setPt(u.privateTerraceArea)

        // if (u.unitOnHoldApproval) {
        //   setDate(u.holdTillDate.slice(0, 10))
        //   setClickedRequestOnHold(true)
        // }

        ///////////// important peice of code //////////////
        // if (u.isOnHold) {
        //   setDate(u.holdTillDate.slice(0, 10))
        //   setClickedRequestOnHold(true)
        //   setApproveRequest(true)
        // }

        setUnitInfo({
          unitName: unitName,
          status: status,
          siteCode: siteCode,
          siteName: siteName,
          phaseCode: phaseCode,
          phaseName: phaseName,
          unitTypeName: unitTypeName,
          baseSqFeetRate: baseSqFeetRate,
          unitOnHoldApproval: unitOnHoldApproval,
          holdTillDate: holdTillDate,
          unitOnHoldBy: unitOnHoldBy,
          isOnHold: isOnHold
        })

      })
  }, [approveRequest, rejectRequest])



  const requestUnitOnHold = () => {

    axios
      .post(`${BASE_URL}/api/v1/Configuration/Unit/unitOnHoldRequest`,
        {
          unitName: unitInfo.unitName,
          userName: userInfo.userName,
          unitOnHoldBy: unitInfo.unitOnHoldBy,
          holdTillDate: date
        },
        { headers: { Authorization: Token } })
      .then((response) => {

        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            }
          }).then(function () {
            setClickedRequestOnHold(true)
            setCheckDateSurpass(false)
            setRejectRequest(false)
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err
        })
      })
  }

  const releaseUnitOnHold = () => {
    axios
      .post(`${BASE_URL}/api/v1/Configuration/Unit/releaseUnitOnHold`,
        {
          unitName: unitInfo.unitName,
        },
        { headers: { Authorization: Token } })
      .then((response) => {

        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            }
          }).then(function () {
            setRejectRequest()
            setApproveRequest()
            setDate('')
            setClickedRequestOnHold(false)
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err
        })
      })

  }


  const ApproveOrRejectUnitOnHold = (decision) => {
    axios
      .post(`${BASE_URL}/api/v1/Configuration/Unit/approveUnitOnHold`,
        {
          unitName: unitInfo.unitName,
          isApproved: decision,
          userName: userInfo.userName,
          userFullName: userInfo.userFullName
        },
        { headers: { Authorization: Token } })
      .then((response) => {

        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: decision ? `Unit ${unitInfo.unitName} is approved for on hold till ${date}` : response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            }
          }).then(function () {
            if (decision === false) {
              setRejectRequest(true)
              setDate('')
              setClickedRequestOnHold(false)
            }
            else {
              setApproveRequest(true)
            }

          })
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err
        })
      })
  }
  return (
    <div className='editunit'>
      <div className="editunit__btn">
        <button
          onClick={() => {
            navigate('/flatAllotment/flats')
            setUnitTab('first')
          }}
        >
          <IoMdArrowBack />
          Back
        </button>
      </div>

      <UnitTab tabName={setUnitTab} />

      {unitTab === 'first' &&

        <form className='editunit__form' method='POST'>

          <div className="row container-fluid justify-content-center">
            <div className='col-4'>
              <label>Unit Name</label>
              <input type='text' value={unitInfo?.unitName} className='form-control' readOnly
              />
            </div>

            <div className='col-4'>
              <label>Status</label>
              <input type='text' value={unitInfo?.status} className='form-control' readOnly />
            </div>
          </div>

          <br />

          <div className="row container-fluid justify-content-center">
            <div className='col-4'>
              <label>Site Code</label>
              <input type='text' value={unitInfo.siteCode} className='form-control' readOnly />
            </div>

            <div className='col-4'>
              <label>Site Name</label>
              <input type='text' value={unitInfo.siteName} className='form-control' readOnly />
            </div>
          </div>

          <br />

          <div className="row container-fluid justify-content-center">
            <div className='col-4'>
              <label>Phase Code</label>
              <input type='text' value={unitInfo.phaseCode} className='form-control' readOnly />
            </div>

            <div className='col-4'>
              <label>Phase Name</label>
              <input type='text' value={unitInfo.phaseName} className='form-control' readOnly />
            </div>
          </div>

          <br />

          <div className="row container-fluid justify-content-center">
            <div className='col-4'>
              <label>Unit Type Name</label>
              <input type='text' value={unitInfo.unitTypeName}
                className='form-control' readOnly
              />
            </div>
            <div className='col-4'>
              <label>Unit On Hold By</label>
              <input type='text' value={unitInfo.unitOnHoldByFullName ? unitInfo.unitOnHoldByFullName : 'N/A'}
                className='form-control' readOnly
              />
            </div>
          </div>

          <br />

          <div className="row container-fluid justify-content-center">
            <div className='col-4'>
              <label>Unit Floor</label>
              <input type='text'
                className='form-control'
                value={uf}
                readOnly
              />
            </div>

            <div className='col-4'>
              <label>BHK</label>
              <input type='text' value={bhk} className='form-control' readOnly
              />
            </div>
          </div>

          <br />

          <div className="row container-fluid justify-content-center">
            <div className='col-4'>
              <label>Carpet Area</label>
              <input type='number' value={ca} className='form-control' readOnly
              />
            </div>

            <div className='col-4'>
              <label>Balcony Area</label>
              <input type='number' value={ba} className='form-control' readOnly
              />
            </div>
          </div>

          <br />

          <div className="row container-fluid justify-content-center">
            <div className='col-4'>
              <label>Built up Area</label>
              <input type='number' value={bua} className='form-control' readOnly
              />
            </div>

            <div className='col-4'>
              <label>Super Built Up Area</label>
              <input type='number' value={sbua} className='form-control' readOnly
              />
            </div>
          </div>

          <br />

          <div className="row container-fluid justify-content-center">
            <div className='col-4'>
              <label>Private Terrace Area</label>
              <input type='number' value={pt} className='form-control' readOnly
              />
            </div>
          </div>

          {/* <br />

          <div className="row container-fluid justify-content-center">
            <div className='col-4'>
              <label>Hold Till Date</label>
              <input type='date'
                min={tomorrow}
                max={maxdate}
                value={date}
                required
                disabled={clickedRequestOnHold === true}
                onChange={(e) => setDate(e.target.value)}
                className="form-control" />
            </div>

            <div className='col-4 editunit__form__btn'>
              <button
                disabled={clickedRequestOnHold === true || !date || unitInfo.status != 'Available'}
                style={{ backgroundColor: (!date || clickedRequestOnHold === true || unitInfo.status != 'Available') ? 'grey' : '#EE4B46' }}
                onClick={(e) => {
                  e.preventDefault()
                  requestUnitOnHold()
                }}>Request On Hold</button>
            </div>
          </div>

          <br />

          {(clickedRequestOnHold === true && !checkDateSurpass && rejectRequest !== true && userInfo.userRole === 'Director')
            &&
            <div>
              <div className="row container-fluid justify-content-center"
              >
                <div className=' editunit__form__btn'>
                  <button
                    disabled={approveRequest}
                    style={{ backgroundColor: approveRequest ? 'grey' : '#EE4B46' }}
                    onClick={(e) => {
                      e.preventDefault()
                      ApproveOrRejectUnitOnHold(true)
                    }}>Approve On Hold</button>
                </div>

                <div className='editunit__form__btn'>
                  <button
                    disabled={approveRequest}
                    style={{
                      backgroundColor: approveRequest ? 'grey' : '#EE4B46',
                      margin: '0 25px'
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      ApproveOrRejectUnitOnHold(false)

                    }}>Reject On Hold</button>
                </div>

                <div className='editunit__form__btn'>
                  <button
                    // disabled={approveRequest}
                    // style={{ backgroundColor: approveRequest ? 'grey' : '#EE4B46' }}
                    onClick={(e) => {
                      e.preventDefault()
                      releaseUnitOnHold()

                    }}>Release On Hold</button>
                </div>
              </div>

              <br />

              {approveRequest &&
                <div className="row container-fluid justify-content-center">
                  <div className='col-4'>
                    <label>Unit onHold Approval</label>
                    <input type='text'
                      readOnly
                      value={unitInfo.isOnHold}

                      className='form-control' />
                  </div>

                  <br />

                  <div className='col-4'>
                    <label>Unit onHold Till Date</label>
                    <input type='date'
                      readOnly
                      required
                      value={date}

                      className="form-control" />
                  </div>

                </div>}

            </div>
          } */}

        </form >
       
      }

      {unitTab === 'second' && <UnitPricing />}

      {unitTab === 'third' && <UnitPhoto />}

      {unitTab === 'fourth' && <UnitInspection />}

      {unitTab === 'fifth' && <UnitHandover />}

    </div >
  )
}

export default FlatDetails;