import React, { useEffect, useState } from "react";
import "./ViewSalaryStructure.scss";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const ViewSalaryStructure = () => {
  const RedSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#EE4B46",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#EE4B46",
    },
  }));

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  let employeeId = useParams().employeeId;
  const empId = userInfo.employeeId;

  const [fullName, setFullName] = useState("");
  const [jobLevel, setJobLevel] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [designation, setDesignation] = useState("");

  const [salaryAccount, setSalaryAccount] = useState({});
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [IFSCCode, setIFSCCode] = useState("");

  const [basicMonthly, setBasicMonthly] = useState(0);
  const [basicYearly, setBasicYearly] = useState(0);
  const [financialYear, setFinancialYear] = useState("");
  const [hraMonthly, setHraMonthly] = useState(0);
  const [hraYearly, setHraYearly] = useState(0);
  const [monthlyPersonalAllowance, setMonthlyPersonalAllowance] = useState(0);
  const [yearlyPersonalAllowance, setYearlyPersonalAllowance] = useState(0);
  const [monthlyUniformNLaundryAllowance, setMonthlyUniformNLaundryAllowance] =
    useState(0);
  const [yearlyUniformNLaundryAllowance, setYearlyUniformNLaundryAllowance] =
    useState(0);
  const [monthlyMealAllowance, setMonthlyMealAllowance] = useState(0);
  const [yearlyMealAllowance, setYearlyMealAllowance] = useState(0);
  const [monthlyTravelAllowance, setMonthlyTravelAllowance] = useState(0);
  const [yearlyTravelAllowance, setYearlyTravelAllowance] = useState(0);
  const [monthlyPhoneAndDataAllowance, setMonthlyPhoneAndDataAllowance] =
    useState(0);
  const [yearlyPhoneAndDataAllowance, setYearlyPhoneAndDataAllowance] =
    useState(0);
  const [monthlyProvidentFund, setMonthlyProvidentFund] = useState(0);
  const [yearlyProvidentFund, setYearlyProvidentFund] = useState(0);
  const [monthlyGratuity, setMonthlyGratuity] = useState(0);
  const [yearlyGratuity, setYearlyGratuity] = useState(0);
  const [yearlyFestivalBonus, setYearlyFestivalBonus] = useState(0);

  const [monthlyPerfBonus, setMonthlyPerfBonus] = useState(0);
  const [yearlyPerfBonus, setYearlyPerfBonus] = useState(0);
  const [monthTotal, setMonthTotal] = useState(0);
  const [totalCTC, setTotalCTC] = useState(0);
  const [isPF, setIsPF] = useState(true);
  const [ctcDownload, setCtcDownload] = useState(false);
  const [medicalInsurancePremium, setMedicalInsurancePremium] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [salaryStructureId, setSalaryStructureId] = useState(null);

  useEffect(() => {
    getEmployeeByEmployeeId();
  }, []);

  const getEmployeeByEmployeeId = () => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${employeeId}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        let salInfo = response.data.employee;
        setAccountNumber(salInfo?.salaryAccount?.accountNumber);
        setAccountHolderName(salInfo?.salaryAccount?.accountHolderName);
        setBankName(salInfo?.salaryAccount?.bankName);
        setBranchName(salInfo?.salaryAccount?.branchName);
        setIFSCCode(salInfo?.salaryAccount?.IFSCCode);
        setFullName(salInfo?.employeeFullName);
        setJobLevel(salInfo?.jobLevel);
        setDesignation(salInfo?.designation);
        setJobRole(salInfo?.employeeRole);
      });
  };

  useEffect(() => {
    getSalaryStructureByEmployeeId();
  }, []);

  const getSalaryStructureByEmployeeId = async () => {
    await axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/salarystructure/getSalaryStructureByEmployeeId/${employeeId}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        console.log(response.data);
        const salInfo = response?.data?.salaryStructure;
        // setFullName(salInfo?.employeeFullName);
        // setJobLevel(salInfo?.jobLevel);
        // setDesignation(salInfo?.designation);
        // setJobRole(salInfo?.employeeRole);
        setFinancialYear(salInfo?.financialYear);
        setBasicMonthly(salInfo?.monthlyBasic);
        setBasicYearly(salInfo?.yearlyBasic);
        setHraMonthly(salInfo?.monthlyHRA);
        setHraYearly(salInfo?.yearlyHRA);
        setMonthlyPersonalAllowance(salInfo?.monthlyPersonalAllowance);
        setYearlyPersonalAllowance(salInfo?.yearlyPersonalAllowance);
        setMonthlyUniformNLaundryAllowance(
          salInfo?.monthlyUniformNLaundryAllowance
        );
        setYearlyUniformNLaundryAllowance(
          salInfo?.yearlyUniformNLaundryAllowance
        );
        setMonthlyMealAllowance(salInfo?.monthlyMealAllowance);
        setYearlyMealAllowance(salInfo?.yearlyMealAllowance);
        setMonthlyTravelAllowance(salInfo?.monthlyTravelAllowance);
        setYearlyTravelAllowance(salInfo?.yearlyTravelAllowance);
        setMonthlyPhoneAndDataAllowance(salInfo?.monthlyPhoneAndDataAllowance);
        setYearlyPhoneAndDataAllowance(salInfo?.yearlyPhoneAndDataAllowance);
        setMonthlyProvidentFund(salInfo?.monthlyProvidentFund);
        setYearlyProvidentFund(salInfo?.yearlyProvidentFund);
        setMonthlyGratuity(salInfo?.monthlyGratuity);
        setYearlyGratuity(salInfo?.yearlyGratuity);
        setMonthlyPerfBonus(salInfo?.monthlyPerformanceBonus);
        setYearlyPerfBonus(salInfo?.yearlyPerformanceBonus);
        setYearlyFestivalBonus(salInfo?.yearlyFestivalBonus);
        setTotalCTC(salInfo?.annualCTC);
        setMonthTotal(salInfo?.monthlyTotal);
        setCtcDownload(salInfo?.ctcDocumentLink);
        setMedicalInsurancePremium(salInfo?.medicalPremium);
        setIsPF(salInfo?.eligibleForPF);
        setSalaryStructureId(salInfo?.salaryStructureId);
      });
  };

  const updateSalaryAccount = async () => {
    try {
      const response = await axios.put(
        `${BASE_URL}/api/v1/hrmgmt/employee/updateEmployee`,
        {
          employeeId: employeeId,
          update: {
            salaryAccount: {
              accountNumber: accountNumber,
              accountHolderName: accountHolderName,
              bankName: bankName,
              branchName: branchName,
              IFSCCode: IFSCCode,
            },
          },
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      );

      if (response.data.status === true) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        }).then(() => {});
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong!",
      });
    }
  };

  const deleteCTC = async () => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/api/v1/hrmgmt/salaryStructure/deleteSalaryStructureByEmployeeId/${empId}/${salaryStructureId}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      );
      console.log("res", response);
      setDeleteModal(false);
      if (response.data.status === true) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (err) {
      setDeleteModal(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: err.response.data.error,
      });
    }
  };

  return (
    <div className="view-salary-structure">
      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <div className="row justify-content-start">
          <div className="col-4">
            <label>Employee Id</label>
            <input
              type="text"
              readOnly
              value={employeeId}
              className="form-control"
            />
          </div>

          <div className="col-4">
            <label>Job Level</label>
            <input
              type="text"
              readOnly
              value={jobLevel}
              className="form-control"
            />
          </div>

          <div className="col-4">
            <label>Designation</label>
            <input
              type="text"
              value={designation}
              readOnly
              className="form-control"
            />
          </div>
        </div>

        <div className="row justify-content-start mt-3">
          <div className="col-4">
            <label>Employee Full Name</label>
            <input
              type="text"
              readOnly
              value={fullName}
              className="form-control"
            />
          </div>

          <div className="col-4">
            <label>Job Role</label>
            <input
              type="text"
              readOnly
              value={jobRole}
              className="form-control"
            />
          </div>

          <div className="col-4">
            <label>Financial Year</label>
            <input
              type="text"
              readOnly
              value={financialYear}
              className="form-control"
            />
          </div>
        </div>
      </Paper>

      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <h4 className="text-center mb-4">
          <b>Salary Account Details</b>
        </h4>

        <div className="row justify-content-start">
          <div className="col-4">
            <label>Account No.</label>
            <input
              type="text"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              className="form-control"
            />
          </div>

          <div className="col-4">
            <label>Account Holder Name</label>
            <input
              type="text"
              value={accountHolderName}
              onChange={(e) => setAccountHolderName(e.target.value)}
              className="form-control"
            />
          </div>

          <div className="col-4">
            <label>Bank Name</label>
            <input
              type="text"
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
              className="form-control"
            />
          </div>
        </div>

        <div className="row justify-content-start mt-3">
          <div className="col-4">
            <label>Branch Name</label>
            <input
              type="text"
              value={branchName}
              onChange={(e) => setBranchName(e.target.value)}
              className="form-control"
            />
          </div>

          <div className="col-4">
            <label>IFSC Code</label>
            <input
              type="text"
              value={IFSCCode}
              onChange={(e) => setIFSCCode(e.target.value)}
              className="form-control"
            />
          </div>

          <div className="col-4 text-center">
            <button
              className="view-salary-structure__btn mt-4"
              style={{ fontSize: "15px" }}
              onClick={updateSalaryAccount}
            >
              Update Salary Account
            </button>
          </div>
        </div>
      </Paper>

      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <div className="row justify-content-center view-salary-structure__headers">
          <div className="col-6">Component</div>
          <div className="col-3">Monthly</div>
          <div className="col-3">Yearly</div>
        </div>

        <br />

        <div className="row view-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Basic & HRA</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center view-salary-structure__components">
            <h5>Basic Salary</h5>
            <h5>HRA</h5>
          </div>

          <div className="col-3">
            <input
              type="number"
              readOnly
              className="form-control"
              value={basicMonthly}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={hraMonthly}
            />
          </div>

          <div className="col-3">
            <input
              type="number"
              className="form-control"
              readOnly
              value={basicYearly}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={hraYearly}
            />
          </div>
        </div>

        <hr />

        <div className="row view-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Allowances</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center view-salary-structure__components">
            <h5>Personal Allowance</h5>
            <h5>Uniform and Laundry Allowance</h5>
            <h5>Meal Allowance</h5>
            <h5>Travel Allowance</h5>
            <h5>Phone & Date Allowance</h5>
          </div>

          <div className="col-3">
            <input
              type="number"
              className="form-control"
              readOnly
              value={monthlyPersonalAllowance}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={monthlyUniformNLaundryAllowance}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={monthlyMealAllowance}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={monthlyTravelAllowance}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={monthlyPhoneAndDataAllowance}
            />
          </div>

          <div className="col-3">
            <input
              type="number"
              className="form-control"
              readOnly
              value={yearlyPersonalAllowance}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={yearlyUniformNLaundryAllowance}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={yearlyMealAllowance}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={yearlyTravelAllowance}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={yearlyPhoneAndDataAllowance}
            />
          </div>
        </div>

        <hr />

        <div className="row view-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Retirals & Insurance</em>
            <FormGroup
              style={{
                display: "flex",
                justifyContent: "center",
                width: "200px",
                margin: "auto",
              }}
            >
              <FormControlLabel
                control={<RedSwitch checked={isPF} />}
                label="PF"
              />
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center view-salary-structure__components">
            {isPF && (
              <>
                <h5>Provident Fund</h5>
                <h5>Gratuity</h5>
              </>
            )}
            <h5>Medical Insurance Premium</h5>
          </div>

          <div className="col-3">
            {isPF && (
              <>
                <input
                  type="number"
                  className="form-control"
                  readOnly
                  value={monthlyProvidentFund}
                />
                <br />
                <input
                  type="number"
                  className="form-control"
                  readOnly
                  value={monthlyGratuity}
                />
              </>
            )}
          </div>

          <div className="col-3">
            {isPF && (
              <>
                <input
                  type="number"
                  className="form-control"
                  readOnly
                  value={yearlyProvidentFund}
                />
                <br />
                <input
                  type="number"
                  className="form-control"
                  readOnly
                  value={yearlyGratuity}
                />
                <br />
              </>
            )}

            <input
              type="number"
              className="form-control"
              readOnly
              value={medicalInsurancePremium}
            />
          </div>
        </div>

        <hr />

        <div className="row view-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Bonus</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center view-salary-structure__components">
            <h5>Performance Bonus</h5>
            <h5>Yearly Festival Bonus</h5>
          </div>

          <div className="col-3">
            <input
              type="number"
              readOnly
              className="form-control"
              value={monthlyPerfBonus}
            />
          </div>

          <div className="col-3">
            <input
              type="number"
              className="form-control"
              readOnly
              value={yearlyPerfBonus}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={yearlyFestivalBonus}
            />
          </div>
        </div>

        <hr />

        <div className="row view-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Total</em>
          </div>
        </div>

        <div className="row">
          <div className="col-4 text-center view-salary-structure__components">
            <h5>Monthly Total</h5>
            <h5>Annual CTC</h5>
          </div>

          <div className="col-4">
            <input
              type="number"
              className="form-control"
              readOnly
              value={monthTotal}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={totalCTC}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <a
            href={ctcDownload}
            download="Salary Structure"
            target="_blank"
            className="col-4 text-center mt-5"
            rel="noreferrer"
          >
            <button
              className="view-salary-structure__btn mt-3"
              style={{ fontSize: "15px" }}
              disabled={!ctcDownload}
            >
              Download
            </button>
          </a>

          <div className="col-4 text-center mt-5 ml-3">
            <button
              className="view-salary-structure__btn mt-3"
              style={{ fontSize: "15px" }}
              disabled={!ctcDownload}
              onClick={() => {
                setDeleteModal(true);
              }}
            >
              Delete CTC
            </button>
          </div>
        </div>
      </Paper>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={deleteModal}>
          <div className="paper">
            <h4 className="text-center">
              Are you sure you want to delete CTC of {employeeId} ?
            </h4>

            <div className="row container-fluid justify-content-center mt-4">
              <div className="col-6 text-center">
                <button
                  className="view-salary-structure__btn"
                  onClick={() => {
                    deleteCTC();
                  }}
                >
                  Yes
                </button>
              </div>

              <div className="col-6 text-center">
                <button
                  className="view-salary-structure__btn"
                  onClick={() => {
                    setDeleteModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ViewSalaryStructure;
