import { Switch, Paper } from '@mui/material'
import React from 'react'
import Swal from 'sweetalert2';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { Modal, Fade } from "@mui/material";
import { MoonLoader } from "react-spinners";

const RegistrationAgreementDraft = ({ unitHandover, setUnitHandover }) => {

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const [loader, setLoader] = React.useState(false);
    const [file, setFile] = React.useState(null)
    const [generatedDate, setGeneratedDate] = React.useState(null)
    // console.log(unitHandover)

    const generateRegistrationAgreementDraft = () => {


        if (userInfo?.userRole != "Director" && userInfo?.userRole != "Operations") {
            return Swal.fire({
                icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
                text: "Only Director or Operations Department can perform this action",
            })
        }

        setLoader(true);

        


        let formData = new FormData();
        formData.append('handoverId', unitHandover?.handoverId)
        formData.append('generatedByUserName', userInfo?.userName)
        formData.append('generatedByUserFullName', userInfo?.userFullName)

        axios
            .put(
                `${BASE_URL}/api/v1/CustomerMgmt/handover/generateRegistrationAgreementDraft`,
                formData,
                {
                    headers: { Authorization: `bearer ${userInfo.token}` },
                }
            )
            .then((response) => {
                setLoader(false);
                if (response.data.status) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                    }).then(function () {
                        setUnitHandover(response.data.handover);
                    });
                }
            })
            .catch((err) => {
                setLoader(false);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error,
                });
            });

    }

    const dateFormatting = (input) => {

        if (!input) {
            return 'N/A'
        }
        // console.log(input)
        let d = new Date(input)
        // console.log(d)

        let date = d.getDate()
        if (date < 10) {
            date = `0${date}`
        }
        let m = d.getMonth() + 1;
        if (m < 10) {
            m = `0${m}`
        }
        let y = d.getFullYear()

        let final;

        final = `${date}/${m}/${y}`
        return final

    }


    return (
        <>
            <Paper elevation={1}
                style={{
                    padding: '30px 20px',
                    width: '90%',
                    margin: 'auto'
                }}>
                <div className="row mb-3 mx-2 card-1">
                    <div className="col-12 tab-card pt-3 pb-3 text-center">
                        {!unitHandover?.registrationAgreementDraft?.registrationAgreementDraftLink ? (
                            (userInfo.userRole !== "Operations" &&
                                userInfo.userRole !== "Director") ? (
                                <h5
                                    style={{
                                        paddingRight: "10px",
                                        marginRight: "5px",
                                        fontSize: "22px",
                                        fontWeight: "bold",
                                        paddingTop: "5px",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    Registration Deed Draft Pending
                                </h5>
                            ) : (
                                <>
                                    <h4
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    >
                                        Registration Deed Draft
                                    </h4>

                                    <div className='row justify-content-center mt-4'>

                                        <div className='col-6 text-center mt-3'>
                                            <button className='unit-handover__btns'
                                                type='click'
                                                onClick={() => {
                                                    generateRegistrationAgreementDraft()
                                                }}
                                            >
                                                Generate
                                            </button>
                                        </div>

                                    </div>
                                </>
                            )
                        ) : (
                            <>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <p
                                        style={{
                                            paddingRight: "10px",
                                            marginRight: "5px",
                                            fontSize: "25px",
                                            fontWeight: "bold",
                                            paddingTop: "5px",
                                            paddingLeft: "10px",
                                        }}
                                    >
                                        Registration Deed Draft
                                    </p>
                                    <br />
                                    <div>
                                        <p>Agreement Deed: {" "}<b><a href={unitHandover?.registrationAgreementDraft?.registrationAgreementDraftLink}>Download</a></b></p>
                                        <p>Generated Date:{" "}
                                            <b>{dateFormatting(unitHandover?.registrationAgreementDraft?.generatedDate)}</b> </p>
                                        <p>Generated By:{" "}<b>{unitHandover?.registrationAgreementDraft?.generatedByUserFullName}</b></p>

                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Paper>

           
            {/* //////////// Modal for spinner //////////// */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={loader}
                onClose={() => {
                    setLoader(false);
                }}
                closeAfterTransition
            >
                <Fade in={loader}>
                    <div>
                        <MoonLoader
                            color="red"
                            className="spinner"
                            loading={loader}
                            size={70}
                        />
                    </div>
                </Fade>
            </Modal>

        </>
    )
}

export default RegistrationAgreementDraft