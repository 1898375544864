import React from "react";
import "./SpecificationSidebar.scss";

export default function SpecificationSidebar({ active, setSelectedTab }) {
  const tabs = [
    "Construction",
    "In your Apartment",
    "Kitchen",
    "WC/Toilets",
    "Electricity",
    "Doors and Windows",
    "Common Areas",
    "More Features",
  ];

  return (
    <div className="specificationSidebar">
      {tabs.map((tab) => (
        <span
          className={`${active === tab ? "specificationSidebar--active" : ""}`}
          onClick={() => setSelectedTab(tab)}
        >
          {tab}
        </span>
      ))}
    </div>
  );
}
