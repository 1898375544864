import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {BsArrowLeftShort} from "react-icons/bs";
import axios from "axios";
import {BASE_URL} from "../../utils/config/url";
import InvUserDetails from "../../components/ViewIndividualUser/InvUserDetails";
import InvUserDoc from "../../components/ViewIndividualUser/InvUserDoc";
import Button from "../../components/Button/Button";

const ViewIndividualUser = () => {

  const [invUser, setInvUser] = useState({});
  const [activeMenu, setActiveMenu] = useState("dt");
  const navigate = useNavigate();
  const {userName} = useParams();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {

    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/user/getUserByUserName/${userName}`,
       {headers: {Authorization: `bearer ${userInfo.token}`}});
      const data = res.data.user;
    //  console.log(data)
      setInvUser(data);
    })();

  }, []);

 // console.log(invUser)

  return (
    <div style={{height: "100%"}} className="outlet_bg">
      <div className="outlet_container">
       <Button navigateTo={'/viewuser'}/>
       
        <div className="profile_tabs">
          {/* <button
            className={`${activeMenu === "dt" ? "bg-red" : "bg-dark"}`}
            onClick={() => {
              setActiveMenu("dt");
            }}
          >
            Details
          </button> */}
          {/* <button
            className={`${activeMenu === "doc" ? "bg-red" : "bg-dark"}`}
            onClick={() => {
              setActiveMenu("doc");
            }}
          >
            Documents
          </button> */}
        </div>
        {activeMenu === "dt" && <InvUserDetails user={invUser} />}
        {/* {activeMenu === "doc" && <InvUserDoc user={invUser} />} */}
      </div>
    </div>
  );
};

export default ViewIndividualUser;
