import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../utils/config/url";
import { BsArrowLeftShort } from "react-icons/bs";
import FormControl from "@mui/material/FormControl";
import { useStyles } from "../../utils/ModalStyles";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "../../components/Button/Button";
import { Paper } from "@mui/material";
import BarCharts from "./BarCharts";

const Gst = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [gstList, setGstList] = useState([]);
  const [finYear, setFinYear] = useState("");
  const [month, setMonth] = useState("");
  const [monthName, setMonthName] = useState([]);
  const [monthlyCollection, setMonthlyCollection] = useState([]);


  //get list of gst
  useEffect(() => {
    if (finYear === "" && month === "") {
      axios.get(`${BASE_URL}/api/v1/FinanceMgmt/GST/getListOfGST`, { headers: { Authorization: `bearer ${userInfo.token}` } }).then((response) => {
        setGstList(response.data.gstList);
      });
    } else if (finYear !== "" && month === "") {
      axios.get(`${BASE_URL}/api/v1/FinanceMgmt/GST/getListOfGST?finYear=${finYear}`, { headers: { Authorization: `bearer ${userInfo.token}` } }).then((response) => {
        setGstList(response.data.gstList);
      });
    } else if (finYear !== "" && month !== "") {
      axios.get(`${BASE_URL}/api/v1/FinanceMgmt/GST/getListOfGST?finYear=${finYear}&month=${month}`, { headers: { Authorization: `bearer ${userInfo.token}` } }).then((response) => {
        setGstList(response.data.gstList);
      });
    }
  }, [finYear, month]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/financemgmt/gst/getMonthwiseGSTReport`, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((response) => {
        // console.log(response)
        // setMonthlyLeadCount(response.data.monthwiseLeadCount);

        let monthlyCount = response.data.report.map((data) => {
          return Object.values(data[0])[0];
        });

        setMonthlyCollection(monthlyCount);

        let monthName = response.data.report.map((data) => {
          return Object.keys(data[0])[0];
        });

        setMonthName(monthName);
      });
  }, []);

  //reset filter
  const reset = () => {
    setFinYear("");
    setMonth("");
  };


  return (
    <div className="outlet_bg">

      <div className="outlet_container">
        <Button navigateTo={'/home'} />

        <br />

        <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
          <h4 className="text-center">GST Collection Monthwise</h4>

          <div className="row">
            <div className="col-12">
              <BarCharts monthName={monthName} monthCount={monthlyCollection} />
            </div>
          </div>
        </Paper>

        <br />

        <div className="mt-5">
          <h3>GST</h3>
          <MaterialTable
            data={gstList}
            title=""

            columns={[
              { title: "Money Receipt No.", field: "moneyReceiptNo" },
              { title: "Transaction Comments", field: "transactionComments" },
              {
                title: "Collection Date",
                defaultSort: "desc",
                render: (rowData) => (!rowData.collectionDate ? "" : rowData.collectionDate.substring(8, 10) + "-" + rowData.collectionDate.substring(5, 7) + "-" + rowData.collectionDate.substring(0, 4) + ", " + rowData.collectionDate.substring(11, 13) + ":" + rowData.collectionDate.substring(14, 16)),
                customSort: (a, b) => (a.collectionDate < b.collectionDate ? -1 : 1),
              },
              { title: "GST Amount", field: "gstAmount" },
              { title: "GST Percentage", field: "gstPercentage" },
              { title: "Money Receipt Total", field: "transactionAmount" },
            ]}

            options={{
              search: window.innerWidth < 700 ? false : true,
              searchFieldStyle: {
                width: 180
              },
              paging: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 20, 30],
              headerStyle: {
                backgroundColor: "#EE4B46",
                color: "#fff",
              },
            }}

            components={{
              Toolbar: props => (
                <div className="filters text-left">
                  <MTableToolbar {...props} />
                  <FormControl variant="standard" className={classes?.formControl}
                    style={{ marginTop: "-65px", width: "8rem", marginLeft: "15px" }}>
                    <InputLabel id="demo-simple-select-standard-label">Financial year</InputLabel>
                    <Select labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard" value={finYear} onChange={(e) => setFinYear(e.target.value)} className={classes?.selectEmpty} inputProps={{ "aria-label": "Without label" }}>
                      <MenuItem value="">Financial year</MenuItem>
                      <MenuItem value="2021-2022">2021-2022</MenuItem>
                      <MenuItem value="2022-2023">2022-2023</MenuItem>
                      <MenuItem value="2023-2024">2023-2024</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl variant="standard" className={classes?.formControl}
                    style={{ marginTop: "-65px", width: "8rem", marginLeft: "15px" }}>
                    <InputLabel id="demo-simple-select-standard-label">Month</InputLabel>
                    <Select labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard" value={month} onChange={(e) => setMonth(e.target.value)} className={classes?.selectEmpty} inputProps={{ "aria-label": "Without label" }} disabled={finYear === "" ? true : false}>
                      <MenuItem value="">Month</MenuItem>
                      <MenuItem value="1">January</MenuItem>
                      <MenuItem value="2">February</MenuItem>
                      <MenuItem value="3">March</MenuItem>
                      <MenuItem value="4">April</MenuItem>
                      <MenuItem value="5">May</MenuItem>
                      <MenuItem value="6">June</MenuItem>
                      <MenuItem value="7">July</MenuItem>
                      <MenuItem value="8">August</MenuItem>
                      <MenuItem value="9">September</MenuItem>
                      <MenuItem value="10">October</MenuItem>
                      <MenuItem value="11">November</MenuItem>
                      <MenuItem value="12">December</MenuItem>
                    </Select>
                  </FormControl>

                  {window.innerWidth < 1000 && <br />}

                  <FormControl className={classes?.formControl}
                    style={{ marginTop: window.innerWidth < 1000 ? '-30px' : '-50px' }}>
                    <button
                      onClick={reset}
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid black",
                        borderRadius: "20px",
                        padding: "5px",
                        margin: "5px 0px 0px 15px",
                      }}
                    >
                      Reset Filter
                    </button>
                  </FormControl>

                </div>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Gst;
