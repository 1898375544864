import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import LeadList from "../../components/ViewLeads/LeadList";
import { BASE_URL } from "../../utils/config/url";
import Button from "../../components/Button/Button";
import './ViewLeads.scss';


const ViewLeads = () => {
  const [leads, setLeads] = useState([]);
  const [uaLeads, setUaLeads] = useState([]);
  const [clLeads, setClLeads] = useState([]);
  const [olLeads, setOlLeads] = useState([]);
  const [idLeads, setIdLeads] = useState([]);
  const [active, setActive] = useState("al");
  const [change, setChange] = useState(false);
  const [fullAccess , setFullAccess] = useState(false);
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userName = userInfo.userName;
  const userRole = userInfo.userRole;

  useEffect(() => {
    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getEmployeebyEmployeeId/${userInfo.employeeId}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } });
      const data = res.data.employee;
      setFullAccess(data.leadFullAccess);
    })();
  }, [])

  // set active state
  useEffect(() => {
    if (userRole === 'Director' || fullAccess) {
      setActive('al')
    } else {
      setActive('ol')
    }
  }, [fullAccess])

  // get all lead
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/leadmgmt/lead/getListOfLeads`,
        { headers: { Authorization: `bearer ${userInfo.token}` } });
      const data = res.data.leads;
      setLeads(data);
    })();
  }, [change]);

  // get unassigned lead
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/leadmgmt/lead/getListOfLeads?leadStatus=New Lead`,
        { headers: { Authorization: `bearer ${userInfo.token}` } });
      const data = res.data.leads;
      setUaLeads(data);
    })();
  }, [change]);

  // get my closed lead
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/leadmgmt/lead/getListOfLeads?isActive=false&assigneeUserName=${userName}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } });
      const data = res.data.leads;
      // console.log(data)
      setClLeads(data);
    })();
  }, []);

  //get my open leads
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/leadmgmt/lead/getListOfLeads?assigneeUserName=${userName}&leadStatus=Assigned`,
        { headers: { Authorization: `bearer ${userInfo.token}` } });
      const data = res.data.leads;
      // console.log(data)
      setOlLeads(data);
    })();
  }, [change]);

  // get my In discussion Leads
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/leadmgmt/lead/getListOfLeads?leadStatus=InDiscussion&assigneeUserName=${userName}`,
        { headers: { Authorization: `bearer ${userInfo.token}` } });
      const data = res.data.leads;
      setIdLeads(data);
    })();
  }, [change]);

  return (
    <div className="outlet_bg">
      <div className="outlet_container" style={{ padding: window.innerWidth < 700 ? '20px 0' : '20px' }}>
        <div className="d-flex justify-content-between align-items-center">

          <Button navigateTo='/lead-management' />

          <div className="profile_tabs">

            {(userRole === 'Director' || fullAccess) &&
              <button
                className={`${active === "al" ? "bg-red" : "bg-dark"}`}
                onClick={() => {
                  setActive("al");
                }}
              >
                All Lead
              </button>}

            {(userRole === 'Director' || fullAccess) &&
              <button
                className={` ${userRole === "Director" || fullAccess ? `${active === "ul" ? "bg-red d-block" : "bg-dark d-block"}` : "d-none"}`}
                onClick={() => {
                  setActive("ul");
                }}
              >
                Unassigned Lead
              </button>}


            <button
              className={`${active === "ol" ? "bg-red" : "bg-dark"}`}
              onClick={() => {
                setActive("ol");
              }}
            >
              My Open Leads
            </button>

            <button
              className={`${active === "cl" ? "bg-red" : "bg-dark"}`}
              onClick={() => {
                setActive("cl");
              }}
            >
              My Closed Leads
            </button>

            <button
              className={`${active === "idl" ? "bg-red" : "bg-dark"} `}
              onClick={() => {
                setActive("idl");
              }}
            >
              My In Discussion Leads
            </button>

          </div>

          <div>
            <button className="addlead__btns"
              onClick={() => navigate('/addlead')}
            >Add Lead</button>

          </div>

        </div>

        {/* leads tabs  */}
        <div>
          {active === "al" && <LeadList AllLeads={leads} title="All Leads" tab={active} />}
          {active === "ul" && <LeadList leads={uaLeads} change={change} setChange={setChange} title="Unassigned Leads" tab={active} fullAccess={fullAccess}/>}
          {active === "ol" && <LeadList leads={olLeads} title="My Open Leads" tab={active} change={change} setChange={setChange} />}
          {active === "cl" && <LeadList leads={clLeads} title="My Closed Leads" tab={active} />}
          {active === "idl" && <LeadList leads={idLeads} title="My In Discussion Leads" tab={active} />}
        </div>
      </div>
    </div>
  );
};

export default ViewLeads;
