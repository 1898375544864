import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import DefaultAssigneeList from "../../components/DefaultAssignee/DefaultAssigneeList";
import Nav from 'react-bootstrap/Nav';
import './DefaultAssignee.scss';
import Sales from "../../components/DefaultAssignee/Sales";
import Operations from "../../components/DefaultAssignee/Operations";

const DefaultAsigne = () => {

  const [activeMenu, setActiveMenu] = useState("da");
  const [tab, setTab] = useState('first')
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  return (
    <div style={{ minHeight: "94vh" }} className="outlet_bg">
      <div className="outlet_container">

        <div className="row justify-content-start">
          <div className="col-2 text-center">
            <Button navigateTo={'/home'} />
          </div>
          <div className="col-10 text-center">
            <h3 style={{ marginRight: '200px' }}>Default Assignee </h3>
          </div>
        </div>

        <br />

        <div className="tabs">
          <Nav>
            <Nav.Item
              onClick={() => {
                setTab('first')
              }}
            >
              <Nav.Link className={tab === 'first' ? 'active' : 'inactive'} eventKey="first">
                Sales
              </Nav.Link>
            </Nav.Item>

            <Nav.Item
              onClick={() => {
                setTab('second')
              }}
            >
              <Nav.Link className={tab === 'second' ? 'active' : 'inactive'} eventKey="second">
                Operations
              </Nav.Link>
            </Nav.Item>

            <Nav.Item
              onClick={() => {
                setTab('third')
              }}
            >
              <Nav.Link className={tab === 'third' ? 'active' : 'inactive'} eventKey="third">
                Finance
              </Nav.Link>
            </Nav.Item>

            <Nav.Item
              onClick={() => {
                setTab("fourth")
              }}
            >
              <Nav.Link className={tab === 'fourth' ? 'active' : 'inactive'} eventKey="fourth">
                Engineering
              </Nav.Link>
            </Nav.Item>

            <Nav.Item
              onClick={() => {
                setTab("fifth")
              }}
            >
              <Nav.Link className={tab === 'fifth' ? 'active' : 'inactive'} eventKey="fifth">
                HR
              </Nav.Link>
            </Nav.Item>

          </Nav>

        </div>

        <br />

        {
          userInfo.userRole === 'ITAdmin' || userInfo.userRole === 'Director'
            ?
            <>
              {tab === 'first' && <Sales />}
              {tab === 'second' && <Operations />}
            </>
            :
            <h3 style={{ textAlign: 'center', color: '#EE4B46' }}><em>You do not have access to this Page.</em></h3>
        }

      </div>
    </div >
  );
};

export default DefaultAsigne;
