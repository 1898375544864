import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useLocation,
} from "react-router-dom";

import LoginPage from "./Pages/LoginPage/LoginPage";
import HomePage from "./Pages/HomePage/HomePage";
import MyAppraisal from "./Pages/MyAppraisal/MyAppraisal";
import AddSite from "./Pages/AddSite/AddSite";
import Site from "./Pages/Site/Site";
import IndividualSite from "./Pages/IndividualSite/IndividualSite";
import Dashboard from "./components/Dashboard/Dashboard";
import AddUnitType from "./Pages/AddUnitType/AddUnitType";
import AddUnit from "./Pages/AddUnit/AddUnit";
import React, { useEffect, useState } from "react";
import { Context } from "./utils/Context";
import MyProfile from "./Pages/MyProfile/MyProfile";
import EditUnitType from "./Pages/EditUnitType/EditUnitType";
import EditUnit from "./Pages/EditUnit/EditUnit";
import "./index.scss";
import PrivateOutlet from "./PrivateOutlet/PrivateOutlet";
import AddTeamMember from "./Pages/AddTeamMember/AddTeamMember";
import ViewTeamMembers from "./Pages/ViewTeamMembers/ViewTeamMembers";
import DefaultAsigne from "./Pages/DefaultAsigne/DefaultAsigne";
import Office from "./Pages/Office/Office";
import UploadDocument from "./Pages/UploadDocument/UploadDocument";
import ViewIndividualUser from "./Pages/ViewIndividualUser/ViewIndividualUser";
import AddLead from "./Pages/AddLead/AddLead";
import ViewLeads from "./Pages/ViewLeads/ViewLeads";

import EditPaymentTerms from "./Pages/EditPaymentTerms/EditPaymentTerms";

import AddCarParking from "./Pages/AddCarParking/AddCarParking";
import InvLead from "./Pages/InvLead/InvLead";
import AddCarParkingType from "./Pages/AddCarParkingType/AddCarParkingType";
import EditCarParkingType from "./Pages/EditCarParkingType/EditCarParkingType";
import AddPaymentTerms from "./Pages/AddPaymentTerms/AddPaymentTerms";
import EditCarParking from "./Pages/EditCarParking/EditCarParking";
import ListOfCarParking from "./Pages/ListOfCarParking/ListOfCarParking";
import ListOfFlats from "./Pages/ListOfFlats/ListOfFlats";
import AddLoanBank from "./Pages/AddLoanBank/AddLoanBank";
import GenerateQuotation from "./Pages/Quotation/GenerateQuotation";
import InitiateBooking from "./Pages/InitiateBooking/InitiateBooking";
import ApplicationDetails from "./Pages/ApplicationDetails/ApplicationDetails";
import ManageApplications from "./Pages/ManageApplications/ManageApplications";
import Brokers from "./Pages/Broker/Brokers";
import AddApplicant from "./Pages/AddApplicant/AddApplicant";
import Banks from "./Pages/Banks/Banks";
import FlatDetails from "./Pages/FlatDetails/FlatDetails";
import AddBroker from "./Pages/AddBroker/AddBroker";
import InvBroker from "./Pages/InvBroker/InvBroker";
import InvBank from "./Pages/InvBank/InvBank";
import LoanCalculator from "./Pages/LoanCalculator/LoanCalculator";
import ViewCustomers from "./Pages/ListOfCustomers/ListOfCustomers";

import ViewIndividualCustomer from "./Pages/ViewIndividualCustomer/ViewIndividualCustomer";

import Tasks from "./Pages/Task/Tasks";
import ShowAdmin from "./Pages/Task/ShowAdmin";
import Supports from "./Pages/Supports/Supports";
import AddTicket from "./Pages/AddTicket/AddTicket";
import InvDemand from "./Pages/InvDemand/InvDemand";
import CreateTask from "./Pages/Task/CreateTask";
import Transactions from "./Pages/Transactions/Transactions";
import CashDeposit from "./Pages/CashDeposit/CashDeposit";
import ReceiveCashForm from "./Pages/ReceiveCashForm/ReceiveCashForm";
import Gst from "./Pages/GST/Gst";
import ChequeBook from "./Pages/ChequeBook/ChequeBook";
import ReceiveChequeForm from "./Pages/ReceiveChequeForm/ReceiveChequeForm";
import CashBack from "./Pages/CashBack/CashBack";
import HandOver from "./Pages/HandOver/HandOver";
import AlterationRequests from "./Pages/AlterationRequests/AlterationRequests";
import DebitPayments from "./Pages/DebitPayments/DebitPayments";
import TDS from "./Pages/TDS/TDS";
import Procurements from "./Pages/Procurements/Procurements";
import Alterations from "./Pages/Alterations/Alterations";
import Utils from "./Pages/Utils/Utils";
import SiteVisits from "./Pages/SiteVisits/SiteVisits";
import IndividualCheque from "./Pages/IndividualCheque/IndividualCheque";
import Demands from "./Pages/Demands/Demands";
import MoneyReceipts from "./Pages/MoneyReceipts/MoneyReceipts";
import ListOfSalesCode from "./Pages/ListOfSalesCode/ListOfSalesCode";
import AddSalesCode from "./Pages/AddSalesCode/AddSalesCode";
import ConfigPaymentTerms from "./Pages/ConfigPaymentTerms/ConfigPaymentTerms";
import InvPaymentTerm from "./Pages/InvPaymentTerm/InvPaymentTerm";
import ReportingDashboard from "./Pages/ReportingDashboard/ReportingDashboard";
import HRPolicies from "./Pages/HRPolicies/HRPolicies";
import BankTransfer from "./Pages/BankTransfer/BankTransfer";
import BankPayment from "./Pages/BankPayment/BankPayment";
import BankTransferForm from "./Pages/BankTransferForm/BankTransferForm";
import LeaveManagement from "./Pages/LeaveManagement/LeaveManagement";
import ListOfLeavePlan from "./Pages/ListOfLeavePlan/ListOfLeavePlan";
import CreateLeavePlan from "./Pages/CreateLeavePlan/CreateLeavePlan";
import EditLeavePlan from "./Pages/EditLeavePlan/EditLeavePlan";
import OpenLeaveRequests from "./Pages/OpenLeaveRequests/OpenLeaveRequests";
import ListOfLeaveBalance from "./Pages/ListOfLeaveBalance/ListOfLeaveBalance";
import ListOfBooking from "./Pages/ListOfBooking/ListOfBooking";
import LeadReport from "./Pages/LeadReport/LeadReport";
import AttendanceManagement from "./Pages/AttendanceManagement/AttendanceManagement";
import AttendanceApproval from "./Pages/AttendanceApproval/AttendanceApproval";
import AddWFH from "./Pages/AddWFH/AddWFH";
import AddFieldWork from "./Pages/AddFieldWork/AddFieldWork";
import AttendanceList from "./Pages/AttendanceList/AttendanceList";
import ViewBankTransfer from "./Pages/ViewBankTransfer/ViewBankTransfer";
import UnitInspectionListType from "./Pages/UnitInspectionListType/UnitInspectionListType";
import CreateUnitInspectionListType from "./Pages/CreateUnitInspectionListType/CreateUnitInspectionListType";
import ViewUnitInspectionListType from "./Pages/ViewUnitInspectionListType/ViewUnitInspectionListType";
import ListOfEmployees from "./Pages/ListOfEmployees/ListOfEmployees";
import ViewInvEmployee from "./Pages/ViewInvEmployees/ViewInvEmployees";
import Claims from "./Pages/Claims/Claims";
import ClaimsToBeApproved from "./components/Claims/ClaimsToBeApproved";
import ClaimsToBeSettled from "./components/Claims/ClaimsToBeSettled";
import ListOfAllClaims from "./components/Claims/ListOfAllClaims";
import IndividualClaim from "./Pages/IndividualClaim/IndividualClaim";
import AddDebitPayment from "./Pages/AddDebitPayment/AddDebitPayment";
import LeadMgmtDashboard from "./Pages/LeadMgmtDashboard/LeadMgmtDashboard";
// import CurrentProject from "./Pages/CurrentProjects/CurrentProjects";
import CreateSalaryStructure from "./Pages/CreateSalaryStructure/CreateSalaryStructure";
import InOfficeReport from "./Pages/InOfficeReport/InOfficeReport";
import EmployeeAttendanceReport from "./Pages/EmployeeAttendanceReport/EmployeeAttendanceReport";
import Quotations from "./Pages/Quotations/Quotations";
import StockManagement from "./Pages/StockManagement/StockManagement";
import CreateCategory from "./components/StockManagement/CreateCategory/CreateCategory";
import CreateBrand from "./components/StockManagement/CreateBrand/CreateBrand";
import CreateCatalogueItem from "./components/StockManagement/CreateCatalogueItem/CreateCatalogueItem";
import CreateSubCategory from "./components/StockManagement/CreateSubCategory/CreateSubCategory";
import ListOfCategory from "./components/StockManagement/ListOfCategory/ListOfCategory";
import ListOfSubCategory from "./components/StockManagement/ListOfSubCategory/ListOfSubCategory";
import ListOfBrand from "./components/StockManagement/ListOfBrand/ListOfBrand";
import ListOfCatalogueItem from "./components/StockManagement/ListOfCatalogueItem/ListOfCatalogueItem";
import IndividualTicket from "./Pages/IndividualTicket/IndividualTicket";
import ViewInvCategory from "./Pages/ViewInvCategory/ViewInvCategory";
import ViewInvBrand from "./Pages/ViewInvBrand/ViewInvBrand";
import ViewInvCatalogueItem from "./Pages/ViewInvCatalogueItem/ViewInvCatalogueItem";
import ViewInSubCat from "./Pages/ViewInSubCat/ViewInSubCat";
import PayRoll from "./Pages/PayRoll/PayRoll";
import GeneratedPaySlip from "./Pages/GeneratedPaySlip/GeneratedPaySlip";
import ApprovedPaySlip from "./Pages/ApprovedPaySlip/ApprovedPaySlip";
import DisbursedPaySlip from "./Pages/DisbursedPaySlip/DisbursedPaySlip";
import RejectedPaySlip from "./Pages/RejectedPaySlip/RejectedPaySlip";
import CashPayment from "./Pages/CashPayment/CashPayment";
import Warehouse from "./Pages/Warehouse/Warehouse";
import ViewWarehouse from "./Pages/ViewWarehouse/ViewWarehouse";
import CreateWareHouse from "./Pages/CreateWareHouse/CreateWareHouse";
import ApprovedLeaves from "./Pages/ApprovedLeaves/ApprovedLeaves";
import SalaryAdvance from "./Pages/SalaryAdvance/SalaryAdvance";
import AddScanIn from "./Pages/AddScanIn/AddScanIn";
import ListOfCostCenter from "./Pages/ListOfCostCenter/ListOfCostCenter";
import AddCostCenter from "./Pages/AddCostCenter/AddCostCenter";
import EditCostCenter from "./Pages/EditCostCenter/EditCostCenter";
import LimitApplication from "./Pages/LimitApplication/LimitApplication";
import ApplyBackendLeave from "./Pages/ApplyBackendLeave/ApplyBackendLeave";
import ApplySalaryAdvance from "./Pages/ApplySalaryAdvance/ApplySalaryAdvance";
import InvSalaryAdvance from "./Pages/InvSalaryAdvance/InvSalaryAdvance";
import SearchCatalogueItem from "./components/StockManagement/SearchCatalogueItem/SearchCatalogueItem";
import CompOff from "./Pages/CompOff/CompOff";
import InvCompOff from "./components/CompOff/InvCompOff";
import TabletDashboard from "./Pages/TableView/TabletDashboard/TabletDashboard";
import Footer from "./Pages/TableView/Footer/Footer";
import TabletHeader from "./Pages/TableView/TabletHeader/TabletHeader";

import ProjectDashboard from "./Pages/TableView/ProjectDashboard/ProjectDashboard";

import QuotationForm from "./Pages/TableView/Quotation/QuotationForm";

import UnitFinder from "./Pages/TableView/UnitFinder/UnitFinder";
import UnitTablet from "./Pages/TableView/UnitTablet/UnitTablet";
import VideoGallery from "./Pages/TableView/VideoGallery/VideoGallery";
import Specification from "./Pages/TableView/Specification/Specification";
import Broucher from "./Pages/TableView/Broucher/Broucher";
import StatusDashboard from "./Pages/StatusDashboard/StatusDashboard";
import EditHandover from "./Pages/EditHandover/EditHandover";
import ChangeApplicantOperation from "./Pages/ChangeApplicantOperation/ChangeApplicantOperation";
import AddApplicantOperation from "./Pages/AddApplicantsOperation/AddApplicantsOperation";
import ViewOffice from "./components/ViewOffice/ViewOffice";
import CreateNewOffice from "./components/CreateNewOffice/CreateNewOffice";
import ListOfOnboardings from "./Pages/ListOfOnboardings/ListOfOnboardings";
import StartOnboarding from "./Pages/StartOnboarding/StartOnboarding";
import ViewInvEmpOnboarding from "./Pages/ViewInvEmpOnboarding/ViewInvEmpOnboarding";
import OnboardEmpLogin from "./Pages/OnboardEmpLogin/OnboardEmpLogin";
import OnboardOutlet from "./PrivateOutlet/OnboardOutlet";
import OnboardEmpHome from "./Pages/OnboardEmpLogin/OnboardEmpHome";

const Routemaker = () => {
  const [menu, setMenu] = useState(false);
  const [tab, setTab] = useState("first");
  const [unitTab, setUnitTab] = useState("first");

  return (
    <div style={{ height: "100%" }}>
      <Context.Provider value={{ menu, setMenu }}>
        <Dashboard />
      </Context.Provider>
      <Context.Provider value={{ tab, setTab, unitTab, setUnitTab }}>
        <Outlet />
      </Context.Provider>
    </div>
  );
};

const OnboardRouteMaker = () => {
  return (
    <div style={{ height: "100%" }}>
      <Outlet />
    </div>
  );
};

const TabletRouteMaker = () => {
  const [project, setProject] = useState(null);
  const { pathname } = useLocation();

  useEffect(() => {
    let val = JSON.parse(localStorage.getItem("site"));
    val != null && setProject(val);
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div style={{ height: "100%" }}>
      {project != null && pathname != "/tabletView" && <TabletHeader />}

      <Outlet />
      <Footer />
    </div>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/onboardLogin" element={<OnboardEmpLogin />} />
        <Route path="/*" element={<OnboardOutlet />}>
          <Route path="" element={<OnboardRouteMaker />}>
            <Route path="onboarding" element={<OnboardEmpHome/>} />
          </Route>
        </Route>

        {/* nested dashboard  */}
        <Route path="/*" element={<PrivateOutlet />}>
          <Route path="" element={<Routemaker />}>
            <Route path="" element={<HomePage />} />
            <Route path="home" element={<HomePage />} />
            <Route path="myappraisal" element={<MyAppraisal />} />
            <Route path="hrpolicies" element={<HRPolicies />} />
            <Route path="listofbanks" element={<Banks />} />
            <Route path="addloanbank" element={<AddLoanBank />} />
            <Route path="individuabank/:ifscCode" element={<InvBank />} />

            {/* home loan calculator  */}
            <Route path="homeloancalculator" element={<LoanCalculator />} />

            {/* broker routes  */}
            <Route path="listofChannelPartner" element={<Brokers />} />
            <Route path="addChannelPartner" element={<AddBroker />} />
            <Route
              path="viewIndividualChannelpartner/:brokerPAN"
              element={<InvBroker />}
            />

            {/* Customer acquistion routes */}
            <Route path="addlead">
              <Route path="" element={<AddLead />} />
              <Route path="report" element={<LeadReport />} />
            </Route>
            <Route path="lead-management" element={<LeadMgmtDashboard />} />
            <Route path="quotations" element={<Quotations />} />
            <Route path="viewleads" element={<ViewLeads />} />
            <Route path="tasklist" element={<Tasks />} />
            <Route
              path="reporting-dashboard"
              element={<ReportingDashboard />}
            />
            <Route path="addticket" element={<AddTicket />} />
            <Route path="createtask" element={<CreateTask />} />
            <Route path="taskDashboard" element={<ShowAdmin />} />
            <Route path="gst" element={<Gst />} />
            <Route path="initiatebooking" element={<InitiateBooking />} />
            <Route path="site-visits" element={<SiteVisits />} />
            <Route path="support" element={<Supports />} />
            <Route path="individualticket">
              <Route path=":ticketId" element={<IndividualTicket />} />
            </Route>
            <Route path="cashback" element={<CashBack />} />

            <Route path="handover">
              <Route path="" element={<HandOver />} />
              <Route path=":handoverId" element={<EditHandover />} />
            </Route>

            <Route
              path="alteration-requests"
              element={<AlterationRequests />}
            />
            <Route path="individualDemand/:id" element={<InvDemand />} />

            <Route path="viewcustomers" element={<ViewCustomers />} />
            <Route path="demands" element={<Demands />} />

            <Route
              path="viewindividualcustomer/:customerId"
              element={<ViewIndividualCustomer />}
            />

            {/* nested route for individual lead  */}
            <Route path="individuallead">
              <Route path=":leadId" element={<InvLead />} />
            </Route>

            <Route path="addloanbank" element={<AddLoanBank />} />
            <Route path="quotation" element={<GenerateQuotation />} />

            {/* nested route for cofigurations  */}
            <Route path="configurations">
              {/* nested route for site  */}
              <Route path="site">
                <Route path="" element={<Site />} />
                <Route path="add-site" element={<AddSite />} />
                <Route path=":siteCode" element={<IndividualSite />} />

                <Route path="add-site" element={<AddSite />} />
                <Route path=":siteCode" element={<IndividualSite />} />

                <Route path="addunittype">
                  <Route path=":siteCode" element={<AddUnitType />} />
                </Route>

                <Route path=":siteCode">
                  <Route path="editunittype">
                    <Route path=":unitType" element={<EditUnitType />} />
                  </Route>
                </Route>

                <Route path="addunit">
                  <Route path=":siteCode" element={<AddUnit />} />
                </Route>

                <Route path=":siteCode">
                  <Route path="editunit">
                    <Route path=":unitname" element={<EditUnit />} />
                  </Route>
                </Route>

                <Route path="addcarparkingtype">
                  <Route path=":siteCode" element={<AddCarParkingType />} />
                </Route>

                <Route path=":siteCode">
                  <Route path="editcarparkingtype">
                    <Route
                      path=":carParkingTypeCode"
                      element={<EditCarParkingType />}
                    />
                    {/*<Route
                      path=":carParkingTypeCode"
                      element={<EditCarParkingType />}
  />*/}
                  </Route>
                </Route>

                <Route path="addcarparking">
                  <Route path=":siteCode" element={<AddCarParking />} />
                </Route>

                <Route path=":siteCode">
                  <Route path="editcarparking">
                    <Route
                      path=":carParkingName"
                      element={<EditCarParking />}
                    />
                  </Route>
                </Route>

                <Route path="addpaymentterm">
                  <Route path=":siteCode" element={<AddPaymentTerms />} />
                </Route>

                <Route path=":siteCode">
                  <Route path="editpaymentterms">
                    <Route
                      path=":paymentTermsId"
                      element={<EditPaymentTerms />}
                    />
                  </Route>
                </Route>
              </Route>

              <Route path="paymentTerms">
                <Route path="" element={<ConfigPaymentTerms />} />
                <Route path=":paymentTermId" element={<InvPaymentTerm />} />
              </Route>

              <Route path="TDS" element={<TDS />} />

              <Route path="unitInspectionListType">
                <Route path="" element={<UnitInspectionListType />} />
                <Route
                  path="createUnitInspectionListType"
                  element={<CreateUnitInspectionListType />}
                />
                <Route
                  path=":inspectionListTypeId"
                  element={<ViewUnitInspectionListType />}
                />
              </Route>
            </Route>

            {/*  nested route for engineering */}
            <Route path="engineering">
              <Route path="procurements" element={<Procurements />} />
              <Route path="alterations" element={<Alterations />} />
              <Route path="stockManagement">
                <Route path="" element={<StockManagement />} />
                <Route path="warehouse">
                  <Route path="" element={<Warehouse />} />
                  <Route path=":warehouseId" element={<ViewWarehouse />} />
                  <Route path="create" element={<CreateWareHouse />} />
                </Route>
              </Route>
            </Route>

            <Route path="createCategory" element={<CreateCategory />} />
            <Route path="createBrand" element={<CreateBrand />} />

            <Route
              path="createCatalogueItem"
              element={<CreateCatalogueItem />}
            />

            <Route path="createSubCategory" element={<CreateSubCategory />} />

            <Route path="listOfCategory">
              <Route path="" element={<ListOfCategory />} />
              <Route path=":categoryCode" element={<ViewInvCategory />} />
            </Route>

            <Route path="listOfBrand">
              <Route path="" element={<ListOfBrand />} />
              <Route path=":brandCode" element={<ViewInvBrand />} />
            </Route>

            <Route path="listOfSubCategory">
              <Route path="" element={<ListOfSubCategory />} />
              <Route path=":subCategoryCode" element={<ViewInSubCat />} />
            </Route>

            <Route path="listOfCatalogueItem">
              <Route path="" element={<ListOfCatalogueItem />} />
              <Route
                path=":catalogueItemCode"
                element={<ViewInvCatalogueItem />}
              />
            </Route>

            <Route
              path="searchCatalogueItem"
              element={<SearchCatalogueItem />}
            />

            {/* nested route for Flat Allotment  */}

            <Route path="flatAllotment">
              {/* nested route for Flats  */}

              <Route path="flats">
                <Route path="" element={<ListOfFlats />} />
                <Route path=":unitname" element={<FlatDetails />} />
              </Route>

              {/* nested Route for list of booking */}
              <Route path="listofbooking">
                <Route path="" element={<ListOfBooking />} />
              </Route>

              {/* nested route for Car Parking  */}
              <Route path="carparkinglist">
                <Route path="" element={<ListOfCarParking />} />
              </Route>

              {/* nested route for manage applications */}
              <Route path="manageapplications">
                <Route path="" element={<ManageApplications />} />
                <Route path="statusDashboard" element={<StatusDashboard />} />
                <Route path=":applicationId">
                  <Route path="" element={<ApplicationDetails />} />
                  <Route path="add-applicant" element={<AddApplicant />} />
                  <Route
                    path="changeApplicantOperation"
                    element={<ChangeApplicantOperation />}
                  />
                  <Route
                    path="addApplicantOperation/AddApplicant"
                    element={<AddApplicantOperation />}
                  />
                </Route>
              </Route>
            </Route>

            {/* nested route for finance */}
            <Route path="finance">
              <Route path="debit-payments">
                <Route path="" element={<DebitPayments />} />
                <Route path="add-debit-payment" element={<AddDebitPayment />} />
              </Route>

              <Route path="costCenters">
                <Route path="" element={<ListOfCostCenter />} />
                <Route path=":costCenterCode" element={<EditCostCenter />} />
              </Route>
              <Route path="addCostCenter" element={<AddCostCenter />} />

              <Route path="transactions">
                <Route path="" element={<Transactions />} />
              </Route>

              <Route path="moneyreceipts">
                <Route path="" element={<MoneyReceipts />} />
              </Route>

              <Route path="banktransfer">
                <Route path="" element={<BankTransfer />} />
                <Route
                  path="bank-transfer-form"
                  element={<BankTransferForm />}
                />
                <Route path=":bankTransferId" element={<ViewBankTransfer />} />
              </Route>

              <Route path="bankpayment">
                <Route path="" element={<BankPayment />} />
              </Route>

              {/* nested route for cash deposit */}
              <Route path="cashdeposit">
                <Route path="" element={<CashDeposit />} />
                <Route path="receive-cash" element={<ReceiveCashForm />} />
              </Route>

              <Route path="cashpayment">
                <Route path="" element={<CashPayment />} />
                <Route path="receive-cash" element={<ReceiveCashForm />} />
              </Route>

              {/*  nested route for cheque book */}
              <Route path="chequebook">
                <Route path="" element={<ChequeBook />} />
                <Route path="receive-cheque" element={<ReceiveChequeForm />} />
                <Route path=":chequeNo" element={<IndividualCheque />} />
              </Route>

              {/* nested Route for Claims */}
              <Route path="claims">
                <Route path="" element={<Claims />} />
                <Route
                  path="claimsToBeApproved"
                  element={<ClaimsToBeApproved />}
                />
                <Route
                  path="claimsToBeSettled"
                  element={<ClaimsToBeSettled />}
                />
                <Route path="listOfAllClaims" element={<ListOfAllClaims />} />
                <Route path=":claimId">
                  <Route path="" element={<IndividualClaim />} />
                  {/* <Route path="add-debit-payment" element={<AddDebitPayment />} /> */}
                </Route>
              </Route>
            </Route>

            <Route path="myprofile" element={<MyProfile />} />

            <Route path="addmember" element={<AddTeamMember />} />

            {/* nested route for leave mgmt */}
            <Route path="leavemanagement">
              <Route path="" element={<LeaveManagement />} />
              <Route path="listofleaveplan" element={<ListOfLeavePlan />} />
              <Route path="createleaveplan" element={<CreateLeavePlan />} />
              <Route
                path="editleaveplan/:leavePlanId"
                element={<EditLeavePlan />}
              />
              <Route
                path="listofopenleaverequests"
                element={<OpenLeaveRequests />}
              />
              <Route path="applyBackendLeave" element={<ApplyBackendLeave />} />
              <Route path="listofapprovedleaves" element={<ApprovedLeaves />} />
              <Route
                path="listofleavebalance"
                element={<ListOfLeaveBalance />}
              />

              <Route path="compoff">
                <Route path="" element={<CompOff />} />
                <Route path=":compOffId" element={<InvCompOff />} />
              </Route>
            </Route>

            {/* nested route for attendance mgmt */}
            <Route path="attendanceManagement">
              <Route path="" element={<AttendanceManagement />} />
              <Route path="addWFH" element={<AddWFH />} />
              <Route path="addScanIn" element={<AddScanIn />} />
              <Route path="addFieldWork" element={<AddFieldWork />} />
              <Route path="listOfAttendance" element={<AttendanceList />} />
              <Route
                path="attendanceApproval"
                element={<AttendanceApproval />}
              />
              <Route
                path="attendanceReport"
                element={<EmployeeAttendanceReport />}
              />
            </Route>

            <Route path="inOffice" element={<InOfficeReport />} />

            {/* nested route for payroll */}
            <Route path="payRoll">
              <Route path="" element={<PayRoll />} />

              <Route path="generated">
                <Route path=":payslipNumber" element={<GeneratedPaySlip />} />
              </Route>

              <Route path="approved">
                <Route path=":payslipNumber" element={<ApprovedPaySlip />} />
              </Route>

              <Route path="disbursed">
                <Route path=":payslipNumber" element={<DisbursedPaySlip />} />
              </Route>

              <Route path="rejected">
                <Route path=":payslipNumber" element={<RejectedPaySlip />} />
              </Route>
            </Route>

            <Route path="salaryAdvance">
              <Route path="" element={<SalaryAdvance />} />
              <Route path=":salaryAdvanceId" element={<InvSalaryAdvance />} />
            </Route>
            <Route path="applySalaryAdvance" element={<ApplySalaryAdvance />} />
            <Route path="limitApplications" element={<LimitApplication />} />

            <Route path="viewuser" element={<ViewTeamMembers />} />
            <Route path="viewemployees">
              <Route path="" element={<ListOfEmployees />} />
              <Route path=":employeeId" element={<ViewInvEmployee />} />
            </Route>
            <Route
              path="create-salary-structure"
              element={<CreateSalaryStructure />}
            />
            {/* <Route path=":employeeId" element={<ViewInvEmployee />} /> */}
            <Route path="salescode" element={<ListOfSalesCode />} />
            <Route path="createsalescode" element={<AddSalesCode />} />
            <Route path="defaultAsignee" element={<DefaultAsigne />} />
            <Route path="office">
              <Route path="" element={<Office />} />
              <Route path=":officeCode" element={<ViewOffice />} />
              <Route path="create-new-office" element={<CreateNewOffice />} />
            </Route>
            <Route path="list-of-onboardings">
              <Route path="" element={<ListOfOnboardings />} />
              <Route path="start-onboarding" element={<StartOnboarding />} />
              <Route path=":onboardingId" element={<ViewInvEmpOnboarding />} />
            </Route>
            <Route path="uploaddocument" element={<UploadDocument />} />
            <Route path="utils" element={<Utils />} />

            <Route
              path="viewindividualuser/:userName"
              element={<ViewIndividualUser />}
            />
          </Route>

          {/* start of tablet view */}
          <Route path="" element={<TabletRouteMaker />}>
            <Route path="tabletView">
              <Route path="" element={<TabletDashboard />} />

              <Route path="dashboard">
                <Route path=":siteCode">
                  <Route path="" element={<ProjectDashboard />} />
                  <Route path="quotation" element={<QuotationForm />} />
                  <Route path="brochure" element={<Broucher />} />
                  <Route path="videos" element={<VideoGallery />} />
                  <Route path="unitfinder">
                    <Route path="" element={<UnitFinder />} />
                    <Route path=":unitTypeName" element={<UnitTablet />} />
                  </Route>
                  <Route path="amenities" element={<Specification />} />

                  <Route path=":siteCode">
                    <Route path="" element={<ProjectDashboard />} />
                    <Route path="videos" element={<VideoGallery />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            {/* ^^ closing of nested dashboard ^^ */}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
