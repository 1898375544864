import React, { useEffect, useState } from 'react'
import './EditCarParking.scss';
import Paper from '@mui/material/Paper';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import Button from '../../components/Button/Button';
import Swal from 'sweetalert2';

const EditCarParking = () => {

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const Token = 'bearer' + " " + userInfo.token
  const carParkingName = useParams().carParkingName;
  const siteCode = useParams().siteCode
  const navigate = useNavigate()

  const [cpInfo, setCpInfo] = useState({
    siteName: '', siteCode: '', carParkingName: '', parkingNumber: '',
    carParkingTypeName: '', phaseName: '', parkingDescription: '',
    carParkingGrossPrice: '', carParkingGSTPercentage: '', carParkingGST: '',
    carParkingPrice: ''
  });

  const [phases, setPhases] = useState([])
  const [cpTypes, setCpTypes] = useState([])
  const [sname, setSname] = useState('')
  const [pn, setPn] = useState()
  const [pc, setPc] = useState()
  const [cptc, setCptc] = useState()
  const [desc, setDesc] = useState('')
  const [gp, setGp] = useState()
  const [updateGI, setUpdateGI] = useState({});
  const [updatePrice, setUpdatePrice] = useState({});

  const [phaseName, setPhaseName] = useState('')
  const [cptName, setCptName] = useState('')

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/Configuration/Site/getSiteBySiteCode/${siteCode}`,
        { headers: { Authorization: Token } })
      .then((response) => {
        // console.log(response)
        setSname(response.data.site.siteName)
        setPhases(response.data.site.phases)
      })
      .then(() => {

        axios
          .get(`${BASE_URL}/api/v1/Configuration/CarParkingType/getListOfCarParkingType/${siteCode}`,
            { headers: { Authorization: Token } })
          .then((response) => {

            let cptList = []
            response.data.carParkingTypeList.map((c) => {
              cptList.push({
                carParkingTypeName: c.carParkingTypeName,
                carParkingTypeCode: c.carParkingTypeCode
              })
            })

            setCpTypes(cptList)

          })
      })
      .then(() => {
        axios
          .get(`${BASE_URL}/api/v1/Configuration/CarParking/getcarparkingbycarparkingname/${carParkingName}`,
            { headers: { Authorization: Token } })
          .then((response) => {

            const c = response.data.carParking;
            //console.log(c)
            setCpInfo(response.data.carParking)
            setPn(c?.parkingNumber)
            setPc(c?.phaseCode)
            setPhaseName(c?.phaseName)
            setCptName(c?.carParkingTypeName)
            setCptc(c?.carParkingTypeCode)
            setDesc(c?.parkingDescription)
            setGp(c?.carParkingGrossPrice)
            setUpdatePrice((prev) => {
              return {
                ...prev,
                carParkingName : c?.carParkingName,
                carParkingGrossPrice: c?.carParkingGrossPrice,
                carParkingGST: c?.carParkingGST,
                carParkingGSTPercentage: c?.carParkingGSTPercentage,
                carParkingPrice: c?.carParkingPrice
              }
            })
          })

      })

  }, [])


  useEffect(() => {
   
    setUpdateGI({
      carParkingName: cpInfo.carParkingName,
      parkingDescription: desc,
      phaseName: phaseName,
      phaseCode: pc,
      siteName: sname
    })

    setUpdatePrice((prev)=>{
      return{
        ...prev ,
        carParkingName : cpInfo?.carParkingName
      }
    })
  }, [desc, pc, phaseName, sname , cpInfo])


  
  const handleSubmitGI = (e) => {
    e.preventDefault()

    let body = {};
    console.log(updateGI)
    if(pn !== cpInfo.parkingNumber || cptName !== cpInfo.carParkingTypeName){
      console.log('yes')
      body = {
        ...updateGI,
        carParkingName : cpInfo.carParkingName,
        parkingNumber  : pn,
        carParkingTypeCode : cptc,
        carParkingTypeName : cptName,
        siteCode : siteCode
      }
    }else{
      body = updateGI
    }

console.log(body)
    axios
      .put(`${BASE_URL}/api/v1/Configuration/CarParking/updateCarParking`,
        body,
        { headers: { Authorization: Token } })
      .then((response) => {
       // console.log(response)
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            }
          }).then(() => {
            const c = response.data.carParking;
            console.log(c)
            setCpInfo(response.data.carParking)
            setPn(c.parkingNumber)
            setPc(c.phaseCode)
            setPhaseName(c.phaseName)
            setCptName(c.carParkingTypeName)
            setCptc(c.carParkingTypeCode)
            setDesc(c.parkingDescription)
            setUpdateGI({})
          })
        }
        else {

          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.message
          })
        }

      })
      .catch((error)=>{
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          //text: response.data.message
        })
      })
  }

  const handleUpdatePrice = (e) => {
    e.preventDefault()
    console.log(updatePrice)

    axios
      .put(`${BASE_URL}/api/v1/Configuration/CarParking/updateCarParking`,
        updatePrice,
        { headers: { Authorization: Token } })
      .then((response) => {

        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            }
          }).then(() => {
            const c = response.data.carParking;

            setCpInfo(response.data.carParking)
            setGp(c.carParkingGrossPrice)
          })
        }
        else {

          Swal.fire({
            icon: "error",
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            text: response.data.error
          })
        }

      })

  }

  return (
    <div className='editcarparking'>

      <Button navigateTo={1} />

      <div className='editcarparking__sections'>
        <Paper elevation={2} style={{ padding: '20px', marginBottom: '40px' }}>
          <h4>General Information</h4>
          <br />
          <form onSubmit={(e) => handleSubmitGI(e)}>
            <div className="row justify-content-center">
              <div className='col-4'>
                <label>Site Name</label>
                <input className='form-control' readOnly value={sname} />
              </div>

              <div className='col-4'>
                <label>Site Code</label>
                <input className='form-control' readOnly value={siteCode} />
              </div>

              <div className='col-4'>
                <Form.Group controlId="phase name">
                  <Form.Label>Car Parking Type</Form.Label>
                  <Form.Control as="select" required value={cptName}
                    onChange={(e) => {
                      cpTypes.forEach((c) => {
                        if (c.carParkingTypeName === e.target.value) {
                          { 
                            setCptc(c.carParkingTypeCode)
                            setCptName(c.carParkingTypeName)
                          }
                        }
                      })
                    }}>
                    {cpTypes.map((c, index) => {
                      return <option value={c.carParkingTypeName} key={index}>{c.carParkingTypeName}</option>
                    })}

                  </Form.Control>
                </Form.Group>
              </div>

            </div>
            <br />

            <div className="row justify-content-start">
              <div className='col-4'>
                <label>Car Parking Name</label>
                <input className='form-control' type='text' value={cpInfo?.carParkingName} readOnly />
              </div>

              <div className='col-4'>
                <label>Car Parking Number</label>
                <input className='form-control' type='number' required
                  value={pn}
                  onChange={(e) => {
                    setPn(e.target.value)
                  }} />
              </div>

              <div className='col-4'>
                <Form.Group controlId="phase name">
                  <Form.Label>Phase Name</Form.Label>
                  <Form.Control as="select" required value={phaseName}
                    onChange={(e) => {
                      phases.forEach((p) => {
                        if (p.phaseName === e.target.value) {
                          {
                            setPc(p.phaseCode)
                            setPhaseName(p.phaseName)
                            setUpdateGI((prev) => {
                              return {
                                ...prev,
                                phaseCode: p.phaseCode,
                                phaseName: p.phaseName
                              }
                            })
                          }
                        }
                      })
                    }}>
                    {phases.map((p, index) => {
                      return <option key={index} value={p.phaseName}>{p.phaseName}</option>
                    })}

                  </Form.Control>
                </Form.Group>
              </div>
            </div>

            <br />

            <div className="row justify-content-start">
              <div className='col-12'>
                <label>Parking description</label>
                <textarea className='form-control' value={desc} required
                  onChange={(e) => {
                    setDesc(e.target.value)
                    setUpdateGI((prev) => {
                      return {
                        ...prev,
                        parkingDescription: e.target.value
                      }
                    })
                  }} />
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              <button className='editcarparking__btn' type='sumbit' >Update</button>
            </div>
          </form>
        </Paper>


        <Paper elevation={2} style={{ padding: '20px', marginBottom: '40px' }}>
          <h4>Price Details</h4>
          <br />
          <form onSubmit={(e) => handleUpdatePrice(e)}>
            <div className="row justify-content-center">
              <div className='col-5'>
                <label>Gross Amount <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                <input className='form-control' value={gp} required
                  onChange={(e) => {
                    setGp(e.target.value)
                    setUpdatePrice((prev) => {
                      return {
                        ...prev,
                        carParkingGrossPrice: e.target.value
                      }
                    })
                  }} />
              </div>

              <div className='col-5'>
                <label>GST Rate <em style={{ color: '#EE4B46' }}>(Percentage)</em></label>
                <input className='form-control' readOnly value={cpInfo?.carParkingGSTPercentage} />
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              <div className='col-5'>
                <label>GST <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                <input className='form-control' readOnly value={cpInfo?.carParkingGST} />
              </div>

              <div className='col-5'>
                <label>Total <em style={{ color: '#EE4B46' }}>(INR)</em></label>
                <input className='form-control' readOnly value={cpInfo?.carParkingPrice} />
              </div>
            </div>

            <br />

            <div className="row justify-content-center">
              <button className='editcarparking__btn' type='sumbit'>Update</button>
            </div>
          </form>
        </Paper>

      </div>

    </div>
  )
}

export default EditCarParking