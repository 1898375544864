import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";
import { useParams, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { IoMdArrowBack } from "react-icons/io";
import { BsArrowLeftShort } from "react-icons/bs";
import "./InvBroker.scss";

const InvBroker = () => {
  const navigate = useNavigate();
  const { brokerPAN } = useParams();
  // const { brokerName } = useParams();

  const [invBroker, setInvBroker] = useState({});
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [address, setAddress] = useState("");
  const [rera, setRera] = useState("");
  // changes
  const [type, setType] = useState("");
  const [isActive, setisActive] = useState("");
  const [comment, setComment] = useState("");

  const [change, setChange] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/leadmgmt/broker/getBrokerByPAN/${brokerPAN}`, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((res) => {
        setInvBroker(res.data.broker);
        setName(invBroker.brokerName);
        setCompany(invBroker.brokerCompany);
        setAddress(invBroker.brokerAddress);
        setRera(invBroker.brokerRERA);
        // changes
        setComment(invBroker.comment);
        // setType(invBroker.brokerType);
        setisActive(invBroker.brokerActive);
        console.log(res.data.broker);
      });
  }, [change]);

  //   update broker data
  const handleSubmit = (e) => {
    console.log("Click on Button");
    e.preventDefault();
    axios
      .put(
        `${BASE_URL}/api/v1/leadmgmt/broker/updateBrokerByBrokerPAN`,
        {
          brokerPAN: invBroker.brokerPAN,
          brokerName: name,
          brokerCompany: company,
          brokerAddress: address,
          brokerRERA: rera,
          // brokerType: type,
          brokerActive: isActive,
          comment: comment,
        },
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((res) => {
        console.log(res);

        if (res.data.status === true) {
          Swal.fire("Successfully!", "Broker Updated!", "success");
          setChange(!change);
        } else {
          Swal.fire("Something Wrong!", "Broker Not Updated!", "error");
        }
      });
  };
  console.log(invBroker.brokerName);
  return (
    <div>
      {/* Broker info  */}
      <div className="row justify-content-center mt-5">
        <div className="col-lg-6">
          <button className="back_btn" onClick={() => navigate(-1)}>
            <span>
              <BsArrowLeftShort className="back_icon" />
            </span>
            Back
          </button>
        </div>
      </div>
      <button className="back_btn" onClick={() => navigate(-1)}>
        <span>
          <BsArrowLeftShort className="back_icon" />
        </span>
        Back
      </button>
      {/* <p className="brokerName">{invBroker.brokerName}</p> */}
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-center brokerEdit">
          <div className="details">
            <div className="user_info">
              <label>Broker PAN</label>
              <Form.Control type="text" value={invBroker.brokerPAN} disabled />
            </div>
            <div className="user_info">
              <label>Broker Name</label>
              <Form.Control
                type="text"
                onChange={(e) => setName(e.target.value)}
                defaultValue={invBroker.brokerName}
              />
            </div>
            <div className="user_info">
              <label>Type</label>
              <Form.Control
                // onChange={(e) => setName(e.target.value)}
                type="text"
                name="userLandMark"
                value={invBroker.brokerType}
                disabled
              />
            </div>
            <div className="user_info">
              <label>Broker Company</label>
              <Form.Control
                onChange={(e) => setCompany(e.target.value)}
                type="text"
                name="userLandMark"
                defaultValue={invBroker.brokerCompany}
              />
            </div>
            <div className="user_info">
              <label>Broker RERA</label>
              <Form.Control
                onChange={(e) => setRera(e.target.value)}
                type="text"
                name="userLandMark"
                defaultValue={invBroker.brokerRERA}
              />
            </div>
            <div className="user_info">
              <label>isActive</label>
              <Form.Control
                onChange={(e) => setisActive(e.target.value)}
                type="text"
                name="userLandMark"
                defaultValue={invBroker.brokerActive}
                readOnly
              />
            </div>
          </div>
          <div className="user_info ">
            <label>Broker Address</label>
            <Form.Control
              onChange={(e) => setAddress(e.target.value)}
              as="textarea"
              name="brokerAddress"
              defaultValue={invBroker.brokerAddress}
              className="large-input"
            />
          </div>
          <div className="user_info">
            <label>Comment</label>
            <Form.Control
              onChange={(e) => setComment(e.target.value)}
              as="textarea"
              name="userLandMark"
              defaultValue={invBroker.comment}
              className="large-input"
            />
          </div>
        </div>

        <div className="d-flex justify-content-center upd-btn">
          <button className="save_btn" type="submit">
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default InvBroker;
