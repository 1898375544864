import React, {useEffect, useState} from "react";
import {Modal, Backdrop, Fade} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Form} from "react-bootstrap";
import axios from "axios";
import {BASE_URL} from "../../utils/config/url";
import Swal from "sweetalert2";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AssignModal = ({open, setOpen, lId, setChange}) => {
  const classes = useStyles();
  const [userName, setUserName] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [users, setUsers] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const clearValue = () => {
    setUserName("");
  };
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${BASE_URL}/api/v1/user/getListOfUsers?isActive=${true}`,
       {headers: {Authorization: `bearer ${userInfo.token}`}});
      const data = res?.data?.userList;
      setUsers(data);
      //console.log(data);
    })();
  }, []);
  const handleUser = (value) => {
    const separateValue = value.split(", ");
    setUserName(separateValue[0]);
    setUserFullName(separateValue[1]);
  };
  const handleClose = () => {
    setOpen(!open);
    clearValue();
  };
  const handleAssign = () => {
    axios
      .put(
        `${BASE_URL}/api/v1/leadmgmt/lead/assignlead`,
        {
          leadID: lId,
          userName: userName,
          userFullName: userFullName,
          currentUserFullName: userInfo.userFullName,
          currentUserName: userInfo.userName,
        },
        {headers: {Authorization: `bearer ${userInfo.token}`}}
      )
      .then((response) => {
        setChange((prev) => !prev);
        Swal.fire({
          icon: "success",
          title: "Lead Assigned Succesfully!",
          showConfirmButton: false,
          timer: 1900,
        });
        clearValue();
        setOpen(false);
      });
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Form.Group className="mb-3" controlId="status">
            <Form.Label>Assign to</Form.Label>
            <Form.Control as="select" onChange={(e) => handleUser(e.target.value)}>
              <option>Select an assignee</option>
              {users?.map((single, index) => (
                <option key={index} value={`${single?.userName}, ${single?.userFullName}`}>
                  {single?.userFullName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <div className="text-center mt-4">
            <button className="btn btn-secondary btn-user" disabled={!userName} onClick={handleAssign}>
              Save
            </button>
            &nbsp;&nbsp;
            <button
              className="btn btn-secondary btn-user"
              onClick={handleClose}
              style={{
                backgroundColor: "white",
                color: "black",
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AssignModal;
