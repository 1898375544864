import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../utils/config/url";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import "../../components/Tabs/Tabs.scss";
import { FormControl } from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useStyles } from "../../utils/ModalStyles";
import { useNavigate } from "react-router-dom";
import { Backdrop, Fade, Modal } from "@mui/material";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Tooltip } from "@mui/material";

const CreditChequeBook = () => {
  const classes = useStyles();
  const [cheques, setCheques] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [depositedBy, setDepositedBy] = useState("");
  const [chequeNo, setChequeNo] = useState("");
  const [micr, setMicr] = useState("");
  const [chequeCleared, setChequeCleared] = useState("");
  const [clearanceDate, setClearanceDate] = useState("");
  const [bounceReason, setBounceReason] = useState("");
  const [instrumentType, setInstrumentType] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();

  useEffect(() => {
    getListOfCheque();
  }, []);

  const getListOfCheque = () => {
    axios
      .get(`${BASE_URL}/api/v1/FinanceMgmt/Cheque/getlistofcheque?paymentType=Credit`, {
        headers: { Authorization: `bearer ${userInfo.token}` },
      })
      .then((response) => {
        // console.log(response)
        setCheques(response.data.chequeList);
      });
  };

  useEffect(() => {
    setCheques([]);

    // if (category === "") {
    //   setSubCategory("");
    //   return getListOfCheque();
    // }

    // console.log(category, subCategory, status)
    let params = [];

    if (category !== "") {
      params.push("paymentCategory");
    }
    if (subCategory) {
      params.push("paymentSubCategory");
    }
    if (instrumentType !== "") {
      params.push("instrumentType");
    }
    let url = [];
    console.log(params);
    if (params.length === 0) {
      setSubCategory("");
      return getListOfCheque();
    }
    params.forEach((p, index) => {
      if (p === "paymentCategory") {
        url.push(`&${p}=${category}`);
      }
      if (p === "paymentSubCategory") {
        url.push(`&${p}=${subCategory}`);
      }
      if (p === "instrumentType") {
        url.push(`&${p}=${instrumentType}`);
      }
    });

    let final = url.join("");

    axios
      .get(
        `${BASE_URL}/api/v1/FinanceMgmt/Cheque/getlistofcheque?paymentType=Credit${final}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        // console.log(response)
        if (response.data.chequeList) {
          setCheques(response.data.chequeList);
        } else {
          setCheques([]);
        }
      });
  }, [instrumentType, category, subCategory]);

  const reset = () => {
    setCategory("");
    setSubCategory("");
    setInstrumentType("");
  };

  const handleModal = (e) => {
    e.preventDefault();

    let body = {
      clearanceBankName: accountName,
      clearanceBankAccount: accountNo,
      chequeNo: chequeNo,
      MICRNo: micr,
      depositedBy: depositedBy,
      userName: userInfo.userName,
      userFullName: userInfo.userFullName,
    };

    axios
      .post(`${BASE_URL}/api/v1/FinanceMgmt/Cheque/chequesenttobank`, body, {
        headers: { Authorization: `bearer ${userInfo?.token}` },
      })
      .then((response) => {
        //  console.log(response)
        setOpen(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setDepositedBy("");
            setAccountName("");
            setAccountNo("");
            getListOfCheque();
          });
        }
      })
      .catch((err) => {
        setOpen(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const handleModal2 = (e) => {
    e.preventDefault();

    // let arr = clearanceDate.split('-')
    // let final = arr.reverse().join('/')
    console.log(clearanceDate);

    let body = {};

    if (!bounceReason) {
      body = {
        chequeNo: chequeNo,
        MICRNo: micr,
        clearanceDate: clearanceDate,
        chequeCleared: chequeCleared,
      };
    } else {
      body = {
        chequeNo: chequeNo,
        MICRNo: micr,
        clearanceDate: clearanceDate,
        chequeCleared: chequeCleared,
        bounceReason: bounceReason,
      };
    }

    //console.log(body)
    axios
      .post(
        `${BASE_URL}/api/v1/FinanceMgmt/Cheque/chequeClearanceConfirmationCredit`,
        body,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        // console.log(response)
        setOpen2(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(function () {
            setOpen2(false);
            setMicr("");
            setChequeNo("");
            setClearanceDate("");
            setChequeCleared(false);
            setBounceReason("");
            getListOfCheque();
          });
        }
      })
      .catch((err) => {
        setOpen2(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  const dateFormatting = (date) => {
    let str = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Jakarta",
    });

    let d = new Date(str).getDate();
    if (d < 10) {
      d = `0${d}`;
    }
    let m = new Date(str).getMonth() + 1;
    if (m < 10) {
      m = `0${m}`;
    }
    let y = new Date(str).getFullYear();
    return `${d}-${m}-${y}`;
  };

  return (
    <div>
      <br />

      <h4>Credit Cheques</h4>

      <MaterialTable
        data={cheques}
        title=""
        columns={[
          {
            title: "Actions",
            render: (rowData) => {
              return (
                <div className="icons" style={{ display: "flex" }}>
                  <Tooltip title="View Cheque">
                    <RemoveRedEyeIcon
                      style={{ marginRight: "15px", cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/finance/chequebook/${rowData.chequeNo}`);
                        localStorage.setItem("MICRNo", rowData.MICRNo);
                        localStorage.setItem(
                          "ActiveKeyIndividualCheque",
                          "first"
                        );
                      }}
                    />
                  </Tooltip>

                  <Tooltip title="Send to Bank">
                    <AccountBalanceIcon
                      style={{
                        color: rowData.sentToBank ? "lightgrey" : "black",
                        cursor: rowData.sentToBank ? "default" : "pointer",
                        display:
                          userInfo.userRole === "Finance" ? "block" : "none",
                        marginRight: "15px",
                      }}
                      onClick={() => {
                        if (rowData.sentToBank === false) {
                          setOpen(true);
                          setChequeNo(rowData?.chequeNo);
                          setMicr(rowData?.MICRNo);
                        }
                      }}
                    />
                  </Tooltip>

                  <Tooltip title="Confirm Cheque Clearance">
                    <CheckCircleOutlineIcon
                      style={{
                        color:
                          rowData.chequeStatus !== "SentToBank"
                            ? "lightgrey"
                            : "black",
                        cursor:
                          rowData.chequeStatus !== "SentToBank"
                            ? "default"
                            : "pointer",
                        display:
                          userInfo.userRole === "Finance" ? "block" : "none",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        if (rowData.chequeStatus === "SentToBank") {
                          setOpen2(true);
                          setChequeNo(rowData.chequeNo);
                          setMicr(rowData.MICRNo);
                        }
                      }}
                    />
                  </Tooltip>
                </div>
              );
            },
          },
          { title: "Cheque No.", field: "chequeNo" },
          { title: "Amount", field: "chequeAmount" },
          { title: "Status", field: "chequeStatus" },
          {
            title: "Category",
            field: "paymentCategory",
          },
          {
            title: "Sub Category",
            field: "paymentSubCategory",
          },
          {
            title: "Instrument Type",
            field: "instrumentType",
          },
          { title: "Cheque Bank", field: "chequeBankName" },
          {
            title: "Cheque Date",
            render: (rowData) => {
              let res = "";
              if (rowData.chequeDate) {
                res = dateFormatting(rowData.chequeDate);
              }

              return !rowData.chequeDate ? "" : res;
            },
            customSort: (a, b) => (a.chequeDate < b.chequeDate ? -1 : 1),
            defaultSort: "desc",
          },

          {
            title: "Sent to Bank",
            render: (rowData) => {
              return rowData.sentToBank ? "Yes" : "No";
            },
          },
          {
            title: "Bank Submit Date",
            render: (rowData) =>
              !rowData.bankSubmitDate
                ? ""
                : rowData.bankSubmitDate.substring(8, 10) +
                  "-" +
                  rowData.bankSubmitDate.substring(5, 7) +
                  "-" +
                  rowData.bankSubmitDate.substring(0, 4),
            customSort: (a, b) =>
              a.bankSubmitDate < b.bankSubmitDate ? -1 : 1,
          },
          {
            title: "Clearance Processed",
            render: (rowData) => {
              return rowData.clearanceProcessed ? "Yes" : "No";
            },
          },
          {
            title: "Cleared",
            render: (rowData) => {
              return rowData.chequeCleared === true ? "Yes" : "No";
            },
          },
          {
            title: "Clearance Date",
            render: (rowData) =>
              !rowData.clearanceDate
                ? ""
                : rowData.clearanceDate.substring(8, 10) +
                  "-" +
                  rowData.clearanceDate.substring(5, 7) +
                  "-" +
                  rowData.clearanceDate.substring(0, 4),
            customSort: (a, b) => (a.clearanceDate < b.clearanceDate ? -1 : 1),
          },
        ]}
        options={{
          search: window.innerWidth < 700 ? false : true,
          searchFieldStyle: {
            width: 200,
          },
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          actionsColumnIndex: 0,
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
            whiteSpace: "nowrap",
          },
        }}
        components={{
          Toolbar: (props) => (
            <div className="filters text-left">
              <MTableToolbar {...props} />

              <FormControl
                variant="standard"
                className={classes.formControl}
                style={{
                  margin: "0px 10px",
                  marginTop: "-65px",
                  width: "150px",
                }}
              >
                <InputLabel
                  id="demo-simple-select-standard-label"
                  style={{ fontSize: "13px" }}
                >
                  Category
                </InputLabel>
                <Select
                  llabelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setSubCategory("");
                  }}
                  className={classes.selectEmpty}
                  style={{
                    fontSize: "13px",
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">Category</MenuItem>
                  <MenuItem value="CustomerPayment">Customer Payment</MenuItem>
                  <MenuItem value="OtherPayment">Other Payment</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                variant="standard"
                // className={classes.formControl}
                style={{
                  margin: "0px 10px",
                  marginTop: "-65px",
                  width: "150px",
                }}
              >
                <InputLabel
                  id="demo-simple-select-standard-label"
                  style={{ fontSize: "13px" }}
                >
                  Sub Category
                </InputLabel>
                {category === "CustomerPayment" ? (
                  <Select
                    llabelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                    className={classes.selectEmpty}
                    inputProps={{ "aria-label": "Without label" }}
                    style={{
                      fontSize: "13px",
                      textAlign: "left",
                    }}
                    disabled={category === "" ? true : false}
                  >
                    <MenuItem value="">SubCategory</MenuItem>
                    <MenuItem value="BookingPayment">Booking Payment</MenuItem>
                    <MenuItem value="DemandPayment">Demand Payment</MenuItem>
                    <MenuItem value="FinalPayment">Final Payment</MenuItem>
                  </Select>
                ) : (
                  <Select
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                    className={classes.selectEmpty}
                    inputProps={{ "aria-label": "Without label" }}
                    disabled={category === "" ? true : false}
                  >
                    <MenuItem value="">Sub Category</MenuItem>
                    <MenuItem value="MaterialsSold">Materials Sold</MenuItem>
                    <MenuItem value="OtherPayment">Other Payment</MenuItem>
                  </Select>
                )}
              </FormControl>
              <FormControl
                variant="standard"
                className={classes.formControl}
                style={{
                  margin: "0px 10px",
                  marginTop: "-65px",
                  width: "150px",
                }}
              >
                <InputLabel
                  id="demo-simple-select-standard-label"
                  style={{ fontSize: "13px" }}
                >
                  Instrument Type
                </InputLabel>
                <Select
                  llabelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={instrumentType}
                  onChange={(e) => {
                    setInstrumentType(e.target.value);
                  }}
                  className={classes.selectEmpty}
                  style={{
                    fontSize: "13px",
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">Instrument Type</MenuItem>
                  <MenuItem value="DemandDraft">Demand Draft</MenuItem>
                  <MenuItem value="Cheque">Cheque</MenuItem>
                </Select>
              </FormControl>
              {window.innerWidth < 1000 && <br />}

              <FormControl
                className={classes.formControl}
                style={{
                  marginTop: window.innerWidth < 1000 ? "-20px" : "-50px",
                  marginLeft: "7px",
                }}
              >
                <button
                  onClick={reset}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                    borderRadius: "20px",
                    padding: "5px",
                  }}
                >
                  Reset Filter
                </button>
              </FormControl>
            </div>
          ),
        }}
      ></MaterialTable>

      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(false);
          setDepositedBy("");
          setAccountName("");
          setAccountNo("");
        }}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <form
              onSubmit={(e) => handleModal(e)}
              style={{
                display: "flex",
                //border: '2px solid green',
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="row container-fluid justify-content-center">
                <div className="col-10">
                  <Form.Group controlId="payment-category">
                    <Form.Label>Collection Account</Form.Label>
                    <Form.Control
                      as="select"
                      required
                      value={accountName}
                      onChange={(e) => {
                        let value = e.target.value;
                        setAccountName(value);
                        if (value === "WESTROAD DEVELOPERS PVT LTD A/C")
                          setAccountNo("50442540521");
                        else if (value === "WESTROAD HEIGHTS COLLECTION A/C")
                          setAccountNo("38861908851");
                      }}
                    >
                      <option value="">Select an Account</option>
                      <option value="WESTROAD DEVELOPERS PVT LTD A/C">
                        WESTROAD DEVELOPERS PVT LTD A/C
                      </option>
                      <option value="WESTROAD HEIGHTS COLLECTION A/C">
                        WESTROAD HEIGHTS COLLECTION A/C
                      </option>
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-10 mt-2">
                  <label>Deposited By</label>
                  <input
                    type="text"
                    className="form-control"
                    value={depositedBy}
                    onChange={(e) => setDepositedBy(e.target.value)}
                  />
                </div>
              </div>

              <br />

              {/*<div className="row container-fluid justify-content-center">
                <div className="col-6 text-center">
                  <label>MICR No.</label>
                  <input type="text" className="form-control" value={micr} onChange={(e) => setMicr(e.target.value)} />
                </div>
                    </div>*/}

              <br />
              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-6 text-center">
                  <button className="modal__btns" type="submit">
                    Save
                  </button>
                </div>

                <div className="col-6 text-center">
                  <button
                    className="modal__btns"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(false);
                      setDepositedBy("");
                      setAccountName("");
                      setAccountNo("");
                      setMicr("");
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
        open={open2}
        onClose={() => {
          setOpen2(false);
          setMicr("");
          setChequeNo("");
          setClearanceDate("");
          setChequeCleared(false);
          setBounceReason("");
        }}
        closeAfterTransition
      >
        <Fade in={open2}>
          <div className={classes.paper}>
            <form
              onSubmit={(e) => handleModal2(e)}
              style={{
                display: "flex",
                //border: '2px solid green',
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h4>Cheque Clearnce Confirmation</h4>

              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-12">
                  <label>Clearance Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={clearanceDate}
                    max={new Date().toISOString().split("T")[0]}
                    required
                    onChange={(e) => setClearanceDate(e.target.value)}
                  />
                </div>
              </div>

              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-12">
                  <label>Cheque Passed</label>
                  <input
                    type="radio"
                    required
                    name="cheque cleared"
                    style={{ marginLeft: "25px" }}
                    onClick={() => setChequeCleared(true)}
                  />{" "}
                  Yes
                  <input
                    type="radio"
                    required
                    name="cheque cleared"
                    style={{ marginLeft: "25px" }}
                    onClick={() => setChequeCleared(false)}
                  />{" "}
                  No
                </div>
              </div>

              <br />

              {chequeCleared === false && (
                <div className="row container-fluid justify-content-center">
                  <div className="col-12">
                    <label>Bounce Reason</label>
                    <input
                      type="text"
                      onChange={(e) => setBounceReason(e.target.value)}
                      required
                      className="form-control"
                    />
                  </div>
                </div>
              )}

              <br />

              <div className="row container-fluid justify-content-center">
                <div className="col-6 text-center">
                  <button className="modal__btns" type="submit">
                    Save
                  </button>
                </div>

                <div className="col-6 text-center">
                  <button
                    className="modal__btns"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen2(false);
                      setMicr("");
                      setChequeNo("");
                      setClearanceDate("");
                      setChequeCleared(false);
                      setBounceReason("");
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default CreditChequeBook;
