import React, { useState } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import { BASE_URL } from '../../utils/config/url'
import customer from '../../assets/images/customer.png'
import westroad from '../../assets/images/westroad.png'
import date from '../../assets/images/date.jpeg'

const Comments = ({ ticket, change, setChange }) => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [comment, setComment] = React.useState('')

    const addCommentToTicket = () => {
        console.log(comment)

        axios.put(`${BASE_URL}/api/v1/customermgmt/ticket/addcommenttoticket`,
            {
                ticketId: ticket?.ticketId,
                workComments: comment,
                commentType: 'westroad',
                commentBy: userInfo.userFullName
            },
            {
                headers: { Authorization: `Bearer ${userInfo.token}` },
            })
            .then((response) => {
                if (response.data.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                    })
                        .then(() => {
                            setChange(!change)
                            setComment('')
                        })
                }

            })
            .catch((err) => {
                // console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error,
                })
            })

    }

    const dateFormatting = (input) => {

        if(!input) return 'NA'

        let date = new Date(input)
        let d = date.getDate()
        let m = date.getMonth() + 1;
        let y = date.getFullYear()

        return `${d}/${m}/${y}`
    }

    return (
        <div className='inv-ticket__comments'>

            <div className='row flex-column align-items-center mt-4'>
                <div className='col-6 text-center'>
                    <textarea className='form-control'
                        rows="5" cols="50"
                        placeholder='Add Comment...'
                        style={{ border: '2px solid #EE4B46' }}
                        value={comment}
                        onChange={e => setComment(e.target.value)}
                    />
                </div>

                <br />

                <div className='col-12 row justify-content-center'>
                    <div className='col-4 text-center'>
                        <button
                            style={{ backgroundColor: 'white', color: '#EE4B46', border: '1px solid #EE4B46' }}
                            onClick={() => setComment('')}
                        >Clear</button>
                    </div>
                    <div className='col-4 text-center'>
                        <button
                            onClick={() => addCommentToTicket()}
                        >Save</button>
                    </div>
                </div>


            </div>

            <br />

            {
                ticket?.workComments?.map((comment, index) => {
                    return (
                        <div className='row inv-ticket__comments__card'>

                            <div className='row d-flex justify-content-between'>

                                <div className='col-7 d-flex'>
                                    <img src={comment?.commentType === 'westroad' ? westroad : customer} />
                                    <span style={{fontSize : '20px'}}><b>Commented By : </b> <span style={{color : '#EE4B46'}}>{comment?.commentBy}</span></span>
                                </div>

                                <div className='col-5 text-center d-flex justify-content-end'>
                                    <img src={date}
                                        style={{ width: '25px', height: '25px' }}
                                    />
                                    <p className='ml-2'>{dateFormatting(comment?.commentDate)}</p>
                                </div>
                            </div>

                            <div className='row justify-content-start'>
                                <div className='col-10' style={{marginLeft:"4.7rem", marginTop:"-2rem", textAlign:"justify"}}>
                                    {comment?.comment}
                                </div>
                            </div>

                        </div>
                    )
                })
            }

        </div>
    )
}

export default Comments
