import { Navigate} from "react-router-dom";
import AddLoanBankForm from "./AddLoanBankForm.js";

const AddLoanBank=()=>{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if( userInfo.token === undefined ) {
    return <Navigate to="/login" noThrow />
  }
  else {
  return <AddLoanBankForm/>;
  }
}

export default AddLoanBank;