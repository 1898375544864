import React, { useState } from "react";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ActionModal = ({ open, setOpen, svid, setChange , leadId }) => {

    
  const classes = useStyles();
  const [svStatus, setSvStatus] = useState("");
  const [siteVisitStatus, setSiteVisitStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [creason, setCreason] = useState("");
  const [rdate, setRdate] = useState("");
  const [rTime, setRTime] = useState("");
  const [rReason, setRReason] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const clearValue = () => {
    setRemarks("");
    setSvStatus("");
    setCreason("");
    setRdate("");
    setRTime("");
    setRReason("");
    setSiteVisitStatus("");
  };
  const handleClose = () => {
    setOpen(!open);
    clearValue();
  };
  const handleEditSv = () => {
    if (svStatus === "Completed") {
      axios
        .post(
          `${BASE_URL}/api/v1/leadmgmt/siteVisit/completeSiteVisit`,
          {
            siteVisitId: svid,
            siteVisitStatus: siteVisitStatus,
            siteVisitRemarks: remarks,
            userFullName: userInfo.userFullName,
            userName: userInfo.userName,
          },
          { headers: { Authorization: `bearer ${userInfo.token}` } }
        )
        .then((response) => {
          console.log(response)
          setOpen(false)
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: response.data.message,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              }
            }).then(function () {

              setChange((prev) => !prev);
              clearValue()
            })
          }

        });
    } else if (svStatus === "Cancelled") {

      siteCancelled()

    } else if (svStatus === "Rescheduled") {
      siteRescheduled()
    }
  };

  const siteCancelled = () => {
    axios
      .post(
        `${BASE_URL}/api/v1/leadmgmt/siteVisit/cancelSiteVisit`,
        {
          siteVisitId: svid,
          leadID : leadId,
          cancellationReason: creason,
          userFullName: userInfo.userFullName,
          userName: userInfo.userName,
        },
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      )
      .then((response) => {
       // console.log(response)

        setOpen(false)
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            }
          }).then(function () {

            clearValue();
            setChange((prev) => !prev);
          })
        }

      })
      .catch((err)=>{
        setOpen(false)
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
              popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
              popup: "animate__animated animate__fadeOutUp",
          },
          text: err
      })
      })
  }

  const siteRescheduled = () => {
    //console.log(svid)
    axios
      .put(
        `${BASE_URL}/api/v1/leadmgmt/siteVisit/rescheduleSiteVisit`,
        { leadID : leadId,
          siteVisitId: svid,
          siteVisitDate: rdate,
          siteVisitTime: rTime,
          rescheduleReason: rReason,
          userName : userInfo.userName,
          userFullName: userInfo.userFullName,
        },
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      )
      .then((response) => {
       // console.log(response)
        setOpen(false)
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            }
          }).then(function () {

            clearValue();
            setChange((prev) => !prev);
          })
        }
      });
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Form.Group className="mb-3" controlId="status">
            <Form.Label>Status</Form.Label>
            <Form.Control as="select" onChange={(e) => setSvStatus(e.target.value)}>
              <option>Select a Status</option>
              <option value="Completed">Completed</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Rescheduled">Rescheduled</option>
            </Form.Control>
          </Form.Group>

          {svStatus === "Completed" && (
            <>
              <Form.Group controlId="status">
                <Form.Label>Site Visit Status</Form.Label>
                <Form.Control as="select" onChange={(e) => setSiteVisitStatus(e.target.value)}>
                  <option>Select a Site Visit Status</option>
                  <option value="Interested">Interested</option>
                  <option value="Not Interested">Not Interested</option>
                </Form.Control>
              </Form.Group>
              <br />
              {siteVisitStatus === "Interested" || siteVisitStatus === "Not Interested" ? (
                <>
                  <label>Remarks</label>
                  <input type="text" class="form-control" name="remarks" onChange={(e) => setRemarks(e.target.value)} />
                </>
              ) : null}
              <br />
              <div className="text-center">
                <button className="btn btn-secondary btn-user" onClick={handleEditSv}>
                  Save
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-secondary btn-user"
                  onClick={handleClose}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          )}

          {svStatus === "Cancelled" ? (
            <>
              <label>Reason for cancellation</label>
              <input type="text" class="form-control" name="reason" onChange={(e) => setCreason(e.target.value)} />

              <br />
              <div className="text-center">
                <button className="btn btn-secondary btn-user" onClick={handleEditSv}>
                  Save
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-secondary btn-user"
                  onClick={handleClose}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : null}

          {svStatus === "Rescheduled" ? (
            <>
              <label>Reschedule on</label>
              <input type="date" class="form-control" name="rdate" onChange={(e) => setRdate(e.target.value)} />
              <br />
              <label>Reschedule Time</label>
              <select className="form-control" name="rTime" onChange={(e) => setRTime(e.target.value)}>
                <option value="" selected disabled>
                  --Select--
                </option>
                <option value="9AM-10AM">9AM-10AM</option>
                <option value="10AM-11AM">10AM-11AM</option>
                <option value="11AM-12PM">11AM-12PM</option>
                <option value="12PM-01PM">12PM-01PM</option>
                <option value="01PM-02PM">01PM-02PM</option>
                <option value="02PM-03PM">02PM-03PM</option>
                <option value="03PM-04PM">03PM-04PM</option>
                <option value="04PM-05PM">04PM-05PM</option>
                <option value="05PM-06PM">05PM-06PM</option>
                <option value="06PM-07PM">06PM-07PM</option>
                <option value="07PM-08PM">07PM-08PM</option>
              </select>
              <br />
              <label>Reschedule Reason</label>
              <input type="text" class="form-control" name="rReason" onChange={(e) => setRReason(e.target.value)} />
              <br />
              <div className="text-center">
                <button className="btn btn-secondary btn-user" onClick={handleEditSv}>
                  Save
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-secondary btn-user"
                  onClick={handleClose}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : null}
        </div>
      </Fade>
    </Modal>
  );
};

export default ActionModal;
