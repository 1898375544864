import React, { useEffect } from 'react'
import { Nav } from 'react-bootstrap';
import Details from '../../components/InvSalaryAdvance/Details';
import './InvSalaryAdvance.scss';
import Payment from '../../components/InvSalaryAdvance/Payment';
import Button from '../../components/Button/Button';

const InvSalaryAdvance = () => {

    const [tab, setTab] = React.useState('Details')

   
    return (
        <div className='inv-salary-advance'>

            <Button navigateTo="/myprofile"/>

            <br/>

            <div className="tabs mb-5">
                <Nav>

                    <Nav.Item
                        onClick={() => {
                            setTab('Details')
                        }}
                    >
                        <Nav.Link className={tab === 'Details' ? 'active' : 'inactive'} eventKey="first">
                            Details
                        </Nav.Link>
                    </Nav.Item>


                    <Nav.Item
                        onClick={() => {
                            setTab('Payment')
                        }}
                    >
                        <Nav.Link className={tab === 'Payment' ? 'active' : 'inactive'} eventKey="second">
                            Payment
                        </Nav.Link>
                    </Nav.Item>

                </Nav>

            </div>

            {tab === 'Details' && <Details/>}
            {tab === 'Payment' && <Payment/>}

        </div>
    )
}

export default InvSalaryAdvance