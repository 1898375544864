import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ListItem,
  List,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import { FaDraftingCompass, FaUserFriends } from "react-icons/fa";
import { BsBuilding, BsPeople } from "react-icons/bs";
import { GiTakeMyMoney } from "react-icons/gi";
import { BsFillPieChartFill } from "react-icons/bs";
import { AiOutlineSetting } from "react-icons/ai";
import "./dashboard.scss";
import "./sidebar.scss";
import { useContext } from "react";
import { Context } from "../../utils/Context";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";

const Sidebar = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${userInfo.employeeId}`,
        {
          headers: { Authorization: `bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          setEmployee(response.data.employee);
        } else {
          setEmployee({});
        }
      });
  }, []);

  const [expanded, setExpanded] = useState("");
  const [employee, setEmployee] = useState({});
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { menu } = useContext(Context);
  const screenSize = window.screen.width;

  return (
    <div
      className="sidebar"
      style={{
        transform:
          !menu && screenSize > 100 ? "translate(0px)" : "translate(-240px)",
      }}
    >
      <List>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <BsPeople className="sidebar-icons" />
            <Typography variant="body2" className="sidebar-menu">
              Customer Acquisition
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/addlead"
              >
                <ListItem button key={"Add Lead"}>
                  <span>Add Lead</span>
                </ListItem>
              </Link>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/lead-management"
              >
                <ListItem button key={"Lead Management"}>
                  <span>Lead Management</span>
                </ListItem>
              </Link>

              {(userInfo.userRole === "Director" ||
                employee.leadFullAccess === true) && (
                <Link
                  style={{ color: "#073b4c", textDecoration: "none" }}
                  to="/quotations"
                >
                  <ListItem button key={"Quotations"}>
                    <span>Quotations</span>
                  </ListItem>
                </Link>
              )}

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/site-visits"
              >
                <ListItem button key={"Site Visits"}>
                  <span>Site Visits</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/listofChannelPartner"
              >
                <ListItem button key={"Channel Partner"}>
                  <span>Channel Partner</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/initiatebooking"
              >
                <ListItem button key={"Initiate Booking"}>
                  <span>Initiate Booking</span>
                </ListItem>
              </Link>
            </List>
          </AccordionDetails>
        </Accordion>
      </List>

      <List>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <BsBuilding className="sidebar-icons" />
            <Typography variant="body2" className="sidebar-menu">
              Flat Allotment
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/flatAllotment/manageapplications"
              >
                <ListItem button key={"Manage Applications"}>
                  <span>Manage Applications</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/flatAllotment/listofbooking"
              >
                <ListItem button key={"List of Booking Payments"}>
                  <span>List of Booking Payments</span>
                </ListItem>
              </Link>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/flatAllotment/flats"
              >
                <ListItem button key={"Flats / Units"}>
                  <span>Flats / Units</span>
                </ListItem>
              </Link>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/flatAllotment/carparkinglist"
              >
                <ListItem button key={"Car Parking"}>
                  <span>Car Parking</span>
                </ListItem>
              </Link>
            </List>
          </AccordionDetails>
        </Accordion>
      </List>

      <List>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <GiTakeMyMoney className="sidebar-icons" />
            <Typography variant="body2" className="sidebar-menu">
              Customer Relations
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/viewcustomers"
              >
                <ListItem button key={"Customer List"}>
                  <span>Customer List</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/demands"
              >
                <ListItem button key={"Demands"}>
                  <span>Demands</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/support"
              >
                <ListItem button key={"Support"}>
                  <span>Support</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/cashback"
                onClick={() => localStorage.setItem("ActiveCashKey", "first")}
              >
                <ListItem button key={"Cashback"}>
                  <span>Cashback</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/handover"
              >
                <ListItem button key={"Handover"}>
                  <span>Handover</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/alteration-requests"
              >
                <ListItem button key={"Alteration requests"}>
                  <span>Alteration Requests</span>
                </ListItem>
              </Link>
            </List>
          </AccordionDetails>
        </Accordion>
      </List>

      <List>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <BsFillPieChartFill className="sidebar-icons" />
            <Typography variant="body2" className="sidebar-menu">
              Finance
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/finance/banktransfer"
              >
                <ListItem button key={"Bank Transfer"}>
                  <span>Bank Transfer</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/finance/bankpayment"
              >
                <ListItem button key={"Bank Payment"}>
                  <span>Bank Payment</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/finance/cashdeposit"
              >
                <ListItem button key={"Cash Deposit"}>
                  <span>Cash Deposit</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/finance/cashpayment"
              >
                <ListItem button key={"Cash Payment"}>
                  <span>Cash Payment</span>
                </ListItem>
              </Link>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/finance/chequebook"
                onClick={() =>
                  localStorage.setItem("ActiveKeyCheques", "first")
                }
              >
                <ListItem button key={"Cheque Book"}>
                  <span>Cheque Book</span>
                </ListItem>
              </Link>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/finance/transactions"
                onClick={() =>
                  localStorage.setItem("ActiveKeyTransaction", "first")
                }
              >
                <ListItem button key={"Transactions"}>
                  <span>Transactions</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/finance/moneyreceipts"
              >
                <ListItem button key={"Receipts"}>
                  <span>Money Receipts</span>
                </ListItem>
              </Link>
              {/* <Link style={{ color: "#073b4c", textDecoration: "none" }} to="/taskmanagement">
                <ListItem button key={"Utils"}>
                  <span>Utils</span>
                </ListItem>
              </Link> */}

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/gst"
              >
                <ListItem button key={"GST"}>
                  <span>GST</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/finance/claims"
              >
                <ListItem button key={"Claims"}>
                  <span>Claims</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/finance/debit-payments"
              >
                <ListItem button key={"Debit Payments"}>
                  <span>Debit Payments</span>
                </ListItem>
              </Link>

              {(userInfo?.userRole === "Director" ||
                userInfo?.userRole === "Finance") && (
                <Link
                  style={{ color: "#073b4c", textDecoration: "none" }}
                  to="/finance/costCenters"
                >
                  <ListItem button key={"Cost Centers"}>
                    <span>Cost Center</span>
                  </ListItem>
                </Link>
              )}
            </List>
          </AccordionDetails>
        </Accordion>
      </List>

      <List>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <AiOutlineSetting className="sidebar-icons" />
            <Typography variant="body2" className="sidebar-menu">
              Configurations
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/configurations/site"
              >
                <ListItem button key={"Site"}>
                  <span>Site</span>
                </ListItem>
              </Link>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/configurations/paymentTerms"
              >
                <ListItem button key={"Payment Terms"}>
                  <span>Payment Terms</span>
                </ListItem>
              </Link>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/configurations/TDS"
              >
                <ListItem button key={"TDS"}>
                  <span>TDS</span>
                </ListItem>
              </Link>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/listofbanks"
              >
                <ListItem button key={"Bank"}>
                  <span>Bank</span>
                </ListItem>
              </Link>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/addloanbank"
              >
                <ListItem button key={"addloanBank"}>
                  <span>Add Bank</span>
                </ListItem>
              </Link>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/configurations/unitInspectionListType"
              >
                <ListItem button key={" Unit Inspection List Type"}>
                  <span> Unit Inspection List Type</span>
                </ListItem>
              </Link>
            </List>
          </AccordionDetails>
        </Accordion>
      </List>

      <List>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <FaDraftingCompass className="sidebar-icons" />
            <Typography variant="body2" className="sidebar-menu">
              Engineering
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/engineering/procurements"
              >
                <ListItem button key={"Procurements"}>
                  <span>Procurements</span>
                </ListItem>
              </Link>
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/engineering/alterations"
              >
                <ListItem button key={"Alterations"}>
                  <span>Alterations</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/engineering/stockManagement"
              >
                <ListItem button key={"Stock Management"}>
                  <span>Stock Management</span>
                </ListItem>
              </Link>
            </List>
          </AccordionDetails>
        </Accordion>
      </List>

      <List>
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <FaUserFriends className="sidebar-icons" />
            <Typography variant="body2" className="sidebar-menu">
              Team Management
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {/* <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/addmember"
              >
                <ListItem button key={"Add Team members"}>
                  <span>Add ERP User</span>
                </ListItem>
              </Link> */}

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/viewuser"
              >
                <ListItem button key={"View Team members"}>
                  <span>ERP User</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/viewemployees"
              >
                <ListItem button key={"View Employees"}>
                  <span>Employees</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/salescode"
              >
                <ListItem button key={"Sales Code"}>
                  <span>Sales Code</span>
                </ListItem>
              </Link>

              {(userInfo?.userRole === "Director" ||
                userInfo?.userRole === "HR") && (
                <Link
                  style={{ color: "#073b4c", textDecoration: "none" }}
                  to="/attendanceManagement"
                >
                  <ListItem button key={"Attendance Management"}>
                    <span>Attendance Management</span>
                  </ListItem>
                </Link>
              )}

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/inOffice"
              >
                <ListItem button key={"In Office"}>
                  <span>In Office</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/leavemanagement"
              >
                <ListItem button key={"Leave Management"}>
                  <span>Leave Management</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/payRoll"
              >
                <ListItem button key={"PayRoll"}>
                  <span>Pay Roll</span>
                </ListItem>
              </Link>

              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/salaryAdvance"
              >
                <ListItem button key={"Salary Advance"}>
                  <span>Salary Advance</span>
                </ListItem>
              </Link>

              {/* <Link style={{ color: "#073b4c", textDecoration: "none" }} to="/utils">

                <ListItem button key={"Utils"}>
                  <span>Utils</span>
                </ListItem>
              </Link> */}
              <Link
                style={{ color: "#073b4c", textDecoration: "none" }}
                to="/defaultAsignee"
              >
                <ListItem button key={"Utils"}>
                  <span>Default Asignee</span>
                </ListItem>
              </Link>
              {(userInfo?.userRole === "Director" ||
                userInfo?.userRole === "HR") && (
                  <Link
                    style={{ color: "#073b4c", textDecoration: "none" }}
                    to="/office"
                  >
                    <ListItem button key={"Utils"}>
                      <span>Office</span>
                    </ListItem>
                  </Link>
                )}
                {(userInfo?.userRole === "Director" ||
                userInfo?.userRole === "HR" || userInfo?.userRole === "Operations") && (
                  <Link
                    style={{ color: "#073b4c", textDecoration: "none" }}
                    to="/list-of-onboardings"
                  >
                    <ListItem button key={"Utils"}>
                      <span>Onboarding</span>
                    </ListItem>
                  </Link>
                )}
            </List>
          </AccordionDetails>
        </Accordion>
      </List>
    </div>
  );
};

export default Sidebar;
