import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";
import MaterialTable from "material-table";
import { useNavigate } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { Add } from "@material-ui/icons";
import "./BrokerTable.scss";
import { Context } from "../../utils/Context";
import { useStyles } from "../../utils/ModalStyles";
import { createMuiTheme, Switch, ThemeProvider } from "@material-ui/core";
import Button from "../../components/Button/Button";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#ef5350",
      main: "#c62828",
      dark: "#b71c1c",
      contrastText: "#fff",
    },
  },
});
const BrokerTable = () => {

  const classes = useStyles();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const Token = "bearer" + " " + userInfo.token;
  const navigate = useNavigate();

  const { setTab } = useContext(Context);
  const [broker, setBroker] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [isChanges, setIsChanges] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [deactivateComment, setDeactivateComment] = useState("");
  const [brokerPAN, setBrokerPAN] = useState('')
  const [leadFullAccess, setLeadFullAccess] = useState(false)

  useEffect(() => {

    axios
      .get(`${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${userInfo?.employeeId}`, {
        headers: { Authorization: Token },
      })
      .then((response) => {

        if (response.data.status) {
          setLeadFullAccess(response.data.employee.leadFullAccess)
        }
      })

  }, [])

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/leadmgmt/broker/getListOfBroker`, {
        headers: { Authorization: Token },
      })
      .then((response) => {
        const brokers = response?.data?.List?.map((broker) => {
          const {
            brokerName,
            brokerCompany,
            brokerAddress,
            brokerRERA,
            brokerPAN,
            brokerActive,
            brokerType,
          } = broker;
          // const date = new Date(updatedAt);
          // const fullDate = date.toISOString().slice(0, 10);

          return {
            brokerName,
            brokerCompany,
            brokerAddress,
            brokerRERA,
            brokerPAN,
            brokerActive,
            brokerType,
          };
        });
        setBroker(brokers);
      });
  }, [isChange]);



  // delete bank
  const handleDelete = (PAN) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${BASE_URL}/api/v1/leadmgmt/broker/deleteBrokerByPAN/${PAN}`,
            {
              headers: { Authorization: Token },
            }
          )
          .then((response) => {
            // console.log(response);
            const errorMessage = response.data.message;
            if (response.data.status === true) {
              setIsChange(!isChange);
              Swal.fire("Deleted!", `${errorMessage}`, "success");
            } else {
              Swal.fire("Not Deleted!", `${errorMessage}`, "error");
            }
          });
      }
    });
  };


  // call the active/deactive api
  const activeDeactiveBroker = (brokerPAN, isActive) => {
    // console.log(brokerPAN, isActive);
    let body = {
      brokerPAN: brokerPAN,
      brokerActive: isActive,
    }

    if (!isActive) {
      body.comment = deactivateComment
    }

    axios
      .put(
        `${BASE_URL}/api/v1/leadmgmt/broker/deactivateBrokerByPAN`,
        body,
        { headers: { Authorization: `bearer ${userInfo.token}` } }
      )
      .then((response) => {
        // console.log(response);
        setDeactivateModal(false)
        setIsChange(!isChange);

        if (response.data.broker.brokerActive === true) {
          Swal.fire(" Successfully!", "Broker Activated!", "success");
        } else {
          Swal.fire("Successfully!", "Broker Deactivated!", "success");
        }
      })
      .catch((err) => {
        // console.log(err)
        setDeactivateModal(false)
        Swal.fire("Error!", err.response.data.error, "error");
      })
  };


  // activate / deactivate broker
  const handleDeactive = (brokerPAN, isActive) => {
    if (isActive) {
      Swal.fire({
        title: "Are you sure?",
        text: "Want to Deactivate the Broker?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          activeDeactiveBroker(brokerPAN, isActive);
        }
      });
    } else {
      activeDeactiveBroker(brokerPAN, isActive);
    }
  };


  return (
    <div className="brokers">
      <div className="brokers__btns">
        <Button navigateTo={"/home"} />
        <button
          style={{ backgroundColor: "#EE4B46", color: "white" }}
          onClick={() => navigate("/addChannelPartner")}
        >
          <Add />
          Add Channel Partner
        </button>
      </div>
      <div className="brokers__table">
        <ThemeProvider theme={theme}>
          <MaterialTable
            data={broker}
            title="Channel Partner"
            actions={[
              (rowData) => ({

                icon: () => (

                  leadFullAccess && <Switch checked={rowData.brokerActive} color="primary" />

                ),
                tooltip: rowData.brokerActive ? "Deactive User" : "Active User",
                onClick: (event, rowData) => {
                  if (rowData.brokerActive === true) {
                    setBrokerPAN(rowData?.brokerPAN)
                    setDeactivateModal(true)
                  } else {
                    activeDeactiveBroker(rowData.brokerPAN, true)
                  }
                },

              }),

              (rowData) => ({
                icon: () => (
                  leadFullAccess && <AiFillDelete/>
                ),
                tooltip: "Delete Broker",
                onClick: (event, rowData) => {
                  // console.log(rowData);
                  handleDelete(rowData.brokerPAN);
                },
              }),

              {
                icon: "remove_red_eye",
                tooltip: "View/Edit Broker",
                onClick: (event, rowData) => {
                  navigate(
                    `/viewIndividualChannelPartner/${rowData.brokerPAN}`
                  );
                },
              },
            ]}
            columns={[
              { title: "Channel Partner Name", field: "brokerName" },
              { title: "Type", field: "brokerType" },
              { title: "Channel Partner PAN", field: "brokerPAN" },
              { title: "Address", field: "brokerAddress" },
              { title: "RERA ", field: "brokerRERA" },
              {
                title: "Active",
                render: (rowData) =>
                  rowData.brokerActive === true ? "Yes" : "No",
              },
            ]}
            options={{
              search: true,
              actionsColumnIndex: 0,
              paging: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 20, 30],
              headerStyle: {
                backgroundColor: "#EE4B46",
                color: "#fff",
              },
            }}
          ></MaterialTable>
        </ThemeProvider>
      </div>


      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={deactivateModal}
        onClose={() => setDeactivateModal(false)}
        closeAfterTransition
      >
        <Fade in={deactivateModal}>
          <form className="paper"
            onSubmit={(e) => {
              e.preventDefault()
              activeDeactiveBroker(brokerPAN, false)
            }}
          >
            <h6>Are you sure you want to Deactivate this Broker ?</h6>
            <br />
            <div className="row container-fluid justify-content-center">
              <div className="col-12">
                <label>Deactivate Comment</label>
                <input type="text" className="form-control"
                  required
                  value={deactivateComment}
                  onChange={(e) => {
                    setDeactivateComment(e.target.value)
                  }}
                />
              </div>
            </div>

            <br />

            <div className="row container-fluid justify-content-center">
              <div className="col-6 text-right">
                <button className="modal__btns"
                  type="submit"
                >
                  Yes
                </button>
              </div>
              <div className="col-6 text-left">
                <button
                  type="click"
                  className="modal__btns"
                  onClick={() => {
                    setDeactivateModal(false)
                    setDeactivateComment('')
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </form>
        </Fade>
      </Modal>


    </div >
  );
};

export default BrokerTable;
