import React, { useEffect , useRef } from 'react'
import heights from '../../../assets/tableView/heights.png'
import iconic from '../../../assets/tableView/iconic.png'
import altitude from '../../../assets/tableView/altitude.png'
import './TabletDashboard.scss'
import { useState } from 'react'
import mainImage from '../../../assets/tableView/hero-img.png';
import { IoIosArrowDown, IoIosArrowRoundForward } from 'react-icons/io';
import sun from '../../../assets/tableView/sun.png'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../../../utils/config/url'
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";


const TabletDashboard = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const [open, setOpen] = React.useState(false);
    const anchorRef = useRef(null);
    const navigate = useNavigate();

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const [projects, setProjects] = useState([
        { name: 'Westroad Heights', img: heights, code: 'WH' },
        { name: 'Westroad Iconic', img: iconic, code: 'WI' },
        { name: 'Westroad Altitude', img: altitude, code: 'WA' },
    ])

    useEffect(() => {
        localStorage.removeItem('site')
    }, [])


    const getSiteBySiteCode = (code) => {
        axios.get(`${BASE_URL}/api/v1/configuration/site/getSiteBySiteCode/${code}`, {
            headers: {
                Authorization: `bearer ${userInfo.token}`
            }
        })
            .then((response) => {
                // console.log(response)
                if (response.data.status) {
                    localStorage.setItem('site', JSON.stringify(response.data.site))
                    navigate(`/tabletView/dashboard/${code}`)
                }
            })
    }

    return (
        <div className='dashboard'>

            <img src={mainImage} alt="hero image" className='hero-img' />

            <div className='dashboard__logged'
            onClick={()=>setOpen(true)}
            >
                <img src={userInfo?.userPhoto} alt="" />
                {userInfo?.userName}
                <IoIosArrowDown />
            </div>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                id='popper'
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                // onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem onClick={() => navigate("/")}>
                                        Back to ERP
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            <img src={sun} alt="sun" className='sun' />

            <div className='dashboard__title'>
                <p>welcome to</p>
                <h1>WESTROAD</h1>
            </div>

            <div className='dashboard__projects'>
                {
                    projects?.map((project, index) => {
                        return <div key={index} className='dashboard__projects__project'
                        onClick={()=>{getSiteBySiteCode(project?.code)}}>
                            <img src={project.img} alt={project.name} />
                            <div className='dashboard__projects__project__name'
                                onClick={() => {
                                    // localStorage.setItem('projectSelected' , project?.name)
                                    // navigate('/tabletView/dashboard')
                                    getSiteBySiteCode(project?.code)
                                }}
                            >
                                {project?.name}
                                <IoIosArrowRoundForward />
                            </div>

                        </div>
                    })
                }

            </div>


        </div>
    )
}

export default TabletDashboard