import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import MaterialTable from "material-table";
import { BASE_URL } from "../../utils/config/url";
import Swal from "sweetalert2";
import "./ListOfOnboardings.scss";
import { dateFormatting } from "../../utils/dateFormatting";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { MTableToolbar } from "material-table";

const ListOfOnboardings = () => {

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();
  const [onboardingList, setOnboardingList] = useState([]);

  const [filterStatus, setFilterStatus] = useState("");

  useEffect(() => {
    const fetchOnboardingList = async () => {
      try {
        let apiUrl = `${BASE_URL}/api/v1/hrmgmt/onboarding/getListOfOnboarding`;

        if (userInfo?.userRole === "Operations") {
          apiUrl = `${BASE_URL}/api/v1/hrmgmt/onboarding/getListOfOnboarding?status=SelfOnboardingDone`;
        }

        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`,
          },
          params: filterStatus ? { status: filterStatus } : {},
        });

        setOnboardingList(response.data.onboardingList);
      } catch (err) {
        console.log(err);
      }
    };

    fetchOnboardingList();
  }, [userInfo?.token, filterStatus]);

  return (
    <div className="list-of-onboardings">
      <div className="list-of-onboardings__btns">
        <Button navigateTo={"/home"} />

        <div>
          {(userInfo?.userRole === "Director" ||
            userInfo?.userRole === "HR" ||
            userInfo?.userRole === "Operations") && (
            <button
              className="list-of-onboardings__btns__btn mr-1"
              onClick={() => navigate("/list-of-onboardings/start-onboarding")}
            >
              Start New Onboarding
            </button>
          )}
        </div>
      </div>

      <br />
      <br />

      <h4>List of Onboarding</h4>

      <MaterialTable
        data={onboardingList}
        title=""
        columns={[
          { title: "Onboarding ID", field: "onboardingId" },
          {
            title: "Status",
            field: "status",
          },
          {
            title: "Employee Name",
            field: "selectedEmployee.employeeFullName",
          },
          { title: "Employee ID", field: "selectedEmployee.employeeId" },
          { title: "Role", field: "orgRole" },
          {
            title: "Created On",
            field: "createdAt",
            render: (rowData) => dateFormatting(rowData.createdAt),
          },
          {
            title: "Last Updated",
            field: "updatedAt",
            render: (rowData) => dateFormatting(rowData.updatedAt),
          },
        ]}
        actions={[
          {
            icon: "remove_red_eye",
            tooltip: "View Onboarding Details",
            disabled:
              userInfo?.userRole === "Director" ||
              userInfo?.userRole === "HR" ||
              userInfo?.userRole === "Operations"
                ? false
                : true,
            onClick: (event, rowData) => {
              navigate(`/list-of-onboardings/${rowData.onboardingId}`);
            },
          },
        ]}
        options={{
          search: true,
          searchFieldStyle: {
            width: 180,
          },
          actionsColumnIndex: 0,
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],
          actionsCellStyle: {
            width: 120,
            paddingLeft: 30,
          },
          sorting: true,
          headerStyle: {
            backgroundColor: "#EE4B46",
            color: "#fff",
            paddingLeft: "11px",
          },
        }}
        components={{
          Toolbar: (props) => (
            <div className="filters text-left">
              <MTableToolbar {...props} />
              {userInfo?.userRole !== "Operations" && (
                <FormControl
                  variant="standard"
                  className="formControl"
                  style={{
                    width: "250px",
                    margin: "0 13px",
                    marginTop: "-65px",
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    style={{ fontSize: "14px" }}
                  >
                    Status
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                    label="Status"
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="Initiated">Initiated</MenuItem>
                    <MenuItem value="SentToEmployee">Sent To Employee</MenuItem>
                    <MenuItem value="SelfOnboardingDone">
                      Self Onboarding Done
                    </MenuItem>
                    <MenuItem value="OperationsOnboardingDone">
                      Operations Onboarding Done
                    </MenuItem>
                    <MenuItem value="HRApproved">HR Approved</MenuItem>
                  </Select>
                </FormControl>
              )}
            </div>
          ),
        }}
      ></MaterialTable>
    </div>
  );
};

export default ListOfOnboardings;