import React, { useState, useEffect, useRef } from 'react'
import Button from '../../components/Button/Button'
import Paper from "@mui/material/Paper";
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url';
import { Form } from 'react-bootstrap';
import './EmployeeAttendanceReport.scss';
import Swal from 'sweetalert2';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';


const EmployeeAttendanceReport = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [employee, setEmployee] = useState('');
    const [employeeList, setEmployeeList] = useState([]);
    const [report, setReport] = useState([]);
    const [monthlyReport, setMonthlyReport] = useState({});
    const [spinnerModal, setSpinnerModal] = useState(false);

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getListOfEmployee?isActive=true`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                // console.log(response.data.employees)
                setEmployeeList(response.data.employees)

            })
    }, [])

    const generateAttendanceReport = (e) => {

        e.preventDefault();

      setMonthlyReport({});

        setSpinnerModal(true);
        let y = parseInt(year);
        let m = month.split(',')[0];
        m = parseInt(m)

        axios.get(`${BASE_URL}/api/v1/hrmgmt/report/attendance/getDailyAttendanceReportEmployee?year=${y}&month=${m}&employeeId=${employee}`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {
                setSpinnerModal(false);
                if (response.data.status) {
                    // console.log(response.data.report)
                    setReport(response.data.report)
                    getEmployeeMontlyReport()
                } else {
                    setReport([])
                }
            })
            .catch((err) => {
                setReport([])
                setSpinnerModal(false);


                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })

            })
    }

    const getEmployeeMontlyReport = () => {

        let y = parseInt(year);
        let m = month.split(',')[0];
        m = parseInt(m)

        axios.get(`${BASE_URL}/api/v1/hrmgmt/report/employee/employeeMonthlyReport?year=${y}&month=${m}&employeeId=${employee}`,
            {
                headers: { Authorization: `bearer ${userInfo.token}` }
            })
            .then((response) => {

                setMonthlyReport(response.data.report)

            })
            .catch((err) => {

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: err.response.data.error
                })

            })

    }


    const dateFormatter = (input, type) => {

        if (!input) return 'N/A';

        let d = new Date(input)
        let date = d.getDate();
        if (date < 10) {
            date = `0${date}`
        }
        let m = d.getMonth() + 1
        if (m < 10) {
            m = `0${m}`
        }
        let y = d.getFullYear()

        let final;

        if (type === 'original') {
            final = `${y}-${m}-${date}`
            return final;
        } else {
            final = `${date}/${m}/${y}`
            return final;
        }

    }

    const timeFormatter = (input) => {
        // console.log(input)
        // let date = new Date(new Date(input).setTime(new Date(input).getTime() - (5*60*60*1000)));
        // let date = new Date(new Date(input).setTime(new Date(input).getTime() - ((5 * 60 * 60 * 1000) + (30 * 60 * 1000))));

        let date = new Date(input);

        let hrs = date.getHours();
        if (hrs < 10) {
            hrs = `0${hrs}`
        }

        if (hrs === 0) {
            hrs = `00`;
        }

        let min = date.getMinutes();

        if (min < 10) {
            min = `0${min}`
        }

        if (min === 0) {
            min = `00`;
        }

        let time = hrs > 12 ? 'pm' : 'am'

        return `${hrs}:${min} ${time}`
    }

    return (
        <div className='attendance-report'>

            <div className='row justify-content-start'>
                <Button navigateTo={'/attendanceManagement'} />
            </div>

            <br />

            <h3 className='text-center mb-5'>Employee Attendance Report</h3>

            <Paper elevation={2} style={{ padding: '20px', marginBottom: '20px' }}>

                <form
                    onSubmit={(e) => (generateAttendanceReport(e))}
                >
                    <div className='row justify-content-center mt-4'>

                        <div className='col-md-4 col-6'>
                            <Form.Group controlId="month">
                                <Form.Label>Month</Form.Label>
                                <Form.Control as="select" required
                                    value={month}
                                    onChange={(e) => {
                                        setMonth(e.target.value)
                                    }}
                                >
                                    <option value="">Select a Month</option>
                                    <option value='1,January'>January</option>
                                    <option value='2,February'>February</option>
                                    <option value='3,March'>March</option>
                                    <option value='4,April'>April</option>
                                    <option value='5,May'>May</option>
                                    <option value='6,June'>June</option>
                                    <option value='7,July'>July</option>
                                    <option value='8,August'>August</option>
                                    <option value='9,September'>September</option>
                                    <option value='10,October'>October</option>
                                    <option value='11,November'>November</option>
                                    <option value='12,December'>December</option>
                                </Form.Control>
                            </Form.Group>
                        </div>

                        <div className='col-md-4 col-6'>
                            <Form.Group controlId="year">
                                <Form.Label>Year</Form.Label>
                                <Form.Control as="select" required
                                    value={year}
                                    onChange={(e) => {
                                        setYear(e.target.value)
                                    }}
                                >
                                    <option value="">Select a Year</option>
                                    <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                                    <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>

                                </Form.Control>
                            </Form.Group>
                        </div>

                    </div>

                    <div className='row justify-content-center mt-3'>

                        <div className='col-md-4 col-6'>
                            <Form.Group controlId="employee">
                                <Form.Label>Employee</Form.Label>
                                <Form.Control as="select" required
                                    value={employee}
                                    onChange={(e) => {
                                        setEmployee(e.target.value)
                                    }}
                                >
                                    <option value="">Add Employee</option>
                                    {
                                        employeeList?.map((employee, index) => {
                                            return <option key={index} value={`${employee.employeeId}`}>{employee.employeeFirstName} {employee.employeeLastName}</option>
                                        })
                                    }

                                </Form.Control>
                            </Form.Group>
                        </div>
                    </div>


                    <div className='row justify-content-center mt-5'>
                        <div className='col-6 text-center'>
                            <button className='attendance-report__btn'
                                type='submit'
                            >Generate</button>
                        </div>
                    </div>

                </form>

            </Paper>

            <table className="attendance-report__table mb-5">
                <thead style={{ backgroundColor: "#EE4B46", color: "#fff" }}>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Total Hours</th>
                        <th scope="col">Details</th>
                    </tr>
                </thead>
                <tbody>
                    {report?.map((data, index) => (
                        <tr key={index}>
                            <td>{dateFormatter(data.date)}</td>
                            <td>{data.totalHours}</td>
                            <td style={{
                                textAlign: 'center',
                                display: 'flex', justifyContent: 'center'
                            }}>
                                {
                                    data.items.length != 0
                                        ?
                                        <table
                                            style={{ width: '100%', borderBottom: 'none' }}
                                        >
                                            <thead style={{ backgroundColor: '#bdbcbc' }}>
                                                <tr>
                                                    <th className='inner-table'>Type</th>
                                                    <th className='inner-table'>Start Time</th>
                                                    <th className='inner-table'>End Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.items.map((item, index) => {
                                                        return <tr key={`item${index}`}>
                                                            <td>{item.type}</td>
                                                            <td>{timeFormatter(item.startTime)}</td>
                                                            <td>{timeFormatter(item.endTime)}</td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        :
                                        <>No Attendance Found</>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {
                Object.keys(monthlyReport).length > 0
                &&
                <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }} id="report">
                    <div className="row">
                        <div className="col-3 data">
                            <span>Days in Month</span>
                            <div className="attendance-report__Data">
                                {monthlyReport?.noOfDaysInMonth}
                            </div>
                        </div>
                        <div className="col-3 data">
                            <span>Present Days</span>
                            <div className="attendance-report__Data">{monthlyReport?.presentDays}</div>
                        </div>
                        <div className="col-3 data">
                            <span>Absent Days</span>
                            <div className="attendance-report__Data">
                                {monthlyReport?.absentDays}
                            </div>
                        </div>

                        <div className="col-3 data">
                            <span>Late Days At Work</span>
                            <div className="attendance-report__Data">{monthlyReport?.lateDaysAtWork}</div>
                        </div>
                        <div className="col-3 data">
                            <span>Total Work Hours</span>
                            <div className="attendance-report__Data">
                                {monthlyReport?.totalWorkHours}
                            </div>
                        </div>
                        <div className="col-3 data">
                            <span>Total Leave Days</span>
                            <div className="attendance-report__Data">
                                {monthlyReport?.totalLeaveDays}
                            </div>
                        </div>

                    </div>
                </Paper>
            }


            {/* /////////////////Modal for spinner /////////// */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={spinnerModal}
                onClose={() => setSpinnerModal(false)}
                closeAfterTransition
            >
                <Fade in={spinnerModal}>

                    <div>
                        <ClipLoader
                            color='#EE4B46'
                            loading={spinnerModal}
                            size={70}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                </Fade>
            </Modal>

        </div>
    )
}

export default EmployeeAttendanceReport
