import React, { useEffect, useState } from 'react'
import './ViewDocuments.scss';
import { Paper } from '@mui/material';
import { BASE_URL } from '../../utils/config/url';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const ViewDocuments = () => {

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const empId = useParams().employeeId;

    const [aadhar, setAadhar] = useState('');
    const [aadharLink, setAadharLink] = useState('');
    const [pan, setPan] = useState('');
    const [panLink, setPanLink] = useState('');
    const [appointmentDate, setAppointmentDate] = useState('');
    const [appointmentLetterLink, setAppointmentLetterLink] = useState('');
    const [offerDate, setOfferDate] = useState('');
    const [offerLetterLink, setOfferLetterLink] = useState('');

    const [aadharFile, setAadharFile] = useState('');
    const [panFile, setPanFile] = useState('');
    const [appointmentLetterFile, setAppointmentLetterFile] = useState('');
    const [offerLetterFile, setOfferLetterFile] = useState('');
    const [changedDoc, setChangedDoc] = useState('');
    const [changedFile, setChangedFile] = useState('');

    //////// error variables ///////
    const [aadharError, setAddharError] = useState('');
    const [panError, setPanError] = useState('');

    useEffect(() => {
        getEmployeeById();
    }, [])
    

    const getEmployeeById = () => {
        axios.get(`${BASE_URL}/api/v1/hrMgmt/employee/getEmployeeByEmployeeId/${empId}`, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        })
            .then((response) => {
                // console.log(response.data)
                let employee = response.data.employee;
                employee?.employeeAadhar != 0 && setAadhar(employee?.employeeAadhar);
                employee?.employeeAadharScan != null && setAadharLink(employee?.employeeAadharScan);
                employee?.employeePAN != 0 && setPan(employee?.employeePAN);
                employee?.employeePANScan != null && setPanLink(employee?.employeePANScan);
                employee?.appointmentDate != null && setAppointmentDate(dateFormatFromUTCToLocal(employee?.appointmentDate));
                employee?.employeeAppointmentLetterScan != null && setAppointmentLetterLink(employee?.employeeAppointmentLetterScan);
                employee?.offerDate && setOfferDate(dateFormatFromUTCToLocal(employee?.offerDate));
                employee?.employeeOfferLetterScan != null && setOfferLetterLink(employee?.employeeOfferLetterScan);
            })
    }

    const dateFormatFromUTCToLocal = (input) => {
        let date = new Date(input).getDate();
        if (date < 10) {
            date = `0${date}`
        }

        let month = new Date(input).getMonth() + 1;
        if (month < 10) {
            month = `0${month}`
        }

        let year = new Date(input).getFullYear();
        if (year < 10) {
            year = `0${year}`
        }

        return `${year}-${month}-${date}`;
    }


    const uploadDoc = async () => {
        try {

            if(aadharError || panError)return;

            let formData = new FormData();
            formData.append('employeeId', empId);

            if (changedFile === 'Aadhar') {
                formData.append('documentType', 'Aadhar');
                formData.append('file', aadharFile);
                formData.append('employeeAadhar', aadhar);
            }
            // if (changedDoc === 'Aadhar') {
            //     formData.append('documentType', 'Aadhar');
            // }

            if (changedFile === 'PAN') {
                formData.append('documentType', 'PAN');
                formData.append('file', panFile);
                formData.append('employeePAN', pan);
            }

            // if (changedDoc === 'PAN') {
            //     formData.append('documentType', 'PAN');
            // }

            if (changedFile === 'AppointmentLetter') {
                formData.append('documentType', 'AppointmentLetter');
                formData.append('file', appointmentLetterFile);
                formData.append('appointmentDate', appointmentDate);
            }
            // if (changedDoc === 'AppointmentLetter') {
            //     formData.append('documentType', 'AppointmentLetter');
            // }
            if (changedFile === 'OfferLetter') {
                formData.append('documentType', 'OfferLetter');
                formData.append('file', offerLetterFile);
                formData.append('offerDate', offerDate);
            }
            // if (changedDoc === 'OfferLetter') {
            //     formData.append('documentType', 'OfferLetter');
            // }

            let response = await axios.put(`${BASE_URL}/api/v1/hrMgmt/employee/uploadEmployeeDocuments`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`
                    }
                })

            if (response.data.status === true) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: response.data.message,
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                })
                    .then(() => {
                        setAadharFile('')
                        setPanFile('')
                        setAppointmentLetterFile('')
                        setOfferLetterFile('')
                        setChangedDoc('')
                        setChangedFile('')
                        getEmployeeById()
                        let files = document.querySelectorAll('input[type="file"]')
                        files.forEach(file => file.value = '')
                        
                        // window.location.reload();
                    })
            }

        } catch (err) {
            Swal.fire({
                icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
                text: err.response.data.error
            })
        }
    }

    return (
        <div className='view-docs'>
            <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
                <h4 className='text-center mb-3'>Employee Aadhar</h4>

                <div className='row justify-content-center'>
                    <div className='col-6 text-center'>
                        <label>Aadhar No.</label>
                        <input type='text'
                            className='form-control'
                            value={aadhar} onChange={(e) => {
                                setAadhar(e.target.value)
                                setChangedDoc('Aadhar');

                                if (e.target.value.length !== 12) {
                                    setAddharError('Aadhar no. should be of 12 digits')
                                }
                                else if(/^[0-9]+$/.test(e.target.value) === false){
                                    setAddharError('Aadhar no. should contain only numbers')
                                }
                                else {
                                    setAddharError('')
                                }
                            }} />

                          { aadharError && <em style={{color : '#EE4B46'}}>{aadharError}</em>}

                    </div>

                    {
                        aadharLink &&
                        <div className='col-6 text-center mt-4'>
                            <a href={aadharLink}>Aadhar Scan</a>
                        </div>
                    }

                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-6 text-center'>
                        <input type='file' name='aadhar-file'
                            className='form-control'
                            onChange={(e) => {
                                setAadharFile(e.target.files[0])
                                setChangedFile('Aadhar')
                            }} />
                    </div>

                    <div className='col-6 text-center'>
                        <button className='view-docs__btn'
                            onClick={uploadDoc}
                        >Upload</button>
                    </div>
                </div>
            </Paper>

            <br />

            <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
                <h4 className='text-center mb-3'>Employee PAN</h4>

                <div className='row justify-content-center'>
                    <div className='col-6 text-center'>
                        <label>PAN No.</label>
                        <input type='text'
                            className='form-control'
                            value={pan} onChange={(e) => {
                                setPan(e.target.value)
                                setChangedDoc('PAN')
                                if (e.target.value.length !== 10) {
                                    setPanError('PAN no. should be of 10 digits')
                                } else if (/^[A-Z0-9]+$/.test(e.target.value) === false ) {
                                    setPanError('PAN no. should contain only numbers and uppercase letters')
                                } else {
                                    setPanError('')
                                }

                            }} />

                            {panError && <em style={{color : '#EE4B46'}}>{panError}</em>}
                    </div>

                    {
                        panLink &&
                        <div className='col-6 text-center mt-4'>
                            <a href={panLink}>PAN Scan</a>
                        </div>
                    }

                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-6 text-center'>
                        <input type='file' name='pan-file'
                            className='form-control'
                            onChange={(e) => {
                                setPanFile(e.target.files[0])
                                setChangedFile('PAN')
                            }} />
                    </div>

                    <div className='col-6 text-center'>
                        <button className='view-docs__btn'
                            onClick={uploadDoc}
                        >Upload</button>
                    </div>
                </div>
            </Paper>

            <br />

            <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
                <h4 className='text-center mb-3'>Employee Appointment Letter</h4>

                <div className='row justify-content-center'>
                    <div className='col-6 text-center'>
                        <label>Appointment Date</label>
                        <input type='date'
                            className='form-control'
                            value={appointmentDate} onChange={(e) => {
                                setAppointmentDate(e.target.value)
                                setChangedDoc('AppointmentLetter')
                            }} />
                    </div>

                    {
                        appointmentLetterLink &&
                        <div className='col-6 text-center mt-4'>
                            <a href={appointmentLetterLink}>Appointment Letter Scan</a>
                        </div>
                    }

                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-6 text-center'>
                        <input type='file' name='appointment-file'
                            className='form-control'
                            onChange={(e) => {
                                setAppointmentLetterFile(e.target.files[0])
                                setChangedFile('AppointmentLetter')
                            }} />
                    </div>

                    <div className='col-6 text-center'>
                        <button className='view-docs__btn'
                            onClick={uploadDoc}
                        >Upload</button>
                    </div>
                </div>
            </Paper>

            <br />

            <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
                <h4 className='text-center mb-3'>Employee Offer Letter</h4>

                <div className='row justify-content-center'>
                    <div className='col-6 text-center'>
                        <label>Offer Date</label>
                        <input type='date'
                            className='form-control'
                            value={offerDate} onChange={(e) => {
                                setOfferDate(e.target.value)
                                setChangedDoc('OfferLetter')
                            }} />
                    </div>

                    {
                        offerLetterLink &&
                        <div className='col-6 text-center mt-4'>
                            <a href={offerLetterLink}>Offer Letter Scan</a>
                        </div>
                    }

                </div>

                <br />

                <div className='row justify-content-center'>
                    <div className='col-6 text-center'>
                        <input type='file' name='offer-file'
                            className='form-control'
                            onChange={(e) => {
                                setOfferLetterFile(e.target.files[0])
                                setChangedFile('OfferLetter')
                            }} />
                    </div>

                    <div className='col-6 text-center'>
                        <button className='view-docs__btn'
                            onClick={uploadDoc}
                        >Upload</button>
                    </div>
                </div>
            </Paper>
        </div>
    )
}

export default ViewDocuments