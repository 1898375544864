import { Paper } from '@mui/material'
import React, { useEffect } from 'react'
import { BASE_URL } from '../../utils/config/url'
import axios from 'axios'
import { useParams } from 'react-router-dom'

const Payment = () => {

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))

  const salaryAdvanceId = useParams().salaryAdvanceId
  const [salaryAdvance, setSalaryAdvance] = React.useState({})
  const [salaryAccount, setSalaryAccount] = React.useState({})

  useEffect(() => {
    axios.get(`${BASE_URL}/api/v1/hrmgmt/salaryAdvance/getSalaryAdvanceById/${salaryAdvanceId}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`
        }
      })
      .then((response) => {

        if (response.data.status) {
          setSalaryAdvance(response.data.SalaryAdvance)
        } else {
          setSalaryAdvance({})
        }

      })

  }, [])

  useEffect(() => {

    if(salaryAdvance?.employeeId === undefined) return;
    
    axios.get(`${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${salaryAdvance?.employeeId}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`
        }
      })
      .then((response) => {

        if (response.data.status) {
          setSalaryAccount(response.data.employee.salaryAccount)
        } else {
          setSalaryAccount({})
        }

      })

  }, [salaryAdvance])


  const dateFormatting = (input) => {

    if (!input) return 'N/A';

    let date = new Date(input).getDate();
    if (date < 10) {
      date = '0' + date;
    }

    let month = new Date(input).getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }

    const year = new Date(input).getFullYear();

    return `${date}/${month}/${year}`;
  }


  return (
    <div className='inv-salary-advance__payment'>

      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>

        <div className='row justify-content-center'>
          <div className='col-6'>
            <label>Total Advance</label>
            <input type="number" readOnly className='form-control' value={salaryAdvance?.approvedAmount} />
          </div>

          <div className='col-6'>
            <label>Remaining Amount</label>
            <input type="number" readOnly className='form-control' value={salaryAdvance?.remainingAmount} />
          </div>
        </div>

        <br />

        <div className='row justify-content-center'>
          <div className='col-6'>
            <label>No. Of Installments</label>
            <input type="text" readOnly className='form-control' value={salaryAdvance?.noOfInstallments} />
          </div>

          <div className='col-6'>
            <label>Paid Installments</label>
            <input type="number" readOnly className='form-control' value={salaryAdvance?.paidInstallments} />
          </div>
        </div>

      </Paper>

      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>

        <div className='row justify-content-center'>
          <div className='col-6'>
            <label>Disbursal Date</label>
            <input type="text" readOnly className='form-control' value={dateFormatting(salaryAdvance?.disbursalDate)} />
          </div>

          <div className='col-6'>
            <label>Disbursed By</label>
            <input type="text" readOnly className='form-control' value={salaryAdvance?.disbursedByFullName} />
          </div>
        </div>

        <br />

        <div className='row justify-content-center'>
          <div className='col-6'>
            <label>Bank Payment Id</label>
            <input type="text" readOnly className='form-control' value={salaryAdvance?.bankPaymentId} />
          </div>
        </div>

      </Paper>

      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>

        <div className='row justify-content-center'>
          <div className='col-6'>
            <label>Bank Account Number</label>
            <input type="text" readOnly className='form-control' value={salaryAccount?.accountNumber} />
          </div>

          <div className='col-6'>
            <label>Bank Name</label>
            <input type="text" readOnly className='form-control' value={salaryAccount?.bankName} />
          </div>
        </div>

        <br />

        <div className='row justify-content-center'>
          <div className='col-6'>
            <label>Branch Name</label>
            <input type="text" readOnly className='form-control' value={salaryAccount?.branchName} />
          </div>

          <div className='col-6'>
            <label>Bank IFSC</label>
            <input type="text" readOnly className='form-control' value={salaryAccount?.IFSCCode} />
          </div>
        </div>

      </Paper>

      {
        salaryAdvance?.installmentPayment?.length > 0
        &&
        <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>

          {
            salaryAdvance?.installmentPayment?.map((installment, index) => {
              return <div className='row justify-content-center' key={index}>

                <div className='col-4'>
                  <label>Payment Date</label>
                  <input type="text" className='form-control' readOnly value={dateFormatting(installment?.paymentDate)} />
                </div>

                <div className='col-4'>
                  <label>Payment Amount</label>
                  <input type="number" className='form-control' readOnly value={installment?.paymentAmount} />
                </div>

                <div className='col-4'>
                  <label>Payment Type</label>
                  <input type="text" className='form-control' readOnly value={installment?.paymentType} />
                </div>

              </div>
            })
          }

        </Paper>
      }
    </div>
  )
}

export default Payment