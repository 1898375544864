import './CreateWareHouse.scss';
import Button from "../../components/Button/Button";
import {  Fade, FormControlLabel, FormGroup, Modal, Switch, styled } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from "../../utils/config/url";
import { useStyles } from '../../utils/ModalStyles';
import Swal from 'sweetalert2';

const CreateWareHouse = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  //get the userinfo
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  //state variable for form field
  const [wHCode, setWHCode] = useState('')
  const [wareHouseName, setWareHouseName] = useState('')
  const [isActive, setIsActive] = useState(false);


  //warehouse addrs
  const [fullAddress, setFullAddress] = useState('')
  const [landmark, setLandmark] = useState('')
  const [city, setCity] = useState('')
  const [pinCode, setPinCode] = useState('')
  const [state, setState] = useState('')
  const [siteEngineerFullName, setSiteEngineerFullName] = useState('')
  const [siteEngineerEmpId, setSiteEngineerEmpId] = useState('')
  const [siteEngineerMobile, setSiteEngineerMobile] = useState('')

  const handleCreate = (e) => {
    e.preventDefault();
    if (wHCode.trim() === '' || wareHouseName.trim() === '' || fullAddress.trim() === '' || landmark.trim() === '' || city.trim() === '' || pinCode.trim() === '' || state.trim() === '' ) {
      Swal.fire({
        icon: "warning",
        title: "Enter all fields",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        }
      })
      return;
    }
    if (isNaN(pinCode) || pinCode.length !== 6) {
      Swal.fire({
        icon: "warning",
        title: "Enter valid pincode",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        }
      })
      return;
    }

    setModal(true);
  }


  const createNewWarehouse = async () => {
    const warehousedata = {
      WHCode: wHCode,
      wareHouseName,
      isActive,
      createdBy: userInfo.userName,
      createdByFullName: userInfo.userFullName,
      createdByEmpId: userInfo.employeeId,
      wareHouseAddress: {
        fullAddress,
        landmark,
        city,
        pinCode,
        state,
      },
    }
    // console.log(warehousedata)
    try {
      const { data } = await axios.post(`${BASE_URL}/api/v1/stockmgmtapi/warehouse/createNewWarehouse`, warehousedata, {
        headers: { Authorization: `bearer ${userInfo.token}` }
      })
      // console.log(data)
      setModal(false)
      Swal.fire({
        icon: "success",
        title: "Create Successfully",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        }
      })
      .then(()=>{
        navigate('/engineering/stockManagement/warehouse')
      })


    } catch (err) {
      setModal(false)
      // console.log(err.error)
      Swal.fire({
        icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: err.error
      })
    }
  }

  const handleCancel=()=>{
    navigate('/home');
  }

  return (
    <div className='createwarehouse'>
      <div className='warehouse__buttons'>
        <Button navigateTo={'/engineering/stockManagement/warehouse'} />
      </div>
      <br />
      <br />
      <div className='create__form__container'>
        <form onSubmit={handleCreate}>
          <div className="first__card">
            <div className='input__field'>
              <label htmlFor="WHCode">Ware House Code</label>
              <input type="text" name="WHCode" id="WHCode" value={wHCode} onChange={(e) => setWHCode(e.target.value)} />
            </div>
            <div className='input__field'>
              <label htmlFor="wareHouseName">Ware House Name</label>
              <input type="text" name="wareHouseName" id="wareHouseName" value={wareHouseName} onChange={(e) => setWareHouseName(e.target.value)} />
            </div>
            <div className='input__field'>
              <FormGroup>
                <FormControlLabel
                  control={
                    <RedSwitch
                      checked={isActive}
                      onChange={(e) => setIsActive(e.target.checked)}
                    />
                  }
                  label="Is Active"
                />
              </FormGroup>
            </div>
          </div>
          <div className="second__card">
            <h2>Ware House Address</h2>
            <div className='warehouse__address' >
              <div className='input__field'>
                <label htmlFor="fullAddress">Full Address</label>
                <input type="text" name="fullAddress" id="fullAddress" value={fullAddress} onChange={(e) => setFullAddress(e.target.value)} />
              </div>
              <div className='input__field'>
                <label htmlFor="landmark">Landmark</label>
                <input type="text" name="landmark" id="landmark" value={landmark} onChange={(e) => setLandmark(e.target.value)} />
              </div>
              <div className='input__field'>
                <label htmlFor="city">City</label>
                <input type="text" name="city" id="city" value={city} onChange={(e) => setCity(e.target.value)} />
              </div>
              <div className='input__field'>
                <label htmlFor="pinCode">Pincode</label>
                <input type="text" name="pinCode" id="pinCode" value={pinCode} onChange={(e) => setPinCode(e.target.value)} />
              </div>
              <div className='input__field'>
                <label htmlFor="state">State</label>
                <input type="text" name="state" id="state" value={state} onChange={(e) => setState(e.target.value)} />
              </div>
            </div>
          </div>

          {/* <div className="third__card">
            <h2>Site Engineer</h2>
            <div className='site__engineer'>
              <div className='input__field'>
                <label htmlFor="siteEngineerFullName">Site Engineer Fullname</label>
                <input type="text" name="siteEngineerFullName" id="siteEngineerFullName" value={siteEngineerFullName} onChange={(e) => setSiteEngineerFullName(e.target.value)} />
              </div>
              <div className='input__field'>
                <label htmlFor="siteEngineerEmpId">Site Engineer Employee Id</label>
                <input type="text" name="siteEngineerEmpId" id="siteEngineerEmpId" value={siteEngineerEmpId} onChange={(e) => setSiteEngineerEmpId(e.target.value)} />
              </div>
              <div className='input__field'>
                <label htmlFor="siteEngineerMobile">Site Enginee Mobile Number</label>
                <input type="text" name="siteEngineerMobile" id="siteEngineerMobile" value={siteEngineerMobile} onChange={(e) => setSiteEngineerMobile(e.target.value)} />
              </div>
            </div>
          </div> */}

          <div className='btn__container'>
            <button className='cancelBtn' type='button' onClick={handleCancel}>Cancel</button>
            <button className='createBtn' type='submit'>Create</button>
          </div>
        </form>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal}
        onClose={() => {
          setModal(false)
        }}
        closeAfterTransition

      >
        <Fade in={modal}>
          <div className={classes.paper}
            style={{ minWidth: '300px', minHeight: '180px', display: 'flex', flexDirection: 'column' }}
          >

            <div className='row justify-content-center'>
              <h3 className='col-10'><b>Are you sure ?</b></h3>
            </div>

            <br />

            <div className="row container-fluid justify-content-center m-auto">
              <div className="col-6 text-right">
                <button className="modal__btns"
                  onClick={createNewWarehouse}>
                  Yes
                </button>
              </div>
              <div className="col-6 text-left">
                <button
                  className="modal__btns"
                  onClick={() => {
                    setModal(false)
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default CreateWareHouse;

const RedSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#EE4B46",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#EE4B46",
  },
}));