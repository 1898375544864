import React from 'react'
import {Navigate} from 'react-router-dom'

import BrokerTable from './BrokerTable';

const Brokers = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if( userInfo.token === undefined ) {
        return <Navigate to="/login" noThrow />
    }
      else {
      return <BrokerTable/>;
      }
}

export default Brokers