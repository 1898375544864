import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import './Claims.scss';
import axios from 'axios';
import { BASE_URL } from '../../utils/config/url'
import { Paper } from '@mui/material';
import { Bar, Pie } from "react-chartjs-2";
import { Form } from 'react-bootstrap';
import { alpha, styled } from "@mui/material/styles";
import { Switch, Select, InputLabel, MenuItem } from "@mui/material";
import BarCharts from "./BarCharts";

const RedSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: "#EE4B46",
        "&:hover": {
            backgroundColor: alpha("#EE4B46", theme.palette.action.hoverOpacity),
        },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#EE4B46",
    },
}));

const Claims = () => {

    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [openClaimsCount, setOpenClaimsCount] = useState({})

    const [claimsCountByEmpId, setClaimsCountByEmpId] = useState([])
    const [claimsCountByEmpIdNames, setClaimsCountByEmpIdNames] = useState([])
    const [claimsCountByEmpIdBg, setClaimsCountByEmpIdBg] = useState([])
    const [empIdDays, setEmpIdDays] = useState(30)
    const [empIdType, setEmpIdType] = useState("count")

    const [claimCountByClaimType, setClaimCountByClaimType] = useState([])
    const [claimCountByClaimTypeNames, setClaimCountByClaimTypeNames] = useState([])
    const [claimCountByClaimTypeBg, setClaimCountByClaimTypeBg] = useState([])
    const [claimTypeDays, setClaimTypeDays] = useState(30)
    const [claimTypeType, setClaimTypeType] = useState("count")

    const [monthwiseClaimsLabels, setMonthwiseClaimsLabels] = useState([])
    const [monthwiseClaimsCount, setMonthwiseClaimsCount] = useState([])

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/claims/claimsOpenCount`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)
                if (response.data.status === true) {
                    setOpenClaimsCount({
                        openClaimsLineMgr: response.data.openClaimsLineMgr,
                        openClaimsFinance: response.data.openClaimsFinance,
                        openClaimsSettlement: response.data.openClaimsSettlement
                    })
                }

            })

    }, [])

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/claims/claimCountByEmployeeId?type=${empIdType}&days=${empIdDays}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)

                if (response.data.status === true) {

                    let data = response?.data?.claimsCountByEmpId.map((data) => {
                        return data.count;
                    });

                    setClaimsCountByEmpId(data);

                    let names = response?.data?.claimsCountByEmpId.map((data) => {
                        return data._id;
                    });

                    setClaimsCountByEmpIdNames(names);

                    let colors = response?.data?.claimsCountByEmpId.map((val) => {
                        return (
                            "#" +
                            "0123456789abcdef"
                                .split("")
                                .map(function (v, i, a) {
                                    return i > 5 ? null : a[Math.floor(Math.random() * 16)];
                                })
                                .join("")
                        );
                    });

                    setClaimsCountByEmpIdBg(colors);

                }

            })

    }, [empIdType, empIdDays])

    useEffect(() => {

        axios.get(`${BASE_URL}/api/v1/hrmgmt/claims/claimCountByClaimType?type=${claimTypeType}&days=${claimTypeDays}`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)

                if (response.data.status === true) {

                    let data = response?.data?.claimsCount.map((data) => {
                        return data.count;
                    });

                    setClaimCountByClaimType(data);

                    let names = response?.data?.claimsCount.map((data) => {
                        return data._id;
                    });

                    setClaimCountByClaimTypeNames(names);

                    let colors = response?.data?.claimsCount.map((val) => {
                        return (
                            "#" +
                            "0123456789abcdef"
                                .split("")
                                .map(function (v, i, a) {
                                    return i > 5 ? null : a[Math.floor(Math.random() * 16)];
                                })
                                .join("")
                        );
                    });

                    setClaimCountByClaimTypeBg(colors);

                }

            })

    }, [claimTypeType, claimTypeDays])


    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/hrmgmt/claims/getClaimsOfLastTwelveMonths`, {
            headers: { Authorization: `bearer ${userInfo.token}` }
        })
            .then((response) => {
                // console.log(response)

                let names = response?.data?.NumberOfClaims.map((data) => {
                    return data.month;
                });

                setMonthwiseClaimsLabels(names);

                let data = response?.data?.NumberOfClaims.map((data) => {
                    return [data.approved , data.rejected , data.total];
                });

                setMonthwiseClaimsCount(data);
            })

    }, [])

    return (
        <div className='claims'>

            <Button navigateTo={'/home'} />

            <div className='row d-flex flex-column flex-md-row justify-content-center align-items-center mt-4'>

                <div className='claims__btns mx-2 my-2'>
                    <button
                        onClick={() => navigate('/finance/claims/claimsToBeApproved')}
                    >
                        Claims to be Approved
                    </button>
                </div>

                <div className='claims__btns mx-2 my-2'>
                    <button
                        onClick={() => navigate('/finance/claims/claimsToBeSettled')}
                    >Claims to be Settled</button>
                </div>

                <div className='claims__btns mx-2 my-2'>
                    <button
                        onClick={() => navigate('/finance/claims/listOfAllClaims')}
                    >List of all Claims</button>
                </div>
            </div>

            <Paper elevation={2} style={{ padding: "20px", margin: "40px" }}>

                <div className="row">

                    <div className="col-4">
                        <span>Open Claims with Line Manager</span>
                        <div className="lead-mgmt__leadData">
                            {openClaimsCount?.openClaimsLineMgr}
                        </div>
                    </div>
                    <div className="col-4">
                        <span>Open Claims with Finance</span>
                        <div className="lead-mgmt__leadData">{openClaimsCount?.openClaimsFinance}</div>
                    </div>
                    <div className="col-4">
                        <span>Claims To Be Settled</span>
                        <div className="lead-mgmt__leadData">
                            {openClaimsCount?.openClaimsSettlement}
                        </div>

                    </div>

                </div>
            </Paper>

            <Paper elevation={2} style={{ padding: "20px", margin: "40px" }}>
                <h4 className="text-center">Claims Count By Claim Type</h4>

                <div className='row justify-content-between'>

                    <div className='col-4'>
                        <Form.Group controlId="days">
                            <Form.Label
                                style={{ fontSize: '20px' }}
                            >Days</Form.Label>
                            <Form.Control
                                as="select"
                                required
                                value={claimTypeDays}
                                onChange={(e) => {
                                    setClaimTypeDays(e.target.value)
                                }}
                            >
                                <option value={90}>90 days</option>
                                <option value={365}>365 days</option>
                            </Form.Control>
                        </Form.Group>
                    </div>

                    <div className='col-6 d-flex align-items-center'>

                        <span>Count</span>
                        <RedSwitch
                            checked={claimTypeType === "amount" ? true : false}
                            onChange={() => setClaimTypeType((prev) => {
                                if (prev === "count") {
                                    return "amount"
                                } else {
                                    return "count"
                                }
                            })}
                        />
                        <span>Amount</span>
                    </div>

                </div>

                <div className="row">
                    <div className="col-12">
                        {claimCountByClaimType.length === 0 ? (
                            <h4 className="text-center mt-4"
                                style={{ color: "#EE4B46" }}
                            >NO CLAIMS FOUND</h4>
                        ) : (
                            <Pie
                                data={{
                                    labels: claimCountByClaimTypeNames,
                                    datasets: [
                                        {
                                            // label: '# Open ans Assigned Leads',
                                            data: claimCountByClaimType,
                                            backgroundColor: claimCountByClaimTypeBg,
                                            borderWidth: 1,
                                        },
                                    ],
                                }}
                                width={200}
                                height={250}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                }}
                            />
                        )}
                    </div>
                </div>
            </Paper>

            <Paper elevation={2} style={{ padding: "20px", margin: "40px" }}>
                <h4 className="text-center">Claims Count By Employee Id</h4>

                <div className='row justify-content-between'>

                    <div className='col-4'>
                        <Form.Group controlId="days">
                            <Form.Label
                                style={{ fontSize: '20px' }}
                            >Days</Form.Label>
                            <Form.Control
                                as="select"
                                required
                                value={empIdDays}
                                onChange={(e) => {
                                    setEmpIdDays(e.target.value)
                                }}
                            >
                                <option value={90}>90 days</option>
                                <option value={365}>365 days</option>
                            </Form.Control>
                        </Form.Group>
                    </div>

                    <div className='col-6 d-flex align-items-center'>

                        <span>Count</span>
                        <RedSwitch
                            checked={empIdType === "amount" ? true : false}
                            onChange={() => setEmpIdType((prev) => {
                                if (prev === "count") {
                                    return "amount"
                                } else {
                                    return "count"
                                }
                            })}
                        />
                        <span>Amount</span>
                    </div>

                </div>

                <div className="row">
                    <div className="col-12">
                        {claimsCountByEmpId.length === 0 ? (
                            <h4 className="text-center mt-4"
                                style={{ color: "#EE4B46" }}
                            >NO CLAIMS FOUND</h4>
                        ) : (
                            <Pie
                                data={{
                                    labels: claimsCountByEmpIdNames,
                                    datasets: [
                                        {
                                            // label: '# Open ans Assigned Leads',
                                            data: claimsCountByEmpId,
                                            backgroundColor: claimsCountByEmpIdBg,
                                            borderWidth: 1,
                                        },
                                    ],
                                }}
                                width={200}
                                height={250}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                }}
                            />
                        )}
                    </div>
                </div>
            </Paper>

            <Paper elevation={2} style={{ padding: "20px", margin: "40px" }}>
                <h4 className="text-center">Claims Created Monthwise</h4>

                <div className="row">
                    <div className="col-12">
                        <BarCharts claimsLabel={monthwiseClaimsLabels} claimsCount={monthwiseClaimsCount} />
                    </div>
                </div>
            </Paper>

        </div>
    )
}

export default Claims