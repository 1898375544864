import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { BASE_URL } from "../../utils/config/url";
import axios from "axios";
import "./ApplySalaryAdvance.scss";
import Swal from "sweetalert2";

const ApplySalaryAdvance = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();

  const [employee, setEmployee] = useState({});
  const [availablePercentage, setAvailablePercentage] = useState(0);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [appliedAmount, setAppliedAmount] = useState(0);
  const [installmentAmount, setInstallmentAmount] = useState(0);
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");
  const [noOfInstallments, setNoOfInstallments] = useState(0);

  useEffect(() => {
    let total = employee?.salaryAdvanceLimit;
    total = (total / total) * 100;
    let available = (employee?.availableSalaryAdvance / total) * 100;

    setAvailablePercentage(available);
    setTotalPercentage(total);
  }, [employee]);

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${userInfo.employeeId}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        const emp = res.data.employee;
        setEmployee(emp);
      });
  }, []);

  useEffect(() => {
    if (appliedAmount == 0) {
      setInstallmentAmount(0);
    } else {
      setInstallmentAmount(Math.round(appliedAmount / noOfInstallments));
    }
  }, [noOfInstallments, appliedAmount]);

  const applySalaryAdvance = (e) => {
    e.preventDefault();

    if (error) {
      return;
    }
    if (appliedAmount == 0) {
      setError("Salary Advance Applied cannot be zero.");
      return;
    }
    if (!noOfInstallments) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select number of installments",
      });
    }

    axios
      .post(
        `${BASE_URL}/api/v1/hrmgmt/SalaryAdvance/applySalaryAdvance`,
        {
          employeeId: employee?.employeeId,
          employeeFullName: employee?.employeeFullName,
          requestedAmount: parseInt(appliedAmount),
          installmentAmount: parseInt(installmentAmount),
          requestReason: reason,
          noOfInstallments: noOfInstallments,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
          }).then(() => {
            navigate("/myprofile");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.error,
        });
      });
  };

  console.log(availablePercentage, totalPercentage);

  return (
    <div className="apply-salary-advance">
      <div className="row justify-content-start">
        <div className="col-2">
          <Button navigateTo="/myprofile" />
        </div>

        <div className="col-8 text-center">
          <h3 className="text-center" style={{ color: "#EE4B46" }}>
            Apply Salary Advance
          </h3>
        </div>
      </div>

      <br />
      <br />

      <form
        onSubmit={(e) => {
          applySalaryAdvance(e);
        }}
      >
        <div
          className="row justify-content-start"
          style={{
            width: "70%",
            margin: "auto",
          }}
        >
          <div className="col-6 text-center">
            <div className="d-flex align-items-center justify-content-start">
              <div
                style={{
                  backgroundColor: "white",
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  border: "1px solid black",
                  marginRight: "10px",
                }}
              ></div>
              <span>Available Limit : {employee?.availableSalaryAdvance}</span>
            </div>

            <div className="d-flex align-items-center justify-content-start">
              <div
                style={{
                  backgroundColor: "#EE4B46",
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  border: "1px solid black",
                  marginRight: "10px",
                }}
              ></div>
              <span>Total Limit : {employee?.salaryAdvanceLimit}</span>
            </div>
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-8 d-flex flex-column">
            <div className="apply-salary-advance__bar p-0">
              <div
                style={{
                  flex: `${availablePercentage}`,
                  backgroundColor: "white",
                }}
              >
                {/* Available Limit : {employee?.availableSalaryAdvance} */}
              </div>

              <div
                style={{
                  backgroundColor: "#EE4B46",
                  flex: `${totalPercentage}`,
                }}
              >
                {/* Total Limit : {employee?.salaryAdvanceLimit} */}
              </div>
            </div>
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-6">
            <label>Salary Advance Applied</label>
            <input
              type="number"
              required
              className="form-control"
              value={appliedAmount}
              onChange={(e) => {
                if (e.target.value > employee?.availableSalaryAdvance) {
                  setError(
                    "Applied Amount cannot be greater than available amount"
                  );
                } else {
                  setError("");
                }
                setAppliedAmount(e.target.value);
              }}
            />
            {error && <p style={{ color: "#EE4B46" }}>{error}</p>}
          </div>

          <div className="col-6">
            <label>Installment Amount</label>
            <input
              type="number"
              className="form-control"
              readOnly
              value={installmentAmount}
            />
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-8 text-center">
            <div className="apply-salary-advance__installmentbar"></div>

            <div className="apply-salary-advance__options">
              <div
                className="d-flex flex-column apply-salary-advance__option"
                onClick={(e) => {
                  e.preventDefault();
                  setNoOfInstallments(3);
                }}
              >
                <div></div>
                <span
                  style={{
                    backgroundColor: noOfInstallments == 3 ? "grey" : "",
                  }}
                >
                  3 Months
                </span>
              </div>

              <div
                className="d-flex flex-column apply-salary-advance__option"
                onClick={(e) => {
                  e.preventDefault();
                  setNoOfInstallments(6);
                }}
              >
                <div></div>
                <span
                  style={{
                    backgroundColor: noOfInstallments == 6 ? "grey" : "",
                  }}
                >
                  6 Months
                </span>
              </div>

              <div
                className="d-flex flex-column apply-salary-advance__option"
                onClick={(e) => {
                  e.preventDefault();
                  setNoOfInstallments(9);
                }}
              >
                <div></div>
                <span
                  style={{
                    backgroundColor: noOfInstallments == 9 ? "grey" : "",
                  }}
                >
                  9 Months
                </span>
              </div>

              <div
                className="d-flex flex-column apply-salary-advance__option"
                type="click"
                onClick={(e) => {
                  e.preventDefault();
                  setNoOfInstallments(12);
                }}
              >
                <div></div>
                <span
                  style={{
                    backgroundColor: noOfInstallments == 12 ? "grey" : "",
                  }}
                >
                  12 Months
                </span>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-6">
            <label>Reason</label>
            <textarea
              type="text"
              className="form-control"
              required
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </div>
        </div>

        <br />

        <div className="row justify-content-center">
          <div className="col-6 text-center">
            <button className="apply-salary-advance__btn" type="submit">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ApplySalaryAdvance;
