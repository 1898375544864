import './Tabs.scss';
import { useContext, useState } from 'react';
import { Context } from '../../utils/Context';
import Nav from 'react-bootstrap/Nav';

const Tabs = ({tabName}) => {
//console.log(tabName)
  //const { tab, setTab } = useContext(Context);
  // const tab = localStorage.getItem('ActiveKeySite')
  const [tab, setTab] = useState(localStorage.getItem('ActiveKeySite'))

  return (
    <div className="tabs">
      <Nav>
        <Nav.Item
          onClick={() => {
            localStorage.setItem("ActiveKeySite", "first");
            setTab('first')
            tabName('first')
          }}
        >
          <Nav.Link className={tab === 'first' ? 'active' : 'inactive'} eventKey="first">
            Site Details
          </Nav.Link>
        </Nav.Item>

        <Nav.Item
          onClick={() => {
            localStorage.setItem("ActiveKeySite", "second");
            setTab('second')
            tabName('second')
          }}
        >
          <Nav.Link className={tab === 'second' ? 'active' : 'inactive'} eventKey="second">
            Site Configurations
          </Nav.Link>
        </Nav.Item>

        <Nav.Item
          onClick={() => {
            localStorage.setItem("ActiveKeySite", "third");
            setTab('third')
            tabName('third')
          }}
        >
          <Nav.Link className={tab === 'third' ? 'active' : 'inactive'} eventKey="third">
            Unit Types
          </Nav.Link>
        </Nav.Item>

        <Nav.Item
          onClick={() => {
            localStorage.setItem("ActiveKeySite", "fourth");
            setTab("fourth")
            tabName('fourth')
          }}
        >
          <Nav.Link className={tab === 'fourth' ? 'active' : 'inactive'} eventKey="fourth">
            Unit
          </Nav.Link>
        </Nav.Item>

        <Nav.Item
          onClick={() => {
            localStorage.setItem("ActiveKeySite", "fifth");
            setTab("fifth")
            tabName('fifth')
          }}
        >
          <Nav.Link className={tab === 'fifth' ? 'active' : 'inactive'} eventKey="fifth">
            Car Parking Type
          </Nav.Link>
        </Nav.Item>

        <Nav.Item
          onClick={() => {
            localStorage.setItem("ActiveKeySite", "sixth");
            setTab("sixth")
            tabName('sixth')
          }}
        >
          <Nav.Link className={tab === 'sixth' ? 'active' : 'inactive'} eventKey="sixth">
            Car Parking
          </Nav.Link>
        </Nav.Item>

        <Nav.Item
          onClick={() => {
            localStorage.setItem("ActiveKeySite", "seventh");
            setTab("seventh")
            tabName('seventh')
          }}
        >
          <Nav.Link className={tab === 'seventh' ? 'active' : 'inactive'} eventKey="seventh">
            Payment Terms
          </Nav.Link>
        </Nav.Item>

        <Nav.Item
          onClick={() => {
            localStorage.setItem("ActiveKeySite", "eighth");
            setTab("eighth")
            tabName('eighth')
          }}
        >
          <Nav.Link className={tab === 'eighth' ? 'active' : 'inactive'} eventKey="eighth">
          Collection Account
          </Nav.Link>
        </Nav.Item>

      </Nav>
      {/* <div className={tab === 'first' ? 'active' : 'inactive'} onClick={() => setTab('first')}>
        Site Details
      </div> */}

    </div>
  )
}

export default Tabs;