import React from "react";
import apartmentImg1 from "../../../assets/tableView/apartment-1.png";
import apartmentImg2 from "../../../assets/tableView/apartment-2.png";
import apartmentImg3 from "../../../assets/tableView/apartment-3.png";
import "./CommonArea.scss";
export default function CommonArea() {
  const data = [
    {
      heading: "LIFTS / ELEVATOR",
      text: "KONE 13 passenger Lift (Reputed Brand from Finland Europe). 2 nos. for each Phase. Fully Automatic with SS Aesthetics & Child Lock Enabled.",
    },
    {
      heading: "STAIRCASE AND LOBBIES",
      text: "Indoor Common areas flooring: Ceramic tiles Staircase/Service area: Marble / Granite Flooring Entrance ground floor lobby: Marble / Vitrified tiles Other floor lobbies: Marble / Vitrified tiles",
    },
    {
      heading: "COMMON LIGHTING",
      text: "Overhead illumination for compound and street lighting/Ample illumination in all lobbies, staircases and common areas",
    },
    {
      heading: "INTERNAL COMMON AREA WALLS",
      text: "POP/JK Wall Putty over plaster",
    },
  ];

  return (
    <>
      <div className="commonArea__images">
        <img src={apartmentImg1} alt="" />
        <div className="commonArea__images__subcontainer">
          <img src={apartmentImg2} alt="" />

          <img src={apartmentImg3} alt="" />
        </div>
      </div>
      <div className="commonArea__infoContainer">
        {data.map(({ heading, text }) => (
          <div className="commonArea__infoContainer__info">
            <b>{heading}</b>
            <p>{text}</p>
          </div>
        ))}
      </div>
    </>
  );
}
