import Back from '../Back/Back'
import './Broucher.scss';
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { MdOutlineArrowBackIos } from 'react-icons/md'

import slide1 from '../../../assets/tableView/brochure/brochure1-01.png';
import slide2 from '../../../assets/tableView/brochure/brochure1-02.png';
import slide3 from '../../../assets/tableView/brochure/brochure1-03.png';
import slide4 from '../../../assets/tableView/brochure/brochure1-04.png';
import slide5 from '../../../assets/tableView/brochure/brochure1-05.png';
import slide6 from '../../../assets/tableView/brochure/brochure1-06.png';
import slide7 from '../../../assets/tableView/brochure/brochure1-07.png';
import slide8 from '../../../assets/tableView/brochure/brochure1-08.png';
import slide9 from '../../../assets/tableView/brochure/brochure1-09.png';
import slide10 from '../../../assets/tableView/brochure/brochure1-10.png';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
const Broucher = () => {
    const site = JSON.parse(localStorage.getItem('site'))
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const ref = useRef(null);
    const swiperRef = useRef();

    return (
        <div className='broucher'>
            {/*<Back navigateTo={`/tabletView/dashboard/${site?.siteCode}`} />*/}
            <div className="broucher__container">
                <h1>Brochure</h1>
                <div style={{ width: '90%', margin: '3rem auto', position: 'relative' }}>

                    <Swiper
                        style={{
                            '--swiper-navigation-color': '#fff',
                            '--swiper-pagination-color': '#fff',
                        }}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        loop={true}
                        spaceBetween={10}
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper2"
                    >
                        {
                            [slide1, slide2, slide3, slide4, slide5, slide6, slide7, slide8, slide9, slide10].map(slide => <SwiperSlide key={slide}>
                                <img src={slide} alt='slide' />
                            </SwiperSlide>)
                        }

                    </Swiper>

                    <br />
                    <br />

                    <Swiper
                        onSwiper={setThumbsSwiper}
                        loop={true}
                        spaceBetween={30}
                        slidesPerView={4}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper"
                    >
                        {
                            [slide1, slide2, slide3, slide4, slide5, slide6, slide7, slide8, slide9, slide10].map(slide => <SwiperSlide>
                                <img
                                    src={slide}
                                    alt='slide'
                                    className='slide__preview' />
                            </SwiperSlide>)
                        }
                    </Swiper>
                    <div className='circle__red__left'>

                    </div>
                    <div className='circle__red__right'>

                    </div>
                    <div
                        onClick={() => swiperRef.current?.slidePrev()}
                        className="backward__arrow"
                    >
                        <MdOutlineArrowBackIos className="text-2xl" />
                    </div>

                    <div onClick={() => {
                        swiperRef.current?.slideNext()
                    }}
                        className="forward__arrow"
                    >
                        <MdOutlineArrowBackIos style={{ rotate: '180deg' }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Broucher