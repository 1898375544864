import { Paper, Switch } from '@mui/material'
import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import axios from 'axios'
import { BASE_URL } from '../../utils/config/url';
import Swal from 'sweetalert2'
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

const RedSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#EE4B46",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#EE4B46",
    },
}));


const AbsentAndLateDays = ({ paySlip, setPaySlip }) => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const [absentDays, setAbsentDays] = React.useState([])
    const [lateDays, setLateDays] = React.useState([])
    const [spinnerModal, setSpinnerModal] = React.useState(false);


    useEffect(() => {

        // console.log(paySlip.absentDays)

        let temp = []
        paySlip?.absentDays?.forEach((day, index) => {

            let d = new Date(day.date).getDate()
            if (d < 10) d = `0${d}`
            let y = new Date(day.date).getFullYear()

            temp.push({ date: `${d}/${paySlip?.monthName}/${y}`, cancel: `${day?.cancel}` })
        })

        temp.sort((a, b) => {
            let dateA = new Date(a.date)
            let dateB = new Date(b.date)
            return dateA - dateB
        })

        setAbsentDays(temp)
    }, [])


    useEffect(() => {

        let temp = []
        paySlip?.lateDays?.forEach((day, index) => {
            let d = new Date(day.date).getDate()
            if (d < 10) d = `0${d}`
            let y = new Date(day.date).getFullYear()

            temp.push({ date: `${d}/${paySlip?.monthName}/${y}`, cancel: `${day?.cancel}` })
        })

        temp.sort((a, b) => {
            let dateA = new Date(a.date)
            let dateB = new Date(b.date)
            return dateA - dateB
        })

        setLateDays(temp)
    }, [])

    const recalculateAbsentDays = () => {

        setSpinnerModal(true)

        axios.put(`${BASE_URL}/api/v1/hrmgmt/payslip/adjustAbsentDays`,
            {
                payslipNumber: paySlip?.payslipNumber,
                payslipStatus: paySlip?.payslipStatus,
                adjustAbsentDays: paySlip?.absentDays
            }, {
            headers: {
                Authorization: `bearer ${userInfo.token}`
            }
        })
            .then((response) => {
                setSpinnerModal(false)
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.message
                })
                    .then(() => {
                        setPaySlip(response.data.paySlip)
                    })
            })
            .catch((err) => {
                setSpinnerModal(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error
                })
            })

    }

    const recalculateLateDays = () => {

        setSpinnerModal(true)

        axios.put(`${BASE_URL}/api/v1/hrmgmt/payslip/adjustLateDays`,
            {
                payslipNumber: paySlip?.payslipNumber,
                payslipStatus: paySlip?.payslipStatus,
                adjustLateDays: paySlip?.lateDays
            }, {
            headers: {
                Authorization: `bearer ${userInfo.token}`
            }
        })
            .then((response) => {
                setSpinnerModal(false)
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.message
                })
                    .then(() => {
                        setPaySlip(response.data.paySlip)
                    })
            })
            .catch((err) => {
                setSpinnerModal(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.error
                })
            })

    }


    return (
        <div className='generatedPaySlip__absent'>

            <Paper elevation={2} style={{ padding: "20px", marginTop: "40px", width: '70%', margin: 'auto' }}>

                <div className='row justify-content-around'>

                    <div className='col-4'>
                        <h4 className='text-center mb-4'>
                            Adjust Absent Days
                        </h4>
                    </div>

                    <div className='col-6 text-center'>
                        <b style={{ fontSize: '20px' }}>Total Absent Days : {paySlip?.noOfDaysAbsent}</b>
                    </div>

                </div>



                {
                    absentDays?.map((day, index) => {
                        return <div key={index} className='row justify-content-center'>
                            <div className='col-6 text-center'>{day?.date}</div>
                            <div className='col-6 text-center'>

                                <RedSwitch
                                    // inputProps={{ 'aria-label': 'controlled' }}
                                    checked={day?.cancel == true || day?.cancel === 'true' ? true : false}
                                    onChange={(e) => {
                                        let temp = [...absentDays]
                                        // console.log(e.target.checked)
                                        temp[index].cancel = e.target.checked
                                        setAbsentDays(temp)
                                        paySlip.absentDays[index].cancel = e.target.checked
                                    }}
                                />

                            </div>
                        </div>
                    })
                }

                <div className='row justify-content-center'>
                    <div className='col-6 text-center'>
                        <button className='generatedPaySlip__btn'
                            onClick={recalculateAbsentDays}
                        >Re-Calculate</button>
                    </div>
                </div>


            </Paper>

            <br />

            <Paper elevation={2} style={{ padding: "20px", marginTop: "40px", width: '70%', margin: 'auto' }}>

                <div className='row justify-content-around'>

                    <div className='col-4'>
                        <h4 className='text-center mb-4'>
                            Adjust Late Days
                        </h4>
                    </div>

                    <div className='col-6 text-center'>
                        <b style={{ fontSize: '20px' }}>Total Late Days : {paySlip?.noOfDaysLate}</b>
                    </div>

                </div>

                {
                    lateDays?.map((day, index) => {
                        return <div key={index} className='row justify-content-center'>
                            <div className='col-6 text-center'>{day?.date}</div>
                            <div className='col-6 text-center'>

                                <RedSwitch
                                    // inputProps={{ 'aria-label': 'controlled' }}
                                    checked={day?.cancel === true || day?.cancel === 'true' ? true : false}
                                    onChange={(e) => {
                                        let temp = [...lateDays]
                                        temp[index].cancel = e.target.checked
                                        setLateDays(temp)
                                        paySlip.lateDays[index].cancel = e.target.checked
                                    }}
                                />

                            </div>
                        </div>
                    })
                }

                <div className='row justify-content-center'>
                    <div className='col-6 text-center'>
                        <button className='generatedPaySlip__btn'
                            onClick={recalculateLateDays}
                        >Re-Calculate</button>
                    </div>
                </div>


            </Paper>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={spinnerModal}
                onClose={() => setSpinnerModal(false)}
                closeAfterTransition
            >
                <Fade in={spinnerModal}>

                    <div>
                        <ClipLoader
                            color='#EE4B46'
                            loading={spinnerModal}
                            size={70}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                </Fade>
            </Modal>

        </div>
    )
}

export default AbsentAndLateDays